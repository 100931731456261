import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
  standalone: true
})
export class SortPipe implements PipeTransform {

  public transform(arr: any[] = [], property?: string): any[] {
    return arr.sort((a, b) => {
      const compareA = !!property ? a[property] : a;
      const compareB = !!property ? b[property] : b;
      return compareA < compareB ? -1 : 1;
    });
  }
}
