import { CdkDragEnd, CdkDragMove, Point, CdkDrag } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { colorAtPos, posOfColor } from '@core/helpers/color.helper';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'naris-color-palette',
  templateUrl: './color-palette.component.html',
  styleUrls: ['./color-palette.component.scss'],
  standalone: true,
  imports: [NgStyle, CdkDrag]
})
export class ColorPaletteComponent implements OnInit {

  @ViewChild('canvas') public canvas: ElementRef<HTMLCanvasElement>;
  @Input() public hueChange: Subject<string>;
  @Input() public chosenHue: string | null;
  @Input() public height = 256;
  @Output() public readonly color = new EventEmitter<string>();
  private ctx: CanvasRenderingContext2D | null;
  public colorPos: Point;
  public dragging = false;
  public currentColor: string;
  private hue: string;

  public ngOnInit(): void {
    this.hueChange.pipe(distinctUntilChanged()).subscribe(hue => {
      this.hue = hue;
      this.draw();
      if (!!this.chosenHue) {
        this.colorPos = posOfColor(this.ctx, this.canvas.nativeElement, this.chosenHue);
        this.currentColor = this.chosenHue;
        this.color.emit(this.chosenHue);
        this.chosenHue = null;
      } else if (!!this.colorPos) this.emitColor(this.colorPos.x, this.colorPos.y);
    });
  }

  public onClick(evt: MouseEvent): void {
    this.colorPos = { x: evt.offsetX, y: evt.offsetY };
    this.emitColor(this.colorPos.x, this.colorPos.y);
  }

  private emitColor(x: number, y: number): void {
    if (x >= 0 && y >= 0) this.currentColor = colorAtPos(this.ctx, x, y, 'hex');
    this.color.emit(this.currentColor);
  }

  private draw(): void {
    const canvas = this.canvas.nativeElement;
    this.ctx ??= this.canvas.nativeElement.getContext('2d');
    if (!this.ctx) return;
    this.ctx.fillStyle = this.hue;
    this.ctx.fillRect(0, 0, canvas.width, canvas.height);
    const whiteGrad = this.ctx.createLinearGradient(0, 0, canvas.width, 0);
    whiteGrad.addColorStop(0, '#ffffffff');
    whiteGrad.addColorStop(1, '#ffffff00');
    this.ctx.fillStyle = whiteGrad;
    this.ctx.fillRect(0, 0, canvas.width, canvas.height);
    const blackGrad = this.ctx.createLinearGradient(0, 0, 0, canvas.height);
    blackGrad.addColorStop(0, '#00000000');
    blackGrad.addColorStop(1, '#000000ff');
    this.ctx.fillStyle = blackGrad;
    this.ctx.fillRect(0, 0, canvas.width, canvas.height);
  }

  public onDragMoved(evt: CdkDragMove): void {
    const pos = evt.source.getFreeDragPosition();
    this.emitColor(pos.x, pos.y);
  }

  public onDragEnded(evt: CdkDragEnd): void {
    const pos = evt.source.getFreeDragPosition();
    this.colorPos = {x: pos.x, y: pos.y};
  }
}
