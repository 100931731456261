import { inherits as e, createSuper as t, classCallCheck as n, defineProperty as o, assertThisInitialized as i, toConsumableArray as s, createClass as a } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { COMPONENT_TYPE_CONNECTION as r, Components as l, Component as d } from "../component/component.js";
import { convertToFullOverlaySpec as p } from "../overlay/overlay.js";
import { KEY_CONNECTION_OVERLAYS as c } from "../constants.js";
import { extend as u, uuid as v, isObject as y } from "../../util/util.js";
import { DEFAULT as f } from "../../common/index.js";
import { LabelOverlay as m } from "../overlay/label-overlay.js";
import { _internalLabelOverlayId as h, _internalLabelOverlayClass as S, Connections as g } from "./connections.js";
var E = "_jsplumb_connection";
var b = function (d) {
  e(C, d);
  var b = t(C);
  function C(e, t) {
    var a;
    n(this, C);
    a = b.call(this, e, "_jsPlumb_c", t);
    a.instance = e;
    o(i(a), "edge", void 0);
    o(i(a), "objectType", r);
    o(i(a), "overlays", {});
    o(i(a), "overlayPositions", {});
    o(i(a), "overlayPlacements", {});
    o(i(a), "connector", void 0);
    o(i(a), "defaultLabelLocation", .5);
    o(i(a), "scope", void 0);
    o(i(a), "_pathEditing", void 0);
    o(i(a), "typeId", E);
    o(i(a), "idPrefix", "_jsPlumb_c");
    o(i(a), "previousConnection", void 0);
    o(i(a), "sourceId", void 0);
    o(i(a), "targetId", void 0);
    o(i(a), "source", void 0);
    o(i(a), "target", void 0);
    o(i(a), "detachable", true);
    o(i(a), "reattach", false);
    o(i(a), "cost", 1);
    o(i(a), "directed", void 0);
    o(i(a), "endpoints", [null, null]);
    o(i(a), "endpointStyles", void 0);
    o(i(a), "endpointSpec", void 0);
    o(i(a), "endpointsSpec", void 0);
    o(i(a), "endpointStyle", {});
    o(i(a), "endpointHoverStyle", {});
    o(i(a), "endpointHoverStyles", void 0);
    o(i(a), "suspendedEndpoint", void 0);
    o(i(a), "suspendedIndex", void 0);
    o(i(a), "suspendedElement", void 0);
    o(i(a), "suspendedElementId", void 0);
    o(i(a), "suspendedElementType", void 0);
    o(i(a), "_forceReattach", void 0);
    o(i(a), "_forceDetach", void 0);
    o(i(a), "proxies", []);
    o(i(a), "pending", false);
    a.edge = t.edge;
    a.id = t.id;
    a.previousConnection = t.previousConnection;
    a.overlays = {};
    a.overlayPositions = {};
    var d = t.overlays || [],
      c = {};
    var _ = a.getDefaultOverlayKey();
    if (_) {
      var I = a.instance.defaults[_];
      if (I) {
        d.push.apply(d, s(I));
      }
      for (var P = 0; P < d.length; P++) {
        var j = p(d[P]);
        c[j.options.id] = j;
      }
    }
    a._defaultType.overlays = c;
    if (t.label) {
      a._defaultType.overlays[h] = {
        type: m.type,
        options: {
          label: t.label,
          useHTMLElement: t.useHTMLLabel === true,
          location: t.labelLocation || a.defaultLabelLocation,
          id: h,
          cssClass: S
        }
      };
    }
    a.source = t.source;
    a.target = t.target;
    if (t.sourceEndpoint) {
      a.source = t.sourceEndpoint.element;
      a.sourceId = t.sourceEndpoint.elementId;
    } else {
      a.sourceId = e.getId(a.source);
    }
    if (t.targetEndpoint) {
      a.target = t.targetEndpoint.element;
      a.targetId = t.targetEndpoint.elementId;
    } else {
      a.targetId = e.getId(a.target);
    }
    a.scope = t.scope;
    var T = t.anchors ? t.anchors[0] : t.anchor;
    var H = t.anchors ? t.anchors[1] : t.anchor;
    e.manage(a.source);
    e.manage(a.target);
    a.visible = true;
    a.params = {
      cssClass: t.cssClass,
      hoverClass: t.hoverClass,
      "pointer-events": t["pointer-events"],
      overlays: t.overlays
    };
    a.lastPaintedAt = null;
    if (t.type) {
      t.endpoints = t.endpoints || a.instance._deriveEndpointAndAnchorSpec(t.type).endpoints;
    }
    a.endpointSpec = t.endpoint;
    a.endpointsSpec = t.endpoints || [null, null];
    a.endpointStyle = t.endpointStyle;
    a.endpointHoverStyle = t.endpointHoverStyle;
    a.endpointStyles = t.endpointStyles || [null, null];
    a.endpointHoverStyles = t.endpointHoverStyles || [null, null];
    a.paintStyle = t.paintStyle;
    a.hoverPaintStyle = t.hoverPaintStyle;
    g.makeEndpoint(i(a), true, a.source, a.sourceId, T, t.sourceEndpoint);
    g.makeEndpoint(i(a), false, a.target, a.targetId, H, t.targetEndpoint);
    if (!a.scope) {
      a.scope = a.endpoints[0].scope;
    }
    if (t.deleteEndpointsOnEmpty != null) {
      a.endpoints[0].deleteOnEmpty = t.deleteEndpointsOnEmpty;
      a.endpoints[1].deleteOnEmpty = t.deleteEndpointsOnEmpty;
    }
    var D = a.instance.defaults.connectionsDetachable;
    if (t.detachable === false) {
      D = false;
    }
    if (a.endpoints[0].connectionsDetachable === false) {
      D = false;
    }
    if (a.endpoints[1].connectionsDetachable === false) {
      D = false;
    }
    a.endpointsSpec = t.endpoints || [null, null];
    a.endpointSpec = t.endpoint || null;
    var k = t.reattach || a.endpoints[0].reattachConnections || a.endpoints[1].reattachConnections || a.instance.defaults.reattachConnections;
    var L = u({}, a.endpoints[0].connectorStyle || a.endpoints[1].connectorStyle || t.paintStyle || a.instance.defaults.paintStyle);
    l.appendToDefaultType(i(a), {
      detachable: D,
      reattach: k,
      paintStyle: L,
      hoverPaintStyle: u({}, a.endpoints[0].connectorHoverStyle || a.endpoints[1].connectorHoverStyle || t.hoverPaintStyle || a.instance.defaults.hoverPaintStyle)
    });
    if (t.outlineWidth) {
      L.outlineWidth = t.outlineWidth;
    }
    if (t.outlineColor) {
      L.outlineStroke = t.outlineColor;
    }
    if (t.lineWidth) {
      L.strokeWidth = t.lineWidth;
    }
    if (t.color) {
      L.stroke = t.color;
    }
    if (!a.instance._suspendDrawing) {
      var x = a.instance._suspendedAt || v();
      a.instance._paintEndpoint(a.endpoints[0], {
        timestamp: x
      });
      a.instance._paintEndpoint(a.endpoints[1], {
        timestamp: x
      });
    }
    a.cost = t.cost || a.endpoints[0].connectionCost;
    a.directed = t.directed;
    if (t.directed == null) {
      a.directed = a.endpoints[0].connectionsDirected;
    }
    var O = u({}, a.endpoints[1].parameters);
    u(O, a.endpoints[0].parameters);
    u(O, a.parameters);
    a.parameters = O;
    a.paintStyleInUse = a.paintStyle || {};
    g._setConnector(i(a), a.endpoints[0].connector || a.endpoints[1].connector || t.connector || a.instance.defaults.connector, true);
    var W = t.data == null || !y(t.data) ? {} : t.data;
    l.setData(i(a), W);
    var A = [f, a.endpoints[0].edgeType, a.endpoints[1].edgeType, t.type].join(" ");
    if (/[^\s]/.test(A)) {
      l.addType(i(a), A, t.data);
    }
    return a;
  }
  a(C, [{
    key: "getDefaultOverlayKey",
    value: function e() {
      return c;
    }
  }, {
    key: "getXY",
    value: function e() {
      return {
        x: this.connector.x,
        y: this.connector.y
      };
    }
  }]);
  return C;
}(d);
o(b, "type", r);
export { b as ConnectionImpl, E as TYPE_ID_CONNECTION };