import { Component, EventEmitter, forwardRef, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { FormService } from '@core/services';
import { ArchimateService } from '@core/services/archimate.service';
import { FooterToolbarService } from '@core/services/footer-toolbar.service';
import { GuardService } from '@core/services/guard.service';
import { Subscription } from 'rxjs';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormInputComponent } from '../form-input/form-input.component';
import { FormFrequencyComponent } from '../form-frequency/form-frequency.component';

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, forwardRef(() => FormInputComponent), NgClass, forwardRef(() => FormFrequencyComponent), TranslateModule]
})
export class FormGroupComponent implements OnInit, OnDestroy {

  @ViewChild('hideOptionalToggle') public optionalToggle: MatSlideToggle;

  @Input() public formGroup: FormGroup;

  @Input() public label: string;

  @Input() public formId: string;

  private _inputs: any[];
  public _frequencyInputs: any[];
  @Input() set inputs(value: any[]) {
    this._frequencyInputs = JSON.parse(JSON.stringify(value));
    this._inputs = value;

    const frequencyIndex = this._inputs?.findIndex(input => input.id === 'FrequencyUnit');
    if (frequencyIndex > -1) {
      this._inputs = this._inputs.filter((control, index) => index < frequencyIndex);
    }
    this.onlyOptionalInputs.next(this._inputs?.filter(input => !input.hidden)?.every(input => !input.mandatory));
    this.onlyMandatoryInputs.next(this._inputs?.filter(input => !input.hidden)?.every(input => input.mandatory));
  }

  get inputs(): any[] {
    return this._inputs;
  }

  @Input() public errors: any[] | null;
  @Input() public isEmbedded = false;
  @Input() public isAssessment = false;
  @Input() public isConsequence = false;
  @Input() public hidePreviousAssessment = false;
  @Input() public canEditApplicability = false;
  @Input() public tabHref: string;

  @Input() public isStriped = true;
  @Input() public endpoint: string;
  @Input() public isInitialApplicability = false;
  @Input() public isProcessToolkitForm = false;
  @Input() public isContextChips = false;
  @Input() public isArchimate = false;

  @Output()
  public readonly autoSubmit = new EventEmitter<void>();

  @Output()
  public readonly backAndReset = new EventEmitter<void>();

  @Output()
  public readonly onlyOptionalInputs = new EventEmitter<boolean>();
  @Output()
  public readonly onlyMandatoryInputs = new EventEmitter<boolean>();
  public readonly historyNames = ['CategoryImpactHistoryTypeOther', 'CategoryImpactHistoryTypeNotOther', 'LikelihoodHistory', 'ArgumentationHistory'];
  public nonEssentialFields = ['helperText', 'heading'];
  public applicabilityFields = [''];
  public noOptionalFields = false;
  public hideOptionalFields = false;
  public applicabilityStatus: boolean;
  public readonlyFields: string[] = [];
  public currentFormValue: string;
  public valueChangeTimeout: NodeJS.Timeout;

  private readonly subs: Subscription[] = [];

  constructor(
    private readonly footerToolbarService: FooterToolbarService,
    private readonly formService: FormService,
    private readonly router: Router,
    private readonly guardService: GuardService,
    private readonly archimateService: ArchimateService
  ) {}

  public ngOnInit(): void {
    this.setToggleState();

    if (!this.canEditApplicability) {
      const applicableInputs = this.inputs?.filter(input => input.layouthint?.includes('Applicable'));
      applicableInputs?.forEach(input => {
        const control = this.formGroup.get(input.id);
        if (control) control.disable();
      });
    }

    const url = this.endpoint || this.tabHref || this.router.url;
    if (!url.includes('-wizard') && url.includes('/object/')) {
      this.setDisabled();
      this.subs.push(
        this.formService.updateObjectForm$.subscribe(val => { 
          if (val.enabled) {
            this.setEnabled(val.endpoint);
          } else {
            this.setDisabled(val.endpoint);
          }
        })
      );
    }

    if (this.isArchimate && this.endpoint.includes('add-archimate')) {
      const position = this.formGroup.get('Position');
      position?.setValue(this.archimateService.position);
      const type = this.formGroup.get('Type');
      type?.setValue(this.archimateService.type);
    }

    this.subs.push(
      this.footerToolbarService.hideOptionalFields$.subscribe(() => {
        this.setToggleState();
      }),
      this.formGroup.valueChanges.subscribe(() => {
        if (this.formGroup.dirty) {
          clearTimeout(this.valueChangeTimeout);
          this.valueChangeTimeout = setTimeout(() => {
            const value = JSON.stringify(this.formGroup.getRawValue());
            this.guardService.setChanged(this.endpoint, value);
          }, 500);
        }
        if (!!this.errors?.length) {
          this.errors = null;
          Object.keys(this.formGroup.controls).forEach(key => {
            this.formGroup.get(key)?.setErrors(null);
          });
        }
      }),
      this.formService.resetControlError$.subscribe(elementId => this.resetControlError(elementId))
    );
  }

  private setDisabled(endpoint?: string) {
    if (!!endpoint && endpoint !== this.endpoint) return;
    if (!this.formGroup.disabled) {
      this.readonlyFields = [];
      Object.keys(this.formGroup.controls).forEach(key => {
        const control = this.formGroup.controls[key];
        if (control.disabled) this.readonlyFields.push(key);
      });
      this.formGroup.disable();
    }
  }

  private setEnabled(endpoint?: string) {
    if (!!endpoint && endpoint !== this.endpoint) return;
    if (!this.formGroup.enabled) {
      this.formGroup.enable();
      this.readonlyFields.forEach(key => {
        const control = this.formGroup.controls[key];
        if (!!control) control.disable();
      });
    }
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  get filteredInputs(): any[] {
    return this._inputs.filter((i: any) => !i.hidden && !i.layouthint?.includes('hide') && !(this.isConsequence && i.id === 'EventCategoryId') && !(!this.hidePreviousAssessment && this.historyNames.includes(i.id)));
  }

  public getErrorForInput(elementid: string): any[] | undefined {
    const error = this.errors?.filter((err: any) => err.anchor?.elementid === elementid);
    if (!!error?.length) 
      this.formGroup.controls?.[elementid]?.setErrors({});

    return error;
  }

  private resetControlError(elementId: string | undefined) {
    if (!elementId) return;
    const errorIndex = this.errors?.findIndex((err: any) => err.anchor?.elementid === elementId);
    if (errorIndex! > -1)
      this.errors?.splice(errorIndex!, 1);
    this.formGroup.controls?.[elementId]?.setErrors(null);
  }

  /**
   * Determines if this form group is a frequency group
   * @returns boolean
   */
  get hasFrequency(): boolean {
    return this._frequencyInputs?.find((i: any) => i.id === 'FrequencyUnit');
  }

  @HostBinding('class')
  get rootClasses() {
    if (!this.isEmbedded) return;
    if (this.isConsequence) return ['is-consequence'];
    if (this.isAssessment) return ['assessment'];
    return ['embedded'];
  }

  public setToggleState() {
    if (this._inputs?.every(input => !input.mandatory)) {
      this.hideOptionalFields = false;
    } else {
      this.hideOptionalFields = this.footerToolbarService.getChecked();
    }
  }
}
