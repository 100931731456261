import { createClass as e, classCallCheck as t, defineProperty as a, toConsumableArray as r } from "../_virtual/_rollupPluginBabelHelpers.js";
import { DEFAULT as s } from "../ui-core/common/index.js";
var i = function () {
  function i(e, r, n) {
    var l = this;
    t(this, i);
    this.instance = e;
    this.tagName = r;
    this.options = n;
    a(this, "id", void 0);
    a(this, "template", void 0);
    a(this, "rendered", void 0);
    a(this, "updated", void 0);
    a(this, "fragments", {});
    a(this, "defaultableFragmentKeys", void 0);
    a(this, "fragmentKeys", void 0);
    a(this, "parsedTemplates", {});
    this.id = "custom-".concat(r);
    var h = n.fragments || {};
    this.defaultableFragmentKeys = n.defaultableFragmentKeys || [];
    this.template = n.template;
    var u = new Set();
    for (var f in h) {
      this.fragments[f] = this.fragments[f] || {};
      u.add(f);
      var o = function t(a) {
        var s = "custom-".concat(r, "-").concat(f, "-").concat(a);
        var i = h[f][a];
        e.addTemplate(s, i);
        l.fragments[f][a] = e.parseAndCache(s, function (e) {
          if (e === s) {
            return i;
          }
        });
      };
      for (var c in h[f]) {
        o(c);
      }
    }
    this.fragmentKeys = Array.from(u);
    this.fragmentKeys.sort();
    this.parsedTemplates[s] = this._parseDefault(this.id);
    this.rendered = n.rendered || function (e, t, a) {};
    this.updated = n.updated || function (e, t, a) {};
  }
  e(i, [{
    key: "_parseDefault",
    value: function e(t) {
      var a = this;
      return this.instance.parseAndCache(t, function (e) {
        if (e === t) {
          return a.template;
        }
      });
    }
  }, {
    key: "extractTemplateData",
    value: function e(t, a) {
      var r = t.split(":"),
        i = [];
      for (var n = 0; n < r.length; n++) {
        var l = a[r[n]];
        if (l == null) {
          if (this.defaultableFragmentKeys.indexOf(r[n]) !== -1) {
            i.push(s);
          } else {
            return null;
          }
        } else {
          i.push(l);
        }
      }
      return i.join(":");
    }
  }, {
    key: "getAST",
    value: function e(t) {
      var a = this;
      if (t == null) {
        return this.parsedTemplates[s];
      } else {
        var r = [];
        var i = {};
        this.fragmentKeys.forEach(function (e) {
          var s = a.extractTemplateData(e, t);
          if (s != null) {
            i[e] = a.fragments[e][s] || [];
            r.push("".concat(e, ":").concat(s));
          }
        });
        if (r.length > 0) {
          var n = r.join(" ");
          if (this.parsedTemplates[n] != null) {
            return this.parsedTemplates[n];
          } else {
            var l = this._parseDefault(n);
            this._replaceAST(l[0], i);
            this.parsedTemplates[n] = l;
            return l;
          }
        }
        return this.parsedTemplates[s];
      }
    }
  }, {
    key: "_replaceAST",
    value: function e(t, a) {
      var s = [];
      for (var i = 0; i < t.children.length; i++) {
        if (t.children[i].tag === "r-fragment") {
          var n = a[t.children[i].atts.key] || [];
          s.push.apply(s, r(n));
        } else {
          s.push(t.children[i]);
          this._replaceAST(t.children[i], a);
        }
      }
      t.children = s;
    }
  }]);
  return i;
}();
export { i as CustomTag };