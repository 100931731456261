import { Component, OnInit, Input, ChangeDetectionStrategy, forwardRef } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FREQUENCY_CONFIG } from '@core/constants';
import { MatTooltip } from '@angular/material/tooltip';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { NgTemplateOutlet } from '@angular/common';
import { OrdinalPipe } from '@shared/pipes/ordinal.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { InlineInputComponent } from '../../../shared/components/inline-input/inline-input.component';
import { InlineSelectComponent } from '../../../shared/components/inline-select/inline-select.component';
import { ButtonComponent } from '../../../shared/elements/button/button.component';
import { TimepickerComponent } from '../../../shared/components/timepicker/timepicker.component';
import { DatepickerComponent } from '../../../shared/components/datepicker/datepicker.component';
import { DoubleDatepickerComponent } from '../../../shared/components/double-datepicker/double-datepicker.component';
import { FormInputComponent } from '../form-input/form-input.component';
import type { DateTime } from 'luxon';

@Component({
  selector: 'app-form-frequency',
  templateUrl: './form-frequency.component.html',
  styleUrls: ['./form-frequency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [InlineInputComponent, InlineSelectComponent, ButtonComponent, MatTooltip, TimepickerComponent, MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, DatepickerComponent, DoubleDatepickerComponent, NgTemplateOutlet, forwardRef(() => FormInputComponent), FormsModule, ReactiveFormsModule, OrdinalPipe, TranslateModule]
})
export class FormFrequencyComponent implements OnInit {
  @Input() public formGroup: FormGroup;
  @Input() public inputs: any[];
  @Input() public formId: string;
  @Input() set errors(value: any[] | null) {
    value?.forEach(err => {
      if (!!err?.anchor?.elementid.toLowerCase().startsWith('period')) {
        this.periodError = {error: err.message, control: err.anchor.elementid.split('.')?.[1]};
      }
    });
  }

  public periodError: any;

  public type = 'custom';
  public form: FormGroup;
  public config = FREQUENCY_CONFIG;
  public loaded = false;
  public minDate: DateTime;
  public maxDate: DateTime;

  public ngOnInit(): void {
    this.load(this.formGroup.value);
  }

  /**
   * Returns any restrictions set for the period input.
   * Contains min and max date used on the datepicker component.
   */
  get restrictions(): { minDate: DateTime; maxDate: DateTime } | null {
    const restrictions = this.getInput('Once_DeadlineRestriction')?.value || {};
    return !restrictions || Object.values(restrictions).every(r => !r) ? null : {
      minDate: restrictions.StartDate || null,
      maxDate: restrictions.EndDate || null
    };
  }

  get rangeRestrictions(): { minDate: DateTime; maxDate: DateTime } | null {
    const rangeRestrictions = this.getInput('PeriodRestriction')?.value || {};
    return !rangeRestrictions || Object.values(rangeRestrictions).every(r => !r) ? null : {
      minDate: rangeRestrictions.StartDate_TimeStamp || null,
      maxDate: rangeRestrictions.EndDate_TimeStamp || null
    };
  }

  public load(values: any) {
    const rawType = values['FrequencyUnit'];
    if (rawType === 'Once') {
      this.type = 'once';
    } else {
      this.type = 'custom';
    }
  }

  public setType(type?: string) {
    const freqUnit = this.formGroup.get('FrequencyUnit');
    if (this.type !== type) this.formGroup.markAsDirty();
    this.type = type || freqUnit?.value;
    // Set unit to once for once type
    if (type === 'once' && freqUnit?.value !== 'Once') freqUnit?.setValue('Once');
    // Set unit to days for custom type if it it not a valid option
    if (type === 'custom' && !['Days', 'Weeks', 'Months', 'Years'].includes(freqUnit?.value)) freqUnit?.setValue('Days');
    // Set unit to days for workdays option
    if (type === 'workdays' && freqUnit?.value !== 'Days') freqUnit?.setValue('Days');
  }

  public setOption(id: string, value: string) {
    const control = this.formGroup.get(id);
    control?.setValue(value);
  }

  public getInput(id: string) {
    return this.inputs.find((i: any) => i.id === id);
  }

  public getErrorForInput(elementid: string): any {
    return this.errors?.filter((err: any) => err.anchor?.elementid === elementid).map(error => error.message).join(', ');
  }

  public getFormControl(controlName: string) {
    return this.formGroup.get(controlName) as FormControl;
  }
}
