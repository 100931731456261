import { Port as e } from "./graph.js";
function t(t) {
  var n = t.graph,
    r = t.source,
    o = t.target,
    i = t.nodeFilter,
    l = t.edgeFilter,
    u = {},
    c = {},
    a = {},
    d = {
      dist: u,
      previous: c,
      edges: a,
      path: []
    },
    f = t.processAll,
    g = {},
    p = {},
    s = !(t.strict === false),
    v = function e(t) {
      return t.getFullId ? t.getFullId() : t.id;
    },
    h = [],
    y = function e(t) {
      var n = p[t.getFullId()];
      return g[n.v.id];
    },
    b = function t(n, r) {
      var o, i;
      if (n.objectType === e.objectType) {
        u[n.getFullId()] = r;
        o = y(n);
        for (i = 0; i < o.length; i++) {
          if (o[i].p != n) {
            u[o[i].p.getFullId()] = r + n.getParent().getInternalEdge(n, o[i].p).cost;
          }
        }
        if (!s) {
          u[n.getParent().id] = r;
        }
      } else {
        u[n.id] = r;
        o = g[n.id];
        for (i = 0; i < o.length; i++) {
          u[o[i].p.getFullId()] = r;
        }
      }
    },
    j = function e(t) {
      if (i && !i(t)) return Infinity;
      return u[v(t)];
    },
    T = function t(n, r, o) {
      if (n.objectType === e.objectType) {
        var i = y(n);
        for (var l = 0; l < i.length; l++) {
          c[i[l].p.getFullId()] = o.node;
        }
        if (!s) c[n.getParent().id] = o.node;
      }
      c[r] = o.node;
    },
    I = function t(n, r, o) {
      if (n.objectType === e.objectType) {
        var i = y(n);
        for (var l = 0; l < i.length; l++) {
          a[i[l].p.getFullId()] = o;
        }
        if (!s) a[n.getParent().id] = o;
      }
      a[r] = o;
    },
    F = function t(n, r, o, i, l) {
      var u = -1,
        c = null,
        a = Infinity;
      for (var d = 0; d < n.length; d++) {
        if (!r[d]) {
          var f = l(n[d]);
          if (f < a) {
            a = f;
            u = d;
            c = n[d];
          } else if (f === a) {
            if (n[d].objectType === e.objectType && n[d].getParent() === c) {
              u = d;
              c = n[d];
            }
          }
        }
      }
      return {
        node: c,
        index: u
      };
    },
    P = function e(t, n) {
      var r = n.getFullId(),
        o = t[r];
      if (o == null) {
        r = n.getParent ? n.getParent().id : n.id;
        o = t[r];
      }
      return o == null ? null : {
        p: o,
        id: r
      };
    },
    x = function e(t, n, r, o, i, l) {
      var u = [],
        c = o;
      var a = P(n, c);
      while (a != null) {
        u.splice(0, 0, {
          vertex: c,
          cost: t[a.id],
          edge: r[a.id]
        });
        c = a.p;
        a = P(n, c);
      }
      u.splice(0, 0, {
        vertex: c,
        cost: 0,
        edge: null
      });
      return u;
    };
  var k = function e(t) {
    for (var n = 0; n < t.length; n++) {
      var r = t[n],
        o = r.getPorts();
      h.push(r);
      var i = {
        v: r,
        i: h.length - 1
      };
      g[r.id] = [];
      b(r, Infinity);
      for (var l = 0; l < o.length; l++) {
        h.push(o[l]);
        p[o[l].getFullId()] = i;
        g[r.id].push({
          p: o[l],
          i: h.length - 1
        });
        b(o[l], Infinity);
      }
    }
  };
  k(n.nodes);
  k(n.groups);
  if (r == null) {
    r = n.getVertex(t.sourceId);
  }
  if (o == null) {
    o = n.getVertex(t.targetId);
  }
  if (r == null || o == null) {
    return d;
  }
  b(r, 0);
  var C = new Array(n.nodes.length),
    w = 0,
    A = function e(t, n, r, o) {
      for (var i = 0; i < n.length; i++) {
        var l = n[i];
        if (r(l)) {
          var u = o(l),
            c = u.tp || u.tn,
            a = v(c);
          var d = j(t.node) + l.getCost(),
            f = j(c);
          if (d < f) {
            b(c, d);
            T(c, a, t);
            I(c, a, l);
          }
        }
      }
    };
  var O = function t() {
    var n = F(h, C, u, v, j),
      r = n.node ? v(n.node) : null;
    if (!n.node || j(n.node) == Infinity) return "break";
    if (o && (r == v(o) || !s && n.node.objectType === e.objectType && n.node.isChildOf(o))) {
      d.path = x(u, c, a, o);
      d.pathDistance = d.path[d.path.length - 1].cost;
      if (!f) return "break";
    }
    C[n.index] = true;
    w = w + 1;
    A(n, n.node.getAllEdges(), function (t) {
      if (l && !l(t)) return false;
      return !t.isDirected() || n.node == t.source || !s && t.source.objectType === e.objectType && t.source.isChildOf(n.node);
    }, function (t) {
      var r = t.source.objectType === e.objectType ? t.source.getParent() : t.source,
        o = t.source.objectType === e.objectType ? t.source : null,
        i = t.target.objectType === e.objectType ? t.target.getParent() : t.target,
        l = t.target.objectType === e.objectType ? t.target : null;
      return t.source == n.node || !s && t.source.objectType === e.objectType && t.source.isChildOf(n.node) ? {
        tn: i,
        tp: l
      } : {
        tn: r,
        tp: o
      };
    });
  };
  while (w < h.length) {
    var m = O();
    if (m === "break") break;
  }
  return d;
}
export { t as djikstra };