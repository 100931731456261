import { inherits as r, createSuper as e, classCallCheck as t, createClass as a } from "../_virtual/_rollupPluginBabelHelpers.js";
import { BaseSurfaceLayoutAdapter as u } from "./base-surface-layout-adapter.js";
var s = function (u) {
  r(l, u);
  var s = e(l);
  function l(r) {
    var e;
    t(this, l);
    e = s.call(this, r);
    e.surface = r;
    return e;
  }
  a(l, [{
    key: "filter",
    value: function r(e) {
      return e.group == null;
    }
  }, {
    key: "getElements",
    value: function r() {
      var e = this.surface.getNodes();
      Array.prototype.push.apply(e, this.surface.getGroups());
      e = e.filter(function (r, e, t) {
        return r.group == null;
      });
      return e;
    }
  }]);
  return l;
}(u);
export { s as CanvasLayoutAdapter };