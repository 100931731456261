import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root'
})
export class StreamService {

  public localStream: MediaStream | null;
  public streamError$ = new Subject<boolean>();

  constructor(private readonly dialogService: DialogService) { }

  public async openLocalStream(constraintConfig: MediaStreamConstraints = {}): Promise<MediaStream | null> {
    if (!!this.localStream) return this.localStream;
    else {
      const constraints: MediaStreamConstraints = { video: false, audio: { echoCancellation: true, noiseSuppression: true } };
      try {
        this.localStream = await navigator.mediaDevices.getUserMedia({ ...constraints, ...constraintConfig });
        return this.localStream;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        this.dialogService.open({
          type: 'error',
          text: 'collab.dialog.text.no_stream',
          title: 'collab.dialog.title.no_stream'
        }).subscribe(() => this.streamError$.next(true));
        this.localStream = null;
        return null;
      }
    }
  }

  public stopStream(stream: MediaStream) {
    if (stream) stream.getTracks().forEach(track => track.stop());
  }
}
