import { Pipe, PipeTransform } from '@angular/core';
import { FrequencyService } from '@core/services/frequency.service';

@Pipe({
  name: 'toFrequencyString',
  standalone: true
})
export class ToFrequencyStringPipe implements PipeTransform {

  constructor(
    private readonly frequencyService: FrequencyService
  ) {}

  public transform(value: any): any {
    return this.frequencyService.frequencyString(value);
  }

}
