@if (!loading) {
  <div class="canvas-wrapper">
    @if (!!vision) {
      <div class="vision">
        <p>{{vision}}</p>
      </div>
    }
    <!-- main drawing area -->
    <div class="canvas strategy-map">
      <div class="delete" #deleteEdge (click)="doDeleteEdge()" tabindex="0">
        <naris-icon
          icon="delete"
          iconClass="small"
          [matTooltip]="'strategy_map.remove_edge' | translate"
          matTooltipPosition="above"
        />
      </div>
      <jsplumb-surface 
        surfaceId="surface"
        toolkitId="toolkit"
        [toolkitParams]="toolkitParams"
        [renderParams]="renderParams"
        [view]="view"
        [style.height.px]="poolContainer.scrollHeight - 30"
      />
      <div class="pool" #poolContainer>
        @for (lane of lanes; track $index) {
          <div
            class="lane"
            [ngClass]="{'goal-lane': lane.goal}"
            #laneContainer
          >
            <div 
              class="lane__header"
              [style.width.px]="laneContainer.offsetHeight"
            >
              <p class="lane__header__text">{{lane.name}}</p>
              @if (lane.goal && !!addGoalAction) {
                <naris-button
                  class="lane__header__add-goal"
                  icon="add"
                  size="small"
                  color="white"
                  [matTooltip]="'strategy_map.add-goal' | translate"
                  matTooltipPosition="above"
                  matTooltipShowDelay="200"
                  (clicked)="addGoal()"
                  id="StrategyMapAddGoal"
                />
              }
            </div>
          </div>
        }
      </div>
      <div 
        class="drag-preview"
        id="dragPreview"
        [style.display]="dragging ? 'block' : 'none'"
      ></div>
      <!-- node/edge inspector -->
      <app-inspector
        id="nodeInspector"
        surfaceId="surface"
      />
    </div>
  </div>
} @else {
  <div class="strategy-loading-wrapper">
    {{ 'strategy_map.loading' | translate }}
    <naris-bouncer />
  </div>
}
