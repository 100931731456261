import { createClass as i, classCallCheck as t } from "../../_virtual/_rollupPluginBabelHelpers.js";
var s = function () {
  function s(i, o, e, r) {
    t(this, s);
    this.obj = i;
    this.originalPosition = o;
    this.pos = e;
    this.renderer = r;
  }
  i(s, [{
    key: "redo",
    value: function i() {
      this.renderer.setPosition(this.obj, this.pos.x, this.pos.y, true, true);
    }
  }, {
    key: "undo",
    value: function i() {
      this.renderer.setPosition(this.obj, this.originalPosition.x, this.originalPosition.y, true, true);
    }
  }, {
    key: "hasDeltas",
    value: function i() {
      return this.pos.x !== this.originalPosition.x || this.pos.y !== this.originalPosition.y;
    }
  }]);
  return s;
}();
export { s as MoveAction };