import { defineProperty as t } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { ORIENTATION_PERPENDICULAR as e, ORIENTATION_ORTHOGONAL as u, ORIENTATION_OPPOSITE as n } from "./definitions.js";
var r = "x";
var s = "y";
var b = "h";
var S = "w";
var a = {
  x: [0, 1],
  y: [1, 0]
};
function d(t, e) {
  return [t.startStubX, t.startStubY, t.endStubX, t.endStubY];
}
var o = new Map();
o.set(e, d);
o.set(u, d);
o.set(n, function (e, u) {
  var n;
  var b = e,
    S = b.sourceAxis === r ? 0 : 1,
    a = (n = {}, t(n, r, function () {
      return b.so[S] === 1 && (b.startStubX > b.endStubX && b.tx > b.startStubX || b.sx > b.endStubX && b.tx > b.sx) || b.so[S] === -1 && (b.startStubX < b.endStubX && b.tx < b.startStubX || b.sx < b.endStubX && b.tx < b.sx);
    }), t(n, s, function () {
      return b.so[S] === 1 && (b.startStubY > b.endStubY && b.ty > b.startStubY || b.sy > b.endStubY && b.ty > b.sy) || b.so[S] === -1 && (b.startStubY < b.endStubY && b.ty < b.startStubY || b.sy < b.endStubY && b.ty < b.sy);
    }), n);
  if (!u && a[b.sourceAxis]()) {
    var d;
    return (d = {}, t(d, r, [(e.sx + e.tx) / 2, e.startStubY, (e.sx + e.tx) / 2, e.endStubY]), t(d, s, [e.startStubX, (e.sy + e.ty) / 2, e.endStubX, (e.sy + e.ty) / 2]), d)[b.sourceAxis];
  } else {
    return [e.startStubX, e.startStubY, e.endStubX, e.endStubY];
  }
});
var i = new Map();
i.set(e, function (t, e, u, n, r, s, b, S, d, o, i) {
  var X = {
      x: [[[1, 2, 3, 4], null, [2, 1, 4, 3]], null, [[4, 3, 2, 1], null, [3, 4, 1, 2]]],
      y: [[[3, 2, 1, 4], null, [2, 3, 4, 1]], null, [[4, 1, 2, 3], null, [1, 4, 3, 2]]]
    },
    Y = {
      x: [[e.startStubX, e.endStubX], null, [e.endStubX, e.startStubX]],
      y: [[e.startStubY, e.endStubY], null, [e.endStubY, e.startStubY]]
    },
    l = {
      x: [[d, e.startStubY], [d, e.endStubY]],
      y: [[e.startStubX, o], [e.endStubX, o]]
    },
    x = {
      x: [[e.endStubX, e.startStubY]],
      y: [[e.startStubX, e.endStubY]]
    },
    y = {
      x: [[e.startStubX, e.endStubY], [e.endStubX, e.endStubY]],
      y: [[e.endStubX, e.startStubY], [e.endStubX, e.endStubY]]
    },
    v = {
      x: [[e.startStubX, o], [e.endStubX, o], [e.endStubX, e.endStubY]],
      y: [[d, e.startStubY], [d, e.endStubY], [e.endStubX, e.endStubY]]
    },
    f = {
      x: [e.startStubY, e.endStubY],
      y: [e.startStubX, e.endStubX]
    },
    m = a[n][0],
    c = a[n][1],
    p = e.so[m] + 1,
    g = e.to[c] + 1,
    h = e.to[c] === -1 && f[n][1] < f[n][0] || e.to[c] === 1 && f[n][1] > f[n][0],
    M = Y[n][p][0],
    w = Y[n][p][1],
    A = X[n][p][g];
  if (e.segment === A[3] || e.segment === A[2] && h) {
    return l[n];
  } else if (e.segment === A[2] && w < M) {
    return x[n];
  } else if (e.segment === A[2] && w >= M || e.segment === A[1] && !h) {
    return v[n];
  } else if (e.segment === A[0] || e.segment === A[1] && h) {
    return y[n];
  }
});
i.set(u, function (e, u, n, b, S, a, d, o, i, X, Y) {
  var l, x;
  var y = (l = {}, t(l, r, u.so[0] === -1 ? Math.min(S, d) : Math.max(S, d)), t(l, s, u.so[1] === -1 ? Math.min(S, d) : Math.max(S, d)), l)[b];
  return (x = {}, t(x, r, [[y, a], [y, o], [d, o]]), t(x, s, [[a, y], [o, y], [o, d]]), x)[b];
});
i.set(n, function (e, u, n, a, d, o, i, X, Y, l, x) {
  var y;
  var v = (y = {}, t(y, r, s), t(y, s, r), y)[a],
    f = u["is" + a.toUpperCase() + "GreaterThanStubTimes2"];
  if (n.sourceEndpoint.elementId === n.targetEndpoint.elementId) {
    var m, c;
    var p = (m = {}, t(m, r, b), t(m, s, S), m)[a];
    var g = o + (1 - n.sourceEndpoint._anchor.computedPosition[v]) * n.sourceInfo[p] + e.maxStub;
    return (c = {}, t(c, r, [[d, g], [i, g]]), t(c, s, [[g, d], [g, i]]), c)[a];
  } else if (!f || u.so[x] === 1 && d > i || u.so[x] === -1 && d < i) {
    var h;
    return (h = {}, t(h, r, [[d, l], [i, l]]), t(h, s, [[Y, d], [Y, i]]), h)[a];
  } else if (u.so[x] === 1 && d < i || u.so[x] === -1 && d > i) {
    var M;
    return (M = {}, t(M, r, [[Y, u.sy], [Y, u.ty]]), t(M, s, [[u.sx, l], [u.tx, l]]), M)[a];
  }
});
export { i as lineCalculators, o as stubCalculators };