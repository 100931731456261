import { IArchimatePalette } from '@core/models/process-manager.models';
import { ObjectAnchorSpec } from '@jsplumbtoolkit/browser-ui';

export const JSPLUMB_ANCHOR_POSITIONS: (ObjectAnchorSpec & { id: string })[] = [
  { x: 0, y: 0.5, ox: -1, oy: 0, id: 'left' },
  { x: 1, y: 0.5, ox: 1, oy: 0, id: 'right' },
  { x: 0.5, y: 0, ox: 0, oy: -1, id: 'top' },
  { x: 0.5, y: 1, ox: 0, oy: 1, id: 'bottom' }
];

export const ARCHIMATE_PALETTE_ELEMENTS: IArchimatePalette[] = [
  {
    title: 'archimate.strategy-layer',
    type: 'Strategy',
    color: '#f5deaa',
    elements: [
      { icon: 'archimate-resource', type: 'Resource' },
      { icon: 'archimate-capability', type: 'Capability' },
      { icon: 'archimate-value_stream', type: 'ValueStream' },
      { icon: 'archimate-course_of_action', type: 'CourseOfAction' }
    ]
  },
  {
    title: 'archimate.business-layer',
    type: 'Business',
    color: '#ffffaf',
    elements: [
      { icon: 'archimate-business_actor', type: 'BusinessActor' },
      { icon: 'archimate-business_role', type: 'Role' },
      { icon: 'archimate-collaboration', type: 'Collaboration' },
      { icon: 'archimate-interface', type: 'Interface' },
      { icon: 'archimate-process', type: 'Process' },
      { icon: 'archimate-function', type: 'Function' },
      { icon: 'archimate-interaction', type: 'Interaction' },
      { icon: 'archimate-event', type: 'Event' },
      { icon: 'archimate-service', type: 'Service' },
      { icon: 'archimate-object', type: 'Object' },
      { icon: 'archimate-contract', type: 'Contract' },
      { icon: 'archimate-representation', type: 'Representation' },
      { icon: 'archimate-product', type: 'Product' }
    ]
  },
  {
    title: 'archimate.application-layer',
    type: 'Application',
    color: '#afffff',
    elements: [
      { icon: 'archimate-component', type: 'Component' },
      { icon: 'archimate-collaboration', type: 'Collaboration' },
      { icon: 'archimate-interface', type: 'Interface' },
      { icon: 'archimate-function', type: 'Function' },
      { icon: 'archimate-interaction', type: 'Interaction' },
      { icon: 'archimate-process', type: 'Process' },
      { icon: 'archimate-event', type: 'Event' },
      { icon: 'archimate-service', type: 'Service' },
      { icon: 'archimate-object', type: 'Object' }
    ]
  },
  {
    title: 'archimate.technology-layer',
    type: 'Technology',
    color: '#afffaf',
    elements: [
      { icon: 'archimate-node', type: 'Node' },
      { icon: 'archimate-device', type: 'Device' },
      { icon: 'archimate-system_software', type: 'SystemSoftware' },
      { icon: 'archimate-collaboration', type: 'Collaboration' },
      { icon: 'archimate-interface', type: 'Interface' },
      { icon: 'archimate-path', type: 'Path' },
      { icon: 'archimate-communication_network', type: 'CommunicationNetwork' },
      { icon: 'archimate-function', type: 'Function' },
      { icon: 'archimate-process', type: 'Process' },
      { icon: 'archimate-interaction', type: 'Interaction' },
      { icon: 'archimate-event', type: 'Event' },
      { icon: 'archimate-service', type: 'Service' },
      { icon: 'archimate-artifact', type: 'Artifact' }
    ]
  }, 
  {
    title: 'archimate.physical-layer',
    type: 'Physical',
    color: '#afffaf',
    elements: [
      { icon: 'archimate-equipment', type: 'Equipment' },
      { icon: 'archimate-facility', type: 'Facility' },
      { icon: 'archimate-distribution_network', type: 'DistributionNetwork' },
      { icon: 'archimate-material', type: 'Material' }
    ]
  },
  {
    title: 'archimate.motivation-layer',
    type: 'Motivation',
    color: '#ccccff',
    elements: [
      { icon: 'archimate-business_role', type: 'Stakeholder' },
      { icon: 'archimate-driver', type: 'Driver' },
      { icon: 'archimate-assessment', type: 'Assessment' },
      { icon: 'archimate-goal', type: 'Goal' },
      { icon: 'archimate-outcome', type: 'Outcome' },
      { icon: 'archimate-principle', type: 'Principle' },
      { icon: 'archimate-requirement', type: 'Requirement' },
      { icon: 'archimate-constraint', type: 'Constraint' },
      { icon: 'archimate-meaning', type: 'Meaning' },
      { icon: 'archimate-value', type: 'Value' }
    ]
  },
  {
    title: 'archimate.implementation-migration-layer',
    type: 'ImplementationMigration',
    color: '#ffe0e0',
    elements: [
      { icon: 'archimate-work_package', type: 'WorkPackage' },
      { icon: 'archimate-representation', type: 'Representation' },
      { icon: 'archimate-event', type: 'Event' },
      { icon: 'archimate-plateau', type: 'Plateau' },
      { icon: 'archimate-gap', type: 'Gap'}
    ]
  },
  {
    title: 'archimate.other-layer',
    type: 'Archimate',
    color: '#efd1e4',
    elements: [
      { icon: 'archimate-junction', type: 'Junction' }
    ]
  }
];

export const ARCHIMATE_SHARP_CORNER_ELEMENTS: string[] = [
  'Component',
  'Collaboration',
  'Interface',
  'Object',
  'Actor',
  'Role',
  'Contract',
  'Representation',
  'Product',
  'Node',
  'Device',
  'SystemSoftware',
  'Path',
  'CommunicationNetwork',
  'Resource',
  'Plateau',
  'Gap',
  'Artifact'
];

export const ARCHIMATE_ROUNDED_CORNER_ELEMENTS: string[] = [
  'Function',
  'Interaction',
  'Process',
  'Event',
  'Service',
  'Capability',
  'ValueStream',
  'CourseOfAction',
  'WorkPackage'
];

export const ARCHIMATE_RELATIONSHIP_OPTIONS = [
  { label: 'Composition', name: 'composed of' },
  { label: 'Aggregation', name: 'aggregates' },
  { label: 'Assignment', name: 'assigned to' },
  { label: 'Realization', name: 'realizes' },
  { label: 'Serving', name: 'serves' },
  { label: 'Access', name: 'accesses' },
  { label: 'Influence', name: 'influences' },
  { label: 'Association', name: 'associated to' },
  { label: 'Triggering', name: 'triggers' },
  { label: 'Flow', name: 'flows to' },
  { label: 'Specialization', name: 'specializes' }
];
