<naris-card class="card left">
  <h2 class="mb-4">{{'sign_in' | translate}}</h2>
  <naris-button 
    class="mcs-button" 
    color="black"
    [fullwidth]="true"
    (clicked)="onClickB2C()"
    id="LoginB2C"
  >
    <svg enable-background="new 0 0 2499.6 2500" viewBox="0 0 2499.6 2500" xmlns="http://www.w3.org/2000/svg" height="16" width="16">
      <path d="m1187.9 1187.9h-1187.9v-1187.9h1187.9z" fill="#f1511b"/>
      <path d="m2499.6 1187.9h-1188v-1187.9h1187.9v1187.9z" fill="#80cc28"/>
      <path d="m1187.9 2500h-1187.9v-1187.9h1187.9z" fill="#00adef"/>
      <path d="m2499.6 2500h-1188v-1187.9h1187.9v1187.9z" fill="#fbbc09"/>
    </svg>
    <span class="ml-2">Microsoft account</span>
  </naris-button>
  <naris-divider [label]="'or' | translate" />
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <naris-form-field [label]="'email_address' | translate" [fixStyling]="true">
      <naris-input [control]="getFormControl('email')" [placeholder]="'email_address' | translate" (enterPress)="onSubmit()" [id]="'Email'" />
    </naris-form-field>
    <naris-form-field [label]="'password' | translate" [fixStyling]="true">
      <naris-input type="password" [control]="getFormControl('password')" [placeholder]="'password' | translate" (enterPress)="onSubmit()" [id]="'Password'" />
    </naris-form-field>
    <naris-form-actions>
      <naris-button [disabled]="loginForm.invalid" [loading]="loading" color="primary" id="LoginSignIn">{{'sign_in' | translate}}</naris-button>
      <div class="mr-4"></div>
      <a 
        class="naris-link"
        [routerLink]="['/forgot']" 
      >{{'login.forgot_email_password' | translate}}</a>
    </naris-form-actions>
  </form>
</naris-card>

<!-- <naris-card class="card right text-center">
  <h2 class="mb-8">{{'login.no_account' | translate}}</h2>
  <p>{{'login.wanting_demo' | translate}}</p>
  <img src="../../../assets/img/demo_screen.png" alt="Demo" class="mb-8">
  <div><a [href]="langService.getHref('demo')" target="_blank">
    <naris-button>{{'login.ask_demo' | translate}}</naris-button>
  </a></div>
</naris-card> -->
