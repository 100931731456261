<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div
  class="item"
  [ngClass]="{
    open: item.opened,
    key: item.keyItem,
    active: item.active && !compareObject,
    clickable: prefix && !isGroup && !layouthint?.includes('no-details'),
    'edit-mode': editMode,
    'is-group': isGroup && !stringIncludes(item.kindOfChange, 'NoChange'),
    'item-disabled': !item.isEnabled || !stringIncludes(item.kindOfChange, 'Changed') && compareObject?.showCompareColors,
    'removed': stringIncludes(item.kindOfChange, 'Removed') && compareObject?.showCompareColors,
    'added': stringIncludes(item.kindOfChange, 'Added') && compareObject?.showCompareColors,
    'moved': stringIncludes(item.kindOfChange, 'Moved') && compareObject?.showCompareColors,
    'changed': stringIncludes(item.kindOfChange, 'Changed') && compareObject?.showCompareColors,
    'user-accepted': stringIncludes(item.kindOfChange, 'UserAccepted') && compareObject?.showCompareColors,
    'no-changes': compareObject?.showCompareColors,
    'not-key': !item.keyItem,
    'disabled': router.url.includes('archive') && !isArchived
  }"
  cdkDrag
  cdkDropList
  [cdkDropListData]="item.children"
  [id]="item.id.toString()"
  dragAndDropManager
  (cdkDropListDropped)="onDragDrop$.next($any($event))"
  (cdkDragStarted)="dragStart(item)"
  (cdkDragEnded)="dragged.emit(false)"
  [cdkDragDisabled]="dragDropDisabled"
  [matTooltip]="(router.url.includes('archive') && !isArchived ? 'not-archived' : '') | translate"
  #group
>
  <div class="drag-placeholder" *cdkDragPlaceholder></div>
  <div class="item-header active" (click)="!isAsset && itemClicked(item) || isAssetClassification && !item.children?.length && toggleCheckBox({checked: !item.checked}, item)" (contextmenu)="$event.preventDefault(); show($event, item)" (mouseenter)="mouseEnter($event.target)"  (mouseleave)="mouseLeave()">
    @if (isGroup && item.hasChildren && !childrenLoading && !isProcessStructure) {
      <naris-icon
        icon="keyboard_arrow_right"
        class="item-toggle mr-4"
        (click)="$event.stopPropagation(); toggleClicked()"
      />
    }
    @if (childrenLoading) {
      <naris-bouncer />
    }
    @if (!item.hasChildren && !isProcessStructure && !item.href?.includes('archimate')) {
      <div class="item-spacer mr-4"></div>
    }
    @if (isAssetClassification && !item.children?.length) {
      <naris-checkbox
        [checked]="item.checked || false"
        (click)="$event.stopPropagation()"
        (changed)="toggleCheckBox($event, item)"
        id="TreeviewGroupAssetClassification"
      />
    }
    @if (isProcessStructure) {
      <naris-icon class="process-structure-icon" [icon]="processIcon" iconClass="small" />
    }
    @if (hasInvolvedType) {
      <naris-icon 
        class="process-structure-icon"
        [icon]="item.involvedType?.toLowerCase() || ''"
        
        [matTooltip]="(item.involvedType?.toLowerCase() || '') | translate"
      />
    }
    @if (isGroup) {
      <div class="subtitle mr-2">{{ item.typeLabel | translate}}</div>
    }
    @if (!compareObject ||
    (!!compareObject && stringIncludes(item.kindOfChange, 'Removed') && !compareObject.isNewTree ||
      !!compareObject && stringIncludes(item.kindOfChange, 'Added') && compareObject.isNewTree ||
      !!compareObject && stringIncludes(item.kindOfChange, 'Moved') ||
      !!compareObject && (stringIncludes(item.kindOfChange, 'NoChange') || stringIncludes(item.kindOfChange, 'Changed')))) {
      <div
        [matTooltip]="item.contextName ? item.contextName :  ''"
        class="item-header-name"
      >{{ item.name }}</div>
    }
    @if (!!item.testType) {
      <sub class="item-header-test-type">&nbsp;- {{item.testType}}</sub>
    }
    @if (item.event) {
      <div class="subtitle ml-2 event">
        <naris-icon icon="link" [matTooltip]="getEventLinkText(item.event)" [inline]="true" class="subtitle-icon" />
      </div>
    }
    @if (!!item.description) {
      <naris-icon
        [matTooltip]="('table.description' | translate) + ':&#13;&#13;' + item.description"
        matTooltipPosition="above"
        icon="info-outline"
        class="ml-1 desc-icon"
      />
    }
    @if (!!item.available?.length) {
      <div class="item-icons ml-4">
        @for (object of item.available; track $index) {
          <naris-icon
            iconClass="primary"
            [icon]="object.toLowerCase()"
            [matTooltip]="getAvailableLabel(object) | translate"
            (click)="$event.stopPropagation(); clickAvailable(item, object);"
            class="item-icon"
          />
        }
      </div>
    }
    <div class="ml-auto"></div>
    @if ((!!popupData && showPopupData(item)) || (showExtraItems && showPopupData(item))) {
      <div class="popup-icon">
        <naris-icon
          [icon]="popupData.icon"
          iconClass="small"
          class="icon"
          [matMenuTriggerFor]="rowMenu"
          (click)="$event.stopPropagation()"
          [matTooltip]="popupData.label | translate"
        />
      </div>
    }
    @for (action of ungroupedActions; track $index) {
      <div class="action-icon">
        <naris-icon [icon]="action.name || 'help-outline'" [iconClass]="'small'" class="icon" (click)="$event.stopPropagation(); executeAction(action)" [matTooltip]="action.label!" />
      </div>
    }
    @if (!!processStructureAddActions.actions.length) {
      <div>
        <naris-button
          [icon]="'add'"
          color="transparent"
          size="small"
          [matMenuTriggerFor]="processActionsMenu"
          [@fadeInOutAnimation]
          id="TreeviewGroupActions"
        />
      </div>
    }
    <mat-menu #processActionsMenu="matMenu" xPosition="before">
      <div>
        @for (addAction of processStructureAddActions.actions; track $index) {
          <button mat-menu-item type="button" (click)="onClickAction(addAction, item)" class="process-add-button">
            <div class="action-label">
              {{addAction.label}}
            </div>
          </button>
        }
      </div>
    </mat-menu>
    @for (action of rowActions.actions; track $index) {
      <div>
        @if (action.name !=='move-object') {
          <naris-button
            [icon]="getIcon(action.name)"
            [matTooltip]="action.label || action.name || ''"
            matTooltipPosition="above"
            matTooltipShowDelay="200"
            color="transparent"
            size="small"
            (clicked)="$event.stopPropagation(); onClickAction(action, item, $event)"
            [@fadeInOutAnimation]
            [id]="'TreeviewGroup' + action.name"
          />
        }
      </div>
    }
    @if (isAsset && !!item.parentId) {
      <div>
        <naris-button
          [icon]="'go-to'"
          matTooltipShowDelay="200"
          color="transparent"
          size="small"
          (clicked)="itemClicked(item)"
          [@fadeInOutAnimation]
          id="TreeviewGroupGoTo"
        />
      </div>
    }
    @if (!!compareObject) {
      <div class="compare-icons">
        @if (stringIncludes(item.kindOfChange, 'Moved')) {
          <naris-icon
            [icon]="'moved'"
            class="compare-icons__moved"
            [matTooltip]="'tree-compare.moved' | translate"
          />
        }
        @if (stringIncludes(item.kindOfChange, 'Changed')) {
          <naris-icon
            [icon]="'update'"
            class="compare-icons__changed"
            [matTooltip]="'tree-compare.changed' | translate"
          />
        }
        @if (stringIncludes(item.kindOfChange, 'Removed')) {
          <naris-icon
            [icon]="'delete'"
            class="compare-icons__removed"
            [matTooltip]="'tree-compare.removed' | translate"
          />
        }
        @if (stringIncludes(item.kindOfChange, 'Added')) {
          <naris-icon
            [icon]="'add_outline'"
            class="compare-icons__added"
            [matTooltip]="'tree-compare.added' | translate"
          />
        }
      </div>
    }
    @if (showOccupied(item)) {
      <naris-icon
        class="viewers mr-2"
        icon="eye"
        iconSize="small"
        [class.self]="item.href === treeviewService.sentHref"
        [class.double]="hasMultipleViewers(item)"
      />
    }
    @if (!!item.color) {
      <div
        class="item-color mr-5"
        [style.backgroundColor]="item.color"
        [matTooltip]="item.color || ''"
        matTooltipPosition="left"
      ></div>
    }
    @if (!this.layouthint?.includes('hide-status') && !item.scoreResult) {
      <div
        class="status-indicator mr-5"
        [ngClass]="item.status!"
        [matTooltip]="item.statusLabel || ''"
        matTooltipPosition="left"
      ></div>
    }
    @if (!this.layouthint?.includes('hide-status') && item.scoreResult === '-' && !!item.scoreResult) {
      <naris-avatar
        class="avatar-status mr-5"
        [ngClass]="item.status?.toString() || ''"
        [matTooltip]="item.statusLabel || ''"
        matTooltipPosition="left"
        [label]="item.scoreId?.toString() || ''"
        (click)="$event.stopPropagation(); scoreItem(scoreAction)"
        [userColor]="item.scoreColor?.toString()"
        id="TreeviewGroupNoScore"
      />
    }
    @if (!this.layouthint?.includes('hide-status') && item.scoreResult !== '-' && !!item.scoreResult) {
      <naris-avatar
        class="avatar-status mr-5"
        [ngClass]="item.scoreStatus?.toString() || ''"
        [matTooltip]="item.scoreResult"
        matTooltipPosition="left"
        [label]="item.scoreId?.toString() || ''"
        (click)="$event.stopPropagation(); scoreItem(scoreAction)"
        [userColor]="item.scoreColor?.toString()"
        id="TreeviewGroupScore"
      />
    }
  </div>
  @if (item.hasChildren && item.opened) {
    <div class="item-body">
      @for (item of item.children; let i = $index; track i) {
        @if (draggedIndex === i) {
          <div class="item-origin"></div>
        }
        <naris-treeview-group
          [item]="item"
          [prefix]="prefix"
          [editMode]="editMode"
          (updated)="update()"
          (selected)="select($event)"
          [grouplessNodeButtons]="grouplessNodeButtons"
          [popupData]="popupData"
          [layouthint]="layouthint"
          [onlyRedirect]="onlyRedirect"
          [onDragDrop]="onDragDrop$"
          [dragDropDisabled]="dragDropDisabled"
          (dragged)="draggedIndex = $event ? i : null;"
          [compareObject]="compareObject"
          [enableLazyLoading]="enableLazyLoading"
          [roomName]="roomName"
          [occupiedNodes]="occupiedNodes"        
          [isAssetClassification]="isAssetClassification"
          (selectedUrl)="selectedUrl.emit($event)"
          (reloadParent$)="reloadParent()"
        />
      }
    </div>
  }
  
  <mat-menu #rowMenu="matMenu" xPosition="after" yPosition="above" class="naris-table-menu--row">
    @if (!!popupData && item[popupData.keyString]) {
      <div class="conclusie-content">
        {{item[popupData.keyString]}}
      </div>
    }
    @if (showExtraItems && !!popupData) {
      <div class="conclusie-content">
        @for (popupItem of popupData.data; track $index) {
          <div>
            &bull;&nbsp;{{popupItem[popupData.keyString]}}
          </div>
        }
      </div>
    }
    @if (showExtraItems && !!popupData) {
      <div class="conclusie-content">
        @for (popupItem of popupData.data; track $index) {
          <div>
            &bull;&nbsp;{{popupItem[popupData.keyString]}}
          </div>
        }
      </div>
    }
  </mat-menu>
</div>

<div class="context-menu__wrapper"
    [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y"
    [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
	<ng-template matMenuContent>
    <div class="context-menu">
      @if (!!copyAction) {
        <div class="naris-menu-item" (click)="copy()">{{'treeview.copy' | translate}}</div>
        <div class="naris-menu-item" [class.disabled]="!hasItemInBuffer" (click)="paste();">{{'treeview.paste' | translate}}</div>
        @if (!!extraActions?.length) {
          <hr>
        }
      }
      @for (extraAction of extraActions; track $index) {
        <div class="naris-menu-item" (click)="executeAction(extraAction);">{{extraAction.label}}</div>
      }
    </div>
	</ng-template>
</mat-menu>
