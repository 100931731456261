import { inherits as e, createSuper as t, classCallCheck as n, createClass as r } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { SelectionBase as i } from "./common.js";
import { Endpoints as o } from "../endpoint/endpoint.js";
var s = function (i) {
  e(u, i);
  var s = t(u);
  function u() {
    n(this, u);
    return s.apply(this, arguments);
  }
  r(u, [{
    key: "setEnabled",
    value: function e(t) {
      this.each(function (e) {
        return e.enabled = t;
      });
      return this;
    }
  }, {
    key: "setAnchor",
    value: function e(t) {
      this.each(function (e) {
        return o.setAnchor(e, t);
      });
      return this;
    }
  }, {
    key: "deleteEveryConnection",
    value: function e() {
      this.each(function (e) {
        return o.deleteEveryConnection(e);
      });
      return this;
    }
  }, {
    key: "deleteAll",
    value: function e() {
      var t = this;
      this.each(function (e) {
        return t.instance.deleteEndpoint(e);
      });
      this.clear();
      return this;
    }
  }]);
  return u;
}(i);
export { s as EndpointSelection };