import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EMAIL } from '@core/constants';
import { AuthService } from '@core/services';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CardComponent } from '../../../shared/components/card/card.component';
import { FormFieldComponent } from '../../../shared/components/form-field/form-field.component';
import { InputComponent } from '../../../shared/elements/input/input.component';
import { FormActionsComponent } from '../../../shared/components/form-actions/form-actions.component';
import { ButtonComponent } from '../../../shared/elements/button/button.component';

@Component({
  selector: 'app-pw-forgot',
  templateUrl: './pw-forgot.component.html',
  styleUrls: ['./pw-forgot.component.scss'],
  standalone: true,
  imports: [CardComponent, FormsModule, ReactiveFormsModule, FormFieldComponent, InputComponent, FormActionsComponent, RouterLink, ButtonComponent, TranslateModule]
})
export class PwForgotComponent implements OnInit {

  public forgotForm: FormGroup;

  constructor(private readonly authService: AuthService) {}

  public ngOnInit(): void {
    this.forgotForm = new FormGroup({
      [EMAIL]: new FormControl('', [Validators.required, Validators.email])
    });
  }

  public onSubmit() {
    this.authService.resetPWRequest(this.forgotForm.get(EMAIL)?.value);
  }

  public getFormControl(controlName: string) {
    return this.forgotForm.get(controlName) as FormControl;
  }
}
