@for (input of inputs; track input.id; let isFirst = $first) {
  <naris-input
    [id]="input.id"
    [type]="input.inputType"
    [control]="getFormControl(input.id)"
    [placeholder]="input.placeholder || input.label"
    [prepend]="!!input.operator ? (getPrepend(input.operator)! | translate) : ''"
    [ngStyle]="{borderTop: !isFirst && 'none'}"
    (wheel)="$event.preventDefault()"
    [elementId]="input.id"
  />
}
