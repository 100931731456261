import { Injectable, OnDestroy, Injector } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { HttpService } from '@core/services/http.service';
import { LanguageService } from '@core/services/language.service';
import type { TCaseListContributionsResponse, IKeyValuePair } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class ContributionsService extends HttpService implements OnDestroy {

  private contributionCollection: IKeyValuePair<TCaseListContributionsResponse>[] = [];

  private readonly subs: Subscription[] = [];

  constructor(injector: Injector, private readonly languageService: LanguageService) {
    super(injector);
    this.subs.push(
      this.languageService.languageUpdated.subscribe(() => this.contributionCollection = [])
    );
  }

  public getContributions(url: string): Observable<TCaseListContributionsResponse> {
    return new Observable(observer => {
      const foundContribution = this.contributionCollection.find(item => item.key === url);
      if (!!foundContribution && !foundContribution.value['error']) {
        observer.next(foundContribution.value);
        observer.complete();
      } else {
        this.get(url).subscribe(x => {
          this.contributionCollection.push({key: url, value: x});
          observer.next(x);
          observer.complete();
        });
      }
    });
  }

  public ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
