<div class="naris-notification-header">
  @if (!notification().NotificationRead) {
    <div class="naris-notification-indicator"></div>
  }
  <div class="naris-notification-title">
    {{ notification().NotificationName }}
  </div>
</div>
<div class="naris-notification-content">
  <div>
    <div class="naris-notification-notification">
      {{ notification().NotificationContent }}
    </div>
    <div class="naris-notification-timestamp">
      {{ formattedTimestamp }}
    </div>
  </div>
  <div class="naris-notification-actions">
    @if (!read) {
      <naris-button
        icon="mark-as-read"
        size="small"
        matTooltip="Mark as read"
        class="mr-2"
        (clicked)="$event.stopPropagation(); handleAction('mark-notification-as-read')"
        id="NotificationMarkAsRead"
      />
    } @else {
      <naris-button
        icon="mark-as-unread"
        size="small"
        matTooltip="Mark as unread"
        class="mr-2"
        (clicked)="$event.stopPropagation(); handleAction('mark-notification-as-not-read')"
        id="NotificationMarkAsNotRead"
      />
    }
    <naris-button
      icon="delete"
      size="small"
      matTooltip="Remove"
      (clicked)="$event.stopPropagation(); handleAction('delete')"
      id="NotificationDelete"
    />
  </div>
</div>
