/* eslint-disable @angular-eslint/use-injectable-provided-in */
import { CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpHeaders, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { Socket, SocketIoModule } from 'ngx-socket-io';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApolloClientOptions, InMemoryCache, NormalizedCacheObject } from '@apollo/client/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpBatchLink } from 'apollo-angular/http';
import { environment } from '@src/environments/environment';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { DragAndDropManagerService } from '@core/services/drag-and-drop-manager.service';
import { AUDIT_SOCKET_CONFIG, BASE_URL, SOCKET_CONFIG } from '@core/constants';
import { AppInterceptor } from '@core/interceptors/app-interceptor';
import { HelpComponent } from '@layouts/app-layout/help/help.component';
import { QuickSearchComponent } from '@layouts/app-layout/quick-search/quick-search.component';
import { AppLayoutComponent } from '@layouts/app-layout/app-layout.component';
import { AuthLayoutComponent } from '@layouts/auth-layout/auth-layout.component';
import { NotificationsComponent } from '@layouts/app-layout/notifications/notifications.component';
import { ChangelogsComponent } from '@layouts/app-layout/changelogs/changelogs.component';
import { AuthModule } from '@features/auth/auth.module';
import { DatafeedsComponent } from '@features/datafeeds/datafeeds/datafeeds.component';
import { NgxEditorModule } from 'ngx-editor';
import { StartComponent } from '@features/start/start.component';
import { TestComponent } from '@features/test/test.component';
import { GridsterComponent, GridsterItemComponent } from 'angular-gridster2';
import { TabByModuleComponent } from '@features/tab-by-module/tab-by-module.component';
import { jsPlumbToolkitModule } from '@jsplumbtoolkit/browser-ui-angular';
import { trustedResourceUrl, unwrapResourceUrl } from 'safevalues';

// required for AOT compilation
export const httpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http);
@Injectable()
export class CollabSocket extends Socket {
  constructor() {
    super(SOCKET_CONFIG);
  }
}

@Injectable()
export class AuditSocket extends Socket {
  constructor() {
    super(AUDIT_SOCKET_CONFIG);
  }
}

@NgModule({ declarations: [AppComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [ApolloModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    DragDropModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register(unwrapResourceUrl(trustedResourceUrl`ngsw-worker.js`) as string, {enabled: environment.production}),
    SocketIoModule,
    NgxEditorModule,
    GridsterComponent,
    GridsterItemComponent,
    jsPlumbToolkitModule, StartComponent,
    AppLayoutComponent,
    AuthLayoutComponent,
    NotificationsComponent,
    TestComponent,
    QuickSearchComponent,
    HelpComponent,
    DatafeedsComponent,
    ChangelogsComponent,
    TabByModuleComponent], providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    DragAndDropManagerService,
    CollabSocket,
    AuditSocket,
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpBatchLink) => ({
        cache: new InMemoryCache({ possibleTypes: { EventData: ['MongoEvent', 'SqlEvent', 'MongoControl', 'SqlControl'] } }),
        defaultOptions: { watchQuery: { fetchPolicy: 'cache-and-network' } },
        link: httpLink.create({
          uri: `${BASE_URL}/graphql`, headers: new HttpHeaders({
            'apollographql-client-name': `Angular-${environment.production ? 'Production' : 'Local'}`,
            'apollographql-client-version': environment.version,
            'Content-Type': 'application/json'
          })
        })
      } as ApolloClientOptions<NormalizedCacheObject>),
      deps: [HttpBatchLink]
    },
    provideHttpClient(withInterceptorsFromDi())
  ] })
export class AppModule { }
