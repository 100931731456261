<div [class.hidden]="loading && !isEmbeddedInModal" [class.loading-modal]="loading && isEmbeddedInModal">
  @if (!loading && !isEmbedded && !isProcessToolkitForm && !isRegisterForm) {
    <naris-toolbar color="blue-gradient" sticky="top">
      <naris-toolbar-item>
        @if (!!getActiveObject?.label) {
          <h4 class="title">{{ getActiveObject?.label }}</h4>
        } @else if (!getActiveObject?.label) {
          <h4 class="title">{{ title }}</h4>
        }
      </naris-toolbar-item>
      <naris-toolbar-item class="ml-auto">
        <naris-button (clicked)="close()" color="primary" icon="close" class="close-button" id="FormClose" />
      </naris-toolbar-item>
    </naris-toolbar>
  }

  @if (isRegisterForm && !!getActiveObject?.label) {
    <div class="register-title-wrapper">
      <h4 class="register-title">{{ getActiveObject?.label }}</h4>
    </div>
  }
  
  @if (isDetailObjectPage || isEmbeddedInModal) {
    <div class="naris-form-toggle-container">
      @if (showUpdateToggle) {
        <naris-slidetoggle
          [checked]="objectFormEnabled"
          [disabled]="hasChanges"
          (changed)="updateObjectForm($event, endpoint)"
          [id]="'FormEnabled'"
        >{{ 'object.update' | translate }}</naris-slidetoggle>
      }
      @if (isEmbeddedInModal && !onlyOptionalInputs && !onlyMandatoryInputs) {
        <naris-slidetoggle
          [checked]="isOptionalFieldsChecked"
          (changed)="updateOptionalFields($event)"
          [id]="'FormOptionalFields'"
        >{{ 'form.hide_optional' | translate }}</naris-slidetoggle>
      }
    </div>
  }
  
  @for (formGroup of getFormObjects; track $index) {
    <div
      [class.opened]="formGroup.objectid === activeObject"
      [class.max-modal-height]="isEmbeddedInModal"
      class="form-step"
    >
      <app-form-group
        [inputs]="formGroup.inputs.elements"
        [errors]="validationErrors"
        [formGroup]="getFormGroup(formGroup.objectid)"
        (autoSubmit)="onAutoSubmit()"
        [isEmbedded]="isEmbedded"
        [isAssessment]="isAssessment"
        [isConsequence]="isConsequence"
        [hidePreviousAssessment]="hidePreviousAssessment"
        [canEditApplicability]="canEditApplicability"
        [tabHref]="tabHref"
        [isStriped]="isStriped"
        [endpoint]="endpoint"
        [isInitialApplicability]="isInitialApplicability"
        [isProcessToolkitForm]="isProcessToolkitForm"
        [class.no-padding]="isProcessToolkitForm"
        [isContextChips]="isContextChips"
        (onlyOptionalInputs)="setOnlyOptionalInputs($event)"
        (onlyMandatoryInputs)="setOnlyMandatoryInputs($event)"
        [formId]="formGroup.objectid"
        [isArchimate]="isArchimate"
      />
    </div>
  }
  
  @if (errors) {
    <div class="form-errors">
      @for (error of errors; track $index) {
        <div class="form-error">
          {{ error.message }} ({{ error.id }})
        </div>
      }
    </div>
  }
  
  @if (!hideButtons && (!isFormGroupDisabled() || isEmbeddedInModal || !isDetailObjectPage)) {
    <naris-toolbar [ngClass]="{'toolbar-buttons-end': isCollabPush}">
      <naris-toolbar-item
        class="button-toolbar"
        [ngClass]="{'btn-bar': (getActiveObject?.inputs?.buttons?.length ?? 0) >= 3, 'align-right': isAddWidgetForm}"
      >
        @if (getActiveObject?.inputs?.buttons?.length) {
          @for (button of getActiveObject?.inputs?.buttons; track $index) {
            <naris-button
              [ngClass]="{'mr-2': (getActiveObject?.inputs?.buttons?.length ?? 3) <= 2}"
              color="primary"
              [icon]="button.icon"
              [loading]="loading"
              [disabled]="!isFormValid(activeObject) || isDetailObjectPage && objectFormEnabled"
              (clicked)="submitForm(button)"
              [id]="button.id"
            >
              {{ button.label | translate}}
            </naris-button>
          }
        }
        @if (!getActiveObject?.inputs?.buttons?.length) {
          <naris-button
            [color]="getActiveObject?.buttonLabels?.finish?.toLowerCase()?.includes('complete') ? 'success' : 'primary'"
            class="mr-2"
            [loading]="loading"
            [disabled]="!isFormValid(activeObject)"
            (clicked)="submitForm()"
            id="FormSave"
            [size]="isAddWidgetForm ? 'small' : 'medium'"
          >
            {{ getActiveObject?.buttonLabels?.finish || 'save' | translate}}
          </naris-button>
        }
        <!-- Onderstaande knop weer aan als er aanpassingen zijn voor #14847, #14926, #15004 -->
        <!-- <naris-button
          *ngIf="showClearButton && activeObjectInputs > 0 && !layouthint?.includes('hide-reset')"
          color="primary"
          [loading]="loading"
          (clicked)="resetForm(false)"
        >
          {{ 'reset' | translate }}
        </naris-button> -->
      </naris-toolbar-item>
      @if ((getActiveObject?.inputs?.buttons?.length ?? 3) <= 2 && !isCollabPush) {
        <naris-toolbar-item class="ml-auto">
          <ng-container *ngTemplateOutlet="secBtns" />
        </naris-toolbar-item>
      }
    </naris-toolbar>
  }
  @if ((getActiveObject?.inputs?.buttons?.length ?? 0) >= 3 && !isEmbedded) {
    <naris-toolbar class="btn-bar">
      <ng-container *ngTemplateOutlet="secBtns" />
    </naris-toolbar>
  }
</div>

<ng-template #secBtns>
  @if (previousObject && !endpoint.endsWith('/bulk-change-assignments') && !endpoint.endsWith('/change-default-assignment')) {
    <naris-button (click)="back()" id="FormBack">
      {{ getActiveObject?.buttonLabels?.back || 'back' | translate }}
    </naris-button>
  }
</ng-template>

@if (loading && !isEmbeddedInModal) {
  <naris-loader
    height="5rem"
    [timeout]="4"
    [embeddedInForm]="true"
    (cancel)="close()"
  />
}
