import { Inject, Injectable } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import {
  FormInput,
  HeadingBlock,
  HelperTextBlock,
  SelectInput,
  RadioInput,
  AutoCompleteInput,
  TextInput,
  DateInput,
  TimeInput,
  SlideToggleInput,
  CheckboxGroup,
  TableBlock,
  DiagramInput,
  ColorInput,
  FileInput,
  NotifyTextBlock,
  RangeInput,
  FrequencyTextBlock,
  WarningTextBlock,
  PeriodInput
} from '@core/classes';
import { REQUIRED, MAX_LENGTH, PATTERN, REQUIRED_TRUE, EMAIL, MIN, MAX, MIN_LENGTH, ACTION_CONFIG, INFO_DIALOG_TOKEN } from '@core/constants';
import { environment } from '@src/environments/environment';
import { COLOR_REG } from '@core/constants/core-constants';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { InfoDialogComponent } from '@shared/components/info-dialog/info-dialog.component';
import { toNumber } from '@core/helpers';
import type { IInputOption, INarisOption, INarisDrawer, TInputType, IFormAnchor, IFormButton, INarisStepperStep, IFormResult } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  // Storage for all active drawers
  public drawers: INarisDrawer[] = [];
  // Triggers when drawers are mutated; returns new drawer count
  public drawersChanged = new Subject<INarisDrawer[]>();
  public currentForm: IFormAnchor;
  public wizardSteps$ = new Subject<INarisStepperStep[]>();
  public updateObjectForm$ = new Subject<{ enabled: boolean; endpoint?: string }>();
  public updateFormTitle$ = new Subject<string>();
  public resetControlError$ = new Subject<string | undefined>();
  public closeColorPicker$ = new Subject<void>();
  public refreshAuditExeStructureSteps$ = new Subject<string[]>();
  public isRedirect = false;

  private readonly fixedWidthEndpoints = ['structure'];

  constructor(
    private readonly dialog: MatDialog,
    @Inject(INFO_DIALOG_TOKEN) private readonly infoDialogComponent: ComponentType<InfoDialogComponent>
  ) {}


  /**
   * Returns drawer by id
   * @param id drawer id
   */
  public getDrawer(id: string) {
    return this.drawers.find((d: any) => d.id === id);
  }

  /**
   * Opens a drawer for given endpoint and returns its saved subject
   * @param endpoint BeInformed API endpoint
   */
  public open(endpoint: string, type = 'form', isWizardClosing = false, isArchimate = false): Observable<any> {
    return new Observable(observer => {
      this.dialog.open(this.infoDialogComponent, {
        minWidth: '54rem',
        width: this.fixedWidthEndpoints.some(fwe => endpoint.includes(fwe))  ? '60vw' : '',
        maxHeight: this.fixedWidthEndpoints.some(fwe => endpoint.includes(fwe)) ? '70vh' : '',
        data: {endpoint, type, isWizardClosing, isArchimate}
      }).afterClosed().subscribe(res => {
        observer.next(res);
        observer.complete();
      });
    });
  }

  /**
   * Save drawer by emitting saved listeners and closing the drawer
   * @param id drawer id
   */
  public saveForm(id: string, response: any, button?: IFormButton) {
    // Find the drawer by drawer id
    const subject = this.drawers.find((d: any) => d.id === id)?.saved;

    // Tell our subscribers we were saved, and pass the result
    subject?.next(response);

    // Complete the subject to remove subscriptions
    if (button?.id !== 'ADD_NEW') subject?.complete();
  }

  /**
   * Map beinformed attributes to naris form inputs
   * @param els array of elements
   */
  public mapElementsToInputs(els: any[], isFilter = false) {
    const buttons: IFormButton[] = [];
    let hasPeriod = false;
    const elements = els.map(element => {
      // Set the id of the element to match elementId
      element.id = isFilter ? element.name : element.elementId;
      // Get value from either element value, suggestion or suggestions
      let periodValue: Record<string, any> | null = null;           // In case of a period, we get an array containing 2 objects. One with startdate,
      element.elements?.forEach((e: Record<string, any>) => {  // the other with enddate. We need just one object with start/enddate so it needs to be converted
        if (periodValue === null) periodValue = {};
        periodValue[e['elementId'] || e['elementid']] = e['suggestion'];
      });
      element.value = element.suggestion ?? element.suggestions ?? element.value ?? element.values ?? periodValue;
      // Set element to disabled if it's a readonly attribute
      element.disabled = !!element.readonly;
      // Set element multiple
      element.multiple = element.type === 'binary' ? !!element.multiple : !!element.multiplechoice;
      // Set element to hidden
      element.hidden = !element.isMissing || element.layouthint?.includes('create-action') || element.layouthint?.includes('get-dependency-column') || element.id.toLowerCase().endsWith('_symbol');

      // Add validators
      element.validators = Object.entries(element).reduce<Record<string, any>>((acc, [ key, value ]) => {
        if (key === 'mandatory') acc[REQUIRED] = value;
        else if (key === MAX_LENGTH) acc[MAX_LENGTH] = value;
        else if (key === 'regexp') acc[PATTERN] = value;
        return acc;
      }, {});
      if (element.layouthint?.includes('mandatory')) element.validators[REQUIRED] = true;

      // Transform static options to naris options
      let staticOptions = element.options?.map((option: any) => ({key: option.code, value: option.label} as IInputOption));
      // Transform dynamic options to naris options
      const filterFields = this.getFilterDependencyInput(element.layouthint);
      let dynamicOptions = element.dynamicschema?.map((el: any) => ({
        label: el.elements ? this.mapElements(el.elements, filterFields) : el.label,
        value: el.code,
        elements: el.elements
      } as INarisOption));

      // Get lookup info if its present
      const lookup = element._links?.lookupOptions ? {
        href: element._links.lookupOptions.href,
        filter: element._links.lookupOptions.filter,
        list: element._links.lookupList?.href
      } : null;

      // Exceptions for filter inputs
      if (isFilter) {
        const filterOptions = element.options?.map((option: any) => {
          const opt = element.dynamics?.find((o: any) => o.code === option.key);
          const optionMeta = opt?.elements || opt?.label;
          const label = !!optionMeta && optionMeta instanceof Object ? Object.values(optionMeta).join(' | ') : optionMeta || option?.label || '';
          const value = option.key;
          return { label, value, key: value, count: option.count } as IInputOption;
        });
        staticOptions = dynamicOptions = filterOptions;
      }

      // #region Non-input elements
      if (element.layouthint?.includes('redirect-next')) {
        element.options.forEach((opt: IInputOption) => {
          const optCode = opt.code as keyof Pick<typeof ACTION_CONFIG, 'ADD_NEW' | 'CANCEL' | 'CONTINUE' | 'DEFAULT' | 'DELETE_SOME' | 'DELETE' | 'OPEN' | 'STOP'>;
          buttons.push({
            id: optCode,
            label: ACTION_CONFIG[optCode].label,
            icon: ACTION_CONFIG[optCode].icon
          });
        });
        return null;
      }

      if (element.layouthint?.includes('table') && element.readonly) {
        const meta: Record<string, any> = {};
        const columns = element.children.map((col: { any: any }) => {
          const objName = Object.keys(col)[0];
          const obj = Object.values(col)[0];
          meta[objName] = obj;
          return objName;
        });
        const records = dynamicOptions;
        const tableData = { meta, columns, records };
        return new TableBlock({ ...element, tableData });
      }

      if (element.readonly && element.layouthint?.includes('label'))
        return new HeadingBlock(element);

      if (element.readonly && element.layouthint?.includes('attribute_notify') && !element.text) {
        element.value = JSON.parse(element.value);
        return new NotifyTextBlock(element);
      }

      if (element.readonly && element.layouthint?.includes('warning')) {
        return new WarningTextBlock(element);
      }

      if (element.readonly && !!element.text)
        return new HelperTextBlock(element);
      // #endregion

      // #region Form inputs

      if (element.layouthint?.includes('period')) {
        if (hasPeriod) return null;
        const periodElements = els.filter(item => item.layouthint?.includes('period'));
        hasPeriod = true;
        return new PeriodInput(element, periodElements);
      }

      // Number inputs
      if (['number', 'numberfilter'].includes(element.type)) {
        const foundSymbolElement = els.find(el => el.elementId?.toLowerCase().endsWith(`${element.id?.toLowerCase()}_symbol`) && el.isMissing) || {};
        let prepend = null;
        if (!!foundSymbolElement?.dynamicschema?.length) 
          prepend = foundSymbolElement?.dynamicschema[0].label;
        return new TextInput({ ...element, inputType: 'number', prepend });
      }

      // File upload inputs
      if (['binary'].includes(element.type))
        return new FileInput({ ...element, inputType: 'number' });

      // Datepicker inputs
      if (['range', 'datetime', 'date', 'datefilter', 'timestamp'].includes(element.type))
        return new DateInput({ ...element, inputType: element.type });

      // Timepicker inputs
      if (['time'].includes(element.type))                              // For now an array with a single element,
        return new TimeInput({ ...element, inputType: element.type });  // not sure if BI has other ways to describe a timepicker field

      if (['numberrangefilter', 'daterangefilter'].includes(element.type))
        return new RangeInput({...element, inputType: element.type.includes('date') ? 'date' : 'number'});

      // BPMN editor inputs
      if (element.layouthint?.includes('transform-to-diagram'))
        return new DiagramInput({ ...element, options: staticOptions });

      if (element.layouthint?.includes('frequency')) {
        return new FrequencyTextBlock({ ...element });
      }

      if (element.layouthint?.includes('frequency-json')) {
        let frequencyObject = {};
        try {
          frequencyObject = JSON.parse(element.value);
        } catch {
          // eslint-disable-next-line no-console
          !environment.production && console.error('Parse error, invalid jSON');
          return null;
        }

        if (!element.dynamicschema) {
          element.dynamicschema = [];
        }
        element.dynamicschema.push({elements: frequencyObject});
        return new FrequencyTextBlock({ ...element });
      }

      // Slidetoggle inputs
      if (element.type === 'boolean')
        return new SlideToggleInput(element);

      // Radio inputs
      if (element.type === 'string' && element.layouthint?.includes('radiobutton')) {
        const correctDynamicOptions = dynamicOptions?.map((option: any) => ({key: option.label, value: option.value} as IInputOption));
        const correctElementOptions = element.options?.map((option: any) => ({key: option.label, value: option.code} as IInputOption));
        const radioOptions = correctDynamicOptions || correctElementOptions;
        return new RadioInput({ ...element, options: radioOptions});
      }

      // Checkbox group inputs
      if (
        element.type === 'array' && element.multiplechoice && element.optionMode === 'static'
        || isFilter && element.multiple && element.optionMode === 'static' 
        || element.layouthint?.includes('dynamic-as-static')
      ) {
        const mappedOptions = element.layouthint?.includes('dynamic-as-static') ? dynamicOptions.map((opt: { value: string; label: string }) => ({key: opt.value, value: opt.label})) : staticOptions;
        return new CheckboxGroup({ ...element, options: mappedOptions });
      }

      // Select inputs
      if (['static', 'dynamic'].includes(element.optionMode) || element.optionMode === 'dynamicWithThreshold' && !lookup) {
        return new SelectInput({
          ...element,
          options: element.optionMode === 'static' ? staticOptions : dynamicOptions
        });
      }

      // Autocomplete inputs
      // TODO: add support for multi select autocomplete + dynamicWithThreshold
      if (['lookup'].includes(element.optionMode) || lookup) {
        element.value = toNumber(element.value);
        const { value, suggestion, label } = els.find(el => el.elementId?.startsWith(`${element.id}CreateAction`) && el.isMissing) || {};
        const createEndpoint = value || suggestion || label;
        const shouldLookupDependencyColumn = element.layouthint?.some((hint: string) => hint.includes('dependency-column:'));
        const dependencyColumnUrl = shouldLookupDependencyColumn ? els.find(el => el.layouthint?.includes('get-dependency-column'))?.label : null;
        return new AutoCompleteInput({ ...element, lookup, dynamicOptions, createEndpoint, dependencyColumnEndpoint: dependencyColumnUrl});
      }

      // Text inputs
      if (element.type === 'string' && element.layouthint?.includes('color'))
        return new ColorInput(element);

      if (element.type === 'string' || element.type === 'stringfilter') {
        if (element.value === null) element.value = '';
        return new TextInput({
          ...element,
          inputType: element.rows ? 'textarea' : element.layouthint?.includes('email') ? 'email' : 'text',
          rows: element.rows,
          filterSuffix: element.type === 'stringfilter' ? 'enter' : null
        });
      }
      // #endregion

      // If we don't have a valid Naris input, return null and log
      // eslint-disable-next-line no-console
      if (!environment.production) console.log('Unable to map BeInformed attribute element to Naris input', element);
      return null;
    })
    // Remove all null items from the array
      .filter(el => !!el) as TInputType[];
    return { elements, buttons };
  }

  /**
   * Method that generates an ng reactive forms control or group, based on input type
   */
  public toFormField(input: FormInput) {
    // Generate validators
    const inputValidation = this.getValidators(input);
    // If no exception, return a new FormControl
    return new FormControl({
      value: !!input.text && (input instanceof HelperTextBlock || input instanceof WarningTextBlock || input instanceof TextInput) ? input.text.message : input.value,
      disabled: input.disabled
    }, inputValidation);
  }

  /**
   * Method that transforms input validators into ng reactive forms validators
   */
  public getValidators(input: FormInput) {
    const validators: ValidatorFn[] = [Validators.nullValidator];
    for (const rule in input.validators) {
      if (rule === REQUIRED && input.validators[rule]) validators.push(Validators.required);
      else if (rule === REQUIRED_TRUE) validators.push(Validators.requiredTrue);
      else if (rule === EMAIL) validators.push(Validators.email);
      else if (rule === MIN) validators.push(Validators.min(input.validators[rule]!));
      else if (rule === MAX) validators.push(Validators.max(input.validators[rule]!));
      else if (rule === MIN_LENGTH) validators.push(Validators.minLength(input.validators[rule]!));
      else if (rule === MAX_LENGTH) validators.push(Validators.maxLength(input.validators[rule]!));
      else if (rule === PATTERN) validators.push(Validators.pattern(input.validators[rule]!));
    }
    return Validators.compose(validators);
  }

  private mapElements(elements: Record<string, any>, excludedFields?: string[]) {
    return Object.entries(elements)
      .filter(([key, value]) => !key.toLowerCase().includes('description') && !COLOR_REG.test(value?.toString()) && !excludedFields?.includes(key))
      .map(e => e[1]).join(' | ');
  }

  private getFilterDependencyInput(layouthint: string[]): string[] | undefined {
    const foundLayoutHint = layouthint?.find(hint => hint.startsWith('dependent: filter') || hint.startsWith('dependent:filter'));
    if (!!foundLayoutHint) {
      const layouthintFieldName = foundLayoutHint.split('filter')?.[1]?.split('by')?.[0];
      return layouthintFieldName ? [layouthintFieldName.trim()] : undefined;
    }
    return undefined;
  }

  public setWizardSteps(result: IFormResult) {
    const steps: INarisStepperStep[] = [];
    const objectid = result.data.error.formresponse.missing?.anchors[0].objectid;
    const stepIds = result.data.error.formresponse.missing?.anchors[0].elements.find(el => el.elementid === 'WizardSteps')?.values;
    if (!!stepIds && !!objectid) {
      const attribute = result.contributions.objects[objectid].attributes.find(attr => Object.keys(attr)[0] === 'WizardSteps');
      const allSteps = attribute?.['WizardSteps'].options;
      stepIds.forEach(stepId => {
        const step = allSteps?.find(_step => _step.code === stepId) as unknown as INarisStepperStep;
        steps.push(step);
      });
    }
    this.wizardSteps$.next(steps);
  }
}
