import { inherits as e, createSuper as t, classCallCheck as n, defineProperty as s, assertThisInitialized as o, createClass as r } from "../_virtual/_rollupPluginBabelHelpers.js";
import { _makeAndAppendHandle as i, EDGE_DRAG_HANDLE as a, EditorBase as l } from "../connector-editors/editor-base.js";
import { _setOrthogonalSegmentPosition as g, _trimOrthogonalConnector as m } from "../ui-core/connector-orthogonal/operations.js";
import { orthogonalSegmentLength as h } from "../ui-core/connector-orthogonal/utils.js";
import { AXIS_ORIENTATION_VERTICAL as u } from "../ui-core/connector-orthogonal/definitions.js";
var y = "jtk-orthogonal-handle";
var d = "jtk-orthogonal-segment-drag";
var c = "jtk-orthogonal-segment-drag-ns";
var f = "jtk-orthogonal-segment-drag-ew";
var v = function (l) {
  e(p, l);
  var v = t(p);
  function p(e, t) {
    var r;
    n(this, p);
    r = v.call(this, e, t);
    s(o(r), "segments", void 0);
    s(o(r), "segmentHandles", void 0);
    s(o(r), "geometry", void 0);
    r.segments = [];
    r.segmentHandles = [];
    r._addDragHandler({
      selector: "." + d,
      drag: function e(t) {
        var n = t.drag.getDragElement()._jsPlumbDragHandle;
        var s = r.jsplumb.getSize(n.el);
        var o = [Math.floor(t.pos.x + s.w / 2), Math.floor(t.pos.y + s.h / 2)];
        var i = g(r.currentConnector, n.geometry, o, r.sourceInfo, r.targetInfo);
        if (i != null) {
          r.segments.length = 0;
          r.geometry.segments = i.segments;
          Array.prototype.push.apply(r.segments, r.geometry.segments);
          n.geometry = r.geometry.segments[i.index];
          r.repaintConnection({
            segmentInfo: n,
            segmentIndex: i.index
          });
        }
      },
      constrainFunction: function e(t, n, s, o) {
        var r = n._jsPlumbDragHandle;
        var i = r.geometry.ax1 === r.geometry.ax2;
        if (i) {
          return {
            x: t.x,
            y: (r.geometry.ay1 + r.geometry.ay2) / 2 - o.h / 2
          };
        } else {
          return {
            x: (r.geometry.ax1 + r.geometry.ax2) / 2 - o.w / 2,
            y: t.y
          };
        }
      },
      stop: function e(t) {
        r._trimConnection();
        r.fireConnectionEditEvent();
      }
    });
    return r;
  }
  r(p, [{
    key: "_setHandlePosition",
    value: function e(t, n) {
      t.el.style.visibility = "visible";
      this._setElementPosition(t.el, n[0], n[1]);
    }
  }, {
    key: "_repaint",
    value: function e(t) {
      this._update(t);
      for (var n = 0; n < this.segmentHandles.length; n++) {
        this.segmentHandles[n].geometry = this.geometry.segments[n + 1];
        if (h(this.segmentHandles[n].geometry) > 0) {
          var s = [(this.segmentHandles[n].geometry.ax1 + this.segmentHandles[n].geometry.ax2) / 2, (this.segmentHandles[n].geometry.ay1 + this.segmentHandles[n].geometry.ay2) / 2];
          this._setHandlePosition(this.segmentHandles[n], s);
        } else {
          this.segmentHandles[n].el.style.visibility = "hidden";
        }
      }
    }
  }, {
    key: "_clearHandles",
    value: function e(t) {
      for (var n = 0; n < this.segmentHandles.length; n++) {
        if (this.segmentHandles[n].el !== t) {
          this.jsplumb._removeElement(this.segmentHandles[n].el);
        }
      }
    }
  }, {
    key: "_activate",
    value: function e(t, n) {
      this._update();
    }
  }, {
    key: "_elementDragged",
    value: function e(t) {
      this._trimConnection();
    }
  }, {
    key: "_elementDragging",
    value: function e(t) {
      this._repaint();
    }
  }, {
    key: "_update",
    value: function e(t) {
      t = t || {};
      var n = t.segmentInfo,
        s = t.segmentIndex;
      this.geometry = this.current.connector.geometry;
      if (this.geometry && this.geometry.segments) {
        this._clearHandles(n ? n.el : null);
        this.segmentHandles.length = 0;
        this.segments.length = 0;
        Array.prototype.push.apply(this.segments, this.geometry.segments);
        for (var o = 1; o < this.segments.length - 1; o++) {
          if (n == null || o !== s) {
            var r = [(this.segments[o].ax1 + this.segments[o].ax2) / 2, (this.segments[o].ay1 + this.segments[o].ay2) / 2],
              l = r[0],
              g = r[1],
              m = this.segments[o].o === u,
              h = i(l, g, this.jsplumb, [a, y, d, m ? f : c].join(" "), true);
            var v = {
              left: l,
              top: g,
              el: h,
              geometry: this.segments[o],
              vertical: m
            };
            h._jsPlumbDragHandle = v;
            this.segmentHandles.push(v);
            this._setHandlePosition(v, r);
          } else if (o === s) {
            this.segmentHandles.push(n);
          }
        }
      }
    }
  }, {
    key: "_trimConnection",
    value: function e() {
      if (this.current) {
        m(this.currentConnector);
        this.repaintConnection();
      }
    }
  }]);
  return p;
}(l);
s(v, "type", "Orthogonal");
export { y as FLOWCHART_HANDLE_CLASS, v as OrthogonalEditor, d as SEGMENT_DRAG_HANDLE, f as SEGMENT_DRAG_HANDLE_HORIZONTAL_CLASS, c as SEGMENT_DRAG_HANDLE_VERTICAL_CLASS };