/**

 This module contains the mappings for the various edge types.

 */

import {
  PROPERTY_LINE_STYLE
} from '@core/constants/jsplumb-base-constants';
import { ArrowOverlay } from '@jsplumbtoolkit/browser-ui';

export default function edgeMappings() {
  return [
    {
      property: PROPERTY_LINE_STYLE,
      mappings: {
        ['ksf-connection']: {
          cssClass: 'ksf-connection',
          paintStyle: {
            stroke: '#00b4ff33'
          }
        },
        ['goal-connection']: {
          overlays: [{
            type: ArrowOverlay.type,
            options: {
              location: 1,
              width: 10,
              length: 10
            }
          }],
          paintStyle: {
            stroke: '#57d998'
          }
        }
      }
    }
  ];
}
