import { Plugin, PluginKey } from 'prosemirror-state';
import { DOC_TRIGGER } from '@core/constants';
import type { Subscription } from 'rxjs';
import type { EditorView } from 'prosemirror-view';
import type { NodeSpec, ResolvedPos } from 'prosemirror-model';
// eslint-disable-next-line @typescript-eslint/naming-convention
import type OrderedMap from 'orderedmap';
import type { IDocOption, IDocPluginOpts, IPluginState } from '@core/models';

const docNode: NodeSpec = {
  group: 'inline',
  inline: true,
  atom: true,
  attrs: {name: {}, revision: {}, hash: {}},
  selectable: false,
  draggable: false,
  toDOM: node => [
    'span',
    {'class': 'doc-link', 'data-doclink-revision': node.attrs.revision, 'data-doclink-hash': node.attrs.hash},
    `${DOC_TRIGGER}${node.attrs.name}`
  ],
  parseDOM: [{
    tag: 'span[data-doclink-revision][data-doclink-hash]',
    getAttrs: dom => ({
      revision: dom.getAttribute('data-doclink-revision'),
      hash: dom.getAttribute('data-doclink-hash'),
      name: dom.innerText.slice(1)
    })
  }]
};

const getRegexp = (docTrigger: string, allowSpace: boolean) => allowSpace
  ? new RegExp(`(^|\\s)${docTrigger}([\\w-\\+]*\\s?[\\w-\\+]*)$`)
  : new RegExp(`(^|\\s)${docTrigger}([\\w-\\+]*)$`)
;

const getMatch = ($position: ResolvedPos, { docTrigger, allowSpace }: IDocPluginOpts) => {
  const parastart = $position.before();
  const text = $position.doc.textBetween(parastart, $position.pos, '\n', '\0');
  const regex = getRegexp(docTrigger, allowSpace);
  const match = text.match(regex);
  if (!match) return;
  match.index = match[0].startsWith(' ') ? match.index! + 1 : match.index;
  match[0] = match[0].startsWith(' ') ? match[0].substring(1, match[0].length) : match[0];
  const from = $position.start() + match.index!;
  const to = from + match[0].length;
  const queryText = match[2];
  return {range: {from, to}, queryText};
};

const getNewState = (): IPluginState => ({range: {from: 0, to: 0}, text: '', active: false});

const select = (view: EditorView, state: IPluginState, item: IDocOption) => {
  const node = view.state.schema.nodes['docNode'].create(item);
  const tr = view.state.tr.replaceWith(state.range.from, state.range.to, node);
  view.dispatch(tr);
  view.focus();
};

export const getDocLinksPlugin = (options: Partial<IDocPluginOpts>) => {
  const defaultOpts: IDocPluginOpts = {
    docTrigger: DOC_TRIGGER,
    allowSpace: true,
    getSuggestions: () => void 0,
    hidePanel: () => void 0,
    selected$: null,
    // eslint-disable-next-line no-console
    openDoclink: console.log
  };
  const opts = {...defaultOpts, ...options};
  let sub: Subscription | null = null;
  return new Plugin({
    key: new PluginKey('doclinks'),
    state: {
      init: getNewState,
      apply: tr => {
        const newState = getNewState();
        const selection = tr.selection;
        if (selection.from !== selection.to) return newState;
        const $position = selection.$from;
        const match = getMatch($position, opts);
        return match ? {range: match.range, text: match.queryText, active: true} : newState;
      }
    },
    props: {
      handleClickOn: (_, __, { type, attrs }, _nodePos, evt) => {
        if (type.name !== 'docNode' || !('revision' in attrs || 'hash' in attrs)) return;
        evt.preventDefault();
        evt.stopPropagation();
        opts.openDoclink(attrs.hash, attrs.revision);
      }
    },
    view(editorView: EditorView) {
      if (!!opts.selected$ && !sub) sub = opts.selected$.subscribe(doc => {
        const state = this.key?.getState(editorView.state);
        select(editorView, state, doc);
      });
      return {
        update: view => {
          const state = this.key?.getState(view.state);
          if (!state.active) return opts.hidePanel();
          opts.getSuggestions(state.text);
        },
        destroy: () => {
          sub?.unsubscribe();
          sub &&= null;
        }
      };
    }
  });
};

export const addDocNodes = (nodes: OrderedMap<NodeSpec>) => nodes.append({docNode});
