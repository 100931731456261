import { classCallCheck as r, defineProperty as t } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { createAbstractBezierConnector as o } from "./abstract-bezier-connector.js";
import { Connectors as e } from "../core/connector/connectors.js";
import { computeBezierConnector as i, importBezierGeometry as n, exportBezierGeometry as c, transformBezierGeometry as s } from "./operations.js";
var a = "StateMachine";
function m(r, t) {
  var e = o(a, r, t);
  var i = Object.assign(e, {
    curviness: t.curviness || 10,
    margin: t.margin || 5,
    proximityLimit: t.proximityLimit || 80,
    clockwise: t.orientation && t.orientation === "clockwise",
    _controlPoint: {
      x: 0,
      y: 0
    }
  });
  return i;
}
e.register(a, {
  compute: i,
  create: m,
  setAnchorOrientation: function r(t, o, e) {},
  geometryImporter: n,
  geometryExporter: c,
  transformGeometry: s
});
var p = function t() {
  r(this, t);
};
t(p, "type", a);
var l = function t() {
  r(this, t);
};
t(l, "type", a);
export { a as CONNECTOR_TYPE_QUADRATIC_BEZIER, l as QuadraticBezierConnector, p as StateMachineConnector, m as _createQuadraticBezierConnector };