<div class="left" [ngClass]="{'mr-4': numOfPages! === 1}">
  <div
    class="icon"
    [ngClass]="{active: refreshActive}"
    matTooltip="Refresh"
    matTooltipPosition="above"
  >
    <naris-button
      icon="refresh"
      [color]="refreshActive ? 'primary' : 'white'"
      (clicked)="!refreshActive ? click('refresh') : null"
      id="PaginatorRefresh"
    />
  </div>
  @if (!!numOfItems && !!total) {
    <div class="info">
      {{'paginator.showing_num_of_total' | translate:showingParams}}
    </div>
  }
</div>
@if (numOfPages! > 1) {
  <div class="mid">
    <naris-button
      icon="first_page"
      (click)="toFirstPage.emit()"
      color="white"
      [ngClass]="{hidden: currentPage === 1 || numOfPages! <= 2}"
      matTooltip="First page"
      matTooltipPosition="above"
      id="PaginatorFirstPage"
    />
    <naris-button
      icon="keyboard_arrow_left"
      (click)="toPreviousPage.emit()"
      color="white"
      [ngClass]="{hidden: currentPage === 1}"
      matTooltip="Previous page"
      matTooltipPosition="above"
      id="PaginatorPreviousPage"
    />
    @if (currentPage! > 10) {
      <naris-button
        icon="in-process"
        (click)="toPreviousSlice.emit()"
        color="white"
        matTooltip="Previous 10 pages"
        matTooltipPosition="above"
        id="PaginatorPrevious10Pages"
      />
    }
    <div class="page-list">
      @for (page of (numOfPages! | toArray); let i = $index; track i) {
        @if (!!currentPageSlice?.includes(i + 1)) {
          <button
            class="naris-button page-item"
            [class.active]="currentPage === (i + 1)"
            (click)="toPage.emit(i + 1)"
            type="button"
          >
            {{ i + 1 }}
          </button>
        }
      }
    </div>
    @if (numOfPages! > 10 && !isLastSlice) {
      <naris-button
        icon="in-process"
        (click)="toNextSlice.emit()"
        color="white"
        matTooltip="Next 10 pages"
        matTooltipPosition="above"
        id="PaginatorNext10Pages"
      />
    }
    <naris-button
      icon="keyboard_arrow_right"
      (click)="toNextPage.emit()"
      color="white"
      [ngClass]="{hidden: currentPage === numOfPages}"
      matTooltip="Next page"
      matTooltipPosition="above"
      id="PaginatorNextPage"
    />
    <naris-button
      icon="last_page"
      (click)="toLastPage.emit()"
      color="white"
      [ngClass]="{hidden: currentPage === numOfPages || numOfPages! <= 2}"
      matTooltip="Last page"
      matTooltipPosition="above"
      id="PaginatorLastPage"
    />
  </div>
}

<div class="right" [ngClass]="{'ml-4': numOfPages! === 1}">
  @if (!!pagesizeOptions && total! > pagesizeOptions[0].value) {
    <span class="info">{{'paginator.showing' | translate}} <span class="pagesize-select" [matMenuTriggerFor]="pagesizeMenu">{{ pagesize }} {{'paginator.items' | translate}}</span> {{'paginator.per_page' | translate}}</span>
    <mat-menu #pagesizeMenu="matMenu" xPosition="after">
      <naris-menu>
        @for (option of pagesizeOptions; track $index) {
          <naris-menu-item
            [active]="option.value === pagesize"
            (click)="pagesizeSelect.setValue(option.value)"
          >
            {{ option.label }} {{'paginator.items' | translate}}
          </naris-menu-item>
        }
      </naris-menu>
    </mat-menu>
  }
</div>
