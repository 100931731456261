import { sgn as r } from "../util/util.js";
var t = {
  subtract: function r(t, n) {
    return {
      x: t.x - n.x,
      y: t.y - n.y
    };
  },
  dotProduct: function r(t, n) {
    return t.x * n.x + t.y * n.y;
  },
  square: function r(t) {
    return Math.sqrt(t.x * t.x + t.y * t.y);
  },
  scale: function r(t, n) {
    return {
      x: t.x * n,
      y: t.y * n
    };
  }
};
var n = 64;
var a = Math.pow(2, -n - 1);
function u(r, n) {
  var a = [],
    u = i(r, n),
    o = n.length - 1,
    e = 2 * o - 1,
    c = f(u, e, a, 0),
    h = t.subtract(r, n[0]),
    y = t.square(h),
    l = 0,
    s;
  for (var p = 0; p < c; p++) {
    h = t.subtract(r, v(n, o, a[p], null, null));
    s = t.square(h);
    if (s < y) {
      y = s;
      l = a[p];
    }
  }
  h = t.subtract(r, n[o]);
  s = t.square(h);
  if (s < y) {
    y = s;
    l = 1;
  }
  return {
    location: l,
    distance: y
  };
}
function o(r, t) {
  var n = u(r, t);
  return {
    point: v(t, t.length - 1, n.location, null, null),
    location: n.location
  };
}
function i(r, n) {
  var a = n.length - 1,
    u = 2 * a - 1,
    o = [],
    i = [],
    f = [],
    e = [],
    c = [[1, .6, .3, .1], [.4, .6, .6, .4], [.1, .3, .6, 1]];
  for (var h = 0; h <= a; h++) {
    o[h] = t.subtract(n[h], r);
  }
  for (var v = 0; v <= a - 1; v++) {
    i[v] = t.subtract(n[v + 1], n[v]);
    i[v] = t.scale(i[v], 3);
  }
  for (var y = 0; y <= a - 1; y++) {
    for (var l = 0; l <= a; l++) {
      if (!f[y]) f[y] = [];
      f[y][l] = t.dotProduct(i[y], o[l]);
    }
  }
  for (var s = 0; s <= u; s++) {
    if (!e[s]) {
      e[s] = [];
    }
    e[s].y = 0;
    e[s].x = parseFloat("" + s) / u;
  }
  var p = a,
    x = a - 1;
  for (var M = 0; M <= p + x; M++) {
    var w = Math.max(0, M - x),
      q = Math.min(M, p);
    for (var b = w; b <= q; b++) {
      var g = M - b;
      e[b + g].y += f[g][b] * c[g][b];
    }
  }
  return e;
}
function f(r, t, a, u) {
  var o = [],
    i = [],
    y,
    l,
    s = [],
    p = [];
  switch (e(r, t)) {
    case 0:
      {
        return 0;
      }
    case 1:
      {
        if (u >= n) {
          a[0] = (r[0].x + r[t].x) / 2;
          return 1;
        }
        if (c(r, t)) {
          a[0] = h(r, t);
          return 1;
        }
        break;
      }
  }
  v(r, t, .5, o, i);
  y = f(o, t, s, u + 1);
  l = f(i, t, p, u + 1);
  for (var x = 0; x < y; x++) {
    a[x] = s[x];
  }
  for (var M = 0; M < l; M++) {
    a[M + y] = p[M];
  }
  return y + l;
}
function e(t, n) {
  var a = 0,
    u,
    o;
  u = o = r(t[0].y);
  for (var i = 1; i <= n; i++) {
    u = r(t[i].y);
    if (u != o) a++;
    o = u;
  }
  return a;
}
function c(r, t) {
  var n, u, o, i, f, e, c, h, v, y, l, s, p, x, M, w;
  e = r[0].y - r[t].y;
  c = r[t].x - r[0].x;
  h = r[0].x * r[t].y - r[t].x * r[0].y;
  var q, b;
  q = b = 0;
  for (var g = 1; g < t; g++) {
    var d = e * r[g].x + c * r[g].y + h;
    if (d > q) {
      q = d;
    } else if (d < b) {
      b = d;
    }
  }
  l = 0;
  s = 1;
  p = 0;
  x = e;
  M = c;
  w = h - q;
  v = l * M - x * s;
  y = 1 / v;
  u = (s * w - M * p) * y;
  x = e;
  M = c;
  w = h - b;
  v = l * M - x * s;
  y = 1 / v;
  o = (s * w - M * p) * y;
  i = Math.min(u, o);
  f = Math.max(u, o);
  n = f - i;
  return n < a ? 1 : 0;
}
function h(r, t) {
  var n = 1,
    a = 0,
    u = r[t].x - r[0].x,
    o = r[t].y - r[0].y,
    i = r[0].x - 0,
    f = r[0].y - 0,
    e = u * a - o * n,
    c = 1 / e,
    h = (u * f - o * i) * c;
  return 0 + n * h;
}
function v(r, t, n, a, u) {
  var o = [[]];
  for (var i = 0; i <= t; i++) {
    o[0][i] = r[i];
  }
  for (var f = 1; f <= t; f++) {
    for (var e = 0; e <= t - f; e++) {
      if (!o[f]) o[f] = [];
      if (!o[f][e]) o[f][e] = {};
      o[f][e].x = (1 - n) * o[f - 1][e].x + n * o[f - 1][e + 1].x;
      o[f][e].y = (1 - n) * o[f - 1][e].y + n * o[f - 1][e + 1].y;
    }
  }
  if (a != null) {
    for (var c = 0; c <= t; c++) {
      a[c] = o[c][0];
    }
  }
  if (u != null) {
    for (var h = 0; h <= t; h++) {
      u[h] = o[t - h][h];
    }
  }
  return o[t][0];
}
function y(r, t) {
  var n = [];
  r--;
  for (var a = 0; a <= r; a++) {
    n.push(l(a / r, t));
  }
  return n;
}
function l(r, t) {
  var n = {
    x: 0,
    y: 0
  };
  if (r === 0) {
    return t[0];
  }
  var a = t.length - 1;
  if (r === 1) {
    return t[a];
  }
  var u = t;
  var o = 1 - r;
  if (a === 0) {
    return t[0];
  }
  if (a === 1) {
    return {
      x: o * u[0].x + r * u[1].x,
      y: o * u[0].y + r * u[1].y
    };
  }
  if (4 > a) {
    var i = o * o,
      f = r * r,
      e = 0,
      c,
      h,
      v;
    if (a === 2) {
      u = [u[0], u[1], u[2], n];
      c = i;
      h = 2 * (o * r);
      v = f;
    } else if (a === 3) {
      c = i * o;
      h = 3 * (i * r);
      v = 3 * (o * f);
      e = r * f;
    }
    return {
      x: c * u[0].x + h * u[1].x + v * u[2].x + e * u[3].x,
      y: c * u[0].y + h * u[1].y + v * u[2].y + e * u[3].y
    };
  } else {
    return n;
  }
}
function s(r) {
  var t = 0;
  if (!q(r)) {
    var n = 16;
    var a = y(n, r);
    for (var u = 0; u < n - 1; u++) {
      var o = a[u],
        i = a[u + 1];
      t += w(o, i);
    }
  }
  return t;
}
var p = new Map();
function x(r) {
  var t = p.get(r);
  if (!t) {
    t = [];
    var n = function t() {
        return function (t) {
          return Math.pow(t, r);
        };
      },
      a = function t() {
        return function (t) {
          return Math.pow(1 - t, r);
        };
      },
      u = function r(t) {
        return function (r) {
          return t;
        };
      },
      o = function r() {
        return function (r) {
          return r;
        };
      },
      i = function r() {
        return function (r) {
          return 1 - r;
        };
      },
      f = function r(t) {
        return function (r) {
          var n = 1;
          for (var a = 0; a < t.length; a++) {
            n = n * t[a](r);
          }
          return n;
        };
      };
    t.push(n());
    for (var e = 1; e < r; e++) {
      var c = [u(r)];
      for (var h = 0; h < r - e; h++) {
        c.push(o());
      }
      for (var v = 0; v < e; v++) {
        c.push(i());
      }
      t.push(f(c));
    }
    t.push(a());
    p.set(r, t);
  }
  return t;
}
function M(r, t) {
  var n = x(r.length - 1),
    a = 0,
    u = 0;
  for (var o = 0; o < r.length; o++) {
    a = a + r[o].x * n[o](t);
    u = u + r[o].y * n[o](t);
  }
  return {
    x: a,
    y: u
  };
}
function w(r, t) {
  return Math.sqrt(Math.pow(r.x - t.x, 2) + Math.pow(r.y - t.y, 2));
}
function q(r) {
  return r[0].x === r[1].x && r[0].y === r[1].y;
}
function b(r, t, n) {
  if (q(r)) {
    return {
      point: r[0],
      location: t
    };
  }
  var a = M(r, t),
    u = 0,
    o = t,
    i = n > 0 ? 1 : -1,
    f = null;
  while (u < Math.abs(n)) {
    o += .005 * i;
    f = M(r, o);
    u += w(f, a);
    a = f;
  }
  return {
    point: f,
    location: o
  };
}
function g(r, t, n) {
  return b(r, t, n).point;
}
function d(r, t, n) {
  return b(r, t, n).location;
}
function m(r, t) {
  var n = M(r, t),
    a = M(r.slice(0, r.length - 1), t),
    u = a.y - n.y,
    o = a.x - n.x;
  return u === 0 ? Infinity : Math.atan(u / o);
}
function P(r, t, n) {
  var a = b(r, t, n);
  if (a.location > 1) a.location = 1;
  if (a.location < 0) a.location = 0;
  return m(r, a.location);
}
function I(r, t, n, a) {
  a = a == null ? 0 : a;
  var u = b(r, t, a),
    o = m(r, u.location),
    i = Math.atan(-1 / o),
    f = n / 2 * Math.sin(i),
    e = n / 2 * Math.cos(i);
  return [{
    x: u.point.x + e,
    y: u.point.y + f
  }, {
    x: u.point.x - e,
    y: u.point.y - f
  }];
}
function j(r, t, n, a, u) {
  var o = a - t,
    i = r - n,
    f = r * (t - a) + t * (n - r),
    e = A(u),
    c = [o * e[0][0] + i * e[1][0], o * e[0][1] + i * e[1][1], o * e[0][2] + i * e[1][2], o * e[0][3] + i * e[1][3] + f],
    h = B.apply(null, c),
    v = [];
  if (h != null) {
    for (var y = 0; y < 3; y++) {
      var l = h[y],
        s = Math.pow(l, 2),
        p = Math.pow(l, 3),
        x = {
          x: e[0][0] * p + e[0][1] * s + e[0][2] * l + e[0][3],
          y: e[1][0] * p + e[1][1] * s + e[1][2] * l + e[1][3]
        };
      var M = void 0;
      if (n - r !== 0) {
        M = (x[0] - r) / (n - r);
      } else {
        M = (x[1] - t) / (a - t);
      }
      if (l >= 0 && l <= 1 && M >= 0 && M <= 1) {
        v.push(x);
      }
    }
  }
  return v;
}
function k(r, t, n, a, u) {
  var o = [];
  o.push.apply(o, j(r, t, r + n, t, u));
  o.push.apply(o, j(r + n, t, r + n, t + a, u));
  o.push.apply(o, j(r + n, t + a, r, t + a, u));
  o.push.apply(o, j(r, t + a, r, t, u));
  return o;
}
function F(r, t) {
  var n = [];
  n.push.apply(n, j(r.x, r.y, r.x + r.w, r.y, t));
  n.push.apply(n, j(r.x + r.w, r.y, r.x + r.w, r.y + r.h, t));
  n.push.apply(n, j(r.x + r.w, r.y + r.h, r.x, r.y + r.h, t));
  n.push.apply(n, j(r.x, r.y + r.h, r.x, r.y, t));
  return n;
}
function z(r, t) {
  return [-r[0][t] + 3 * r[1][t] + -3 * r[2][t] + r[3][t], 3 * r[0][t] - 6 * r[1][t] + 3 * r[2][t], -3 * r[0][t] + 3 * r[1][t], r[0][t]];
}
function A(r) {
  return [z(r, "x"), z(r, "y")];
}
function B(t, n, a, u) {
  var o = n / t,
    i = a / t,
    f = u / t,
    e = (3 * i - Math.pow(o, 2)) / 9,
    c = (9 * o * i - 27 * f - 2 * Math.pow(o, 3)) / 54,
    h = Math.pow(e, 3) + Math.pow(c, 2),
    v,
    y,
    l = [0, 0, 0];
  if (h >= 0) {
    v = r(c + Math.sqrt(h)) * Math.pow(Math.abs(c + Math.sqrt(h)), 1 / 3);
    y = r(c - Math.sqrt(h)) * Math.pow(Math.abs(c - Math.sqrt(h)), 1 / 3);
    l[0] = -o / 3 + (v + y);
    l[1] = -o / 3 - (v + y) / 2;
    l[2] = -o / 3 - (v + y) / 2;
    if (Math.abs(Math.sqrt(3) * (v - y) / 2) !== 0) {
      l[1] = -1;
      l[2] = -1;
    }
  } else {
    var s = Math.acos(c / Math.sqrt(-Math.pow(e, 3)));
    l[0] = 2 * Math.sqrt(-e) * Math.cos(s / 3) - o / 3;
    l[1] = 2 * Math.sqrt(-e) * Math.cos((s + 2 * Math.PI) / 3) - o / 3;
    l[2] = 2 * Math.sqrt(-e) * Math.cos((s + 4 * Math.PI) / 3) - o / 3;
  }
  for (var p = 0; p < 3; p++) {
    if (l[p] < 0 || l[p] > 1) {
      l[p] = -1;
    }
  }
  return l;
}
export { j as bezierLineIntersection, F as boundingBoxIntersection, k as boxIntersection, s as computeBezierLength, w as dist, u as distanceFromCurve, m as gradientAtPoint, P as gradientAtPointAlongPathFrom, q as isPoint, d as locationAlongCurveFrom, o as nearestPointOnCurve, I as perpendicularToPathAt, g as pointAlongCurveFrom, b as pointAlongPath, M as pointOnCurve };