import { gql } from 'apollo-angular';
import { EPageSize } from '@core/enums';
import type { IGQLResponse, IGQLVariables, IKbBase, IKbIcon, IMongoVariables, ISearchVariables, ISubItem, TKbObject } from '@core/models/graphql.models';

const BASE_FIELDS = `
  count
  filters {
    id
    label
    type
  }
  limit
  page
  resultCount
  totalPages
`;

const BASE_DATA_FIELDS = `
  name
  organizationId
  organizationType
  organizationSubtype
  caseId
  PCFs
`;

const BASE_MONGO_SEARCH_FIELDS = `
  _id
  name
  score
`;

const EVT_FIELDS = `
  ${BASE_DATA_FIELDS}
  eventCategories
  riskCategories
`;

export const GET_EVENTS = gql<IGQLResponse, IGQLVariables>`
  query getEvents($pageSize: PageSize, $page: Int, $filter: FilterInput, $sorting: SortingInput) {
    sqlEvents(pageSize: $pageSize, page: $page, filter: $filter, sorting: $sorting) {
      ${BASE_FIELDS}
      data {
        ... on SqlEvent {
          ${BASE_DATA_FIELDS}
          kbId
          controls { ${BASE_DATA_FIELDS} }
          eventCategories
          rejected
          riskCategories
        }
      }
    }
  }
`;

export const GET_MONGO_EVENTS = gql<IGQLResponse, IGQLVariables>`
  query getMongoEvents($pageSize: PageSize, $page: Int, $filter: FilterInput, $sorting: SortingInput) {
    mongoEvents(pageSize: $pageSize, page: $page, filter: $filter, sorting: $sorting) {
      ${BASE_FIELDS}
      data {
        ... on MongoEvent {
          originalName
          ${EVT_FIELDS}
          _id
          status
          controls { ${BASE_DATA_FIELDS} }
          rejectComment
          related {
            _id
            ${EVT_FIELDS}
          }
        }
      }
    }
  }
`;

export const GET_CONTROLS = gql<IGQLResponse, IGQLVariables>`
  query getControls($pageSize: PageSize, $page: Int, $filter: FilterInput, $sorting: SortingInput) {
    sqlControls(pageSize: $pageSize, page: $page, filter: $filter, sorting: $sorting) {
      ${BASE_FIELDS}
      data {
        ... on SqlControl {
          ${BASE_DATA_FIELDS}
          kbId
          events { ${EVT_FIELDS} }
          rejected
        }
      }
    }
  }
`;

export const GET_MONGO_CONTROLS = gql<IGQLResponse, IGQLVariables>`
  query getMongoControls($pageSize: PageSize, $page: Int, $filter: FilterInput, $sorting: SortingInput) {
    mongoControls(pageSize: $pageSize, page: $page, filter: $filter, sorting: $sorting) {
      ${BASE_FIELDS}
      data {
        ... on MongoControl {
          originalName
          ${BASE_DATA_FIELDS}
          _id
          status
          events { ${EVT_FIELDS} }
          rejectComment
          related {
            _id
            ${BASE_DATA_FIELDS}
          }
        }
      }
    }
  }
`;

export const POST_EVENTS = gql<IGQLResponse, Partial<IMongoVariables>>`
  mutation postEvents($events: [EventInput!]!) {
    addEvents(events: $events) {
      caseId
      id
    }
  }
`;

export const POST_CONTROLS = gql<IGQLResponse, Partial<IMongoVariables>>`
  mutation postControls($controls: [ControlInput!]!) {
    addControls(controls: $controls) {
      caseId
      id
    }
  }
`;

export const REVIEW_EVENTS = gql<IGQLResponse, Partial<IMongoVariables>>`
  mutation reviewEvents($reviewed: ReviewInput!) {
    reviewEvents(reviewed: $reviewed) {
      caseIds
      ids
    }
  }
`;

export const REVIEW_CONTROLS = gql<IGQLResponse, Partial<IMongoVariables>>`
  mutation reviewControls($reviewed: ReviewInput!) {
    reviewControls(reviewed: $reviewed) {
      caseIds
      ids
    }
  }
`;

export const SEARCH_EVENTS = gql<IGQLResponse, Partial<ISearchVariables>>`
  query searchEvents($searchTerm: String) {
    searchEvents(searchTerm: $searchTerm) {
      score
      highlights {
        name
        PCFs
      }
      document {
        ... on SearchEvent {
          _id
          name
          organizationType
          organizationSubtype
          PCFs
          eventCategories
          riskCategories
          controls {
            name
            organizationType
            organizationSubtype
            PCFs
          }
          searchId
        }
      }
    }
  }
`;

export const SEARCH_CONTROLS = gql<IGQLResponse, Partial<ISearchVariables>>`
  query searchControls($searchTerm: String) {
    searchControls(searchTerm: $searchTerm) {
      score
      highlights {
        name
        PCFs
      }
      document {
        ... on SearchControl {
          _id
          name
          organizationType
          organizationSubtype
          PCFs
          events {
            name
            organizationType
            organizationSubtype
            PCFs
            eventCategories
            riskCategories
          }
          searchId
        }
      }
    }
  }
`;

export const SEARCH_MONGO_EVENTS = gql<IGQLResponse, Partial<ISearchVariables>>`
  query searchMongoEvents($searchTerm: String, $caseId: Int!) {
    searchMongoEvents(searchTerm: $searchTerm, caseId: $caseId) {
      ${BASE_MONGO_SEARCH_FIELDS}
      controls { ${BASE_DATA_FIELDS} }
      eventCategories
      PCFs
      riskCategories
    }
  }
`;

export const SEARCH_MONGO_CONTROLS = gql<IGQLResponse, Partial<ISearchVariables>>`
  query searchMongoControls($searchTerm: String, $caseId: Int!) {
    searchMongoControls(searchTerm: $searchTerm, caseId: $caseId) {
      ${BASE_MONGO_SEARCH_FIELDS}
      events { ${EVT_FIELDS} }
      PCFs
    }
  }
`;

export const DISCARD_CONTROL = gql<IGQLResponse, Partial<IMongoVariables>>`
  mutation discardControl($control: ControlInput!) {
    discardControl(item: $control)
  }
`;

export const DISCARD_EVENT = gql<IGQLResponse, Partial<IMongoVariables>>`
  mutation discardEvent($event: EventInput!) {
    discardEvent(item: $event)
  }
`;

export const PAGE_SIZE: Record<number, EPageSize> = {
  0: EPageSize.All,
  10: EPageSize.Few,
  25: EPageSize.Med,
  50: EPageSize.Many
};

export const GQL_BLACKLIST: (keyof TKbObject)[] = ['__typename', 'caseId', 'eventCategories', 'organizationId', 'organizationType', 'organizationSubtype', 'kbId', 'riskCategories'];

export const GQL_TO_NUM: (keyof (IKbBase & ISubItem))[] = ['caseId', 'organizationId'];

export const SUB_ITEMS: (keyof TKbObject)[] = ['controls', 'events'];

export const KB_ICONS: Record<string, Pick<IKbIcon, 'class' | 'icon'>> = {
  controls: {icon: 'control', class: 'green'},
  eventCategories: {icon: 'event', class: 'warn'},
  events: {icon: 'event', class: 'warn'},
  PCFs: {icon: 'process', class: 'primary'},
  riskCategories: {icon: 'risk', class: 'primary'}
};

export const KB_DIALOG_STYLES: Record<string, string> = {
  minWidth: '33vw',
  maxWidth: '80vw',
  maxHeight: '80vh'
};
