import { Decorators as e, SyntheticDecorator as r } from "./decorators.js";
import { uuid as t, log as o } from "../ui-core/util/util.js";
function n(n, i, l) {
  if (n == null) {
    return [];
  }
  var u = [];
  var a = function n(u) {
    var a, f, s, c;
    var d = u;
    if (typeof d === "string") {
      a = d;
      f = {};
      c = t();
    } else {
      if (d.type != null) {
        a = d.type;
        f = d.options || {};
        c = d.id || t();
      }
    }
    if (a != null && f != null) {
      s = e.get(a, f, i, l, c);
    } else {
      var p = u;
      c = p.id || t();
      if (p.reset != null && typeof p.reset === "function" && p.decorate != null && typeof p.decorate === "function") {
        s = new r(p, {}, i, l, c, p.incremental);
      } else {
        o('Cannot register decorator. Ensure both "reset" and "decorate" methods are provided.');
      }
    }
    if (!s) {
      o("Decorator [" + a + "] nor registered. Not fatal.");
      return null;
    }
    return s;
  };
  for (var f = 0; f < n.length; f++) {
    var s = a(n[f]);
    if (s) {
      u.push(s);
    }
  }
  return u;
}
export { n as _initialiseDecorators };