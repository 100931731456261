import { Info } from 'luxon';
import type { DurationLikeObject } from 'luxon';

export const FREQUENCY_CONFIG = {
  options: [
    { key: 'once', label: 'frequency.once' },
    { key: 'custom', label: 'frequency.custom' }
  ],
  deadlineUnits: [
    { key: 'Deadline_Days', label: 'frequency.unit_days' },
    { key: 'Deadline_Workdays', label: 'frequency.unit_workdays' },
    { key: 'Deadline_Weeks', label: 'frequency.unit_weeks' },
    { key: 'Deadline_Months', label: 'frequency.unit_months' },
    { key: 'Deadline_Years', label: 'frequency.unit_years' }
  ],
  units: [
    { key: 'Days', label: 'frequency.unit_days' },
    { key: 'Weeks', label: 'frequency.unit_weeks' },
    { key: 'Months', label: 'frequency.unit_months' },
    { key: 'Years', label: 'frequency.unit_years' }
  ],
  periods: {
    Days: {
      max: 28,
      frequencyControl: 'Daily_Frequency',
      options: Info.weekdays().map(m => ({ key: m, label: m.toLowerCase() }))
    },
    Weeks: {
      max: 52,
      frequencyControl: 'Weekly_Frequency'
    },
    Months: {
      max: 12,
      frequencyControl: 'Monthly_Frequency',
      options: Info.months().map(m => ({ key: m, label: m.toLowerCase() }))
    },
    Years: {
      max: 5,
      frequencyControl: 'Years_Frequency'
    }
  }
};

export const HOUR_NUMS = [...Array(24).keys()];
export const MINUTE_NUMS = [...Array(60).keys()];
export const DATE_FMT = 'yyyy-MM-dd';

export const UNITS: (keyof DurationLikeObject)[] = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds', 'milliseconds'];
