import { createClass as e, classCallCheck as r, defineProperty as t, toConsumableArray as n } from "../_virtual/_rollupPluginBabelHelpers.js";
import { TYPE_ROOT as a, TYPE_ELEMENT as l, TYPE_TEXT as i, TYPE_IF as s, TYPE_TMPL as u, TYPE_EACH as o, PLACEHOLDER_VALUE as c, TYPE_ATTRIBUTE as h, PLACEHOLDER_KEY as p } from "./defs.js";
import { data as f, eachNotEmpty as d } from "../core/util.js";
import { parseAttributes as v, _addBinding as m, parseAtts as y } from "./parser.js";
import { TextEntry as g } from "./text-entry.js";
import { CommentEntry as x } from "./comment-entry.js";
import { ElementEntry as _ } from "./element-entry.js";
import { addNewExecutionToEachExecution as k, addEntryToEachExecution as T } from "./each-entry.js";
import { Elements as C } from "./elements.js";
import { CustomTag as A } from "./custom-tag.js";
import { updateBindings as b, clearExecution as R, emptyUpdateResult as w } from "./executions.js";
import { evaluateExpression as E } from "./expressions.js";
import { resolveIfExecution as N } from "./if-entry.js";
import { TmplEntry as j } from "./tmpl-entry.js";
import { log as M, forEach as B, uuid as I, fastTrim as z, isObject as $ } from "../ui-core/util/util.js";
function Z(e, r) {
  if (e.length != null) {
    for (var t = 0; t < e.length; t++) {
      r(e[t], t);
    }
  } else {
    for (var n in e) {
      r(e[n], n);
    }
  }
}
var D = function () {
  function d(e) {
    r(this, d);
    t(this, "templateResolver", void 0);
    t(this, "defaultTemplate", void 0);
    t(this, "macros", void 0);
    t(this, "entries", void 0);
    t(this, "customTags", {});
    t(this, "cache", new Map());
    t(this, "templateCache", new Map());
    t(this, "openRe", new RegExp("<([^/>]*?)>$|<([^/].*[^/])>$"));
    t(this, "closeRe", new RegExp("^</([^>]+)>"));
    t(this, "openCloseRe", new RegExp("<(.*)(/>$)"));
    t(this, "tokenizerRe", /(<[^\^>]+\/>)|(<!--[\s\S]*?-->)|(<[\/a-zA-Z0-9\-:]+(?:\s*[\(a-zA-Z\-\):]+=\"[^\"]*\"|\s*[a-zA-Z\-:]+='[^']+'|\s*[\(a-zA-Z\-\):]|\s*\{\{.*\}\}|\s*\{#.*\})*>)/);
    t(this, "commentRe", /<!--[\s\S]*?-->/);
    t(this, "attributesRe", /([\(a-zA-Z0-9\-_:\)]+="[^"]*")|\s+([\(a-zA-Z0-9\-_:\)]+(?=\s.|$))/);
    t(this, "helperExpressionRe", /^[\s]*\{#([^\}]*)\}[\s]*$/);
    t(this, "isBrowser", true);
    t(this, "_templates", void 0);
    t(this, "namespaceHandlers", {});
    t(this, "namespaces", {});
    e = e || {};
    this._templates = e.templates || {};
    this.entries = {};
    this.defaultTemplate = e.defaultTemplate;
    this.templateResolver = e.templateResolver || this._getDefaultTemplateResolver();
    this.macros = e.templateMacros || {};
  }
  e(d, [{
    key: "resolveTemplate",
    value: function e(r) {
      if (this._templates[r] != null) {
        return this._templates[r];
      } else {
        return this.templateResolver(r);
      }
    }
  }, {
    key: "setAttribute",
    value: function e(r, t, n) {
      var a = t.split(":");
      if (a.length === 1) {
        r.setAttribute(a[0], n);
      } else {
        if (this.namespaces[a[0]] == null) {
          r.setAttribute(t, n);
        } else {
          r.setAttributeNS(this.namespaces[a[0]], a[1], n);
        }
      }
    }
  }, {
    key: "clearCache",
    value: function e() {
      this.cache.clear();
      this.templateCache.clear();
    }
  }, {
    key: "parseAttributes",
    value: function e(r) {
      return v(r, this.attributesRe);
    }
  }, {
    key: "_wrapCache",
    value: function e(r, t) {
      var n = this;
      return function (e) {
        var a = t ? null : n.cache.get(e);
        if (a == null) {
          a = r(e);
        }
        if (a == null) {
          a = n.defaultTemplate;
        }
        if (a != null) {
          n.cache.set(e, a);
        }
        return a;
      };
    }
  }, {
    key: "getTemplate",
    value: function e(r) {
      return this.templateCache.get(r);
    }
  }, {
    key: "addTemplate",
    value: function e(r, t) {
      this._templates[r] = t;
    }
  }, {
    key: "template",
    value: function e(r, t, n, a) {
      t = t || {};
      var l = !a ? this.templateCache.get(r) : null;
      if (l != null) {
        return this.render(l, t).fragment;
      } else {
        var i = this.parseAndCache(r, n, a);
        if (i != null) {
          return this.render(i, t).fragment;
        } else {
          return this.cf();
        }
      }
    }
  }, {
    key: "parseAndCache",
    value: function e(r, t, n) {
      var a = this;
      var l = this.templateCache.get(r);
      if (l == null || n === true) {
        t = this._wrapCache(t || function (e) {
          return a.resolveTemplate(e);
        }, n);
        var i = t(r);
        if (i != null) {
          var s = S(this, i, t, null, [r]);
          this.templateCache.set(r, s);
          return s;
        }
      } else {
        return l;
      }
    }
  }, {
    key: "data",
    value: function e(r, t, n) {
      return f(r, t, n);
    }
  }, {
    key: "each",
    value: function e(r, t, n, a, l) {
      var i;
      if (Array.isArray(r)) {
        for (i = 0; i < r.length; i++) {
          t(r[i], n, i, a, l);
        }
      } else {
        for (i in r) {
          if (r.hasOwnProperty(i)) {
            t({
              $key: i,
              $value: r[i]
            }, n, i, a, l);
          }
        }
      }
    }
  }, {
    key: "update",
    value: function e(r, t) {
      var n = r._recado,
        a = w();
      if (n != null) {
        this._updateExecution(n, t, a);
      }
      return a;
    }
  }, {
    key: "onUpdate",
    value: function e(r, t) {
      if (r._recado == null) {
        return;
      }
      r._recado.updaters = r._recado.updaters || [];
      r._recado.updaters.push(t);
    }
  }, {
    key: "_updateExecution",
    value: function e(r, t, n) {
      var c = this;
      if (r.type === a) {
        for (var h = 0; h < r.children.length; h++) {
          this._updateExecution(r.children[h], t, n);
        }
      } else if (r.type === l || r.type === i) {
        b(r, t, function (e) {
          return c._expand(e, t);
        });
        if (this.customTags[r.entry.tag] != null) {
          var p = this.customTags[r.entry.tag];
          if (p.updated) {
            p.updated(r.el, t, this);
          }
        }
        for (var d = 0; d < r.children.length; d++) {
          this._updateExecution(r.children[d], t, n);
        }
      } else if (r.type === s) {
        var v = r;
        var m = v.resolution;
        var y = N(v, t, function (e) {
          return c._expand(e, t);
        });
        if (!y) {
          for (var g = 0; g < r.children.length; g++) {
            R(this, r.children[g]).map(function (e) {
              return n.removed.push({
                el: e
              });
            });
          }
          r.children.length = 0;
        }
        if (y) {
          if (!m) {
            var x = this.render(r.entry.children, t);
            r.children = x.children;
            for (var _ = 0; _ < r.children.length; _++) {
              var T = r.children[_];
              v.placeholder.before(T.el);
              n.added.push({
                el: T.el
              });
            }
          } else {
            for (var C = 0; C < r.children.length; C++) {
              this._updateExecution(r.children[C], t, n);
            }
          }
        }
      } else if (r.type === u) {
        var A = r.entry.context == null || r.entry.context.length === 0 ? t : f(t, r.entry.context);
        for (var w = 0; w < r.children.length; w++) {
          this._updateExecution(r.children[w], A, n);
        }
      } else if (r.type === o) {
        (function () {
          var e = r;
          var a = f(t, r.entry.context);
          if (a == null) {
            R(c, r).map(function (e) {
              return n.removed.push({
                el: e
              });
            });
          } else {
            if (e.entry.key == null || e.entry.key.length === 0) {
              M("WARN: Cannot update r-each entry when no `key` parameter was set.");
            } else {
              var l = {};
              var i = [];
              var s = [];
              Z(a, function (r, t) {
                var n = f(r, e.entry.key);
                l[n] = r;
                if (e.entryMap[n] != null) {
                  var a = e.entryMap[n];
                  for (var s = 0; s < a.length; s++) {
                    i.push({
                      data: r,
                      key: n,
                      execution: a[s]
                    });
                  }
                } else {
                  i.push({
                    data: r,
                    key: n
                  });
                }
              });
              for (var u in e.entryMap) {
                if (l[u] == null) {
                  for (var o = 0; o < e.entryMap[u].length; o++) {
                    s.push({
                      e: e.entryMap[u][o],
                      key: u
                    });
                  }
                }
              }
              for (var h = 0; h < s.length; h++) {
                delete e.entryMap[s[h].key];
                R(c, s[h].e).map(function (e) {
                  return n.removed.push({
                    el: e
                  });
                });
              }
              for (var p = 0; p < i.length; p++) {
                var d = i[p];
                if (d.execution) {
                  c._updateExecution(d.execution, d.data, n);
                  if (d.execution.el != null) {
                    e.placeholder.before(d.execution.el);
                  } else {
                    B(d.execution.children, function (r) {
                      if (r.el != null) {
                        e.placeholder.before(r.el);
                      }
                    });
                  }
                } else {
                  var v = c.render(r.entry.children, d.data);
                  k(e, v, d.key, n);
                }
              }
            }
          }
        })();
      }
      if (r.updaters) {
        for (var E = 0; E < r.updaters.length; E++) {
          r.updaters[E](r.el);
        }
      }
      return n;
    }
  }, {
    key: "remove",
    value: function e(r, t) {
      if (t) {
        r.parentNode && r.parentNode.removeChild(r);
      }
    }
  }, {
    key: "registerTag",
    value: function e(r, t) {
      this.customTags[r] = new A(this, r, t);
    }
  }, {
    key: "parse",
    value: function e(r, t, n, a) {
      return S(this, r, t, n, a);
    }
  }, {
    key: "_expand",
    value: function e(r, t) {
      if (this.macros[r]) {
        return this.macros[r](t) || "";
      } else {
        return "";
      }
    }
  }, {
    key: "render",
    value: function e(r, t, a) {
      var d = this;
      var v = {
        id: I(),
        fragment: this.cf(),
        children: [],
        type: "root",
        bindings: {},
        entry: null
      };
      var m = v.fragment;
      var y = null;
      var x = v;
      m._recado = v;
      var _ = function e(r, t, n) {
        var a = {
          id: t,
          entry: r,
          placeholder: d.cc("".concat(o, ":").concat(t)),
          entryMap: {},
          type: o,
          children: []
        };
        R(a.placeholder, n);
        x.children.push(a);
        return a;
      };
      var k = function e(r, t, n) {
        var a = {
          placeholder: d.cc("".concat(u, ":").concat(t)),
          entry: r,
          id: t,
          children: [],
          type: u
        };
        R(a.placeholder, n);
        x.children.push(a);
        return a;
      };
      var C = function e(r, t, n, a) {
        w(r, a);
        var i = {
          id: I(),
          entry: t,
          bindings: n,
          children: [],
          el: r,
          type: l
        };
        x.children.push(i);
        r._recado = i;
        return i;
      };
      var A = function e(r, t, n, a) {
        w(r, a);
        var l = {
          id: I(),
          el: r,
          entry: t,
          bindings: n,
          children: [],
          type: i
        };
        x.children.push(l);
        r._recado = l;
        return l;
      };
      var b = function e(r, t) {
        var n = I();
        var a = {
          id: n,
          entry: r,
          resolution: false,
          placeholder: d.cc("".concat(s, ":").concat(n)),
          type: s,
          children: []
        };
        R(a.placeholder, t);
        x.children.push(a);
        return a;
      };
      var R = function e(r, t) {
        if (t != null) {
          t.before(r);
        } else {
          if (y != null) {
            y.appendChild(r);
          } else {
            m.appendChild(r);
          }
        }
      };
      var w = function e(r, t) {
        if (t != null) {
          t.before(r);
        } else {
          if (y != null) {
            y.appendChild(r);
          } else {
            m.appendChild(r);
          }
        }
      };
      var j = function e(r, t, v, m) {
        t = t || {};
        var R = false;
        var w = null;
        if (r.type === s) {
          var j = x;
          var M = b(r, v);
          x = M;
          R = N(M, t, function (e) {
            return d._expand(e, t);
          });
          if (R) {
            for (var B = 0; B < r.children.length; B++) {
              e(r.children[B], t, M.placeholder, m);
            }
          }
          x = j;
          w = M;
        } else if (r.type === o) {
          var z = r;
          var D = y;
          var G = r.context === c ? t : f(t, r.context);
          var P = x;
          var S = I();
          var X = _(z, S, v);
          x = X;
          if (G != null) {
            Z(G, function (t, n) {
              var a = z.key && $(t) ? f(t, z.key) : n || null;
              for (var l = 0; l < r.children.length; l++) {
                var i = e(r.children[l], t, X.placeholder, n);
                if (a != null) {
                  T(X, a, i);
                }
              }
            });
          }
          y = D;
          x = P;
          w = X;
        } else if (r.type === u) {
          var H = r;
          var O = y;
          var U = x;
          var V = I();
          var W = k(H, V, v);
          x = W;
          var q = r.context == null || r.context.length === 0 ? t : f(t, r.context);
          var F = H.lookup != null ? f(t, H.lookup.replace(/[\{\{\}\}]/g, "")) : H.templateId;
          var J = d.parseAndCache(F, a);
          if (J == null && H.default != null) {
            J = d.parseAndCache(H.default, a);
          }
          for (var K = 0; K < J.length; K++) {
            e(J[K], q, W.placeholder, m);
          }
          y = O;
          x = U;
          w = W;
        } else if (r.type === l) {
          var L;
          var Q;
          var Y = r;
          var ee;
          var re = {};
          var te = [];
          if (d.customTags[r.tag] != null) {
            ee = d.customTags[r.tag];
            var ne = d.render(ee.getAST(t), t);
            Q = ne.fragment.childNodes[0];
            var ae = ne.children[0].bindings;
            for (var le in ae) {
              re[le] = ae[le];
            }
            te.push.apply(te, n(ne.children[0].children));
          } else if (Y.namespace != null) {
            Q = d.namespaceHandlers[Y.namespace](r.tag);
          } else {
            Q = d.ce(r.tag);
          }
          for (var ie in Y.atts) {
            if (Y.atts.hasOwnProperty(ie)) {
              var se = Y.atts[ie];
              if (r.compiledBindings[ie] != null) {
                var ue = r.compiledBindings[ie];
                re[ie] = {
                  id: ue.id,
                  binding: ue,
                  results: [],
                  originalValue: se,
                  type: h
                };
                for (var oe = 0; oe < ue.expressions.length; oe++) {
                  var ce = ue.expressions[oe];
                  var he = E(ce.expression, function (e) {
                    if (e === p) {
                      return m;
                    } else if (e === c) {
                      return t;
                    } else {
                      return f(t, e);
                    }
                  }, function (e) {
                    return d._expand(e, t);
                  });
                  se = se.replace(ce.source, he);
                  re[ie].results.push({
                    source: ce.source,
                    result: he
                  });
                }
              }
              d.setAttribute(Q, ie, se);
            }
          }
          if (ee && ee.rendered) {
            ee.rendered(Q, t, d, y);
          }
          var pe = y;
          var fe = x;
          var de = C(Q, Y, re, v);
          (L = de.children).push.apply(L, te);
          x = de;
          y = Q;
          for (var ve = 0; ve < r.children.length; ve++) {
            e(r.children[ve], t, null, m);
          }
          x = fe;
          y = pe;
          w = de;
        } else if (r.type === i) {
          var me = r.value || "";
          var ye = {};
          var ge = r.compiledBindings[g.TEXT_BINDING_NAME];
          if (ge != null) {
            ye[g.TEXT_BINDING_NAME] = {
              id: ge.id,
              binding: ge,
              results: [],
              originalValue: me,
              type: i
            };
            for (var xe = 0; xe < ge.expressions.length; xe++) {
              var _e = ge.expressions[xe];
              var ke = E(_e.expression, function (e) {
                if (e === p) {
                  return m;
                } else if (e === c) {
                  return t;
                } else {
                  return f(t, e);
                }
              }, function (e) {
                return d._expand(e, t);
              });
              me = me.replace(_e.source, ke);
              ye[g.TEXT_BINDING_NAME].results.push({
                source: _e.source,
                result: ke
              });
            }
          }
          var Te = d.ctn(me);
          var Ce = A(Te, r, ye, v);
          w = Ce;
        }
        return w;
      };
      for (var M = 0; M < r.length; M++) {
        j(r[M], t || {}, null, null);
        y = null;
        x = v;
      }
      return v;
    }
  }]);
  return d;
}();
function G(e) {
  return C.exists(e);
}
function P(e, r, t, n, a, l) {
  return C.construct(r, t, e, n, a, l);
}
function S(e, r, t, n, a) {
  a = a || [];
  t = e._wrapCache(t || function (r) {
    return e.resolveTemplate(r);
  }, null);
  var l = [],
    i = [],
    s = function e(r, t) {
      var n = r.match(t);
      return n == null ? null : n;
    },
    u = function e() {
      return l.length > 0 ? l[l.length - 1] : null;
    },
    o = function e(r) {
      var t = u();
      return t != null && t.tag === r;
    },
    c = function e(r, t) {
      if (l.length > 0) {
        u().children.push(r);
      }
      if (!t) {
        l.push(r);
      } else if (l.length === 0) {
        i.push(r);
      }
    },
    h = function e(r) {
      c(r, true);
    },
    p = function e() {
      var r = l.pop();
      if (l.length === 0 && !r.remove) {
        i.push(r);
      } else {
        if (r.remove) {
          var t = u();
          if (t) {
            t.children.pop();
          }
        }
      }
      return r;
    },
    f = function r(t, n, i, s) {
      var u = y(t, e);
      if (G(u.el)) {
        return P(e, u.el, u, i, l, a);
      } else {
        return new _(u, e, i, l, a);
      }
    },
    v = [{
      re: e.commentRe,
      handler: function e(r, t, n, a, l) {
        c(new x(r, a), true);
      }
    }, {
      re: e.openRe,
      handler: function e(r, t, n, a, l) {
        var i = f(r, t, n);
        c(i, i.remove);
      }
    }, {
      re: e.closeRe,
      handler: function e(r, t, n, a, l) {
        if (t[1] === j.tag) {
          return;
        }
        if (!o(t[1])) {
          throw new TypeError("Unbalanced closing tag '" + t[1] + "'; opening tag was '" + p().tag + "'");
        } else p();
      }
    }, {
      re: e.openCloseRe,
      handler: function e(r, t, n, a, l) {
        var i = f(r, t, n);
        c(i, true);
      }
    }, {
      re: /.*/,
      handler: function e(r, t, n, a, l) {
        var i = new g({
          value: r
        }, a);
        h(i);
        var s = r.match(/^[\s]*$/) != null;
        if (!s) {
          m(g.TEXT_BINDING_NAME, r, i);
        }
      }
    }];
  d(z(r).split(e.tokenizerRe), function (r, n) {
    var l = z(n);
    for (var i = 0; i < v.length; i++) {
      var u = s(l, v[i].re);
      if (u) {
        v[i].handler(n, u, t, e, a);
        break;
      }
    }
  });
  if (i.length > 0 && n) {
    for (var k in n) {
      i[0][k] = n[k];
    }
  }
  return i;
}
export { D as Recado };