@if (!!panel && !!componentType) {
  @switch(componentType) {
    @case ('table') {
      <!-- Table -->
      <naris-card>
        @if (panel.layouthint?.includes('grid-table')) {
          <app-grid-table
            [dataUrl]="panel.href!"
            [tableConfig]="tableConfig"
            [widgetConfig]="widgetConfig!"
            (widgetConfigChanged)="widgetConfigChanged.emit($event)"
            [editMode]="!!editMode"
            (loaded)="update()"
            [reloadTable]="reload"
            [layoutHint]="panel.layouthint || []"
            [selectedTab]="selectedTab"
            [tabHref]="tabHref"
            [isOverviewDashboard]="isOverviewDashboard"
            [widgetPanelSize]="widgetPanelSize"
          />
        } @else {
          <naris-table
            [dataUrl]="panel.href!"
            [tableConfig]="tableConfig"
            [widgetConfig]="widgetConfig!"
            (widgetConfigChanged)="widgetConfigChanged.emit($event)"
            [editMode]="!!editMode"
            (loaded)="update()"
            [reloadTable]="reload"
            [layoutHint]="panel.layouthint || []"
            [selectedTab]="selectedTab"
            [tabHref]="tabHref"
            [isOverviewDashboard]="isOverviewDashboard"
            [widgetPanelSize]="widgetPanelSize"
            [isTableReload]="isTableReload"
          />
        }
      </naris-card>
    }
    @case ('list') {
      <!-- List -->
      <naris-card>
        <naris-list [dataUrl]="panel.href!" [isEmbedded]="isEmbedded" />
      </naris-card>
    }
    @case ('treeview') {
      <!-- Treeview -->
      <naris-card class="treeview-card">
        <naris-treeview
          [href]="panel.href!"
          [editMode]="true"
          (updated)="update()"
          [layouthint]="panel.layouthint || []"
          [opened]="false"
          [enableLazyLoading]="panel.layouthint?.includes('lazy-load') || false"
        />
      </naris-card>
    }
    @case ('multi-slider') {
      <!-- Multi Slider -->
      <naris-card>
        <naris-multi-slider
          [href]="panel.href!"
          (updated)="update()"
          [id]="panel.name || ''"
        />
      </naris-card>
    }
    @case ('frequency') {
      <!-- Frequency -->
      <naris-card>
        <naris-frequency
          [href]="panel.href!"
          (loaded)="update()"
          [layouthint]="panel.layouthint || []"
          [title]="panel.label!"
          [tabHref]="tabHref"
        />
      </naris-card>
    }
    @case ('cause-and-consequence-overview') {
      <!-- Execution nested list -->
      <naris-card>
        <naris-cause-and-consequence-overview
          [href]="panel.href!" />
      </naris-card>
    }
    @case ('graph') {
      <!-- Naris graph widget -->
      <naris-card>
        @if (panel.layouthint?.includes('graph:heatmap')) {
          <naris-widget-heatmap-wrapper
            [href]="panel.href!"
            [editMode]="!!editMode"
            [widgetConfig]="widgetConfig!"
            (widgetConfigChanged)="widgetConfigChanged.emit($event)" 
          />
        } @else {
          <naris-graph
            [layouthint]="panel.layouthint!"
            [showValuesInChart]="true"
            [href]="panel.href!"
            [editMode]="!!editMode"
            [widgetConfig]="widgetConfig!"
            (widgetConfigChanged)="widgetConfigChanged.emit($event)" 
          />
        }
      </naris-card>
    }
    @case ('process-list') {
      <naris-process-manager [href]="panel.href!" />
    }
    @case ('archimate-list') {
      <app-process-archimate [href]="panel.href!" />
    }
    @case ('strategymap') {
      <naris-process-editor [endpoint]="panel.href!" />
    }
    @case ('register-form') {
      <naris-card>
        <app-form 
          [endpoint]="registerFormEndpoint"
          [isRegisterForm]="true"
          [isStriped]="false"
        />
      </naris-card>
    }
  }
}
