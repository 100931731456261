function i(i, a, r, v, d, h, t, l, n, m) {
  var o, e;
  v.x + d;
  v.y + h;
  m = m || a.minSize;
  var f = a.maxSize;
  var x = a.padding || 0;
  var u = ((o = m) === null || o === void 0 ? void 0 : o.w) || 0;
  var g = ((e = m) === null || e === void 0 ? void 0 : e.h) || 0;
  var M = 0;
  var w = 0;
  if (i.xmin < x && l) {
    M = x - i.xmin;
  }
  if (i.ymin < x && l) {
    w = x - i.ymin;
  }
  var y = i.xmax + M + x;
  var c = i.ymax + w + x;
  var s = Math.min((f === null || f === void 0 ? void 0 : f.w) || y, y);
  var S = Math.min((f === null || f === void 0 ? void 0 : f.h) || c, c);
  s = Math.max(u, s);
  S = Math.max(g, S);
  if (r === true) {
    if (i.xmin > x && t) {
      var p;
      var z = i.xmin - x;
      var G = s - z;
      s = Math.max(((p = m) === null || p === void 0 ? void 0 : p.w) || G, G);
      if (s > G) {
        z -= s - G;
      }
      M = -z;
    }
    if (i.ymin > x && t) {
      var T;
      var X = i.ymin - x;
      var Y = S - X;
      S = Math.max(((T = m) === null || T === void 0 ? void 0 : T.h) || Y, Y);
      if (S > Y) {
        X -= S - Y;
      }
      w = -X;
    }
  } else {
    if (a.elastic) ;
  }
  if (n.grid && n.fitGroupsToGrid !== false) {
    var b = s / n.grid.w;
    s = n.grid.w * Math.ceil(b);
    var j = S / n.grid.h;
    S = n.grid.h * Math.ceil(j);
    var k = M / n.grid.w;
    M = n.grid.w * Math.ceil(k);
    var q = w / n.grid.h;
    w = n.grid.h * Math.ceil(q);
  }
  return {
    width: s,
    height: S,
    layoutShiftX: M,
    layoutShiftY: w
  };
}
export { i as computeGroupSize };