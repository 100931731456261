import { Component, Input, OnInit } from '@angular/core';
import { AuditExecutionHistoryViewerComponent } from '../../../shared/components/audit-execution-history-viewer/audit-execution-history-viewer.component';

@Component({
  selector: 'naris-audit-execution-history',
  templateUrl: './audit-execution-history.component.html',
  styleUrls: ['./audit-execution-history.component.scss'],
  standalone: true,
  imports: [AuditExecutionHistoryViewerComponent]
})
export class AuditExecutionHistoryComponent implements OnInit {
  @Input() public jsonObject: any;
  @Input() public name: string;

  public historyViewerData: any[];

  public ngOnInit(): void {
    const names = Object.keys(this.jsonObject);
    const historyExecutions = this.jsonObject?.['history.executions'];

    delete this.jsonObject?.['history.executions'];

    this.historyViewerData = [{name: names[0], data: this.jsonObject}];
    if (!!historyExecutions?.length) {
      this.historyViewerData.push({name: names[1], data: historyExecutions});
    } 
  }

}
