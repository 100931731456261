<div 
  class="naris-loader" 
  [style.height]="height"
  [ngClass]="{'naris-loader--form-embedded': embeddedInForm}"
>
  @if (!timeoutPassed) {
    <naris-progress-spinner
      [strokeWidth]="2"
      [diameter]="24"
      class="naris-loader__icon"
    />
    @if (label) {
      <div class="naris-loader__label">{{ label }}</div>
    }
  }
  @if (timeoutPassed) {
    <div class="mb-4">{{'loader.waiting' | translate}}</div>
    <naris-bouncer />
  }
</div>
