import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { IFormAnchorElement, IFormAnchorElementDynamic } from '@core/models';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../../elements/icon/icon.component';
import { SlidetoggleComponent } from '../../../elements/slidetoggle/slidetoggle.component';

@Component({
  selector: 'naris-json-compare-select-notification',
  templateUrl: './json-compare-select-notification.component.html',
  styleUrls: ['./json-compare-select-notification.component.scss'],
  standalone: true,
  imports: [IconComponent, SlidetoggleComponent, TranslateModule]
})
export class JsonCompareSelectNotificationComponent {

  public helpText?: string;
  public items: IFormAnchorElementDynamic[] = [];
  public columnNames: string[] = [];

  @Input() public selectedItems: string[];
  @Output() public readonly selectedItemsChange = new EventEmitter<string[]>();

  @Input() set data(value: IFormAnchorElement[]) {
    this.items = [];
    const helptextItem = value.find(item => item.elementid.toLowerCase() === 'helptext');
    const caseIdsItem = value.find(item => ['caseids', 'changetypeid'].includes(item.elementid.toLowerCase()));
    this.setHelpText(helptextItem);
    this.setCaseItems(caseIdsItem);
  }

  private setHelpText(helptextItem?: IFormAnchorElement) {
    this.helpText = helptextItem?.value;
  }

  private setCaseItems(_caseIdsItems?: IFormAnchorElement) {
    if (!!_caseIdsItems?.dynamicschema) {
      const applicableItems = _caseIdsItems?.dynamicschema?.filter(item => item.elements.Applicable === '1');
      const applicableNullItems = _caseIdsItems?.dynamicschema?.filter(item => (item.elements.Applicable as string)?.toLowerCase() === 'null');
      const notApplicableItems = _caseIdsItems?.dynamicschema?.filter(item => item.elements.Applicable === '0');
      this.items.push(...applicableItems);
      this.items.push(...applicableNullItems);
      this.items.push(...notApplicableItems);
      if (!!this.items?.length) this.columnNames = Object.keys(this.items[0]?.elements);
    }
  }

  public updateSelectedItems(event: MatSlideToggleChange, code: string) {
    if (!this.selectedItems) this.selectedItems = [];
    if (event.checked) this.selectedItems.push(code);
    else {
      const codeIndex = this.selectedItems.findIndex(item => item === code);
      this.selectedItems.splice(codeIndex, 1);
    }
    this.selectedItemsChange.emit(this.selectedItems);
  }
}
