{
  "name": "naris4-ng-frontend",
  "version": "4.22.2",
  "biVersion": "20.0.0",
  "beinformedBackendImage": "20200323.5",
  "scripts": {
    "ng": "ng",
    "start": "ng serve -o",
    "start:ssl": "ng serve -o --ssl",
    "start:docker": "ng serve --host 0.0.0.0 --hmr --port $PORT_INTERNAL",
    "start:docker:ssl": "ng serve --host 0.0.0.0 --hmr --port $PORT_INTERNAL --ssl",
    "build": "ng build",
    "build:prod": "ng build --configuration production",
    "build:dev": "ng build --configuration develop",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "compodoc": "npx compodoc -p tsconfig.app.json",
    "prepare": "husky install",
    "build:stats": "ng build --stats-json",
    "analyze": "webpack-bundle-analyzer dist/naris4-ng-frontend/stats.json"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.2",
    "@angular/cdk": "^18.2.2",
    "@angular/common": "^18.2.2",
    "@angular/compiler": "^18.2.2",
    "@angular/core": "^18.2.2",
    "@angular/forms": "^18.2.2",
    "@angular/material": "^18.2.2",
    "@angular/material-luxon-adapter": "^18.2.2",
    "@angular/platform-browser": "^18.2.2",
    "@angular/platform-browser-dynamic": "^18.2.2",
    "@angular/router": "^18.2.2",
    "@angular/service-worker": "^18.2.2",
    "@apollo/client": "^3.9.2",
    "@jsplumbtoolkit/browser-ui": "6.71",
    "@jsplumbtoolkit/browser-ui-angular": "6.71",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@typescript-eslint/utils": "^8.3.0",
    "angular-gridster2": "^18.0.1",
    "apollo-angular": "^7.1.2",
    "byte-size": "^8.1.1",
    "chart.js": "^4.4.1",
    "chartjs-adapter-luxon": "^1.3.1",
    "chartjs-plugin-datalabels": "^2.2.0",
    "graphql": "^16.8.1",
    "hark": "^1.2.3",
    "html2canvas": "^1.4.1",
    "luxon": "^3.4.4",
    "minimist": "^1.2.8",
    "moddle": "^6.2.3",
    "ng2-charts": "^5.0.4",
    "ngx-editor": "^17.5.1",
    "ngx-socket-io": "^4.7.0",
    "prosemirror-model": "^1.21.0",
    "prosemirror-state": "^1.4.3",
    "prosemirror-view": "^1.33.6",
    "rxjs": "^7.8.1",
    "safevalues": "^0.5.2",
    "sanitize-html": "^2.11.0",
    "sass": "^1.70.0",
    "socket.io": "^4.7.4",
    "theme": "^0.1.0",
    "tslib": "^2.6.2",
    "yargs-parser": "^21.1.1",
    "zone.js": "^0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.2",
    "@angular-eslint/builder": "^18.2.0",
    "@angular-eslint/eslint-plugin": "^18.2.0",
    "@angular-eslint/eslint-plugin-template": "^18.2.0",
    "@angular-eslint/schematics": "^18.2.0",
    "@angular-eslint/template-parser": "^18.2.0",
    "@angular/cli": "^18.2.2",
    "@angular/compiler-cli": "^18.2.2",
    "@angular/language-service": "^18.2.2",
    "@commitlint/cli": "^18.6.0",
    "@commitlint/config-angular": "^18.6.0",
    "@compodoc/compodoc": "^1.1.23",
    "@stylistic/eslint-plugin": "^2.7.2",
    "@stylistic/eslint-plugin-ts": "^2.7.2",
    "@types/byte-size": "^8.1.2",
    "@types/hark": "^1.2.5",
    "@types/jasmine": "^5.1.4",
    "@types/jasminewd2": "~2.0.13",
    "@types/luxon": "^3.4.2",
    "@types/node": "^20.11.16",
    "@types/sanitize-html": "^2.11.0",
    "@typescript-eslint/eslint-plugin": "^8.3.0",
    "@typescript-eslint/parser": "^8.3.0",
    "eslint": "^8.56.0",
    "eslint-import-resolver-typescript": "^3.6.1",
    "eslint-plugin-import": "^2.29.1",
    "husky": "^9.0.10",
    "jasmine-core": "^5.1.1",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage-istanbul-reporter": "~3.0.3",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "ngx-json-viewer": "^3.2.1",
    "orderedmap": "^2.1.1",
    "postcss": "^8.4.34",
    "stylelint": "^16.2.1",
    "stylelint-config-sass-guidelines": "^11.0.0",
    "stylelint-config-standard": "^36.0.0",
    "stylelint-order": "^6.0.4",
    "stylelint-selector-bem-pattern": "^3.0.1",
    "ts-node": "~10.9.2",
    "typescript": "~5.5.4",
    "webpack-bundle-analyzer": "^4.10.1"
  },
  "husky": {
    "hooks": {
      "commit-msg": "commitlint -E HUSKY_GIT_PARAMS"
    }
  },
  "release": {
    "branches": [
      "shadow-dev",
      "develop",
      {
        "name": "hotfix",
        "prerelease": true
      }
    ],
    "plugins": [
      [
        "@semantic-release/commit-analyzer",
        {
          "releaseRules": [
            {
              "type": "style",
              "release": "patch"
            },
            {
              "type": "refactor",
              "release": "patch"
            },
            {
              "type": "chore",
              "release": false
            },
            {
              "scope": "no-release",
              "release": false
            }
          ]
        }
      ],
      "@semantic-release/release-notes-generator",
      [
        "@semantic-release/changelog",
        {
          "changeLogFile": "CHANGELOG.md"
        }
      ],
      "semantic-release-ado",
      "@semantic-release/npm",
      [
        "@semantic-release/git",
        {
          "assets": [
            "CHANGELOG.md",
            "package.json",
            "package-lock.json"
          ],
          "message": "chore(release): ${nextRelease.version} [skip ci]\n\n${nextRelease.notes}"
        }
      ]
    ],
    "preset": "angular"
  }
}
