var n = {};
var r = {
  get: function r(e) {
    var o = n[e];
    if (!o) {
      throw {
        message: "jsPlumbToolkit: unknown background [".concat(e, "]")
      };
    } else {
      return o;
    }
  },
  register: function r(e, o) {
    n[e] = o;
  }
};
export { r as AvailableBackgrounds };