import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, Inject, HostBinding, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { AuthService, BeinformedService, CollabService, DialogService, NarisBreadcrumbService, TaskgroupService } from '@core/services';
import { ACTION_CONFIG, COMPARE_TOKEN, ACTIONS } from '@core/constants';
import { FooterToolbarService } from '@core/services/footer-toolbar.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AssessmentService } from '@core/services/assessment.service';
import { MatTooltip } from '@angular/material/tooltip';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../elements/button/button.component';
import { MenuComponent } from '../menu/menu.component';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';
import type { JSONCompareComponent } from '@shared/components/json-compare/json-compare.component';
import type { IAction, ITaskGroup } from '@core/models';

@Component({
  selector: 'naris-taskgroup',
  templateUrl: './taskgroup.component.html',
  styleUrls: ['./taskgroup.component.scss'],
  standalone: true,
  imports: [ButtonComponent, MatTooltip, MatMenuTrigger, MatMenu, MenuComponent, MenuItemComponent, TranslateModule]
})
export class TaskgroupComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public taskgroup: ITaskGroup;
  @Input() public showLabel = true;
  @Input() public buttonSize = 'medium';
  @Input() public buttonClass: string;
  @Input() public itemLimit: number;
  @Input() public collapseAfter: number;
  @Input() public fullwidthButtons = false;
  @Input() public hasDescription = false;
  @Input() public showIcons = true;

  public buttons: IAction[] | undefined;
  public menu: IAction[] | undefined;
  public loading: boolean;

  private readonly subs: Subscription[] = [];

  @Output() public readonly postCallback = new EventEmitter<boolean>();
  @Output() public readonly formCallback = new EventEmitter<boolean>();

  @HostBinding('class')
  get rootClasses() {
    const classes: string[] = [];
    if (this.hasDescription) classes.push('taskgroup-description');
    return classes;
  }

  constructor(
    private readonly beinformedService: BeinformedService,
    private readonly dialog: DialogService,
    private readonly authService: AuthService,
    private readonly taskgroupService: TaskgroupService,
    @Inject(COMPARE_TOKEN) private readonly compareComponent: ComponentType<JSONCompareComponent>,
    private readonly popUpDialog: MatDialog,
    private readonly breadcrumb: NarisBreadcrumbService,
    private readonly footerToolbarService: FooterToolbarService,
    private readonly router: Router,
    private readonly assessmentService: AssessmentService,
    private readonly collabService: CollabService
  ) { }

  public ngOnChanges(changes: SimpleChanges) {
    if (!!changes.taskgroup) this.setActions();
    this.subs.push(
      this.footerToolbarService.updateTaskGroup$.subscribe(_ => this.setActions())
    );
  }

  public ngOnInit() {
    this.setActions();
    this.startJSONCompare(this.taskgroup.actions);
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public buttonColor(action: IAction) {
    const actionName = action.name as keyof Omit<typeof ACTION_CONFIG, 'ADD_NEW' | 'CANCEL' | 'CONTINUE' | 'DEFAULT' | 'DELETE_SOME' | 'DELETE' | 'OPEN' | 'STOP'>;
    if (ACTION_CONFIG[actionName]?.color) return ACTION_CONFIG[actionName].color;
    else if ((action.name?.toLowerCase() || '').includes('in-wizard') && (action.name?.toLowerCase() || '').includes('create')) return 'primary';
    else if (/review|finish|complete|create/.test(action.name?.toLowerCase() || '')) return 'success';
    else if ((action.name?.toLowerCase() || '').includes('update') || (action.name?.toLowerCase() || '').includes('score') || (action.name?.toLowerCase() || '').includes('enable-questionnaire')) return 'primary';
    else return 'dark';
  }

  public buttonIcon(action: IAction) {
    if (!this.showIcons) return '';
    return action.icon ?? ACTIONS[action.name as keyof typeof ACTIONS]?.icon;
  }

  public setActions() {
    const filteredItems = ['hide', 'auto-json-compare', 'update', 'disable', 'enable'];
    const actions = this.taskgroup.actions
      // Remove items without href if disabled items should be hidden and hide items with certain layouthints
      ?.filter((a: IAction) => a.href && filteredItems.every(item => !a.layouthint?.includes(item)))
      // Add optional action config to each action item
      .map((a: IAction) => {
        const actionName = a.name as keyof typeof ACTION_CONFIG;
        const aMeta = ACTION_CONFIG[actionName] || {};
        return { ...a, ...aMeta };
      }) || [];
    if (this.itemLimit) {
      if ((actions?.length || -1) > this.itemLimit) this.menu = actions;
      else this.buttons = actions;
    } else if (this.collapseAfter) {
      this.buttons = actions?.slice(0, this.collapseAfter);
      this.menu = actions?.slice(this.collapseAfter);
    } else this.buttons = actions;
  }

  public onActionClicked(action: IAction) {
    const isAssessment = this.router.url.includes('-assessment') && !this.router.url.includes('compliance-assessment');
    if (action.name === 'request-password-reset') {
      this.dialog.open({
        type: 'alert',
        title: 'taskgroup.change_pw.title',
        text: 'taskgroup.change_pw.text',
        confirmLabel: 'continue',
        cancelLabel: 'cancel'
      }).subscribe((res: boolean) => {
        if (res) this.authService.resetPWRequest();
      });
    } else if (action.name === 'start-live-session') {
      this.loading = true;
      this.beinformedService.startLiveSession(action.href!)?.subscribe({complete: () => setTimeout(() => this.loading = false, 1000)});
    } else if (action.name === 'join-live-session') void this.router.navigate([`/collaboration/collaboration/${this.router.url.split('/')[3]}/session`]);
    else if (action.name === 'save-answers') {
      this.collabService.sendAnswers$.next(action.href!);
    } else if (['push-updates', 'accept-updates'].includes(action.name?.toLowerCase() || '')) {
      this.popUpDialog.open(this.compareComponent, {
        panelClass: 'naris-json-compare-dialog',
        data: { action }
      }).afterClosed().subscribe((saved: boolean) => {
        if (saved) this.footerToolbarService.refreshView$.next(true);
      });
    } else if (isAssessment && (action.name === 'save' || action.name === 'save-next')) {
      this.assessmentService.saveForm.next(action.name === 'save-next');
    } else if (action.name?.startsWith('delete-') && action.layouthint?.includes('confirm')) {
      if (!!action?.href)
        this.beinformedService.fetchForm(action?.href).subscribe(res => {
          const tokens = res.data.error.formresponse.tokens;
          const keys = Object.keys(res.contributions.objects);
          let text = '';
          keys.forEach(key => {
            const object = res.contributions.objects?.[key];
            if (object.label.toLowerCase() === 'info') {
              const objName = Object.keys(object.attributes[0])?.[0];
              const attrObject = object.attributes[0][objName];
              if (!!attrObject && attrObject.label.toLowerCase() === 'info') {
                text = (attrObject.text as any)?.message;
              }
            }
          });
          this.dialog.open({
            type: 'alert',
            title: res.contributions.label,
            text: text,
            confirmLabel: 'remove',
            cancelLabel: 'cancel'
          }).subscribe((dialogRes: boolean) => {
            if (dialogRes && !!res.data.error.formresponse.missing) {
              const payload = { tokens, objects: { [res.data.error.formresponse.missing.anchors[0].objectid]: {} } };
              this.beinformedService.fetchForm(action.href!, true, payload).subscribe(() => this.emitCallbackAndRefresh(action, !!close));
            }
          });
        });
    } else {
      const formCallback = (reload?: boolean, close?: boolean) => {
        this.footerToolbarService.refreshView$.next(true);
        return reload ? this.emitCallbackAndRefresh(action, !!close) : null;
      };
      const postCallback = (reload?: boolean, close?: boolean) => {
        this.footerToolbarService.refreshView$.next(true);
        return reload ? this.postCallback.emit(close) : null;
      };
      if (action.name?.toLowerCase() === 'complete') {
        this.breadcrumb.addBreadcrumb = false;
      }
      void this.beinformedService.handleAction(action, formCallback, postCallback, true);
    }
  }

  private emitCallbackAndRefresh(action: IAction, close: boolean) {
    this.formCallback.emit(close);
    if (!!action.layouthint?.includes('refresh')) this.taskgroupService.refreshPage.next(true);
  }

  private startJSONCompare(actions: IAction[] = []) {
    const action = actions?.find(item => item.layouthint?.includes('auto-json-compare'));
    if (!!action) {
      this.popUpDialog.open(this.compareComponent, {
        panelClass: 'naris-json-compare-dialog',
        data: { action },
        disableClose: true
      });
    }
  }
}
