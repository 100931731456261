import { HttpService } from '@core/services';
import { decodeRich } from '@core/helpers';
import type { IAction, ICaseListRow } from '@core/models';

export default class Changelog {

  public version: string;
  public versionId: number;
  public content?: string;
  public date: Date;
  public read: boolean;
  public actions: IAction[];

  /* eslint-disable @typescript-eslint/naming-convention*/
  constructor({ _id, actions, Version, VersionContent, VersionDate, VersionRead }: ICaseListRow, private readonly httpService: HttpService) {
    this.version = Version;
    this.versionId = _id;
    this.content = decodeRich(VersionContent);
    this.date = new Date(VersionDate);
    this.read = VersionRead;
    this.actions = actions;
  }

  public markAsRead() {
    if (!this.read) {
      const { href } = this.actions?.find(({ name }) => name === 'read') || {};
      if (!!href) this.httpService.post(href).subscribe();
      this.read = true;
    }
  }
}
