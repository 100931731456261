import { inherits as t, createSuper as i, classCallCheck as e, defineProperty as s, assertThisInitialized as o, createClass as n } from "../_virtual/_rollupPluginBabelHelpers.js";
import { PX as h, _makeHandle as r, EDGE_DRAG_HANDLE as a, CLASS_CONNECTION_EDIT as p, EditorBase as l } from "../connector-editors/editor-base.js";
import { CONNECTOR_TYPE_CUBIC_BEZIER as c } from "../ui-core/connector-bezier/bezier-connector.js";
import { extend as d } from "../ui-core/util/util.js";
import { lineLength as u, perpendicularLineTo as y, quadrant as g } from "../ui-core/util/geom.js";
import { BLOCK as m, NONE as x } from "../ui-core/core/constants.js";
import { LEFT as f, TOP as v, RIGHT as _, BOTTOM as j } from "../ui-core/core/factory/definitions.js";
import { CONNECTOR_TYPE_QUADRATIC_BEZIER as C } from "../ui-core/connector-bezier/statemachine-connector.js";
import { CLASS_EDGE_GUIDELINE as b } from "../connector-editors-segmented/segmented-editor.js";
import { getElementPosition as P, getBoundingClientRectElementSize as k } from "../ui-core/browser-ui-renderer/positioning-and-sizing-utils.js";
import { exportBezierGeometry as z } from "../ui-core/connector-bezier/operations.js";
import { Connectors as M } from "../ui-core/core/connector/connectors.js";
import { Connections as H } from "../ui-core/core/connector/connections.js";
var G = "jtk-bezier-handle";
var E = "jtk-bezier-handle-control-point";
var S = "jtk-bezier-handle-control-point-1";
var O = "jtk-bezier-handle-control-point-2";
var w = "jtk-bezier-handle-secondary";
var T = "jtk-bezier-handle-secondary-source";
var I = "jtk-bezier-handle-secondary-target";
var Q = "jtk-bezier-guideline";
var D = "dual";
var W = "single";
var F = function (l) {
  t(C, l);
  var c = i(C);
  function C(t, i) {
    var n;
    e(this, C);
    n = c.call(this, t, i);
    s(o(n), "mode", void 0);
    s(o(n), "center", void 0);
    s(o(n), "cp", void 0);
    s(o(n), "cp1", {
      x: 0,
      y: 0
    });
    s(o(n), "cp2", {
      x: 0,
      y: 0
    });
    s(o(n), "originalCp1", {
      x: 0,
      y: 0
    });
    s(o(n), "originalCp2", {
      x: 0,
      y: 0
    });
    s(o(n), "origin", void 0);
    s(o(n), "flipY", void 0);
    s(o(n), "sp", void 0);
    s(o(n), "tp", void 0);
    s(o(n), "sourceMidpoints", void 0);
    s(o(n), "targetMidpoints", void 0);
    s(o(n), "sourceFace", void 0);
    s(o(n), "targetFace", void 0);
    s(o(n), "sourceCenter", void 0);
    s(o(n), "targetCenter", void 0);
    s(o(n), "sourceEdgeSupported", void 0);
    s(o(n), "targetEdgeSupported", void 0);
    s(o(n), "noEdits", void 0);
    s(o(n), "nodeQuadrant", void 0);
    s(o(n), "h1", void 0);
    s(o(n), "h2", void 0);
    s(o(n), "h3", void 0);
    s(o(n), "h4", void 0);
    s(o(n), "l1", void 0);
    s(o(n), "l2", void 0);
    s(o(n), "lockHandles", void 0);
    s(o(n), "h1Size", void 0);
    s(o(n), "h2Size", void 0);
    s(o(n), "h3Size", void 0);
    s(o(n), "h4Size", void 0);
    s(o(n), "geometry", void 0);
    n.flipY = false;
    n.noEdits = true;
    n._addDragHandler({
      selector: "." + G,
      drag: function t(i) {
        if (n.noEdits) {
          n._setGeometry();
          n.noEdits = false;
        }
        var e = i.drag.getDragElement();
        var s = e._jsPlumbControlPoint,
          o = e._jsPlumbOriginalControlPoint;
        var r = i.pos.x - i.originalPos.x,
          a = i.pos.y - i.originalPos.y;
        i.pos.x - n.origin.x;
        i.pos.y - n.origin.y;
        if (!n.lockHandles) {
          s.x = o.x + r;
          s.y = o.y + a;
        } else {
          if (n.mode === D) {
            var p = u(n.center, i.pos);
            var l = y(n.center, i.pos, p * 1.5);
            var c = Math.min(l[0].y, l[1].y),
              d = Math.min(l[0].x, l[1].x);
            var g = Math.max(l[0].y, l[1].y),
              m = Math.max(l[0].x, l[1].x);
            l = [null, [{
              x: m,
              y: c
            }, {
              x: d,
              y: g
            }], [{
              x: m,
              y: g
            }, {
              x: d,
              y: c
            }], [{
              x: d,
              y: g
            }, {
              x: m,
              y: c
            }], [{
              x: d,
              y: c
            }, {
              x: m,
              y: g
            }]][n.nodeQuadrant];
            n.cp1.x = l[0].x - n.origin.x;
            n.cp1.y = l[0].y - n.origin.y;
            n.cp2.x = l[1].x - n.origin.x;
            n.cp2.y = l[1].y - n.origin.y;
            n.h3.style.left = n.origin.x + n.cp1.x + h;
            n.h3.style.top = n.origin.y + n.cp1.y + h;
            n.h4.style.left = n.origin.x + n.cp2.x + h;
            n.h4.style.top = n.origin.y + n.cp2.y + h;
          } else {
            n.cp1.x = n.originalCp1.x + r;
            n.cp1.y = n.originalCp1.y + a;
            n.cp2.x = n.cp1.x;
            n.cp2.y = n.cp1.y;
          }
        }
        n._updateQuadrants();
        n._setGeometry();
        n._updateGuidelines();
      },
      stop: function t(i) {
        n.originalCp1.x = n.cp1.x;
        n.originalCp1.y = n.cp1.y;
        n.originalCp2.x = n.cp2.x;
        n.originalCp2.y = n.cp2.y;
        if (!n.noEdits) {
          var e = i.drag.getDragElement();
          e._jsPlumbOriginalControlPoint.x = e._jsPlumbControlPoint.x;
          e._jsPlumbOriginalControlPoint.y = e._jsPlumbControlPoint.y;
          n.fireConnectionEditEvent();
        }
        n.noEdits = true;
      }
    });
    return n;
  }
  n(C, [{
    key: "getPosition",
    value: function t(i) {
      return P(i, this.jsplumb);
    }
  }, {
    key: "getSize",
    value: function t(i) {
      return k(i, this.jsplumb.currentZoom);
    }
  }, {
    key: "_updateOrigin",
    value: function t() {
      this.sp = this.getPosition(this.current.endpoints[0].endpoint.canvas);
      this.tp = this.getPosition(this.current.endpoints[1].endpoint.canvas);
      this.origin = {
        x: Math.min(this.sp.x, this.tp.x),
        y: Math.min(this.sp.y, this.tp.y)
      };
      this.center = {
        x: (this.sp.x + this.tp.x) / 2,
        y: (this.sp.y + this.tp.y) / 2
      };
      this.nodeQuadrant = g({
        x: this.sp.x,
        y: this.sp.y
      }, {
        x: this.tp.x,
        y: this.tp.y
      });
    }
  }, {
    key: "_updateConnectorInfo",
    value: function t() {
      this._updateOrigin();
      this.geometry = z(this.currentConnector);
      if (this.geometry && this.geometry.controlPoints) {
        this.cp = this.geometry.controlPoints;
        this.cp1.x = this.geometry.controlPoints[0].x;
        this.cp1.y = this.geometry.controlPoints[0].y;
        this.cp2.x = this.geometry.controlPoints[1].x;
        this.cp2.y = this.geometry.controlPoints[1].y;
        this.originalCp1 = Object.assign({}, this.cp1);
        this.originalCp2 = Object.assign({}, this.cp2);
      }
    }
  }, {
    key: "_updateQuadrants",
    value: function t() {
      var i = {
          x: this.origin.x + this.cp2.x,
          y: this.origin.y + this.cp2.y
        },
        e = {
          x: this.origin.x + this.cp1.x,
          y: this.origin.y + this.cp1.y
        };
      this.sourceMidpoints.sort(function (t, e) {
        return u(t, i) < u(e, i) ? -1 : 1;
      });
      this.sourceFace = this.sourceMidpoints[0][2];
      this.targetMidpoints.sort(function (t, i) {
        return u(t, e) < u(i, e) ? -1 : 1;
      });
      this.targetFace = this.targetMidpoints[0][2];
    }
  }, {
    key: "_updateHandlePositions",
    value: function t() {
      if (this.mode === D) {
        this.h1.style.left = this.origin.x + (this.cp1.x + this.cp2.x) / 2 + h;
        this.h1.style.top = this.origin.y + (this.cp1.y + this.cp2.y) / 2 + h;
        this.h3.style.left = this.origin.x + this.cp1.x + h;
        this.h3.style.top = this.origin.y + this.cp1.y + h;
        this.h4.style.left = this.origin.x + this.cp2.x + h;
        this.h4.style.top = this.origin.y + this.cp2.y + h;
      } else {
        this.h1.style.left = this.origin.x + this.cp1.x + h;
        this.h1.style.top = this.origin.y + this.cp1.y + h;
        var i = this.lockHandles ? this.cp1 : this.cp2;
        this.h2.style.left = this.origin.x + i.x + h;
        this.h2.style.top = this.origin.y + i.y + h;
      }
      this._updateQuadrants();
    }
  }, {
    key: "_setGeometry",
    value: function t() {
      var i = {
        controlPoints: [this.cp1, this.cp2],
        source: d({}, this.geometry.source),
        target: d({}, this.geometry.target)
      };
      M.setGeometry(this.current.connector, i, false);
      this.jsplumb.revalidate(this.current.endpoints[0].element);
      if (this.current.endpoints[0].elementId !== this.current.endpoints[1].elementId) {
        this.jsplumb.revalidate(this.current.endpoints[1].element);
      }
    }
  }, {
    key: "_updateGuidelines",
    value: function t() {
      this._updateGuideline(this.h1, this.tp, this.l1, this.origin.x + this.cp1.x, this.origin.y + this.cp1.y);
      var i = this.lockHandles ? this.cp1 : this.cp2;
      this._updateGuideline(this.lockHandles ? this.h1 : this.h2, this.sp, this.l2, this.origin.x + i.x, this.origin.y + i.y);
    }
  }, {
    key: "_makeGuideline",
    value: function t(i, e, s, o) {
      var n = Math.abs(s - e.x),
        h = Math.abs(o - e.y),
        r = this.surface.jsplumb.svg.node("svg", {
          width: n,
          height: h
        }),
        a = this.surface.jsplumb.svg.node("path", {
          d: "M " + 0 + " " + 0 + " L " + n + " " + h
        });
      r.appendChild(a);
      this.surface.jsplumb.addClass(r, Q);
      this.surface.jsplumb.addClass(r, b);
      this._updateGuideline(i, e, r, s, o);
      return r;
    }
  }, {
    key: "_updateGuideline",
    value: function t(i, e, s, o, n) {
      o = o + i.offsetWidth / 2;
      n = n + i.offsetHeight / 2;
      var r = Math.max(5, Math.abs(o - e.x)),
        a = Math.max(5, Math.abs(n - e.y));
      this.surface.jsplumb.svg.attr(s, {
        width: r,
        height: a
      });
      s.style.left = Math.min(e.x, o) + h;
      s.style.top = Math.min(e.y, n) + h;
      var p = "M " + (o > e.x ? r : "0") + " " + (n > e.y ? a : "0") + " L " + (o > e.x ? "0" : r) + " " + (n > e.y ? "0" : a);
      this.surface.jsplumb.svg.attr(s.childNodes[0], {
        d: p
      });
    }
  }, {
    key: "_activate",
    value: function t(i, e) {
      this.cp1 = {
        x: 0,
        y: 0
      };
      this.cp2 = {
        x: 0,
        y: 0
      };
      this.cp = [this.cp1, this.cp2];
      e = e || {};
      this.mode = e.mode || W;
      this._updateConnectorInfo();
      this.h1 = r(this.sp.x + this.cp[0].x, this.sp.y + this.cp[0].y, [a, G, E, S].join(" "));
      this.h2 = r(this.sp.x + this.cp[1].x, this.sp.y + this.cp[1].y, [a, G, E, O].join(" "));
      this.h1._jsPlumbControlPoint = this.cp1;
      this.h2._jsPlumbControlPoint = this.cp2;
      this.h1._jsPlumbOriginalControlPoint = Object.assign({}, this.cp1);
      this.h2._jsPlumbOriginalControlPoint = Object.assign({}, this.cp2);
      this.h3 = r(this.origin.x + this.cp[0].x, this.origin.y + this.cp[0].y, [a, w, T].join(" "));
      this.h4 = r(this.origin.x + this.cp[0].x, this.origin.y + this.cp[0].y, [a, w, I].join(" "));
      if (this.mode === D) {
        this.h3.style.display = m;
        this.h4.style.display = m;
        this.jsplumb._appendElementToContainer(this.h3);
        this.jsplumb._appendElementToContainer(this.h4);
        this.flipY = this.tp.y < this.sp.y;
      }
      this.jsplumb._appendElementToContainer(this.h1);
      this.jsplumb._appendElementToContainer(this.h2);
      this.h1Size = {
        w: this.h1.offsetWidth,
        h: this.h1.offsetHeight
      };
      this.h1.style.display = m;
      if (!this.lockHandles) {
        this.h2.style.display = m;
        this.h2Size = {
          w: this.h2.offsetWidth,
          h: this.h2.offsetHeight
        };
      }
      if (this.mode === D) {
        this.h3.style.display = m;
        this.h4.style.display = m;
        this.h3Size = {
          w: this.h3.offsetWidth,
          h: this.h3.offsetHeight
        };
        this.h4Size = {
          w: this.h4.offsetWidth,
          h: this.h4.offsetHeight
        };
      }
      this.l1 = this._makeGuideline(this.h1, this.tp, this.origin.x + this.cp[0].x, this.origin.y + this.cp[0].y);
      this.l2 = this._makeGuideline(this.lockHandles ? this.h1 : this.h2, this.sp, this.origin.x + this.cp[1].x, this.origin.y + this.cp[1].y);
      this.jsplumb._appendElementToContainer(this.l1);
      this.jsplumb._appendElementToContainer(this.l2);
      var s = this.getSize(this.current.source),
        o = this.getPosition(this.current.source),
        n = this.getSize(this.current.target),
        h = this.getPosition(this.current.target);
      this.sourceCenter = {
        x: o.x + s.w / 2,
        y: o.y + s.h / 2
      };
      this.targetCenter = {
        x: h.x + n.w / 2,
        y: h.y + n.h / 2
      };
      this.sourceMidpoints = [[o.x, this.sourceCenter.y, f], [this.sourceCenter.x, o.y, v], [o.x + s.w, this.sourceCenter.y, _], [this.sourceCenter.x, o.y + s.h, j]];
      this.targetMidpoints = [[h.x, this.targetCenter.y, f], [this.targetCenter.x, h.y, v], [h.x + n.w, this.targetCenter.y, _], [this.targetCenter.x, h.y + n.h, j]];
      this._updateHandlePositions();
      var l = e.guidelines !== false;
      this.l1.style.display = l ? m : x;
      this.l2.style.display = l ? m : x;
      this.sp = this.getPosition(this.current.endpoints[0].endpoint.canvas);
      this.tp = this.getPosition(this.current.endpoints[1].endpoint.canvas);
      this._updateGuidelines();
      H.addClass(this.current, p);
      this._setGeometry();
    }
  }, {
    key: "_elementDragged",
    value: function t(i) {
      this._updateOrigin();
      this._updateHandlePositions();
      this._updateGuidelines();
    }
  }, {
    key: "_elementDragging",
    value: function t(i) {
      this.sp = this.getPosition(this.current.endpoints[0].endpoint.canvas);
      this.tp = this.getPosition(this.current.endpoints[1].endpoint.canvas);
      this._updateGuidelines();
      this._repaint();
    }
  }, {
    key: "_clearHandles",
    value: function t() {
      (function (t) {
        for (var i = 0; i < t.length; i++) {
          if (t[i] != null && t[i].parentNode) {
            t[i].parentNode.removeChild(t[i]);
          }
        }
      })([this.h1, this.h2, this.h3, this.h4, this.l1, this.l2]);
    }
  }, {
    key: "_repaint",
    value: function t(i) {
      this._update();
    }
  }, {
    key: "_update",
    value: function t() {
      this._updateOrigin();
      this._updateConnectorInfo();
      this._updateHandlePositions();
      this._updateGuidelines();
    }
  }, {
    key: "exportGeometry",
    value: function t() {
      if (this.geometry == null) {
        return null;
      } else {
        return {
          controlPoints: [d({}, this.geometry.controlPoints[0]), d({}, this.geometry.controlPoints[1])],
          source: d({}, this.geometry.source),
          target: d({}, this.geometry.target)
        };
      }
    }
  }]);
  return C;
}(l);
s(F, "type", c);
var N = function (s) {
  t(n, s);
  var o = i(n);
  function n(t, i) {
    var s;
    e(this, n);
    s = o.call(this, t, i);
    s.lockHandles = true;
    return s;
  }
  return n;
}(F);
s(N, "type", C);
export { F as BezierEditor, G as CLASS_BEZIER_HANDLE, D as DUAL, W as SINGLE, N as StateMachineEditor };