import {
  Component,
  ViewEncapsulation,
  HostBinding,
  ViewChild,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  AfterViewInit,
  booleanAttribute
} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { FormControl, FormsModule } from '@angular/forms';
import { Subject, map } from 'rxjs';
import { Editor, type Toolbar, NgxEditorModule } from 'ngx-editor';
import { schema as baseSchema, marks } from 'ngx-editor/schema';
import { Schema } from 'prosemirror-model';
import { FileService, HttpService } from '@core/services';
import { DOC_URL } from '@core/constants';
import { decodeRich, toBase64, addDocNodes, getDocLinksPlugin, getExtension } from '@core/helpers';
import { MatAutocomplete, MatAutocompleteTrigger, MatAutocompleteOrigin } from '@angular/material/autocomplete';
import { NgClass } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../elements/icon/icon.component';
import { FileIconPipe } from '../../pipes/file-icon.pipe';
import type { IDocOption, TCaseListResponse } from '@core/models';

@Component({
  selector: 'naris-ngx-text-editor',
  templateUrl: './ngx-text-editor.component.html',
  styleUrls: ['./ngx-text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass, NgxEditorModule, FormsModule, MatAutocompleteOrigin, MatAutocompleteTrigger, MatAutocomplete, MatOption, IconComponent, FileIconPipe, TranslateModule]
})
export class NgxTextEditorComponent implements OnInit, AfterViewInit, OnDestroy {

  public editor: Editor;
  public html = '';
  public toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    // ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    // ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify']
    // ['horizontal_rule', 'format_clear']
  ];
  public colorPresets = [
    '#6b8299', '#00c7d9', '#e62e3d', '#f27a24', '#f2ca00', '#39bf7c', '#407fff', '#9973e6',
    '#cfdae6', '#e8fbfc', '#ffe6e8', '#ffe9d9', '#fff7cc', '#daf2e6', '#e6eeff', '#eee6ff'
  ];
  public docs: IDocOption[];
  public selectedDoc$: Subject<IDocOption>;

  @Input()
  public control: FormControl;

  @Input()
  public mandatory: boolean | undefined;

  @Input()
  public id: string;

  @Input({transform: booleanAttribute})
  public docEnabled = false;

  @ViewChild('editor')
  public editorElement: ElementRef;

  @ViewChild('trigger')
  public docTrigger: MatAutocompleteTrigger;

  @ViewChild('docAuto')
  public docAuto: MatAutocomplete;

  @HostBinding('class')
  public rootClass = 'naris-text-editor';

  constructor(private readonly httpService: HttpService, private readonly fileService: FileService) {}

  public ngOnInit() {
    if (this.docEnabled) {
      this.selectedDoc$ = new Subject();
      const docLinkPlugin = getDocLinksPlugin({
        allowSpace: false,
        getSuggestions: this.getSuggestions,
        hidePanel: () => this.docTrigger?.closePanel(),
        selected$: this.selectedDoc$,
        openDoclink: (hash, revision) => this.fileService.openDoclink(hash, revision)
      });
      const schema = new Schema({nodes: addDocNodes(baseSchema.spec.nodes), marks});
      this.editor = new Editor({schema, plugins: [docLinkPlugin]});
    } else this.editor = new Editor();
  }

  public ngAfterViewInit(): void {
    if (this.control.value) {
      setTimeout(() => {
        this.html = decodeRich(this.control.value) || '';
      });
    }
  }

  public onChange(html: string) {
    const encodedString = toBase64(html);
    this.control.setValue(encodedString);
  }

  public ngOnDestroy(): void {
    if (!!this.editor)
      this.editor.destroy();
  }

  private readonly getSuggestions = (text = '') => {
    const params = new HttpParams({fromObject: {DocumentName: text}});
    this.httpService.get<TCaseListResponse>(DOC_URL, true, true, {params})
      .pipe(map(this.mapDocuments))
      .subscribe(docs => {
        this.docs = docs;
        this.docTrigger.openPanel();
      })
    ;
  };

  private readonly mapDocuments = (docRes: TCaseListResponse) => docRes?.['Documents']?.['_embedded']?.['results']?.map<IDocOption>(
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ({ DocumentsWithHash: { DocumentName, DocumentHash, FileName, RevisionNumber } }) => ({
      name: DocumentName,
      hash: DocumentHash,
      revision: RevisionNumber,
      ext: getExtension(FileName)
    })
  ) || [];
}
