@if (!loading) {
  <div class="treeview" [class.edit-mode]="editMode">
    @if (items.length && showToolbar) {
      <div class="treeview-toolbar">
        <div class="mr-auto">
          <h4>{{title || 'treeview.select-structure' | translate}}</h4>
        </div>
        @if (showTreeActions) {
          <div>
            @for (treeviewAction of treeviewActions; track $index) {
              <naris-button icon="add" size="small" (click)="onClickAction(treeviewAction)" class="mr-2" [matTooltip]="treeviewAction.label || ''" [id]="'Treeview' + treeviewAction.name"/>
            }
          </div>
        }
        @if (isAssetClassification) {
          <naris-button
            color="primary"
            class="naris-table-toolbar-action"
            size="small"
            (click)="submit()"
            id="TreeviewSubmit"
          >
            {{'table.submit_selection' | translate}}
          </naris-button>
        }
        @if (isAssetClassification) {
          <naris-button
            color="danger"
            size="small"
            class="naris-table-toolbar-action"
            (click)="clearSelection()"
            id="TreeviewClearSelection"
          >
            {{'table.clear_selection' | translate}}
          </naris-button>
        }
        <div>
          <naris-button icon="keyboard_arrow_up" size="small" (click)="collapse()" class="mr-2" [matTooltip]="(enableLazyLoading ? 'treeview.collapse-one-level' : 'treeview.collapse-all') | translate" id="TreeviewCollapseOneAll"/>
          <naris-button icon="keyboard_arrow_down" size="small" (click)="expand()" [matTooltip]="(enableLazyLoading ? 'treeview.expand-one-level' : 'treeview.expand-all') | translate" id="TreeviewExpandOneAll"/>
        </div>
        @if (editMode) {
          <naris-taskgroup
            [taskgroup]="taskgroup"
            buttonSize="small"
            class="ml-2"
            buttonClass="mr-2"
            (formCallback)="reload()"
            (postCallback)="reload()"
          />
        }
      </div>
    }
    @if (!items.length && !loading) {
      <div class="empty-wrapper">
        @for (action of taskgroup.actions; track $index) {
          <div 
            tabindex="0"
            class="empty-wrapper__panel" 
            [class.active]="action.name === selectedEmptyAction"
            (click)="selectedEmptyAction = action.name"
          >
            <div class="empty-wrapper__panel__header" [class.disabled]="action.name !== selectedEmptyAction">
              <div>
                @if (taskgroup.actions!.length > 1) {
                  <naris-icon [icon]="action.name === selectedEmptyAction ? 'radio_button_checked' : 'radio_button_unchecked'" />
                }
                <h3>{{action.label}}</h3>
              </div>
              @if (action.name) {
                <div class="empty-wrapper__panel__header__desc">
                  {{getActionDescription(action.name!) | translate}}
                </div>
              }
            </div>
            @if (action.href) {
              <app-form
                [enabled]="action.name === selectedEmptyAction"
                [endpoint]="action.href"
                [isEmbedded]="true"
                [isStriped]="false"
                (closed)="reload()"
              />
            }
          </div>
        }
      </div>
    }
    <div
      cdkDropList
      dragAndDropManagerRoot
      [cdkDropListData]="items"
      (cdkDropListDropped)="onDragDrop$.next($event)"
    >
      @for (item of items; let i = $index; track i) {
        @if (draggedIndex === i) {
          <div class="item-origin"></div>
        }
        <naris-treeview-group
          [item]="item"
          [onDragDrop]="onDragDrop$"
          [prefix]="prefix"
          [editMode]="editMode"
          (updated)="reload()"
          (selected)="itemSelected($event)"
          [grouplessNodeButtons]="grouplessNodeButtons"
          [popupData]="popupData"
          [layouthint]="layouthint"
          [onlyRedirect]="onlyRedirect"
          [dragDropDisabled]="dragDropDisabled"
          (dragged)="draggedIndex = $event ? i : null;"
          [compareObject]="compareObject"
          class="root"
          [enableLazyLoading]="enableLazyLoading"
          [roomName]="roomName"
          [occupiedNodes]="occupiedNodes"
          (selectedUrl)="passSelectedUrl($event)"
          [isAssetClassification]="isAssetClassification"
          (refreshData$)="refreshData()"
        />
      }
      @if (loading) {
        &nbsp;
        <div class="treeview-loader">
          <mat-progress-bar mode="indeterminate" />
        </div>
      }
    </div>
  </div>
} @else {
  <naris-loader height="200px" />
}
