import { registerEndpointRenderer as r } from "./browser-jsplumb-instance.js";
import { _node as o, _attr as t } from "./svg-util.js";
import { DotEndpoint as e } from "../core/endpoint/dot-endpoint.js";
var i = "circle";
var n = function n() {
  r(e.type, {
    makeNode: function r(t, e) {
      return o(i, {
        cx: t.w / 2,
        cy: t.h / 2,
        r: t.radius
      });
    },
    updateNode: function r(o, e) {
      t(e, {
        cx: "" + o.w / 2,
        cy: "" + o.h / 2,
        r: "" + o.radius
      });
    }
  });
};
export { n as register };