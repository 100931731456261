import { EMPTY_BOUNDS as t } from "../../util/util.js";
import { quadrant as n } from "../../util/geom.js";
import { _createSegment as e, _getSegmentLength as o, Segments as r } from "./segments.js";
var s = {};
var i = {
  get: function t(n, e, o) {
    var r = s[e];
    if (!r) {
      throw "jsPlumb: unknown connector type '" + e + "'";
    } else {
      return r.create(n, o);
    }
  },
  register: function t(n, e) {
    s[n] = e;
  },
  compute: function t(n, e, o) {
    var r = s[n.type];
    if (!r) {
      throw "jsPlumb: unknown connector type '" + n.type + "'";
    } else {
      r.compute(n, e, o);
    }
  },
  exportGeometry: function t(n) {
    var e = s[n.type];
    if (!e) {
      throw "jsPlumb: unknown connector type '" + n.type + "'";
    } else {
      return e.geometryExporter(n);
    }
  },
  importGeometry: function t(n, e) {
    var o = s[n.type];
    if (!o) {
      throw "jsPlumb: unknown connector type '" + n.type + "'";
    } else {
      return o.geometryImporter(n, e);
    }
  },
  transformGeometry: function t(n, e, o, r) {
    var i = s[n.type];
    if (!i) {
      throw "jsPlumb: unknown connector type '" + n.type + "'";
    } else {
      return i.transformGeometry(e, o, r);
    }
  },
  setAnchorOrientation: function t(n, e, o) {
    var r = s[n.type];
    if (!r) {
      throw "jsPlumb: unknown connector type '" + n.type + "'";
    } else {
      return r.setAnchorOrientation(n, e, o);
    }
  },
  setGeometry: function t(n, e, o) {
    n.geometry = e;
    n.edited = e != null && !o;
  }
};
function a(t) {
  return t.geometry;
}
function u(t, n) {
  t.geometry = n;
  return true;
}
function p(t, n) {
  var e = n.extents;
  t.bounds.xmin = Math.min(t.bounds.xmin, e.xmin);
  t.bounds.xmax = Math.max(t.bounds.xmax, e.xmax);
  t.bounds.ymin = Math.min(t.bounds.ymin, e.ymin);
  t.bounds.ymax = Math.max(t.bounds.ymax, e.ymax);
}
function m(t) {
  t.totalLength = 0;
  t.segments.length = 0;
  t.segmentProportions.length = 0;
  t.segmentProportionalLengths.length = 0;
}
function g(t, n, r) {
  if (r.x1 === r.x2 && r.y1 === r.y2) {
    return;
  }
  var s = e(n, r);
  t.segments.push(s);
  t.totalLength += o(s);
  p(t, s);
}
function l(t, n, e) {
  var o, r, s;
  if (e) {
    n = n > 0 ? n / t.totalLength : (t.totalLength + n) / t.totalLength;
  }
  if (n === 1) {
    o = t.segments.length - 1;
    s = 1;
  } else if (n === 0) {
    s = 0;
    o = 0;
  } else {
    if (n >= .5) {
      o = 0;
      s = 0;
      for (r = t.segmentProportions.length - 1; r > -1; r--) {
        if (t.segmentProportions[r][1] >= n && t.segmentProportions[r][0] <= n) {
          o = r;
          s = (n - t.segmentProportions[r][0]) / t.segmentProportionalLengths[r];
          break;
        }
      }
    } else {
      o = t.segmentProportions.length - 1;
      s = 1;
      for (r = 0; r < t.segmentProportions.length; r++) {
        if (t.segmentProportions[r][1] >= n) {
          o = r;
          s = (n - t.segmentProportions[r][0]) / t.segmentProportionalLengths[r];
          break;
        }
      }
    }
  }
  return {
    segment: t.segments[o],
    proportion: s,
    index: o
  };
}
function c(t, n, e) {
  var o = l(t, n, e);
  return o.segment && r.pointOnPath(o.segment, o.proportion, false) || {
    x: 0,
    y: 0
  };
}
function f(t, n, e) {
  var o = l(t, n, e);
  return o.segment && r.gradientAtPoint(o.segment, o.proportion, false) || 0;
}
function y(t, n, e, o) {
  var s = l(t, n, o);
  return s.segment && r.pointAlongPathFrom(s.segment, s.proportion, e, false) || {
    x: 0,
    y: 0
  };
}
function h(t) {
  var n = 0;
  for (var e = 0; e < t.segments.length; e++) {
    var r = o(t.segments[e]);
    t.segmentProportionalLengths[e] = r / t.totalLength;
    t.segmentProportions[e] = [n, n += r / t.totalLength];
  }
}
function x(t, e) {
  t.strokeWidth = e.strokeWidth;
  var o = e.sourcePos.curX,
    r = e.targetPos.curX,
    s = e.sourcePos.curY,
    i = e.targetPos.curY,
    a = n({
      x: o,
      y: s
    }, {
      x: r,
      y: i
    }),
    u = r < o,
    p = i < s,
    m = [e.sourcePos.ox, e.sourcePos.oy],
    g = [e.targetPos.ox, e.targetPos.oy],
    l = u ? r : o,
    c = p ? i : s,
    f = Math.abs(r - o),
    y = Math.abs(i - s);
  var h = m[0] === 0 && m[1] === 0;
  var x = g[0] === 0 && g[1] === 0;
  if (h || x) {
    var b = f > y ? 0 : 1,
      P = [1, 0][b],
      v = b === 0 ? o : s,
      d = b === 0 ? r : i;
    if (h) {
      m[b] = v > d ? -1 : 1;
      m[P] = 0;
    }
    if (x) {
      g[b] = v > d ? 1 : -1;
      g[P] = 0;
    }
  }
  var S = u ? f + t.sourceGap * m[0] : t.sourceGap * m[0],
    G = p ? y + t.sourceGap * m[1] : t.sourceGap * m[1],
    w = u ? t.targetGap * g[0] : f + t.targetGap * g[0],
    L = p ? t.targetGap * g[1] : y + t.targetGap * g[1],
    I = m[0] * g[0] + m[1] * g[1];
  var k = {
    sx: S,
    sy: G,
    tx: w,
    ty: L,
    xSpan: Math.abs(w - S),
    ySpan: Math.abs(L - G),
    mx: (S + w) / 2,
    my: (G + L) / 2,
    so: m,
    to: g,
    x: l,
    y: c,
    w: f,
    h: y,
    segment: a,
    startStubX: S + m[0] * t.sourceStub,
    startStubY: G + m[1] * t.sourceStub,
    endStubX: w + g[0] * t.targetStub,
    endStubY: L + g[1] * t.targetStub,
    isXGreaterThanStubTimes2: Math.abs(S - w) > t.sourceStub + t.targetStub,
    isYGreaterThanStubTimes2: Math.abs(G - L) > t.sourceStub + t.targetStub,
    opposite: I === -1,
    perpendicular: I === 0,
    orthogonal: I === 1,
    sourceAxis: m[0] === 0 ? "y" : "x",
    points: [l, c, f, y, S, G, w, L],
    stubs: [t.sourceStub, t.targetStub]
  };
  k.anchorOrientation = k.opposite ? "opposite" : k.orthogonal ? "orthogonal" : "perpendicular";
  return k;
}
function b(t, n) {
  t.paintInfo = x(t, n);
  m(t);
  i.compute(t, t.paintInfo, n);
  t.x = t.paintInfo.points[0];
  t.y = t.paintInfo.points[1];
  t.w = t.paintInfo.points[2];
  t.h = t.paintInfo.points[3];
  t.segment = t.paintInfo.segment;
  h(t);
}
function P(t) {
  t.geometry = null;
  t.edited = false;
}
function v(n) {
  n.bounds = t();
}
function d(t, n, e) {
  var o = {
    d: Infinity,
    s: null,
    x: null,
    y: null,
    l: null,
    x1: null,
    y1: null,
    x2: null,
    y2: null,
    index: null,
    connectorLocation: null
  };
  for (var s = 0; s < t.segments.length; s++) {
    var i = r.findClosestPointOnPath(t.segments[s], n, e);
    if (i.d < o.d) {
      o.d = i.d;
      o.l = i.l;
      o.x = i.x;
      o.y = i.y;
      o.s = t.segments[s];
      o.x1 = i.x1;
      o.x2 = i.x2;
      o.y1 = i.y1;
      o.y2 = i.y2;
      o.index = s;
      o.connectorLocation = t.segmentProportions[s][0] + i.l * (t.segmentProportions[s][1] - t.segmentProportions[s][0]);
    }
  }
  return o;
}
function S(t, n, e, o, s) {
  var i = [];
  for (var a = 0; a < t.segments.length; a++) {
    i.push.apply(i, r.lineIntersection(t.segments[a], n, e, o, s));
  }
  return i;
}
function G(t, n, e, o, s) {
  var i = [];
  for (var a = 0; a < t.segments.length; a++) {
    i.push.apply(i, r.boxIntersection(t.segments[a], n, e, o, s));
  }
  return i;
}
function w(t, n) {
  var e = [];
  for (var o = 0; o < t.segments.length; o++) {
    e.push.apply(e, r.boundingBoxIntersection(t.segments[o], n));
  }
  return e;
}
function L(t) {
  return t.typeDescriptor === "connector";
}
export { i as Connectors, g as _addConnectorSegment, m as _clearConnectorSegments, b as _computeConnector, w as _connectorBoundingBoxIntersection, G as _connectorBoxIntersection, f as _connectorGradientAtPoint, S as _connectorLineIntersection, y as _connectorPointAlongPathFrom, c as _connectorPointOnPath, l as _findConnectorSegmentForLocation, d as _findConnectorSegmentForPoint, v as _resetConnectorBounds, P as _resetConnectorGeometry, p as _updateConnectorBounds, h as _updateConnectorSegmentProportions, a as defaultExportGeometry, u as defaultImportGeometry, L as isConnector };