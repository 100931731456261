import { Injectable } from '@angular/core';
import { Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, SnackbarService, UserService } from '@core/services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly snackbar: SnackbarService
  ) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<UrlTree | boolean> | Promise<UrlTree | boolean> | UrlTree | boolean {
    const url = state.url;
    return this.checkStatus(url);
  }

  public canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<UrlTree | boolean> | Promise<UrlTree | boolean> | UrlTree | boolean {
    return this.canActivate(next, state);
  }

  public canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    const url = `/${route.path}`;
    const redirectUrl = `/${segments.map(x => x.path).join('/')}`;
    return this.checkStatus(url, redirectUrl);
  }

  private checkStatus(url: string, redirectUrl: string | null = null): boolean {
    if (this.authService.authIsValid()) {
      const allowed = !!this.userService.organizationID || !!this.userService.userData?.Roles?.includes('Administrator') || url.includes('org');
      if (allowed) return true;
      else {
        this.authService.redirectUrl = redirectUrl || url;
        void this.router.navigate(['/org']);
        return false;
      }
    } else if (url.startsWith('/open-case/b2c')) return true;
    else {
      this.authService.redirectUrl = redirectUrl || url;
      void this.router.navigate(['/login']);
      this.snackbar.open({text: 'login.redirected'});
      return false;
    }
  }
}
