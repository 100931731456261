import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export const sameValidator = (mainControl: AbstractControl | null): ValidatorFn => (control: AbstractControl): ValidationErrors | null => mainControl?.value !== control.value ? {notSame: {main: mainControl?.value, value: control.value}} : null;

export const regexValidator = (regs: RegExp[]): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
  let result = null;
  const patterns: string[] = [];
  regs.forEach(reg => {
    if (!reg.test(control.value)) {
      patterns.push(reg.toString());
      result = {noMatch: {value: control.value, patterns}};
    }
  });
  return result;
};

export const notSameValidator = (mainControl: AbstractControl): ValidatorFn => (control: AbstractControl): ValidationErrors | null => mainControl.value === control.value ? {same: {main: mainControl.value, value: control.value}} : null;

export const notPartlySameValidator = (mainControl: AbstractControl | null, consecutiveChars: number): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
  let result = null;
  for (let i = 0; i <= control.value.length - consecutiveChars; i++) {
    if (mainControl?.value.includes(control.value.slice(i, i + consecutiveChars))) result = {partlySame: {main: mainControl.value, value: control.value}};
  }
  return result;
};

export const notPartlySameStringValidator = (mainString: string, consecutiveChars: number): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
  let result = null;
  for (let i = 0; i <= control.value.length - consecutiveChars; i++) {
    if (mainString.includes(control.value.slice(i, i + consecutiveChars))) result = {partlySame: {main: mainString, value: control.value}};
  }
  return result;
};
