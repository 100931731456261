export const TABLE_DEF = {
  PAGESIZE: 10,
  PAGE: 1,
  PAGESIZE_OPTIONS: [
    {label: '10', value: 10},
    {label: '25', value: 25},
    {label: '50', value: 50}
  ],
  MAX_PAGES: 1,
  PAGE_SLICE: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
};

export enum TableSettings {
  SHOWHEADER = 'SHOWHEADER',
  SHOWFOOTER = 'SHOWFOOTER',
  SHOWSORTING = 'SHOWSORTING',
  SHOWPIN = 'SHOWPIN'
}