import { createClass as i, classCallCheck as s, defineProperty as e, slicedToArray as t } from "../_virtual/_rollupPluginBabelHelpers.js";
import { processExpression as n } from "./expressions.js";
import { uuid as o } from "../ui-core/util/util.js";
var r = function () {
  function r(i) {
    s(this, r);
    this.instance = i;
    e(this, "type", void 0);
    e(this, "tag", void 0);
    e(this, "remove", void 0);
    e(this, "uuid", void 0);
    e(this, "children", []);
    e(this, "elements", []);
    e(this, "bindings", void 0);
    e(this, "context", void 0);
    e(this, "compiledBindings", void 0);
    this.uuid = o();
    i.entries[this.uuid] = this;
    this.compiledBindings = {};
  }
  i(r, [{
    key: "_processBindings",
    value: function i(s) {
      for (var e in s) {
        var r = s[e];
        if (r.expressions.length > 0) {
          this.compiledBindings[e] = {
            id: o(),
            name: e,
            expressions: r.expressions.map(function (i) {
              var s = t(i, 2),
                e = s[0],
                o = s[1];
              return {
                source: e,
                expression: n(o)
              };
            })
          };
        }
      }
    }
  }]);
  return r;
}();
export { r as AbstractEntry };