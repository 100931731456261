import type { IInputConfig, IInputOption, IInputValidators, ILookup, INarisOption, TControlType } from '@core/models';

export class FormInput {
  public id: string;
  public label: string;
  public controlType: TControlType;
  public inputType: string;
  public value?: any;
  public options?: IInputOption[];
  public autoComplete?: string;
  public rows?: number;
  public children?: FormInput[];
  public validators?: IInputValidators;
  public mandatory?: boolean;
  public layouthint?: string[];
  public displaysize?: number;
  public text?: { id: string; message: string };
  public formatted?: boolean;
  public assistant?: string;
  public lookup?: ILookup;
  public multiple?: boolean;
  public disabled?: boolean;
  public filterSuffix?: string;
  public dynamicOptions: INarisOption[];
  public tableData?: { meta: any; columns: any; records: any };
  public hidden?: boolean;
  public placeholder?: string;
  public createEndpoint?: string;
  public parent?: FormInput;
  public operator?: string;
  public tooltip?: string;
  public childUrl?: string;
  public missing?: boolean;
  public dependencyColumnEndpoint?: string;
  public frequency: any;
  public elementId?: string;
  public type?: string;
  public allowedMimeTypes?: string[];
  public allowedExtensions?: string[];
  public suggestion?: number;
  public prepend?: string;
  public elements?: Record<string, any>[];

  constructor(config: IInputConfig) {
    Object.entries(config).forEach(([ key, value ]) => this[key as keyof FormInput] = value);
    this.label = config.label || '';
    this.mandatory = config.mandatory || !!config.layouthint?.includes('mandatory');
  }
}
