import { Router } from '@angular/router';
import { ILayoutTab } from '@core/models';
import { TabService } from '@core/services';

export default class SetTabClass {

  private readonly dashboardHrefs = ['dashboard', 'start', 'my-activities-panel'];
  private readonly detailsHrefs = ['properties', 'definition', 'simulation', 'object'];

  constructor(
    private readonly tabs: TabService,
    private readonly router: Router
  ) {}

  public setTabs(panels: any[], _showStructureButton = false, _customTabs = false) {
    this.tabs.clear();
    
    panels.forEach(panel => {
      const tab: ILayoutTab = {title: panel.title ?? panel.label, active: !!panel.active, href: panel.href};
      this.tabs.add(tab);
    });
      
  }

  public clear() {
    this.tabs.clear();
  }
}
