<div [formGroup]="formGroup" class="form-group-wrapper">
  @if (label) {
    <h4 class="label">{{ label }}</h4>
  }
  <ng-container>
    @for (input of filteredInputs; let index = $index; track index) {
      <app-form-input
        [input]="input"
        [step]="inputs"
        [formGroup]="formGroup"
        [error]="getErrorForInput(input.id)"
        [oddInput]="index % 2 === 0 && isStriped"
        (autoSubmit)="autoSubmit.emit()"
        (backAndReset)="backAndReset.emit()"
        [isConsequence]="isConsequence"
        [hidePreviousAssessment]="hidePreviousAssessment"
        [isInitialApplicability]="isInitialApplicability"
        [isProcessToolkitForm]="isProcessToolkitForm"
        [isContextChips]="isContextChips"
        [formId]="formId"
        [ngClass]="{'hide-optional-field': historyNames.includes(input.id) ? false : hideOptionalFields && !input.mandatory}"
      />
    }
  </ng-container>
  @if (hasFrequency) {
    <div class="frequency-wrapper">
      <div class="frequency-wrapper__label">{{'Frequency' | translate}}</div>
      <app-form-frequency
        [formGroup]="formGroup"
        [inputs]="_frequencyInputs"
        [errors]="errors"
        [formId]="formId"
      />
    </div>
  }
</div>
