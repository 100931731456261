import { inherits as r, createSuper as e, classCallCheck as i, defineProperty as a, assertThisInitialized as s } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { EndpointRepresentation as t } from "./endpoint-representation.js";
import { TYPE_DOT_ENDPOINT as n } from "./definitions.js";
var u = function (t) {
  r(d, t);
  var u = e(d);
  function d(r, e) {
    var t;
    i(this, d);
    t = u.call(this, r, e);
    a(s(t), "radius", void 0);
    a(s(t), "defaultOffset", void 0);
    a(s(t), "defaultInnerRadius", void 0);
    a(s(t), "type", n);
    e = e || {};
    t.radius = e.radius || 5;
    t.defaultOffset = .5 * t.radius;
    t.defaultInnerRadius = t.radius / 3;
    return t;
  }
  return d;
}(t);
a(u, "type", n);
export { u as DotEndpoint };