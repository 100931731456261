<mat-checkbox
  [attr.formControl]="control"
  [color]="color"
  [labelPosition]="labelPosition"
  [indeterminate]="indeterminate"
  [disabled]="disabled"
  [checked]="setChecked()"
  (change)="onChange($event)"
  [attr.data-test-id]="'checkbox__' + id"
>
  <ng-content />
</mat-checkbox>
