import { createClass as e, inherits as t, createSuper as r, classCallCheck as n, defineProperty as i, assertThisInitialized as s } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { djikstra as u } from "./djikstra.js";
import { floydWarshall as o } from "./floyd-warshall.js";
import { Cluster as a } from "./cluster.js";
import { partition as l } from "../util.js";
import { uuid as g, extend as d } from "../../ui-core/util/util.js";
function h(e) {
  return b(e) ? e.getParent().id : e.getFullId();
}
function p(e) {
  return b(e) ? e.getParent() : e;
}
function c(e) {
  return b(e) ? e.id : null;
}
function f(e) {
  return e != null && e.objectType === j.objectType;
}
function v(e) {
  return f(e) && e.group != null;
}
function y(e) {
  return e != null && e.objectType === V.objectType;
}
function k(e) {
  return y(e) && e.group != null;
}
function m(e) {
  return e != null && e.objectType === S.objectType;
}
function b(e) {
  return e != null && e.objectType === x.objectType;
}
function E(e) {
  return e.objectType === V.objectType;
}
function T(e) {
  return e.objectType === j.objectType || e.objectType === V.objectType;
}
function _(e) {
  return e.objectType === x.objectType;
}
function I(e) {
  return e.id;
}
var M = ".";
var C = function () {
  function t(e) {
    n(this, t);
    i(this, "nodes", []);
    i(this, "id", void 0);
    i(this, "edges", []);
    i(this, "groups", []);
    i(this, "_nodeMap", new Map());
    i(this, "_edgeMap", new Map());
    i(this, "_groupMap", new Map());
    i(this, "defaultDirected", void 0);
    i(this, "defaultCost", void 0);
    i(this, "_defaultIdFunction", void 0);
    i(this, "typeFunction", void 0);
    i(this, "enableSubgraphs", void 0);
    i(this, "portSeparator", void 0);
    i(this, "_topLevelCache", {});
    e = e || {};
    this.nodes = [];
    this.edges = [];
    this.groups = [];
    this.id = e.id;
    this.defaultDirected = !(e.defaultDirected === false);
    this.defaultCost = e.defaultCost || 1;
    this._defaultIdFunction = e.defaultIdFunction || I;
    this.typeFunction = e.typeFunction || function (e) {
      return e.type || "default";
    };
    this.enableSubgraphs = e.enableSubgraphs === true;
    this.portSeparator = e.portSeparator || M;
  }
  e(t, [{
    key: "_removeFromTopLevelCache",
    value: function e(t) {
      delete this._topLevelCache[t.id];
    }
  }, {
    key: "_addToTopLevelCache",
    value: function e(t) {
      this._topLevelCache[t.id] = t;
    }
  }, {
    key: "_clearTopLevelCache",
    value: function e() {
      this._topLevelCache = {};
    }
  }, {
    key: "getIdFunction",
    value: function e() {
      return this._defaultIdFunction;
    }
  }, {
    key: "setIdFunction",
    value: function e(t) {
      this._defaultIdFunction = t;
    }
  }, {
    key: "setTypeFunction",
    value: function e(t) {
      this.typeFunction = t;
    }
  }, {
    key: "getType",
    value: function e(t) {
      return this.typeFunction(t);
    }
  }, {
    key: "getId",
    value: function e(t, r) {
      if (t == null) {
        return g();
      } else if (typeof t === "string") {
        return t;
      } else {
        var n = r || this.getIdFunction();
        return n(t) || g();
      }
    }
  }, {
    key: "getTopLevelElements",
    value: function e() {
      return this._topLevelCache;
    }
  }, {
    key: "setEnableSubgraphs",
    value: function e(t) {
      this.enableSubgraphs = t;
    }
  }, {
    key: "setPortSeparator",
    value: function e(t) {
      this.portSeparator = t;
    }
  }, {
    key: "getPortSeparator",
    value: function e() {
      return this.portSeparator;
    }
  }, {
    key: "splitPortId",
    value: function e(t) {
      return t != null ? t.split(this.portSeparator) : [];
    }
  }, {
    key: "getVertexByPortId",
    value: function e(t) {
      var r = this.splitPortId(t);
      if (r.length === 2) {
        return this._nodeMap.get(r[0]) || this._groupMap.get(r[0]);
      }
    }
  }, {
    key: "getVertex",
    value: function e(t, r) {
      var n = this;
      if (t == null) return null;
      var i;
      if (typeof t != "string") {
        if (t.constructor === x || t.constructor === j || t.constructor === V) return t;
        var s = t;
        i = this._defaultIdFunction(t);
        if (typeof i != "string") {
          return s;
        }
      }
      i = t;
      var u = this.enableSubgraphs ? i.split("/") : [i],
        o = function e(t) {
          if (n._nodeMap.has(t)) {
            return n._nodeMap.get(t);
          }
          if (n._groupMap.has(t)) {
            return n._groupMap.get(t);
          }
          var i = t.split(n.portSeparator),
            s = i[0],
            u = n._nodeMap.get(s) || n._groupMap.get(s);
          if (i.length === 2 && u != null) {
            var o = u.getPort(i[1]);
            if (o == null && r) o = u.addPort(i[1]);
            return o;
          } else return u;
        };
      if (u.length == 1) return o(u[0]);else if (u.length > 1 && u.length % 2 == 0) throw "Subgraph path format error.";else {
        var a = null,
          l = null;
        for (var g = 0; g < u.length - 1; g += 2) {
          a = o(u[g]);
          l = a.getGraph(u[g + 1]);
        }
        return l.getVertex(u[u.length - 1]);
      }
    }
  }, {
    key: "clear",
    value: function e() {
      this.nodes.length = 0;
      this.groups.length = 0;
      this._nodeMap.clear();
      this._edgeMap.clear();
      this._groupMap.clear();
      this._clearTopLevelCache();
    }
  }, {
    key: "getVertices",
    value: function e() {
      return this.nodes;
    }
  }, {
    key: "getVertexCount",
    value: function e() {
      return this.nodes.length;
    }
  }, {
    key: "getVertexAt",
    value: function e(t) {
      return this.nodes[t];
    }
  }, {
    key: "getEdgeCount",
    value: function e() {
      return this._edgeMap.size;
    }
  }, {
    key: "addEdge",
    value: function e(t, r, n) {
      var i = t.directed == null ? this.defaultDirected === true : !(t.directed === false),
        s = t.cost || this.defaultCost,
        u = this.getId(t.data, r),
        o = this.getVertex(t.source, true),
        a = this.getVertex(t.target, true);
      if (o == null || o.objectType == null) throw new TypeError("Unknown source node [" + t.source + "]");
      if (a == null || a.objectType == null) throw new TypeError("Unknown target node [" + t.target + "]");
      if (n && !n(o, a)) {
        return null;
      }
      var l = new S({
        source: o,
        target: a,
        cost: s,
        directed: i,
        data: t.data || {},
        id: u,
        graph: this
      });
      l.source.addEdge(l);
      if (l.source !== l.target) {
        l.target.addEdge(l);
      }
      this._edgeMap.set(u, l);
      if (t.geometry != null) {
        l.geometry = t.geometry;
      }
      return l;
    }
  }, {
    key: "addNode",
    value: function e(t, r) {
      var n = new j(this, t || {}, r || this._defaultIdFunction);
      if (!this._nodeMap.has(n.id)) {
        this.nodes.push(n);
        this._nodeMap.set(n.id, n);
        this._addToTopLevelCache(n);
        return n;
      }
      return null;
    }
  }, {
    key: "addNodes",
    value: function e(t, r) {
      for (var n = 0; n < t.length; n++) {
        this.addNode(t[n], r || this._defaultIdFunction);
      }
    }
  }, {
    key: "addGroup",
    value: function e(t, r) {
      var n = new V(this, t, r || this._defaultIdFunction);
      if (!this._groupMap.has(n.id)) {
        this.groups.push(n);
        this._groupMap.set(n.id, n);
        this._addToTopLevelCache(n);
        return n;
      }
      return this._groupMap.get(n.id);
    }
  }, {
    key: "getGroupCount",
    value: function e() {
      return this.groups.length;
    }
  }, {
    key: "getGroupAt",
    value: function e(t) {
      return this.groups[t];
    }
  }, {
    key: "getGroups",
    value: function e() {
      return this.groups;
    }
  }, {
    key: "addMemberToGroup",
    value: function e(t, r) {
      var n = typeof r === "string" ? this._groupMap.get(r) : r;
      var i = this.getVertex(t);
      if (i && n) {
        n.addMember(i);
        this._removeFromTopLevelCache(i);
      }
    }
  }, {
    key: "addMembersToGroup",
    value: function e(t, r) {
      for (var n = 0; n < t.length; n++) {
        this.addMemberToGroup(t[n], r);
      }
    }
  }, {
    key: "removeMemberFromGroup",
    value: function e(t) {
      var r = this.getVertex(t);
      if (r && r.group) {
        r.group.removeMember(r);
        this._addToTopLevelCache(r);
      }
    }
  }, {
    key: "removeMembersFromGroup",
    value: function e(t) {
      for (var r = 0; r < t.length; r++) {
        this.removeMemberFromGroup(t[r]);
      }
    }
  }, {
    key: "getGroup",
    value: function e(t) {
      return typeof t === "string" ? this._groupMap.get(t) : t;
    }
  }, {
    key: "deleteGroup",
    value: function e(t, r) {
      var n = this;
      var i = {
        group: typeof t === "string" ? this._groupMap.get(t) : t,
        edges: [],
        nodes: [],
        nestedGroups: [],
        children: []
      };
      if (i.group) {
        i.parentGroup = i.group.group;
        i.children = i.group.members.slice();
        var s;
        if (r) {
          var u = l(i.group.getMembers(), function (e) {
              return y(e);
            }),
            o = u.left,
            a = u.right;
          o.forEach(function (e) {
            i.nestedGroups.push(n.deleteGroup(e, true));
          });
          a.forEach(function (e) {
            i.nodes.push(n.deleteNode(e));
          });
        } else {
          i.group.getMembers().forEach(function (e) {
            return delete e.group;
          });
        }
        i.group.members.length = 0;
        i.edges = i.group.getAllDirectEdges().slice();
        var g = i.edges.length;
        for (s = 0; s < g; s++) {
          this.deleteEdge(i.edges[s]);
        }
        var d = i.group.getPorts();
        for (var h = 0; h < d.length; h++) {
          this.deletePort(d[h]);
        }
        this._groupMap.delete(i.group.id);
        var p = -1;
        for (var c = 0; c < this.groups.length; c++) {
          if (this.groups[c].id === i.group.id) {
            p = c;
            break;
          }
        }
        if (p > -1) {
          this.groups.splice(p, 1);
        }
        if (i.group.group != null) {
          i.group.group.removeMember(t);
        }
        this._removeFromTopLevelCache(i.group);
      }
      return i;
    }
  }, {
    key: "deletePort",
    value: function e(t) {
      var r = t.getEdges();
      for (var n = 0; n < r.length; n++) {
        this.deleteEdge(r[n]);
      }
      return r;
    }
  }, {
    key: "deleteNode",
    value: function e(t) {
      var r = this.getVertex(t),
        n = {
          node: null,
          edges: [],
          parentGroup: null
        };
      if (r) {
        n.node = r;
        n.parentGroup = r.group;
        var i = r.getPorts();
        for (var s = 0; s < i.length; s++) {
          this.deletePort(i[s]);
        }
        var u = r;
        var o = -1;
        for (var a = 0; a < this.nodes.length; a++) {
          if (this.nodes[a].id === r.id) {
            o = a;
            break;
          }
        }
        if (o > -1) {
          this.nodes.splice(o, 1);
          if (u.group != null) {
            u.group.removeMember(u);
          }
        }
        n.edges = r.getEdges().slice();
        for (var l = 0; l < n.edges.length; l++) {
          this.deleteEdge(n.edges[l]);
        }
        this._nodeMap.delete(r.getFullId());
        this._removeFromTopLevelCache(r);
      }
      return n;
    }
  }, {
    key: "deleteEdge",
    value: function e(t) {
      t = this.getEdge(t);
      if (t == null) return;
      var r = this.getVertex(t.source);
      if (r) {
        r.deleteEdge(t);
      }
      var n = this.getVertex(t.target);
      if (n) {
        n.deleteEdge(t);
      }
      this._edgeMap.delete(t.getId());
    }
  }, {
    key: "getEdge",
    value: function e(t) {
      if (t == null) return;
      if (typeof t !== "string") {
        if (t.constructor === S) {
          return t;
        }
        var r = t;
        t = this._defaultIdFunction(t);
        if (typeof t !== "string") {
          return r;
        }
      }
      return this._edgeMap.get(t);
    }
  }, {
    key: "getEdges",
    value: function e(t) {
      t = t || {};
      var r = t.source != null,
        n = t.target != null,
        i = t.node ? this.getVertex(t.node) : null,
        s = t.filter || function () {
          return true;
        },
        u = function e(s) {
          return (!r || (t.source === true ? s.source === i : s.source !== i)) && (!n || (t.target === true ? s.target === i : s.target !== i));
        },
        o = [],
        a = function e(t) {
          if (s(t) && u(t)) {
            o.push(t);
          }
        },
        l;
      if (t.node) {
        var g = this.getVertex(t.node);
        var d = g.getAllEdges();
        for (l = 0; l < d.length; l++) {
          a(d[l]);
        }
      } else {
        this._edgeMap.forEach(function (e, t) {
          return a(e);
        });
      }
      return o;
    }
  }, {
    key: "getAllEdges",
    value: function e() {
      var t = [];
      this._edgeMap.forEach(function (e, r) {
        return t.push(e);
      });
      return t;
    }
  }, {
    key: "renamePort",
    value: function e(t, r) {
      if (t.getParent().renamePort(t, r)) {
        t.id = r;
        return true;
      } else {
        return false;
      }
    }
  }, {
    key: "getAncestors",
    value: function e(t) {
      var r = p(t);
      var n = [];
      var i = r.group;
      while (i != null) {
        n.push(i);
        i = i.group;
      }
      return n;
    }
  }, {
    key: "isAncestor",
    value: function e(t, r) {
      if (t == null || r == null) {
        return false;
      }
      return this.getAncestors(t).indexOf(r) !== -1;
    }
  }, {
    key: "findPath",
    value: function e(t, r, n, i, s) {
      var o = this.getVertex(t);
      var a = this.getVertex(r);
      return u({
        graph: this,
        source: o,
        target: a,
        strict: !(n === false),
        nodeFilter: i,
        edgeFilter: s
      });
    }
  }, {
    key: "getDistance",
    value: function e(t, r, n) {
      var i = this.findPath(t, r, n);
      return i.pathDistance;
    }
  }, {
    key: "setTarget",
    value: function e(t, r) {
      var n = this.getVertex(r);
      if (n == null) return {
        success: false
      };
      var i = t.target;
      t.target.deleteEdge(t);
      t.target = n;
      n.addEdge(t);
      return {
        old: i,
        edge: t,
        new: n,
        success: true
      };
    }
  }, {
    key: "setSource",
    value: function e(t, r) {
      var n = this.getVertex(r);
      if (n == null) return {
        success: false
      };
      var i = t.source;
      t.source.deleteEdge(t);
      t.source = n;
      n.addEdge(t);
      return {
        old: i,
        edge: t,
        new: n,
        success: true
      };
    }
  }, {
    key: "printPath",
    value: function e(t, r) {
      var n = this.getVertex(t);
      var i = this.getVertex(r);
      var s = this.findPath(n, i).path;
      var u = "[" + n.id + " - " + i.id + "] : ";
      for (var o = 0; o < s.length; o++) {
        u = u + "{ vertex:" + s[o].vertex.id + ", cost:" + s[o].cost + ", edge: " + (s[o].edge && s[o].edge.getId()) + " } ";
      }
      return u;
    }
  }, {
    key: "getDiameter",
    value: function e(t) {
      var r = 0;
      for (var n = 0; n < this.nodes.length; n++) {
        for (var i = 0; i < this.nodes.length; i++) {
          if (i != n) {
            var s = u({
              graph: this,
              source: this.nodes[n],
              target: this.nodes[i]
            });
            if (s.path == null || s.path.length == 0) {
              if (!t) return Infinity;
            } else r = Math.max(r, s.pathDistance);
          }
        }
      }
      return r;
    }
  }, {
    key: "getCentrality",
    value: function e(t) {
      var r = this.getVertex(t);
      return (r.getIndegreeCentrality() + r.getOutdegreeCentrality()) / (this.getVertexCount() - 1);
    }
  }, {
    key: "getIndegreeCentrality",
    value: function e(t) {
      var r = this.getVertex(t);
      return r.getIndegreeCentrality() / (this.getVertexCount() - 1);
    }
  }, {
    key: "getOutdegreeCentrality",
    value: function e(t) {
      var r = this.getVertex(t);
      return r.getOutdegreeCentrality() / (this.getVertexCount() - 1);
    }
  }, {
    key: "getCloseness",
    value: function e(t) {
      return 1 / this.getFarness(t);
    }
  }, {
    key: "getFarness",
    value: function e(t) {
      var r = this.getVertex(t);
      var n = u({
        graph: this,
        source: r,
        target: r,
        processAll: true
      });
      var i = 0;
      for (var s in n.dist) {
        i += n.dist[s];
      }
      return i / (this.getVertexCount() - 1);
    }
  }, {
    key: "getBetweenness",
    value: function e(t) {
      var r = this.getVertexCount(),
        n = (r - 1) * (r - 2) / 2,
        i = 0,
        s = function e(t, r, n, i, s) {
          var u = n.parents[t][r];
          if (u.length == 0) {
            var o = i.slice();
            o.unshift(t);
            s.push(o);
          } else {
            for (var a = 0; a < u.length; a++) {
              if (i.indexOf(u[a][0].id) == -1) {
                var l = i.slice();
                l.unshift(u[a][0].id);
                e(t, u[a][0].id, n, l, s);
              }
            }
          }
        };
      var u = this.getVertex(t);
      var a = o({
        graph: this,
        focus: u
      });
      for (var l in a.paths) {
        for (var g in a.paths[l]) {
          if (l != g) {
            var d = [];
            var h = 0;
            s(l, g, a, [g], d);
            for (var p = 0; p < d.length; p++) {
              var c = d[p].indexOf(u.id);
              if (c > 0 && c < d[p].length - 1) {
                h++;
              }
            }
            i += h / d.length;
          }
        }
      }
      return i / n;
    }
  }, {
    key: "inspect",
    value: function e() {
      var t = "";
      for (var r = 0; r < this.nodes.length; r++) {
        t += this.nodes[r].inspect() + "\n";
      }
      return t;
    }
  }, {
    key: "serialize",
    value: function e() {
      var t = {
          nodes: [],
          edges: [],
          groups: []
        },
        r,
        n,
        i;
      for (var s = 0; s < this.nodes.length; s++) {
        r = this.nodes[s];
        if (r.transient) {
          continue;
        }
        t.nodes.push(d({}, r.data));
        n = r.getAllEdges();
        for (var u = 0; u < n.length; u++) {
          if (n[u].source == r || n[u].source.objectType === x.objectType && n[u].source.getParent() == r) {
            var o = {
              source: n[u].source.getFullId(),
              target: n[u].target.getFullId()
            };
            if (n[u].data) {
              o.data = d({}, n[u].data);
            }
            if (n[u].geometry) {
              o.geometry = d({}, n[u].geometry);
            }
            t.edges.push(o);
          }
        }
      }
      for (var a = 0; a < this.groups.length; a++) {
        i = this.groups[a];
        t.groups.push(d({}, i.data));
        n = i.getEdges();
        for (var l = 0; l < n.length; l++) {
          if (n[l].source === i) {
            var g = {
              source: i.getFullId(),
              target: n[l].target.getFullId()
            };
            if (n[l].data) {
              g.data = d({}, n[l].data);
            }
            if (n[l].geometry) {
              g.geometry = d({}, n[l].geometry);
            }
            t.edges.push(g);
          }
        }
      }
      return t;
    }
  }, {
    key: "legacySerialize",
    value: function e() {
      var t = {
          nodes: [],
          edges: [],
          ports: [],
          groups: []
        },
        r,
        n,
        i,
        s;
      for (var u = 0; u < this.nodes.length; u++) {
        r = this.nodes[u];
        t.nodes.push(d({}, r.data));
        n = r.getAllEdges();
        i = r.getPorts();
        for (var o = 0; o < n.length; o++) {
          if (n[o].source == r || n[o].source.objectType === x.objectType && n[o].source.getParent() == r) {
            var a = {
              source: n[o].source.getFullId(),
              target: n[o].target.getFullId()
            };
            if (n[o].data) {
              a.data = d({}, n[o].data);
            }
            if (n[o].geometry) {
              a.geometry = d({}, n[o].geometry);
            }
            t.edges.push(a);
          }
        }
        for (var l = 0; l < i.length; l++) {
          var g = {};
          for (var h in i[l].data) {
            g[h] = i[l].data[h];
          }
          g.id = i[l].getFullId();
          t.ports.push(g);
        }
      }
      for (var p = 0; p < this.groups.length; p++) {
        s = this.groups[p];
        t.groups.push(d({}, s.data));
        n = s.getEdges();
        for (var c = 0; c < n.length; c++) {
          if (n[c].source === s) {
            var f = {
              source: s.getFullId(),
              target: n[c].target.getFullId()
            };
            if (n[c].data) {
              f.data = d({}, n[c].data);
            }
            if (n[c].geometry) {
              f.geometry = d({}, n[c].geometry);
            }
            t.edges.push(f);
          }
        }
      }
      return t;
    }
  }, {
    key: "getClusters",
    value: function e() {
      var t = [],
        r = {},
        n;
      var i = function e(n, i) {
        if (n == null || r[n.id]) return;
        if (i == null) {
          i = new a(n);
          t.push(i);
        } else {
          i.addVertex(n);
        }
        r[n.id] = true;
        var s = n.getAllEdges();
        for (var u = 0; u < s.length; u++) {
          var o = s[u].source === n ? s[u].target : s[u].source;
          e(o, i);
        }
      };
      for (n = 0; n < this.nodes.length; n++) {
        i(this.nodes[n]);
      }
      for (n = 0; n < this.groups.length; n++) {
        i(this.groups[n]);
      }
      return t;
    }
  }]);
  return t;
}();
var F = function () {
  function t(e) {
    var r = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    n(this, t);
    i(this, "atts", new Map());
    i(this, "type", void 0);
    i(this, "objectType", void 0);
    i(this, "data", void 0);
    i(this, "graph", void 0);
    this.graph = e;
    this.data = r || {};
    this.type = e.getType(this.data);
  }
  e(t, [{
    key: "setAttribute",
    value: function e(t, r) {
      this.atts.set(t, r);
    }
  }, {
    key: "getAttribute",
    value: function e(t) {
      return this.atts.get(t);
    }
  }, {
    key: "getType",
    value: function e() {
      return this.type;
    }
  }, {
    key: "setType",
    value: function e(t) {
      this.type = t;
    }
  }]);
  return t;
}();
var P = function (u) {
  t(a, u);
  var o = r(a);
  function a(e, t, r) {
    var u;
    n(this, a);
    u = o.call(this, e, t);
    u.graph = e;
    u.idFunction = r;
    i(s(u), "id", void 0);
    i(s(u), "transient", void 0);
    i(s(u), "edges", []);
    i(s(u), "indegreeCentrality", 0);
    i(s(u), "outdegreeCentrality", 0);
    i(s(u), "nestedGraphs", []);
    i(s(u), "graphs", []);
    u.id = e.getId(t, r);
    u.data = typeof t === "string" ? {
      id: t
    } : t;
    return u;
  }
  e(a, [{
    key: "getIndegreeCentrality",
    value: function e() {
      return this.indegreeCentrality;
    }
  }, {
    key: "getOutdegreeCentrality",
    value: function e() {
      return this.outdegreeCentrality;
    }
  }, {
    key: "addGraph",
    value: function e(t) {
      t = typeof t === "string" ? new C({
        id: t
      }) : t;
      this.graphs.push(t);
      if (!t.id) t.id = "" + this.graphs.length;
      return t;
    }
  }, {
    key: "getGraph",
    value: function e(t) {
      for (var r = 0; r < this.graphs.length; r++) {
        if (this.graphs[r].id === t) return this.graphs[r];
      }
    }
  }, {
    key: "getFullId",
    value: function e() {
      return this.id;
    }
  }, {
    key: "getEdges",
    value: function e(t) {
      if (t == null || t.filter == null) return this.edges;else {
        var r = [];
        for (var n = 0; n < this.edges.length; n++) {
          if (t.filter(this.edges[n])) {
            r.push(this.edges[n]);
          }
        }
        return r;
      }
    }
  }, {
    key: "getSourceEdges",
    value: function e() {
      var t = this;
      return this.getEdges({
        filter: function e(r) {
          return r.source == t;
        }
      });
    }
  }, {
    key: "getTargetEdges",
    value: function e() {
      var t = this;
      return this.getEdges({
        filter: function e(r) {
          return r.target == t;
        }
      });
    }
  }, {
    key: "addEdge",
    value: function e(t) {
      this.edges.push(t);
      if (t.source === this || !t.isDirected()) {
        this.outdegreeCentrality++;
      }
      if (t.target === this || !t.isDirected()) {
        this.indegreeCentrality++;
      }
    }
  }, {
    key: "deleteEdge",
    value: function e(t) {
      var r = -1;
      for (var n = 0; n < this.edges.length; n++) {
        if (this.edges[n].getId() === t.getId()) {
          r = n;
          break;
        }
      }
      if (r > -1) {
        this.edges.splice(r, 1);
        if (t.source === this || !t.isDirected()) {
          this.outdegreeCentrality--;
        }
        if (t.target === this || !t.isDirected()) {
          this.indegreeCentrality--;
        }
        return true;
      }
      return false;
    }
  }, {
    key: "inspect",
    value: function e() {
      var t = "{ id:" + this.id + ", edges:[\n";
      for (var r = 0; r < this.edges.length; r++) {
        t += this.edges[r].inspect() + "\n";
      }
      t += "]}";
      return t;
    }
  }, {
    key: "isChildOf",
    value: function e(t) {
      return false;
    }
  }]);
  return a;
}(F);
var j = function (u) {
  t(a, u);
  var o = r(a);
  function a(e, t, r) {
    var u;
    n(this, a);
    u = o.call(this, e, t, r);
    i(s(u), "objectType", a.objectType);
    i(s(u), "ports", []);
    i(s(u), "group", void 0);
    i(s(u), "portMap", {});
    i(s(u), "defaultInternalCost", 0);
    i(s(u), "internalEdges", {});
    return u;
  }
  e(a, [{
    key: "getAllEdges",
    value: function e(t) {
      var r = this.getEdges(t).slice(0);
      for (var n = 0; n < this.ports.length; n++) {
        r.push.apply(r, this.ports[n].getEdges(t));
      }
      return r;
    }
  }, {
    key: "getAllSourceEdges",
    value: function e() {
      var t = this.getSourceEdges().slice(0);
      for (var r = 0; r < this.ports.length; r++) {
        t.push.apply(t, this.ports[r].getSourceEdges());
      }
      return t;
    }
  }, {
    key: "getAllTargetEdges",
    value: function e() {
      var t = this.getTargetEdges().slice(0);
      for (var r = 0; r < this.ports.length; r++) {
        t.push.apply(t, this.ports[r].getTargetEdges());
      }
      return t;
    }
  }, {
    key: "getDirectEdges",
    value: function e(t) {
      return this.getEdges(t);
    }
  }, {
    key: "getDirectSourceEdges",
    value: function e() {
      return this.getSourceEdges();
    }
  }, {
    key: "getDirectTargetEdges",
    value: function e() {
      return this.getTargetEdges();
    }
  }, {
    key: "getPortEdges",
    value: function e(t) {
      var r = [];
      for (var n = 0; n < this.ports.length; n++) {
        r.push.apply(r, this.ports[n].getEdges(t));
      }
      return r;
    }
  }, {
    key: "getPortSourceEdges",
    value: function e() {
      var t = [];
      for (var r = 0; r < this.ports.length; r++) {
        t.push.apply(t, this.ports[r].getSourceEdges());
      }
      return t;
    }
  }, {
    key: "getPortTargetEdges",
    value: function e() {
      var t = [];
      for (var r = 0; r < this.ports.length; r++) {
        t.push.apply(t, this.ports[r].getTargetEdges());
      }
      return t;
    }
  }, {
    key: "getIndegreeCentrality",
    value: function e() {
      var t = 0;
      for (var r = 0; r < this.ports.length; r++) {
        t += this.ports[r].getIndegreeCentrality();
      }
      return this.indegreeCentrality + t;
    }
  }, {
    key: "getOutdegreeCentrality",
    value: function e() {
      var t = 0;
      for (var r = 0; r < this.ports.length; r++) {
        t += this.ports[r].getOutdegreeCentrality();
      }
      return this.outdegreeCentrality + t;
    }
  }, {
    key: "getPorts",
    value: function e() {
      return this.ports;
    }
  }, {
    key: "addPort",
    value: function e(t, r) {
      var n = this.graph.getId(t, r),
        i = this.getPort(n);
      if (i == null) {
        i = new x(this, t, r);
        this.ports.push(i);
        this.portMap[i.id] = i;
      }
      return i;
    }
  }, {
    key: "setPort",
    value: function e(t, r) {
      var n = this.getPort(t);
      if (!n) {
        n = this.addPort({
          id: t
        });
      }
      n.data = r;
      n.setType(this.graph.getType(r));
      return n;
    }
  }, {
    key: "getPort",
    value: function e(t) {
      return this.portMap[t];
    }
  }, {
    key: "_portId",
    value: function e(t) {
      return t instanceof x ? t.id : t;
    }
  }, {
    key: "renamePort",
    value: function e(t, r) {
      if (this.portMap[t.id]) {
        this.portMap[r] = this.portMap[t.id];
        delete this.portMap[t.id];
        return true;
      } else {
        return false;
      }
    }
  }, {
    key: "removePort",
    value: function e(t) {
      var r = false;
      if (t) {
        var n = this._portId(t),
          i = -1;
        for (var s = 0; s < this.ports.length; s++) {
          if (this.ports[s].id === n) {
            i = s;
            break;
          }
        }
        if (i != -1) {
          var u = this.ports[i];
          this.graph.deletePort(u);
          this.ports.splice(i, 1);
          r = true;
        }
        delete this.portMap[n];
      }
      return r;
    }
  }, {
    key: "setDefaultInternalCost",
    value: function e(t) {
      this.defaultInternalCost = t;
    }
  }, {
    key: "getInternalEdge",
    value: function e(t, r) {
      var n = this._portId(t),
        i = this._portId(r),
        s = {
          source: this.portMap[n],
          target: this.portMap[i],
          cost: Infinity
        };
      if (s.source && s.target) {
        var u = this.internalEdges[n + "-" + i] || {
          cost: this.defaultInternalCost,
          directed: false
        };
        for (var o in u) {
          s[o] = u[o];
        }
      }
      return s;
    }
  }, {
    key: "setInternalEdge",
    value: function e(t, r, n, i) {
      var s = this._portId(t),
        u = this._portId(r);
      this.internalEdges[s + "-" + u] = {
        cost: n || this.defaultInternalCost,
        directed: i
      };
      return this.getInternalEdge(t, r);
    }
  }]);
  return a;
}(P);
i(j, "objectType", "Node");
var x = function (u) {
  t(a, u);
  var o = r(a);
  function a(e, t, r) {
    var u;
    n(this, a);
    u = o.call(this, e.graph, t, r);
    u.parent = e;
    i(s(u), "objectType", a.objectType);
    return u;
  }
  e(a, [{
    key: "getParent",
    value: function e() {
      return this.parent;
    }
  }, {
    key: "getFullId",
    value: function e() {
      return this.parent.id + this.graph.getPortSeparator() + this.id;
    }
  }, {
    key: "isChildOf",
    value: function e(t) {
      return this.parent === t;
    }
  }, {
    key: "getAllEdges",
    value: function e(t) {
      return this.getEdges(t);
    }
  }]);
  return a;
}(P);
i(x, "objectType", "Port");
var V = function (u) {
  t(a, u);
  var o = r(a);
  function a(e, t, r) {
    var u;
    n(this, a);
    u = o.call(this, e, t, r);
    i(s(u), "objectType", a.objectType);
    i(s(u), "members", []);
    i(s(u), "_memberMap", {});
    return u;
  }
  e(a, [{
    key: "addMember",
    value: function e(t) {
      if (this._memberMap[t.id] != null) {
        return false;
      } else {
        this.members.push(t);
        this._memberMap[t.id] = t;
        t.group = this;
        return true;
      }
    }
  }, {
    key: "getMemberCount",
    value: function e() {
      return this.members.length;
    }
  }, {
    key: "getMembers",
    value: function e() {
      return this.members;
    }
  }, {
    key: "removeMember",
    value: function e(t) {
      var r = typeof t === "string" ? this._memberMap[t] : t;
      if (r) {
        var n = this.members.indexOf(r);
        if (n != -1) {
          this.members.splice(n, 1);
          delete this._memberMap[r.id];
        }
        if (r.group && r.group.id === this.id) {
          delete r.group;
        }
        return true;
      } else {
        return false;
      }
    }
  }, {
    key: "getAllDirectEdges",
    value: function e(t) {
      var r = [],
        n;
      r.push.apply(r, this.getEdges(t).slice(0));
      var i = this.getPorts();
      for (n = 0; n < i.length; n++) {
        r.push.apply(r, i[n].getEdges(t));
      }
      return r;
    }
  }, {
    key: "getInternalEdges",
    value: function e(t) {
      var r = [],
        n = {};
      for (var i = 0; i < this.members.length; i++) {
        Array.prototype.push.apply(r, this.members[i].getAllEdges(t).filter(function (e) {
          var t = e.getId(),
            r = n[t] == null;
          n[t] = true;
          return r;
        }));
      }
      return r;
    }
  }]);
  return a;
}(j);
i(V, "objectType", "Group");
var S = function (u) {
  t(a, u);
  var o = r(a);
  function a(e) {
    var t;
    n(this, a);
    t = o.call(this, e.graph, e.data);
    i(s(t), "objectType", a.objectType);
    i(s(t), "source", void 0);
    i(s(t), "target", void 0);
    i(s(t), "cost", void 0);
    i(s(t), "directed", void 0);
    i(s(t), "id", void 0);
    i(s(t), "geometry", void 0);
    t.source = e.source;
    t.target = e.target;
    t.cost = e.cost || 1;
    t.directed = !(e.directed === false);
    t.id = e.id;
    return t;
  }
  e(a, [{
    key: "getCost",
    value: function e() {
      return this.cost;
    }
  }, {
    key: "setCost",
    value: function e(t) {
      this.cost = t;
    }
  }, {
    key: "getId",
    value: function e() {
      return this.id == null ? this.source.id + "_" + this.target.id : this.id;
    }
  }, {
    key: "getFullId",
    value: function e() {
      return this.getId();
    }
  }, {
    key: "setId",
    value: function e(t) {
      this.id = t;
    }
  }, {
    key: "isDirected",
    value: function e() {
      return this.directed;
    }
  }, {
    key: "setDirected",
    value: function e(t) {
      this.directed = t;
    }
  }, {
    key: "inspect",
    value: function e() {
      if (this.id != null) return "{ id:" + this.id + ", cost:" + this.cost + ", directed:" + this.directed + ", source:" + this.source.id + ", target:" + this.target.id + "}";
    }
  }]);
  return a;
}(F);
i(S, "objectType", "Edge");
export { F as Base, S as Edge, C as Graph, V as Group, j as Node, x as Port, P as Vertex, I as defaultIdFunction, m as isEdge, y as isGroup, E as isGroupVertex, k as isNestedGroup, f as isNode, v as isNodeInsideAGroup, T as isNodeVertex, b as isPort, _ as isPortVertex, p as resolveNode, h as resolveNodeId, c as resolvePortId };