import { Injectable } from '@angular/core';
import { IAction, ITaskGroup } from '@core/models';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterToolbarService {
  private actionGroups: ITaskGroup[] = [];
  private readonly filteredActionNames = ['tags', 'add-document', 'push-updates', 'accept-updates'];
  public hideToggle: boolean;
  public actionsUpdated$: Subject<ITaskGroup[]> = new Subject<ITaskGroup[]>();
  public formValid$: Subject<boolean> = new Subject<boolean>();
  public updateTaskGroup$: Subject<boolean> = new Subject<boolean>();
  public hideOptionalFields$: Subject<boolean> = new Subject<boolean>();
  public refreshView$: Subject<boolean> = new Subject<boolean>();

  public add(actions: IAction[], name?: string): void {
    const filteredActions = actions.filter(action => !this.filteredActionNames.includes(action.name!.toLowerCase()));
    if (!!filteredActions?.length) {
      const foundGroup = this.actionGroups?.find(group => group.name === name);
      if (!foundGroup) this.actionGroups.push({actions: filteredActions, name} as ITaskGroup);
      else {
        foundGroup.actions = filteredActions;
      }
      this.actionGroups = this.actionGroups.sort((a, _b) => {
        if (a.name === 'WIZARD') return 1;
        else if (a.name === 'PANELACTIONS') return 0;
        return -1;
      });
      this.actionsUpdated$.next(this.actionGroups);
    }
  }

  public get(): ITaskGroup[] {
    return this.actionGroups;
  }

  public getChecked(): boolean {
    return JSON.parse(localStorage.getItem('hide-optional-fields') || 'false');
  }

  public reset(): void {
    this.actionGroups = [];
    this.actionsUpdated$.next(this.actionGroups);
  }

  public setFormValid(value: boolean): void {
    this.formValid$.next(value);
  }
}
