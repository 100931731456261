import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormService, HttpService, DialogService, SnackbarService, UserService } from '@core/services';
import { BASE_URL, BI_PATH } from '@core/constants';
import { ITestCard } from '@core/models';
import { rowExpand } from '@shared/animations/table.animations';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { JsonPipe } from '@angular/common';
import { CardComponent } from '../../shared/components/card/card.component';
import { SelectComponent } from '../../shared/elements/select/select.component';
import { InputComponent } from '../../shared/elements/input/input.component';
import { ButtonComponent } from '../../shared/elements/button/button.component';
import { DividerComponent } from '../../shared/elements/divider/divider.component';
import { ListComponent } from '../../shared/components/list/list.component';
import { TableComponent } from '../../shared/components/table/table.component';
import { AutocompleteSingleComponent } from '../../shared/elements/autocomplete-single/autocomplete-single.component';

@Component({
  selector: 'app-test',
  standalone: true,
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
  animations: [rowExpand],
  imports: [CardComponent, SelectComponent, MatButtonToggleGroup, FormsModule, ReactiveFormsModule, MatButtonToggle, InputComponent, MatButton, ButtonComponent, MatIconButton, MatIcon, DividerComponent, ListComponent, TableComponent, NgxJsonViewerModule, JsonPipe, AutocompleteSingleComponent]
})
export class TestComponent implements OnInit, OnDestroy {

  public dataTypes = [
    {label: 'Form', value: 'form'},
    {label: 'List', value: 'list'},
    {label: 'Table', value: 'table'},
    {label: 'Response', value: 'response'}
  ];

  public _cards: ITestCard[] = [
    {
      type: new FormControl('response'),
      name: new FormControl('New 1'),
      control: new FormControl(''),
      dataUrl: null,
      response: null,
      requestType: new FormControl('get'),
      dynamics: [],
      removed$: new Subject<any>()
    }
  ];

  public editNames: ITestCard[] = [];
  public restUrl = `${BASE_URL}${BI_PATH}`;
  public urlOptions: { label: string; value: string }[] = [];
  private readonly subs: Subscription[] = [];

  public userData = this.userService.userData;

  constructor(
    private readonly formService: FormService,
    private readonly httpService: HttpService,
    private readonly dialogService: DialogService,
    private readonly snackbar: SnackbarService,
    private readonly userService: UserService
  ) {}

  public ngOnInit() {
    this.httpService.get('/api-docs/v3/?browse').subscribe(({ paths }) => {
      for (const key in paths) this.urlOptions.push({label: key, value: key});
    });
    this._cards.forEach(card => this.subs.push(card.control.valueChanges.pipe(
      takeUntil(card.removed$)
    ).subscribe(val => this.checkVal(val, card))));
  }

  public sendRequest(card: ITestCard) {
    card.response = null;
    const controlVal = card.control.value;
    const cardUrl = typeof controlVal === 'string' ? controlVal : controlVal.value;
    const cardDynamics = card.dynamics;
    if (!cardUrl) return;
    if (card.type.value === 'response') {
      if (card.requestType.value === 'get') {
        this.httpService.get(this.transformUrl(cardUrl, cardDynamics)).subscribe({
          next: res => card.response = res,
          error: err => card.response = err
        });
      } else {
        this.httpService.post(`${this.transformUrl(cardUrl, cardDynamics)}?commit=false`, {}).subscribe({
          next: res => card.response = res,
          error: err => card.response = err
        });
      }
    }
    if (card.type.value === 'form') this.formService.open(this.transformUrl(cardUrl, cardDynamics)).subscribe();
    card.dataUrl = this.transformUrl(cardUrl, cardDynamics);
  }

  public reset(card: ITestCard) {
    card.dataUrl = null;
    card.response = null;
    card.control.reset('');
  }

  public addCard() {
    const cardIndex = this._cards.length + 1;
    const newCard = {
      type: new FormControl('response'),
      name: new FormControl(`New ${cardIndex}`),
      control: new FormControl(''),
      dataUrl: null,
      response: null,
      requestType: new FormControl('get'),
      dynamics: [],
      removed$: new Subject<any>()
    };
    this.subs.push(newCard.control.valueChanges.pipe(
      takeUntil(newCard.removed$)
    ).subscribe(val => this.checkVal(val, newCard)));
    this._cards.push(newCard);
  }

  public resetCards() {
    this.dialogService.open({
      title: 'Reset cards',
      text: 'Are you sure you want to reset card? This will remove all the cards but the first.',
      type: 'alert',
      confirmLabel: 'Reset',
      confirmColor: 'danger',
      cancelLabel: 'Cancel'
    }).subscribe(res => {
      if (!res) return;
      this._cards.forEach((card: ITestCard, i: number) => !!i ? card.removed$.next(null) : null);
      this._cards = [this._cards[0]];
    });
  }

  public removeCard(card: ITestCard, index: number) {
    this.dialogService.open({
      title: `Remove "${card.name.value}"`,
      text: 'Are you sure you want to remove this card? This cannot be undone.',
      type: 'alert',
      confirmLabel: 'Remove',
      confirmColor: 'danger',
      cancelLabel: 'Cancel'
    }).subscribe(res => {
      if (!res) return;
      this._cards.splice(index, 1);
      card.removed$.next(null);
    });
  }

  public editName(card: ITestCard) {
    if (this.editNames.includes(card)) {
      const index = this.editNames.indexOf(card);
      this.editNames.splice(index, 1);
    } else this.editNames.push(card);
  }

  public divide(card: ITestCard) {
    const cardType = card.type.value;
    return !!card.dataUrl && ['list', 'table'].includes(cardType) || !!card.response && cardType === 'response';
  }

  private transformUrl(url: string, cardDynamics: { label: string; control: FormControl }[]) {
    const regex = /\{[\s\S]+\}/;
    const urlArr = url.split('/').map(seg => regex.test(seg) ? cardDynamics.find(dyn => dyn.label === seg)?.control.value : seg);
    return urlArr.join('/');
  }

  private checkVal(value: string | { label: string; value: string } | null, card: ITestCard) {
    const regex = /\{[\s\S]+\}/;
    const endpointArr = (typeof value === 'string' ? value?.split('/') : value?.label?.split('/')) || [];
    const dynamicArr: { label: string; control: FormControl }[] = [];
    endpointArr.forEach(val => {
      if (regex.test(val)) {
        const existingDynamic = card.dynamics.find(dyn => dyn.label === val);
        if (!!existingDynamic) dynamicArr.push(existingDynamic);
        else dynamicArr.push({label: val, control: new FormControl('')});
      }
    });
    card.dynamics = dynamicArr;
  }

  public ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public snackbarSuccess() {
    this.snackbar.open({text: 'Success', type: 'success', duration: 0});
  }

  public snackbarError() {
    this.snackbar.open({text: 'Error', type: 'error', duration: 0});
  }
}
