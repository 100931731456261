import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AUTH_TIMEOUT, ID_TOKEN } from '@core/constants';
import { EAuthContext } from '@core/enums';
import { AuthService, BeinformedService, UserService } from '@core/services';
import { fadeInOutAnimation } from '@shared/animations/core.animations';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-open-case',
  templateUrl: './open-case.component.html',
  styleUrls: ['./open-case.component.scss'],
  animations: [fadeInOutAnimation],
  standalone: true,
  imports: [TranslateModule]
})
export class OpenCaseComponent implements OnInit {

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly beinformedService: BeinformedService,
    private readonly authService: AuthService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly userService: UserService
  ) {}

  public ngOnInit() {
    const caseId = this.route.snapshot.params['id'];
    if (caseId === 'b2c') {
      const fragment = this.activatedRoute.snapshot.fragment;
      if (!!fragment?.includes(ID_TOKEN)) {
        this.authService.authContext = EAuthContext.SSO;
        const token = fragment.split('&').find(param => param.includes(ID_TOKEN))?.split('=')[1];
        this.authService.idTokenLogin(token || '').subscribe({
          next: () => {
            this.authService.auth = Date.now() + AUTH_TIMEOUT;
            this.userService.fetchUserData().subscribe(res => {
              const defaultOrg = res.userdata?.DefaultOrganizationID;
              const multiOrg = res.userdata?.sessiondata?.MultipleOrganizations === 'true';
              if (res.userdata.Roles?.includes('Administrator') || !multiOrg)
                void this.router.navigate([this.authService.redirectUrl || '/start']);
              else this.userService.getUserOrgs().subscribe(() => {
                if (defaultOrg) {
                  this.userService.organizationID = defaultOrg;
                  this.userService.defaultOrg = defaultOrg;
                  this.userService.submitOrganization(defaultOrg, null);
                } else void this.router.navigate(['/org']);
              });
            });
          },
          error: () => this.router.navigate(['/login'])
        });
      }
    } else this.beinformedService.openCase(caseId).subscribe(url => this.router.navigate([url]));
  }
}
