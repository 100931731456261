import { createClass as s, classCallCheck as i, defineProperty as t } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { EMPTY_BOUNDS as e } from "../../util/util.js";
var n = function () {
  function n(s, a) {
    i(this, n);
    this.endpoint = s;
    t(this, "typeId", void 0);
    t(this, "x", void 0);
    t(this, "y", void 0);
    t(this, "w", void 0);
    t(this, "h", void 0);
    t(this, "computedValue", void 0);
    t(this, "bounds", e());
    t(this, "classes", []);
    t(this, "instance", void 0);
    t(this, "type", void 0);
    a = a || {};
    this.instance = s.instance;
    if (s.cssClass) {
      this.classes.push(s.cssClass);
    }
    if (a.cssClass) {
      this.classes.push(a.cssClass);
    }
  }
  s(n, [{
    key: "addClass",
    value: function s(i) {
      this.classes.push(i);
      this.instance.addEndpointClass(this.endpoint, i);
    }
  }, {
    key: "removeClass",
    value: function s(i) {
      this.classes = this.classes.filter(function (s) {
        return s !== i;
      });
      this.instance.removeEndpointClass(this.endpoint, i);
    }
  }, {
    key: "setVisible",
    value: function s(i) {
      this.instance.setEndpointVisible(this.endpoint, i);
    }
  }]);
  return n;
}();
export { n as EndpointRepresentation };