import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@shared/vendors/material.module';
import { AvatarComponent } from '@shared/elements/avatar/avatar.component';
import { ButtonComponent } from '@shared/elements/button/button.component';
import { CheckboxComponent } from '@shared/elements/checkbox/checkbox.component';
import { ChipComponent } from '@shared/elements/chip/chip.component';
import { IconComponent } from '@shared/elements/icon/icon.component';
import { InputComponent } from '@shared/elements/input/input.component';
import { ProgressBarComponent } from '@shared/elements/progress-bar/progress-bar.component';
import { ProgressSpinnerComponent } from '@shared/elements/progress-spinner/progress-spinner.component';
import { RadioComponent } from '@shared/elements/radio/radio.component';
import { ScoreComponent } from '@shared/elements/score/score.component';
import { SelectComponent } from '@shared/elements/select/select.component';
import { SliderComponent } from '@shared/elements/slider/slider.component';
import { SlidetoggleComponent } from '@shared/elements/slidetoggle/slidetoggle.component';
import { DividerComponent } from '@shared/elements/divider/divider.component';
import { InputConditionComponent } from '@shared/elements/input-condition/input-condition.component';
import { OptionComponent } from '@shared/elements/option/option.component';
import { ToArrayPipe } from '@shared/pipes/to-array.pipe';
import { CompareObjPipe } from '@shared/pipes/compare-obj.pipe';
import { CheckboxGroupComponent } from '@shared/elements/checkbox-group/checkbox-group.component';
import { OrdinalPipe } from '@shared/pipes/ordinal.pipe';
import { HighlightPipe } from '@shared/pipes/highlight.pipe';
import { TranslateSelectorPipe } from '@shared/pipes/translate-selector.pipe';
import { FilterPipe } from '@shared/pipes/filter.pipe';
import { FindPipe } from '@shared/pipes/find.pipe';
import { PointPipe } from '@shared/pipes/point.pipe';
import { ToFrequencyStringPipe } from '@shared/pipes/frequency.pipe';
import { IndicatorComponent } from '@shared/elements/indicator/indicator.component';
import { MultiInputComponent } from '@shared/elements/multi-input/multi-input.component';
import { MultiSliderComponent } from '@shared/elements/multi-slider/multi-slider.component';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { AsTypePipe } from '@shared/pipes/as-type.pipe';
import { SortPipe } from '@shared/pipes/sort.pipe';
import { FirstUpperPipe } from '@shared/pipes/first-upper.pipe';
import { DecamelizePipe } from '@shared/pipes/decamelize.pipe';
import { StripPipe } from '@shared/pipes/strip.pipe';
import { FileIconPipe } from '@shared/pipes/file-icon.pipe';
import { AnswerToggleComponent } from './answer-toggle/answer-toggle.component';
import { CheckedTreeComponent } from './checked-tree/checked-tree.component';
import { AutocompleteSingleComponent } from './autocomplete-single/autocomplete-single.component';
import { AutocompleteMultiComponent } from './autocomplete-multi/autocomplete-multi.component';
import { DynamicDashboardCardComponent } from './dynamic-dashboard-card/dynamic-dashboard-card.component';

@NgModule({
  imports: [
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    FileIconPipe,
    AvatarComponent,
    ButtonComponent,
    CheckboxComponent,
    ChipComponent,
    IconComponent,
    InputComponent,
    ProgressBarComponent,
    ProgressSpinnerComponent,
    RadioComponent,
    ScoreComponent,
    SelectComponent,
    SliderComponent,
    SlidetoggleComponent,
    DividerComponent,
    InputConditionComponent,
    OptionComponent,
    ToArrayPipe,
    CheckboxGroupComponent,
    OrdinalPipe,
    CompareObjPipe,
    HighlightPipe,
    TranslateSelectorPipe,
    FilterPipe,
    FindPipe,
    PointPipe,
    IndicatorComponent,
    MultiInputComponent,
    MultiSliderComponent,
    ToFrequencyStringPipe,
    SafeHtmlPipe,
    AnswerToggleComponent,
    AsTypePipe,
    SortPipe,
    FirstUpperPipe,
    DecamelizePipe,
    StripPipe,
    CheckedTreeComponent,
    AutocompleteSingleComponent,
    AutocompleteMultiComponent,
    DynamicDashboardCardComponent
  ],
  exports: [
    ReactiveFormsModule,
    InputComponent,
    SlidetoggleComponent,
    ButtonComponent,
    CheckboxComponent,
    RadioComponent,
    SliderComponent,
    ProgressBarComponent,
    IconComponent,
    ProgressSpinnerComponent,
    SelectComponent,
    AvatarComponent,
    DividerComponent,
    InputConditionComponent,
    ChipComponent,
    OptionComponent,
    ToArrayPipe,
    CheckboxGroupComponent,
    OrdinalPipe,
    CompareObjPipe,
    HighlightPipe,
    TranslateSelectorPipe,
    FilterPipe,
    FindPipe,
    PointPipe,
    IndicatorComponent,
    MultiInputComponent,
    MultiSliderComponent,
    ToFrequencyStringPipe,
    SafeHtmlPipe,
    AnswerToggleComponent,
    AsTypePipe,
    SortPipe,
    FirstUpperPipe,
    DecamelizePipe,
    StripPipe,
    CheckedTreeComponent,
    AutocompleteSingleComponent,
    AutocompleteMultiComponent,
    FileIconPipe,
    ScoreComponent,
    DynamicDashboardCardComponent
  ]
})
export class ElementsModule {}
