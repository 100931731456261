import { CdkDropList } from '@angular/cdk/drag-drop';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { DragAndDropManagerService } from '@core/services/drag-and-drop-manager.service';
import { Subscription } from 'rxjs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[dragAndDropManager]',
  standalone: true
})
export class DragAndDropManagerDirective implements OnInit, OnDestroy {
  private manager!: Subscription;
  constructor(
    private readonly dropList: CdkDropList,
    private readonly managerService: DragAndDropManagerService
  ) { }
  public ngOnInit(): void {
    this.managerService.register(this.dropList.id);
    this.manager = this.managerService.onListChange().subscribe(x => {
      this.dropList.connectedTo = x.reverse();
    });
  }
  public ngOnDestroy(): void {
    this.manager.unsubscribe();
    this.managerService.unregister(this.dropList.id);
  }
}
// Create a root directive that uses scoped drag&drop manager service.
// This allows us to have multiple independant nested drag&drop lists
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[dragAndDropManagerRoot]',
  providers: [
    {
      provide: DragAndDropManagerService
    }
  ],
  standalone: true
})
export class DragAndDropManagerRootDirective extends DragAndDropManagerDirective {
}