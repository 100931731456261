import { registerEndpointRenderer as t } from "./browser-jsplumb-instance.js";
import { _node as r, _attr as n } from "./svg-util.js";
import { BlankEndpoint as e } from "../core/endpoint/blank-endpoint.js";
var o = {
  width: 10,
  height: 0,
  fill: "transparent",
  stroke: "transparent"
};
var i = function i() {
  t(e.type, {
    makeNode: function t(n, e) {
      return r("rect", o);
    },
    updateNode: function t(r, e) {
      n(e, o);
    }
  });
};
export { i as register };