var a = {
  FILL: "#FFFFFF",
  STROKE: "#000000",
  TEXT_COLOR: "#000000"
};
var r = "left";
var e = "center";
var v = "right";
var t = "Default";
var F = "Default shapes";
var h = "viewBox";
var l = "jtk-shape";
var p = "jtk-shape-label";
var s = "jtk-shape";
export { s as CLASS_SHAPE, p as CLASS_SHAPE_LABEL, F as DEFAULT_SET_DESCRIPTION, t as DEFAULT_SET_NAME, e as ICON_ORIGIN_CENTER, r as ICON_ORIGIN_LEFT, v as ICON_ORIGIN_RIGHT, a as ShapeLibraryDefaults, l as TAG_SHAPE, h as VIEW_BOX };