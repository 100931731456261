import { PerimeterAnchorShapes as r, AnchorLocations as o } from "./definitions.js";
import { _createAnchor as n } from "./anchor-record-factory.js";
function t(r) {
  var o = .5,
    n = Math.PI * 2 / r,
    t = [];
  var e = 0;
  for (var a = 0; a < r; a++) {
    var i = o + o * Math.sin(e),
      f = o + o * Math.cos(e);
    t.push({
      x: i,
      y: f,
      ox: 0,
      oy: 0,
      offx: 0,
      offy: 0,
      iox: 0,
      ioy: 0,
      cls: ""
    });
    e += n;
  }
  return t;
}
function e(r, o) {
  var n = o / r.length,
    t = [],
    e = function r(e, a, i, f, s, h, u) {
      n = o * s;
      var c = (i - e) / n,
        l = (f - a) / n;
      for (var p = 0; p < n; p++) {
        t.push({
          x: e + c * p,
          y: a + l * p,
          ox: h == null ? 0 : h,
          oy: u == null ? 0 : u,
          offx: 0,
          offy: 0,
          iox: 0,
          ioy: 0,
          cls: ""
        });
      }
    };
  for (var a = 0; a < r.length; a++) {
    e.apply(null, r[a]);
  }
  return t;
}
function a(r, o) {
  var n = [];
  for (var t = 0; t < r.length; t++) {
    n.push([r[t][0], r[t][1], r[t][2], r[t][3], 1 / r.length, r[t][4], r[t][5]]);
  }
  return e(n, o);
}
function i(r) {
  return a([[0, 0, 1, 0, 0, -1], [1, 0, 1, 1, 1, 0], [1, 1, 0, 1, 0, 1], [0, 1, 0, 0, -1, 0]], r);
}
function f(r) {
  return a([[.5, 0, 1, .5], [1, .5, .5, 1], [.5, 1, 0, .5], [0, .5, .5, 0]], r);
}
function s(r) {
  return a([[.5, 0, 1, 1], [1, 1, 0, 1], [0, 1, .5, 0]], r);
}
function h(r, o) {
  var n = [],
    t = o / 180 * Math.PI;
  for (var e = 0; e < r.length; e++) {
    var a = r[e].x - .5,
      i = r[e].y - .5;
    n.push({
      x: .5 + (a * Math.cos(t) - i * Math.sin(t)),
      y: .5 + (a * Math.sin(t) + i * Math.cos(t)),
      ox: r[e].ox,
      oy: r[e].oy,
      offx: 0,
      offy: 0,
      iox: 0,
      ioy: 0,
      cls: ""
    });
  }
  return n;
}
var u = new Map();
u.set(r.Circle, t);
u.set(r.Ellipse, t);
u.set(r.Rectangle, i);
u.set(r.Square, i);
u.set(r.Diamond, f);
u.set(r.Triangle, s);
function c(r) {
  r = r || {};
  var t = r.anchorCount || 60,
    e = r.shape;
  if (!e) {
    throw new Error("no shape supplied to Perimeter Anchor type");
  }
  if (!u.has(e)) {
    throw new Error("Shape [" + e + "] is unknown by Perimeter Anchor type");
  }
  var a = u.get(e)(t);
  if (r.rotation) {
    a = h(a, r.rotation);
  }
  var i = n(o.Perimeter, a, r);
  var f = Object.assign(i, {
    shape: e
  });
  return f;
}
export { c as _createPerimeterAnchor };