import { AfterContentChecked, Component, ElementRef, Inject, OnInit, ViewChild, HostListener } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BeinformedService, HttpService, SnackbarService, TaskgroupService } from '@core/services';
import { FormControl } from '@angular/forms';
import { CompareService } from '@core/services/compare.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { CdkOverlayOrigin, CdkConnectedOverlay } from '@angular/cdk/overlay';
import { JsonPipe } from '@angular/common';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { ToolbarItemComponent } from '../toolbar/toolbar-item/toolbar-item.component';
import { ButtonComponent } from '../../elements/button/button.component';
import { IconComponent } from '../../elements/icon/icon.component';
import { AnswerToggleComponent } from '../../elements/answer-toggle/answer-toggle.component';
import { DividerComponent } from '../../elements/divider/divider.component';
import { RadioComponent } from '../../elements/radio/radio.component';
import { JsonCompareSelectNotificationComponent } from './json-compare-select-notification/json-compare-select-notification.component';
import { JSONCompareInputComponent } from './json-compare-input/json-compare-input.component';
import { JsonCompareTreeComponent } from './json-compare-tree/json-compare-tree.component';
import { fadeInDelayedOutAnimation, overlayButtonShow, overlayShow } from './json-compare-animations';
import type { IContributionsAttributeOption, IFormAnchorElement, IFormContributions, IFormContributionsObject, TFormContributionsObjects, IFormResponse, IAction, IFormResult, IInputOption, IHref, IPostableObject, TContributionsAttribute, ITreeviewCompareItem } from '@core/models';

@Component({
  selector: 'naris-json-compare',
  templateUrl: './json-compare.component.html',
  styleUrls: ['./json-compare.component.scss'],
  animations: [fadeInDelayedOutAnimation, overlayShow, overlayButtonShow],
  standalone: true,
  imports: [ToolbarComponent, ToolbarItemComponent, ButtonComponent, IconComponent, CdkOverlayOrigin, CdkConnectedOverlay, AnswerToggleComponent, JsonCompareSelectNotificationComponent, JSONCompareInputComponent, JsonCompareTreeComponent, DividerComponent, RadioComponent, JsonPipe, TranslateModule]
})
export class JSONCompareComponent implements OnInit, AfterContentChecked {

  public compareData: Record<string, any> | null = null;
  public selectedItems: Record<string, any>[];
  public selectedNotificationItems: string[];
  public sameValueItems: Record<string, any>[];
  public pickChanges = false;
  public tableLabels: string[];
  public dialogTitle = 'compare.dialog_title';
  public showCompare = true;
  public showChoice = false;
  public singleObject = false;
  public showTreeCompare = false;
  public notificationSelectionData: IFormAnchorElement[];
  public compareExecution = true;
  public buttonOptions?: IContributionsAttributeOption[];
  public helperText?: string;
  public showPushUpdatesWithoutAcceptation?: boolean;
  public pushUpdatesWithoutAcceptationInput = {type: 'boolean'};
  public pushUpdatesWithoutAcceptationControl = new FormControl();
  public pushUpdatesWithoutAcceptationLabel: string;
  public pushUpdatesWithoutAcceptationAssistant: string;
  public choiceElements: IFormAnchorElement[] = [];
  public updateAll = false;
  public changeOptions: IInputOption[] = [];
  public optionControl = new FormControl(null);
  public overlayTop = 0;
  public overlayBottom = 0;
  public overlayWidth = 0;
  public overlayHeight = 0;
  public showOverlay = false;
  public treeOldValue: any[];
  public treeNewValue: any[];
  private notificationObjectId: string;
  private tokens?: string[];
  private objectId?: keyof TFormContributionsObjects;
  private savedPostableObject: any;
  private redirect = false;
  private selfUrl?: IHref | null = null;
  private formObjects = {};
  public innerWidth: any;
  private selectedTreeItem: ITreeviewCompareItem;
  private readonly treeCompareAcceptedItems: any[] = [];
  public readOnlyKey: string | undefined;
  public notificationStructureItemsChanged: any ;
  private contributions: TFormContributionsObjects;
  public isSaving = false;
  public showAssistantOverlay = false;

  @ViewChild('mainContent') public mainContent: ElementRef;

  constructor(
    private readonly beinformedService: BeinformedService,
    private readonly httpService: HttpService,
    private readonly snackbarService: SnackbarService,
    private readonly taskgroupService: TaskgroupService,
    private readonly router: Router,
    private readonly dialogRef: MatDialogRef<JSONCompareComponent>,
    private readonly compareService: CompareService,
    private readonly translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: { action: IAction }
  ) {}

  public ngOnInit(): void {
    this.pushUpdatesWithoutAcceptationControl.setValue(false);
    this.innerWidth = window.innerWidth;
    const url = this.data.action.href!;
    this.redirect = !!this.data?.action?.layouthint?.map((hint: string) => hint.toLowerCase()).includes('redirect');
    
    this.beinformedService.fetchForm(url).subscribe(this.processResult);
    this.compareService.compareData$.subscribe(item => {
      this.selectedTreeItem = item;
      this.readOnlyKey = item.readOnlyKey;
      this.showOverlay = true;
      const oldProperties = this.treeOldValue.find(oldItem => oldItem.id === item.id)?.Properties;
      const newProperties = this.treeNewValue.find(newItem => newItem.id === item.id)?.Properties;
      const oldObjects = this.treeOldValue.find(oldItem => oldItem.id === item.id)?.Objects;
      const newObjects = this.treeNewValue.find(newItem => newItem.id === item.id)?.Objects;
      this.compareData = {};
      if (!!oldProperties && !!newProperties) {
        this.compareData.OLD_Properties = [oldProperties];
        this.compareData.NEW_Properties = [newProperties];
      }
      if (!!oldObjects && !!newObjects) {
        const oldObjectKeys = Object.keys(oldObjects);
        const newObjectKeys = Object.keys(newObjects);
        const concatArray = [...oldObjectKeys, ...newObjectKeys];
        const arrayUnion = [...new Set(concatArray)];
        arrayUnion.forEach(key => {
          this.compareData![`OLD_${key}`] = oldObjects[key];
          this.compareData![`NEW_${key}`] = newObjects[key];
        });
      }
    });
  }
  
  public ngAfterContentChecked(): void {
    setTimeout(() => {
      const el = this.mainContent.nativeElement;
      this.overlayTop = el.offsetTop;
      this.overlayWidth = el.offsetWidth;
      this.overlayHeight = el.offsetHeight;
      this.overlayBottom = this.overlayTop + this.overlayHeight;
    }, 200);
  }

  private readonly processResult = (result: IFormResult, choice = false) => {
    const anchors = result?.data?.error?.formresponse?.missing?.anchors;
    const objectId = !!anchors ? anchors[0].objectid : null;
    this.pickChanges = !objectId?.includes('_notification_acceptUpdates') && !objectId?.endsWith('_pushAllUpdates') && !objectId?.endsWith('_pushUpdates');
    const foundAnchor = anchors?.find(anchor => anchor.objectid.toLowerCase().includes('_pushupdates') || anchor.objectid.toLowerCase().includes('_pushallupdates') || anchor.objectid.toLowerCase().includes('_acceptupdates') || anchor.objectid.toLowerCase().includes('changetype'));
    this.objectId = foundAnchor?.objectid;
    this.tokens = result?.data?.error?.formresponse?.tokens;
    
    this.contributions = result.contributions.objects;
    this.singleObject = Object.keys(this.contributions).length === 1;
    const contributions = result.contributions.objects[this.objectId!].attributes;
    const notificationStructureItemsChangedObject = contributions.find(item => Object.keys(item)[0] === 'NotificationStructureItemsChanged');
    this.notificationStructureItemsChanged = !!notificationStructureItemsChangedObject ? (notificationStructureItemsChangedObject.NotificationStructureItemsChanged?.text as any)?.['message'] : '';
    const hasStructureItems = !!contributions.find(item => Object.keys(item)[0].toLowerCase().includes('structure') && !item.NotificationStructureItemsChanged);
    this.processData(foundAnchor?.elements, contributions);
    this.dialogTitle = result.contributions.objects[this.objectId!].label;
    if (this.objectId === 'ChangeType') {
      this.showChoice = true;
      this.showCompare = false;
      this.showTreeCompare = false;
      this.choiceElements = foundAnchor!.elements;
      const formResponse = result?.data?.error?.formresponse;
      this.selfUrl = formResponse._links?.self;
      const foundElement = foundAnchor?.elements.find(el => el.elementid === 'ChangeTypeID');
      this.changeOptions = foundElement?.dynamicschema?.map(({ code: value, elements: { ObjectType: key, TestType: type } }) => ({key: type === null ? this.translate.instant(key as string) : `${this.translate.instant(key as string)} - ${this.translate.instant(type as string)}`, value})) as IInputOption[] || [];
      return;
    } else if (this.objectId?.toLowerCase().includes('compliance') && hasStructureItems) {
      this.showChoice = false;
      this.showCompare = false;
      this.showTreeCompare = true;
      const ids = foundAnchor?.elements?.map(item => item.elementid).filter(item => item.toLowerCase().includes('structure_items'));
      const oldItemId = ids?.find(item => item.startsWith('OLD_'));
      const newItemId = ids?.find(item => item.startsWith('NEW_'));
      const oldItem = foundAnchor?.elements.find(element => element.elementid === oldItemId)?.value;
      const newItem = foundAnchor?.elements.find(element => element.elementid === newItemId)?.value;
      this.treeOldValue = !!oldItem ? JSON.parse(oldItem) : null;
      this.treeNewValue = !!newItem ? JSON.parse(newItem) : null;

      if (!!this.treeOldValue && !!this.treeNewValue) {
        this.checkChanged();
      }
      this.compareService.updateAccepted$.subscribe(isAccepted => {
        this.showOverlay = false;
        this.compareService.setUserAccepted$.next(this.selectedTreeItem.id as number);
        if (!!isAccepted) {
          const selectedUpdates = {id: this.selectedTreeItem.id, updates: this.preparePostableObject()};
          const foundItem = this.treeCompareAcceptedItems.find(item => item.id === selectedUpdates.id);
          if (!!foundItem) {
            foundItem.updates = selectedUpdates.updates;
          } else {
            this.treeCompareAcceptedItems.push(selectedUpdates);
          }
        }
      });
    }
    if (choice) {
      this.showChoice = false;
      this.showCompare = true;
      this.showTreeCompare = false;
    }
    this.compareData = this.processData(foundAnchor?.elements, contributions);
    this.tableLabels = result.contributions?.objects?.[this.objectId!]?.label?.split('|');
    this.dialogTitle = result.contributions?.label;
    this.prepareForExecution(result);
  };

  private checkChanged() {
    const changedItems = this.treeOldValue.filter(item => item.kindOfChange.includes('Changed'));
    changedItems.forEach(item => {
      const newItem = this.treeNewValue.find(value => value.id === item.id);
      if (!!newItem) {
        const oldPropertyString = JSON.stringify(item.Properties);
        const newPropertyString = JSON.stringify(newItem.Properties);
        const oldObjectsString = JSON.stringify(item.Objects);
        const newObjectsString = JSON.stringify(newItem.Objects);
        
        const hasChanges = oldPropertyString !== newPropertyString || oldObjectsString !== newObjectsString;
        if (!hasChanges) {
          item.kindOfChange = item.kindOfChange.replace('Changed', 'NoChange');
          newItem.kindOfChange = newItem.kindOfChange.replace('Changed', 'NoChange');
        }
      }
    });
  }

  private processData(dataElements?: IFormAnchorElement[], contributions?: TContributionsAttribute[]) {
    this.showPushUpdatesWithoutAcceptation = false;
    return dataElements?.reduce<typeof this.compareData>((acc, { elementid, value, suggestion }) => {
      if ((elementid.includes('OLD_') || elementid.includes('NEW_')) && (!!value || !!suggestion)) {
        const key = !!value ? value : suggestion;
        const jsonData = !!key ? JSON.parse(key) : null;
        return {...acc, [elementid]: jsonData};
      } else if (elementid.toLowerCase().includes('helptext')) {
        const foundContributionValue = contributions?.find(item => Object.keys(item)[0] === elementid);
        this.helperText = !!foundContributionValue ? (foundContributionValue[elementid].text as any).message : '';
      } else if (elementid.includes('PushUpdatesWithoutAcceptation')) {
        this.showPushUpdatesWithoutAcceptation = !!contributions?.find(item => Object.keys(item)[0] === elementid);
        this.pushUpdatesWithoutAcceptationLabel = contributions?.find(attr => Object.keys(attr)?.[0] === 'PushUpdatesWithoutAcceptation')?.PushUpdatesWithoutAcceptation.label || '';
        this.pushUpdatesWithoutAcceptationAssistant = contributions?.find(attr => Object.keys(attr)?.[0] === 'PushUpdatesWithoutAcceptation')?.PushUpdatesWithoutAcceptation.assistant || '';
        this.pushUpdatesWithoutAcceptationControl.setValue(suggestion);
      } else if (elementid.includes('CaseIDs')) {
        const element = dataElements.find(e => e.elementid === elementid);
        if (!!element)
          this.notificationSelectionData = [element];
      }
      return acc;
    }, {}) || {};
  }

  public saveAndClose(mustSave: boolean, option?: IContributionsAttributeOption): void {
    this.isSaving = true;
    this.helperText = undefined;
    const postableObject = {
      tokens: this.tokens,
      objects: (Object.keys(this.formObjects).length > 0 ? this.formObjects : {[this.objectId!]: {}}) as Partial<IFormContributionsObject>
    };
    if (!mustSave) return this.dialogRef?.close(false);
    if (this.showCompare) this.saveComparison(postableObject, option);
    else if (!this.showCompare && this.showChoice) this.onChoice();
    else if (this.showTreeCompare) this.saveTreeComparison(postableObject);
    else this.saveNotificationSelection(postableObject);
  }

  public onChoice(): void {
    this.formObjects = {ChangeType: {ChangeTypeID: this.optionControl.value, UpdateAll: false}};
    const formBody = {tokens: this.tokens, objects: this.formObjects};
    if (this.showPushUpdatesWithoutAcceptation && !!this.objectId) {
      (formBody.objects as Record<string, Record<string, any>>)[this.objectId]['PushUpdatesWithoutAcceptation'] = this.pushUpdatesWithoutAcceptationControl.value;
    }
    this.beinformedService.fetchForm(this.selfUrl?.href || '/', true, formBody).subscribe(res => this.processResult(res, true));
  }

  public onUpdateAll(): void {
    const postableObject = {
      tokens: this.tokens, 
      objects: {
        ChangeType: {
          UpdateAll: true
        }
      }
    };

    if (this.showPushUpdatesWithoutAcceptation) {
      (postableObject.objects as Record<string, Record<string, any>>)['ChangeType']['PushUpdatesWithoutAcceptation'] = this.pushUpdatesWithoutAcceptationControl.value;
    }
    this.httpService.post(this.selfUrl?.href || '/', postableObject, false).subscribe(res => {
      if (!!res?.formresponse?.success?.redirect) {
        this.dialogRef?.close(true);
        void this.router.navigate([res.formresponse.success.redirect]);
      }
    });
  }

  private saveComparison(postableObject: IPostableObject, option?: IContributionsAttributeOption): void {
    const url = this.data.action.href!;
    if (this.pickChanges) {
      postableObject.objects = this.preparePostableObject();
      postableObject.objects[this.objectId!] = {};
    } else (postableObject.objects! as Record<string, Record<string, any>>)[this.objectId!] = {};
    if (this.compareExecution) (postableObject.objects! as Record<string, Record<string, any>>)[this.objectId!]['Continue'] = option?.code;       
    if (this.showPushUpdatesWithoutAcceptation && !!this.objectId) {
      (postableObject.objects! as Record<string, Record<string, any>>)[this.objectId]['PushUpdatesWithoutAcceptation'] = this.pushUpdatesWithoutAcceptationControl.value;
    }
    this.savedPostableObject = postableObject;    
    if (!!this.notificationSelectionData) {
      this.saveNotificationSelection(postableObject);
    } else this.postObject(url, postableObject);
  }

  private saveNotificationSelection(postableObject: IPostableObject): void {
    const url = this.data.action.href!;
    postableObject = this.savedPostableObject ?? postableObject;
    if (!!this.notificationObjectId)  {
      (postableObject.objects! as Record<string, Record<string, any>>)[this.notificationObjectId] = {};
    }
    if (!!this.selectedNotificationItems?.length) {
      const resultArray = this.selectedNotificationItems.join(',');
      (postableObject.objects! as Record<string, Record<string, any>>)[this.objectId!].CaseIDs = resultArray;
    }    
    if (this.showPushUpdatesWithoutAcceptation && !!this.objectId) {
      (postableObject.objects! as Record<string, Record<string, any>>)[this.objectId]['PushUpdatesWithoutAcceptation'] = this.pushUpdatesWithoutAcceptationControl.value;
    }
    this.postObject(url, postableObject);
  }

  private saveTreeComparison(passedPostableObject: IPostableObject) {
    const postableObject = passedPostableObject;
    const url = this.data.action.href!;
    if (this.data.action.name?.includes('accept')) {
      (postableObject.objects! as Record<string, Record<string, any>>).Results = {};
      (postableObject.objects! as Record<string, Record<string, any>>).Results[this.objectId!] = JSON.stringify(this.treeCompareAcceptedItems);
    }
    if (this.showPushUpdatesWithoutAcceptation) {
      (postableObject.objects! as Record<string, Record<string, any>>)[this.objectId!]['PushUpdatesWithoutAcceptation'] = this.pushUpdatesWithoutAcceptationControl.value;
    }
    this.postObject(url, postableObject);
  }

  private postObject(url: string, postableObject: any): void {
    this.beinformedService.fetchForm(url, true, postableObject).subscribe(res => {
      if (!!res?.data?.error && res?.data?.status === 400) {
        const formAnchor = res.data.error.formresponse.missing!.anchors[0];
        this.notificationObjectId = formAnchor?.objectid;
        if (!!this.notificationObjectId) {
          this.showCompare = false;
          this.notificationSelectionData = formAnchor?.elements;
          this.objectId = this.notificationObjectId;
          this.processData(this.notificationSelectionData, this.contributions[this.notificationObjectId].attributes);
          const emptyTitle = this.dialogTitle.split('-')?.[0];
          const objectTitle = res.contributions?.objects[formAnchor?.objectid] ? ` - ${res.contributions.objects[formAnchor.objectid].label}` : '';
          this.dialogTitle = `${emptyTitle}${objectTitle}`;
        }
        
      } else {
        this.closeCompare(res.data);
      }
      this.isSaving = false;
    });
  }

  private readonly closeCompare = (result: IFormResponse) => {
    this.snackbarService.open({ text: 'snackbar.successfully', type: 'success' });
    if (!this.objectId?.includes('EXE_')) this.taskgroupService.refreshPage.next(true);
    this.dialogRef?.close(true);
    if (this.redirect) {
      const redirectHref = result.formresponse?.success?.redirect;
      //! Tijdelijke fix, moet een andere oplossing voor gevonden worden
      window.location.href = window.location.origin + redirectHref;
    }
  };

  private preparePostableObject() {
    const returnObject = {} as TFormContributionsObjects;
    this.selectedItems.forEach(item => {
      const parentName = `Results_${item.parent}`;
      if (['properties', 'testdefinition', 'cause'].includes(item.parent.toLowerCase())) {
        const objectStructure = this.compareData?.[`NEW_${item.parent}`]?.[0];
        if (!objectStructure) return;
        if (!returnObject[parentName]) returnObject[parentName] = {} as IFormContributionsObject;
        if (Array.isArray(objectStructure[item.key])) {
          const arrObject = this.getArrayObjectValue(item);
          if (!!arrObject) {
            const itemKey = item.key as keyof IFormContributionsObject;
            if (!returnObject[parentName][itemKey]) returnObject[parentName][itemKey] = '';
            else returnObject[parentName][itemKey] += ',';
            returnObject[parentName][itemKey] += arrObject;
          }
        } else {
          if (item.isNewSelected && !!item.key) returnObject[parentName][item.key] = item.new;
          else if (!item.isNewSelected && !!item.key) returnObject[parentName][item.key] = item.old;
          else if (item.isNewSelected && !!item.parent) {
            if (!returnObject[parentName][item.parent]) returnObject[parentName][item.parent] = '';
            if (!!returnObject[parentName][item.parent]) returnObject[parentName][item.parent] += ',';
            returnObject[parentName][item.parent] += this.getArrayObjectValue(item);
          }
        }
      } else if (item.parent.toLowerCase() === 'frequency') {
        if (!returnObject[parentName]) returnObject[parentName] = {} as IFormContributionsObject;
        if (item.isNewSelected) returnObject[parentName] = item.new;
        else if (!item.isNewSelected) returnObject[parentName] = item.old;
        if (!!returnObject[parentName].Period?.length) {
          const periodKeys = Object.keys(returnObject[parentName].Period?.[0] || {});
          periodKeys.forEach(periodKey => {
            returnObject[parentName][`Period_${periodKey}`] = returnObject[parentName].Period[0][periodKey];
          });
          delete returnObject[parentName].Period;
        }
      } else if (item.parent.toLowerCase().includes('guidelines')) {
        returnObject[parentName] = {[item.key]: item.isNewSelected ? item.new : item.old} as IFormContributionsObject;
      } else {
        const arrObject = this.getArrayObjectValue(item);
        if (!!arrObject) {
          if (!returnObject[parentName]) returnObject[parentName] = {} as IFormContributionsObject;
          const itemParent = item.parent as keyof IFormContributionsObject;
          if (!returnObject[parentName][itemParent]) returnObject[parentName][itemParent] = '';
          else returnObject[parentName][itemParent] += ',';
          returnObject[parentName][itemParent] += arrObject;
        }
      }
    });
    this.addSameValueItems(returnObject);
    return returnObject;
  }

  private addSameValueItems(returnObject: any) {
    this.selectedItems.forEach(item => {
      const parentName = `Results_${item.parent}`;
      const key = ['properties', 'testdefinition'].includes(item.parent.toLowerCase()) ? item.key : item.parent;
      const valueItem = this.sameValueItems.find(sameValueItem => sameValueItem.name === key);
      valueItem?.compareData.forEach((sameValueItem: Record<string, any>) => {
        const arrObject = this.getArrayObjectValue({isNewSelected: true, new: sameValueItem.value});
        if (!!arrObject) {
          if (!returnObject[parentName]) returnObject[parentName] = {};
          if (!returnObject[parentName][key]?.includes(arrObject)) {
            if (!returnObject[parentName][key]) returnObject[parentName][key] = '';
            else returnObject[parentName][key] += ',';
            returnObject[parentName][key] += arrObject;
          }
        }
      });
    });
  }

  private getArrayObjectValue(item: Record<string, any>) {
    let dataString = '';
    if (item.isNewSelected && item.new !== '-') {
      const keys = Object.keys(item.new).filter(key => key.toLowerCase().endsWith('id'));
      keys.forEach(key => {
        if (dataString !== '') dataString += '|';
        dataString += `${key}:${item.new[key]}`;
      });
    } else if (!item.isNewSelected && item.old !== '-') {
      const keys = Object.keys(item.old).filter(key => key.toLowerCase().endsWith('id'));
      keys.forEach(key => {
        if (dataString !== '') dataString += '|';
        dataString += `${key}:${item.old[key]}`;
      });
    }
    return dataString;
  }

  private prepareForExecution(result: { data: IFormResponse; contributions: IFormContributions; objectName: string }) {
    const dataElements = result?.data?.error?.formresponse?.missing?.anchors?.find(anchor => anchor.objectid.toLowerCase() === this.objectId?.toLowerCase())?.elements;
    const continueElement = dataElements?.find(element => element.elementid.toLowerCase() === 'continue');
    if (this.data?.action?.layouthint?.includes('auto-json-compare') && !!continueElement) {
      const continueObject = result.contributions.objects[this.objectId!]?.attributes?.find(element => Object.keys(element).map(item => item.toLowerCase()).includes('continue'));
      this.buttonOptions = continueObject?.['Continue'].options;
      this.compareExecution = true;
    } else this.compareExecution = false;
    this.isSaving = false;
  }

  public updateControlsChanged(event: MatSlideToggleChange) {
    this.updateAll = event.checked;
  }

  public getHeight(height: number) {
    return setTimeout(() => this.showOverlay ? height : 0, 2000);
  }

  @HostListener('document:mousewheel', ['$event'])
  public onScroll(_event: any) {
    this.compareService.updatePositions$.next(true);
  }
}
