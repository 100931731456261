import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AppLayoutComponent } from '@layouts/app-layout/app-layout.component';
import { AuthLayoutComponent } from '@layouts/auth-layout/auth-layout.component';
import { LoginComponent } from '@features/auth/login/login.component';
import { SignupComponent } from '@features/auth/signup/signup.component';
import { SignupInfoComponent } from '@features/auth/signup-info/signup-info.component';
import { PwForgotComponent } from '@features/auth/pw-forgot/pw-forgot.component';
import { PwChangeComponent } from '@features/auth/pw-change/pw-change.component';
import { AuthErrorComponent } from '@features/auth/auth-error/auth-error.component';
import { OrgChoiceComponent } from '@features/auth/org-choice/org-choice.component';
import { LinkAccountComponent } from '@features/auth/link-account/link-account.component';
import { TestComponent } from '@features/test/test.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { OpenCaseComponent } from '@features/auth/open-case/open-case.component';
import { StreamGuard } from '@core/guards/stream.guard';
import { DatafeedsComponent } from '@features/datafeeds/datafeeds/datafeeds.component';
import { CollaborationRoomComponent } from '@shared/components/collaboration/collaboration-room/collaboration-room.component';
import { DoQuestionnaireComponent } from '@features/collaboration/do-questionnaire/do-questionnaire.component';

const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: '', component: AuthLayoutComponent, children: [
    {path: 'login', component: LoginComponent},
    {path: 'signup', component: SignupComponent},
    {path: 'signup/info', component: SignupInfoComponent},
    {path: 'forgot', component: PwForgotComponent},
    {path: 'change', component: PwChangeComponent},
    {path: 'error', component: AuthErrorComponent},
    {path: 'link-account', component: LinkAccountComponent},
    {path: 'org', component: OrgChoiceComponent, canActivate: [AuthGuard]},
    {path: 'do-questionnaire', component: DoQuestionnaireComponent}
  ]},
  {path: '', component: AppLayoutComponent, children: [
    {path: 'start', redirectTo: '/dashboard'},
    {path: 'test', component: TestComponent, data: {breadcrumb: 'breadcrumb.test'}, canActivate: [AuthGuard]},
    {path: 'datafeeds', component: DatafeedsComponent, data: {breadcrumb: 'breadcrumb.datafeeds'}, canActivate: [AuthGuard]},
    {path: 'collaboration/collaboration/:room/session', component: CollaborationRoomComponent, data: {breadcrumb: 'breadcrumb.collaboration'}, canActivate: [AuthGuard, StreamGuard]},
    {path: 'collaboration/collaboration/:room/offline-session', component: CollaborationRoomComponent, data: {breadcrumb: 'breadcrumb.collaboration'}, canActivate: [AuthGuard]},
    {path: 'knowledgebase', loadChildren: () => import('./features/knowledgebase/knowledgebase.module').then(m => m.KnowledgebaseModule), canLoad: [AuthGuard]},
    {path: 'risk', loadChildren: () => import('./features/risk/risk.module').then(m => m.RiskModule), canLoad: [AuthGuard]},
    {path: 'control', loadChildren: () => import('./features/control/control.module').then(m => m.ControlModule), canLoad: [AuthGuard]},
    {path: 'audit', loadChildren: () => import('./features/audit/audit.module').then(m => m.AuditModule), canLoad: [AuthGuard]},
    {path: 'config', loadChildren: () => import('./features/config/config.module').then(m => m.ConfigModule), canLoad: [AuthGuard]},
    {path: 'holding', loadChildren: () => import('./features/holding/holding.module').then(m => m.HoldingModule), canLoad: [AuthGuard]},
    {path: 'import/assets-import', loadChildren: () => import('./features/import/assets/assets.module').then(m => m.AssetsModule), canLoad: [AuthGuard]},
    {path: 'risk-push', loadChildren: () => import('./features/push-modules/push.module').then(m => m.PushModule), canLoad: [AuthGuard]},
    {path: 'process-push', loadChildren: () => import('./features/push-modules/push.module').then(m => m.PushModule), canLoad: [AuthGuard]},
    {path: 'common-push', loadChildren: () => import('./features/push-modules/push.module').then(m => m.PushModule), canLoad: [AuthGuard]},
    {path: 'control-push', loadChildren: () => import('./features/push-modules/push.module').then(m => m.PushModule), canLoad: [AuthGuard]},
    {path: 'strategy-push', loadChildren: () => import('./features/push-modules/push.module').then(m => m.PushModule), canLoad: [AuthGuard]},
    {path: 'asset-push', loadChildren: () => import('./features/push-modules/push.module').then(m => m.PushModule), canLoad: [AuthGuard]},
    {path: 'compliance-push', loadChildren: () => import('./features/push-modules/push.module').then(m => m.PushModule), canLoad: [AuthGuard]},
    {path: 'audit-push', loadChildren: () => import('./features/push-modules/push.module').then(m => m.PushModule), canLoad: [AuthGuard]},
    {path: 'register-push', loadChildren: () => import('./features/push-modules/push.module').then(m => m.PushModule), canLoad: [AuthGuard]},
    {path: 'contract-push', loadChildren: () => import('./features/push-modules/push.module').then(m => m.PushModule), canLoad: [AuthGuard]},
    {path: 'activities', loadChildren: () => import('./features/modules/activities/activities.module').then(m => m.ActivitiesModule), canLoad: [AuthGuard]},
    {path: 'strategy', loadChildren: () => import('./features/modules/strategy/strategy.module').then(m => m.StrategyModule), canLoad: [AuthGuard]},
    {path: 'recommendation-register', loadChildren: () => import('./features/modules/recommendation-register/recommendation-register.module').then(m => m.RecommendationRegisterModule), canLoad: [AuthGuard]},
    {path: 'common-register', loadChildren: () => import('./features/modules/common-register/common-register.module').then(m => m.CommonRegisterModule), canLoad: [AuthGuard]},
    {path: 'profile-tab', loadChildren: () => import('./features/modules/profile-tab/profile-tab.module').then(m => m.ProfileTabModule), canLoad: [AuthGuard]},
    {path: 'naris-admin', loadChildren: () => import('./features/modules/naris-admin/naris-admin.module').then(m => m.NarisAdminModule), canLoad: [AuthGuard]},
    {path: 'naris-support', loadChildren: () => import('./features/modules/naris-support/naris-support.module').then(m => m.NarisSupportModule), canLoad: [AuthGuard]},
    {path: 'naris-csm', loadChildren: () => import('./features/modules/naris-csm/naris-csm.module').then(m => m.NarisCsmModule), canLoad: [AuthGuard]},
    {path: 'setup-organization', loadChildren: () => import('./features/modules/setup-organization/setup-organization.module').then(m => m.SetupOrganizationModule), canLoad: [AuthGuard]},
    {path: 'collaboration', loadChildren: () => import('./features/modules/collaboration/collaboration.module').then(m => m.CollaborationModule), canLoad: [AuthGuard]},
    {path: 'asset', loadChildren: () => import('./features/modules/asset/asset.module').then(m => m.AssetModule), canLoad: [AuthGuard]},
    {path: 'process', loadChildren: () => import('./features/modules/process/process.module').then(m => m.ProcessModule), canLoad: [AuthGuard]},
    {path: 'compliance', loadChildren: () => import('./features/modules/compliance/compliance.module').then(m => m.ComplianceModule), canLoad: [AuthGuard]},
    {path: 'import', loadChildren: () => import('./features/modules/import/import.module').then(m => m.ImportModule), canLoad: [AuthGuard]},
    {path: 'organizations', loadChildren: () => import('./features/modules/organizations/organizations.module').then(m => m.OrganizationsModule), canLoad: [AuthGuard]},
    {path: 'dashboard', loadChildren: () => import('./features/modules/dashboard/dashboard.module').then(m => m.DashboardModule), canLoad: [AuthGuard]},
    {path: 'risk-archive', loadChildren: () => import('./features/modules/archive/archive.module').then(m => m.ArchiveModule), canLoad: [AuthGuard]},
    {path: 'control-archive', loadChildren: () => import('./features/modules/archive/archive.module').then(m => m.ArchiveModule), canLoad: [AuthGuard]},
    {path: 'compliance-archive', loadChildren: () => import('./features/modules/archive/archive.module').then(m => m.ArchiveModule), canLoad: [AuthGuard]},
    {path: 'audit-archive', loadChildren: () => import('./features/modules/archive/archive.module').then(m => m.ArchiveModule), canLoad: [AuthGuard]},
    {path: 'common-archive', loadChildren: () => import('./features/modules/archive/archive.module').then(m => m.ArchiveModule), canLoad: [AuthGuard]},
    {path: 'simulation-archive', loadChildren: () => import('./features/modules/archive/archive.module').then(m => m.ArchiveModule), canLoad: [AuthGuard]},
    {path: 'configuration-archive', loadChildren: () => import('./features/modules/archive/archive.module').then(m => m.ArchiveModule), canLoad: [AuthGuard]},
    {path: 'asset-archive', loadChildren: () => import('./features/modules/archive/archive.module').then(m => m.ArchiveModule), canLoad: [AuthGuard]},
    {path: 'process-archive', loadChildren: () => import('./features/modules/archive/archive.module').then(m => m.ArchiveModule), canLoad: [AuthGuard]},
    {path: 'strategy-archive', loadChildren: () => import('./features/modules/archive/archive.module').then(m => m.ArchiveModule), canLoad: [AuthGuard]},
    {path: 'register', loadChildren: () => import('./features/modules/register/register.module').then(m => m.RegisterModule), canLoad: [AuthGuard]},
    {path: 'contract', loadChildren: () => import('./features/modules/contract/contract.module').then(m => m.ContractModule), canLoad: [AuthGuard]},
    {path: 'register-archive', loadChildren: () => import('./features/modules/archive/archive.module').then(m => m.ArchiveModule), canLoad: [AuthGuard]},
    {path: 'contract-archive', loadChildren: () => import('./features/modules/archive/archive.module').then(m => m.ArchiveModule), canLoad: [AuthGuard]}
  ]},
  {path: 'open-case/:id', component: OpenCaseComponent, canActivate: [AuthGuard]},
  {path: '**', redirectTo: '/dashboard'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
