<naris-card>
  @if (!!matrixRows?.length && (!dataIsLoading && !isLoading)) {
    <div>
      <naris-toolbar>
        <h3 class="mr-05">{{'matrix' | translate}}</h3>
        @if (!!minNumOfSamples.value) {
          <div class="header-sub"> - {{minNumOfSamples.text}}: {{minNumOfSamples.value}}</div>
        }
        <naris-toolbar-item class="ml-auto">
          @if (tableActions) {
            <naris-taskgroup
              [taskgroup]="{actions: tableActions}"
              (formCallback)="reload()"
              (postCallback)="reload()"
              [collapseAfter]="2"
              buttonClass="ml-2"
              [showLabel]="false"
            />
          }
        </naris-toolbar-item>
      </naris-toolbar>
      <div class="example-container">
        @if (!!matrixRows) {
          <table mat-table [dataSource]="matrixRows">
            @for (col of matrixColumns; track $index) {
              <ng-container [matColumnDef]="col">
                <th mat-header-cell *matHeaderCellDef>
                  <div class="column-title">
                    <span
                      class="column-title-text"
                      [id]="'txt_' + getColIdText(col)"
                      [matTooltip]="col.length > 25 ? col :  ''"
                      matTooltipPosition="above"
                      [class.add-sample]="col === 'addSample'"
                    >
                      @if (col !== 'addSample') {
                        <div>
                          {{col === 'criteriumName' ? '' : col.length > 25 ? (col.slice().substr(0, 26) + '...') : col}}
                        </div>
                      }
                    </span>
                    @if (col !== 'criteriumName' && col !== 'addSample' && (getSampleDescription(col) || getSampleDocumentName(col) || (getActions(col)?.length ?? 0) > 1)) {
                      <naris-audit-execution-sample-action
                        [actions]="getActions(col)!"
                        [description]="getDescription(col)"
                        (actionClicked)="executeAction($event)"
                        [reverse]="col === matrixColumns[matrixColumns.length-1]"
                      />
                    }
                  </div>
                </th>
                <td mat-cell *matCellDef="let row" [class.crit-name]="col === 'criteriumName'">
                  @if (row.criteriumName !== 'addCriterium') {
                    <div>
                      @if (col === 'criteriumName') {
                        <div class="row-header">
                          <div [class.align]="col === 'criteriumName'">
                            @if (!!deleteCriteriumAction) {
                              <naris-icon
                                class="desc-icon delete-criterium pad-0"
                                icon="delete"
                                (click)="deleteCriterium(getRowData(row, col))"
                                [matTooltip]="'audit_execution.remove_criterium' | translate"
                                matTooltipPosition="above"
                                matTooltipClass="desc-icon-tooltip"
                              />
                            }
                          </div>
                          <div>{{col === 'criteriumName' ? getRowData(row, col) : getRowData(row, col).href}}</div>
                          @if (!!getCriteriumDescription(getRowData(row, col))) {
                            <naris-icon
                              icon="info-outline"
                              class="desc-icon pad-0 ml-2"
                              [matTooltip]="('table.description' | translate) + ':&#13;&#13;' + getCriteriumDescription(getRowData(row, col))"
                              matTooltipPosition="above"
                              matTooltipClass="desc-icon-tooltip"
                            />
                          }
                        </div>
                      } @else {
                        @if (col !== 'addSample') {
                          <naris-audit-execution-assessment [appraisal]="getRowData(row, col)?.appraisal" (changed)="reload()" />
                        }
                      }
                    </div>
                  }
                </td>
              </ng-container>
            }  
            <tr mat-header-row *matHeaderRowDef="matrixColumns;"></tr>
            <tr mat-row *matRowDef="let row; columns: matrixColumns;"></tr>
          </table>
        }
      </div>
      <div class="footer"></div>
    </div>
  }
  
  @if (!matrixRows?.length && (!dataIsLoading && !isLoading)) {
    <div class="empty-card">
      <h3 class="mr-05">{{'matrix' | translate}}</h3>
      <p>{{'table.no_items_of_kind'| translate}}</p>
      <naris-button color="primary" (click)="executeAction(addIfEmptyAction)" id="AuditExecutionMatrixAddIfEmptyAction">{{addIfEmptyAction?.label}}</naris-button>
    </div>
  }
  
  @if (dataIsLoading || isLoading) {
    <div class="loader">
      <naris-progress-spinner [strokeWidth]="2" [diameter]="24" />
    </div>
  }
</naris-card>

@if (isDownloading) {
  <div class="downloading-icon">
    {{'matrix.downloading' | translate}}
    <naris-icon [icon]="'downloading'" [iconClass]="'small'" />
  </div>
}

