import { Pipe, PipeTransform } from '@angular/core';
import { transformTranslation } from '@core/helpers';

/**
 * Usage in translation json files:
 * - en.json (key: value):
 * "found results": "=0 {No results} =1 {One result} >1 {{{count}} results found}",
 * - sk.json (key: value):
 * "found results": "=0 {Žiadne výsledky} =1 {Jeden výsledok} <5 {{{count}} výsledky} other {{{count}} nájdených záznamov}"
 *
 * Usage in component:
 * {{'found results' | translate:{count: cnt} | translateSelector:cnt}}
 *
 * Results:
 * If cnt = 0: No results (en) Žiadne výsledky (sk)
 * If cnt = 7: 7 results found (en) 7 nájdených záznamov (sk)
 */
@Pipe({
  name: 'translateSelector',
  standalone: true
})
export class TranslateSelectorPipe implements PipeTransform {

  public transform(text: string, value: number | string): string {
    return transformTranslation(text, value);
  }
}
