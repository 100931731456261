import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { LanguageService } from '@core/services';
import { TranslateModule } from '@ngx-translate/core';
import { CardComponent } from '../../../shared/components/card/card.component';
import { ButtonComponent } from '../../../shared/elements/button/button.component';

@Component({
  selector: 'app-auth-error',
  templateUrl: './auth-error.component.html',
  styleUrls: ['./auth-error.component.scss'],
  standalone: true,
  imports: [CardComponent, ButtonComponent, RouterLink, TranslateModule]
})
export class AuthErrorComponent {

  public errorTitle = 'Unknown';
  public errorDescription = 'Not available.';

  constructor(public langService: LanguageService, private readonly router: Router) {
    const navState = router.getCurrentNavigation();
    if (!!navState) {
      const { extras: { state } } = navState;
      this.errorTitle = state?.title;
      this.errorDescription = state?.message;
    }
  }
}
