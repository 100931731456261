import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ITaskGroup } from '@core/models';
import { CoreService } from '@core/services';
import { FooterToolbarService } from '@core/services/footer-toolbar.service';
import { Subscription } from 'rxjs';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { SlidetoggleComponent } from '../../elements/slidetoggle/slidetoggle.component';
import { TaskgroupComponent } from '../taskgroup/taskgroup.component';

@Component({
  selector: 'app-footer-toolbar',
  templateUrl: './footer-toolbar.component.html',
  styleUrls: ['./footer-toolbar.component.scss'],
  standalone: true,
  imports: [SlidetoggleComponent, TaskgroupComponent, TranslateModule]
})
export class FooterToolbarComponent implements OnInit, OnDestroy, AfterViewInit {

  public actionGroups: ITaskGroup[];
  public isFormValid: boolean;

  private readonly subs: Subscription[] = [];
  
  @ViewChild('hideOptionalToggle') public optionalToggle: MatSlideToggle;

  constructor(
    public readonly footerToolbarService: FooterToolbarService,
    public readonly coreService: CoreService
  ) {}

  public ngOnInit(): void {
    this.subs.push(
      this.footerToolbarService.actionsUpdated$.subscribe(actionGroups => this.actionGroups = actionGroups),
      this.footerToolbarService.formValid$.subscribe(value => {
        this.isFormValid = value;
      })
    );

  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.optionalToggle.checked = this.footerToolbarService.getChecked();
    });
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public toggleOptionalFields(event: MatSlideToggleChange) {
    localStorage.setItem('hide-optional-fields', String(event.checked));
    this.footerToolbarService.hideOptionalFields$.next(event.checked);
  }

  public actionGroupHasActions(actionGroup: ITaskGroup) {
    return actionGroup.actions?.some(action => !!action.href); // If there isn't an action with a href, we don't want to show a border
  }
}
