<mat-select
  class="input"
  (focus)="focus()"
  (blur)="blur()"
  [placeholder]="placeholder"
  [formControl]="control"
  [multiple]="multiple"
  (selectionChange)="setSelection($event);"
  [attr.data-test-id]="'select__' + id"
  [value]="value"
>
  @if (!mandatory && !multiple) {
    <mat-option>{{ 'select.none' | translate }}</mat-option>
  }
  @for (option of options; track i; let i = $index) {
    <mat-option [value]="option.code || option.key || option.value">
      <naris-option [label]="option.label || option.value?.toString() || option.key?.toString() || option.code || 'Option {{index}}'" [meta]="option">
        @if (option.count !== null && option.count !== undefined) {
          <span [style.opacity]="0.5">({{ option.count }})</span>
        }
      </naris-option>
    </mat-option>
  }
</mat-select>
