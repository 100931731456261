<div
  class="editor-wrapper"
  [ngClass]="{'editor-invalid': mandatory && ((!control.valid && control.value !== null && control.value !== '') || control.value === 'PHA+PC9wPg=='),
              'editor-valid': (control.valid || control.value !== '') && control.value !== 'PHA+PC9wPg=='}"
  [attr.data-test-id]="'ngx-text-editor__' + id"
>
  <ngx-editor-menu
    [editor]="editor"
    [toolbar]="toolbar"
    [colorPresets]="colorPresets"
  />
  <ngx-editor
    [editor]="editor"
    [ngModel]="html"
    [disabled]="false"
    [placeholder]="''"
    (ngModelChange)="onChange($event)"
    matAutocompleteOrigin
    #origin="matAutocompleteOrigin"
  >
    @if (docEnabled && !!docAuto) {
      <input
        class="doc-input"
        [matAutocomplete]="docAuto"
        [matAutocompleteConnectedTo]="origin"
        #trigger="matAutocompleteTrigger"
      />
    }
  </ngx-editor>
  @if (docEnabled) {
    <mat-autocomplete
      class="doc-panel"
      #docAuto="matAutocomplete"
      (optionSelected)="selectedDoc$.next($event.option.value)"
      autoActiveFirstOption
      hideSingleSelectionIndicator
    >
      
      <div class="type-to-search">{{'wysiwyg.type_to_search' | translate}}</div>
      <div class="spacer"></div>
      
      @for (doc of docs; track $index) {
        <mat-option class="doc-option" [value]="doc">
          <naris-icon [icon]="doc.ext | fileIcon" iconClass="small-medium"/>
          <span class="revision">Rev: {{ doc.revision }}</span>
          <span>{{ doc.name }}</span>
        </mat-option>
      }
    </mat-autocomplete>
  }
</div>
