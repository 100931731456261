import { createClass as e, classCallCheck as t, defineProperty as i } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { forEach as a, findWithFunction as n } from "../util/util.js";
import { addClass as r } from "./browser-util.js";
import { setPosition as s } from "./positioning-and-sizing-utils.js";
var o = "jtk-drag-selected";
var l = function () {
  function l(e) {
    t(this, l);
    this.instance = e;
    i(this, "_dragSelection", []);
    i(this, "_dragSizes", new Map());
    i(this, "_dragElements", new Map());
    i(this, "_dragElementStartPositions", new Map());
    i(this, "_dragElementPositions", new Map());
    i(this, "__activeSet", void 0);
  }
  e(l, [{
    key: "_activeSet",
    get: function e() {
      if (this.__activeSet == null) {
        return this._dragSelection;
      } else {
        return this.__activeSet;
      }
    }
  }, {
    key: "length",
    get: function e() {
      return this._dragSelection.length;
    }
  }, {
    key: "filterActiveSet",
    value: function e(t) {
      var i = this;
      this.__activeSet = [];
      a(this._dragSelection, function (e) {
        if (t(e)) {
          i.__activeSet.push(e);
        }
      });
    }
  }, {
    key: "clear",
    value: function e() {
      var t = this;
      this.reset();
      a(this._dragSelection, function (e) {
        return t.instance.removeClass(e.jel, o);
      });
      this._dragSelection.length = 0;
    }
  }, {
    key: "reset",
    value: function e() {
      this._dragElementStartPositions.clear();
      this._dragElementPositions.clear();
      this._dragSizes.clear();
      this._dragElements.clear();
      this.__activeSet = null;
    }
  }, {
    key: "initialisePositions",
    value: function e() {
      var t = this;
      a(this._activeSet, function (e) {
        var i = t.instance.viewport.getPosition(e.id);
        var a = {
          x: parseInt("" + e.jel.offsetLeft, 10),
          y: parseInt("" + e.jel.offsetTop, 10)
        };
        t._dragElementStartPositions.set(e.id, a);
        t._dragElementPositions.set(e.id, a);
        t._dragSizes.set(e.id, {
          w: i.w,
          h: i.h
        });
      });
    }
  }, {
    key: "updatePositions",
    value: function e(t, i, n) {
      var r = this;
      var o = t.x - i.x,
        l = t.y - i.y;
      a(this._activeSet, function (e) {
        var t = r._dragElementStartPositions.get(e.id);
        if (t) {
          var i = t.x + o,
            a = t.y + l;
          var c = r._dragSizes.get(e.id);
          var h = {
            x: i,
            y: a,
            w: c.w,
            h: c.h
          };
          if (e.jel._jsPlumbParentGroup && e.jel._jsPlumbParentGroup.constrain) {
            var d = {
              w: e.jel.parentNode.offsetWidth + e.jel.parentNode.scrollLeft,
              h: e.jel.parentNode.offsetHeight + e.jel.parentNode.scrollTop
            };
            h.x = Math.max(h.x, 0);
            h.y = Math.max(h.y, 0);
            h.x = Math.min(h.x, d.w - c.w);
            h.y = Math.min(h.y, d.h - c.h);
          }
          r._dragElementPositions.set(e.id, {
            x: i,
            y: a
          });
          s(e.jel, h);
          n(e.jel, e.id, c, h, t);
        }
      });
    }
  }, {
    key: "each",
    value: function e(t) {
      var i = this;
      a(this._activeSet, function (e) {
        var a = i._dragSizes.get(e.id);
        var n = i._dragElementPositions.get(e.id);
        var r = i._dragElementStartPositions.get(e.id);
        t(e.jel, e.id, n, a, r);
      });
    }
  }, {
    key: "add",
    value: function e(t, i) {
      var a = t;
      i = i || this.instance.getId(a);
      var s = n(this._dragSelection, function (e) {
        return e.id === i;
      });
      if (s === -1) {
        r(t, o);
        this._dragSelection.push({
          id: i,
          jel: a
        });
      }
    }
  }, {
    key: "remove",
    value: function e(t) {
      var i = this;
      var a = t;
      this._dragSelection = this._dragSelection.filter(function (e) {
        var t = e.jel !== a;
        if (!t) {
          i.instance.removeClass(e.jel, o);
        }
        return t;
      });
    }
  }, {
    key: "toggle",
    value: function e(t) {
      var i = t;
      var a = n(this._dragSelection, function (e) {
        return e.jel === i;
      });
      if (a !== -1) {
        this.remove(i);
      } else {
        this.add(t);
      }
    }
  }]);
  return l;
}();
export { o as CLASS_DRAG_SELECTED, l as DragSelection };