<div class="wrapper" [ngStyle]="{cursor: dragging ? 'grabbing' : 'crosshair'}">
  <canvas
    #canvas
    class="color-slider"
    width="16"
    [height]="height"
    (mousedown)="onClick($event)"
    [attr.data-test-id]="'color-slider__Canvas'"
  ></canvas>
  <div
    class="drag-handle"
    cdkDrag
    cdkDragBoundary=".wrapper"
    cdkDragLockAxis="y"
    [cdkDragFreeDragPosition]="dragPos"
    (cdkDragStarted)="dragging = true"
    (cdkDragReleased)="dragging = false"
    (cdkDragMoved)="onDragMoved($event)"
    (cdkDragEnded)="onDragEnded($event)"
  ></div>
</div>
