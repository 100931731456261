var e = "dataLoadStart";
var a = "dataAppendStart";
var r = "dataLoadEnd";
var d = "dataAppendEnd";
var v = "dataUpdated";
var t = "destroy";
var o = "graphClearStart";
var p = "graphCleared";
var n = "graphChanged";
var g = "group:updated";
var h = "node:updated";
var u = "port:updated";
var s = "edge:updated";
var i = "port:renamed";
var m = "edge:added";
var C = "node:added";
var l = "edge:removed";
var y = "node:removed";
var c = "port:added";
var T = "port:removed";
var z = "edge:target";
var E = "edge:source";
var S = "select";
var A = "deselect";
var L = "selection:cleared";
var R = "edge:pathRestored";
var U = "edge:pathEdited";
var w = "edge:typeChanged";
var x = "node:typeChanged";
var b = "port:typeChanged";
var f = "group:typeChanged";
var j = "group:move:start";
var k = "group:move";
var q = "group:move:end";
var B = "internal:group:sizeChanged";
var D = "internal:group:sizeChangedRedo";
var F = "internal:group:sizeChangedUndo";
var G = "node:move:start";
var H = "node:move";
var I = "node:move:end";
var J = "renderer:added";
var K = "undoredo:update";
var M = {
  portType: "portType",
  edgeType: "edgeType"
};
var N = "width";
var O = "height";
export { d as EVENT_DATA_APPEND_END, a as EVENT_DATA_APPEND_START, r as EVENT_DATA_LOAD_END, e as EVENT_DATA_LOAD_START, v as EVENT_DATA_UPDATED, A as EVENT_DESELECT, t as EVENT_DESTROY, m as EVENT_EDGE_ADDED, U as EVENT_EDGE_PATH_EDITED, R as EVENT_EDGE_PATH_RESTORED, l as EVENT_EDGE_REMOVED, E as EVENT_EDGE_SOURCE_CHANGED, z as EVENT_EDGE_TARGET_CHANGED, w as EVENT_EDGE_TYPE_CHANGED, s as EVENT_EDGE_UPDATED, n as EVENT_GRAPH_CHANGED, p as EVENT_GRAPH_CLEARED, o as EVENT_GRAPH_CLEAR_START, k as EVENT_GROUP_MOVE, q as EVENT_GROUP_MOVE_END, j as EVENT_GROUP_MOVE_START, B as EVENT_GROUP_SIZE_CHANGED, D as EVENT_GROUP_SIZE_CHANGED_REDO, F as EVENT_GROUP_SIZE_CHANGED_UNDO, f as EVENT_GROUP_TYPE_CHANGED, g as EVENT_GROUP_UPDATED, C as EVENT_NODE_ADDED, H as EVENT_NODE_MOVE, I as EVENT_NODE_MOVE_END, G as EVENT_NODE_MOVE_START, y as EVENT_NODE_REMOVED, x as EVENT_NODE_TYPE_CHANGED, h as EVENT_NODE_UPDATED, c as EVENT_PORT_ADDED, T as EVENT_PORT_REMOVED, i as EVENT_PORT_RENAMED, b as EVENT_PORT_TYPE_CHANGED, u as EVENT_PORT_UPDATED, J as EVENT_RENDERER_ADDED, S as EVENT_SELECT, L as EVENT_SELECTION_CLEARED, K as EVENT_UNDOREDO_UPDATE, O as HEIGHT, M as Parameters, N as WIDTH };