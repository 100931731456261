<div class="header" [ngClass]="{'color-span': colorSpan}" [ngStyle]="{backgroundColor: color || 'white'}">
  @if (colorSpan) {
    <span>{{color | lowercase}}</span>
  }
  <naris-button
    class="button"
    size="small"
    icon="close"
    color="white"
    (clicked)="closed.emit()"
    id="ColorPickerClose"
  />
</div>
<div class="color-wrapper">
  <naris-color-slider (color)="hueChange.next($event)" [chosenHue]="chosenHue" />
  <naris-color-palette [hueChange]="hueChange" (color)="emitColor($event)" [chosenHue]="chosenHue" />
</div>
<div class="swatches">
  @for (swatch of swatches; track swatch) {
    <div
      tabindex="0"
      class="swatch"
      [ngStyle]="{backgroundColor: swatch, borderColor: ['#fff', '#ffffff'].includes(swatch.toLowerCase()) ? 'gray' : 'transparent'}"
      [matTooltip]="swatch"
      (click)="chosenHue = swatch"
    ></div>
  }
</div>
