import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@shared/components/snackbar/snackbar.component';
import { ISnackbarData } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private readonly snackbar: MatSnackBar) { }

  public open({text, type = 'default', dismissLabel = 'ok', duration = 5000}: ISnackbarData): any {
    const snackbarConfig = {
      panelClass: type,
      data: {type, text, dismissLabel},
      duration
    };
    return this.snackbar.openFromComponent(SnackbarComponent, snackbarConfig);
  }
}
