import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { merge, Observable, throwError, from } from 'rxjs';
// import { finalize, catchError, throttleTime, first } from 'rxjs/operators';
import { catchError, throttleTime, first } from 'rxjs/operators';
import { IAuditViewerMessage, ICollabEvent, ICollabState, IMessage, IPeerData, IPeerUser, IRoomEvent, IVote } from '@core/models';
import { UserService } from '@core/services/user.service';
import { AuditSocket, CollabSocket } from '@app/app.module';
import { Router } from '@angular/router';
import { SnackbarService } from './snackbar.service';
import { DialogService } from './dialog.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  public self: IPeerUser;

  constructor(
    private readonly socket: CollabSocket,
    private readonly auditSocket: AuditSocket,
    private readonly userService: UserService,
    private readonly snackbarService: SnackbarService,
    private readonly dialogService: DialogService,
    private readonly httpService: HttpService,
    private readonly router: Router

  ) {
    this.setSelf();
    socket.on('connect', () => this.setSelf(socket.ioSocket.id));
    socket.on('connect_error', (e: any) => {
      snackbarService.open({ text: 'websocket.collabConnectionError', type: 'error' });
      // eslint-disable-next-line no-console
      console.log('Collaboration WebSocket error', e);
    });
    // TODO
    socket.on('disconnect', (reason: string) => {
      console.log('Socket disconnect', reason);
    });
    auditSocket.on('connect', () => this.setSelf(socket.ioSocket.id));
    auditSocket.on('connect_error', (e: any) => {
      snackbarService.open({ text: 'websocket.auditConnectionError', type: 'error' });
      // eslint-disable-next-line no-console
      console.log('Audit WebSocket error', e);
    });
  }

  get socketId(): string {
    return this.socket.ioSocket.id;
  }

  get auditSocketId(): string {
    return this.auditSocket.ioSocket.id;
  }

  get connected() {
    return this.socket.ioSocket.connected;
  }

  get auditConnected() {
    return this.auditSocket.ioSocket.connected;
  }

  public setWhiteList(room: string, experts: string[]): void {
    this.socket.emit('whitelist', { room, experts });
  }

  public setCurrentCase(room: string, caseUrl: string): void {
    this.socket.emit('setCurrentCase', { room, caseUrl });
  }

  public joinRoom(room: string): void {
    this.socket.emit('join', { room, user: this.self });
  }

  public joinAuditRoom(room: string): void {
    this.auditSocket.emit('join', { room, user: this.self });
  }

  public leaveRoom(room: string): void {
    this.socket.emit('leave', { room, user: this.self });
  }

  public leaveAuditRoom(room: string): void {
    this.auditSocket.emit('leave', { room, user: this.self });
  }

  public sendOffer(id: string, offer: any): void {
    this.socket.emit('offer', { id, offer, user: this.self });
  }

  public sendAnswer(id: string, answer: any): void {
    this.socket.emit('answer', { id, answer });
  }

  public sendCandidate(id: string, candidate: any): void {
    this.socket.emit('candidate', { id, candidate });
  }

  public sendMessage(room: string, msg: string): void {
    this.socket.emit('message', { room, msg, user: this.self });
  }

  public sendTyping(room: string): void {
    this.socket.emit('typing', { room, user: this.self });
  }

  public sendMuted(room: string, userId: number | string, muted: boolean): void {
    this.socket.emit('audio', { room, userId, muted });
  }

  public sendToggleMuteAll(room: string, muted: boolean) {
    this.socket.emit('toggleAudio', {room, muted});
  }

  public sendClick(target: string, click: ICollabEvent): void {
    this.socket.emit('hostClick', { target, click });
  }

  public sendStateRequest(room: string) {
    this.socket.emit('requestState', { room, socketId: this.socketId });
  }

  public sendState(data: { socketId: string; state: ICollabState }) {
    this.socket.emit('sendState', data);
  }

  public sendVote(room: string, data: { caseId: string; vote: IVote }): void {
    this.socket.emit('sendVote', { room, user: this.self, ...data });
  }

  public sendCloseSession(): void {
    this.socket.emit('closeSession');
  }

  public sendFetchSaveState(room: string): void {
    this.socket.emit('fetchSaveState', {room});
  }

  public sendCollabComplete(room: string, caseId: string): void {
    this.socket.emit('collabComplete', {room, caseId});
  }

  public sendNodeClick(node: string): void {
    this.auditSocket.emit('nodeClick', { node });
  }

  public sendSyncSession(room: string) {
    this.socket.emit('syncSession', { room });
  }

  public typeListener(): Observable<string> {
    return this.socket.fromEvent<string>('typing').pipe(
      catchError(this.handleError),
      throttleTime(500)
    );
  }

  public audioListener(): Observable<{ userId: number | string; muted: boolean }> {
    return this.socket.fromEvent<{ userId: number | string; muted: boolean }>('audio').pipe(catchError(this.handleError));
  }

  public toggleAudioListener(): Observable<{muted: boolean}> {
    return this.socket.fromEvent<{muted: boolean}>('toggleAudio').pipe(catchError(this.handleError));
  }

  public peerListeners(): Observable<IPeerData | IRoomEvent> {
    return merge(
      this.socket.fromEvent<IRoomEvent>('joined'),
      this.socket.fromEvent<IRoomEvent>('left'),
      this.socket.fromEvent<IPeerData>('offer'),
      this.socket.fromEvent<IPeerData>('answer'),
      this.socket.fromEvent<IPeerData>('candidate')
    ).pipe(catchError(this.handleError));
  }

  public messageListeners(): Observable<IMessage | IMessage[]> {
    return merge(
      this.socket.fromEvent<IMessage>('message'),
      this.socket.fromEvent<IMessage[]>('history')
    ).pipe(catchError(this.handleError));
  }

  public userNodeCollection(): Observable<IAuditViewerMessage> {
    return this.auditSocket.fromEvent<any>('userNodeCollection').pipe(catchError(this.handleError));
  }

  public userLeavesNode(): Observable<IAuditViewerMessage> {
    return this.auditSocket.fromEvent<any>('userLeavesNode').pipe(catchError(this.handleError));
  }

  public userOccupiesNode(): Observable<IAuditViewerMessage> {
    return this.auditSocket.fromEvent<any>('userOccupiesNode').pipe(catchError(this.handleError));
  }

  public userOccupiedNodes(): Observable<IAuditViewerMessage> {
    return this.auditSocket.fromEvent<any>('userOccupiedNodes').pipe(catchError(this.handleError));
  }

  public clickListener(): Observable<ICollabEvent> {
    return this.socket.fromEvent<ICollabEvent>('hostClick').pipe(catchError(this.handleError));
  }

  public stateRequestListener(): Observable<string> {
    return this.socket.fromEvent<string>('requestState').pipe(catchError(this.handleError));
  }

  public stateListener(): Observable<ICollabState> {
    return this.socket.fromEvent<ICollabState>('sendState').pipe(catchError(this.handleError));
  }

  public voteListener(): Observable<any> {
    return this.socket.fromEvent<any>('sendVote').pipe(catchError(this.handleError));
  }

  public joinListener(): Observable<void> {
    return this.socket.fromEvent<void>('noHost').pipe(catchError(this.handleError));
  }

  public whitelistListener(): Observable<void> {
    return this.socket.fromEvent<void>('noWhitelist').pipe(catchError(this.handleError));
  }

  public currentCaseListener(): Observable<{currentCase: string, blindVote?: Record<string, any>}> {
    return this.socket.fromEvent<{currentCase: string}>('currentCase').pipe(catchError(this.handleError));
  }

  public fetchStateListener(): Observable<void> {
    return this.socket.fromEvent<void>('fetchState').pipe(catchError(this.handleError));
  }

  public collabCompleteListener(): Observable<string> {
    return this.socket.fromEvent<string>('collabCompleted').pipe(catchError(this.handleError));
  }

  public syncSessionListener(): Observable<any> {
    return this.socket.fromEvent<any>('syncSession').pipe(catchError(this.handleError));
  }

  public sessionClosed(): Observable<void> {
    return this.socket.fromEvent<void>('sessionClosed').pipe(catchError(this.handleError));
  }

  public hostDisconnected(): Observable<void> {
    return this.socket.fromEvent<void>('hostDisconnected').pipe(catchError(this.handleError));
  }

  public hostConnected(): Observable<void> {
    return this.socket.fromEvent<void>('hostConnected').pipe(catchError(this.handleError));
  }

  public openConnection() {
    const connectProm = new Promise((resolve, reject) => {
      this.socket.once('connect', resolve);
      this.socket.once('connect_error', reject);
      this.socket.connect();
    });
    return from(connectProm).pipe(first());
  }

  public openAuditConnection() {
    const connectProm = new Promise((resolve, reject) => {
      this.auditSocket.once('connect', resolve);
      this.auditSocket.once('connect_error', reject);
      this.auditSocket.connect();
    });
    return from(connectProm).pipe(first());
  }

  public closeConnection() {
    this.socket.disconnect();
  }

  public closeAuditConnection() {
    this.auditSocket.disconnect();
  }

  private setSelf(socketId: string | null = null) {
    this.self = {
      id: this.userService.idOfUser?.toString(),
      name: this.userService.userData?.Fullname,
      socketId
    };
  }

  private readonly handleError = (err: HttpErrorResponse): Observable<never> => throwError(() => err);
}
