function a() {
  for (var a = arguments.length, r = new Array(a), n = 0; n < a; n++) {
    r[n] = arguments[n];
  }
  return r.map(function (a) {
    return "." + a;
  }).join(",");
}
function r() {
  for (var a = arguments.length, r = new Array(a), n = 0; n < a; n++) {
    r[n] = arguments[n];
  }
  return r.join(" ");
}
function n() {
  for (var a = arguments.length, r = new Array(a), n = 0; n < a; n++) {
    r[n] = arguments[n];
  }
  return r.map(function (a) {
    return "[" + a + "]";
  }).join(",");
}
var t = "source";
var e = "target";
var o = "block";
var v = "none";
var c = 0;
var i = 1;
var s = "absolute";
var l = "fixed";
var u = "static";
var d = "data-jtk-group";
var f = "data-jtk-managed";
var h = "data-jtk-not-draggable";
var b = "tabindex";
var g = "data-jtk-scope";
var p = g + "-";
var j = "checkCondition";
var k = "checkDropAllowed";
var D = "isDetachAllowed";
var w = "beforeDrag";
var x = "beforeDrop";
var A = "beforeDetach";
var C = "beforeStartDetach";
var y = n(f);
var m = "Cannot establish connection: source endpoint is full";
var O = "Cannot establish connection: target endpoint is full";
var S = "Cannot establish connection: source does not exist";
var q = "Cannot establish connection: target does not exist";
var z = "connectionOverlays";
var B = "internal.anchor:changed";
export { s as ABSOLUTE, d as ATTRIBUTE_GROUP, f as ATTRIBUTE_MANAGED, h as ATTRIBUTE_NOT_DRAGGABLE, g as ATTRIBUTE_SCOPE, p as ATTRIBUTE_SCOPE_PREFIX, b as ATTRIBUTE_TABINDEX, o as BLOCK, j as CHECK_CONDITION, k as CHECK_DROP_ALLOWED, S as ERROR_SOURCE_DOES_NOT_EXIST, m as ERROR_SOURCE_ENDPOINT_FULL, q as ERROR_TARGET_DOES_NOT_EXIST, O as ERROR_TARGET_ENDPOINT_FULL, B as EVENT_INTERNAL_ANCHOR_CHANGED, l as FIXED, A as INTERCEPT_BEFORE_DETACH, w as INTERCEPT_BEFORE_DRAG, x as INTERCEPT_BEFORE_DROP, C as INTERCEPT_BEFORE_START_DETACH, D as IS_DETACH_ALLOWED, z as KEY_CONNECTION_OVERLAYS, v as NONE, y as SELECTOR_MANAGED_ELEMENT, t as SOURCE, c as SOURCE_INDEX, u as STATIC, e as TARGET, i as TARGET_INDEX, n as att, r as classList, a as cls };