import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IInputOption } from '@core/models';
import { FormComponent } from '../../../../core/form/form.component';

@Component({
  selector: 'app-treeview-copy-form',
  templateUrl: './treeview-copy-form.component.html',
  styleUrls: ['./treeview-copy-form.component.scss'],
  standalone: true,
  imports: [FormComponent]
})
export class TreeviewCopyFormComponent implements OnInit {

  public endpoint: string;
  public options: IInputOption[];
  public error: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      endpoint: string;
    },
    public dialogRef: MatDialogRef<TreeviewCopyFormComponent>
  ) {}

  public ngOnInit(): void {
    this.endpoint = this.data.endpoint;
  }

  public formClosed(event: any) {
    if (typeof event === 'boolean') {
      this.dialogRef.close(null);
    } else {
      this.dialogRef.close(event);
    }
  }
}
