export const ordinalValues = new Map<string, { suffix: string; rule: string }[]>();
export const enMap: { suffix: string; rule: string }[] = [
  {suffix: 'st', rule: 'n % 10 = 1 and n % 100 != 11'},
  {suffix: 'nd', rule: 'n % 10 = 2 and n % 100 != 12'},
  {suffix: 'rd', rule: 'n % 10 = 3 and n % 100 != 13'},
  {suffix: 'th', rule: 'other'}
];

export const nlMap: { suffix: string; rule: string }[] = [
  {suffix: 'e', rule: 'other'}
];

export const deMap: { suffix: string; rule: string }[] = [
  {suffix: '.', rule: 'other'}
];

export const frMap: { suffix: string; rule: string }[] = [
  {suffix: 're', rule: 'n = 1'},
  {suffix: 'e', rule: 'other'}
];

ordinalValues.set('en', enMap);
ordinalValues.set('nl', nlMap);
ordinalValues.set('de', deMap);
ordinalValues.set('fr', frMap);
