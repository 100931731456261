import { createClass as n, classCallCheck as r, defineProperty as t } from "../../_virtual/_rollupPluginBabelHelpers.js";
function e(n, r, t) {
  if (n === "*") {
    return true;
  }
  return n.length > 0 ? n.indexOf(r) !== -1 : !t;
}
function u(n, r, t) {
  var e;
  n = n || {};
  r = r || {};
  var u = n,
    i = r;
  if (t) {
    for (e = 0; e < t.length; e++) {
      u[t[e]] = i[t[e]];
    }
  } else {
    for (e in i) {
      u[e] = i[e];
    }
  }
  return n;
}
function i(n) {
  return Object.prototype.toString.call(n) === "[object Number]";
}
function l(n) {
  return typeof n === "string";
}
function f(n) {
  return typeof n === "boolean";
}
function a(n) {
  return n == null ? false : Object.prototype.toString.call(n) === "[object Object]";
}
function o(n) {
  return n == null ? false : Object.prototype.toString.call(n).match(/\[object .*Element]/) != null;
}
function s(n) {
  return n == null ? false : Object.prototype.toString.call(n) === "[object Text]";
}
function c(n) {
  return Object.prototype.toString.call(n) === "[object Date]";
}
function h(n) {
  return Object.prototype.toString.call(n) === "[object Function]";
}
function y(n) {
  return h(n) && n.name != null && n.name.length > 0;
}
function v(n) {
  for (var r in n) {
    if (n.hasOwnProperty(r)) {
      return false;
    }
  }
  return true;
}
function x(n) {
  if (l(n)) {
    return "" + n;
  } else if (f(n)) {
    return !!n;
  } else if (c(n)) {
    return new Date(n.getTime());
  } else if (h(n)) {
    return n;
  } else if (Array.isArray(n)) {
    var r = [];
    for (var t = 0; t < n.length; t++) {
      r.push(x(n[t]));
    }
    return r;
  } else if (o(n) || s(n)) {
    return n;
  } else if (a(n)) {
    var e = {};
    for (var u in n) {
      e[u] = x(n[u]);
    }
    return e;
  } else {
    return n;
  }
}
function p(n) {
  var r = {};
  for (var t in n) {
    if (n[t] != null) {
      r[t] = n[t];
    }
  }
  return r;
}
function m(n, r, t, e) {
  var u = {},
    o,
    s,
    c = {};
  t = t || [];
  e = e || [];
  for (s = 0; s < t.length; s++) {
    u[t[s]] = true;
  }
  for (s = 0; s < e.length; s++) {
    c[e[s]] = true;
  }
  var y = x(n);
  for (s in r) {
    if (y[s] == null || c[s]) {
      y[s] = r[s];
    } else if (u[s]) {
      o = [];
      o.push.apply(o, Array.isArray(y[s]) ? y[s] : [y[s]]);
      o.push(r[s]);
      y[s] = o;
    } else if (l(r[s]) || f(r[s]) || h(r[s]) || i(r[s])) {
      y[s] = r[s];
    } else {
      if (Array.isArray(r[s])) {
        o = [];
        if (Array.isArray(y[s])) {
          o.push.apply(o, y[s]);
        }
        o.push.apply(o, r[s]);
        y[s] = o;
      } else if (a(r[s])) {
        if (!a(y[s])) {
          y[s] = {};
        }
        for (var v in r[s]) {
          y[s][v] = r[s][v];
        }
      }
    }
  }
  return y;
}
function g(n, r) {
  if (n != null && r == null) {
    return false;
  } else {
    if ((n == null || l(n) || f(n) || i(n)) && n !== r) {
      return false;
    } else {
      if (Array.isArray(n)) {
        if (!Array.isArray(r)) {
          return false;
        } else {
          if (!d(n, r)) {
            return false;
          }
        }
      } else if (a(n)) {
        if (!a(n)) {
          return false;
        } else {
          if (!A(n, r)) {
            return false;
          }
        }
      }
    }
  }
  return true;
}
function d(n, r) {
  if (n == null && r == null) {
    return true;
  } else if (n == null && r != null) {
    return false;
  } else if (n != null && r == null) {
    return false;
  }
  if (n.length !== r.length) {
    return false;
  } else {
    for (var t = 0; t < n.length; t++) {
      if (!g(n[t], r[t])) {
        return false;
      }
    }
  }
  return true;
}
function A(n, r) {
  if (n == null && r == null) {
    return true;
  } else if (n == null && r != null) {
    return false;
  } else if (n != null && r == null) {
    return false;
  }
  for (var t in n) {
    var e = n[t],
      u = r[t];
    if (!g(e, u)) {
      return false;
    }
  }
  return true;
}
function b(n, r, t) {
  if (n == null) {
    return;
  }
  var e = n,
    u = e;
  r.replace(/([^\.])+/g, function (n, r, e, i) {
    var l = n.match(/([^\[0-9]+){1}(\[)([0-9+])/),
      f = e + n.length >= i.length,
      a = function n() {
        return u[l[1]] || function () {
          u[l[1]] = [];
          return u[l[1]];
        }();
      };
    if (f) {
      if (l) {
        a()[l[3]] = t;
      } else {
        u[n] = t;
      }
    } else {
      if (l) {
        var o = a();
        u = o[l[3]] || function () {
          o[l[3]] = {};
          return o[l[3]];
        }();
      } else {
        u = u[n] || function () {
          u[n] = {};
          return u[n];
        }();
      }
    }
    return "";
  });
  return n;
}
function M(n, r, t, e) {
  var u = function n(t) {
    var e = t.match(/(\${.*?})/g);
    if (e != null) {
      for (var u = 0; u < e.length; u++) {
        var i = r[e[u].substring(2, e[u].length - 1)] || "";
        if (i != null) {
          t = t.replace(e[u], i);
        }
      }
    }
    e = t.match(/({{.*?}})/g);
    if (e != null) {
      for (var l = 0; l < e.length; l++) {
        var f = r[e[l].substring(2, e[l].length - 2)] || "";
        if (f != null) {
          t = t.replace(e[l], f);
        }
      }
    }
    return t;
  };
  var i = function n(i) {
    if (i != null) {
      if (l(i)) {
        return u(i);
      } else if (h(i) && !e && (t == null || (i.name || "").indexOf(t) === 0)) {
        return i(r);
      } else if (Array.isArray(i)) {
        var f = [];
        for (var o = 0; o < i.length; o++) {
          f.push(n(i[o]));
        }
        return f;
      } else if (a(i)) {
        var s = {};
        for (var c in i) {
          s[c] = n(i[c]);
        }
        return s;
      } else {
        return i;
      }
    }
  };
  return i(n);
}
function j(n, r) {
  if (n) {
    for (var t = 0; t < n.length; t++) {
      r(n[t]);
    }
  } else {
    return null;
  }
}
function O(n, r) {
  if (n) {
    for (var t = 0; t < n.length; t++) {
      if (r(n[t])) {
        return t;
      }
    }
  }
  return -1;
}
function w(n, r) {
  var t = [];
  if (n) {
    for (var e = 0; e < n.length; e++) {
      if (r(n[e])) {
        t.push(e);
      }
    }
  }
  return t;
}
function S(n, r) {
  var t = O(n, r);
  return t === -1 ? null : n[t];
}
function I(n, r) {
  var t = w(n, r);
  return t.map(function (r) {
    return n[r];
  });
}
function P(n, r) {
  var t = null;
  n.forEach(function (n) {
    if (r(n)) {
      t = n;
    }
  });
  return t;
}
function k(n) {
  var r = [];
  n.forEach(function (n) {
    r.push(n);
  });
  return r;
}
function E(n, r) {
  var t = O(n, r);
  if (t > -1) {
    n.splice(t, 1);
  }
  return t !== -1;
}
function C(n) {
  if (Array.fromArray != null) {
    return Array.from(n);
  } else {
    var r = [];
    Array.prototype.push.apply(r, n);
    return r;
  }
}
function D(n, r) {
  var t = n.indexOf(r);
  if (t > -1) {
    n.splice(t, 1);
  }
  return t !== -1;
}
function T(n, r, t) {
  if (O(n, t) === -1) {
    n.push(r);
  }
}
function _(n, r, t, e) {
  var u = n[r];
  if (u == null) {
    u = [];
    n[r] = u;
  }
  u[e ? "unshift" : "push"](t);
  return u;
}
function B(n, r, t, e) {
  var u = n.get(r);
  if (u == null) {
    u = [];
    n.set(r, u);
  }
  u[e ? "unshift" : "push"](t);
  return u;
}
function F(n, r, t) {
  if (n.indexOf(r) === -1) {
    if (t) {
      n.unshift(r);
    } else {
      n.push(r);
    }
    return true;
  }
  return false;
}
var H = [];
for (var N = 0; N < 256; N++) {
  H[N] = (N < 16 ? "0" : "") + N.toString(16);
}
function $() {
  var n = Math.random() * 4294967295 | 0;
  var r = Math.random() * 4294967295 | 0;
  var t = Math.random() * 4294967295 | 0;
  var e = Math.random() * 4294967295 | 0;
  return H[n & 255] + H[n >> 8 & 255] + H[n >> 16 & 255] + H[n >> 24 & 255] + "-" + H[r & 255] + H[r >> 8 & 255] + "-" + H[r >> 16 & 15 | 64] + H[r >> 24 & 255] + "-" + H[t & 63 | 128] + H[t >> 8 & 255] + "-" + H[t >> 16 & 255] + H[t >> 24 & 255] + H[e & 255] + H[e >> 8 & 255] + H[e >> 16 & 255] + H[e >> 24 & 255];
}
function q(n, r, t) {
  var e = {
      x: n.x - r.x,
      y: n.y - r.y
    },
    u = Math.cos(t / 360 * Math.PI * 2),
    i = Math.sin(t / 360 * Math.PI * 2);
  return {
    x: e.x * u - e.y * i + r.x,
    y: e.y * u + e.x * i + r.y,
    cr: u,
    sr: i
  };
}
function z(n, r) {
  var t = q({
    x: n[0],
    y: n[1]
  }, {
    x: 0,
    y: 0
  }, r);
  return [Math.round(t.x), Math.round(t.y)];
}
function G(n) {
  if (n == null) {
    return null;
  }
  var r = n.replace(/^\s\s*/, ""),
    t = /\s/,
    e = r.length;
  while (t.test(r.charAt(--e))) {}
  return r.slice(0, e + 1);
}
function J(n, r) {
  n = n.length == null || typeof n === "string" ? [n] : n;
  for (var t = 0; t < n.length; t++) {
    r(n[t]);
  }
}
function K(n, r) {
  var t = [];
  for (var e = 0; e < n.length; e++) {
    t.push(r(n[e]));
  }
  return t;
}
var L = true;
function Q() {
  for (var n = arguments.length, r = new Array(n), t = 0; t < n; t++) {
    r[t] = arguments[t];
  }
  if (typeof console !== "undefined") {
    try {
      var e = arguments[arguments.length - 1];
      console.log(e);
    } catch (n) {}
  }
}
function R(n) {
  return n < 0 ? -1 : n > 0 ? 1 : 0;
}
function U(n, r, t) {
  return function () {
    var e = null;
    try {
      if (r != null) {
        e = r.apply(this, arguments);
      }
    } catch (n) {
      Q("jsPlumb function failed : " + n);
    }
    if (n != null && (t == null || e !== t)) {
      try {
        e = n.apply(this, arguments);
      } catch (n) {
        Q("wrapped function failed : " + n);
      }
    }
    return e;
  };
}
function V(n, r, t, e) {
  if (r.length === 0) {
    r.push(n);
  } else {
    var u = e ? -1 : 1;
    var i = 0;
    var l = r.length;
    var f = Math.floor((i + l) / 2);
    while (l > i) {
      var a = t(n, r[f]) * u;
      if (a < 0) {
        l = f;
      } else {
        i = f + 1;
      }
      f = Math.floor((i + l) / 2);
    }
    r.splice(f, 0, n);
  }
}
function W(n, r, t) {
  return {
    x: n.x + t * r.x,
    y: n.y + t * r.y,
    w: n.w,
    h: n.h
  };
}
function X() {
  return {
    xmin: Infinity,
    xmax: -Infinity,
    ymin: Infinity,
    ymax: -Infinity
  };
}
var Y = function () {
  function e() {
    r(this, e);
    t(this, "extents", void 0);
    this.extents = X();
  }
  n(e, [{
    key: "add",
    value: function n(r, t) {
      this.extents.xmin = Math.min(this.extents.xmin, r.x);
      this.extents.ymin = Math.min(this.extents.ymin, r.y);
      this.extents.xmax = Math.max(this.extents.xmax, r.x + t.w);
      this.extents.ymax = Math.max(this.extents.ymax, r.y + t.h);
      return this;
    }
  }, {
    key: "addAll",
    value: function n(r) {
      var t = this;
      r.forEach(function (n) {
        t.extents.xmin = Math.min(t.extents.xmin, n.x);
        t.extents.ymin = Math.min(t.extents.ymin, n.y);
        t.extents.xmax = Math.max(t.extents.xmax, n.x + n.w);
        t.extents.ymax = Math.max(t.extents.ymax, n.y + n.h);
      });
      return this;
    }
  }, {
    key: "reset",
    value: function n() {
      this.extents = X();
    }
  }, {
    key: "getExtents",
    value: function n() {
      return Object.assign({}, this.extents);
    }
  }]);
  return e;
}();
export { X as EMPTY_BOUNDS, Y as ExtentsManager, _ as addToDictionary, B as addToList, T as addWithFunction, d as arraysEqual, x as clone, J as each, u as extend, G as fastTrim, e as filterList, p as filterNull, w as findAllWithFunction, O as findWithFunction, j as forEach, C as fromArray, I as getAllWithFunction, P as getFromSetWithFunction, S as getWithFunction, V as insertSorted, f as isBoolean, c as isDate, o as isElement, v as isEmpty, h as isFunction, y as isNamedFunction, i as isNumber, a as isObject, l as isString, s as isTextNode, Q as log, L as logEnabled, K as map, m as merge, A as objectsEqual, M as populate, D as remove, E as removeWithFunction, b as replace, z as rotateAnchorOrientation, q as rotatePoint, k as setToArray, R as sgn, F as suggest, W as translateRect, $ as uuid, U as wrap };