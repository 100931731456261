import { animate, state, style, transition, trigger } from '@angular/animations';

export const overlayShow = trigger('overlayShow', [
  state('invisible', 
    style({
      height: '0',
      bottom: '{{height}}px'
    }), {params: {height: '10px', top: '0'}}
  ),
  state('visible', 
    style({
      height: '{{height}}px',
      top: '{{top}}px'
    }), {params: {height: '10px', top: '0'}} 
  ),
  transition('invisible => visible', [
    animate('0.5s 0.2s cubic-bezier(0.34, 1.56, 0.64, 1)')
  ]),
  transition('visible => invisible', [
    animate('0.6s 0.1s cubic-bezier(0.36, 0, 0.66, -0.56)')
  ])
]);

export const overlayButtonShow = trigger('overlayButtonShow', [
  state('button-invisible', 
    style({
      opacity: '0',
      transform: 'rotate(180deg)',
      top: '{{height}}px'
    }), {params: {height: '0', top: '0'}} 
  ),
  state('button-visible', 
    style({
      opacity: '1',
      transform: 'rotate(0)',
      top: '{{top}}px'
    }), {params: {top: '0'}} 
  ),
  transition('button-invisible => button-visible', [
    animate('0.2s 0.3s ease-out')
  ]),
  transition('button-visible => button-invisible', [
    animate('0.2s ease-in')
  ])
]);

export const fadeInDelayedOutAnimation = trigger(
  'fadeInDelayedOutAnimation', [
    transition(':enter',
      [
        style({ opacity: 0 }),
        animate('0.2s ease-out',
          style({ opacity: 1 })
        )
      ]
    ),
    transition(':leave',
      [
        style({ opacity: 1 }),
        animate('0.2s 0.7s ease-out',
          style({ opacity: 0 })
        )
      ]
    )
  ]
);

export const showLine = trigger(
  'showLine', [
    transition(':enter', 
      [
        style({ stroke: 'transparent' }),
        animate('0.3s ease-in', 
          style({ stroke: '#407fff' })
        )
      ]
    ),
    transition(':leave', 
      [
        style({ stroke: '#407fff' }),
        animate('0.3s ease-out', 
          style({ stroke: 'transparent' })
        )
      ]
    )
  ]
);