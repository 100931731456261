<div class="wrapper">
  <naris-inline-input
    [control]="amountControl"
    [min]="1"
    [max]="9999"
    type="number"
    value="1"
    [requiredIfVisible]="true"
    [clearOnDestroy]="true"
    [id]="id + '__periodAmount'"
  />
  <naris-inline-select  
    [control]="scaleControl"
    [options]="scaleOptions"
    [placeholder]="'frequency.select_unit' | translate"
    [value]="firstOption"
    class="ml-2 mr-2"
    [id]="id + '__periodScale'"
  />
</div>