import { Injectable, Injector, OnDestroy } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from '@core/services/http.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { FormService } from '@core/services/form.service';
import { PUSH_ACTIONS } from '@core/constants';
import { environment } from '@src/environments/environment';
import { EAuthContext } from '@core/enums';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PushService extends HttpService implements OnDestroy {

  private pushNotificationTitle = 'push_notification_setup_title';
  private pushNotificationMessage = 'push_notification_setup_message';
  private readonly subs: Subscription[] = [];

  constructor(
    injector: Injector,
    private readonly httpService: HttpService,
    private readonly swPush: SwPush,
    private readonly snackbarService: SnackbarService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly formService: FormService,
    private readonly translate: TranslateService
  ) {
    super(injector);
    this.subs.push(
      this.translate.get([this.pushNotificationTitle, this.pushNotificationMessage]).subscribe((res: any) => {
        this.pushNotificationTitle = res[this.pushNotificationTitle];
        this.pushNotificationMessage = res[this.pushNotificationMessage];
      })
    );
  }

  // eslint-disable-next-line no-console
  public async allowPush(allow: boolean, userId: number | string, callback: (message: string) => void = console.log) {
    if (!this.swPush.isEnabled) return;
    if (allow) {
      try {
        const pushSub = await this.swPush.requestSubscription({serverPublicKey: environment.vapid_public_key});
        this.addSub(userId, pushSub).subscribe({
          next: () => this.setPushBeInformed(true, userId).subscribe({
            next: () => {
              this.snackbarService.open({text: 'You have subscribed to pushnotifications!'});
              this.swPush.notificationClicks.subscribe(event => {
                switch (event.action) {
                  case PUSH_ACTIONS.SNOOZE: this.snooze(event.notification); break;
                  case PUSH_ACTIONS.REDIRECT:
                  default: this.redirect(event.notification.data.url); break;
                }
              });
            },
            error: () => this.handleSubError(userId, callback)
          }),
          error: () => this.handleSubError(userId, callback)
        });
      } catch {
        callback('Subscribe unsuccessful, please allow notifications in the site settings and try again!');
      }
    } else {
      if (!this.swPush.subscription) return;
      try {
        await this.swPush.unsubscribe();
        this.delSub(userId).subscribe({
          next: () => this.setPushBeInformed(false, userId).subscribe(() => this.snackbarService.open({text: 'You have unsubscribed!'})),
          error: () => callback('Could not delete subscription! Please contact us!')
        });
      } catch {
        await this.swPush.requestSubscription({serverPublicKey: environment.vapid_public_key});
        await this.allowPush(allow, userId);
      }
    }
  }

  private handleSubError(userId: number | string, cb: (...args: any[]) => any) {
    this.delSub(userId).subscribe(() => this.setPushBeInformed(false, userId).subscribe());
    cb('Could not save subscription! Please contact us!');
  }

  public getSubscribed(userId: number | string) {
    const url = `/push/restServices/subscribed/${userId}`;
    return this.httpService.get(url, true, false);
  }

  private addSub(uid: number | string, pushSub: PushSubscription) {
    const url = '/push/restServices/subscribe';
    const body = {
      personCaseId: uid,
      sub: pushSub,
      pushNotificationTitle: this.pushNotificationTitle,
      pushNotificationMessage: this.pushNotificationMessage
    };
    return this.httpService.post(url, body, true, false);
  }

  private setPushBeInformed(enable: boolean, userId: number | string) {
    const url = `/profile-tab/my-person/${userId}/tasks/turn-real-time-notifications-${enable ? 'on' : 'off'}`;
    const isRest = this.authService.authContext === EAuthContext.SSO;
    return this.httpService.post(url, {}, isRest);
  }

  private delSub(uid: number | string) {
    const url = '/push/restServices/unsubscribe';
    const body = {personCaseId: uid};
    return this.httpService.post(url, body, true, false);
  }

  private redirect(url: string) {
    const href = url.split('/').splice(0, 5).join('/');
    void this.router.navigate([href]);
  }

  private snooze(notification: NotificationOptions) {
    this.formService.open(notification.data.endpoint);
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
