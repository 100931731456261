import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CoreService, MessageService, UserService } from '@core/services';
import { WORDPRES_BASE_URL } from '@core/constants/core-constants';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ToolbarComponent } from '../../../shared/components/toolbar/toolbar.component';
import { ToolbarItemComponent } from '../../../shared/components/toolbar/toolbar-item/toolbar-item.component';
import { IconComponent } from '../../../shared/elements/icon/icon.component';
import { ButtonComponent } from '../../../shared/elements/button/button.component';
import { ChangelogComponent } from '../../../shared/components/changelog/changelog.component';
import { EmptyStateComponent } from '../../../shared/components/empty-state/empty-state.component';
import type { Changelog } from '@core/classes';

@Component({
  selector: 'naris-changelogs',
  templateUrl: './changelogs.component.html',
  styleUrls: ['./changelogs.component.scss'],
  standalone: true,
  imports: [ToolbarComponent, ToolbarItemComponent, IconComponent, ButtonComponent, ChangelogComponent, MatTooltip, EmptyStateComponent, TranslateModule]
})
export class ChangelogsComponent implements OnInit {

  @Output() public readonly closed = new EventEmitter<any>();

  public changelogs: Changelog[] | undefined = [];
  public currentPanel: number;

  constructor(public readonly messageService: MessageService, private readonly coreService: CoreService, private readonly userService: UserService) {}

  public ngOnInit() {
    if (this.userService.userData._id !== 1)
      this.messageService.fetchChangelogs().subscribe(cl => this.changelogs = cl);
  }

  public onPanelOpen(changelog: Changelog, i: number) {
    changelog.markAsRead();
    this.currentPanel = i;
  }

  public openHelp() {
    const href = `${WORDPRES_BASE_URL}/index.php/category/release-notes`;
    this.coreService.setHelp(href);
    this.coreService.openArticle = false;
    this.coreService.helpOpened = true;
  }
}
