@if (label) {
  <div class="progress-label">{{label}}</div>
}
<div class="progress-wrapper" [class.has-label]="label">
  <mat-progress-bar
    [mode]="mode"
    [color]="color"
    [value]="percentage"
    [class.progress-rounded]="rounded"
    [class.small]="size === 'small'"
    [ngClass]="color" 
  />
  @if (showValueAfter && !showMaxValueAfter) {
    <div class="value-label-after">
      {{value()}}
    </div>
  }
  @if (showMaxValueAfter) {
    <div class="value-label-after">
      {{value()}} / <b>{{maxValue()}}</b>
    </div>
  }
</div>
