import { Injectable } from '@angular/core';
import { IConnectionData } from '@core/models';
import { ObjectData } from '@jsplumbtoolkit/browser-ui';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StrategyMapService {

  public connectionHovered$: Subject<IConnectionData> = new Subject<IConnectionData>;
  public addNode$: Subject<{ parentId: string; position: string }> = new Subject<{ parentId: string; position: string }>;
  public refreshMap$: Subject<void> = new Subject<void>;
  public configClicked$: Subject<{ obj: ObjectData; el: HTMLDivElement }> = new Subject<{ obj: ObjectData; el: HTMLDivElement }>;
  public refreshTables$: Subject<void> = new Subject<void>;

  public strategyVision: string;
  public pinnedConnections: IConnectionData[] = [];
  public nodePosition = '';
  public nodeRelation: { type: string; id: number[] } | undefined;
  public bounds: { vw: number }; 

  public connectionPinned(data: any[], nodeId: string, nodeName: string, nodeType: string, connectionType: string) {
    const duplicate = this.pinnedConnections.find(x => x.node.id === nodeId);
    if (!duplicate) this.pinnedConnections.push({data, node: {id: nodeId, name: nodeName, type: nodeType}, connectionType});
  }

  public getNodePosition() {
    return this.nodePosition;
  }

  public getNodeRelation() {
    return this.nodeRelation;
  }
}
