import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IInputOption } from '@core/models';
import { truncate } from '@core/helpers';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'naris-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatRadioGroup, FormsModule, ReactiveFormsModule, MatRadioButton, MatTooltip]
})
export class RadioComponent {
  @Input() public maxLength = 100;

  @Input()
  public options: IInputOption[] = [
    {value: 'option1', key: 'Option 1'},
    {value: 'option2', key: 'Option 2'},
    {value: 'option3', key: 'Option 3'}
  ];

  @Input()
  public control: FormControl;

  @Input()
  public disabled: boolean;

  @Input() public inputModel: number | string;
  @Output() public readonly inputModelChange = new EventEmitter<number | string>();

  public truncate(value: number | string | undefined): string {
    return truncate(value!.toString(), this.maxLength);
  }

  public length(value: number | string | undefined): number {
    if (typeof value === 'string') {
      return value.length;
    } else {
      return 0;
    }
  }
}
