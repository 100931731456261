<div class="input" [attr.data-test-id]="'slider__' + id">
  @if (!!control) {
  <mat-slider
    [formControl]="control"
    [min]="min"
    [max]="max"
    [step]="step"
    [color]="color"
    thumbLabel
    [displayWith]="formatLabel"
  >
    <input matSliderThumb />
  </mat-slider>
  } @else {

    <mat-slider
      [min]="min"
      [max]="max"
      [step]="step"
      [color]="color"
      [disabled]="disabled"
      thumbLabel
      [displayWith]="formatLabel"
      [discrete]="discrete"
    >
      <input
        matSliderThumb
        [(ngModel)]="inputModel"
        (ngModelChange)="inputModelChange.emit(inputModel)"
      />
    </mat-slider>
  }
  <div class="ticks">
    @for (tick of ticks; track tick) {
    <div class="tick">
      <span class="tick-label" (click)="onTickClicked(tick)" tabindex="0">{{
        tickLabel(tick)
      }}</span>
    </div>
    }
    <div class="tick">
      <span
        class="tick-label"
        (click)="onTickClicked(ticks.length)"
        tabindex="0"
        >{{ tickLabel(ticks.length) }}</span
      >
    </div>
  </div>
</div>
