import { inherits as r, createSuper as e, classCallCheck as a, createClass as t } from "../_virtual/_rollupPluginBabelHelpers.js";
import { BaseSurfaceLayoutAdapter as o } from "./base-surface-layout-adapter.js";
import { isGroup as u, isNode as s } from "../core/model/graph.js";
var l = function (o) {
  r(i, o);
  var l = e(i);
  function i(r, e) {
    var t;
    a(this, i);
    t = l.call(this, e);
    t.group = r;
    t.surface = e;
    return t;
  }
  t(i, [{
    key: "getElements",
    value: function r() {
      return this.group.getMembers();
    }
  }, {
    key: "filter",
    value: function r(e) {
      return (u(e) || s(e)) && e.group == this.group;
    }
  }]);
  return i;
}(o);
var i = "allGroupsRelayout";
var p = "groupRelayoutAdhoc";
var n = "groupMemberRemoved";
var c = "newVertexAddedToGroup";
var v = "existingVertexAddedToGroup";
export { i as ALL_GROUPS_RELAYOUT, p as GROUP_RELAYOUT_ADHOC, v as GROUP_RELAYOUT_EXISTING_VERTEX_ADDED, n as GROUP_RELAYOUT_MEMBER_REMOVED, c as GROUP_RELAYOUT_NEW_VERTEX_ADDED, l as GroupLayoutAdapter };