var a = "context";
var r = "lookup";
var v = "default";
var t = "element";
var e = "text";
var l = "tmpl";
var o = "each";
var u = "if";
var c = "root";
var p = "class";
var s = "style";
var x = "$value";
var f = "$key";
var i = "attribute";
export { p as ATTRIBUTE_CLASS, a as ATTRIBUTE_CONTEXT, v as ATTRIBUTE_DEFAULT, r as ATTRIBUTE_LOOKUP, s as ATTRIBUTE_STYLE, f as PLACEHOLDER_KEY, x as PLACEHOLDER_VALUE, i as TYPE_ATTRIBUTE, o as TYPE_EACH, t as TYPE_ELEMENT, u as TYPE_IF, c as TYPE_ROOT, e as TYPE_TEXT, l as TYPE_TMPL };