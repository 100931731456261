<a 
  class="naris-menu-item"
  [routerLink]="route"
  [class.active]="isActive"
  [class.disabled]="disabled"
  [class.remove-spacing]="removeSpacing"
  (click)="onClick($event)"
>
  <ng-content />
</a>
