import { Component, forwardRef, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TableSettings } from '@core/constants';
import { TableComponent } from '../table/table.component';

@Component({
  selector: 'naris-overview-table',
  templateUrl: './overview-table.component.html',
  styleUrl: './overview-table.component.scss',
  standalone: true,
  imports: [forwardRef(() => TableComponent)]
})
export class OverviewTableComponent {
  
  public href: string;
  private previousValue: string;
  private _formGroup: FormGroup;

  @Input() set formGroup(formGroup: FormGroup) {
    this._formGroup = formGroup;
    if (!!formGroup) formGroup.valueChanges.subscribe(changes => {
      if (JSON.stringify(changes) === this.previousValue) return;
      this.previousValue = JSON.stringify(changes);
      this.setOverviewUrl();
    });
  };
  get formGroup(): FormGroup {
    return this._formGroup;
  }

  @Input() public controlId: string;

  get tableSettingsEnum(): typeof TableSettings {
    return TableSettings; 
  }
  
  private setOverviewUrl() {
    const overviewControl = this.formGroup.get(this.controlId) as FormControl;
    const parentId = this.controlId.replace('OverviewAction', '');
    const control = this.formGroup.get(parentId) as FormControl;

    setTimeout(() => {
      if (!!overviewControl && !!control)
        this.href = overviewControl?.value.replace(`{${parentId}}`, control?.value.toString());
    });
  }

}
