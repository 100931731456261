import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GROUPING_PANEL_TYPES, STATUS_CONFIG } from '@core/constants';
import { truncate } from '@core/helpers';
import { ICaseViewPanel, ITaskGroup } from '@core/models';
import { BeinformedService, TableService, TaskgroupService } from '@core/services';
import { NarisBreadcrumbService } from '@core/services/breadcrumb.service';
import { inOutAnimation } from '@shared/animations/core.animations';
import { forkJoin, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NgClass, NgStyle } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { TaskgroupComponent } from '../../../shared/components/taskgroup/taskgroup.component';
import { CardComponent } from '../../../shared/components/card/card.component';
import { ToolbarComponent } from '../../../shared/components/toolbar/toolbar.component';
import { IconComponent } from '../../../shared/elements/icon/icon.component';
import { AuditExecutionHistoryComponent } from '../audit-execution-history/audit-execution-history.component';
import { AuditExecutionMatrixComponent } from '../audit-execution-matrix/audit-execution-matrix.component';
import { PanelComponent } from '../../../shared/components/panel/panel.component';

@Component({
  selector: 'app-audit-execution-step',
  templateUrl: './audit-execution-step.component.html',
  styleUrls: ['./audit-execution-step.component.scss'],
  animations: [inOutAnimation],
  standalone: true,
  imports: [NgClass, TaskgroupComponent, NgStyle, MatTooltip, CardComponent, ToolbarComponent, IconComponent, AuditExecutionHistoryComponent, AuditExecutionMatrixComponent, PanelComponent, TranslateModule]
})
export class AuditExecutionStepComponent {

  public id: number;
  public title: string;
  public description: string;
  public state: string;
  public stateClass: string;
  public samples?: ICaseViewPanel | null;
  public loaded = false;
  public loading = false;
  public panels?: ICaseViewPanel[] = [];
  public taskgroups: ITaskGroup[] = [];
  public historyObject: any;
  public historyPanelName: string;
  public descriptionPanelText: string | null;
  public descriptionHeaderExpanded: boolean;

  public isBranch = false;
  public isRoot = false;

  public minNumOfSamples: { text?: string; value: number };

  private caseState: string;

  private subs: Subscription[] = [];

  @Input() set href(value: string) {
    let stepId = null;
    let objectId = null;
    let rootObjectId = null;
    const splittedValue = value.split('/');
    if (value.includes('root')) rootObjectId = +splittedValue[splittedValue.indexOf('root') + 1];
    else if (value.includes('group')) objectId = +splittedValue[splittedValue.indexOf('group') + 1];
    else stepId = +splittedValue[splittedValue.length - 1];
    this.id = stepId || objectId || rootObjectId || -1;
    this.isBranch = !stepId && !!objectId && !rootObjectId;
    this.isRoot = !stepId && !objectId && !!rootObjectId;
    this.descriptionPanelText = null;
    this.historyObject = null;
    this.fetchStepCaseView();
  } 

  @Output() public readonly changed = new EventEmitter<any>();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly beinformedService: BeinformedService,
    private readonly router: Router,
    private readonly taskgroupService: TaskgroupService,
    private readonly narisBreadcrumb: NarisBreadcrumbService,
    private readonly tableService: TableService
  ) { }

  public fetchStepCaseView(): void {
    this.loading = true;
    this.panels = undefined;
    this.subs = [];
    if (!this.id) {
      this.loading = false;
      return;
    }
    const baseUrl = this.router.url.includes('/audit-archive/') ? 'audit-archive' : 'audit';
    const endpoint = this.isRoot ? `/${baseUrl}/audit-execution-view-structure/${this.id}` : this.isBranch ? `/${baseUrl}/audit-object/${this.id}` : `/${baseUrl}/audit-step/${this.id}`;
    this.beinformedService.fetchResponseWithContributions(endpoint).subscribe({next: results => {
      const panels = this.beinformedService.extractPanels(results);
      const attributes = this.beinformedService.extractAttributes(results);
      const taskgroups = this.beinformedService.extractTaskGroups(results);
      this.fetchTaskGroups(taskgroups);
      this.title = attributes?.find((i: any) => i.layouthint?.includes('title'))?.valueLabel;
      const descriptionKey = Object.keys(results?.data).find(key => key.endsWith('Description'));
      if (!!descriptionKey)
        this.description = results?.data?.[descriptionKey];
      const attrObj = attributes?.find((attributeObject: any) => attributeObject.name === 'CaseState');
      this.state = attrObj?.options?.find(option => option.code === results.data.CaseState)?.label ?? 'Initial';
      this.stateClass = STATUS_CONFIG[results.data.CaseState]?.color;
      this.caseState = results.data.CaseState;
      const numOfSamplesText = attributes?.find((attributeObject: any) => attributeObject.name === 'MinimumNumberOfSamples')?.label;
      const numOfSamplesValue = results.data.MinimumNumberOfSamples;
      this.minNumOfSamples = {text: numOfSamplesText, value: numOfSamplesValue};
      const label = truncate(this.title, 35);
      const url = this.router.routerState.snapshot.url;
      this.narisBreadcrumb.add(url, label);
      panels?.forEach(panel => {
        if (GROUPING_PANEL_TYPES.includes(panel.name)) this.fetchPanel(panel.href);
      });
      this.loaded = true;
    }, error: () => this.loading = false});
    this.subs.push(
      this.tableService.refreshPanels$.subscribe(() => this.fetchStepCaseView())
    );
  }

  public fetchPanel(endpoint: string) {
    this.beinformedService.fetchResponseWithContributions(endpoint).pipe(
      finalize(() => this.loading = false)
    ).subscribe({next: ({ data, contributions }) => {
      const panels = this.beinformedService.extractPanels({ data, contributions });
      this.samples = panels?.find(p => p.layouthint?.includes('matrix_layout'));
      this.panels = panels?.filter(panel => !panel.layouthint?.includes('matrix_layout') && !panel.layouthint?.includes('dashboard-only'));
    }});
  }

  private fetchTaskGroups(taskgroups: ITaskGroup[]): void {
    this.taskgroups = [];
    // Map endpoint urls to flat array
    const endpoints = taskgroups?.map((group: ITaskGroup) => group.href);
    // If we have endpoints, fetch the taskgroups
    if (endpoints) forkJoin(endpoints.map(endpoint => this.beinformedService.fetchResponseWithContributions(endpoint!))).subscribe({next: result => {
      this.taskgroups = result
        .map((taskgroup: any) => this.beinformedService.extractTaskGroup(taskgroup))
        // Filter only taskgroups that contain actions
        .filter((taskgroup: ITaskGroup) => taskgroup.actions?.length);

      const historyAction = this.taskgroups[0]?.actions?.find(task => task.name === 'HistoryObjectCase');
      this.taskgroups = this.taskgroups.filter(group => {
        group.actions = group.actions?.filter(action => action.name !== 'HistoryObjectCase');
        return group;
      });
      this.getHistoryObject(historyAction);
    }});
  }

  private getHistoryObject(historyAction?: ITaskGroup) {
    this.historyObject = null;
    if (!historyAction) return;
    this.beinformedService.fetchForm(historyAction.href!).subscribe(
      {
        next: result => {
          if (result.data.error?.error?.id === 'Error.ResourceNotFound') {
            // eslint-disable-next-line no-console
            console.log('Error retrieving action data, "getHistoryObject"');
          } else {
            const elements = result.data.error?.formresponse.missing?.anchors[0].elements;
            const jsonString = elements?.find(el => el.elementid === 'JSON')?.suggestion;
            this.historyPanelName = elements?.find(el => el.elementid === 'PanelName')?.suggestion;
            this.getAuditStepDescription(elements?.find(el => el.elementid === 'Description')?.suggestion);
            this.historyObject = jsonString ? this.parseJson(jsonString) : null;
          }
        }
      }
    );
  }

  private parseJson(jsonString: string) {
    try {
      return JSON.parse(jsonString);
    } catch {
      return null;
    }
  }

  private getAuditStepDescription(descriptionSuggestion: string) {
    const auditStepDescriptionArray = descriptionSuggestion ? JSON.parse(descriptionSuggestion) : null;
    this.descriptionPanelText = auditStepDescriptionArray?.[0]?.['AUDITSTEPDESCRIPTION'];
  }

  public matrixChanged() {
    this.fetchStepCaseView();
  }

  public reload(fullPage = false) {
    this.fetchStepCaseView();
    this.changed.emit(true);
    if (fullPage) {
      this.taskgroupService.refreshPage.next(true);
    }
  }

  public panelUpdated(panel: any, event?: boolean) {
    if (panel?.panel?.name === 'Findings') {
      const recPanel = this.panels?.find(p => p.name === 'Recommendations');
      if (!recPanel) return;
      recPanel['reload'] = true;
      setTimeout(() => {
        recPanel['reload'] = false;
      });
    }
    if (this.caseState.toLowerCase() === 'initial' || panel?.panel?.name === 'Conclusion' && this.router.url.includes('root')) {
      this.reload(true);
    }
    if (panel?.panel?.name?.toLowerCase().includes('review')) {
      this.reload();
    }
    if (panel?.name === 'CriteriaAndSamples') {
      if (event) {
        this.reload();
      } else if (this.caseState.toLowerCase() === 'initial') {
        this.fetchPanel(panel.href.replace('/samples', ''));
      } else {
        const tmpSamples = this.samples;
        this.samples = null;
        setTimeout(() => {
          this.samples = tmpSamples;
        });
      }
    }
  }
}
