import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/shared/vendors/material.module';
import { RouterModule } from '@angular/router';
import { SignupComponent } from '@features/auth/signup/signup.component';
import { AuthErrorComponent } from '@features/auth/auth-error/auth-error.component';
import { OrgChoiceComponent } from '@features/auth/org-choice/org-choice.component';
import { SignupInfoComponent } from '@features/auth/signup-info/signup-info.component';
import { PwChangeComponent } from '@features/auth/pw-change/pw-change.component';
import { PwForgotComponent } from '@features/auth/pw-forgot/pw-forgot.component';
import { LoginComponent } from '@features/auth/login/login.component';
import { LinkAccountComponent } from '@features/auth/link-account/link-account.component';
import { OpenCaseComponent } from '@features/auth/open-case/open-case.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule,
    SignupComponent,
    AuthErrorComponent,
    OrgChoiceComponent,
    SignupInfoComponent,
    PwChangeComponent,
    PwForgotComponent,
    LoginComponent,
    LinkAccountComponent,
    OpenCaseComponent
  ]
})
export class AuthModule { }
