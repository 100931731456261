<textarea
  class="area"
  [maxlength]="maxLength"
  [formControl]="control"
  [matTooltip]="error!"
  [matTooltipDisabled]="!error"
  [placeholder]="placeholder"
  [ngClass]="{
    error: !!error || control.invalid,
    disabled: control.disabled,
    rounded
  }"
  (input)="inputChange.emit($event)"
  [attr.data-test-id]="'inline-textarea__' + id"
></textarea>
