import { createClass as t, inherits as e, createSuper as r, classCallCheck as i, defineProperty as o } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { isPort as s } from "../model/graph.js";
var a = function () {
  function e(t, r, a) {
    i(this, e);
    this.obj = t;
    this.toolkit = r;
    this.manager = a;
    o(this, "source", void 0);
    o(this, "target", void 0);
    o(this, "edgeId", void 0);
    o(this, "sourcePort", void 0);
    o(this, "targetPort", void 0);
    o(this, "geometry", void 0);
    this.source = t.source.getFullId();
    this.target = t.target.getFullId();
    if (s(t.source)) {
      this.sourcePort = t.source.id;
      this.source = t.source.getParent().getFullId();
    }
    if (s(t.target)) {
      this.targetPort = t.target.id;
      this.target = t.target.getParent().getFullId();
    }
    this.edgeId = t.getId();
    this.geometry = t.geometry;
  }
  t(e, [{
    key: "generateSourceId",
    value: function t() {
      return this.sourcePort == null ? this.source : this.source + this.toolkit.getGraph().getPortSeparator() + this.sourcePort;
    }
  }, {
    key: "generateTargetId",
    value: function t() {
      return this.targetPort == null ? this.target : this.target + this.toolkit.getGraph().getPortSeparator() + this.targetPort;
    }
  }, {
    key: "_add",
    value: function t() {
      var e = {
        source: this.generateSourceId(),
        target: this.generateTargetId(),
        data: this.obj.data,
        geometry: this.geometry
      };
      var r = this.toolkit.addEdge(e);
      this.manager.edgeChange(this.edgeId, r);
    }
  }, {
    key: "_remove",
    value: function t() {
      this.toolkit.removeEdge(this.obj);
    }
  }, {
    key: "edgeChange",
    value: function t(e) {
      this.obj = e;
      this.edgeId = this.obj.getId();
    }
  }]);
  return e;
}();
var u = function (o) {
  e(a, o);
  var s = r(a);
  function a() {
    i(this, a);
    return s.apply(this, arguments);
  }
  t(a, [{
    key: "undo",
    value: function t() {
      this._remove();
    }
  }, {
    key: "redo",
    value: function t() {
      this._add();
    }
  }, {
    key: "hasDeltas",
    value: function t() {
      return this.obj != null;
    }
  }]);
  return a;
}(a);
var n = function (o) {
  e(a, o);
  var s = r(a);
  function a() {
    i(this, a);
    return s.apply(this, arguments);
  }
  t(a, [{
    key: "undo",
    value: function t() {
      this._add();
    }
  }, {
    key: "redo",
    value: function t() {
      this._remove();
    }
  }, {
    key: "hasDeltas",
    value: function t() {
      return this.obj != null;
    }
  }]);
  return a;
}(a);
export { a as EdgeAction, u as EdgeAddAction, n as EdgeRemoveAction };