import { Component, Input, HostBinding, Output, EventEmitter } from '@angular/core';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { OptionComponent } from '../option/option.component';
import type { IInputOption } from '@core/models';
import type { FormControl } from '@angular/forms';

@Component({
  selector: 'naris-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  standalone: true,
  imports: [MatSelect, FormsModule, ReactiveFormsModule, MatOption, OptionComponent, TranslateModule]
})
export class SelectComponent {

  public focused = false;

  @Input()
  public label: string;

  @Input()
  public color: string;

  @Input()
  public placeholder: string;

  @Input()
  public control: FormControl;

  @Input()
  public options: IInputOption[];

  @Input()
  public multiple: boolean;

  @Input()
  public mandatory: boolean;

  @Input()
  public disabled = false;

  @Input()
  public id: string;

  @Input()
  public value: number;

  @Output() public readonly selectionChanged = new EventEmitter<number>();

  @HostBinding('class')
  get classes() {
    const classes = [];
    if (this.focused && !this.disabled) classes.push('focused');
    if (this.touched && this.valid) classes.push('valid');
    if (this.touched && !this.valid) classes.push('invalid');
    if (this.disabled) {
      classes.push('disabled');
      this.control?.disable();
    } 

    return classes;
  }

  /**
   * Set focus state
   */
  public focus(): void {
    this.focused = true;
  }

  /**
   * Unset focus state
   */
  public blur(): void {
    this.focused = false;
  }

  /**
   * Return validity of the from control
   *
   * Returns true if the from control status is exactly 'VALID'
   */
  get valid() {
    return this.control.status === 'VALID';
  }

  /**
   * Pristine state
   *
   * Return true if the form control hsa not been changed since instantiation.
   */
  get pristine() {
    return this.control.pristine;
  }

  /**
   * Touched state
   *
   * Returns true if the form control has been touched.
   */
  get touched() {
    return this.control?.touched;
  }

  public setSelection(event: MatSelectChange) {
    if (!!this.control)
      this.control.clearValidators();
    else      
      this.selectionChanged.emit(event.value);
  }

  public resetValidation() {
    try {
      const validator = JSON.parse(JSON.stringify(this.control.validator));
      this.control.clearValidators();
      setTimeout(() => {
        this.control.setValidators(validator);
      });
    } catch (e) { console.log(e) }
    
  }
}
