<div class="wrapper" [ngStyle]="{cursor: dragging ? 'none' : 'crosshair'}">
  <canvas
    #canvas
    class="color-palette"
    [width]="height"
    [height]="height"
    (mousedown)="onClick($event)"
    [attr.data-test-id]="'color-palette__Canvas'"
  ></canvas>
  <div
    class="drag-handle"
    cdkDrag
    cdkDragBoundary=".wrapper"
    (cdkDragMoved)="onDragMoved($event)"
    [cdkDragFreeDragPosition]="colorPos"
    (cdkDragStarted)="dragging = true"
    (cdkDragReleased)="dragging = false"
    (cdkDragEnded)="onDragEnded($event)"
    [ngStyle]="{borderColor: currentColor}"
  ></div>
</div>

