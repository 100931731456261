import { Point } from '@angular/cdk/drag-drop';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'point',
  standalone: true
})
export class PointPipe implements PipeTransform {

  public transform(value: any): Point {
    const diff = 318;
    // const x = (diff / 2) - 8;
    // const y = (diff / 2) - 8;
    const x = ((value.likelihood * diff) / 100) - 9;
    const y = 318 - ((value.impact * diff) / 100) - 8;
    return {x: x, y: y};//getPoint(value, parentConfig);
  }
}
