import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService, BeinformedService, HttpService } from '@core/services';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@src/environments/environment';
import { EAuthContext } from '@core/enums';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../../shared/elements/icon/icon.component';

@Component({
  selector: 'naris-audit-execution-assessment',
  templateUrl: './audit-execution-assessment.component.html',
  styleUrls: ['./audit-execution-assessment.component.scss'],
  standalone: true,
  imports: [IconComponent, MatTooltip, TranslateModule]
})
export class AuditExecutionAssessmentComponent implements OnInit {

  @Input() public appraisal: { appraisalId: string; href: string };
  @Output() public readonly changed: EventEmitter<boolean> = new EventEmitter<boolean>();

  public state?: IState;
  public tokens: string[];

  constructor(
    private readonly beinformedService: BeinformedService,
    private readonly httpService: HttpService,
    private readonly authService: AuthService
  ) {}

  public ngOnInit(): void {
    this.setState(this.appraisal?.appraisalId);
  }

  private setState(appraisalId: string) {
    this.state = states.find(state => state.id === appraisalId);
  }

  private getFormData(endpoint: string, appraissalId: string) {
    this.beinformedService.fetchForm(endpoint).subscribe({
      next: result => {
        if (result.data.error?.error?.id === 'Error.ResourceNotFound') {
          // eslint-disable-next-line no-console
          console.log(`Fout: ${result.data.error?.error?.id}`);
        } else {
          this.tokens = result.data.error?.formresponse.tokens || [];
          const pascalAppraisalId = this.stringToPascalCase(appraissalId);
          const postableObject = {tokens: this.tokens, objects: {Sample: {Appraisal: pascalAppraisalId}}};
          const isRest = this.authService.authContext === EAuthContext.SSO;
          this.httpService.post(`${this.appraisal.href}?commit=true`, postableObject, isRest)
            .pipe(
              catchError(() => of(undefined))
            ).subscribe(() => {
              this.setState(appraissalId);
              this.changed.emit(true);
            });
        }
      },
      // eslint-disable-next-line no-console
      error: () => !environment.production && console.log(`Error retrieving endpoint data: ${endpoint}`)
    });
  }

  private stringToPascalCase(text: string): string {
    return text.replace(/[^a-zA-Z0-9]/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');
  }

  public selectAppraisal(appraissalId: string) {
    if (!!this.appraisal?.href) {
      this.getFormData(this.appraisal.href, appraissalId);
    }
  }

}

export interface IState {
  id: string;
  label: string;
}

export const states: IState[] = [
  {id: 'ToBeDetermined', label: 'To be determined'},
  {id: 'Ok', label: 'Ok'},
  {id: 'NotOk', label: 'Not ok'},
  {id: 'NotApplicable', label: 'Not applicable'},
  {id: 'Indeterminable', label: 'Indeterminable'}
];
