<naris-card class="card">
  @if (!isUnavailable && showWelcome && !isStop) {
    <naris-toolbar class="card__header">
      <naris-toolbar-item>
        <h3>{{'collab.participant-questionnaire.invited-title' | translate}}</h3>
      </naris-toolbar-item>
    </naris-toolbar>
    <div class="card__content">
      <div class="card__content__explain">
        <div class="image">
          <naris-empty-state
            [title]="'collab.participant-questionnaire.welcome' | translate"
            [text]="'collab.participant-questionnaire.invited'  | translate:{ questionnaireName, questionnaireDeadline }"
            [image]="'invite'"
          />
        </div>
      </div>
    </div>
    <div class="card__footer">
      <naris-button id="saveGuestQuestionnaire" color="primary" (click)="showWelcome = !showWelcome">{{'next' | translate}}</naris-button>
    </div>
  }
  @if (!isUnavailable && !showWelcome && isStop) {
    <naris-toolbar class="card__header">
      <naris-toolbar-item>
        <h3>{{'collab.participant-questionnaire.invited-title' | translate}}</h3>
      </naris-toolbar-item>
    </naris-toolbar>
    <div class="card__content">
      <div class="card__content__explain">
        <div class="image">
          <naris-empty-state
            [title]="'collab.participant-questionnaire.end' | translate"
            [text]="'collab.participant-questionnaire.ended' | translate"
          />
        </div>
      </div>
    </div>
    <div class="card__footer">
      <naris-button id="startGuestQuestionnaire" color="primary" (click)="isStop = !isStop">{{'back' | translate}}</naris-button>
    </div>
  }
  @if (!isUnavailable && !showWelcome && !isStop) {
    <naris-toolbar class="card__header">
      <naris-toolbar-item>
        <h3>{{label()}}</h3>
        @if (showGuestInfo) {
          <span class="card__header__description">{{'collab.participant-questionnaire.invited-title.description' | translate}}</span>
        }
      </naris-toolbar-item>
    </naris-toolbar>
    @for (formGroups of formsArray; let i = $index; track i) {
      <mat-expansion-panel [expanded]="formGroups.active">
        <mat-expansion-panel-header>
          <mat-panel-title>{{questionFormsChangedIDs().includes(formGroups.id) ? getFormControl(formGroups.id, formGroups?.inputs?.[0]?.id + '@' + formGroups.id).value : 'collab.participant-questionnaire.new' | translate}}</mat-panel-title>
        </mat-expansion-panel-header>
    
        <div class="card__content">
          @if (!!formGroups.savedId) {
            <naris-slidetoggle
              class="update-questionnaire"
              [checked]="!formGroups.isSaved"
              [id]="'FormEnabled' + formGroups.id"
              (click)="$event.stopPropagation()"
              (changed)="toggleFormgroup(formGroups)"
            >{{ 'object.update' | translate }}</naris-slidetoggle>
          }
          @if (!!formGroups.inputs.length) {
            @for (input of formGroups.inputs; track input.id;) {
              <div>
                @switch (input.type) {
                  @case ('string') {
                    <naris-form-field
                      [label]="input.label"
                      [labelSuffix]="(!input.layouthint?.includes('mandatory') && !input.mandatory && input.controlType !== 'table' ? 'optional' :  '') | translate"
                      [tooltip]="input.description"
                    >
                      <naris-input
                        [id]="input.label"
                        [control]="getFormControl(formGroups.id, input.id + '@' + formGroups.id)"
                        [placeholder]="input.placeholder || input.label"
                        [lookup]="input.lookup"
                        [type]="input.inputType"
                        [append]="input.filterSuffix!"
                        [filter]="!!input.filterSuffix"
                        [layouthint]="input.layouthint"
                      />
                    </naris-form-field>
                  }
                  @case ('memo') {
                    <naris-form-field
                      [label]="input.label"
                      [labelSuffix]="(!input.layouthint?.includes('mandatory') && !input.mandatory && input.controlType !== 'table' ? 'optional' :  '') | translate"
                      [tooltip]="input.description"
                    >
                      <naris-input
                        [control]="getFormControl(formGroups.id, input.id + '@' + formGroups.id)"
                        [id]="input.label"
                        [placeholder]="input.placeholder || input.label"
                        type="textarea"
                        [autosize]="true"
                      />
                    </naris-form-field>
                  }
                  @case ('boolean') {
                    <naris-slidetoggle 
                      [control]="getFormControl(formGroups.id, input.id + '@' + formGroups.id)"
                      [id]="input.id"
                    >
                      {{ input.label }}
                    </naris-slidetoggle>
                  }
                }
              </div>
            }
          }
        </div>
      </mat-expansion-panel>
      @if (!formGroups.isSaved) {
        <div class="card__footer">
            <naris-button 
              id="saveGuestQuestionnaire"
              color="default" 
              (click)="submit(formGroups.id, false)"
              [disabled]="!formGroup(formGroups.id)?.valid"
            >{{'collab.participant-questionnaire.save' | translate}}</naris-button>
            
            @if (!formGroups.savedId) {
              <naris-button
                id="saveGuestQuestionnaire"
                color="primary"
                (click)="submit(formGroups.id)"
                [disabled]="!formGroup(formGroups.id)?.valid"
              >{{'collab.participant-questionnaire.save_next' | translate}}</naris-button>
            }
            @if (!formGroups.savedId) {
              <naris-button id="stopGuestQuestionnaire" color="success" (click)="isStop = !isStop">{{'collab.participant-questionnaire.stop' | translate}}</naris-button>
            }
        </div>
      } @else if (i === formsArray.length - 1){
        <div class="card__footer">
          <naris-button
            id="saveGuestQuestionnaire"
            color="primary"
            (click)="createFormGroups();"
          >{{'collab.participant-questionnaire.add' | translate}}</naris-button>
          <naris-button id="stopGuestQuestionnaire" color="success" (click)="isStop = !isStop">{{'collab.participant-questionnaire.stop' | translate}}</naris-button>
        </div>
      }
    }
    
    
    
  }
</naris-card>
