<div class="wrapper">
  @if (!!helpText) {
    <div class="help-text">
      <div [innerHtml]="helpText"></div>
    </div>
  }
  <table>
    <tr>
      @for (title of columnNames; track $index) {
        <th>
          <h4>{{title.toLowerCase() | translate}}</h4>
        </th>
      }
      <th>
        <h4>{{'compare.send_notification' | translate}}</h4>
      </th>
    </tr>
    @for (item of items; track $index) {
      <tr>
        @for (title of columnNames; track $index) {
          <td>
            @if (title.toLowerCase() === 'applicable') {
              <div>
                @if (item.elements[title] === '1') {
                  <naris-icon [icon]="'check'" [iconClass]="'green'" />
                }
                @if (item.elements[title] === '0') {
                  <naris-icon [icon]="'close'" [iconClass]="'warn'" />
                }
                @if ($any(item.elements[title]).toLowerCase() === 'null') {
                  <naris-icon [icon]="'question'" [iconClass]="'accent'" />
                }
              </div>
            } @else {
              <div [class.disabled]="item.elements['Applicable'] !== '0'">
                {{item.elements[title]}}
              </div>
            }
          </td>
        }
        
        <td>
          <naris-slidetoggle
            [checked]="item.elements['Applicable'] !== '0'"
            [disabled]="item.elements['Applicable'] !== '0'"
            (changed)="updateSelectedItems($event, item.code)"
            [id]="'JSONCompareSelectNotificationApplicable'"
          />
        </td>
      </tr>
    }
    
  </table>

</div>
