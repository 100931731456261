import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssessmentService { 
  
  public causes = new Subject<any>();

  public saveForm = new Subject<boolean>();

  public testScript = new Subject<{ label: string; value: string }>();

  public criteria = new Subject<{ _id: number; CriteriumName: string; Description: string }[]>();

  public formData: Record<string, any> = {};

  public formGroupData: Record<string, any> = {};

  public reset() {
    this.formData = {};
    this.formGroupData = [];
  }
}
