function e(t) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    e = function (e) {
      return typeof e;
    };
  } else {
    e = function (e) {
      return e && typeof Symbol === "function" && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    };
  }
  return e(t);
}
function t(e, t) {
  if (!(e instanceof t)) {
    throw new TypeError("Cannot call a class as a function");
  }
}
function r(e, t) {
  for (var r = 0; r < t.length; r++) {
    var n = t[r];
    n.enumerable = n.enumerable || false;
    n.configurable = true;
    if ("value" in n) n.writable = true;
    Object.defineProperty(e, n.key, n);
  }
}
function n(e, t, n) {
  if (t) r(e.prototype, t);
  if (n) r(e, n);
  return e;
}
function o(e, t, r) {
  if (t in e) {
    Object.defineProperty(e, t, {
      value: r,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    e[t] = r;
  }
  return e;
}
function a(e, t) {
  if (typeof t !== "function" && t !== null) {
    throw new TypeError("Super expression must either be null or a function");
  }
  e.prototype = Object.create(t && t.prototype, {
    constructor: {
      value: e,
      writable: true,
      configurable: true
    }
  });
  if (t) i(e, t);
}
function u(e) {
  u = Object.setPrototypeOf ? Object.getPrototypeOf : function e(t) {
    return t.__proto__ || Object.getPrototypeOf(t);
  };
  return u(e);
}
function i(e, t) {
  i = Object.setPrototypeOf || function e(t, r) {
    t.__proto__ = r;
    return t;
  };
  return i(e, t);
}
function f() {
  if (typeof Reflect === "undefined" || !Reflect.construct) return false;
  if (Reflect.construct.sham) return false;
  if (typeof Proxy === "function") return true;
  try {
    Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
function l(e) {
  if (e === void 0) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }
  return e;
}
function c(e, t) {
  if (t && (typeof t === "object" || typeof t === "function")) {
    return t;
  } else if (t !== void 0) {
    throw new TypeError("Derived constructors may only return object or undefined");
  }
  return l(e);
}
function s(e) {
  var t = f();
  return function r() {
    var n = u(e),
      o;
    if (t) {
      var a = u(this).constructor;
      o = Reflect.construct(n, arguments, a);
    } else {
      o = n.apply(this, arguments);
    }
    return c(this, o);
  };
}
function y(e, t) {
  while (!Object.prototype.hasOwnProperty.call(e, t)) {
    e = u(e);
    if (e === null) break;
  }
  return e;
}
function p(e, t, r) {
  if (typeof Reflect !== "undefined" && Reflect.get) {
    p = Reflect.get;
  } else {
    p = function e(t, r, n) {
      var o = y(t, r);
      if (!o) return;
      var a = Object.getOwnPropertyDescriptor(o, r);
      if (a.get) {
        return a.get.call(n);
      }
      return a.value;
    };
  }
  return p(e, t, r || e);
}
function b(e, t) {
  return m(e) || w(e, t) || O(e, t) || j();
}
function h(e) {
  return d(e) || v(e) || O(e) || S();
}
function d(e) {
  if (Array.isArray(e)) return g(e);
}
function m(e) {
  if (Array.isArray(e)) return e;
}
function v(e) {
  if (typeof Symbol !== "undefined" && e[Symbol.iterator] != null || e["@@iterator"] != null) return Array.from(e);
}
function w(e, t) {
  var r = e == null ? null : typeof Symbol !== "undefined" && e[Symbol.iterator] || e["@@iterator"];
  if (r == null) return;
  var n = [];
  var o = true;
  var a = false;
  var u, i;
  try {
    for (r = r.call(e); !(o = (u = r.next()).done); o = true) {
      n.push(u.value);
      if (t && n.length === t) break;
    }
  } catch (e) {
    a = true;
    i = e;
  } finally {
    try {
      if (!o && r["return"] != null) r["return"]();
    } finally {
      if (a) throw i;
    }
  }
  return n;
}
function O(e, t) {
  if (!e) return;
  if (typeof e === "string") return g(e, t);
  var r = Object.prototype.toString.call(e).slice(8, -1);
  if (r === "Object" && e.constructor) r = e.constructor.name;
  if (r === "Map" || r === "Set") return Array.from(e);
  if (r === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(r)) return g(e, t);
}
function g(e, t) {
  if (t == null || t > e.length) t = e.length;
  for (var r = 0, n = new Array(t); r < t; r++) n[r] = e[r];
  return n;
}
function S() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function j() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
export { g as arrayLikeToArray, m as arrayWithHoles, d as arrayWithoutHoles, l as assertThisInitialized, t as classCallCheck, n as createClass, s as createSuper, o as defineProperty, p as get, u as getPrototypeOf, a as inherits, f as isNativeReflectConstruct, v as iterableToArray, w as iterableToArrayLimit, j as nonIterableRest, S as nonIterableSpread, c as possibleConstructorReturn, i as setPrototypeOf, b as slicedToArray, y as superPropBase, h as toConsumableArray, e as typeof, O as unsupportedIterableToArray };