import { createClass as t, classCallCheck as e, defineProperty as n, toConsumableArray as o } from "../_virtual/_rollupPluginBabelHelpers.js";
import { PanAxes as i } from "./pan-zoom-options.js";
import { PinchListener as a } from "./pinch-listener.js";
import { addWheelListener as s, removeWheelListener as r } from "./wheel-listener.js";
import { FixedLayer as h } from "./fixed-layer.js";
import { extend as l } from "../ui-core/util/util.js";
import { pageLocation as u, EventManager as m, touches as v } from "../ui-core/browser-ui-renderer/event-manager.js";
import { EVENT_MOUSEMOVE as c, EVENT_MOUSEUP as f, EVENT_CONTEXTMENU as d, EVENT_MOUSEDOWN as p } from "../ui-core/browser-ui-renderer/event-constants.js";
import { consume as y, addClass as g } from "../ui-core/browser-ui-renderer/browser-util.js";
import { lineLength as x, encloses as w, intersects as z } from "../ui-core/util/geom.js";
var _ = "transformOrigin";
var k = "transform";
var M = "translateX";
var b = "translateY";
var E = "scale";
var C = "px";
var P = "width";
var T = "height";
var Z = "jtk-pan-zoom";
var A = "jtk-pan-zoom-canvas";
var O = "left";
var S = "right";
var R = "top";
var B = "bottom";
var W = "center";
function N() {}
function F(t) {
  var e = t,
    n = 0,
    o = 0;
  while (e != null) {
    if (!isNaN(e.scrollLeft)) {
      n += e.scrollLeft;
    }
    if (!isNaN(e.scrollTop)) {
      o += e.scrollTop;
    }
    e = e.parentNode;
  }
  return {
    x: n,
    y: o
  };
}
var L = function () {
  function o(t) {
    var r = this;
    e(this, o);
    n(this, "canvasElement", void 0);
    n(this, "viewportElement", void 0);
    n(this, "_panRepeatTimer", void 0);
    n(this, "_doWheelZoom", void 0);
    n(this, "eventManager", void 0);
    n(this, "pinchListener", void 0);
    n(this, "position", {
      x: 0,
      y: 0
    });
    n(this, "zoom", void 0);
    n(this, "transformOrigin", {
      x: 0,
      y: 0
    });
    n(this, "_currentCompoundZoom", void 0);
    n(this, "panning", false);
    n(this, "_panFilterFunction", void 0);
    n(this, "pinchZooming", false);
    n(this, "zooming", false);
    n(this, "zoomingWithWheel", false);
    n(this, "downAt", void 0);
    n(this, "_viewportElementSize", void 0);
    n(this, "_canvasElementSize", void 0);
    n(this, "zoomRange", void 0);
    n(this, "zoomAtZoomStart", void 0);
    n(this, "maximumZoomTravel", 150);
    n(this, "distanceAtZoomStart", void 0);
    n(this, "lastDistance", void 0);
    n(this, "canvasAtPanStart", void 0);
    n(this, "lastMouseX", -1);
    n(this, "lastMouseY", -1);
    n(this, "lastMovedAt", null);
    n(this, "lastVelocity", 0);
    n(this, "lastAcceleration", 0);
    n(this, "onZoom", void 0);
    n(this, "onMaybeZoom", void 0);
    n(this, "onPan", void 0);
    n(this, "onMouseDown", void 0);
    n(this, "onMouseUp", void 0);
    n(this, "onMouseMove", void 0);
    n(this, "onSetTransformOrigin", void 0);
    n(this, "clamp", void 0);
    n(this, "clampZoom", void 0);
    n(this, "enablePan", void 0);
    n(this, "directRender", void 0);
    n(this, "enableWheelZoom", void 0);
    n(this, "enableAnimation", void 0);
    n(this, "panWithMetaKey", void 0);
    n(this, "wheelFilter", void 0);
    n(this, "wheelZoomRequiresMetaKey", void 0);
    n(this, "wheelDirection", void 0);
    n(this, "wheelSensitivity", void 0);
    n(this, "wheelPan", void 0);
    n(this, "wheelPanAxis", void 0);
    n(this, "background", void 0);
    n(this, "fixedLayer", void 0);
    n(this, "_mouseMove", void 0);
    n(this, "_mouseMoveEnd", void 0);
    n(this, "_mouseMoveReset", void 0);
    n(this, "_mouseDown", void 0);
    n(this, "_contextMenu", void 0);
    n(this, "viewport", void 0);
    n(this, "consumeRightClick", void 0);
    n(this, "smartMinimumZoom", void 0);
    n(this, "_renderingSuspended", void 0);
    n(this, "idFunction", void 0);
    n(this, "getOffset", void 0);
    n(this, "getOffsetRelativeToRoot", void 0);
    n(this, "getSize", void 0);
    n(this, "enabled", void 0);
    n(this, "clampToBackground", void 0);
    n(this, "clampToBackgroundExtents", void 0);
    n(this, "filter", void 0);
    n(this, "_suspendMap", {});
    n(this, "handlers", n({
      start: function t(e, n) {
        if (r.pinchZooming) return;
        var o = e.srcElement || e.target;
        if (r.enabled && (o === r.canvasElement || o === r.viewportElement || o._jtkDecoration || r.background && r.background.owns(o) || r.filter(o, e) === true)) {
          r.zoomingWithWheel = false;
          r.lastMouseX = -1;
          r.lastMouseY = -1;
          if (e.which === 3 && r.enableWheelZoom !== false && (e.mozInputSource == null || e.mozInputSource === 1)) {
            r.zooming = true;
            r.downAt = u(e);
            r._currentCompoundZoom = r.getCompoundZoom();
            r._viewportElementSize = r._$_getViewportSize();
            r._canvasElementSize = r._$_getCanvasSize();
            r._setTransformOriginToEvent(e);
            r.canvasAtPanStart = l({}, r.position);
            r.zoomAtZoomStart = r.zoom;
          } else if (n.length <= 1) {
            if (r._canStartPanning(e)) {
              r._viewportElementSize = r._$_getViewportSize();
              r._canvasElementSize = r._$_getCanvasSize();
              r.panning = true;
              r._currentCompoundZoom = r.getCompoundZoom();
              r.downAt = u(e);
              r.canvasAtPanStart = l({}, r.position);
              y(e);
            }
          }
        }
        r.onMouseDown(e, r);
      },
      move: function t(e, n) {
        var o, i, a;
        r.zoomingWithWheel = false;
        if (r.pinchZooming) {
          return;
        }
        if (r.zooming) {
          a = u(e);
          o = a.x - r.downAt.x;
          i = a.y - r.downAt.y;
          r._zoomBy(o, i, e);
        } else if (r.panning && r.enablePan && r.downAt != null) {
          y(e);
          a = u(e);
          o = (a.x - r.downAt.x) / r._currentCompoundZoom;
          i = (a.y - r.downAt.y) / r._currentCompoundZoom;
          var s = r._setCanvasPosition({
            x: r.canvasAtPanStart.x + o,
            y: r.canvasAtPanStart.y + i,
            animate: false
          });
          r.onPan(s.x, s.y, r.zoom, r.zoom, e);
          r.background && r.background.pan();
          r.fixedLayer.pan();
          y(e);
        }
        r.onMouseMove(e, r);
      },
      end: function t(e, n) {
        if (r.pinchZooming) {
          return;
        }
        r.zooming = false;
        r.downAt = null;
        r.panning = false;
        r._currentCompoundZoom = 1;
        r._viewportElementSize = null;
        r._canvasElementSize = null;
        r.zoomingWithWheel = false;
        r.eventManager.off(document, c, r._mouseMove);
        r.eventManager.off(document, f, r._mouseMoveEnd);
        r.eventManager.on(document, c, r._mouseMoveReset);
        r.onMouseUp(e, r);
      }
    }, d, function (t) {}));
    t.events = t.events || {};
    this.zoom = t.zoom || 1;
    this.zoomRange = t.zoomRange || [.05, 3];
    this.fixedLayer = new h(this);
    this.eventManager = new m();
    this.viewport = t.viewport;
    this.canvasElement = t.canvasElement;
    this._setCanvasPosition({
      x: 0,
      y: 0,
      animate: false
    });
    this.viewportElement = t.viewportElement;
    g(this.viewportElement, Z);
    g(this.canvasElement, A);
    this.idFunction = t.idFunction;
    this.getOffset = t.getOffset;
    this.getOffsetRelativeToRoot = t.getOffsetRelativeToRoot;
    this.getSize = t.getSize;
    this.onZoom = t.events.zoom || N;
    this.onMaybeZoom = t.events.maybeZoom || function () {
      return true;
    };
    this.onPan = t.events.pan || N;
    this.onMouseDown = t.events.mousedown || N;
    this.onMouseUp = t.events.mouseup || N;
    this.onMouseMove = t.events.mousemove || N;
    this.onSetTransformOrigin = t.events.transformOrigin || N;
    this.clamp = !(t.clamp === false);
    this.clampZoom = t.clampZoom !== false;
    this.panWithMetaKey = t.panWithMetaKey === true;
    this.enablePan = t.enablePan !== false;
    this._panFilterFunction = t.panFilter && typeof t.panFilter === "function" ? t.panFilter : null;
    this.directRender = t.directRender === true;
    this.enableAnimation = t.enableAnimation !== false;
    var v = t.wheel || {};
    this.enableWheelZoom = v.zoom !== false;
    this.wheelFilter = v.filter || function () {
      return true;
    };
    this.wheelZoomRequiresMetaKey = v.useMetaKey === true;
    this.wheelDirection = v.reverse === true ? -1 : 1;
    this.wheelSensitivity = this.wheelDirection * (v.sensitivity || 10);
    this.wheelPan = v.pan === true;
    this.wheelPanAxis = v.axis || i.both;
    this.consumeRightClick = t.consumeRightClick !== false;
    this.smartMinimumZoom = t.smartMinimumZoom;
    this._renderingSuspended = false;
    this._mouseMove = function (t) {
      this._call("move", t);
    }.bind(this);
    this._mouseMoveEnd = function (t) {
      this._call("end", t);
    }.bind(this);
    this._mouseMoveReset = function (t) {
      this.zoomingWithWheel = false;
    }.bind(this);
    this.enabled = !(t.enabled === false);
    this.clampToBackground = t.clampToBackground;
    this.clampToBackgroundExtents = t.clampToBackgroundExtents;
    this.filter = t.filter || function () {
      return false;
    };
    if (this.enableWheelZoom || this.wheelPan) {
      this._doWheelZoom = function (t, e, n, o) {
        return r.wheelPanOrZoom(t, e, n, o);
      };
      s(this.viewportElement, this._doWheelZoom, true, this.wheelZoomRequiresMetaKey);
    }
    this.pinchListener = new a({
      el: this.viewportElement,
      enableWheelZoom: this.enableWheelZoom,
      onPinch: function t(e, n, o, i) {
        r._zoom(i * r.zoomAtZoomStart);
        var a = e.x - r.downAt.x,
          s = e.y - r.downAt.y;
        r._setCanvasPosition({
          x: r.canvasAtPanStart.x + a,
          y: r.canvasAtPanStart.y + s,
          animate: false
        });
      },
      onPinchStart: function t(e, n) {
        r.pinchZooming = true;
        r.downAt = e;
        r.distanceAtZoomStart = r.lastDistance = n;
        r.zoomAtZoomStart = r.zoom;
        r._setTransformOriginToPageLocation(r.downAt.x, r.downAt.y);
        r.canvasAtPanStart = l({}, r.position);
      },
      onPinchEnd: function t() {
        r.pinchZooming = false;
        r.downAt = null;
      }
    });
    this._zoom(this.zoom, null, false, false, true);
    this._writeTransformOrigin();
    this.background = new I();
    if (t.background) {
      this.background.addBackground(t.background);
    }
    this._mouseDown = function (t) {
      r.start(t);
    };
    this.eventManager.on(this.viewportElement, p, this._mouseDown);
    this.eventManager.on(document, c, this._mouseMoveReset);
    this._contextMenu = function (t) {
      r._call(d, t);
    };
    this.eventManager.on(this.viewportElement, d, this._contextMenu);
  }
  t(o, [{
    key: "_$_getViewportSize",
    value: function t() {
      return this.getSize(this.viewportElement);
    }
  }, {
    key: "_$_getCanvasSize",
    value: function t() {
      return this.getSize(this.canvasElement);
    }
  }, {
    key: "_animateToCanvasPosition",
    value: function t(e, n, o, i) {
      var a = this;
      i = i || 150;
      var s = this.position,
        r = s.x,
        h = s.y;
      var l = this.zoom;
      var u = e - l,
        m = n - r,
        v = o - h;
      var c = null;
      var f = function t(s) {
        if (!c) {
          c = s;
        }
        var f = s - c;
        var d = f / i;
        var p = l + d * u,
          y = r + d * m,
          g = h + d * v;
        a.canvasElement.style.transform = "scale(".concat(p, ") translateX(").concat(y / p, "px) translateY(").concat(g / p, "px)");
        if (f < i) {
          requestAnimationFrame(t);
        } else {
          a.zoom = e;
          a.position = {
            x: n,
            y: o
          };
          a.onPan(n, o, a.zoom, a.zoom);
        }
      };
      requestAnimationFrame(f);
    }
  }, {
    key: "_setCanvasPosition",
    value: function t(e) {
      var n = this._clamp(e.x, e.y);
      if (this.enableAnimation && e.animate) {
        if (e.zoom != null) {
          var o = this._constrainZoom(e.zoom);
          this._animateToCanvasPosition(o, n.x, n.y, e.animationDuration);
        } else {
          this.position = n;
          this._animateTransform(null, e.onComplete);
        }
      } else {
        this.position = n;
        this._writeTransform();
        e.onComplete && e.onComplete(this.position);
      }
      return this.position;
    }
  }, {
    key: "_moveCanvas",
    value: function t(e, n, o, i, a) {
      var s = this.position;
      return this._setCanvasPosition({
        x: s.x + e,
        y: s.y + n,
        animate: o,
        onComplete: i,
        animationDuration: a
      });
    }
  }, {
    key: "_wheelPan",
    value: function t(e, n, o, i) {
      if (!i) {
        this.pan(-n * 100, -o * 100);
      }
    }
  }, {
    key: "_wheelZoom",
    value: function t(e, n, o, i) {
      this.zoomAtZoomStart = this.zoom;
      if (!this.zoomingWithWheel) {
        this._setTransformOriginToEvent(e);
        this.zoomingWithWheel = true;
      }
      this._zoomBy(0, e.normalizedWheelDelta * this.wheelSensitivity, e, true);
    }
  }, {
    key: "wheelPanOrZoom",
    value: function t(e, n, o, a) {
      if (this.wheelFilter(e)) {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();
        if (!this.wheelPan || a) {
          this._wheelZoom(e, n, o, a);
        } else {
          n = this.wheelPanAxis === i.both || this.wheelPanAxis === i.x ? n : 0;
          o = this.wheelPanAxis === i.both || this.wheelPanAxis === i.y ? o : 0;
          this._wheelPan(e, n, o, a);
        }
      }
    }
  }, {
    key: "setSuspendRendering",
    value: function t(e) {
      this._renderingSuspended = e;
    }
  }, {
    key: "_cssAnimation",
    value: function t(e, n, o) {
      var i = this;
      this.canvasElement.animate(e, {
        duration: n || 250
      }).finished.then(function () {
        for (var t in e) {
          i.canvasElement.style[t] = e[t];
        }
        if (o) {
          o(i.position);
        }
      });
    }
  }, {
    key: "_constructTransformProperty",
    value: function t() {
      return "".concat(E, "(").concat(this.zoom, ") ").concat(M, "(").concat(this.position.x / this.zoom).concat(C, ") ").concat(b, "(").concat(this.position.y / this.zoom).concat(C, ")");
    }
  }, {
    key: "_writeTransform",
    value: function t() {
      this.canvasElement.style[k] = this._constructTransformProperty();
    }
  }, {
    key: "_animateTransform",
    value: function t(e, o) {
      if (this.canvasElement.animate != null) {
        var i = n({}, k, this._constructTransformProperty());
        this._cssAnimation(i, e, o);
      } else {
        this._writeTransform();
      }
    }
  }, {
    key: "_constructTransformOriginProperty",
    value: function t() {
      return this.transformOrigin.x + "% " + this.transformOrigin.y + "%";
    }
  }, {
    key: "_writeTransformOrigin",
    value: function t() {
      this.canvasElement.style[_] = this._constructTransformOriginProperty();
    }
  }, {
    key: "_originHelper",
    value: function t(e, n, o) {
      var i = o ? {
          x: e,
          y: n
        } : this.fromPageLocation(e, n),
        a = this._canvasElementSize || this._$_getCanvasSize();
      return {
        w: a.w,
        h: a.h,
        xy: i,
        xScale: i.x / a.w,
        yScale: i.y / a.h,
        o: {
          x: i.x / a.w * 100,
          y: i.y / a.h * 100
        }
      };
    }
  }, {
    key: "_setTransformHelper",
    value: function t(e, n, o) {
      var i,
        a,
        s,
        r,
        h = this.transformOrigin.x / 100 * n,
        l = this.transformOrigin.y / 100 * o;
      i = -(h * (1 - this.zoom));
      a = -(l * (1 - this.zoom));
      this.transformOrigin = e;
      this._writeTransformOrigin();
      h = this.transformOrigin.x / 100 * n;
      l = this.transformOrigin.y / 100 * o;
      s = -(h * (1 - this.zoom));
      r = -(l * (1 - this.zoom));
      var u = this._moveCanvas(s - i, r - a, false);
      this.onSetTransformOrigin && this.onSetTransformOrigin(this.transformOrigin, u);
    }
  }, {
    key: "_setTransformOriginToPageLocation",
    value: function t(e, n) {
      var o = this._originHelper(e, n, false);
      this._setTransformHelper(o.o, o.w, o.h);
    }
  }, {
    key: "_setTransformOriginToEvent",
    value: function t(e) {
      var n = u(e);
      this._setTransformOriginToPageLocation(n.x, n.y);
    }
  }, {
    key: "_setTransformOriginToCanvasPoint",
    value: function t(e, n) {
      var o = this._originHelper(e, n, true);
      this._setTransformHelper(o.o, o.w, o.h);
    }
  }, {
    key: "_constrainZoom",
    value: function t(e) {
      var n = this.zoomRange[0];
      if (this.smartMinimumZoom) {
        n = .5;
        var o = this.getBoundsInfo().z,
          i = e / o;
        if (i < n) e = o * n;
      } else {
        if (e < n) {
          e = n;
        }
      }
      if (e > this.zoomRange[1]) {
        e = this.zoomRange[1];
      }
      return e;
    }
  }, {
    key: "_zoom",
    value: function t(e, n, o, i, a) {
      var s = this;
      if (this.directRender) {
        return;
      }
      if (e == null || isNaN(e) || e < 0) return;
      e = this._constrainZoom(e);
      var r = this.zoom;
      var h = function t(i) {
        if (!a) {
          s.onZoom(0, 0, e, r, n, o);
        }
        if (s.background != null) {
          s.background.setZoom(e);
        }
        s.fixedLayer.pan();
        if (s.clampZoom) {
          var h = s.position;
          var l = s._clamp(h.x, h.y);
          if (l.x != h.x || l.y != h.y) {
            s._setCanvasPosition({
              x: l.x,
              y: l.y,
              animate: false
            });
          }
        }
      };
      if (i) {
        var l = this.getViewportCenter();
        this._setTransformOriginToCanvasPoint(l.x, l.y);
        this.zoom = e;
        this._animateTransform(null, h);
      } else {
        this.zoom = e;
        this._writeTransform();
        h(this.position);
      }
      return this.zoom;
    }
  }, {
    key: "_zoomBy",
    value: function t(e, n, o, i) {
      if (n < -this.maximumZoomTravel) n = -this.maximumZoomTravel;
      if (n > this.maximumZoomTravel) n = this.maximumZoomTravel;
      this._zoomWithMappedRange(this.zoomAtZoomStart, n, -this.maximumZoomTravel, this.maximumZoomTravel, o, i);
    }
  }, {
    key: "_zoomWithMappedRange",
    value: function t(e, n, o, i, a, s) {
      var r = n / (n >= 0 ? i : o),
        h = n >= 0 ? 1 : 0,
        l = e + r * (this.zoomRange[h] - e);
      this._zoom(l, a, s);
    }
  }, {
    key: "_clamp",
    value: function t(e, n, o) {
      if (!this.clamp && !this.clampToBackground && !this.clampToBackgroundExtents) {
        return {
          x: e,
          y: n
        };
      } else {
        var i = this._viewportElementSize || this._$_getViewportSize();
        var a = this._apparentOffset(),
          s = e,
          r = n,
          h = this.clamp ? this.getBoundsInfo() : {
            x: 0,
            y: 0,
            w: 0,
            h: 0,
            vw: i.w,
            vh: i.h,
            padding: o,
            z: 1
          };
        o = (o || 20) * this.zoom;
        if ((this.clampToBackground || this.clampToBackgroundExtents) && this.background != null) {
          var l = this.background.getWidth(),
            u = this.background.getHeight(),
            m = Math.max(h.x + h.w, l),
            v = Math.max(h.y + h.h, u);
          h.w = m - h.w;
          h.h = v - h.h;
          var c = h.vw / h.w,
            f = h.vh / h.h;
          h.z = Math.min(c, f);
          if (this.clampToBackgroundExtents) o = Math.max(h.vw, h.vh);
        }
        var d = [h.x + h.w, h.y + h.h];
        if (this.background) {
          d[0] = Math.max(d[0], this.background.getWidth());
          d[1] = Math.max(d[1], this.background.getHeight());
        }
        var p = e + a.x + d[0] * this.zoom - o,
          y = n + a.y + d[1] * this.zoom - o,
          g = e + a.x + h.x * this.zoom + o,
          x = n + a.y + h.y * this.zoom + o;
        if (p < 0) s -= p;
        if (g > h.vw) s -= g - h.vw;
        if (y < 0) r -= y;
        if (x > h.vh) r -= x - h.vh;
        return {
          x: s,
          y: r
        };
      }
    }
  }, {
    key: "_getPosition",
    value: function t(e) {
      var n = e;
      return {
        x: parseInt(n.style.left, 10) || 0,
        y: parseInt(n.style.top, 10) || 0
      };
    }
  }, {
    key: "_apparentOffset",
    value: function t() {
      var e = this._canvasElementSize || this._$_getCanvasSize(),
        n = this.transformOrigin.x / 100 * e.w,
        o = this.transformOrigin.y / 100 * e.h;
      return {
        x: n * (1 - this.zoom),
        y: o * (1 - this.zoom)
      };
    }
  }, {
    key: "_canStartPanning",
    value: function t(e) {
      var n = e.srcElement || e.target;
      var o = this.panWithMetaKey && !(e.metaKey || e.ctrlKey);
      if (o) {
        return false;
      } else {
        if (this._panFilterFunction != null) {
          return this._panFilterFunction(n) !== false;
        } else {
          return true;
        }
      }
    }
  }, {
    key: "_call",
    value: function t(e, n) {
      if (e === d && this.consumeRightClick) {
        n.preventDefault && n.preventDefault();
      }
      var o = v(n);
      this.handlers[e](n, o);
    }
  }, {
    key: "start",
    value: function t(e) {
      if (this.enabled && e != null) {
        this.eventManager.off(document, c, this._mouseMoveReset);
        this.eventManager.on(document, c, this._mouseMove);
        this.eventManager.on(document, f, this._mouseMoveEnd);
        this.handlers["start"](e, v(e));
      }
    }
  }, {
    key: "getBoundsInfo",
    value: function t(e, n) {
      var o = this._viewportElementSize || this._$_getViewportSize();
      if (this.viewport.isEmpty()) {
        return {
          w: 0,
          h: 0,
          x: 0,
          y: 0,
          vw: o.w,
          vh: o.h,
          padding: e,
          z: 1,
          zoom: 1
        };
      }
      e = e || 0;
      n = n || .9;
      var i = this.viewport.getBoundsWidth(),
        a = this.viewport.getBoundsHeight(),
        s = o.w / ((i + e * 2) / n),
        r = o.h / ((a + e * 2) / n),
        h = Math.min(s, r);
      return {
        w: i,
        h: a,
        x: this.viewport.getX(),
        y: this.viewport.getY(),
        vw: o.w,
        vh: o.h,
        padding: e,
        z: h,
        zoom: this.zoom
      };
    }
  }, {
    key: "isPinchZooming",
    value: function t() {
      return this.pinchZooming;
    }
  }, {
    key: "add",
    value: function t(e, n) {
      if (n) {
        e._jtkDecoration = true;
      }
    }
  }, {
    key: "suspend",
    value: function t(e) {
      var n = typeof e === "string" ? e : this.idFunction(e);
      this._suspendMap[n] = true;
    }
  }, {
    key: "isSuspended",
    value: function t(e) {
      var n = typeof e === "string" ? e : this.idFunction(e);
      return this._suspendMap[n] === true;
    }
  }, {
    key: "restore",
    value: function t(e) {
      var n = typeof e === "string" ? e : this.idFunction(e);
      delete this._suspendMap[n];
    }
  }, {
    key: "remove",
    value: function t(e) {
      var n = this.idFunction(e);
      delete this._suspendMap[n];
    }
  }, {
    key: "reset",
    value: function t() {
      this._suspendMap = {};
      this._setCanvasPosition({
        x: 0,
        y: 0,
        animate: false
      });
    }
  }, {
    key: "zoomToFit",
    value: function t(e) {
      e = e || {};
      var n = this.getBoundsInfo(e.padding, e.fill);
      if (!(e.doNotZoomIfVisible && n.z > this.zoom)) {
        this._zoom(n.z);
      }
      this.alignContent({
        bounds: n,
        padding: e.padding,
        doNotAnimate: e.doNotAnimate !== false,
        onComplete: e.onComplete,
        doNotFirePanEvent: e.doNotFirePanEvent,
        alignX: e.alignX || W,
        alignY: e.alignY || W
      });
    }
  }, {
    key: "zoomToFitIfNecessary",
    value: function t(e) {
      var n = l(e || {}, {});
      n.doNotZoomIfVisible = true;
      this.zoomToFit(n);
    }
  }, {
    key: "zoomToElements",
    value: function t(e) {
      var n = this._$_getViewportSize();
      var o = {
        x: Infinity,
        y: Infinity,
        xMax: -Infinity,
        yMax: -Infinity,
        z: 1,
        vw: n.w,
        vh: n.h
      };
      for (var i = 0; i < e.elements.length; i++) {
        var a = e.elements[i],
          s = this.viewport.getPosition(this.idFunction(a));
        if (s) {
          o.x = Math.min(o.x, s.x);
          o.y = Math.min(o.y, s.y);
          o.xMax = Math.max(o.xMax, s.x2);
          o.yMax = Math.max(o.yMax, s.y2);
        }
      }
      var r = e.fill || .9;
      o.w = o.xMax - o.x;
      o.h = o.yMax - o.y;
      o.z = r * Math.min(o.vw / o.w, o.vh / o.h);
      if (!(e.doNotZoomIfVisible && o.z > this.zoom)) {
        this._zoom(o.z);
      }
      this.centerContent({
        bounds: o,
        doNotAnimate: e.doNotAnimate !== false,
        onComplete: e.onComplete,
        doNotFirePanEvent: e.doNotFirePanEvent
      });
    }
  }, {
    key: "zoomToBackground",
    value: function t(e) {
      e = e || {};
      if (this.background != null) {
        var n = this.background.getWidth(),
          o = this.background.getHeight(),
          i = this._$_getViewportSize(),
          a = i.w / n,
          s = i.h / o,
          r = Math.min(a, s),
          h = {
            w: n,
            h: o,
            x: 0,
            y: 0,
            vw: i.w,
            vh: i.h,
            padding: 0,
            z: r,
            zoom: r
          };
        this._zoom(h.z);
        this.centerContent({
          bounds: h,
          doNotAnimate: e.doNotAnimate,
          onComplete: e.onComplete
        });
      }
    }
  }, {
    key: "zoomToExtents",
    value: function t(e) {
      var n = Array.isArray(e.extents) ? e.extents : [e.extents];
      if (n.length > 0) {
        var o = this._$_getViewportSize();
        var i = {
          x: Infinity,
          y: Infinity,
          xMax: -Infinity,
          yMax: -Infinity,
          z: 1,
          vw: o.w,
          vh: o.h
        };
        n.forEach(function (t) {
          i.x = Math.min(i.x, t.x);
          i.y = Math.min(i.y, t.y);
          i.xMax = Math.max(i.xMax, t.x + t.w);
          i.yMax = Math.max(i.yMax, t.y + t.h);
        });
        var a = e.fill || .9;
        i.w = i.xMax - i.x;
        i.h = i.yMax - i.y;
        i.z = a * Math.min(i.vw / i.w, i.vh / i.h);
        if (!(e.doNotZoomIfVisible && i.z > this.zoom)) {
          this._zoom(i.z);
        }
        this.centerContent({
          bounds: i,
          doNotAnimate: e.doNotAnimate !== false,
          onComplete: e.onComplete,
          doNotFirePanEvent: e.doNotFirePanEvent
        });
      }
    }
  }, {
    key: "setFilter",
    value: function t(e) {
      this.filter = e || function () {
        return false;
      };
    }
  }, {
    key: "centerBackground",
    value: function t(e) {
      if (this.background != null) {
        var n = l({}, this.getBoundsInfo());
        n.x = this.background.getWidth() / 2;
        n.y = this.background.getHeight() / 2;
        n.w = 1;
        n.h = 1;
        this.centerContent({
          bounds: n,
          doNotAnimate: e.doNotAnimate,
          onComplete: e.onComplete,
          vertical: true,
          horizontal: true
        });
      }
    }
  }, {
    key: "alignBackground",
    value: function t(e, n) {
      if (this.background != null) {
        var o = e.split(" "),
          i = o[0] || "left",
          a = o[1] || "top",
          s = this.getBoundsInfo(),
          r = i === "left" ? 0 : s.vw - this.background.getWidth() * this.zoom,
          h = a === "top" ? 0 : s.vh - this.background.getHeight() * this.zoom,
          l = this._apparentOffset();
        this._setCanvasPosition({
          x: r - l.x,
          y: h - l.y,
          animate: true,
          animationDuration: n
        });
        this.background.pan();
        this.fixedLayer.pan();
      }
    }
  }, {
    key: "positionElementAt",
    value: function t(e, n, o, i, a, s) {
      i = i || 0;
      a = a || 0;
      var r = this._apparentOffset(),
        h = this.position,
        l = e.parentNode,
        u = this.getOffset(l),
        m = this.getOffset(this.viewportElement),
        v = m.x - u.x + (h.x + r.x) + n * this.zoom + i,
        c = m.y - u.y + (h.y + r.y) + o * this.zoom + a;
      if (s && v < 0) v = 0;
      if (s && c < 0) c = 0;
      e.style.left = v + "px";
      e.style.top = c + "px";
    }
  }, {
    key: "positionElementAtPageLocation",
    value: function t(e, n, o, i, a) {
      var s = this.fromPageLocation(n, o);
      this.positionElementAt(e, s.x, s.y, i, a);
    }
  }, {
    key: "positionElementAtEventLocation",
    value: function t(e, n, o, i) {
      var a = this.mapEventLocation(n);
      this.positionElementAt(e, a.x, a.y, o, i);
    }
  }, {
    key: "zoomToEvent",
    value: function t(e, n) {
      this._setTransformOriginToEvent(e);
      this._zoom(this.zoom + n, e);
    }
  }, {
    key: "relayout",
    value: function t(e) {
      if (this.enablePan === false) {
        var n = e.xmax - e.xmin,
          o = e.ymax - e.ymin,
          i = window.getComputedStyle(this.viewportElement),
          a = parseInt(i.getPropertyValue(P), 10),
          s = parseInt(i.getPropertyValue(T), 10),
          r = a / n,
          h = s / o;
        var l = Math.min(r, h);
        this.canvasElement.style.width = a + C;
        this.canvasElement.style.height = s + C;
        this.canvasElement.style.left = "0";
        this.canvasElement.style.top = "0";
        var u = -e.xmin,
          m = -e.ymin;
        this.canvasElement.style.transform = "".concat(M, "(").concat(u * l).concat(C, ") ").concat(b, "(").concat(m * l).concat(C, ") ").concat(E, "(").concat(l, ")");
        this.setTransformOrigin(0, 0);
      }
    }
  }, {
    key: "nudgeZoom",
    value: function t(e, n) {
      var o = this.getOffsetRelativeToRoot(this.viewportElement),
        i = this._$_getViewportSize(),
        a = o.x + i.w / 2,
        s = o.y + i.h / 2;
      this._setTransformOriginToPageLocation(a, s);
      return this._zoom(this.zoom + e, n);
    }
  }, {
    key: "nudgeWheelZoom",
    value: function t(e, n) {
      this.zoomAtZoomStart = this.zoom;
      this._zoomBy(0, e, n, true);
    }
  }, {
    key: "alignContent",
    value: function t(e) {
      var n = this;
      if (this.directRender) return;
      e = e || {};
      var o = e.bounds || this.getBoundsInfo(),
        i = this.position;
      var a = this._$_getCanvasSize(),
        s = (1 - 1 / this.zoom) * a.h,
        r = (1 - 1 / this.zoom) * a.w,
        h = this._apparentOffset(),
        l = o.x * this.zoom + o.w * this.zoom / 2,
        u = o.y * this.zoom + o.h * this.zoom / 2,
        m = o.vw / 2 - l,
        v = o.vh / 2 - u;
      var c = e.padding || 10;
      var f = this.position.x,
        d = this.position.y,
        p = s * this.transformOrigin.y / 100 - o.y + c / this.zoom,
        y = (p - o.h - 2 * c + o.vh / this.zoom) * this.zoom,
        g = e.alignY == null ? d : e.alignY === B ? y : e.alignY === W ? v - h.y : p * this.zoom,
        x = r * this.transformOrigin.x / 100 - o.x + c / this.zoom,
        w = (x - o.w - 2 * c + o.vw / this.zoom) * this.zoom,
        z = e.alignX == null ? f : e.alignX === S ? w : e.alignX === W ? m - h.x : x * this.zoom;
      this._setCanvasPosition({
        x: z,
        y: g,
        animate: !e.doNotAnimate,
        animationDuration: e.animationDuration,
        onComplete: function t() {
          if (!e.doNotFirePanEvent) {
            n.onPan(z - i.x, g - i.y, n.zoom, n.zoom);
          }
          n.background && n.background.pan();
          n.fixedLayer.pan();
          if (e.onComplete) {
            e.onComplete({
              x: z,
              y: g
            });
          }
        }
      });
    }
  }, {
    key: "alignContentTop",
    value: function t(e) {
      e = e || {};
      e.alignY = R;
      this.alignContent(e);
    }
  }, {
    key: "alignContentBottom",
    value: function t(e) {
      e = e || {};
      e.alignY = B;
      this.alignContent(e);
    }
  }, {
    key: "alignContentLeft",
    value: function t(e) {
      e = e || {};
      e.alignX = O;
      this.alignContent(e);
    }
  }, {
    key: "alignContentRight",
    value: function t(e) {
      e = e || {};
      e.alignX = S;
      this.alignContent(e);
    }
  }, {
    key: "centerContent",
    value: function t(e) {
      e = e || {};
      e.alignX = e.horizontal === false ? null : W;
      e.alignY = e.vertical === false ? null : W;
      this.alignContent(e);
    }
  }, {
    key: "centerContentHorizontally",
    value: function t(e) {
      this.centerContent(l({
        horizontal: true,
        vertical: false
      }, e));
    }
  }, {
    key: "centerContentVertically",
    value: function t(e) {
      this.centerContent(l({
        vertical: true,
        horizontal: false
      }, e));
    }
  }, {
    key: "centerOn",
    value: function t(e) {
      var n = this;
      var o = Object.assign({}, this.getBoundsInfo());
      Object.assign(o, e.bounds);
      var i = function t() {
        n._setTransformOriginToCanvasPoint(o.x + o.w / 2, o.y + o.h / 2);
        e.onComplete && e.onComplete(o);
      };
      this.centerContent({
        bounds: o,
        doNotAnimate: e.doNotAnimate,
        onComplete: i,
        vertical: e.vertical !== false,
        horizontal: e.horizontal !== false,
        zoom: e.zoom
      });
    }
  }, {
    key: "centerOnHorizontally",
    value: function t(e) {
      this.centerOn({
        vertical: false,
        doNotAnimate: e.doNotAnimate,
        onComplete: e.onComplete,
        bounds: e.bounds
      });
    }
  }, {
    key: "centerOnVertically",
    value: function t(e) {
      this.centerOn({
        bounds: e.bounds,
        horizontal: false,
        doNotAnimate: e.doNotAnimate,
        onComplete: e.onComplete
      });
    }
  }, {
    key: "centerOnAndZoom",
    value: function t(e) {
      var n = e.fillRatio || .6;
      var o = e.bounds,
        i = this.getBoundsInfo(),
        a = i.vw < i.vh ? [i.vw, "w"] : [i.vh, "h"],
        s = n * a[0],
        r = s / o[a[1]];
      if (r < this.zoomRange[0]) {
        r = this.zoomRange[0];
      }
      if (r > this.zoomRange[1]) {
        r = this.zoomRange[1];
      }
      this._setTransformOriginToCanvasPoint(o.x + o.w / 2, o.y + o.h / 2);
      this.centerOn({
        bounds: o,
        zoom: r
      });
    }
  }, {
    key: "getViewportCenter",
    value: function t() {
      var e = l({}, this.getBoundsInfo()),
        n = this._apparentOffset(),
        o = this.position,
        i = {
          x: e.vw / 2,
          y: e.vh / 2
        };
      return {
        x: (i.x - (o.x + n.x)) / this.zoom,
        y: (i.y - (o.y + n.y)) / this.zoom
      };
    }
  }, {
    key: "setViewportCenter",
    value: function t(e) {
      var n = l({}, this.getBoundsInfo()),
        o = this._apparentOffset(),
        i = {
          x: n.vw / 2,
          y: n.vh / 2
        };
      var a = {
        x: -(e.x * this.zoom - i.x + o.x),
        y: -(e.y * this.zoom - i.y + o.y)
      };
      this._setCanvasPosition({
        x: a.x,
        y: a.y,
        animate: false
      });
    }
  }, {
    key: "setClamping",
    value: function t(e) {
      this.clamp = e;
    }
  }, {
    key: "isClamping",
    value: function t() {
      return this.clamp;
    }
  }, {
    key: "setZoom",
    value: function t(e, n) {
      return this._zoom(e, null, null, n);
    }
  }, {
    key: "setZoomRange",
    value: function t(e, n) {
      if (e != null && e.length === 2 && e[0] < e[1] && e[0] != null && e[1] != null && e[0] > 0 && e[1] > 0) {
        this.zoomRange = e;
        if (!n) {
          if (this.zoom < this.zoomRange[0] || this.zoom > this.zoomRange[1]) this._zoom(this.zoom);
        }
      }
      return this.zoomRange;
    }
  }, {
    key: "getZoomRange",
    value: function t() {
      return this.zoomRange;
    }
  }, {
    key: "getZoom",
    value: function t() {
      return this.zoom;
    }
  }, {
    key: "getCompoundZoom",
    value: function t() {
      var e = this._$_getViewportSize();
      var n = this.viewportElement.getBoundingClientRect();
      var o = n.width / e.w;
      return o;
    }
  }, {
    key: "getPan",
    value: function t() {
      return l({}, this.position);
    }
  }, {
    key: "pan",
    value: function t(e, n, o, i) {
      var a = this;
      this._moveCanvas(e, n, o, function (t) {
        a.onPan(t.x, t.y, a.zoom, a.zoom);
        a.background && a.background.pan();
        a.fixedLayer.pan();
        i && i(t);
      });
    }
  }, {
    key: "startPanRepeat",
    value: function t(e, n, o, i, a, s) {
      var r = this;
      this.stopPanRepeat();
      var h = function t() {
        var a = e * o;
        var h = n * i;
        s && s({
          x: a,
          y: h
        });
        r.pan(a, h, false);
      };
      h();
      this._panRepeatTimer = window.setInterval(function () {
        return h();
      }, a);
    }
  }, {
    key: "stopPanRepeat",
    value: function t() {
      window.clearTimeout(this._panRepeatTimer);
    }
  }, {
    key: "setPan",
    value: function t(e, n, o, i) {
      return this._setCanvasPosition({
        x: e,
        y: n,
        animate: o,
        onComplete: i
      });
    }
  }, {
    key: "setTransformOrigin",
    value: function t(e, n) {
      this.transformOrigin = {
        x: e,
        y: n
      };
      this._writeTransformOrigin();
    }
  }, {
    key: "_calculateCumulativeScroll",
    value: function t() {
      return F(this.viewportElement);
    }
  }, {
    key: "fromPageLocation",
    value: function t(e, n) {
      var o = this._apparentOffset(),
        i = this.position,
        a = F(this.viewportElement),
        s = this.viewportElement.getBoundingClientRect();
      var r = this.getCompoundZoom();
      var h = {
        x: ((e - (s.x + a.x)) / r - (i.x + o.x)) / this.zoom,
        y: ((n - (s.y + a.y)) / r - (i.y + o.y)) / this.zoom
      };
      return h;
    }
  }, {
    key: "toPageLocation",
    value: function t(e, n) {
      var o = this._apparentOffset(),
        i = this.position,
        a = F(this.viewportElement),
        s = this.viewportElement.getBoundingClientRect();
      var r = this.getCompoundZoom();
      var h = r * (this.zoom * e + (i.x + o.x)) + (s.x + a.x);
      var l = r * (this.zoom * n + (i.y + o.y)) + (s.y + a.y);
      return {
        x: h,
        y: l
      };
    }
  }, {
    key: "mapEventLocation",
    value: function t(e) {
      var n = u(e);
      return this.fromPageLocation(n.x, n.y);
    }
  }, {
    key: "setEnabled",
    value: function t(e) {
      this.enabled = e;
    }
  }, {
    key: "showElementAt",
    value: function t(e, n, o) {
      var i = e.parentNode,
        a = this.getOffset(i),
        s = this.getOffset(this.viewportElement),
        r = this._apparentOffset(),
        h = a.x - s.x + r.x + n,
        l = a.y - s.y + r.y + o;
      e.style.left = h + "px";
      e.style.top = l + "px";
    }
  }, {
    key: "getApparentCanvasLocation",
    value: function t() {
      var e = this._apparentOffset(),
        n = this.position;
      return {
        x: n.x + e.x,
        y: n.y + e.y
      };
    }
  }, {
    key: "setApparentCanvasLocation",
    value: function t(e, n) {
      var o = this._apparentOffset();
      var i = this._setCanvasPosition({
        x: e - o.x,
        y: n - o.y,
        animate: false
      });
      this.background && this.background.pan();
      this.fixedLayer.pan();
      return i;
    }
  }, {
    key: "floatElement",
    value: function t(e, n) {
      if (e == null) return;
      var o = e;
      o.style.position = "absolute";
      o.style.left = n.x + "px";
      o.style.top = n.y + "px";
      this.viewportElement.appendChild(e);
    }
  }, {
    key: "fixElement",
    value: function t(e, n, o, i) {
      return this.fixedLayer.append(e, n, o, i);
    }
  }, {
    key: "unfixElement",
    value: function t(e) {
      this.fixedLayer.remove(e);
    }
  }, {
    key: "findIntersectingElements",
    value: function t(e, n, o, i, a) {
      var s = this;
      var r = this.getApparentCanvasLocation(),
        h = this.getOffset(this.viewportElement),
        l = this.viewportElement.scrollLeft,
        u = this.viewportElement.scrollTop,
        m = [],
        v = {
          x: e.x,
          y: e.y,
          w: n.w,
          h: n.h
        },
        c = function t(e, n) {
          if (o) {
            return w(e, n);
          } else {
            return z(e, n);
          }
        };
      var f = {
        x: h.x + r.x - l,
        y: h.y + r.y - u
      };
      var d = this.viewport.getElements();
      d.forEach(function (t, e) {
        if (a === true || !s._suspendMap[e]) {
          var n = {
            x: f.x + t.x * s.zoom,
            y: f.y + t.y * s.zoom,
            w: t.w * s.zoom,
            h: t.h * s.zoom
          };
          if (c(v, n) && (i == null || i(t, n, e))) {
            m.push({
              id: e,
              r: n
            });
          }
        }
      });
      return m;
    }
  }, {
    key: "findNearbyElements",
    value: function t(e, n, o, i) {
      var a = [];
      if (!o || this.isInViewport(e.x, e.y)) {
        a = this.findIntersectingElements({
          x: e.x - n,
          y: e.y - n
        }, {
          w: n * 2,
          h: n * 2
        }, false, i);
        var s = this.fromPageLocation(e.x, e.y);
        a.sort(function (t, e) {
          var n = {
              x: t.x + t.w / 2,
              y: t.y + t.h / 2
            },
            o = {
              x: e.x + e.w / 2,
              y: e.y + e.h / 2
            },
            i = x({
              x: s.x,
              y: s.y
            }, n),
            a = x({
              x: s.x,
              y: s.y
            }, o);
          return i < a ? -1 : i > a ? 1 : 0;
        });
      }
      return a;
    }
  }, {
    key: "isInViewport",
    value: function t(e, n) {
      var o = this.viewportElement.getBoundingClientRect();
      return o.x <= e && e <= o.x + o.width && o.y <= n && n <= o.y + o.height;
    }
  }, {
    key: "setWheelFilter",
    value: function t(e) {
      this.wheelFilter = e || function (t) {
        return true;
      };
    }
  }, {
    key: "addBackground",
    value: function t(e) {
      this.background.addBackground(e);
    }
  }, {
    key: "removeBackground",
    value: function t(e) {
      this.background.removeBackground(e);
    }
  }, {
    key: "destroy",
    value: function t() {
      if (this._doWheelZoom != null) {
        r(this.viewportElement, this._doWheelZoom);
      }
      this.pinchListener.destroy();
      this.fixedLayer.destroy();
      this.background.destroy();
      this.pinchListener = null;
      this.fixedLayer = null;
      this.background = null;
      this.eventManager.off(document, c, this._mouseMoveReset);
      this.eventManager.off(this.viewportElement, p, this._mouseDown);
      this.eventManager.off(this.viewportElement, d, this._contextMenu);
      delete this.onMaybeZoom;
      delete this.onPan;
      delete this.onMouseDown;
      delete this.onMouseUp;
      delete this.onMouseMove;
      delete this.onSetTransformOrigin;
    }
  }]);
  return o;
}();
var I = function () {
  function i() {
    e(this, i);
    n(this, "backgrounds", []);
  }
  t(i, [{
    key: "addBackground",
    value: function t(e) {
      this.backgrounds.push(e);
    }
  }, {
    key: "removeBackground",
    value: function t(e) {
      this.backgrounds = this.backgrounds.filter(function (t) {
        return t !== e;
      });
    }
  }, {
    key: "getWidth",
    value: function t() {
      return Math.max.apply(Math, o(this.backgrounds.map(function (t) {
        return t.getWidth();
      })));
    }
  }, {
    key: "getHeight",
    value: function t() {
      return Math.max.apply(Math, o(this.backgrounds.map(function (t) {
        return t.getHeight();
      })));
    }
  }, {
    key: "setZoom",
    value: function t(e, n) {
      this.backgrounds.forEach(function (t) {
        return t.setZoom(e, n);
      });
    }
  }, {
    key: "owns",
    value: function t(e) {
      return this.backgrounds.find(function (t) {
        return t.owns(e);
      }) != null;
    }
  }, {
    key: "pan",
    value: function t() {
      this.backgrounds.forEach(function (t) {
        return t.pan();
      });
    }
  }, {
    key: "destroy",
    value: function t() {
      this.backgrounds.forEach(function (t) {
        return t.destroy();
      });
      this.backgrounds.length = 0;
    }
  }, {
    key: "_setVisible",
    value: function t(e) {
      this.backgrounds.forEach(function (t) {
        return t._setVisible(e);
      });
    }
  }]);
  return i;
}();
export { B as ALIGN_BOTTOM, W as ALIGN_CENTER, O as ALIGN_LEFT, S as ALIGN_RIGHT, R as ALIGN_TOP, A as CLASS_PAN_ZOOM_CANVAS, Z as CLASS_PAN_ZOOM_VIEWPORT, L as PanZoom };