import { Component, Input, Output, EventEmitter, OnInit, forwardRef } from '@angular/core';
import { TableService } from '@core/services';
import { CardComponent } from '../card/card.component';
import { GridTableComponent } from '../grid-table/grid-table.component';
import { ListComponent } from '../list/list.component';
import { TreeviewComponent } from '../treeview/treeview.component';
import { MultiSliderComponent } from '../../elements/multi-slider/multi-slider.component';
import { FrequencyComponent } from '../frequency/frequency.component';
import { CauseAndConsequenceOverviewComponent } from '../cause-and-consequence-overview/cause-and-consequence-overview.component';
import { WidgetHeatmapWrapperComponent } from '../widget-heatmap/widget-heatmap-wrapper/widget-heatmap-wrapper.component';
import { GraphComponent } from '../graph/graph.component';
import { ProcessManagerComponent } from '../process-manager/process-manager.component';
import { ProcessArchimateComponent } from '../process-archimate/process-archimate.component';
import { StrategyMapComponent } from '../strategy-map/strategy-map.component';
import { FormComponent } from '../../../core/form/form.component';
import { TableComponent } from '../table/table.component';
import type { ICaseTabComponent, ICaseViewPanel, ILayoutTab, IWidgetConfig } from '@core/models';

@Component({
  selector: 'naris-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  standalone: true,
  imports: [CardComponent, GridTableComponent, forwardRef(() => TableComponent), forwardRef(() => ListComponent), TreeviewComponent, MultiSliderComponent, forwardRef(() => FrequencyComponent), CauseAndConsequenceOverviewComponent, WidgetHeatmapWrapperComponent, GraphComponent, ProcessManagerComponent, ProcessArchimateComponent, StrategyMapComponent, forwardRef(() => FormComponent)]
})
export class PanelComponent implements OnInit {
  public reload = false;

  @Input()
  public panel: ICaseTabComponent | ICaseViewPanel | undefined;

  @Input()
  public caseId: number;

  @Input()
  public editMode?: boolean;

  @Input()
  public widgetConfig?: IWidgetConfig;

  @Input()
  public isEmbedded: boolean;

  @Input()
  public isOverviewDashboard: boolean;

  @Input()
  public widgetPanelSize: { width: number | undefined; height: number | undefined } | null;

  @Input()
  set reloadPanel(value: boolean) {
    if (value) {
      this.reload = true;
      setTimeout(() => {
        this.reload = false;
      });
    }
  }

  @Input()
  public isWizard: boolean;

  @Input()
  public selectedTab: ILayoutTab;

  @Input() 
  public tabHref: string;

  @Input()
  public isTableReload = false;

  @Output()
  public readonly widgetConfigChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public readonly updated: EventEmitter<any> = new EventEmitter<any>();

  public registerFormEndpoint: string;

  constructor(
    private readonly tableService: TableService
  ) { }
  

  public ngOnInit(): void {
    if (this.panel?.name === 'Documents' && this.panel?.href?.includes('audit')) {
      this.tableService.reloadPanel.subscribe(() => {
        this.reloadPanel = true;
      });
    }
  }

  /**
   * Update gets called when panel component loaded event is triggered
   */
  public update() {
    this.updated.emit(this);
  }

  get tableConfig() {
    return {
      parentTable: !!this.panel?.layouthint?.includes('parent-list'),
      nestedTable: !!this.panel?.layouthint?.includes('nested-list'),
      listSummary: !!this.panel?.layouthint?.includes('list-summary'),
      isWizard: !!this.isWizard
    };
  }

  /**
   * Determines the type of component based on the resourcetype
   */
  get componentType(): string {
    if (this.panel?.layouthint?.includes('transform-to-multi-slider')) return 'multi-slider';
    else if (this.panel?.layouthint?.includes('dashboard')) return 'dashboard';
    else if (this.panel?.layouthint?.includes('process-list')) return 'process-list';
    else if (this.panel?.layouthint?.includes('archimate-list')) return 'archimate-list';
    else if (this.panel?.layouthint?.includes('treeview')) return 'treeview';
    else if (this.panel?.resourcetype?.endsWith('PropertiesPanel') || this.panel?.layouthint?.includes('list')) return 'list';
    else if (this.panel?.layouthint?.includes('cause-and-consequence-overview')) return 'cause-and-consequence-overview';
    else if (this.panel?.layouthint?.includes('execution-nested-list')) return 'execution-nested-list';
    else if (this.panel?.layouthint?.some(x => x.includes('graph'))) return 'graph';
    else if (this.panel?.layouthint?.includes('strategymap')) return 'strategymap';
    else if (this.panel?.layouthint?.includes('register-form')) {
      this.registerFormEndpoint = this.panel.href + '/create-processing-data';
      return 'register-form';
    } else return 'table';
  }
}
