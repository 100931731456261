import { Component, Input, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter, Inject, forwardRef } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { FormService, BeinformedService } from '@core/services';
import { FORM_LOOKUP_TOKEN } from '@core/constants';
import { NgClass } from '@angular/common';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NarisDependencyAttributeDirective } from '../../../shared/directives/attribute-dependency.directive';
import { ButtonComponent } from '../../../shared/elements/button/button.component';
import { IconComponent } from '../../../shared/elements/icon/icon.component';
import { FormFieldComponent } from '../../../shared/components/form-field/form-field.component';
import { NgxTextEditorComponent } from '../../../shared/components/ngx-text-editor/ngx-text-editor.component';
import { OverviewTableComponent } from '../../../shared/components/overview-table/overview-table.component';
import { InputComponent } from '../../../shared/elements/input/input.component';
import { CheckboxGroupComponent } from '../../../shared/elements/checkbox-group/checkbox-group.component';
import { AnswerToggleComponent } from '../../../shared/elements/answer-toggle/answer-toggle.component';
import { RadioComponent } from '../../../shared/elements/radio/radio.component';
import { SelectComponent } from '../../../shared/elements/select/select.component';
import { DateRangepickerComponent } from '../../../shared/components/daterangepicker/daterangepicker.component';
import { DatepickerComponent } from '../../../shared/components/datepicker/datepicker.component';
import { TimepickerComponent } from '../../../shared/components/timepicker/timepicker.component';
import { CheckedTreeComponent } from '../../../shared/elements/checked-tree/checked-tree.component';
import { AutocompleteMultiComponent } from '../../../shared/elements/autocomplete-multi/autocomplete-multi.component';
import { AutocompleteSingleComponent } from '../../../shared/elements/autocomplete-single/autocomplete-single.component';
import { MultiInputComponent } from '../../../shared/elements/multi-input/multi-input.component';
import { SliderComponent } from '../../../shared/elements/slider/slider.component';
import { FileUploadComponent } from '../../../shared/components/file-upload/file-upload.component';
import { ColorInputComponent } from '../../../shared/components/color-input/color-input.component';
import { PeriodComponent } from '../../../shared/components/period/period.component';
import { FrequencyComponent } from '../../../shared/components/frequency/frequency.component';
import type { FormLookupComponent } from '@core/form/form-lookup/form-lookup.component';
import type { ICombinedResponse, TInputType } from '@core/models';
import type { FormInput } from '@core/classes';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  standalone: true,
  imports: [NarisDependencyAttributeDirective, NgClass, FormsModule, ReactiveFormsModule, ButtonComponent, IconComponent, FormFieldComponent, NgxTextEditorComponent, forwardRef(() => OverviewTableComponent), InputComponent, CheckboxGroupComponent, AnswerToggleComponent, RadioComponent, SelectComponent, DateRangepickerComponent, DatepickerComponent, TimepickerComponent, CheckedTreeComponent, AutocompleteMultiComponent, AutocompleteSingleComponent, MultiInputComponent, SliderComponent, MatMenuTrigger, MatTooltip, MatMenu, FileUploadComponent, ColorInputComponent, PeriodComponent, forwardRef(() => FrequencyComponent), TranslateModule]
})
export class FormInputComponent implements AfterViewInit {

  @Input() public isFilter: boolean;
  @Input() public input: FormInput;
  @Input() public formGroup: FormGroup;
  @Input() public error: any[] | undefined;
  @Input() public step: TInputType[];
  @Input() public isConsequence = false;
  @Input() public hidePreviousAssessment = false;
  @Input() public oddInput = false;
  @Input() public isInitialApplicability = false;
  @Input() public isProcessToolkitForm = false;
  @Input() public isContextChips = false;
  @Input() public formId: string;

  @Output()
  public readonly autoSubmit = new EventEmitter<void>();

  @Output()
  public readonly enterPressed = new EventEmitter<void>();

  @Output()
  public readonly backAndReset = new EventEmitter<void>();

  @ViewChild('self') public self: ElementRef;

  public parentWidth: number;

  constructor(
    private readonly dialog: MatDialog,
    private readonly formService: FormService,
    private readonly beinformedService: BeinformedService,
    @Inject(FORM_LOOKUP_TOKEN) private readonly formLookupComponent: ComponentType<FormLookupComponent>
  ) {}

  public ngAfterViewInit() {
    setTimeout(() => this.parentWidth = this.self?.nativeElement?.offsetParent?.clientWidth);
    if (this.input.id === 'ImpactCategoryMinimum') this.getFormControl(this.input.id).setValue(this.input.suggestion);
  }

  /**
   * Method to handle create-action layouthint forms
   * @param input the input config object
   */
  public createAction(input: FormInput) {
    const endpoint = input.label;
    const attributeId = input.id.replace('CreateAction', '');
    const formControl = this.formGroup.get(attributeId) as FormControl & Record<string, any>;
    const isMultiple = !!this.step.find(item => item.id === attributeId)?.multiple;
    this.formService.open(endpoint).subscribe(saved => {
      const caseEndpoint = saved.redirect;
      const id = caseEndpoint.split('/').pop();
      this.beinformedService.fetchResponseWithContributions(caseEndpoint).subscribe((result: ICombinedResponse) => {
        const attributes = this.beinformedService.extractAttributes(result);
        const label = attributes?.find((attr: any) => attr.layouthint?.includes('title'))?.valueLabel || attributes?.[0].valueLabel;
        const returnObj = isMultiple ? [{value: id, code: id, label}] : {value: id, code: id, label};
        if (!!formControl['setSelection'] && typeof formControl['setSelection'] === 'function') formControl['setSelection'](returnObj, null, isMultiple);
        else formControl.setValue(id);
      });
    });
  }

  /**
   * Returns any string with html tags without those tags
   */
  public sanitize(text: string) {
    return text ? text.replace(/(<([^>]+)>)/ig, '') : '';
  }

  /**
   * Opens a modal table for advanced lookup for inputId
   * @param inputId inputId
   */
  public openAdvancedLookup(inputId: string) {
    this.dialog.open(this.formLookupComponent, {
      panelClass: 'naris-advanced-lookup-dialog',
      minWidth: '54rem',
      data: {endpoint: this.input.lookup?.list},
      position: {left: '11%'}
    }).afterClosed().subscribe(val => {
      const mappedVal = this.transformSelection(val);
      this.formGroup.get(inputId)?.setValue(mappedVal);
    });
  }

  /**
   * Transform lookup selection
   * @param value selected item(s)
   */
  private transformSelection(value: any) {
    if (!(value instanceof Object) || Array.isArray(value)) return value;
    const label = Object.entries<string>(value).reduce<string[]>((acc, [key, val]) => !['children', '_id', '_links'].includes(key) && !!val ? [...acc, val] : acc, []).join(' | ');
    return {code: value._id, label, value: value._id};
  }

  public getFormControl(controlName: string) {
    return this.formGroup.get(controlName) as FormControl;
  }

  public getFormControlRequired(controlName: string) {
    const fc = this.formGroup.get(controlName) as FormControl;
    if (!!fc?.validator) {
      const validator = fc.validator({} as AbstractControl);
      return !!validator?.required;
    } else return false;
  }

  get isNotify(): boolean {
    return this.input.layouthint?.includes('attribute_notify') || false;
  }

  public getUnknownPropertyValue(property: string, obj: Record<string, any>) {
    return obj[property];
  }
}
