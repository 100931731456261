import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'naris-menu-item',
  templateUrl: './menu-item.component.html',
  standalone: true,
  imports: [RouterLink]
})
export class MenuItemComponent {
  @Input() public route?: string;
  @Input() public removeSpacing = false;
  @Input() public activeClass = 'active';
  @Input() public activeClassExactMatch = true;
  @Input() public disabled = false;
  @Input() public active = false;

  @Output()
  public readonly clicked = new EventEmitter<MouseEvent>();

  constructor(private readonly router: Router) {}

  get isActive() {
    return this.active || this.router.isActive(this.route!, {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'});
  }

  public onClick(event: MouseEvent) {
    if (!this.disabled) this.clicked.emit(event);
  }
}
