import { Component, Output, EventEmitter, Host, input, effect, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { catchError, mergeMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { BeinformedService, HttpService } from '@core/services';
import { OverlayComponent } from '@shared/components/overlay/overlay.component';
import { IFormLookupCreateSuccess, IInputOption } from '@core/models';
import { fadeInOutAnimation } from '@shared/animations/core.animations';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ToolbarComponent } from '../../toolbar/toolbar.component';
import { ToolbarItemComponent } from '../../toolbar/toolbar-item/toolbar-item.component';
import { ButtonComponent } from '../../../elements/button/button.component';
import { IconComponent } from '../../../elements/icon/icon.component';
import { SelectComponent } from '../../../elements/select/select.component';
import { FormComponent } from '../../../../core/form/form.component';
import { EmptyStateComponent } from '../../empty-state/empty-state.component';

@Component({
  selector: 'naris-dashboard-add-widget',
  templateUrl: './dashboard-add-widget.component.html',
  styleUrls: ['./dashboard-add-widget.component.scss'],
  animations: [fadeInOutAnimation],
  standalone: true,
  imports: [ToolbarComponent, ToolbarItemComponent, ButtonComponent, FormsModule, ReactiveFormsModule, IconComponent, SelectComponent, MatTooltip, FormComponent, EmptyStateComponent, TranslateModule]
})
export class DashboardAddWidgetComponent {
  public endpoint = input.required<string>();
  @Output() public readonly selected = new EventEmitter<any>();
  @Input() set clear(value: any) {
    this.selectedWidgetId = '';
    this.formHref = null;
  }
  public query = new FormControl();
  public selectedWidgetType = new FormControl();

  public _widgets: any[] | undefined;
  public result: any;

  public widgetTypes: IInputOption[];
  public selectedWidgetId: string;
  public formHref = null;

  constructor(
    private readonly httpService: HttpService,
    private readonly beinformedService: BeinformedService,
    @Host() public parent: OverlayComponent
  ) { 
    effect(() => {
      if (!!this.endpoint()) {
        this.fetchWidgets(this.endpoint());
      }
    });
  }

  public reset(): void {
    this.query.setValue(null);
  }

  get widgets(): any[] | undefined {
    const widgets = this._widgets
      ?.filter((w: any) => {
        if (!this.parent.data?.widgetType) return true;
        return w.WidgetType === this.parent.data.widgetType;
      })
      ?.filter((w: any) => {
        if (!this.query.value) return true;
        const match = this.query.value?.toLowerCase();

        return this.query.value && (w.WidgetName?.toLowerCase().includes(match) || w.WidgetType?.toLowerCase().includes(match));
      })
      ?.filter((w: any) => {
        if (!this.selectedWidgetType.value) return true;
        const match = this.selectedWidgetType.value?.toLowerCase();
        return this.selectedWidgetType.value && w.WidgetType?.toLowerCase().includes(match);
      });
    return widgets;
  }

  public selectWidget(widget: any) {
    
    this.formHref = null;
    this.selectedWidgetId = this.selectedWidgetId === widget._id ? null : widget._id;

    const body = {
      objects: [
        {
          WidgetsOther:
            {
              WidgetID: widget._id
            }
        }
      ]
    };

    this.httpService.post(this.endpoint(), body).subscribe({next: res => {
      const successValue = res.formresponse.success;
      const json = successValue.data?.Result?.added_JSON;
      if (!!json) this.emitAddJson(json);
      else if (res.formresponse.success.redirect) this.formHref = res.formresponse.success.redirect;
    }});
  }

  private transformWidgets(results: any[]) {
    const widgets = results.map((w: any) => {
      const objName = Object.keys(w)[0];
      const obj = w[objName];
      return obj;
    });
    return widgets;
  }

  private fetchWidgetsFromLookup(endpoint: string) {
    return this.beinformedService.fetchResponseWithContributions(endpoint);
  }

  private fetchWidgets(endpoint: string): any[] {
    this.httpService.post(endpoint)
      .pipe(
        catchError(err => {
          if (!!err.error?.formresponse) return of(err);
          throw err;
        }),
        mergeMap(result => {
          const widgetsElement = result.error.formresponse.missing.anchors[0].elements.find((el: any) => el.elementid === 'WidgetID');
          const lookupList = widgetsElement._links.lookupList;
          const lookupOptions = widgetsElement._links.lookupOptions;
          return of({ lookupList, lookupOptions });
        }),
        mergeMap(result => this.fetchWidgetsFromLookup(result.lookupList.href)),
        map(({ data }) => {
          let widgets = [];
          if (!!data._embedded) widgets = this.transformWidgets(data._embedded.results);
          if (!!data.dynamicschema)
            widgets.forEach((widget: Record<string, any>) => {
              const widgetKeys = Object.keys(widget);
              widgetKeys.forEach(key => {
                const labels = data.dynamicschema?.[key];
                if (!!labels) {
                  const foundLabel = labels.find(label => label.code === widget[key]);
                  widget[key] = foundLabel?.label;
                }

              });
            });
          return widgets;
        })
      )
      .subscribe((result: any) => {
        this.widgetTypes = [...new Set(result?.map((widget: any) => widget.WidgetType))].map(type => ({key: type, label: type})) as IInputOption[];
        this._widgets = result;
      });
    return [];
  }

  public addWidgetSaved(result: IFormLookupCreateSuccess) {
    this.emitAddJson(result.result.data.Result.added_JSON);
  }

  private emitAddJson(json: string) {
    this.selected.emit(json);
    this.selectedWidgetId = '';
    this.formHref = null;
    this.parent.close();
  }
}
