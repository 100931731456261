import { inherits as t, createSuper as e, classCallCheck as n, createClass as r } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { SelectionBase as i } from "./common.js";
import { Connections as a } from "../connector/connections.js";
var c = function (i) {
  t(o, i);
  var c = e(o);
  function o() {
    n(this, o);
    return c.apply(this, arguments);
  }
  r(o, [{
    key: "setDetachable",
    value: function t(e) {
      this.each(function (t) {
        return t.detachable = e === true;
      });
      return this;
    }
  }, {
    key: "setReattach",
    value: function t(e) {
      this.each(function (t) {
        return t.reattach = e === true;
      });
      return this;
    }
  }, {
    key: "setConnector",
    value: function t(e) {
      this.each(function (t) {
        return a._setConnector(t, e);
      });
      return this;
    }
  }, {
    key: "deleteAll",
    value: function t() {
      var e = this;
      this.each(function (t) {
        return e.instance.deleteConnection(t);
      });
      this.clear();
    }
  }, {
    key: "repaint",
    value: function t() {
      var e = this;
      this.each(function (t) {
        return e.instance._paintConnection(t);
      });
      return this;
    }
  }]);
  return o;
}(i);
export { c as ConnectionSelection };