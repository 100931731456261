import { toConsumableArray as a } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { findSegment as r, transformOrthogonalFromAbsolute as e, orthogonalSegmentLength as o } from "./utils.js";
import { vertexAvoidance as s } from "./vertex-avoidance.js";
import { clone as t } from "../util/util.js";
import { _trimOrthogonalSegments as y } from "./operations.js";
import { AXIS_ORIENTATION_VERTICAL as l, AXIS_ORIENTATION_HORIZONTAL as x } from "./definitions.js";
function u(a, r, e, o, s) {
  var t = o === 1 ? r.length - 1 : 0;
  var y = [];
  var l = null;
  for (var x = e; x !== t; x += o) {
    if (r[x].o === s) {
      l = r[x];
      break;
    } else {
      y.push(r[x]);
    }
  }
  if (l == null) {
    return false;
  } else {
    y.forEach(function (r) {
      r.ay1 = a;
      r.ay2 = a;
    });
    if (o === 1) {
      l.ay1 = a;
    } else {
      l.ay2 = a;
    }
    return true;
  }
}
function i(a, r, e, o, s) {
  var t = o === 1 ? r.length - 1 : 0;
  var y = [];
  var l = null;
  for (var x = e; x !== t; x += o) {
    if (r[x].o === s) {
      l = r[x];
      break;
    } else {
      y.push(r[x]);
    }
    if (l == null) {
      return false;
    } else {
      y.forEach(function (r) {
        r.ax1 = a;
        r.ax2 = a;
      });
      if (o === 1) {
        l.ax1 = a;
      } else {
        l.ax2 = a;
      }
      return true;
    }
  }
}
function f(r) {
  var e;
  var s = r.map(t);
  var x = s[0],
    f = s.slice(s.length - 1);
  var n = [x];
  var v = false;
  for (var c = 1; c < s.length - 1; c++) {
    var g = s[c];
    var p = s[c + 1].o;
    if (g.o !== x.o && p !== g.o) {
      var h = o(g);
      if (h < 10) {
        v = true;
        if (g.o === l) {
          var m = Math.sign(g.ay2 - g.ay1);
          var P = m === 1 ? g.ay1 : g.ay2;
          var X = u(P, s, c + m, m, g.o);
          if (!X) {
            P = m === 1 ? g.ay2 : g.ay1;
            X = u(P, s, c - m, -m, g.o);
            if (!X) {
              n.push(g);
            }
          }
        } else {
          var Y = Math.sign(g.ax2 - g.ax1);
          var b = Y === 1 ? g.ax1 : g.ax2;
          var j = i(b, s, c + Y, Y, g.o);
          if (!j) {
            b = Y === 1 ? g.ax2 : g.ax1;
            j = i(b, s, c - Y, -Y, g.o);
            if (!j) {
              n.push(g);
            }
          }
        }
      } else {
        n.push(g);
      }
    } else {
      n.push(g);
    }
    x = g;
  }
  (e = n).push.apply(e, a(f));
  if (v) {
    n = y(n);
  }
  return n;
}
function n(a, o, t, y, u) {
  if (o) {
    var i = t.orthogonalSegments;
    var n = i[i.length - 1];
    var v = r(i, -1, l);
    var c = r(i, -1, x);
    n.ax1 = u.targetPos.curX + y.stubs[1] * y.to[0];
    n.ay1 = u.targetPos.curY + y.stubs[1] * y.to[1];
    n.ax2 = u.targetPos.curX;
    n.ay2 = u.targetPos.curY;
    n.o = u.targetPos.oy === 0 ? x : l;
    if (v != null && c != null) {
      if (v[1] > c[1]) {
        v[0].ax1 = n.ax1;
        v[0].ax2 = n.ax1;
        v[0].ay2 = n.ay1;
        c[0].ax2 = n.ax1;
      } else {
        c[0].ay1 = n.ay1;
        c[0].ay2 = n.ay1;
        c[0].ax2 = n.ax1;
        v[0].ay2 = n.ay1;
      }
    } else if (c != null) {
      var g = i[i.length - 1];
      var p = g.o;
      var h = t.geometry.target.curY - u.targetPos.curY;
      if (h !== 0) {
        if (p === x) {
          c[0].o = l;
          c[0].ax2 = c[0].ax1;
          c[0].ay2 = u.targetPos.curY;
          var m = {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            o: x,
            ax1: c[0].ax2,
            ay1: c[0].ay2,
            ax2: n.ax1,
            ay2: n.ay1
          };
          i.splice(c[1] + 1, 0, m);
        } else {
          var P = {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            o: l,
            ax1: c[0].ax2,
            ay1: c[0].ay2,
            ax2: c[0].ax2,
            ay2: g.ay1
          };
          i.splice(c[1] + 1, 0, P);
        }
      }
    } else if (v != null) {
      var X = t.geometry.target.curX - u.targetPos.curX;
      if (X !== 0) {
        var Y = i[i.length - 1];
        var b = Y.o;
        if (b === l) {
          v[0].o = x;
          v[0].ay2 = v[0].ay1;
          v[0].ax2 = u.targetPos.curX;
          var j = {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            o: l,
            ax1: v[0].ax2,
            ay1: v[0].ay2,
            ax2: n.ax1,
            ay2: n.ay1
          };
          i.splice(v[1] + 1, 0, j);
        } else {
          var S = {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            o: x,
            ax1: v[0].ax2,
            ay1: v[0].ay2,
            ay2: v[0].ay2,
            ax2: Y.ax1
          };
          i.splice(v[1] + 1, 0, S);
        }
      }
    }
    if (t.vertexAvoidance !== false) {
      var d = f(i);
      s(t, d, u.sourceInfo, u.targetInfo, true);
      t.orthogonalSegments = e(d);
    } else {
      t.orthogonalSegments = e(i);
    }
  }
  if (a) {
    var I = t.orthogonalSegments.splice(0);
    var A = I[0];
    var k = r(I, 1, l);
    var E = r(I, 1, x);
    A.ax1 = u.sourcePos.curX;
    A.ay1 = u.sourcePos.curY;
    A.ax2 = u.sourcePos.curX + y.stubs[0] * y.so[0];
    A.ay2 = u.sourcePos.curY + y.stubs[0] * y.so[1];
    A.o = u.sourcePos.oy === 0 ? x : l;
    if (k != null && E != null) {
      if (k[1] > E[1]) {
        E[0].ay1 = A.ay2;
        E[0].ay2 = A.ay2;
        E[0].ax1 = A.ax2;
        k[0].ay1 = A.ay2;
      } else {
        k[0].ax1 = A.ax2;
        k[0].ax2 = A.ax2;
        k[0].ay1 = A.ay2;
        E[0].ax1 = A.ax2;
      }
    } else if (E != null) {
      var M = I[0];
      var _ = M.o;
      var B = t.geometry.source.curY - u.sourcePos.curY;
      if (B !== 0) {
        if (_ === x) {
          E[0].x2 = 0;
          E[0].x1 = 0;
          E[0].y2 = 0;
          E[0].y1 = 0;
          E[0].o = l;
          E[0].ax2 = E[0].ax1;
          E[0].ay1 = u.sourcePos.curY;
        } else {
          var C = {
            o: l,
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            ax1: M.ax2,
            ax2: M.ax2,
            ay1: M.ay2,
            ay2: E[0].ay1
          };
          I.splice(1, 0, C);
        }
      }
    } else if (k != null) {
      var H = t.geometry.source.curX - u.sourcePos.curX;
      var q = I[0];
      var w = q.o;
      if (H !== 0) {
        if (w === l) {
          k[0].o = x;
          k[0].ay2 = k[0].ay1;
          k[0].x2 = 0;
          k[0].x1 = 0;
          k[0].y2 = 0;
          k[0].y1 = 0;
          k[0].ax1 = u.sourcePos.curX;
        } else {
          var z = {
            o: x,
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            ax1: q.ax2,
            ax2: k[0].ax1,
            ay1: q.ay2,
            ay2: q.ay2
          };
          I.splice(1, 0, z);
        }
      }
    }
    if (t.vertexAvoidance !== false) {
      var D = f(I);
      s(t, D, u.sourceInfo, u.targetInfo, false);
      t.orthogonalSegments = e(D);
    } else {
      t.orthogonalSegments = e(I);
    }
  }
}
export { n as legacySourceAndTargetRelocation };