import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { AuthService } from '@core/services';
import { TranslateModule } from '@ngx-translate/core';
import { CardComponent } from '../../../shared/components/card/card.component';
import { FormFieldComponent } from '../../../shared/components/form-field/form-field.component';
import { ButtonComponent } from '../../../shared/elements/button/button.component';
import { DividerComponent } from '../../../shared/elements/divider/divider.component';
import { FormActionsComponent } from '../../../shared/components/form-actions/form-actions.component';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  standalone: true,
  imports: [CardComponent, FormFieldComponent, ButtonComponent, DividerComponent, RouterLink, FormActionsComponent, TranslateModule]
})
export class SignupComponent implements OnInit {

  constructor(private readonly activatedRoute: ActivatedRoute, private readonly authService: AuthService) {}

  public ngOnInit() {
    this.authService.idToken ??= this.activatedRoute.snapshot.queryParams['idToken'];
    this.authService.invitedEmail ??= this.activatedRoute.snapshot.queryParams['invitedEmail'];
  }

  public toLogin() {
    this.authService.idToken = '';
    this.authService.invitedEmail = '';
  }
}
