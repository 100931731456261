import { Component, ViewEncapsulation, HostBinding, HostListener, Output, EventEmitter, input, effect } from '@angular/core';
import { INarisNotification, IAction } from '@core/models';
import { MessageService, BeinformedService } from '@core/services';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { MatTooltip } from '@angular/material/tooltip';
import { ButtonComponent } from '../../elements/button/button.component';

@Component({
  selector: 'naris-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [ButtonComponent, MatTooltip]
})
export class NotificationComponent {

  public notification = input.required<INarisNotification>();
  @Output() public readonly closed = new EventEmitter<any>();
  public loading = false;

  public formattedTimestamp: string | null;
  public read = false;

  constructor(
    private readonly messageService: MessageService,
    private readonly beinformedService: BeinformedService,
    private readonly router: Router
  ) {
    effect(() => {
      setTimeout(() => {
        this.formattedTimestamp = DateTime.fromISO(this.notification().CreateDate).toRelative();
        this.read = this.notification().NotificationRead;
      });
    });
  }

  private update(actionName: string): void {
    this.notification().NotificationRead = actionName === 'mark-notification-as-read';
    this.messageService.fetchUnreadNotifications();
    this.loading = false;
  }

  public handleAction(actionName: string) {
    this.loading = true;
    const allActions = JSON.parse(JSON.stringify(this.notification().actions));
    let action = allActions.find((a: IAction) => a.name === actionName)!;
    if (actionName === 'mark-notification-as-not-read') {
      action = allActions.find((a: IAction) => a.name === 'mark-notification-as-read')!;
      action.name = action.name?.replace('mark-notification-as-read', 'mark-notification-as-not-read');
      action.href = action.href?.replace('mark-notification-as-read', 'mark-notification-as-not-read');
    }
    void this.beinformedService.handleAction(action, () => this.update(actionName), () => this.update(actionName), false, false);
  }

  @HostListener('click')
  public clicked(): void {
    const route = this.notification().URLForRoute || this.notification().URLForRedirect;
    if (!!route) {
      if (!this.read) this.handleAction('mark-notification-as-read');
      void this.router.navigate([route]);
      this.closed.emit();
    }
  }

  @HostBinding('class')
  get rootClasses() {
    const classes = ['naris-notification'];
    if (this.loading) classes.push('naris-notification--loading');
    if (!this.notification().NotificationRead) classes.push('naris-notification--unread');
    if (this.notification().URLForRoute || this.notification().URLForRedirect) classes.push('naris-notification--clickable');
    return classes;
  }
}
