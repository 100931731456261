import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { Base, Inspector } from '@jsplumbtoolkit/browser-ui';
import { jsPlumbService } from '@jsplumbtoolkit/browser-ui-angular';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IAction, IPostableObject } from '@core/models';
import { getIcon } from '@core/helpers';
import { BeinformedService, FormService } from '@core/services';
import { StrategyMapService } from '@core/services/strategy-map.service';
import { MatTooltip } from '@angular/material/tooltip';
import { NgStyle } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import edgeMappings from '../functions/edge-mappings';
import { ButtonComponent } from '../../../../elements/button/button.component';
import { BouncerComponent } from '../../../bouncer/bouncer.component';

@Component({
  selector: 'app-inspector',
  templateUrl: './inspector.component.html',
  styleUrls: ['./inspector.component.scss'],
  standalone: true,
  imports: [ButtonComponent, MatTooltip, NgStyle, FormsModule, ReactiveFormsModule, BouncerComponent, TranslateModule]
})
export class InspectorComponent implements AfterViewInit {

  @Input() public surfaceId: string;

  public inspector: Inspector;
  public edgeMappings = edgeMappings;
  public currentType = '';

  public inspectorForm = new FormGroup({});
  private objectId: string;
  private tokens: string[];

  public title = '';
  public type: string;
  public risks: string[] = [];
  public detachAction: IAction;
  public updateAction: IAction;
  public actions: IAction[] = [];
  public objectHref: string;
  public loading = true;

  constructor(
    private readonly $jsplumb: jsPlumbService,
    public readonly el: ElementRef,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly beinformedService: BeinformedService,
    private readonly strategyMapService: StrategyMapService,
    private readonly formService: FormService
  ) { }

  public ngAfterViewInit(): void {
    this.$jsplumb.getSurface(this.surfaceId, surface => {
      this.inspector = new Inspector({
        container: this.el.nativeElement,
        surface,
        renderEmptyContainer: () => {
          this.currentType = '';
        },
        refresh: (obj: Base, cb: () => void) => {
          this.clearControls();
          this.type = obj.type;
          this.currentType = obj.objectType;
          if (this.currentType !== 'Edge') {
            this.risks = obj.data.risks;
            this.objectHref = obj.data.objectHref;
            this.detachAction = obj.data.actions?.find((action: IAction) => {
              if (obj.type === 'goal') return action.name === 'detach-goal';
              else return action.name === 'detach-critical-success-factor';
            });
            this.updateAction = obj.data.actions?.find((action: IAction) => {
              if (obj.type === 'goal') return action.name === 'update-goal';
              else return action.name === 'update-critical-success-factor';
            });
            this.initializeForm(this.updateAction.href!);
          }
          setTimeout(cb, 0);
          this.changeDetector.detectChanges();
        }
      });
    });
  }

  private initializeForm(href: string) {
    this.loading = true;
    this.beinformedService.fetchForm(href).subscribe({
      next: res => {
        this.objectId = res.data.error.formresponse?.missing?.anchors[0].objectid || '';
        this.tokens = res.data.error.formresponse?.tokens || [];
        const elements = res.data.error.formresponse?.missing?.anchors[0].elements as any[];
        elements.forEach(el => this.inspectorForm.setControl(el.elementid, new FormControl(el.suggestion)));
        this.loading = false;
      }
    });
  }

  private clearControls() {
    const keys = Object.keys(this.inspectorForm.controls);
    keys.forEach(key => this.inspectorForm.removeControl(key));
  }

  public submitForm() {
    const objects: Record<string, any> = {};
    const values: Record<string, any> = {};
    const controls = this.inspectorForm.controls;
    const keys = Object.keys(controls);
    keys.forEach(key => {
      const control = this.inspectorForm.get(key);
      if (!!control) values[key] = control.value;
    });
    objects[this.objectId] = values;
    const postableObject: IPostableObject = {objects: objects, tokens: this.tokens};
    this.beinformedService.fetchForm(this.updateAction.href!, true, postableObject).subscribe({
      next: res => {
        if (!!res.data.formresponse?.success) this.strategyMapService.refreshMap$.next();
      }
    });
  }

  public getInspectorTitle() {
    const controlName = this.type === 'goal' ? 'GoalName' : 'CriticalSuccessFactorName';
    const control = this.inspectorForm.get(controlName);
    return control?.value || '';
  }

  public getIconType(inputString: string): string {
    const lowerString = inputString.toLowerCase().trim();
    return getIcon(lowerString);
  }

  public handleAction(action: IAction) {
    void this.beinformedService.handleAction(action);
  }

}
