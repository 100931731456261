import { trigger, transition, style, animate, stagger, keyframes, query } from '@angular/animations';

export const fadeInOutAnimation = trigger(
  'fadeInOutAnimation', [
    transition(':enter',
      [
        style({ opacity: 0 }),
        animate('0.2s ease-out',
          style({ opacity: 1 })
        )
      ]
    ),
    transition(':leave',
      [
        style({ opacity: 1 }),
        animate('0.2s ease-out',
          style({ opacity: 0 })
        )
      ]
    )
  ]
);

export const slideInOutRightAnimation = trigger(
  'slideInOutRightAnimation', [
    transition(':enter',
      [
        style({ right: '-100%' }),
        animate('0.3s ease-out',
          style({ right: 0 })
        )
      ]
    ),
    transition(':leave',
      [
        style({ right: 0 }),
        animate('0.3s ease-out',
          style({ right: '-100%' })
        )
      ]
    )
  ]
);

export const inOutAnimation = trigger(
  'inOutAnimation',
  [
    transition(
      ':enter',
      [
        style({ height: 0, opacity: 0 }),
        animate('0.1s ease-out',
          style({ height: 'auto', opacity: 1 }))
      ]
    ),
    transition(
      ':leave',
      [
        style({ height: 'auto', opacity: 1 }),
        animate('0.1s ease-in',
          style({ height: 0, opacity: 0 }))
      ]
    )
  ]
);

export const whooshAnimation = trigger('whooshAnimation', [ // gebruiken voor @for loop, als volgt: [@whooshAnimation]="array.length" vergeet niet array te tracken door in id oid, $index werkt niet!
  transition(':increment, void => *', [ //trigger wanneer de length van de list waar deze animatie op staat increment of als deze van een lege naar iets gaat
    query(':enter', [ // wanneer er een item toegevoegd wordt deze animatie starten
      style({ opacity: 0 }), // initieel is het item niet zichtbaar
      stagger(50, [ // wanneer er meerdere items in 1 keer worden toegevoegd, een delay tussen de animaties
        animate('300ms ease-out', 
          keyframes([ // op elke keyframe (definieerbaar door offset) styling toepassen
            style({ opacity: 0, transform: 'translateY(100%)', offset: 0 }),
            style({ opacity: 0.7, transform: 'translateY(-10%)', offset: 0.7 }),            
            style({ opacity: 1, transform: 'translateY(0)', offset: 1 })
          ])
        ),
      ]),
    ], { optional: true })
  ]),
  transition(':decrement, * => void', [ //trigger wanneer de length van de list waar deze animatie op staat decrement of als deze van iets naar een lege gaat
    query(':leave', [ // wanneer er een item weggehaald wordt deze animatie starten
      stagger(50, [ // wanneer er meerdere items in 1 keer worden verwijdered, een delay tussen de animaties
        animate('300ms ease-out',
          keyframes([
            style({ opacity: 1, transform: 'translateX(0) translateY(0)', offset: 0 }),
            style({ opacity: 0.5, transform: 'translateX(0) translateY(100%)', offset: 0.3 }),            
            style({ opacity: 0, transform: 'translateX(-100%) translateY(0)', offset: 1 })
          ])
        ),
      ]),
    ])
  ]),
]);
