import { inherits as e, createSuper as t, classCallCheck as s, defineProperty as n, assertThisInitialized as i, createClass as a, get as r, getPrototypeOf as o } from "../_virtual/_rollupPluginBabelHelpers.js";
import { _makeAndAppendHandle as u, EDGE_DRAG_HANDLE as l, EditorBase as g } from "../connector-editors/editor-base.js";
import { CONNECTOR_TYPE_SEGMENTED as c } from "../ui-core/connector-segmented/segmented-connector.js";
import { cls as d } from "../ui-core/core/constants.js";
import { EVENT_TAP as m } from "../ui-core/browser-ui-renderer/event-constants.js";
import { _updateSegmentedConnectorFromAbsolute as h } from "../ui-core/connector-segmented/operations.js";
var f = "jtk-segmented-handle";
var v = "jtk-segmented-segment-drag";
var p = "jtk-segmented-segment-split";
var y = "jtk-segmented-segment-delete";
var x = "jtk-segmented-editor-guideline";
var j = "jtk-edge-guideline";
var H = function (g) {
  e(H, g);
  var c = t(H);
  function H(e, t) {
    var a;
    s(this, H);
    a = c.call(this, e, t);
    n(i(a), "geometry", void 0);
    n(i(a), "segments", []);
    n(i(a), "segmentHandles", []);
    n(i(a), "guideLine", void 0);
    n(i(a), "splitListener", void 0);
    n(i(a), "deleteListener", void 0);
    a.segments = [];
    a.splitListener = function (e) {
      var t = e.target._jsPlumbDragHandle;
      if (t) {
        var s = {
          ax1: t.focus.ax1,
          ay1: t.focus.ay1,
          ax2: t.midPoint.x,
          ay2: t.midPoint.y,
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 0
        };
        t.focus.ax1 = t.midPoint.x;
        t.focus.ay1 = t.midPoint.y;
        var n = a.current.connector.geometry.segments;
        n[t.index].ax1 = t.midPoint.x;
        n[t.index].ay1 = t.midPoint.y;
        n.splice(t.index, 0, s);
        h(a.currentConnector);
        a.currentConnector.edited = true;
        a.repaintConnection();
        a.fireConnectionEditEvent();
      }
    }.bind(i(a));
    a.deleteListener = function (e) {
      var t = e.target._jsPlumbDragHandle;
      if (t) {
        var s = t.index;
        if (s === 0) {
          a.segments[1].ax1 = t.focus.ax1;
          a.segments[1].ay1 = t.focus.ay1;
        } else if (s < a.segments.length - 1) {
          a.segments[s - 1].ax2 = t.focus.ax2;
          a.segments[s - 1].ay2 = t.focus.ay2;
          a.segments[s + 1].ax1 = t.focus.ax2;
          a.segments[s + 1].ay1 = t.focus.ay2;
        } else if (s === a.segments.length - 1) {
          a.segments[s - 1].ax2 = t.focus.ax2;
          a.segments[s - 1].ay2 = t.focus.ay2;
        }
        a.current.connector.geometry.segments.splice(s, 1);
        h(a.currentConnector);
        a.currentConnector.edited = true;
        a.repaintConnection();
        a.fireConnectionEditEvent();
      }
    }.bind(i(a));
    a._addDragHandler({
      selector: "." + v,
      drag: function e(t) {
        var s = t.drag.getDragElement()._jsPlumbDragHandle;
        var n = t.pos.x - t.originalPos.x,
          i = t.pos.y - t.originalPos.y;
        s.focus.ax1 = s.originalLocation.x + n;
        s.focus.ay1 = s.originalLocation.y + i;
        if (s.previous) {
          s.previous.ax2 = s.focus.ax1;
          s.previous.ay2 = s.focus.ay1;
        }
        h(a.currentConnector);
        a.currentConnector.edited = true;
        a.repaintConnection();
      },
      stop: function e(t) {
        a.fireConnectionEditEvent();
      }
    });
    return a;
  }
  a(H, [{
    key: "_activate",
    value: function e(t, s) {
      this.surface.on(this.surface.getContainer(), m, d(p), this.splitListener);
      this.surface.on(this.surface.getContainer(), m, d(y), this.deleteListener);
      this._update();
    }
  }, {
    key: "_clearHandles",
    value: function e(t) {
      for (var s = 0; s < this.segmentHandles.length; s++) {
        if (this.segmentHandles[s].index !== t) {
          if (this.segmentHandles[s].splitButton) {
            this.surface.jsplumb._removeElement(this.segmentHandles[s].splitButton);
          }
          if (this.segmentHandles[s].deleteButton) {
            this.surface.jsplumb._removeElement(this.segmentHandles[s].deleteButton);
          }
          if (this.segmentHandles[s].dragHandle) {
            this.surface.jsplumb._removeElement(this.segmentHandles[s].dragHandle);
          }
        }
      }
      if (this.guideLine && this.guideLine.parentNode) {
        this.guideLine.parentNode.removeChild(this.guideLine);
      }
    }
  }, {
    key: "_elementDragged",
    value: function e(t) {}
  }, {
    key: "_elementDragging",
    value: function e(t) {
      this._repaint();
    }
  }, {
    key: "_repaint",
    value: function e(t) {
      this._update(t);
      for (var s = 0; s < this.segmentHandles.length; s++) {}
    }
  }, {
    key: "_maybeDrawGuideline",
    value: function e() {
      if (this.currentConnector.smooth && this.segments.length > 1) {
        this.guideLine = this.surface.jsplumb.svg.node("svg", {
          width: 10,
          height: 10
        });
        this.guideLine.style.overflow = "visible";
        this.surface.jsplumb.addClass(this.guideLine, [x, j].join(" "));
        var t = this.segments[this.segments.length - 1];
        var s = "M ".concat(this.segments[0].ax1, ", ").concat(this.segments[0].ay1, " ");
        for (var n = 0; n < this.segments.length; n++) {
          s += "L ".concat(this.segments[n].ax2, ", ").concat(this.segments[n].ay2, " ");
        }
        s += "L ".concat(t.ax2, ", ").concat(t.ay2);
        var i = this.surface.jsplumb.svg.node("path", {
          d: s
        });
        this.guideLine.appendChild(i);
        this.surface.jsplumb._appendElement(this.guideLine, this.surface.getContainer());
      }
    }
  }, {
    key: "_update",
    value: function e(t) {
      t = t || {};
      var s = t.segmentInfo;
      this.geometry = this.current.connector.geometry;
      if (this.geometry && this.geometry.segments) {
        this._clearHandles();
        this.segmentHandles.length = 0;
        this.segments.length = 0;
        Array.prototype.push.apply(this.segments, this.geometry.segments);
        for (var n = 0; n < this.segments.length; n++) {
          if (s == null || n !== s.index) {
            var i = this.segments[n];
            var a = {
              x: (i.ax1 + i.ax2) / 2,
              y: (i.ay1 + i.ay2) / 2
            };
            var r = {
              x: (i.ax2 + a.x) / 2,
              y: (i.ay2 + a.y) / 2
            };
            var o = a;
            var g = null;
            if (n > 0) {
              g = u(this.segments[n].ax1, this.segments[n].ay1, this.surface.jsplumb, [l, f, v].join(" "), true);
            }
            var c = u(o.x, o.y, this.surface.jsplumb, [l, f, p].join(" "), true);
            var d = this.segments.length > 1 ? u(r.x, r.y, this.surface.jsplumb, [l, f, y].join(" "), true) : null;
            var m = {
              focus: i,
              previous: n >= 0 ? this.segments[n - 1] : null,
              dragHandle: g,
              splitButton: c,
              deleteButton: d,
              index: n,
              originalLocation: {
                x: i.ax1,
                y: i.ay1
              },
              midPoint: a
            };
            if (g != null) {
              g._jsPlumbDragHandle = m;
            }
            if (c != null) {
              c._jsPlumbDragHandle = m;
            }
            if (d != null) {
              d._jsPlumbDragHandle = m;
            }
            this.segmentHandles.push(m);
          }
        }
        this._maybeDrawGuideline();
      }
    }
  }, {
    key: "deactivate",
    value: function e(t) {
      r(o(H.prototype), "deactivate", this).call(this, t);
      this.surface.off(this.surface.getContainer(), m, this.splitListener);
    }
  }]);
  return H;
}(g);
n(H, "type", c);
export { j as CLASS_EDGE_GUIDELINE, y as CLASS_SEGMENTED_DELETE_HANDLE, v as CLASS_SEGMENTED_DRAG_HANDLE, f as CLASS_SEGMENTED_HANDLE, p as CLASS_SEGMENTED_SPLIT_HANDLE, H as SegmentedEditor };