@if (label) {
  <div class="label">
    {{label}}
  </div>
}

<div
  class="datepicker-wrapper"
  matTooltipPosition="left"
  [matTooltip]="error?.message || error?.toString() || 'An error has occured'"
  [matTooltipDisabled]="!error"
  [ngClass]="{error: error, disabled: control.disabled}"  
  [attr.data-test-id]="'datepicker__' + id"
>
    <input
      readonly
      matInput
      [min]="minDate"
      [max]="maxDate"
      type="text"
      [matDatepicker]="picker"
      [disabled]="control.disabled"
      [placeholder]="input.placeholder || input.label"
      [formControl]="control"
      (click)="picker.open()"
      (dateInput)="setDate($event.value)"
      [attr.data-test-id]="'datepicker__Input'"
    />
    <div class="icon-wrapper" [ngClass]="{disabled: control.disabled}">
      <mat-datepicker-toggle [for]="picker" [attr.data-test-id]="'datepicker__Calendar'">
        <naris-icon
          class="calendar"
          matDatepickerToggleIcon
          icon="calendar-o"
          iconClass="default light"
        />
      </mat-datepicker-toggle>
    </div>
    <mat-datepicker
      #picker
      yPosition="below"
      xPosition="start"
      [disabled]="control.disabled"
      [attr.data-test-id]="'datepicker__DatePicker'"
    >
      @if (input.inputType !== 'date') {
        <mat-datepicker-actions>
          <div class="bottom-content">
            @if (dateTimeArray.includes(input.inputType)) {
              <div class="timepicker">
                <naris-icon icon="waiting" iconClass="default light" />
                <naris-inline-select [control]="hourControl" [options]="hourOpts" id="hourControl"/>
                :
                <naris-inline-select [control]="minuteControl" [options]="minuteOpts" id="minuteControl"/>
              </div>
            }
            <div class="datepicker-actions">
              <naris-button matDatepickerCancel size="small" id="DatePickerCancel">Cancel</naris-button>
              <naris-button
                matDatepickerApply
                size="small"
                color="primary"
                class="ml-2"
                id="DatePickerApply"
              >
                Apply
              </naris-button>
            </div>
          </div>
        </mat-datepicker-actions>
      }
    </mat-datepicker>
</div>
