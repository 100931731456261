@defer (when !loading) {
  @if (isFormList || isCreateWizard) {
    @if (isApplicabilityModule && !!applicabilityEndpoint) {
      <naris-card>
        <app-form
          [endpoint]="applicabilityEndpoint"
          [isEmbedded]="true"
          [showActions]="true"
          [canEditApplicability]="hasApplicability"
          [tabHref]="tabHref || router.url"
          (initialApplicability)="initialApplicability = true"
        />
      </naris-card>
      <br>
    }
    <div class="container-w-sidebar">
      @if (!!formListEndpoint) {
        <naris-card [ngClass]="{'initial-applicability': isApplicabilityModule && initialApplicability}">
          <app-form
            [endpoint]="formListEndpoint"
            [isEmbedded]="true"
            [showActions]="true"
            [canEditApplicability]="hasApplicability"
            [tabHref]="tabHref || router.url"
            (titleChanged)="titleChanged($event)"
          />
        </naris-card>
      }
      @if (showProcessBar) {
        <naris-process-sidebar [processIds]="processIds" [caseId]="caseId" />
      }
    </div>
  } @else {
    <naris-grid>
      @if (!!helpText) {
        <naris-grid-item class="help-text">
          <div [innerHtml]="helpText"></div>
        </naris-grid-item>
      }
      @for (panel of panels; track $index) {
        <naris-grid-item>
          <naris-panel
            [isWizard]="moduleCaseViewComponent.isWizard"
            [panel]="panel"
            [caseId]="moduleCaseViewComponent.caseId"
            [selectedTab]="selectedTab"
            [tabHref]="tabHref || router.url"
            [isTableReload]="true"
            (updated)="update()"
          />
        </naris-grid-item>
      }
    </naris-grid>
  }
}
