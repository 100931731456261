var e = {};
var n = {
  get: function n(t, o) {
    var r = e[t];
    if (!r) {
      throw {
        message: "jsPlumb: unknown layout type '" + t + "'"
      };
    } else {
      o.options = o.options || {};
      return new r(o);
    }
  },
  register: function n(t, o) {
    e[t] = o;
  }
};
export { n as Layouts };