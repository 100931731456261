import { JsonPipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { BASE_URL, NON_REST_BI_PATH } from '@core/constants';
import { generateGUID } from '@core/helpers';
import { IFile } from '@core/models';
import { LanguageService, SnackbarService } from '@core/services';
import { TranslateModule } from '@ngx-translate/core';
import { whooshAnimation } from '@shared/animations/core.animations';
import { ElementsModule } from '@shared/elements/elements.module';
import byteSize from 'byte-size';

@Component({
  selector: 'naris-file-upload',
  standalone: true,
  imports: [MatIconModule, TranslateModule, ElementsModule, JsonPipe],
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss',
  animations: [whooshAnimation]
})
export class FileUploadComponent implements OnInit{

  constructor(
    private readonly http: HttpClient,
    private readonly langService: LanguageService,
    private readonly snackbar: SnackbarService
  ) {}

  /**
   * Boolean value to determine if the user can select multiple files.
   * 
   * Default: true
   */
  @Input()
  public acceptMultipleFiles = true;

  /**
   * String array value representing the file types to accept
   * 
   * Default: ['*']
   */
  @Input()
  public accept? = ['*'];

  /**
   * String array value representing the file extensions to accept
   * 
   * Default: ['*']
   */
  @Input()
  public allowedExtensions: string[] | undefined = ['*'];

  @Input()
  public id: string;

  /**
   * Formgroup control that receives the tokens of the uploaded files
   * 
   * Default: no-default
   */
  @Input()
  set control(value: FormControl) {
    this._control = value;
    if (value.value === null) {
      this.fileTokens = [];
      this.files = [];
      this.setErrors();
    }
  }

  get control(): FormControl {
    return this._control;
  }

  private _control: FormControl;

  /**
   * Maximum size of the uploaded files (in bytes)
   * 
   * Default: 10485760
   */
  @Input()
  public maxFileSize = 10485760;

  public files: IFile[] = [];
  public notAllowedError = false;
  public sizeExceededError = false;
  public fileTokens: string[] = [];
  public uploading =false;
  public maxFileSizeReadable: string;

  public ngOnInit(): void {
    this.maxFileSizeReadable = byteSize(this.maxFileSize, {precision: 0, units: 'iec', locale: this.langService.getAppLang()}).toString();
  }

  public onFileSelected(event: Event) {
    const files = (event.target as any)?.files;
    this.handleFiles(files);
  }

  public preventDefaults(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }

  public addClass(element: HTMLElement) {
    if ((!this.acceptMultipleFiles && !this.files.length) || this.acceptMultipleFiles)
      element.classList.add('highlight');
    else
      element.classList.add('block');
  }

  public removeClass(element: HTMLElement) {
    element.classList.remove('highlight');
    element.classList.remove('block');
  }

  public handleDrop(e: Event) {
    if ((!this.acceptMultipleFiles && !this.files.length) || this.acceptMultipleFiles) {
      const dt = (e as any).dataTransfer;
      const files = dt.files;
    
      this.handleFiles(files);
    }
  }

  private handleFiles(files: File[]) {
    if (!this.acceptMultipleFiles && files.length > 1) {
      this.snackbar.open({text: 'dropzone.error.multiple', type: 'error'});
      return;
    }
    ([...files]).forEach(file => {
      const splittedFileName = file.name.split('.');
      const fileExtension = splittedFileName[splittedFileName.length - 1];
      const newFile = {
        id: generateGUID(),
        name: file.name,
        type: file.type,
        extension: fileExtension,
        size: file.size,
        readableSize: byteSize(file.size, {precision: 0, units: 'iec', locale: this.langService.getAppLang()}).toString()
      } as IFile;
      if (!!newFile.extension && this.allowedExtensions?.[0] !== '*' && !this.allowedExtensions?.includes(newFile.extension)) newFile.error = 'dropzone.extension-not-allowed';
      if (!!newFile.type && this.accept?.[0] !== '*' && !this.accept?.includes(newFile.type)) newFile.error = 'dropzone.not-accepted';
      if (!!newFile.size && newFile.size > this.maxFileSize) newFile.error = 'dropzone.error.size';
      this.files.push(newFile);
      
      this.setErrors();

      if (!newFile.error) this.uploadFile(file, newFile);
    });
  }

  public removeFile(guid: string) {
    const fileIndex = this.files.findIndex((file: IFile) => file.id === guid);
    const removedFile = this.files[fileIndex];
    const fileToken = removedFile.token;
    if (!!fileToken) {
      const fileTokenIndex = this.fileTokens.findIndex(token => token === fileToken);
      this.fileTokens.splice(fileTokenIndex, 1);
    }
    this.files.splice(fileIndex, 1);
    this.setErrors();
  }

  private setErrors() {
    this.notAllowedError = this.files.some(file => file.error === 'dropzone.extension-not-allowed');
    this.sizeExceededError = this.files.some(file => file.error === 'dropzone.error.size');
  }

  private readonly uploadFile = (file: File, newFile: IFile) => {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': file.type,  'x-filename': file.name, 'x-filesize': file.size.toString(), 'x-requested-with': 'XMLHttpRequest'})
    };
    const url = `${BASE_URL}${NON_REST_BI_PATH}/uploadFile`;
    this.uploading = true;
    // this url isn't catched by the interceptor for some reason, therefore manually check if we're in production environment and change the URL
    
    this.http.post(url, file, httpOptions).subscribe({
      next: (data: Record<string, any>) => {
        const token = data['token'];
        this.fileTokens.push(token);
        newFile.token = token;
        if (!!this.control) this.control.setValue(this.fileTokens);
      },
      error: err => this.snackbar.open({text: err.error?.error?.id || err.message, type: 'error'}),
      complete: () => this.uploading = false
    });
  };
}