export const ACTIONS = {
  'tags': {
    icon: 'tags'
  },
  'add-document': {
    icon: 'document_add'
  },
  'abort-audit-execution': {
    icon: 'dangerous'
  },
  'request-password-reset': {
    icon: 'change_password'
  },
  'set-context': {
    icon: 'set_context'
  },
  'resume-audit-execution': {
    icon: 'audit_play'
  },
  'suspend-audit-execution': {
    icon: 'audit_pause'
  }
};
