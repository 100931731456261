import { createClass as t, classCallCheck as e, defineProperty as r } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { Port as i, isNode as s, isGroup as h } from "./graph.js";
import { log as u } from "../../ui-core/util/util.js";
var l = function () {
  function l(t, i) {
    e(this, l);
    this.toolkit = t;
    r(this, "result", void 0);
    r(this, "vertices", []);
    r(this, "vertexMap", {});
    this.result = t.getGraph().findPath(i.source, i.target, i.strict, i.nodeFilter, i.edgeFilter);
    for (var s = 0; s < this.result.path.length; s++) {
      this.vertices.push(this.result.path[s].vertex);
      this.vertexMap[this.result.path[s].vertex.getFullId()] = [this.result.path[s].vertex, s];
    }
  }
  t(l, [{
    key: "deleteEdges",
    value: function t() {
      for (var e = 0; e < this.result.path.length; e++) {
        if (this.result.path[e].edge) {
          this.toolkit.removeEdge(this.result.path[e].edge);
        }
      }
      return this;
    }
  }, {
    key: "deleteVertices",
    value: function t() {
      for (var e = 0; e < this.result.path.length; e++) {
        this.toolkit.removeNode(this.result.path[e].vertex);
      }
      return this;
    }
  }, {
    key: "contains",
    value: function t(e, r) {
      var s = this.toolkit.findGraphObject(e),
        h = false;
      if (s) {
        for (var u = 0; u < this.result.path.length; u++) {
          if (this.result.path[u].vertex == s || this.result.path[u].edge == s || !r && this.result.path[u].vertex.objectType == i.objectType && this.result.path[u].vertex.isChildOf(s)) {
            h = true;
            break;
          }
        }
      }
      return h;
    }
  }, {
    key: "getVertices",
    value: function t() {
      return this.vertices;
    }
  }, {
    key: "getVertex",
    value: function t(e) {
      return this.vertexMap[typeof e === "string" ? e : e.id][0];
    }
  }, {
    key: "getAllEdgesFor",
    value: function t(e) {
      var r = this.vertexMap[e.id][1];
      if (r < this.result.path.length - 1) {
        return [this.result.path[r + 1].edge];
      } else return [];
    }
  }, {
    key: "filter",
    value: function t(e, r) {
      u("not implemented.");
    }
  }, {
    key: "_each",
    value: function t(e, r) {
      for (var i = r || 0; i < this.result.path.length; i++) {
        try {
          e(i, this.result.path[i]);
        } catch (t) {
          u("Path iterator function failed", t);
        }
      }
    }
  }, {
    key: "each",
    value: function t(e) {
      this._each(function (t, r) {
        e(t, r);
      });
    }
  }, {
    key: "eachVertex",
    value: function t(e) {
      this._each(function (t, r) {
        e(t, r.vertex);
      });
    }
  }, {
    key: "eachEdge",
    value: function t(e) {
      this._each(function (t, r) {
        e(t, r.edge);
      }, 1);
    }
  }, {
    key: "eachNode",
    value: function t(e) {
      this._each(function (t, r) {
        if (s(r.vertex)) {
          e(t, r.vertex);
        }
      });
    }
  }, {
    key: "eachGroup",
    value: function t(e) {
      this._each(function (t, r) {
        if (h(r.vertex)) {
          e(t, r.vertex);
        }
      });
    }
  }, {
    key: "getVertexCount",
    value: function t() {
      return this.result.path.length;
    }
  }, {
    key: "getNodeAt",
    value: function t(e) {
      return this.result.path[e].vertex;
    }
  }, {
    key: "getEdgeCount",
    value: function t() {
      return this.result.path.length == 0 ? 0 : this.result.path.length - 1;
    }
  }, {
    key: "getEdgeAt",
    value: function t(e) {
      if (e < 0) e = this.result.path.length - 1 + e;
      return this.result.path.length > e + 1 ? this.result.path[e + 1].edge : null;
    }
  }, {
    key: "deleteAll",
    value: function t() {
      this.deleteVertices();
    }
  }, {
    key: "isEmpty",
    value: function t() {
      return this.result.path.length == 0;
    }
  }, {
    key: "getCost",
    value: function t() {
      return this.result.pathDistance;
    }
  }, {
    key: "exists",
    value: function t() {
      return this.result.pathDistance != null;
    }
  }]);
  return l;
}();
export { l as Path };