import { NgStyle } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ARCHIMATE_ROUNDED_CORNER_ELEMENTS, ARCHIMATE_SHARP_CORNER_ELEMENTS } from '@core/constants/jsplumb-constants';
import { ANCHOR_POSITIONS } from '@core/constants/process-constants';
import { ObjectData } from '@jsplumbtoolkit/browser-ui';
import { BaseNodeComponent } from '@jsplumbtoolkit/browser-ui-angular';
import { TranslateModule } from '@ngx-translate/core';
import { ElementsModule } from '@shared/elements/elements.module';

@Component({
  selector: 'app-archimate-node',
  standalone: true,
  imports: [ElementsModule, NgStyle, MatTooltipModule, TranslateModule],
  templateUrl: './archimate-node.component.html',
  styleUrl: './archimate-node.component.scss'
})
export class ArchimateNodeComponent extends BaseNodeComponent implements OnInit {

  public anchorPositions = ANCHOR_POSITIONS;
  public backgroundColor: string;
  public cornerStyle: string;

  public ngOnInit(): void {
    this.backgroundColor = this.getNodeColor(this.obj.layer);
    this.cornerStyle = this.getNodeCorners(this.obj);
  }

  private getNodeColor(type: string): string {
    switch(type) {
      case 'Strategy':
        return '#f5deaa';
      case 'Business':
        return '#ffffaf';
      case 'Application':
        return '#afffff';
      case 'Technology':
        return '#afffaf';
      case 'Physical': 
        return '#afffaf';
      case 'Motivation':
        return '#ccccff';
      case 'ImplementationMigration':
        return '#ffe0e0';
      default:
        return '#fff';
    }
  }

  private getNodeCorners(obj: ObjectData): string {
    if (obj.layer === 'Motivation') return 'slanted';
    else if (ARCHIMATE_ROUNDED_CORNER_ELEMENTS.includes(obj.element)) return 'rounded';
    else if (ARCHIMATE_SHARP_CORNER_ELEMENTS.includes(obj.element)) return 'sharp';
    else return '';
  }
}
