import { Component, ElementRef, ViewChild, Input, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { SnackbarService, DialogService, ContributionsService, HttpService, FormService, BeinformedService, AuthService } from '@core/services';
import { EAuthContext } from '@core/enums';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../button/button.component';
import { IconComponent } from '../icon/icon.component';
import type { IAction, IMultiSliderClassData, IMultiSliderData } from '@core/models';

@Component({
  selector: 'naris-multi-slider',
  templateUrl: './multi-slider.component.html',
  styleUrls: ['./multi-slider.component.scss'],
  standalone: true,
  imports: [ButtonComponent, MatTooltip, IconComponent, TranslateModule]
})
export class MultiSliderComponent implements OnInit {

  public data: IMultiSliderData[] | null = [] as IMultiSliderData[];

  public addBtnLeft: number;
  public offsetWidth: number;
  public offsetLeft: number;
  private selectedSlider: EventTarget & Record<string, any> | null;
  public isMouseDown = false;
  public canDeleteClass = true;
  public canUpdateClass = true;
  public className: string;
  public minName: string;
  public maxName: string;
  private defaultName: string;
  private labelName: string;

  public createAction: IAction | undefined;
  public updateAction: IAction | undefined;
  public pushAction: IAction | undefined;

  private tokens: string[];

  public hadOverlap = false;
  public overlapMessage = '';
  public overlapFixMessage = 'multislider.auto_fix_message';
  public overlapSaveMessage = 'multislider.overlap_save_message';
  public title: string;

  public param: any;

  public calculatedHighlightWidth: number;
  private isInit = true;
  private resetData: any[];
  private isRest: boolean;

  public hideColumns: string[] = [];

  @Input() public showScale = true;
  @Input() public saveText = 'save';
  @Input() public resetText = 'reset';
  @Input() public href = '';
  @Input() public id: string;

  @Output() public readonly updated = new EventEmitter<void>();

  @ViewChild('multislider') public multiSlider: ElementRef;

  constructor(
    private readonly httpService: HttpService,
    private readonly formService: FormService,
    private readonly confirmService: DialogService,
    private readonly snackbar: SnackbarService,
    private readonly contributionsService: ContributionsService,
    private readonly renderer: Renderer2,
    private readonly beinformedService: BeinformedService,
    private readonly authService: AuthService
  ) {}

  public ngOnInit() {
    this.isRest = this.authService.authContext === EAuthContext.SSO;
    this.getData();
  }

  /**
   * Get data for displaying the multi slider (width, start position etc.)
   */
  private setInitialData() {
    this.setNames();
    this.offsetLeft = this.multiSlider?.nativeElement?.offsetLeft;
    this.calculateViewProperties();
  }

  /**
   * Determine the property names of the class
   * (in case of likelihood class it's 'LikelihoodClassMinimum' in case of consequence classes it's 'ConsequenceClassMinimum')
   */
  private setNames() {
    if (!this.data || this.data.length < 1) return;
    const dataItem = this.data[0];
    this.className = Object.keys(dataItem)[0];
    Object.keys(dataItem[this.className]).forEach(key => {
      const lowerKey = key.toLowerCase();
      if (lowerKey.includes('min')) this.minName = key;
      if (lowerKey.includes('max')) this.maxName = key;
      if (lowerKey.includes('default')) this.defaultName = key;
      if (lowerKey.includes('name')) this.labelName = key;
    });
  }

  /**
   * Actual getting the data to be displayed in the slider
   */
  private getData() {
    this.data = null;
    this.beinformedService.fetchResponseWithContributions(this.href, false).subscribe(res => {
      const result = res.data?._embedded?.results;
      const actions = res.data?.actions;
      const objName = Object.keys(res.contributions.results)[0];
      const attributes = res.contributions.results?.[objName].attributes as any[];

      // Look for attributes with layouthint column-hide so we can exclude them while saving
      attributes.forEach(attr => {
        const attrName = Object.keys(attr)[0];
        if (!!attr[attrName].layouthint?.find((hint: any) => hint.includes('column-hide'))) this.hideColumns.push(attrName);
      });

      this.createAction = actions?.find(action => action.name?.includes('create'));
      this.updateAction = actions?.find(action => action.name?.includes('update'));
      this.pushAction = actions?.find(action => action.name?.includes('push'));

      const foundPushAction = res.contributions.actions.find((action: IAction) => action.name === this.pushAction?.name);
      if (!!this.pushAction && !!foundPushAction) {
        this.pushAction = {...this.pushAction, ...foundPushAction};
      }
      this.data = result;
      this.title = res.contributions.label;
      if (!!this.data) this.setInitialData();
    });
  }

  /**
   * Calculate the physical witdh of the element
   * @param data, an object that includes the min and max value of a single element
   */
  public calculateWidth(data: IMultiSliderClassData) {
    this.offsetWidth = this.multiSlider?.nativeElement?.offsetWidth - 20; // add some right side margin
    this.offsetWidth = !!this.createAction ? this.offsetWidth - 36 : this.offsetWidth;
    if (!this.offsetWidth) return;
    const currentRange = data[this.maxName] - data[this.minName];
    const currentWidth = this.offsetWidth * currentRange / 100;
    this.calculatedHighlightWidth = this.offsetWidth * data[this.maxName] / 100;
    return currentWidth;
  }

  /**
   * The mouseDown event checks if we start sliding
   * @param event the object passed by the mouseDown event
   */
  public mouseDown(event: MouseEvent) {
    this.isMouseDown = !this.pushAction;
    this.selectedSlider = event.target;
  }

  /**
   * This function is triggered by the mouseDown event and checks if we want to start dragging.
   * If so, calculate the width of the current element and set the start position of its next sibling
   * @param event the object passed by the mouseMove event
   */
  public mouseMove(event: MouseEvent & Record<string, any>) {
    if (!this.isMouseDown) return;
    const relativePos = event['layerX'] - this.offsetLeft;
    const newValue = this.determineNewValue(relativePos);
    const foundElement = this.data?.find(element => element[this.className]['_id'] === +this.selectedSlider?.id);
    const diff = relativePos - foundElement!.sliderLeft;
    const selectedIndex = this.data?.findIndex(element => element[this.className]['_id'] === +this.selectedSlider?.id);
    const nextElement = selectedIndex !== this.data?.length ? this.data?.[selectedIndex! + 1] : null;
    const prevElement = selectedIndex !== 0 ? this.data?.[selectedIndex! - 1] : null;

    if (newValue < nextElement?.[this.className][this.maxName] - 1 && newValue > +prevElement?.[this.className][this.maxName] + 1 || selectedIndex === 0 && newValue > 1) {
      this.renderer.setStyle(this.selectedSlider, 'left', `${relativePos}px`);
      if (!!foundElement) {
        foundElement.sliderLeft = relativePos;
        foundElement[this.className][this.maxName] = newValue;
        foundElement.width = +foundElement.width + diff;
        if (newValue <= foundElement[this.className][this.defaultName]) foundElement[this.className][this.defaultName] = newValue - 1;
      }
      if (!!nextElement) {
        nextElement.width = +nextElement.width - diff;
        nextElement[this.className][this.minName] = newValue;
        if (newValue >= nextElement[this.className][this.defaultName]) nextElement[this.className][this.defaultName] = newValue + 1;
      }
    }
  }

  /**
   * A function used to determine the value of the current cursor position (mapping the position to a valid value)
   * @param position the X position of the cursor
   */
  private determineNewValue(position: number): number {
    const percentage = position * 100 / this.offsetWidth;
    return Math.round(percentage);
  }

  /**
   * Reseting the values needed for dragging
   * @param event  the object passed by the mouseUp event
   */
  public mouseUp() {
    this.isMouseDown = false;
    this.selectedSlider = null;
    this.save();
  }

  /**
   * Get the X position of where the slide handle should be displayed
   * @param data, an object that includes the min and max value of a single element
   */
  public getSliderPosition(data: Record<string, any>): number {
    const max = data[this.className][this.maxName];
    const left = this.offsetWidth * max / 100;
    return left - 11;
  }

  /**
   * The text shown in each element
   * @param data, an object that includes the min and max value of a single element
   */
  public getLabel(data: IMultiSliderData): string {
    const name = data[this.className][this.labelName];
    const defaultValue = Object.values(data)[0][this.defaultName];
    const returnValue = `${name} - ${defaultValue}`;
    return returnValue;
  }

  /**
   * Calling the form generator to add an extra element
   */
  public create() {
    if (!!this.createAction?.href) {
      this.formService.open(this.createAction.href).subscribe(generatedForm => {
        if (generatedForm) {
          this.snackbar.open({text: 'saved_succesfully', type: 'success'});
          this.getData();
          this.updated.emit();
        } else this.snackbar.open({text: 'error.save_failed', type: 'warning'});
      });
    }
  }

  /**
   * Tokens are needed to update the elements.
   * They are returned by the back end after calling the create endpoint without correct data
   */
  public getTokens() {
    return this.httpService.post(`${this.createAction?.href}?commit=false`, {}, this.isRest).pipe(
      catchError((err: any) => { // the backend returns an error 400 which includes the tokens needed for posting updates
        if (err.status === 400 && err.error.formresponse) this.tokens = err.error.formresponse.tokens || null;
        throw err;
      })
    );
  }

  /**
   * Returns an object which is used to determine if the user has the rights to execute that action
   * @param data, an object that includes the min and max value of a single element
   * @param actionName the action that should be returned if the user has the correct rights.
   */
  public getAction(data: IMultiSliderData, actionName: string) {
    const currentActions = Object.values(data)[0]['actions'] as IAction[];
    return currentActions?.find(element => element.name?.includes(actionName));
  }

  /**
   * This saves the current state of the sliders
   */
  public saveMultiple() {
    const body = {tokens: this.tokens, objects: [] as Record<string, any>[]};
    this.data?.forEach(x => {
      const [ key, value ] = Object.entries(x)[0];
      const objKeys = Object.keys(value);
      objKeys.forEach(objKey => {
        if (this.hideColumns.includes(objKey)) delete value[objKey];
      });
      body.objects.push({[key]: this.stripUnnecessaryItems(value)});
    });
    if (!!this.updateAction?.href) {
      this.httpService.post(this.updateAction.href, body).subscribe(() => {
        this.updated.emit();
        this.snackbar.open({text: 'saved_succesfully', type: 'success'});
        this.hadOverlap = false;
      });
    }
  }

  /**
   * The function used when pushing the 'Save' button.
   */
  public save() {
    this.getTokens().subscribe({
      next: () => null, // first get tokens
      error: err => {
        // we got the tokens
        if (err.status === 400) this.saveMultiple(); // Now save the data
      }
    });
  }

  /**
   * A function to strip some properties off of an object before posting it to the backend
   * @param classObject an object returned by the BeInformed backend
   */
  private stripUnnecessaryItems(classObject: IMultiSliderClassData) {
    const {actions, _id, _links, ...rest} = classObject;
    return rest;
  }

  /**
   * When the pencil or trashcan on an element is pressed it should either update or call the element
   * @param data, an object that includes the min and max value of a single element
   */
  public executeAction(data: any, item: any) {
    if (data.name.includes('delete')) {
      this.confirmService.open({
        title: 'are_you_sure',
        text: 'multislider.delete_message',
        confirmLabel: 'accept',
        cancelLabel: 'cancel'
      }).subscribe(x => {
        if (x) {
          this.httpService.post(data.href, {}, this.isRest).subscribe(() => {
            this.recalculateOthers(item);
            this.getData();
          });
        }
      });
    } else if (data.name.includes('update')) {
      this.formService.open(data.href).subscribe(generatedForm => {
        if (generatedForm) this.getData();
      });
    }
  }

  /**
   * Reset the unsaved changes
   */
  public reset() {
    this.data = this.resetData;
    this.save();
  }

  /**
   * Calculate the values of the surrounding items to keep the values of the items consistent
   * @param deletedItem, the item whose values are used for a subsequent item.
   */
  private recalculateOthers(deletedItem: IMultiSliderData) {
    const deletedItemMinimum = deletedItem.LikelihoodClass?.LikelihoodClassMinimum;
    const deletedItemMaximum = deletedItem.LikelihoodClass?.LikelihoodClassMaximum;
    const deletedItemId = deletedItem.LikelihoodClass?._id;
    // should work, because minimum and maximum values should be subsequent
    let item = this.data?.find(x => x.LikelihoodClass.LikelihoodClassMaximum === deletedItemMinimum);
    const updatePrevious = !!item;
    if (!updatePrevious) item = this.data?.find(x => x.LikelihoodClass.LikelihoodClassMinimum === deletedItemMaximum);
    if (!item) return;
    if (updatePrevious) item.LikelihoodClass.LikelihoodClassMaximum = deletedItemMaximum;
    else item.LikelihoodClass.LikelihoodClassMinimum = deletedItemMinimum;
    const deleteIndex = this.data?.findIndex(x => x.LikelihoodClass._id === deletedItemId);
    if (deleteIndex !== -1) this.data?.splice(deleteIndex!, 1);
    this.save();
    this.calculateViewProperties();
  }

  /**
   * Set the values, used in the view, of all items in the array
   */
  private calculateViewProperties() {
    this.data?.forEach((element, index) => { // add some extra information to the data objects to be used in HTML
      element['isLast'] = index === this.data!.length - 1;
      element['isFirst'] = index === 0;
      element['width'] = this.calculateWidth(element[this.className]) || 1;
      element['start'] = +this.data![index - 1]?.['width'] + +this.data![index - 1]?.['start'] || 0;
      element['sliderLeft'] = this.getSliderPosition(element);
      element['canUpdate'] = this.getAction(element, 'update')!;
      element['canDelete'] = this.getAction(element, 'delete')!;
    });
    if (this.isInit) this.resetData = JSON.parse(JSON.stringify(this.data));
    this.isInit = false;
  }

  public pushAll(): void {
    if (!!this.pushAction?.href) {
      this.httpService.post(this.pushAction?.href, {objects: {Organizations_LikelihoodClassChoice: {AllOrganizations: true}}}).subscribe(_res => {
        this.snackbar.open({text: 'saved_succesfully', type: 'success'});
      });
    }
  }
}
