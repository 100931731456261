<div class="wrapper" [attr.data-test-id]="'timepicker__' + id">
  <naris-input (focusedChange)="focusChanged($event)" [isTime]="true" [control]="getFormControl('hours')" type="number" [placeholder]="'timepicker.hours' | translate" [min]="0" [max]="23" [maxLength]="2" />
  <naris-input (focusedChange)="focusChanged($event)" [isTime]="true" [control]="getFormControl('minutes')" type="number" [placeholder]="'timepicker.minutes' | translate" [min]="0" [max]="59" [maxLength]="2" #minuteElement />
</div>
@if (!disabled) {
  <div 
    class="validation-indicator valid"
    [class.active]="isFocused"
    [class.valid]="internalFormgroup.valid && (!!internalFormgroup.controls['hours'].touched || !!internalFormgroup.controls['minutes'].touched)"
    [class.invalid]="!internalFormgroup.valid && (!!internalFormgroup.controls['hours'].touched || !!internalFormgroup.controls['minutes'].touched)"
  ></div>
}

