import { createClass as t, classCallCheck as r, defineProperty as e } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { EVENT_REVERT as n } from "./event-constants.js";
import { Collicat as i, generateConstrainFunctionFromContainmentType as a } from "./collicat.js";
import { CLASS_DRAG_SELECTED as o } from "./drag-selection.js";
import { extend as s, wrap as l, forEach as d, getWithFunction as c } from "../util/util.js";
var u = "jtk-delegated-draggable";
var h = "jtk-draggable";
var g = "jtk-drag";
var f = "jtk-ghost-proxy";
var v = "jtk-drag-active";
var m = "jtk-dragged";
var k = "jtk-drag-hover";
var p = "jtk-drag-original-group";
var y = function () {
  function m(t, n, a) {
    var s = this;
    r(this, m);
    this.instance = t;
    this.dragSelection = n;
    e(this, "collicat", void 0);
    e(this, "drag", void 0);
    e(this, "_draggables", {});
    e(this, "_dlist", []);
    e(this, "_elementsWithEndpoints", {});
    e(this, "_draggablesForElements", {});
    e(this, "handlers", []);
    e(this, "_trackScroll", void 0);
    e(this, "_filtersToAdd", []);
    a = a || {};
    this.collicat = new i({
      getCompoundZoom: a.getCompoundZoom,
      zoom: this.instance.currentZoom,
      getPosition: function t(r) {
        return s.instance.getPosition(r);
      },
      setPosition: function t(r, e) {
        return s.instance.setPosition(r, e);
      },
      getSize: function t(r) {
        return s.instance.getSize(r);
      },
      css: {
        noSelect: this.instance.dragSelectClass,
        delegatedDraggable: u,
        draggable: h,
        drag: g,
        selected: o,
        active: v,
        hover: k,
        ghostProxy: f
      }
    });
    this._trackScroll = a.trackScroll !== false;
  }
  t(m, [{
    key: "setZoom",
    value: function t(r) {
      this.collicat.zoom = r;
    }
  }, {
    key: "addHandler",
    value: function t(r, e) {
      var i = this;
      var o = s({
        selector: r.selector
      }, e || {});
      o.start = l(o.start, function (t) {
        return r.onStart(t);
      }, false);
      o.drag = l(o.drag, function (t) {
        return r.onDrag(t);
      });
      o.stop = l(o.stop, function (t) {
        return r.onStop(t);
      });
      var c = (r.onBeforeStart || function (t) {}).bind(r);
      o.beforeStart = l(o.beforeStart, function (t) {
        return c(t);
      });
      o.dragInit = function (t, e) {
        return r.onDragInit(t, e);
      };
      o.dragAbort = function (t) {
        return r.onDragAbort(t);
      };
      if (r.useGhostProxy) {
        o.useGhostProxy = r.useGhostProxy;
        o.makeGhostProxy = r.makeGhostProxy;
      }
      if (o.constrainFunction == null && o.containment != null) {
        o.constrainFunction = a(o.containment, o.containmentPadding);
      }
      if (this.drag == null) {
        o.trackScroll = this._trackScroll;
        this.drag = this.collicat.draggable(this.instance.getContainer(), o);
        d(this._filtersToAdd, function (t) {
          return i.drag.addFilter(t[0], t[1]);
        });
        this.drag.on(n, function (t) {
          i.instance.revalidate(t);
        });
      } else {
        this.drag.addSelector(o);
      }
      this.handlers.push({
        handler: r,
        options: o
      });
      r.init(this.drag);
    }
  }, {
    key: "addSelector",
    value: function t(r, e) {
      this.drag && this.drag.addSelector(r, e);
    }
  }, {
    key: "addFilter",
    value: function t(r, e) {
      if (this.drag == null) {
        this._filtersToAdd.push([r, e === true]);
      } else {
        this.drag.addFilter(r, e);
      }
    }
  }, {
    key: "removeFilter",
    value: function t(r) {
      if (this.drag != null) {
        this.drag.removeFilter(r);
      }
    }
  }, {
    key: "setFilters",
    value: function t(r) {
      var e = this;
      d(r, function (t) {
        e.drag.addFilter(t[0], t[1]);
      });
    }
  }, {
    key: "reset",
    value: function t() {
      var r = [];
      d(this.handlers, function (t) {
        t.handler.reset();
      });
      this.handlers.length = 0;
      if (this.drag != null) {
        var e = this.drag._filters;
        for (var n in e) {
          r.push([n, e[n][1]]);
        }
        this.collicat.destroyDraggable(this.instance.getContainer());
      }
      delete this.drag;
      return r;
    }
  }, {
    key: "setOption",
    value: function t(r, e) {
      var n = c(this.handlers, function (t) {
        return t.handler === r;
      });
      if (n != null) {
        s(n.options, e || {});
      }
    }
  }, {
    key: "canvasPanned",
    value: function t(r) {
      this.drag.canvasPanned(r);
    }
  }]);
  return m;
}();
export { u as CLASS_DELEGATED_DRAGGABLE, h as CLASS_DRAGGABLE, m as CLASS_DRAGGED, v as CLASS_DRAG_ACTIVE, g as CLASS_DRAG_CONTAINER, k as CLASS_DRAG_HOVER, p as CLASS_DRAG_ORIGINAL_GROUP, f as CLASS_GHOST_PROXY, y as DragManager };