<mat-accordion>
  @for (step of case().steps; let i = $index; track i) {
    <mat-expansion-panel
      [expanded]="case().caseState.activeStep === i"
      (opened)="panelOpened(i)"
      (closed)="case().caseState.activeStep === i && panelClosed()"
      [disabled]="(!!i && case().caseState.steps[i - 1] !== 2) || !isHost"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="panel-title">
          <naris-avatar
            class="panel-title-number"
            [icon]="case().caseState.steps[i] === 2 ? 'check' : ''"
            [label]="case().caseState.steps[i] !== 2 ? (i + 1).toString() : ''"
            [color]="
            case().caseState.steps[i] === 2
                ? 'success'
                : case().caseState.steps[i] === 1
                ? 'primary'
                : 'default'
            "
            size="small"
            id="CollaborationPanel"
          />
          <span>{{ step.title | translate }}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="step-content">
        @switch(i) { 
          @case(0) {
            <naris-card class="step-content-card" [border]="true">
              <naris-table
                [dataUrl]="causesUrl"
                [tableConfig]="{ pagination: false }"
                (loaded)="isHost && sendRefresh(0)"
                #causesTable
              />
            </naris-card>
            <naris-card class="step-content-card" [border]="true">
              <naris-table
                [dataUrl]="consequencesUrl"
                [tableConfig]="{ pagination: false }"
                (loaded)="isHost && sendRefresh(1)"
                #consequencesTable
              />
            </naris-card>
            <div class="mt-1">
              <naris-slidetoggle
                [control]="blindVoteControl"
                [id]="'CollaborationBlindVoting'"
                >{{ "collab.blind_voting" | translate }}</naris-slidetoggle
              >
            </div>
          } 
          @case(1) { 
            @if (!isHost) {
              <div class="likelihood-wrapper">
                <p>{{'heatmap.likelihood' | translate}}: <b>{{likelihoodValue}}%</b></p>
                <naris-slider
                  [(inputModel)]="likelihoodValue"
                  [discrete]="true"
                />
                @if(showLikelihoodMessage) {
                  <div class="notify-text">
                    <div class="notify-text-icon">
                      <naris-icon [icon]="'error'" />
                    </div>
                    <div class="notify-text-content">
                      <div>
                        {{'collab.change_likelihood' | translate}}
                      </div>
                    </div>
                  </div>
                }
                <div>
                  <naris-button [color]="voteColor" (clicked)="setLikelihood()">{{'collab.cast_vote' | translate}}</naris-button>
                </div>
              </div>
            }
            <div class="vote-wrapper">
              @for (category of case().caseState.categories; track $index) {
                <naris-collaboration-room-panel-vote
                  [category]="category"
                  [blindVoting]="blindVoting"
                  [isHost]="isHost"
                  [isSingle]="case().caseState.categories?.length === 1"
                  [xValue]="likelihoodValue"
                  [disabled]="!likelihoodSet"
                  (saveVote)="saveVote($event)"
                  [displayLikelihood]="!isHost"
                  [mode]="'voting'"
                />
              }
            </div>
          } 
          @case (2) { 
            @if (isHost) {
              <div class="likelihood-wrapper">
                <p>{{'heatmap.likelihood' | translate}}: <b>{{likelihoodValueHost}}%</b></p>
                <naris-slider
                  [(inputModel)]="likelihoodValueHost"
                  [discrete]="true"
                />
                @if(showLikelihoodMessage) {
                  <div class="notify-text">
                    <div class="notify-text-icon">
                      <naris-icon [icon]="'error'" />
                    </div>
                    <div class="notify-text-content">
                      <div>
                        {{'collab.change_likelihood' | translate}}
                      </div>
                    </div>
                  </div>
                }
                <div>
                  <naris-button [color]="voteColor" (clicked)="setLikelihood()">{{'collab.cast_vote' | translate}}</naris-button>
                </div>
              </div>
            }
            <div class="vote-wrapper">
              @for (category of case().caseState.categories; track $index) {
                <naris-collaboration-room-panel-vote
                  [category]="category"
                  [blindVoting]="blindVoting"
                  [isSingle]="case().caseState.categories?.length === 1"
                  [xValue]="likelihoodValueHost"
                  [disabled]="!hostLikelihoodSet"
                  (saveVote)="saveVote($event)"
                  [isHost]="isHost"
                  [displayLikelihood]="isHost"
                  [mode]="'overview'"
                />
              }
            </div>
          } @case (3) {
            @if (isHost) {
              @if (!!pushUrl) {
                <app-form 
                  [endpoint]="pushUrl"
                  [isCollabPush]="true"
                  [isEmbedded]="true"
                  (completed)="collabCompleted()"
                />
              }
            } @else {
              @if (case().completed && !!pushUrl) {
                <app-form 
                  [endpoint]="pushUrl"
                  [isCollabPush]="true"
                  [isEmbedded]="true"
                  (completed)="collabCompleted()"
                />
              } @else {
                <naris-empty-state 
                  [title]="'collab.facilitator_wrapping_up' | translate"
                  [text]="'collab.facilitator_missing_data' | translate"
                  [image]="'wrapping-up'"
                  [size]="'large'"
                />
              }
            }
          }
        }
        <ng-template #empty>
          <p>{{ "collab.no_categories" | translate }}</p>
        </ng-template>
      </div>
      @if (isHost && i !== 3) {
        <mat-action-row>
          @if (!!i && i !== 2) {
            <naris-button
              color="default"
              (clicked)="setStep(i - 1)"
              id="CollaborationPanelPrevious"
              >{{ "collab.previous" | translate }}</naris-button
            >
          }
          <naris-button
            class="step-button"
            [color]="case().caseState.steps[i] === 2 ? 'primary' : 'success'"
            (clicked)="setStep(i + 1)"
            [disabled]="
              (i === 1 && case().caseState.categories!.length === 0) ||
              (i === 3 && !collabService.allFinalVotes) ||
              (case().caseState.activeStep === 1 && case().caseState.categories?.length === 0) ||
              (case().caseState.activeStep === 2 && !allVoted())
            "
            id="CollaborationPanelCloseNextComplete"
          >
            {{
              (case().caseState.steps[i] === 2 && i === 3
                ? "collab.close"
                : "collab.complete"
              ) | translate
            }}
          </naris-button>
        </mat-action-row>
      }
    </mat-expansion-panel>
  }
</mat-accordion>
