import { createClass as i, classCallCheck as n } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { extend as l } from "../util/util.js";
import { _attr as t, _pos as r } from "./svg-util.js";
import { ElementTypes as a } from "./browser-util.js";
var s = function () {
  function s() {
    n(this, s);
  }
  i(s, null, [{
    key: "paint",
    value: function i(n, s, e, m) {
      if (e != null) {
        var o = [n.x, n.y],
          u = [n.w, n.h];
        if (m != null) {
          if (m.xmin < 0) {
            o[0] += m.xmin;
          }
          if (m.ymin < 0) {
            o[1] += m.ymin;
          }
          u[0] = m.xmax + (m.xmin < 0 ? -m.xmin : 0);
          u[1] = m.ymax + (m.ymin < 0 ? -m.ymin : 0);
        }
        if (isFinite(u[0]) && isFinite(u[1])) {
          var f = {
            width: "" + (u[0] || 0),
            height: "" + (u[1] || 0)
          };
          if (s.containerType === a.HTML) {
            t(n.canvas, l(f, {
              style: r([o[0], o[1]])
            }));
          } else {
            t(n.canvas, l(f, {
              x: o[0],
              y: o[1]
            }));
          }
        }
      }
    }
  }]);
  return s;
}();
export { s as SvgComponent };