export const LANGUAGE_KEYS = {
  DISMISS: 'dismiss',
  ACTION_COMPLETED_SUCCESSFULL: 'action_completed_successfull',
  SETUP_YOUR_PERSONAL_DASHBOARD: 'setup_your_personal_dashboard'
} as Record<string, string>;

export const NARIS_SITE = 'https://www.naris.com';
export const EN = 'en';
export const NL = 'nl';
export const DE = 'de';
export const FR = 'fr';
