
<div class="wrapper">
  <div class="header">
    <div class="header-left">
      <h3>{{label}}</h3>
      <mat-chip-listbox #chipGrid>
        @for (chip of (filterChips$ | async); track $index) {
          <mat-chip>
            {{ chip.label }}: {{ chip.val }}
          </mat-chip>
        }
      </mat-chip-listbox>
    </div>
    <div>
      @if (editMode) {
        <naris-button
          color="success"
          class="naris-table-toolbar-action"
          icon="save"
          (clicked)="widgetConfigChanged.emit(newWidgetConfig)"
          [disabled]="newWidgetConfig | compareObj:previousWidgetConfig"
          id="GraphSave"
        >
          {{'table.save_config' | translate}}
        </naris-button>
      }
      @if (!!filterMeta.length) {
        <naris-button
          color="white"
          icon="filter"
          [matTooltip]="'filter' | translate"
          matTooltipPosition="above"
          [active]="activeOption === 'filter'"
          (onMenuOpen)="activeOption = 'filter'"
          (onMenuClose)="activeOption = null"
          [matMenuTriggerFor]="filterMenu"
          id="GraphFilter"
        />
      }
      <mat-menu #filterMenu="matMenu" xPosition="before" class="naris-table-menu">
        <div class="naris-table-filter" (click)="$event.stopPropagation()" tabindex="0">
          <form [formGroup]="filterForm">
            @for (input of filterMeta; track $index) {
              <app-form-input
                [input]="input"
                [formGroup]="filterForm"
                (enterPressed)="handleFilter(filterForm.get(input.id)?.value, input)"
                [isFilter]="true"
              />
            }
          </form>
        </div>
      </mat-menu>
    </div>
  </div>

  @if (!!chartData?.length && chartData?.[0]?.data?.length) {
    <div class="chart-wrapper" [style.height.px]="chartType === 'bar' || chartType === 'histogram' ? chartHeight + 30 : 400" [ngClass]="{'overview-chart': isOverview, 'histogram': chartType === 'histogram'}">
      <div class="chart" [class.histogram]="chartType === 'histogram'">
        <div [style.height]="chartType === 'histogram' ? '100%' : chartHeight + 'px'" [ngStyle]="{width: !!chartWidth? chartWidth + 'px' : '100%'}">
          @if (!!chartType && !!chartData) {
            @if (chartType === 'pie') {
              <canvas
                #graphCanvas
                baseChart
                [data]="$any(chartData)"
                [labels]="chartLabels"
                [options]="$any(setPieSettings)"
                [legend]="chartLegend"
                [type]="'pie'"
                [plugins]="pieChartPlugins"
                (chartClick)="chartClicked($any($event))"
                (chartClicked)="chartClicked($any($event))"
              ></canvas>
            } @else {
              <canvas
                #graphCanvas
                baseChart
                [datasets]="chartData"
                [labels]="chartLabels"
                [options]="chartOptions"
                [plugins]="barChartPlugins"
                [legend]="chartLegend"
                [type]="chartType === 'histogram' || chartType === 'horizontalBar' ? 'bar' : chartType"
                (chartClick)="chartClicked($any($event))"
                (chartClicked)="chartClicked($any($event))"
                [style.height]="chartType === 'histogram' ? '100%' : chartHeight + 'px'"
                [style.width.px]="chartWidth"
              ></canvas>
            }
          }
        </div>
      </div>
    </div>
  } @else {
    <naris-empty-state
      [title]="'graph.no-data' | translate"
      [image]="'empty'"
      [padding]="1"
    />
  }
  <div class="footer">
    <div class="action-bar">
      @if (chartType === 'bar' || chartType === 'horizontalBar') {
        <naris-icon
          [icon]="'icon-bar_chart'"
          [iconClass]="'small'"
          [matMenuTriggerFor]="chartTypeMenu"
          [matTooltip]="'graph.change_chart_type' | translate"
        />
      } @else {
        <naris-icon
          [icon]="'drill_up'"
          [iconClass]="'small'"
          (click)="drillUp()"
          [matTooltip]="'graph.drill_up' | translate"
        />
      }
      <naris-icon
        [icon]="'save'"
        [iconClass]="'small'"
        (click)="save()"
        [matTooltip]="'graph.download_as_image' | translate"
      />
      @if (histogramDataType === 'time') {
        <naris-icon
          [icon]="'edit-calendar'"
          [iconClass]="'small'"
          [matMenuTriggerFor]="histogramTimeUnitMenu"
          [matTooltip]="('graph.histogram_unit' | translate) + ': ' + (histogramTimeUnit | translate)"
        />
      }
      @if (!!contextFilterInput) {
        <form>
          <naris-select
            class="context-filter"
            [placeholder]="'Context'"
            [control]="getFormControl('PrimaryContext')"
            [options]="contextFilterInput.options"
            [multiple]="contextFilterInput.multiple"
            [mandatory]="contextFilterInput.mandatory"
            [id]="'GraphContextFilter'"
          />
        </form>
      }
    </div>
  </div>
</div>

<mat-menu #sortmenu="matMenu" yPosition="above">
  <div class="popup-menu-header">
    {{'graph.sort' | translate}}
  </div>
  <div class="popup-menu-options">
    <button mat-menu-item type="button" (click)="setSort(true, true, false, chartData)" [class.active]="isAlpha && isAsc && !clear">
      <naris-icon [icon]="'icon-sort-alpha-asc'" [iconClass]="'small'" />{{'name' | translate}}
    </button>
    <button mat-menu-item type="button" (click)="setSort(true, false, false, chartData)" [class.active]="isAlpha && !isAsc && !clear">
      <naris-icon [icon]="'icon-sort-alpha-desc'" [iconClass]="'small'" />{{'name' | translate}}
    </button>
    <naris-divider />
    <button mat-menu-item type="button" (click)="setSort(false, true, false, chartData)" [class.active]="!isAlpha && isAsc && !clear">
      <naris-icon [icon]="'icon-sort-numeric-asc'" [iconClass]="'small'" />{{'graph.value' | translate}}
    </button>
    <button mat-menu-item type="button" (click)="setSort(false, false, false, chartData)" [class.active]="!isAlpha && !isAsc && !clear">
      <naris-icon [icon]="'icon-sort-numeric-desc'" [iconClass]="'small'" />{{'graph.value' | translate}}
    </button>
    <naris-divider />
    <button mat-menu-item type="button" (click)="setSort(false, false, true, chartData)">
      <naris-icon [icon]="'delete'" [iconClass]="'small'" />{{'clear' | translate}}
    </button>
  </div>
</mat-menu>

<mat-menu #chartTypeMenu="matMenu" yPosition="above">
  <div class="popup-menu-header">
    {{'graph.chart_type' | translate}}
  </div>
  <div class="popup-menu-options">
    <button mat-menu-item type="button" (click)="changeGraphStyle('horizontalBar')" [class.active]="chartType === 'horizontalBar'">
      <naris-icon [icon]="'icon-insert_chart_outlined_horizontal'" [iconClass]="'small'" />{{'graph.horizontal_bar' | translate}}
    </button>
    <button mat-menu-item type="button" (click)="changeGraphStyle('bar')" [class.active]="chartType === 'bar'">
      <naris-icon [icon]="'icon-insert_chart_outlined'" [iconClass]="'small'" />{{'graph.vertical_bar' | translate}}
    </button>
    <!-- <button mat-menu-item (click)="changeGraphStyle('pie')" [class.active]="chartType === 'pie'">
      <naris-icon [icon]="'icon-pie_chart_outlined'" [iconClass]="'small'"></naris-icon>{{'graph.pie' | translate}}
    </button> -->
  </div>
</mat-menu>

<mat-menu #histogramTimeUnitMenu="matMenu" yPosition="above">
  <div class="popup-menu-header">
    {{'graph.histogram_unit' | translate}}
  </div>
  <div class="popup-menu-options">
    <button mat-menu-item type="button" (click)="changeHistogramUnit('days')" [class.active]="histogramTimeUnit === 'graph.days'">
      <naris-icon [icon]="'dag'" [iconClass]="'small'" />{{'graph.days' | translate}}
    </button>
    <button mat-menu-item type="button" (click)="changeHistogramUnit('weeks')" [class.active]="histogramTimeUnit === 'graph.weeks'">
      <naris-icon [icon]="'week'" [iconClass]="'small'" />{{'graph.weeks' | translate}}
    </button>
    <button mat-menu-item type="button" (click)="changeHistogramUnit('months')" [class.active]="histogramTimeUnit === 'graph.months'">
      <naris-icon [icon]="'maand'" [iconClass]="'small'" />{{'graph.months' | translate}}
    </button>
    <button mat-menu-item type="button" (click)="changeHistogramUnit('years')" [class.active]="histogramTimeUnit === 'graph.years'">
      <naris-icon [icon]="'jaar'" [iconClass]="'small'" />{{'graph.years' | translate}}
    </button>
  </div>
</mat-menu>
