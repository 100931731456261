import { Endpoints as e } from "../endpoint/endpoint.js";
import { isString as n, isFunction as t, merge as o, extend as a, uuid as r } from "../../util/util.js";
import { Connectors as i } from "./connectors.js";
import { LabelOverlay as l } from "../overlay/label-overlay.js";
import { OverlayFactory as s } from "../factory/overlay-factory.js";
import { Components as c } from "../component/component.js";
import { EVENT_ENDPOINT_REPLACED as f } from "../event-constants.js";
import { makeLightweightAnchorFromSpec as u } from "../factory/anchor-record-factory.js";
var d = "__label";
var v = "jtk-default-label";
var p = "overlay";
var y = "labelLocation";
function h(e, n) {
  var t = {
      cssClass: n.cssClass,
      id: d,
      component: e
    },
    o = a(t, n);
  return new l(e.instance, e, o);
}
function m(e, t) {
  var o = null;
  if (n(t)) {
    o = s.get(e.instance, t, e, {});
  } else if (t.type != null && t.options != null) {
    var i = t;
    var l = a({}, i.options);
    o = s.get(e.instance, i.type, e, l);
  } else {
    o = t;
  }
  o.id = o.id || r();
  c.cacheTypeItem(e, p, o, o.id);
  e.overlays[o.id] = o;
  return o;
}
function C(e, n) {
  return e.detachable === false ? false : n != null ? n.connectionsDetachable === true : e.detachable === true;
}
function b(e, n) {
  e.detachable = n === true;
}
function S(e) {
  return e.reattach === true || e.endpoints[0].reattachConnections === true || e.endpoints[1].reattachConnections === true;
}
function g(e, n) {
  e.reattach = n === true;
}
function P(e, t) {
  var o = m(e, t);
  if (e.data && o.type === l.type && !n(t)) {
    var a = e.data,
      r = t.options;
    if (a) {
      var i = r.labelLocationAttribute || y;
      var s = a[i];
      if (s) {
        o.location = s;
      }
    }
  }
  return o;
}
function O(e, n) {
  return e.overlays[n];
}
function T(e) {
  return e.overlays;
}
function I(e, n) {
  var t = O(e, n);
  if (t) {
    t.setVisible(false);
  }
}
function E(e) {
  for (var n = arguments.length, t = new Array(n > 1 ? n - 1 : 0), o = 1; o < n; o++) {
    t[o - 1] = arguments[o];
  }
  t = t || [];
  for (var a in e.overlays) {
    if (t.length === 0 || t.indexOf(a) !== -1) {
      e.overlays[a].setVisible(false);
    }
  }
}
function _(e, n) {
  var t = O(e, n);
  if (t) {
    t.setVisible(true);
  }
}
function j(e) {
  for (var n = arguments.length, t = new Array(n > 1 ? n - 1 : 0), o = 1; o < n; o++) {
    t[o - 1] = arguments[o];
  }
  t = t || [];
  for (var a in e.overlays) {
    if (t.length === 0 || t.indexOf(a) !== -1) {
      e.overlays[a].setVisible(true);
    }
  }
}
function V(e) {
  for (var n in e.overlays) {
    e.instance.destroyOverlay(e.overlays[n]);
  }
  e.overlays = {};
  e.overlayPositions = null;
  e.overlayPlacements = {};
}
function k(e, n, t) {
  var o = e.overlays[n];
  if (o) {
    o.setVisible(false);
    if (!t) {
      e.instance.destroyOverlay(o);
    }
    delete e.overlays[n];
    if (e.overlayPositions) {
      delete e.overlayPositions[n];
    }
    if (e.overlayPlacements) {
      delete e.overlayPlacements[n];
    }
  }
}
function A(e) {
  for (var n = 0, t = arguments.length <= 1 ? 0 : arguments.length - 1; n < t; n++) {
    k(e, n + 1 < 1 || arguments.length <= n + 1 ? undefined : arguments[n + 1]);
  }
}
function H(e) {
  var n = L(e);
  return n != null ? n.getLabel() : null;
}
function L(e) {
  return O(e, d);
}
function w(e, o) {
  var a = L(e);
  if (!a) {
    var r = n(o) || t(o) ? {
      label: o
    } : o;
    a = h(e, r);
    e.overlays[d] = a;
  } else {
    if (n(o) || t(o)) {
      a.setLabel(o);
    } else {
      var i = o;
      if (i.label) {
        a.setLabel(i.label);
      }
      if (i.location) {
        a.location = i.location;
      }
    }
  }
}
var x = "add";
var D = "remove";
var F = "anchors";
var W = "connector";
function q(e, n, t) {
  for (var o in e.overlays) {
    if (n === x) {
      e.instance.addOverlayClass(e.overlays[o], t);
    } else if (n === D) {
      e.instance.removeOverlayClass(e.overlays[o], t);
    }
  }
}
function z(n, t, o, a, r, i, l) {
  var s;
  if (t) {
    n.endpoints[o] = t;
    e.addConnection(t, n);
  } else {
    var c = l || n.endpointSpec || n.endpointsSpec[o] || n.instance.defaults.endpoints[o] || n.instance.defaults.endpoint;
    var f = n.endpointStyles[o] || n.endpointStyle || n.instance.defaults.endpointStyles[o] || n.instance.defaults.endpointStyle;
    if (f.fill == null && n.paintStyle != null) {
      f.fill = n.paintStyle.stroke;
    }
    if (f.outlineStroke == null && n.paintStyle != null) {
      f.outlineStroke = n.paintStyle.outlineStroke;
    }
    if (f.outlineWidth == null && n.paintStyle != null) {
      f.outlineWidth = n.paintStyle.outlineWidth;
    }
    var u = n.endpointHoverStyles[o] || n.endpointHoverStyle || n.endpointHoverStyle || n.instance.defaults.endpointHoverStyles[o] || n.instance.defaults.endpointHoverStyle;
    if (n.hoverPaintStyle != null) {
      if (u == null) {
        u = {};
      }
      if (u.fill == null) {
        u.fill = n.hoverPaintStyle.stroke;
      }
    }
    a = a != null ? a : n.instance.defaults.anchors != null ? n.instance.defaults.anchors[o] : n.instance.defaults.anchor;
    s = n.instance._internal_newEndpoint({
      paintStyle: f,
      hoverPaintStyle: u,
      endpoint: c,
      connections: [n],
      element: r,
      scope: n.scope,
      anchor: a,
      reattachConnections: n.reattach || n.instance.defaults.reattachConnections,
      connectionsDetachable: n.detachable || n.instance.defaults.connectionsDetachable
    });
    n.instance._refreshEndpoint(s);
    if (t == null) {
      s.deleteOnEmpty = true;
    }
    n.endpoints[o] = s;
  }
  return s;
}
var B = {
  addClass: function n(t, o, a) {
    c.addClass(t, o, a);
    q(t, x, o);
    if (a) {
      e.addClass(t.endpoints[0], o);
      e.addClass(t.endpoints[1], o);
      if (t.suspendedEndpoint) {
        e.addClass(t.suspendedEndpoint, o);
      }
    }
    if (t.connector) {
      t.instance.addConnectorClass(t.connector, o);
    }
  },
  removeClass: function n(t, o, a) {
    c.removeClass(t, o);
    q(t, D, o);
    if (a) {
      e.removeClass(t.endpoints[0], o);
      e.removeClass(t.endpoints[1], o);
      if (t.suspendedEndpoint) {
        e.removeClass(t.suspendedEndpoint, o);
      }
    }
    if (t.connector) {
      t.instance.removeConnectorClass(t.connector, o);
    }
  },
  setAbsoluteOverlayPosition: function e(n, t, o) {
    n.overlayPositions[t.id] = o;
  },
  getAbsoluteOverlayPosition: function e(n, t) {
    return n.overlayPositions ? n.overlayPositions[t.id] : null;
  },
  setPreparedConnector: function e(n, t, o, a, r) {
    if (n.connector !== t) {
      var i,
        l = "";
      if (n.connector != null) {
        i = n.connector;
        l = n.instance.getConnectorClass(n.connector);
        n.instance.destroyConnector(n);
      }
      n.connector = t;
      if (r) {
        c.cacheTypeItem(n, W, t, r);
      }
      B.addClass(n, l);
      if (i != null) {
        var s = T(n);
        for (var f in s) {
          n.instance.reattachOverlay(s[f], n);
        }
      }
      if (!o) {
        n.instance._paintConnection(n);
      }
    }
  },
  makeEndpoint: function e(n, t, o, a, r, i) {
    a = a || n.instance.getId(o);
    return z(n, i, t ? 0 : 1, r, o);
  },
  replaceEndpoint: function n(t, o, a) {
    var r = t.endpoints[o],
      i = r.elementId,
      l = t.instance.getEndpoints(r.element),
      s = l.indexOf(r),
      c = z(t, null, o, null, r.element, i, a);
    t.endpoints[o] = c;
    l.splice(s, 1, c);
    e.detachFromConnection(r, t);
    t.instance.deleteEndpoint(r);
    t.instance.fire(f, {
      previous: r,
      current: c
    });
  },
  destroy: function e(n) {
    c.destroy(n);
    for (var t in n.overlays) {
      n.instance.destroyOverlay(n.overlays[t]);
    }
    n.overlays = {};
    n.overlayPositions = {};
    n.endpoints = null;
    n.endpointStyles = null;
    n.source = null;
    n.target = null;
    n.instance.destroyConnector(n);
    n.connector = null;
    n.deleted = true;
  },
  applyType: function e(n, t, o) {
    var a = null;
    if (t.connector != null) {
      a = c.getCachedTypeItem(n, W, o.connector);
      if (a == null) {
        a = B._prepareConnector(n, t.connector, o.connector);
        c.cacheTypeItem(n, W, a, o.connector);
      }
      B.setPreparedConnector(n, a);
    }
    c.applyType(n, t, o);
    if (t.overlays) {
      var r = {},
        i;
      for (i in t.overlays) {
        var l = t.overlays[i].options;
        var s = n.overlays[l.id];
        if (s) {
          s.updateFrom(l);
          r[l.id] = true;
          n.instance.reattachOverlay(s, n);
        } else {
          var f = c.getCachedTypeItem(n, p, l.id);
          if (f != null) {
            n.instance.reattachOverlay(f, n);
            f.setVisible(true);
            f.updateFrom(l);
            n.overlays[f.id] = f;
          } else {
            f = P(n, t.overlays[i]);
          }
          r[f.id] = true;
        }
      }
      for (i in n.overlays) {
        if (n.overlays[i].ignoreTypes !== true) {
          if (r[n.overlays[i].id] == null) {
            k(n, n.overlays[i].id, true);
          }
        }
      }
    }
    if (t.detachable != null) {
      n.detachable = t.detachable === true;
    }
    if (t.reattach != null) {
      n.reattach = t.reattach === true;
    }
    if (t.scope) {
      n.scope = t.scope;
    }
    var d = null;
    if (t.anchor) {
      d = c.getCachedTypeItem(n, F, o.anchor);
      if (d == null) {
        d = [u(t.anchor), u(t.anchor)];
        c.cacheTypeItem(n, F, d, o.anchor);
      }
    } else if (t.anchors) {
      d = c.getCachedTypeItem(n, F, o.anchors);
      if (d == null) {
        d = [u(t.anchors[0]), u(t.anchors[1])];
        c.cacheTypeItem(n, F, d, o.anchors);
      }
    }
    if (d != null) {
      n.instance.router.setConnectionAnchors(n, d);
      if (n.instance.router.isDynamicAnchor(n.endpoints[1])) {
        n.instance.repaint(n.endpoints[1].element);
      }
    }
    n.instance.applyConnectorType(n.connector, t);
  },
  setVisible: function e(n, t) {
    c.setVisible(n, t);
    if (t) {
      j(n);
    } else {
      E(n);
    }
    if (n.connector) {
      n.instance.setConnectorVisible(n.connector, t);
    }
    n.instance._paintConnection(n);
  },
  _prepareConnector: function e(t, a, r) {
    var l = {
        cssClass: t.params.cssClass,
        hoverClass: t.params.hoverClass,
        "pointer-events": t.params["pointer-events"]
      },
      s;
    if (n(a)) {
      s = i.get(t, a, l);
    } else {
      var c = a;
      s = i.get(t, c.type, o(c.options || {}, l));
    }
    if (r != null) {
      s.typeId = r;
    }
    return s;
  },
  _setConnector: function e(n, t, o, a, r) {
    var i = B._prepareConnector(n, t, r);
    B.setPreparedConnector(n, i, o, a, r);
  }
};
export { x as ACTION_ADD, D as ACTION_REMOVE, B as Connections, y as LOCATION_ATTRIBUTE, F as TYPE_ITEM_ANCHORS, W as TYPE_ITEM_CONNECTOR, p as TYPE_ITEM_OVERLAY, P as _addOverlay, H as _getLabel, L as _getLabelOverlay, O as _getOverlay, T as _getOverlays, I as _hideOverlay, E as _hideOverlays, v as _internalLabelOverlayClass, d as _internalLabelOverlayId, C as _isConnectionDetachable, S as _isConnectionReattach, V as _removeAllOverlays, k as _removeOverlay, A as _removeOverlays, b as _setConnectionDetachable, w as _setConnectionLabel, g as _setConnectionReattach, _ as _showOverlay, j as _showOverlays };