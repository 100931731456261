import { lineRectangleIntersection as a } from "../util/geom.js";
import { valueInRange as e, _findNextVerticalSegment as r, _findNextHorizontalSegment as t, _findPreviousVerticalSegment as x, _findPreviousHorizontalSegment as y } from "./utils.js";
import { AXIS_ORIENTATION_HORIZONTAL as n, AXIS_ORIENTATION_VERTICAL as s } from "./definitions.js";
function o(x, y, o) {
  var f = x[1];
  var i = false;
  var u = [{
    x: f.ax1,
    y: f.ay1
  }, {
    x: f.ax2,
    y: f.ay2
  }];
  if (f.o === n) {
    var v = e(f.ax2, x[0].ax1, x[0].ax2) || a(u, y).length > 0;
    if (v) {
      var l = r(f, 1, x);
      if (l != null) {
        var c = (l.segment.ay1 + l.segment.ay2) / 2;
        i = true;
        if (c < f.ay1) {
          var h = Math.round((y.y - o) * 100) / 100;
          var g = f.ay1;
          l.others.forEach(function (a) {
            a.ay1 = h;
            a.ay2 = h;
          });
          x.splice(1, 0, {
            ax1: f.ax1,
            ax2: f.ax1,
            ay1: g,
            ay2: h,
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            o: s
          });
          l.segment.ay1 = h;
        } else {
          var m = Math.round((y.y2 + o) * 100) / 100;
          var d = f.ay1;
          l.others.forEach(function (a) {
            a.ay1 = m;
            a.ay2 = m;
          });
          x.splice(1, 0, {
            ax1: f.ax1,
            ax2: f.ax1,
            ay1: d,
            ay2: m,
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            o: s
          });
          l.segment.ay1 = m;
        }
      }
    }
  } else if (f.o === s) {
    var p = e(f.ay2, x[0].ay1, x[0].ay2) || a(u, y).length > 0;
    if (p) {
      var E = t(f, 1, x);
      if (E != null) {
        var M = (E.segment.ax1 + E.segment.ax2) / 2;
        i = true;
        if (M < f.ax1) {
          var j = Math.round((y.x - o) * 100) / 100;
          var A = f.ax1;
          E.others.forEach(function (a) {
            a.ax1 = j;
            a.ax2 = j;
          });
          x.splice(1, 0, {
            ay1: f.ay1,
            ay2: f.ay1,
            ax1: A,
            ax2: j,
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            o: n
          });
          E.segment.ax1 = j;
        } else {
          var b = Math.round((y.x2 + o) * 100) / 100;
          var S = f.ax1;
          E.others.forEach(function (a) {
            a.ax1 = b;
            a.ax2 = b;
          });
          x.splice(1, 0, {
            ay1: f.ay1,
            ay2: f.ay1,
            ax1: S,
            ax2: b,
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            o: n
          });
          E.segment.ax1 = b;
        }
      }
    }
  }
  return i;
}
function f(r, t, o) {
  var f = r.length - 2;
  var i = r[f];
  var u = false;
  var v = [{
    x: i.ax1,
    y: i.ay1
  }, {
    x: i.ax2,
    y: i.ay2
  }];
  var l = r[r.length - 1];
  r[0];
  if (i.o === n) {
    var c = e(i.ax1, l.ax1, l.ax2) || a(v, t).length > 0;
    if (c) {
      var h = x(i, f, r);
      if (h != null) {
        var g = (h.segment.ay1 + h.segment.ay2) / 2;
        u = true;
        if (g < i.ay1) {
          var m = Math.round((t.y - o) * 100) / 100;
          var d = i.ay1;
          h.others.forEach(function (a) {
            a.ay1 = m;
            a.ay2 = m;
          });
          r.splice(f + 1, 0, {
            ax1: i.ax2,
            ax2: i.ax2,
            ay1: m,
            ay2: d,
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            o: s
          });
          h.segment.ay2 = m;
        } else {
          var p = Math.round((t.y2 + o) * 100) / 100;
          var E = i.ay1;
          h.others.forEach(function (a) {
            a.ay1 = p;
            a.ay2 = p;
          });
          r.splice(f + 1, 0, {
            ax1: i.ax2,
            ax2: i.ax2,
            ay1: p,
            ay2: E,
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            o: s
          });
          h.segment.ay2 = p;
        }
      }
    }
  } else if (i.o === s) {
    var M = e(i.ay1, l.ay1, l.ay2) || a(v, t).length > 0;
    if (M) {
      var j = y(i, f, r);
      if (j != null) {
        var A = (j.segment.ax1 + j.segment.ax2) / 2;
        u = true;
        if (A < i.ax1) {
          var b = Math.round((t.x - o) * 100) / 100;
          var S = i.ax1;
          j.others.forEach(function (a) {
            a.ax1 = b;
            a.ax2 = b;
          });
          r.splice(f + 1, 0, {
            ay1: i.ay2,
            ay2: i.ay2,
            ax1: b,
            ax2: S,
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            o: n
          });
          j.segment.ax2 = b;
        } else {
          var k = Math.round((t.x2 + o) * 100) / 100;
          var q = i.ax1;
          j.others.forEach(function (a) {
            a.ax1 = k;
            a.ax2 = k;
          });
          r.splice(f + 1, 0, {
            ay1: i.ay2,
            ay2: i.ay2,
            ax1: k,
            ax2: q,
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            o: n
          });
          j.segment.ax2 = k;
        }
      }
    }
  }
  return u;
}
function i(a, e, r, t, x) {
  if (a.vertexAvoidance === false) {
    return {
      segments: e,
      sourceAdjusted: false,
      targetAdjusted: false
    };
  } else {
    var y, n;
    if (x) {
      y = f(e, t, a.targetStub);
      n = o(e, r, a.sourceStub);
    } else {
      n = o(e, r, a.sourceStub);
      y = f(e, t, a.targetStub);
    }
    return {
      segments: e,
      sourceAdjusted: n,
      targetAdjusted: y
    };
  }
}
export { o as sourceVertexAvoidance, f as targetVertexAvoidance, i as vertexAvoidance };