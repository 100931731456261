import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { IconComponent } from '../../../../shared/elements/icon/icon.component';
import type { ICaseListAction } from '@core/models';

@Component({
  selector: 'naris-audit-execution-sample-action',
  templateUrl: './audit-execution-sample-action.component.html',
  styleUrls: ['./audit-execution-sample-action.component.scss'],
  standalone: true,
  imports: [IconComponent, MatTooltip, MatMenuTrigger, MatMenu, MatMenuItem, MatProgressSpinner]
})
export class AuditExecutionSampleActionComponent {

  @Input() public actions: ICaseListAction[];

  @Input() public description: string;

  @Input() public reverse = true;

  @Output() public readonly actionClicked = new EventEmitter<ICaseListAction>();

  public executeAction(action: ICaseListAction) {
    this.actionClicked.emit(action);
  }
}
