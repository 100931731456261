<mat-tab-group 
  preserveContent  
  (selectedIndexChange)="tabClicked($event)"
>
  @for (tab of modules; track $index) {
    <mat-tab [label]="tab.name | translate">
      <ng-template matTabContent>
        @for (panel of tab.panels; track $index) {
          <naris-card>
            <naris-panel [panel]="panel" />
          </naris-card>
        }
      </ng-template>
    </mat-tab>
  }
</mat-tab-group>