<naris-card>
  <naris-toolbar class="header">
    <naris-icon
      (click)="headerExpanded = !headerExpanded"
      icon="keyboard_arrow_right"
      class="mr-4"
      [class.open]="headerExpanded"
    />
    <h3 class="mr-8">{{'history' | translate}} {{name}}</h3>
  </naris-toolbar>
  @if (headerExpanded) {
    <div [@inOutAnimation]>
      @for (item of data; track $index) {
        <div class="container ml-2 mr-5 pb-5">
          <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ item: item }" />
        </div>
      }
    </div>
  }
</naris-card>

<ng-template #recursiveListTmpl let-item="item">
  @for (property of item | keyvalue: originalOrder; track $index) {
    <div class="node">
      <div class="property-parent" [class.expandable]="isArray(property.value) || isObject(property.value)">
        @if (isArray(property.value) || isObject(property.value)) {
          <naris-icon
            (click)="$any(property).expanded = !$any(property).expanded"
            icon="keyboard_arrow_right"
            class="item-toggle mr-4"
            [class.open]="$any(property).expanded"
          />
        }
        <span class="key mr-2">{{property.key}}</span>
        @if (!isArray(property.value) && !isObject(property.value)) {
          <span>{{property.value}}</span>
        }
      </div>
      @if (isArray(property.value) && $any(property).expanded) {
        <div>
          @for (subProperty of property.value; track $index) {
            <div class="child">
              <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ item: subProperty }" />
            </div>
          }
        </div>
      }
      @if (isObject(property.value) && $any(property).expanded) {
        <div>
          <div class="child">
            <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ item: property.value }" />
          </div>
        </div>
      }
    </div>
  }
</ng-template>
