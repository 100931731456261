import { AfterViewInit, Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { ANCHOR_POSITIONS } from '@core/constants/process-constants';
import { FormService, BeinformedService, TabService } from '@core/services';
import { ProcessManagerService } from '@core/services/process-manager.service';
import { BaseNodeComponent } from '@jsplumbtoolkit/browser-ui-angular';
import { MatTooltip } from '@angular/material/tooltip';
import { LowerCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../../../elements/icon/icon.component';
import { ButtonComponent } from '../../../../elements/button/button.component';

@Component({
  selector: 'app-process-node',
  templateUrl: './process-node.component.html',
  styleUrls: ['./process-node.component.scss'],
  standalone: true,
  imports: [ButtonComponent, MatTooltip, IconComponent, LowerCasePipe, TranslateModule]
})
export class ProcessNodeComponent extends BaseNodeComponent implements AfterViewInit {

  @ViewChildren('anchor') public anchors: QueryList<ElementRef>;

  public readOnly: boolean;
  public anchorPositions = ANCHOR_POSITIONS;
  public icon: string;
  public children: any;

  public showAddProcessStep = false;
  public showAddCondition = false;
  public showAddEndEvent = false;
  public showAddProcess = false;
  public showDatastore = false;
  public showDocument = false;
  public showIntermediate = false;
  public showAddRelation = false;

  private anchorTimeout: NodeJS.Timeout;
  private anchorListTimeout: NodeJS.Timeout;

  constructor(
    private readonly processManagerService: ProcessManagerService,
    private readonly beinformedService: BeinformedService,
    private readonly router: Router,
    private readonly formService: FormService,
    private readonly tabService: TabService
  ) {
    super();
    this.readOnly = this.tabService.activeTab.href.endsWith('/viewer');
  }

  public ngAfterViewInit(): void {
    if (!!this.obj.actions) {
      this.showAddProcessStep = !!this.obj.actions.find((action: any) => action.name === 'add-process-step');
      this.showAddCondition = !!this.obj.actions.find((action: any) => action.name === 'add-condition');
      this.showAddEndEvent = !!this.obj.actions.find((action: any) => action.name === 'add-end-event');
      this.showDatastore = !!this.obj.actions.find((action: any) => action.name === 'add-datastore');
      this.showDocument = !!this.obj.actions.find((action: any) => action.name === 'add-document');
      this.showIntermediate = !!this.obj.actions.find((action: any) => action.name === 'add-intermediate');
      this.showAddRelation = !!this.obj.actions.find((action: any) => action.name === 'add-relation');
      if (this.obj.type === 'process-condition') this.showAddProcess = !!this.obj.actions.find((action: any) => action.name === 'add-process-object');
    }
  }

  public hideAddAnchors() {
    this.anchorTimeout = setTimeout(() => {
      this.toggleAnchors('hide');
    }, 250);
  }

  public toggleAnchors(state: string) {
    this.anchors.forEach(anchor => {
      const el = anchor.nativeElement as HTMLElement;
      if (state === 'show') {
        el.style.visibility = 'visible';
        el.style.opacity = '1';
      }
      if (state === 'hide') {
        el.style.visibility = 'hidden';
        el.style.opacity = '0';
      }
    });
  }

  public showAddList(element: HTMLDivElement) {
    clearTimeout(this.anchorTimeout);
    this.toggleAnchorList(element, 'show');
  }

  public hideAddList(element: HTMLDivElement) {
    this.anchorListTimeout = setTimeout(() => {
      this.toggleAnchorList(element, 'hide');
    }, 250);
  }

  public toggleAnchorList(element: HTMLDivElement, state: string) {
    const size = element.classList.contains('right') || element.classList.contains('left') ? element.offsetHeight : element.offsetWidth;
    if (state === 'show') {
      element.style.visibility = 'visible';
      element.style.opacity = '1';
      if (element.classList.contains('right') || element.classList.contains('left')) element.style.top = `calc(50% - ${size / 2}px)`;
      else element.style.left = `calc(50% - ${size / 2}px)`;
    }
    if (state === 'hide') {
      element.style.visibility = 'hidden';
      element.style.opacity = '0';
    }
  }

  public openConnectedObjects(child: any) {
    const tab = (child['StructureType'] as string) + 's';
    this.tabService.activeTabName = tab;
    this.formService.open(this.obj.selfHref, 'detail').subscribe();
  }

  public clearAddListTimeout() {
    clearTimeout(this.anchorTimeout);
    clearTimeout(this.anchorListTimeout);
  }

  public addNode(type: string, direction: string) {
    this.processManagerService.addNode$.next({type: type, direction: direction, id: this.obj.id});
  }

  public openSubProcess() {
    this.processManagerService.openSubProcess$.next(this.obj);
  }
}
