<div class="wrapper" [ngClass]="{'overview-heatmap': isOverview}">
  @if (!isHeatmapSet) {
    <div>
      <naris-empty-state
        [title]="'heatmap.no_category_selected' | translate"
        [text]="'heatmap.no_category_selected_text' | translate"
        [image]="'empty'"
      />
    </div>
  } @else {
    <div class="flexie">
      <div class="consequence-classnames">
        <div class="flex-1"></div>
        <div class="label-y">
          <div class="label-y__wrapper">
            <span class="label-y__wrapper__content">
              {{ 'heatmap.consequence' | translate}}
              <div>
                <div class="long-arrow-right"></div>
              </div>
            </span>
          </div>
        </div>
        <div class="flex-1"></div>
      </div>
      <div>
        <div class="canvas-wrapper">
          <canvas #heatmapCanvas class="heatmap-canvas"></canvas>
          <div class="heatmap-counts">

            @for (yValue of heatmapValues; let y = $index; track y) {
              <div class="y-axis">
                @for (xValue of yValue; let x = $index; track x) {
                  <div 
                    tabindex="0"
                    class="x-axis"
                    (click)="setSelectedNode(x + 1, heatmapValues.length - y)"
                  >
                    <div class="count" [class.no-click]="getCount(x + 1, heatmapValues.length - y) === 0" [style.width.px]="heatmapDimensions[y].size[x]" [style.height.px]="heatmapDimensions[y].size[x]">
                      {{getCount(x + 1, heatmapValues.length - y)}}
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>
        <div>
          <div class="likelihood-classnames">
            <div class="likelihood-classnames__item">
              <div>{{ 'heatmap.likelihood' | translate }}</div>
              <div><div class="long-arrow-right"></div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
</div>