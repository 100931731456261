import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'naris-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss'],
  standalone: true,
  imports: [NgStyle]
})
export class ScoreComponent implements OnInit {

  @Input()
  public width = 250;

  @Input()
  public value = 0;

  @Input()
  public min = 0;

  @Input()
  public max = 5;

  @Input()
  public step = 1;

  @Input()
  public initValue = 4;

  @Input()
  public normValue = 3;

  @Input()
  public showDirection = true;

  @Input()
  public flipColors = false;

  @Input()
  public valueItems: string[];

  @Input()
  public id: string;

  public numOfSteps = 0;
  public steps: number[] = [];
  public stepWidth = 0;

  private mouseDown = false;

  public dragPosition = 0;
  public offsetWidth = 0;

  public indicatorWidth = 150;
  public indicatorPosition = 16;

  public _normLeft = 2;
  get normLeft(): number {
    return this._normLeft;
  }
  set normLeft(value) {
    this._normLeft = value === 0 ? value + 3 : value - 2;
  }

  private _initLeft = 3;

  get initLeft(): number {
    return this._initLeft;
  }

  set initLeft(value) {
    this._initLeft = value === 0 ? value + 3 : value - 5;
  }

  @ViewChild('slider') public slider: ElementRef;
  @ViewChild('dragitem') public dragItem: ElementRef;

  public ngOnInit(): void {
    this.numOfSteps = (this.max - this.min) / this.step;
    this.stepWidth = 100 / this.numOfSteps;
    this.steps = Array(this.numOfSteps).fill(null).map((x, i) => (i + 1) * this.step);
    setTimeout(() => {
      this.offsetWidth = this.slider.nativeElement.offsetWidth;

      this.normLeft = this.setPosition(this.normValue);
      this.initLeft = this.setPosition(this.initValue);
      this.setdragHandle(this.setPosition(this.value), true);
    });
  }

  public updateSlider(event: MouseEvent) {
    event.stopPropagation();
    return;
    // this.setdragHandle(event.screenX);
  }

  private setdragHandle(mousePos: number, override = false) {
    if (this.mouseDown || override) {
      this.dragPosition = mousePos >= this.offsetWidth ? this.dragPosition : mousePos - 8;
      this.setIndicator(mousePos);
      this.setValue();
    }
  }

  private setValue() {
    const temp = 100 * (this.dragPosition === 0 ? 1 : this.dragPosition + 7) / this.offsetWidth;
    this.value = Math.round(temp * this.max / 100);
  }

  private setIndicator(mousePos = 0) {
    if (this.dragPosition < this.initLeft - 2) {
      this.indicatorPosition = mousePos >= this.offsetWidth ? this.offsetWidth + 16 : mousePos + 8;
      this.indicatorWidth = this.initLeft - this.dragPosition - 3;
    } else if (this.dragPosition > this.initLeft - 2) {
      this.indicatorPosition = this.initLeft + 13;
      this.indicatorWidth = this.dragPosition - this.initLeft;
    }
  }

  public setMouseDown() {
    this.mouseDown = true;
  }

  public setMouseUp() {
    if (this.mouseDown) this.mouseDown = false;
  }

  public setPosition(value: number): number {
    const range = this.max - this.min;
    const relPosition = value - this.min;
    const tempPosition = relPosition * this.offsetWidth / range;
    return tempPosition;
  }

  public reset() {
    this.setdragHandle(this.initLeft + 5, true);
  }

  public isNegative(correction = 0) {
    if (this.flipColors) return this.dragPosition > this.initLeft - correction;
    return this.dragPosition < this.initLeft - correction;
  }

  public isPositive(correction = 0) {
    if (this.flipColors) return this.dragPosition < this.initLeft - correction;
    return this.dragPosition > this.initLeft - correction;
  }
}
