import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { SnackbarService, DialogService, AuthService, HttpService, LanguageService } from '@core/services';
import { AUTH_TIMEOUT, SESSION_TIMEOUT, TIME_OUT, TOKEN_INVALID, BEINFORMED, EXPIRE_TIME, BEINFORMED_SSO } from '@core/constants';
import { EAuthContext } from '@core/enums';
import { WORDPRES_BASE_URL } from '@core/constants/core-constants';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  private userInactive: NodeJS.Timeout | void;

  constructor(
    private readonly snackbar: SnackbarService,
    private readonly dialogService: DialogService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly httpService: HttpService,
    private readonly languageService: LanguageService
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith('restServices/uiundefined')) {
      this.authService.logout().pipe(
        finalize(() => {
          void this.router.navigate(['/login']);
          this.snackbar.open({text: 'timeout.expired'});
        })
      ).subscribe();
      return EMPTY;
    }
    const currentLanguage = this.languageService.getAppLang();
    const headersReq = req.url.startsWith(WORDPRES_BASE_URL) ? req : req.clone({
      url: req.url.includes(BEINFORMED) && this.authService.authContext === EAuthContext.SSO ? req.url.replace(BEINFORMED, BEINFORMED_SSO) : req.url,
      setHeaders: {
        'Accept': 'application/json',
        'Accept-Language': currentLanguage,
        'X-Requested-With': 'XMLHttpRequest',
        'Referrer-Policy': 'strict-origin-when-cross-origin'
      }
    });
    return next.handle(headersReq).pipe(
      tap({
        next: () => this.handleSuccess(req),
        error: err => this.handleError(err)
      })
    );
  }

  private handleSuccess(req: HttpRequest<any>) {
    if (!req.url.endsWith('information-notifications') && !req.url.endsWith('check-states')) {
      this.sessionTimeout();
      if (this.authService.authIsValid()) this.authService.auth = Date.now() + AUTH_TIMEOUT;
    }
  }

  private handleError(err: HttpErrorResponse) {
    const errConfig = {text: err.error?.error?.id || err.message, type: 'error'};
    if (err.error?.error?.message) errConfig.text = err.error?.error?.message || err.message; // 500 ERROR from BE
    if (err.status === 404 && err.url?.endsWith('userdata')) void this.router.navigate(['error'], {state: {title: err.error?.error?.id?.split('.')?.[1] || 'Unknown', message: err.message}});
    else if (this.authService.authIsValid() && err.url?.includes('restServices')) {
      if (err.status !== 400) this.snackbar.open(errConfig);
    } else if (err.url?.includes('loginerror') || err.url?.includes('j_security_check') || err.status !== 400 && err.error?.error?.properties?.message !== TOKEN_INVALID) this.snackbar.open(errConfig);
  }

  private sessionTimeout() {
    if (!!this.userInactive) clearTimeout(this.userInactive);
    if (this.authService.authIsValid()) this.userInactive = setTimeout(() => {
      this.dialogService.open({
        type: 'alert',
        title: 'timeout.session_expiring',
        closable: false,
        text: 'timeout.description',
        cancelLabel: 'timeout.log_out',
        confirmLabel: 'timeout.stay_logged_in',
        timer: EXPIRE_TIME
      }).subscribe((stay: boolean | string) => {
        if (typeof stay === 'boolean' && stay) this.httpService.get('/', false).subscribe(() => this.authService.auth = Date.now() + AUTH_TIMEOUT);
        else this.authService.logout().pipe(
          finalize(() => {
            void this.router.navigate(['/login']);
            if (stay === TIME_OUT) this.snackbar.open({text: 'timeout.logged_out_message', duration: 0});
          })
        ).subscribe();
      });
    }, SESSION_TIMEOUT);
  }
}
