
<input type="file" class="file-input" (change)="onFileSelected($event)" (click)="fileUpload.value = ''" #fileUpload [multiple]="acceptMultipleFiles">
<div 
  class="file-upload"
  #fileUploadContainer
  (dragenter)="preventDefaults($event); addClass(fileUploadContainer)"
  (dragover)="preventDefaults($event); addClass(fileUploadContainer)"
  (dragleave)="preventDefaults($event); removeClass(fileUploadContainer)"
  (drop)="preventDefaults($event); removeClass(fileUploadContainer); handleDrop($event)"
  [attr.data-test-id]="'file-upload__' + id"
>
  @if ((!acceptMultipleFiles && !files.length) || acceptMultipleFiles) {
    <div class="file-upload__button-wrapper" (click)="fileUpload.click()" tabindex="0">
      <div>
        <span class="file-upload__button-wrapper__drop-text">{{"dropzone.drop_files" | translate}} </span>
        <span>{{"dropzone.or" | translate}} </span>
      </div>
    
      <naris-button class="file-upload__btn" color="primary" icon="add" id="FileUploadClickToSelect">
        <span>{{"dropzone.click_to_select" | translate}}</span>
      </naris-button>
    </div>
  }

  @if (!!files.length) {
    <div [@whooshAnimation]="files.length" class="file-upload__file-list">
      @for(file of files; track file.id) {
        <div class="file-upload__file" [class.error]="!!file.error">
          <span>
            {{file.name}}  - {{file.readableSize}}
            @if (!!file.error) {- <span class="file-upload__file__error-msg">{{file.error | translate}}</span>}
          </span>
          <naris-icon
            icon="delete"
            iconClass="small"
            class="file-upload__file__btn"
            (click)="removeFile(file.id)"
          />
        </div>
      }
    </div>
  }



  @if (uploading) {
    <div class="bouncer-wrapper">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  }
</div>
<div class="file-upload__msg__wrapper">
  <div class="file-upload__msg" [class.error]="notAllowedError && !!allowedExtensions?.length">
    <span>{{'dropzone.allowedExtensions' | translate:({ extensions: allowedExtensions?.join(', ') })}}</span>
  </div>

  <div class="file-upload__msg" [class.error]="sizeExceededError">
    <span>{{'dropzone.max-file-size' | translate:({ maxFileSize: maxFileSizeReadable })}}</span>
  </div>
</div>