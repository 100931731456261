import { createClass as e, inherits as t, createSuper as s, classCallCheck as i, defineProperty as n } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { log as r, remove as u, addToDictionary as l, uuid as h } from "./util.js";
var a = function () {
  function t() {
    i(this, t);
    n(this, "_listeners", {});
    n(this, "eventsSuspended", false);
    n(this, "tick", false);
    n(this, "eventsToDieOn", {
      ready: true
    });
    n(this, "queue", []);
  }
  e(t, [{
    key: "fire",
    value: function e(t, s, i) {
      var n = null;
      if (!this.tick) {
        this.tick = true;
        if (!this.eventsSuspended && this._listeners[t]) {
          var u = this._listeners[t].length,
            l = 0,
            h = false;
          if (!this.shouldFireEvent || this.shouldFireEvent(t, s, i)) {
            while (!h && l < u && n !== false) {
              if (this.eventsToDieOn[t]) {
                this._listeners[t][l](s, i);
              } else {
                try {
                  n = this._listeners[t][l](s, i);
                } catch (e) {
                  r("jsPlumb: fire failed for event " + t + " : " + (e.message || e));
                }
              }
              l++;
              if (this._listeners == null || this._listeners[t] == null) {
                h = true;
              }
            }
          }
        }
        this.tick = false;
        this._drain();
      } else {
        this.queue.unshift(arguments);
      }
      return n;
    }
  }, {
    key: "_drain",
    value: function e() {
      var t = this.queue.pop();
      if (t) {
        this.fire.apply(this, t);
      }
    }
  }, {
    key: "unbind",
    value: function e(t, s) {
      if (arguments.length === 0) {
        this._listeners = {};
      } else if (arguments.length === 1) {
        if (typeof t === "string") {
          delete this._listeners[t];
        } else if (t.__jsPlumb) {
          var i;
          for (var n in t.__jsPlumb) {
            i = t.__jsPlumb[n];
            u(this._listeners[i] || [], t);
          }
        }
      } else if (arguments.length === 2) {
        u(this._listeners[t] || [], s);
      }
      return this;
    }
  }, {
    key: "getListener",
    value: function e(t) {
      return this._listeners[t] || [];
    }
  }, {
    key: "isSuspendEvents",
    value: function e() {
      return this.eventsSuspended;
    }
  }, {
    key: "setSuspendEvents",
    value: function e(t) {
      this.eventsSuspended = t;
    }
  }, {
    key: "bind",
    value: function e(t, s, i) {
      var n = this;
      var r = function e(t) {
        l(n._listeners, t, s, i);
        s.__jsPlumb = s.__jsPlumb || {};
        s.__jsPlumb[h()] = t;
      };
      r(t);
      return this;
    }
  }, {
    key: "silently",
    value: function e(t) {
      this.setSuspendEvents(true);
      try {
        t();
      } catch (e) {
        r("Cannot execute silent function " + e);
      }
      this.setSuspendEvents(false);
    }
  }]);
  return t;
}();
var f = function (n) {
  t(u, n);
  var r = s(u);
  function u() {
    i(this, u);
    return r.apply(this, arguments);
  }
  e(u, [{
    key: "shouldFireEvent",
    value: function e(t, s, i) {
      return true;
    }
  }]);
  return u;
}(a);
export { a as EventGenerator, f as OptimisticEventGenerator };