import { createClass as e, classCallCheck as i } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { isUpdateAction as n, isMoveAction as t } from "./undo-redo.js";
import { EdgeAction as s } from "./edge-action.js";
var o = function () {
  function o(e) {
    i(this, o);
    this.actions = e;
    if (this.actions == null) {
      this.actions = [];
    }
  }
  e(o, [{
    key: "addAction",
    value: function e(i, n) {
      if (n) {
        this.actions.unshift(i);
      } else {
        this.actions.push(i);
      }
    }
  }, {
    key: "undo",
    value: function e() {
      this.actions.slice().reverse().forEach(function (e) {
        return e.undo();
      });
    }
  }, {
    key: "redo",
    value: function e() {
      this.actions.forEach(function (e) {
        return e.redo();
      });
    }
  }, {
    key: "edgeChange",
    value: function e(i, n) {
      this.actions.forEach(function (e) {
        if (e instanceof s && e.edgeId === i) {
          e.edgeChange(n);
        }
      });
    }
  }, {
    key: "hasDeltas",
    value: function e() {
      for (var i = 0; i < this.actions.length; i++) {
        if (this.actions[i].hasDeltas()) {
          return true;
        }
      }
      return false;
    }
  }, {
    key: "compress",
    value: function e() {
      var i = new o();
      var s = {};
      var a = {};
      for (var r = 0; r < this.actions.length; r++) {
        var c = this.actions[r];
        if (n(c)) {
          if (s[c.obj.id] != null) {
            Object.assign(s[c.obj.id].newData, c.newData);
          } else {
            s[c.obj.id] = c;
            i.addAction(c);
          }
        } else if (t(c)) {
          if (a[c.obj.id] != null) {
            Object.assign(a[c.obj.id].pos, c.pos);
          } else {
            a[c.obj.id] = c;
            i.addAction(c);
          }
        } else {
          i.addAction(c);
        }
      }
      return i;
    }
  }]);
  return o;
}();
export { o as CompoundAction };