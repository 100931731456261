import { defineProperty as n } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { NEGATIVE as r, AXIS_ORIENTATION_HORIZONTAL as t, AXIS_ORIENTATION_VERTICAL as a } from "./definitions.js";
function e(n, r, t, a) {
  var e = Math.max(r, t),
    u = Math.min(r, t);
  return a ? u <= n && e >= n : u < n && e > n;
}
function u(n, r, t) {
  return f(r, "h", t, 1);
}
function l(n, r, t) {
  return f(r, "h", t, -1);
}
function i(n, r, t) {
  return f(r, "v", t, 1);
}
function o(n, r, t) {
  return f(r, "v", t, -1);
}
function f(n, r, t, a) {
  var e = r === "h" ? "v" : "h";
  var u = [];
  var l = n;
  var i = a === -1 ? -1 : t.length;
  while (l !== i) {
    var o = t[l];
    if (o.o === e) {
      u.push(o);
      l += a;
    } else {
      return {
        segment: o,
        others: u,
        index: l
      };
    }
  }
  return null;
}
function s(n) {
  return Math.sqrt(Math.pow(n.ax2 - n.ax1, 2) + Math.pow(n.ay2 - n.ay1, 2));
}
function x(n, t, a, e) {
  var u = null,
    l;
  if (e != null) {
    l = n.indexOf(e);
    if (l === -1) {
      return null;
    } else {
      l += t;
    }
  } else {
    l = t === r ? n.length - 2 : 1;
  }
  for (var i = l; i > 0 && i < n.length - 1; i += t) {
    if (n[i].o === a) {
      u = [n[i], i];
      break;
    }
  }
  return u;
}
function h(n, r, t, a) {
  var e = n.indexOf(a);
  var u = x(n, r, t, a);
  if (u && u[1] == e + r) {
    return u;
  } else {
    return null;
  }
}
function y(r) {
  var e;
  return e = {}, n(e, t, Math.sign(r.ax2 - r.ax1)), n(e, a, Math.sign(r.ay2 - r.ay1)), e;
}
function c(n) {
  return [Math.sign(n.x2 - n.x1), Math.sign(n.y2 - n.y1)];
}
function v(n) {
  var r = n[0].ax1,
    e = n[0].ay1,
    u = n[n.length - 1].ax2,
    l = n[n.length - 1].ay2,
    i = u >= r ? r : u,
    o = l >= e ? e : l;
  n.forEach(function (n) {
    n.x1 = n.ax1 - i;
    n.y1 = n.ay1 - o;
    n.x2 = n.ax2 - i;
    n.y2 = n.ay2 - o;
    if (n.o == null || n.o.length === 0) {
      n.o = n.ax1 === n.ax2 ? a : t;
    }
  });
  return n;
}
function g(n, r) {
  return n.curX === r.curX && n.curY === r.curY;
}
function p(n, r, e, u, l, i) {
  if (n.lastx === r && n.lasty === e) {
    return;
  }
  var o = n.lastx == null ? u.sx : n.lastx,
    f = n.lasty == null ? u.sy : n.lasty,
    s = o === r ? a : t;
  n.lastx = r;
  n.lasty = e;
  var x = {
    x1: o,
    y1: f,
    x2: r,
    y2: e,
    o: s,
    ax1: o + l,
    ay1: f + i,
    ax2: r + l,
    ay2: e + i
  };
  n.orthogonalSegments.push(x);
}
export { u as _findNextHorizontalSegment, f as _findNextSegmentOfType, i as _findNextVerticalSegment, l as _findPreviousHorizontalSegment, o as _findPreviousVerticalSegment, y as aSegmentDirections, p as addSegment, g as anchorPlacementEquals, h as findAdjacentSegment, x as findSegment, s as orthogonalSegmentLength, c as segmentDirections, v as transformOrthogonalFromAbsolute, e as valueInRange };