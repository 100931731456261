import { Component, ViewEncapsulation, Input, Host } from '@angular/core';
import { OverlayComponent } from '@shared/components/overlay/overlay.component';
import { slideInOutRightAnimation } from '@shared/animations/core.animations';
import { NgClass, AsyncPipe } from '@angular/common';
import { CdkScrollable } from '@angular/cdk/scrolling';

@Component({
  selector: 'naris-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [slideInOutRightAnimation],
  standalone: true,
  imports: [NgClass, CdkScrollable, AsyncPipe]
})
export class DrawerComponent {

  @Input()
  public width = '40rem';

  @Input()
  public offset = 0;

  @Input()
  public isDynamicDashboardAddDrawer = false;

  constructor(@Host() public parent: OverlayComponent) {}
}
