<div class="process-archimate">
  <div class="process-archimate__editor">
    <div class="process-archimate__editor__surface">
      <jsplumb-surface
        surfaceId="archimateSurfaceId"
        toolkitId="archimateToolkitId"
        [view]="viewOpts"
        [renderParams]="renderOpts"
        [toolkitParams]="toolkitOpts"
      />
    </div>
    <div 
      #archimateInspector
      class="process-archimate__editor__inspector"
    >
      <app-archimate-inspector surfaceId="archimateSurfaceId" />
    </div>
    @if (loading) {
      <div class="process-archimate__loading-chip">
        <mat-progress-spinner
          mode="indeterminate"
          color="primary"
          diameter="18"
        />
        <span>{{'archimate.loading' | translate}}</span>
      </div>
    }
  </div>
  <div class="process-archimate__toolkit">
    <span class="process-archimate__toolkit__title">Archimate toolkit</span>
    <div 
      class="process-archimate__toolkit__palette"
      jsplumb-surface-drop
      selector=".process-archimate__toolkit__palette__layer__elements__element"
      surfaceId="archimateSurfaceId"
      [dataGenerator]="dataGenerator"
    >
      @for (layer of palette; track $index) {
        <div class="process-archimate__toolkit__palette__layer">
          <span class="process-archimate__toolkit__palette__layer__title">{{layer.title | translate}}</span>
          <div 
            class="process-archimate__toolkit__palette__layer__elements"
            [ngStyle]="{'background-color': layer.color}"
          >
            @for (element of layer.elements; track $index) {
              <div 
                class="process-archimate__toolkit__palette__layer__elements__element"
                [attr.data-type]="layer.type + '_' + element.type"
                [attr.data-icon]="element.icon"
              >
                <naris-icon [icon]="element.icon" [matTooltip]="('archimate.' + element.type) | translate"/>
              </div>
            }
          </div>
        </div>
      }
    </div>
  </div>
</div>