import { Injectable } from '@angular/core';
import { ILayoutTab } from '@core/models';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TabService {

  public activeTab: ILayoutTab;
  public activeTabName: string | null;

  private tabs: ILayoutTab[] = [];

  public updated: Subject<ILayoutTab[]> = new Subject<ILayoutTab[]>();
  public caseViewLoaded: Subject<boolean> = new Subject<boolean>();
  public tabChanged: Subject<ILayoutTab> = new Subject<ILayoutTab>();
  public setActiveTab: Subject<ILayoutTab> = new Subject<ILayoutTab>();

  public updateTab$: Subject<boolean> = new Subject<boolean>();

  public hideOvershadow = false;

  public pushStateUrl: string | null;

  private updatedTimeout: NodeJS.Timeout;

  public useTabServiceForHelp = false;


  get isBarVisible() {
    return this.tabs.length > 0 && !this.hideOvershadow;
  }

  public add(tabs: ILayoutTab | ILayoutTab[]) {
    if (!Array.isArray(tabs)) {
      const foundTab = this.tabs.find(t => t.href === tabs.href);
      if (!foundTab) {
        this.tabs.push(tabs);
        this.updateTabs();
      }
    } else {
      this.tabs = tabs;
      this.updateTabs();
    }
  }

  public clear() {
    this.tabs = [];
    this.activeTab = {title: '', active: false, href: ''};

    this.updateTabs();
  }

  private updateTabs() {
    clearTimeout(this.updatedTimeout);
    this.updatedTimeout = setTimeout(() => {      
      this.updated.next(this.tabs);
    }, 2000);
  }

  public length(): number {
    return this.tabs.length;
  }
}
