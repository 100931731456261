import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { IInputOption } from '@core/models';
import { UserService, AuthService } from '@core/services';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { CardComponent } from '../../../shared/components/card/card.component';
import { FormFieldComponent } from '../../../shared/components/form-field/form-field.component';
import { SelectComponent } from '../../../shared/elements/select/select.component';
import { CheckboxComponent } from '../../../shared/elements/checkbox/checkbox.component';
import { FormActionsComponent } from '../../../shared/components/form-actions/form-actions.component';
import { ButtonComponent } from '../../../shared/elements/button/button.component';

@Component({
  selector: 'app-org-choice',
  templateUrl: './org-choice.component.html',
  styleUrls: ['./org-choice.component.scss'],
  standalone: true,
  imports: [CardComponent, FormsModule, ReactiveFormsModule, FormFieldComponent, SelectComponent, CheckboxComponent, FormActionsComponent, ButtonComponent, TranslateModule]
})
export class OrgChoiceComponent implements OnInit, OnDestroy {

  public orgForm: FormGroup;
  public organizations: IInputOption[];
  private readonly previousUrl: string;
  private subs: Subscription[];

  constructor(public userService: UserService, private readonly authService: AuthService, private readonly router: Router) {
    this.previousUrl = router.getCurrentNavigation()?.extras?.state?.previousUrl || '';
  }

  public ngOnInit(): void {
    this.userService.getUserOrgs().subscribe({
      next: orgs => {
        if (!orgs?.length) void this.router.navigate(['/login']);
        else {
          this.organizations = orgs;
          this.orgForm = new FormGroup({
            organization: new FormControl(this.userService.defaultOrg || this.userService.organizationID.toString() || this.organizations[0].code),
            isDefault: new FormControl(!!this.userService.defaultOrg || null)
          });
          this.subs = [
            this.orgForm.get('organization')!.valueChanges.subscribe(val => {
              const checkedBool = val === this.userService.defaultOrg?.toString();
              this.orgForm.get('isDefault')?.setValue(checkedBool);
            })
          ];
        }
      },
      error: () => this.router.navigate(['/login'])
    });
  }

  public onSubmit() {
    const orgVal = this.orgForm.value.organization;
    const defaultVal = this.orgForm.value.isDefault;
    let defaultBool = defaultVal || null;
    if (this.userService.defaultOrg === orgVal && !defaultVal) {
      this.userService.defaultOrg = null;
      defaultBool = false;
    }
    this.userService.submitOrganization(orgVal, defaultBool);
  }

  public onBack() {
    if (!this.previousUrl) window.history.go(-1);
    else void this.router.navigate([this.previousUrl]);
  }

  public ngOnDestroy() {
    this.subs?.forEach(sub => sub.unsubscribe());
  }

  public getFormControl(controlName: string) {
    return this.orgForm.get(controlName) as FormControl;
  }
}
