<div class="compare">
  @if (restructuredData.length > 0) {
    <div class="compare-input">
      <div>
        <table class="compare-table">
          @for (data of restructuredData; track $index) {
            <tbody class="compare-table" [@slideIn] [@.disabled]="animationDisabled">
              <tr class="compare-table">
                <th></th>
                <th class="compare-table__text" colspan="4">
                  <h3>{{data.name | translate}}</h3>
                </th>
              </tr>
              <tr class="compare-table__sub-header">
                <th class="compare-table__sub-header__text"></th>
                <th class="compare-table__sub-header__text">{{tableLabels[0] | translate}}</th>
                <th class="compare-table__sub-header__text">{{tableLabels[1] | translate}}</th>
                <th class="compare-table__sub-header__text">{{tableLabels[2] | translate}}</th>
              </tr>
              @for (compareData of data.compareData; track $index) {
                <tr class="compare-table__row">
                  <td class="compare-table__row__column first">
                    <div class="ml-2 mr-2">
                      {{(compareData.key || data.name) | translate}}
                    </div>
                  </td>
                  <td
                    class="compare-table__row__column second"
                    (click)="selectedVersion(data, compareData, false)"
                    [class.selected]="compareData.isNewSelected === false && !isReadonly(compareData.key || data.name, data, compareData)"
                    [class.selectable]="pickChanges && isReadonly(compareData.key || data.name)"
                    [class.w-33]="pickChanges"
                  >
                    <div class="mr-4">
                      @if (data.name === 'Frequency') {
                        <div [innerHTML]="compareData.old | toFrequencyString"></div>
                      } @else {
                        <div>
                          @if (compareData.old.Questions) {
                            @for (question of compareData.old.Questions; let last = $last; track $index) {
                              {{question}}
                              @if (!last) {
                                <br>
                              }
                            }
                          } @else {
                            {{objectToString(compareData.old)}}
                          }
                        </div>
                      }
                    </div>
                  </td>
                  <td
                    class="compare-table__row__column third"
                    (click)="selectedVersion(data, compareData, true)"
                    [ngClass]="{
                      selected: compareData.isNewSelected === true && !isReadonly(compareData.key || data.name),
                      selectable: pickChanges && isReadonly(compareData.key || data.name),
                      fourth: !pickChanges,
                      'no-color': noColors,
                      last: !pickChanges,
                      'w-33': pickChanges
                    }"
                  >
                    <div class="ml-4">
                      @if (data.name !== 'Frequency') {
                        <div>
                          @if (compareData.new.Questions) {
                            @for (question of compareData.new.Questions; let last = $last; track $index) {
                              {{question}}
                              @if (!last) {
                                <br>
                              }
                            }
                          } @else {
                            {{objectToString(compareData.new)}}
                          }
                        </div>
                      } @else {
                        <div [innerHTML]="compareData.new | toFrequencyString"></div>
                      }
                    </div>
                  </td>
                  @if (pickChanges) {
                    <td class="compare-table__row__column fourth" [class.w-33]="pickChanges">
                      <div class="ml-4">
                        @if (data.name !== 'Frequency') {
                          <div>
                            {{objectToString(compareData.isNewSelected === true ? compareData.new : compareData.old)}}
                          </div>
                        } @else {
                          <div [innerHTML]="(compareData.isNewSelected === true ? compareData.new : compareData.old) | toFrequencyString"></div>
                        }
                      </div>
                    </td>
                  }
                </tr>
              }
              
            </tbody>
          }
        </table>
      </div>
      @if (showAcceptButtons) {
        <div class="accept-buttons">
          <naris-button (click)="accept(false)" id="JSONCompareInputCancel">{{'cancel' | translate}}</naris-button>
          @if (pickChanges) {
            <naris-button color="primary" (click)="accept(true)" id="JSONCompareInputAccept">{{'accept' | translate}}</naris-button>
          }
        </div>
      }
    </div>
  } @else if (hasData) {
    <naris-progress-spinner [strokeWidth]="2" [diameter]="24" />
  }
</div>