import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { states } from '@features/audit/audit-execution-assessment/audit-execution-assessment.component';
import { TableDataSource } from '@core/classes';
import { AuthService, BeinformedService, FormService, HttpService, SnackbarService, TableService, UserService } from '@core/services';
import { FileService } from '@core/services/file.service';
import { EAuthContext } from '@core/enums';
import { TableSettingsService } from '@core/services/table-settings.service';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { ToolbarItemComponent } from '../../../shared/components/toolbar/toolbar-item/toolbar-item.component';
import { TaskgroupComponent } from '../../../shared/components/taskgroup/taskgroup.component';
import { ToolbarComponent } from '../../../shared/components/toolbar/toolbar.component';
import { CardComponent } from '../../../shared/components/card/card.component';
import { IconComponent } from '../../../shared/elements/icon/icon.component';
import { AuditExecutionAssessmentComponent } from '../audit-execution-assessment/audit-execution-assessment.component';
import { ButtonComponent } from '../../../shared/elements/button/button.component';
import { ProgressSpinnerComponent } from '../../../shared/elements/progress-spinner/progress-spinner.component';
import { AuditExecutionSampleActionComponent } from './audit-execution-sample-action/audit-execution-sample-action.component';
import type { IAction, ICaseListAction, IInputOption, IListModel, ITableDataMapped } from '@core/models';

@Component({
  selector: 'naris-audit-execution-matrix',
  templateUrl: './audit-execution-matrix.component.html',
  styleUrls: ['./audit-execution-matrix.component.scss'],
  standalone: true,
  imports: [CardComponent, ToolbarComponent, ToolbarItemComponent, TaskgroupComponent, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatTooltip, AuditExecutionSampleActionComponent, MatCellDef, MatCell, IconComponent, AuditExecutionAssessmentComponent, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, ButtonComponent, ProgressSpinnerComponent, TranslateModule]
})
export class AuditExecutionMatrixComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public panel: any;
  @Input() public minNumOfSamples: { text?: string; value: number };
  @Output() public readonly changed = new EventEmitter<boolean>();
  @Input() public dataIsLoading = false;

  public matrixRows: Record<string, any>[] | undefined = [];
  public matrixColumns: string[] = [];

  public displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];

  public dataSource = new TableDataSource(this.tableService, this.beinformedService, this.formService, this.userService, this.translateService, this.tableSettingsService);

  public tableActions: any;

  public activeOption = null;

  public states = states;

  private readonly sampleDescriptionArray: { name: string; description: string }[] = [];
  private readonly criteriumDescriptionArray: { name: string; description: string }[] = [];
  private readonly sampleDocumentNameArray: { name: string; documentName: string }[] = [];

  private matrixData: Record<string, any>[] | null = null;
  public singleRowDataItems = [];

  public listData: IListModel;

  public descriptionString: string;
  private readonly subscriptions: Subscription[] = [];

  public isDownloading = false;

  public deleteCriteriumAction?: ICaseListAction;
  public addIfEmptyAction?: ICaseListAction;

  public isLoading = false;
  private allSet = true;

  constructor(
    private readonly tableService: TableService,
    private readonly beinformedService: BeinformedService,
    private readonly formService: FormService,
    private readonly userService: UserService,
    private readonly fileService: FileService,
    private readonly translateService: TranslateService,
    private readonly httpService: HttpService,
    private readonly authService: AuthService,
    private readonly snackbarService: SnackbarService,
    private readonly tableSettingsService: TableSettingsService
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (!!changes.panel && changes.panel.currentValue !== changes.panel.previousValue) {
      this.isLoading = true;
      const panel = changes.panel.currentValue;
      this.dataSource.getTableData(panel.href, false, {}, true, true).subscribe(data => this.translateData(data));
    }
  }

  public ngOnInit() {
    this.subscriptions.push(
      this.translateService.get('table.description').subscribe(x => {
        this.descriptionString = x;
      }),
      this.fileService.downloading.subscribe(x => {
        this.isDownloading = x;
      })
    );
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private translateData(data: ITableDataMapped) {
    const mainData = data.tableData;
    const hiddenActions = ['delete-criterium'];
    this.tableActions = data.tableActions.filter(action => !hiddenActions.includes(action.name));
    this.deleteCriteriumAction = data.tableActions.find(action => action.name === 'delete-criterium');
    this.addIfEmptyAction = data.tableActions.find(action => action.name.includes('add'));
    const appraisalOptions = data.tableColumns.find(column => Object.keys(column)?.[0] === 'Appraisal')?.Appraisal?.options as IInputOption[];
    const toBeDeterminedLabel = appraisalOptions?.find(option => option.code === 'ToBeDetermined')?.label;
    this.allSet = !data.tableData.some(item => item.Appraisal === toBeDeterminedLabel) && data.tableData.length >= this.minNumOfSamples.value;
    const criteriumNames: string[] = [];
    this.matrixColumns = [];
    this.matrixRows = [];
    this.matrixData = mainData as any;
    if (!mainData || Object.keys(mainData).length === 0) {
      this.isLoading = false;
      return;
    }
    this.matrixData?.forEach(dataElement => {
      const critName = dataElement.CriteriumID[0].CriteriumName;
      if (!criteriumNames.includes(critName)) criteriumNames.push(critName);
    });
    criteriumNames.forEach(criteriumName => {
      const criteria = this.matrixData?.filter(criterium => criterium.CriteriumID[0].CriteriumName === criteriumName);      
      const row = new Map<number | string, any>();
      row.set('criteriumName', criteriumName);
      criteria?.forEach(criterium => {
        row.set(criterium.SampleName, {
          href: criterium.actions.find((action: ICaseListAction) => action.name === 'assess-sample')?.href,
          assess: true,
          appraisal: {appraisalId: this.getAppraisalId(criterium.Appraisal, data.tableColumns), href: criterium.actions.find((action: ICaseListAction) => action.name === 'assess-sample')?.href},
          actions: criterium.actions
        });
      });
      this.matrixRows?.push(row);
    });

    this.matrixColumns = Array.from(this.matrixRows[0]?.keys());
    this.matrixColumns.splice(1, 0, 'addSample');
    this.getDescriptions();
    this.isLoading = false;
  }

  private getAppraisalId(appraisal: string, tableColumns: any): string {
    const objectName = Object.keys(tableColumns)?.[0];
    const appraisalColumn = tableColumns[objectName]?.find((item: Record<string, any>) => Object.keys(item).includes('Appraisal'));
    const appraisalOption = appraisalColumn?.Appraisal?.options?.find((option: IInputOption) => option.label === appraisal);
    return appraisalOption?.code || appraisal;
  }

  private getDescriptions() {
    this.matrixColumns.forEach(col => {
      const row = this.matrixData?.find(item => item.SampleName === col);
      this.sampleDescriptionArray.push({name: col, description: row?.SampleDescription});
      this.sampleDocumentNameArray.push({name: col, documentName: row?.DocumentName});
    });

    this.matrixRows?.forEach(row => {
      this.matrixData?.forEach(element => {
        const criterium = element.CriteriumID.find((c: Record<string, any>) => c.CriteriumName === row.criteriumName);
        if (!!criterium) {
          const descriptionExists = this.criteriumDescriptionArray.find(item => item.name === criterium.CriteriumName);
          if (!descriptionExists) this.criteriumDescriptionArray.push({name: criterium.CriteriumName, description: criterium.Description});
        }
      });
    });
  }

  public reload = () => {
    this.dataSource.getTableData(this.panel.href, false, {}, true).subscribe({
      next: data => this.translateData(data),
      complete: () => this.changed.emit(this.allSet)
    });
  };

  public isStickyColumn(columName: string): boolean {
    if (this.matrixColumns[0] === columName) return true;
    return false;
  }

  public getCriteriumDescription(row: string) {
    return this.criteriumDescriptionArray.find(item => item.name === row)?.description;
  }

  public getSampleDescription(col: string) {
    return this.sampleDescriptionArray.find(item => item.name === col)?.description;
  }

  public getSampleDocumentName(col: string) {
    const documentName = this.sampleDocumentNameArray.find(item => item.name === col)?.documentName;
    let documentNameLabel = 'Document name';
    this.subscriptions.push(
      this.translateService.get('matrix.document-name').subscribe(x => {
        documentNameLabel = x;
      })
    );
    const returnValue = `${documentNameLabel}: ${documentName}`;
    return !!documentName ? returnValue : null;
  }

  public getActions(col: string) {
    if (col === 'criteriumName') return;
    const actions = (this.matrixRows?.[0].get(col).actions as IAction[]).filter(action => action.name !== 'assess-sample');
    actions.forEach(action => {
      if (action.name?.includes('delete')) action.icon = 'delete';
      if (action.name?.includes('update')) action.icon = 'edit';
      if (action.name?.includes('download')) action.icon = 'download';
    });
    return actions as ICaseListAction[];
  }

  public executeAction(action?: ICaseListAction) {
    if (!!action?.name.includes('delete')) void this.beinformedService.handleAction(action, this.reload, this.reload, false, false);
    else if (!!action?.name.includes('download')) this.downloadFile(action);
    else this.formService.open(action?.href || '').subscribe(() => this.reload());
  }

  private downloadFile(action: ICaseListAction) {
    this.subscriptions.push(
      this.fileService.downloading.subscribe(downloading => {
        action.name = downloading ? 'spinner' : 'download-document';
      })
    );
    this.fileService.downloadFile(action.href);
  }

  public getColIdText(textId: string) {
    return textId.replace(/\W/g, '');
  }

  public colTextWidth(textId: string) {
    if (textId === 'criteriumName' || textId === 'addSample') return;
    const tmp = this.getColIdText(textId);
    const selectedElement = document.querySelector(`#txt_${tmp}`);
    const txtElement = selectedElement as HTMLElement;
    if (!txtElement) return;
    const txtWidth = txtElement.clientWidth;
    const returnValue = {transform: `translateX(${txtWidth}px)`};
    return returnValue;
  }

  public getDescription(col: string) {
    let desc = this.getSampleDocumentName(col) ? `${this.getSampleDocumentName(col)}` : '';
    if (this.getSampleDescription(col)) desc += `${this.descriptionString}: ${this.getSampleDescription(col)}`;
    return desc;
  }

  public deleteCriterium(data: Record<string, any>) {
    if (!!this.matrixData?.length) {
      let foundCriterium: Record<string, any> | null = null;
      this.matrixData.forEach(matrixItem => {
        if (!!foundCriterium) return;
        foundCriterium = matrixItem.CriteriumID?.find((criteriumData: Record<string, any>) => criteriumData.CriteriumName === data);
        if (!!foundCriterium) return;
      });
      if (!!foundCriterium) {
        const isRest = this.authService.authContext === EAuthContext.SSO;
        this.httpService.post(this.deleteCriteriumAction?.href || '', {}, isRest).subscribe({error: e => {
          if (e.status === 400) {
            const tokens = e.error.formresponse.tokens;
            const body = {tokens, objects: {Criteria: {CriteriumID: foundCriterium?._id}}};
            this.httpService.post(this.deleteCriteriumAction?.href || '', body).subscribe({next: () => {
              this.snackbarService.open({ text: 'snackbar.successfully', type: 'success' });
              this.reload();
            }});
          }
        }});
      }
    }
  }

  public getRowData(row: any, col: any) {
    return row.get(col);
  }
} 
