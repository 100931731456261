import { Component, Input, ViewEncapsulation, Output, EventEmitter, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange, MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'naris-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatCheckbox]
})
export class CheckboxComponent implements OnInit {

  /**
   * Material color code for checkbox. Naris design only shows primary color
   */
  @Input()
  public color = 'primary';

  /**
   * The formcontrol the checkbox belongs to.
   */
  @Input()
  public control: FormControl;

  /**
   * If it shows a state between checked and not checked
   */
  @Input()
  public indeterminate: boolean;

  /**
   * Whether the checkbox is checked
   */
  @Input()
  public checked: boolean;

  /**
   * Position of the label, either 'before' or 'after'. Default: 'after'
   */
  @Input()
  public labelPosition: 'after' | 'before' = 'after';

  @Input()
  public disabled: boolean;

  @Input()
  public id: string;

  /**
   * Property to pass on the (change) event of a mat-checkbox
   */
  @Output()
  public readonly changed = new EventEmitter<MatCheckboxChange>();

  public ngOnInit(): void {
    if (this.control?.value) this.checked = true;
  }

  public onChange(event: MatCheckboxChange) {
    if (this.control) this.control.setValue(event.checked);
    this.changed.emit(event);
  }

  public setChecked() {
    if (this.control) return this.control.value;
    else return this.checked;
  }
}
