import { inherits as e, createSuper as r, classCallCheck as s, defineProperty as i, assertThisInitialized as t } from "../_virtual/_rollupPluginBabelHelpers.js";
import { AbstractEntry as a } from "./abstract-entry.js";
import { uuid as l } from "../ui-core/util/util.js";
var n = function (a) {
  e(u, a);
  var n = r(u);
  function u(e, r) {
    var a;
    s(this, u);
    a = n.call(this, r);
    i(t(a), "type", "text");
    i(t(a), "value", void 0);
    a.value = e.value;
    a.bindings = {};
    var o = {
      id: l(),
      w: e.value,
      expressions: []
    };
    e.value.replace(/\{\{([^\}]*)\}\}/g, function (e, r, s, i) {
      o.expressions.push([e, r]);
      return "";
    });
    a._processBindings(i({}, u.TEXT_BINDING_NAME, o));
    return a;
  }
  return u;
}(a);
i(n, "TEXT_BINDING_NAME", "__element");
export { n as TextEntry };