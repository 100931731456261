@defer (when !loading) {
  @if (!error && data) {
    @if (drawer || listTitle) {
      <naris-toolbar sticky="top">
        <naris-toolbar-item>
          @if (data.title || listTitle) {
            <h4 class="list-title">{{ listTitle || data.title  }}</h4>
          }
        </naris-toolbar-item>
        @if (!listTitle) {
          <naris-toolbar-item class="ml-auto">
            <naris-button icon="close" class="close-button" color="white" (clicked)="close()" id="ListClose"/>
          </naris-toolbar-item>
        }
      </naris-toolbar>
    }
    @if ((data.label || data.taskgroup?.actions) && !isEmbedded) {
      <naris-toolbar [size]="stacked ? 'small' : 'large'" border="bottom">
        <naris-toolbar-item>
          @if (data.label && !structureType) {
            <h4>{{data.label}}</h4>
          }
          @if (!!structureType) {
            <h4>{{structureType}}</h4>
          }
        </naris-toolbar-item>
        @if (!!data.taskgroup?.actions) {
          <naris-toolbar-item class="ml-auto">
            <naris-taskgroup
              buttonSize="small"
              buttonClass="ml-2"
              [taskgroup]="data.taskgroup!"
              [collapseAfter]="2"
              (postCallback)="update($event, true)"
              (formCallback)="update($event, true)"
            />
          </naris-toolbar-item>
        }
      </naris-toolbar>
    }
    @if (!listTitle && !!data.values?.length) {
      <div class="list" [class.border]="showBorder" [ngClass]="{refresh: refreshing}">
        <table class="content">
          <tbody>
            @for (value of data.values; track $index) {
              <tr class="row">
                <td class="key">
                  @if (stacked) {
                    <div class="stacked-label">
                      <span
                        [class.label]="value.layouthint === 'label'"
                        [ngStyle]="{'cursor': value.key.length > truncateLength && 'help'}"
                        [matTooltip]="value.key.length > truncateLength ? value.key : ''"
                        [matTooltipClass]="{truncated: value.key.length > truncateLength}"
                        matTooltipPosition="after"
                      >{{truncateLabel(value.key)}}</span>
                    </div>
                    <ng-container *ngTemplateOutlet="arrayValue; context: {$implicit: value}" />
                  } @else {
                    <span
                      [class.label]="value.layouthint === 'label'"
                      [ngStyle]="{'cursor': value.key.length > truncateLength && 'help'}"
                      [matTooltip]="value.key.length > truncateLength ? value.key : ''"
                      [matTooltipClass]="{truncated: value.key.length > truncateLength}"
                      matTooltipPosition="after"
                    >{{ truncateLabel(value.key) }}</span>
                  }
                </td>
                @if (!stacked) {
                  <td class="value" [class.no-value]="value.value === undefined" #valueCol>
                    <ng-container *ngTemplateOutlet="arrayValue; context: {$implicit: value}" />
                  </td>
                }
              </tr>
            }
          </tbody>
        </table>
      </div>
    }
    @if (!!data.panels?.length) {
      <div>
        <naris-tab-panel [panels]="data.panels!" [isEmbedded]="true" (updated)="update()" [useMargin]="!!drawer || !!listTitle" />
      </div>
    }
    
  } @else {
    <div class="list-nodata">
      <naris-icon icon="error" iconClass="medium accent" />
      <p>{{'list.error_loading_resource' | translate}}</p>
    </div>
  }
} @placeholder {
  <naris-loader />
}

<ng-template #arrayValue let-value>
  <div class="value-wrapper">
    <div class="value-box column-info-class" [ngClass]="{stacked: stacked, color: value.layouthint?.includes('color')}">
      @switch (value.type) {
        @case ('array') {
          @for (item of value.value; track $index) {
            <div class="center-info-class">
              @if (isArray(item)) {
                @for (label of item; track $index) {
                  @if (!value.layouthint.includes('color') || !colorReg.test(label)) {
                    {{ label + ' | '}}
                  } @else {
                    @if (!!label) {
                      <span
                        matTooltipPosition="right"
                        class="color-box spaced"
                        [ngStyle]="{backgroundColor: label}"
                        [matTooltip]="label"
                      ></span>
                    }
                  }
                }
              } @else {
                @if (!value.layouthint.includes('color') || !colorReg.test(item)) {
                  {{ item }}
                } @else {
                  @if (!!item) {
                    <span
                      class="color-box spaced"
                      [ngStyle]="{backgroundColor: item}"
                      [matTooltip]="item"
                      matTooltipPosition="right"
                    ></span>
                  }  
                }
              }
            </div>
          }
        }
        @case ('boolean') {
          <naris-icon
            [icon]="value.value ? 'check' : 'close'"
            [iconClass]="value.value ? 'green' : 'warn'"
          />
        }
        @case ('string') {
          @if (!!value.value && value.layouthint?.includes('color')) {
            <span
              class="color-box"
              [ngClass]="{prio: value.value.includes('|\\|')}"
              [ngStyle]="{backgroundColor: value.value.includes('|\\|') ? value.value.split('|\\|')[0] : value.value}"
              [matTooltip]="value.value.includes('|\\|') ? value.value.split('|\\|')[0] : value.value"
              matTooltipPosition="right"
            ></span>
          }
          @if (!value.layouthint.includes('color')) {
            <div>
              <span
                tabindex="0"
                class="value-span"
                [class.expanded]="value.expandText"
                #valueContainer
                (click)="value.expandText = !value.expandText"
              >

                @if (value.layouthint.includes('rich_text')) {
                  {{value.value | richText}}
                } @else {
                  {{value.value}}
                }
              </span>
              @if (isTruncated(valueContainer) || value.expandText) {
                <div class="expand" (click)="value.expandText = !value.expandText" tabindex="0">
                  <naris-icon icon="keyboard_arrow_down" [class.rotated]="value.expandText" />
                </div>
              }
            </div>
          } @else {
            @if (value.value.includes('|\\|')) {
              <span>{{value.value.split('|\\|')[1]}}</span>
            }
          }
        }
        @case ('empty') {
          @if (value.layouthint !== 'label') {
            <span>{{'list.no_value' | translate}}</span>
          }
        }
        @default {
          <div
            [ngStyle]="{'cursor': (value.value?.length ?? 0) > truncateLength && 'help'}"
            [matTooltip]="(value.value?.length ?? 0) > truncateLength ? value.value : ''"
            [matTooltipClass]="{truncated: (value.value?.length ?? 0) > truncateLength}"
            matTooltipPosition="after"
          >
            {{value.value}}
          </div>
        }
      }
    </div>
    @if (value.actions) {
      <div class="actions">
        @for (action of value.actions; track $index) {
          <naris-icon
            [icon]="getActionIcon(action)"
            [matTooltip]="action.label"
            (click)="executeAction(action)"
            [iconClass]="'small'"
          />
        }
      </div>
    }
  </div>
</ng-template>
