// From https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem.
const base64ToBytes = (base64: string) => {
  const binString = atob(base64);
  return Uint8Array.from(binString as any, m => (m as any).codePointAt(0));
};

// From https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem.
const bytesToBase64 = (bytes: Uint8Array) => {
  const binString = String.fromCodePoint(...bytes);
  return btoa(binString);
};

// Quick polyfill since Firefox and Opera do not yet support isWellFormed().
// encodeURIComponent() throws an error for lone surrogates, which is essentially the same.
const isWellFormed = (str: any) => {
  if (typeof(str.isWellFormed) != 'undefined') {
    // Use the newer isWellFormed() feature.
    return str.isWellFormed();
  } else {
    // Use the older encodeURIComponent().
    try {
      encodeURIComponent(str);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
};

export const toBase64 = (text: string): string | null => {
  if (isWellFormed(text))
    return bytesToBase64(new TextEncoder().encode(text));
  else
    return null;  
};

export const fromBase64 = (base64: string): string => new TextDecoder().decode(base64ToBytes(base64));