export enum ElementColors {
  default = 'default',
  success = 'success',
  warning = 'warning',
  danger = 'danger'
}

export enum StatusColor {
  default = 'default',
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  primary = 'primary',
  planned = 'planned'
}

export const enum EPageSize {
  All = 'ALL',
  Few = 'FEW',
  Many = 'MANY',
  Med = 'MED'
}

export const enum EOrderOption {
  Asc = 'ASC',
  Desc = 'DESC'
}

export const enum EFilterType {
  Text = 'TEXT',
  Number = 'NUMBER',
  Embedded = 'EMBEDDED'
}
