import { createClass as e, typeof as t, toConsumableArray as n, slicedToArray as i, classCallCheck as s, defineProperty as r } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { CLASS_DRAG_ACTIVE as o, CLASS_DRAG_HOVER as a } from "./drag-manager.js";
import { getPositionOnElement as l } from "./browser-jsplumb-instance.js";
import { EVENT_MOUSEDOWN as c, EVENT_MOUSEUP as p, EVENT_CONNECTION_DRAG as d, EVENT_CONNECTION_DRAGGING as h, EVENT_CONNECTION_DRAG_END as u, EVENT_CONNECTION_ABORT as f } from "./event-constants.js";
import { findParent as g, consume as m, addClass as v } from "./browser-util.js";
import { extend as j, merge as E, each as _, forEach as y, getWithFunction as C, getAllWithFunction as D, isObject as I } from "../util/util.js";
import { ATTRIBUTE_JTK_ENABLED as b, ATTRIBUTE_JTK_SCOPE as A } from "./constants.js";
import { Endpoints as k } from "../core/endpoint/endpoint.js";
import { EndpointRepresentation as S } from "../core/endpoint/endpoint-representation.js";
import { createFloatingAnchor as x } from "../core/factory/anchor-record-factory.js";
import { cls as T, SELECTOR_MANAGED_ELEMENT as w, SOURCE as P, TARGET as F, ATTRIBUTE_SCOPE_PREFIX as O, classList as H } from "../core/constants.js";
import { CLASS_ENDPOINT as G, CLASS_ENDPOINT_FLOATING as N } from "../core/css-constants.js";
import { REDROP_POLICY_ANY as R, REDROP_POLICY_STRICT as Z, REDROP_POLICY_ANY_SOURCE as J, REDROP_POLICY_ANY_TARGET as M, REDROP_POLICY_ANY_SOURCE_OR_TARGET as V } from "../core/source-selector.js";
import { FALSE as q } from "../common/index.js";
import { intersects as B } from "../util/geom.js";
import { svg as L } from "./svg-util.js";
import { getElementPosition as z, getBoundingClientRectElementSize as W } from "./positioning-and-sizing-utils.js";
import { Connections as K, _isConnectionDetachable as Q, _isConnectionReattach as U } from "../core/connector/connections.js";
import { Components as X } from "../core/component/component.js";
function Y(e, t, n, i, s, r) {
  var o = x(r, e, i, s);
  var a = {
    paintStyle: e.paintStyle,
    preparedAnchor: o,
    element: i,
    scope: e.scope,
    cssClass: [N, e.cssClass].join(" ")
  };
  if (t != null) {
    if (t instanceof S) {
      a.existingEndpoint = t;
    } else {
      a.endpoint = t;
    }
  }
  var l = r._internal_newEndpoint(a);
  r._paintEndpoint(l, {});
  return l;
}
function $(e, t, n, i, s) {
  var r = e.target || e.srcElement,
    o = false,
    a = i.getSelector(t, n);
  for (var l = 0; l < a.length; l++) {
    if (a[l] === r) {
      o = true;
      break;
    }
  }
  return s ? !o : o;
}
var ee = T(o, a);
var te = "sourceSelectorEndpoint";
var ne = function () {
  function S(e) {
    s(this, S);
    this.instance = e;
    r(this, "jpc", void 0);
    r(this, "existingJpc", void 0);
    r(this, "_originalAnchorSpec", void 0);
    r(this, "ep", void 0);
    r(this, "endpointRepresentation", void 0);
    r(this, "canvasElement", void 0);
    r(this, "_activeDefinition", void 0);
    r(this, "placeholderInfo", {
      id: null,
      element: null
    });
    r(this, "floatingIndex", void 0);
    r(this, "floatingId", void 0);
    r(this, "floatingElement", void 0);
    r(this, "floatingEndpoint", void 0);
    r(this, "floatingAnchor", void 0);
    r(this, "_stopped", void 0);
    r(this, "inPlaceCopy", void 0);
    r(this, "endpointDropTargets", []);
    r(this, "currentDropTarget", null);
    r(this, "payload", void 0);
    r(this, "floatingConnections", {});
    r(this, "_forceReattach", void 0);
    r(this, "_forceDetach", void 0);
    r(this, "mousedownHandler", void 0);
    r(this, "mouseupHandler", void 0);
    r(this, "selector", T(G));
    var t = e.getContainer();
    this.mousedownHandler = this._mousedownHandler.bind(this);
    this.mouseupHandler = this._mouseupHandler.bind(this);
    e.on(t, c, w, this.mousedownHandler);
    e.on(t, p, [w, T(G)].join(","), this.mouseupHandler);
  }
  e(S, [{
    key: "_resolveDragParent",
    value: function e(t, n) {
      var i = this.instance.getContainer();
      var s = g(n, w, i, true);
      if (t.parentSelector != null) {
        var r = g(n, t.parentSelector, i, true);
        if (r != null) {
          s = g(r.parentNode, w, i, false);
        }
        return r || s;
      } else {
        return s;
      }
    }
  }, {
    key: "_mousedownHandler",
    value: function e(t) {
      var n;
      var i;
      if (t.which === 3 || t.button === 2) {
        return;
      }
      var s = t.target || t.srcElement;
      i = this._getSourceDefinition(t);
      if (i != null) {
        n = this._resolveDragParent(i.def.def, s);
        if (n == null || n.getAttribute(b) === q) {
          return;
        }
      }
      if (i) {
        var r = t.currentTarget,
          o;
        if (s.getAttribute(b) !== q) {
          m(t);
          this._activeDefinition = i;
          o = i.def.def;
          if (o.canAcceptNewConnection != null && !o.canAcceptNewConnection(n, t)) {
            return false;
          }
          var a = l(t, n, this.instance.getEffectiveVisibleZoom());
          var p = {
            element: n
          };
          j(p, o);
          p.isTemporarySource = true;
          if (o.scope) {
            p.scope = o.scope;
          } else {
            var d = s.getAttribute(A);
            if (d != null) {
              p.scope = d;
            }
          }
          var h = o.parameterExtractor ? o.parameterExtractor(n, s, t) : {};
          p = E(p, h);
          if (p.maxConnections != null && p.maxConnections >= 0) {
            var u = this.instance.select({
              source: n
            }).length;
            if (u >= p.maxConnections) {
              m(t);
              if (o.onMaxConnections) {
                o.onMaxConnections({
                  element: n,
                  maxConnections: p.maxConnections
                }, t);
              }
              t.stopImmediatePropagation && t.stopImmediatePropagation();
              return false;
            }
          }
          if (o.anchorPositionFinder) {
            var f = o.anchorPositionFinder(n, a, o, t);
            if (f != null) {
              p.anchor = f;
            }
          }
          this._originalAnchorSpec = p.anchor || (this.instance.areDefaultAnchorsSet() ? this.instance.defaults.anchors[0] : this.instance.defaults.anchor);
          var g = this.instance.router.prepareAnchor(this._originalAnchorSpec);
          var v = [a.x, a.y, 0, 0];
          if (g.locations.length > 0) {
            v[2] = g.locations[0].ox;
            v[3] = g.locations[0].oy;
          } else if (g.isContinuous) {
            a.x < .5 ? a.x : 1 - a.x;
            a.y < .5 ? a.y : 1 - a.y;
            v[2] = 0;
            v[3] = 0;
          }
          p.anchor = v;
          p.deleteOnEmpty = true;
          this.ep = this.instance._internal_newEndpoint(p);
          var _ = {};
          if (o.extract) {
            for (var y in o.extract) {
              var C = s.getAttribute(y);
              if (C) {
                _[o.extract[y]] = C;
              }
            }
            X.mergeParameters(this.ep, _);
          }
          if (p.uniqueEndpoint) {
            var D = this.ep.elementId;
            var I = this.instance.getManagedData(D, te, i.id);
            if (I == null) {
              this.instance.setManagedData(D, te, i.id, this.ep);
              this.ep.deleteOnEmpty = false;
            } else {
              this.ep.finalEndpoint = I;
            }
          }
          r._jsPlumbOrphanedEndpoints = r._jsPlumbOrphanedEndpoints || [];
          r._jsPlumbOrphanedEndpoints.push(this.ep);
          this.instance.trigger(this.ep.endpoint.canvas, c, t, _);
        }
      }
    }
  }, {
    key: "_mouseupHandler",
    value: function e(t) {
      var n = t.currentTarget || t.srcElement;
      if (n._jsPlumbOrphanedEndpoints) {
        _(n._jsPlumbOrphanedEndpoints, this.instance._maybePruneEndpoint.bind(this.instance));
        n._jsPlumbOrphanedEndpoints.length = 0;
      }
      this._activeDefinition = null;
    }
  }, {
    key: "onDragInit",
    value: function e(t) {
      var n = z(t, this.instance),
        i = W(t, this.instance.getEffectiveVisibleZoom());
      this._makeDraggablePlaceholder(n, i);
      this.placeholderInfo.element.jtk = t.jtk;
      return this.placeholderInfo.element;
    }
  }, {
    key: "onDragAbort",
    value: function e(t) {
      this._cleanupDraggablePlaceholder();
    }
  }, {
    key: "_makeDraggablePlaceholder",
    value: function e(t, n) {
      this.placeholderInfo = this.placeholderInfo || {};
      var i = L.node("svg", {
        position: "absolute"
      });
      this.instance._appendElementToContainer(i);
      var s = this.instance.getId(i);
      this.instance.setPosition(i, t);
      i.style.width = n.w + "px";
      i.style.height = n.h + "px";
      this.instance.manage(i);
      this.placeholderInfo.id = s;
      this.placeholderInfo.element = i;
      return i;
    }
  }, {
    key: "_cleanupDraggablePlaceholder",
    value: function e() {
      if (this.placeholderInfo.element) {
        this.instance.unmanage(this.placeholderInfo.element, true);
        delete this.placeholderInfo.element;
        delete this.placeholderInfo.id;
      }
    }
  }, {
    key: "reset",
    value: function e() {
      var t = this.instance.getContainer();
      this.instance.off(t, p, this.mouseupHandler);
      this.instance.off(t, c, this.mousedownHandler);
    }
  }, {
    key: "init",
    value: function e(t) {}
  }, {
    key: "startNewConnectionDrag",
    value: function e(t, n) {
      this.jpc = this.instance._newConnection({
        sourceEndpoint: this.ep,
        targetEndpoint: this.floatingEndpoint,
        source: this.ep.element,
        target: this.placeholderInfo.element,
        paintStyle: this.ep.connectorStyle,
        hoverPaintStyle: this.ep.connectorHoverStyle,
        connector: this.ep.connector,
        overlays: this.ep.connectorOverlays,
        type: n != null ? n.type || this.ep.edgeType : this.ep.edgeType,
        cssClass: this.ep.connectorClass,
        hoverClass: this.ep.connectorHoverClass,
        scope: t,
        data: n
      });
      this.jpc.pending = true;
      K.addClass(this.jpc, this.instance.draggingClass);
      k.addClass(this.ep, this.instance.draggingClass);
      this.instance.fire(d, this.jpc);
    }
  }, {
    key: "startExistingConnectionDrag",
    value: function e() {
      this.existingJpc = true;
      this.instance.setHover(this.jpc, false);
      var t = this.jpc.endpoints[0].id === this.ep.id ? 0 : 1;
      k.detachFromConnection(this.ep, this.jpc, null, true);
      k.addConnection(this.floatingEndpoint, this.jpc);
      this.instance.fire(d, this.jpc);
      this.instance.sourceOrTargetChanged(this.jpc.endpoints[t].elementId, this.placeholderInfo.id, this.jpc, this.placeholderInfo.element, t);
      this.jpc.suspendedEndpoint = this.jpc.endpoints[t];
      this.jpc.suspendedElement = this.jpc.endpoints[t].element;
      this.jpc.suspendedElementId = this.jpc.endpoints[t].elementId;
      this.jpc.suspendedElementType = t === 0 ? P : F;
      this.instance.setHover(this.jpc.suspendedEndpoint, false);
      this.floatingEndpoint.referenceEndpoint = this.jpc.suspendedEndpoint;
      X.mergeParameters(this.floatingEndpoint, this.jpc.suspendedEndpoint.parameters);
      this.jpc.endpoints[t] = this.floatingEndpoint;
      K.addClass(this.jpc, this.instance.draggingClass);
      this.floatingId = this.placeholderInfo.id;
      this.floatingIndex = t;
      this.instance._refreshEndpoint(this.ep);
    }
  }, {
    key: "_shouldStartDrag",
    value: function e() {
      var n = true;
      if (!this.ep.enabled) {
        n = false;
      }
      if (this.jpc == null && !this.ep.isSource && !this.ep.isTemporarySource) {
        n = false;
      }
      if (this.ep.isSource && k.isFull(this.ep) && !(this.jpc != null && this.ep.dragAllowedWhenFull)) {
        n = false;
      }
      if (this.jpc != null && !Q(this.jpc, this.ep)) {
        if (k.isFull(this.ep)) {
          n = false;
        } else {
          this.jpc = null;
        }
      }
      var i = {};
      var s = {
        endpoint: this.ep,
        source: this.ep.element,
        sourceId: this.ep.elementId,
        connection: this.jpc
      };
      var r = this.jpc == null ? this.instance.beforeDrag(s) : this.instance.beforeStartDetach(s);
      if (r === false) {
        n = false;
      } else if (t(r) === "object") {
        i = r;
        j(i, this.payload || {});
      } else {
        i = this.payload || {};
      }
      return [n, i];
    }
  }, {
    key: "_createFloatingEndpoint",
    value: function e(t) {
      var n = this.ep.endpoint;
      if (this.ep.edgeType != null) {
        var i = this.instance._deriveEndpointAndAnchorSpec(this.ep.edgeType);
        n = i.endpoints[1];
      }
      this.floatingEndpoint = Y(this.ep, n, t, this.placeholderInfo.element, this.placeholderInfo.id, this.instance);
      this.floatingAnchor = this.floatingEndpoint._anchor;
      this.floatingEndpoint.deleteOnEmpty = true;
      this.floatingElement = this.floatingEndpoint.endpoint.canvas;
      this.floatingId = this.instance.getId(this.floatingElement);
    }
  }, {
    key: "_populateTargets",
    value: function e(t, n) {
      var i = this;
      var s = this.jpc && this.jpc.endpoints[0] === this.ep;
      var r;
      var a = this.instance.getContainer().querySelectorAll([".", G, "[", O, this.ep.scope, "]:not(.", N, ")"].join(""));
      y(a, function (e) {
        var n = e;
        if ((i.jpc != null || n !== t) && n !== i.floatingElement && (i.jpc != null || !k.isFull(n.jtk.endpoint))) {
          if (s && n.jtk.endpoint.isSource || !s && n.jtk.endpoint.isTarget) {
            var a = z(e, i.instance),
              l = W(e, i.instance.getEffectiveVisibleZoom());
            r = {
              x: a.x,
              y: a.y,
              w: l.w,
              h: l.h
            };
            i.endpointDropTargets.push({
              el: n,
              targetEl: n,
              r: r,
              endpoint: n.jtk.endpoint,
              def: null
            });
            v(e, o);
          }
        }
      });
      if (s) {
        var l = C(this.instance.sourceSelectors, function (e) {
          return e.isEnabled() && (e.def.def.scope == null || e.def.def.scope === i.ep.scope);
        });
        if (l != null) {
          var c = this._findTargetZones(l);
          y(c, function (e) {
            if (e.getAttribute(b) !== q) {
              var t = e.getAttribute(A);
              if (t != null && t !== i.ep.scope) {
                return;
              }
              var n = {
                r: null,
                el: e
              };
              n.targetEl = g(e, w, i.instance.getContainer(), true);
              var s = z(n.el, i.instance),
                r = W(n.el, i.instance.getEffectiveVisibleZoom());
              n.r = {
                x: s.x,
                y: s.y,
                w: r.w,
                h: r.h
              };
              if (l.def.def.rank != null) {
                n.rank = l.def.def.rank;
              }
              n.def = l.def;
              i.endpointDropTargets.push(n);
              v(n.targetEl, o);
            }
          });
        }
      } else {
        var p = D(this.instance.targetSelectors, function (e) {
          return e.isEnabled();
        });
        p.forEach(function (e) {
          var t = i._findTargetZones(e);
          y(t, function (t) {
            if (t.getAttribute(b) !== q) {
              var s = t.getAttribute(A);
              if (s != null && s !== i.ep.scope) {
                return;
              }
              var r = {
                r: null,
                el: t
              };
              if (e.def.def.parentSelector != null) {
                r.targetEl = g(t, e.def.def.parentSelector, i.instance.getContainer(), true);
              }
              if (r.targetEl == null) {
                r.targetEl = g(t, w, i.instance.getContainer(), true);
              }
              if (e.def.def.allowLoopback === false || i._activeDefinition && i._activeDefinition.def.def.allowLoopback === false) {
                if (r.targetEl === i.ep.element) {
                  return;
                }
              }
              if (e.def.def.canAcceptNewConnection != null && !e.def.def.canAcceptNewConnection(r.targetEl, n)) {
                return;
              }
              var a = e.def.def.maxConnections;
              if (a != null && a !== -1) {
                if (i.instance.select({
                  target: r.targetEl
                }).length >= a) {
                  return;
                }
              }
              var l = z(t, i.instance),
                c = W(t, i.instance.getEffectiveVisibleZoom());
              r.r = {
                x: l.x,
                y: l.y,
                w: c.w,
                h: c.h
              };
              r.def = e.def;
              if (e.def.def.rank != null) {
                r.rank = e.def.def.rank;
              }
              i.endpointDropTargets.push(r);
              v(r.targetEl, o);
            }
          });
        });
      }
      this.endpointDropTargets.sort(function (e, t) {
        if (e.targetEl._isJsPlumbGroup && !t.targetEl._isJsPlumbGroup) {
          return 1;
        } else if (!e.targetEl._isJsPlumbGroup && t.targetEl._isJsPlumbGroup) {
          return -1;
        } else {
          if (e.targetEl._isJsPlumbGroup && t.targetEl._isJsPlumbGroup) {
            if (i.instance.groupManager.isAncestor(e.targetEl._jsPlumbGroup, t.targetEl._jsPlumbGroup)) {
              return -1;
            } else if (i.instance.groupManager.isAncestor(t.targetEl._jsPlumbGroup, e.targetEl._jsPlumbGroup)) {
              return 1;
            }
          } else {
            if (e.rank != null && t.rank != null) {
              if (e.rank > t.rank) {
                return -1;
              } else if (e.rank < t.rank) {
                return 1;
              } else ;
            } else {
              return 0;
            }
          }
        }
      });
    }
  }, {
    key: "_findTargetZones",
    value: function e(t) {
      var i;
      if (t.redrop === R) {
        var s = this.instance.targetSelectors.map(function (e) {
          return e.selector;
        });
        s.push.apply(s, n(this.instance.sourceSelectors.map(function (e) {
          return e.selector;
        })));
        s.push(w);
        i = s.join(",");
      } else if (t.redrop === Z) {
        i = t.selector;
      } else if (t.redrop === J) {
        i = this.instance.sourceSelectors.map(function (e) {
          return e.selector;
        }).join(",");
      } else if (t.redrop === M) {
        i = this.instance.targetSelectors.map(function (e) {
          return e.selector;
        }).join(",");
      } else if (t.redrop === V) {
        var r = this.instance.targetSelectors.map(function (e) {
          return e.selector;
        });
        r.push.apply(r, n(this.instance.sourceSelectors.map(function (e) {
          return e.selector;
        })));
        i = r.join(",");
      }
      var o = Array.from(this.instance.getContainer().querySelectorAll(".jtk-surface"));
      var a = [];
      o.forEach(function (e) {
        var t = Array.from(e.querySelectorAll(i));
        a.push.apply(a, n(t));
      });
      var l = Array.from(this.instance.getContainer().querySelectorAll(i));
      return l.filter(function (e) {
        return a.indexOf(e) === -1;
      });
    }
  }, {
    key: "onStart",
    value: function e(t) {
      this.endpointDropTargets.length = 0;
      this.currentDropTarget = null;
      this._stopped = false;
      var n = t.drag.getDragElement();
      this.ep = n.jtk.endpoint;
      if (!this.ep) {
        return false;
      }
      this.endpointRepresentation = this.ep.endpoint;
      this.canvasElement = this.endpointRepresentation.canvas;
      this.jpc = k.connectorSelector(this.ep);
      var s = this._shouldStartDrag(),
        r = i(s, 2),
        o = r[0],
        a = r[1];
      if (o === false) {
        this._stopped = true;
        return false;
      }
      this.instance.setHover(this.ep, false);
      this.instance.isConnectionBeingDragged = true;
      if (this.jpc && !k.isFull(this.ep) && this.ep.isSource) {
        this.jpc = null;
      }
      this._createFloatingEndpoint(this.canvasElement);
      this._populateTargets(this.canvasElement, t.e);
      if (this.jpc == null) {
        this.startNewConnectionDrag(this.ep.scope, a);
      } else {
        this.startExistingConnectionDrag();
      }
      this._registerFloatingConnection(this.placeholderInfo, this.jpc);
      this.instance.currentlyDragging = true;
    }
  }, {
    key: "onBeforeStart",
    value: function e(t) {
      this.payload = t.e.payload || {};
    }
  }, {
    key: "onDrag",
    value: function e(t) {
      if (this._stopped) {
        return true;
      }
      if (this.placeholderInfo.element) {
        var n = W(this.floatingElement, this.instance.getEffectiveVisibleZoom());
        this.instance.setElementPosition(this.placeholderInfo.element, t.pos.x, t.pos.y);
        var i = {
            x: t.pos.x,
            y: t.pos.y,
            w: n.w,
            h: n.h
          },
          s,
          r,
          o;
        for (var l = 0; l < this.endpointDropTargets.length; l++) {
          if (B(i, this.endpointDropTargets[l].r)) {
            s = this.endpointDropTargets[l];
            break;
          }
        }
        if (s !== this.currentDropTarget && this.currentDropTarget != null) {
          r = this._getFloatingAnchorIndex();
          this.instance.removeClass(this.currentDropTarget.el, a);
          if (this.currentDropTarget.endpoint) {
            this.currentDropTarget.endpoint.endpoint.removeClass(this.instance.endpointDropAllowedClass);
            this.currentDropTarget.endpoint.endpoint.removeClass(this.instance.endpointDropForbiddenClass);
          }
          this.floatingAnchor.out();
        }
        if (s != null) {
          v(s.el, a);
          r = this._getFloatingAnchorIndex();
          if (s.endpoint != null) {
            o = s.endpoint.isSource && r === 0 || s.endpoint.isTarget && r !== 0 || this.jpc.suspendedEndpoint && s.endpoint.referenceEndpoint && s.endpoint.referenceEndpoint.id === this.jpc.suspendedEndpoint.id;
            if (o) {
              var c = this.instance.beforeDrop({
                dropEndpoint: s.endpoint,
                connection: this.jpc
              });
              if (c) {
                s.endpoint.endpoint.addClass(this.instance.endpointDropAllowedClass);
                s.endpoint.endpoint.removeClass(this.instance.endpointDropForbiddenClass);
              } else {
                s.endpoint.endpoint.removeClass(this.instance.endpointDropAllowedClass);
                s.endpoint.endpoint.addClass(this.instance.endpointDropForbiddenClass);
              }
              this.floatingAnchor.over(s.endpoint);
              this.instance._paintConnection(this.jpc);
            } else {
              s = null;
            }
          }
        }
        this.currentDropTarget = s;
        this.instance.fire(h, this.jpc);
      }
    }
  }, {
    key: "_maybeCleanup",
    value: function e(t) {
      if (t._mtNew && t.connections.length === 0) {
        this.instance.deleteEndpoint(t);
      } else {
        delete t._mtNew;
      }
    }
  }, {
    key: "_reattachOrDiscard",
    value: function e(t) {
      var n = this.jpc.suspendedEndpoint != null;
      var i = this._getFloatingAnchorIndex();
      if (n && this._shouldReattach()) {
        if (i === 0) {
          this.jpc.source = this.jpc.suspendedElement;
          this.jpc.sourceId = this.jpc.suspendedElementId;
        } else {
          this.jpc.target = this.jpc.suspendedElement;
          this.jpc.targetId = this.jpc.suspendedElementId;
        }
        this._doForceReattach(i);
        return true;
      } else {
        this._discard(i, t);
        return false;
      }
    }
  }, {
    key: "onStop",
    value: function e(t) {
      var n = this;
      var i = t.e;
      this.instance.isConnectionBeingDragged = false;
      this.instance.currentlyDragging = false;
      var s = H(a, o);
      var r = this.instance.getContainer().querySelectorAll(ee);
      y(r, function (e) {
        n.instance.removeClass(e, s);
      });
      if (this.jpc && this.jpc.endpoints != null) {
        var l = this.jpc.suspendedEndpoint != null;
        var c = this._getFloatingAnchorIndex();
        var p = this.jpc.suspendedEndpoint;
        var d;
        if (this.currentDropTarget != null) {
          d = this._getDropEndpoint(t, this.jpc);
          if (d == null) {
            this._reattachOrDiscard(t.e);
          } else {
            if (p && p.id === d.id) {
              this._doForceReattach(c);
            } else {
              if (!d.enabled) {
                this._reattachOrDiscard(t.e);
              } else if (k.isFull(d)) {
                this._reattachOrDiscard(t.e);
              } else {
                if (c === 0) {
                  this.jpc.source = d.element;
                  this.jpc.sourceId = d.elementId;
                } else {
                  this.jpc.target = d.element;
                  this.jpc.targetId = d.elementId;
                }
                var h = true;
                if (l && this.jpc.suspendedEndpoint.id !== d.id) {
                  if (!this.instance.beforeDetach(this.jpc)) {
                    h = false;
                  }
                }
                if (h) {
                  this._drop(d, c, i, h);
                } else {
                  this._reattachOrDiscard(t.e);
                }
              }
            }
          }
        } else {
          this._reattachOrDiscard(t.e);
        }
        this.instance._refreshEndpoint(this.ep);
        k.removeClass(this.ep, this.instance.draggingClass);
        this._cleanupDraggablePlaceholder();
        K.removeClass(this.jpc, this.instance.draggingClass);
        delete this.jpc.suspendedEndpoint;
        delete this.jpc.suspendedElement;
        delete this.jpc.suspendedElementType;
        delete this.jpc.suspendedElementId;
        delete this.jpc.suspendedIndex;
        delete this.floatingId;
        delete this.floatingIndex;
        delete this.floatingElement;
        delete this.floatingEndpoint;
        delete this.floatingAnchor;
        delete this.jpc.pending;
        if (d != null) {
          this._maybeCleanup(d);
        }
        this.instance.fire(u, this.jpc);
      }
    }
  }, {
    key: "_getSourceDefinition",
    value: function e(t) {
      var n;
      var i = this.instance.getContainer();
      for (var s = 0; s < this.instance.sourceSelectors.length; s++) {
        n = this.instance.sourceSelectors[s];
        if (n.isEnabled()) {
          var r = $(t, i, n.selector, this.instance, n.exclude);
          if (r !== false) {
            return n;
          }
        }
      }
    }
  }, {
    key: "_getDropEndpoint",
    value: function e(t, n) {
      var i;
      if (this.currentDropTarget.endpoint == null) {
        var s = this.currentDropTarget.def;
        var r = t.e.target || t.e.srcElement;
        if (s == null) {
          return null;
        }
        var o = this.currentDropTarget.targetEl;
        var a = l(t.e, o, this.instance.getEffectiveVisibleZoom());
        var c = this.instance._deriveEndpointAndAnchorSpec(X.getType(n).join(" "), true);
        var p = c.endpoints ? j(t, {
          endpoint: s.def.endpoint || c.endpoints[1],
          cssClass: s.def.cssClass || "",
          source: s.def.source === true,
          target: s.def.target === true
        }) : t;
        var d = this.instance.validAnchorsSpec(c.anchors) ? c.anchors : this.instance.areDefaultAnchorsSet() ? this.instance.defaults.anchors : null;
        var h = s.def.anchor;
        var u = s.def.anchorPositionFinder ? s.def.anchorPositionFinder(o, a, s.def, t.e) : null;
        var f = u != null ? u : h != null ? h : d != null && d[1] != null ? d[1] : null;
        if (f != null) {
          p = j(p, {
            anchor: f
          });
        }
        if (s.def.portId != null) {
          p.portId = s.def.portId;
        }
        delete p.drag;
        delete p.selection;
        var g = s.def.parameterExtractor ? s.def.parameterExtractor(this.currentDropTarget.el, r, t.e) : {};
        p = E(p, g);
        p.element = o;
        i = this.instance._internal_newEndpoint(p);
        i._mtNew = true;
        i.deleteOnEmpty = true;
        if (s.def.parameters) {
          X.mergeParameters(i, s.def.parameters);
        }
        if (s.def.extract) {
          var m = {};
          for (var v in s.def.extract) {
            var _ = this.currentDropTarget.el.getAttribute(v);
            if (_) {
              m[s.def.extract[v]] = _;
            }
          }
          X.mergeParameters(i, m);
        }
      } else {
        i = this.currentDropTarget.endpoint;
      }
      if (i) {
        k.removeClass(i, [this.instance.endpointDropAllowedClass, this.instance.endpointDropForbiddenClass].join(" "));
      }
      return i;
    }
  }, {
    key: "_doForceReattach",
    value: function e(t) {
      k.detachFromConnection(this.floatingEndpoint, this.jpc, null, true);
      this.jpc.endpoints[t] = this.jpc.suspendedEndpoint;
      this.instance.setHover(this.jpc, false);
      this.jpc._forceDetach = true;
      k.addConnection(this.jpc.suspendedEndpoint, this.jpc);
      this.instance.sourceOrTargetChanged(this.floatingId, this.jpc.suspendedEndpoint.elementId, this.jpc, this.jpc.suspendedEndpoint.element, t);
      this.instance.deleteEndpoint(this.floatingEndpoint);
      this.instance.repaint(this.jpc.source);
      delete this.jpc._forceDetach;
    }
  }, {
    key: "_shouldReattach",
    value: function e() {
      if (U(this.jpc) || this.jpc._forceReattach) {
        return true;
      } else {
        return !this.instance.beforeDetach(this.jpc);
      }
    }
  }, {
    key: "_discard",
    value: function e(t, n) {
      if (this.jpc.pending) {
        this.instance.fire(f, this.jpc, n);
      } else {
        if (t === 0) {
          this.jpc.source = this.jpc.suspendedEndpoint.element;
          this.jpc.sourceId = this.jpc.suspendedEndpoint.elementId;
        } else {
          this.jpc.target = this.jpc.suspendedEndpoint.element;
          this.jpc.targetId = this.jpc.suspendedEndpoint.elementId;
        }
        this.jpc.endpoints[t] = this.jpc.suspendedEndpoint;
      }
      if (this.floatingEndpoint) {
        k.detachFromConnection(this.floatingEndpoint, this.jpc);
      }
      this.instance.deleteConnection(this.jpc, {
        originalEvent: n,
        force: true
      });
    }
  }, {
    key: "_drop",
    value: function e(t, n, i, s) {
      k.detachFromConnection(this.jpc.endpoints[n], this.jpc);
      if (this.jpc.suspendedEndpoint) {
        k.detachFromConnection(this.jpc.suspendedEndpoint, this.jpc);
      }
      this.jpc.endpoints[n] = t;
      k.addConnection(t, this.jpc);
      if (this.jpc.suspendedEndpoint) {
        var r = this.jpc.suspendedEndpoint.elementId;
        this.instance.fireMoveEvent({
          index: n,
          originalSourceId: n === 0 ? r : this.jpc.sourceId,
          newSourceId: n === 0 ? t.elementId : this.jpc.sourceId,
          originalTargetId: n === 1 ? r : this.jpc.targetId,
          newTargetId: n === 1 ? t.elementId : this.jpc.targetId,
          originalEndpoint: this.jpc.suspendedEndpoint,
          connection: this.jpc,
          newEndpoint: t
        }, i);
      }
      if (n === 1) {
        this.instance.sourceOrTargetChanged(this.floatingId, this.jpc.targetId, this.jpc, this.jpc.target, 1);
      } else {
        this.instance.sourceOrTargetChanged(this.floatingId, this.jpc.sourceId, this.jpc, this.jpc.source, 0);
      }
      var o = this.jpc.endpoints[0].element;
      if (this.jpc.endpoints[0].finalEndpoint) {
        var a = this.jpc.endpoints[0];
        k.detachFromConnection(a, this.jpc);
        this.jpc.endpoints[0] = this.jpc.endpoints[0].finalEndpoint;
        k.addConnection(this.jpc.endpoints[0], this.jpc);
      }
      if (I(s)) {
        X.mergeData(this.jpc, s);
      }
      if (this._originalAnchorSpec) {
        k.setAnchor(this.jpc.endpoints[0], this._originalAnchorSpec);
        this._originalAnchorSpec = null;
      }
      this.instance.setHover(this.jpc, false);
      this.instance._finaliseConnection(this.jpc, null, i);
      this.instance.revalidate(o);
    }
  }, {
    key: "_registerFloatingConnection",
    value: function e(t, n) {
      this.floatingConnections[t.id] = n;
    }
  }, {
    key: "_getFloatingAnchorIndex",
    value: function e() {
      return this.floatingIndex == null ? 1 : this.floatingIndex;
    }
  }]);
  return S;
}();
export { ne as EndpointDragHandler };