import { defineProperty as t, createClass as n, classCallCheck as o, slicedToArray as e } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { Endpoints as i } from "../endpoint/endpoint.js";
import { TOP as r, RIGHT as a, BOTTOM as c, LEFT as s, X_AXIS_FACES as u, Y_AXIS_FACES as h } from "../factory/definitions.js";
import { EVENT_INTERNAL_CONNECTION_DETACHED as l, EVENT_INTERNAL_ENDPOINT_UNREGISTERED as f } from "../event-constants.js";
import { EVENT_INTERNAL_ANCHOR_CHANGED as d, SOURCE as p, TARGET as v } from "../constants.js";
import { forEach as y, rotatePoint as g, extend as m, findWithFunction as _, removeWithFunction as x, uuid as L } from "../../util/util.js";
import { lineLength as k } from "../../util/geom.js";
import { _resetConnectorBounds as A, _computeConnector as C } from "../connector/connectors.js";
import { makeLightweightAnchorFromSpec as I } from "../factory/anchor-record-factory.js";
import { getDefaultFace as w, isEdgeSupported as E } from "../factory/continuous-anchors.js";
var P;
function F(t, n, o, e, i) {
  var r = o ? t.w : t.h;
  var a = o ? t.h : t.w;
  var c = [],
    s = r / (n.length + 1);
  for (var u = 0; u < n.length; u++) {
    var h = (u + 1) * s,
      l = e * a;
    if (i) {
      h = r - h;
    }
    var f = o ? h : l,
      d = t.x + f,
      p = f / t.w;
    var v = o ? l : h,
      y = t.y + v,
      m = v / t.h;
    if (t.r !== 0 && t.r != null) {
      var _ = g({
        x: d,
        y: y
      }, t.c, t.r);
      d = _.x;
      y = _.y;
    }
    c.push({
      x: d,
      y: y,
      xLoc: p,
      yLoc: m,
      c: n[u].c
    });
  }
  return c;
}
function M(t, n) {
  return n.theta - t.theta;
}
function j(t, n) {
  var o = t.theta < 0 ? -Math.PI - t.theta : Math.PI - t.theta,
    e = n.theta < 0 ? -Math.PI - n.theta : Math.PI - n.theta;
  return o - e;
}
var b = (P = {}, t(P, r, j), t(P, a, M), t(P, c, M), t(P, s, j), P);
function S(t) {
  return t.isContinuous === true;
}
function O(t) {
  return t.isContinuous === true;
}
function R(t) {
  return t.locations.length > 1;
}
function Y(t) {
  return [t.currentLocation, t.locations[t.currentLocation]];
}
var T = function () {
  function P(n) {
    var e = this;
    o(this, P);
    this.instance = n;
    t(this, "anchorLists", new Map());
    t(this, "anchorLocations", new Map());
    n.bind(l, function (t) {
      if (t.sourceEndpoint._anchor.isContinuous) {
        e._removeEndpointFromAnchorLists(t.sourceEndpoint);
      }
      if (t.targetEndpoint._anchor.isContinuous) {
        e._removeEndpointFromAnchorLists(t.targetEndpoint);
      }
    });
    n.bind(f, function (t) {
      e._removeEndpointFromAnchorLists(t);
    });
  }
  n(P, [{
    key: "getAnchorOrientation",
    value: function t(n) {
      var o = this.anchorLocations.get(n.id);
      return o ? [o.ox, o.oy] : [0, 0];
    }
  }, {
    key: "_distance",
    value: function t(n, o, e, i, r, a, c) {
      var s = i.x + n.x * r.w,
        u = i.y + n.y * r.h;
      if (a != null && a.length > 0) {
        var h = this.instance._applyRotations([s, u, 0, 0], a);
        s = h.x;
        u = h.y;
      }
      return Math.sqrt(Math.pow(o - s, 2) + Math.pow(e - u, 2));
    }
  }, {
    key: "_anchorSelector",
    value: function t(n, o, e, i, r, a, c, s) {
      var u = e.x + i.w / 2,
        h = e.y + i.h / 2;
      var l = -1,
        f = Infinity;
      for (var d = 0; d < c.length; d++) {
        var p = this._distance(c[d], u, h, n, o, r, a);
        if (p < f) {
          l = d + 0;
          f = p;
        }
      }
      return [l, c[l]];
    }
  }, {
    key: "_floatingAnchorCompute",
    value: function t(n, o) {
      var e = o.xy;
      var i = {
        curX: e.x + n.size.w / 2,
        curY: e.y + n.size.h / 2,
        x: 0,
        y: 0,
        ox: 0,
        oy: 0
      };
      return this._setComputedPosition(n, i);
    }
  }, {
    key: "_setComputedPosition",
    value: function t(n, o, e) {
      this.anchorLocations.set(n.id, o);
      n.computedPosition = o;
      if (e) {
        n.timestamp = e;
      }
      return o;
    }
  }, {
    key: "_computeSingleLocation",
    value: function t(n, o, e, i) {
      var r;
      var a = i.rotation;
      var c = {
        curX: o.x + n.x * e.w + n.offx,
        curY: o.y + n.y * e.h + n.offy,
        x: n.x,
        y: n.y,
        ox: 0,
        oy: 0
      };
      if (a != null && a.length > 0) {
        var s = [n.iox, n.ioy],
          u = {
            x: c.curX,
            y: c.curY,
            cr: 0,
            sr: 0
          };
        y(a, function (t) {
          u = g(u, t.c, t.r);
          var n = [Math.round(s[0] * u.cr - s[1] * u.sr), Math.round(s[1] * u.cr + s[0] * u.sr)];
          s = n.slice();
        });
        n.ox = s[0];
        n.oy = s[1];
        r = {
          curX: u.x,
          curY: u.y,
          x: n.x,
          y: n.y,
          ox: s[0],
          oy: s[1]
        };
      } else {
        n.ox = n.iox;
        n.oy = n.ioy;
        r = m({
          ox: n.iox,
          oy: n.ioy
        }, c);
      }
      return r;
    }
  }, {
    key: "_singleAnchorCompute",
    value: function t(n, o) {
      var i = o.xy,
        r = o.wh,
        a = o.timestamp,
        c = this.anchorLocations.get(n.id);
      if (c != null && a && a === n.timestamp) {
        return c;
      }
      var s = Y(n),
        u = e(s, 2);
      u[0];
      var h = u[1];
      c = this._computeSingleLocation(h, i, r, o);
      return this._setComputedPosition(n, c, a);
    }
  }, {
    key: "_setCurrentLocation",
    value: function t(n, o, e) {
      var i = n.currentLocation !== o;
      n.currentLocation = o;
      if (i && e) {
        this.instance.fire(d, {
          connector: e,
          connection: e.connection,
          edge: e.connection.edge,
          anchorIndex: o,
          anchor: n
        });
      }
    }
  }, {
    key: "_defaultAnchorCompute",
    value: function t(n, o) {
      var r;
      if (n.locations.length === 1) {
        return this._singleAnchorCompute(n, o);
      }
      var a = o.xy,
        c = o.wh,
        s = o.txy,
        u = o.twh;
      var h = Y(n),
        l = e(h, 2),
        f = l[0],
        d = l[1];
      if (n.locked || s == null || u == null) {
        r = this._computeSingleLocation(d, a, c, o);
      } else {
        var p = this._anchorSelector(a, c, s, u, o.rotation, o.tRotation, n.locations, o.otherAnchor),
          v = e(p, 2),
          y = v[0],
          g = v[1];
        if (y !== f) {
          n.cssClass = g.cls || n.cssClass;
          i._anchorLocationChanged(o.element, n);
          this._setCurrentLocation(n, y, o.connection.connector);
        }
        r = this._computeSingleLocation(g, a, c, o);
      }
      return this._setComputedPosition(n, r, o.timestamp);
    }
  }, {
    key: "_placeAnchors",
    value: function t(n, o) {
      var e = this;
      var i = this.instance.viewport.getPosition(n),
        u = function t(o, c, s, u, h, l) {
          if (s.length > 0) {
            var f = s.sort(b[o]),
              d = o === a || o === r,
              p = F(i, f, u, h, d);
            for (var v = 0; v < p.length; v++) {
              var y = p[v].c,
                g = y.endpoints[0].elementId === n,
                m = g ? y.endpoints[0] : y.endpoints[1];
              e._setComputedPosition(m._anchor, {
                curX: p[v].x,
                curY: p[v].y,
                x: p[v].xLoc,
                y: p[v].yLoc,
                ox: l[0],
                oy: l[1]
              });
            }
          }
        };
      u(c, i, o.bottom, true, 1, [0, 1]);
      u(r, i, o.top, true, 0, [0, -1]);
      u(s, i, o.left, false, 0, [-1, 0]);
      u(a, i, o.right, false, 1, [1, 0]);
    }
  }, {
    key: "_updateAnchorList",
    value: function t(n, o, e, i, r, a, c, s, u, h, l) {
      var f = i.endpoints[c],
        d = f.id,
        p = [1, 0][c],
        v = {
          theta: o,
          order: e,
          c: i,
          b: r,
          elId: a,
          epId: d
        },
        y = n[u],
        g = f._continuousAnchorEdge ? n[f._continuousAnchorEdge] : null,
        m;
      if (g) {
        var x = _(g, function (t) {
          return t.epId === d;
        });
        if (x !== -1) {
          g.splice(x, 1);
          for (var L = 0; L < g.length; L++) {
            m = g[L].c;
            if (m.placeholder !== true) {
              h.add(m);
            }
            l.add(g[L].c.endpoints[c]);
            l.add(g[L].c.endpoints[p]);
          }
        }
      }
      for (var k = 0; k < y.length; k++) {
        m = y[k].c;
        if (m.placeholder !== true) {
          h.add(m);
        }
        l.add(y[k].c.endpoints[c]);
        l.add(y[k].c.endpoints[p]);
      }
      {
        var A = s ? 0 : y.length;
        y.splice(A, 0, v);
      }
      f._continuousAnchorEdge = u;
    }
  }, {
    key: "_removeEndpointFromAnchorLists",
    value: function t(n) {
      var o = this.anchorLists.get(n.elementId);
      var e = 0;
      (function (t, n) {
        if (t) {
          var o = function t(o) {
            return o.epId === n;
          };
          x(t.top, o);
          x(t.left, o);
          x(t.bottom, o);
          x(t.right, o);
          e += t.top.length;
          e += t.left.length;
          e += t.bottom.length;
          e += t.right.length;
        }
      })(o, n.id);
      if (e === 0) {
        this.anchorLists.delete(n.elementId);
      }
      this.anchorLocations.delete(n._anchor.id);
    }
  }, {
    key: "computeAnchorLocation",
    value: function t(n, o) {
      var e;
      if (S(n)) {
        e = this.anchorLocations.get(n.id) || {
          curX: 0,
          curY: 0,
          x: 0,
          y: 0,
          ox: 0,
          oy: 0
        };
      } else if (O(n)) {
        e = this._floatingAnchorCompute(n, o);
      } else {
        e = this._defaultAnchorCompute(n, o);
      }
      n.timestamp = o.timestamp;
      return e;
    }
  }, {
    key: "computePath",
    value: function t(n, o) {
      var e = this.instance.viewport.getPosition(n.sourceId),
        i = this.instance.viewport.getPosition(n.targetId),
        r = n.endpoints[0],
        a = n.endpoints[1];
      var c = this.getEndpointLocation(r, {
          xy: e,
          wh: e,
          element: r,
          timestamp: o,
          rotation: this.instance._getRotations(n.sourceId)
        }),
        s = this.getEndpointLocation(a, {
          xy: i,
          wh: i,
          element: a,
          timestamp: o,
          rotation: this.instance._getRotations(n.targetId)
        });
      A(n.connector);
      C(n.connector, {
        sourcePos: c,
        targetPos: s,
        sourceEndpoint: n.endpoints[0],
        targetEndpoint: n.endpoints[1],
        strokeWidth: n.paintStyleInUse.strokeWidth,
        sourceInfo: e,
        targetInfo: i
      });
    }
  }, {
    key: "getEndpointLocation",
    value: function t(n, o) {
      o = o || {};
      var e = n._anchor;
      var i = this.anchorLocations.get(e.id);
      if (i == null || o.timestamp != null && e.timestamp !== o.timestamp) {
        i = this.computeAnchorLocation(e, o);
        this._setComputedPosition(e, i, o.timestamp);
      }
      return i;
    }
  }, {
    key: "getEndpointOrientation",
    value: function t(n) {
      return n._anchor ? this.getAnchorOrientation(n._anchor) : [0, 0];
    }
  }, {
    key: "setAnchorOrientation",
    value: function t(n, o) {
      var e = this.anchorLocations.get(n.id);
      if (e != null) {
        e.ox = o[0];
        e.oy = o[1];
      }
    }
  }, {
    key: "isDynamicAnchor",
    value: function t(n) {
      return n._anchor ? !S(n._anchor) && n._anchor.locations.length > 1 : false;
    }
  }, {
    key: "isFloating",
    value: function t(n) {
      return n._anchor ? O(n._anchor) : false;
    }
  }, {
    key: "prepareAnchor",
    value: function t(n) {
      return I(n);
    }
  }, {
    key: "redraw",
    value: function t(n, o, e) {
      var i = this;
      var a = new Set(),
        c = new Set(),
        s = new Set();
      if (!this.instance._suspendDrawing) {
        var u = this.instance.endpointsByElement[n] || [];
        o = o || L();
        var h = {},
          l,
          f;
        for (var d = 0; d < u.length; d++) {
          f = u[d];
          if (f.visible === false) {
            continue;
          }
          c.add(f);
          l = f._anchor;
          if (f.connections.length === 0) {
            if (S(l)) {
              if (!this.anchorLists.has(n)) {
                this.anchorLists.set(n, {
                  top: [],
                  right: [],
                  bottom: [],
                  left: []
                });
              }
              this._updateAnchorList(this.anchorLists.get(n), -Math.PI / 2, 0, {
                endpoints: [f, f],
                placeholder: true
              }, false, n, 0, false, w(l), a, c);
              s.add(n);
            }
          } else {
            for (var p = 0; p < f.connections.length; p++) {
              var v = f.connections[p],
                y = v.sourceId,
                g = v.targetId,
                m = S(v.endpoints[0]._anchor),
                _ = S(v.endpoints[1]._anchor);
              if (m || _) {
                var x = (v.endpoints[0]._anchor.faces || []).join("-"),
                  k = (v.endpoints[1]._anchor.faces || []).join("-"),
                  A = [y, x, g, k].join("-"),
                  C = h[A],
                  I = v.sourceId === n ? 1 : 0;
                if (m && !this.anchorLists.has(y)) {
                  this.anchorLists.set(y, {
                    top: [],
                    right: [],
                    bottom: [],
                    left: []
                  });
                }
                if (_ && !this.anchorLists.has(g)) {
                  this.anchorLists.set(g, {
                    top: [],
                    right: [],
                    bottom: [],
                    left: []
                  });
                }
                var E = this.instance.viewport.getPosition(g),
                  P = this.instance.viewport.getPosition(y);
                if (g === y && (m || _)) {
                  this._updateAnchorList(this.anchorLists.get(y), -Math.PI / 2, 0, v, false, g, 0, false, r, a, c);
                  this._updateAnchorList(this.anchorLists.get(g), -Math.PI / 2, 0, v, false, y, 1, false, r, a, c);
                } else {
                  var F = this.instance._getRotations(y);
                  var M = this.instance._getRotations(g);
                  if (!C) {
                    C = this._calculateOrientation(y, g, P, E, v.endpoints[0]._anchor, v.endpoints[1]._anchor, F, M, v);
                    h[A] = C;
                  }
                  if (m) {
                    this._updateAnchorList(this.anchorLists.get(y), C.theta, 0, v, false, g, 0, false, C.a[0], a, c);
                  }
                  if (_) {
                    this._updateAnchorList(this.anchorLists.get(g), C.theta2, -1, v, true, y, 1, true, C.a[1], a, c);
                  }
                }
                if (m) {
                  s.add(y);
                }
                if (_) {
                  s.add(g);
                }
                a.add(v);
                if (m && I === 0 || _ && I === 1) {
                  c.add(v.endpoints[I]);
                }
              } else {
                var j = f.connections[p].endpoints[v.sourceId === n ? 1 : 0],
                  b = j._anchor;
                if (R(b)) {
                  this.instance._paintEndpoint(j, {
                    elementWithPrecedence: n,
                    timestamp: o,
                    otherAnchor: b
                  });
                  a.add(f.connections[p]);
                  for (var O = 0; O < j.connections.length; O++) {
                    if (j.connections[O] !== f.connections[p]) {
                      a.add(j.connections[O]);
                    }
                  }
                } else {
                  a.add(f.connections[p]);
                }
              }
            }
          }
        }
        s.forEach(function (t) {
          i._placeAnchors(t, i.anchorLists.get(t));
        });
        c.forEach(function (t) {
          var n = i.instance.viewport.getPosition(t.elementId);
          i.instance._paintEndpoint(t, {
            timestamp: o,
            offset: n
          });
        });
        a.forEach(function (t) {
          i.instance._paintConnection(t, {
            timestamp: o
          });
        });
      }
      return {
        c: a,
        e: c
      };
    }
  }, {
    key: "reset",
    value: function t() {
      this.anchorLocations.clear();
      this.anchorLists.clear();
    }
  }, {
    key: "setAnchor",
    value: function t(n, o) {
      if (o != null) {
        n._anchor = o;
      }
    }
  }, {
    key: "setConnectionAnchors",
    value: function t(n, o) {
      n.endpoints[0]._anchor = o[0];
      n.endpoints[1]._anchor = o[1];
    }
  }, {
    key: "_calculateOrientation",
    value: function n(o, e, i, u, h, l, f, d, y) {
      var g = this;
      var m = {
        HORIZONTAL: "horizontal",
        VERTICAL: "vertical",
        DIAGONAL: "diagonal",
        IDENTITY: "identity"
      };
      if (o === e) {
        return {
          orientation: m.IDENTITY,
          a: [r, r]
        };
      }
      var _ = Math.atan2(u.c.y - i.c.y, u.c.x - i.c.x),
        x = Math.atan2(i.c.y - u.c.y, i.c.x - u.c.x);
      var L = [],
        A = {};
      (function (n, o) {
        for (var e = 0; e < n.length; e++) {
          var i;
          A[n[e]] = (i = {}, t(i, s, {
            x: o[e][0].x,
            y: o[e][0].c.y
          }), t(i, a, {
            x: o[e][0].x + o[e][0].w,
            y: o[e][0].c.y
          }), t(i, r, {
            x: o[e][0].c.x,
            y: o[e][0].y
          }), t(i, c, {
            x: o[e][0].c.x,
            y: o[e][0].y + o[e][0].h
          }), i);
          if (o[e][1] != null && o[e][1].length > 0) {
            for (var u in A[n[e]]) {
              A[n[e]][u] = g.instance._applyRotationsXY(A[n[e]][u], o[e][1]);
            }
          }
        }
      })([p, v], [[i, f], [u, d]]);
      var C = [r, s, a, c];
      for (var I = 0; I < C.length; I++) {
        for (var w = 0; w < C.length; w++) {
          L.push({
            source: C[I],
            target: C[w],
            dist: k(A.source[C[I]], A.target[C[w]])
          });
        }
      }
      L.sort(function (n, o) {
        if (n.dist < o.dist) {
          return -1;
        } else if (o.dist < n.dist) {
          return 1;
        } else {
          var e;
          var i = (e = {}, t(e, s, 0), t(e, r, 1), t(e, a, 2), t(e, c, 3), e),
            u = i[n.source],
            h = i[o.source],
            l = i[n.target],
            f = i[o.target];
          return u < h ? -1 : h < u ? 1 : l < f ? -1 : f < l ? 1 : 0;
        }
      });
      var P = L[0].source,
        F = L[0].target;
      for (var M = 0; M < L.length; M++) {
        if (S(h) && h.locked) {
          P = h.currentFace;
        } else if (!h.isContinuous || E(h, L[M].source)) {
          P = L[M].source;
        } else {
          P = null;
        }
        if (l.isContinuous && l.locked) {
          F = l.currentFace;
        } else if (!l.isContinuous || E(l, L[M].target)) {
          F = L[M].target;
        } else {
          F = null;
        }
        if (P != null && F != null) {
          break;
        }
      }
      if (h.isContinuous) {
        this._setCurrentContinuousAnchorFace(h, P, y.connector, 0);
      }
      if (l.isContinuous) {
        this._setCurrentContinuousAnchorFace(l, F, y.connector, 1);
      }
      return {
        a: [P, F],
        theta: _,
        theta2: x
      };
    }
  }, {
    key: "_setCurrentContinuousAnchorFace",
    value: function t(n, o, e, i, r) {
      var a = n.currentFace != null && n.currentFace !== o;
      var c = false;
      n.currentFace = o;
      if (r && n.lockedFace != null) {
        n.lockedFace = n.currentFace;
        c = true;
      }
      if (a) {
        this.instance.fire("internal.anchor:changed", {
          connector: e,
          connection: e.connection,
          edge: e.connection.edge,
          face: o,
          locked: c,
          anchorIndex: i,
          a: n
        });
      }
    }
  }, {
    key: "lock",
    value: function t(n) {
      n.locked = true;
      if (S(n)) {
        n.lockedFace = n.currentFace;
      }
    }
  }, {
    key: "unlock",
    value: function t(n) {
      n.locked = false;
      if (S(n)) {
        n.lockedFace = null;
      }
    }
  }, {
    key: "selectAnchorLocation",
    value: function t(n, o) {
      var e = _(n.locations, function (t) {
        return t.x === o.x && t.y === o.y;
      });
      if (e !== -1) {
        n.currentLocation = e;
        return true;
      } else {
        return false;
      }
    }
  }, {
    key: "lockCurrentAxis",
    value: function t(n) {
      if (n.currentFace != null) {
        n.lockedAxis = n.currentFace === s || n.currentFace === a ? u : h;
      }
    }
  }, {
    key: "unlockCurrentAxis",
    value: function t(n) {
      n.lockedAxis = null;
    }
  }, {
    key: "anchorsEqual",
    value: function t(n, o) {
      if (!n || !o) {
        return false;
      }
      var e = n.locations[n.currentLocation],
        i = o.locations[o.currentLocation];
      return e.x === i.x && e.y === i.y && e.offx === i.offx && e.offy === i.offy && e.ox === i.ox && e.oy === i.oy;
    }
  }]);
  return P;
}();
export { T as LightweightRouter, S as isContinuous, R as isDynamic, O as isFloating };