import { Injectable } from '@angular/core';
import { IConsequenceModel } from '@core/models';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConsequenceService {

  public saveTrigger = new Subject<boolean>();
  public updatedObjectNames = new Subject<IConsequenceModel[]>();  
  public updatedFormsValid = new Subject<Map<string, boolean>>();
  public formsValid = new Map<string, boolean>();
  
  public formData: Record<string, any>[] = [];

  public dataArray: string[] = [];

  private _numOfObjects: number;

  public getData(): Observable<string> {
    this.dataArray = [];
    this.saveTrigger.next(true);
    return new Observable(observer => {
      const interval = setInterval(() => {
        if (this.dataArray.length >= this._numOfObjects) {
          clearInterval(interval);
          observer.next(`[${this.dataArray.join(',')}]`);
          observer.complete();
        }
      });      
    });
  }

  public setNumOfObjects(mainObjects: IConsequenceModel[]) {
    this._numOfObjects = mainObjects.length;
    this.updatedObjectNames.next(mainObjects);
  }

  public hasObjects(): boolean {
    return !!this._numOfObjects;
  }
}
