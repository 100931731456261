import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy } from '@angular/core';
import { Base, Edge, Inspector, Node, Vertex } from '@jsplumbtoolkit/browser-ui';
import { jsPlumbService } from '@jsplumbtoolkit/browser-ui-angular';
import { ArchimateService } from '@core/services/archimate.service';
import { ARCHIMATE_RELATIONSHIP_OPTIONS } from '@core/constants/jsplumb-constants';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { ICaseListAction } from '@core/models';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MatOption } from '@angular/material/core';
import edgeMappings from '../edge-mappings';
import { ButtonComponent } from '../../../elements/button/button.component';
import { FormComponent } from '../../../../core/form/form.component';

@Component({
  selector: 'app-archimate-inspector',
  templateUrl: './archimate-inspector.component.html',
  styleUrl: './archimate-inspector.component.scss',
  standalone: true,
  imports: [ButtonComponent, MatAutocompleteTrigger, MatAutocomplete, MatOption, FormComponent]
})
export class ArchimateInspectorComponent implements AfterViewInit, OnDestroy {

  @Input() public surfaceId: string;

  public obj: Base;
  public currentType = '';
  public edgeMappings = edgeMappings();
  public inspector: Inspector;
  public title: string;
  public relations = ARCHIMATE_RELATIONSHIP_OPTIONS;
  public filteredRelations: {label: string; name: string}[] = [];
  public updateAction: ICaseListAction;
  public deleteAction: ICaseListAction;

  public isJunction = false;
  public isJunctionSource = false;

  private readonly subs: Subscription[] = [];

  constructor(
    private readonly $jsplumb: jsPlumbService,
    private readonly el: ElementRef,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly archimateService: ArchimateService,
    private readonly http: HttpClient,
    private readonly translate: TranslateService
  ) { }

  public ngAfterViewInit(): void {
    this.$jsplumb.getSurface(this.surfaceId, surface => {
      this.inspector = new Inspector({
        container: this.el.nativeElement,
        surface,
        renderEmptyContainer: () => {
          this.currentType = '';
        },
        refresh: async (obj: Base, cb: () => void) => {
          this.obj = obj;
          this.currentType = obj.objectType;
          this.title = this.translate.instant(`archimate.${obj.type.split('_')[1]}`);
          this.updateAction = obj.data.actions.find((x: ICaseListAction) => x.name.includes('update'));
          this.deleteAction = obj.data.actions.find((x: ICaseListAction) => x.name.includes('delete'));
          if (this.currentType === 'Node') {
            const node = obj as Node;
            this.isJunction = node.type === 'Archimate_Junction';
            if (this.isJunction && node.edges?.length > 1) {
              const foundSource = node.edges.find(edge => edge.target.id === node.id)?.source;
              const foundTarget = node.edges.find(edge => edge.source.id === node.id)?.target;
              if (!!foundSource && !!foundTarget) this.filteredRelations = await this.getAllowedRelations(foundSource.type, foundTarget.type);
            }
          } else {
            const edge = obj as Edge;
            this.isJunctionSource = edge.source.type === 'Archimate_Junction';
            const source = edge.source.type === 'Archimate_Junction' ? this.getJunctionSourceType(edge.source) : edge.source.type;
            const target = edge.target.type === 'Archimate_Junction' ? this.getJunctionTargetType(edge.target) : edge.target.type;
            if (!!source && !!target) this.filteredRelations = await this.getAllowedRelations(source, target);
          }
          setTimeout(cb, 0);
          this.changeDetector.detectChanges();
        }
      });
    });
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public edgeTypeChanged(event: MatAutocompleteSelectedEvent) {
    let label = '';
    const match = this.relations.find(opt => opt.label === event.option.value);
    if (!!match) label = match.name;
    this.archimateService.edgeTypeChanged$.next({id: this.obj.getFullId(), type: event.option.value, label: label});
  }

  public junctionTypeChanged(event: MatAutocompleteSelectedEvent) {
    this.archimateService.junctionTypeChanged$.next({id: this.obj.getFullId(), type: event.option.value});
  }

  public close() {
    this.archimateService.closeInspector$.next();
  }

  public remove() {
    this.archimateService.remove$.next(this.obj);
  }

  public getAllowedRelations(source: string, target: string): Promise<{label: string; name: string}[]> {
    return new Promise<{label: string; name: string}[]>(resolve => {
      const url = `/archimate?source=${source}&target=${target}`;
      this.http.get(url).subscribe((res: any) => {
        const allowedRelations = res as string[];
        resolve(this.relations.filter(rel => allowedRelations.includes(rel.label) || rel.label === 'Association'));
      });
    });
  }

  public getJunctionTargetType(junction: Vertex): string | undefined {
    const sourceEdges = junction.getSourceEdges();
    if (sourceEdges.length > 0) return sourceEdges[0].target.type;
    else return undefined;
  }

  public getJunctionSourceType(junction: Vertex): string | undefined {
    const targetEdges = junction.getTargetEdges();
    if (targetEdges.length > 0) return targetEdges[0].source.type;
    else return undefined;
  }
}
