import { fastTrim as n, forEach as t } from "../ui-core/util/util.js";
function r(t, e, u) {
  if (t == null) {
    return null;
  }
  if (e === "$data" || e == null) {
    return t;
  }
  var i = e.match(/^\{(.*)\}$/);
  if (i) {
    var l = {},
      f = i[1].split(",");
    for (var o = 0; o < f.length; o++) {
      var a = f[o].split(":"),
        c = r(t, a[1]);
      l[n(a[0])] = c || a[1].replace(/'/g, "");
    }
    return l;
  }
  e = e.replace(/\['([^']*)'\]/g, ".$1");
  var s = t,
    h = s,
    p = null;
  e.replace(/([^\.])+/g, function (n, t, r, e) {
    if (p != null) {
      return;
    }
    var i = n.match(/([^\[0-9]+){1}(\[)([0-9+])/),
      l = r + n.length >= e.length,
      f = function n() {
        return h[i[1]] || function () {
          h[i[1]] = [];
          return h[i[1]];
        }();
      };
    if (l) {
      if (i) {
        var o = f(),
          a = i[3];
        if (u == null) {
          p = o[a];
        } else {
          o[a] = u;
        }
      } else {
        if (u == null) {
          p = h[n];
        } else {
          h[n] = u;
        }
      }
    } else {
      if (i) {
        var c = f();
        h = c[i[3]] || function () {
          c[i[3]] = {};
          return c[i[3]];
        }();
      } else h = h[n] || function () {
        h[n] = {};
        return h[n];
      }();
    }
  });
  return p;
}
function e(n, r) {
  var e = {
    left: [],
    right: []
  };
  t(n, function (n) {
    if (r(n)) {
      e.left.push(n);
    } else {
      e.right.push(n);
    }
  });
  return e;
}
function u(t) {
  return t.filter(function (t) {
    return t != null && n(t).length > 0;
  });
}
function i(n, t) {
  for (var r = 0; r < n.length; r++) {
    var e = n[r];
    if (e == null || e.length === 0) {
      continue;
    } else {
      t(r, e);
    }
  }
}
function l(n, t, r) {
  var e = r.map(function (r, e) {
    var u = t.w * r.x,
      i = t.h * r.y,
      l = Math.sqrt(Math.pow(n.x - u, 2) + Math.pow(n.y - i, 2));
    return {
      p: r,
      idx: e,
      distance: l
    };
  });
  e.sort(function (n, t) {
    if (n.distance < t.distance) {
      return -1;
    } else {
      return 1;
    }
  });
  return e[0];
}
function f(n, t, r) {
  if (!n.has(t)) {
    n.set(t, r());
  }
  return n.get(t);
}
function o(n, t, r) {
  if (n[t] == null) {
    n[t] = r();
  }
  return n[t];
}
function a(n, t) {
  var r = n.prototype;
  while (r != null) {
    if (r instanceof t) {
      return true;
    }
    r = r.prototype;
  }
  return false;
}
export { r as data, i as eachNotEmpty, u as filterEmpty, l as findClosestPoint, f as getsert, a as isAssignableFrom, e as partition, o as recordGetsert };