<naris-card class="card">
  <h2 class="mb-4">{{'password.forgot' | translate}}</h2>
  <p class="mb-8">{{'password.forgot_fill_email' | translate}}</p>
  <form [formGroup]="forgotForm" (ngSubmit)="onSubmit()">
    <naris-form-field [label]="'email_address' | translate" [fixStyling]="true">
      <naris-input [placeholder]="'email_address' | translate" [control]="getFormControl('email')" />
    </naris-form-field>
    <naris-form-actions justify="right">
      <a [routerLink]="['/login']" class="naris-link">{{'go_back' | translate}}</a>
      <div class="mr-4"></div>
      <naris-button [disabled]="forgotForm.invalid" color="primary" id="PWForgotSendVerification">{{'send_verification' | translate}}</naris-button>
    </naris-form-actions>
  </form>
</naris-card>
