<h3 mat-dialog-title>
  {{ 'knowledgebase.reject_comment' | translate }}
  <naris-button
    mat-dialog-close
    color="transparent"
    icon="close"
    class="close"
    id="KnowledgebaseCommentClose"
  />
</h3>
<mat-dialog-content>
  <p>{{ 'knowledgebase.state_reason' | translate }}:</p>
  <form>
    <naris-inline-textarea
      #tooltip="matTooltip"
      [control]="commentControl"
      [maxLength]="500"
      rounded
      [matTooltip]="commentControl.value?.length + ' / 500'"
      matTooltipPosition="above"
      (inputChange)="tooltip.show()"
      id="commentControl"
    />
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <naris-button
    mat-dialog-close
    icon="close"
    color="danger"
    class="mr-2"
    [matTooltip]="'cancel' | translate"
    placement="left"
    id="KnowledgebaseCommentCancel"
  />
  <naris-button
    icon="check"
    color="success"
    class="ml-2"
    [matTooltip]="'submit' | translate | titlecase"
    placement="right"
    [disabled]="commentControl.invalid"
    (clicked)="onSubmit()"
    id="KnowledgebaseCommentSubmit"
  />
</mat-dialog-actions>
