import { isString as e, forEach as n, fromArray as t, fastTrim as l, log as r } from "../util/util.js";
function s(e, n, t) {
  t = t || e.parentNode;
  var l = t.querySelectorAll(n);
  for (var r = 0; r < l.length; r++) {
    if (l[r] === e) {
      return true;
    }
  }
  return false;
}
function i(e, n) {
  if (e.stopPropagation) {
    e.stopPropagation();
  } else {
    e.returnValue = false;
  }
  if (!n && e.preventDefault) {
    e.preventDefault();
  }
}
function u(e, n, t, l) {
  if (l && s(e, n, t)) {
    return e;
  } else {
    e = e.parentNode;
  }
  while (e != null && e !== t) {
    if (s(e, n)) {
      return e;
    } else {
      e = e.parentNode;
    }
  }
}
function a(e, n, t) {
  t = t || document.body;
  var l = [];
  var r = u(e, n, t, false);
  while (r != null) {
    l.push(r);
    r = u(r, n, t, false);
  }
  return l;
}
function o(e) {
  return e.srcElement || e.target;
}
function f(e, n, t) {
  n = l(n);
  if (typeof e.className.baseVal !== "undefined") {
    e.className.baseVal = n;
  } else {
    e.className = n;
  }
  try {
    var s = e.classList;
    if (s != null) {
      while (s.length > 0) {
        s.remove(s.item(0));
      }
      for (var i = 0; i < t.length; i++) {
        if (t[i]) {
          s.add(t[i]);
        }
      }
    }
  } catch (e) {
    r("WARN: cannot set class list", e);
  }
}
function c(e) {
  return e.className != null ? typeof e.className.baseVal === "undefined" ? e.className : e.className.baseVal : "";
}
function d(e, n, t) {
  var l = n == null ? [] : Array.isArray(n) ? n : n.split(/\s+/);
  var r = t == null ? [] : Array.isArray(t) ? t : t.split(/\s+/);
  var s = c(e),
    i = s.split(/\s+/);
  var u = function e(n, t) {
    for (var l = 0; l < t.length; l++) {
      if (n) {
        if (i.indexOf(t[l]) === -1) {
          i.push(t[l]);
        }
      } else {
        var r = i.indexOf(t[l]);
        if (r !== -1) {
          i.splice(r, 1);
        }
      }
    }
  };
  u(true, l);
  u(false, r);
  f(e, i.join(" "), i);
}
function p(n) {
  return !e(n) && !Array.isArray(n) && n.length != null && n.documentElement == null && n.nodeType == null;
}
function m(n) {
  return !e(n) && (Array.isArray(n) || p(n));
}
function v(e) {
  return c(e);
}
function h(e, t) {
  var r = function e(t, r) {
    if (t != null && r != null && r.length > 0) {
      if (t.classList) {
        var s = l(r).split(/\s+/);
        n(s, function (e) {
          t.classList.add(e);
        });
      } else {
        d(t, r);
      }
    }
  };
  if (p(e)) {
    n(e, function (e) {
      return r(e, t);
    });
  } else {
    r(e, t);
  }
}
function g(e, n) {
  if (e.classList) {
    return e.classList.contains(n);
  } else {
    return c(e).indexOf(n) !== -1;
  }
}
function y(e, t) {
  var r = function e(n, t) {
    if (n != null && t != null && t.length > 0) {
      if (n.classList) {
        var r = l(t).split(/\s+/);
        r.forEach(function (e) {
          n.classList.remove(e);
        });
      } else {
        d(n, null, t);
      }
    }
  };
  if (p(e)) {
    n(e, function (e) {
      return r(e, t);
    });
  } else {
    r(e, t);
  }
}
function L(e, t) {
  var l = this;
  var r = function e(n, t) {
    if (n != null && t != null && t.length > 0) {
      if (n.classList) {
        n.classList.toggle(t);
      } else {
        if (l.hasClass(n, t)) {
          l.removeClass(n, t);
        } else {
          l.addClass(n, t);
        }
      }
    }
  };
  if (p(e)) {
    n(e, function (e) {
      return r(e, t);
    });
  } else {
    r(e, t);
  }
}
function A(n, l) {
  var r = null;
  if (arguments.length === 1) {
    if (!e(n)) {
      var s = document.createDocumentFragment();
      s.appendChild(n);
      return t(s.childNodes);
    }
    r = t(document.querySelectorAll(n));
  } else {
    r = t(n.querySelectorAll(l));
  }
  return r;
}
function N(e, n, t, l) {
  return V(null, e, n, t, l);
}
function V(e, n, t, l, r) {
  var s = e == null ? document.createElement(n) : document.createElementNS(e, n);
  var i;
  t = t || {};
  for (i in t) {
    s.style[i] = t[i];
  }
  if (l) {
    h(s, l);
  }
  r = r || {};
  for (i in r) {
    s.setAttribute(i, "" + r[i]);
  }
  return s;
}
function S(e, n) {
  var t = e.getBoundingClientRect(),
    l = document.body,
    r = document.documentElement,
    s = window.pageYOffset || r.scrollTop || l.scrollTop,
    i = window.pageXOffset || r.scrollLeft || l.scrollLeft,
    u = r.clientTop || l.clientTop || 0,
    a = r.clientLeft || l.clientLeft || 0,
    o = t.top + s - u,
    f = t.left + i - a;
  return {
    x: n ? f : Math.round(f),
    y: n ? o : Math.round(o)
  };
}
var T;
(function (e) {
  e["SVG"] = "SVG";
  e["HTML"] = "HTML";
})(T || (T = {}));
function b(e) {
  return e == null ? T.HTML : e instanceof SVGElement ? T.SVG : T.HTML;
}
function w(e) {
  return b(e) === T.SVG;
}
export { T as ElementTypes, h as addClass, i as consume, N as createElement, V as createElementNS, u as findParent, a as findParents, v as getClass, b as getElementType, o as getEventSource, A as getSelector, g as hasClass, m as isArrayLike, p as isNodeList, w as isSVGElement, s as matchesSelector, S as offsetRelativeToRoot, y as removeClass, L as toggleClass };