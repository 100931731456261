import { Injectable } from '@angular/core';
import { ITreeViewItem } from '@core/models';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TreeviewService {
  
  public copyBufferItem: ITreeViewItem;
  public maxLevel = 0;
  public sentHref: string;
  
  public deleteItem$ = new Subject<ITreeViewItem>();
  public deselect$ = new Subject<boolean>();
  public expandCollapseLevel$ = new Subject<{ level: number; expand: boolean }>();
  public refresh$ = new Subject<void>();
  public reloadChildren$ = new Subject<number>();
  public resetContextMenu$ = new Subject<boolean>();
  public updateItem$ = new Subject<ITreeViewItem>();
  public updatedItemIds$ = new Subject<number[]>();
  
  public setItemChecked$ = new Subject<{ itemId: number | string; checked: boolean }>();
}
