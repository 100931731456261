import { Component } from '@angular/core';
import { IconService } from '@core/services';
import { UpdateService } from '@core/services/update.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public title = 'naris4-ng-frontend';

  constructor(
    private readonly iconService: IconService, 
    private readonly translate: TranslateService, 
    //! updateService nooit verwijderen!!!
    private readonly updateService: UpdateService
  ) {
    void this.clearSWCache();
    this.iconService.init();
    this.translate.setDefaultLang('en');
  }

  private async clearSWCache() {
    if ('caches' in window) {
      await caches.keys()
        .then(keyList => Promise.all(keyList.map(key => caches.delete(key))));
    }
  }
}
