/* eslint-disable no-console */
import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, first } from 'rxjs/operators';
// import { BASE_URL } from '@core/constants';
// import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  constructor(appRef: ApplicationRef, swUpdate: SwUpdate) {
    // if (environment.production && BASE_URL.includes('dev.naris')) {
    console.log('UpdateService initialized!');
    swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
    ).subscribe(console.dir);
    swUpdate.unrecoverable.subscribe(({ reason }) => console.log('Unrecoverable because: ', reason));
    appRef.isStable.pipe(first(isStable => !!isStable)).subscribe(() => swUpdate.checkForUpdate());
    // }
  }
}
