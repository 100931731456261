import { log as t } from "../../util/util.js";
import { RectangleEndpoint as r } from "./rectangle-endpoint.js";
import { TYPE_RECTANGLE_ENDPOINT as n, TYPE_DOT_ENDPOINT as e, TYPE_BLANK_ENDPOINT as i } from "./definitions.js";
import { DotEndpoint as o } from "./dot-endpoint.js";
import { BlankEndpoint as u } from "./blank-endpoint.js";
var a = {};
a[n] = {
  type: n,
  cls: r,
  compute: function t(r, n, e, i) {
    var o = i.width || r.width,
      u = i.height || r.height,
      a = n.curX - o / 2,
      c = n.curY - u / 2;
    r.x = a;
    r.y = c;
    r.w = o;
    r.h = u;
    return [a, c, o, u];
  },
  getParams: function t(r) {
    return {
      width: r.width,
      height: r.height
    };
  }
};
a[e] = {
  type: e,
  cls: o,
  compute: function t(r, n, e, i) {
    var o = n.curX - r.radius,
      u = n.curY - r.radius,
      a = r.radius * 2,
      c = r.radius * 2;
    if (i && i.stroke) {
      var s = i.strokeWidth || 1;
      o -= s;
      u -= s;
      a += s * 2;
      c += s * 2;
    }
    r.x = o;
    r.y = u;
    r.w = a;
    r.h = c;
    return [o, u, a, c, r.radius];
  },
  getParams: function t(r) {
    return {
      radius: r.radius
    };
  }
};
a[i] = {
  type: i,
  cls: u,
  compute: function t(r, n, e, i) {
    r.x = n.curX;
    r.y = n.curY;
    r.w = 10;
    r.h = 0;
    return [n.curX, n.curY, 10, 0];
  },
  getParams: function t(r) {
    return {};
  }
};
var c = {
  get: function t(r, n, e) {
    var i;
    var o = (i = a[n]) === null || i === void 0 ? void 0 : i.cls;
    if (!o) {
      throw {
        message: "jsPlumb: unknown endpoint type '" + n + "'"
      };
    } else {
      return new o(r, e);
    }
  },
  clone: function t(r) {
    var n = a[r.type];
    return c.get(r.endpoint, r.type, n.getParams(r));
  },
  compute: function r(n, e, i, o) {
    var u;
    var c = (u = a[n.type]) === null || u === void 0 ? void 0 : u.compute;
    if (c != null) {
      return c(n, e, i, o);
    } else {
      t("jsPlumb: cannot find endpoint calculator for endpoint of type ", n.type);
    }
  }
};
export { c as EndpointFactory };