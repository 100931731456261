<div class="inspector">
  @switch (currentType) {
    @case ('') {
      <div></div>
    }
    @case ('Node') {
      <div
        class="inspector__editor"
      >
        @if (!loading) {
          <div class="inspector__editor__header">
            <div class="inspector__editor__header__text">
              <div class="inspector__editor__header__text__edit">{{ 'inspector.title' | translate }}</div>
              <div class="inspector__editor__header__text__title">{{getInspectorTitle()}}</div>
            </div>
            <div class="inspector__editor__header__actions">
              @if (!!detachAction) {
                <naris-button
                  [icon]="getIconType(detachAction.name!)"
                  [matTooltip]="(detachAction.label || detachAction.name!) | translate"
                  matTooltipPosition="above"
                  matTooltipShowDelay="200"
                  size="small"
                  color="transparent"
                  [disabled]="!detachAction.href || detachAction.layouthint?.includes('readonly') || false"
                  (clicked)="$event.stopPropagation(); handleAction(detachAction)"
                  [ngStyle]="{'display': detachAction.layouthint?.includes('hide') && 'none'}"
                  [id]="'Inspector' + detachAction.name"
                />
              }
            </div>
          </div>
          @if (!loading) {
            <form 
              [formGroup]="inspectorForm"
            >
              @if (type === 'goal') {
                <div class="inspector__editor__field">
                  <label class="inspector__editor__field__label" for="nameInput">{{ 'title' | translate }}</label>
                  <div class="inspector__editor__field__input__wrapper">
                    <input 
                      type="text" 
                      class="inspector__editor__field__input" 
                      id="nameInput" 
                      formControlName="GoalName"
                      jtk-focus
                    />  
                  </div>
                </div>
        
                <div class="inspector__editor__field">
                  <label class="inspector__editor__field__label" for="descInput">{{ 'Description' | translate }}</label>
                  <div class="inspector__editor__field__input__wrapper">
                    <textarea
                      class="inspector__editor__field__input textarea"
                      id="descInput"
                      rows="5"
                      formControlName="GoalDescription"
                    ></textarea>
                  </div>
                </div>
              } @else {
                <div class="inspector__editor__field">
                  <label class="inspector__editor__field__label" for="nameInput">{{ 'title' | translate }}</label>
                  <div class="inspector__editor__field__input__wrapper">
                    <input 
                      type="text" 
                      class="inspector__editor__field__input" 
                      id="nameInput" 
                      formControlName="CriticalSuccessFactorName"
                      jtk-focus
                    />  
                  </div>
                </div>
        
                <div class="inspector__editor__field">
                  <label class="inspector__editor__field__label" for="descInput">{{ 'Description' | translate }}</label>
                  <div class="inspector__editor__field__input__wrapper">
                    <textarea
                      class="inspector__editor__field__input textarea"
                      id="descInput"
                      rows="5"
                      formControlName="CriticalSuccessFactorDescription"
                    ></textarea>
                  </div>
                </div>
              }
            </form>
          }
          
          <div class="inspector__editor__footer">
            <naris-button
              class="inspector__editor__footer__button"
              color="primary"
              (clicked)="submitForm()"
              id="InspectorSubmitForm"
            >{{'save' | translate}}</naris-button>
          </div>
        } @else {
          <div class="inspector__editor--loading">
            {{ 'strategy_map.loading-object-data' | translate }}
            <naris-bouncer />
          </div>
        }
      </div>
    }
  }
</div>
