var e = "click";
var o = "contextmenu";
var n = "dblclick";
var a = "dbltap";
var r = "focus";
var t = "mousedown";
var v = "mouseenter";
var c = "mouseexit";
var m = "mousemove";
var u = "mouseup";
var i = "mouseout";
var d = "mouseover";
var l = "tap";
var s = "touchstart";
var p = "touchend";
var b = "touchmove";
var g = "drag:move";
var k = "drag:stop";
var x = "drag:start";
var w = "revert";
var h = "connection:abort";
var f = "connection:drag";
var j = "connection:dragging";
var q = "connection:drag:end";
var y = "element:click";
var z = "element:dblclick";
var A = "element:dbltap";
var B = "element:mouseout";
var C = "element:mouseover";
var D = "element:mousemove";
var E = "element:mouseup";
var F = "element:mousedown";
var G = "element:contextmenu";
var H = "element:tap";
var I = "endpoint:click";
var J = "endpoint:dblclick";
var K = "endpoint:dbltap";
var L = "endpoint:mouseout";
var M = "endpoint:mouseover";
var N = "endpoint:mouseup";
var O = "endpoint:mousedown";
var P = "endpoint:tap";
var Q = "connection:click";
var R = "connection:dblclick";
var S = "connection:dbltap";
var T = "connection:mouseout";
var U = "connection:mouseover";
var V = "connection:mouseup";
var W = "connection:mousedown";
var X = "connection:contextmenu";
var Y = "connection:tap";
export { e as EVENT_CLICK, h as EVENT_CONNECTION_ABORT, Q as EVENT_CONNECTION_CLICK, X as EVENT_CONNECTION_CONTEXTMENU, R as EVENT_CONNECTION_DBL_CLICK, S as EVENT_CONNECTION_DBL_TAP, f as EVENT_CONNECTION_DRAG, j as EVENT_CONNECTION_DRAGGING, q as EVENT_CONNECTION_DRAG_END, W as EVENT_CONNECTION_MOUSEDOWN, T as EVENT_CONNECTION_MOUSEOUT, U as EVENT_CONNECTION_MOUSEOVER, V as EVENT_CONNECTION_MOUSEUP, Y as EVENT_CONNECTION_TAP, o as EVENT_CONTEXTMENU, n as EVENT_DBL_CLICK, a as EVENT_DBL_TAP, g as EVENT_DRAG_MOVE, x as EVENT_DRAG_START, k as EVENT_DRAG_STOP, y as EVENT_ELEMENT_CLICK, G as EVENT_ELEMENT_CONTEXTMENU, z as EVENT_ELEMENT_DBL_CLICK, A as EVENT_ELEMENT_DBL_TAP, F as EVENT_ELEMENT_MOUSE_DOWN, D as EVENT_ELEMENT_MOUSE_MOVE, B as EVENT_ELEMENT_MOUSE_OUT, C as EVENT_ELEMENT_MOUSE_OVER, E as EVENT_ELEMENT_MOUSE_UP, H as EVENT_ELEMENT_TAP, I as EVENT_ENDPOINT_CLICK, J as EVENT_ENDPOINT_DBL_CLICK, K as EVENT_ENDPOINT_DBL_TAP, O as EVENT_ENDPOINT_MOUSEDOWN, L as EVENT_ENDPOINT_MOUSEOUT, M as EVENT_ENDPOINT_MOUSEOVER, N as EVENT_ENDPOINT_MOUSEUP, P as EVENT_ENDPOINT_TAP, r as EVENT_FOCUS, t as EVENT_MOUSEDOWN, v as EVENT_MOUSEENTER, c as EVENT_MOUSEEXIT, m as EVENT_MOUSEMOVE, i as EVENT_MOUSEOUT, d as EVENT_MOUSEOVER, u as EVENT_MOUSEUP, w as EVENT_REVERT, l as EVENT_TAP, p as EVENT_TOUCHEND, b as EVENT_TOUCHMOVE, s as EVENT_TOUCHSTART };