import { Component, Input, ViewEncapsulation, input, effect } from '@angular/core';
import { ElementColors } from '@core/enums';
import { MatProgressBar } from '@angular/material/progress-bar';
import { NgClass } from '@angular/common';
import type { ProgressBarMode } from '@angular/material/progress-bar';

@Component({
  selector: 'naris-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: true,
  imports: [MatProgressBar, NgClass]
})
export class ProgressBarComponent {

  /**
   * The value to be shown in the progressbar (the indicator size) (default: 0)
   */
  public value = input.required<number>();

  /**
   * If the value should be between, for example 0 - 10, than maxValue should be set to 10
   * If the value is in the range of 0 - 100, maxValue doesn't need to be set (default: 100)
   */
  public maxValue = input.required<number>();

  /**
   * Should the progress-bar have rounded corners? (default: true)
   */
  @Input()
  public rounded = false;

  /**
   * Indicates what color the progressbar should be (default: gray)
   * options:
   *    - 'success'
   *    - 'warning'
   *    - 'danger'
   */
  @Input()
  public color: ElementColors;

  /**
   * show the value of the progress-bar behind the bar
   */
  @Input()
  public showValueAfter = false;

  /**
   * show the value of the progress-bar behind the bar including the max value
   */
  @Input()
  public showMaxValueAfter = false;

  /**
   * A text label above the progressbar
   */
  @Input()
  public label: string;

  /**
   * Set mode to 'determinate', 'indeterminate', 'buffer' or 'query', default: 'determinate'
   */
  @Input()
  public mode: ProgressBarMode = 'determinate';

  /**
   * Set size to 'normal' or 'small', default: 'normal'
   */
  @Input()
  public size = 'normal';

  /**
   * The calculated position of the progressbar
   */
  public percentage: number | null;

  constructor() {
    effect(() => this.setPercentage(this.value(), this.maxValue()));
  }

  /**
   * Calculates the value of the progressbar. If a maxValue is set to anything but 100, we need to recalculate the position of the progressbar
   */
  private setPercentage(value: number, maxValue:number) {
    if (this.mode === 'indeterminate') return this.percentage = null;
    this.percentage = maxValue !== 100 || !value ? value * 100 / maxValue : value;
  }
}
