import { inherits as r, createSuper as e, classCallCheck as t, defineProperty as i, assertThisInitialized as s } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { EndpointRepresentation as a } from "./endpoint-representation.js";
import { TYPE_BLANK_ENDPOINT as n } from "./definitions.js";
var o = function (a) {
  r(l, a);
  var o = e(l);
  function l(r, e) {
    var a;
    t(this, l);
    a = o.call(this, r, e);
    i(s(a), "type", n);
    return a;
  }
  return l;
}(a);
i(o, "type", n);
export { o as BlankEndpoint };