import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, of, BehaviorSubject, Subscription, Subject, identity } from 'rxjs';
import { catchError, finalize, map, skip } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { TableService, BeinformedService, FormService, UserService } from '@core/services';
import { OPS, TABLE_DEF } from '@core/constants';
import { FilterChip } from '@core/classes/filter-chip.class';
import { TranslateService } from '@ngx-translate/core';
import { TableSettingsService } from '@core/services/table-settings.service';
import type { FormInput } from '@core/classes/form-input.class';
import type { ITableDataMapped, IDataOptions, IPagingMeta, IMappedInput, TFilterVal, TInputType, IFilterMetaItem, ISortingMeta, IRootColumn, INarisOption, ICaseListResult, ITableConfig } from '@core/models';

export default class TableDataSource implements DataSource<any[]> {

  private readonly _data$ = new BehaviorSubject<any[]>([]);
  private readonly _tableData$ = new Subject<ITableDataMapped>();
  private readonly _loading$ = new Subject<boolean>();
  private readonly _refreshing$ = new Subject<boolean>();
  private readonly _error$ = new Subject<boolean>();
  private readonly _isEmpty$ = new Subject<boolean>();
  private readonly _numPages$ = new BehaviorSubject<number>(TABLE_DEF.MAX_PAGES);
  private readonly _totalResults$ = new BehaviorSubject<number>(0);
  private readonly _currentPage$ = new BehaviorSubject<number>(TABLE_DEF.PAGE);
  private readonly _currentPageLength$ = new BehaviorSubject<string>(TABLE_DEF.PAGESIZE.toString());
  private readonly _currentPageSlice$ = new BehaviorSubject<number[]>(TABLE_DEF.PAGE_SLICE);
  private readonly _pageSlices$ = new BehaviorSubject<number[][]>([TABLE_DEF.PAGE_SLICE]);
  private readonly _activeFilter$ = new BehaviorSubject<boolean>(false);
  private readonly _filterChips$ = new BehaviorSubject<FilterChip[]>([]);
  private readonly _sortingMeta$ = new Subject<any>();
  private readonly _tableConfiguration = new Subject<ITableConfig>;
  private readonly subs: Subscription[] = [];
  private allowExecution = true;
  private _optionsChanged = false;
  private readonly defaultDataOptions: IDataOptions = {
    page: TABLE_DEF.PAGE,
    pagesize: TABLE_DEF.PAGESIZE,
    filter: '',
    sort: ''
  };
  private prevDataOptions: IDataOptions = {};
  private allowedPaging = {};
  public tableConfiguration: ITableConfig = {} as ITableConfig;
  public activeFilters: FilterChip[] = [];
  public filterForm = new FormGroup({});
  public pages: { pageLength: number | string; data: Record<string, any>[] }[] = [];
  public dataUrl = '';
  public isModal = false;
  public pagingMeta: IPagingMeta = {
    page: TABLE_DEF.PAGE,
    maxpages: TABLE_DEF.MAX_PAGES,
    currentPageSlice: TABLE_DEF.PAGE_SLICE,
    pageSlices: [TABLE_DEF.PAGE_SLICE]
  };
  public filterMeta: TInputType[] = [];
  public sortingMeta = {
    sortedColumn: '',
    sortDirection: 'asc'
  };
  public resetPages = true;

  public isGridTable = false;

  get data$(): Observable<any[]> {
    return this._data$.asObservable();
  }

  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  get refreshing$(): Observable<boolean> {
    return this._refreshing$.asObservable();
  }

  get tableData$(): Observable<ITableDataMapped> {
    return this._tableData$.asObservable();
  }

  get error$(): Observable<boolean> {
    return this._error$.asObservable();
  }

  get isEmpty$(): Observable<boolean> {
    return this._isEmpty$.asObservable();
  }

  get numPages$(): Observable<number> {
    return this._numPages$.asObservable();
  }

  get totalResults$(): Observable<number> {
    return this._totalResults$.asObservable();
  }

  get currentPage$(): Observable<number> {
    return this._currentPage$.asObservable();
  }

  get currentPageLength$(): Observable<string> {
    return this._currentPageLength$.asObservable();
  }

  get currentPageSlice$(): Observable<number[]> {
    return this._currentPageSlice$.asObservable();
  }

  get pageSlices$(): Observable<number[][]> {
    return this._pageSlices$.asObservable();
  }

  get filterChips$(): Observable<FilterChip[]> {
    return this._filterChips$.asObservable();
  }

  get activeFilter$(): Observable<boolean> {
    return this._activeFilter$.asObservable();
  }

  get sortingMeta$(): Observable<any> {
    return this._sortingMeta$.asObservable();
  }

  constructor(
    private readonly tableService: TableService,
    private readonly beinformedService: BeinformedService,
    private readonly formService: FormService,
    private readonly userService: UserService,
    private readonly translateService: TranslateService,
    private readonly tableSettingsService: TableSettingsService
  ) {}

  public connect(_: CollectionViewer): Observable<any> {
    return this.data$;
  }

  public disconnect(_: CollectionViewer): void {
    this._data$.complete();
    this._tableData$.complete();
    this._filterChips$.complete();
    this._sortingMeta$.complete();
    this._loading$.complete();
    this._refreshing$.complete();
    this._isEmpty$.complete();
    this._error$.complete();
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public getTableData(url: string, isModal = false, dataOptions?: IDataOptions, refresh = false, separateObjectTypes?: boolean, ignoreDataOptions = false): Observable<ITableDataMapped> {
    const newDataOptions = {...this.defaultDataOptions, ...dataOptions} as IDataOptions;
    for (const key in newDataOptions) {
      const optKey = key as keyof IDataOptions;
      if (key !== 'page' && newDataOptions[optKey] !== this.prevDataOptions[optKey]) {
        if (!!this.pages.length && (!refresh || ['filter', 'sort', 'pagesize'].includes(key))) this.pages = [];
        if (Object.values(this.prevDataOptions) !== Object.values(newDataOptions)) this.prevDataOptions = newDataOptions;
      }
    }
    if (!refresh) {
      this.dataUrl = url;
      this.isModal = isModal;
      this._loading$.next(true);
      this._error$.next(false);
    } else this._refreshing$.next(true);
    // const loadedPage = this.getLoadedPage(dataOptions?.page ?? 0); // this.pages[dataOptions.page - 1];
    // if (!!loadedPage) {
    //   const currentPage = this.pages.indexOf(loadedPage) + 1;
    //   this.pagingMeta.page = currentPage;
    //   this._currentPage$.next(currentPage);
    //   this._currentPageLength$.next(loadedPage.pageLength.toString());
    //   this._data$.next(loadedPage.data);
    //   this._isEmpty$.next(!loadedPage.data.length);
    //   refresh ? this._refreshing$.next(false) : this._loading$.next(false);
    //   return of({} as ITableDataMapped);
    // } else {
    const reqUrl = ignoreDataOptions ? url : this.createURIString(url, dataOptions!);
    return this.beinformedService.fetchResponseWithContributions<'caselist'>(reqUrl, !this.isModal).pipe(
      catchError(() => this.handleError()),
      map((joinedRes: ICaseListResult) => {
        const data = this.tableService.createTableData(joinedRes, separateObjectTypes);
        if (!data) {
          this._error$.next(true);
          return {} as ITableDataMapped;
        }
        if (reqUrl.includes('collaboration') && (reqUrl.includes('session/current') || reqUrl.includes('session/potential')) && reqUrl.includes('consequences')) {
          const key = Object.keys(joinedRes.contributions.results)[0];
          const categories = joinedRes.data._embedded.results.map(res => JSON.parse(res[key]['consequenceCategories']));
          this.tableService.collabCategories$.next(categories);
        }
        this.setDataSource(data, refresh);
        return data;
      }),
      finalize(() => refresh ? this._refreshing$.next(false) : this._loading$.next(false))
    );
    // }
  }

  private setDataSource(data: ITableDataMapped, refresh: boolean): void {
    const { filterMeta, sortingMeta, pagingMeta, tableData, tableDataSource } = data;
    if (!!filterMeta?.length && !refresh) this.initFilters(filterMeta);
    if (!!sortingMeta?.initial) this.initSorting(sortingMeta);
    const pageSlices = this.setSlices(pagingMeta?.maxpages);
    const currentSliceIndex = pageSlices.findIndex(slice => slice.includes(pagingMeta?.page ?? -1));
    const pageLength = this.getPageLength(data);
    if (!!pagingMeta) this.initPaging(pagingMeta, pageSlices, pageLength, tableDataSource);
    this._totalResults$.next(pagingMeta?.totalresults ?? -1);
    this.pagingMeta.currentPageSlice = pageSlices[currentSliceIndex];
    this._currentPageSlice$.next(pageSlices[currentSliceIndex]);
    this._currentPage$.next(pagingMeta?.page ?? -1);
    this._numPages$.next(pagingMeta?.maxpages ?? -1);
    this._currentPageLength$.next(pageLength);
    this._tableData$.next(data);
    this._data$.next(tableDataSource);
    this._isEmpty$.next(!tableData.length);
    if (this.pagingMeta.pageSlices !== pageSlices) this._pageSlices$.next(pageSlices);
  }

  private createURIString(url: string, dataOptions: IDataOptions): string {
    let reqUrl = url;
    if (!!dataOptions) {
      let queryString = '';
      if (this.isModal) {
        const [originalUrl, lookupToken] = url.split('?');
        reqUrl = originalUrl;
        queryString = !!lookupToken ? `?${encodeURI(lookupToken)}` : '';
      } else if (url.includes('?')) {
        reqUrl = url.split('?')[0];
        if (!queryString) queryString = `?${url.split('?')[1]}`;
        else queryString += `&${url.split('?')[1]}`;
      }
      for (const key in dataOptions) {
        const optKey = key as keyof IDataOptions;
        if (!!dataOptions[optKey]) {
          let uriComp = `${key}=${dataOptions[optKey]}`;
          if (key === 'filter') uriComp = dataOptions[optKey] as string;
          queryString += `${!!queryString ? '&' : '?'}${['filter', 'sort'].includes(key) ? uriComp : encodeURI(uriComp)}`;
        }
      }
      reqUrl += queryString;
    }
    return reqUrl;
  }

  private handleError(): Observable<ICaseListResult> {
    if (!!this._error$) this._error$.next(true);
    return of({} as ICaseListResult);
  }

  private initFilters(filterMeta: IFilterMetaItem[]) {
    const excludeContext = !!this.userService.userData.sessiondata.WorkingContextIds ? ['PrimaryContext', 'SecondaryContext'] : [];
    const filterEls = filterMeta.filter(el => !['IsParent', 'ParentID', ...excludeContext].includes(el.name!));
    this.filterMeta = this.formService.mapElementsToInputs(filterEls, true).elements;
    this.filterMeta.forEach((input, i, arr) => {
      if (input.controlType === 'multi-input') input.children?.forEach(c => this.setControl(c));
      else this.setControl(input);
      if (i === arr.length - 1 && !!this.activeFilters.length) this._filterChips$.next(this.activeFilters);
      if (!!input.value?.length) this.handleFilter(input.value, input, false);
    });
  }

  private initSorting(sortingMeta: ISortingMeta) {
    const [ sortedCol, sortDir ] = sortingMeta.initial?.split(' ') || [];
    this.sortingMeta.sortedColumn = sortedCol || '';
    this.sortingMeta.sortDirection = sortDir || 'asc';
  }

  private initPaging(pagingMeta: IPagingMeta, pageSlices: number[][], pageLength: number | string, tableDataSource: Record<string, any>[]) {
    this.allowedPaging = pagingMeta;
    this.pagingMeta = {...this.pagingMeta, ...pagingMeta, pageSlices};
    if (!this.pages.length) this.pages = Array(pagingMeta.maxpages);
    const pageIndex = (pagingMeta?.page ?? 0) - 1;
    if (!this.pages[pageIndex]) this.pages.splice(pageIndex, 1, {pageLength, data: tableDataSource});
  }

  set optionsChanged(value: boolean) {
    this._optionsChanged = value;
    this.allowExecution = true;
  }
  get optionsChanged(): boolean {
    return this._optionsChanged;
  }
  public handleFilter(value: TFilterVal, input: FormInput, emit = true): void {
    this._error$.next(false);
    if (!this.allowExecution) return;
    this.allowExecution = this._optionsChanged;
    this.activeFilters = this.activeFilters.filter((f: any) => f.input.id !== input.id);
    if (!value) return this._filterChips$.next(this.activeFilters);
    const mappedInput: IMappedInput = {id: input.id, controlType: input.controlType, value: input.value, multiple: !!input.multiple};
    if (Array.isArray(value)) value.forEach(val => {
      const tmp = Object.keys(val).length === 2 && input.controlType === 'autocomplete' ? {value: (val as any).label, val: (val as any).value} : val;
      let chip = new FilterChip(input.label, (tmp as Record<string, unknown>).label, (tmp as Record<string, unknown>).value, mappedInput);
      if (!!(tmp as Record<string, any>).val)
        chip = new FilterChip(input.label, (tmp as Record<string, unknown>).value, (tmp as Record<string, unknown>).val, mappedInput);
      if (input.controlType !== 'autocomplete') {
        const option = input.options?.find(opt => opt.value === val);
        const chipValue = option?.code || option?.value || option?.key;
        chip = new FilterChip(input.label, option?.label, chipValue, mappedInput);
      }
      this.activeFilters.push(chip);
    });
    else {
      let chip = new FilterChip(input.label, value, value, mappedInput);
      if (input.parent?.controlType === 'multi-input') {
        const chipValue = input.inputType === 'date' ? DateTime.fromISO(value as string).toFormat('dd-MM-yyyy') : value;
        chip = new FilterChip(input.parent?.label || input.label, chipValue, value, mappedInput, OPS[input.operator as keyof typeof OPS]);
      } else switch (input.controlType) {
        case 'autocomplete': chip = new FilterChip(input.label, (value as Record<string, unknown>).label, (value as Record<string, unknown>).value, mappedInput); break;
        case 'date':
          const formattedDate = DateTime.fromISO(value as string).toFormat('dd-MM-yyyy');
          chip = new FilterChip(input.label, formattedDate, value, mappedInput);
          break;
        case 'radio':
          const foundOption = input.options?.find(option => option.value === value);
          chip = new FilterChip(input.label, value, foundOption?.key || value, mappedInput);
          break;
        case 'text':
          const control = this.filterForm.get(chip.input.id);
          if (control?.value !== value) control?.setValue(value);
        default: break;
      }
      this.activeFilters.push(chip);
    }
    if (emit) {
      const dataOptions = this.getCurrentDataOptions(false);
      this.tableConfiguration.tableUrl = this.dataUrl;
      if (Object.keys(dataOptions).length > 0) {
        this.tableConfiguration = this.tableSettingsService.saveOptionsConfiguration(this.dataUrl, this.tableConfiguration, dataOptions, this.activeFilters);
      }
      this._filterChips$.next(this.activeFilters);
    }
  }

  public removeChip = (chip: FilterChip) => {
    // Remove the chip from the filters
    this.activeFilters = this.activeFilters.filter((f: any) => f.input.id !== chip.input.id);
    // if (chip.input.controlType === 'text') 
    if (chip.input.controlType !== 'autocomplete') this._filterChips$.next(this.activeFilters);
    // Also update form control value
    const control = this.filterForm.get(chip.input.id) as FormControl & Record<string, any>;
    if (chip.input.controlType === 'autocomplete' && !!chip.input.multiple) {
      if (Array.isArray(control?.value)) {
        const filtered = control?.value.filter(v => v.value !== chip.val);
        control?.['setSelection'](filtered?.length === 0 ? null : filtered);
      } else control?.['setSelection']([]);
    } else if (Array.isArray(control?.value)) control?.setValue(control.value.filter((v: string) => v !== chip.val));
    else control?.setValue(null);
    const dataOptions = this.getCurrentDataOptions(false);
    this.tableConfiguration = this.tableSettingsService.saveOptionsConfiguration(this.dataUrl, this.tableConfiguration, dataOptions, this.activeFilters);
  };

  public createFilterString(chips: FilterChip[]) {
    const filterStringArr: string[] = [];
    chips.forEach(chip => {
      const existingIndex = filterStringArr.findIndex(item => item.includes(chip.input.id as string));
      if (chip.input.id === 'RetentionPeriodAmount') this.createRetentionPeriodAmountFilter(chip, filterStringArr);
      else if (existingIndex >= 0) filterStringArr[existingIndex] += encodeURIComponent(`,${chip.val}`);
      else filterStringArr.push(`${chip.input.id}=${chip.val}`);
    });
    return filterStringArr.join('&');
  }

  private createRetentionPeriodAmountFilter(chip: FilterChip, filterStringArr: string[]) {
    const keys = Object.keys(chip.value);
    keys.forEach((key: string) => {
      const existingIndex = filterStringArr.findIndex(item => item.includes(key));
      if (existingIndex >= 0) filterStringArr[existingIndex] += encodeURIComponent(`,${chip.val}`);
      else filterStringArr.push(`${key}=${chip.value[key]}`);
    });
  }

  public createSortString(columnId: string, direction: string) {
    if (!columnId || !direction) return '';
    return encodeURIComponent(`${columnId} ${direction}`);
  }

  private setSlices(maxpages = 1): number[][] {
    const numSlices = Math.ceil(maxpages / 10);
    const slices = Array(numSlices).fill([]);
    for (let i = 0; i < maxpages; i++) {
      const sliceIndex = Math.floor(i / 10);
      slices[sliceIndex] = [...slices[sliceIndex], i + 1];
    }
    return slices;
  }

  private getPageLength({tableDataSource, pagingMeta}: ITableDataMapped) {
    const maxpages = pagingMeta?.maxpages || 1;
    const total = pagingMeta?.totalresults;
    const pageLength = tableDataSource.length;
    const currentPage = pagingMeta?.page;
    if (maxpages <= 1) return pageLength?.toString();
    else if (currentPage === maxpages) return `${total! - (pageLength - 1)}-${total}`;
    else {
      const max = currentPage! * pageLength;
      const min = max - (pageLength - 1);
      return `${min}-${max}`;
    }
  }

  public sortColumn(column: IRootColumn): void {
    const sortedCol = this.sortingMeta.sortedColumn;
    const dir = this.sortingMeta.sortDirection;
    if (sortedCol !== column.id) {
      this.sortingMeta.sortDirection = 'asc';
      this.sortingMeta.sortedColumn = column.id.toString();
    } else this.sortingMeta.sortDirection = dir === 'asc' ? 'desc' : 'asc';
    this._sortingMeta$.next(this.sortingMeta);
    const dataOptions = this.getCurrentDataOptions();
    this.tableConfiguration.tableUrl = this.dataUrl;
    this.tableConfiguration = this.tableSettingsService.saveOptionsConfiguration(this.dataUrl, this.tableConfiguration, dataOptions, this.activeFilters);
    this.getTableData(this.dataUrl, this.isModal, dataOptions, true).subscribe();
  }

  public firstPage(pagesizeChange?: boolean): void {
    if (this.pagingMeta.page === 1 && !pagesizeChange) return;
    const firstSlice = this.pagingMeta.pageSlices?.[0];
    this.pagingMeta.currentPageSlice = firstSlice;
    this._currentPageSlice$.next(firstSlice!);
    const dataOptions = this.getCurrentDataOptions();
    this.getTableData(this.dataUrl, this.isModal, {...dataOptions, page: 1}, true).subscribe();
  }

  public lastPage(): void {
    const lastPage = this.pagingMeta.maxpages;
    if (!lastPage || this.pagingMeta.page === lastPage) return;
    const lastSlice = this.pagingMeta.pageSlices?.[this.pagingMeta.pageSlices.length - 1];
    this.pagingMeta.currentPageSlice = lastSlice;
    this._currentPageSlice$.next(lastSlice!);
    const dataOptions = this.getCurrentDataOptions();
    this.getTableData(this.dataUrl, this.isModal, {...dataOptions, page: lastPage}, true).subscribe();
  }

  public nextPage(): void {
    const nextPage = this.pagingMeta.page! + 1;
    if (nextPage > this.pagingMeta.maxpages!) return;
    if (!this.pagingMeta.currentPageSlice?.includes(nextPage)) this.toNextSlice();
    else {
      const dataOptions = this.getCurrentDataOptions();
      this.getTableData(this.dataUrl, this.isModal, {...dataOptions, page: nextPage}, true).subscribe();
    }
  }

  public previousPage(): void {
    const prevPage = this.pagingMeta.page! - 1;
    if (prevPage < 1) return;
    if (!this.pagingMeta.currentPageSlice?.includes(prevPage)) this.toPreviousSlice();
    else {
      const dataOptions = this.getCurrentDataOptions();
      this.getTableData(this.dataUrl, this.isModal, {...dataOptions, page: prevPage}, true).subscribe();
    }
  }

  public toPage(page: number): void {
    const dataOptions = this.getCurrentDataOptions();
    this.tableConfiguration.tableUrl = this.dataUrl;
    this.tableConfiguration = this.tableSettingsService.saveOptionsConfiguration(this.dataUrl, this.tableConfiguration, {...dataOptions, page}, this.activeFilters);
    this.getTableData(this.dataUrl, this.isModal, {...dataOptions, page}, true).subscribe();
  }

  public toNextSlice(): void {
    const nextSliceIndex = (this.pagingMeta.pageSlices?.findIndex(slice => slice.includes(this.pagingMeta.currentPageSlice![0])) ?? -1) + 1;
    const nextPageSlice = this.pagingMeta.pageSlices?.[nextSliceIndex];
    this.pagingMeta.currentPageSlice = nextPageSlice;
    this._currentPageSlice$.next(nextPageSlice!);
    const newPage = nextPageSlice![0];
    this.toPage(newPage);
  }

  public toPreviousSlice(): void {
    const prevSliceIndex = (this.pagingMeta.pageSlices?.findIndex(slice => slice.includes(this.pagingMeta.currentPageSlice![0])) ?? 1) - 1;
    const prevPageSlice = this.pagingMeta.pageSlices?.[prevSliceIndex];
    this.pagingMeta.currentPageSlice = prevPageSlice;
    this._currentPageSlice$.next(prevPageSlice!);
    const newPage = prevPageSlice![prevPageSlice!.length - 1];
    this.toPage(newPage);
  }

  public changePageSize(pagesize: number) {
    if (!this.dataUrl) return;
    const currentDataOptions = this.getCurrentDataOptions();
    this.tableConfiguration.tableUrl = this.dataUrl;
    this.tableConfiguration = this.tableSettingsService.saveOptionsConfiguration(this.dataUrl, this.tableConfiguration, {...currentDataOptions, pagesize}, this.activeFilters);
    this.getTableData(this.dataUrl, this.isModal, {...currentDataOptions, pagesize, page: 1}, true).subscribe();
  }

  public getCurrentDataOptions(returnDefault = true): IDataOptions {
    if (returnDefault) {
      return {
        pagesize: this.allowedPaging.hasOwnProperty('pagesize') ? this.pagingMeta.pagesize : 0,
        page: this.allowedPaging.hasOwnProperty('page') ? this.pagingMeta.page : 0,
        filter: this.createFilterString(this.activeFilters),
        sort: this.createSortString(this.sortingMeta.sortedColumn, this.sortingMeta.sortDirection)
      };
    } else {
      if (Object.keys(this.allowedPaging).length > 0) {
        return {
          pagesize: this.allowedPaging.hasOwnProperty('pagesize') ? this.pagingMeta.pagesize : 0,
          page: this.allowedPaging.hasOwnProperty('page') ? this.pagingMeta.page : 0,
          filter: this.createFilterString(this.activeFilters),
          sort: this.createSortString(this.sortingMeta.sortedColumn, this.sortingMeta.sortDirection)
        };
      } else {
        return {};
      }
    }
  }

  public setFilters(filters: FilterChip[]) {
    this.activeFilters = [...filters];
    this._filterChips$.next(filters);
  }

  public getLookupTokenParam(url: string) {
    const params = url.split('?')[1].split('&');
    return params.find(param => param.includes('lookupToken'));
  }

  public setTableConfiguration(config: ITableConfig) {
    this.tableConfiguration = config;
    if (!!config?.settings?.filter) this.activeFilters = config.settings.filter;
  }

  private getLoadedPage(page: number) {
    if (this.resetPages) {
      this.pages = [];
      this.resetPages = false;
      return null;
    }
    return this.pages[page - 1];
  }

  private setControl(input: FormInput) {
    let inputVal: Iterable<INarisOption | string> | string = !!input.multiple ? new Set() : '';
    this.activeFilters.forEach(fil => {
      if (fil.input.id === input.id) {
        const { label, ...rest } = fil;
        const filVal = fil.input.controlType === 'autocomplete' ? rest : fil.val;
        inputVal instanceof Set ? inputVal.add(filVal) : inputVal = filVal;
      }
    });
    const defaultVal = this.extractDefault(input) || [];
    inputVal instanceof Set && Array.isArray(defaultVal) ? defaultVal.forEach(v => (inputVal as Set<INarisOption | string>).add(v)) : inputVal = defaultVal;
    input.value = inputVal instanceof Set ? [...inputVal] : inputVal;
    const control = this.formService.toFormField(input);
    this.filterForm.addControl(input.id, control);
    if (input.controlType !== 'text') this.subs.push(
      control.valueChanges.pipe(input.controlType === 'date' ? skip(1) : identity).subscribe(val => this.handleFilter(val, input))
    );
    control.setValue(input.value);
  }

  private extractDefault({ layouthint, controlType, multiple }: FormInput) {
    const defaultHint = layouthint?.find(lh => lh.includes('default-filter'));
    if (!defaultHint) return;
    const defVal = defaultHint.split(': ')[1];
    if (!!multiple) {
      const valArr = defVal.split('|');
      return controlType === 'autocomplete' ? valArr.map(v => {
        const [ value, label ] = v.split('*');
        const transLabel = this.translateService.instant(value);
        return {value, label: label || transLabel};
      }) : valArr;
    } else return defVal;
  }
}
