import { Pipe, PipeTransform } from '@angular/core';
import { getOrdinal } from '@core/helpers';
import { LanguageService } from '@core/services';

@Pipe({
  name: 'ordinal',
  standalone: true
})
export class OrdinalPipe implements PipeTransform {

  constructor(private readonly languageService: LanguageService) {}

  public transform(value: number) {
    const currentLang = this.languageService.getAppLang();
    return getOrdinal(value, currentLang);
  }
}
