import { createClass as e, classCallCheck as t, defineProperty as i } from "../_virtual/_rollupPluginBabelHelpers.js";
import { getElementSize as n, setPosition as o, setXPosition as r, setYPosition as s } from "../ui-core/browser-ui-renderer/positioning-and-sizing-utils.js";
import { isSVGElement as l } from "../ui-core/browser-ui-renderer/browser-util.js";
var a = "left";
var u = "top";
var f;
(function (e) {
  e["left"] = "left";
  e["right"] = "right";
})(f || (f = {}));
var m;
(function (e) {
  e["top"] = "top";
  e["bottom"] = "bottom";
})(m || (m = {}));
var p = function () {
  function p(e) {
    t(this, p);
    this.panZoom = e;
    i(this, "fixedElements", {});
  }
  e(p, [{
    key: "_findRequestedPosition",
    value: function e(t, i) {
      if (i === "x") {
        var o = t.pos[i];
        if (t.alignX === f.right) {
          var r = n(t.el, this.panZoom.zoom);
          return o - r.w;
        }
        return o;
      } else {
        var s = t.pos[i];
        if (t.alignY === m.bottom) {
          var l = n(t.el, this.panZoom.zoom);
          return s - l.h;
        }
        return s;
      }
    }
  }, {
    key: "_fixElements",
    value: function e(t) {
      var i = this;
      var n;
      var o;
      for (var l in this.fixedElements) {
        if (this.fixedElements.hasOwnProperty(l)) {
          var f = function () {
            n = n || i.panZoom.getApparentCanvasLocation();
            o = o == null ? i.panZoom.getZoom() : o;
            if (t != null && t !== l) {
              return "continue";
            }
            var e = i.fixedElements[l];
            var f = function t(r, s, l) {
              if (e[r]) {
                var a = i._findRequestedPosition(e, s);
                e.requestedPosition[s] = a;
                if (n[s] / o + a < 0) {
                  l(e.el, -(n[s] / o));
                } else {
                  l(e.el, a);
                }
              }
            };
            f(a, "x", function (t, i) {
              r(t, i);
              e.currentPosition.x = i;
            });
            f(u, "y", function (t, i) {
              s(t, i);
              e.currentPosition.y = i;
            });
          }();
          if (f === "continue") continue;
        }
      }
    }
  }, {
    key: "pan",
    value: function e() {
      this._fixElements();
    }
  }, {
    key: "append",
    value: function e(t, i, n, r) {
      if (t == null) return;
      var s = t;
      n = n || {};
      var a = this.panZoom.idFunction(t);
      this.fixedElements[a] = {
        el: s,
        left: n.left,
        top: n.top,
        pos: i,
        alignX: n.alignX || f.left,
        alignY: n.alignY || m.top,
        currentPosition: Object.assign({}, i),
        requestedPosition: Object.assign({}, i),
        id: r
      };
      if (!l(t)) {
        s.style.position = "absolute";
      }
      o(s, i);
      this.panZoom.canvasElement.appendChild(t);
      this._fixElements(a);
      return this.fixedElements[a];
    }
  }, {
    key: "remove",
    value: function e(t) {
      var i = this.panZoom.idFunction(t);
      if (i && this.fixedElements[i]) {
        this.panZoom.canvasElement.removeChild(this.fixedElements[i].el);
        delete this.fixedElements[i];
        this._fixElements();
      }
    }
  }, {
    key: "destroy",
    value: function e() {
      this.panZoom = null;
      this.fixedElements = null;
    }
  }]);
  return p;
}();
export { f as FixedElementHorizontalAlignments, m as FixedElementVerticalAlignments, p as FixedLayer };