<div class="process-inspector">
  @if (currentType !== '' && obj?.type !== 'sub-process' && obj?.type !== 'process-startEvent') {
    <div class="process-inspector__separator"></div>
  }
  @switch(currentType) {
    @case ('') {
      <div></div>
    }
    @case ('Edge') {
      <div>
        <div class="process-inspector__form-field">
          <label class="process-inspector__form-field__label" for="edgeLabel">{{ 'name' | translate }}:</label>
          <div class="process-inspector__form-field__wrapper">
            <input 
              class="process-inspector__form-field__input" 
              type="text" 
              id="edgeLabel" 
              jtk-att="label" 
              [disabled]="readOnly"
              [class.disabled-input]="readOnly"
            >
          </div>
        </div>
        @if (!readOnly) {
          <naris-button
            class="inspector__editor__footer__button"
            color="primary"
            (clicked)="updateEdge()"
            id="ProcessInspectorSaveEdge"
          >{{'save' | translate}}</naris-button>
        }
      </div>
    }
    @case ('Node') {
      @if (obj?.type !== 'sub-process' && obj?.type !== 'process-startEvent') {
        <div class="process-inspector__wrapper">
          @if (obj?.type === 'process-task') {
            <div class="process-inspector__toolbar">
              @if (!!deleteAction) {
                <naris-button
                  icon="delete"
                  color="transparent"
                  size="small"
                  [matTooltip]="'process-management.remove-item' | translate"
                  matTooltipPosition="above"
                  class="delete-process-button"
                  (clicked)="deleteNode()"
                  id="ProcessInspectorRemoveObject"
                />
              }
              @if (!!addAction) {
                  <naris-button
                    icon="attachment"
                    color="transparent"
                    size="small"
                    [matTooltip]="'process-management.connected-objects' | translate"
                    matTooltipPosition="above"
                    class="add-process-object-button"
                    (clicked)="addProcessObject()"
                    id="ProcessInspectorConnectedObjects"
                  />
              }
            </div>
            @if (!readOnly) {
              @if (!!updateEndpoint) {
                <app-form 
                  [endpoint]="updateEndpoint"
                  [isProcessToolkitForm]="true"
                  [class.no-padding]="true"
                  (nodeChanged)="nodeChanged($event)"
                />
              }
            } @else {
              <div class="process-inspector__form-field">
                <label class="process-inspector__form-field__label" for="nodeLabel">{{ 'name' | translate }}:</label>
                <div class="process-inspector__form-field__wrapper">
                  <input 
                    class="process-inspector__form-field__input disabled-input" 
                    type="text" 
                    id="nodeLabel" 
                    jtk-att="name" 
                    disabled
                  >
                </div>
              </div>
              <div class="process-inspector__form-field">
                <label class="process-inspector__form-field__label" for="nodeDesc">{{ 'Description' | translate }}:</label>
                <div class="process-inspector__form-field__wrapper">
                  <input 
                    class="process-inspector__form-field__input disabled-input" 
                    type="text" 
                    id="nodeDesc" 
                    jtk-att="description" 
                    disabled
                  >
                </div>
              </div>
            }
          } @else {
            @if (obj?.type !== 'sub-process') {
              <div class="process-inspector__form-field">
                <label class="process-inspector__form-field__label" for="nodeName">{{ 'name' | translate }}:</label>
                <div class="process-inspector__form-field__wrapper">
                  <input 
                    class="process-inspector__form-field__input" 
                    type="text" 
                    id="nodeName" 
                    jtk-att="name"
                    [disabled]="readOnly"
                    [class.disabled-input]="readOnly"
                  >
                </div>
              </div>
              @if (!readOnly) {
                <naris-button
                  class="inspector__editor__footer__button"
                  color="primary"
                  (clicked)="updateNode()"
                  id="ProcessInspectorSaveNode"
                >{{'save' | translate}}</naris-button> 
              }
            }
          }
        </div>
      } 
    }
  }
</div>
