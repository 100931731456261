import { defineProperty as a, toConsumableArray as r } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { aSegmentDirections as e, findAdjacentSegment as v, transformOrthogonalFromAbsolute as t, orthogonalSegmentLength as s } from "./utils.js";
import { AXIS_ORIENTATION_HORIZONTAL as n, AXIS_ORIENTATION_VERTICAL as o } from "./definitions.js";
function u(a, r, e, v, t) {
  if (r || a) {
    l(1, e, v, t);
    l(0, e, v, t);
  }
}
function l(r, s, u, l) {
  var c = s.orthogonalSegments;
  var p = r === 1 ? c[c.length - 1] : c[0];
  var h = r === 1 ? c[0] : c[c.length - 1];
  var b = r === 1 ? s.targetStub : s.sourceStub;
  var m = r === 1 ? l.targetPos.curX : l.sourcePos.curX;
  var d = r === 1 ? l.targetPos.curY : l.sourcePos.curY;
  var j = r === 1 ? u.to : u.so;
  var O = r === 1 ? i : f;
  var P = r === 1 ? x : y;
  var S = r === 1 ? f : i;
  var M = r === 1 ? y : x;
  var X = r === 1 ? function () {
    return c.length - 1;
  } : function () {
    return 1;
  };
  var Y = r === 1 ? function () {
    return 1;
  } : function () {
    return c.length - 2;
  };
  var _ = r === 1 ? -1 : 1;
  var A = r === 1 ? c[c.length - 2] : c[1];
  p[S] = m + b * j[0];
  p[M] = d + b * j[1];
  p[O] = m;
  p[P] = d;
  var B = e(p);
  var C = p.o;
  var H = p.o === n ? o : n;
  var k = p.o === n ? [i, f] : [x, y];
  var q = p.o === n ? [f, i] : [y, x];
  var w = p.o === n ? [x, y] : [i, f];
  var z = p.o === n ? [y, x] : [f, i];
  var D = k[r];
  var E = q[r];
  var F = w[r];
  var G = z[r];
  if (A != null) {
    if (A.o === p.o) {
      A[E] = p[D];
      A[F] = A[G] = p[F];
      var I = e(A)[C];
      var J = v(c, _, H, A);
      if (I !== B[C]) {
        if (J != null) {
          var K = v(c, _, C, J[0]);
          if (K != null) {
            c.splice(X(), 1);
            K[0][E] = p[D];
            J[0][D] = p[D];
            J[0][E] = p[D];
          } else {
            var L;
            var N = (J[0][F] + J[0][G]) / 2;
            J[0][G] = N;
            A[F] = A[G] = N;
            var Q = (L = {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 0,
              o: H
            }, a(L, D, p[D]), a(L, F, N), a(L, G, p[F]), a(L, E, p[D]), L);
            c.splice(X(), 0, Q);
          }
        }
      } else {
        A[F] = A[G] = p[F];
        if (J != null) {
          J[0][G] = p[F];
        }
      }
    } else {
      A[G] = p[F];
      A[D] = A[E] = p[D];
      var R = v(c, _, C, A);
      if (R != null) {
        var T = e(R[0])[C];
        R[0][E] = p[D];
        var U = e(R[0])[C];
        if (T !== U) {
          if (R[1] === Y()) {
            var V, W;
            c.splice(R[1], 1);
            var Z = (A[F] + A[G]) / 2;
            A[G] = Z;
            A[D] = A[E] = h[E];
            var $ = (V = {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 0,
              o: C
            }, a(V, D, h[E]), a(V, F, Z), a(V, G, Z), a(V, E, p[D]), V);
            c.splice(X(), 0, $);
            var aa = (W = {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 0,
              o: H
            }, a(W, D, p[D]), a(W, F, Z), a(W, G, p[F]), a(W, E, p[D]), W);
            c.splice(X(), 0, aa);
          }
        }
      } else {
        var ra, ea;
        var va = (A[F] + A[G]) / 2;
        A[G] = va;
        A[D] = A[E] = h[E];
        var ta = (ra = {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 0,
          o: C
        }, a(ra, D, h[E]), a(ra, F, va), a(ra, G, va), a(ra, E, p[D]), ra);
        c.splice(X(), 0, ta);
        var sa = (ea = {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 0,
          o: H
        }, a(ea, D, p[D]), a(ea, F, va), a(ea, G, p[F]), a(ea, E, p[D]), ea);
        c.splice(X(), 0, sa);
      }
    }
  }
  var na = g(s, c);
  s.orthogonalSegments = t(na);
}
var i = "ax2";
var f = "ax1";
var y = "ay1";
var x = "ay2";
function c(a, r, e) {
  var v = e === a.ax1 ? {
    value: a.ax2,
    coord: i,
    prev: true
  } : {
    value: a.ax1,
    coord: f,
    prev: true
  };
  var t = e === r.ax1 ? {
    value: r.ax2,
    coord: i,
    prev: false
  } : {
    value: r.ax1,
    coord: f,
    prev: false
  };
  var s = Math.abs(e - v.value);
  var n = Math.abs(e - t.value);
  var o = s > n ? v : t;
  var u = s > n ? t : v;
  return Object.assign(o, {
    ref: u.value
  });
}
function p(a, r, e) {
  var v = e === a.ay1 ? {
    value: a.ay2,
    coord: x,
    prev: true
  } : {
    value: a.ay1,
    coord: y,
    prev: true
  };
  var t = e === r.ay1 ? {
    value: r.ay2,
    coord: x,
    prev: false
  } : {
    value: r.ay1,
    coord: y,
    prev: false
  };
  var s = Math.abs(e - v.value);
  var n = Math.abs(e - t.value);
  var o = s > n ? v : t;
  var u = s > n ? t : v;
  return Object.assign(o, {
    ref: u.value
  });
}
function g(a, v) {
  if (v.length < 5) {
    return v;
  }
  function t(a, r, e) {
    var v = e === a.ax1 ? a.ax2 : a.ax1;
    var t = e === r.ax1 ? r.ax2 : r.ax1;
    if (v > r.ax1 && r.ax2 > v) {
      return v;
    }
    if (v > r.ax2 && r.ax1 > v) {
      return v;
    }
    if (t > a.ax1 && a.ax2 > t) {
      return t;
    }
    if (t > a.ax2 && a.ax1 > t) {
      return t;
    }
  }
  function u(a, r, e) {
    var v = e === a.ay1 ? a.ay2 : a.ay1;
    var t = e === r.ay1 ? r.ay2 : r.ay1;
    if (v > r.ay1 && r.ay2 > v) {
      return v;
    }
    if (v > r.ay2 && r.ay1 > v) {
      return v;
    }
    if (t > a.ay1 && a.ay2 > t) {
      return t;
    }
    if (t > a.ay2 && a.ay1 > t) {
      return t;
    }
  }
  var l = v[1],
    i,
    f;
  var y = Math.max(a.sourceStub, a.targetStub);
  var x = [];
  for (var g = 2; g < v.length - 2; g++) {
    i = v[g];
    f = e(l)[l.o];
    if (i.o !== l.o) {
      var h = s(i);
      if (h < y) {
        var b = v[g + 1],
          m = e(b)[b.o];
        if (m !== f) {
          var d = Object.assign({}, i);
          if (i.o === o) {
            var j = c(l, b, i.ax1);
            var O = t(l, b, i.ax1);
            if (O != undefined) {
              d.ax1 = O;
              d.ax2 = O;
              if (!j.prev) {
                var P = Object.assign({}, b);
                if (b.ax1 === i.ax1) {
                  P.ax1 = O;
                } else {
                  P.ax2 = O;
                }
                x.push({
                  start: g - 1,
                  segments: [d, P]
                });
              } else {
                var S = Object.assign({}, l);
                if (l.ax2 === i.ax1) {
                  S.ax2 = O;
                } else {
                  S.ax1 = O;
                }
                x.push({
                  start: g - 1,
                  segments: [S, d]
                });
              }
              l = b;
              g += 1;
            } else {
              l = i;
            }
          } else if (i.o === n) {
            var M = p(l, b, i.ay1);
            var X = u(l, b, i.ay1);
            if (X != undefined) {
              d.ay1 = X;
              d.ay2 = X;
              if (!M.prev) {
                var Y = Object.assign({}, b);
                if (b.ay1 === i.ay1) {
                  Y.ay1 = X;
                } else {
                  Y.ay2 = X;
                }
                x.push({
                  start: g - 1,
                  segments: [d, Y]
                });
              } else {
                var _ = Object.assign({}, l);
                if (l.ay2 === i.ay1) {
                  _.ay2 = X;
                } else {
                  _.ay1 = X;
                }
                x.push({
                  start: g - 1,
                  segments: [_, d]
                });
              }
              l = b;
              g += 1;
            } else {
              l = i;
            }
          }
        } else {
          l = i;
        }
      } else {
        l = i;
      }
    } else {
      l = i;
    }
  }
  var A = v.slice();
  for (var B = 0; B < x.length; B++) {
    A.splice.apply(A, [x[B].start - B, 3].concat(r(x[B].segments)));
  }
  return A;
}
export { g as _removeShortSegments, u as modernSourceAndTargetRelocation, l as stubRelocator };