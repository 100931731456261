import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag, CdkDragPreview, CdkDragHandle } from '@angular/cdk/drag-drop';
import { of, Observable, Subscription, BehaviorSubject } from 'rxjs';
import { catchError, concatMap, finalize, map } from 'rxjs/operators';
import { BeinformedService, HttpService, SnackbarService, DialogService, FormService, TabService } from '@core/services';
import { WidgetIcons, WidgetColors } from '@core/enums';
import { clone } from '@core/helpers';
import { OverlayComponent } from '@shared/components/overlay/overlay.component';
import { DashboardService } from '@core/services/dashboard.service';
import { SetTabClass } from '@core/classes';
import { MatTooltip } from '@angular/material/tooltip';
import { FilterPipe } from '@shared/pipes/filter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { ToolbarItemComponent } from '../toolbar/toolbar-item/toolbar-item.component';
import { ButtonComponent } from '../../elements/button/button.component';
import { TaskgroupComponent } from '../taskgroup/taskgroup.component';
import { GridComponent } from '../grid/grid.component';
import { GridItemComponent } from '../grid/grid-item/grid-item.component';
import { DashboardCardComponent } from '../dashboard-card/dashboard-card.component';
import { PanelComponent } from '../panel/panel.component';
import { LoaderComponent } from '../loader/loader.component';
import { OverlayComponent as OverlayComponent_1 } from '../overlay/overlay.component';
import { DrawerComponent } from '../drawer/drawer.component';
import { DashboardAddWidgetComponent } from './dashboard-add-widget/dashboard-add-widget.component';
import type { IDashboard, IAction, ICaseListResult, IDashboardWidget, IDashboardWidgetConfig, IDashboardWidgetRow, IForm } from '@core/models';

@Component({
  selector: 'naris-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [ToolbarComponent, ToolbarItemComponent, ButtonComponent, TaskgroupComponent, CdkDropList, CdkDrag, CdkDragPreview, GridComponent, GridItemComponent, CdkDragHandle, MatTooltip, DashboardCardComponent, PanelComponent, LoaderComponent, OverlayComponent_1, DrawerComponent, DashboardAddWidgetComponent, FilterPipe, TranslateModule]
})
export class DashboardComponent implements OnInit, OnDestroy {

  private _href: string;
  private _timeout: NodeJS.Timeout;

  /**
   * Dashboard endpoint href
   */
  @Input()
  set href(value: string) {
    this._href = value;
    clearTimeout(this._timeout);
    this._timeout = setTimeout(() => {      
      this.fetchDashboard();
    }, 200);
  }

  /**
   * Optional case id
   */
  @Input()
  public caseId?: number;

  @ViewChild('addWidgetOverlay')
  private readonly addWidgetOverlay: OverlayComponent;

  public dashboard: IDashboard;
  public activeDashboardId: number;
  public defaultDashboardId: number;
  public selectEndpoint: string;
  public widgetIcons = WidgetIcons;
  public widgetColors = WidgetColors;
  public viewLoading = true;
  public viewSaving = false;
  public editModeEnabled = false;
  public drawerOpened: BehaviorSubject<boolean>;
  private previousConfigObject: string;
  private readonly subs: Subscription[] = [];

  constructor(
    private readonly beinformedService: BeinformedService,
    private readonly httpService: HttpService,
    private readonly snackbarService: SnackbarService,
    private readonly dialogService: DialogService,
    private readonly formService: FormService,
    private readonly dashboardService: DashboardService,
    private readonly tabs: TabService,
    private readonly router: Router
  ) {}

  public ngOnInit() {
    this.subs.push(
      this.dashboardService.editDashChanged.subscribe(result => this.editModeEnabled = result),
      this.dashboardService.dashViewChanged.subscribe(result => this.setView(result))
    );
    if (this.router.url.endsWith('/start')) {
      const setTab = new SetTabClass(this.tabs, this.router);
      setTab.setTabs([{href: '/start'}]);
    }
  }

  /**
   * Returns the object for one widget by id
   * @param id widget id
   */
  public getWidget(id: string) {
    const widget = this.activeView.WidgetsInPermission.find((w: any) => w.WidgetId === id);
    if (this.caseId) widget.Endpoint = widget.Endpoint.replace('(case-id)', this.caseId);
    return widget;
  }

  public getPanel(id: string) {
    const widget = this.getWidget(id);
    if (!widget) return;
    return {
      layouthint: widget.Layouthint?.split(',') || [],
      label: widget.WidgetName,
      name: widget.WidgetName,
      href: widget.Endpoint,
      resourcetype: 'List'
    };
  }

  /**
   * Transforms flat list of widget ids to dashboard config structure
   * @param widgets list of widget ids
   */
  private transformWidgetsToConfig(widgets: IDashboardWidget[]) {
    const cards: IDashboardWidgetConfig[] = [];
    const cardRows: IDashboardWidgetRow[] = [];
    const graphs: IDashboardWidgetConfig[] = [];
    const graphRows: IDashboardWidgetRow[] = [];
    const listRows: IDashboardWidgetRow[] = [];
    widgets?.forEach(w => {
      if (w.WidgetType === 'Card') cards.push({id: w.WidgetId, config: {}});
      if (w.WidgetType === 'Graph') graphs.push({id: w.WidgetId, config: {}});
      if (w.WidgetType === 'List') listRows.push({type: 'List', widgets: [{ id: w.WidgetId, config: {}}]});
    });
    // Chuck cards up in rows of 4
    while (cards.length) cardRows.push({type: 'Card', widgets: cards.splice(0, 4)});
    // Chuck graphs up in rows of 2
    while (graphs.length) graphRows.push({type: 'Graph', widgets: graphs.splice(0, 2)});
    return {rows: [...cardRows, ...graphRows, ...listRows]};
  }

  /**
   * Returns an array of unique widget ids based on dashboard config
   * @param config dashboard config object
   */
  private getUniqueWidgetsIds(rows: Record<string, Record<string, string>[]>[]) {
    const ids = new Set<string>();
    rows.forEach(row => {
      row.widgets.forEach(({ id }) => ids.add(id));
    });
    return [...ids];
  }

  public editDashboard() {
    const endpoint = this.activeView.actions.find((a: IAction) => a.name === 'update-dashboard').href;
    this.formService.open(endpoint).subscribe(result => {
      const dashboardId = parseInt(result.redirect.slice(1));
      if (dashboardId !== this.activeDashboardId) this.reload(true, dashboardId);
      else this.reload();
    });
  }

  /**
   * Set the active view by id
   * @param id dashboard id
   */
  public setView(id: number) {
    this.activeDashboardId = id;
    this.dashboardService.activeDashIdChanged.next(id);

    this.fetchDashboardView(id)
      .pipe(
        finalize(() => this.viewLoading = false)
      )
      .subscribe(result => {
        const dashboard = this.dashboard.views?.find((view: any) => view._id === id);
        if (!!dashboard) {
          dashboard.WidgetsInPermission = result.WidgetsInPermission;
          dashboard.WidgetsNoPermission = result.WidgetsNoPermission;
          dashboard.WidgetsNoModule = result.WidgetsNoModule;
          dashboard.updateWidgetsEndpoint = (dashboard.actions as IAction[])?.find(({ name }) => name === 'update-widgets')?.href;
          this.previousConfigObject = JSON.stringify(clone(dashboard.DashboardConfig));
          if (!dashboard.DashboardConfig) {
            const config = this.transformWidgetsToConfig(dashboard.WidgetsInPermission);
            dashboard.DashboardConfig = config;
          } else {
            const config = typeof dashboard.DashboardConfig === 'string' ? JSON.parse(dashboard.DashboardConfig) : dashboard.DashboardConfig;
            dashboard.DashboardConfig = config;
          }
        }
      });
  }

  /**
   * Reloads the dashboard
   * @param hard optional hard refresh; will reload the intire component
   */
  public reload(refresh = false, id?: number) {
    this.fetchDashboard(refresh, id);
  }

  public dropRow(event: CdkDragDrop<string[]>) {
    const rows = clone(this.activeView.DashboardConfig.rows);
    moveItemInArray(rows, event.previousIndex, event.currentIndex);
    this.saveDashboardView(rows, true);
  }

  public dropWidget(event: CdkDragDrop<string[]>, rowIndex: number) {
    const rows = clone(this.activeView.DashboardConfig.rows);
    moveItemInArray(rows[rowIndex].widgets, event.previousIndex, event.currentIndex);
    this.saveDashboardView(rows, true);
  }

  public addWidget(rowIndex?: number, createRow = false, widgetType?: string) {
    this.addWidgetOverlay.open({ rowIndex, createRow, widgetType });
  }

  public addWidgetToGrid(event: { widget: any; rowIndex: number; createRow: boolean; rowType: string }) {
    if (event.createRow) {
      const rows = clone(this.activeView.DashboardConfig.rows);
      rows.splice(event.rowIndex !== null ? event.rowIndex + 1 : 0, 0, {
        type: event.widget.WidgetType,
        widgets: [{ id: event.widget._id.toString(), config: {} }]
      });
      this.saveDashboardView(rows);
    } else {
      const rows = clone(this.activeView.DashboardConfig.rows);
      rows[event.rowIndex].widgets.push({ id: event.widget._id.toString(), config: {} });
      this.saveDashboardView(rows);
    }
  }

  /**
   * Removes entire row from current view
   * @param rowIndex index of row
   */
  public removeRow(rowIndex: number, askConfirmation = true) {
    const rows = clone(this.activeView.DashboardConfig.rows);
    rows.splice(rowIndex, 1);

    if (!askConfirmation) {
      this.saveDashboardView(rows);
    } else {
      this.dialogService.open({
        type: 'alert',
        title: 'dashboard.remove_row',
        text: 'dashboard.row_delete_confirmation',
        confirmLabel: 'dashboard.remove_row',
        confirmColor: 'danger',
        cancelLabel: 'cancel'
      }).subscribe((confirmed: boolean) => {
        if (confirmed) this.saveDashboardView(rows);
      });
    }
  }

  /**
   * Removes a widget from current view
   * @param rowIndex row index
   * @param widgetIndex widget index
   */
  public removeWidget(rowIndex: number, widgetIndex: number) {
    this.dialogService.open({
      type: 'alert',
      title: 'dashboard.remove_widget',
      text: 'dashboard.widget_delete_confirmation',
      confirmLabel: 'dashboard.remove_widget',
      confirmColor: 'danger',
      cancelLabel: 'cancel'
    }).subscribe((confirmed: boolean) => {
      if (confirmed) {
        const rows = clone(this.activeView.DashboardConfig.rows);
        if (rows[rowIndex].widgets.length === 1) this.removeRow(rowIndex, false);
        else rows[rowIndex].widgets.splice(widgetIndex, 1);
        this.saveDashboardView(rows);
      }
    });
  }

  /**
   * Returns the active view based on activeDashboardId
   */
  get activeView() {
    return this.dashboard.views?.find((view: any) => view._id === this.activeDashboardId);
  }

  /**
   * Returns dashboard view results
   * @param id dashboard id
   */
  private fetchDashboardView(id: number): Observable<any> {
    const body = {objects: {Attribute: {DashboardId: id}}};
    return this.httpService.post(this.dashboard.endpoints?.select || '', body).pipe(
      catchError(this.handleFormErr),
      concatMap(result => {
        const widgets = this.extractWidgets(result);
        return of(widgets);
      })
    );
  }

  /**
   * Returns the id of the default dashboard view
   */
  private getDefaultDashboardId(): Observable<number> {
    return this.httpService.post(this.dashboard.endpoints?.default || '').pipe(
      catchError(this.handleFormErr),
      map(result => {
        const dashboardId = +result.missing?.anchors[0].elements[0].suggestion;
        return dashboardId;
      })
    );
  }

  /**
   * Fetch dashboard
   * @param id optional id
   */
  private fetchDashboard(refresh = false, id?: number) {
    this.beinformedService.fetchResponseWithContributions<'caselist'>(this._href)
      .pipe(catchError(this.handleFormErr))
      .subscribe(result => {
        this.dashboard = this.extractDashboard(result);
        this.dashboardService.dashChanged.next(this.dashboard);
        if (id) this.setView(id);
        else if (!this.activeDashboardId || refresh) {
          this.getDefaultDashboardId().subscribe((dashboardId: number) => {
            // this.activeDashboardId = dashboardId;
            this.defaultDashboardId = dashboardId;
            this.setView(dashboardId);
          });
        } else this.setView(this.activeDashboardId);
      });
  }

  /**
   * Saves the current dashboard view
   */
  public saveDashboardView(dashRows?: any[], updateViewImmediately = false) {
    const rows = dashRows || clone(this.activeView.DashboardConfig.rows);
    this.viewSaving = true;
    const updateEndpoint = this.activeView.actions.find((a: IAction) => a.name === 'update-widgets').href;
    const widgetIds = this.getUniqueWidgetsIds(rows);
    // clone dashboard for mutations
    const dashboardConfig = clone(this.activeView.DashboardConfig);
    // set rows based on rows in paramater
    dashboardConfig.rows = rows;
    // Stringify the config object
    const dashboardConfigJSON = JSON.stringify(dashboardConfig);

    if (dashboardConfigJSON === this.previousConfigObject) {
      // If the new config exactly matches old config, don't do anything
      this.viewSaving = false;
    } else {
      // If item was dragged and dropped, update view immediately
      if (updateViewImmediately) {
        this.activeView.DashboardConfig = dashboardConfig;
        this.previousConfigObject = dashboardConfigJSON;
      }
      // Create a postable object for BI
      const postObject = {
        objects: {
          UpdateDashboardWidgetsUser: {
            Widgets: widgetIds,
            DashboardConfig: dashboardConfigJSON
          }
        }
      };
      // Attempt to save the dashboard view
      this.httpService.post(updateEndpoint, postObject)
        .pipe(catchError(this.handleFormErr))
        .subscribe(result => {
          if (result.errors) {
            const error = result.errors[0].message;
            this.viewSaving = false;
            this.snackbarService.open({ text: error, type: 'error' });
            this.reload(true);
          } else {
            const dashboardId = +result.formresponse?.success.redirect.slice(1);
            if (updateViewImmediately && this.activeDashboardId !== dashboardId) this.reload(true, dashboardId);
            if (!updateViewImmediately) this.reload(dashboardId !== this.activeDashboardId, dashboardId);
            this.viewSaving = false;
          }
        });
    }
  }

  /**
   * Extract widgets from dashboard view
   * @param results any
   */
  private extractWidgets(result: IForm) {
    const widgetElements = result.missing?.anchors[0].elements;
    const widgets: Record<string, any> = {};
    widgetElements?.forEach(type => {
      const wdg = type.dynamicschema?.map(w => ({WidgetId: w.code, ...w.elements}));
      widgets[type.elementid] = wdg;
    });
    return widgets;
  }

  /**
   * Extracts base dashboard information from response
   * @param results combined response
   */
  private extractDashboard(results: ICaseListResult) {
    const label = results.contributions.label;
    const taskgroup = this.beinformedService.extractTaskGroup<'caselist'>(results);
    const views = this.beinformedService.extractResults(results);
    const endpoints = {
      select: results.data.actions?.find(({ name }) => name === 'select-dashboard')?.href,
      default: results.data.actions?.find(({ name }) => name === 'autoselect-dashboard')?.href
    };
    // Filter taskgroup actions
    taskgroup.actions = taskgroup.actions?.filter(({ type }) => type !== 'general');
    return { label, views, taskgroup, endpoints };
  }

  /**
   * Return formresponse as results or throw error
   * @param err form error
   */
  private readonly handleFormErr = (err: HttpErrorResponse) => {
    if (!!err.error?.formresponse) return of(err.error.formresponse);
    else throw err;
  };

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub?.unsubscribe());
  }

  get showToolbar() {
    return this.editModeEnabled || this.activeView.WidgetsNoModule || this.activeView.WidgetsNoPermission || !!this.dashboard.taskgroup?.actions?.length;
  }
}
