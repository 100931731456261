import { createClass as e, defineProperty as i, classCallCheck as t } from "../_virtual/_rollupPluginBabelHelpers.js";
import { AvailableBackgrounds as s } from "./available-backgrounds.js";
import { SurfacePlugins as o } from "../browser-ui/surface.js";
import { log as r } from "../ui-core/util/util.js";
var n = function () {
  function o() {
    t(this, o);
    i(this, "panZoom", void 0);
    i(this, "background", void 0);
    i(this, "_visible", true);
  }
  e(o, [{
    key: "initialise",
    value: function e(i, t) {
      this.panZoom = i.panZoom;
      var o = t.type;
      var r = s.get(o);
      this._visible = t.options != null ? t.visible !== false : true;
      this.background = new r({
        type: o,
        surface: i,
        canvas: this.panZoom.canvasElement,
        viewport: this.panZoom.viewportElement,
        zoomWidget: this.panZoom,
        options: t,
        visible: this._visible
      });
      this.panZoom.addBackground(this.background);
      return true;
    }
  }, {
    key: "destroy",
    value: function e() {
      this.panZoom.removeBackground(this.background);
      this.background.destroy();
    }
  }, {
    key: "reset",
    value: function e() {}
  }, {
    key: "setUrl",
    value: function e(i) {
      if (this.background.setUrl != null) {
        this.background.setUrl(i);
      } else {
        r("The current background does not support the `setUrl` method");
      }
    }
  }, {
    key: "show",
    value: function e() {
      this.setVisible(true);
    }
  }, {
    key: "hide",
    value: function e() {
      this.setVisible(false);
    }
  }, {
    key: "toggle",
    value: function e() {
      this.setVisible(!this._visible);
    }
  }, {
    key: "setVisible",
    value: function e(i) {
      this._visible = i;
      this.background._setVisible(i);
    }
  }]);
  return o;
}();
i(n, "type", "background");
o.register(n.type, n);
export { n as BackgroundPlugin };