import { forEach as t } from "./util.js";
var n = [null, [1, -1], [1, 1], [-1, 1], [-1, -1]];
var r = [null, [-1, -1], [-1, 1], [1, 1], [1, -1]];
var x = 2 * Math.PI;
function y(t, n) {
  return {
    x: t.x + n.x,
    y: t.y + n.y
  };
}
function a(t, n) {
  return {
    x: t.x - n.x,
    y: t.y - n.y
  };
}
function h(t, n) {
  if (n.x === t.x) return n.y > t.y ? Infinity : -Infinity;else if (n.y === t.y) return n.x > t.x ? 0 : -0;else return (n.y - t.y) / (n.x - t.x);
}
function u(t, n) {
  return -1 / h(t, n);
}
function o(t, n) {
  return Math.sqrt(Math.pow(n.y - t.y, 2) + Math.pow(n.x - t.x, 2));
}
function M(t, n) {
  if (n.x > t.x) {
    return n.y > t.y ? 2 : 1;
  } else if (n.x == t.x) {
    return n.y > t.y ? 2 : 1;
  } else {
    return n.y > t.y ? 3 : 4;
  }
}
function e(t, n) {
  var r = h(t, n),
    x = Math.atan(r),
    y = M(t, n);
  if (y == 4 || y == 3) x += Math.PI;
  if (x < 0) x += 2 * Math.PI;
  return x;
}
function i(t, n) {
  var r = t.x,
    x = t.x + t.w,
    y = t.y,
    a = t.y + t.h,
    h = n.x,
    u = n.x + n.w,
    o = n.y,
    M = n.y + n.h;
  return r <= h && h <= x && y <= o && o <= a || r <= u && u <= x && y <= o && o <= a || r <= h && h <= x && y <= M && M <= a || r <= u && h <= x && y <= M && M <= a || h <= r && r <= u && o <= y && y <= M || h <= x && x <= u && o <= y && y <= M || h <= r && r <= u && o <= a && a <= M || h <= x && r <= u && o <= a && a <= M;
}
function f(t) {
  var n = t[1].y - t[0].y;
  var r = t[0].x - t[1].x;
  return {
    A: n,
    B: r,
    C: l(n * t[0].x + r * t[0].y)
  };
}
function l(t, n) {
  n = n == null ? 3 : n;
  return Math.floor(t * Math.pow(10, n)) / Math.pow(10, n);
}
function s(t, n) {
  var r = f(t),
    x = f(n),
    y = r.A * x.B - x.A * r.B;
  if (y == 0) {
    return null;
  } else {
    var a = {
        x: Math.round((x.B * r.C - r.B * x.C) / y),
        y: Math.round((r.A * x.C - x.A * r.C) / y)
      },
      h = Math.floor(Math.min(t[0].x, t[1].x)),
      u = Math.round(Math.max(t[0].x, t[1].x)),
      o = Math.floor(Math.min(t[0].y, t[1].y)),
      M = Math.round(Math.max(t[0].y, t[1].y)),
      e = Math.floor(Math.min(n[0].x, n[1].x)),
      i = Math.round(Math.max(n[0].x, n[1].x)),
      l = Math.floor(Math.min(n[0].y, n[1].y)),
      s = Math.round(Math.max(n[0].y, n[1].y));
    if (a.x >= h && a.x <= u && a.y >= o && a.y <= M && a.x >= e && a.x <= i && a.y >= l && a.y <= s) {
      return a;
    } else {
      return null;
    }
  }
}
function c(n, r) {
  var x = [],
    y = [[{
      x: r.x,
      y: r.y
    }, {
      x: r.x + r.w,
      y: r.y
    }], [{
      x: r.x + r.w,
      y: r.y
    }, {
      x: r.x + r.w,
      y: r.y + r.h
    }], [{
      x: r.x,
      y: r.y
    }, {
      x: r.x,
      y: r.y + r.h
    }], [{
      x: r.x,
      y: r.y + r.h
    }, {
      x: r.x + r.w,
      y: r.y + r.h
    }]];
  t(y, function (t) {
    var r = s(n, t);
    if (r != null) {
      x.push(r);
    }
  });
  return x;
}
function w(t, n, r) {
  var x = t.x,
    y = t.x + t.w,
    a = t.y,
    h = t.y + t.h,
    u = n.x,
    o = n.x + n.w,
    M = n.y,
    e = n.y + n.h,
    i = function t(n, x, y, a) {
      return r ? n <= x && y >= a : n < x && y > a;
    };
  return i(x, u, y, o) && i(a, M, h, e);
}
function v(t, n, r) {
  return r ? t.x <= n.x && t.x + t.w >= n.x && t.y <= n.y && t.y + t.h >= n.y : t.x < n.x && t.x + t.w > n.x && t.y < n.y && t.y + t.h > n.y;
}
function d(t, x, y) {
  var a = h(t, x),
    u = M(t, x),
    o = y > 0 ? n[u] : r[u],
    e = Math.atan(a),
    i = Math.abs(y * Math.sin(e)) * o[1],
    f = Math.abs(y * Math.cos(e)) * o[0];
  return {
    x: t.x + f,
    y: t.y + i
  };
}
function m(t, n, r) {
  var x = h(t, n),
    y = Math.atan(-1 / x),
    a = r / 2 * Math.sin(y),
    u = r / 2 * Math.cos(y);
  return [{
    x: n.x + u,
    y: n.y + a
  }, {
    x: n.x - u,
    y: n.y - a
  }];
}
function p(t, n, r, x) {
  r = r == null ? n.thresholdX == null ? n.w / 2 : n.thresholdX : r;
  x = x == null ? n.thresholdY == null ? n.h / 2 : n.thresholdY : x;
  var y = Math.floor(t.x / n.w),
    a = n.w * y,
    h = a + n.w,
    u = Math.abs(t.x - a) <= r ? a : Math.abs(h - t.x) <= r ? h : t.x;
  var o = Math.floor(t.y / n.h),
    M = n.h * o,
    e = M + n.h,
    i = Math.abs(t.y - M) <= x ? M : Math.abs(e - t.y) <= x ? e : t.y;
  return {
    x: u,
    y: i
  };
}
export { x as TWO_PI, y as add, v as containsPoint, w as encloses, l as fixPrecision, h as gradient, i as intersects, s as lineIntersection, o as lineLength, c as lineRectangleIntersection, u as normal, m as perpendicularLineTo, d as pointOnLine, M as quadrant, p as snapToGrid, a as subtract, e as theta };