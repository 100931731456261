import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { MessageService, UserService } from '@core/services';
import { Router } from '@angular/router';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { INarisNotification } from '@core/models';
import { MatBadge } from '@angular/material/badge';
import { TranslateModule } from '@ngx-translate/core';
import { ToolbarComponent } from '../../../shared/components/toolbar/toolbar.component';
import { ToolbarItemComponent } from '../../../shared/components/toolbar/toolbar-item/toolbar-item.component';
import { SlidetoggleComponent } from '../../../shared/elements/slidetoggle/slidetoggle.component';
import { ButtonComponent } from '../../../shared/elements/button/button.component';
import { NotificationComponent } from '../../../shared/components/notification/notification.component';
import { EmptyStateComponent } from '../../../shared/components/empty-state/empty-state.component';
import type { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  standalone: true,
  imports: [ToolbarComponent, ToolbarItemComponent, SlidetoggleComponent, ButtonComponent, MatBadge, NotificationComponent, EmptyStateComponent, TranslateModule]
})
export class NotificationsComponent implements OnInit {
  @Output() public readonly closed = new EventEmitter<any>();

  public showPushInfo = false;
  public colorNew = 'new' as ThemePalette;

  constructor(
    public readonly messageService: MessageService,
    private readonly userService: UserService,
    private readonly router: Router
  ) {}

  public ngOnInit(): void {
    if (!this.userService.isArchiveMode)
      this.messageService.determineEmailNotifications(this.userService.userId);
  }

  public markAsRead() {
    this.messageService.markNotificationsAsRead();
  }

  get notifications(): INarisNotification[] | undefined {
    return this.messageService.notifications;
  }

  get unreadCount() {
    return this.messageService.unreadNotifications;
  }

  public viewNotifications(): void {
    void this.router.navigate([this.messageService.endpoint]);
    this.close();
  }

  public reload(): void {
    this.messageService.fetchNotifications();
  }

  public close(): void {
    this.closed.emit();
  }

  get isSubscribed(): boolean {
    return this.messageService.isSubscribed;
  }

  public async toggleSubscription(event: MatSlideToggleChange) {
    const userId = this.userService.userId;
    if (event.checked) {
      // Subscribe the user
      await this.messageService.subscribeUser(userId);
      this.showPushInfo = true;
    } else {
      // Unsubscribe user
      await this.messageService.unsubscribeUser(userId);
      this.showPushInfo = false;
    }
  }

  get hasEmailNotificationOn(): boolean {
    return this.messageService.hasEmailNotifications;
  }

  public toggleEmailSubscription(event: MatSlideToggleChange) {
    const userId = this.userService.userId;
    this.messageService.setEmailNotifications(event.checked, userId);
  }
}
