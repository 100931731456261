import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { TOKEN_INVALID } from '@core/constants';
import { sameValidator } from '@core/helpers';
import { AuthService, HttpService, SnackbarService } from '@core/services';
import { TranslateModule } from '@ngx-translate/core';
import { CardComponent } from '../../../shared/components/card/card.component';
import { FormFieldComponent } from '../../../shared/components/form-field/form-field.component';
import { InputComponent } from '../../../shared/elements/input/input.component';
import { FormActionsComponent } from '../../../shared/components/form-actions/form-actions.component';
import { ButtonComponent } from '../../../shared/elements/button/button.component';
import { ProgressSpinnerComponent } from '../../../shared/elements/progress-spinner/progress-spinner.component';

@Component({
  selector: 'app-link-account',
  templateUrl: './link-account.component.html',
  styleUrls: ['./link-account.component.scss'],
  standalone: true,
  imports: [CardComponent, FormsModule, ReactiveFormsModule, FormFieldComponent, InputComponent, FormActionsComponent, ButtonComponent, ProgressSpinnerComponent, TranslateModule]
})
export class LinkAccountComponent implements OnInit {

  public linkForm: FormGroup;
  public invalidToken: boolean;
  public loading = true;
  private idToken: string;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly httpService: HttpService,
    private readonly snackbarService: SnackbarService,
    private readonly authService: AuthService
  ) {}

  public ngOnInit(): void {
    this.idToken = this.activatedRoute.snapshot.queryParams['idToken'];
    this.authService.checkToken(this.idToken).pipe(
      finalize(() => this.loading = false)
    ).subscribe(res => {
      if (res === TOKEN_INVALID) this.invalidToken = true;
      else {
        this.linkForm = new FormGroup({
          account: new FormControl('', [Validators.required, Validators.email]),
          accountRepeat: new FormControl('')
        });
        this.linkForm.get('accountRepeat')?.setValidators([Validators.required, Validators.email, sameValidator(this.linkForm.get('account'))]);
        this.invalidToken = false;
      }
    });
  }

  public onSubmit() {
    const url = `/b2c/tasks/update-user?idToken=${this.idToken}`;
    const body = {
      tokens: null,
      objects: {
        Attributes: {
          newEmail: this.linkForm.get('account')?.value,
          repeatEmail: this.linkForm.get('accountRepeat')?.value
        }
      }
    };
    this.httpService.post(url, body).subscribe(() => {
      this.snackbarService.open({text: `Success! Your account is now linked to ${this.linkForm.get('account')?.value}`, type: 'success'});
      void this.router.navigate(['login']);
    });
  }

  public onBack() {
    void this.router.navigate(['login']);
  }

  public getFormControl(controlName: string) {
    return this.linkForm.get(controlName) as FormControl;
  }
}
