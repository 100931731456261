<div
  class="status-indicator"
  [ngClass]="status"
>
  @if (iconName) {
    <naris-icon
      class="status-indicator-icon"
      [icon]="iconName"
    />
  }
</div>
