import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, of, throwError } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';
import { HttpService } from '@core/services/http.service';
import { LocalStorage, SessionStorage } from '@core/decorators/storage.decorators';
import { UserService } from '@core/services/user.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { TOKEN_INVALID } from '@core/constants';
import { EAuthContext } from '@core/enums';
import { DashboardService } from './dashboard.service';
import { CollabService } from './collab.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends HttpService {

  @LocalStorage()
  private _auth: number | null;
  private _redirectUrl = '';
  @SessionStorage()
  private _nonce: string;
  private _idToken: string;
  private _invitedEmail: string;
  private _invitedFirstName: string;
  private _invitedLastName: string;
  @SessionStorage()
  private _authContext: EAuthContext;

  public closePopUps$ = new Subject();

  constructor(
    injector: Injector,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly snackbar: SnackbarService,
    private readonly dashboardService: DashboardService,
    private readonly collabService: CollabService
  ) {
    super(injector);
  }

  get auth(): number | null {
    return this._auth;
  }

  set auth(val: number | null) {
    if (!val) localStorage.removeItem('naris4_auth');
    else this._auth = val;
  }

  get nonce() {
    return this._nonce;
  }

  set nonce(val: string) {
    if (!val) sessionStorage.removeItem('naris4_nonce');
    else this._nonce = val;
  }

  get redirectUrl(): string {
    return this._redirectUrl;
  }

  set redirectUrl(url: string) {
    this._redirectUrl = url;
  }

  get idToken(): string {
    return this._idToken;
  }

  set idToken(val: string) {
    this._idToken = val;
  }

  get invitedEmail(): string {
    return this._invitedEmail;
  }

  set invitedEmail(val: string) {
    this._invitedEmail = val;
  }

  get invitedFirstName(): string {
    return this._invitedFirstName;
  }

  set invitedFirstName(val: string) {
    this._invitedFirstName = val;
  }

  get invitedLastName(): string {
    return this._invitedLastName;
  }

  set invitedLastName(val: string) {
    this._invitedLastName = val;
  }

  get authContext() {
    return this._authContext;
  }

  set authContext(val: EAuthContext) {
    if (!val) sessionStorage.removeItem('naris4_authContext');
    else this._authContext = val;
  }


  /**
   * Preflight function needed to call postSecurityCheck
   */
  public login(): Observable<any> {
    return this.get('/login', false);
  }

  /**
   * Method to logout current user
   */
  public logout(): Observable<any> {
    return this.get('/Logoff', false).pipe(
      tap(() => {
        this.collabService.isHost ? this.collabService.stopSession() : this.collabService.stopCollab();
        
        this.closePopUps$.next(null);
        sessionStorage.clear();
        this.userService.clearStorage();
        this.dashboardService.clearConfig();
      })
    );
  }

  public resetPWRequest(email?: string) {
    const emailAddress = email || this.userService.userData?.Username;
    if (!emailAddress) this.snackbar.open({ text: 'No email-address was found. Please contact Naris support for help.', type: 'error' });
    else {
      const url = '/b2c/tasks/request-password-reset';
      const body = {
        tokens: null,
        objects: {
          Attributes: {
            Emailaddress: emailAddress
          }
        }
      };
      this.post(url, body).subscribe(() => {
        void this.router.navigate(['login']);
        this.snackbar.open({ text: `An email has been sent to ${emailAddress} for confirmation. You can now close this tab.`, duration: 0 });
      });
    }
  }

  /**
   * function to send credentials to backend to athenticate userst
   * @param email Emailaddress of the user to be authenticated
   * @param password password of the user to be authenticated
   */
  public postSecurityCheck(email: string, password: string) {
    const jSecurityCheck = '/j_security_check';
    return this.post(jSecurityCheck, `j_username=${encodeURIComponent(email)}&j_password=${encodeURIComponent(password)}`, false, true, undefined, false)
      .pipe(
        tap(item => {
          if (item === jSecurityCheck) throw new Error('Authentication failed');
        }),
        catchError(
          this.handleError<any>(jSecurityCheck)
        )
      );
  }

  public authIsValid() {
    return (this._auth ?? 0) - Date.now() > 0;
  }

  public idTokenLogin(token: string) {
    return this.post(`/b2c/tasks/manipulate-session-data?id_token=${token}&nonce=${this.nonce}`, {}, true).pipe(
      catchError(err => throwError(() => err)),
      finalize(() => this.nonce = '')
    );
  }


  public checkToken(token: string) {
    return this.post(`/b2c/tasks/check-token-validity?idToken=${token}`, {}).pipe(
      catchError(err => {
        if (err.error?.error?.properties?.message === TOKEN_INVALID) return of(TOKEN_INVALID);
        throw err;
      })
    );
  }
}
