import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'naris-form-actions',
  templateUrl: './form-actions.component.html',
  styleUrls: ['./form-actions.component.scss'],
  standalone: true
})
export class FormActionsComponent {
  @Input()
  public justify = 'between';

  @HostBinding('class')
  get classes() {
    return [this.justify];
  }
}
