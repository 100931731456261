<div class="main">
  <naris-toolbar color="blue-gradient" sticky="top">
    <naris-toolbar-item>
      <ng-container>
        <h4>{{dialogTitle | translate}}</h4>
      </ng-container>
    </naris-toolbar-item>
    <naris-toolbar-item class="ml-auto">
      @if (!compareExecution) {
        <naris-button
          color="primary"
          icon="close"
          class="close-button"
          (click)="saveAndClose(false)"
          id="JSONCompareClosePopUp"
        />
      }
    </naris-toolbar-item>
  </naris-toolbar>
  @if (!!helperText && !showChoice) {
    <div class="helper-text" [innerHTML]="helperText"></div>
  }
  @if (!!notificationStructureItemsChanged) {
    <div class="helper-text" [innerHTML]="notificationStructureItemsChanged"></div>
  }
  @if (showPushUpdatesWithoutAcceptation && !showChoice) {
    <div class="push-label"  [class.top-margin]="!helperText">
      <span>
        {{pushUpdatesWithoutAcceptationLabel}}
        @if (!!pushUpdatesWithoutAcceptationAssistant) {
          <naris-icon
            icon="info-outline"
            iconClass="small"
            cdkOverlayOrigin
            (mouseenter)="showAssistantOverlay = true"
            (mouseleave)="showAssistantOverlay = false"
            #caseViewHeaderTitle="cdkOverlayOrigin"
          />
          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="caseViewHeaderTitle"
            [cdkConnectedOverlayOpen]="showAssistantOverlay"
          >
            <div class="assistant-overlay">
              <p [innerHTML]="pushUpdatesWithoutAcceptationAssistant"></p>
            </div>
          </ng-template>
        }
      </span>
      <naris-answer-toggle
        [input]="pushUpdatesWithoutAcceptationInput" 
        [control]="pushUpdatesWithoutAcceptationControl"
      />
    </div>
  }
  @if (!!notificationSelectionData) {
    <naris-json-compare-select-notification
      [data]="notificationSelectionData"
      [(selectedItems)]="selectedNotificationItems"
    />
  }
  <div class="main__content" #mainContent [class.saving]="isSaving">
    <div>
      @if (!showTreeCompare && showCompare && (compareData | json) !== '{}') {
        <naris-json-compare-input
          [data]="compareData"
          [(selectedItems)]="selectedItems"
          [(sameValueItems)]="sameValueItems"
          [noColors]="!!data.action.layouthint?.includes('auto-json-compare')"
          [pickChanges]="pickChanges"
          [tableLabels]="tableLabels"
        />
      }
      @if (showTreeCompare && !showCompare && !showChoice) {
        <naris-json-compare-tree
          [oldData]="treeOldValue"
          [newData]="treeNewValue"        
          [tableLabels]="tableLabels"
          [overlayTop]="overlayTop"
          [overlayBottom]="overlayBottom"
        />
      }
      @if (!showTreeCompare && !showCompare && showChoice) {
        <div class="show-choice">
          <div class="show-choice__header"> 
            @if (!!helperText) {
              <div class="info-text" [innerHTML]="helperText"></div>
            }         
            <naris-button (clicked)="onUpdateAll()" color="primary" id="JSONCompareUpdateAllControls">{{ 'compare.update_all_controls' | translate }}</naris-button>
          </div>
          
          <naris-divider [label]="'or' | translate" />
          <naris-radio [control]="optionControl" [options]="changeOptions" [maxLength]="35" />
        </div>
      }
    </div>
    <div class="main__content__button-group">
      @if (!compareExecution || showChoice) {
        <naris-button (click)="saveAndClose(false)" [disabled]="isSaving" id="JSONCompareClose">{{'close' | translate}}</naris-button>
        <naris-button [disabled]="showChoice && !optionControl.value || isSaving" color="primary" (click)="saveAndClose(true)" id="JSONCompareNextPush">{{(showCompare || showChoice) && !singleObject ? ('next' | translate) : ('Push' | translate)}}</naris-button>
      }
      @for (option of buttonOptions; track $index) {
        <naris-button color="primary" (click)="saveAndClose(true, option)" [disabled]="isSaving" [id]="'JSONCompare' + option.code">{{option.label}}</naris-button>
      }
    </div>
  </div>
  @if (showOverlay) {
    <div @fadeInDelayedOutAnimation class="overlay-background" tabindex="0" [style.top.px]="overlayTop" [style.width.px]="overlayWidth" [style.height.px]="overlayHeight" (click)="showOverlay = false"></div>
  }
  <div [@overlayShow]="{value: showOverlay ? 'visible' : 'invisible', params: {height: overlayHeight - 40, top: overlayTop + 60}}" class="overlay" [style.width.px]="overlayWidth">
    <naris-json-compare-input
      [data]="compareData"
      [(selectedItems)]="selectedItems"
      [(sameValueItems)]="sameValueItems"
      [noColors]="!!data.action.layouthint?.includes('auto-json-compare')"
      [pickChanges]="pickChanges"
      [tableLabels]="tableLabels"
      [showAcceptButtons]="true"
      [animationDisabled]="true"
      [readOnlyKey]="readOnlyKey"
    />
  </div>
</div>