<naris-toolbar>
  <naris-toolbar-item>
    <h4>Add widget</h4>
  </naris-toolbar-item>
  <naris-toolbar-item class="ml-auto">
    <naris-button icon="close" color="white" class="close-button" (clicked)="parent.close()"  id="DashboardAddWidgetClose"/>
  </naris-toolbar-item>
</naris-toolbar>

<naris-toolbar height="auto" [spacing]="false" sticky="top" class="header">
  <div class="input">
    <div class="input__wrapper">
      <label
        for="searchWidget"
      >{{'add-widget.search' | translate}}</label>
      <div class="input__search">
        <div style="display: flex; flex-direction: column;">
          <input [formControl]="query" class="input-element" placeholder="Search widget..." id="searchWidget">
        </div>
        <naris-icon icon="search" class="input-icon" />
      </div>
    </div>
  </div>
  <div class="header__select">
    <label
      for="selectWidgetType"
    >{{'add-widget.select_type' | translate}}</label>
    <naris-select [options]="widgetTypes" [control]="selectedWidgetType" id="selectWidgetType"/>
  </div>
</naris-toolbar>

@if (widgets?.length) {
  <div class="widgets">
    @for (widget of widgets; track widget) {
      <div class="widget" (click)="selectWidget(widget)" tabindex="0">
        <div class="widget-header">
          <div class="widget-title">
            {{ widget.WidgetName }} 
            @if (!!widget.WidgetDescription) {
              <naris-icon
                icon="info-outline"
                iconClass="small"
                matTooltipPosition="above"
                matTooltipShowDelay="200"
                [matTooltip]="widget.WidgetDescription"
              />
            }
          </div>
          <div class="widget-type">{{ widget.WidgetType }}</div>
        </div>
      </div>
      @if (selectedWidgetId === widget._id) {
        <div @fadeInOutAnimation class="widget-content">
          @if (!!formHref) {
            <app-form [endpoint]="formHref" [isEmbedded]="true" [isDynamic]="true" (dynamicSaved)="addWidgetSaved($event)" [isAddWidgetForm]="true" [isStriped]="false" />
          }
        </div>
      }
    }
  </div>
}

@if (!widgets?.length) {
  @if (!!query.value) {
    <naris-empty-state
      [title]="'No widgets were found'"
      [text]="'Adjust your filters and find the widgets you want.'"
    />
  } @else {
    <naris-empty-state
      [title]="'No widgets available'"
      [text]="'There are no widgets that are available in the current dashboard.'"
    />
  }
}
