import { inherits as e, createSuper as t, classCallCheck as r, createClass as i } from "../_virtual/_rollupPluginBabelHelpers.js";
import { AbstractLayoutAdapter as s } from "../core/layout/abstract-layout-adapter.js";
var a = function (s) {
  e(u, s);
  var a = t(u);
  function u(e) {
    var t;
    r(this, u);
    t = a.call(this);
    t.surface = e;
    return t;
  }
  i(u, [{
    key: "getOffset",
    value: function e(t) {
      return this.surface.getOffset(t);
    }
  }, {
    key: "getViewportPosition",
    value: function e(t) {
      return this.surface._getViewportPosition(t);
    }
  }, {
    key: "getViewportPositionById",
    value: function e(t) {
      return this.surface._getViewportPositionById(t);
    }
  }, {
    key: "getSize",
    value: function e(t) {
      return this.surface.jsplumb.getSize(t);
    }
  }]);
  return u;
}(s);
export { a as BaseSurfaceLayoutAdapter };