<naris-card class="card">
  @if (!loading && invalidToken === false) {
    <h2 class="mb-4">{{'password.change_password' | translate}}</h2>
    <p class="mb-8">{{'password.new_password' | translate}}</p>
    <form [formGroup]="changeForm" (ngSubmit)="onSubmit()">
      <naris-form-field [label]="'new_password_label' | translate" [fixStyling]="true">
        <naris-input
          [control]="getFormControl('password')"
          [placeholder]="'new_password_label' | translate"
          class="mb-4"
          type="password" />
        <naris-input-condition [valid]="lengthOK">
          {{'password.eight_chars' | translate}}
        </naris-input-condition>
        <naris-input-condition [valid]="numOK">
          {{'password.one_number' | translate}}
        </naris-input-condition>
        <naris-input-condition [valid]="capsOK">
          {{'password.one_capital' | translate}}
        </naris-input-condition>
        <naris-input-condition [valid]="specialOK">
          {{'password.one_special' | translate}}
        </naris-input-condition>
        <naris-input-condition [valid]="originalOK">
          {{'password.original' | translate}}
        </naris-input-condition>
      </naris-form-field>
      <naris-form-field [label]="'repeat_password_label' | translate" [fixStyling]="true">
        <naris-input
          [control]="getFormControl('passwordRepeat')"
          [placeholder]="'repeat_password_label' | translate"
          class="mb-4"
          type="password"
        />
      </naris-form-field>
      <naris-form-actions justify="right">
        <a [routerLink]="['/login']" class="naris-link">{{'go_back' | translate}}</a>
        <div class="mr-4"></div>
        <naris-button [disabled]="changeForm.invalid" color="primary" id="PWChangeChangePassword">{{'password.change_password' | translate}}</naris-button>
      </naris-form-actions>
    </form>
  }
  @if (!loading && (!!invalidToken || invalidToken === undefined)) {
    <h2 class="mb-8">{{'request_expired' | translate}}</h2>
    <p>{{'request_expired.info' | translate}}</p>
  }
  @if (loading) {
    <div class="loader">
      <naris-progress-spinner [strokeWidth]="2" [diameter]="24" />
    </div>
  }
</naris-card>
