import { Directive, Input, OnInit, TemplateRef, ViewContainerRef, EmbeddedViewRef } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { SessionStorage } from '@core/decorators/storage.decorators';
import { CON_FUNCS } from '@core/constants';
import { DependencySetAction } from '@core/enums';
import type { FormInput } from '@core/classes';
import type { IFillElement, TInputType } from '@core/models';

@Directive({
  selector: '[narisDependencyAttribute]',
  standalone: true
})
export class NarisDependencyAttributeDirective implements OnInit {

  private _narisDependencyAttributeOf: FormGroup;
  private _narisDependencyAttributeControl: FormInput;
  private _narisDependencyAttributeFrom: TInputType[];

  @Input() set narisDependencyAttributeOf(value: FormGroup) {
    this._narisDependencyAttributeOf = value;
  }

  @Input() set narisDependencyAttributeControl(value: FormInput) {
    this._narisDependencyAttributeControl = value;
  }

  @Input() set narisDependencyAttributeFrom(value: TInputType[]) {
    this._narisDependencyAttributeFrom = value;
  }

  private readonly showStringArray: string[] = [];
  private readonly mandatoryStringArray: string[] = [];
  private readonly fillElements: IFillElement[] = [];
  private hint: string | null;
  private viewRef: EmbeddedViewRef<any>;
  private isVisible = true;
  private shouldBeFilled = false;
  private shouldBeSelected = false;
  private shouldBeFiltered = false;
  private elementToUpdateName?: string;
  private elementValue?: string;
  private elementProperty1?: string;
  private elementProperty2?: string;
  private mustShowArray: boolean[] = [];  
  private isMandatory = true;
  private setValidity = false;
  private initialValue: any;
  private triggerElement: string;
  private initialOptions: any;

  @SessionStorage()
  private elementToUpdate?: string | null = null;

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef
  ) {}

  /**
   * Initialization, check if and what layouthint is set
   */
  public ngOnInit() {
    this.initialValue = this._narisDependencyAttributeControl.value;
    this.initialOptions = this._narisDependencyAttributeControl.options;
    const layoutHint = this._narisDependencyAttributeControl['layouthint'];
    this.hint = layoutHint instanceof Array ? layoutHint[0] : null;
    this.isMandatory = layoutHint ? layoutHint.includes('mandatory') : false;
    const hints = layoutHint instanceof Array ? layoutHint.filter(x => x.includes('dependent:')) : null;
    this.viewContainer.createEmbeddedView(this.templateRef, {$implicit: this.hint});
    hints?.forEach(hint =>  {
      // layouthint determines if other element should be filled with a selected value (text inputs)
      if (hint?.startsWith('dependent: fill') || hint?.startsWith('dependent:fill') || hint?.startsWith('fill')) this.deconstructFillLayouthint(hint);
      // layouthint determines if the options of this element should be filtered by the value of another element
      if (hint?.startsWith('dependent: filter') || hint?.startsWith('dependent:filter') || hint?.startsWith('filter')) this.deconstructFilterLayouthint(hint);
      // Because the backend is offering multiple versions of the layouthint, we need to check 'em all
      // layouthint determines if element should be shown
      if (hint.startsWith('dependent: show') || hint.startsWith('dependent:show') || hint.startsWith('show')) {
        this.showStringArray.push(hint);
        const formGroupValues = Object.entries(this._narisDependencyAttributeOf.controls).reduce((acc, [ key, control ]) => ({...acc, [key]: control.value}), {});
        this.setElement(formGroupValues, hint);
      }
      // layouthint determines if element should be mandatory
      if (hint.startsWith('dependent: mandatory') || hint.startsWith('dependent:mandatory')) {        
        this.mandatoryStringArray.push(hint);
        const formGroupValues = Object.entries(this._narisDependencyAttributeOf.controls).reduce((acc, [ key, control ]) => ({...acc, [key]: control.value}), {});
        this.setElement(formGroupValues, hint, DependencySetAction.mandatory);
      }
    });
    if (this.mustShowArray.length > 0) this.showElement(this.mustShowArray.some(element => element));
    // layouthint determines if certain value of element should be selected (select inputs)
    if (this.hint?.startsWith('dependent: select') || this.hint?.startsWith('dependent:select') || this.hint?.startsWith('select')) this.deconstructSelectLayouthint();
    // Handle change on a specific input change
    this._narisDependencyAttributeOf.get(this._narisDependencyAttributeControl.id)?.valueChanges.subscribe(selectedValue => {
      const triggerValue = this.elementToUpdate;
      if (triggerValue === null || triggerValue === undefined || triggerValue === 'undefined') {
        this.elementToUpdate = this.elementToUpdateName;
        // choose what to do, fill element or select value in element
        if (this.shouldBeFilled) this.fillElement(this._narisDependencyAttributeOf, selectedValue);
        else if (this.shouldBeSelected) this.selectItemInElement(this._narisDependencyAttributeOf, selectedValue);
      }
      // if current element is element that was just updated, do nothing and reset the value of elementToUpdate
      if (triggerValue === this._narisDependencyAttributeControl.id) this.elementToUpdate = null;
    });

    // Handle changes on any input change
    this._narisDependencyAttributeOf.valueChanges.subscribe(change => {
      if (!!this.showStringArray?.length && !this.setValidity) {
        this.mustShowArray = [];
        this.showStringArray.forEach(showString => this.setElement(change, showString));
        this.showElement(!!this.mustShowArray.length && this.mustShowArray.some(element => element));
      } else if (!!this.mandatoryStringArray?.length && !this.setValidity) {
        this.mandatoryStringArray.forEach(showString => this.setElement(change, showString, DependencySetAction.mandatory));
        this.setValidity = true;
        const formControl = this._narisDependencyAttributeOf.get(this._narisDependencyAttributeControl.id);
        if (this.isMandatory) formControl?.setValidators(Validators.required);
        if (!this.isMandatory) formControl?.removeValidators(Validators.required);
        formControl?.updateValueAndValidity();
      } else if (this.setValidity) this.setValidity = false;
      if (!!change?.[this.triggerElement] && this.shouldBeFiltered) {
        this.filterValues(change[this.triggerElement]);
      }
    });
    this._narisDependencyAttributeOf.updateValueAndValidity();
  }

  /**
   * function that hides or shows the current element depending on the value of the elements it's depending on.
   * @param formGroupValues the values of the FormGroup passed from the parent
   */
  public setElement(formGroupValues: Record<string, any>, showString: string, setAction: DependencySetAction = DependencySetAction.show) {
    if (!showString) return;
    const [ dependingOn, condition, conditionValueBlock ] = showString.split(' if ')[1]?.trim().split(' ');
    if (!Object.keys(formGroupValues).includes(dependingOn)) {
      const stepProp = this._narisDependencyAttributeFrom.find(({ id }) => id === dependingOn);
      if (!!stepProp) formGroupValues[stepProp.id] = stepProp.value;
      else return;
    }
    const andArray: boolean[] = [];
    let valueToCheck: any = null;
    let values: any[][] | null = null;
    let isDependencyColumn = false;
    // if the value needed to check the dependency is a string, use it. Else make it into an array
    if (['string', 'boolean'].includes(typeof formGroupValues[dependingOn])) values = formGroupValues[dependingOn];
    else {
      values = null;
      if (formGroupValues[dependingOn] !== null) {
        if (conditionValueBlock.startsWith('{') && conditionValueBlock.endsWith('}')) {
          isDependencyColumn = true;
          const c = conditionValueBlock.replace('{', '{"').replace(':', '":');
          const obj = JSON.parse(c);
          const objectKeys = Object.keys(obj);
          values = formGroupValues[dependingOn][objectKeys[0]];
          if (Array.isArray(formGroupValues[dependingOn])) values = formGroupValues[dependingOn].map((v: Record<string, any>) => v[objectKeys[0]]);
        } else values = Object.entries(formGroupValues[dependingOn]).filter(([, value ]) => value === true || typeof value === 'string');
      }
    }
    if (['string', 'boolean'].includes(typeof values)) valueToCheck = values;
    else if (conditionValueBlock.startsWith('{')) valueToCheck = values;
    else if (['has', 'hasNot'].includes(condition) || (values || []).length > 1) valueToCheck = values?.map(([ type, value ]) => isNaN(type) ? type : value);
    else valueToCheck = values?.[0]?.[0];
    // Check AND and OR statements. push results to andArray
    conditionValueBlock.split('&').forEach(andValue => {
      if (conditionValueBlock.startsWith('{') && conditionValueBlock.endsWith('}')) {
        const c = conditionValueBlock.replace('{', '{"').replace(':', '":');
        const obj = JSON.parse(c);
        const objectKeys = Object.keys(obj);
        andValue = obj[objectKeys[0]].toString();
      }
      if (andValue.includes('|')) {
        const orBlock = andValue.split('|');
        const orIsValid = orBlock.some(orValue => CON_FUNCS[condition](valueToCheck, orValue));
        andArray.push(orIsValid);
      } else {
        let andIsValid = valueToCheck || typeof valueToCheck === 'boolean' ? CON_FUNCS[condition](valueToCheck.toString(), andValue) : false;
        if (isDependencyColumn && Array.isArray(valueToCheck)) {
          andIsValid = valueToCheck.some(value => CON_FUNCS[condition](value.toString(), andValue));
        }
        andArray.push(andIsValid);
      }
    });

    // if the value meets the requirements, then isValid will become true and the element should be shown
    // else remove the element
    const isValid = andArray.every(andValue => andValue);

    if (setAction === DependencySetAction.show) {
      this.mustShowArray.push(isValid);
    } else if (setAction === DependencySetAction.mandatory) {
      this.isMandatory = isValid;
    }
  }

  private showElement(showThisElement: boolean) {
    const formControl = this._narisDependencyAttributeOf.get(this._narisDependencyAttributeControl.id);
    if (showThisElement && !this.isVisible) {
      this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef, {$implicit: this.hint});
      this.isVisible = true;
      if (this.isMandatory) formControl?.setValidators(Validators.required);
    } else if (!showThisElement && this.isVisible) {
      formControl?.clearValidators();
      const resetValue = Array.isArray(this.initialValue) ? [] : this.initialValue;
      formControl?.patchValue(resetValue, {onlySelf: true});
      this.setValidity = true; // variable to check so we don't end up in a loop (updateAndValidity will change value again)
      setTimeout(() => formControl?.updateValueAndValidity());
      this.hideElement();
    }
  }

  /**
   * fill the a textfield (elementToUpdateName) with a value from this select list
   * @param formGroup the FormGroup passed from the parent
   * @param selectedValue, the value set in this element
   */
  public fillElement(formGroup: FormGroup, selectedValue: any) {
    if (!!this._narisDependencyAttributeFrom && !!this.fillElements.length) {
      this.fillElements.forEach(x => {
        const foundInput = this._narisDependencyAttributeFrom.find(e => e.id === x.elementValue);
        if (!foundInput) return;
        const inputToSet = formGroup.get(x.elementToUpdateName);
        const foundValue = foundInput.options?.find(e => e.value === selectedValue);
        const valueToBeSet = foundValue?.elements?.[x.elementProperty1];
        inputToSet?.setValue(valueToBeSet);
      });
    }
  }

  /**
   * select an item in a select list (elementToUpdateName) depending on a value set in this element
   * @param formGroup the FormGroup passed from the parent
   * @param selectedValue, the value set in this element
   */
  public selectItemInElement(formGroup: FormGroup, selectedValue: any) {
    const foundInput = this._narisDependencyAttributeFrom.find(e => e.id === this.elementToUpdateName);
    const inputToSet = formGroup.get(this.elementToUpdateName!);
    if ((foundInput?.options || []).length > 0) {
      const foundValue = foundInput!.options!.find(e => {
        let betweenValue1 = e.elements![this.elementProperty1!];
        let betweenValue2 = e.elements![this.elementProperty2!];
        if (betweenValue1 > betweenValue2) { // if first value is larger than second value, then turn values around for between comparison
          const tempValue = betweenValue1;
          betweenValue1 = betweenValue2;
          betweenValue2 = tempValue;
        }
        return selectedValue >= betweenValue1 && selectedValue <= betweenValue2;
      });
      if (foundValue) {
        const valueToBeSet = foundValue.value;
        inputToSet?.setValue(valueToBeSet);
      } else this.elementToUpdate = null;
    }
  }

  /**
   * If there is a layouthint with 'fill' in it, deconstruct it into variables
   */
  private deconstructFillLayouthint(hint: string) {
    // example hint:
    // "dependent: fill LikelihoodPercentage with DefaultLikelihood of LikelihoodClassId"
    const splittedByWith = hint.split('with');
    this.elementToUpdateName = splittedByWith[0].split('fill')[1].trim();
    this.elementValue = splittedByWith[1].split('of')[1].trim();
    this.elementProperty1 = splittedByWith[1].split('of')[0].trim();
    this.fillElements.push({elementToUpdateName: this.elementToUpdateName, elementValue: this.elementValue, elementProperty1: this.elementProperty1});
    this.shouldBeFilled = true;
  }

  /**
   * If there is a layouthint with 'select' in it, deconstruct it into variables
   */
  private deconstructSelectLayouthint() {
    // example hint:
    // "dependent: select LikelihoodClassId where LikelihoodPercentage between LikelihoodClassMaximum and LikelihoodClassMinimum"
    const splittedByWhere = this.hint?.split('where');
    const splittedByBetween = splittedByWhere?.[1].split('between');
    const splittedByAnd = splittedByBetween?.[1].split('and');
    this.elementToUpdateName = splittedByWhere?.[0].split('select')[1].trim();
    this.elementValue = splittedByBetween?.[0].trim();
    this.elementProperty1 = splittedByAnd?.[0].trim();
    this.elementProperty2 = splittedByAnd?.[1].trim();
    this.shouldBeSelected = true;
  }

  /**
   * If there is a layouthint with 'filter' in it, deconstruct it into variables
   */
  private deconstructFilterLayouthint(hint: string): void {
    // example hint:
    // "dependent: filter currentFieldVarName by Field"
    const splittedByFilter = hint?.split('filter');
    const splittedByBy = splittedByFilter?.[1].split('by');
    this.elementValue = splittedByBy?.[0].trim(); // currentFieldVarName
    this.triggerElement = splittedByBy?.[1].trim(); // Field
    this.shouldBeFiltered = true;
  }

  /**
   * Hide the currentElement
   */
  private hideElement() {
    if (this.viewRef) this.viewRef.destroy();
    this.viewContainer.clear();
    this.viewContainer.remove();
    this.isVisible = false;
  }

  /**
   * Filter the options of the current element
   * @param value The value of the element on which the options should get filtered
   */
  private filterValues(value: any) {
    if (this._narisDependencyAttributeControl.lookup) {
      this.updateEndpointFilters(value);
    } else if (!!this.elementValue && !!this.initialOptions) {
      const filteredOptions = this.initialOptions?.filter((option: any) => option.elements?.[this.elementValue!].toString() === value.toString());
      this._narisDependencyAttributeControl.options = filteredOptions;
    }
  }

  private updateEndpointFilters(value: string[] | string) {
    const splittedUrl = this._narisDependencyAttributeControl.lookup!.list!.split('?');
    let updatedQueryString = splittedUrl[0] + '?';
    if (splittedUrl[1].includes('&')) {
      const queryParts = splittedUrl[1].split('&');
      const queryParamIdx = queryParts.findIndex(part => part.includes('Name'));
      // Rebuild the query string without the 'Name' filter
      queryParts.forEach((part, index) => {
        if (index !== queryParamIdx) {
          updatedQueryString += part;
          if (index !== queryParts.length - 1 && index !== 0)
            updatedQueryString += '&';
        }
      });
      // Then, re-add the 'Name' filter with its new values
      updatedQueryString += this.buildQueryString(value);
    } else {
      if (!splittedUrl[1].includes('Name')) {
        updatedQueryString += splittedUrl[1];
      }
      updatedQueryString += this.buildQueryString(value);
    }
    this._narisDependencyAttributeControl.lookup!.list = updatedQueryString;
  }

  private buildQueryString(value: string[] | string) {
    let queryString = '&Name=';
    if (Array.isArray(value)) {
      value.forEach((filterValue, index) => {
        queryString += filterValue;
        if (!(index === value.length - 1))
          queryString += ',';
      });
    } else {
      queryString += value;
    }
    return queryString;
  }
}
