import { inherits as t, createSuper as i, classCallCheck as e, defineProperty as s, assertThisInitialized as n, createClass as o } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { EventGenerator as r } from "../../util/event-generator.js";
import { uuid as a, isString as l } from "../../util/util.js";
function u(t) {
  return t.type != null && t.options != null;
}
function v(t) {
  var i = null;
  if (l(t)) {
    i = {
      type: t,
      options: {}
    };
  } else {
    i = t;
  }
  i.options.id = i.options.id || a();
  return i;
}
var c = function (r) {
  t(u, r);
  var l = i(u);
  function u(t, i, o) {
    var r;
    e(this, u);
    r = l.call(this);
    r.instance = t;
    r.component = i;
    s(n(r), "id", void 0);
    s(n(r), "type", void 0);
    s(n(r), "cssClass", void 0);
    s(n(r), "visible", true);
    s(n(r), "location", void 0);
    s(n(r), "events", void 0);
    s(n(r), "attributes", void 0);
    s(n(r), "ignoreTypes", false);
    o = o || {};
    r.id = o.id || a();
    r.cssClass = o.cssClass || "";
    r.setLocation(o.location);
    r.events = o.events || {};
    r.attributes = o.attributes || {};
    for (var v in r.events) {
      r.bind(v, r.events[v]);
    }
    return r;
  }
  o(u, [{
    key: "setLocation",
    value: function t(i) {
      var e = this.location == null ? .5 : this.location;
      if (i != null) {
        try {
          var s = typeof i === "string" ? parseFloat(i) : i;
          if (!isNaN(s)) {
            e = s;
          }
        } catch (t) {}
      }
      this.location = e;
    }
  }, {
    key: "shouldFireEvent",
    value: function t(i, e, s) {
      return true;
    }
  }, {
    key: "setVisible",
    value: function t(i) {
      this.visible = i;
      this.instance.setOverlayVisible(this, i);
    }
  }, {
    key: "isVisible",
    value: function t() {
      return this.visible;
    }
  }]);
  return u;
}(r);
export { c as Overlay, v as convertToFullOverlaySpec, u as isFullOverlaySpec };