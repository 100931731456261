<div mat-dialog-title class="top" [attr.data-test-id]="'dialog__' + data.type">
  <div class="title">
    @if (!!data.type) {
      <naris-icon [icon]="iconTypes[data.type]" [iconClass]="iconClasses[data.type]" class="icon" />
    }
    @if (!!data.title) {
      <h3>{{data.title | translate}}</h3>
    }
  </div>
  @if (!!data.closable) {
    <naris-button
      color="transparent"
      icon="close"
      mat-dialog-close
      class="close"
      id="DialogClose"
    />
  }
</div>
<div mat-dialog-content class="content">
  @if (!!data.text) {
    @if (data.isRichText) {
      <p [innerHtml]="data.text | safeHtml:['class', 'data-doclink-revision', 'data-doclink-hash']" narisDoclink></p>          
    } @else {
      <p>{{ data.text | translate:{expireTime: expireTime} }}</p>
    }
  }
  @if (!!data.timer) {
    <p>{{'dialog.time_left' | translate}} <strong [ngStyle]="{'color': timeLeft > 20 ? 'initial' : '#e62e3d'}">{{timeLeft}}</strong> {{'dialog.seconds' | translate}}</p>
  }
</div>
@if (!data.isRichText) {
  <div mat-dialog-actions>
    <naris-button size="medium" [color]="data.confirmColor!" (clicked)="dialogRef.close(true)" id="DialogConfirm">
      {{data.confirmLabel?.includes(' ') ? data.confirmLabel : (data.confirmLabel || '' | translate)}}
    </naris-button>
    <div class="ml-2"></div>
    @if (!!data.cancelLabel) {
      <naris-button size="medium" (clicked)="dialogRef.close(false)" id="DialogCancel">
        {{data.cancelLabel.includes(' ') ? data.cancelLabel : (data.cancelLabel | translate)}}
      </naris-button>
    }
  </div>
}
