import { createClass as r, classCallCheck as e, defineProperty as t } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { CLASS_DRAGGED as n, CLASS_DRAG_ACTIVE as i, CLASS_DRAG_HOVER as a, CLASS_DRAG_ORIGINAL_GROUP as o } from "./drag-manager.js";
import { EVENT_DRAG_STOP as s, EVENT_DRAG_MOVE as u, EVENT_DRAG_START as l } from "./event-constants.js";
import { forEach as p, getFromSetWithFunction as g, isString as c } from "../util/util.js";
import { SELECTOR_MANAGED_ELEMENT as f, cls as d, ATTRIBUTE_NOT_DRAGGABLE as h } from "../core/constants.js";
import { intersects as v } from "../util/geom.js";
import { FALSE as m } from "../common/index.js";
import { setPosition as _, getPosition as G } from "./positioning-and-sizing-utils.js";
import { removeClass as y, addClass as P } from "./browser-util.js";
import { CLASS_OVERLAY as D } from "../core/css-constants.js";
function x(r, e) {
  if (c(e)) {
    return {
      id: e,
      active: true
    };
  } else {
    return {
      id: e.id,
      active: e.active
    };
  }
}
function b(r, e) {
  var t = g(r.members, function (r) {
    return r.el === e;
  });
  if (t !== null) {
    return t.active === true;
  } else {
    return false;
  }
}
function j(r) {
  var e = [];
  var t = r._jsPlumbParentGroup;
  while (t != null) {
    e.push(t.el);
    t = t.uiGroup;
  }
  return e;
}
var I = function () {
  function c(r, n, i) {
    e(this, c);
    this.instance = r;
    this._dragSelection = n;
    t(this, "selector", "> " + f + ":not(" + d(D) + ")");
    t(this, "_dragOffset", null);
    t(this, "_groupLocations", []);
    t(this, "_intersectingGroups", []);
    t(this, "_currentDragParentGroup", null);
    t(this, "_dragGroupByElementIdMap", {});
    t(this, "_dragGroupMap", {});
    t(this, "_currentDragGroup", null);
    t(this, "_currentDragGroupOffsets", new Map());
    t(this, "_currentDragGroupSizes", new Map());
    t(this, "_currentDragGroupOriginalPositions", new Map());
    t(this, "_dragPayload", null);
    t(this, "drag", void 0);
    t(this, "originalPosition", void 0);
    t(this, "_onDragInit", void 0);
    this._onDragInit = i;
  }
  r(c, [{
    key: "onDragInit",
    value: function r(e) {
      if (this._onDragInit == null) {
        return null;
      } else {
        return this._onDragInit(e);
      }
    }
  }, {
    key: "onDragAbort",
    value: function r(e) {
      return null;
    }
  }, {
    key: "getDropGroup",
    value: function r() {
      var e = null;
      if (this._intersectingGroups.length > 0) {
        var t = this._intersectingGroups[0].groupLoc.group;
        var n = this._intersectingGroups[0].intersectingElement;
        var i = n._jsPlumbParentGroup;
        if (i !== t) {
          if (i == null || !i.overrideDrop(n, t)) {
            e = this._intersectingGroups[0];
          }
        }
      }
      return e;
    }
  }, {
    key: "onStop",
    value: function r(e) {
      var t,
        i = this;
      var a = e.drag.getDragElement();
      var o = this.getDropGroup();
      var u = [];
      u.push({
        el: a,
        id: this.instance.getId(a),
        pos: e.finalPos,
        originalGroup: a._jsPlumbParentGroup,
        redrawResult: null,
        originalPos: e.originalPos,
        reverted: false,
        dropGroup: o != null ? o.groupLoc.group : null
      });
      function l(r, e, t, n, i) {
        var a = t.x,
          s = t.y;
        if (r._jsPlumbParentGroup && r._jsPlumbParentGroup.constrain) {
          var l = {
            w: r.parentNode.offsetWidth + r.parentNode.scrollLeft,
            h: r.parentNode.offsetHeight + r.parentNode.scrollTop
          };
          a = Math.max(a, 0);
          s = Math.max(s, 0);
          a = Math.min(a, l.w - n.w);
          s = Math.min(s, l.h - n.h);
          t.x = a;
          t.y = s;
        }
        u.push({
          el: r,
          id: e,
          pos: t,
          originalPos: i,
          originalGroup: r._jsPlumbParentGroup,
          redrawResult: null,
          reverted: false,
          dropGroup: o === null || o === void 0 ? void 0 : o.groupLoc.group
        });
      }
      this._dragSelection.each(function (r, t, n, i, a) {
        if (r !== e.el) {
          l(r, t, {
            x: n.x,
            y: n.y
          }, i, a);
        }
      });
      (t = this._currentDragGroup) === null || t === void 0 ? void 0 : t.members.forEach(function (r) {
        if (r.el !== e.el) {
          var t = i._currentDragGroupOffsets.get(r.elId);
          var n = i._currentDragGroupSizes.get(r.elId);
          var a = {
            x: e.finalPos.x + t[0].x,
            y: e.finalPos.y + t[0].y
          };
          l(r.el, r.elId, a, n, i._currentDragGroupOriginalPositions.get(r.elId));
        }
      });
      p(u, function (r) {
        var t = {
            x: 0,
            y: 0
          },
          a = r.originalGroup != null,
          s = a && r.originalGroup.elastic === true,
          u = e.e.shiftKey === true,
          l = a && i.instance.isInsideParent(r.el, r.pos),
          p = !l && s && u,
          g = l || s && !u;
        if (a && !g) {
          if (o == null) {
            var c = i._pruneOrOrphan(r, true, true, p);
            if (c.pos != null) {
              r.pos = c.pos.pos;
            } else {
              if (!c.pruned && r.originalGroup.revert) {
                r.pos = r.originalPos;
                r.reverted = true;
              }
            }
          }
        } else if (a && g) {
          t = i._computeOffsetByParentGroup(r.originalGroup);
        }
        if (o != null && !g) {
          i.instance.groupManager.addToGroup(o.groupLoc.group, false, r.el);
        } else {
          r.dropGroup = null;
        }
        if (r.reverted) {
          _(r.el, r.pos);
        }
        r.redrawResult = i.instance.setElementPosition(r.el, r.pos.x + t.x, r.pos.y + t.y);
        y(r.el, n);
        i.instance.select({
          source: r.el
        }).removeClass(i.instance.elementDraggingClass + " " + i.instance.sourceElementDraggingClass, true);
        i.instance.select({
          target: r.el
        }).removeClass(i.instance.elementDraggingClass + " " + i.instance.targetElementDraggingClass, true);
      });
      if (u[0].originalGroup != null) {
        var g = a._jsPlumbParentGroup;
        if (g !== u[0].originalGroup) {
          var c = e.drag.getDragElement(true);
          if (u[0].originalGroup.ghost) {
            var f = G(g.contentArea);
            var d = G(u[0].originalGroup.contentArea);
            var h = {
              x: d.x + e.pos.x - f.x,
              y: d.y + e.pos.y - f.y
            };
            c.style.left = h.x + "px";
            c.style.top = h.y + "px";
            this.instance.revalidate(c);
          }
        }
      }
      this.instance.fire(s, {
        elements: u,
        e: e.e,
        el: a,
        payload: this._dragPayload
      });
      this._cleanup();
    }
  }, {
    key: "_cleanup",
    value: function r() {
      p(this._groupLocations, function (r) {
        y(r.el, i);
        y(r.el, a);
        y(r.el, o);
      });
      this._currentDragParentGroup = null;
      this._groupLocations.length = 0;
      this.instance.hoverSuspended = false;
      this._dragOffset = null;
      this._dragSelection.reset();
      this._dragPayload = null;
      this._currentDragGroupOffsets.clear();
      this._currentDragGroupSizes.clear();
      this._currentDragGroupOriginalPositions.clear();
      this._currentDragGroup = null;
    }
  }, {
    key: "reset",
    value: function r() {}
  }, {
    key: "init",
    value: function r(e) {
      this.drag = e;
    }
  }, {
    key: "onDrag",
    value: function r(e) {
      var t = this;
      var n = e.drag.getDragElement();
      var i = this.instance.getId(n);
      var o = e.pos;
      var s = this.instance.viewport.getPosition(i);
      var l = {
        x: o.x,
        y: o.y
      };
      this._intersectingGroups.length = 0;
      if (this._dragOffset != null) {
        l.x += this._dragOffset.x;
        l.y += this._dragOffset.y;
      }
      var g = [];
      var c = function r(n, i, o, s) {
        if (o) {
          var u = new Set();
          p(t._groupLocations, function (r) {
            if (!u.has(r.group.id) && v(i, r.r)) {
              P(r.el, a);
              t._intersectingGroups.push({
                groupLoc: r,
                intersectingElement: e.drag.getDragElement(true),
                d: 0
              });
              t.instance.toolkit.graph.getAncestors(r.group.group).forEach(function (r) {
                return u.add(r.id);
              });
            } else {
              y(r.el, a);
            }
          });
        }
        t.instance.setElementPosition(n, i.x, i.y, true);
        g.push({
          el: n,
          bounds: i,
          originalPosition: s
        });
      };
      var f = {
        x: l.x,
        y: l.y,
        w: s.w,
        h: s.h
      };
      c(n, f, true, this.originalPosition);
      this._dragSelection.updatePositions(o, this.originalPosition, function (r, e, t, n, i) {
        c(r, n, false, i);
      });
      this._currentDragGroupOffsets.forEach(function (r, e) {
        var n = t._currentDragGroupSizes.get(e);
        var i = {
          x: f.x + r[0].x,
          y: f.y + r[0].y,
          w: n.w,
          h: n.h
        };
        r[1].style.left = i.x + "px";
        r[1].style.top = i.y + "px";
        c(r[1], i, false, r[0]);
      });
      g.forEach(function (r) {
        t.instance.repaint(r.el);
        t.instance.fire(u, {
          el: r.el,
          e: e.e,
          pos: {
            x: r.bounds.x - (t._dragOffset != null ? t._dragOffset.x : 0),
            y: r.bounds.y - (t._dragOffset != null ? t._dragOffset.y : 0)
          },
          originalPosition: r.originalPosition,
          payload: t._dragPayload
        });
      });
    }
  }, {
    key: "_computeOffsetByParentGroup",
    value: function r(e) {
      var t = G(e.el);
      var n = e.contentArea;
      if (n !== e.el) {
        var i = G(n);
        t.x += i.x;
        t.y += i.y;
      }
      if (e.el._jsPlumbParentGroup) {
        var a = this._computeOffsetByParentGroup(e.el._jsPlumbParentGroup);
        t.x += a.x;
        t.y += a.y;
      }
      return t;
    }
  }, {
    key: "onStart",
    value: function r(e) {
      var t = this;
      var n = e.drag.getDragElement();
      var a = G(n);
      this.originalPosition = {
        x: e.pos.x,
        y: e.pos.y
      };
      var s = true;
      var u = n.getAttribute(h);
      if (this.instance.elementsDraggable === false || u != null && u !== m) {
        s = false;
      }
      if (n._jsPlumbParentGroup) {
        if (n._jsPlumbParentGroup.elementsDraggable === false) {
          s = false;
        } else {
          this._dragOffset = this._computeOffsetByParentGroup(n._jsPlumbParentGroup);
          this._currentDragParentGroup = n._jsPlumbParentGroup;
        }
      }
      if (s) {
        this._groupLocations.length = 0;
        this._intersectingGroups.length = 0;
        this.instance.hoverSuspended = true;
        var g = e.drag.getDragElement(true),
          c = g.querySelectorAll(f),
          d = j(g),
          v = [];
        Array.prototype.push.apply(v, c);
        Array.prototype.push.apply(v, d);
        this._dragSelection.filterActiveSet(function (r) {
          return v.indexOf(r.jel) === -1;
        });
        this._dragSelection.initialisePositions();
        var _ = function r(n, a, s) {
          if (!n._isJsPlumbGroup || t.instance.allowNestedGroups) {
            var u = !n._jsPlumbParentGroup;
            var g = !u && n._jsPlumbParentGroup.elastic === true && e.e.shiftKey !== true;
            var c = u || n._jsPlumbParentGroup.dropOverride !== true;
            var f = !u && (n._jsPlumbParentGroup.ghost || n._jsPlumbParentGroup.constrain !== true);
            if (u || c && f && !g) {
              p(t.instance.groupManager.getGroups(), function (r) {
                var e = n._jsPlumbGroup;
                if (r.droppable !== false && r.enabled !== false && n._jsPlumbGroup !== r && !t.instance.groupManager.isDescendant(r, e)) {
                  var a = r.el,
                    s = t.instance.getId(a),
                    u = t.instance.viewport.getPosition(s),
                    l = {
                      x: u.x,
                      y: u.y,
                      w: u.w,
                      h: u.h
                    };
                  var p = {
                    el: a,
                    r: l,
                    group: r
                  };
                  t._groupLocations.push(p);
                  P(a, i);
                  if (r === t._currentDragParentGroup) {
                    P(a, o);
                  }
                }
              });
              var d = function r(e) {
                var t = 1,
                  n = e.group;
                while (n != null) {
                  t++;
                  n = n.group;
                }
                return t;
              };
              t._groupLocations.sort(function (r, e) {
                return d(e.group.group) - d(r.group.group);
              });
            }
          }
          t.instance.select({
            source: n
          }).addClass(t.instance.elementDraggingClass + " " + t.instance.sourceElementDraggingClass, true);
          t.instance.select({
            target: n
          }).addClass(t.instance.elementDraggingClass + " " + t.instance.targetElementDraggingClass, true);
          return t.instance.fire(l, {
            el: n,
            e: e.e,
            originalPosition: t.originalPosition,
            pos: t.originalPosition,
            dragGroup: a,
            dragGroupMemberSpec: s
          });
        };
        var y = this.instance.getId(n);
        this._currentDragGroup = this._dragGroupByElementIdMap[y];
        if (this._currentDragGroup && !b(this._currentDragGroup, n)) {
          this._currentDragGroup = null;
        }
        var D = _(n);
        if (D === false) {
          this._cleanup();
          return false;
        } else {
          this._dragPayload = D;
        }
        if (this._currentDragGroup != null) {
          this._currentDragGroupOffsets.clear();
          this._currentDragGroupSizes.clear();
          this._currentDragGroup.members.forEach(function (r) {
            var e = t.instance.viewport.getPosition(r.elId);
            if (v.indexOf(r.el) === -1) {
              t._currentDragGroupOffsets.set(r.elId, [{
                x: e.x - a.x,
                y: e.y - a.y
              }, r.el]);
              t._currentDragGroupSizes.set(r.elId, e);
              t._currentDragGroupOriginalPositions.set(r.elId, {
                x: e.x,
                y: e.y
              });
              _(r.el, t._currentDragGroup, r);
            }
          });
        }
      }
      return s;
    }
  }, {
    key: "addToDragGroup",
    value: function r(e) {
      var t = this;
      var n = x(this.instance, e);
      var i = this._dragGroupMap[n.id];
      if (i == null) {
        i = {
          id: n.id,
          members: new Set()
        };
        this._dragGroupMap[n.id] = i;
      }
      for (var a = arguments.length, o = new Array(a > 1 ? a - 1 : 0), s = 1; s < a; s++) {
        o[s - 1] = arguments[s];
      }
      this.removeFromDragGroup.apply(this, o);
      p(o, function (r) {
        var e = t.instance.getId(r);
        i.members.add({
          elId: e,
          el: r,
          active: n.active
        });
        t._dragGroupByElementIdMap[e] = i;
      });
    }
  }, {
    key: "removeFromDragGroup",
    value: function r() {
      var e = this;
      for (var t = arguments.length, n = new Array(t), i = 0; i < t; i++) {
        n[i] = arguments[i];
      }
      p(n, function (r) {
        var t = e.instance.getId(r);
        var n = e._dragGroupByElementIdMap[t];
        if (n != null) {
          var i = new Set();
          n.members.forEach(function (e) {
            if (e.el !== r) {
              i.add(e);
            }
          });
          n.members = i;
          delete e._dragGroupByElementIdMap[t];
        }
      });
    }
  }, {
    key: "setDragGroupState",
    value: function r(e) {
      var t = this;
      for (var n = arguments.length, i = new Array(n > 1 ? n - 1 : 0), a = 1; a < n; a++) {
        i[a - 1] = arguments[a];
      }
      var o = i.map(function (r) {
        return t.instance.getId(r);
      });
      p(o, function (r) {
        var n = t._dragGroupByElementIdMap[r];
        if (n != null) {
          var i = g(n.members, function (e) {
            return e.elId === r;
          });
          if (i != null) {
            i.active = e;
          }
        }
      });
    }
  }, {
    key: "clearDragGroup",
    value: function r(e) {
      var t = this;
      var n = this._dragGroupMap[e];
      if (n != null) {
        n.members.forEach(function (r) {
          delete t._dragGroupByElementIdMap[r.elId];
        });
        n.members.clear();
      }
    }
  }, {
    key: "_pruneOrOrphan",
    value: function r(e, t, n, i) {
      var a = e.el;
      var o = {
        pruned: false,
        pos: null
      };
      if (n || !this.instance.isInsideParent(a, e.pos)) {
        var s = a._jsPlumbParentGroup;
        if (s.prune) {
          if (a._isJsPlumbGroup) {
            this.instance.removeGroup(a._jsPlumbGroup);
          } else {
            s.remove(e.el, true);
          }
          o.pruned = true;
        } else if (s.orphan || i) {
          o.pos = this.instance.groupManager.orphan(e.el, t);
          if (a._isJsPlumbGroup) {
            s.removeGroup(a._jsPlumbGroup);
          } else {
            s.remove(e.el);
          }
        }
      }
      return o;
    }
  }]);
  return c;
}();
export { I as ElementDragHandler };