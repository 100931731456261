import { inherits as r, createSuper as e, classCallCheck as t, defineProperty as a, assertThisInitialized as i } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { ArrowOverlay as o } from "./arrow-overlay.js";
import { OverlayFactory as s } from "../factory/overlay-factory.js";
var n = function (o) {
  r(n, o);
  var s = e(n);
  function n(r, e, o) {
    var l;
    t(this, n);
    l = s.call(this, r, e, o);
    l.instance = r;
    a(i(l), "type", n.type);
    l.length = l.length / 2;
    l.foldback = 2;
    return l;
  }
  return n;
}(o);
a(n, "type", "Diamond");
function l(r) {
  return r.type === n.type;
}
s.register(n.type, n);
export { n as DiamondOverlay, l as isDiamondOverlay };