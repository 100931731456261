import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { LanguageService } from '@core/services';
import { DateTime } from 'luxon';
import { MatTooltip } from '@angular/material/tooltip';
import { NgClass } from '@angular/common';
import { MatDateRangeInput, MatStartDate, MatEndDate, MatDatepickerToggle, MatDatepickerToggleIcon, MatDateRangePicker, MatDatepickerActions, MatDatepickerCancel, MatDatepickerApply } from '@angular/material/datepicker';
import { IconComponent } from '../../elements/icon/icon.component';
import { ButtonComponent } from '../../elements/button/button.component';
import type { FormInput } from '@core/classes';

@Component({
  selector: 'naris-daterangepicker',
  templateUrl: './daterangepicker.component.html',
  styleUrls: ['./daterangepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatTooltip, NgClass, MatDateRangeInput, FormsModule, ReactiveFormsModule, MatStartDate, MatEndDate, MatDatepickerToggle, IconComponent, MatDatepickerToggleIcon, MatDateRangePicker, MatDatepickerActions, ButtonComponent, MatDatepickerCancel, MatDatepickerApply]
})
export class DateRangepickerComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  public label: string;

  @Input()
  public error: Error;

  @Input()
  public control: FormControl;

  @Input()
  public input: FormInput;

  @Input()
  public helpText: string;

  @Input()
  public id: string;

  @Input() public requiredIfVisible = false;

  @Input() public minDate = DateTime.now().minus({years: 5});

  @Input() public maxDate = DateTime.now().plus({years: 20});

  public rangeGroup: FormGroup;

  constructor(
    private readonly dateAdapter: DateAdapter<DateTime>,
    private readonly langService: LanguageService
  ) {}

  public ngOnInit() {
    const locale = this.langService.getAppLang();
    this.dateAdapter.setLocale(locale);
    const { startDate, endDate } = this.setInitControl();
    this.rangeGroup = new FormGroup({
      start: new FormControl(startDate),
      end: new FormControl(endDate)
    });
    this.submitDate();
  }

  public ngAfterViewInit() {
    if (this.control && this.requiredIfVisible) setTimeout(() => {
      this.control.setValidators([Validators.required]);
      this.control.updateValueAndValidity();
    }, 0);
  }

  public ngOnDestroy(): void {
    if (this.control && this.requiredIfVisible) {
      this.control.clearValidators();
      this.control.updateValueAndValidity();
    }
  }

  public submitDate() {
    const dateRange = Object.entries<DateTime>(this.rangeGroup.value).reduce<Record<string, string>>((acc, [ key, value ]): Record<string, string> => {
      const prop = this.getDateProp(key as 'end' | 'start') ?? '';
      return {...acc, [prop]: value.toISO({includeOffset: false})!};
    }, {});
    this.control.setValue(dateRange);
  }

  private getDateProp(key: 'end' | 'start') {
    return Object.keys(this.control.value).find(k => k.toLowerCase().includes(key));
  }

  private setInitControl() {
    const startProp = this.getDateProp('start') ?? '';
    const endProp = this.getDateProp('end') ?? '';
    const startValue = this.control.value[startProp];
    const endValue = this.control.value[endProp];
    const hasRangeValue = !!this.control.value && typeof this.control.value === 'object' && !!startValue && !!endValue;
    const now = DateTime.now();
    const startDate = hasRangeValue ? startValue : now.toISO();
    const endDate = hasRangeValue ? endValue : now.plus({days: 1}).toISO();
    return {startDate: DateTime.fromISO(startDate), endDate: DateTime.fromISO(endDate)};
  }
}
