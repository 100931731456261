import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IBreadcrumbTab } from '@core/models/breadcrumb-tab.models';
import { FooterToolbarService } from './footer-toolbar.service';
import { FormService } from './form.service';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbTabsService {

  private _tabs: IBreadcrumbTab[] = [];

  constructor(
    private readonly router: Router,
    private readonly footerToolbarService: FooterToolbarService,
    private readonly formService: FormService
  ) { }

  get tabs() {
    return this._tabs;
  }

  set tabs(_tabs: IBreadcrumbTab[]) {
    this._tabs = _tabs;
  }

  public add(tab: IBreadcrumbTab) {
    const existingTab = this._tabs.find(_tab => _tab.originalUrl === tab.url);
    if (!!existingTab) {
      this.setActive(existingTab);
    } else {
      const newTab = this.setActive(tab);
      this._tabs.push(newTab);
      localStorage.setItem('breadcrumb-tabs', JSON.stringify(this._tabs));
    }
  }

  public update(label: string, href: string) {
    const activeTab = this._tabs.find(_tab => _tab.active === true);
    if (!!activeTab) {
      activeTab.label = label;
      activeTab.url = href;
    }
  }

  public getType(): string {
    const activeTab = this._tabs.find(_tab => _tab.active === true);
    if (!!activeTab)
      return activeTab.type;
    return '';
  }

  public setLabel(translatedType: string, number: string) {
    const activeTab = this._tabs.find(_tab => _tab.active === true);
    if (!!activeTab) {
      activeTab.translatedType = translatedType;
      activeTab.number = number;
    }
  }

  public setHref(href: string) {
    const activeTab = this._tabs.find(_tab => _tab.active === true);
    if (!!activeTab)
      activeTab.url = href;
  }

  public setTooltip(tooltip: string) {
    const activeTab = this._tabs.find(_tab => _tab.active === true);
    if (!!activeTab)
      activeTab.tooltip = tooltip;
  }

  public clear() {
    this.tabs = [];
    localStorage.removeItem('breadcrumb-tabs');
  }

  public clicked(tab: IBreadcrumbTab) {
    const clickedTab = this.setActive(tab);
    localStorage.setItem('breadcrumb-tabs', JSON.stringify(this._tabs));
    this.formService.isRedirect = true;  
    if (this.router.url !== clickedTab.url)      
      this.footerToolbarService.reset();
    if (!!tab.queryParams) 
      void this.router.navigate([clickedTab.url], {queryParams: tab.queryParams});
    else
      void this.router.navigate([clickedTab.url]);
  }

  public close(tab: IBreadcrumbTab) {
    const index = this._tabs.findIndex(_tab => _tab === tab);
    if (index !== -1) {
      if (tab.active) {
        tab.active = false;

        // If the tab to close is currently active we activate the tab to the left
        this._tabs[index - 1].active = true;
        this.removeTab(index);
        void this.router.navigate([this._tabs[index - 1].url]);
      } else this.removeTab(index);

    }
  }

  public closeByHref(href: string) {
    const tab = this._tabs.find(_tab => _tab.originalUrl === href);
    if (!!tab)
      this.close(tab);
  }

  private removeTab(index: number) {
    // Remove the tab from the collection
    this._tabs.splice(index, 1);
    localStorage.setItem('breadcrumb-tabs', JSON.stringify(this._tabs));
  }

  private setActive(tab: IBreadcrumbTab): IBreadcrumbTab {
    const activeTab = this._tabs.find(_tab => _tab.active === true);
    if (!!activeTab)
      activeTab.active = false;
    
    tab.active = true;
    return tab;
  }

  public updateWizardTab(href: string) {
    const tab = this._tabs.find(x => x.active === true);
    if (!!tab) {
      tab.url = href;
      tab.queryParams = undefined;
      localStorage.setItem('breadcrumb-tabs', JSON.stringify(this._tabs));
    }
  }
}
