import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  constructor(private readonly matIconRegistry: MatIconRegistry, private readonly domSanitizer: DomSanitizer) {}

  public init() {
    const safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('~/../assets/naris-icons.svg');
    this.matIconRegistry.addSvgIconSet(safeUrl);
  }
}
