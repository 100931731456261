import { createClass as e, classCallCheck as n } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { SvgComponent as t } from "./svg-component.js";
import { _node as o, ELEMENT_SVG as s, _applyStyles as i } from "./svg-util.js";
import { ABSOLUTE as l, ATTRIBUTE_SCOPE_PREFIX as a, BLOCK as r, NONE as p } from "../core/constants.js";
import { TRUE as u } from "../common/index.js";
import { extend as d } from "../util/util.js";
import { addClass as c } from "./browser-util.js";
var m = function () {
  function m() {
    n(this, m);
  }
  e(m, null, [{
    key: "getEndpointElement",
    value: function e(n) {
      if (n.canvas != null) {
        return n.canvas;
      } else {
        var t = o(s, {
          style: "",
          width: "0",
          height: "0",
          "pointer-events": "all",
          position: l
        });
        n.canvas = t;
        var i = n.classes.join(" ");
        c(t, i);
        var d = n.endpoint.scope.split(/\s/);
        for (var m = 0; m < d.length; m++) {
          t.setAttribute(a + d[m], u);
        }
        n.instance._appendElementToContainer(t);
        if (n.cssClass != null) {
          c(t, n.cssClass);
        }
        c(t, n.instance.endpointClass);
        t.jtk = t.jtk || {};
        t.jtk.endpoint = n.endpoint;
        t.style.display = n.endpoint.visible !== false ? r : p;
        return t;
      }
    }
  }, {
    key: "paint",
    value: function e(n, o, s) {
      if (n.endpoint.deleted !== true) {
        this.getEndpointElement(n);
        t.paint(n, n.instance, s);
        var l = d({}, s);
        if (l.outlineStroke) {
          l.stroke = l.outlineStroke;
        }
        if (n.node == null) {
          n.node = o.makeNode(n, l);
          n.canvas.appendChild(n.node);
        } else if (o.updateNode != null) {
          o.updateNode(n, n.node);
        }
        i(n.node, l);
      }
    }
  }]);
  return m;
}();
export { m as SvgEndpoint };