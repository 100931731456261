import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap, catchError } from 'rxjs/operators';
import { Observable, of, Subject, throwError } from 'rxjs';
import { HttpService } from '@core/services/http.service';
import { IUserData, IInputOption, IAction } from '@core/models';
import { LocalStorage } from '@core/decorators/storage.decorators';
import { LanguageService } from './language.service';
import { GuardService } from './guard.service';
import { BreadcrumbTabsService } from './breadcrumb-tab.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  @LocalStorage()
  private _storageOrganizationID: string;
  private _organizationID: string;

  @LocalStorage()
  private _storageUserOrgs: IInputOption[];
  private _userOrgs: IInputOption[] | null;

  @LocalStorage()
  private _storageUserData: IUserData;
  private _userData: IUserData;

  @LocalStorage()
  private _storageDefaultOrg: string;
  private _defaultOrg: string | null;

  @LocalStorage()
  private _storageCurrentVersion: string;
  private _currentVersion: string;

  @LocalStorage()
  private _storageLatestRead: string;
  private _latestRead: string;

  public userLang: string;
  public userActions: IAction[] | null;
  public tasks: IAction[];

  public userDataUpdated = new Subject<boolean>();

  public toggleArchiveAction: IAction | undefined;
  public toggleArchiveChecked: boolean | null = null;

  constructor(
    private readonly httpService: HttpService, 
    private readonly router: Router, 
    private readonly languageService: LanguageService, 
    private readonly guardService: GuardService,
    private readonly tabs: BreadcrumbTabsService
  ) {}

  get userId() {
    return this.userData?.sessiondata?.PersonID;
  }

  get idOfUser() {
    return this.userData?._id;
  }

  get userData() {
    return this._userData || this._storageUserData;
  }

  set userData(val: IUserData) {
    this._userData = val;
    this._storageUserData = val;
    this.userDataUpdated.next(true);
  }

  get organizationID() {
    return this._organizationID || this._storageOrganizationID;
  }

  set organizationID(val: string) {
    if (!val) localStorage.removeItem('naris4_storageOrganizationID');
    else this._storageOrganizationID = val;
    this._organizationID = val;
  }

  get userOrgs() {
    return this._userOrgs || this._storageUserOrgs;
  }

  set userOrgs(val: IInputOption[] | null) {
    if (!val) localStorage.removeItem('naris4_storageUserOrgs');
    else this._storageUserOrgs = val;
    this._userOrgs = val;
  }

  get defaultOrg() {
    return this._defaultOrg || this._storageDefaultOrg;
  }

  set defaultOrg(val: string | null) {
    if (!val) localStorage.removeItem('naris4_storageDefaultOrg');
    else this._storageDefaultOrg = val;
    this._defaultOrg = val;
  }

  get currentVersion() {
    return this._currentVersion || this._storageCurrentVersion;
  }

  set currentVersion(val: string) {
    if (!val) localStorage.removeItem('naris4_storageCurrentVersion');
    else this._storageCurrentVersion = val;
    this._currentVersion = val;
  }

  get latestRead() {
    return this._latestRead || this._storageLatestRead;
  }

  set latestRead(val: string) {
    if (!val) localStorage.removeItem('naris4_storageLatestRead');
    else this._storageLatestRead = val;
    this._latestRead = val;
  }

  get showToggleArchiveAction(): boolean {
    return !!this.toggleArchiveAction;
  }

  get isArchiveMode(): boolean {
    return Boolean(this._userData?.sessiondata.ArchiveMode);
  }

  public fetchUserData(): Observable<any> {
    return this.httpService.get('/user-login/userdata').pipe(tap(res => {
      const defaultOrg = res.userdata?.DefaultOrganizationID;
      const userLang = res.userdata?.AddressLine4; // Language property is 'Addressline4' in BE
      this.languageService.setLanguage(userLang);
      this.organizationID = res.userdata?.sessiondata?.OrganizationID;
      this.currentVersion = res.userdata?.CurrentVersion;
      this.defaultOrg = defaultOrg;
      this.userData = res.userdata;
    }));
  }

  public getUserOrgs() {
    return this.httpService.post('/profile-tab/person-choice/organization-choice?commit=false', {}).pipe(
      catchError(err => {
        if (err.status === 400 && err.error?.formresponse) {
          const form = err.error.formresponse;
          const options = form.missing?.anchors[0]?.elements[0]?.dynamicschema;
          this.userOrgs = options || [];
          return of(options || []);
        }
        return throwError(() => err);
      })
    );
  }

  public submitOrganization(orgId: string, setDefault: boolean | null) {
    const orgUrl = '/profile-tab/person-choice/organization-choice';
    this.organizationID = orgId;
    const orgBody = {
      objects: {
        ChooseOrganization: {
          OrganizationID: orgId,
          SetAsDefaultOrganization: setDefault
        }
      }
    };
    this.httpService.post(orgUrl, orgBody).subscribe(() => {
      this.fetchUserData().subscribe(() => this.router.navigate(['/start']));
    });
  }

  public getUserActions(): Observable<any> {
    const url = '/profile-tab/person-choice';
    return this.httpService.get(url).pipe(tap((res: any) => {
      this.userActions = res?.taskgroup?.actions;
    }));
  }

  public getTasks(): Observable<any> {
    const url = '/b2c/tasks';
    return this.httpService.get(url).pipe(tap((res: any) => {
      this.tasks = res?.taskgroup?.actions;
    }));
  }

  public toggleUserHoldingOrganization(url: string) {
    return this.httpService.post(url);
  }

  public async toggleArchiveMode() {
    if (!!this.toggleArchiveAction?.href) {
      this.tabs.clear();
      this.toggleArchiveChecked = !this.toggleArchiveChecked;
      const canDeactivate = await this.guardService.canDeactivate();
      if (canDeactivate) {
        return this.httpService.post(this.toggleArchiveAction.href).subscribe(async () => {
          if (this.isArchiveMode) await this.router.navigate(['dashboard']);
          else await this.router.navigate(['risk-archive']);
          window.location.reload();
        });
      } else {
        this.toggleArchiveChecked = false;
      }
    }
  }

  public clearStorage() {
    const items = ['naris4_storageOrganizationID', 'naris4_storageUserOrgs', 'naris4_storageUserData', 'naris4_storageDefaultOrg', 'naris4_auth'];
    items.forEach(item => localStorage.removeItem(item));
  }

  public readLatest() {
    return this.currentVersion === this.latestRead;
  }
}
