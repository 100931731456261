import { Component, Input, ViewEncapsulation, HostBinding, Output, EventEmitter } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';

@Component({
  selector: 'naris-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatSlider, FormsModule, ReactiveFormsModule, MatSliderThumb]
})
export class SliderComponent {

  @HostBinding('class') public rootClass = 'naris-slider';

  @Input() public control: FormControl;
  @Input() public min = 0;
  @Input() public max = 100;
  @Input() public step = 1;
  @Input() public showTickMarks = 20;
  @Input() public thumbLabel = true;
  @Input() public color = 'primary' as ThemePalette;
  @Input() public disabled = false;
  @Input() public appendLabel: string;
  @Input() public inputModel: number | string = 0;
  @Input() public id: string;
  @Input() public discrete = false;
  @Output() public readonly inputModelChange = new EventEmitter<number | string>();

  /**
   * Returns an array with n items, where n is the amount of ticks needed based on the max divided by the tickInterval.
   * Used to iterate through ticks in the template.
   */
  get ticks() {
    const count = this.max / this.showTickMarks; 
    return [...Array(count).keys()];
  }

  /**
   * Returns the calculated tick value
   * @param tick ticks iteration item
   */
  public tickLabel(tick: number): number {
    return tick * this.showTickMarks;
  }

  /**
   * Sets the value of the control property to the tick's corresponding value.
   * @param tick ticks iteration item
   */
  public onTickClicked(tick: number) {
    this.control.setValue(tick * this.showTickMarks);
  }

  public formatLabel = (value: number) => `${value}${this.appendLabel ?? ''}`;
}
