@defer(when !!card) {
  <div 
    [class]="['dynamic-dashboard-card', card.color]"
    [ngStyle]="{'cursor': !!card.href ? 'pointer' : 'default'}"
    (click)="openDialog()" tabindex="0"
  >
    <span class="dynamic-dashboard-card__value">{{card.value}}</span>
    <span 
      class="dynamic-dashboard-card__label"
      [matTooltip]="card.label"
      matTooltipShowDelay="250"
      matTooltipPosition="below"
    >{{card.label}}</span>
    @if (!!card.href) {
      <naris-icon 
        class="dynamic-dashboard-card__icon"
        icon="go-to" 
        [iconClass]="'small'"
      />
    }
  </div>
} @placeholder {
  <p>Loading...</p>
}
