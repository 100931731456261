/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
/**
 * Decorator to store variable value in Local storage
 * @param key The key in which the data should be stored
 */
export const LocalStorage = (key?: string) => WebStorage(localStorage, key);

/**
  * Decorator to store variable value in Session storage
  * @param key The key in which the data should be stored
  */
export const SessionStorage = (key?: string) => WebStorage(sessionStorage, key);

/**
  * The function to get and set the variable value
  * @param webStorage localStorage or sessionStorage, depending on chosen decorator
  * @param key The key in which the data should be stored
  */
export const WebStorage = (webStorage: Storage, key?: string) => (target: any, propertyName: string): void => {
  const storageKey = `naris4${key || propertyName}`;
  Object.defineProperty(target, propertyName, {
    get: () => get(webStorage, storageKey),
    set: value => set(webStorage, storageKey, value)
  });
};

/**
  * The get function to retrieve data from the storage
  * @param storage LocalStorage or sessionStorage, depending on chosen decorator
  * @param key The key in which the data should be stored
  */
export const get = (storage: Storage, key: string): any => {
  const value = storage.getItem(key) || '';
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

/**
  * The set function to set the data in the storage
  * @param storage LocalStorage or sessionStorage, depending on chosen decorator
  * @param key The key from which the data should be stored
  * @param value The value that should be stored
  */
export const set = (storage: Storage, key: string, value: any): void => {
  const item = typeof value === 'string' ? value : JSON.stringify(value);
  storage.setItem(key, item);
};
