import { ComponentType } from '@angular/cdk/portal';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FormInput } from '@core/classes';
import { FORM_LOOKUP_TOKEN } from '@core/constants';
import { FormLookupComponent } from '@core/form/form-lookup/form-lookup.component';
import { ICaseListRow, IFormLookupData, ILookup, IRecursiveObject } from '@core/models';
import { BeinformedService } from '@core/services';
import { fadeInOutAnimation } from '@shared/animations/core.animations';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'naris-checked-tree',
  templateUrl: './checked-tree.component.html',
  styleUrls: ['./checked-tree.component.scss'],
  animations: [fadeInOutAnimation],
  standalone: true,
  imports: [IconComponent, MatTooltip, ButtonComponent, TranslateModule]
})
export class CheckedTreeComponent implements OnInit {

  constructor(
    private readonly beinformedService: BeinformedService,
    private readonly dialog: MatDialog,
    @Inject(FORM_LOOKUP_TOKEN) private readonly formLookupComponent: ComponentType<FormLookupComponent>
  ) {}

  @Input()
  public lookup?: ILookup;

  @Input()
  public control: FormControl & Record<string, any>;

  @Input()
  public input: FormInput;

  @Input()
  public disabled: boolean;

  public treeValues: IRecursiveObject[] = [];
  private values: Record<string, any>[] = [];
  
  public ngOnInit(): void {
    if (!!this.input?.value)
      this.setValues(this.lookup?.list || '', this.input.value, false);
  }

  private setValues(href: string, value: any, _isArray: boolean) {
    if (!value || Array.isArray(value) && !value?.length) return;
    this.beinformedService.fetchResponseWithContributions(href, false).subscribe(res => {
      if (!!res?.data?._embedded?.results.length) {
        const itemKey = Object.keys(res.data._embedded.results[0])[0];
        this.values = res.data._embedded.results.filter((item: any) => Array.isArray(value) ? value.includes(item[itemKey]._id) : value === item[itemKey]._id).map((item: any) => item[itemKey]);
        this.setTreeValues();
        this.setControlvalue();
      }
    });
  }

  private setTreeValues() {
    this.treeValues = [];
    this.values.forEach((item: any) => {
      const classificationName = item.Name;
      const aspectName = item.Aspect;
      const className = item.Class;
      const color = item.HEXColorCode;
      const foundTreeItem = this.treeValues.find(x => x.label === classificationName);
      const classObject = {id: item._id, label: className, color} as IRecursiveObject;
      const aspectObject = {label: aspectName, children: [classObject]} as IRecursiveObject;
      if (!!foundTreeItem) {
        foundTreeItem.children?.push(aspectObject);
      } else {
        const classificationObject = {label: classificationName, children: [aspectObject]} as IRecursiveObject;
        this.treeValues.push(classificationObject);
      }
    });
  }

  private setControlvalue() {
    const controlValue = this.values.map((x: Record<string, any>) => x._id);
    this.control.setValue(controlValue);
  }

  public openAdvancedLookup() {
    this.dialog.open<FormLookupComponent, IFormLookupData, ICaseListRow | string>(this.formLookupComponent, {
      panelClass: 'naris-advanced-lookup-dialog',
      minWidth: '65rem',
      maxHeight: '98vh',
      data: {
        endpoint: this.lookup?.list,
        multiple: true,
        selected: this.control.value?.map((val: number) => ({value: val})),
        layouthint: this.input?.layouthint,
        createAction: !!this.input?.createEndpoint,
        createEndpoint: this.input?.createEndpoint
      },
      position: {left: '11%'}
    }).afterClosed().subscribe(val => {
      this.setValues(this.lookup?.list || '', (val as unknown as { value: number; label: string }[]).map((x: any) => x.value), false);
    });
  }

  public removeItem(aspectClassId: number | undefined) {
    const valueIndex = this.values.findIndex((val: Record<string, any>) => val._id === aspectClassId);
    if (valueIndex > -1)
      this.values.splice(valueIndex, 1);

    this.setTreeValues();
    this.setControlvalue();
  }
}
