import { createClass as e, toConsumableArray as t, classCallCheck as r, defineProperty as i } from "../_virtual/_rollupPluginBabelHelpers.js";
import { isNode as n, isGroup as o, isPort as s } from "../core/model/graph.js";
import { DEFAULT_KEY_ANCHOR as l, DEFAULT_KEY_ANCHORS as a, DEFAULT_KEY_CONNECTOR as u, DEFAULT_KEY_PAINT_STYLE as f, DEFAULT_KEY_HOVER_PAINT_STYLE as p, DEFAULT_KEY_ENDPOINT as v, DEFAULT_KEY_ENDPOINTS as c } from "../ui-core/core/defaults.js";
import { isFunction as d, merge as h } from "../ui-core/util/util.js";
import { isFullOverlaySpec as g } from "../ui-core/core/overlay/overlay.js";
import { DEFAULT as y } from "../ui-core/common/index.js";
import { LabelOverlay as m } from "../ui-core/core/overlay/label-overlay.js";
import { findClosestPoint as b } from "../core/util.js";
var M = "labelLocation";
var w = [l, a, "cssClass", u, f, p, v, c];
var O = "parent";
function D(e, t) {
  return e === "endpoints" && d(t.endpoints);
}
function L(e, r, i) {
  var n = function e(t) {
    return t ? r[t] : null;
  };
  var o = function e(r, i) {
    if (r == null) {
      return null;
    } else {
      var o = r[O];
      var s;
      if (Array.isArray(o)) {
        s = o;
      } else {
        s = [o];
      }
      var l = s.find(function (e) {
        return i.indexOf(e) !== -1;
      });
      if (l) {
        throw new Error("circular view reference");
      }
      i.push.apply(i, t(s));
      return s.map(function (e) {
        return n(e);
      }).filter(function (e) {
        return e != null;
      });
    }
  };
  var s = function e(t, r, n) {
    var s = w.slice();
    if (r.mergeStrategy === "override") {
      Array.prototype.push.apply(s, ["events", "overlays"]);
    }
    if (i != null) {
      s = s.filter(function (e) {
        return i(e, r);
      });
    }
    var a = h(t, r, [], s);
    return l(o(t, n), a, n);
  };
  var l = function e(t, r, i) {
    if (t == null) {
      return r;
    } else {
      var n = r;
      for (var o = 0; o < t.length; o++) {
        n = s(t[o], n, i);
      }
      return n;
    }
  };
  var a = function e(t) {
    if (t == null) {
      return {};
    }
    if (typeof t === "string") {
      return n(t);
    } else if (t.length) {
      var r = false,
        i = 0,
        o;
      while (!r && i < t.length) {
        o = e(t[i]);
        if (o) {
          r = true;
        } else {
          i++;
        }
      }
      return o;
    }
  };
  var u = a(e);
  var f = [];
  if (u) {
    return l(o(u, f), u, f);
  } else {
    return {};
  }
}
function j(e) {
  return function (t, r, i) {
    var n = b(r, {
      w: 1,
      h: 1
    }, e);
    var o = Object.assign(n.p, {
      portId: e[n.idx].portId
    });
    var s = [o.x, o.y, o.ox, o.oy, o.offsetX == null ? 0 : o.offsetX, o.offsetY == null ? 0 : o.offsetY];
    s.portId = o.portId;
    return s;
  };
}
function C(e) {
  if (e.anchorPositions != null) {
    e.anchorPositionFinder = j(e.anchorPositions);
  }
}
var k = function () {
  function t(e, n, o, s) {
    r(this, t);
    this.toolkit = e;
    this.viewOptions = o;
    i(this, "defMap", {
      nodes: new Map(),
      edges: new Map(),
      groups: new Map(),
      ports: new Map()
    });
    i(this, "nodes", void 0);
    i(this, "edges", void 0);
    i(this, "groups", void 0);
    i(this, "ports", void 0);
    this.viewOptions = this.viewOptions || {};
    this.nodes = this.viewOptions.nodes || {};
    this.edges = this.viewOptions.edges || {};
    this.ports = this.viewOptions.ports || {};
    this.groups = this.viewOptions.groups || {};
    var l, a, u;
    if (typeof s != "undefined") {
      for (var f in this.viewOptions.edges) {
        l = this.getEdgeDefinition(f);
        if (l.overlays) {
          for (a = 0; a < l.overlays.length; a++) {
            var p = l.overlays[a];
            if (g(p)) {
              var v = p.options;
              for (var c in v.events) {
                v.events[c] = function (e, t) {
                  return function (r) {
                    e.call(t, {
                      overlay: r.overlay,
                      e: r.e,
                      connection: r.overlay.component,
                      edge: r.overlay.component.edge
                    });
                  };
                }(v.events[c], p);
              }
            }
          }
        }
        s.registerConnectionType(f, l);
      }
      for (a in this.viewOptions.ports) {
        u = this.getPortDefinition(a);
        s.registerEndpointType(a, u);
      }
    }
  }
  e(t, [{
    key: "getEdgeDefinition",
    value: function e(t) {
      if (t == null) {
        return null;
      }
      if (!this.defMap.edges.has(t)) {
        var r = L([t, y], this.edges);
        if (r.label != null) {
          r.overlays = r.overlays || [];
          var i = {
            type: m.type,
            options: {
              useHTMLElement: r.useHTMLLabel === true,
              id: "label",
              label: r.label
            }
          };
          if (r.labelClass) {
            i.options.cssClass = r.labelClass;
          }
          var n = r.labelLocationAttribute || M;
          if (r.labelLocation) {
            i.options.location = r.labelLocation;
          } else {
            i.options.location = "{{".concat(n, "}}");
          }
          if (r.labelLocationAttribute) {
            i.options.labelLocationAttribute = r.labelLocationAttribute;
          }
          r.overlays.push(i);
          delete r.label;
        }
        delete r.parent;
        this.defMap.edges.set(t, r);
        return r;
      } else {
        return this.defMap.edges.get(t);
      }
    }
  }, {
    key: "getNodeDefinition",
    value: function e(t) {
      if (t == null) {
        return null;
      }
      if (!this.defMap.nodes.has(t)) {
        var r = L([t, y], this.nodes, D);
        delete r.parent;
        C(r);
        this.defMap.nodes.set(t, r);
        return r;
      } else {
        return this.defMap.nodes.get(t);
      }
    }
  }, {
    key: "getPortDefinition",
    value: function e(t) {
      if (t == null) {
        return null;
      }
      if (!this.defMap.ports.has(t)) {
        var r = L([t, y], this.ports, D);
        delete r.parent;
        C(r);
        this.defMap.ports.set(t, r);
        return r;
      } else {
        return this.defMap.ports.get(t);
      }
    }
  }, {
    key: "getGroupDefinition",
    value: function e(t) {
      if (t == null) {
        return null;
      }
      if (!this.defMap.groups.has(t)) {
        var r = L([t, y], this.groups, D);
        delete r.parent;
        C(r);
        this.defMap.groups.set(t, r);
        return r;
      } else {
        return this.defMap.groups.get(t);
      }
    }
  }, {
    key: "getTypeDefinition",
    value: function e(t) {
      var r = this.toolkit.getType(t);
      if (n(t)) {
        return this.getNodeDefinition(r);
      } else if (o(t)) {
        return this.getGroupDefinition(r);
      } else if (s(t)) {
        return this.getPortDefinition(r);
      }
    }
  }]);
  return t;
}();
export { k as BrowserUIModel, M as DEFAULT_LABEL_LOCATION_ATTRIBUTE, j as createFinderFromAnchorPositions, L as mergeWithParents, D as shouldOverrideEndpoints };