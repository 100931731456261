import { createClass as i, classCallCheck as t, defineProperty as n } from "../../_virtual/_rollupPluginBabelHelpers.js";
var e = function () {
  function e(i, o) {
    t(this, e);
    this.toolkit = i;
    n(this, "nodeDefinitions", new Map());
    n(this, "groupDefinitions", new Map());
    n(this, "portDefinitions", new Map());
    if (o != null) {
      var r = o.nodes || {};
      for (var s in r) {
        this.nodeDefinitions.set(s, r[s]);
      }
      var f = o.groups || {};
      for (var a in f) {
        this.groupDefinitions.set(a, f[a]);
      }
      var u = o.ports || {};
      for (var p in u) {
        this.portDefinitions.set(p, u[p]);
      }
    }
  }
  i(e, [{
    key: "getNodeDefinition",
    value: function i(t) {
      return this.nodeDefinitions.get(t) || {};
    }
  }, {
    key: "getGroupDefinition",
    value: function i(t) {
      return this.groupDefinitions.get(t) || {};
    }
  }, {
    key: "getPortDefinition",
    value: function i(t) {
      return this.portDefinitions.get(t) || {};
    }
  }]);
  return e;
}();
export { e as DataModel };