

export const transformTranslation = (text: string, value: number | string): string => {
  const match = text.match(/(([=<>][^}]+|other|else) ?{([^}]+))}/g);
  if (match) {
    const ret = match
      .map(m => /([=<>oe]) ?([^{]+) ?{([^}]+)}/.exec(m))
      .find(f => evalCondition(value, f![1], f![2].trim()));
    if (!!ret) return ret[3];
  }
  return text;
};

export const evalCondition = (
  left: number | string,
  operator: string,
  right: string): boolean => {

  if (['o', 'e'].includes(operator)) return true;

  const strings = typeof left === 'string';
  left = left.toString();
  const leftNumber: number = +left;
  const rightNumber = +right;

  if (strings && ['<', '>'].includes(operator)) return false;
  else if (!strings && (Number.isNaN(leftNumber) || Number.isNaN(rightNumber))) return false;
  switch (operator) {
    case '=': return strings ? left === right : leftNumber === rightNumber;
    case '<': return leftNumber < rightNumber;
    case '>': return leftNumber > rightNumber;
  }
  return false;
};
