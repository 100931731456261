<div class="process-manager">
  <div class="process-manager__playground">
    <div class="process-manager__toolbar">
      @for (group of toolbarActions; track $index; let last = $last) {
        @if (!readOnly) {
          @for (action of group.actions; track $index) {
            <naris-button
              class="process-manager__toolbar__item"
              color="transparent"
              size="small"
              [icon]="action.icon"
              [matTooltip]="action.label | translate"
              matTooltipPosition="below"
              matTooltipShowDelay="200"
              (clicked)="handleToolbarAction(action.action)"
              [id]="'ProcessManager' + action.action"
            />
            @if (!last) {
              <div class="process-manager__toolbar__separator"></div>
            }
          }
          
        } @else {
          @if (!group.hideWithReadOnly) {
            @for (action of group.actions; track $index) {
              <naris-button
                class="process-manager__toolbar__item"
                color="transparent"
                size="small"
                [icon]="action.icon"
                [matTooltip]="action.label | translate"
                matTooltipPosition="below"
                matTooltipShowDelay="200"
                (clicked)="handleToolbarAction(action.action)"
                [id]="'ProcessManager' + action.action"
              />
              @if (!last) {
                <div class="process-manager__toolbar__separator"></div>
              }
            }
          }
        }
      }
      @if (readOnly) {
        <div class="process-manager__toolbar__readonly-chip">{{'readonly-mode' | translate}}</div>
      }
    </div>
    <div class="process-manager__surface">
      <jsplumb-surface
        surfaceId="surface"
        toolkitId="toolkitId"
        [view]="view"
        [renderParams]="renderParams"
        [toolkitParams]="toolkitParams"
        [ngClass]="{'read-only-surface': readOnly}"
      />
      @if (showDeleteButton) {
        <div 
          #deleteEdge 
          tabindex="0"
          class="delete" 
          (click)="doDeleteEdge()"
        >
          <naris-icon
            icon="delete"
            iconClass="small"
            [matTooltip]="'strategy_map.remove_edge' | translate"
            matTooltipPosition="above"
          />
        </div>
      }
    </div>
  </div>
  @if (!readOnly) {
    <div
      class="process-manager__toolkit" 
      cdkDrag
    >
      <span class="process-manager__toolkit__title">Toolkit</span>
      <div 
        class="process-manager__toolkit__palette"
        jsplumb-surface-drop
        selector=".process-manager__toolkit__palette__item"
        surfaceId="surface"
        [dataGenerator]="dataGenerator"
      >
        @for (nodeType of nodeTypes; track $index) {
          <div 
            [class]="'process-manager__toolkit__palette__item '"
            [matTooltip]="nodeType.label | translate"
            [attr.data-type]="nodeType.type"
          >
            <naris-icon
              [icon]="nodeType.type"
            />
          </div>
        }
      </div>

      <div class="process-manager__toolkit__inspector" (mousedown)="$event.stopPropagation()">
        <app-process-inspector surfaceId="surface" [readOnly]="readOnly"/>
      </div>
    </div>
  } @else {
    @if (!!selection) {
      <div
        class="process-manager__toolkit" 
        cdkDrag
      >
        <span class="process-manager__toolkit__title">Toolkit</span>
        @if (!readOnly) {
          <div 
            class="process-manager__toolkit__palette"
            jsplumb-surface-drop
            selector=".process-manager__toolkit__palette__item"
            surfaceId="surface"
            [dataGenerator]="dataGenerator"
          >
            @for (nodeType of nodeTypes; track $index) {
              <div 
                tabindex="0"
                [class]="'process-manager__toolkit__palette__item '"
                [matTooltip]="nodeType.label | translate"
                [attr.data-type]="nodeType.type"
                (click)="createPaletteItem(nodeType.type)"
              >
                <naris-icon
                  [icon]="nodeType.type"
                />
              </div>
            }
          </div>
        }
        @if (!!selection && !readOnly) {
          <div class="process-manager__toolkit__separator"></div>
        }
        <div class="process-manager__toolkit__inspector">
          <app-process-inspector surfaceId="surface" [readOnly]="readOnly" />
        </div>
      </div>
    }
  }
</div>

