import { inherits as e, createSuper as t, classCallCheck as n, createClass as r } from "../_virtual/_rollupPluginBabelHelpers.js";
import { Recado as u } from "../templates-2/core.js";
var a = "svg";
var l = "xlink";
function c(e) {
  var t = document.getElementById(e);
  return t != null ? t.innerHTML : null;
}
var o = function (u) {
  e(m, u);
  var o = t(m);
  function m(e) {
    var t;
    n(this, m);
    t = o.call(this, e);
    t.namespaceHandlers[a] = function (e) {
      var t = document.createElementNS("http://www.w3.org/2000/svg", e.split(":")[1]);
      return t;
    };
    t.namespaces[l] = "http://www.w3.org/1999/xlink";
    return t;
  }
  r(m, [{
    key: "ce",
    value: function e(t) {
      return document.createElement(t);
    }
  }, {
    key: "cf",
    value: function e() {
      return document.createDocumentFragment();
    }
  }, {
    key: "ctn",
    value: function e(t) {
      return document.createTextNode(t);
    }
  }, {
    key: "cc",
    value: function e(t) {
      return document.createComment(t);
    }
  }, {
    key: "_getDefaultTemplateResolver",
    value: function e() {
      return c;
    }
  }, {
    key: "removeElement",
    value: function e(t) {
      t.parentElement.removeChild(t);
    }
  }, {
    key: "removeTextNode",
    value: function e(t) {
      t.parentNode.removeChild(t);
    }
  }]);
  return m;
}(u);
function m(e) {
  return new o(e || {});
}
export { o as BrowserUiRecado, c as InBrowserTemplateResolver, m as newRecadoInstance };