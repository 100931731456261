import { createClass as e, classCallCheck as t, defineProperty as r } from "../_virtual/_rollupPluginBabelHelpers.js";
import { CLASS_SHAPE_LABEL as a, CLASS_SHAPE as i, ICON_ORIGIN_LEFT as n, ICON_ORIGIN_CENTER as s, ICON_ORIGIN_RIGHT as o } from "./shape-library-definitions.js";
import { newRecadoInstance as h } from "../browser-ui-templates-2/browser-ui-recado.js";
import { log as c } from "../ui-core/util/util.js";
import { DEFAULT as l } from "../ui-core/common/index.js";
import { ATTRIBUTE_VECTOR_EFFECT as d, ATTRIBUTE_VALUE_NON_SCALING_STROKE as p, svg as u, ELEMENT_TEXT as f, ELEMENT_SVG as v, ELEMENT_DEFS as m } from "../ui-core/browser-ui-renderer/svg-util.js";
import { getsert as g } from "../core/util.js";
var y = "label";
var w = "transform";
var b = "x";
var k = "y";
var S = "origin";
var C = "size";
var x = "key";
var A = "iconId";
var I = "currentColor";
var R = "middle";
var _ = "type";
var E = "category";
var j = "jtk-shape-icon";
var P = "jtk-icon";
var D = "category";
var L = "type";
var T = function () {
  function T(e, a) {
    var i = this;
    t(this, T);
    r(this, "compiledShapes", new Map());
    r(this, "templateRenderer", h());
    r(this, "shapeSetList", void 0);
    r(this, "shapeSets", new Map());
    r(this, "defaultCategoryId", void 0);
    r(this, "iconContainerTemplate", '<svg:svg class="'.concat(j, '" x="{{x}}" y="{{y}}" viewBox="{{viewBox}}" width="{{width}}" height="{{height}}" style="overflow:visible"/>'));
    r(this, "iconContainerAst", void 0);
    r(this, "compiledIcons", new Map());
    r(this, "compiledIconList", []);
    r(this, "typeParameter", void 0);
    r(this, "categoryParameter", void 0);
    a = a || {};
    if (e == null) {
      c("WARN: no shapes were provided to shape library.");
      e = [];
    } else {
      if (!Array.isArray(e)) {
        e = [e];
      }
    }
    this.typeParameter = a.typeParameter || L;
    this.categoryParameter = a.categoryParameter || D;
    this.shapeSetList = e.slice();
    e.forEach(function (e) {
      i.shapeSets.set(e.id, e);
      if (e.icons) {
        var t = g(i.compiledIcons, e.id, function () {
          return new Map();
        });
        for (var r in e.icons) {
          var a = {
            setId: e.id,
            id: r,
            def: e.icons[r],
            ast: i.templateRenderer.parse(e.icons[r].template)
          };
          t.set(r, a);
          i.compiledIconList.push(a);
        }
      }
    });
    this.defaultCategoryId = this.shapeSetList.length > 0 ? this.shapeSetList[0].id : l;
    this.iconContainerAst = this.templateRenderer.parse(this.iconContainerTemplate);
  }
  e(T, [{
    key: "getShapeDefinition",
    value: function e(t, r) {
      r = r || this.defaultCategoryId;
      return this.shapeSets.get(r).shapes[t];
    }
  }, {
    key: "getShapeTemplate",
    value: function e(t, r) {
      var a = this.getShapeDefinition(t, r);
      if (a == null) {
        c("Cannot find shape [".concat(t, "] in set [").concat(r, "]. Returning default shape."));
        return this._defaultTemplate();
      } else {
        return a.template;
      }
    }
  }, {
    key: "getCompiledShape",
    value: function e(t, r) {
      var a = this;
      var i = this._shapeKey(t, r);
      if (!this.compiledShapes.has(i)) {
        var n = this.templateRenderer.parse(this.getShapeTemplate(t, r));
        var s = function e(t) {
          return a.templateRenderer.render(n, t).fragment;
        };
        this.compiledShapes.set(i, s);
      }
      return this.compiledShapes.get(i);
    }
  }, {
    key: "_applyVectorEffect",
    value: function e(t) {
      var r = this;
      if (t.setAttribute) {
        t.setAttribute(d, p);
      }
      if (t.hasChildNodes) {
        t.childNodes.forEach(function (e) {
          return r._applyVectorEffect(e);
        });
      }
    }
  }, {
    key: "renderCompiledShape",
    value: function e(t, r, a, i) {
      r = t.outlineWidth || r || 1;
      var n = a || this.typeParameter;
      var s = i || this.categoryParameter;
      var o = this.getShapeDefinition(t[n], t[s]);
      var h = o.square ? Math.min(t.width, t.height) : t.width;
      var c = o.square ? Math.min(t.width, t.height) : t.height;
      var l = this.getCompiledShape(t[n], t[s]);
      var d = l(Object.assign({
        sw: r
      }, t, {
        width: h,
        height: c
      })).childNodes[0];
      return d;
    }
  }, {
    key: "renderShapeLabel",
    value: function e(t, r, i) {
      r = r || y;
      var n = u.node(f, {
        x: "50%",
        y: "50%",
        fill: I,
        stroke: I,
        "text-anchor": R,
        "dominant-baseline": R,
        class: a,
        "stroke-width": i || "0.25px"
      });
      n.textContent = t[r] || "";
      return n;
    }
  }, {
    key: "_shapeKey",
    value: function e(t, r) {
      r = r || this.defaultCategoryId;
      return [t, r].join(":");
    }
  }, {
    key: "_shapesToFragments",
    value: function e(t, r) {
      var i = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : y;
      var n = {};
      this.shapeSets.forEach(function (e) {
        for (var t in e.shapes) {
          var s = e.shapes[t].template + (r ? '<svg:text x="50%" y="50%" fill="'.concat(I, '" stroke="').concat(I, '" stroke-width="0.25px" text-anchor="').concat(R, '" dominant-baseline="').concat(R, '" class="').concat(a, '">{{').concat(i, "}}</svg:text>") : "");
          n["".concat(e.id, ":").concat(t)] = s;
          n["".concat(l, ":").concat(t)] = s;
        }
      });
      return n;
    }
  }, {
    key: "getShapeTagDefinition",
    value: function e(t, a) {
      var n = this;
      var s = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : y;
      t = t || 1;
      return {
        template: '<svg:svg class="'.concat(i, '" fill="{{fill}}" stroke="{{outline}}" viewBox="0 0 {{width}} {{height}}" preserveAspectRatio="none" stroke-width="').concat(t, '">                       \n                            <r-fragment key="').concat(E, ":").concat(_, '"/>                        \n                        </svg:svg>'),
        fragments: r({}, "".concat(E, ":").concat(_), this._shapesToFragments(t, a, s)),
        defaultableFragmentKeys: [E],
        rendered: function e(t, r, a, i) {
          n._maybeRenderIcons(t, r, a, i);
        },
        updated: function e(t, r, a) {
          n._maybeRenderIcons(t, r, a);
        }
      };
    }
  }, {
    key: "_defaultTemplate",
    value: function e() {
      return '<svg:rect x="0" y="0" width="{{width}}" height="{{height}}"/>';
    }
  }, {
    key: "_resolveIcon",
    value: function e(t, r) {
      return r != null ? this.compiledIcons.get(r).get(t) : this.compiledIconList.find(function (e) {
        return e.id === t;
      });
    }
  }, {
    key: "_maybeRenderIcons",
    value: function e(t, r, a, i) {
      var h = this;
      Array.from(t.querySelectorAll("svg.".concat(j))).forEach(function (e) {
        return e.parentNode.removeChild(e);
      });
      Array.from(t.querySelectorAll(P)).forEach(function (e) {
        var a = e.getAttribute(x),
          i = a != null ? r[a] : e.getAttribute(A);
        if (i != null) {
          var c = h._resolveIcon(i, r.category);
          if (c != null) {
            var l = parseFloat(e.getAttribute(b)) * r.width,
              u = parseFloat(e.getAttribute(k)) * r.height,
              f = Math.min(r.width, r.height),
              v = parseFloat(e.getAttribute(C)) * f,
              m = e.getAttribute(S) || n;
            var g = h.templateRenderer.render(c.ast).fragment.childNodes[0];
            g.setAttribute(d, p);
            var y = "0 0 ".concat(c.def.viewBox.width, " ").concat(c.def.viewBox.height);
            var I = h.templateRenderer.render(h.iconContainerAst, {
              x: l,
              y: u,
              width: v,
              height: v,
              viewBox: y
            }).fragment.childNodes[0];
            if (m === s) {
              I.setAttribute(w, "translate(-".concat(v / 2, ",-").concat(v / 2, ")"));
            } else if (m === o) {
              I.setAttribute(w, "translate(-".concat(v, ",0)"));
            }
            t.appendChild(I);
            I.appendChild(g);
          }
        }
      });
    }
  }, {
    key: "parseDefs",
    value: function e() {
      var t = this;
      var r = [];
      this.shapeSetList.forEach(function (e) {
        if (e.defs) {
          for (var a in e.defs) {
            var i = t.templateRenderer.parse(e.defs[a]);
            i[0].atts.id = a;
            r.push(i);
          }
        }
      });
      return r;
    }
  }, {
    key: "getDefsElements",
    value: function e() {
      var t = this;
      return this.parseDefs().map(function (e) {
        return t.templateRenderer.render(e, {}).fragment.childNodes[0];
      });
    }
  }, {
    key: "getSvgDefsElement",
    value: function e() {
      var t = u.node(v);
      var r = u.node(m);
      t.appendChild(r);
      this.getDefsElements().forEach(function (e) {
        r.appendChild(e);
      });
      return t;
    }
  }]);
  return T;
}();
export { T as ShapeLibraryImpl };