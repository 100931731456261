import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toArray',
  standalone: true
})
export class ToArrayPipe implements PipeTransform {

  public transform(value: number, fill = false): number[] {
    const createdArr = Array(value);
    return fill ? createdArr.fill(1).map((item: number, i: number) => item + i) : createdArr;
  }
}
