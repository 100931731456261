import { classCallCheck as r, defineProperty as t } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { _createConnectorBase as e } from "../core/connector/abstract-connector.js";
import { Connectors as o } from "../core/connector/connectors.js";
import { _computeOrthogonalConnector as a, _setOrthogonalAnchorOrientation as n, _importOrthogonalGeometry as s, _exportOrthogonalGeometry as i, transformOrthogonalGeometry as l } from "./operations.js";
import { CONNECTOR_TYPE_ORTHOGONAL as c, ORTHOGONAL_CONNECTOR_RECALC_STRATEGY_MODERN as u } from "./definitions.js";
function p(r, t) {
  var o = e(c, r, t, [30, 30]);
  t = t || {};
  var a = t.stub == null ? 30 : t.stub;
  var n = t.midpoint == null ? .5 : isNaN(t.midpoint) ? .5 : t.midpoint;
  var s = t.alwaysRespectStubs !== false;
  var i = t.cornerRadius != null ? t.cornerRadius : 0;
  var l = t.slightlyWonky === true;
  var p = t.supportLegacyConnectorData === true;
  var m = t.loopbackRadius || 25;
  var y = false;
  var v = Object.assign(o, {
    midpoint: n,
    stub: a,
    alwaysRespectStubs: s,
    lastx: null,
    lasty: null,
    lastOrientation: null,
    cornerRadius: i,
    slightlyWonky: l,
    supportLegacyConnectorData: p,
    loopbackRadius: m,
    isLoopbackCurrently: y,
    orthogonalSegments: [],
    geometry: {
      segments: []
    },
    vertexAvoidance: t.vertexAvoidance !== false,
    recalcStrategy: t.recalcStrategy || u
  });
  return v;
}
o.register(c, {
  compute: a,
  create: p,
  setAnchorOrientation: n,
  geometryImporter: s,
  geometryExporter: i,
  transformGeometry: l
});
var m = function t() {
  r(this, t);
};
t(m, "type", c);
export { m as OrthogonalConnector, p as _createOrthogonalConnector };