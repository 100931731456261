import { transformAnchorPlacement as e, _setAnchorLocation as n } from "../core/connector/abstract-connector.js";
import { log as t, clone as r, extend as o } from "../util/util.js";
import { Connectors as a, _addConnectorSegment as l } from "../core/connector/connectors.js";
import { SEGMENT_TYPE_ARC as s } from "../core/connector/arc-segment.js";
import { SEGMENT_TYPE_STRAIGHT as u } from "../core/connector/straight-segment.js";
import { addSegment as g, transformOrthogonalFromAbsolute as c, orthogonalSegmentLength as i, segmentDirections as y, anchorPlacementEquals as x, findSegment as m } from "./utils.js";
import { vertexAvoidance as f } from "./vertex-avoidance.js";
import { modernSourceAndTargetRelocation as h } from "./modern-source-target-relocator.js";
import { legacySourceAndTargetRelocation as v } from "./legacy-source-target-relocator.js";
import { AXIS_ORIENTATION_VERTICAL as S, AXIS_ORIENTATION_HORIZONTAL as p, ORTHOGONAL_CONNECTOR_RECALC_STRATEGY_LEGACY as d } from "./definitions.js";
import { stubCalculators as X, lineCalculators as Y } from "./stub-calculators.js";
function j(n, t, r) {
  return {
    source: e(n.source, t, r),
    target: e(n.target, t, r),
    segments: n.segments.map(function (e) {
      return {
        x: e.x + t,
        y: e.y + r
      };
    })
  };
}
function b(e) {
  if (e.geometry == null) {
    return null;
  } else {
    var n = e.geometry.segments.slice(1).map(function (e) {
      return {
        x: e.ax1,
        y: e.ay1
      };
    });
    var t = [],
      r = Infinity,
      o = Infinity;
    for (var a = 0; a < n.length; a++) {
      if (!(n[a].x === r && n[a].y === o)) {
        t.push(n[a]);
      }
      r = n[a].x;
      o = n[a].y;
    }
    return {
      segments: t,
      source: e.geometry.source,
      target: e.geometry.target
    };
  }
}
function I(e) {
  if (e.curX != null && e.curY != null) {
    return e;
  } else {
    var n = e;
    if (n.length === 6) {
      return {
        curX: n[0],
        curY: n[1],
        x: n[2],
        y: n[3],
        ox: n[4],
        oy: n[5]
      };
    } else {
      return null;
    }
  }
}
function P(e) {
  var n = 0;
  var r = e.map(function (e) {
    if (e.x != null && e.y != null) {
      return e;
    } else {
      var t = e[0],
        r = e[1];
      if (t != null && r != null) {
        return {
          x: t,
          y: r
        };
      } else {
        n++;
      }
    }
  });
  if (n > 0) {
    t("Could not process all segments in connector geometry - cannot load");
    return null;
  } else {
    return r;
  }
}
function A(e, r) {
  if (r != null && r.segments != null && r.source != null && r.target != null) {
    var o = e.supportLegacyConnectorData ? I(r.source) : r.source,
      a = e.supportLegacyConnectorData ? I(r.target) : r.target;
    if (o == null) {
      t("Invalid connector geometry: cannot decode source");
      return false;
    }
    if (a == null) {
      t("Invalid connector geometry: cannot decode target");
      return false;
    }
    var l = e.supportLegacyConnectorData ? P(r.segments) : r.segments;
    if (l != null && l.length >= 2) {
      var s = [],
        u = o.curX,
        g = o.curY;
      for (var c = 0; c < l.length; c++) {
        if (l[c].x === u && l[c].y === g) {
          t("Invalid path segment: ".concat(u, ", ").concat(g, ", ").concat(l[c].x, ", ").concat(l[c].y, ", zero length."));
          return false;
        } else {
          if (l[c].x - u === 0 || l[c].y - g === 0) {
            s.push({
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 0,
              o: null,
              ax1: u,
              ay1: g,
              ax2: l[c].x,
              ay2: l[c].y
            });
            u = l[c].x;
            g = l[c].y;
          } else {
            t("Invalid path segment: ".concat(u, ", ").concat(g, ", ").concat(l[c].x, ", ").concat(l[c].y, ", not in X or Y axis"));
            return false;
          }
        }
      }
      s.push({
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0,
        o: null,
        ax1: u,
        ay1: g,
        ax2: a.curX,
        ay2: a.curY
      });
      k(e, {
        segments: s,
        source: o,
        target: a
      });
      L(e);
      n(o, e.connection, 0);
      n(a, e.connection, 1);
      return true;
    }
  }
  return false;
}
function C(e, n, t, r, o) {
  var a = t.geometry;
  var l = !x(e, a.source),
    s = !x(n, a.target);
  if (l || s) {
    var u = e.curX - a.source.curX;
    var g = e.curY - a.source.curY;
    var i = n.curX - a.target.curX;
    var y = n.curY - a.target.curY;
    if (u === i && g === y) {
      var m = [];
      t.orthogonalSegments.forEach(function (e) {
        var n = Object.assign({}, e);
        n.ax1 += u;
        n.ax2 += u;
        n.ay1 += g;
        n.ay2 += g;
        m.push(n);
      });
      t.orthogonalSegments = c(m);
      return;
    }
    if (t.recalcStrategy !== d) {
      h(l, s, t, r, o);
    } else {
      v(l, s, t, r, o);
    }
  }
}
function O(e, n, t) {
  e.lastx = null;
  e.lasty = null;
  e.lastOrientation = null;
  var r = t.sourcePos,
    o = t.targetPos;
  var l = e.geometry;
  if (e.edited && l != null && l.segments != null && l.segments.length > 0) {
    e.orthogonalSegments = l.segments;
    C(r, o, e, n, t);
    a.setGeometry(e, {
      segments: e.orthogonalSegments,
      source: t.sourcePos,
      target: t.targetPos,
      quadrant: n.segment
    }, false);
  } else {
    e.orthogonalSegments.length = 0;
    var s = o.curX >= r.curX ? r.curX : o.curX,
      u = o.curY >= r.curY ? r.curY : o.curY;
    var c = X.get(n.anchorOrientation)(n, e.alwaysRespectStubs),
      i = n.sourceAxis === "x" ? 0 : 1,
      y = n.sourceAxis === "x" ? 1 : 0,
      x = c[i],
      m = c[y],
      f = c[i + 2],
      h = c[y + 2];
    g(e, c[0], c[1], n, s, u);
    var v = n.startStubX + (n.endStubX - n.startStubX) * e.midpoint,
      S = n.startStubY + (n.endStubY - n.startStubY) * e.midpoint;
    var p = Y.get(n.anchorOrientation);
    var d = p(e, n, t, n.sourceAxis, x, m, f, h, v, S, i);
    if (d) {
      for (var j = 0; j < d.length; j++) {
        g(e, d[j][0], d[j][1], n, s, u);
      }
    }
    g(e, c[2], c[3], n, s, u);
    g(e, n.tx, n.ty, n, s, u);
    a.setGeometry(e, {
      segments: e.orthogonalSegments,
      source: t.sourcePos,
      target: t.targetPos,
      quadrant: n.segment
    }, true);
  }
  q(e, e.orthogonalSegments, n);
}
function w(e, n, t) {
  var r = e.findIndex(function (e) {
    return e.ax1 === n.ax1 && e.ay1 === n.ay1 && e.ax2 === n.ax2 && e.ay2 === n.ay2;
  });
  if (r > -1) {
    var o = e[r].o,
      a = e[r];
    return {
      segment: a,
      index: r,
      orientation: o,
      prev: r > 1 ? e[r - 1] : null,
      next: r < e.length - 2 ? e[r + 1] : null,
      left: t ? m(e, -1, o, a) : null,
      right: t ? m(e, 1, o, a) : null
    };
  } else {
    return null;
  }
}
function D(e, n, t, r, o, a) {
  var l = w(e.orthogonalSegments, n);
  if (l != null) {
    if (l.orientation === S) {
      l.segment.ax1 = l.segment.ax2 = t[0];
    } else {
      l.segment.ay1 = l.segment.ay2 = t[1];
    }
    var s = l.orientation === S;
    var u = l.index;
    var g = l.index;
    var i = u > 1 ? e.orthogonalSegments[u - 1] : null,
      y = l.segment;
    while (i != null) {
      if (i.o === y.o) {
        if (s) {
          i.ax1 = y.ax1;
          i.ax2 = y.ax2;
        } else {
          i.ay1 = y.ay1;
          i.ay2 = y.ay2;
        }
      } else {
        i.ax2 = y.ax1;
        i.ay2 = y.ay1;
      }
      y = i;
      u--;
      i = u > 1 ? e.orthogonalSegments[u - 1] : null;
    }
    if (y.ax1 !== e.orthogonalSegments[0].ax2) {
      var x = {
        x1: null,
        y1: null,
        x2: null,
        y2: null,
        o: p,
        ax1: e.orthogonalSegments[0].ax2,
        ay1: e.orthogonalSegments[0].ay2,
        ax2: y.ax1,
        ay2: y.ay1
      };
      e.orthogonalSegments.splice(1, 0, x);
      g++;
    } else if (y.ay1 !== e.orthogonalSegments[0].ay2) {
      var m = {
        x1: null,
        y1: null,
        x2: null,
        y2: null,
        o: S,
        ax1: e.orthogonalSegments[0].ax2,
        ay1: e.orthogonalSegments[0].ay2,
        ax2: y.ax1,
        ay2: y.ay1
      };
      e.orthogonalSegments.splice(1, 0, m);
      g++;
    }
    y = l.segment;
    u = l.index;
    var h = u < e.orthogonalSegments.length - 2 ? e.orthogonalSegments[u + 1] : null;
    while (h != null) {
      if (h.o === y.o) {
        if (s) {
          h.ax1 = y.ax1;
          h.ax2 = y.ax2;
        } else {
          h.ay1 = y.ay1;
          h.ay2 = y.ay2;
        }
      } else {
        h.ax1 = y.ax2;
        h.ay1 = y.ay2;
      }
      y = h;
      u++;
      h = u < e.orthogonalSegments.length - 2 ? e.orthogonalSegments[u + 1] : null;
    }
    var v = e.orthogonalSegments[e.orthogonalSegments.length - 1];
    if (y.ax2 !== v.ax1) {
      var d = {
        x1: null,
        y1: null,
        x2: null,
        y2: null,
        o: p,
        ax1: y.ax2,
        ay1: y.ay2,
        ax2: v.ax1,
        ay2: v.ay1
      };
      e.orthogonalSegments.splice(e.orthogonalSegments.length - 1, 0, d);
    } else if (y.ay2 !== v.ay1) {
      var X = {
        x1: null,
        y1: null,
        x2: null,
        y2: null,
        o: S,
        ax1: y.ax2,
        ay1: y.ay2,
        ax2: v.ax1,
        ay2: v.ay1
      };
      e.orthogonalSegments.splice(e.orthogonalSegments.length - 1, 0, X);
    }
    if (a !== true) {
      var Y = f(e, e.orthogonalSegments, r, o, false);
      if (Y.sourceAdjusted) {
        g += 1;
      }
    }
    e.orthogonalSegments = c(e.orthogonalSegments);
    e.edited = true;
    return {
      ctx: l,
      segments: e.orthogonalSegments,
      index: g
    };
  } else {
    return null;
  }
}
function G(e) {
  var n = [r(e[0])];
  var t = r(e[e.length - 1]);
  var o = e.slice(1, e.length - 1).filter(function (e) {
    return i(e) > 0;
  }).map(r);
  if (o.length === 0) {
    return e;
  }
  var a = null;
  var l = null;
  for (var s = 0; s < o.length; s++) {
    if (a == null || l == null) {
      a = o[s];
      l = o[s].o;
    } else {
      if (o[s].o === l) {
        a.y2 = o[s].y2;
        a.x2 = o[s].x2;
        a.ay2 = o[s].ay2;
        a.ax2 = o[s].ax2;
      } else {
        n.push(a);
        a = o[s];
        l = o[s].o;
      }
    }
  }
  n.push(a);
  n.push(t);
  if (n.length === 3) {
    var u = n[1],
      g = u.o;
    var c = r(u),
      y = r(u),
      x = r(u);
    y.o = g === p ? S : p;
    y.x1 = c.x1;
    y.y1 = c.y1;
    y.x2 = c.x1;
    y.y2 = c.y1;
    y.ax1 = c.ax1;
    y.ay1 = c.ay1;
    y.ax2 = c.ax1;
    y.ay2 = c.ay1;
    x.o = g === p ? S : p;
    x.x1 = c.x2;
    x.y1 = c.y2;
    x.x2 = c.x2;
    x.y2 = c.y2;
    x.ax1 = c.ax2;
    x.ay1 = c.ay2;
    x.ax2 = c.ax2;
    x.ay2 = c.ay2;
    n = [n[0], y, c, x, n[2]];
  }
  return n;
}
function L(e) {
  var n = G(e.orthogonalSegments);
  e.orthogonalSegments = n;
  if (e.geometry != null) {
    e.geometry.segments = c(n);
  }
}
function R(e) {
  return o({}, e);
}
function q(e, n, t) {
  e.paintInfo = t;
  var r = null,
    a,
    g,
    c;
  for (var x = 0; x < n.length - 1; x++) {
    r = r || R(n[x]);
    a = R(n[x + 1]);
    g = y(r);
    c = y(a);
    if (e.cornerRadius > 0 && r.o !== a.o) {
      var m = Math.min(i(r), i(a));
      var f = Math.min(e.cornerRadius, m / 2);
      r.x2 -= g[0] * f;
      r.y2 -= g[1] * f;
      a.x1 += c[0] * f;
      a.y1 += c[1] * f;
      var h = g[1] === c[0] && c[0] === 1 || g[1] === c[0] && c[0] === 0 && g[0] !== c[1] || g[1] === c[0] && c[0] === -1,
        v = a.y1 > r.y2 ? 1 : -1,
        S = a.x1 > r.x2 ? 1 : -1,
        p = v === S,
        d = p && h || !p && !h ? a.x1 : r.x2,
        X = p && h || !p && !h ? r.y2 : a.y1;
      l(e, u, o({}, r));
      if (!e.slightlyWonky) {
        l(e, s, {
          r: f,
          x1: r.x2,
          y1: r.y2,
          x2: a.x1,
          y2: a.y1,
          cx: d,
          cy: X,
          ac: h
        });
      } else {
        l(e, s, o({
          r: f,
          cx: d,
          cy: X,
          ac: h
        }, r));
      }
    } else {
      l(e, u, o({}, r));
    }
    r = a;
  }
  if (a != null) {
    l(e, u, o({}, r));
  }
}
function M(e, n, t) {
  if (e.orthogonalSegments.length >= 2) {
    var r = n === 0 ? e.orthogonalSegments[0] : e.orthogonalSegments[e.orthogonalSegments.length - 1];
    var o = t[0] === 0 ? S : p;
    r.o = o;
    e.edited = true;
  }
}
function k(e, n) {
  n.segments = c(n.segments);
  e.orthogonalSegments = n.segments;
  a.setGeometry(e, n, false);
}
export { O as _computeOrthogonalConnector, b as _exportOrthogonalGeometry, A as _importOrthogonalGeometry, M as _setOrthogonalAnchorOrientation, D as _setOrthogonalSegmentPosition, L as _trimOrthogonalConnector, G as _trimOrthogonalSegments, j as transformOrthogonalGeometry };