<div class="title"><h3>{{'tree-compare.title' | translate}}</h3></div>

<svg class="svg">
  @if (!!line) {
    <path @showLine class="line" [attr.d]="line" />
  }
</svg>
<div class="main">
  <div class="tree-wrapper">
    <div class="tree-wrapper__header">{{ tableLabels[0] | translate }}</div>
    <div class="tree-wrapper__tree">
      <naris-treeview 
        [data]="oldData"
        [layouthint]="['hide-status']"
        [compareObject]="{isNewTree: false, showCompareColors: true}"
      />
    </div>
  </div>
  <div class="tree-wrapper">
    <div class="tree-wrapper__header">{{ tableLabels[1] | translate }}</div>
    <div class="tree-wrapper__tree">
      <naris-treeview 
        [data]="newData"
        [layouthint]="['hide-status']"
        [compareObject]="{isNewTree: true, showCompareColors: true}"
      />
    </div>
  </div>
</div>