import { createClass as t, classCallCheck as e, defineProperty as i } from "../_virtual/_rollupPluginBabelHelpers.js";
import { svg as r, ELEMENT_RECT as n, ATTRIBUTE_STROKE_WIDTH as a, SVG_NAMESPACE as o, ELEMENT_G as s, ATTRIBUTE_VERSION as l, ATTRIBUTE_XMLNS as h, ATTRIBUTE_FILL as u, ATTRIBUTE_STROKE as d, ELEMENT_TEXT as c, ATTRIBUTE_TEXT_ANCHOR as m, ELEMENT_PATH as p, ELEMENT_SVG as f, XLINK_NAMESPACE as v, ELEMENT_DEFS as y, ATTRIBUTE_STROKE_DASHARRAY as g, ATTRIBUTE_STROKE_LINEJOIN as x, ATTRIBUTE_STROKE_LINECAP as b, ATTRIBUTE_STROKE_MITERLIMIT as w, ATTRIBUTE_STROKE_DASHOFFSET as k, ATTRIBUTE_STROKE_OPACITY as E } from "../ui-core/browser-ui-renderer/svg-util.js";
import { log as C, isString as A } from "../ui-core/util/util.js";
import { Edge as S } from "../core/model/graph.js";
import { NONE as L } from "../ui-core/core/constants.js";
import { EVENT_CLICK as _ } from "../ui-core/browser-ui-renderer/event-constants.js";
import { WIDTH as M, HEIGHT as N } from "../core/constants.js";
import { Selection as j } from "../core/selection.js";
import { BackgroundPlugin as I } from "../browser-ui-plugin-background/background-plugin.js";
import { GeneratedGridBackground as V } from "../browser-ui-plugin-background/generated-grid-background.js";
import { CLASS_OVERLAY as W } from "../ui-core/core/css-constants.js";
var X = "jtk-export-underlay";
var Y = "jtk-export-overlay";
var B = "jtk-export-cancel";
var T = "jtk-export-dimensions";
var O = "jtk-export-download-tools";
var R = "jsplumbtoolkit-export";
var P = "Download";
var G = "middle";
var H = {
  png: "image/png",
  svg: "image/svg+xml",
  jpeg: "image/jpeg"
};
var D = [[g, "dashstyle"], [x, null], [b, null], [w, null], [k, null], [E, null]];
function q(t) {
  var e = 32768;
  var i = 0,
    r = "",
    n;
  var a = t.length;
  while (i < a) {
    n = t.subarray(i, Math.min(i + e, a));
    r += String.fromCodePoint.apply(null, n);
    i += e;
  }
  return btoa(r);
}
function z(t) {
  var e = new TextEncoder().encode(t);
  return q(e);
}
function F(t) {
  return z(t.outerHTML);
}
function U(t) {
  var e = F(t);
  return Z(e);
}
function Z(t) {
  return "data:image/svg+xml;base64,".concat(t);
}
function $(t) {
  var e = {
    x: 0,
    y: 0
  };
  var i = t.group;
  while (i != null) {
    e.x += i.data.left;
    e.y += i.data.top;
    i = i.group;
  }
  return e;
}
var J = function () {
  function g(t, r) {
    e(this, g);
    i(this, "surface", void 0);
    i(this, "shapeLibrary", void 0);
    i(this, "minx", Infinity);
    i(this, "miny", Infinity);
    i(this, "maxx", -Infinity);
    i(this, "maxy", -Infinity);
    i(this, "minVertexX", Infinity);
    i(this, "minVertexY", Infinity);
    i(this, "maxVertexX", -Infinity);
    i(this, "maxVertexY", -Infinity);
    i(this, "minEdgeX", Infinity);
    i(this, "minEdgeY", Infinity);
    i(this, "maxEdgeX", -Infinity);
    i(this, "maxEdgeY", -Infinity);
    i(this, "style", void 0);
    i(this, "options", {
      fill: "white",
      outline: "black",
      showLabels: true,
      labelAttribute: "text",
      labelStrokeWidth: "0.25px",
      margins: {
        x: 10,
        y: 10
      },
      labelColor: "black",
      strokeWidth: 2,
      gridPaddingCells: 1
    });
    this.surface = t;
    this.shapeLibrary = r || t._$_shapeLibrary;
    if (this.shapeLibrary == null) {
      C("WARN: No shapeLibrary passed in to SvgExporter or registered on the Surface to export. A default node renderer will be used instead.");
    }
  }
  t(g, [{
    key: "_setOptions",
    value: function t(e) {
      e = e || {};
      if (e.showLabels != null) {
        this.options.showLabels = e.showLabels;
      }
      this.options.showGrid = e.showGrid !== false;
      this.options.labelAttribute = e.labelAttribute || this.options.labelAttribute;
      this.options.labelStrokeWidth = e.labelStrokeWidth || this.options.labelStrokeWidth;
      this.options.margins = e.margins || this.options.margins;
      this.options.labelColor = e.labelColor || this.options.labelColor;
      this.options.outline = e.outline || this.options.outline;
      this.options.fill = e.fill || this.options.fill;
      this.options.strokeWidth = e.strokeWidth || this.options.strokeWidth;
      if (e.strokeWidth != null) {
        this.options.strokeWidth = e.strokeWidth;
      }
      this.options.defaultSize = this.options.defaultSize || {
        w: 50,
        h: 50
      };
      this.options.selection = e.selection;
      this.options.path = e.path;
      this.style = this._processStyle(e.style);
    }
  }, {
    key: "_processStyle",
    value: function t(e) {
      if (e != null) {
        if (A(e)) {
          return e;
        } else {
          var i = e;
          var r = "";
          for (var n in i) {
            r += "".concat(n, " {\n");
            for (var a in i[n]) {
              r += "".concat(a, ":").concat(i[n][a], ";\n");
            }
            r += "}\n";
          }
          return r;
        }
      } else {
        return null;
      }
    }
  }, {
    key: "_defaultRenderNode",
    value: function t(e) {
      return r.node(n, i({}, a, this.options.strokeWidth));
    }
  }, {
    key: "_renderNode",
    value: function t(e) {
      if (this.shapeLibrary != null) {
        try {
          return this.shapeLibrary.renderCompiledShape(e, e.outlineWidth || this.options.strokeWidth);
        } catch (t) {
          return this._defaultRenderNode(e);
        }
      } else {
        return this._defaultRenderNode(e);
      }
    }
  }, {
    key: "_processGroups",
    value: function t(e, i) {
      var r = i ? i.getGroups() : this.surface.toolkitInstance.getGroups();
      return this._processVertices(r, e, i);
    }
  }, {
    key: "_processNodes",
    value: function t(e, i) {
      var r = i ? i.getNodes() : this.surface.toolkitInstance.getNodes();
      return this._processVertices(r, e, i);
    }
  }, {
    key: "_processVertices",
    value: function t(e, i, r) {
      var n = this;
      var p = this.surface.getZoom();
      var f = this.surface.getContainer().getBoundingClientRect();
      return e.map(function (t) {
        var e = t.data.width == null ? i.w : t.data.width;
        var r = t.data.height == null ? i.h : t.data.height;
        if (t.group != null) {
          n.minVertexX = Math.min(n.minVertexX, t.data.left);
          n.maxVertexX = Math.max(n.maxVertexX, t.data.left + e);
          n.minVertexY = Math.min(n.minVertexY, t.data.top);
          n.maxVertexY = Math.max(n.maxVertexY, t.data.top + r);
        }
        Array.from(n.surface.getRenderedElement(t).querySelectorAll(".jtk-shape *")).forEach(function (t) {
          var e = t.getBoundingClientRect();
          n.minVertexX = Math.min(n.minVertexX, (e.left - f.left) / p);
          n.maxVertexX = Math.max(n.maxVertexX, (e.right - f.left) / p);
          n.minVertexY = Math.min(n.minVertexY, (e.top - f.top) / p);
          n.maxVertexY = Math.max(n.maxVertexY, (e.bottom - f.top) / p);
        });
        n.minx = Math.min(n.minx, n.minVertexX);
        n.maxx = Math.max(n.maxx, n.maxVertexX);
        n.miny = Math.min(n.miny, n.minVertexY);
        n.maxy = Math.max(n.maxy, n.maxVertexY);
        var v = document.createElementNS(o, s);
        var y = n._renderNode(t.data);
        y.removeAttribute(l);
        y.removeAttribute(h);
        y.setAttribute(u, t.data.fill || n.options.fill);
        y.setAttribute(d, t.data.outline || n.options.outline);
        y.setAttribute(a, t.data.outlineWidth || n.options.strokeWidth);
        v.appendChild(y);
        var g;
        if (n.options.showLabels) {
          var x = t.data[n.options.labelAttribute];
          if (x != null && x.length > 0) {
            g = document.createElementNS(o, c);
            g.setAttribute(u, t.data.textColor || n.options.labelColor);
            g.setAttribute(d, t.data.textColor || n.options.labelColor);
            g.setAttribute(a, n.options.labelStrokeWidth);
            g.setAttribute(m, G);
            g.innerHTML = x;
            v.appendChild(g);
          }
        }
        return {
          data: t.data,
          g: v,
          shape: y,
          lbl: g,
          groupOffset: $(t)
        };
      });
    }
  }, {
    key: "_resolveStrokeAttributes",
    value: function t(e, i) {
      var r = {};
      D.forEach(function (t) {
        var n = e.getAttribute(t[0]);
        if (n == null) {
          var a = t[1];
          if (a != null) {
            n = i.paintStyleInUse[a];
          }
          if (n == null) {
            n = window.getComputedStyle(e)[t[0]];
          }
        }
        if (n != null) {
          r[t[0]] = n;
        }
      });
      return r;
    }
  }, {
    key: "_processEdges",
    value: function t(e) {
      var r = this;
      var s = e ? e.getAll().filter(function (t) {
        return t.objectType === S.objectType;
      }) : this.surface.toolkitInstance.getAllEdges();
      return s.map(function (t) {
        var e = r.surface.getRenderedConnection(t.id);
        var s = e.connector;
        var l = s.canvas;
        var h = parseInt(l.style.left, 10);
        var f = parseInt(l.style.top, 10);
        var v = document.createElementNS(o, "g");
        var y = Array.from(l.querySelectorAll(p));
        var g = y.map(function (t) {
          var e = r._resolveStrokeAttributes(t, s.connection);
          var i = t.getAttribute(d) || "black";
          var n = parseFloat(t.getAttribute("stroke-width") || "".concat(r.options.strokeWidth));
          var a = (t.getAttribute("transform") || "translate(0,0)").match(/translate\(([0-9\.]+),([0-9\.]+)\)/).slice(1).map(parseFloat);
          r.minEdgeX = Math.min(r.minEdgeX, s.x + s.bounds.xmin - n);
          r.maxEdgeX = Math.max(r.maxEdgeX, s.x + s.bounds.xmax + n);
          r.minEdgeY = Math.min(r.minEdgeY, s.y + s.bounds.ymin - n);
          r.maxEdgeY = Math.max(r.maxEdgeY, s.y + s.bounds.ymax + n);
          var o = {
            d: t.getAttribute("d"),
            transform: a,
            stroke: i,
            fill: t.getAttribute("fill") || L,
            strokeWidth: n
          };
          Object.assign(o, e);
          return o;
        });
        r.minx = Math.min(r.minx, r.minEdgeX);
        r.maxx = Math.max(r.maxx, r.maxEdgeX);
        r.miny = Math.min(r.miny, r.minEdgeY);
        r.maxy = Math.max(r.maxy, r.maxEdgeY);
        var x = Array.from(l.querySelectorAll("g.".concat(W)));
        var b = x.map(function (t) {
          var e = t.querySelector(n);
          var o = t.querySelector(c);
          if (e && o) {
            var s;
            var l = (o.getAttribute("transform") || "translate(0,0)").match(/translate\(([0-9\.]+),([0-9\.]+)\)/).slice(1).map(parseFloat);
            var h = o.getAttribute("fill") || r.options.labelColor;
            var p = o.getAttribute(a) || r.options.labelStrokeWidth;
            var f = {
              transform: l,
              label: o.textContent,
              bg: {
                fill: e.getAttribute(u) || "white",
                stroke: e.getAttribute(d) || L,
                x: e.getAttribute("x"),
                y: e.getAttribute("y"),
                width: e.getAttribute("width"),
                height: e.getAttribute("height")
              },
              text: (s = {
                fill: h,
                stroke: h
              }, i(s, a, p), i(s, "x", o.getAttribute("x")), i(s, "y", o.getAttribute("y")), i(s, "dominant-baseline", G), i(s, m, G), s)
            };
            return f;
          } else {
            return null;
          }
        }).filter(function (t) {
          return t != null;
        });
        return {
          edge: t,
          connector: s,
          connection: e,
          g: v,
          outputPaths: g,
          outputTextGroups: b,
          x: h,
          y: f
        };
      });
    }
  }, {
    key: "exportCurrentSelection",
    value: function t(e) {
      return this.export(Object.assign(e || {}, {
        selection: this.surface.toolkitInstance.getSelection()
      }));
    }
  }, {
    key: "export",
    value: function t(e) {
      var a,
        s = this;
      this.minx = Infinity;
      this.miny = Infinity;
      this.maxx = -Infinity;
      this.maxy = -Infinity;
      this._setOptions(e);
      var l = null;
      if (this.options.path) {
        l = new j(this.surface.toolkitInstance);
        l.append(this.options.path);
      } else {
        l = this.options.selection;
      }
      var u = this._processGroups(this.options.defaultSize, l);
      var d = this._processNodes(this.options.defaultSize, l);
      var m = this._processEdges(l);
      var g;
      var x = null;
      if (this.options.showGrid !== false) {
        var b = this.surface.getPlugin(I.type);
        if (b != null) {
          if (b.background instanceof V) {
            var w = b.background;
            x = Object.assign({}, w.grid);
            g = w.generateGrid();
            var k = this.minx - this.options.gridPaddingCells * x.w;
            k = x.w * Math.floor(k / x.w);
            var E = this.maxx + this.options.gridPaddingCells * x.w;
            E = x.w * Math.ceil(E / x.w);
            this.minx = k;
            this.maxx = E;
            var C = this.miny - this.options.gridPaddingCells * x.h;
            C = x.h * Math.floor(C / x.h);
            var A = this.maxy + this.options.gridPaddingCells * x.h;
            A = x.h * Math.ceil(A / x.h);
            this.miny = C;
            this.maxy = A;
          }
        }
      }
      this.minx -= this.options.margins.x;
      this.miny -= this.options.margins.y;
      this.maxx += this.options.margins.x;
      this.maxy += this.options.margins.y;
      var S = this.maxx - this.minx;
      var L = this.maxy - this.miny;
      var _ = r.node(f, (a = {
        viewBox: "0 0 ".concat(S, " ").concat(L)
      }, i(a, h, o), i(a, "xmlns:xlink", v), i(a, "version", "1.1"), a));
      if (this.style != null) {
        var W = r.node("style");
        _.appendChild(W);
        W.innerHTML = this.style;
      }
      var X = this.shapeLibrary.getDefsElements();
      if (X.length > 0) {
        var Y = r.node(y);
        _.appendChild(Y);
        X.forEach(function (t) {
          return Y.appendChild(t);
        });
      }
      var B = function t(e) {
        e.forEach(function (t) {
          var e = t.data.left + t.groupOffset.x - s.minx,
            i = t.data.top + t.groupOffset.y - s.miny;
          t.shape.setAttribute("transform", "translate(".concat(e, ",").concat(i, ")"));
          if (t.lbl) {
            var r = parseFloat(t.data.width == null ? s.options.defaultSize.w : t.data.width);
            var n = parseFloat(t.data.height == null ? s.options.defaultSize.h : t.data.height);
            t.lbl.setAttribute("x", "" + r / 2);
            t.lbl.setAttribute("y", "" + n / 2);
            t.lbl.setAttribute("transform", "translate(".concat(e, ",").concat(i, ")"));
          }
          _.append(t.g);
        });
      };
      B(u);
      m.forEach(function (t) {
        var e = t.x - s.minx,
          i = t.y - s.miny;
        t.outputPaths.forEach(function (r) {
          var n = {
            transform: "translate(".concat(e + r.transform[0], ",").concat(i + r.transform[1], ")"),
            stroke: r.stroke,
            fill: r.fill,
            d: r.d
          };
          D.forEach(function (t) {
            if (r[t[0]] != null) {
              n[t[0]] = r[t[0]];
            }
          });
          var a = document.createElementNS(o, p);
          for (var s in n) {
            a.setAttribute(s, n[s]);
          }
          t.g.appendChild(a);
        });
        t.outputTextGroups.forEach(function (r) {
          var a = "translate(".concat(e + r.transform[0], ",").concat(i + r.transform[1], ")");
          var s = document.createElementNS(o, n);
          for (var l in r.bg) {
            s.setAttribute(l, r.bg[l]);
          }
          s.setAttribute("transform", a);
          t.g.appendChild(s);
          var h = document.createElementNS(o, c);
          for (var u in r.text) {
            h.setAttribute(u, r.text[u]);
          }
          h.setAttribute("transform", a);
          h.textContent = r.label;
          t.g.appendChild(h);
        });
        _.appendChild(t.g);
      });
      B(d);
      if (g != null) {
        var T = r.node(f, {
          x: this.options.margins.x,
          y: this.options.margins.y,
          width: S - 2 * this.options.margins.x + 1,
          height: L - 2 * this.options.margins.y + 1
        });
        T.innerHTML = g;
        _.insertBefore(T, _.firstChild);
      }
      var O = _.outerHTML;
      _.setAttribute(M, "" + S);
      _.setAttribute(N, "" + L);
      return {
        width: S,
        height: L,
        element: _,
        svg: _.outerHTML,
        viewBoxOnlySvg: O
      };
    }
  }]);
  return g;
}();
var K = function () {
  function r(t, n) {
    e(this, r);
    i(this, "surface", void 0);
    i(this, "shapeLibrary", void 0);
    i(this, "svgExporter", void 0);
    this.surface = t;
    this.shapeLibrary = n;
    this.svgExporter = new J(this.surface, this.shapeLibrary);
  }
  t(r, [{
    key: "_doExport",
    value: function t(e, i, r) {
      i = i || {};
      var n = i.type || "image/png";
      var a = i.quality;
      var o = document.createElement("canvas");
      o.style.left = "-50000px";
      o.style.top = "-50000px";
      o.style.opacity = "0";
      document.body.appendChild(o);
      var s = o.getContext("2d");
      var l = e.width / e.height;
      var h = e.width,
        u = e.height;
      if (i.width) {
        h = i.width;
        u = h / l;
      } else if (i.height) {
        u = i.height;
        h = u * l;
      }
      o.width = h;
      o.height = u;
      s.fillStyle = "white";
      s.fillRect(0, 0, h, u);
      var d = new Image();
      d.onload = function () {
        s.drawImage(d, 0, 0, h, u);
        var t = o.toDataURL(n, a);
        o.parentElement.removeChild(o);
        r({
          url: t,
          width: h,
          height: u,
          contentType: n,
          element: e.element
        });
      };
      e.element.setAttribute(M, "".concat(h));
      e.element.setAttribute(N, "".concat(u));
      d.src = U(e.element);
    }
  }, {
    key: "export",
    value: function t(e, i) {
      var r = this.svgExporter.export(e);
      this._doExport(r, e, i);
    }
  }, {
    key: "exportCurrentSelection",
    value: function t(e, i) {
      var r = this.svgExporter.exportCurrentSelection(e);
      this._doExport(r, e, i);
    }
  }]);
  return r;
}();
var Q = function () {
  function r(t, n) {
    var a = this;
    e(this, r);
    i(this, "underlay", void 0);
    i(this, "overlay", void 0);
    i(this, "surface", void 0);
    i(this, "closeButton", void 0);
    i(this, "shapeLibrary", void 0);
    this.surface = t;
    this.shapeLibrary = n;
    this.underlay = document.createElement("div");
    this.underlay.className = X;
    this.underlay.addEventListener(_, function (t) {
      if (t.target === a.underlay) {
        a._cancel();
      }
    });
    this.overlay = document.createElement("div");
    this.overlay.className = Y;
    this.closeButton = document.createElement("div");
    this.closeButton.className = B;
    this.overlay.appendChild(this.closeButton);
    this.closeButton.addEventListener(_, function () {
      a._cancel();
    });
  }
  t(r, [{
    key: "_cancel",
    value: function t() {
      this.underlay.parentNode.removeChild(this.underlay);
      this.overlay.parentNode.removeChild(this.overlay);
    }
  }, {
    key: "export",
    value: function t(e) {
      e = e || {};
      var i = new J(this.surface, this.shapeLibrary);
      var r = i.export(e);
      this.overlay.appendChild(r.element);
      var n = new Blob([r.svg], {
        type: H.svg
      });
      var a = URL.createObjectURL(n);
      var o = document.createElement("a");
      o.href = a;
      o.download = e.filename || R;
      o.innerHTML = e.downloadButtonLabel || P;
      this.overlay.appendChild(o);
      document.body.appendChild(this.underlay);
      document.body.appendChild(this.overlay);
    }
  }]);
  return r;
}();
var tt = function () {
  function r(t, n) {
    var a = this;
    e(this, r);
    i(this, "underlay", void 0);
    i(this, "overlay", void 0);
    i(this, "closeButton", void 0);
    i(this, "surface", void 0);
    i(this, "shapeLibrary", void 0);
    this.surface = t;
    this.shapeLibrary = n;
    this.underlay = document.createElement("div");
    this.underlay.className = X;
    this.underlay.addEventListener(_, function (t) {
      if (t.target === a.underlay) {
        a._cancel();
      }
    });
    this.overlay = document.createElement("div");
    this.overlay.className = Y;
    this.closeButton = document.createElement("div");
    this.closeButton.className = B;
    this.overlay.appendChild(this.closeButton);
    this.closeButton.addEventListener(_, function () {
      a._cancel();
    });
  }
  t(r, [{
    key: "_cancel",
    value: function t() {
      this.underlay.parentNode.removeChild(this.underlay);
      this.overlay.parentNode.removeChild(this.overlay);
    }
  }, {
    key: "_export",
    value: function t(e, i) {
      var r = this;
      i = Object.assign({}, i || {});
      if (i.dimensions) {
        Object.assign(i, i.dimensions[0]);
      }
      var n = new K(this.surface, this.shapeLibrary);
      this._actuallyDoExport(n, e, i, function (t) {
        r.overlay.appendChild(t.element);
        var a = t.width / t.height;
        var o = document.createElement("div");
        o.className = O;
        r.overlay.appendChild(o);
        var s = document.createElement("a");
        s.href = t.url;
        s.download = i.filename || R;
        s.innerHTML = i.downloadButtonLabel || P;
        o.appendChild(s);
        if (i.dimensions) {
          var l = document.createElement("select");
          l.className = T;
          i.dimensions.forEach(function (t) {
            var e = document.createElement("option");
            var i = t.width ? Math.floor(t.width / a) : t.height;
            var r = t.width ? t.width : Math.floor(t.height * a);
            e.innerHTML = "".concat(r, "x").concat(i);
            e.setAttribute(M, "".concat(r));
            e.value = "".concat(r);
            l.appendChild(e);
          });
          l.addEventListener("change", function () {
            var t = l.options[l.selectedIndex],
              a = parseInt(t.getAttribute(M), 10);
            r._actuallyDoExport(n, e, i, function (t) {
              i.onDimensionsChanged && i.onDimensionsChanged(t.width, t.height, t.url);
              s.href = t.url;
            }, a);
          });
          o.appendChild(l);
        }
        document.body.appendChild(r.underlay);
        document.body.appendChild(r.overlay);
        i.onShow && i.onShow(r.overlay, t.width, t.height, t.url);
      });
    }
  }, {
    key: "export",
    value: function t(e) {
      this._export(false, e);
    }
  }, {
    key: "_actuallyDoExport",
    value: function t(e, i, r, n, a) {
      if (a) {
        r.width = a;
        delete r.height;
      }
      i ? e.exportCurrentSelection(r, n) : e.export(r, n);
    }
  }]);
  return r;
}();
export { B as CLASS_EXPORT_CANCEL, T as CLASS_EXPORT_DIMENSIONS, O as CLASS_EXPORT_DOWNLOAD_TOOLS, Y as CLASS_EXPORT_OVERLAY, X as CLASS_EXPORT_UNDERLAY, K as ImageExporter, tt as ImageExporterUI, J as SvgExporter, Q as SvgExporterUI, z as base64Encode };