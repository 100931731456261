<div [style.width]="coreService.sidebarCollapsed ? 'calc(100% - 5rem)' : 'calc(100% - 14rem)'" class="action">
  <div class="toggle-wrapper">
    <naris-slidetoggle
      class="hide-optional-field-toggle"
      (changed)="toggleOptionalFields($event)"
      [id]="'FooterToolbarOptionalFields'"
      #hideOptionalToggle
    >{{'form.hide_optional' | translate}}</naris-slidetoggle>
  </div>
  @for (actionGroup of actionGroups; track $index) {
    <div class="action-group">
      @if (actionGroupHasActions(actionGroup)) {
        <div class="action-wrapper">
          <naris-taskgroup [taskgroup]="actionGroup" [showIcons]="false" buttonClass="ml-2" />
        </div>
      }
    </div>
  }
</div>