<naris-card class="card left">
  <h2>
    <svg class="mr-4" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#FF4D5B"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66667 5L5 6.66667L8.33333 10L5 13.3333L6.66667 15L10 11.6667L13.3333 15L15 13.3333L11.6667 10L15 6.66667L13.3333 5L10 8.33333L6.66667 5Z" fill="white"/>
    </svg>
    {{'error.something_wrong' | translate}}
  </h2>
  <p>{{'auth.sign_in_partner_error' | translate}}</p>
  <naris-button color="dark" [routerLink]="['/login']" id="AuthErrorTryAgain">Try again</naris-button>
</naris-card>

<naris-card class="card right">
  <h2>Error: {{ errorTitle }}</h2>
  <p>{{'auth.please_contact_us' | translate}}</p>
  <h4>{{'error.description_label' | translate}}</h4>
  <p class="error mb-8">{{ errorDescription }}</p>
  <a [href]="langService.getHref('help')" target="_blank"><naris-button color="primary" id="AuthErrorNeedHelp">{{'auth.need_help' | translate}}</naris-button></a>
</naris-card>
