import { cls as a, att as r, ATTRIBUTE_GROUP as t } from "../core/constants.js";
import { CLASS_CONNECTOR as o, CLASS_ENDPOINT as n, CLASS_OVERLAY as v } from "../core/css-constants.js";
var e = "data-jtk-container";
var c = "data-jtk-group-content";
var s = "data-jtk-enabled";
var i = "data-jtk-scope";
var d = "endpoint";
var p = "element";
var j = "connection";
var m = "div";
var k = "position";
var f = a(o);
var l = a(n);
var b = r(t);
var g = r(c);
var u = a(v);
export { e as ATTRIBUTE_CONTAINER, c as ATTRIBUTE_GROUP_CONTENT, s as ATTRIBUTE_JTK_ENABLED, i as ATTRIBUTE_JTK_SCOPE, j as CONNECTION, p as ELEMENT, m as ELEMENT_DIV, d as ENDPOINT, k as PROPERTY_POSITION, f as SELECTOR_CONNECTOR, l as SELECTOR_ENDPOINT, b as SELECTOR_GROUP, g as SELECTOR_GROUP_CONTAINER, u as SELECTOR_OVERLAY };