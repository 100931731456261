import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import html2canvas from 'html2canvas';
import { FilterChip, FormInput } from '@core/classes';
import { BeinformedService, FormService } from '@core/services';
import { filterExpand } from '@shared/animations/table.animations';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FORM_LOOKUP_TOKEN } from '@core/constants';
import { ComponentType } from '@angular/cdk/portal';
import { FormLookupComponent } from '@core/form/form-lookup/form-lookup.component';
import { ToolbarComponent } from '../../toolbar/toolbar.component';
import { ToolbarItemComponent } from '../../toolbar/toolbar-item/toolbar-item.component';
import { ButtonComponent } from '../../../elements/button/button.component';
import { WidgetHeatmapComponent } from '../widget-heatmap.component';
import { SelectComponent } from '../../../elements/select/select.component';
import type { IFormAnchorElementDynamic, IHeatmap, IInputOption, IWidgetConfig } from '@core/models';

@Component({
  selector: 'naris-widget-heatmap-wrapper',
  templateUrl: './widget-heatmap-wrapper.component.html',
  styleUrls: ['./widget-heatmap-wrapper.component.scss'],
  animations: [filterExpand],
  standalone: true,
  imports: [ToolbarComponent, ToolbarItemComponent, ButtonComponent, WidgetHeatmapComponent, FormsModule, SelectComponent]
})
export class WidgetHeatmapWrapperComponent implements OnInit {

  @ViewChild('heatmap') public heatmap: ElementRef;

  @Input() public href: string;
  @Input() public editMode = false;
  @Input() public widgetConfig: IWidgetConfig;
  @Input() public category?: string;

  @Output() public readonly widgetConfigChanged = new EventEmitter<any>();
  @Output() public readonly categoryChanged = new EventEmitter<string>();

  public chips: FilterChip[] = [];
  public input: any;
  public title: string;
  public inputs: any[] = [];
  public isLoading = true;
  public filterForm = new FormGroup<Record<string, FormControl>>({});
  public heatmapData?: IFormAnchorElementDynamic[] | null;
  public originalData: IFormAnchorElementDynamic[] | null;
  public newWidgetConfig: IWidgetConfig;
  public selectedHeatmap: IHeatmap[] | null;
  public previousWidgetConfig: IWidgetConfig;
  public isOverview = false;

  private readonly allRiskCats = new Map<string, string>();
  private readonly heatmapDictionary = new Map<string, { name: string; heatmapID: string }>();
  private heatmapValuesEndpoint: string;
  public heatmapTableHref: string | undefined;
  public dialogAllHref: string;

  constructor(
    private readonly beinformedService: BeinformedService,
    private readonly formService: FormService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    @Inject(FORM_LOOKUP_TOKEN) private readonly formLookupComponent: ComponentType<FormLookupComponent>
  ) {}

  public ngOnInit(): void {
    if (!this.router.url.startsWith('/dashboard')) this.isOverview = true;
    this.beinformedService.fetchForm(this.href, false, {}).subscribe({next: res => {
      this.title = res?.contributions?.label;
      const suffix = res.data.error.formresponse.missing?.anchors[0].elements.find(el => el.elementid === 'SuffixName');
      if (!!suffix?.suggestion) this.title = `${this.title} ${suffix.suggestion}`;
      const allHrefUrl = res.data.error.formresponse.missing?.anchors[0].elements.find(el => el.elementid === 'AllHref');
      if (!!allHrefUrl) this.dialogAllHref = allHrefUrl.suggestion;
      this.heatmapTableHref = res.data.error.formresponse.missing?.anchors[0].elements.find(el => el.elementid === 'Href')?.suggestion;
      const dynamicschema = res?.data?.error?.formresponse?.missing?.anchors?.[0]?.elements?.[0]?.dynamicschema || null;
      this.heatmapValuesEndpoint = res?.data?.error?.formresponse?.missing?.anchors?.[0].elements.find(el => el.elementid === 'CurrentRiskMapValues')?.suggestion || '';
      this.originalData = dynamicschema;
      this.heatmapData = this.originalData;
      dynamicschema?.forEach(row => {
        const riskCategoryID = row.elements.RiskCategoryID;
        const riskCategoryName = row.elements.RiskCategoryName as string;
        if (!this.allRiskCats.get(riskCategoryID as string)) this.allRiskCats.set(riskCategoryID as string, riskCategoryName);
      });
      this.setFilters();
      this.setHeatmapDictionary();
      if (!!this.widgetConfig?.filters) {
        this.chips = this.widgetConfig?.filters;
        this.filterData();
        this.filterForm.get('RiskCategoryID')?.setValue(this.chips[0].val);
      }
      if (!!this.category) {
        this.filterForm.get('RiskCategoryID')?.setValue(this.category);
      }
      this.isLoading = false;
    }});
  }

  private setFilters() {
    const options: IInputOption[] = [];
    this.allRiskCats.forEach((val, key) => options.push({label: val, value: key, key: key}));

    this.input = {
      param: 'RiskCategoryID',
      name: 'RiskCategoryID',
      options,
      type: 'choicefilter',
      label: 'Category',
      layouthint: ['checkbox'],
      multiplechoice: true,
      optionMode: 'dynamicWithThreshold',
      children: [
        {
          RiskCategoryName: {
            type: 'string',
            label: 'Category name',
            displaysize: 50,
            maxLength: 50
          }
        }
      ],
      dynamics: [],
      id: 'RiskCategoryID',
      value: [],
      disabled: false,
      multiple: false,
      hidden: true,
      validators: {},
      mandatory: false,
      controlType: 'select'
    };
    const control = this.formService.toFormField(this.input as unknown as FormInput);
    this.filterForm.addControl(this.input.id, control);
    this.inputs.push(this.input);

    this.filterForm.valueChanges.subscribe(changes => {
      if (!!changes.RiskCategoryID && !Array.isArray(changes.RiskCategoryID)) {
        this.addChip('RiskCategoryID', changes.RiskCategoryID, this.input.label, this.allRiskCats.get(changes.RiskCategoryID)!);
        this.filterData();
        this.getHeatmapData(changes.RiskCategoryID);
        this.categoryChanged.emit(changes.RiskCategoryID);
      } else if (Object.keys(changes).includes('RiskCategoryID') && !changes.RiskCategoryID) {
        this.heatmapData = null;
        this.selectedHeatmap = null;
      }
    });
  }

  private setHeatmapDictionary() {
    this.originalData?.forEach(item => {
      const riskCategoryID = item.elements.RiskCategoryID as string;
      this.heatmapDictionary.set(riskCategoryID, {name: item.elements.RiskCategoryName as string, heatmapID: item.elements.HeatmapID as string});
    });
  }

  private getHeatmapData(riskCategoryID: string) {
    const heatmap = this.heatmapDictionary.get(riskCategoryID);
    const heatmapUrl = `${this.heatmapValuesEndpoint}?HeatmapID=${heatmap?.heatmapID}`;
    this.beinformedService.fetchForm(heatmapUrl, false, {}).subscribe({next: res => {
      const dynamicSchema = res.data?.error?.formresponse?.missing?.anchors?.[0]?.elements?.[0]?.dynamicschema;
      if (!!dynamicSchema) {
        this.selectedHeatmap = dynamicSchema.map(item => ({...item.elements}) as unknown as IHeatmap);
      }
    }});
  }

  private addChip(elementId: string, value: string, label: string, itemLabel: string) {
    const mappedInput = {id: elementId, controlType: 'select', value: value, multiple: true};
    const chip = new FilterChip(label, itemLabel, value, mappedInput);
    this.chips = [];
    this.chips.push(chip);
    if (this.editMode) {
      this.newWidgetConfig = {...this.widgetConfig, filters: this.chips};
    }
  }

  private filterData() {
    const selectedChips = this.chips.map(x => x.val);
    const filteredData = !!selectedChips?.length ? this.originalData?.filter(item => selectedChips.includes(item.elements.RiskCategoryID)) : this.originalData;
    this.heatmapData = filteredData;
  }

  public async save() {
    const element = this.heatmap.nativeElement;
    const canvas = await html2canvas(element);
    canvas.toBlob(blob => {
      const link = document.createElement('a');
      link.download = `${this.title}.png`;
      link.href = URL.createObjectURL(blob!);
      link.click();
    }, 'image/png');
  }

  public getFormControl(controlName: string) {
    return this.filterForm.get(controlName) as FormControl;
  }

  public openDialog(href: string) {
    const riskCategoryId = this.filterForm.get('RiskCategoryID')?.value;
    if (!!href && !!riskCategoryId) {
      href = href.replace('{heatmapRiskCategoryId}', riskCategoryId);
      this.dialog.open(this.formLookupComponent, {
        panelClass: 'naris-advanced-lookup-dialog',
        minWidth: '54rem',
        data: {endpoint: href, multiple: false, enableRowClick: false}
      }).afterClosed().subscribe(val => {
        if (!!val?._links?.self.href) {
          void this.router.navigate([val._links.self.href]);
        }
      });
    }
  }
}
