<naris-card class="card left">
  <h2 class="mb-4">{{'signup.sign_up' | translate}}</h2>
  <p class="mb-4">{{'signup.with_existing_email' | translate}}</p>
  <naris-form-field 
    label="One-click sign up"
    [fixStyling]="true"
  >
    <naris-button size="medium" color="black" id="SignupB2C">
      <svg enable-background="new 0 0 2499.6 2500" viewBox="0 0 2499.6 2500" xmlns="http://www.w3.org/2000/svg" height="16" width="16">
        <path d="m1187.9 1187.9h-1187.9v-1187.9h1187.9z" fill="#f1511b"/>
        <path d="m2499.6 1187.9h-1188v-1187.9h1187.9v1187.9z" fill="#80cc28"/>
        <path d="m1187.9 2500h-1187.9v-1187.9h1187.9z" fill="#00adef"/>
        <path d="m2499.6 2500h-1188v-1187.9h1187.9v1187.9z" fill="#fbbc09"/>
      </svg>
      <span class="ml-2">Microsoft account</span>
    </naris-button>
  </naris-form-field>
  <naris-divider label="or" />
  <naris-form-field 
    label="With your email address"
    [fixStyling]="true"  
  >
    <naris-button size="medium" color="dark" [routerLink]="['/signup/info']" id="SignupEmailAddress">{{'email_address' | translate}}</naris-button>
  </naris-form-field>
  <naris-form-actions justify="right">
    <a (click)="toLogin()" [routerLink]="['/login']" class="naris-link">{{'to_login' | translate}}</a>
  </naris-form-actions>
</naris-card>
<naris-card class="card right">
  <h2 class="text-center mb-4">{{'signup.difference' | translate}}</h2>
  <h4>{{'signup.one_click' | translate}}</h4>
  <ul class="process-list">
    <li class="active">
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM15 12C15 13.66 13.66 15 12 15C10.34 15 9 13.66 9 12C9 10.34 10.34 9 12 9C13.66 9 15 10.34 15 12Z" fill="#6B8299"/>
      </svg>
      {{'signup.partners' | translate}}
    </li>
    <li>
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="#CFDAE5"/>
      </svg>
      {{'signup.add_personal_info' | translate}}
    </li>
    <li>
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="#CFDAE5"/>
      </svg>
      {{'signup.access_naris' | translate}}
    </li>
  </ul>
  <naris-divider label="or" />
  <h4>{{'email_address' | translate}}</h4>
  <ul class="process-list">
    <li class="active">
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM15 12C15 13.66 13.66 15 12 15C10.34 15 9 13.66 9 12C9 10.34 10.34 9 12 9C13.66 9 15 10.34 15 12Z" fill="#6B8299"/>
      </svg>
      {{'signup.with_email' | translate}}
    </li>
    <li>
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="#CFDAE5"/>
      </svg>
      {{'signup.add_personal_info' | translate}}
    </li>
    <li>
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="#CFDAE5"/>
      </svg>
      {{'signup.login' | translate}}
    </li>
  </ul>
</naris-card>
