<div class="header">
  <div class="header__item">
    @if (title) {
      <h3>{{title}}</h3>
    }
  </div>
  <div class="header__item">
    @if (!!pushAction) {
      <naris-button
        type="button"
        (click)="pushAll()"
        [matTooltip]="pushAction.label || ''"
        matTooltipPosition="below"
        color="primary"
        id="MultiSliderPushAction"
      >{{pushAction.label}}</naris-button>
    }
  </div>
</div>
<div class="content" [attr.data-test-id]="'multi-slider__' + id">
  <div class="bar-wrapper">
    <div class="bar-wrapper-slider">
      <div class="bar-wrapper-slider-line">
        <div class="bar-wrapper-slider-line-background" #multislider [style.width.px]="offsetWidth"></div>
        <div class="bar-wrapper-slider-line-highlight" [style.width.px]="calculatedHighlightWidth"></div>
      </div>
      <div class="bar-wrapper-values">
        @for (data of data; track data.LikelihoodClass._id) {
          <div 
            class="bar-wrapper-values-value" 
            [style.width.px]="data.width"
          >
            <div class="bar-wrapper-values-value-actions">
              @if (data['canUpdate']) {
                <div (click)="executeAction(data['canUpdate'], data)" class="icon" tabindex="0"><naris-icon [icon]="'edit'" iconClass="small" /></div>
              }
              @if (data['canDelete']) {
                <div (click)="executeAction(data['canDelete'], data)" class="icon" tabindex="0"><naris-icon [icon]="'delete'" iconClass="small" /></div>
              }
            </div>
            <div
              class="bar-wrapper-values-value-label"
              [matTooltip]="getLabel(data)"
              matTooltipPosition="below"
            >
              {{getLabel(data)}}
            </div>
          </div>
        }
        
      </div>
      <div class="bar-wrapper-invisible" (mousemove)="mouseMove($event);" (mouseup)="mouseUp()" [style.width.px]="offsetWidth"></div>
      <div class="bar-wrapper-handles">
        @for (data of data; track data.LikelihoodClass._id) {
          <div
            class="bar-wrapper-handles-handle"
            (mousedown)="mouseDown($event)"
            (mouseup)="mouseUp()"
            [style.left.px]="data.sliderLeft"
            [id]="data[className]._id"
            [class.last]="data.isLast"
            [class.disabled]="!!pushAction"
            >
            <span class="bar-wrapper-handles-handle-value">{{data[className][maxName]}}</span>
          </div>
        }
      </div>
    </div>
    @if (!!createAction) {
      <div class="create-button">
        <naris-button [icon]="'add'" type="button" (click)="create()" id="MultiSliderCreate"/>
      </div>
    }
  </div>
</div>
<div class="footer">
  @if (hadOverlap) {
    <div>{{overlapFixMessage | translate}} {{overlapMessage | translate:param}}<br>{{overlapSaveMessage | translate}}</div>
  }
  @if (!pushAction) {
    <naris-button
      type="button"
      (click)="reset()"
      [matTooltip]="'multislider.reset' | translate"
      matTooltipPosition="below"
      id="MultiSliderReset"
    >{{resetText | translate}}</naris-button>
  }
</div>
