import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { NavigationDirection } from '@core/enums/breadcrumb.enum';
import { ICrumb, ICrumbInfo } from '@core/models/breadcrumb.models';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NarisBreadcrumbService {

  public breadcrumb: ICrumb[] = [];
  public breadcrumbUpdated = new Subject<boolean>();
  public addBreadcrumb = true;
  
  private readonly _buffer: any[] = [];
  private readonly _crumbInfo = new Map<string, ICrumbInfo>();
  private readonly _crumbInfoEmpty = new Map<string, ICrumbInfo>();
  private _freshCrumbs: any[] = [];
  private _originalBreadcrumb: ICrumb[] = [];
  private _timeout: ReturnType<typeof setTimeout>;
  
  private _isNavigatingBackward = false;
  private _previousNavId: number | null;
  private _bufferIndex: number;

  private _navTimeout: NodeJS.Timeout;
  public menuClick = false;
  
  get navigationDirection(): NavigationDirection {
    return this._isNavigatingBackward ? NavigationDirection.back : NavigationDirection.forward;
  }

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) { }

  public add(url: string, label: string, caseType?: string): void {
    if (this._crumbInfo.has(url)) {
      const existingCrumb = this._crumbInfo.get(url);
      caseType = existingCrumb?.caseType ? existingCrumb?.caseType : caseType;
    }
    this._crumbInfo.set(url, {name: label, label: caseType, caseType});
    if (!caseType) {
      this._crumbInfoEmpty.set(url, {name: label, label: label, caseType});    
    }
    this.updateCrumbNames();

    if (!!this._freshCrumbs?.length) {
      this._freshCrumbs = [];
    }

    this.cleanUpCrumbs();
  }

  private updateCrumbNames(): void {    
    this._originalBreadcrumb.forEach((kruimel, index) => {
      let href = kruimel.href.startsWith('/') ? kruimel.href : `/${kruimel.href}`;
      href = href.endsWith('/') ? href.slice(0, -1) : href;
      if (this._crumbInfo.has(href)) {
        kruimel.info = this._crumbInfo.get(href);
        if (!!kruimel.info?.caseType) {
          kruimel.info.name = index === this._originalBreadcrumb.length - 1 ? kruimel.info.label || kruimel.info.caseType : kruimel.info.caseType;
        }
      } else if (!kruimel.routeLabel?.['alias']) {
        if (!!kruimel.routeLabel && !!kruimel.info && !kruimel.info.name) {          
          kruimel.info.name = kruimel.routeLabel;
        }
      }
    });
    this.truncateBreadCrumb();
  }

  public navigationClick(href: string, name: string, label: string, reset: boolean): void {
    if (this.isCrumbClicked) {
      this.isCrumbClicked = false;
      return;
    }
    if (!this.addBreadcrumb) {
      this.addBreadcrumb = true;
      return;
    }
    if (this._buffer.length === 0) {
      this._buffer.push(this._originalBreadcrumb);
      this._bufferIndex = this._buffer.length - 1;
    }
    
    if (reset && this.navigationDirection === NavigationDirection.forward) {
      this._originalBreadcrumb = [];
    }

    if (this.menuClick) {
      this._originalBreadcrumb = [];
      this.menuClick = false;
    }
    clearTimeout(this._navTimeout);
    this._navTimeout = setTimeout(() => {

      if (this._crumbInfo.has(href)) {
        name = this._crumbInfo.get(href)?.name ?? '';
      } else if (href.includes('config') && !href.includes('general')) {
        const splittedHref = href.split('/');
        splittedHref.splice(splittedHref.length - 1, 1);
        const newHref = splittedHref.join('/');
        name = this._crumbInfo.get(newHref)?.name ?? '';
      }
      this._originalBreadcrumb.push({href: href, routeLabel: href, info: {name, label, caseType: undefined}}); 
      
      const foundIndex = this._buffer.findIndex(item => item[0].href === this._originalBreadcrumb[0].href);
      if (foundIndex == -1) {
        this._buffer.push(this._originalBreadcrumb);
        this._bufferIndex = this._buffer.length - 1;
      } else if (foundIndex > -1) {
        this._buffer[foundIndex] = this._originalBreadcrumb;
      }

      this.updateCrumbNames();
      
    }, 500);
  }

  public start(): void {
    this.breadcrumb = [];
    this._originalBreadcrumb = [];
    this.router.events.subscribe( (event: any) => {
      if (event instanceof NavigationEnd) {
        const foundCrumb = this._originalBreadcrumb.find(crumble => crumble.href === event.url);
        const foundChild = this.route.firstChild?.routeConfig?.children?.find(route => route.path === (event.url.startsWith('/') ? event.url.slice(1) : event.url));
        if (!!foundChild && !!foundCrumb && !!foundCrumb.info) {
          foundCrumb.info.name = foundChild.data?.breadcrumb;
        }
        this.truncateBreadCrumb();
      }
    });

    this.determineCurrentBreadcrumb(this.route.snapshot, '');
    this.truncateBreadCrumb();
  }

  public determineCurrentBreadcrumb(activatedRouteSnapshot: ActivatedRouteSnapshot, routeLinkPrefix: string) {
    if (!!activatedRouteSnapshot?.firstChild?.url?.length) {
      this._freshCrumbs.push(activatedRouteSnapshot.firstChild.url.join('/'));
      const href = `/${this._freshCrumbs.join('/')}`;
      const routeLabel = activatedRouteSnapshot?.firstChild?.data?.breadcrumb;
      this._originalBreadcrumb.push({href, routeLabel, info: {name: '___'}} as ICrumb);
      routeLinkPrefix  += `/${activatedRouteSnapshot?.firstChild?.routeConfig?.path}`;
    }
    if (!!activatedRouteSnapshot.firstChild?.firstChild) {
      this.determineCurrentBreadcrumb(activatedRouteSnapshot.firstChild, routeLinkPrefix);
    }

  }

  private truncateBreadCrumb() {
    this.breadcrumb = [...this._originalBreadcrumb];
    this.breadcrumbUpdated.next(true);
  }

  private isCrumbClicked: boolean;
  public navigate(crumb: ICrumb, crumbIndex: number): void {
    const foundIndex = this.breadcrumb.findIndex(item => item.href === crumb.href);    
    const foundOriginalIndex = this._originalBreadcrumb.findIndex(item => item.href === crumb.href);
    if (foundIndex === crumbIndex && foundIndex + 1 !== this.breadcrumb.length) {
      this.breadcrumb.splice(foundIndex + 1, this.breadcrumb.length - foundIndex);
      this._originalBreadcrumb.splice(foundOriginalIndex + 1, this._originalBreadcrumb.length - foundOriginalIndex);
      this.isCrumbClicked = true;
      void this.router.navigate([crumb.href]);
    }
  }

  public pop(url: string): void {
    if (this.navigationDirection === NavigationDirection.back) {
      if (this._originalBreadcrumb.length === 1) {
        this._originalBreadcrumb = this._bufferIndex >= 0 ? this._buffer[--this._bufferIndex] : [];
      } else if (!!this._originalBreadcrumb?.length) {
        this._originalBreadcrumb.pop();
      }
    } else if (this.navigationDirection === NavigationDirection.forward) {
      const itemIndex = this._buffer[this._bufferIndex].findIndex((item: { href: string }) => item.href === url) as number;
      if (itemIndex > -1 && itemIndex !== this._buffer[this._bufferIndex].length) {
        this._originalBreadcrumb = this._buffer[this._bufferIndex].slice(0, itemIndex + 1);
      } else if (itemIndex === -1 && this._buffer.length - 1 > this._bufferIndex) {
        this._originalBreadcrumb = this._buffer[++this._bufferIndex].slice(0, 1);
      }
    }
  }

  public cleanUpCrumbs(): void {
    clearTimeout(this._timeout);
    this._timeout = setTimeout(() => {
      this.breadcrumb = this.breadcrumb.filter(crumb => crumb.info?.name !== '___');
      this._originalBreadcrumb = this._originalBreadcrumb.filter(crumb => crumb.info?.name !== '___');
    }, 750);
  }

  public setNavId(newId: number | null) {
    if (this._previousNavId === null && !!newId) {
      this._isNavigatingBackward = true;
    }
    if (newId === null) {
      this._isNavigatingBackward = false;
    }
    if (!!newId && !!this._previousNavId && this._previousNavId < newId) {
      this._isNavigatingBackward = !this._isNavigatingBackward;
    }
    this._previousNavId = newId;
  }

  public removeLastCrumb() {
    this.breadcrumb.pop();
    this._originalBreadcrumb.pop();
  }
}
