import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { ToolbarItemComponent } from '../toolbar/toolbar-item/toolbar-item.component';
import { MatrixComponent } from '../matrix/matrix.component';

@Component({
  selector: 'app-matrix-dialog',
  templateUrl: './matrix-dialog.component.html',
  styleUrl: './matrix-dialog.component.scss',
  standalone: true,
  imports: [ToolbarComponent, ToolbarItemComponent, MatrixComponent]
})
export class MatrixDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      matrixData: Record<string, any>[];
      gotoAsAction?: boolean;
      title?: string;
    },
    public dialogRef: MatDialogRef<MatrixDialogComponent>
  ) {}

  public close() {
    this.dialogRef.close(true);
  }
}
