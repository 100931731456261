import { AfterViewInit, Component } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { IAction } from '@core/models';
import { FormService, TabService } from '@core/services';
import { StrategyMapService } from '@core/services/strategy-map.service';
import { ObjectData } from '@jsplumbtoolkit/browser-ui';
import { BaseNodeComponent } from '@jsplumbtoolkit/browser-ui-angular';
import { NgClass } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatBadge } from '@angular/material/badge';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../../elements/button/button.component';

@Component({
  selector: 'app-node',
  templateUrl: './node.component.html',
  styleUrls: ['./node.component.scss'],
  standalone: true,
  imports: [NgClass, MatTooltip, ButtonComponent, MatBadge, TranslateModule]
})
export class NodeComponent extends BaseNodeComponent implements AfterViewInit {

  public kpiBadgeColor = 'danger' as ThemePalette;

  public updateGoalAction: IAction;
  public updateCSFAction: IAction;

  constructor(
    public strategyMapService: StrategyMapService,
    private readonly formService: FormService,
    private readonly tabService: TabService
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    this.updateGoalAction = this.obj?.actions?.find((action: IAction) => action.name === 'update-goal');
    this.updateCSFAction = this.obj?.actions?.find((action: IAction) => action.name === 'update-critical-success-factor');
  }

  get isLeft() {
    return this.obj.left === 84;
  }

  get isRight() {
    return this.obj.left as number + 180 >= this.strategyMapService.bounds.vw - 100;
  }

  public addNode(parentId: string, position: string) {
    this.strategyMapService.addNode$.next({parentId, position});
  }

  public configClicked(obj: ObjectData, el: HTMLDivElement) {
    this.strategyMapService.configClicked$.next({obj, el});
  }

  public openConnectedObject(obj: string) {
    this.tabService.activeTabName = obj.endsWith('s') ? obj.toLowerCase() === 'process' ? `${obj}es` : obj : `${obj}s`;
    this.formService.open(this.obj.objectHref as string, 'detail').subscribe(saved => saved ? this.refreshMap() : null);
  }

  public openConnectedKPI() {
    this.tabService.activeTabName = 'KeyPerformanceIndicator';
    this.formService.open(this.obj.objectHref as string, 'detail').subscribe(saved => saved ? this.refreshMap() : null);
  }

  public openConnectedObjects() {
    this.formService.open(this.obj.objectHref, 'detail').subscribe(saved => saved ? this.refreshMap() : null);
  }

  public refreshMap = () => {
    this.strategyMapService.refreshMap$.next();
  };
}
