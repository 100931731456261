<naris-card class="card">
  @if (!loading && invalidToken === false) {
    <h2>{{'link_account' | translate}}</h2>
    <p class="mb-8">{{'link_account.text' | translate}}</p>
    <form [formGroup]="linkForm" (ngSubmit)="onSubmit()">
      <naris-form-field [label]="'email_address' | translate" [fixStyling]="true">
        <naris-input [control]="getFormControl('account')" [placeholder]="'email_address' | translate" />
      </naris-form-field>
      <naris-form-field [label]="'repeat_email_address' | translate" [fixStyling]="true">
        <naris-input [control]="getFormControl('accountRepeat')" [placeholder]="'repeat_email_address' | translate" />
      </naris-form-field>
      <naris-form-actions>
      <a 
        role="button"
        class="naris-link"
        href="javascript:void(0)"
        (click)="onBack()" 
      >{{'to_login' | translate}}</a>
        <naris-button color="primary" [disabled]="linkForm.invalid" id="LinkAccount">{{'link_account' | translate}}</naris-button>
      </naris-form-actions>
    </form>
  }
  @if (!loading && (!!invalidToken || invalidToken === undefined)) {
    <h2 class="mb-8">{{'invitation_expired' | translate}}</h2>
    <p>{{'invitation_expired.info' | translate}}</p>
  }
  @if (loading) {
    <div class="loader">
      <naris-progress-spinner [strokeWidth]="2" [diameter]="24" />
    </div>
  }
</naris-card>
