import { createClass as o, classCallCheck as t, defineProperty as i } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { uuid as n, isString as s, isNumber as e, map as r, extend as f } from "../../util/util.js";
import { AnchorLocations as l } from "./definitions.js";
import { namedContinuousValues as u, _createContinuousAnchor as a } from "./continuous-anchors.js";
import { _createPerimeterAnchor as y } from "./perimeter-anchors.js";
var c = function () {
  function s(o, e, r, f) {
    t(this, s);
    this.instance = o;
    this.element = r;
    i(this, "isFloating", true);
    i(this, "isContinuous", void 0);
    i(this, "isDynamic", void 0);
    i(this, "locations", []);
    i(this, "currentLocation", 0);
    i(this, "locked", false);
    i(this, "cssClass", "");
    i(this, "timestamp", null);
    i(this, "type", "Floating");
    i(this, "id", n());
    i(this, "orientation", [0, 0]);
    i(this, "size", void 0);
    this.size = o.viewport.getPosition(f);
    this.locations.push({
      x: .5,
      y: .5,
      ox: this.orientation[0],
      oy: this.orientation[1],
      offx: 0,
      offy: 0,
      iox: this.orientation[0],
      ioy: this.orientation[1],
      cls: ""
    });
  }
  o(s, [{
    key: "_updateOrientationInRouter",
    value: function o() {
      this.instance.router.setAnchorOrientation(this, [this.locations[0].ox, this.locations[0].oy]);
    }
  }, {
    key: "over",
    value: function o(t) {
      this.orientation = this.instance.router.getEndpointOrientation(t);
      this.locations[0].ox = this.orientation[0];
      this.locations[0].oy = this.orientation[1];
      this._updateOrientationInRouter();
    }
  }, {
    key: "out",
    value: function o() {
      this.orientation = null;
      this.locations[0].ox = this.locations[0].iox;
      this.locations[0].oy = this.locations[0].ioy;
      this._updateOrientationInRouter();
    }
  }]);
  return s;
}();
var x = {
    x: .5,
    y: 0,
    ox: 0,
    oy: -1,
    offx: 0,
    offy: 0
  },
  h = {
    x: .5,
    y: 1,
    ox: 0,
    oy: 1,
    offx: 0,
    offy: 0
  },
  p = {
    x: 0,
    y: .5,
    ox: -1,
    oy: 0,
    offx: 0,
    offy: 0
  },
  m = {
    x: 1,
    y: .5,
    ox: 1,
    oy: 0,
    offx: 0,
    offy: 0
  },
  v = {
    x: 0,
    y: 0,
    ox: 0,
    oy: -1,
    offx: 0,
    offy: 0
  },
  g = {
    x: 1,
    y: 0,
    ox: 1,
    oy: -1,
    offx: 0,
    offy: 0
  },
  d = {
    x: 0,
    y: 1,
    ox: 0,
    oy: 1,
    offx: 0,
    offy: 0
  },
  C = {
    x: 1,
    y: 1,
    ox: 0,
    oy: 1,
    offx: 0,
    offy: 0
  },
  j = {
    x: .5,
    y: .5,
    ox: 0,
    oy: 0,
    offx: 0,
    offy: 0
  };
var k = {
  Top: [x],
  Bottom: [h],
  Left: [p],
  Right: [m],
  TopLeft: [v],
  TopRight: [g],
  BottomLeft: [d],
  BottomRight: [C],
  Center: [j],
  AutoDefault: [x, p, h, m]
};
function A(o, t) {
  t = t || {};
  if (o === l.Perimeter) {
    return y(t);
  }
  var i = k[o];
  if (i != null) {
    return O(o, r(i, function (o) {
      return f({
        iox: o.ox,
        ioy: o.oy
      }, o);
    }), t);
  }
  i = u[o];
  if (i != null) {
    return a(o, i.faces, t);
  }
  throw {
    message: "jsPlumb: unknown anchor type '" + o + "'"
  };
}
function O(o, t, i) {
  return {
    type: o,
    locations: t,
    currentLocation: 0,
    locked: false,
    id: n(),
    isFloating: false,
    isContinuous: false,
    isDynamic: t.length > 1,
    timestamp: null,
    cssClass: i.cssClass || ""
  };
}
function R(o, t, i, n) {
  return new c(o, t, i, n);
}
function L(o) {
  return Array.isArray(o) && o.length < 7 && o.every(e) || o.length === 7 && o.slice(0, 5).every(e) && s(o[6]);
}
function P(o) {
  return o.type == null && o.x != null && o.y != null && o.ox != null && o.oy != null;
}
function _(o) {
  if (s(o)) {
    return A(o, null);
  } else if (Array.isArray(o)) {
    if (L(o)) {
      var t = o;
      return O(null, [{
        x: t[0],
        y: t[1],
        ox: t[2],
        oy: t[3],
        offx: t[4] == null ? 0 : t[4],
        offy: t[5] == null ? 0 : t[5],
        iox: t[2],
        ioy: t[3],
        cls: t[6] || ""
      }], {
        cssClass: t[6] || ""
      });
    } else {
      var i = r(o, function (o) {
        if (s(o)) {
          var t = k[o];
          return t != null ? f({
            iox: t[0].ox,
            ioy: t[0].oy,
            cls: ""
          }, t[0]) : null;
        } else if (L(o)) {
          return {
            x: o[0],
            y: o[1],
            ox: o[2],
            oy: o[3],
            offx: o[4] == null ? 0 : o[4],
            offy: o[5] == null ? 0 : o[5],
            iox: o[2],
            ioy: o[3],
            cls: o[6] || ""
          };
        } else if (P(o)) {
          return Object.assign({
            iox: o.ox,
            ioy: o.oy,
            offx: o.offsetX == null ? 0 : o.offsetX,
            offy: o.offsetY == null ? 0 : o.offsetY,
            cls: ""
          }, o);
        }
      }).filter(function (o) {
        return o != null;
      });
      return O("Dynamic", i, {});
    }
  } else {
    var n = o;
    return A(n.type, n.options);
  }
}
export { c as LightweightFloatingAnchor, O as _createAnchor, R as createFloatingAnchor, _ as makeLightweightAnchorFromSpec };