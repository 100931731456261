import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CollabService } from '@core/services';
import { fadeInOutAnimation } from '@shared/animations/core.animations';
import { ElementColors } from '@core/enums';
import { filter } from 'rxjs';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ToolbarComponent } from '../../toolbar/toolbar.component';
import { ToolbarItemComponent } from '../../toolbar/toolbar-item/toolbar-item.component';
import { IconComponent } from '../../../elements/icon/icon.component';
import { ButtonComponent } from '../../../elements/button/button.component';
import { ProgressBarComponent } from '../../../elements/progress-bar/progress-bar.component';
import { CollaborationRoomPanelComponent } from './collaboration-room-panel/collaboration-room-panel.component';
import { CollaborationRoomCcComponent } from './collaboration-room-cc/collaboration-room-cc.component';
import type { CollabCase } from '@core/classes';

@Component({
  selector: 'naris-collaboration-room',
  templateUrl: './collaboration-room.component.html',
  styleUrls: ['./collaboration-room.component.scss'],
  animations: [fadeInOutAnimation],
  standalone: true,
  imports: [ToolbarComponent, ToolbarItemComponent, NgClass, MatTooltip, IconComponent, ButtonComponent, NgTemplateOutlet, ProgressBarComponent, CollaborationRoomPanelComponent, CollaborationRoomCcComponent, TranslateModule]
})
export class CollaborationRoomComponent implements OnInit, OnDestroy {

  public exclude: string[] = [];
  public colorSuccess = ElementColors.success;
  public isOffline = false;
  public collabCompleted = false;

  constructor(
    public collabService: CollabService,
    private readonly router: Router
  ) { }

  public ngOnInit(): void {
    //! FFR !//
    this.collabService.collabOffline = this.router.url.endsWith('/offline-session');
    this.collabService.getSessionData(this.router.url).subscribe(async () => {
      await this.collabService.getCollabFacilitator();
      this.collabService.startCollab(this.router.url, this.collabService.facilitatorId, true).then(() => {
        this.nextStep(this.collabService.collabState.cases[this.collabService.currentCase!.id.toString()]?.activeStep);
      }).catch((err: unknown) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
    });
    if (this.collabService.collabOffline) this.collabService.setupOfflineCollab();
    else {
      if (!this.collabService.isHost) {
        this.collabService.clicked$.pipe(
          filter(data => ['switchCase', 'getIdentificationCases', 'getPotentialCases', 'removeIdentification', 'removePotential', 'clickPanel', 'kick'].includes(data.clicked))
        ).subscribe(data => {
          switch (data.clicked) {
            case 'switchCase': this.onSwitchCase(data.data); break;
            // case 'getIdentificationCases': this.collabService.getIdentificationCases(); break;
            // case 'getPotentialCases': this.collabService.getPotentialCases(); break;
            // case 'removePotential': this.removePotential(data.data); break;
            case 'clickPanel': this.onClickPanel(data.data); break;
            case 'kick': this.collabService.stopCollab(); break;
            default: break;
          }
        });
        // this.collabService.getCollabFacilitatorAndStartCollab(this.route.snapshot.params.room);
      } 
      // else if (!this.collabService.inSession) {
      //   // void this.router.navigate(['/collaboration']);
      // } 
      // else if (this.collabService.isHost && this.collabService.inSession && !this.collabService.collabOpened) {
        
      // }
  
      // this.nextStep(this.collabService.collabState.cases[this.collabService.currentCaseId!]?.activeStep);
    }
  }

  public ngOnDestroy() {
    this.collabService.setCollabState();
  }

  public nextStep(stepIndex: any) {
    if (
      stepIndex === 0 &&
      !this.collabService.collabIdentificationCases.find(a => a.id === this.collabService.currentCase?.id.toString())?.completed &&
      !this.collabService.collabPotentialCases.find(a => a.id === this.collabService.currentCase?.id.toString())?.completed &&
      !(this.exclude.includes('Causes') && this.exclude.includes('Consequences'))
    ) this.exclude = ['Causes', 'Consequences'];

    // if (stepIndex === 1 && !!this.exclude.length) this.exclude = [];
  }

  public onSwitchCase(collabCase: CollabCase) {
    this.exclude = collabCase.completed ? [] : ['Causes', 'Consequences'];
    this.collabService.currentCaseId = collabCase.id.toString();
    this.collabService.currentCaseRecordId = collabCase.recordId.toString();
    this.collabService.currentCase = undefined;
    setTimeout(() => {
      this.collabService.currentCase = collabCase;
      if (this.collabService.isHost && !!collabCase.links) this.collabService.setCurrentCase(collabCase.links.self.href);
    });
  }

  public onClickPanel(collabCase: CollabCase) {
    const collCase = typeof collabCase === 'string' ? this.collabService.collabIdentificationCases.find(c => c.id === collabCase) : collabCase;
    if (!!collCase && !collCase.completed && collCase.caseState.state !== 2) {
      collCase.intermediate = true;
      collCase.caseState.state = 1;
    }
    if (this.collabService.isHost) this.collabService.sendSocket('clickPanel', collCase?.id);
  }

  public nextCase(caseId: any) {
    let isIdentification = false;
    let collabCase = this.collabService.collabIdentificationCases.find(x => {
      if (x.id.toString() === caseId) {
        isIdentification = true;
        return true;
      } else return false;
    });
    if (!collabCase) collabCase = this.collabService.collabPotentialCases.find(x => x.id.toString() === caseId);
    if (!!collabCase) {
      if (isIdentification) {
        let found = this.collabService.collabIdentificationCases.find(x => !x.completed);
        if (!found) found = this.collabService.collabPotentialCases.find(x => !x.completed);
        this.collabService.currentCase = found;
      } else {
        let found = this.collabService.collabPotentialCases.find(x => !x.completed);
        if (!found) found = this.collabService.collabIdentificationCases.find(x => !x.completed);
        this.collabService.currentCase = found;
      }
      if (!this.collabService.currentCase) this.collabCompleted = true;
    }
  }

  public completedPotentialCases(): number {
    return this.collabService.collabPotentialCases.filter(c => c.caseState.state === 2)?.length;
  }

  public completedIdentificationCases(): number {    
    return this.collabService.collabIdentificationCases.filter(c => c.caseState.state === 2)?.length;
  }
}
