import { toConsumableArray as e } from "../_virtual/_rollupPluginBabelHelpers.js";
import { TYPE_ELEMENT as r, TYPE_TEXT as n, TYPE_EACH as i, ATTRIBUTE_CLASS as s, ATTRIBUTE_STYLE as t } from "./defs.js";
import { evaluateExpression as l } from "./expressions.js";
import { data as o } from "../core/util.js";
function u(e, r, n, i) {
  if (!i) {
    e.el.setAttribute(r, n);
  } else {
    e.el.style[r] = n;
  }
}
function f(e, r) {
  e.el.nodeValue = r;
}
function a(r, n) {
  var i = [];
  for (var s = 0; s < n.children.length; s++) {
    i.push.apply(i, e(p(r, n.children[s])));
  }
  return i;
}
function p(e, s) {
  var t = a(e, s);
  if (s.type === r) {
    e.removeElement(s.el);
    t.unshift(s.el);
  } else if (s.type === n) {
    e.removeTextNode(s.el);
    t.unshift(s.el);
  } else if (s.type === i) {
    s.entryMap = {};
  }
  return t;
}
function c(e, i, a) {
  for (var p in e.bindings) {
    if (p !== s) {
      var c = e.bindings[p];
      var m = c.binding;
      var v = c.originalValue,
        d = false;
      for (var g = 0; g < m.expressions.length; g++) {
        var y = l(m.expressions[g].expression, function (e) {
          return o(i, e);
        }, function (e) {
          return a(e);
        });
        v = v.replace(m.expressions[g].source, y);
        if (y !== c.results[g].result) {
          d = true;
          c.results[g].result = y;
        }
      }
      if (d) {
        if (e.type === r) {
          if (p !== t) {
            u(e, p, v, false);
          } else {
            v.split(";").forEach(function (r) {
              var n = r.indexOf(":"),
                i = r.substring(0, n);
              u(e, i, r.substring(n + 1), true);
            });
          }
        } else if (e.type === n) {
          f(e, v);
        }
      }
    }
  }
}
function m() {
  return {
    added: [],
    removed: [],
    elements: []
  };
}
export { p as clearExecution, m as emptyUpdateResult, u as setElementBindingResult, f as setTextNodeBindingResult, c as updateBindings };