import { classCallCheck as t, defineProperty as r } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { transformAnchorPlacement as e, _createConnectorBase as o } from "./abstract-connector.js";
import { _addConnectorSegment as s, Connectors as n, defaultImportGeometry as a, defaultExportGeometry as u } from "./connectors.js";
import { SEGMENT_TYPE_STRAIGHT as c } from "./straight-segment.js";
var i = "Straight";
function m(t, r, e) {
  s(t, c, {
    x1: r.sx,
    y1: r.sy,
    x2: r.startStubX,
    y2: r.startStubY
  });
  s(t, c, {
    x1: r.startStubX,
    y1: r.startStubY,
    x2: r.endStubX,
    y2: r.endStubY
  });
  s(t, c, {
    x1: r.endStubX,
    y1: r.endStubY,
    x2: r.tx,
    y2: r.ty
  });
  t.geometry = {
    source: e.sourcePos,
    target: e.targetPos
  };
}
function y(t, r, o) {
  return {
    source: e(t.source, r, o),
    target: e(t.target, r, o)
  };
}
n.register(i, {
  compute: m,
  create: g,
  setAnchorOrientation: function t(r, e, o) {},
  geometryImporter: a,
  geometryExporter: u,
  transformGeometry: y
});
function g(t, r) {
  var e = o(i, t, r, [0, 0]);
  return e;
}
var p = function r() {
  t(this, r);
};
r(p, "type", i);
export { i as CONNECTOR_TYPE_STRAIGHT, p as StraightConnector, g as _createStraightConnector, m as computeStraightConnector, y as transformStraightGeometry };