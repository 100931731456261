import { createClass as t, defineProperty as i, classCallCheck as n } from "../_virtual/_rollupPluginBabelHelpers.js";
import { AvailableBackgrounds as r } from "./available-backgrounds.js";
import { uuid as e, extend as a } from "../ui-core/util/util.js";
import { svg as o } from "../ui-core/browser-ui-renderer/svg-util.js";
import { ABSOLUTE as s, NONE as c, BLOCK as d } from "../ui-core/core/constants.js";
import { EVENT_NODE_MOVE_END as h, EVENT_NODE_MOVE as u, EVENT_NODE_ADDED as m, EVENT_NODE_REMOVED as l } from "../core/constants.js";
import { EVENT_RENDER_START as g, EVENT_RENDER_END as f } from "../browser-ui/constants.js";
import { EVENT_GROUP_ADDED as k, EVENT_GROUP_REMOVED as v } from "../ui-core/core/event-constants.js";
var p;
(function (t) {
  t["dotted"] = "dotted";
  t["lines"] = "lines";
})(p || (p = {}));
var _ = {
  w: 50,
  h: 50
};
var x = 2;
var b = "#ddd";
var y = "#ddd";
var w = 2;
var C = 1;
var M = "jtk-background";
var j = "jtk-background-border";
var G = "jtk-background-grid";
var R = "jtk-background-grid-minor";
var B = "jtk-background-grid-major";
var $ = "jtk-background-grid-dotted-major";
var I = "jtk-background-grid-dotted-minor";
var H = function () {
  function r(t) {
    var a = this;
    n(this, r);
    i(this, "_suspended", false);
    i(this, "type", r.type);
    i(this, "grid", void 0);
    i(this, "surface", void 0);
    i(this, "showBorder", true);
    i(this, "minWidth", void 0);
    i(this, "minHeight", void 0);
    i(this, "maxWidth", void 0);
    i(this, "maxHeight", void 0);
    i(this, "showTickMarks", void 0);
    i(this, "tickMarksPerCell", void 0);
    i(this, "autoShrink", true);
    i(this, "dotRadius", void 0);
    i(this, "tickDotRadius", void 0);
    i(this, "gridType", void 0);
    i(this, "currentBounds", void 0);
    i(this, "_maximumExtentsReached", {
      minX: Infinity,
      minY: Infinity,
      maxX: -Infinity,
      maxY: -Infinity
    });
    i(this, "backgroundContainer", void 0);
    i(this, "_$_majorGridId", void 0);
    i(this, "_$_minorGridId", void 0);
    this.surface = t.surface;
    this.showBorder = t.options.showBorder === true;
    this._$_majorGridId = "p".concat(e().substring(0, 8));
    this._$_minorGridId = "p".concat(e().substring(0, 8));
    this.gridType = t.options.gridType || p.lines;
    this.backgroundContainer = o.node("svg");
    this.backgroundContainer.style.position = s;
    this._setVisible(t.visible);
    t.canvas.appendChild(this.backgroundContainer);
    this.surface.bind(h, function (t) {
      a._recompute();
    });
    this.surface.bind(u, function (t) {
      a._recompute();
    });
    this.surface.bind(g, function () {
      a._suspended = true;
    });
    this.surface.bind(f, function () {
      a._suspended = false;
      a._recompute();
    });
    this.surface.bind(m, function (t) {
      a._recompute();
    });
    this.surface.bind(k, function (t) {
      a._recompute();
    });
    this.surface.bind(l, function (t) {
      a._recompute();
    });
    this.surface.bind(v, function (t) {
      a._recompute();
    });
    this.grid = t.options.grid || this.surface.getGrid() || _;
    this.showTickMarks = t.options.showTickMarks !== false;
    this.tickMarksPerCell = t.options.tickMarksPerCell || x;
    this.dotRadius = t.options.dotRadius || w;
    this.tickDotRadius = t.options.tickDotRadius || C;
    this.minWidth = t.options.minWidth;
    this.minHeight = t.options.minHeight;
    this.maxWidth = t.options.maxWidth;
    this.maxHeight = t.options.maxHeight;
    this.autoShrink = t.options.autoShrink != false;
    this._recompute();
  }
  t(r, [{
    key: "owns",
    value: function t(i) {
      return i === this.backgroundContainer || i.parentNode === this.backgroundContainer;
    }
  }, {
    key: "getWidth",
    value: function t() {
      return this.currentBounds != null ? this.currentBounds.w + (this.currentBounds.x < 0 ? this.currentBounds.x : 0) : 0;
    }
  }, {
    key: "getHeight",
    value: function t() {
      return this.currentBounds != null ? this.currentBounds.h + (this.currentBounds.y < 0 ? this.currentBounds.y : 0) : 0;
    }
  }, {
    key: "setZoom",
    value: function t(i, n) {}
  }, {
    key: "pan",
    value: function t() {}
  }, {
    key: "destroy",
    value: function t() {
      this.backgroundContainer.parentNode && this.backgroundContainer.parentNode.removeChild(this.backgroundContainer);
      this.backgroundContainer = null;
    }
  }, {
    key: "_recompute",
    value: function t() {
      var i = this;
      if (!this._suspended) {
        requestAnimationFrame(function () {
          i.currentBounds = a({}, i.surface.getBoundsInfo());
          var t = i.grid.w * (Math.floor(i.currentBounds.x / i.grid.w) - 1);
          var n = i.grid.h * (Math.floor(i.currentBounds.y / i.grid.h) - 1);
          var r = i.grid.w * (Math.floor(i.currentBounds.w / i.grid.w) + 3);
          var e = i.grid.h * (Math.floor(i.currentBounds.h / i.grid.h) + 3);
          if (i.minWidth != null) {
            var o = -i.minWidth / 2;
            if (o < t) {
              r += t - o;
              t = o;
            }
            var s = i.minWidth / 2;
            if (s > t + r) {
              r += s - (t + r);
            }
          }
          if (i.minHeight != null) {
            var c = -i.minHeight / 2;
            if (c < n) {
              e += n - c;
              n = c;
            }
            var d = i.minHeight / 2;
            if (d > n + e) {
              e += d - (n + e);
            }
          }
          if (i.maxWidth != null) {
            var h = -i.maxWidth / 2;
            if (h > t) {
              r -= h - t;
              t = h;
            }
            var u = i.maxWidth / 2;
            if (u < t + r) {
              r -= t + r - u;
            }
          }
          if (i.maxHeight != null) {
            var m = -i.maxHeight / 2;
            if (m > n) {
              e -= m - n;
              n = m;
            }
            var l = i.maxHeight / 2;
            if (l < n + e) {
              e -= n + e - l;
            }
          }
          if (i.autoShrink !== true) {
            t = Math.min(t, i._maximumExtentsReached.minX);
            n = Math.min(n, i._maximumExtentsReached.minY);
            r = Math.max(t + r, i._maximumExtentsReached.maxX) - t;
            e = Math.max(n + e, i._maximumExtentsReached.maxY) - n;
          }
          i.backgroundContainer.style.left = "".concat(t, "px");
          i.backgroundContainer.style.top = "".concat(n, "px");
          i.backgroundContainer.style.width = "".concat(r, "px");
          i.backgroundContainer.style.height = "".concat(e, "px");
          i.backgroundContainer.setAttribute("width", "".concat(r));
          i.backgroundContainer.setAttribute("height", "".concat(e));
          i.backgroundContainer.setAttribute("viewBox", "0 0 ".concat(r, " ").concat(e));
          i.backgroundContainer.innerHTML = i.generateGrid();
          i._maximumExtentsReached.minX = Math.min(i._maximumExtentsReached.minX, t);
          i._maximumExtentsReached.minY = Math.min(i._maximumExtentsReached.minY, n);
          i._maximumExtentsReached.maxX = Math.max(i._maximumExtentsReached.maxX, t + r);
          i._maximumExtentsReached.maxY = Math.max(i._maximumExtentsReached.maxY, n + e);
        });
      }
    }
  }, {
    key: "_$_minorLinesGrid",
    value: function t(i, n) {
      return '<pattern id="'.concat(this._$_minorGridId, '" width="').concat(i, '" height="').concat(n, '" patternUnits="userSpaceOnUse">\n              <path d="M ').concat(i, " 0 L 0 0 0 ").concat(n, '" class="').concat(G, " ").concat(R, '" fill="none" stroke="').concat(b, '" stroke-width="1"/>\n            </pattern>');
    }
  }, {
    key: "_$_majorLinesGrid",
    value: function t() {
      return '<pattern id="'.concat(this._$_majorGridId, '" width="').concat(this.grid.w, '" height="').concat(this.grid.h, '" patternUnits="userSpaceOnUse">') + (this.showTickMarks ? '<rect width="'.concat(this.grid.w, '" height="').concat(this.grid.h, '" fill="url(#').concat(this._$_minorGridId, ')"/>') : "") + '<path d="M '.concat(this.grid.w, " 0 L 0 0 0 ").concat(this.grid.h, '" class="').concat(G, " ").concat(B, '" fill="none" stroke="').concat(y, '" stroke-width="1.5"/></pattern>');
    }
  }, {
    key: "_$_minorDotsGrid",
    value: function t(i, n) {
      var r = '<pattern id="'.concat(this._$_minorGridId, '" width="').concat(this.grid.w, '" height="').concat(this.grid.h, '" patternUnits="userSpaceOnUse">');
      for (var e = 0; e < this.tickMarksPerCell + 2; e++) {
        var a = e * i;
        for (var o = 0; o < this.tickMarksPerCell + 2; o++) {
          var s = o * n;
          r += '<circle cx="'.concat(a, '" cy="').concat(s, '" r="').concat(this.tickDotRadius, '" class="').concat(G, " ").concat(I, '" fill="').concat(b, '" stroke="none"/>');
        }
      }
      return r + "</pattern>`";
    }
  }, {
    key: "_$_majorDotsGrid",
    value: function t() {
      return '<pattern id="'.concat(this._$_majorGridId, '" width="').concat(this.grid.w, '" height="').concat(this.grid.h, '" patternUnits="userSpaceOnUse">') + (this.showTickMarks ? '<rect width="'.concat(this.grid.w, '" height="').concat(this.grid.h, '" fill="url(#').concat(this._$_minorGridId, ')"/>') : "") + '<circle cx="0" cy="0" r="'.concat(this.dotRadius, '" class="').concat(G, " ").concat($, '" fill="').concat(y, '" stroke="none"/>\n            <circle cx="').concat(this.grid.w, '" cy="0" r="').concat(this.dotRadius, '" class="').concat(G, " ").concat($, '" fill="').concat(y, '" stroke="none"/>\n            <circle cx="').concat(this.grid.w, '" cy="').concat(this.grid.h, '" r="').concat(this.dotRadius, '" class="').concat(G, " ").concat($, '" fill="').concat(y, '" stroke="none"/>\n            <circle cx="0" cy="').concat(this.grid.h, '" r="').concat(this.dotRadius, '" class="').concat(G, " ").concat($, '" fill="').concat(y, '" stroke="none"/></pattern>');
    }
  }, {
    key: "_$_generateGridPattern",
    value: function t() {
      var i = this;
      var n = this.grid.w / (this.tickMarksPerCell + 1);
      var r = this.grid.h / (this.tickMarksPerCell + 1);
      var e = function t() {
        return i.gridType === p.lines ? i._$_minorLinesGrid(n, r) : i._$_minorDotsGrid(n, r);
      };
      var a = function t() {
        return i.gridType === p.lines ? i._$_majorLinesGrid() : i._$_majorDotsGrid();
      };
      return "<defs>" + (this.showTickMarks ? e() : "") + a() + "</defs>";
    }
  }, {
    key: "generateGrid",
    value: function t() {
      var i = this._$_generateGridPattern();
      var n = this.showBorder ? '<rect class="'.concat(j, '" x="0" y="0" width="100%" height="100%" fill="').concat(c, '"/>') : "";
      var r = '<rect x="0" y="0" width="100%" height="100%" fill="url(#'.concat(this._$_majorGridId, ')" stroke="').concat(c, '"/>');
      return i + n + r;
    }
  }, {
    key: "_setVisible",
    value: function t(i) {
      this.backgroundContainer.style.display = i ? d : c;
    }
  }]);
  return r;
}();
i(H, "type", "grid");
r.register(H.type, H);
export { M as CLASS_BACKGROUND, j as CLASS_BACKGROUND_BORDER, G as CLASS_BACKGROUND_GRID, $ as CLASS_BACKGROUND_GRID_DOT_MAJOR, I as CLASS_BACKGROUND_GRID_DOT_MINOR, B as CLASS_BACKGROUND_GRID_MAJOR, R as CLASS_BACKGROUND_GRID_MINOR, _ as DEFAULT_GRID, y as DEFAULT_LINE_STROKE, b as DEFAULT_TICK_LINE_STROKE, x as DEFAULT_TICK_MARKS_PER_CELL, H as GeneratedGridBackground, p as GridTypes };