@for (card of _cards; let i = $index; track i) {
  <naris-card class="data">
    <div class="header">
      <div class="header-type">
        <naris-select class="select" [options]="dataTypes" [control]="card.type" [multiple]="false" [mandatory]="true" [id]="'TestCardType'"/>
        @if (card.type.value === 'response') {
          <mat-button-toggle-group [formControl]="card.requestType" class="toggle" appearance="legacy">
            <mat-button-toggle value="get">GET</mat-button-toggle>
            <mat-button-toggle value="post">POST</mat-button-toggle>
          </mat-button-toggle-group>
        }
      </div>
      @if (editNames.includes(card)) {
        <naris-input class="name-field" [control]="card.name" (blurred)="editName(card)" [autofocus]="true" />
      } @else {
        <h3 
          tabindex="0"
          class="card-name" 
          (click)="editName(card)"
        >{{card.name.value}}</h3>
      }
      <button mat-button type="button" color="warn" (click)="$event.stopPropagation(); reset(card)">reset</button>
    </div>
    <div class="form-field">
      <naris-autocomplete-single 
        [control]="card.control"
        class="data-input"
        placeholder="/endpoint"
      />
      <naris-button color="primary" (click)="sendRequest(card)" [disabled]="!card.control.value" id="TestSendRequest">Send request</naris-button>
      <button mat-icon-button class="close" type="button" (click)="removeCard(card, i)">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    @if (!!card.dynamics.length) {
      <div class="dynamics">
        @for (dynamic of card.dynamics; track $index) {
          <naris-input [control]="dynamic.control" [prepend]="dynamic.label" class="dynamic-field" />
        }
      </div>
    }
    <div class="content" [@rowExpand]="divide(card) ? 'expanded' : 'collapsed'">
      @if (divide(card)) {
        <naris-divider class="divider" />
      }
      @switch (card.type.value) {
        @case ('list') {
          @if (!!card.dataUrl) {
            <naris-list [dataUrl]="card.dataUrl" />
          }
        }
        @case ('table') {
          @if (!!card.dataUrl) {
            <naris-table [dataUrl]="card.dataUrl" />
          }
        }
        @case ('response') {
          @if (!!card.response) {
            <ngx-json-viewer [json]="card.response" [expanded]="false" />
          }
        }
      }
    </div>
  </naris-card>
}


<div class="buttons">
  <naris-button color="primary" (click)="addCard()" class="action" icon="add" id="TestAddCard">
    <span>Add card</span>
  </naris-button>
  @if (_cards.length > 1) {
    <naris-button color="danger" (click)="resetCards()" class="action" icon="close" id="TestReset">
      <span>Reset</span>
    </naris-button>
  }

  <naris-button color="success" (click)="snackbarSuccess()" class="action" icon="close" id="TestSSnackbarSuccess">
    <span>Snackbar Success</span>
  </naris-button>
  <naris-button color="danger" (click)="snackbarError()" class="action" icon="close" id="TestSnackbarError">
    <span>Snackbar Error</span>
  </naris-button>
</div>


{{userData._id | json}}
