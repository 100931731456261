<div class="wrapper" [attr.data-test-id]="'score__' + id">
  <div [style.width.px]="width">
    <div class="slider" #slider (mousedown)="setMouseDown()" (mousemove)="updateSlider($event)" (mouseup)="setMouseUp()" (mouseleave)="setMouseUp()">
      <div class="min">{{valueItems ? valueItems[min] : min}}</div>
      @for (stepItem of steps; track stepItem) {
        <div class="step" [ngStyle]="{'width.%':stepWidth}" [class.start]="stepItem === 1*step" [class.end]="stepItem === max">
          <div class="value">{{valueItems ? valueItems[stepItem] : stepItem}}</div>
        </div>
      }
      <div class="diff-indicator" [style.left.px]="indicatorPosition" [style.width.px]="indicatorWidth" [class.negative]="isNegative()" [class.positive]="isPositive()"></div>
      <div class="norm" [style.left.px]="normLeft"></div>
      <div class="drag-item" #dragitem [style.left.px]="dragPosition" [class.negative]="isNegative(3)" [class.positive]="isPositive(3)"></div>
      <div class="init-item" [style.left.px]="initLeft" (dblclick)="reset()">
        @if (dragPosition > initLeft - 3 && showDirection) {
          <div class="icon arrow-icon">
            <svg width="8" height="10" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 11.5L6.5 6.5L1.5 1.5" stroke="#fff" stroke-width="2"/>
            </svg>
          </div>
        }
        @if (dragPosition < initLeft - 3 && showDirection) {
          <div class="icon">
            <svg width="8" height="10" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 1.5L1.5 6.5L6.5 11.5" stroke="#fff" stroke-width="2"/>
            </svg>
          </div>
        }
        @if (dragPosition >= initLeft - 3 && dragPosition <= initLeft - 3 && showDirection) {
          <div class="icon dash">
            <svg width="6" height="2" viewBox="0 0 6 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="0.375" width="5" height="1.25" fill="white"/>
            </svg>
          </div>
        }
      </div>
    </div>
  </div>
</div>
