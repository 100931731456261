<naris-card class="card" [ngClass]="{loading: loading}">
  @if (!loading && invalidToken === false) {
    <h2 class="mb-8">{{'signup-info.additional_info' | translate}}</h2>
    <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
      <naris-form-field [label]="'email_address' | translate" [fixStyling]="true">
        <naris-input
          [placeholder]="'email_address' | translate"
          [control]="getFormControl('email')"
          [autocomplete]="'new-email'"
          class="email-field"
        />
      </naris-form-field>
      <naris-form-field [label]="'name' | translate" [fixStyling]="true">
        <div class="name-field">
          <naris-input
            [placeholder]="'signup-info.given_name' | translate"
            class="mr-2"
            [control]="getFormControl('firstName')"
            [autocomplete]="'new-firstname'"
          />
          <naris-input
            class="mr-0"
            [placeholder]="'surname' | translate"
            [control]="getFormControl('lastName')"
            [autocomplete]="'new-lastname'"
          />
        </div>
      </naris-form-field>
      <naris-form-field [label]="'password' | translate" [fixStyling]="true">
        <naris-input
          type="password"
          [placeholder]="'password' | translate"
          class="mb-4"
          [control]="getFormControl('password')"
          [autocomplete]="'new-password'"
        />
        <naris-input-condition [valid]="lengthOK">
          {{'password.eight_chars' | translate}}
        </naris-input-condition>
        <naris-input-condition [valid]="numOK">
          {{'password.one_number' | translate}}
        </naris-input-condition>
        <naris-input-condition [valid]="capsOK">
          {{'password.one_capital' | translate}}
        </naris-input-condition>
        <naris-input-condition [valid]="specialOK">
          {{'password.one_special' | translate}}
        </naris-input-condition>
        <naris-input-condition [valid]="originalOK">
          {{'password.original' | translate}}
        </naris-input-condition>
      </naris-form-field>
      <naris-form-field [label]="'repeat_password_label' | translate" [fixStyling]="true">
        <naris-input
          type="password"
          [placeholder]="'repeat_password_label' | translate"
          class="mb-4"
          [control]="getFormControl('passwordRepeat')"
          [autocomplete]="'new-password-repeat'"
        />
      </naris-form-field>
      <naris-form-actions justify="right">
        <!-- <a [routerLink]="['/signup']" class="naris-link">{{'go_back' | translate}}</a> -->
        <a [routerLink]="['/login']" class="naris-link" (click)="resetInvited()">{{'to_login' | translate}}</a>
        <div class="mr-4"></div>
        <naris-button [disabled]="signupForm.invalid" color="primary" id="SignupInfoCreateAccount">{{'signup-info.create_account' | translate}}</naris-button>
      </naris-form-actions>
    </form>
  }
  @if (!loading && (!!invalidToken || invalidToken === undefined)) {
    <h2 class="mb-8">{{'invitation_expired' | translate}}</h2>
    <p>{{'invitation_expired.info' | translate}}</p>
  }
  @if (loading) {
    <div class="loader">
      <naris-progress-spinner [strokeWidth]="2" [diameter]="24" />
    </div>
  }
</naris-card>
