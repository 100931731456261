import { Pipe, PipeTransform } from '@angular/core';
import { decodeRich } from '@core/helpers';

@Pipe({
  name: 'richText',
  standalone: true
})
export class RichTextPipe implements PipeTransform {

  public transform(value: any): string {
    // Decode the received value which returns a HTML string
    const decoded = decodeRich(value) || '<p>No text</p>';

    // Replace the HTML with '-' and  then split it by the same value
    const nonHtmlString = decoded.replace(/<[^>]+>/g, '-').split('-');

    // Perform a lookup for the first item which is not an empty string,
    // this lookup can safely be asserted as defined because the decoded variable always has a value
    const firstLine = nonHtmlString.find(string => string !== '')!;

    //Return the resulting value
    return firstLine;
  }

}
