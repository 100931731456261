import { createClass as t, classCallCheck as i, defineProperty as e } from "../_virtual/_rollupPluginBabelHelpers.js";
import { EventManager as s, pageLocation as n } from "../ui-core/browser-ui-renderer/event-manager.js";
import { matchesSelector as o, consume as r, addClass as h } from "../ui-core/browser-ui-renderer/browser-util.js";
import { CLASS_LASSO as a, Constants as l, CLASS_SELECT_DEFEAT as u, CLASS_LASSO_MASK as d, CLASS_LASSO_MASK_TOP as v, CLASS_LASSO_MASK_BOTTOM as m, CLASS_LASSO_MASK_LEFT as f, CLASS_LASSO_MASK_RIGHT as p } from "../browser-ui/constants.js";
import { isString as c } from "../ui-core/util/util.js";
import { EVENT_MOUSEDOWN as g, EVENT_MOUSEUP as y, EVENT_MOUSEMOVE as k } from "../ui-core/browser-ui-renderer/event-constants.js";
import { setBoundingRect as b } from "../ui-core/browser-ui-renderer/positioning-and-sizing-utils.js";
function _() {}
function w(t) {
  var i = document.createElement("div");
  h(i, t.join(" "));
  document.body.appendChild(i);
  return i;
}
var L = function () {
  function h(t) {
    i(this, h);
    e(this, "visibleOrigin", {
      x: 0,
      y: 0
    });
    e(this, "size", {
      w: 0,
      h: 0
    });
    e(this, "_generateLassoContent", void 0);
    e(this, "el", void 0);
    e(this, "masks", {});
    e(this, "origin", {
      x: 0,
      y: 0
    });
    e(this, "onStart", void 0);
    e(this, "onEnd", void 0);
    e(this, "onSelect", void 0);
    e(this, "onClick", void 0);
    e(this, "down", false);
    e(this, "moving", false);
    e(this, "invert", void 0);
    e(this, "surface", void 0);
    e(this, "_filter", void 0);
    e(this, "eventManager", void 0);
    e(this, "mapToGrid", void 0);
    e(this, "downListener", void 0);
    e(this, "upListener", void 0);
    e(this, "moveListener", void 0);
    e(this, "downEvent", g);
    e(this, "upEvent", y);
    e(this, "moveEvent", k);
    e(this, "enabled", false);
    e(this, "_generatedLassoContent", void 0);
    this.surface = t.surface;
    this.onStart = t.onStart || _;
    this.onEnd = t.onEnd || _;
    this.onSelect = t.onSelect || _;
    this.onClick = t.onClick || null;
    this._generateLassoContent = t.generateLassoContent;
    this.mapToGrid = t.mapToGrid;
    this.invert = t.invert === true;
    this.downListener = this._downListener.bind(this);
    this.upListener = this._upListener.bind(this);
    this.moveListener = this._moveListener.bind(this);
    this.eventManager = new s();
    this._prepareFilter(t.filter);
    if (!this.invert) {
      this.el = w([a, t.cssClass || ""]);
    } else {
      this._createMasks();
    }
    this.eventManager.on(this.surface.jsplumb.getContainer().parentNode, this.downEvent, this.downListener);
  }
  t(h, [{
    key: "_prepareFilter",
    value: function t(i) {
      if (i == null) {
        this._filter = function () {
          return false;
        };
      } else {
        if (c(i)) {
          this._filter = function (t) {
            var e = t.srcElement || t.target;
            return o(e, i);
          };
        } else {
          this._filter = i;
        }
      }
    }
  }, {
    key: "_position",
    value: function t(i, e) {
      this.visibleOrigin = i;
      this.size = e;
      if (!this.invert) {
        b(this.el, i, e);
      } else {
        var s = window.innerWidth,
          n = window.innerHeight,
          o = window.pageXOffset,
          r = window.pageYOffset,
          h = n - i.y + r,
          a = n - h + e.h,
          l = s - i.x + o,
          u = s - l + e.w;
        this.masks.top.style.bottom = h + "px";
        this.masks.bottom.style.top = a + "px";
        this.masks.left.style.right = l + "px";
        this.masks.right.style.left = u + "px";
        this.masks.top.style.left = s - l + "px";
        this.masks.top.style.right = s - u + "px";
        this.masks.bottom.style.left = s - l + "px";
        this.masks.bottom.style.right = s - u + "px";
      }
    }
  }, {
    key: "_setVisible",
    value: function t(i) {
      var e = i ? l.block : l.none;
      if (!this.invert) {
        this.el.style.display = e;
      } else {
        this.masks.top.style.display = e;
        this.masks.left.style.display = e;
        this.masks.right.style.display = e;
        this.masks.bottom.style.display = e;
      }
      if (i) {
        this.surface.addClass(document.body, u);
      } else {
        this.surface.removeClass(document.body, u);
      }
    }
  }, {
    key: "_mapPoint",
    value: function t(i) {
      return this.mapToGrid == null ? i : this.mapToGrid(i);
    }
  }, {
    key: "_downListener",
    value: function t(i) {
      if (this.enabled && !this._filter(i)) {
        r(i);
        this.down = true;
        this.eventManager.on(document, this.upEvent, this.upListener);
        this.eventManager.on(document, this.moveEvent, this.moveListener);
        this.origin = this._mapPoint(n(i));
        if (!this.invert) {
          if (this._generateLassoContent) {
            var e = this._generateLassoContent(this.origin, i);
            if (e != null) {
              this._generatedLassoContent = e;
              this.el.replaceChildren(e);
            } else {
              this.el.innerHTML = "";
            }
          }
        }
        this._position(this.origin, {
          w: 1,
          h: 1
        });
        this.onStart(this.origin, i.shiftKey, i);
      }
    }
  }, {
    key: "_moveListener",
    value: function t(i) {
      if (this.down) {
        if (!this.moving) {
          this._setVisible(true);
          this.moving = true;
        }
        r(i);
        var e = this._mapPoint(n(i)),
          s = {
            w: Math.abs(e.x - this.origin.x),
            h: Math.abs(e.y - this.origin.y)
          },
          o = {
            x: Math.min(this.origin.x, e.x),
            y: Math.min(this.origin.y, e.y)
          };
        this._position(o, s);
        this.onSelect(o, s, [this.origin.x < e.x, this.origin.y < e.y], i.shiftKey, this._generatedLassoContent);
      }
    }
  }, {
    key: "_upListener",
    value: function t(i) {
      if (this.down) {
        var e = this.moving === true;
        this.down = false;
        this.moving = false;
        r(i);
        this.eventManager.off(document, this.upEvent, this.upListener);
        this.eventManager.off(document, this.moveEvent, this.moveListener);
        this._setVisible(false);
        if (e) {
          this.onEnd(this.visibleOrigin, this.size);
        } else {
          this.onClick && this.onClick(this.visibleOrigin, i);
        }
      }
    }
  }, {
    key: "_createMasks",
    value: function t() {
      this.masks.top = w([d, v]);
      this.masks.bottom = w([d, m]);
      this.masks.left = w([d, f]);
      this.masks.right = w([d, p]);
    }
  }, {
    key: "isActive",
    value: function t() {
      return this.down;
    }
  }, {
    key: "setEnabled",
    value: function t(i) {
      this.enabled = i;
    }
  }]);
  return h;
}();
export { L as Lasso };