<div [attr.data-test-id]="'double-datepicker__' + id">
@for (index of [0, 1]; track $index) {
  <div [attr.data-test-id]="'double-datepicker__Wrapper' + index">
    <div class="label">
      <div class="pt-4 pb-4">{{getLabel(index) | translate}}</div>
      @if (getTooltip(index)) {
          <naris-icon
          [matTooltip]="getTooltip(index)"
          matTooltipPosition="right"
          tabindex="0"
          icon="help"
          class="icon"
        />
      }
    </div>
    <naris-datepicker
      [control]="getControl(index)!"
      [requiredIfVisible]="true"
      [input]="input"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [attr.data-test-id]="'double-datepicker__DatePicker' + index"
    />
    @if (!getControl(index)?.valid) {
      <div class="error-message">
        {{getControl(index)?.errors?.error}}
      </div>
    }
  </div>
}

@if (!!generalError) {
  <div class="error-message">
    {{generalError}}
  </div>
}

</div>