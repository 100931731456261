import { createClass as e, classCallCheck as t } from "../_virtual/_rollupPluginBabelHelpers.js";
import { Connections as s } from "../ui-core/core/connector/connections.js";
var a = function () {
  function a(e, s) {
    t(this, a);
    this.path = e;
    this.surface = s;
  }
  e(a, [{
    key: "getVertexCount",
    value: function e() {
      return this.path.getVertexCount();
    }
  }, {
    key: "getEdgeCount",
    value: function e() {
      return this.path.getEdgeCount();
    }
  }, {
    key: "setVisible",
    value: function e(t) {
      this.surface.setVisible(this.path, t);
    }
  }, {
    key: "addVertexClass",
    value: function e(t) {
      var s = this;
      this.path.eachVertex(function (e, a) {
        s.surface.addClass(a, t);
      });
    }
  }, {
    key: "removeVertexClass",
    value: function e(t) {
      var s = this;
      this.path.eachVertex(function (e, a) {
        s.surface.removeClass(a, t);
      });
    }
  }, {
    key: "addEdgeClass",
    value: function e(t) {
      var a = this;
      this.path.eachEdge(function (e, n) {
        s.addClass(a.surface.connMap[n.getId()], t);
      });
    }
  }, {
    key: "removeEdgeClass",
    value: function e(t) {
      var a = this;
      this.path.eachEdge(function (e, n) {
        s.removeClass(a.surface.connMap[n.getId()], t);
      });
    }
  }, {
    key: "addClass",
    value: function e(t) {
      this.addVertexClass(t);
      this.addEdgeClass(t);
    }
  }, {
    key: "removeClass",
    value: function e(t) {
      this.removeVertexClass(t);
      this.removeEdgeClass(t);
    }
  }]);
  return a;
}();
export { a as UIPath };