import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Info, Settings } from 'luxon';
import { DE, EN, FREQUENCY_CONFIG, LANGUAGE_KEYS, NARIS_SITE, NL } from '@core/constants';
import { LocalStorage } from '@core/decorators/storage.decorators';
import { HttpService } from '@core/services/http.service';
import type { ILanguage } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  @LocalStorage()
  private _appLanguage: string;
  public dictionary: Record<string, string> = {};
  public availableLanguages: ILanguage[] = [
    {code: EN, image: 'https://cdn.countryflags.com/thumbs/united-kingdom/flag-800.png', label: 'English'},
    {code: DE, image: 'https://cdn.countryflags.com/thumbs/germany/flag-800.png', label: 'Deutsch'},
    // TODO: {code: FR, image: 'https://cdn.countryflags.com/thumbs/france/flag-800.png', label: 'Français'},
    {code: NL, image: 'https://cdn.countryflags.com/thumbs/netherlands/flag-800.png', label: 'Nederlands'}
    // TODO: {code: 'es', image: 'https://cdn.countryflags.com/thumbs/spain/flag-800.png', label: 'España'},
  ];

  private _emitUpdate = false;

  public languageUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  public getAppLang = () => this._appLanguage;

  public setAppLang = (langCode: string) => this._appLanguage = langCode;

  constructor(private readonly translate: TranslateService, private readonly httpService: HttpService) {
    this._appLanguage = this._appLanguage || this.getUsersLanguage(EN);
    setTimeout(() => this.setLanguage(this._appLanguage), 500);
  }

  public getHref = (subject: string) => {
    const lang = this._appLanguage;
    switch (subject) {
      case 'contact':
        const contact = lang === DE ? 'kontakt' : 'contact';
        return `${NARIS_SITE}/${lang}/${contact}`;
      case 'help':
        const help = lang === DE ? 'hilfe' : lang === NL ? 'hulp' : 'help';
        return `${NARIS_SITE}/${lang}/${help}`;
      case 'demo':
        const demo = lang === NL ? 'demo-grc-software' : 'demo';
        return `${NARIS_SITE}/${lang}/${demo}`;
      default: return '#';
    }
  };

  public getTranslation(key: string) {
    if (Object.keys(this.dictionary).length === 0) this.initDictionary();
    return this.dictionary[key];
  }

  private initDictionary() {
    let index = 0;
    Object.keys(LANGUAGE_KEYS).forEach(key => {
      this.translate.get(LANGUAGE_KEYS[key]).subscribe(x => {
        this.dictionary[LANGUAGE_KEYS[key]] = x;
        if (index === Object.keys(LANGUAGE_KEYS).length - 1 && this._emitUpdate) {
          this.languageUpdated.emit(true);
          this._emitUpdate = false;
        } else index++;
      });
    });
  }

  public setLanguage(language: string, shouldCommit =  false) {
    this._appLanguage = this.isLanguageAvailable(language) ? language : EN;
    this.translate.use(this._appLanguage);
    Settings.defaultLocale = this._appLanguage;
    this.setFrequencyConstantDays();
    this.setFrequencyConstantMonths();
    this._emitUpdate = true;
    this.initDictionary();
    const url = '/profile-tab/person-choice/choose-working-language';
    const body = {
      objects: {
        WorkingLanguage: {
          Language: this._appLanguage
        }
      }
    };
    if (shouldCommit) return this.httpService.post(url, body);
  }

  private setFrequencyConstantMonths() {
    const monthKeys = Info.months('long', {locale: EN});
    const months = Info.months('long', {locale: this._appLanguage}).map((m, i) => ({key: monthKeys[i], label: m.toLowerCase()}));
    FREQUENCY_CONFIG.periods.Months.options = months;
  }

  private setFrequencyConstantDays() {
    const dayKeys = Info.weekdays('long', {locale: EN});
    const days = Info.weekdays('long', {locale: this._appLanguage}).map((m, i) => ({key: dayKeys[i], label: m.toLowerCase()}));
    FREQUENCY_CONFIG.periods.Days.options = days;
  }

  private getUsersLanguage(defaultValue: string): string {
    if (typeof window?.navigator === 'undefined') {
      return defaultValue;
    }
    const wn = window.navigator as any;
    const lang = (wn.languages ? wn.languages[0] : defaultValue) || wn.language || wn.browserLanguage || wn.userLanguage;
    return lang.substring(0, 2); // only return first two characters. result can either be en or en-US for example
  }

  public isLanguageAvailable(language: string) {
    return !!this.availableLanguages.find(item => item.code === language);
  }
}
