import { inherits as e, createSuper as n, classCallCheck as o, createClass as r } from "../_virtual/_rollupPluginBabelHelpers.js";
import { ajax as t } from "./browser-util.js";
import { Surface as i } from "./surface.js";
import { JsPlumbToolkit as a } from "../core/toolkit.js";
import { Selection as c } from "../core/selection.js";
import { extend as u, uuid as s } from "../ui-core/util/util.js";
import { EVENT_NODE_ADDED as l, EVENT_NODE_REMOVED as d, EVENT_EDGE_ADDED as f, EVENT_GRAPH_CLEAR_START as p, EVENT_GRAPH_CLEARED as m } from "../core/constants.js";
import { EVENT_GROUP_ADDED as v, EVENT_GROUP_REMOVED as b } from "../ui-core/core/event-constants.js";
var _ = function (i) {
  e(c, i);
  var a = n(c);
  function c() {
    o(this, c);
    return a.apply(this, arguments);
  }
  r(c, [{
    key: "loadData",
    value: function e(n) {
      t(n);
    }
  }]);
  return c;
}(a);
function j(e, n) {
  return "[" + e + "='" + n + "']";
}
function g(e, n, o, r) {
  var t;
  var a = u({
    dataSource: e,
    container: n
  }, r || {});
  if (a.selection != null) {
    if (!(typeof a.selection === "function")) {
      t = a.selection;
    } else {
      t = new c(e, {
        generator: a.selection
      });
    }
    a.dataSource = t;
  }
  a.toolkitInstance = e;
  var _ = a.id || s();
  var j = new i(a, o);
  if (t != null) {
    t.bind(l, function (e) {
      j._nodeAdded(e);
    });
    t.bind(v, function (e) {
      j._groupAdded(e);
    });
    t.bind(d, function (e) {
      j._nodeRemoved(e);
    });
    t.bind(b, function (e) {
      j._groupRemoved(e);
    });
    t.bind(f, function (e) {
      j._edgeAdded(e);
    });
    t.bind(p, function () {
      j._graphClearStart();
    });
    t.bind(m, function () {
      j._graphClearEnd();
    });
  }
  e.addRenderer(j, _);
  return j;
}
export { _ as BrowserUIBase, j as attWithValue, g as render };