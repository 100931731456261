import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TabService, UserService } from '@core/services';
import { DashboardComponent } from '../../shared/components/dashboard/dashboard.component';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
  standalone: true,
  imports: [DashboardComponent]
})
export class StartComponent implements OnInit {
  public endpoint: string;

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly tabs: TabService
  ) {}

  public ngOnInit(): void {
    if (!this.userService.userId) void this.router.navigate(['/naris-admin']);
    else this.endpoint = '/profile-tab/homepage-dashboard/homepage-dashboard';
    this.tabs.clear();
  }
}
