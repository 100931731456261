import { inherits as t, createSuper as e, classCallCheck as r, createClass as o } from "../../_virtual/_rollupPluginBabelHelpers.js";
var n = function () {
  function t(e, o, n) {
    r(this, t);
    this.node = e;
    this.group = o;
    this.toolkit = n;
  }
  o(t, [{
    key: "hasDeltas",
    value: function t() {
      return this.node != null;
    }
  }]);
  return t;
}();
var u = function (n) {
  t(i, n);
  var u = e(i);
  function i() {
    r(this, i);
    return u.apply(this, arguments);
  }
  o(i, [{
    key: "redo",
    value: function t() {
      this.toolkit.addToGroup(this.node, this.group);
    }
  }, {
    key: "undo",
    value: function t() {
      this.toolkit.removeFromGroup(this.node);
    }
  }]);
  return i;
}(n);
var i = function (n) {
  t(i, n);
  var u = e(i);
  function i() {
    r(this, i);
    return u.apply(this, arguments);
  }
  o(i, [{
    key: "redo",
    value: function t() {
      this.toolkit.removeFromGroup(this.node);
    }
  }, {
    key: "undo",
    value: function t() {
      this.toolkit.addToGroup(this.node, this.group);
    }
  }]);
  return i;
}(n);
var s = function () {
  function t(e, o) {
    r(this, t);
    this.group = e;
    this.renderer = o;
  }
  o(t, [{
    key: "redo",
    value: function t() {
      this.renderer.collapseGroup(this.group);
    }
  }, {
    key: "undo",
    value: function t() {
      this.renderer.expandGroup(this.group);
    }
  }, {
    key: "hasDeltas",
    value: function t() {
      return this.group != null;
    }
  }]);
  return t;
}();
var a = function () {
  function t(e, o) {
    r(this, t);
    this.group = e;
    this.renderer = o;
  }
  o(t, [{
    key: "redo",
    value: function t() {
      this.renderer.expandGroup(this.group);
    }
  }, {
    key: "undo",
    value: function t() {
      this.renderer.collapseGroup(this.group);
    }
  }, {
    key: "hasDeltas",
    value: function t() {
      return this.group != null;
    }
  }]);
  return t;
}();
export { u as AddGroupMemberAction, s as GroupCollapseAction, a as GroupExpandAction, i as RemoveGroupMemberAction };