import { inherits as e, createSuper as t, classCallCheck as r, defineProperty as a, assertThisInitialized as l } from "../_virtual/_rollupPluginBabelHelpers.js";
import { ElementEntry as s } from "./element-entry.js";
import { isLoopPresent as i } from "./parser.js";
import { Elements as o } from "./elements.js";
import { ATTRIBUTE_CONTEXT as p, TYPE_TMPL as n, ATTRIBUTE_LOOKUP as m, ATTRIBUTE_DEFAULT as d } from "./defs.js";
var f = function (s) {
  e(f, s);
  var o = t(f);
  function f(e, t, s, u, c) {
    var v;
    r(this, f);
    v = o.call(this, e, t, s, u, c);
    a(l(v), "templateId", void 0);
    a(l(v), "lookup", void 0);
    a(l(v), "default", void 0);
    v.context = e.atts[p];
    v.type = n;
    v.remove = true;
    if (!e.atts[m]) {
      v.templateId = e.atts["id"];
      var h = i(u);
      if (c.indexOf(v.templateId) !== -1) {
        if (!h) {
          throw new TypeError("recursive template call [" + v.templateId + "]");
        }
      } else {
        var I = s(v.templateId);
        c.push(v.templateId);
        var g = v.instance.parse(I, s, null, c);
        for (var j = 0; j < g.length; j++) {
          g[j].context = v.context;
        }
        v.children = g;
        c.pop();
      }
    } else {
      v.lookup = e.atts[m];
      v.default = e.atts[d] || "";
    }
    return v;
  }
  return f;
}(s);
a(f, "tag", "r-tmpl");
o.register(f.tag, f);
export { f as TmplEntry };