import { registerEndpointRenderer as t } from "./browser-jsplumb-instance.js";
import { _node as e, _attr as o } from "./svg-util.js";
import { RectangleEndpoint as r } from "../core/endpoint/rectangle-endpoint.js";
var i = "rect";
var n = function n() {
  t(r.type, {
    makeNode: function t(o, r) {
      return e(i, {
        width: o.w,
        height: o.h
      });
    },
    updateNode: function t(e, r) {
      o(r, {
        width: e.w,
        height: e.h
      });
    }
  });
};
export { n as register };