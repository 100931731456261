@if (!!selection.length) {
  <h3 mat-dialog-title>
    {{ 'knowledgebase.selected_items' | translate }}
    <naris-button
      mat-dialog-close
      color="transparent"
      icon="close"
      class="close"
      id="KnowledgebaseBatchClose"
    />
  </h3>
  <mat-dialog-content>
    @for (item of selection; track $index) {
      <div class="item">
        <span>
          <naris-avatar class="avatar" size="small" [label]="item.caseId?.toString()" id="KnowledgebaseBatch" />
          {{ item.name }}
        </span>
        <div class="icons">
          @for (ico of getIcons(item); track $index) {
            <naris-icon
              [icon]="ico.icon"
              [iconClass]="ico.class"
              [matBadge]="ico.badge"
            />
          }
        </div>
      </div>
      @if ($last) {
        <mat-divider />
      }
    }
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <naris-button
      color="danger"
      icon="delete"
      class="mr-2"
      [matTooltip]="('knowledgebase.dblclick' | translate) + ' ' + ('knowledgebase.clear_batch' | translate)"
      placement="left"
      (dblClicked)="dialogRef.close('clear')"
      id="KnowledgebaseBatchClear"
    />
    <naris-button
      color="success"
      icon="upload"
      class="ml-2"
      [matTooltip]="('knowledgebase.dblclick' | translate) + ' ' + ('knowledgebase.submit_batch' | translate)"
      placement="right"
      (dblClicked)="dialogRef.close('submit')"
      id="KnowledgebaseBatchSubmit"
    />
  </mat-dialog-actions>
} @else {
  <naris-empty-state [title]="'knowledgebase.no_selected' | translate" image="empty" />
}
