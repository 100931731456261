import { environment } from '@src/environments/environment';
import { SocketIoConfig } from 'ngx-socket-io';

export const CONNFIG = {iceServers: environment.iceServers, iceCandidatePoolSize: 10};
export const SOCKET_CONFIG: SocketIoConfig = {url: environment.socketUrl, options: environment.socketOptions};
export const AUDIT_SOCKET_CONFIG: SocketIoConfig = {url: environment.auditSocketUrl, options: environment.auditSocketOptions};
export const NARIS_DATE_FORMATS = {
  parse: { dateInput: 'dd-MM-yyyy HH:mm:ss.u' },
  display: {
    dateInput: 'D',
    monthYearLabel: 'LLL yyyy',
    dateA11yLabel: 'D',
    monthYearA11yLabel: 'LLL yyyy'
  }
};
