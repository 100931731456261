import { inherits as e, createSuper as t, classCallCheck as i, defineProperty as n, assertThisInitialized as s, createClass as o } from "../_virtual/_rollupPluginBabelHelpers.js";
import { Group as r } from "../core/model/graph.js";
import { SurfacePlugins as a } from "../browser-ui/surface.js";
import { EVENT_RESIZE as h, CLASS_MINIVIEW as l, CLASS_MINIVIEW_COLLAPSED as u, Constants as d, CLASS_MINIVIEW_PANNER as p, CLASS_MINIVIEW_CANVAS as c, CLASS_MINIVIEW_COLLAPSE as v, CLASS_MINIVIEW_PANNING as f, EVENT_PAN as m, EVENT_NODE_RENDERED as _, EVENT_GROUP_RELAYOUT as g, EVENT_RELAYOUT as b, EVENT_GROUP_RESIZE as y, EVENT_ROTATE as x, EVENT_AFTER_LAYOUT_REFRESH as w, EVENT_OBJECT_REPAINTED as z, EVENT_STATE_RESTORED as M, EVENT_NODE_VISIBILITY as E, CLASS_MINIVIEW_CLICK_TO_CENTER as k, ATTRIBUTE_JTK_NODE_ID as T, CLASS_MINIVIEW_GROUP_ELEMENT as S, CLASS_MINIVIEW_ELEMENT as C, ATTRIBUTE_MINIVIEW_TYPE as R } from "../browser-ui/constants.js";
import { removeWheelListener as j, addWheelListener as A } from "../browser-ui/wheel-listener.js";
import { debounce as F } from "../browser-ui/browser-util.js";
import { OptimisticEventGenerator as P } from "../ui-core/util/event-generator.js";
import { addClass as L, removeClass as G, consume as B } from "../ui-core/browser-ui-renderer/browser-util.js";
import { EVENT_DATA_LOAD_END as Z, EVENT_NODE_MOVE_END as W, EVENT_NODE_MOVE as I, EVENT_NODE_REMOVED as N, EVENT_NODE_ADDED as O, EVENT_GROUP_MOVE_END as V, EVENT_GROUP_MOVE as D } from "../core/constants.js";
import { EVENT_ZOOM as H, EVENT_GROUP_ADDED as q, EVENT_GROUP_REMOVED as J, EVENT_GROUP_MEMBER_ADDED as K, EVENT_GROUP_MEMBER_REMOVED as Q, EVENT_GROUP_COLLAPSE as U, EVENT_GROUP_EXPAND as X } from "../ui-core/core/event-constants.js";
import { EVENT_MOUSEDOWN as Y, EVENT_CLICK as $, EVENT_TAP as ee, EVENT_MOUSEUP as te, EVENT_MOUSEMOVE as ie } from "../ui-core/browser-ui-renderer/event-constants.js";
import { PanZoom as ne } from "../browser-ui/pan-zoom.js";
import { EventManager as se, pageLocation as oe } from "../ui-core/browser-ui-renderer/event-manager.js";
import { setPosition as re, getPosition as ae } from "../ui-core/browser-ui-renderer/positioning-and-sizing-utils.js";
import { attSel as he } from "../dialogs-core/constants.js";
var le = "jtk-miniview-type";
var ue = function (a) {
  e(ue, a);
  var P = t(ue);
  function ue() {
    var e;
    i(this, ue);
    for (var t = arguments.length, o = new Array(t), r = 0; r < t; r++) {
      o[r] = arguments[r];
    }
    e = P.call.apply(P, [this].concat(o));
    n(s(e), "surface", void 0);
    n(s(e), "panzoom", void 0);
    n(s(e), "containerElement", void 0);
    n(s(e), "canvasElement", void 0);
    n(s(e), "pannerElement", void 0);
    n(s(e), "surfaceBounds", void 0);
    n(s(e), "suspended", void 0);
    n(s(e), "_collapsible", void 0);
    n(s(e), "typeFunction", void 0);
    n(s(e), "_collapser", void 0);
    n(s(e), "_collapsed", void 0);
    n(s(e), "wheelSensitivity", void 0);
    n(s(e), "wheelReverse", void 0);
    n(s(e), "panning", false);
    n(s(e), "downAt", null);
    n(s(e), "pannerAtMouseDown", null);
    n(s(e), "zoomingWithWheel", false);
    n(s(e), "elementFilter", void 0);
    n(s(e), "visible", true);
    n(s(e), "pannerPos", void 0);
    n(s(e), "vertexMap", {});
    n(s(e), "activeTracking", void 0);
    n(s(e), "clickToCenter", void 0);
    n(s(e), "enableWheelZoom", void 0);
    n(s(e), "eventManager", void 0);
    n(s(e), "_resizeHandler", void 0);
    n(s(e), "_zoomToFit", void 0);
    n(s(e), "surfaceBindings", []);
    n(s(e), "_doToggleCollapse", void 0);
    n(s(e), "viewport", void 0);
    n(s(e), "_doWheelZoom", void 0);
    n(s(e), "_downListener", void 0);
    n(s(e), "_moveListener", void 0);
    n(s(e), "_upListener", void 0);
    return e;
  }
  o(ue, [{
    key: "destroy",
    value: function e() {
      var t = this;
      this.eventManager.off(window, h, this._resizeHandler);
      this.panzoom.destroy();
      this.eventManager.off(this.pannerElement, Y, this._downListener);
      this.canvasElement.parentNode.removeChild(this.canvasElement);
      this.pannerElement.parentNode.removeChild(this.pannerElement);
      this.surface.removeClass(this.containerElement, l);
      this.surface.removeClass(this.containerElement, u);
      if (this._collapsible) {
        this.eventManager.off(this._collapser, $, this._doToggleCollapse);
        this._collapser.parentNode.removeChild(this._collapser);
      }
      this.surfaceBindings.forEach(function (e) {
        return t.surface.unbind(e[0], e[1]);
      });
      this.surface.toolkitInstance.unbind(Z, this._zoomToFit);
      if (this._doWheelZoom) {
        j(this.containerElement, this._doWheelZoom);
      }
    }
  }, {
    key: "bindToSurface",
    value: function e(t, i) {
      var n = i.bind(this);
      this.surfaceBindings.push([t, n]);
      this.surface.bind(t, n);
    }
  }, {
    key: "initialise",
    value: function e(t, i) {
      var n = this;
      this.surface = t;
      this.activeTracking = i.activeTracking !== false;
      this.clickToCenter = i.clickToCenter !== false;
      this.wheelReverse = this.wheelReverse || this.surface.wheelReverse === true;
      this._downListener = this.downListener.bind(this);
      this._moveListener = this.moveListener.bind(this);
      this._upListener = this.upListener.bind(this);
      this.containerElement = i.container;
      this.pannerElement = this.surface._createElement({
        position: d.absolute,
        width: d.nominalSize,
        height: d.nominalSize,
        left: 0,
        top: 0,
        clazz: p
      }, this.containerElement);
      this.canvasElement = this.surface._createElement({
        position: d.relative,
        width: d.nominalSize,
        height: d.nominalSize,
        left: 0,
        top: 0,
        clazz: c
      }, this.containerElement);
      this.surfaceBounds = i.bounds;
      this.suspended = i.suspended === true;
      this._collapsible = i.collapsible !== false;
      this.typeFunction = i.typeFunction;
      this._collapser = null;
      this._collapsed = false;
      this.enableWheelZoom = i.enableWheelZoom !== false;
      this.wheelSensitivity = i.wheelSensitivity || 10;
      this.elementFilter = i.elementFilter || function (e) {
        return true;
      };
      this.visible = i.visible !== false;
      this.eventManager = new se();
      L(this.containerElement, l);
      this._doToggleCollapse = this.toggleCollapsed.bind(this);
      if (this._collapsible) {
        this._collapser = this.surface._createElement({
          type: "div"
        });
        this._collapser.className = v;
        this.containerElement.appendChild(this._collapser);
        this.surface.on(this._collapser, d.click, this._doToggleCollapse);
      }
      this.viewport = this.surface.jsplumb.viewport;
      this.panzoom = new ne({
        viewport: this.viewport,
        viewportElement: this.containerElement,
        canvasElement: this.canvasElement,
        getOffset: function e(t) {
          return n.surface.jsplumb.getOffset(t);
        },
        getOffsetRelativeToRoot: function e(t) {
          return n.surface.jsplumb.getOffsetRelativeToRoot(t);
        },
        getSize: function e(t) {
          return n.surface.jsplumb.getSize(t);
        },
        wheel: {
          zoom: false
        },
        enablePan: false,
        enableAnimation: false,
        idFunction: function e(t) {
          return t.getAttribute("id");
        },
        events: {
          pan: function e() {
            n._updateSurface();
          },
          mousedown: function e() {
            L(n.pannerElement, f);
          },
          mouseup: function e() {
            G(n.pannerElement, f);
          }
        },
        zoomRange: [-Infinity, Infinity]
      });
      this._resizeHandler = F(function () {
        n._updatePanner();
      }, 100);
      this.eventManager.on(window, h, this._resizeHandler);
      if (this.enableWheelZoom !== false) {
        this._doWheelZoom = this.wheelZoom.bind(this);
        A(this.containerElement, this._doWheelZoom);
      }
      this.pannerElement.style.transformOrigin = "0 0";
      this.surface.addClass(this.containerElement, l);
      this.eventManager.on(this.pannerElement, Y, this._downListener);
      this._zoomToFit = function () {
        return n.zoomToFit();
      };
      this.surface.toolkitInstance.bind(Z, this._zoomToFit);
      this.setVisible(this.visible);
      this.bindToSurface(m, this._updatePanner);
      this.bindToSurface(H, this._updatePanner);
      this.bindToSurface(W, this._vertexMoved);
      if (this.activeTracking) {
        this.bindToSurface(I, this._vertexMoved);
      }
      this.bindToSurface(N, this._nodeRemoved);
      this.bindToSurface(O, this._nodeAdded);
      this.bindToSurface(_, this._nodeAdded);
      this.bindToSurface(g, this._groupRelayout);
      this.bindToSurface(b, this._afterRelayout);
      this.bindToSurface(q, this._onGroupAdded);
      this.bindToSurface(V, this._vertexMoved);
      if (this.activeTracking) {
        this.bindToSurface(D, this._vertexMoved);
      }
      this.bindToSurface(J, this._groupRemoved);
      this.bindToSurface(K, this._onGroupMemberAdded);
      this.bindToSurface(Q, this._onGroupMemberRemoved);
      this.bindToSurface(U, this._onGroupCollapse);
      this.bindToSurface(X, this._onGroupExpand);
      this.bindToSurface(y, this._onGroupResize);
      this.bindToSurface(x, this._objectRotated);
      var s = function e(t) {
        n.surfaceBounds = t.bounds;
        t.positions.forEach(function (e, t) {
          var i = n.vertexMap[t];
          if (i) {
            re(i, e);
          }
        });
        n.zoomToFit(true);
      };
      this.bindToSurface(b, s);
      this.bindToSurface(w, s);
      this.bindToSurface(z, this._objectRepainted);
      this.bindToSurface(M, this._updatePanner);
      this.bindToSurface(E, this._nodeVisibility);
      this._loadInitialData();
      if (this.clickToCenter) {
        L(this.containerElement, k);
        this.eventManager.on(this.canvasElement, ee, he(T), function (e) {
          var t = e.target.getAttribute(T);
          if (t && n.vertexMap[t]) {
            n.surface.centerOn(t);
          }
        });
      }
      return true;
    }
  }, {
    key: "reset",
    value: function e() {
      this._removeAllNodes();
      this.panzoom.setZoom(1);
      this.panzoom.reset();
      this._updatePanner();
    }
  }, {
    key: "_loadInitialData",
    value: function e() {
      var t = this.surface.toolkitInstance,
        i = t.getNodes(),
        n = t.getGroups();
      for (var s = 0; s < n.length; s++) {
        var o = this.surface.getRenderedElement(n[s]);
        if (o != null) {
          this._onGroupAdded({
            el: o,
            id: n[s].id,
            vertex: n[s],
            pos: this.surface._getViewportPosition(n[s])
          });
        }
      }
      for (var r = 0; r < i.length; r++) {
        var a = this.surface.getRenderedElement(i[r]);
        if (a != null) {
          this._nodeAdded({
            el: a,
            id: i[r].id,
            vertex: i[r],
            pos: this.surface._getViewportPosition(i[r])
          });
        }
      }
      if (n.length > 0 || i.length > 0) {
        this.zoomToFit(true);
      }
    }
  }, {
    key: "_onGroupAdded",
    value: function e(t) {
      this._nodeAdded(t, S);
    }
  }, {
    key: "_onGroupCollapse",
    value: function e(t) {
      this._showGroupMembers(t.group, false);
      this._objectRepainted({
        id: t.group.id
      });
    }
  }, {
    key: "_onGroupExpand",
    value: function e(t) {
      this._showGroupMembers(t.group, true);
      this._objectRepainted({
        id: t.group.id
      });
    }
  }, {
    key: "_onGroupMemberRemoved",
    value: function e(t) {
      var i = this.vertexMap[t.vertex.id];
      if (i) {
        this.canvasElement.appendChild(i);
        if (t.pos) {
          re(i, t.pos);
        }
      }
    }
  }, {
    key: "_onGroupResize",
    value: function e(t) {
      this._objectRepainted({
        id: t.group.id
      });
    }
  }, {
    key: "_onGroupMemberAdded",
    value: function e(t) {
      var i = this.vertexMap[t.group.id],
        n = this.vertexMap[t.vertex.id];
      if (i && n) {
        i.appendChild(n);
        if (t.pos) {
          re(n, t.pos);
        }
      }
    }
  }, {
    key: "_objectRotated",
    value: function e(t) {
      var i = this.vertexMap[t.obj.id];
      if (i != null) {
        i.style.transform = "rotate(" + t.rotation + "deg)";
        i.style.transformOrigin = "50% 50%";
      }
    }
  }, {
    key: "downListener",
    value: function e(t) {
      this.panning = true;
      this.downAt = oe(t);
      this.pannerAtMouseDown = ae(this.pannerElement);
      this.eventManager.on(document, te, this._upListener);
      this.eventManager.on(document, ie, this._moveListener);
      B(t);
    }
  }, {
    key: "moveListener",
    value: function e(t) {
      this.zoomingWithWheel = false;
      if (this.panning) {
        var i = oe(t),
          n = i.x - this.downAt.x,
          s = i.y - this.downAt.y,
          o = {
            x: this.pannerAtMouseDown.x + n,
            y: this.pannerAtMouseDown.y + s
          };
        this._updateSurface(o);
        re(this.pannerElement, o);
      }
    }
  }, {
    key: "upListener",
    value: function e(t) {
      this.panning = false;
      this.downAt = null;
      this.eventManager.off(document, te, this._upListener);
      this.eventManager.off(document, ie, this._moveListener);
    }
  }, {
    key: "wheelZoom",
    value: function e(t) {
      B(t);
      var i = this.panzoom.mapEventLocation(t);
      this.surface.panZoom._setTransformOriginToCanvasPoint(i.x, i.y);
      this.surface.nudgeWheelZoom(t.normalizedWheelDelta * this.wheelSensitivity * (this.wheelReverse ? -1 : 1), t);
    }
  }, {
    key: "toggleCollapsed",
    value: function e() {
      this._collapsed = !this._collapsed;
      this.surface[this._collapsed ? "addClass" : "removeClass"](this.containerElement, u);
      this.zoomToFit(true);
    }
  }, {
    key: "zoomToFit",
    value: function e(t) {
      this.panzoom.zoomToFit({
        onComplete: this._updatePanner,
        doNotFirePanEvent: t
      });
    }
  }, {
    key: "_vertexMoved",
    value: function e(t) {
      var i = t.vertex;
      if (i && this.elementFilter(i) === false) return;
      var n = this.vertexMap[i.id];
      re(n, t.pos);
      this.zoomToFit(true);
    }
  }, {
    key: "_groupRelayout",
    value: function e(t) {
      var i = this;
      var n = t.group.getMembers(),
        s,
        o = this.vertexMap[t.group.id];
      n.forEach(function (e, n) {
        s = t.layout.getPosition(e.id);
        var o = i.vertexMap[e.id];
        if (s != null && o != null) {
          re(o, s);
        }
      });
      var r = this.surface.jsplumb.getSize(t.el);
      o.style.width = r.w + d.px;
      o.style.height = r.h + d.px;
      this._updatePanner();
    }
  }, {
    key: "_afterRelayout",
    value: function e(t) {
      var i = this;
      this.surfaceBounds = t.bounds;
      t.positions.forEach(function (e, t) {
        var n = i.vertexMap[t];
        if (n) {
          re(n, e);
        }
      });
      this.zoomToFit(true);
    }
  }, {
    key: "_migrateGroupMembers",
    value: function e(t, i) {
      var n = t.getMembers();
      for (var s = 0; s < n.length; s++) {
        var o = this.vertexMap[n[s].id];
        if (o) {
          i.appendChild(o);
        }
      }
    }
  }, {
    key: "_nodeAdded",
    value: function e(t, i) {
      if (this.elementFilter(t.vertex) === false) {
        return;
      }
      var n = t.vertex;
      var s = n.id;
      if (this.vertexMap[s] != null) {
        return;
      }
      var o = t.pos || {
        x: 0,
        y: 0
      };
      var a = this.surface.jsplumb.getSize(t.el),
        h = this.surface._createElement({
          position: d.absolute,
          width: a.w + d.px,
          height: a.h + d.px,
          left: o.x,
          top: o.y,
          clazz: C + (i ? " " + i : "")
        });
      if (this.typeFunction) {
        h.setAttribute(R, this.typeFunction(t.vertex));
      }
      h.relatedElement = t.el;
      this.surfaceBounds = this.surface.getBoundsInfo();
      h.setAttribute(T, s);
      this.canvasElement.appendChild(h);
      this.panzoom.add(h);
      this.vertexMap[s] = h;
      if (n.data.rotation != null) {
        h.style.transform = "rotate(" + n.data.rotation + "deg)";
        h.style.transformOrigin = "50% 50%";
      }
      if (t.vertex.type === r.objectType) {
        this._migrateGroupMembers(t.vertex, h);
      } else if (t.vertex.group) {
        var l = this.vertexMap[t.vertex.group.id];
        if (l) {
          l.appendChild(h);
          this.panzoom.suspend(t.el);
        }
      }
      this._updatePanner();
    }
  }, {
    key: "registerNode",
    value: function e(t) {
      this._nodeAdded(t, t.group ? S : "");
      this._vertexMoved(t);
    }
  }, {
    key: "setVisible",
    value: function e(t) {
      this.visible = t;
      this.containerElement.style.display = t ? d.block : d.none;
    }
  }, {
    key: "getPan",
    value: function e() {
      return this.panzoom.getPan();
    }
  }, {
    key: "_showGroupMembers",
    value: function e(t, i) {
      var n = t.getMembers();
      for (var s = 0; s < n.length; s++) {
        this.vertexMap[n[s].id].style.display = i ? "block" : "none";
      }
    }
  }, {
    key: "_objectRepainted",
    value: function e(t) {
      var i = this.vertexMap[t.id];
      if (i) {
        var n = this.surface.jsplumb.getSize(i.relatedElement);
        i.style.width = n.w + d.px;
        i.style.height = n.h + d.px;
        this._updatePanner();
        if (this.typeFunction) {
          var s = t.obj || this.surface.toolkitInstance.getVertex(t.id);
          if (s) {
            i.setAttribute(le, this.typeFunction(s));
          }
        }
      }
    }
  }, {
    key: "invalidate",
    value: function e(t) {
      if (t) {
        this._objectRepainted({
          id: t
        });
      } else {
        for (var i in this.vertexMap) {
          this._objectRepainted({
            id: i
          });
        }
      }
    }
  }, {
    key: "setSuspended",
    value: function e(t, i) {
      this.suspended = t;
      i && this._updatePanner();
    }
  }, {
    key: "_vertexRemoved",
    value: function e(t) {
      var i = t.vertex.id,
        n = this.vertexMap[i];
      if (n) {
        this.panzoom.remove(n);
        delete this.vertexMap[i];
        n.parentNode.removeChild(n);
        this.zoomToFit(true);
      }
    }
  }, {
    key: "_groupRemoved",
    value: function e(t) {
      var i = t.vertex.id,
        n = this.vertexMap[i];
      if (n) {
        var s, o, r;
        if (t.childrenRemoved !== true) {
          for (var a = 0; a < t.children.length; a++) {
            s = t.children[a];
            o = this.vertexMap[s.id];
            if (o) {
              r = t.childPositions[s.id];
              if (r) {
                re(o, r);
              }
              this.canvasElement.appendChild(o);
            }
          }
        }
      }
      this._vertexRemoved(t);
    }
  }, {
    key: "_nodeRemoved",
    value: function e(t) {
      this._vertexRemoved(t);
    }
  }, {
    key: "_removeAllNodes",
    value: function e() {
      var t;
      for (var i in this.vertexMap) {
        t = this.vertexMap[i];
        this.panzoom.remove(t);
        delete this.vertexMap[i];
        t.parentNode.removeChild(t);
      }
      this._updatePanner();
    }
  }, {
    key: "_nodeVisibility",
    value: function e(t) {
      var i = (t.node || t.group).id,
        n = this.vertexMap[i];
      if (n) {
        n.style.display = t.state ? "block" : "none";
      }
    }
  }, {
    key: "_updatePanner",
    value: function e() {
      if (this.surface && this.panzoom && !this.suspended) {
        this.surfaceBounds = this.surface.getBoundsInfo();
        var t = this.surface.getApparentCanvasLocation(),
          i = this.panzoom.getApparentCanvasLocation(),
          n = this.panzoom.getZoom(),
          s = n / this.surfaceBounds.zoom;
        this.pannerElement.style.width = this.surfaceBounds.vw + d.px;
        this.pannerElement.style.height = this.surfaceBounds.vh + d.px;
        this.pannerElement.style.transform = "scale(" + s + ")";
        var o = {
          x: t.x * s,
          y: t.y * s
        };
        this.pannerPos = {
          x: i.x - o.x,
          y: i.y - o.y
        };
        re(this.pannerElement, this.pannerPos);
      }
    }
  }, {
    key: "_updateSurface",
    value: function e(t) {
      if (this.panzoom != null) {
        this.surfaceBounds = this.surface.getBoundsInfo();
        t = t || ae(this.pannerElement);
        var i = this.panzoom.getApparentCanvasLocation(),
          n = this.panzoom.getZoom(),
          s = n / this.surfaceBounds.zoom,
          o = (i.x - t.x) / s,
          r = (i.y - t.y) / s;
        var a = this.surface.setApparentCanvasLocation(o, r);
        return {
          x: i.x - a.x * s,
          y: i.y - a.y * s
        };
      }
    }
  }, {
    key: "getRenderedVertex",
    value: function e(t) {
      return this.vertexMap[t];
    }
  }]);
  return ue;
}(P);
n(ue, "type", "miniview");
a.register(ue.type, ue);
export { ue as MiniviewPlugin };