import { inherits as t, createSuper as e, classCallCheck as n, defineProperty as i, assertThisInitialized as a, createClass as o } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { AbstractLayout as u } from "./abstract-layout.js";
import { Layouts as r } from "./layouts.js";
var l = function (u) {
  t(l, u);
  var r = e(l);
  function l(t) {
    var e;
    n(this, l);
    e = r.call(this, t);
    i(a(e), "_$_suppliedLocationFunction", void 0);
    i(a(e), "defaultMagnetized", false);
    i(a(e), "absoluteBacked", void 0);
    e.absoluteBacked = t.options.absoluteBacked === true;
    e._$_suppliedLocationFunction = t.options.locationFunction;
    return e;
  }
  o(l, [{
    key: "_vertexAdded",
    value: function t(e, n) {
      return this._$_findLocation(e.vertex, e.parameters);
    }
  }, {
    key: "_defaultLocationFunction",
    value: function t(e) {
      return {
        x: e.data.left,
        y: e.data.top
      };
    }
  }, {
    key: "_$_findLocation",
    value: function t(e, n) {
      var i = n != null && n.locationFunction ? n.locationFunction : this._$_suppliedLocationFunction || this._defaultLocationFunction;
      return i(e);
    }
  }, {
    key: "begin",
    value: function t(e, n) {
      var i = this.adapter.getElements();
      var a = i.length;
      for (var o = 0; o < a; o++) {
        var u = i[o],
          r = u.getFullId(),
          l = this.getPosition(r);
        if (l == null) {
          l = this._$_findLocation(u, n);
        }
        this.setPosition(r, l.x, l.y, true);
      }
    }
  }, {
    key: "step",
    value: function t(e, n) {
      this.done = true;
    }
  }, {
    key: "canMagnetize",
    value: function t(e) {
      return true;
    }
  }, {
    key: "end",
    value: function t(e, n, i) {}
  }, {
    key: "reset",
    value: function t() {}
  }, {
    key: "getAbsolutePosition",
    value: function t(e, n) {
      return e == null ? {
        x: null,
        y: null
      } : this._$_findLocation(e, n);
    }
  }]);
  return l;
}(u);
var s = function (u) {
  t(l, u);
  var r = e(l);
  function l(t) {
    var e;
    n(this, l);
    e = r.call(this, t);
    i(a(e), "type", l.type);
    return e;
  }
  o(l, [{
    key: "getDefaultParameters",
    value: function t() {
      return {};
    }
  }]);
  return l;
}(l);
i(s, "type", "Absolute");
r.register(s.type, s);
export { l as AbsoluteBackedLayout, s as AbsoluteLayout };