import { _createBaseSegment as n } from "../../common/abstract-segment.js";
import { gradient as t, lineLength as y, pointOnLine as i } from "../../util/geom.js";
import { _registerSegmentHandler as x } from "./segments.js";
var e = "Straight";
function r(n, t, y) {
  return y > t ? t <= n && n <= y : t >= n && n >= y;
}
function a(n, t, y) {
  return y >= Math.min(n, t) && y <= Math.max(n, t);
}
function o(n, t, y) {
  return Math.abs(y - n) < Math.abs(y - t) ? n : t;
}
var s = {
  create: function y(i) {
    var x = n(i);
    var r = Math.sqrt(Math.pow(x.x2 - x.x1, 2) + Math.pow(x.y2 - x.y1, 2));
    var a = t({
      x: x.x1,
      y: x.y1
    }, {
      x: x.x2,
      y: x.y2
    });
    var o = -1 / a;
    var s = {
      xmin: Math.min(x.x1, x.x2),
      ymin: Math.min(x.y1, x.y2),
      xmax: Math.max(x.x1, x.x2),
      ymax: Math.max(x.y1, x.y2)
    };
    return Object.assign(x, {
      length: r,
      m: a,
      m2: o,
      extents: s,
      type: e
    });
  },
  boundingBoxIntersection: function n(t, y) {
    return s.boxIntersection(t, y.x, y.y, y.w, y.h);
  },
  boxIntersection: function n(t, y, i, x, e) {
    var r = [];
    r.push.apply(r, s.lineIntersection(t, y, i, y + x, i));
    r.push.apply(r, s.lineIntersection(t, y + x, i, y + x, i + e));
    r.push.apply(r, s.lineIntersection(t, y + x, i + e, y, i + e));
    r.push.apply(r, s.lineIntersection(t, y, i + e, y, i));
    return r;
  },
  findClosestPointOnPath: function n(t, i, x) {
    var e = {
      d: Infinity,
      x: null,
      y: null,
      l: null,
      x1: t.x1,
      x2: t.x2,
      y1: t.y1,
      y2: t.y2
    };
    if (t.m === 0) {
      e.y = t.y1;
      e.x = a(t.x1, t.x2, i) ? i : o(t.x1, t.x2, i);
    } else if (t.m === Infinity || t.m === -Infinity) {
      e.x = t.x1;
      e.y = a(t.y1, t.y2, x) ? x : o(t.y1, t.y2, x);
    } else {
      var r = t.y1 - t.m * t.x1,
        s = x - t.m2 * i,
        f = (s - r) / (t.m - t.m2),
        u = t.m * f + r;
      e.x = a(t.x1, t.x2, f) ? f : o(t.x1, t.x2, f);
      e.y = a(t.y1, t.y2, u) ? u : o(t.y1, t.y2, u);
    }
    var h = y({
      x: e.x,
      y: e.y
    }, {
      x: t.x1,
      y: t.y1
    });
    e.d = y({
      x: i,
      y: x
    }, e);
    e.l = h / t.length;
    return e;
  },
  getLength: function n(t) {
    return t.length;
  },
  getPath: function n(t, y) {
    return (y ? "M " + t.x1 + " " + t.y1 + " " : "") + "L " + t.x2 + " " + t.y2;
  },
  gradientAtPoint: function n(t, y, i) {
    return t.m;
  },
  lineIntersection: function n(y, i, x, e, a) {
    var o = Math.abs(t({
        x: i,
        y: x
      }, {
        x: e,
        y: a
      })),
      s = Math.abs(y.m),
      f = s === Infinity ? y.x1 : y.y1 - s * y.x1,
      u = [],
      h = o === Infinity ? i : x - o * i;
    if (o !== s) {
      if (o === Infinity && s === 0) {
        if (r(i, y.x1, y.x2) && r(y.y1, x, a)) {
          u.push({
            x: i,
            y: y.y1
          });
        }
      } else if (o === 0 && s === Infinity) {
        if (r(x, y.y1, y.y2) && r(y.x1, i, e)) {
          u.push({
            x: y.x1,
            y: x
          });
        }
      } else {
        var m, l;
        if (o === Infinity) {
          m = i;
          if (r(m, y.x1, y.x2)) {
            l = s * i + f;
            if (r(l, x, a)) {
              u.push({
                x: m,
                y: l
              });
            }
          }
        } else if (o === 0) {
          l = x;
          if (r(l, y.y1, y.y2)) {
            m = (x - f) / s;
            if (r(m, i, e)) {
              u.push({
                x: m,
                y: l
              });
            }
          }
        } else {
          m = (h - f) / (s - o);
          l = s * m + f;
          if (r(m, y.x1, y.x2) && r(l, y.y1, y.y2)) {
            u.push({
              x: m,
              y: l
            });
          }
        }
      }
    }
    return u;
  },
  pointAlongPathFrom: function n(t, y, x, e) {
    var r = s.pointOnPath(t, y, e),
      a = x <= 0 ? {
        x: t.x1,
        y: t.y1
      } : {
        x: t.x2,
        y: t.y2
      };
    if (x <= 0 && Math.abs(x) > 1) {
      x *= -1;
    }
    return i(r, a, x);
  },
  pointOnPath: function n(t, y, x) {
    if (y === 0 && !x) {
      return {
        x: t.x1,
        y: t.y1
      };
    } else if (y === 1 && !x) {
      return {
        x: t.x2,
        y: t.y2
      };
    } else {
      var e = x ? y > 0 ? y : t.length + y : y * t.length;
      return i({
        x: t.x1,
        y: t.y1
      }, {
        x: t.x2,
        y: t.y2
      }, e);
    }
  }
};
x(e, s);
export { e as SEGMENT_TYPE_STRAIGHT, s as StraightSegmentHandler };