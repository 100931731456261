import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { BeinformedService } from '@core/services/beinformed.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { environment } from '@src/environments/environment';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { GraphAppraisalColors } from '@core/enums';
import type {
  ICaseListResult, ITableDataMapped, IFilterMetaItem, IAttribute, ICaseListAction, IContributionsAction,
  ICaseListContributions, ICaseList, TCaseListDynamicSchema, TContributionsAttribute,
  TCaseListContributionsResults, ICaseListRow, ISortingMeta, TCaseListRows, IPagingMeta, INarisOption, IInputOption
} from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  public filterFormClicked = new Subject<Event>();
  public reloadPanel = new Subject<void>();
  public updateEditedRow$: Subject<string> = new Subject<string>();
  public targetedTableReload$: Subject<string> = new Subject<string>();
  public refreshPanels$: Subject<boolean> = new Subject<boolean>();
  public collabCategories$ = new Subject<Record<string, any>>();

  constructor(
    private readonly beinformedService: BeinformedService, 
    private readonly snackbar: SnackbarService,
    private readonly router: Router
  ) {}

  public getTableData({href, pagesize}: { href: string; pagesize?: number }, isModal = false) {
    const dataUrl = `${href}${pagesize ? `?pagesize=${pagesize}` : ''}`;
    return this.beinformedService.fetchResponseWithContributions(dataUrl, !isModal);
  }

  public createTableData({data, contributions}: ICaseListResult, separateObjectTypes?: boolean) {
    try {
      const tableColumnsObj = contributions?.results || {};
      const tableDataArr = data?._embedded?.results || [];
      const tableDataActions = data?.actions || [];
      const tableMetaActions = contributions?.actions || [];
      const rowMetaActions = this.getRowActionMeta(tableColumnsObj);
      const pagingMeta = this.setPagingMeta(data, contributions);
      const filterMeta = this.setFilterMeta(data, contributions);
      const sortingMeta = this.setSortingMeta(data, contributions);
      let tableData = this.mapTableData(tableDataArr, rowMetaActions, data?.dynamicschema, contributions, separateObjectTypes);
      const tableLink = data?._links?.self?.href || '';
      const tableTitle = contributions?.label || 'NoTitle';
      const tableName = Object.keys(contributions?.results)[0];
      const tableLayouthint = contributions?.layouthint || [];
      let tableColumns = Object.values(tableColumnsObj)[0]?.['attributes'] || [];
      if (separateObjectTypes) tableColumns = this.getSeperatedColumns(tableColumnsObj);
      const tableActions = this.mergeActionArrays(tableDataActions, tableMetaActions);
      let tableDataSource = this.createDataSource(tableData);
      const tableResourceType = contributions?.resourcetype || '';

      const splitKeys: string[] = []; 
      tableColumns.forEach(x => {
        const key = Object.keys(x)[0];
        if (x[key].layouthint?.includes('column-split')) splitKeys.push(key);
      });
      if (splitKeys.length > 0) {
        const splitted = this.splitColumns(tableColumns, tableData, splitKeys, contributions);
        tableColumns = splitted.columns;
        tableData = splitted.data;
        tableDataSource = this.createDataSource(tableData);
      }

      return {
        tableData,
        tableLink,
        tableTitle,
        tableName,
        tableColumns,
        tableActions,
        tableDataSource,
        tableResourceType,
        pagingMeta,
        filterMeta,
        sortingMeta,
        tableLayouthint
      } as ITableDataMapped;
    } catch (err) {
      // eslint-disable-next-line no-console
      if (!environment.production) console.log(err);
      this.snackbar.open({text: 'table.response_incompatible', type: 'error'});
      return undefined;
    }
  }

  private getSeperatedColumns(source: TCaseListContributionsResults) {
    const returnValue: any[] = [];
    const keys = Object.keys(source);
    keys.forEach(key => {
      const columns = source[key]['attributes'] || [];
      returnValue[key as any] = columns;
    });
    return returnValue;
  }

  public createDataSource(data: ICaseListRow[]) {
    return data?.map(row => {
      const tableRow = {} as ICaseListRow;
      for (const key in row) {
        if (key === 'actions' || key === 'children' || key === '_links') tableRow[key] = row[key];
        else tableRow[key] = this.beinformedService.mapToString(row[key], true);
      }
      return tableRow;
    }) || [];
  }

  private getRowActionMeta(obj: TCaseListContributionsResults) {
    const rowActionMeta: IContributionsAction[] = [];
    for (const key in obj) {
      const actionArr = obj[key].actions || [];
      for (const action of actionArr) {
        const hasAction = rowActionMeta.some(act => act.name === action.name);
        if (!hasAction) rowActionMeta.push(action);
      }
    }
    return rowActionMeta;
  }

  private mergeActionArrays(actionDataArr: ICaseListAction[], actionMetaArr: IContributionsAction[]) {
    return actionDataArr.map(actionData => {
      const actionMetaData = actionMetaArr.find(actionMeta => actionMeta.name === actionData.name) || {};
      return {...actionData, ...actionMetaData};
    }).filter(i => i.href);
  }

  private mapTableData(data: TCaseListRows[], rowMetaActions: any[], dynamicschema: TCaseListDynamicSchema = {}, contributions: ICaseListContributions, separateObjectTypes = false) {
    return separateObjectTypes ?
      this.mapTableDataSeparated(data, rowMetaActions, dynamicschema, contributions) :
      data.map(obj => {
        const objectName = Object.keys(obj)[0];
        const objectAttributes = contributions.results[objectName].attributes;
        const dataObj = this.transformTableObject(obj, objectAttributes, dynamicschema);
        const objActions = dataObj['actions'];
        if (!!objActions) dataObj['actions'] = this.mergeActionArrays(objActions, rowMetaActions);
        if (!!objActions && objectName === 'ScoringScaleRankView') dataObj['actions'] = this.mergeScoringScaleActionArrays(dataObj['actions']);
        if (!contributions.layouthint?.includes('no-goto'))
          dataObj['actions'] = this.addNavActions(dataObj['actions'], obj);
        return dataObj;
      });
  }

  private mergeScoringScaleActionArrays(actionDataArr: ICaseListAction[]) {
    const menuAction = {
      href: '', 
      method: '', 
      name: 'hamburger_menu', 
      label: 'Actions', 
      children: actionDataArr
    } as ICaseListAction;

    return [menuAction];
  }

  private mapTableDataSeparated(data: TCaseListRows[], rowMetaActions: any[], dynamicschema: TCaseListDynamicSchema, contributions: ICaseListContributions) {
    const returnObject: any[] = [];
    data.forEach(obj => {
      const objectName = Object.keys(obj)[0] as any;
      const objectAttributes = contributions.results[objectName].attributes;
      const dataObj = this.transformTableObject(obj, objectAttributes, dynamicschema);
      const objActions = dataObj['actions'];
      if (!!objActions) dataObj['actions'] = this.mergeActionArrays(objActions, rowMetaActions);
      if (!contributions.layouthint?.includes('no-goto'))
        dataObj['actions'] = this.addNavActions(dataObj['actions'], obj);
      returnObject.push(dataObj);
    });
    return returnObject;
  }

  private addNavActions(currentActions: ICaseListAction[], row: TCaseListRows): ICaseListAction[] {
    const objectName = Object.keys(row)?.[0];
    if (!!objectName) {
      const selfHref = row?.[objectName]?._links?.self;

      if ((!!selfHref && /^\/[a-z-]+\/[a-z-]+\/[0-9]+$/m.test(selfHref.href) && !this.router.url.startsWith('/import/')) || !!row?.[objectName]?.Href) {
        const action: ICaseListAction = {
          href: !!selfHref && /^\/[a-z-]+\/[a-z-]+\/[0-9]+$/m.test(selfHref.href) ? selfHref.href : row?.[objectName]?.Href,
          label: 'table.open_object',
          method: 'POST',
          name: 'goto-object',
          type: 'goto'
        };
        if (!currentActions) currentActions = [];
        currentActions.push(action);
      }

      if (row?.[objectName]?.Layouthint?.includes('goto-object-new')) {
        const action: ICaseListAction = {
          href: row[objectName].Href,
          label: 'table.open_object',
          method: 'POST',
          name: 'goto-object-new',
          type: 'goto'
        };
        if (!currentActions) currentActions = [];
        currentActions.push(action);
      }
    }
    return currentActions;
  }

  private createOptions(arr: number[]) {
    return arr.map(item => ({label: item.toString(), value: item})) as INarisOption[];
  }

  /**
   * Returns a transformed data object with dynamic references filled from dynamicschema and attribute options
   * @param data table data response
   * @param contributions table contributions response
   */
  private transformTableObject(obj: TCaseListRows, attributes: TContributionsAttribute[], dynamicschema: TCaseListDynamicSchema) {
    const dataObject = Object.values(obj)[0];

    Object.keys(dataObject).forEach((col: string) => {
      const rawValue = dataObject[col];
      const attributeConfig = attributes.find((i: any) => Object.keys(i)[0] === col) as IAttribute;
      const config = attributeConfig ? Object.values(attributeConfig)[0] : null;
      const optionMode = config?.optionMode;

      if (col.toLowerCase() === 'state') {
        dataObject[`${col}Value`] = rawValue;
      }

      // For static attributes, get the value from the attributes options array
      if (optionMode && optionMode === 'static') {
        if (config.multiplechoice) dataObject[col] = rawValue?.map((v: any) => config.options?.find((o: any) => o.code === v)?.label || v) || rawValue;
        else dataObject[col] = config.options?.find((o: any) => o.code === rawValue)?.label || rawValue;
      }

      // For lookup and dynamic options, get value by code from dynamicschema
      if (optionMode && ['lookup', 'dynamic', 'dynamicWithThreshold'].includes(optionMode)) {
        if (Array.isArray(rawValue)) dataObject[col] = rawValue.map(val => dynamicschema?.[col]?.find(o => o.code === val)?.label || val);
        else dataObject[col] = dynamicschema?.[col]?.find((o: any) => o.code === rawValue)?.label || rawValue;
      }

      // Transform date types to formatted date (DD-MM-YYYY)
      if (config?.type === 'date') dataObject[col] = rawValue ? DateTime.fromISO(rawValue).toFormat('yyyy-MM-dd') : rawValue;

      // Transform datetime types to formatted datetime (DD-MM-YYYY @ hh:mm)
      if (config?.type === 'datetime' || config?.type === 'timestamp') dataObject[col] = rawValue ? DateTime.fromISO(rawValue).toFormat('yyyy-MM-dd @ HH:mm') : rawValue;
    });
    return dataObject;
  }

  private setPagingMeta(data: ICaseList, contributions: ICaseListContributions) {
    let pagingMeta = {} as IPagingMeta;
    if (!!data?.paging) pagingMeta = {...data.paging};
    if (!!contributions?.paging) pagingMeta['pagesizeOptions'] = this.createOptions(contributions.paging.pagesize.options);
    return pagingMeta;
  }

  private setFilterMeta(data: ICaseList, contributions: ICaseListContributions) {
    if (!data) return [];
    const filterMeta = [];
    const dynamicschema = data.dynamicschema;
    if (!!data.filter) {
      for (const item in data.filter) {
        const dataObj = data.filter[item];
        const conObj = contributions.filter?.find(o => Object.keys(o)[0] === item) || {};
        const contributionsObj = Object.values(conObj)[0] as IFilterMetaItem;
        if (!dataObj.param && !dataObj.name.includes('range')) {
          for (const key in dataObj) {
            if (!!dataObj[key]?.param) {
              const filterKey = key as keyof IFilterMetaItem;
              const filterData = (dataObj as Omit<typeof dataObj, 'name' | 'param'>)[filterKey];
              const contributionsData = contributionsObj[filterKey] as Record<string, any>;
              const filterLabel = `${contributionsObj.label} - ${contributionsData?.label || ''}`;
              filterMeta.push({...filterData, ...contributionsData, label: filterLabel});
            }
          }
        } else {
          const filterObj = {...dataObj, ...contributionsObj};
          if (!!dataObj.options?.length) {
            const options = dataObj.options.map((dataOption: IInputOption) => {
              const conOption = contributionsObj.options?.find(obj => obj.key === dataOption.key) || {};
              return {...dataOption, ...conOption};
            });
            filterObj['options'] = options as typeof filterObj['options'];
          }
          if (!!dynamicschema) {
            for (const dyn in dynamicschema) {
              if (dyn === filterObj.name) filterObj['dynamics'] = dynamicschema[dyn];
            }
          }
          filterMeta.push(filterObj);
        }
      }
    }
    return filterMeta;
  }

  private setSortingMeta(data: ICaseList, contributions: ICaseListContributions) {
    if (!data) return {};
    const sortingMeta: ISortingMeta = {};
    if (!!data.sorting && typeof data.sorting === 'string') sortingMeta['initial'] = data.sorting || '';
    if (!!contributions.sorting) sortingMeta['attributes'] = contributions.sorting.attributes || [];
    return sortingMeta;
  }

  private splitColumns(columns: TContributionsAttribute[], data: any[], keys: string[], contributions: ICaseListContributions) {
    data.forEach(row => {
      keys.forEach(key => {
        const valuesToSplit = row[key] as any[];
        if (!!valuesToSplit && Array.isArray(valuesToSplit)) {
          const column = columns.find(col => Object.keys(col)[0] === key);
          valuesToSplit.forEach((_value, index) => {
            let value = _value;
            const newKey = key + (index + 1).toString();
            if (key.includes('Appraisal')) value = this.getAppraisalColor(_value, key, contributions);
            row[newKey] = value;
            const duplicate = columns.some(col => Object.keys(col)[0] === newKey);
            if (!duplicate) 
              columns.push({
                [newKey]: {
                  label: `${column?.[key].label} ${index + 1}`, 
                  type: 'string', 
                  displaysize: 50, 
                  maxLength: 256,
                  layouthint: ['color']
                }
              } as TContributionsAttribute);
          });
        }
      });
    });

    // Remove the original column which we splitted above
    keys.forEach(key => {
      const column = columns.findIndex(col => Object.keys(col)[0] === key);
      columns.splice(column, 1);
    });

    return {columns, data};
  }

  private getAppraisalColor(value: string, key: string,  contributions: ICaseListContributions): string {
    const objName = Object.keys(contributions.results)[0];
    const attribute = contributions.results[objName].attributes.find(attr => Object.keys(attr)[0] === key);
    const option = attribute?.[key].options?.find(opt => opt.label === value);
    if (!!option) return GraphAppraisalColors[option.code as keyof typeof GraphAppraisalColors];
    else return '';
  }
}
