import { createClass as e, defineProperty as t, classCallCheck as o } from "../_virtual/_rollupPluginBabelHelpers.js";
import { Lasso as s } from "./lasso.js";
import { Port as n } from "../core/model/graph.js";
import { SurfacePlugins as i, SURFACE_MODE_DISABLED as r, SURFACE_MODE_PAN as l } from "../browser-ui/surface.js";
import { EVENT_SURFACE_MODE_CHANGED as a, Constants as c, EVENT_LASSO_END as d } from "../browser-ui/constants.js";
var u = function () {
  function i() {
    o(this, i);
    t(this, "surface", void 0);
    t(this, "lasso", void 0);
    t(this, "onStart", void 0);
    t(this, "onEnd", void 0);
    t(this, "onSelect", void 0);
    t(this, "selectionFilter", void 0);
    t(this, "lassoSelections", []);
  }
  e(i, [{
    key: "destroy",
    value: function e() {}
  }, {
    key: "initialise",
    value: function e(t, o) {
      var i = this;
      t.bind(a, function (e) {
        i.lasso.enabled = e === c.select;
      });
      this.selectionFilter = o.selectionFilter;
      this.onStart = o.onStart;
      this.onSelect = o.onSelect;
      this.onEnd = o.onEnd;
      this.lasso = new s({
        surface: t,
        invert: o.invert,
        canvas: t.containerElement,
        cssClass: o.cssClass,
        generateLassoContent: o.generateLassoContent,
        onStart: function e() {
          t.setHoverSuspended(true);
          i.lassoSelections.length = 0;
          i.onStart && i.onStart();
        },
        onSelect: function e(s, r, l, a) {
          t.toolkitInstance.clearSelection();
          if (a && i.lassoSelections.length > 0) {
            t.toolkitInstance.removeFromSelection(i.lassoSelections);
          }
          var c = [],
            d,
            u = t.findIntersectingVertices(s, r, !l[0]),
            f = u.length,
            v = {};
          for (d = 0; d < f; d++) {
            if (i.selectionFilter == null || i.selectionFilter(u[d].v) !== false) {
              c.push(u[d].v);
              v[u[d].id] = true;
              t.toolkitInstance.addToSelection(u[d].v);
            }
          }
          i.lassoSelections = c;
          f = c.length;
          if (o.includeEdges) {
            var h = {};
            for (d = 0; d < f; d++) {
              var p = [],
                S = void 0;
              Array.prototype.push.apply(p, c[d].getAllEdges());
              for (var g = 0; g < p.length; g++) {
                S = p[g].getId();
                if (!h[S]) {
                  var y = p[g].source.objectType === n.objectType ? p[g].source.getParent().id : p[g].source.id,
                    m = p[g].target.objectType === n.objectType ? p[g].target.getParent().id : p[g].target.id;
                  if (v[y] && v[m]) {
                    c.push(p[g]);
                  }
                  h[S] = true;
                }
              }
            }
          }
          t.toolkitInstance.addToSelection(c);
          i.onSelect && i.onSelect(c);
        },
        onEnd: function e(o, s) {
          t.setHoverSuspended(false);
          if (t.autoExitSelectMode && t.mode !== r) {
            t.setMode(l);
          }
          t.fire(d);
          i.onEnd && i.onEnd();
        },
        filter: o.filter
      });
      return false;
    }
  }, {
    key: "reset",
    value: function e() {}
  }, {
    key: "activated",
    value: function e() {
      this.lasso.enabled = true;
    }
  }, {
    key: "deactivated",
    value: function e() {
      this.lasso.enabled = false;
    }
  }]);
  return i;
}();
t(u, "type", "lasso");
i.register(u.type, u);
export { u as LassoPlugin };