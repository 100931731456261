import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BeinformedService, DialogService, FileService, FormService, HttpService, LanguageService, NarisBreadcrumbService, SnackbarService, TabService, TableService, TaskgroupService, UserService } from '@core/services';
import { MatDialog } from '@angular/material/dialog';
import { INFO_DIALOG_TOKEN } from '@core/constants';
import { FrequencyService } from '@core/services/frequency.service';
import { BreadcrumbTabsService } from '@core/services/breadcrumb-tab.service';
import { FooterToolbarService } from '@core/services/footer-toolbar.service';
import { TableSettingsService } from '@core/services/table-settings.service';
import { StrategyMapService } from '@core/services/strategy-map.service';
import { ComponentType } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { MatTooltip } from '@angular/material/tooltip';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkDropList, CdkDrag, CdkDragPlaceholder, CdkDragHandle } from '@angular/cdk/drag-drop';
import { NgClass, NgStyle } from '@angular/common';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatFooterCellDef, MatFooterCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatFooterRowDef, MatFooterRow } from '@angular/material/table';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { TableComponent } from '../table/table.component';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { ToolbarItemComponent } from '../toolbar/toolbar-item/toolbar-item.component';
import { ButtonComponent } from '../../elements/button/button.component';
import { FormInputComponent } from '../../../core/form/form-input/form-input.component';
import { IconComponent } from '../../elements/icon/icon.component';
import { SlidetoggleComponent } from '../../elements/slidetoggle/slidetoggle.component';
import { CheckboxComponent } from '../../elements/checkbox/checkbox.component';
import { EmptyStateComponent } from '../empty-state/empty-state.component';

@Component({
  selector: 'app-grid-table',
  templateUrl: './grid-table.component.html',
  styleUrl: './grid-table.component.scss',
  standalone: true,
  imports: [TableComponent, ToolbarComponent, ToolbarItemComponent, ButtonComponent, MatTooltip, MatMenuTrigger, MatMenu, FormsModule, ReactiveFormsModule, FormInputComponent, CdkDropList, CdkDrag, NgClass, CdkDragPlaceholder, IconComponent, CdkDragHandle, SlidetoggleComponent, NgStyle, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, CheckboxComponent, MatCellDef, MatCell, MatMenuItem, MatFooterCellDef, MatFooterCell, EmptyStateComponent, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatFooterRowDef, MatFooterRow, TranslateModule]
})
export class GridTableComponent extends TableComponent {

  @ViewChild('inlineEditWindow') public inlineEditWindow: ElementRef<HTMLDivElement>;

  public editWindow: OverlayRef;

  constructor(
    public router: Router,
    protected readonly tableService: TableService,
    protected readonly httpService: HttpService,
    protected readonly formService: FormService,
    protected readonly snackbar: SnackbarService,
    protected readonly dialogService: DialogService,
    protected readonly beinformedService: BeinformedService,
    protected readonly userService: UserService,
    protected readonly fileService: FileService,
    protected readonly languageService: LanguageService,
    protected readonly taskgroupService: TaskgroupService,
    protected readonly narisBreadcrumb: NarisBreadcrumbService,
    protected readonly dialog: MatDialog,
    @Inject(INFO_DIALOG_TOKEN) protected readonly infoDialogComponent: ComponentType<InfoDialogComponent>,
    protected readonly translateService: TranslateService,
    protected readonly frequencyService: FrequencyService,
    protected readonly breadcrumbTabService: BreadcrumbTabsService,
    protected readonly footerToolbarService: FooterToolbarService,
    protected readonly tableSettingsService: TableSettingsService,
    protected readonly tabs: TabService,
    protected readonly strategyMapService: StrategyMapService,
    private readonly overlayService: Overlay
  ) {
    super(
      router,
      tableService,
      httpService,
      formService,
      snackbar,
      dialogService,
      beinformedService,
      userService,
      fileService,
      languageService,
      taskgroupService,
      narisBreadcrumb,
      dialog,
      translateService,
      frequencyService,
      breadcrumbTabService,
      footerToolbarService,
      tableSettingsService,
      tabs,
      strategyMapService
    );

    this.dataSource.isGridTable = true;
  }
}
