@defer (when !loading) {
  <h3 mat-dialog-title>
    @if (!!suggestions.length) {
      <span>
        <naris-icon icon="join" iconClass="default lightest" />
        {{ 'knowledgebase.relate' | translate }}
      </span>
    }
    <span [ngClass]="{blurred: !!relatedId}">
      <naris-icon icon="edit" iconClass="default lightest" />
      {{ 'knowledgebase.edit' | translate }}
    </span>
    <naris-button
      mat-dialog-close
      color="transparent"
      icon="close"
      class="close"
      id="KnowledgebaseDialogClose"
    />
  </h3>
  <mat-dialog-content>
    @if (!!suggestions.length) {
      <naris-node-tree
        class="tree"
        [sourceData]="suggestions"
        selectKey="_id"
        selectable
        (selection)="relatedId = $event"
      />
      <mat-divider vertical inset />
    }
    <form>
      <div class="form-field" [ngClass]="{disabled: !!relatedId}">
        <h4>{{ 'name' | translate | titlecase }}:</h4>
        <naris-inline-input
          #tooltip="matTooltip"
          class="input"
          [control]="nameControl"
          dynamicWidth
          rounded
          [maxLength]="nameLength"
          [matTooltip]="nameControl.value?.length + ' / ' + nameLength"
          matTooltipPosition="above"
          (inputChange)="tooltip.show()"
          id="KnowledgebaseDialogName"
        />
      </div>
      <div class="info" [ngClass]="{disabled: !!relatedId}">
        <ng-container *ngTemplateOutlet="relatedTitle; context: {$implicit: {label: 'casetype.organization' | translate, icon: 'home', class: 'default darkest'}}" />
        <p><b>{{ 'type' | translate | titlecase }}:</b> {{ clonedData.organizationType }}</p>
        <p><b>{{ 'subtype' | translate | titlecase }}:</b> {{ clonedData.organizationSubtype || ('select.none' | translate) }}</p>
      </div>
      @for (object of (relatedObjects | keyvalue); track $index) {
        <div class="info">
          <ng-container *ngTemplateOutlet="relatedTitle; context: {$implicit: object.value}" />
          <mat-accordion>
            @for (item of object.value.items; track $index) {
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span class="title">{{ item.name }}</span>
                  </mat-panel-title>
                  <naris-button
                    class="remove"
                    size="small"
                    icon="delete"
                    color="danger-outline"
                    [matTooltip]="('knowledgebase.dblclick' | translate) + ' ' + ('knowledgebase.remove_field' | translate)"
                    (clicked)="$event.stopPropagation()"
                    (dblClicked)="removeSubItem(item.caseId, object.key)"
                    id="KnowledgebaseDialogRemoveField"
                  />
                </mat-expansion-panel-header>
                <naris-node-tree [sourceData]="item | strip:['caseId', 'kbId', 'organizationId']:1" />
              </mat-expansion-panel>
            }
          </mat-accordion>
        </div>
      }
      @for (field of (relatedFields | keyvalue); track $index) {
        <div class="info" [ngClass]="{disabled: !!relatedId}">
          <ng-container *ngTemplateOutlet="relatedTitle; context: {$implicit: field.value}" />
          <ul>
            @for (item of field.value.items; track $index) {
              <li>{{ item }}</li>
            }
          </ul>
        </div>
      }
      
      <ng-template #relatedTitle let-field>
        <h4>
          {{ field.label }}:
          <naris-icon [icon]="field.icon" [iconClass]="field.class" />
        </h4>
      </ng-template>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <naris-button
      mat-dialog-close
      icon="close"
      color="danger"
      class="mr-2"
      [matTooltip]="'cancel' | translate"
      placement="left"
      id="KnowledgebaseDialogCancel"
    />
    <naris-button
      [icon]="!relatedId ? 'edit' : 'join'"
      color="success"
      class="ml-2"
      [disabled]="nameControl.invalid"
      [matTooltip]="(!relatedId ? 'knowledgebase.edit' : 'knowledgebase.relate') | translate"
      placement="right"
      (clicked)="onSubmit()"
      id="KnowledgebaseDialogEditRelate"
    />
  </mat-dialog-actions>
} @placeholder {
  <naris-progress-spinner class="loader" [strokeWidth]="2" [diameter]="24" />
}
