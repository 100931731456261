import { Component, Input, OnChanges, Output, EventEmitter, SimpleChanges, OnDestroy, ViewChildren, ElementRef, QueryList, HostListener, forwardRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { BeinformedService, FormService } from '@core/services';
import { FileService } from '@core/services/file.service';
import { COLOR_REG } from '@core/constants/core-constants';
import { truncate } from '@core/helpers';
import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { RichTextPipe } from '@shared/pipes/rich-text.pipe';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { ToolbarItemComponent } from '../toolbar/toolbar-item/toolbar-item.component';
import { ButtonComponent } from '../../elements/button/button.component';
import { TaskgroupComponent } from '../taskgroup/taskgroup.component';
import { TabPanelComponent } from '../tab-panel/tab-panel.component';
import { IconComponent } from '../../elements/icon/icon.component';
import { LoaderComponent } from '../loader/loader.component';
import type { IListModel, ITaskGroup, ICombinedResponse, INarisDrawer, IAttribute, IAction, ILayoutTab, IResponseContent, IContributionsResponseContent, ICaseViewPanel } from '@core/models';

@Component({
  selector: 'naris-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  standalone: true,
  imports: [ToolbarComponent, ToolbarItemComponent, ButtonComponent, TaskgroupComponent, NgClass, NgStyle, MatTooltip, NgTemplateOutlet, forwardRef(() => TabPanelComponent), IconComponent, LoaderComponent, TranslateModule, RichTextPipe]
})
export class ListComponent implements OnChanges, OnDestroy {

  /**
   * The object with title, data and url
   */
  @Input()
  public data: IListModel;

  @Input()
  public dataUrl: string;

  @Input() public drawer: INarisDrawer;
  @Input() public hasChanged: boolean;
  @Output() public readonly closed = new EventEmitter<void>();
  @Output() public readonly updated = new EventEmitter<boolean>();
  @Output() public readonly title = new EventEmitter<string>();

  /**
   * Text on the button preceded by 'Show more '
   */
  @Input()
  public pluralText = 'details';

  @Input()
  public showBorder = false;

  @Input()
  public stacked = false;

  @Input()
  public exclude: string[] = [];

  @Input()
  public showNoDataAvailable = true;

  @Input()
  public isEmbedded: boolean;

  public error: boolean;
  public loading = true;
  public refreshing = false;
  public colorReg = COLOR_REG;
  public truncateLength = 35;

  public customTabs: ILayoutTab[] = [];
  private readonly subs: Subscription[] = [];
  private attributes: IAttribute[] | undefined;

  public isChecked = false;
  public listTitle: string;

  public expandText = false;

  @ViewChildren('valueCol', { read: ElementRef }) public valueColumns: QueryList<ElementRef>;


  @HostListener('window:resize')
  private onResize() {
    setTimeout(() => {
      this.setWidths();
    });
  }

  constructor(
    private readonly beinformedService: BeinformedService,
    private readonly fileService: FileService,
    private readonly formService: FormService
  ) { }

  public ngOnChanges(changes: SimpleChanges) {
    const refresh =
      !changes.dataUrl?.firstChange &&
      changes.dataUrl?.currentValue !== changes.dataUrl?.previousValue ||
      !changes.exclude?.firstChange &&
      changes.exclude?.currentValue.join('') !== changes.exclude?.previousValue.join('')
      ;
    if (this.dataUrl) this.fetchList(this.dataUrl, refresh);
    else this.loading = false;
  }

  public update(close?: any, listAction = false) {
    if (this.drawer) {
      if (!this.drawer.hasChanged) this.drawer.hasChanged = true;
      if (close) {
        this.loading = true;
        setTimeout(() => this.close(), 400);
      } else if (listAction) this.fetchList(this.dataUrl);
    } else this.fetchList(this.dataUrl);
  }

  private fetchList(endpoint: string, refresh = false) {
    this.error = false;
    refresh ? this.refreshing = true : this.loading = true;
    this.beinformedService.fetchResponseWithContributions(endpoint, true, !!this.drawer).subscribe({
      next: res => this.transformResponse(res),
      error: () => this.error = true,
      complete: () => refresh ? this.refreshing = false : this.loading = false
    });
  }

  public close(): void {
    this.closed.emit();
  }

  public isArray(item: any): boolean {
    return Array.isArray(item);
  }

  private transformResponse({ data, contributions }: ICombinedResponse) {
    const structureType = data.StructureType;
    this.listTitle = data.Name;
    const result = data.hasOwnProperty('_embedded') ? Object.values(data._embedded?.results?.[0])[0] as IResponseContent : data;
    const contributionResult = data.hasOwnProperty('_embedded') ? Object.values(contributions.results)[0] as IContributionsResponseContent : contributions;
    const title = contributions.label;
    this.title.emit(title);
    const panels = this.beinformedService.extractPanels({ data: result, contributions: contributionResult });
    const uniquePanels = [...new Map(panels?.map((item: ICaseViewPanel) => [item.name, item])).values()] as ICaseViewPanel[];
    const mergedPanels = this.mergePanels(uniquePanels.filter(a => !this.exclude.includes(a.name)));
    const newPanelarray: ICaseViewPanel[] = []; // sometimes the order of the panels gets messed up at this point. therefore check order in panels and set them right
    uniquePanels?.forEach(panel => {
      const foundPanel = mergedPanels?.find(mergedPanel => mergedPanel.name === panel.name ||
        panel.name.startsWith('Control') && mergedPanel.name === 'Control' ||
        panel.name.startsWith('Risk') && mergedPanel.name === 'Risk');
      if (!!foundPanel && newPanelarray.findIndex((checkPanel: ICaseViewPanel) => checkPanel.name === foundPanel.name) === -1) {
        foundPanel.label = panel.label || foundPanel?.label;
        newPanelarray.push(foundPanel);
      }
    });
    this.attributes = this.beinformedService.extractAttributes({ data: result, contributions: contributionResult })?.filter(a => !this.exclude.includes(a.name));
    const values = this.attributes?.filter((a: any) => !a.layouthint?.includes('title') && a.name !== 'StructureType').map(({ valueLabel: value, name, label, layouthint }: IAttribute) => {
      const type = Array.isArray(value) ? 'array' :
        ['string', 'boolean'].includes(typeof value) ? typeof value :
          !value ? 'empty' : '';
      const mappedVal = data.dynamicschema?.[name!]?.find(({ code }) => code === value)?.label || value;
      const returnVal = mappedVal;
      return { key: label, value: returnVal, type, layouthint: layouthint || [] };
    });
    const label = this.attributes?.find((a: any) => a.layouthint?.includes('title'))?.valueLabel || title;
    const actions = data.actions?.map((action: any) => {
      const actionConfig = contributions.actions.find((a: any) => a.name === action.name);
      return { ...action, ...actionConfig };
    }).filter(a => !this.exclude.includes(a.name));
    actions?.forEach(action => {
      if (action.layouthint?.includes('hide-on-list')) {
        const hintIndex = action.layouthint.indexOf('hide-on-list');
        if (hintIndex !== -1) {
          action.layouthint[hintIndex] = 'hide';
        }
      }
    });
    // Transform actions to a valid taskgroup
    const taskgroup = { actions } as ITaskGroup;
    this.data = { label, title, values, taskgroup, panels: newPanelarray, structureType } as IListModel;

    setTimeout(() => {
      this.setWidths();
    });
  }

  // wanneer de data is geladen en als er eenr esize plaatsvindt, dan zetten we de max-size op 0.
  // Dit is niet de mooiste oplossing, maar lijkt voor nu wel de enige om een tekst dynamisch af te kappen en ellipsis te tonen.
  // Wanneer dit in CSS gedaan wordt, wordt de ellipsis niet getoond, vandaar dat dit programmatisch gebeurd.
  private setWidths(): void {
    this.valueColumns.forEach(column => {
      column.nativeElement.style.maxWidth = '0';
    });
  }

  public mergePanels(panels: ICaseViewPanel[] | undefined) {
    const controlPanels = panels?.filter(panel => panel.name.toLowerCase() !== 'controls' && panel.name.toLowerCase().startsWith('control'));
    const riskPanels = panels?.filter(panel => panel.name.toLowerCase() !== 'risks' && panel.name.toLowerCase().startsWith('risk'));
    const returnValue = panels?.filter(panel => panel.name.toLowerCase() === 'controls' || !panel.name.toLowerCase().startsWith('control'))
      .filter(panel => panel.name.toLowerCase() === 'risks' || !panel.name.toLowerCase().startsWith('risk'));
    if (!!controlPanels?.length) {
      const controlIndex = panels?.findIndex(panel => panel.name === controlPanels[0].name);
      const control = { name: 'Control', label: 'casetype.control', panels: controlPanels };
      returnValue?.splice(controlIndex!, 0, control as any);
    }
    if (!!riskPanels?.length) {
      const riskIndex = panels?.findIndex(panel => panel.name === riskPanels[0].name);
      const risk = { name: 'Risk', label: 'casetype.risk', panels: riskPanels };
      returnValue?.splice(riskIndex!, 0, risk as any);
    }
    return returnValue;
  }

  public getActionIcon(action: IAction) {
    return action.name?.includes('update') ? 'edit' : 'help-outline';
  }

  public executeAction(action: IAction) {
    if (action.name?.includes('delete')) void this.beinformedService.handleAction(action, this.reload, this.reload, false, false);
    else if (action.name?.includes('download')) this.downloadFile(action);
    else this.formService.open(action.href!).subscribe(() => this.reload());
  }

  private downloadFile(action: IAction) {
    this.subs.push(this.fileService.downloading.subscribe(dl => action.name = dl ? 'spinner' : 'download-document'));
    this.fileService.downloadFile(action.href);
  }

  public isDrawer() {
    return this.formService.drawers.length > 0;
  }

  public reload = () => {
    this.updated.emit(true);
  };

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public truncateLabel(value: string): string {
    return truncate(value, this.truncateLength);
  }

  public isTruncated(el: HTMLElement): boolean {
    return el.offsetWidth < el.scrollWidth;
  }

  get structureType(): string | undefined {
    if (!!this.data.structureType) {
      const foundStructureType = this.attributes?.find(item => item.name === 'StructureType');
      if (!!foundStructureType) {
        return foundStructureType.valueLabel as string;
      }
    }
  }
}
