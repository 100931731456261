import { Injectable } from '@angular/core';
import { FilterChip } from '@core/classes';
import { IAction, IDataOptions, IRootColumn, ITableConfig, ITableConfigSettings, ITaskGroup } from '@core/models';
import { Observable } from 'rxjs';
import { INarisDynamicDashboard } from '@core/models/dynamic-dashboard.model';
import { BeinformedService } from './beinformed.service';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class TableSettingsService {

  constructor(
    private readonly beinformedService: BeinformedService,
    private readonly snackbar: SnackbarService
  ) {}

  public updateUrl: string | undefined;
  public readUrl: string | undefined;

  private baseConfig: any[];
  private currentConfig: any[];

  public saveColumnConfiguration(tableUrl: string, tableConfiguration: ITableConfig, columns: IRootColumn[]): ITableConfig {
    if (!tableConfiguration.settings) tableConfiguration.settings = {} as ITableConfigSettings;
    tableConfiguration.settings.columns = columns;
    const foundTableConfig = this.currentConfig?.find(config => config.tableUrl === tableUrl);
    if (!!foundTableConfig)
      foundTableConfig.settings = tableConfiguration.settings;
    else 
      this.currentConfig?.push({...tableConfiguration});
    return tableConfiguration;
  }

  public saveOptionsConfiguration(tableUrl: string, tableConfiguration: ITableConfig, options: IDataOptions, activeFilters: FilterChip[]): ITableConfig {
    const { page, parentTable, filter, ...rest } = options;    
    if (!tableConfiguration.settings) tableConfiguration.settings = {} as ITableConfigSettings;
    tableConfiguration.settings.options = rest;
    tableConfiguration.settings.filter = activeFilters;
    const foundTableConfig = this.currentConfig?.find(config => config.tableUrl === tableUrl);
    if (!!foundTableConfig)
      foundTableConfig.settings = tableConfiguration.settings;
    else 
      this.currentConfig?.push({...tableConfiguration});
    return tableConfiguration;
  }

  public hasUpdates(tableUrl: string): boolean {
    const baseConfig = this.baseConfig?.find(c => c.tableUrl === tableUrl);
    const currentConfig = this.currentConfig?.find(c => c.tableUrl === tableUrl);
    return JSON.stringify(baseConfig?.settings.columns) !== JSON.stringify(currentConfig?.settings.columns) || 
      JSON.stringify(baseConfig?.settings.filter) !== JSON.stringify(currentConfig?.settings.filter) || 
      JSON.stringify(baseConfig?.settings.options) !== JSON.stringify(currentConfig?.settings.options);
  }

  public getConfig(): any[] {
    return this.currentConfig;
  }

  public setSettingsObject(settingsObject: ITaskGroup | null) {
    if (!!settingsObject?.href)
      this.beinformedService.fetchResponseWithContributions(settingsObject.href).subscribe(res => {
        this.setUrls(res.data.actions);
      });
  }

  public setUrls(actions: IAction[] | undefined) {
    actions?.forEach(action => {
      switch (action.name!.toLowerCase()) {
        case 'read': this.readUrl = action.href;
        case 'update': this.updateUrl = action.href;
      }
    });
  }

  public resetUrls() {
    this.updateUrl = undefined;
    this.readUrl = undefined;
  }

  public getDashboard(): Observable<INarisDynamicDashboard[]> {
    return new Observable(obs => {
      if (!!this.readUrl) {
        this.beinformedService.fetchForm(this.readUrl, true).subscribe({
          next: res => {
            const json = res.data.formresponse?.success?.data.Result.JSON;
            const parsedJson = JSON.parse(json);
            obs.next(parsedJson);
            obs.complete();
          },
          error: e => {
            this.snackbar.open({ text: e, type: 'error' });
            obs.next([]);
            obs.complete();
          }
        });
      }
    });
  }

  public getTableOptions(dataUrl: string): Observable<any> {
    return new Observable(observer => {
      if (!!this.readUrl)
        this.beinformedService.fetchForm(this.readUrl, true).subscribe({next: res => {
          const json = res.data.formresponse?.success?.data.Result.JSON;
          const parsedJson = JSON.parse(json);
          this.baseConfig = !!parsedJson ? JSON.parse(JSON.stringify(parsedJson)) : this.addEmpty(dataUrl, this.baseConfig);
          this.currentConfig = !!parsedJson ? parsedJson : [];
          const foundSettings = parsedJson?.find((c: Record<string, any>) => c.tableUrl === dataUrl);
          if (!!foundSettings) {            
            observer.next(foundSettings);
            observer.complete();
          } else {
            observer.next(null);
            observer.complete();
          }
        },
        error: e => {
          this.snackbar.open({ text: e, type: 'error' });
          observer.next(null);
          observer.complete();
        }});
      else {
        observer.next(null);
        observer.complete();
      }
    });
  }

  private addEmpty(dataUrl: string, baseConfig: any[]): any[] {
    if (!Array.isArray(baseConfig))
      baseConfig = [];
    baseConfig.push({tableUrl: dataUrl, settings: {filter: [], options: {pagesize: 0, sort: ''}}});
    return baseConfig;
  }

  public saveTableOptions() {
    if (!!this.updateUrl) {
      this.beinformedService.fetchForm(this.updateUrl).subscribe(tokenRes => {
        const tokens = tokenRes.data.error?.formresponse.tokens;
        const config = JSON.stringify(this.currentConfig);
        const postableObject = {tokens, JSON: config};
        this.beinformedService.fetchForm(this.updateUrl!, true, postableObject as any).subscribe({
          next: res => {
            if (!!res.data.formresponse?.success) this.baseConfig = JSON.parse(JSON.stringify(this.currentConfig));
          },
          error: e => {
            this.snackbar.open({ text: e, type: 'error' });
          }
        });
      });
    }
  }
}
