import { createClass as e, classCallCheck as n, defineProperty as t, toConsumableArray as o } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { UIGroup as i } from "./group.js";
import { EVENT_INTERNAL_CONNECTION as r, EVENT_INTERNAL_CONNECTION_DETACHED as a, EVENT_CONNECTION_MOVED as s, EVENT_GROUP_ADDED as c, EVENT_GROUP_REMOVED as u, EVENT_GROUP_COLLAPSE as l, EVENT_GROUP_EXPAND as p, EVENT_GROUP_MEMBER_ADDED as f } from "../event-constants.js";
import { CLASS_GROUP_EXPANDED as d, CLASS_GROUP_COLLAPSED as v } from "../css-constants.js";
import { SELECTOR_MANAGED_ELEMENT as h } from "../constants.js";
import { WILDCARD as g } from "../../common/index.js";
import { suggest as G, removeWithFunction as m, isString as _, forEach as y } from "../../util/util.js";
import { Endpoints as C } from "../endpoint/endpoint.js";
import { Connections as P } from "../connector/connections.js";
var M = function () {
  function M(e) {
    var o = this;
    n(this, M);
    this.instance = e;
    t(this, "groupMap", {});
    t(this, "_connectionSourceMap", {});
    t(this, "_connectionTargetMap", {});
    e.bind(r, function (e) {
      var n = o.getGroupFor(e.source);
      var t = o.getGroupFor(e.target);
      if (n != null && t != null && n === t) {
        o._connectionSourceMap[e.connection.id] = n;
        o._connectionTargetMap[e.connection.id] = n;
        G(n.connections.internal, e.connection);
      } else {
        if (n != null) {
          if (e.target._jsPlumbGroup === n) {
            G(n.connections.internal, e.connection);
          } else {
            G(n.connections.source, e.connection);
          }
          o._connectionSourceMap[e.connection.id] = n;
        }
        if (t != null) {
          if (e.source._jsPlumbGroup === t) {
            G(t.connections.internal, e.connection);
          } else {
            G(t.connections.target, e.connection);
          }
          o._connectionTargetMap[e.connection.id] = t;
        }
      }
    });
    e.bind(a, function (e) {
      o._cleanupDetachedConnection(e.connection);
    });
    e.bind(s, function (e) {
      var n = e.originalEndpoint.element,
        t = o.getGroupFor(n),
        i = e.connection.endpoints[e.index],
        r = i.element,
        a = o.getGroupFor(r),
        s = e.index === 0 ? o._connectionSourceMap : o._connectionTargetMap,
        c = e.index === 0 ? o._connectionTargetMap : o._connectionSourceMap;
      if (a != null) {
        s[e.connection.id] = a;
        if (e.connection.source === e.connection.target) {
          c[e.connection.id] = a;
        }
      } else {
        delete s[e.connection.id];
        if (e.connection.source === e.connection.target) {
          delete c[e.connection.id];
        }
      }
      if (t != null) {
        o._updateConnectionsForGroup(t);
      }
      if (a != null) {
        o._updateConnectionsForGroup(a);
      }
    });
  }
  e(M, [{
    key: "_cleanupDetachedConnection",
    value: function e(n) {
      n.proxies.length = 0;
      var t = this._connectionSourceMap[n.id],
        o;
      if (t != null) {
        o = function e(t) {
          return t.id === n.id;
        };
        m(t.connections.source, o);
        m(t.connections.target, o);
        m(t.connections.internal, o);
        delete this._connectionSourceMap[n.id];
      }
      t = this._connectionTargetMap[n.id];
      if (t != null) {
        o = function e(t) {
          return t.id === n.id;
        };
        m(t.connections.source, o);
        m(t.connections.target, o);
        m(t.connections.internal, o);
        delete this._connectionTargetMap[n.id];
      }
    }
  }, {
    key: "addGroup",
    value: function e(n, t) {
      var o = t.el;
      if (this.groupMap[n.id] != null) {
        throw new Error("cannot create Group [" + n.id + "]; a Group with that ID exists");
      }
      if (o._isJsPlumbGroup != null) {
        throw new Error("cannot create Group [" + n.id + "]; the given element is already a Group");
      }
      var r = new i(this.instance, n, t.el, t);
      this.groupMap[r.id] = r;
      if (t.collapsed) {
        this.collapseGroup(r);
      }
      this.instance.manage(r.el, n.id);
      this.instance.addClass(r.el, d);
      r.manager = this;
      this._updateConnectionsForGroup(r);
      this.instance.fire(c, {
        group: r
      });
      return r;
    }
  }, {
    key: "getGroup",
    value: function e(n) {
      var t = n;
      if (_(n)) {
        t = this.groupMap[n];
        if (t == null) {
          throw new Error("No such group [" + n + "]");
        }
      }
      return t;
    }
  }, {
    key: "getGroupFor",
    value: function e(n) {
      var t = n;
      var o = this.instance.getContainer();
      var i = false,
        r = null;
      while (!i) {
        if (t == null || t === o) {
          i = true;
        } else {
          if (t._jsPlumbParentGroup) {
            r = t._jsPlumbParentGroup;
            i = true;
          } else {
            t = t.parentNode;
          }
        }
      }
      return r;
    }
  }, {
    key: "getGroups",
    value: function e() {
      var n = [];
      for (var t in this.groupMap) {
        n.push(this.groupMap[t]);
      }
      return n;
    }
  }, {
    key: "removeGroup",
    value: function e(n, t, o, i) {
      var r = this;
      var a = this.getGroup(n);
      this.expandGroup(a, true);
      var s = {};
      y(a.children, function (e) {
        var n = r.instance.getManagedElements()[r.instance.getId(e.el)];
        if (n) {
          delete n.group;
        }
      });
      if (t) {
        y(a.getGroups(), function (e) {
          return r.removeGroup(e, t, o);
        });
        a.removeAll(o, i);
      } else {
        if (a.uiGroup) {
          y(a.children, function (e) {
            return a.uiGroup.add(e.el, e.id);
          });
        }
        s = a.orphanAll();
      }
      if (a.uiGroup) {
        a.uiGroup.removeGroup(a);
      }
      this.instance.unmanage(a.el, true);
      delete this.groupMap[a.id];
      this.instance.fire(u, {
        group: a
      });
      return s;
    }
  }, {
    key: "removeAllGroups",
    value: function e(n, t, o) {
      for (var i in this.groupMap) {
        this.removeGroup(this.groupMap[i], n, t, o);
      }
    }
  }, {
    key: "forEach",
    value: function e(n) {
      for (var t in this.groupMap) {
        n(this.groupMap[t]);
      }
    }
  }, {
    key: "orphan",
    value: function e(n, t) {
      var o = n;
      if (o._jsPlumbParentGroup) {
        var i = o._jsPlumbParentGroup;
        var r = this.instance.getId(o);
        var a = this.instance.getOffset(n);
        if (t !== true && i.uiGroup) {
          this.instance._appendElementToGroup(i.uiGroup, n);
        } else {
          this.instance._appendElementToContainer(n);
        }
        this.instance.setPosition(n, a);
        delete o._jsPlumbParentGroup;
        return {
          id: r,
          pos: a
        };
      }
    }
  }, {
    key: "_updateConnectionsForGroup",
    value: function e(n) {
      var t = this;
      n.connections.source.length = 0;
      n.connections.target.length = 0;
      n.connections.internal.length = 0;
      var o = n.children.slice().map(function (e) {
        return e.el;
      });
      var i = [];
      y(o, function (e) {
        Array.prototype.push.apply(i, t.instance.getSelector(e, h));
      });
      Array.prototype.push.apply(o, i);
      if (o.length > 0) {
        var r = this.instance.getConnections({
          source: o,
          scope: g
        }, true);
        var a = this.instance.getConnections({
          target: o,
          scope: g
        }, true);
        var s = {};
        var c, u;
        var l = function e(o) {
          for (var i = 0; i < o.length; i++) {
            if (s[o[i].id]) {
              continue;
            }
            s[o[i].id] = true;
            c = t.getGroupFor(o[i].source);
            u = t.getGroupFor(o[i].target);
            if (o[i].source === n.el && u === n || o[i].target === n.el && c === n) {
              n.connections.internal.push(o[i]);
            } else if (c === n) {
              if (u !== n) {
                n.connections.source.push(o[i]);
              } else {
                n.connections.internal.push(o[i]);
              }
              t._connectionSourceMap[o[i].id] = n;
            } else if (u === n) {
              n.connections.target.push(o[i]);
              t._connectionTargetMap[o[i].id] = n;
            }
          }
        };
        l(r);
        l(a);
      }
    }
  }, {
    key: "_collapseConnection",
    value: function e(n, t, o) {
      var i = n.endpoints[t === 0 ? 1 : 0].element;
      if (i._jsPlumbParentGroup && !i._jsPlumbParentGroup.proxied && i._jsPlumbParentGroup.collapsed) {
        return false;
      }
      var r = n.endpoints[0].element,
        a = r._jsPlumbParentGroup,
        s = a != null ? a.collapseParent || a : null,
        c = n.endpoints[1].element,
        u = c._jsPlumbParentGroup,
        l = u != null ? u.collapseParent || u : null;
      if (s == null || l == null || s.id !== l.id) {
        var p = o.el;
        this.instance.getId(p);
        this.instance.proxyConnection(n, t, p, function (e, n) {
          return o.getEndpoint(e, n);
        }, function (e, n) {
          return o.getAnchor(e, n);
        });
        return true;
      } else {
        return false;
      }
    }
  }, {
    key: "_expandConnection",
    value: function e(n, t, o) {
      this.instance.unproxyConnection(n, t);
    }
  }, {
    key: "isElementDescendant",
    value: function e(n, t) {
      var o = this.instance.getContainer();
      var i = false;
      while (!i) {
        if (n == null || n === o) {
          return false;
        } else {
          if (n === t) {
            return true;
          } else {
            n = n.parentNode;
          }
        }
      }
    }
  }, {
    key: "collapseGroup",
    value: function e(n) {
      var t = this;
      var o = this.getGroup(n);
      if (o == null || o.collapsed) {
        return;
      }
      var i = o.el;
      if (o.collapseParent == null) {
        this.instance.setGroupVisible(o, false);
        o.collapsed = true;
        this.instance.removeClass(i, d);
        this.instance.addClass(i, v);
        if (o.proxied) {
          var r = new Set();
          var a = function e(n, i) {
            for (var a = 0; a < n.length; a++) {
              var s = n[a];
              if (t._collapseConnection(s, i, o) === true) {
                r.add(s.id);
              }
            }
          };
          a(o.connections.source, 0);
          a(o.connections.target, 1);
          y(o.getGroups(), function (e) {
            t.cascadeCollapse(o, e, r);
          });
        }
        this.instance.revalidate(i);
        this.repaintGroup(o);
        this.instance.fire(l, {
          group: o
        });
      } else {
        o.collapsed = true;
        this.instance.removeClass(i, d);
        this.instance.addClass(i, v);
      }
    }
  }, {
    key: "cascadeCollapse",
    value: function e(n, t, o) {
      var i = this;
      if (n.proxied) {
        var r = function e(t, r) {
          for (var a = 0; a < t.length; a++) {
            var s = t[a];
            if (!o.has(s.id)) {
              if (i._collapseConnection(s, r, n) === true) {
                o.add(s.id);
              }
            }
          }
        };
        r(t.connections.source, 0);
        r(t.connections.target, 1);
      }
      y(t.getGroups(), function (e) {
        i.cascadeCollapse(n, e, o);
      });
    }
  }, {
    key: "expandGroup",
    value: function e(n, t) {
      var o = this;
      var i = this.getGroup(n);
      if (i == null) {
        return;
      }
      var r = i.el;
      if (i.collapseParent == null) {
        this.instance.setGroupVisible(i, true);
        i.collapsed = false;
        this.instance.addClass(r, d);
        this.instance.removeClass(r, v);
        if (i.proxied) {
          var a = function e(n, t) {
            for (var r = 0; r < n.length; r++) {
              var a = n[r];
              o._expandConnection(a, t, i);
            }
          };
          a(i.connections.source, 0);
          a(i.connections.target, 1);
          var s = function e(n, t) {
            if (t || n.collapsed) {
              var i = function e(t, i) {
                for (var r = 0; r < t.length; r++) {
                  var a = t[r];
                  o._collapseConnection(a, i, n.collapseParent || n);
                }
              };
              i(n.connections.source, 0);
              i(n.connections.target, 1);
              y(n.connections.internal, function (e) {
                return P.setVisible(e, false);
              });
              y(n.getGroups(), function (n) {
                return e(n, true);
              });
            } else {
              o.expandGroup(n, true);
            }
          };
          y(i.getGroups(), s);
        }
        this.instance.revalidate(r);
        this.repaintGroup(i);
        if (!t) {
          this.instance.fire(p, {
            group: i
          });
        }
      } else {
        i.collapsed = false;
        this.instance.addClass(r, d);
        this.instance.removeClass(r, v);
      }
    }
  }, {
    key: "toggleGroup",
    value: function e(n) {
      n = this.getGroup(n);
      if (n != null) {
        if (n.collapsed) {
          this.expandGroup(n);
        } else {
          this.collapseGroup(n);
        }
      }
    }
  }, {
    key: "repaintGroup",
    value: function e(n) {
      var t = this.getGroup(n);
      var o = t.children;
      for (var i = 0; i < o.length; i++) {
        this.instance.revalidate(o[i].el);
      }
    }
  }, {
    key: "addToGroup",
    value: function e(n, t) {
      var o = this;
      var i = this.getGroup(n);
      if (i) {
        var r = i.el;
        var a = function e(n) {
          var a = n;
          var s = a._isJsPlumbGroup != null,
            c = a._jsPlumbGroup;
          var u = a._jsPlumbParentGroup;
          if (u !== i) {
            var l = o.instance.getId(n);
            var p = o.instance.manage(n);
            var d = o.instance.getOffset(n);
            var v = i.collapsed ? o.instance.getOffsetRelativeToRoot(r) : o.instance.getOffset(i.contentArea);
            p.group = i.id;
            if (u != null) {
              u.remove(n, false, t, false, i);
              o._updateConnectionsForGroup(u);
            }
            if (s) {
              i.addGroup(c);
            } else {
              i.add(n, l, t);
            }
            var h = function e(n, t) {
              var r = t === 0 ? 1 : 0;
              n.each(function (e) {
                P.setVisible(e, false);
                if (e.endpoints[r].element._jsPlumbGroup === i) {
                  C.setVisible(e.endpoints[r], false);
                  o._expandConnection(e, r, i);
                } else {
                  C.setVisible(e.endpoints[t], false);
                  o._collapseConnection(e, t, i);
                }
              });
            };
            if (i.collapsed) {
              h(o.instance.select({
                source: n
              }), 0);
              h(o.instance.select({
                target: n
              }), 1);
            }
            var g = {
              x: d.x - v.x,
              y: d.y - v.y
            };
            o._updateConnectionsForGroup(i);
            o.instance.revalidate(n);
            if (!t) {
              var G = {
                group: i,
                el: n,
                pos: g
              };
              if (u) {
                G.sourceGroup = u;
              }
              o.instance.fire(f, G);
            }
          }
        };
        for (var s = arguments.length, c = new Array(s > 2 ? s - 2 : 0), u = 2; u < s; u++) {
          c[u - 2] = arguments[u];
        }
        y(c, a);
      }
    }
  }, {
    key: "removeFromGroup",
    value: function e(n, t) {
      var o = this;
      var i = this.getGroup(n);
      if (i) {
        var r = function e(n) {
          if (i.collapsed) {
            var r = function e(t, r) {
              for (var a = 0; a < t.length; a++) {
                var s = t[a];
                if (s.proxies) {
                  for (var c = 0; c < s.proxies.length; c++) {
                    if (s.proxies[c] != null) {
                      var u = s.proxies[c].originalEp.element;
                      if (u === n || o.isElementDescendant(u, n)) {
                        o._expandConnection(s, r, i);
                      }
                    }
                  }
                }
              }
            };
            r(i.connections.source.slice(), 0);
            r(i.connections.target.slice(), 1);
          }
          i.remove(n, null, t);
          var a = o.instance.getManagedElements()[o.instance.getId(n)];
          if (a) {
            delete a.group;
          }
        };
        for (var a = arguments.length, s = new Array(a > 2 ? a - 2 : 0), c = 2; c < a; c++) {
          s[c - 2] = arguments[c];
        }
        y(s, r);
      }
    }
  }, {
    key: "getAncestors",
    value: function e(n) {
      var t = [];
      var o = n.uiGroup;
      while (o != null) {
        t.push(o);
        o = o.uiGroup;
      }
      return t;
    }
  }, {
    key: "isAncestor",
    value: function e(n, t) {
      if (n == null || t == null) {
        return false;
      }
      return this.getAncestors(n).indexOf(t) !== -1;
    }
  }, {
    key: "getDescendants",
    value: function e(n) {
      var t = [];
      var i = function e(n) {
        var i = n.getGroups();
        t.push.apply(t, o(i));
        y(i, e);
      };
      i(n);
      return t;
    }
  }, {
    key: "isDescendant",
    value: function e(n, t) {
      if (n == null || t == null) {
        return false;
      }
      return this.getDescendants(t).indexOf(n) !== -1;
    }
  }, {
    key: "reset",
    value: function e() {
      this._connectionSourceMap = {};
      this._connectionTargetMap = {};
      this.groupMap = {};
    }
  }]);
  return M;
}();
export { M as GroupManager };