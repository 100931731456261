import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { IClickEvent, INarisOption } from '@core/models';
import { TABLE_DEF } from '@core/constants';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { ToArrayPipe } from '@shared/pipes/to-array.pipe';
import { ButtonComponent } from '../../elements/button/button.component';
import { MenuComponent } from '../menu/menu.component';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';

@Component({
  selector: 'naris-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  standalone: true,
  imports: [NgClass, MatTooltip, ButtonComponent, MatMenuTrigger, MatMenu, MenuComponent, MenuItemComponent, ToArrayPipe, TranslateModule]
})
export class PaginatorComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  public numOfItems: string | null = '0';

  @Input()
  public total: number | null = 0;

  @Input()
  public numOfPages: number | null = 1;

  @Input()
  public currentPage: number | null = 1;

  @Input()
  public pagesize?: number;

  @Input()
  public pagesizeOptions?: INarisOption[] = TABLE_DEF.PAGESIZE_OPTIONS;

  @Input()
  public currentPageSlice: number[] | null = TABLE_DEF.PAGE_SLICE;

  @Input()
  public pageSlices: number[][] | null = [TABLE_DEF.PAGE_SLICE];

  @Input()
  public refreshActive = false;

  @Output()
  public readonly buttonClicked = new EventEmitter<IClickEvent>();

  @Output()
  public readonly toNextPage = new EventEmitter<void>();

  @Output()
  public readonly toPreviousPage = new EventEmitter<void>();

  @Output()
  public readonly toFirstPage = new EventEmitter<void>();

  @Output()
  public readonly toLastPage = new EventEmitter<void>();

  @Output()
  public readonly toPage = new EventEmitter<number>();

  @Output()
  public readonly toNextSlice = new EventEmitter<void>();

  @Output()
  public readonly toPreviousSlice = new EventEmitter<void>();

  @Output()
  public readonly pagesizeChange = new EventEmitter<number>();

  public pluralText = 'paginator.items';

  public pagesizeSelect = new FormControl<number | null>(null);

  public showingParams: Record<string, any>;

  private readonly subs: Subscription[] = [];

  constructor(
    private readonly translate: TranslateService
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    const itemsChanged = changes.numOfItems?.currentValue !== changes.numOfItems?.previousValue;
    const totalChanged = changes.total?.currentValue !== changes.total?.previousValue;
    if (itemsChanged || totalChanged) {
      this.showingParams = {numOfItems: this.numOfItems, total: this.total, pluralText: this.translate.instant(this.pluralText)};
    }
  }

  public ngOnInit() {
    this.showingParams = {numOfItems: this.numOfItems, total: this.total, pluralText: this.translate.instant(this.pluralText)};
    this.subs.push(this.pagesizeSelect.valueChanges.pipe(
      distinctUntilChanged()
    ).subscribe(val => {
      this.pagesize = val!;
      this.pagesizeChange.emit(val!);
    }));
  }

  public ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public click(event: string) {
    this.buttonClicked.emit({id: 'items', event});
  }

  get isLastSlice(): boolean | undefined {
    return this.currentPageSlice?.includes(this.numOfPages!); 
  }
}
