import { createClass as t, classCallCheck as e, defineProperty as i } from "../_virtual/_rollupPluginBabelHelpers.js";
var r = "data-";
var a = function () {
  function i(t) {
    e(this, i);
    this.el = t;
  }
  t(i, [{
    key: "value",
    value: function t(e, i) {
      var r = this.el.getAttribute(e);
      return r == null ? i : r;
    }
  }, {
    key: "findDataValues",
    value: function t(e) {
      for (var i = 0; i < this.el.attributes.length; i++) {
        var a = this.el.attributes[i];
        if (a.name.indexOf(r) === 0) {
          e[a.value] = a.name.split("-")[1];
        }
      }
    }
  }]);
  return i;
}();
var n = function () {
  function a(t, r) {
    e(this, a);
    this.obj = t;
    i(this, "stripPrefix", void 0);
    this.stripPrefix = r || "";
  }
  t(a, [{
    key: "value",
    value: function t(e, i) {
      var r = this.obj[e.replace(this.stripPrefix, "").replace(/(\-\w)/g, function (t) {
        return t[1].toUpperCase();
      })];
      return r == null ? i : r;
    }
  }, {
    key: "findDataValues",
    value: function t(e) {
      for (var i in this.obj) {
        if (i.indexOf(r) === 0) {
          e[this.obj[i]] = i.split("-")[1];
        }
      }
    }
  }]);
  return a;
}();
export { a as AttributeExtractor, n as ObjectValueExtractor };