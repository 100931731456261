import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'naris-answer-toggle',
  templateUrl: './answer-toggle.component.html',
  styleUrls: ['./answer-toggle.component.scss'],
  standalone: true,
  imports: [IconComponent, TranslateModule]
})
export class AnswerToggleComponent implements OnInit {

  @Input() public control: FormControl;
  @Input() public id: string;
  @Input() public input: any;
  @Input() public isInitialApplicability = false;

  @Input() public options: Record<string, any>[] = [];

  public ngOnInit(): void {
    if (this.input?.type === 'boolean') {
      if (!this.isInitialApplicability) {
        this.options = [{label: 'boolean.true', value: true, active: this.control.value}, {label: 'boolean.false', value: false, active: !this.control.value}];
        this.control.setValue(this.options.find(option => option.active)!.value);
      } else {
        this.options = [{label: 'boolean.true', value: true, active: false}, {label: 'boolean.false', value: false, active: false}];
        this.control.reset();
      }
    }
  }

  public setOptionActive(option: Record<string, any>) {
    if (!this.control.disabled) {
      this.options.forEach(o => o.active = false);
      option.active = true;
      if (this.input?.type === 'boolean') {
        this.control.setValue(option.value);
      }
    }
  }

}
