import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WORDPRES_BASE_URL } from '@core/constants/core-constants';
import { CoreService } from '@core/services';
import { DateTime } from 'luxon';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription, MatExpansionPanelContent, MatExpansionPanelActionRow } from '@angular/material/expansion';
import { NgClass, DatePipe } from '@angular/common';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { DoclinkDirective } from '../../directives/doclink.directive';

@Component({
  selector: 'naris-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss'],
  standalone: true,
  imports: [MatExpansionPanel, MatExpansionPanelHeader, NgClass, MatExpansionPanelTitle, MatExpansionPanelDescription, MatExpansionPanelContent, DoclinkDirective, MatExpansionPanelActionRow, DatePipe, SafeHtmlPipe, TranslateModule]
})
export class ChangelogComponent implements OnInit {

  @Output() public readonly panelOpened = new EventEmitter<void>();
  @Output() public readonly panelClosed = new EventEmitter<void>();
  @Input() public expanded: boolean;
  @Input() public version: string;
  @Input() public date: Date;
  @Input() public content: string;
  @Input() public read: boolean;
  @Input() public first: boolean;

  public endpoint: string;

  constructor(private readonly coreService: CoreService) {}

  public ngOnInit() {
    const date = DateTime.fromJSDate(this.date).toFormat('y/MM/dd');
    const version = this.version.replaceAll('.', '-');
    this.endpoint = `${WORDPRES_BASE_URL}/index.php/${date}/v${version}/`;
  }

  public onOpened() {
    this.expanded = true;
    this.read = true;
    this.panelOpened.emit();
  }

  public openHelp() {
    this.coreService.setHelp(this.endpoint);
    this.coreService.openArticle = false;
    this.coreService.helpOpened = true;
  }
}
