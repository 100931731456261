import { createClass as n, classCallCheck as e, defineProperty as i, toConsumableArray as t } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { WILDCARD as a } from "../../ui-core/common/index.js";
var r = function () {
  function r(n, t) {
    e(this, r);
    this.surface = n;
    i(this, "_$_edgeMappings", void 0);
    i(this, "_$_originalEdgeTypeFunction", void 0);
    t = t || {
      edgeMappings: []
    };
    this._$_edgeMappings = t.edgeMappings || [];
    this._$_originalEdgeTypeFunction = this.surface.toolkitInstance.edgeTypeFunction;
    this._$_init();
  }
  n(r, [{
    key: "computeTypes",
    value: function n(e) {
      var i = this;
      var r = [this._$_originalEdgeTypeFunction(e)];
      var s = function n(s) {
        var p = i._$_edgeMappings[s].property;
        var o = i._$_edgeMappings[s].mappings;
        if (e[p] != null) {
          var c = e[p].split(" ");
          r.push.apply(r, t(c.filter(function (n) {
            return o[n] != null;
          }).map(function (n) {
            return "".concat(p, "_").concat(n);
          })));
        }
        if (o[a] != null) {
          if (e[p] != null) {
            r.push("".concat(p, "_").concat(a));
          }
        }
      };
      for (var p = 0; p < this._$_edgeMappings.length; p++) {
        s(p);
      }
      return r.join(" ");
    }
  }, {
    key: "_$_init",
    value: function n() {
      var e = this;
      for (var i = 0; i < this._$_edgeMappings.length; i++) {
        var t = this._$_edgeMappings[i].property;
        var a = this._$_edgeMappings[i].mappings;
        for (var r in a) {
          if (a.hasOwnProperty(r)) {
            var s = "".concat(t, "_").concat(r);
            this.surface.jsplumb.registerConnectionType(s, a[r]);
          }
        }
      }
      this.surface.toolkitInstance.edgeTypeFunction = function (n) {
        return e.computeTypes(n);
      };
    }
  }]);
  return r;
}();
export { r as PropertyMapper };