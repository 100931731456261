import { createClass as t, classCallCheck as i, defineProperty as s } from "../_virtual/_rollupPluginBabelHelpers.js";
import { translateRect as r } from "../ui-core/util/util.js";
import { _node as h, ELEMENT_SVG as e } from "../ui-core/browser-ui-renderer/svg-util.js";
import { addClass as o } from "../ui-core/browser-ui-renderer/browser-util.js";
import { setElementSize as u, setPosition as n } from "../ui-core/browser-ui-renderer/positioning-and-sizing-utils.js";
import { computeGroupSize as a } from "./group-sizer.js";
var l = "jtk-surface-elastic-group-resize";
var y = function () {
  function y(t, u, n, a, p) {
    var f = this;
    i(this, y);
    this.surface = t;
    this.focusVertex = u;
    this.group = n;
    this.groupDef = a;
    this.allowShrinkFromOrigin = p;
    s(this, "groupEntry", void 0);
    s(this, "focusPosition", void 0);
    s(this, "siblingEntries", []);
    s(this, "xMinEntries", []);
    s(this, "xMaxEntries", []);
    s(this, "yMinEntries", []);
    s(this, "yMaxEntries", []);
    s(this, "uiGroup", void 0);
    s(this, "frame", void 0);
    s(this, "layout", void 0);
    s(this, "uiGroupContentArea", void 0);
    s(this, "alwaysShowFrame", void 0);
    s(this, "_initialExtents", void 0);
    s(this, "layoutShiftX", 0);
    s(this, "layoutShiftY", 0);
    s(this, "width", 0);
    s(this, "height", 0);
    s(this, "minSize", void 0);
    s(this, "x", 0);
    s(this, "y", 0);
    s(this, "parent", void 0);
    this.uiGroup = t.jsplumb.getGroup(n.id);
    this.uiGroupContentArea = this.uiGroup.contentArea;
    this.frame = h(e);
    this.layout = t._$_layoutMap.get(n.id);
    t.jsplumb._appendElement(this.frame, this.uiGroupContentArea);
    this.alwaysShowFrame = true;
    o(this.frame, l);
    if (this.surface._useModelForSizes) {
      this.minSize = {
        w: n.data[this.surface._modelWidthAttribute],
        h: n.data[this.surface._modelHeightAttribute]
      };
    }
    this.groupEntry = t.jsplumb.viewport._elementMap.get(n.id);
    var g = Object.assign({}, t.jsplumb.viewport._elementMap.get(u.id));
    this.width = this.groupEntry.w;
    this.height = this.groupEntry.h;
    this.focusPosition = r(g, this.groupEntry, -1);
    var c = n.members.filter(function (t) {
      return t.id !== u.id;
    });
    c.forEach(function (i) {
      var s = t.jsplumb.viewport._elementMap.get(i.id);
      var h = r(s, f.groupEntry, -1);
      f.siblingEntries.push(s);
      f.xMinEntries.push(h);
      f.xMaxEntries.push(h);
      f.yMinEntries.push(h);
      f.yMaxEntries.push(h);
    });
    this._sortEntries();
    this._initialExtents = this.getCurrentExtents();
    if (n.group) {
      var m = this.surface.jsplumb.getGroup(n.group.id);
      if (m.elastic) {
        this.parent = new y(t, n, n.group, m, p);
      }
    }
    this._$_recompute();
  }
  t(y, [{
    key: "_sortEntries",
    value: function t() {
      this.xMinEntries.sort(function (t, i) {
        return t.x < i.x ? -1 : 1;
      });
      this.yMinEntries.sort(function (t, i) {
        return t.y < i.y ? -1 : 1;
      });
      this.xMaxEntries.sort(function (t, i) {
        return t.x + t.w > i.x + i.w ? -1 : 1;
      });
      this.yMaxEntries.sort(function (t, i) {
        return t.y + t.h > i.y + i.h ? -1 : 1;
      });
    }
  }, {
    key: "setFocusPosition",
    value: function t(i, s, r, h) {
      var e = (r === null || r === void 0 ? void 0 : r.w) || this.focusPosition.w;
      var o = (r === null || r === void 0 ? void 0 : r.h) || this.focusPosition.h;
      var u = {
        x: i.x - (h ? this.groupEntry.x : 0),
        y: i.y - (h ? this.groupEntry.y : 0)
      };
      this.focusPosition.x = u.x;
      this.focusPosition.y = u.y;
      this.focusPosition.w = e;
      this.focusPosition.h = o;
      this._$_recompute(s);
    }
  }, {
    key: "_$_recompute",
    value: function t(i) {
      var s = !this.allowShrinkFromOrigin && (i == null ? false : i.metaKey || i.ctrlKey);
      var r = this.getCurrentExtents();
      var h = a(r, this.groupDef, this.allowShrinkFromOrigin, {
        x: this.groupEntry.x,
        y: this.groupEntry.y
      }, this.groupEntry.w, this.groupEntry.h, true, true, this.surface._$_gridProfile, s ? null : this.minSize);
      this.width = h.width;
      this.height = h.height;
      this.layoutShiftX = h.layoutShiftX;
      this.layoutShiftY = h.layoutShiftY;
      u(this.frame, this.width, this.height);
      n(this.frame, {
        x: -this.layoutShiftX,
        y: -this.layoutShiftY
      });
      this.parent && this.parent.setFocusPosition({
        x: this.groupEntry.x - this.layoutShiftX,
        y: this.groupEntry.y - this.layoutShiftY
      }, i, {
        w: this.width,
        h: this.height
      }, true);
      if (!this.alwaysShowFrame) {
        var e = this.width !== this.groupEntry.w || this.height !== this.groupEntry.h || this.layoutShiftX !== 0 || this.layoutShiftY !== 0;
        this.frame.style.visibility = e ? "visible" : "hidden";
      }
    }
  }, {
    key: "getCurrentExtents",
    value: function t() {
      var i = this.xMaxEntries[0] || {
        x: -Infinity,
        w: 0
      };
      var s = this.yMaxEntries[0] || {
        y: -Infinity,
        h: 0
      };
      return {
        xmin: Math.min(this.focusPosition.x, (this.xMinEntries[0] || {
          x: Infinity
        }).x),
        ymin: Math.min(this.focusPosition.y, (this.yMinEntries[0] || {
          y: Infinity
        }).y),
        xmax: Math.max(this.focusPosition.x + this.focusPosition.w, i.x + i.w),
        ymax: Math.max(this.focusPosition.y + this.focusPosition.h, s.y + s.h)
      };
    }
  }, {
    key: "cleanup",
    value: function t() {
      this.frame.parentNode.removeChild(this.frame);
      this.parent && this.parent.cleanup();
    }
  }, {
    key: "getValues",
    value: function t(i) {
      if (i == this.group.id) {
        return {
          layoutShiftX: this.layoutShiftX,
          layoutShiftY: this.layoutShiftY,
          width: this.width,
          height: this.height
        };
      } else {
        return this.parent ? this.parent.getValues(i) : null;
      }
    }
  }, {
    key: "persist",
    value: function t(i, s) {
      var r = this;
      i = i || {};
      s = s || {};
      var h = function t(s, r, h) {
        i[s] = i[s] || {
          original: r,
          current: h
        };
        i[s].current = h;
      };
      var e = this.surface.getLayoutFor(this.group);
      var o = {
        x: this.groupEntry.x - this.layoutShiftX,
        y: this.groupEntry.y - this.layoutShiftY
      };
      var u = Object.assign({}, o);
      if (this.group.group) {
        var n = this.surface.jsplumb.viewport._elementMap.get(this.group.group.id);
        u.x -= n.x;
        u.y -= n.y;
      }
      if (this.layoutShiftX !== 0 || this.layoutShiftY !== 0) {
        this.layout.getPositions().forEach(function (t, i) {
          var s = {
            x: t.x + r.layoutShiftX,
            y: t.y + r.layoutShiftY
          };
          r.layout.setPosition(i, s.x, s.y, true);
          r.surface.jsplumb.viewport.updateElement(i, s.x + o.x, s.y + o.y, null, null, null);
          h(i, t, s);
        });
        this.layout._$_calculateExtents();
        h(this.group.id, {
          x: this.groupEntry.x,
          y: this.groupEntry.y
        }, u);
      }
      if (this.width !== this.groupEntry.w || this.height !== this.groupEntry.h) {
        s[this.group.id] = {
          group: this.group,
          originalGroupSize: {
            w: this.groupEntry.w,
            h: this.groupEntry.h
          },
          newGroupSize: {
            w: this.width,
            h: this.height
          }
        };
      }
      this.surface.jsplumb.viewport.updateElement(this.group.id, o.x, o.y, this.width, this.height, null);
      e.setPosition(this.group.id, u.x, u.y, true);
      if (this.parent) {
        this.parent.persist(i, s);
      }
      return {
        movedElements: i,
        resizedGroups: s
      };
    }
  }]);
  return y;
}();
export { l as CLASS_ELASTIC_GROUP_RESIZE, y as ElasticGroupManager };