import { Component } from '@angular/core';
import { ANCHOR_POSITIONS } from '@core/constants/process-constants';
import { BaseNodeComponent } from '@jsplumbtoolkit/browser-ui-angular';

@Component({
  selector: 'app-archimate-junction',
  standalone: true,
  imports: [],
  templateUrl: './archimate-junction.component.html',
  styleUrl: './archimate-junction.component.scss'
})
export class ArchimateJunctionComponent extends BaseNodeComponent {

  public anchorPositions = ANCHOR_POSITIONS;
  
}
