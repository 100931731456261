import { defineProperty as r } from "../_virtual/_rollupPluginBabelHelpers.js";
import { removeWithFunction as e, extend as o, forEach as t, log as d } from "../ui-core/util/util.js";
import { getsert as n } from "./util.js";
var a, u, i;
var f = function r(e, o, d) {
  var a = e.nodes || [],
    u = e.edges || [],
    i = e.groups || [];
  var f = new Map();
  var p = o.getGroups().map(function (r) {
    return r.id;
  });
  var s = function r(e) {
    var d = f.get(e);
    if (d != null) {
      t(d, function (r) {
        p.push(o.addGroup(r).id);
      });
    }
    f.delete(e);
    t(d, function (e) {
      r(e.id);
    });
  };
  for (var g = 0; g < i.length; g++) {
    var v = i[g];
    if (v.group != null && p.indexOf(v.group) === -1) {
      var c = n(f, v.group, function () {
        return [];
      });
      c.push(v);
    } else {
      var l = o.addGroup(v);
      p.push(l.id);
      s(l.id);
    }
  }
  f.forEach(function (r, e) {
    if (p.indexOf(e) === -1) {
      throw new Error("Parent group [" + e + "] not found");
    } else {
      t(r, function (r) {
        return p.push(o.addGroup(r).id);
      });
    }
  });
  for (var h = 0; h < a.length; h++) {
    o.addNode(a[h]);
  }
  for (var m = 0; m < u.length; m++) {
    var G = {
      source: u[m].source,
      target: u[m].target,
      cost: u[m].cost || 1,
      directed: u[m].directed,
      data: u[m].data
    };
    if (u[m].geometry) {
      G.geometry = u[m].geometry;
    }
    o.addEdge(G);
  }
};
var p = function r(e, a, u) {
  var i = e.nodes || [],
    f = e.edges || [],
    p = e.ports || [],
    s = e.groups || [];
  var g = new Map();
  var v = a.getGroups().map(function (r) {
    return r.id;
  });
  var c = function r(e) {
    var o = g.get(e);
    if (o != null) {
      t(o, function (r) {
        v.push(a.addGroup(r).id);
      });
    }
    g.delete(e);
    t(o, function (e) {
      r(e.id);
    });
  };
  for (var l = 0; l < s.length; l++) {
    var h = s[l];
    if (h.group != null && v.indexOf(h.group) === -1) {
      var m = n(g, h.group, function () {
        return [];
      });
      m.push(h);
    } else {
      var G = a.addGroup(h);
      v.push(G.id);
      c(G.id);
    }
  }
  g.forEach(function (r, e) {
    if (v.indexOf(e) === -1) {
      throw new Error("Parent group [" + e + "] not found");
    } else {
      t(r, function (r) {
        return v.push(a.addGroup(r).id);
      });
    }
  });
  for (var E = 0; E < i.length; E++) {
    a.addNode(i[E]);
  }
  for (var P = 0; P < p.length; P++) {
    if (p[P].nodeId) {
      var w = a.getNode(p[P].nodeId);
      if (w == null) throw new Error("Unknown node [" + p[P].nodeId + "]");
      w.addPort(p[P]);
    } else if (p[P].id) {
      var y = a.getGraph().getVertexByPortId(p[P].id);
      if (y) {
        var N = o(p[P], {});
        N.id = a.getGraph().splitPortId(p[P].id)[1];
        y.addPort(N);
      }
    } else {
      d("could not add port with definition " + p[P] + "; no nodeId or id found");
    }
  }
  for (var I = 0; I < f.length; I++) {
    var j = {
      source: f[I].source,
      target: f[I].target,
      cost: f[I].cost || 1,
      directed: f[I].directed,
      data: f[I].data
    };
    if (f[I].geometry) {
      j.geometry = f[I].geometry;
    }
    a.addEdge(j);
  }
};
var s = function r(e, o) {
  return e.getGraph().serialize();
};
var g = function r(e, o) {
  return e.getGraph().legacySerialize();
};
var v = function r(e, o, t) {
  var d = function r(e) {
    var t = o.addNode(e);
    if (e.children) {
      for (var d = 0; d < e.children.length; d++) {
        var n = o.addNode(e.children[d]);
        o.addEdge({
          source: t,
          target: n
        });
        r(e.children[d]);
      }
    }
  };
  d(e);
};
var c = "json";
var l = "hierarchical-json";
var h = "legacy-json";
var m = (a = {}, r(a, c, s), r(a, h, g), a);
var G = (u = {}, r(u, c, f), r(u, l, v), r(u, h, p), u);
function E(r, e) {
  G[r] = e;
}
function P(r, e) {
  m[r] = e;
}
var w;
(function (r) {
  r["removeGroup"] = "removeGroup";
  r["removeNode"] = "removeNode";
  r["removePort"] = "removePort";
  r["removeEdge"] = "removeEdge";
  r["addNode"] = "addNode";
  r["addGroup"] = "addGroup";
  r["addEdge"] = "addEdge";
  r["addPort"] = "addPort";
})(w || (w = {}));
var y = r({}, c, (i = {}, r(i, w.removeNode, function (r, o, t) {
  var d = t(o.data);
  e(r.nodes, function (r) {
    return r.id === d;
  });
}), r(i, w.removeGroup, function (r, o, t) {
  var d = t(o.data);
  e(r.groups, function (r) {
    return r.id === d;
  });
}), r(i, w.removeEdge, function (r, o, t) {
  var d = t(o.data);
  e(r.edges, function (r) {
    return r.data && r.data.id === d;
  });
}), r(i, w.addNode, function (r, e, o) {
  r.nodes = r.nodes || [];
  r.nodes.push(e.data);
}), r(i, w.addGroup, function (r, e, o) {
  r.groups = r.groups || [];
  r.groups.push(e.data);
}), r(i, w.addEdge, function (r, e, o) {
  var t = {
    source: e.source.getFullId(),
    target: e.target.getFullId(),
    data: e.data || {}
  };
  r.edges = r.edges || [];
  r.edges.push(t);
}), r(i, w.addPort, function (r, e, t) {
  r.ports = r.ports || [];
  var d = o({}, e.port.data || {});
  d.id = e.port.getFullId();
  r.ports.push(d);
}), r(i, w.removePort, function (r, o, t) {
  var d = o.port.getFullId();
  e(r.ports, function (r) {
    return r.id === d;
  });
}), i));
function N(r, e, o, t) {
  var d = G[r];
  if (d == null) throw new Error("jsPlumb Toolkit - parse - [" + r + "] is an unsupported type");else return d(e, o, t);
}
function I(r, e, o) {
  var t = m[r];
  if (t === null) throw new Error("jsPlumb Toolkit - exportData - [" + r + "]  is an unsupported type");else return t(e, o);
}
function j(r, e, o, t, d, n) {
  if (y[o] && y[o][r]) {
    y[o][r](e, t, d);
  }
}
export { l as HIERARCHICAL_JSON_DATATYPE, c as JSON_DATATYPE, h as LEGACY_JSON_DATATYPE, w as ManagedOperations, I as exportData, j as manage, N as parse, P as registerExporter, E as registerParser };