import { Component, Input, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { DateTime } from 'luxon';
import { LanguageService } from '@core/services';
import { HOUR_NUMS, MINUTE_NUMS, DATE_FMT } from '@core/constants';
import { MatTooltip } from '@angular/material/tooltip';
import { NgClass } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepickerToggleIcon, MatDatepicker, MatDatepickerActions, MatDatepickerCancel, MatDatepickerApply } from '@angular/material/datepicker';
import { IconComponent } from '../../elements/icon/icon.component';
import { InlineSelectComponent } from '../inline-select/inline-select.component';
import { ButtonComponent } from '../../elements/button/button.component';
import type { FormInput } from '@core/classes';

@Component({
  selector: 'naris-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  standalone: true,
  imports: [MatTooltip, NgClass, MatInput, MatDatepickerInput, FormsModule, ReactiveFormsModule, MatDatepickerToggle, IconComponent, MatDatepickerToggleIcon, MatDatepicker, MatDatepickerActions, InlineSelectComponent, ButtonComponent, MatDatepickerCancel, MatDatepickerApply]
})
export class DatepickerComponent implements OnInit, OnDestroy {

  @Input()
  public label: string;

  @Input()
  public error: Error | undefined;

  @Input()
  public control: FormControl;

  @Input()
  public input: FormInput;

  @Input()
  public helpText: string;

  @Input()
  public requiredIfVisible = false;

  @Input()
  public id: string;

  @Input() public minDate? = DateTime.now().minus({years: 5});
  @Input() public maxDate? = DateTime.now().plus({years: 20});

  private readonly mapOption = (opt: number) => {
    const padded = opt.toString().padStart(2, '0');
    return {key: padded, label: padded};
  };

  public hourControl = new FormControl('00');
  public minuteControl = new FormControl('00');
  public hourOpts = HOUR_NUMS.map(this.mapOption);
  public minuteOpts = MINUTE_NUMS.map(this.mapOption);
  public currentDate: DateTime;
  public dateTimeArray = ['datetime', 'timestamp'];

  constructor(
    @Inject(MAT_DATE_FORMATS) public globalDateFormat: any,
    private readonly dateAdapter: DateAdapter<DateTime>,
    private readonly langService: LanguageService
  ) {
  }

  public ngOnInit() {
    const locale = this.langService.getAppLang();
    this.dateAdapter.setLocale(locale);
    const hasDateValue = !!this.control.value && typeof this.control.value === 'string';
    let date: DateTime = DateTime.now();
    if (hasDateValue) {
      date = ['datetime', 'timestamp'].includes(this.input.inputType) ? DateTime.fromISO(this.control.value) : DateTime.fromFormat(this.control.value, DATE_FMT);
    }
    if (['datetime', 'timestamp'].includes(this.input.inputType)) {
      this.hourControl.setValue(date.hour.toString().padStart(2, '0'));
      this.minuteControl.setValue(date.minute.toString().padStart(2, '0'));
    }
    this.setDate(date);
    if (this.dateTimeArray.includes(this.input.inputType)) {
      this.globalDateFormat.display.dateA11yLabel = 'D T';
      this.globalDateFormat.display.dateInput = 'D T';
    }
  }

  public setDate(value: DateTime) {
    const adapted = ['datetime', 'timestamp', 'range'].includes(this.input.inputType) ? value.set({
      hour: +(this.hourControl.value || '00'),
      minute: +(this.minuteControl.value || '00')
    }).toISO({includeOffset: false}) : value.toFormat(DATE_FMT);
    this.currentDate = value;
    this.control.setValue(adapted);
  }

  public ngOnDestroy(): void {
    if (!!this.globalDateFormat?.display) { //reset to default
      this.globalDateFormat.display.dateA11yLabel = 'D';
      this.globalDateFormat.display.dateInput = 'D';
    }
  }
}
