import { Component, Input, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { generateGUID } from '@core/helpers';
import { TInputType } from '@core/models';
import { FormService, HttpService, SnackbarService } from '@core/services';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { CardComponent } from '../../../shared/components/card/card.component';
import { ToolbarComponent } from '../../../shared/components/toolbar/toolbar.component';
import { ToolbarItemComponent } from '../../../shared/components/toolbar/toolbar-item/toolbar-item.component';
import { EmptyStateComponent } from '../../../shared/components/empty-state/empty-state.component';
import { ButtonComponent } from '../../../shared/elements/button/button.component';
import { SlidetoggleComponent } from '../../../shared/elements/slidetoggle/slidetoggle.component';
import { FormFieldComponent } from '../../../shared/components/form-field/form-field.component';
import { InputComponent } from '../../../shared/elements/input/input.component';

@Component({
  selector: 'app-do-questionnaire',
  templateUrl: './do-questionnaire.component.html',
  styleUrl: './do-questionnaire.component.scss',
  standalone: true,
  imports: [CardComponent, ToolbarComponent, ToolbarItemComponent, EmptyStateComponent, ButtonComponent, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, SlidetoggleComponent, FormFieldComponent, InputComponent, TranslateModule]
})
export class DoQuestionnaireComponent implements OnInit {

  @Input() public showWelcome = true;
  @Input() public showUnavailable = true;
  @Input() public showGuestInfo = true;
  private _endpoint: string;
  @Input() set endpoint(value: string) {
    this._endpoint = value;
    this.getJSON();
  };
  get endpoint(): string {
    return this._endpoint;
  }

  public guestID: string | undefined;
  public questionnaireJSON: string | undefined;

  public formsArray: {id: string, active: boolean, label: string, inputs: Record<string, any>[], isSaved: boolean, savedId?: string}[] = [];
  public formGroupArray: {id: string, group: FormGroup}[] = [];

  public label = signal<string>('');
  public questionFormsChangedIDs = signal<string[]>([]);

  private questionnaireUrl: string;
  private updateQuestionnaireUrl: string;

  public isUnavailable = false;
  public isStop = false;

  public questionnaireName: string;
  public questionnaireDeadline: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly httpService: HttpService,
    private readonly formService: FormService,
    private readonly snackbar: SnackbarService
  ) { }

  public ngOnInit(): void {
    this.guestID = this.activatedRoute.snapshot.queryParamMap.get('Guest')?.toString();
    if (!!this.guestID) this.getJSON();
  }

  private getJSON() {
    if (!!this.guestID) this.questionnaireUrl = `/b2c/guest/do-questionnaire?Guest=${this.guestID}`;
    if (!!this.endpoint) this.questionnaireUrl = this.endpoint;
    this.updateQuestionnaireUrl = this.questionnaireUrl.replace('do-questionnaire', 'update-questionnaire');
    if (!this.questionnaireUrl) return;
    this.formsArray = [];
    this.formGroupArray = [];
    this.httpService.post(!!this.endpoint ? this.endpoint : this.questionnaireUrl).subscribe({
      next: () => this.isUnavailable = true,
      error: e => {
        if (e.status === 400) {
          const formResponse = e.error.formresponse;
          const elements = formResponse.missing.anchors[0].elements;
          this.questionnaireJSON = elements.find((el: Record<string, any>) => el.elementid.toLowerCase() === 'answers')?.suggestion;
          this.questionnaireName = elements.find((el: Record<string, any>) => el.elementid.toLowerCase() === 'name')?.value || '';
          this.questionnaireDeadline = elements.find((el: Record<string, any>) => el.elementid.toLowerCase() === 'deadline')?.value || '';
          const contributionsUrl = formResponse._links.contributions?.href;
          this.httpService.get(contributionsUrl).subscribe({
            next: contributions => {
              const elementId = Object.keys(contributions)?.[0];
              this.label.set(`${contributions[elementId].label}`);
              this.createFormGroups();
            }
          });
        } else this.isUnavailable = true;
      }
    });
  }

  public createFormGroups() {
    const uniqueID = generateGUID();
    const formGroups = {id: uniqueID, active: true, label: '', inputs: [] as Record<string, any>[], isSaved: false };
    if (!this.questionnaireJSON) return;
    const tmpInputs = JSON.parse(this.questionnaireJSON);
    tmpInputs.forEach((input: any) => {
      const tmp = {
        type: input.AnswerTypeID?.toLowerCase() || input.Type?.toLowerCase(),
        label: input.QuestionText || input.Question,
        description: input.QuestionDescription || input.Description,
        mandatory: input.Mandatory,
        readonly: false,
        displaySize: 50,
        maxLength: input.CharacterLimit,
        elementid: input.AnswerID as string || input.QuestionID,
        static: true,
        value: input.AnswerValue || input.Answer,
        elementId: input.AnswerID as string || input.QuestionID,
        isMissing: true,
        id: input.AnswerID as string || input.QuestionID,
        disabled: false,
        multiple: false,
        validators: {
          required: input.Mandatory,
          maxLength: input.CharacterLimit
        },
        inputType: 'text',
        filterSuffix: null,
        controlType: 'text'
      };
      (formGroups.inputs as any[]).push(tmp);
    });
    const formGroup = this.buildForm(formGroups.inputs, uniqueID);
    formGroup.valueChanges.subscribe(changes => {
      const formId = Object.keys(changes)?.[0]?.split('@')?.[1];
      this.questionFormsChangedIDs.update(values => [...values, formId]);
    });
    this.formGroupArray.push({id: uniqueID, group: formGroup});
    this.formsArray.push(formGroups);
  }

  private buildForm(objects: any, uniqueID: string) {
    const formControls = {} as Record<keyof TInputType, FormControl>;
    objects.forEach((input: any) => {
      const controlId = `${input.id}@${uniqueID}`;
      formControls[controlId as keyof TInputType] = this.formService.toFormField(input);
    });
    return new FormGroup(formControls);
  }

  public getFormControl(uniqueId: string, controlName: string) {
    const formGroup = this.formGroupArray.find(item => item.id === uniqueId)?.group;
    return formGroup?.get(controlName + '') as FormControl || new FormControl();
  }

  public submit(uniqueId: string, createNew = true) {
    const formGroup = this.formGroupArray.find(item => item.id === uniqueId)?.group;
    const formGroups = this.formsArray.find(item => item.id === uniqueId);
    const postableObject = { [!!formGroups?.savedId ? 'UpdateAnswers' : 'SaveAnswers']: {} } as any;
    const answers: Record<string, any>[] = [];
    let postUrl = this.questionnaireUrl;
    if (!!formGroups?.savedId)
      postUrl = `${this.updateQuestionnaireUrl}&ID=${formGroups.savedId}`;
    if (!!formGroup) {
      Object.keys(formGroup?.controls).forEach(key => {
        if (!!formGroup) {
          const control = formGroup.get(key);
          answers.push({ QuestionID: key.split('@')?.[0], AnswerValue: control?.value });
        }
      });
      postableObject[!!formGroups?.savedId ? 'UpdateAnswers' : 'SaveAnswers']['Answers'] = JSON.stringify(answers);
    }
    
    this.httpService.post(postUrl, postableObject).subscribe({
      next: (result: Record<string, any>) => {
        if (!!formGroups) {
          formGroups.active = false;
          formGroups.isSaved = true;
        }
        formGroup?.disable();
        this.snackbar.open({text: 'collab.snackbar.questionnaire_success', type: 'success', duration: 5000});
        if (createNew) this.createFormGroups();

        if (!!result.formresponse.success.redirect && !!formGroups) {
          formGroups.savedId = result.formresponse.success.redirect.toString().replace('/', '');
        }
      }
    });
  }

  public formGroup(uniqueId: string) {
    return this.formGroupArray.find(item => item.id === uniqueId)?.group;
  }

  public toggleFormgroup(formGroups: {id: string, active: boolean, label: string, inputs: Record<string, any>[], isSaved: boolean}) {
    formGroups.isSaved = !formGroups.isSaved;
    const formGroup = this.formGroupArray.find(item => item.id === formGroups.id)?.group;
    formGroups.isSaved ? formGroup?.disable() : formGroup?.enable();
  }

}
