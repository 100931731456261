@if (!!control) {
  <mat-radio-group
    class="naris-radio-group"
    [formControl]="control"
    color="primary"
    aria-label="Select an option"
  >
    @for (option of options; track $index) {
      <mat-radio-button
        class="naris-radio-button"
        [value]="option.value"
        [matTooltip]="length(option.key) > maxLength ? option.key!.toString() : ''"
        [matTooltipClass]="{truncated: length(option.key) > maxLength}"
        matTooltipPosition="after"
        [attr.data-test-id]="'radio__' + option.key"
      >
        {{ truncate(option.key) }}
      </mat-radio-button>
    }
  </mat-radio-group>
} @else {
  <mat-radio-group
    class="naris-radio-group"
    [(ngModel)]="inputModel"
    (ngModelChange)="inputModelChange.emit(inputModel)"
    color="primary"
    [disabled]="disabled"
  >
    @for (option of options; track $index) {
      <mat-radio-button
        class="naris-radio-button"
        [value]="option.value"
        [matTooltip]="length(option.key) > maxLength ? option.key!.toString() : ''"
        [matTooltipClass]="{truncated: length(option.key) > maxLength}"
        matTooltipPosition="after"
        [attr.data-test-id]="'radio__' + option.key"
      >
        {{ truncate(option.key) }}
      </mat-radio-button>
    }
  </mat-radio-group>
}
