@defer (when !loading) {
  <div
    class="case-view-header__container"
  >
    <div class="case-view-header__attributes">
      <naris-toolbar
        size="xlarge"
        [spacing]="true"
        class="toolbar"
        [caseViewToolbar]="true"
      >
        @if (isCreateWizard) {
          <div>{{title}}</div>
        }
        @if (attributes?.length) {
          <naris-toolbar-item
            class="ml-1"
          >
            @if (!!state) {
              <app-module-case-attribute [attribute]="state" />
            }
            <span
              cdkOverlayOrigin
              (mouseenter)="showTitleOverlay = true"
              (mouseleave)="showTitleOverlay = false"
              #caseViewHeaderTitle="cdkOverlayOrigin"
            >{{title}}</span>
            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="caseViewHeaderTitle"
              [cdkConnectedOverlayOpen]="showTitleOverlay"
            >
              <div class="case-view-header__info-overlay">
                @for(attribute of attributes; track attribute.valueCode) {
                  <span
                    class="case-view-header__info-overlay__attribute"
                  >
                    @if (!!attribute.valueCode) {
                      <span class="case-view-header__info-overlay__label">{{ attribute.label }}</span>
                      <span class="case-view-header__info-overlay__value">{{ attribute.valueLabel }}</span>
                    }
                  </span>
                }

              </div>
            </ng-template>
          </naris-toolbar-item>
        }

      </naris-toolbar>
      @if (!!description) {
        <div
          class="case-view-header__description"
        >
          <span #headerDescriptionSpan>{{description}}</span>
          @if (headerDescriptionSpan.scrollWidth > headerDescriptionSpan.offsetWidth) {
            <naris-icon
              icon="info-outline"
              iconClass="small"
              matTooltipPosition="above"
              matTooltipShowDelay="200"
              [matTooltip]="description"
            />
          }
        </div>
      }
    </div>
    @if (!isAssessment && !!toolbarTaskgroups.length) {
      <div
        class="task-content ml-auto"
        [ngClass]="{'description': !!description}"
      >
        @for (taskgroup of toolbarTaskgroups; track taskgroup.href) {
          <naris-taskgroup
            [showLabel]="false"
            [taskgroup]="taskgroup"
            [collapseAfter]="5"
            [hasDescription]="!!description"
            buttonClass="ml-2"
            (postCallback)="validate()"
            (formCallback)="validate()"
          />
        }
      </div>
    }
  </div>
  @if (!_isWizard && !_isAssessment) {
    <mat-tab-group
      [(selectedIndex)]="selectedTabIndex"
      (selectedIndexChange)="tabClicked($event)"
      [class.no-description]="!description"
      [class.no-overflow]="router.url.includes('process') && (router.url.endsWith('designer') || router.url.endsWith('viewer'))"
      (selectedTabChange)="checkSelectedTab($event)"
      preserveContent
    >
      @for(tab of layoutTabs; track tab.href) {
        <mat-tab [label]="tab.title">
          <ng-template matTabContent>
            <div class="tab-content">
              <div [class.grid]="_isWizard && !isAssessment" [class.no-grid]="!_isWizard && isAssessment">
                @if (tab.href.endsWith('/overview') && !!dashboard) {
                  <app-overview [endpoint]="dashboard || ''" />
                } @else if (isCollaboration && tab!.href.includes('questionnaire')) {
                  <app-module-collaboration-view [tab]="tab!" />
                  <!-- <app-overview [endpoint]="dashboard || ''" /> -->
                } @else {
                  @if (!isAssessment) {
                    @if (_isWizard) {
                      <div class="sidebar">
                        <div class="sticky-sidebar">
                          <naris-stepper [steps]="panels" [hideIndicator]="!isWizard" />
                          @if (!!wizardTaskgroups.length) {
                            @for (taskgroup of wizardTaskgroups; track taskgroup.href) {
                              <naris-taskgroup
                                [showLabel]="false"
                                [taskgroup]="taskgroup"
                                (postCallback)="validate()"
                                (formCallback)="validate()"
                                buttonClass="mb-2"
                                class="taskgroup"
                                [fullwidthButtons]="true"
                              />
                            }
                          }
                        </div>
                      </div>
                    }
                    <div class="content" [ngStyle]="{'padding': isStrategyMap() ? '1rem 1rem 0' : null}" [ngClass]="{'process-structure': isProcessStructure()}">
                      @if (isAuditExecutionStructure(tab.href)) {
                        <app-audit-execution />
                      } @else {
                        <app-module-case-view-panel [tabHref]="tab.href" [selectedTab]="selectedTab" [caseId]="caseId" />
                      }
                    </div>
                  } @else {
                    <app-module-assessment-view [panels]="panels!" [taskgroups]="toolbarTaskgroups" [processIds]="processIds" [caseId]="caseId" />
                  }
                }
              </div>
            </div>
          </ng-template>
        </mat-tab>
      }
    </mat-tab-group>
  } @else {
    <div class="tab-content">
      <div [class.grid]="_isWizard && !isAssessment && !!panels && panels.length > 0" [class.no-grid]="!_isWizard && isAssessment || !panels" [class.stepper-collapsed]="isStepperCollapsed">
        @if (!isAssessment) {
          @if (_isWizard && !!panels && panels.length > 0) {
            <div class="sidebar">
              <div class="sticky-sidebar">
                <naris-stepper [steps]="panels" [hideIndicator]="!isWizard" (navigated)="stepClicked($event)" />
              </div>
            </div>
          }
          <div class="content">
            @if (!isCreateWizard) {
              @for (panel of panels; track panel?.url) {
                @if (activeStepUrl === panel?.url) {
                  <app-module-case-view-panel [stepEndpoint]="panel?.url" [caseId]="caseId"/>
                }
              }
              @if (activeStepUrl?.endsWith('wizard-finished')) {
                <naris-finished-wizard />
              }
            } @else {
              <router-outlet />
            }
          </div>
        } @else {
          <app-module-assessment-view [panels]="panels!" [taskgroups]="toolbarTaskgroups" [processIds]="processIds" [caseId]="caseId" />
        }
        <!-- !
          ! Beetje een gekke situatie, hier val je in de wizard maar er staat een conditie die kijkt naar of het een dashboard is. Container waar de conditie op staat is ook leeg?
          ! Ga er voor nu vanuit dat hier nooit een dashboard getoond wordt
        ! -->
        <!-- <ng-container *ngIf="dashboard && dashboardActive; else updateView;" />
        <ng-template #updateView>
          <ng-container *ngIf="!isAssessment; else assessment">
            <div class="sidebar" *ngIf="_isWizard">
              <div class="sticky-sidebar">
                <naris-stepper [steps]="panels" [hideIndicator]="!isWizard" (navigated)="stepClicked($event)" />
              </div>
            </div>
            <div class="content">
              <ng-container *ngIf="!isCreateWizard else showCreateWizard">
                <ng-container *ngFor="let panel of panels">
                  <ng-container *ngIf="activeStepUrl === panel?.url">
                    <app-module-case-view-panel [stepEndpoint]="panel?.url" />
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="activeStepUrl?.endsWith('wizard-finished')">
                  <naris-finished-wizard />
                </ng-container>
              </ng-container>
              <ng-template #showCreateWizard>
                <router-outlet />
              </ng-template>
            </div>
          </ng-container>
        </ng-template> -->
      </div>
    </div>
  }
} @placeholder {
  <div class="loader">
    <naris-progress-spinner [strokeWidth]="2" [diameter]="24" />
  </div>
}
