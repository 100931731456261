import { SelectionModel } from '@angular/cdk/collections';
import { ComponentType } from '@angular/cdk/portal';
import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { FormInput } from '@core/classes';
import { FORM_LOOKUP_TOKEN } from '@core/constants';
import { EAuthContext } from '@core/enums';
import { FormLookupComponent } from '@core/form/form-lookup/form-lookup.component';
import { toNumber } from '@core/helpers';
import { ICaseListRow, IFormLookupData, IInputOption, ILookup, INarisOption, TValOrArr } from '@core/models';
import { AuthService, BeinformedService, FormService, HttpService, TableService } from '@core/services';
import { Observable, Subscription, catchError, debounceTime, finalize, map, switchMap, tap } from 'rxjs';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatOption } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';
import { ButtonComponent } from '../button/button.component';
import { OptionComponent } from '../option/option.component';

@Component({
  selector: 'naris-autocomplete-single',
  templateUrl: './autocomplete-single.component.html',
  styleUrl: './autocomplete-single.component.scss',
  standalone: true,
  imports: [NgClass, FormsModule, MatAutocompleteTrigger, ReactiveFormsModule, IconComponent, ButtonComponent, MatTooltip, MatAutocomplete, MatOption, OptionComponent, AsyncPipe, TranslateModule]
})
export class AutocompleteSingleComponent implements OnChanges {

  @ViewChild(MatAutocompleteTrigger)
  public autocomplete: MatAutocompleteTrigger;

  // Control which is given by the form group
  @Input() public control: FormControl & Record<string, any>;
  private _input: FormInput;
  @Input() set input(val: FormInput) {
    this._input = val;
    this.setInputData();
    this.initialize();
  }
  get input(): FormInput {
    return this._input;
  }
  @Input() public isFilter: boolean;
  @Input() public id: string;

  @Output() public readonly autoSubmit = new EventEmitter<void>();

  // Control which is used for the text input in the autocomplete itself
  public autocompleteInput = new FormControl<string | IInputOption & INarisOption>('');

  public loading = false;
  public lookup?: ILookup;
  public layouthint: string[] = [];
  public focused = false;
  public readOnly = false;
  public filteredOptions: Observable<INarisOption[]>;
  public selection = new SelectionModel<TValOrArr<INarisOption>>(true, []);

  private lastOptions: INarisOption[];
  private extraDataName: string;
  private readonly subs: Subscription[] = [];
  private wasTyped = false;

  constructor(
    private readonly httpService: HttpService,
    private readonly beinformedService: BeinformedService,
    private readonly authService: AuthService,
    private readonly dialog: MatDialog,
    private readonly formService: FormService,
    private readonly tableService: TableService,
    @Inject(FORM_LOOKUP_TOKEN) private readonly formLookupComponent: ComponentType<FormLookupComponent>
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.disabled) {
      if (!!this.lookup && Array.isArray(this.control.value) && this.control.value.length > 0)
        this.setSelection(this.control.value, this.input.dynamicOptions);
      else if (!!this.lookup && !Array.isArray(this.control.value) && !!this.control.value)
        this.setSelection(this.control.value, this.input.dynamicOptions);
    }
  }

  private setInputData() {
    if (!!this.input.lookup) this.lookup = this.input.lookup;
    this.layouthint = this.input.layouthint || [];
  }

  private initialize() {
    if (!!this.control?.disabled) this.autocompleteInput.disable();
    this.control['setSelection'] = (selection: any, dynamicOptions: INarisOption[]) => this.setSelection(selection, dynamicOptions);
    
    if (!!this.lookup) {
      this.filteredOptions = this.autocompleteInput.valueChanges.pipe(
        tap(value => {
          this.loading = true;
          if (!!value && typeof value === 'object') {
            const optionVal = value.value || value.code || value._id;
            let controlValue = !!this.isFilter ? value : optionVal;
            if (!!this.input?.dependencyColumnEndpoint) {
              const body = {Input: optionVal};
              this.httpService.post(this.input.dependencyColumnEndpoint, body).subscribe(res => {
                const jsonRes = JSON.parse(res?.formresponse?.success?.data?.GetDependencyColumn_Result?.Result);
                controlValue =  {...value, id: controlValue, [this.extraDataName]: jsonRes[0][this.extraDataName] };
                this.control?.setValue(controlValue);
              });
            } else {
              this.control?.setValue(controlValue);
            }
          } else {
            this.wasTyped = true;
            this.control?.setValue(null);
          }
        }),
        debounceTime(500),
        switchMap(value => this.fetchOptions(value as string).pipe(
          finalize(() => this.loading = false)
        ))
      );

      if (!!this.input.value && !!this.input.dynamicOptions?.length) this.setSelection(this.input.value, this.input.dynamicOptions);
      if (!!this.input.value && (this.input.type === 'array' && !Array.isArray(this.input.value) || this.input.type !== 'array' && typeof this.input.value !== this.input.type)) {
        this.setMissingValues(this.lookup?.list || '', this.input.value, this.input.type?.toLowerCase() === 'array');
      } else if (!!this.input.value && (this.input.type === 'array' && Array.isArray(this.input.value) )) {
        this.setSelection(this.input.value, this.input.dynamicOptions);
      } else if (!!this.control.value) {
        this.setSelection(this.control.value, this.input.dynamicOptions);
      }
    }

    this.control.valueChanges.subscribe(val => {
      this.control.setErrors(null);
      this.selection.clear();
      if (!val && !this.wasTyped) {
        this.autocompleteInput.setValue('', { emitEvent: false });
      } else if (!this.wasTyped && !!val.label && !!val.value) {
        this.autocompleteInput.setValue(val, {emitEvent: false});
      }
      this.wasTyped = false;
    });

    if (!this.input.lookup 
        && !!this.input.options 
        && !this.input.options.length 
        && !!this.input?.disabled 
        && !!this.input?.value 
        && !!this.input.dynamicOptions?.length
    ) {
      this.setSelection(this.input.value, this.input.dynamicOptions);
    }

    if (!!this.layouthint) this.interpretLayoutHint();
    this.subs.push(
      this.tableService.filterFormClicked.subscribe((_event: Event) => this.autocomplete?.closePanel()),
      this.formService.updateObjectForm$.subscribe(() => this.interpretLayoutHint())
    );
  }

  public setSelection(value: any, dynamicOptions?: INarisOption[]) {
    const lookupFilter = this.lookup?.filter?.name || '';
    let controlValue = value?._id || value?.code || value?.value || value?.label || value;
    if (!Array.isArray(controlValue)) controlValue = [controlValue];
    let label = value?.[lookupFilter || ''] || value?.formValues?.[Object.keys(value?.formValues)[0]]?.[lookupFilter || ''] || value?.name || value?.label || '';
    if (this.input?.value && !label) label = dynamicOptions?.find((i: any) => {
      const _value = toNumber(i.value);
      return _value[0] === controlValue[0];
    })?.label || '';
    const valToSelect = {value: controlValue, controlValue, label};
    this.autocompleteInput.setValue(valToSelect);
    this.autocompleteInput.markAsTouched();
    if (!!this.extraDataName) controlValue = {id: controlValue, [this.extraDataName]: value[this.extraDataName] };
    this.control?.setValue(controlValue);
    this.selection.select({value: controlValue, label: label});
    if (!!value && Array.isArray(value) && !value?.some((val: any) => isNaN(val)))
      this.setMissingValues(this.lookup?.list || '', value, this.input?.type?.toLowerCase() === 'array');
    if (this.input?.layouthint?.includes('auto-submit')) this.autoSubmit.emit();
  }

  /**
   * Function to transform value that is shown in the input field.
   * Shows the option label by default, and falls back on the value.
   * @param option INarisOption passed from autocomplete
   */
  public autocompleteLabel(option: INarisOption) {
    return option?.label || option?.value || '';
  }

  public onOptionSelected(event: MatAutocompleteSelectedEvent) {
    if (this.input?.layouthint?.includes('auto-submit')) {
      this.autoSubmit.emit();
    } else {
      this.toggleOption(event.option.value as INarisOption);
    }
  }

  public toggleOption(option: INarisOption & Record<string, any>) {
    if (!!this.input?.dependencyColumnEndpoint) {      
      const body = {Input: option.value};
      this.httpService.post(this.input.dependencyColumnEndpoint, body).subscribe(res => {
        const jsonRes = JSON.parse(res?.formresponse?.success?.data?.GetDependencyColumn_Result?.Result);
        option[this.extraDataName] = jsonRes[0][this.extraDataName];
        this.setSelectedOption(option);
      });
    } else {
      this.setSelectedOption(option);
    }
  }

  public optionClicked(event: Event, option: INarisOption) {
    event.stopPropagation();
    this.toggleOption(option);
  }

  public optionSelected(option: INarisOption) {
    const selected = (this.selection.selected as INarisOption[]).find(opt => opt._id?.toString() === option.value?.toString() || opt.value?.toString() === option.value?.toString());
    return !!selected;
  }

  public onClearSelection() {
    this.selection.clear();
    this.autocompleteInput.setValue('');
  }

  public openAdvancedLookup() {
    this.dialog.open<FormLookupComponent, IFormLookupData, ICaseListRow | string>(this.formLookupComponent, {
      panelClass: 'naris-advanced-lookup-dialog',
      minWidth: '65rem',
      maxHeight: '98vh',
      data: {
        endpoint: this.lookup?.list,
        multiple: false,
        selected: this.selection.selected,
        layouthint: this.input?.layouthint,
        createAction: !!this.input?.createEndpoint,
        createEndpoint: this.input?.createEndpoint
      },
      position: {left: '11%'}
    }).afterClosed().subscribe(val => {
      if (!val) return;
      else if ((val as any).label === 'lookupCreate') this.lookupCreateAction((val as any).result?.redirect);
      else this.setSelection(val);
    });
  }

  public lookupCreateAction(redirect: string) {
    if ((redirect.match(/\//g) || []).length > 1) {
      this.beinformedService.fetchResponseWithContributions(redirect).subscribe(result => {
        const attributes = this.beinformedService.extractAttributes(result);
        const label = attributes?.find((attr: any) => attr.layouthint?.includes('title'))?.valueLabel || attributes?.[0].valueLabel;
        const id = redirect.split('/').pop();
        this.setCreated(id, label);
      });
    }
  }

  private setCreated(id?: number | string, label?: string) {
    if (!id || !label) return;
    const returnObj = this.input?.multiple ? [{ value: id, code: id, label }] : { value: id, code: id, label };
    if (!!this.control['setSelection'] && typeof this.control['setSelection'] === 'function') this.control['setSelection'](returnObj, null, this.input?.multiple);
    else this.control?.setValue(id);
  }

  private setSelectedOption(option: INarisOption) {
    if (this.optionSelected(option)) {
      const selectedOption = (this.selection.selected as INarisOption[]).find(opt => opt._id?.toString() === option.value?.toString() || opt.value?.toString() === option.value?.toString());
      this.selection.deselect(selectedOption!);
    } else {
      this.control.markAsDirty();
      this.control.updateValueAndValidity();
      this.selection.select(option);
    }
  }

  private interpretLayoutHint() {
    this.layouthint.forEach(hint => {
      if (hint.startsWith('dependency-column: ')) {
        this.extraDataName = hint.replace('dependency-column: ', '');
      }
      if (hint === 'read_only' || this.control.disabled) {
        this.readOnly = true;
        this.autocompleteInput.disable();
      } else {
        this.readOnly = false;
        this.autocompleteInput.enable();
      }
    });
  }

  private setMissingValues(href: string, value: any, isArray: boolean) {
    if (!value || Array.isArray(value) && !value?.length) return;
    this.beinformedService.fetchResponseWithContributions(href, false).subscribe(res => {
      if (!!res?.data?._embedded?.results.length) {
        const itemKey = Object.keys(res.data._embedded.results[0])[0];
        const val = res.data._embedded.results.filter((item: any) => Array.isArray(value) ? value.includes(item[itemKey]._id) : value === item[itemKey]._id).map((item: any) => item[itemKey]);
        if (!val?.length) return;
        else this.setSelection(isArray ? val : val[0]);
      }
    });
  }

  private fetchOptions(filterString: string): Observable<INarisOption[]> {
    if (!filterString || typeof filterString !== 'string') // If filterString is not a string, we don't know what to filter => return;
      return new Observable(observer => {
        observer.next(undefined);
        observer.complete();
      });
    const endpoint = this.lookup?.filter
      ? `${this.lookup.href}&${this.lookup.filter[Object.keys(this.lookup.filter)[0]]}=${filterString}`
      : this.lookup?.href;
    const isRest = this.authService.authContext === EAuthContext.SSO;
    return this.httpService.get(endpoint!, isRest).pipe(
      catchError(() => []),
      map(result => {
        const mapped = result.lookup?.options?.map((option: any) => {
          let label = option.label || '';
          if (option.elements) label = this.mapEl(option.elements);
          return {
            value: /^\d+$/.test(option.code) ? +option.code : option.code,
            code: /^\d+$/.test(option.code) ? +option.code : option.code,
            label
          };
        });
        this.lastOptions = mapped;
        return mapped;
      })
    );
  }

  private mapEl(elementsObj: any) {
    const filterName = this.lookup?.filter?.name;
    if (typeof elementsObj !== 'object') return elementsObj;
    if (!!filterName) return elementsObj[filterName] || elementsObj[Object.keys(elementsObj)[0]];
    return Object.values(elementsObj).filter(el => !!el).join(' | ');
  }
}
