import { defineProperty as e } from "../_virtual/_rollupPluginBabelHelpers.js";
import { DialogConstants as t, attSel as n } from "./constants.js";
import { data as r } from "../core/util.js";
var u, i, l;
var o = t.Attributes;
var a = t.Events;
var f = t.Elements;
var c = (u = {}, e(u, f.TEXT, function (e) {
  return e.value;
}), e(u, f.NUMBER, function (e) {
  return e.value;
}), e(u, f.RADIO, function (e) {
  if (e.checked) return e.value;
}), e(u, f.CHECKBOX, function (e) {
  if (e.checked) return true;
}), e(u, f.SELECT, function (e) {
  var t = e.selectedOptions,
    n = e.getAttribute(o.MULTIPLE);
  if (n) {
    var r = [];
    for (var u = 0; u < t.length; u++) {
      r.push(t[u].value);
    }
    return r;
  } else {
    return e.selectedIndex !== -1 ? e.options[e.selectedIndex].value : null;
  }
}), e(u, f.TEXTAREA, function (e) {
  return e.value;
}), e(u, f.COLOR, function (e) {
  return e.value;
}), e(u, f.HIDDEN, function (e) {
  return e.value;
}), u);
function v(e) {
  var t = e.querySelectorAll(n(o.JTK));
  var r;
  for (var u = 0; u < t.length; u++) {
    r = t[u];
    if (r.checkValidity != null && !r.checkValidity()) {
      return false;
    }
  }
  return true;
}
function s(e, t) {
  var u = e.querySelectorAll(n(o.JTK)),
    i = {};
  for (var l = 0; l < u.length; l++) {
    var a = E(u[l], t);
    if (a != null) {
      var f = u[l].getAttribute(o.JTK);
      var c = r(i, f);
      if (c != null) {
        if (!Array.isArray(c)) {
          c = [c];
          r(i, f, c);
        }
        c.push(a);
      } else {
        r(i, f, a);
      }
    }
  }
  return i;
}
function E(e, t) {
  var n = e.tagName.toUpperCase(),
    r = n === f.INPUT ? (e.getAttribute(o.TYPE) || f.TEXT).toUpperCase() : n,
    u = c[r];
  var i;
  if (u != null) {
    i = u(e);
  } else {
    if (t != null) {
      i = t(e);
    }
  }
  return i;
}
function T(e, t, u, i) {
  var l = t.querySelectorAll(n(o.JTK));
  var c = function t(n) {
    var c = l[n].tagName.toUpperCase(),
      v = c === f.INPUT ? (l[n].getAttribute(o.TYPE) || f.TEXT).toUpperCase() : c,
      s = l[n].getAttribute(o.JTK),
      E = r(e, s);
    if (E != null) {
      var T = p[v];
      if (T != null) {
        T(l[n], E);
      } else {
        if (i != null) {
          i(l[n], E);
        }
        return "continue";
      }
    }
    if (u != null) {
      if (l[n].getAttribute(o.JTK_COMMIT)) {
        if (c === f.INPUT) {
          l[n].addEventListener(a.KEYUP, function (e) {
            if (e.keyCode === 10 || e.keyCode === 13) {
              u(l[n]);
            }
          });
        } else if (c === f.TEXTAREA) {
          l[n].addEventListener(a.KEYUP, function (e) {
            if (e.ctrlKey && (e.keyCode === 10 || e.keyCode === 13)) {
              u(l[n]);
            }
          });
        }
      }
    }
  };
  for (var v = 0; v < l.length; v++) {
    var s = c(v);
    if (s === "continue") continue;
  }
}
function d(e, t) {
  for (var n = 0; n < e.length; n++) {
    if (e[n] === t) {
      continue;
    }
    var r = e[n].tagName.toUpperCase(),
      u = r === f.INPUT ? (e[n].getAttribute(o.TYPE) || f.TEXT).toUpperCase() : r,
      i = A[u];
    if (i) {
      i(e[n]);
    }
  }
}
var p = (i = {}, e(i, f.TEXT, function (e, t) {
  e.value = t;
}), e(i, f.NUMBER, function (e, t) {
  e.value = t;
}), e(i, f.RADIO, function (e, t) {
  e.checked = e.value == t;
}), e(i, f.CHECKBOX, function (e, t) {
  e.checked = t == true;
}), e(i, f.SELECT, function (e, t) {
  var n = e.getAttribute(o.MULTIPLE);
  for (var r = 0; r < e.options.length; r++) {
    if (n) {
      if (t.indexOf(e.options[r].value) > -1) {
        e.options[r].selected = true;
      }
    } else {
      if (e.options[r].value === t) {
        e.selectedIndex = r;
        return;
      }
    }
  }
}), e(i, f.TEXTAREA, function (e, t) {
  e.value = t;
}), e(i, f.COLOR, function (e, t) {
  e.value = t;
}), e(i, f.HIDDEN, function (e, t) {
  e.value = t;
}), i);
var A = (l = {}, e(l, f.TEXT, function (e) {
  e.value = "";
}), e(l, f.RADIO, function (e) {
  e.checked = false;
}), e(l, f.CHECKBOX, function (e) {
  e.checked = false;
}), e(l, f.SELECT, function (e) {
  e.selectedIndex = -1;
}), e(l, f.TEXTAREA, function (e) {
  e.value = "";
}), e(l, f.COLOR, function (e) {
  e.value = "";
}), e(l, f.HIDDEN, function (e) {
  e.value = "";
}), l);
export { T as apply, v as checkValidity, d as clear, s as extract, E as extractValueFromElement };