import { inherits as e, createSuper as t, classCallCheck as l, defineProperty as a, assertThisInitialized as i, createClass as s } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { Overlay as n } from "./overlay.js";
import { OverlayFactory as r } from "../factory/overlay-factory.js";
import { isFunction as o } from "../../util/util.js";
var u = function (n) {
  e(u, n);
  var r = t(u);
  function u(e, t, s) {
    var n;
    l(this, u);
    n = r.call(this, e, t, s);
    n.instance = e;
    n.component = t;
    a(i(n), "label", void 0);
    a(i(n), "labelText", void 0);
    a(i(n), "type", u.type);
    a(i(n), "cachedDimensions", void 0);
    a(i(n), "useHTMLElement", void 0);
    s = s || {
      label: ""
    };
    n.useHTMLElement = s.useHTMLElement !== false;
    n.setLabel(s.label);
    return n;
  }
  s(u, [{
    key: "getLabel",
    value: function e() {
      if (o(this.label)) {
        return this.label(this);
      } else {
        return this.labelText;
      }
    }
  }, {
    key: "setLabel",
    value: function e(t) {
      this.label = t;
      this.labelText = null;
      this.instance.updateLabel(this);
    }
  }, {
    key: "getDimensions",
    value: function e() {
      return {
        w: 1,
        h: 1
      };
    }
  }, {
    key: "updateFrom",
    value: function e(t) {
      if (t.label != null) {
        this.setLabel(t.label);
      }
      if (t.location != null) {
        this.setLocation(t.location);
        this.instance.updateLabel(this);
      }
    }
  }]);
  return u;
}(n);
a(u, "type", "Label");
function c(e) {
  return e.type === u.type;
}
r.register(u.type, u);
export { u as LabelOverlay, c as isLabelOverlay };