import { createElementNS as t } from "./browser-util.js";
import { forEach as r } from "../util/util.js";
import { NONE as e } from "../core/constants.js";
var o = {
  "stroke-linejoin": "stroke-linejoin",
  "stroke-dashoffset": "stroke-dashoffset",
  "stroke-linecap": "stroke-linecap"
};
var i = "dashstyle";
var s = "fill";
var a = "stroke";
var n = "stroke-width";
var l = "strokeWidth";
var v = "svg";
var f = "defs";
var h = "path";
var k = "text";
var p = "rect";
var d = "g";
var u = "version";
var c = "xmlns";
var w = "fill";
var x = "stroke-dasharray";
var g = "stroke-linejoin";
var m = "stroke-linecap";
var b = "stroke-miterlimit";
var y = "stroke-dashoffset";
var A = "stroke-opacity";
var j = "stroke";
var W = "stroke-width";
var N = "vector-effect";
var B = "non-scaling-stroke";
var C = "text-anchor";
var M = "http://www.w3.org/2000/svg";
var O = "http://www.w3.org/1999/xlink";
var q = "http://www.w3.org/1999/xhtml";
function z(t, r) {
  for (var e in r) {
    t.setAttribute(e, "" + r[e]);
  }
}
function D(r, e) {
  e = e || {};
  return t(M, r, null, null, e);
}
function E(t) {
  return "position:absolute;left:" + t[0] + "px;top:" + t[1] + "px";
}
function F(t, v) {
  t.setAttribute(s, v.fill ? v.fill : e);
  t.setAttribute(a, v.stroke ? v.stroke : e);
  if (v.strokeWidth) {
    t.setAttribute(n, v.strokeWidth);
  }
  if (v[i] && v[l] && !v[x]) {
    var f = v[i].indexOf(",") === -1 ? " " : ",",
      h = v[i].split(f),
      k = "";
    r(h, function (t) {
      k += Math.floor(t * v.strokeWidth) + f;
    });
    t.setAttribute(x, k);
  } else if (v[x]) {
    t.setAttribute(x, v[x]);
  }
  for (var p in o) {
    if (v[p]) {
      t.setAttribute(o[p], v[p]);
    }
  }
}
function G(t, r, e) {
  if (t.childNodes.length > e) {
    t.insertBefore(r, t.childNodes[e]);
  } else {
    t.appendChild(r);
  }
}
function H(t, r, e, o, i) {
  t.style.width = o + "px";
  t.style.height = i + "px";
  t.style.left = r + "px";
  t.style.top = e + "px";
  t.height = i;
  t.width = o;
}
var I = {
  attr: z,
  node: D
};
export { w as ATTRIBUTE_FILL, j as ATTRIBUTE_STROKE, x as ATTRIBUTE_STROKE_DASHARRAY, y as ATTRIBUTE_STROKE_DASHOFFSET, m as ATTRIBUTE_STROKE_LINECAP, g as ATTRIBUTE_STROKE_LINEJOIN, b as ATTRIBUTE_STROKE_MITERLIMIT, A as ATTRIBUTE_STROKE_OPACITY, W as ATTRIBUTE_STROKE_WIDTH, C as ATTRIBUTE_TEXT_ANCHOR, B as ATTRIBUTE_VALUE_NON_SCALING_STROKE, N as ATTRIBUTE_VECTOR_EFFECT, u as ATTRIBUTE_VERSION, c as ATTRIBUTE_XMLNS, i as DASHSTYLE, f as ELEMENT_DEFS, d as ELEMENT_G, h as ELEMENT_PATH, p as ELEMENT_RECT, v as ELEMENT_SVG, k as ELEMENT_TEXT, s as FILL, l as LINE_WIDTH, a as STROKE, n as STROKE_WIDTH, M as SVG_NAMESPACE, q as XHTML_NAMESPACE, O as XLINK_NAMESPACE, G as _appendAtIndex, F as _applyStyles, z as _attr, D as _node, E as _pos, H as _size, I as svg };