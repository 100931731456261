<div class="group">
  <div class="group__header">
    <span 
      class="group__header__title"
      [matTooltip]="obj['name']"
      matTooltipPosition="above"
      matTooltipShowDelay="200"
    >{{obj['name']}}</span>
    <naris-icon
      class="group__header__toggle"
      icon="expand_more"
      iconClass="white"
      (click)="toggleGroup()"
    />
  </div>
  <div
    class="group__content"
    data-jtk-group-content="true"
  ></div>
</div>
