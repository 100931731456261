import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'find',
  standalone: true
})
export class FindPipe implements PipeTransform {

  public transform(arr: any[], property: string, predicate: any): any {
    return arr.find(x => x[property] === predicate);
  }
}
