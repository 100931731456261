import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { of, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpService } from '@core/services/http.service';
import { AuthService } from '@core/services/auth.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { EAuthContext } from '@core/enums';
import { DOC_HASH_URL } from '@core/constants';
import type { IForm, IFormAnchorElement, IFormResponse } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  public downloading: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly httpService: HttpService,
    private readonly authService: AuthService,
    private readonly snackbarService: SnackbarService
  ) {}

  public downloadFile(href?: string) {
    const url = `${href}${href?.includes('?') ? '&' : '?'}commit=false`;
    this.downloading.next(true);
    const rest = this.authService.authContext === EAuthContext.SSO;
    this.httpService.post(url, {}, rest).pipe(
      catchError(err => {
        if (err.status === 400 && err.error?.formresponse) {
          const form = err.error.formresponse as IForm;
          const formeElements = form.missing?.anchors[0].elements;
          const data = this.extractDocData(formeElements);
          this.downloadDoc(data);
          return of(undefined);
        }
        return throwError(() => new Error(err));
      })
    ).subscribe(() => {
      this.downloading.next(false);
    });
  }

  public openDoclink(hash: string, revision: string) {
    const params = new HttpParams({fromObject: {commit: false, DocumentHash: hash, RevisionNumber: revision}});
    const rest = this.authService.authContext === EAuthContext.SSO;
    this.httpService.post(DOC_HASH_URL, {}, rest, true, {params})
      .pipe(catchError((err: IFormResponse) => {
        if (err.status !== 400 || !err.error.formresponse) return throwError(() => err);
        const elements = err.error.formresponse.missing?.anchors[0].elements;
        try {
          const data = this.extractDocData(elements);
          this.downloadDoc(data);
        } catch(e) {
          console.log(e);
          const contributionHref = err.error.formresponse._links?.contributions.href;
          if (!!contributionHref)
            this.httpService.get(contributionHref).subscribe(res => {
              if (!!elements) {
                const contributionOptions = res['DownloadDocumentWithHash'].objects[elements[0].elementid]?.attributes[0][elements[0].elementid].options;
                const preLabel = res['DownloadDocumentWithHash'].objects[elements[0].elementid]?.label;
                if (!!contributionOptions?.length && !!preLabel) {
                  const snackbarText = contributionOptions.find((option: any) => option.code === elements?.[0].value)?.label;
                  this.snackbarService.open({text: `${preLabel}: ${snackbarText}`, type: elements[1].suggestion.toLowerCase()});
                }
              }
            });
        }
        return of(undefined);
      }))
      .subscribe();
  };

  private extractDocData(elements?: IFormAnchorElement[]) {
    const mimeType = elements?.find(el => el.elementid === 'Mimetype')?.suggestion as string | undefined;
    const data = elements?.find(el => el.elementid === 'Data')?.suggestion as string | undefined;
    const fileName = elements?.find(el => el.elementid === 'DocumentName' || el.elementid === 'FileName' )?.suggestion as string | undefined;
    if (!mimeType || !data || !fileName) throw new Error('Cannot handle file; data could not be extracted.');
    const linkSource = `data:${mimeType};base64,${data}`;
    return {mimeType, fileName, linkSource};
  }

  private downloadDoc({ linkSource, fileName }: ReturnType<typeof this.extractDocData>) {
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
}
