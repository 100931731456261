<div
  cdkDrag
  cdkDragBoundary=".naris-layout"
  class="help-container"
  [cdkDragFreeDragPosition]="_helpPanelPosition"
  (cdkDragEnded)="cdkDragEnded($event)"
>
  <div #helpPanel class="help-panel" [ngClass]="{'help-panel--has-banner': showLanguageBanner}" [ngStyle]="{'width.px': width, 'height.px': height}">
    <naris-toolbar border="bottom" class="help-toolbar" cdkDragHandle>
      <naris-toolbar-item class="help-actions left">
        <naris-button
          size="small"
          color="transparent"
          icon="refresh"
          (clicked)="searchArticle()"
          id="HelpSearchArticle"
        />
        <naris-button
          size="small"
          color="transparent"
          icon="home"
          (clicked)="home()"
          id="HelpHome"
        />
      </naris-toolbar-item>
      <naris-toolbar-item>
        <h4 
          tabindex="0"
          class="help-title" 
          (click)="navigate()"
        >{{ 'help.title' | translate }}</h4>
      </naris-toolbar-item>
      <naris-toolbar-item class="help-actions right">
        <naris-button
          size="small"
          color="transparent"
          icon="close"
          (clicked)="closed.emit()"
          id="HelpClose"
        />
      </naris-toolbar-item>
    </naris-toolbar>
    <div class="help-content">
      @if (!coreService.helpHref) {
        <naris-loader />
      } @else {
        <iframe #frame [src]="coreService.helpHref" id="help-iframe" class="help-iframe"></iframe>
      }
    </div>
    <div class="mb-4"></div>
  </div>
</div>
