<naris-toolbar class="toolbar" border="bottom" color="yellow-gradient" sticky="default">
  <naris-toolbar-item>
    <naris-icon icon="giftcard" />
    <h4>{{ 'changelogs.whats_new' | translate }}</h4>
  </naris-toolbar-item>
  <naris-toolbar-item class="ml-auto">
    <naris-button icon="close" color="white" class="close-button" (clicked)="closed.emit()" id="ChangelogsClose" />
  </naris-toolbar-item>
</naris-toolbar>

@if (!!changelogs?.length) {
  <div class="changelogs">
    @for (cl of changelogs; let i = $index; let first = $first; track i) {
      <naris-changelog
        [version]="cl.version"
        [date]="cl.date"
        [content]="cl.content || ''"
        [read]="cl.read"
        [first]="first"
        [expanded]="currentPanel === i || (!currentPanel && first)"
        [matTooltip]="first ? ('changelogs.current' | translate) : ('changelogs.previous' | translate)"
        matTooltipPosition="left"
        (panelOpened)="onPanelOpen(cl, i)"
      />
    }
  </div>
} @else {
  <naris-empty-state [title]="'changelogs.empty' | translate" image="empty">
    <a class="naris-link" (click)="openHelp()" target="_blank" tabindex="0">{{ 'changelogs.website' | translate }}</a>
  </naris-empty-state>
}
