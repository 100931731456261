@defer (when !loading) {
  @if (!!href) {
    <div>
      @if (!isEmbedded) {
        <naris-toolbar>
          <h3>{{title}}</h3>
        </naris-toolbar>
      }
      @for (parent of parents; track parent) {
        <div class="content">
          <div class="content-panel left">
            <div class="sticky">
              <div class="head">
                <h4 class="event-cat-name">{{parent.EventCategoryName}}</h4>
                <span class="consequence-type">{{parent.ConsequenceType}}</span>
              </div>
              <span class="parent-properties">
                @for (key of parent.parentKeys; track key) {
                  <span>
                    <span>
                      {{parent.parentLabels[key]}}: {{parent[key] === null || parent[key] === undefined ? '-' : parent[key]}}
                    </span>
                  </span>
                }
              </span>
              <naris-taskgroup
                [buttonSize]="'small'"
                [taskgroup]="parent.actions"
                (formCallback)="reloadAll()"
                (postCallback)="reloadAll()"
              />
            </div>
          </div>
          <div class="content-panel right">
            @for (detail of parent.details; track detail) {
              <div class="detail">
                <div class="head">
                  <h4 class="event-cat-name">{{detail.EventName}}</h4>
                  @if (detail.EventDescription) {
                    <span class="consequence-type">{{detail.EventDescription}}</span>
                  }
                </div>
                <div class="flex-row">
                  <span class="parent-properties">
                    @for (detailKey of detail.detailKeys; track detailKey) {
                      <span>
                        @if (detailKey !== 'EventName' && detailKey !== 'EventDescription') {
                          <div>
                            <span class="flex-col" [style.width]="100 / detail.detailKeys.length + '%'">
                              <span>
                                {{detail.detailLabels[detailKey]}}
                              </span>
                              <span>
                                {{detail[detailKey] === null || detail[detailKey] === undefined ? '-' : detail[detailKey]}}
                              </span>
                            </span>
                          </div>
                        }
                      </span>
                    }
                  </span>
                  <naris-taskgroup
                    [buttonSize]="'small'"
                    [taskgroup]="detail.actions"
                    (formCallback)="reloadDetail(detail, parent)"
                    (postCallback)="reloadDetail(detail, parent)"
                  />
                </div>
              </div>
            }
          </div>
        </div>
      }
    </div>
  } @else {

    <div class="no-href">
      <naris-icon icon="error" iconClass="medium accent" />
      <p>{{'list.error_loading_resource' | translate}}</p>
    </div>
  }
  
} @placeholder {
  <naris-loader />
}
