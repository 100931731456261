var a = "allowNestedGroups";
var r = "anchor";
var e = "anchors";
var n = "connectionOverlays";
var o = "connectionsDetachable";
var t = "connector";
var v = "container";
var i = "endpoint";
var c = "endpoints";
var s = "endpointStyle";
var l = "endpointStyles";
var p = "endpointHoverStyle";
var S = "endpointHoverStyles";
var d = "hoverClass";
var y = "hoverPaintStyle";
var h = "listStyle";
var C = "maxConnections";
var u = "paintStyle";
var x = "reattachConnections";
var z = "scope";
var H = "nodeSize";
var b = "groupSize";
export { a as DEFAULT_KEY_ALLOW_NESTED_GROUPS, r as DEFAULT_KEY_ANCHOR, e as DEFAULT_KEY_ANCHORS, o as DEFAULT_KEY_CONNECTIONS_DETACHABLE, n as DEFAULT_KEY_CONNECTION_OVERLAYS, t as DEFAULT_KEY_CONNECTOR, v as DEFAULT_KEY_CONTAINER, i as DEFAULT_KEY_ENDPOINT, c as DEFAULT_KEY_ENDPOINTS, p as DEFAULT_KEY_ENDPOINT_HOVER_STYLE, S as DEFAULT_KEY_ENDPOINT_HOVER_STYLES, s as DEFAULT_KEY_ENDPOINT_STYLE, l as DEFAULT_KEY_ENDPOINT_STYLES, b as DEFAULT_KEY_GROUP_SIZE, d as DEFAULT_KEY_HOVER_CLASS, y as DEFAULT_KEY_HOVER_PAINT_STYLE, h as DEFAULT_KEY_LIST_STYLE, C as DEFAULT_KEY_MAX_CONNECTIONS, H as DEFAULT_KEY_NODE_SIZE, u as DEFAULT_KEY_PAINT_STYLE, x as DEFAULT_KEY_REATTACH_CONNECTIONS, z as DEFAULT_KEY_SCOPE };