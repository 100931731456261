import { Component, ViewEncapsulation, HostBinding } from '@angular/core';

@Component({
  selector: 'naris-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true
})
export class MenuComponent {
  @HostBinding('class')
  get rootClasses() {
    return ['naris-menu'];
  }
}
