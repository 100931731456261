import { StatusColor } from '@core/enums';
import type { IStatusColor } from '@core/models';

export const MODULES = {
  NARISAdmin: {
    icon: 'video_library'
  },
  Organization: {
    icon: 'domain'
  },
  Configuration: {
    icon: 'configuration'
  },
  Config: {
    icon: 'configuration'
  },
  Common: {
    icon: 'common'
  },
  Activities: {
    icon: 'activity'
  },
  MyProfile: {
    icon: 'account_circle'
  },
  Risk: {
    icon: 'risk'
  },
  Strategy: {
    icon: 'directions-fork'
  },
  Audit: {
    icon: 'audit'
  },
  AuditBeta: {
    icon: 'audit'
  },
  Control: {
    icon: 'control'
  },
  Test: {
    icon: 'draft'
  },
  Recommendation: {
    icon: 'rule'
  },
  Collaboration: {
    icon: 'group_outline'
  },
  CollaborationBeta: {
    icon: 'group_outline'
  },
  Holding: {
    icon: 'holding'
  },
  Organizations: {
    icon: 'organizations'
  },
  Datafeeds: {
    icon: 'datafeed'
  },
  Process: {
    icon: 'process'
  },
  ProcessBeta: {
    icon: 'process'
  },
  Compliance: {
    icon: 'compliance-icon'
  },
  ComplianceBeta: {
    icon: 'compliance-icon'
  },
  History: {
    icon: 'icon-history'
  },
  FloatingDocuments: {
    icon: 'document'
  },
  Asset: {
    icon: 'asset'
  },
  AssetBeta: {
    icon: 'asset'
  },
  Knowledgebase: {
    icon: 'knowledge'
  },
  Import: {
    icon: 'import'
  },
  PushRisk: {
    icon: 'risk-push'
  },
  PushProcess: {
    icon: 'process-push'
  },
  PushCommon: {
    icon: 'common-push'
  },
  PushControl: {
    icon: 'control-push'
  },
  PushStrategy: {
    icon: 'strategy-push'
  },
  PushAsset: {
    icon: 'asset-push'
  },
  PushCompliance: {
    icon: 'compliance-push'
  },
  PushAudit: {
    icon: 'audit-push'
  },
  PushRegister: {
    icon: 'register-push'
  },
  PushContract :{
    icon: 'contract-push'
  },
  Home: {
    icon: 'home'
  },
  SetupOrganization: {
    icon: 'setup'
  },
  NARISSupport: {
    icon: 'support'
  },
  NARISCsm: {
    icon: 'csm'
  },
  Register: {
    icon: 'register'
  },
  Contract: {
    icon: 'contract'
  }
} as Record<string, Record<string, string>>;

/**
 * List of module names that are hidden on production
 */
export const MODULES_HIDDEN_ON_PRODUCTION = [
  // 'Strategy'
] as string[];

/**
 * List of module names that are always hidden
 * (functional modules with endpoints but no views in the frontend)
 */
export const MODULES_HIDDEN = [
  'Activities',
  'B2C',
  // 'CollaborationBeta',
  // 'Collaboration'
] as string[];

export const GROUPING_PANEL_TYPES = [
  'TabGroupingPanel',
  'CaseTabGroupingPanel',
  'GroupingPanel'
] as string[];

export const NARIS_MODULE_NAMES = [
  'Risk',
  'Control',
  'Audit',
  'Compliance',
  'History',
  'FloatingDocuments',
  'Strategy',
  'Asset',
  'Import',
  'Register',
  'Contract'
] as string[];

export const CONFIG_BASE_URL = '/config';

export const COLOR_DEFAULT: IStatusColor = { color: StatusColor.default };
export const COLOR_SUCCESS: IStatusColor = { color: StatusColor.success };
export const COLOR_WARNING: IStatusColor = { color: StatusColor.warning };
export const COLOR_DANGER: IStatusColor = { color: StatusColor.danger };
export const COLOR_PRIMARY: IStatusColor = { color: StatusColor.primary };
export const COLOR_PLANNED: IStatusColor = { color: StatusColor.planned };

export const STATUS_CONFIG: Record<string, IStatusColor> = {

  // NotStarted
  NotStarted: COLOR_DEFAULT,

  // Initial
  Initial: COLOR_DEFAULT,

  // Approved
  Approved: COLOR_SUCCESS,

  // Suspended
  Suspended: COLOR_WARNING,

  // Aborted
  Aborted: COLOR_DANGER,

  // Rejected
  Rejected: COLOR_DANGER,

  // InProgress
  InProgress: COLOR_PRIMARY,

  // InReview
  InReview: COLOR_WARNING,

  // Evaluate
  Evaluate: COLOR_PLANNED,

  // Completed
  Completed: COLOR_SUCCESS,

  // Finished
  Finished: COLOR_SUCCESS,

  // Closed
  Closed: COLOR_SUCCESS,

  // New
  New: COLOR_PRIMARY,

  // NotApplicable
  NotApplicable: COLOR_DANGER,

  // Late
  Late: COLOR_WARNING,

  // Overdue
  Overdue: COLOR_DANGER,

  // Planned
  Planned: COLOR_PLANNED,

  // Not in BE translation files
  // Compliant
  Compliant: COLOR_SUCCESS,

  // Not Compliant
  NotCompliant: COLOR_DANGER,

  // Scored
  Scored: COLOR_SUCCESS,

  // Open
  Open: COLOR_PRIMARY,

  ClosedNotFinished: COLOR_DANGER,

  Undefined: COLOR_PRIMARY
};

enum LegendColor {
  default = 'rgba(134, 156, 179, 0.75)',
  success = 'rgba(57,191,124, 0.75)',
  warning = 'rgba(255, 143, 64, 0.75)',
  danger = 'rgba(255, 77, 91, 0.75)',
  primary = 'rgba(64, 127, 255, 0.75)',
  planned = 'rgba(242, 202, 0, 0.75)'
}

export const LEGEND_COLORS: Record<string, string> = {
  Initial: LegendColor.default,
  Approved: LegendColor.success,
  Suspended: LegendColor.warning,
  Aborted: LegendColor.danger,
  Rejected: LegendColor.danger,
  InProgress: LegendColor.primary,
  InReview: LegendColor.warning,
  Completed: LegendColor.success,
  Finished: LegendColor.success,
  Closed: LegendColor.success,
  New: LegendColor.primary,
  NotApplicable: LegendColor.danger,
  Late: LegendColor.warning,
  Overdue: LegendColor.danger,
  Compliant: LegendColor.success,
  NotCompliant: LegendColor.danger,
  Scored: LegendColor.success,
  Open: LegendColor.primary,
  ClosedNotFinished: LegendColor.danger,
  Undefined: LegendColor.primary,
  Evaluate: LegendColor.planned,
  Effective: LegendColor.success,
  PartiallyEffective: LegendColor.warning,
  NotEffective: LegendColor.danger
};

export const BASE_CHART_COLORS = [
  'rgba(136, 46, 114, 0.6)',
  'rgba(25, 101, 176, 0.6)',
  'rgba(67, 125, 191, 0.6)',
  'rgba(97, 149, 207, 0.6)',
  'rgba(123, 175, 222, 0.6)',
  'rgba(144, 201, 135, 0.6)',
  'rgba(202, 224, 171, 0.6)',
  'rgba(247, 240, 86, 0.6)',
  'rgba(247, 203, 69, 0.6)',
  'rgba(244, 167, 54, 0.6)',
  'rgba(238, 128, 38, 0.6)',
  'rgba(230, 85, 24, 0.6)',
  'rgba(220, 5, 12, 0.6)',
  'rgba(165, 23, 14, 0.6)'
];

export const ACTION_CONFIG = {
  'start-assessement': {
    color: 'success'
  },
  'finish-activity': {
    color: 'success'
  },
  'start-activity': {
    color: 'primary'
  },
  'suspend-activity': {
    color: 'warning'
  },
  'restart-activity': {
    color: 'primary'
  },
  'to-review': {
    color: 'primary'
  },
  'close-activity': {
    color: 'danger'
  },
  'create-frequency': {
    color: 'primary'
  },
  'ADD_NEW': {
    icon: 'add',
    label: 'form.add_another'
  },
  'CONTINUE': {
    icon: 'arrow-right',
    label: 'continue'
  },
  'DEFAULT': {
    icon: 'enter',
    label: 'form.to_overview'
  },
  'STOP': {
    icon: 'enter',
    label: 'form.to_overview'
  },
  'OPEN': {
    icon: 'arrow-right',
    label: 'open'
  },
  'DELETE': {
    icon: 'delete',
    label: 'form.delete'
  },
  'DELETE_SOME': {
    icon: 'delete',
    label: 'form.delete_some'
  },
  'CANCEL': {
    icon: 'close',
    label: 'cancel'
  },
  'push-updates': {
    color: 'warning'
  },
  'Accept-updates': {
    color: 'warning'
  },
  'add-sample': {
    color: 'primary'
  },
  'add-criterium': {
    color: 'primary'
  }
};
