import { createClass as e, classCallCheck as t } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { isConnection as n, isEndpoint as i, Components as r } from "../component/component.js";
import { forEach as u } from "../../util/util.js";
import { Connections as s, _removeAllOverlays as a, _setConnectionLabel as o, _addOverlay as c, _removeOverlay as h, _removeOverlays as l, _showOverlay as f, _hideOverlay as v } from "../connector/connections.js";
import { Endpoints as y } from "../endpoint/endpoint.js";
var m = function () {
  function m(e, n) {
    t(this, m);
    this.instance = e;
    this.entries = n;
  }
  e(m, [{
    key: "length",
    get: function e() {
      return this.entries.length;
    }
  }, {
    key: "each",
    value: function e(t) {
      u(this.entries, function (e) {
        return t(e);
      });
      return this;
    }
  }, {
    key: "get",
    value: function e(t) {
      return this.entries[t];
    }
  }, {
    key: "addClass",
    value: function e(t, r) {
      this.each(function (e) {
        if (n(e)) {
          s.addClass(e, t, r);
        } else if (i(e)) {
          y.addClass(e, t, r);
        }
      });
      return this;
    }
  }, {
    key: "removeClass",
    value: function e(t, r) {
      this.each(function (e) {
        if (n(e)) {
          s.removeClass(e, t, r);
        } else if (i(e)) {
          y.removeClass(e, t, r);
        }
      });
      return this;
    }
  }, {
    key: "removeAllOverlays",
    value: function e() {
      this.each(function (e) {
        if (n(e)) {
          a(e);
        }
      });
      return this;
    }
  }, {
    key: "setLabel",
    value: function e(t) {
      this.each(function (e) {
        if (n(e)) {
          o(e, t);
        }
      });
      return this;
    }
  }, {
    key: "clear",
    value: function e() {
      this.entries.length = 0;
      return this;
    }
  }, {
    key: "map",
    value: function e(t) {
      var n = [];
      this.each(function (e) {
        return n.push(t(e));
      });
      return n;
    }
  }, {
    key: "addOverlay",
    value: function e(t) {
      this.each(function (e) {
        if (n(e)) {
          c(e, t);
        }
      });
      return this;
    }
  }, {
    key: "removeOverlay",
    value: function e(t) {
      this.each(function (e) {
        if (n(e)) {
          h(e, t);
        }
      });
      return this;
    }
  }, {
    key: "removeOverlays",
    value: function e() {
      this.each(function (e) {
        if (n(e)) {
          l(e);
        }
      });
      return this;
    }
  }, {
    key: "showOverlay",
    value: function e(t) {
      this.each(function (e) {
        if (n(e)) {
          f(e, t);
        }
      });
      return this;
    }
  }, {
    key: "hideOverlay",
    value: function e(t) {
      this.each(function (e) {
        if (n(e)) {
          v(e, t);
        }
      });
      return this;
    }
  }, {
    key: "setPaintStyle",
    value: function e(t) {
      this.each(function (e) {
        return r.setPaintStyle(e, t);
      });
      return this;
    }
  }, {
    key: "setHoverPaintStyle",
    value: function e(t) {
      this.each(function (e) {
        return r.setHoverPaintStyle(e, t);
      });
      return this;
    }
  }, {
    key: "setParameter",
    value: function e(t, n) {
      this.each(function (e) {
        return e.parameters[t] = n;
      });
      return this;
    }
  }, {
    key: "setParameters",
    value: function e(t) {
      this.each(function (e) {
        return e.parameters = t;
      });
      return this;
    }
  }, {
    key: "setVisible",
    value: function e(t) {
      this.each(function (e) {
        if (n(e)) {
          s.setVisible(e, t);
        } else if (i(e)) {
          y.setVisible(e, t);
        }
      });
      return this;
    }
  }, {
    key: "addType",
    value: function e(t) {
      this.each(function (e) {
        return r.addType(e, t);
      });
      return this;
    }
  }, {
    key: "toggleType",
    value: function e(t) {
      this.each(function (e) {
        return r.toggleType(e, t);
      });
      return this;
    }
  }, {
    key: "removeType",
    value: function e(t) {
      this.each(function (e) {
        return r.removeType(e, t);
      });
      return this;
    }
  }, {
    key: "setHover",
    value: function e(t) {
      var n = this;
      this.each(function (e) {
        return n.instance.setHover(e, t);
      });
      return this;
    }
  }]);
  return m;
}();
export { m as SelectionBase };