import { classCallCheck as r, defineProperty as e } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { createAbstractBezierConnector as o } from "./abstract-bezier-connector.js";
import { Connectors as t } from "../core/connector/connectors.js";
import { computeBezierConnector as n, importBezierGeometry as s, exportBezierGeometry as i, transformBezierGeometry as c } from "./operations.js";
var a = "Bezier";
function m(r, e) {
  var t = o(a, r, e);
  var n = Object.assign(t, {
    curviness: e.curviness || 150,
    majorAnchor: e.curviness || 150,
    minorAnchor: 10
  });
  return n;
}
t.register(a, {
  compute: n,
  create: m,
  setAnchorOrientation: function r(e, o, t) {},
  geometryImporter: s,
  geometryExporter: i,
  transformGeometry: c
});
var p = function e() {
  r(this, e);
};
e(p, "type", a);
var u = function e() {
  r(this, e);
};
e(u, "type", a);
export { p as BezierConnector, a as CONNECTOR_TYPE_CUBIC_BEZIER, u as CubicBezierConnector, m as _createCubicBezierConnector };