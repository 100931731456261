<div class="content" [attr.data-test-id]="'option__' + id">
  @if (!!color) {
    <span
      class="color-box"
      [ngStyle]="{backgroundColor: color}"
      [matTooltip]="color"
      matTooltipPosition="right"
    ></span>
  }
  <div class="label">
    {{ label }}
    <ng-content />
  </div>
  @if (!!desc) {
    <naris-icon
      class="desc"
      icon="info-outline"
      [matTooltip]="('table.description' | translate) + ':&#13;&#13;' + desc"
      matTooltipPosition="above"
      matTooltipClass="desc-icon-tooltip"
    />
  }
  @if (helperText) {
    <div class="helper-text">{{ helperText }}</div>
  }
</div>
@if (icon) {
  <div class="append">
    <naris-icon [icon]="icon" class="icon" />
  </div>
}
