import { Pipe, PipeTransform } from '@angular/core';
import { compareObjects } from '@core/helpers';

@Pipe({
  name: 'compareObj',
  standalone: true
})
export class CompareObjPipe implements PipeTransform {

  public transform(value: Record<string, any>, compareObj: Record<string, any>) {
    return compareObjects(value, compareObj);
  }
}
