import { Component } from '@angular/core';
import { BaseNodeComponent } from '@jsplumbtoolkit/browser-ui-angular';
import { MatTooltip } from '@angular/material/tooltip';
import { IconComponent } from '../../../../elements/icon/icon.component';

@Component({
  selector: 'app-process-group',
  templateUrl: './process-group.component.html',
  styleUrls: ['./process-group.component.scss'],
  standalone: true,
  imports: [MatTooltip, IconComponent]
})
export class ProcessGroupComponent extends BaseNodeComponent {

  public toggleGroup() {
    this.surface.toggleGroup(this.getNode().id);
  }
}
