import { createClass as e, classCallCheck as s, defineProperty as t } from "../../_virtual/_rollupPluginBabelHelpers.js";
var r = function () {
  function r(e) {
    s(this, r);
    t(this, "vertices", void 0);
    this.vertices = [e];
  }
  e(r, [{
    key: "addVertex",
    value: function e(s) {
      this.vertices.push(s);
    }
  }]);
  return r;
}();
export { r as Cluster };