import { inherits as r, createSuper as t, classCallCheck as e, defineProperty as a, assertThisInitialized as s } from "../_virtual/_rollupPluginBabelHelpers.js";
import { AbstractEntry as i } from "./abstract-entry.js";
var n = function (i) {
  r(o, i);
  var n = t(o);
  function o(r, t) {
    var i;
    e(this, o);
    i = n.call(this, t);
    a(s(i), "type", "comment");
    a(s(i), "comment", void 0);
    i.comment = r;
    return i;
  }
  return o;
}(i);
export { n as CommentEntry };