var t = {
  Classes: {
    DIALOG: "jtk-dialog",
    UNDERLAY: "jtk-dialog-underlay",
    OVERLAY: "jtk-dialog-overlay",
    TITLE: "jtk-dialog-title",
    CONTENT: "jtk-dialog-content",
    BUTTONS: "jtk-dialog-buttons",
    OVERLAY_VISIBLE: "jtk-dialog-overlay-visible",
    BUTTON: "jtk-dialog-button",
    BUTTON_OK: "jtk-dialog-button-ok",
    BUTTON_CANCEL: "jtk-dialog-button-cancel",
    OVERLAY_TOP: "jtk-dialog-overlay-top",
    OVERLAY_BOTTOM: "jtk-dialog-overlay-bottom",
    OVERLAY_LEFT: "jtk-dialog-overlay-left",
    OVERLAY_RIGHT: "jtk-dialog-overlay-right",
    OVERLAY_X: "jtk-dialog-overlay-x",
    OVERLAY_Y: "jtk-dialog-overlay-y"
  },
  Attributes: {
    POSITION: "data-position",
    AXIS: "data-axis",
    JTK_CANCEL: "jtk-cancel",
    JTK_COMMIT: "jtk-commit",
    MULTIPLE: "multiple",
    JTK: "jtk-att",
    TITLE: "title",
    FOCUS: "jtk-focus",
    TYPE: "type",
    CANCEL: "cancel",
    ID: "id"
  },
  Values: {
    BLOCK: "block",
    VISIBLE: "visible",
    HIDDEN: "hidden",
    FIXED: "fixed",
    ABSOLUTE: "absolute",
    TOP: "top",
    BOTTOM: "bottom",
    X: "x",
    Y: "y",
    TRUE: "true",
    FALSE: "false",
    NONE: "none",
    PX: "px"
  },
  Events: {
    RESIZE: "resize",
    CLICK: "click",
    KEYUP: "keyup",
    SCROLL: "scroll"
  },
  Elements: {
    BUTTON: "button",
    DIV: "div",
    TEXT: "TEXT",
    RADIO: "RADIO",
    CHECKBOX: "CHECKBOX",
    SELECT: "SELECT",
    TEXTAREA: "TEXTAREA",
    COLOR: "COLOR",
    HIDDEN: "HIDDEN",
    INPUT: "INPUT",
    NUMBER: "NUMBER"
  }
};
function o(t) {
  return ["[", t, "]"].join("");
}
export { t as DialogConstants, o as attSel };