import { classCallCheck as r, defineProperty as o } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { _createConnectorBase as e } from "../core/connector/abstract-connector.js";
import { Connectors as t } from "../core/connector/connectors.js";
import { computeSegmentedConnector as n, importSegmentedGeometry as s, exportSegmentedGeometry as c, transformSegmentedGeometry as i } from "./operations.js";
var m = .15;
var a = "Segmented";
t.register(a, {
  compute: n,
  create: l,
  setAnchorOrientation: function r(o, e, t) {},
  geometryImporter: s,
  geometryExporter: c,
  transformGeometry: i
});
function l(r, o) {
  var t = e(a, r, o, [0, 0]);
  var n = Object.assign(t, {
    cornerRadius: o.cornerRadius != null ? o.cornerRadius : null,
    smooth: o.smooth === true,
    smoothing: o.smoothing == null ? m : o.smoothing,
    geometry: {
      segments: []
    }
  });
  return n;
}
var u = function o() {
  r(this, o);
};
o(u, "type", a);
export { a as CONNECTOR_TYPE_SEGMENTED, m as DEFAULT_SMOOTHING, u as SegmentedConnector, l as _createSegmentedConnector };