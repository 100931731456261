function i(i) {
  var e = i.graph,
    t = e.getVertexCount(),
    r = {},
    d = {},
    f,
    o,
    g;
  for (f = 0; f < t; f++) {
    var s = e.getVertexAt(f);
    if (!r[s.id]) r[s.id] = {};
    if (!d[s.id]) d[s.id] = {};
    r[s.id][s.id] = 0;
    for (o = 0; o < t; o++) {
      if (f != o) {
        var n = e.getVertexAt(o);
        if (!r[s.id][n.id]) r[s.id][n.id] = Infinity;
        if (!d[s.id][n.id]) d[s.id][n.id] = [];
      }
    }
    var a = s.getEdges();
    for (g = 0; g < a.length; g++) {
      if (a[g].source == s) {
        r[s.id][a[g].target.id] = a[g].getCost();
      } else {
        if (!r[a[g].source.id]) {
          r[a[g].source.id] = {};
          d[a[g].source.id] = {};
        }
        r[s.id][a[g].source.id] = a[g].getCost();
      }
    }
  }
  for (g = 0; g < t; g++) {
    for (f = 0; f < t; f++) {
      for (o = 0; o < t; o++) {
        if (f != o && o != g && f != g) {
          var u = e.getVertexAt(f).id,
            x = e.getVertexAt(o).id,
            V = e.getVertexAt(g).id;
          if (r[u][V] + r[V][x] <= r[u][x] && r[u][V] + r[V][x] != Infinity) {
            r[u][x] = r[u][V] + r[V][x];
            if (!d[u][x]) d[u][x] = [];
            d[u][x].unshift([e.getVertexAt(g), r[u][x]]);
          }
        }
      }
    }
  }
  return {
    paths: r,
    parents: d
  };
}
export { i as floydWarshall };