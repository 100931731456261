import { CdkDragEnd, CdkDragMove, CdkDrag } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CAT_TYPES } from '@core/constants/core-constants';
import { getPercentage } from '@core/helpers';
import { Participant } from '@core/classes';
import { CollabService, UserService } from '@core/services';
import { NgClass, NgStyle } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { IconComponent } from '@shared/elements/icon/icon.component';
import { ButtonComponent } from '@shared/elements/button/button.component';
import { FilterPipe } from '@shared/pipes/filter.pipe';
import { PointPipe } from '@shared/pipes/point.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import type { ICatClass, ICategory, IVote, IVoteAverage } from '@core/models';

@Component({
  selector: 'naris-heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.scss'],
  standalone: true,
  imports: [CdkDrag, NgClass, MatTooltip, NgStyle, IconComponent, ButtonComponent, FilterPipe, PointPipe, TranslateModule]
})
export class HeatmapComponent implements OnInit, OnChanges {
  @Input() public mode: 'overview' | 'voting';
  @Input() set category(value: ICategory | null) {
    this._category = value;
    const selfVote = this.collabService.expertVotes[this.collabService.currentCase!.id]?.[this.category!.key]?.find(x => x.user?.id === this.userService.idOfUser); 
    // if (!this.isHost && !this.blindVoting || this.isHost && this.mode === 'overview') this.getAverage();
    if (!!value && !selfVote) {
      if (value.voted && !!value.vote) this.currentVote = {likelihood: value.vote?.likelihood, impact: value.vote?.impact};
      else this.resetVote();
    }
  };
  get category(): ICategory | null {
    return this._category;
  }
  @Input() public categories: ICategory[] | null;
  @Input() public isHost: boolean;
  @Input() public blindVoting = false;
  @Input() public averageVotesInput: Record<string, IVote[]> = {};
  @Input() public experts: Participant[] = [];
  @Input() public selfId: string;
  @Input() public currentCaseId: number | string;
  @Input() public disabled = false;
  @Input() public expertVotes: Record<string, IVote[]> = {};
  @Input() public selectedClass: number;

  private _likelihood: number | string;
  @Input() set likelihood(value: number | string) {
    this._likelihood = value;
    if (!!this._impact && (!!this._likelihood || this._likelihood === 0))
      this.currentVote = {likelihood: this._likelihood as number, impact: this._impact as number};
  };
  get likelihood(): number | string {
    return this._likelihood;
  }

  private _impact: number | string;
  @Input() set impact(value: number | string) {
    this._impact = value;
    if (!!this._impact && (!!this._likelihood || this._likelihood === 0))
      this.currentVote = {likelihood: this._likelihood as number, impact: this._impact as number};
    this.voteColor = 'primary';
  };
  get impact(): number | string {
    return this._impact;
  }


  @Output() public readonly voted = new EventEmitter<{ category: ICategory; likelihood: number; impact: number }>();
  @Output() public readonly saveVote = new EventEmitter<{ category: ICategory; likelihood: number; impact: number }>();
  public dragDisabled = false;
  public averageVotes: Record<string, IVoteAverage> = {};
  public currentLikelihood: number | string = 50;
  public currentImpact: number | string = 50;
  public dragPositionConfig = { parentWidth: 321, parentHeight: 320, offset: 16 };
  public currentVote: {likelihood: number; impact: number; user?: Participant; impactLabel?: string } | undefined;
  public voteColor = 'primary';
  
  public averageTooltip: string;
  public showTooltipOverlay = false;
  private _category: ICategory | null;

  private readonly subs: Subscription[] = [];
  
  constructor(
    public readonly collabService: CollabService,
    private readonly userService: UserService
  ) { }


  public ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.category && changes.category.currentValue !== changes.category.previousValue) {
    //   if (this.collabService.votes && this.collabService.votes[this.collabService.currentCaseId!] && this.collabService.votes[this.collabService.currentCaseId!][changes.category.currentValue.key]) {
    //     this.currentLikelihood = this.collabService.votes[this.collabService.currentCaseId!][changes.category.currentValue.key].likelihood;
    //     this.currentImpact = this.collabService.votes[this.collabService.currentCaseId!][changes.category.currentValue.key].impact;
    //   } else {
      this.currentLikelihood = 50;
      this.currentImpact = 50;
    }

    // this.category!.class = changes.category.currentValue.class || this.category!.classes[0].value;

    // this.currentVote = { likelihood: this.currentLikelihood, impact: this.currentImpact };

    // this.setLikelihood(this.currentLikelihood);
    // this.setImpact(this.currentImpact);
    // }

    // if (!!changes.expertVotes && changes.expertVotes.currentValue?.length !== changes.expertVotes.previousValue?.length && this.mode === 'voting') {
    //   this.expertVotes = changes.expertVotes.currentValue.filter((x: Participant) => x.id !== this.selfId);
    // }

  }
  
  public ngOnInit(): void {
    if (this.isHost) {
      this.collabService.expertsUpdated$.subscribe((experts: Participant[]) => {
        this.experts = experts.filter(expert => expert.id !== this.selfId);
      });
    }

    this.subs.push(
      this.collabService.expertVotesFetched$.subscribe(() => {
        const selfVote = this.collabService.expertVotes[this.collabService.currentCase!.id]?.[this.category!.key]?.find(x => x.user?.id === this.userService.idOfUser);
        if (!!selfVote) this.currentVote = {
          likelihood: selfVote.likelihood!, 
          impact: selfVote.impact!,
          user: selfVote.user!,
          impactLabel: selfVote.impactLabel!
        };
      })
    );

    // this.collabService.updateAverage$.subscribe(() => {
    //   if (!this.isHost && !this.blindVoting || this.isHost && this.mode === 'overview') this.getAverage();
    // });
    this.category!.class = this.category!.classes[0].value;
  }
  // private getAverage() {
  //   if (Object.keys(this.collabService.expertVotes).length > 0) {
  //     const averageVotesInput = this.collabService.expertVotes[this.collabService.currentCaseId!][this.category!.key];
  //     if (averageVotesInput) {
  //       const averageVote = { likelihood: 0, impact: 0, votes: 0 };
  //       const amountOfVotes = averageVotesInput.length;
  //       const chooseExperts = amountOfVotes >= 3 ? true : false;
  
  //       for (const expertVote of averageVotesInput) {
  //         const { category, likelihood, impact } = expertVote;
  //         const expert = this.experts.find(e => e.id === expertVote.user?.id);
  
  //         if (category.key === this.category!.key && (chooseExperts && expert?.voteAverageIncluded || !chooseExperts)) {
  //           averageVote.likelihood = averageVote.likelihood + likelihood;
  //           averageVote.impact = averageVote.impact + impact;
  //           averageVote.votes = averageVote.votes + 1;
  //         }
  //       }
  
  //       averageVote.likelihood = Math.round(averageVote.likelihood / averageVote.votes);
  //       averageVote.impact = Math.round(averageVote.impact / averageVote.votes);
  
  //       this.averageVotes[Number(this.category!.key)] = { likelihood: averageVote.likelihood, impact: averageVote.likelihood };
  
  //       this.averageVotes[this.category!.key].likelihood = averageVote.likelihood;
  //       this.averageVotes[this.category!.key].impact = averageVote.impact;
  //     }
  //   }
  //   this.averageTooltip = `Average<br>Likelihood: ${this.averageVotes[this.category?.key ?? '']?.likelihood}%, Impact: ${this.averageVotes[this.category?.key ?? '']?.impact}%`;
  // }

  public onDragMoved(event: CdkDragMove): void {
    const currentPos = event.source.getFreeDragPosition();
    const parentConfig = { parentWidth: 320, parentHeight: 320, offset: 9 };
    const percentages = getPercentage(currentPos, parentConfig);
    this.currentLikelihood = percentages.x;
    this.currentImpact = percentages.y;
  }

  public onDragEnded(event: CdkDragEnd): void {
    if (this.disabled) return;
    const currentPos = JSON.parse(JSON.stringify(event.source.getFreeDragPosition()));
    // currentPos.y = currentPos.y + 28;
    // currentPos.x = currentPos.x + 11;
    // const parentConfig = { parentWidth: 321, parentHeight: 320, offset: 9 };
    // const percentages = getPercentage(currentPos, parentConfig);
    const diff = 318;
    const x = Math.round(((currentPos.x + 9) * 100) / diff);
    const y = 100 - Math.round(((currentPos.y + 9) * 100) / diff);
    // const percentages = {x: 50, y:50};
    this.currentLikelihood = x;
    this.currentImpact = y;
    this.currentVote = { impact: this.currentImpact, likelihood: this.currentLikelihood };
    
    this.voted.emit({category: this.category!, impact: y, likelihood: x}); 
  }

  public nextVote(category: ICategory): void {
    category.voted = true;
    this.saveVote.emit({ category, likelihood: +this.currentLikelihood, impact: +this.currentImpact });
    if (this.categories?.filter(c => !c.voted).length === 0) this.dragDisabled = true;
    // if (!this.isHost && !this.blindVoting || this.isHost && this.mode === 'overview') this.getAverage();
    this.voteColor = 'success';
  }

  public toggleVoteIncluded(expert: Participant) {
    for (const eVote of this.expertVotes[56]) {
      if (eVote.user?.id === expert.id) {
        expert.voteAverageIncluded = !expert.voteAverageIncluded;
        // this.getAverage();
      }
    }
  }

  public setImpactClass(index: number): void {
    if (!this.category || this.dragDisabled) return;
    this.category.class = this.category.classes[index].value;
  }

  // public setImpact(event: number | string) {
  //   event = Number(event);
  //   this.currentVote = { ...this.currentVote, impact: event };
  //   this.currentImpact = event;
  // }

  public setVoteClick(mouseEvent: MouseEvent) {
    if (this.disabled) return;
    const sourceElement = mouseEvent.target as HTMLElement;
    const x = Math.round((100 * mouseEvent.offsetX) / sourceElement.offsetWidth);
    const y = Math.round(100 - ((100 * mouseEvent.offsetY) / sourceElement.offsetHeight));
    this.voted.emit({category: this.category!, impact: y, likelihood: x}); 
  }

  // public setLikelihood(event: number | string) {
  //   event = Number(event);
  //   this.currentVote = { ...this.currentVote, likelihood: event };
  //   this.currentImpact = event;
  // }

  public setToAverage() {
    this.currentVote = { impact: this.averageVotes[this.category!.key].impact, likelihood: this.averageVotes[this.category!.key].likelihood };
    this.currentLikelihood = this.averageVotes[this.category!.key].likelihood;
    this.currentImpact = this.averageVotes[this.category!.key].impact;
  }

  public isVoteIncluded(eVote: IVote): boolean {
    return !this.experts.find(e => e.id === eVote.user?.id)?.voteAverageIncluded || false;
  }

  public resetVote(): void {
    if (!this.category) return;
    this.currentVote = undefined;
    this.currentLikelihood = 50;
    this.currentImpact = 50;
    this.category.voted = false;
    this.dragDisabled = false;
  }

  public trackByIndex(index: number): number {
    return index;
  }

  public getCatPosition(index: number, parentHeight: number, offset: number) {
    if (!this.category) return;
    const catLength = this.category.classes.length;
    return (index + 1) * (parentHeight / catLength) - offset;
  }

  public classString(catClass: ICatClass) {
    const catType = this.category?.type;
    if (catType === CAT_TYPES.OTHER) return catClass.key;
    else {
      const prefix = catType === CAT_TYPES.FINANCIAL ? '€' : '';
      return `${catClass.key} (max. ${prefix}${catClass.max.toLocaleString()})`;
    }
  }

  public expertHasVoted(expert: Participant): boolean{
    if (!this.expertVotes || this.expertVotes[this.category?.key ?? '']?.length === 0) return false;
    return this.expertVotes[this.category?.key ?? '']?.some(vote => vote.user?.id === expert.id || vote.user?.id === +expert.id);
  }
}
