import { KeyValue, NgTemplateOutlet, NgClass, DatePipe, KeyValuePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DateTime } from 'luxon';
import { JsonViewerComponent } from '@shared/components/json-viewer/json-viewer.component';
import { BeinformedService, FileService, TableService } from '@core/services';
import { MatTooltip } from '@angular/material/tooltip';
import { ToFrequencyStringPipe } from '@shared/pipes/frequency.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ExtractDocumentActionsPipe } from '@shared/pipes/extract-document-actions.pipe';
import { CardComponent } from '../card/card.component';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { IconComponent } from '../../elements/icon/icon.component';
import { ButtonComponent } from '../../elements/button/button.component';
import { AuditExecutionHistoryPropertiesComponent } from './audit-execution-history-properties/audit-execution-history-properties.component';
import { AuditExecutionHistoryArrayComponent } from './audit-execution-history-array/audit-execution-history-array.component';

@Component({
  selector: 'naris-audit-execution-history-viewer',
  templateUrl: './audit-execution-history-viewer.component.html',
  styleUrls: ['./audit-execution-history-viewer.component.scss'],
  standalone: true,
  imports: [CardComponent, ToolbarComponent, IconComponent, AuditExecutionHistoryPropertiesComponent, NgTemplateOutlet, AuditExecutionHistoryArrayComponent, NgClass, ButtonComponent, MatTooltip, DatePipe, KeyValuePipe, ToFrequencyStringPipe, TranslateModule, ExtractDocumentActionsPipe]
})
export class AuditExecutionHistoryViewerComponent extends JsonViewerComponent {

  @Input() public allInOne = false;

  public executionsHeaderExpanded = false;

  public originalOrderHistory = () => 0;

  constructor(
    private readonly fileService: FileService,
    private readonly beinformedService: BeinformedService,
    private readonly tableService: TableService
  ) {
    super();
  }

  public getValue(propertyValue: Record<string, any>): string {
    return propertyValue[Object.keys(propertyValue)?.[0]];
  }

  public getValues(propertyValue?: Record<string, any>): { label: string; value: string }[] {
    return Object.keys(propertyValue?.[0]).map(key => ({label: key, value: propertyValue?.[0]?.[key]}));
  }

  public getLabel(propertyValue: Record<string, any>): string {
    return Object.keys(propertyValue)?.[0];
  }

  public objectHasArray(propertyValue: any): boolean {
    if (this.isObject(propertyValue)) return false;
    return Object.keys(propertyValue?.[0])?.some(key => this.isArray(propertyValue?.[0]?.[key]));
  }

  public getFirstObjectName(data: Record<string, any>): string {
    return Object.keys(data)?.[0];
  }

  public onlyHasProperties(property: KeyValue<number, string>): boolean {
    const keys = Object.keys(property.value);
    return keys.includes('history.properties') && keys.length === 1;
  }

  public valueIsBoolean(value: any) {
    return typeof value === 'boolean';
  }

  public isReqularItem(key?: string): boolean {
    if (!key) return false;
    return !['history.frequency', 'history.root_properties', 'history.properties', 'history.record'].includes(key);
  }

  public isNaN(value: any): boolean {
    return isNaN(value);
  }

  public isDate(value: any): boolean {
    if (isNaN(value)) return DateTime.fromISO(value).isValid;
    return false;
  }

  public isDocumentAction(value: any): boolean {
    return value.includes('document_');
  }

  public performDocumentAction(href: string) {
    if (href.includes('add-document')) {
      this.beinformedService.handlePost(href);
      this.tableService.reloadPanel.next();
    }
    if (href.includes('download-document')) this.fileService.downloadFile(href);
  }

  public extractDocumentActions(actions: any[]): any[] {
    const convertedArr: any[] = [];
    actions.forEach(action => {
      convertedArr.push(Object.entries(action));
    });
    return convertedArr;
  }

  public extractIcon(icon: string): string {
    return icon.replace('history.', '');
  }
}
