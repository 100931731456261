import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, forwardRef } from '@angular/core';
import { BeinformedService, TaskgroupService } from '@core/services';
import { OrdinalPipe } from '@app/shared/pipes/ordinal.pipe';
import { IAction, IFormLookupCreateSuccess, ITaskGroup } from '@core/models';
import { TranslateSelectorPipe } from '@app/shared/pipes/translate-selector.pipe';
import { FrequencyService } from '@core/services/frequency.service';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { NgClass } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { ToolbarItemComponent } from '../toolbar/toolbar-item/toolbar-item.component';
import { IconComponent } from '../../elements/icon/icon.component';
import { ButtonComponent } from '../../elements/button/button.component';
import { CardComponent } from '../card/card.component';
import { FormComponent } from '../../../core/form/form.component';
import { LoaderComponent } from '../loader/loader.component';

@Component({
  selector: 'naris-frequency',
  templateUrl: './frequency.component.html',
  styleUrls: ['./frequency.component.scss'],
  providers: [OrdinalPipe, TranslateSelectorPipe],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [ToolbarComponent, ToolbarItemComponent, IconComponent, ButtonComponent, MatExpansionPanel, NgClass, MatExpansionPanelHeader, MatExpansionPanelTitle, MatTooltip, CardComponent, forwardRef(() => FormComponent), LoaderComponent, TranslateModule]
})
export class FrequencyComponent implements OnInit {

  @Input() public href: string;
  @Input() public layouthint: string[];
  @Input() public title: string;
  @Input() public frequencyObject: any;
  @Input() public isFormInput = false;
  @Input() public tabHref: string;
  @Input() public id: string;
  @Output() public readonly loaded = new EventEmitter<boolean>();
  @Output() public readonly frequencyLoaded = new EventEmitter<boolean>();

  public frequency: any;
  public taskgroup: ITaskGroup;
  public loading = true;
  public showHeader = false;

  public frequencyAction: IAction | null;
  public formExpanded = false;

  constructor(
    private readonly beinformedService: BeinformedService,
    private readonly frequencyService: FrequencyService,
    private readonly taskgroupService: TaskgroupService
  ) {}

  public ngOnInit(): void {
    this.fetchFrequency();

    this.showHeader = !!this.layouthint?.includes('frequency-with-header');
  }

  private fetchFrequency(): void {
    this.loading = true;
    if (!this.frequencyObject) {
      this.beinformedService.fetchResponseWithContributions<'caselist'>(this.href).subscribe(result => {
        this.taskgroup = this.beinformedService.extractTaskGroup<'caselist'>(result);
        this.frequency = this.beinformedService.extractResults(result)?.[0];
        this.loading = false;
        this.frequencyLoaded.emit(true);
      });
    } else {
      this.frequency = this.frequencyObject;
      this.loading = false;
      this.frequencyLoaded.emit(true);
    }
  }

  get deadlineString(): boolean | string {
    return this.frequencyService.deadlineString(this.frequency);
  }

  get frequencyString(): string {
    return this.frequencyService.frequencyString(this.frequency);
  }

  get frequencyPeriod() {
    return this.frequencyService.frequencyPeriod(this.frequency);
  }

  public update(): void {
    this.fetchFrequency();
    this.loaded.emit();
  }

  get hasCreateAction(): boolean {    
    this.frequencyAction = this.taskgroup?.actions?.find(action => action.type === 'create') || null;
    return !!this.frequencyAction;
  }

  get hasUpdateAction(): boolean {    
    this.frequencyAction = this.frequency?.actions?.find((action: IAction) => action.type === 'update') || null;
    return !!this.frequencyAction;
  }

  public toggleEdit() {
    this.formExpanded = !this.formExpanded;
  }

  public dynamicSaved(_event: IFormLookupCreateSuccess, panel: MatExpansionPanel) {
    panel.close();
    this.formExpanded = false;
    this.fetchFrequency();
    if (_event.refresh) this.taskgroupService.refreshPage.next(true);
  }
}
