import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { ENTER, COMMA, SPACE } from '@angular/cdk/keycodes';
import { ComponentType } from '@angular/cdk/portal';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipInput, MatChipGrid, MatChipRow, MatChipRemove } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { FormInput } from '@core/classes';
import { FORM_LOOKUP_TOKEN } from '@core/constants';
import { EAuthContext } from '@core/enums';
import { FormLookupComponent } from '@core/form/form-lookup/form-lookup.component';
import { IFormLookupData, ICaseListRow, INarisOption, IInputOption, TValOrArr, ILookup } from '@core/models';
import { AuthService, BeinformedService, FormService, HttpService, TableService } from '@core/services';
import { tap, debounceTime, switchMap, finalize, filter, startWith, map, Observable, Subscription, catchError } from 'rxjs';
import { NgClass, NgStyle, AsyncPipe } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatOption } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';
import { ButtonComponent } from '../button/button.component';
import { OptionComponent } from '../option/option.component';

@Component({
  selector: 'naris-autocomplete-multi',
  templateUrl: './autocomplete-multi.component.html',
  styleUrl: './autocomplete-multi.component.scss',
  standalone: true,
  imports: [FormsModule, MatAutocompleteTrigger, MatChipInput, ReactiveFormsModule, NgClass, IconComponent, ButtonComponent, MatTooltip, NgStyle, MatChipGrid, MatChipRow, MatChipRemove, MatAutocomplete, MatOption, OptionComponent, AsyncPipe, TranslateModule]
})
export class AutocompleteMultiComponent implements OnInit {

  @ViewChild(MatAutocompleteTrigger)
  public autocomplete: MatAutocompleteTrigger;

  // Control which is given by the form group
  @Input() public control: FormControl & Record<string, any>;
  private _input: FormInput;
  @Input() set input(val: FormInput) {
    this._input = val;
    this.setInputData();
    this.initialize();
  }
  get input(): FormInput {
    return this._input;
  }
  @Input() public isFilter: boolean;
  @Input() public formGroup: FormGroup;
  @Input() public isContextChips = false;
  @Input() public id: string;

  @Output() public readonly autoSubmit = new EventEmitter<void>();

  public loading = false;
  public focused = false;
  public lookup?: ILookup;
  public layouthint: string[] = [];
  public selection = new SelectionModel<INarisOption>(true, []);
  public autocompleteInput = new FormControl<string | IInputOption & INarisOption>('');
  public filteredOptions: Observable<INarisOption[]>;
  public readOnly = false;
  public options?: IInputOption[] | INarisOption[];
  public tagControl?: AbstractControl | null;
  public readonly separatorKeysCodes = [ENTER, COMMA, SPACE];
  
  private extraDataName: string;
  private readonly subs: Subscription[] = [];
  private lastOptions: INarisOption[];

  constructor(
    private readonly httpService: HttpService,
    private readonly formService: FormService,
    private readonly tableService: TableService,
    private readonly authService: AuthService,
    private readonly dialog: MatDialog,
    private readonly beinformedService: BeinformedService,
    @Inject(FORM_LOOKUP_TOKEN) private readonly formLookupComponent: ComponentType<FormLookupComponent>
  ) {}

  public ngOnInit(): void {
    if (this.input?.id === 'TagIDs' && !!this.formService.currentForm?.elements.some(({ elementid }) => elementid === 'TagNamesNew')) this.tagControl = this.formGroup.get('TagNamesNew');
  }

  private setInputData() {
    if (!!this.input.lookup) this.lookup = this.input.lookup;
    if (!!this.input.options) this.options = this.input.options;
    this.layouthint = this.input.layouthint || [];
  }

  private initialize() {
    if (!!this.control.disabled) this.autocompleteInput.disable();
    this.control['setSelection'] = (selection: any, dynamicOptions: INarisOption[]) => this.setSelection(selection, dynamicOptions);
    // If lookup is set, enable lookup watcher on form control
    if (!!this.lookup) {
      this.filteredOptions = this.autocompleteInput.valueChanges.pipe(
        tap((value: any) => {
          if (!!value)
            this.loading = true;
        }),
        debounceTime(500),
        switchMap(value => this.fetchOptions(value as string).pipe(
          finalize(() => this.loading = false)
        ))
      );
      this.selection.changed.pipe(filter(this.filterSelection as any)).subscribe(this.handleSelection);
      // if (!!this.input?.value && !!this.input?.dynamicOptions?.length) this.setSelection(this.input.value, this.input.dynamicOptions);
      if (!!this.input?.value && (this.input?.type === 'array' && !Array.isArray(this.input.value) || this.input?.type !== 'array' && typeof this.input.value !== this.input?.type) && this.input.value.some((x: any) => !x.val || !x.value)) {
        this.setMissingValues(this.lookup?.list || '', this.input.value, this.input?.type?.toLowerCase() === 'array');
      } else if (!!this.input?.value && (this.input?.type === 'array' && Array.isArray(this.input.value) )) {
        this.setSelection(this.input.value, this.input?.dynamicOptions);
      } else if (!!this.control.value) {
        this.setSelection(this.control.value, this.input?.dynamicOptions);
      }
    }

    this.control.valueChanges.subscribe(val => {
      if (!val) {
        this.control.setErrors(null);
        this.selection.clear();
        this.autocompleteInput.setValue('', {emitEvent: false});
      }
    });

    // Enable autocomplete filtered options for static injected options
    if (!this.lookup && !!this.options) {
      if (!this.options.length && !!this.input?.disabled && !!this.input?.value && !!this.input.dynamicOptions?.length) {
        this.setSelection(this.input.value, this.input.dynamicOptions);
      }
      this.filteredOptions = this.autocompleteInput.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value?.label),
          // tap(val => this.control.setValue(val)),
          map(option => option ? this._filter(option) : this.options!.slice())
        ) as Observable<INarisOption[]>;
      this.selection.changed.pipe(filter(this.filterSelection as any)).subscribe(this.handleSelection);
      if (!!this.input?.value && !!this.input.dynamicOptions?.length) {
        this.setSelection(this.input.value, this.input.dynamicOptions);
      }
    }

    if (!!this.layouthint) this.interpretLayoutHint();
    
    this.subs.push(
      this.tableService.filterFormClicked.subscribe((_event: Event) => this.autocomplete?.closePanel()),
      this.formService.updateObjectForm$.subscribe(() => this.interpretLayoutHint())
    );
  }

  public setSelection(value: any, dynamicOptions?: INarisOption[]) {
    this.selection.clear();
    if (!value) {
      this.autocompleteInput.setValue('');
      return;
    }
    this.formService.resetControlError$.next(this.input?.elementId);
    const lookupFilter = this.lookup?.filter?.name;
    if (!!dynamicOptions && value.length === dynamicOptions.length) {
      const initOptions = value?.map((val: any) => {
        const dynamic = dynamicOptions.find(opt => opt.value === val.val?.toString() || opt.value === val.toString());
        return {value: /^\d+$/.test(dynamic?.value) ? +dynamic?.value : dynamic?.value, label: dynamic?.label || val.value};
      }).filter((val: any) => {
        const existingVals = this.selection.selected.map((x: any) => x.value);
        return !existingVals.includes(val.value);
      });
      this.selection.select(...initOptions);
    } else if (Array.isArray(value)) {
      const mapped = value.map(val => {
        const valU = val._id || val.code || val.val || val.value || val;
        let valLabel: string = val.label || val.value || val.name || '';
        const nameKey = Object.keys(val).find(key => key.toLowerCase().endsWith('name'));
        if (!!lookupFilter && !!val[nameKey || lookupFilter]) valLabel = val[nameKey || lookupFilter];
        if (this.isContextChips) {
          if (!!val.context) return val;
          const contextLabel = (val.PrimaryContext as []).join(', ');
          return !!this.extraDataName ? {value: valU, label: valLabel, [this.extraDataName]: val[this.extraDataName], context: contextLabel} : {value: valU, label: valLabel, context: contextLabel};
        } else {
          return !!this.extraDataName ? {value: valU, label: valLabel, [this.extraDataName]: val[this.extraDataName]} : {value: valU, label: valLabel};
        }
      });
      this.selection.select(...mapped);
      if (!!value?.length && typeof value[0] === 'number')
        this.setMissingValues(this.lookup?.list || '', value, this.input?.type?.toLowerCase() === 'array');
      if (!!mapped?.length)
        mapped.forEach(mapItem => {
          if (typeof mapItem.value === 'number' && mapItem.value.toString() === mapItem.label)
            this.setMissingValues(this.lookup?.list || '', mapItem.value, this.input?.type?.toLowerCase() === 'array');
        });
    }
    if (this.input?.layouthint?.includes('auto-submit')) this.autoSubmit.emit();
  }

  public openAdvancedLookup() {
    this.dialog.open<FormLookupComponent, IFormLookupData, ICaseListRow | string>(this.formLookupComponent, {
      panelClass: 'naris-advanced-lookup-dialog',
      minWidth: '65rem',
      maxHeight: '98vh',
      data: {
        endpoint: this.lookup?.list,
        multiple: true,
        selected: this.selection.selected,
        layouthint: this.input?.layouthint,
        createAction: !!this.input?.createEndpoint,
        createEndpoint: this.input?.createEndpoint
      },
      position: {left: '11%'}
    }).afterClosed().subscribe(val => {
      if (!val) return;
      else if ((val as any).label === 'lookupCreate') this.lookupCreateAction((val as any).result?.redirect);
      else this.setSelection(val);
    });
  }

  public lookupCreateAction(redirect: string) {
    if ((redirect.match(/\//g) || []).length > 1) {
      this.beinformedService.fetchResponseWithContributions(redirect).subscribe(result => {
        const attributes = this.beinformedService.extractAttributes(result);
        const label = attributes?.find((attr: any) => attr.layouthint?.includes('title'))?.valueLabel || attributes?.[0].valueLabel;
        const id = redirect.split('/').pop();
        this.setCreated(id, label);
      });
    }
  }

  public onClearSelection() {
    this.selection.clear();
    this.autocompleteInput.setValue('');
  }

  public onOptionSelected(event: MatAutocompleteSelectedEvent) {
    if (this.input?.layouthint?.includes('auto-submit')) {
      this.autoSubmit.emit();
    } else {
      this.toggleOption(event.option.value as INarisOption);
    }
  }

  public toggleOption(option: INarisOption & Record<string, any>) {
    if (!!this.input?.dependencyColumnEndpoint) {      
      const body = {Input: option.value};
      this.httpService.post(this.input.dependencyColumnEndpoint, body).subscribe(res => {
        const jsonRes = JSON.parse(res?.formresponse?.success?.data?.GetDependencyColumn_Result?.Result);
        option[this.extraDataName] = jsonRes[0][this.extraDataName];
        this.setSelectedOption(option);
      });
    } else {
      this.setSelectedOption(option);
    }
  }

  public optionSelected(option: INarisOption) {
    const selected = this.selection.selected.find(opt => opt._id?.toString() === option.value?.toString() || opt.value?.toString() === option.value?.toString());
    return !!selected;
  }

  public handleChipInput({ value }: MatChipInputEvent) {
    const options = this.lastOptions || this.options || [];
    const inOptions = options.some(({ label }) => label === value);
    const existing = this.selection.selected.some(({ value: optVal }) => optVal === value);
    if (!!value && !inOptions && !existing) {
      const opt = {label: value, value, isTag: true} as INarisOption;
      this.selection.select(opt);
      this.autocompleteInput.setValue(null);
      const tagVal = this.tagControl?.value ? `${this.tagControl.value} ${value}` : value;
      this.tagControl?.setValue(tagVal);
    }
  }

  public onChipRemove(chip: TValOrArr<INarisOption>) {
    const chipOption = chip as INarisOption;
    this.selection.deselect(chipOption);
    
  }

  public toOption(value: TValOrArr<INarisOption>) {
    return value as INarisOption;
  }

  /**
   * Function to transform value that is shown in the input field.
   * Shows the option label by default, and falls back on the value.
   * @param option INarisOption passed from autocomplete
   */
  public autocompleteLabel() {
    return '';
  }

  private setSelectedOption(option: INarisOption) {
    if (this.optionSelected(option)) {
      const selectedOption = this.selection.selected.find(opt => opt._id?.toString() === option.value?.toString() || opt.value?.toString() === option.value?.toString());
      this.selection.deselect(selectedOption!);
    } else {
      this.selection.select(option);
      this.control?.markAsDirty();
      this.control?.updateValueAndValidity();
    }
  }

  private setCreated(id?: number | string, label?: string) {
    if (!id || !label) return;
    const returnObj = this.input?.multiple ? [{ value: id, code: id, label }] : { value: id, code: id, label };
    if (!!this.control['setSelection'] && typeof this.control['setSelection'] === 'function') this.control['setSelection'](returnObj, null);
    else this.control?.setValue(id);
  }

  private readonly filterSelection = ({ added, removed }: SelectionChange<INarisOption>) => !added[0]?.isTag && !removed[0]?.isTag;
  private readonly handleSelection = (selectionChange: SelectionChange<INarisOption>) => {
    if (!this.input?.disabled && !this.autocompleteInput.touched) this.autocompleteInput.markAsTouched();
    const selected = selectionChange.source.selected;
    if (Array.isArray(selected)) {
      const valuesWithExtraData = (selected as (INarisOption & Record<string, any>)[]).map(v => ({id: v.value, [this.extraDataName]: v[this.extraDataName] }));
      const mappedSourceVal = !!this.extraDataName ? valuesWithExtraData : selected.map(v => v.value);
      this.control?.setValue(!!this.isFilter ? selected : mappedSourceVal);
    } else this.control?.setValue(selected);
  };

  private interpretLayoutHint() {
    this.layouthint.forEach(hint => {
      if (hint.startsWith('dependency-column: ')) {
        this.extraDataName = hint.replace('dependency-column: ', '');
      }
      if (hint === 'read_only' || this.control.disabled) {
        this.readOnly = true;
        this.autocompleteInput.disable();
      } else {
        this.readOnly = false;
        this.autocompleteInput.enable();
      }
    });
  }

  private fetchOptions(filterString: string): Observable<INarisOption[]> {
    if (!filterString || typeof filterString !== 'string') // If filterString is not a string, we don't know what to filter => return;
      return new Observable(observer => {
        observer.next(undefined);
        observer.complete();
      });
    const endpoint = this.lookup?.filter
      ? `${this.lookup.href}&${this.lookup.filter[Object.keys(this.lookup.filter)[0]]}=${filterString}`
      : this.lookup?.href;
    const isRest = this.authService.authContext === EAuthContext.SSO;
    return this.httpService.get(endpoint!, isRest).pipe(
      catchError(() => []),
      map(result => {
        const mapped = result.lookup?.options?.map((option: any) => {
          let label = option.label || '';
          if (option.elements) label = this.mapEl(option.elements);
          return {
            value: /^\d+$/.test(option.code) ? +option.code : option.code,
            code: /^\d+$/.test(option.code) ? +option.code : option.code,
            label
          };
        });
        this.lastOptions = mapped;
        return mapped;
      })
    );
  }

  /**
   * Filter for autocomplete options
   * @param value value taken from the input field host
   */
  private _filter(value: string): INarisOption[] {
    const filterValue = value.toLowerCase();
    return (this.options as INarisOption[]).filter(option => option.label.toLowerCase().includes(filterValue));
  }

  private mapEl(elementsObj: any) {
    const filterName = this.lookup?.filter?.name;
    if (typeof elementsObj !== 'object') return elementsObj;
    if (!!filterName) return elementsObj[filterName] || elementsObj[Object.keys(elementsObj)[0]];
    return Object.values(elementsObj).filter(el => !!el).join(' | ');
  }

  private setMissingValues(href: string, value: any, isArray: boolean) {
    if (!value || Array.isArray(value) && !value?.length) return;
    this.beinformedService.fetchResponseWithContributions(href, false).subscribe(res => {
      if (!!res?.data?._embedded?.results.length) {
        const itemKey = Object.keys(res.data._embedded.results[0])[0];
        const val = res.data._embedded.results.filter((item: any) => Array.isArray(value) ? value.includes(item[itemKey]._id) : value === item[itemKey]._id).map((item: any) => item[itemKey]);
        if (!val?.length) return;
        else this.setSelection(isArray ? val : val[0]);
      }
    });
  }
}
