import { filterEmpty as e } from "../core/util.js";
import { uuid as n, fastTrim as r } from "../ui-core/util/util.js";
function t(n, r) {
  if (n == null) {
    return n;
  } else {
    var t = n.replace(/\n/g, "").replace(/^[\s]+</, "<").replace("/>", ">").replace(/>[\s]+$/, ">").split(/^<|>$/)[1].split(r);
    return e(t);
  }
}
function l(e) {
  return e.length > 0 ? e[e.length - 1] : null;
}
function u(e) {
  if (e == null || e.length === 0) {
    return false;
  } else {
    for (var n = e.length - 1; n > -1; n--) {
      if (e[n].type === "each") return true;
    }
  }
  return false;
}
function i(e, r, t, l, u) {
  var i = n(),
    s = {
      w: r,
      expressions: [],
      id: i
    };
  t.bindings[e] = s;
  r.replace(/\{\{([^\}]*)\}\}/g, function (e, n, r, t) {
    s.expressions.push([e, n]);
    return "";
  });
  return i;
}
function s(e, n, r, t, l) {
  r.atts[e] = n;
  i(e, n, r);
}
function a(e, n) {
  var l = t(e, n.attributesRe),
    u = {
      el: r(l[0]),
      atts: {},
      bindings: {}
    };
  var i = function e(n, r) {
    var t = n.match(/([^=]+)=['"](.*)['"]/);
    if (t == null && r == null) {
      u.atts[n] = "";
    } else if (t == null) {
      s(n, "", u);
    } else {
      s(t[1], t[2], u);
    }
    return t;
  };
  for (var a = 1; a < l.length; a++) {
    var f = r(l[a]);
    if (f != null && f.length > 0) {
      i(f);
    }
  }
  return u;
}
export { i as _addBinding, s as _bindOneAtt, u as isLoopPresent, t as parseAttributes, a as parseAtts, l as peek };