import { createClass as e, classCallCheck as t, defineProperty as r } from "../_virtual/_rollupPluginBabelHelpers.js";
import { EventManager as i } from "../ui-core/browser-ui-renderer/event-manager.js";
import { EVENT_TAP as n, EVENT_CONNECTION_CLICK as s } from "../ui-core/browser-ui-renderer/event-constants.js";
import { CONNECTOR_TYPE_STRAIGHT as a } from "../ui-core/core/connector/straight-connector.js";
import { BlankEndpoint as o } from "../ui-core/core/endpoint/blank-endpoint.js";
import { cls as c } from "../ui-core/core/constants.js";
import { AnchorLocations as l } from "../ui-core/core/factory/definitions.js";
import { BrowserJsPlumbInstance as p } from "../ui-core/browser-ui-renderer/browser-jsplumb-instance.js";
var u = "div";
var d = "data-value";
var h = "jtk-edge-type-picker";
var v = "jtk-edge-type-picker-selected";
var m = "jtk-edge-type-picker-source";
var f = "jtk-edge-type-picker-target";
var g = 5;
var j = function () {
  function j(e, s, a, o, c) {
    var l = this;
    t(this, j);
    this.toolkit = e;
    this.container = s;
    this.mappings = a;
    this.currentValue = o;
    this.onSelect = c;
    r(this, "eventManager", void 0);
    r(this, "jsplumb", void 0);
    r(this, "entryMap", new Map());
    this.eventManager = new i();
    this.eventManager.on(s, n, u, function (e) {
      var t = e.target.getAttribute(d);
      l.select(t);
    });
    this.container.classList.add(h);
    this.eventManager.on(window, "resize", function () {
      l.jsplumb && l.jsplumb.repaintEverything();
    });
  }
  e(j, [{
    key: "render",
    value: function e(t) {
      var r = this;
      this.container.innerHTML = "";
      var i = this.mappings.find(function (e) {
        return e.property === t;
      });
      this.jsplumb = new p(this.toolkit, {
        container: this.container
      }, {});
      this.jsplumb.bind(s, function (e) {
        r.select(e.data.id);
      });
      if (i != null) {
        for (var n in i.mappings) {
          var c = i.mappings[n];
          var h = document.createElement(u);
          h.setAttribute(d, n);
          if (n === this.currentValue) {
            h.classList.add(v);
          }
          var j = document.createElement(u);
          j.classList.add(m);
          j.style.left = "".concat(g, "px");
          h.appendChild(j);
          var y = document.createElement(u);
          j.classList.add(f);
          y.style.right = "".concat(g, "px");
          h.appendChild(y);
          var b = this.jsplumb.connect({
            source: j,
            target: y,
            connector: a,
            overlays: c.overlays,
            cssClass: c.cssClass,
            endpoint: o.type,
            anchor: l.Center,
            data: {
              id: n
            }
          });
          this.entryMap.set(n, {
            c: b,
            el: h
          });
          this.container.appendChild(h);
        }
      }
    }
  }, {
    key: "select",
    value: function e(t) {
      var r = this.entryMap.get(t);
      if (r) {
        this.currentValue = t;
        r.el.setAttribute(d, t);
        var i = this.container.querySelector(c(v));
        i && i.classList.remove(v);
        r.el.classList.add(v);
        this.onSelect && this.onSelect(t);
      }
    }
  }]);
  return j;
}();
export { j as EdgeTypePicker };