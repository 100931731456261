import { Component, ViewEncapsulation, Input, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList } from '@angular/cdk/drag-drop';
import { Subject, Subscription } from 'rxjs';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { BeinformedService, FormService, HttpService, SnackbarService, TabService, TreeviewService } from '@core/services';
import { LocalStorage } from '@core/decorators/storage.decorators';
import { getIconColumnName, transformResultsToItems } from '@core/helpers';
import { MatTooltip } from '@angular/material/tooltip';
import { MatProgressBar } from '@angular/material/progress-bar';
import { ButtonComponent } from '../../elements/button/button.component';
import { TaskgroupComponent } from '../taskgroup/taskgroup.component';
import { IconComponent } from '../../elements/icon/icon.component';
import { FormComponent } from '../../../core/form/form.component';
import { DragAndDropManagerRootDirective } from '../../directives/drag-and-drop-manager.directive';
import { LoaderComponent } from '../loader/loader.component';
import { TreeviewGroupComponent } from './treeview-group/treeview-group.component';
import { installPatch } from './drag-drop-monkey-patch';
import type { IAction, ITaskGroup, ITreeViewItem } from '@core/models';

@Component({
  selector: 'naris-treeview',
  templateUrl: './treeview.component.html',
  styleUrls: ['./treeview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [ButtonComponent, MatTooltip, TaskgroupComponent, IconComponent, FormComponent, CdkDropList, DragAndDropManagerRootDirective, TreeviewGroupComponent, MatProgressBar, LoaderComponent, TranslateModule]
})
export class TreeviewComponent implements OnInit, OnDestroy {
  @Input() public href?: string;
  @Input() public opened = false;
  @Input() public prefix?: string;
  @Input() public editMode = false;

  @Input() public grouplessNodeButtons: string[] = [];
  @Input() public showTreeActions = false;
  @Input() public onlyRedirect: boolean;

  @Input() set refresh(value: boolean) {
    if (value) {
      this.reload();
      this.refreshChange.emit(false);
    }
  }
  @Output() public readonly refreshChange = new EventEmitter();

  // ! selectedId and idName should both be set when setting selectedId
  @Input() public selectedId: number;
  @Output() public readonly selectedIdChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() public readonly selectedUrl = new EventEmitter<string>();
  
  @Output() public readonly submitSelection = new EventEmitter<any>();

  @Input() public idName: keyof ITreeViewItem;

  @Input() public popupData: { keyString: string; keyId: string; data: any[]; icon: string; label: string };

  @Input() public layouthint: string[];

  @Input() public data: any[];

  @Input() public compareObject: { isNewTree: boolean; showCompareColors: boolean };

  @Input() public enableLazyLoading = true;

  @Input() public roomName: string;

  @Input() public occupiedNodes: any[];

  @Input() public isAssetClassification = false;
  @Input() public selectedData: any[] = [];

  @LocalStorage() public treeviewSelectedItem: ITreeViewItem;
  public treeviewActions: IAction[] | undefined = [];
  public results: ITreeViewItem[];
  public taskgroup: ITaskGroup;
  public loading = false;
  public items: ITreeViewItem[];
  public onDragDrop$ = new Subject<CdkDragDrop<ITreeViewItem[]>>();
  public dragDropDisabled = true;
  public filterActive = false;
  public draggedIndex: number | null = null;
  public showToolbar = true;
  public title: string;
  private readonly subs: Subscription[] = [];
  public expandedLevel = -1;
  public selectedEmptyAction: string | undefined;

  private readonly objectsToShowOnClick: string[] = ['compliancestructureitem', 'complianceidentificationstructureview'];
  @Output() public readonly updated = new EventEmitter<boolean>();

  constructor(
    private readonly beinformedService: BeinformedService,
    private readonly httpService: HttpService,
    private readonly snackbar: SnackbarService,
    private readonly translate: TranslateService,
    private readonly treeviewService: TreeviewService,
    private readonly tabs: TabService,
    private readonly formService: FormService
  ) {
    installPatch();
  }
  public ngOnInit(): void {
    if (!this.data) {
      this.fetchPanel();
      this.subs.push(this.treeviewService.refresh$.subscribe(() => this.reload()));
    } else {
      this.items = this.data.map((i: any) => ({...i, opened: true}));
      this.loading = false;
      this.showToolbar = false;
      if (this.isProcessStructure) {
        this.mapProcessTree(this.items);
      } else {
        this.items = this.mapTreeView(this.items);
      }
    }
    this.subs.push(
      this.onDragDrop$.subscribe(this.onDragDrop),
      this.treeviewService.updatedItemIds$.subscribe(this.updateItems),
      this.treeviewService.deleteItem$.subscribe(this.deleteItem),
      this.formService.refreshAuditExeStructureSteps$.subscribe(keys => this.refreshItems(keys))
    );

    this.tabs.updateTab$.subscribe(() => {
      this.reload();
    });
  }

  get isProcessStructure(): boolean {
    return this.tabs.activeTab?.href.includes('/process/process/') && this.tabs.activeTab?.href.endsWith('/structure');
  }

  private mapTreeView(results: ITreeViewItem[]) {
    const map: Record<string, number> = {};
    const roots: ITreeViewItem[] = [];

    for (let i = 0; i < results?.length; i += 1) {
      map[results[i].id] = i;
      results[i].children = [];
    }

    results?.forEach( n => {
      if (n.parentId) results[map[n.parentId]]?.children?.push(n);
      else roots.push(n);
    });
    return roots;
  }

  private mapProcessTree(results: ITreeViewItem[]) {
    const subProcessIndexes: number[] = [];
    results.forEach((item, index) => {
      if (item.parentId !== 0 && item.type == 'Process') {
        const foundParent = results.find(treeItem => treeItem.id === item.parentId!.toString());
        if (!!foundParent) {
          if (!foundParent.children) foundParent.children = [];
          foundParent.children?.push(item);
          subProcessIndexes.unshift(index);
        }
      }
    });
    subProcessIndexes.forEach(index => results.splice(index, 1));
    this.items = results;
  }

  public reload(): void {
    this.expandedLevel = -1;
    this.treeviewService.expandCollapseLevel$.next({level: this.expandedLevel, expand: false});
    this.fetchPanel();
  }

  public refreshData() {
    this.items = [];
    this.expandedLevel = -1;
    this.treeviewService.expandCollapseLevel$.next({level: this.expandedLevel, expand: false});
    this.fetchPanel();
    this.updated.emit(true);
  }

  public collapse(): void {
    if (this.enableLazyLoading) {
      const level = --this.expandedLevel;
      this.expandedLevel = level < -1 ? -1 : level;
      this.treeviewService.expandCollapseLevel$.next({level: this.expandedLevel, expand: false});
    } else {
      this.setItemsOpened(this.items, false);
    }
  }

  public expand(): void {
    if (this.enableLazyLoading) {
      if (this.expandedLevel < this.treeviewService.maxLevel) {
        const level = ++this.expandedLevel;
        this.treeviewService.expandCollapseLevel$.next({level: level, expand: true});
      }
    } else {
      this.setItemsOpened(this.items, true);
    }
  }

  private setItemsOpened(items: ITreeViewItem[], value: boolean) {
    items.forEach(item => {
      item.opened = value;
      if (!!item.children?.length) {
        this.setItemsOpened(item.children, value);
      }
    });
  }

  private readonly fetchPanel = () => {
    this.loading = true;
    let url = this.href;
    if (this.enableLazyLoading && !!this.href) {
      url += '?IsParent=true';
    }
    this.beinformedService.fetchResponseWithContributions<'caselist'>(url  || '').subscribe(res => {
      const results = this.beinformedService.extractResults(res);
      const objectName = Object.keys(res.contributions.results)[0];
      const attributes = res.contributions.results[objectName].attributes;
      this.taskgroup = this.beinformedService.extractTaskGroup<'caselist'>(res);
      this.selectedEmptyAction = this.taskgroup.actions?.[0]?.name;
      
      const iconColName = getIconColumnName(res);

      this.dragDropDisabled = !results?.some(({ actions }) => actions?.some(({ name }) => name?.includes('move-')));
      this.results = results ? transformResultsToItems(this.getPopupItemsName() || '', this.opened, results, iconColName, attributes) : [];
      this.setSelected();
      this.loading = false;
      if (this.isAssetClassification) {
        if (!!this.layouthint?.length)
          this.layouthint.push('hide-status');
        else 
          this.layouthint = ['hide-status'];
        this.items = [];
        results.forEach(result => {
          const isChecked = !!this.selectedData?.length ? this.selectedData.some(x => x.value === result._id) : false;
          const name = (result as any).Name;
          const foundClassification = this.items.find(item => item.name === name);
          const classColor = (result as any).HEXColorCode;
          if (!foundClassification) {
            const aspectClass = {id: result._id, isEnabled: true, name: (result as any).Class, level: 1, opened: false, color: classColor, checked: isChecked} as ITreeViewItem;
            const aspect = {id: result._id, isEnabled: true, name: (result as any).Aspect, level: 1, opened: false, children: [aspectClass], hasChildren: true} as ITreeViewItem;
            const classification = {id: result._id, isEnabled: true, name: name, level: 0, opened: false, children: [aspect], hasChildren: true} as ITreeViewItem;
            this.items.push(classification);
          } else {
            const aspectName = (result as any).Aspect;
            const foundAspect = foundClassification.children?.find(child => child.name === aspectName);
            if (!foundAspect) {              
              const aspectClass = {id: result._id, isEnabled: true, name: (result as any).Class, level: 1, opened: false, color: classColor, checked: isChecked} as ITreeViewItem;
              const aspect = {id: result._id, isEnabled: true, name: (result as any).Aspect, level: 1, opened: false, children: [aspectClass], hasChildren: true} as ITreeViewItem;
              foundClassification.children?.push(aspect);
            } else {
              const aspectClass = {id: result._id, isEnabled: true, name: (result as any).Class, level: 1, opened: false, color: classColor, checked: isChecked} as ITreeViewItem;
              foundAspect.children?.push(aspectClass);
            }
          }
        });
        this.treeviewService.setItemChecked$.subscribe(item => {
          this.items.forEach(classification => {
            classification.children?.forEach(aspect => {
              const foundAspectClass = aspect.children?.find(aspectClass => aspectClass.id === item.itemId);
              if (!!foundAspectClass) {
                aspect.children?.forEach(aspectClass => aspectClass.checked = false);
                foundAspectClass.checked = true;
              }
            });
          });
        });
      } else if (this.isProcessStructure) {
        this.mapProcessTree(this.results);
      } else {
        this.items = this.mapTreeView(this.results);
        this.items = this.items.map(item => ({...item, level: 0}));
      }
      
      this.title = res?.contributions?.label;

      this.treeviewActions = this.taskgroup.actions;

      if (!this.enableLazyLoading) this.expand();
    });

  };

  private setSelected() {
    if (!!this.selectedId) {
      this.results.forEach(item => item.active = false);
      this.treeviewSelectedItem = {} as ITreeViewItem;
      const item = this.results?.find(x => x[this.idName] === this.selectedId);
      if (!!item) item.active = true;
    }
  }

  public itemSelected(item: ITreeViewItem) {
    this.results.forEach(x => x.active = x.id === item.id);
    this.selectedIdChange.emit(item[this.idName]);
  }

  private getPopupItemsName() {
    const layouthintFound = this.layouthint?.find(x => x.includes('show-popup'));
    if (!layouthintFound) return null;
    const returnValue = layouthintFound.split('of')[1].split('with')[0].trim();
    return returnValue;
  }

  private readonly onDragDrop = (event: CdkDragDrop<ITreeViewItem[]>) => {
    if (!event.isPointerOverContainer) return;
    const movedItem = event.item.dropContainer.data.find((i: Record<string, any>) => i.id === event.item.element.nativeElement.id);
    const moveAction = movedItem?.moveAction;
    const body = {objects: {MoveObjectStructure: {ParentID: isNaN(+event.container.id) ? null : +event.container.id, Ranking: event.currentIndex}}};
    if (!!moveAction) {
      if (!!movedItem?.allowedParents && !movedItem?.allowedParents?.includes(+event.container.id)) {
        const allowedParents = movedItem.allowedParentsNames.map((name: string) => `'${name}'`).join(', ');
        const snackbarText = this.translate.instant('treeview.drag_drop_not_allowed', {allowedParents});
        this.snackbar.open({text: snackbarText, type: 'error'});
        return;
      }
      if (event.container === event.previousContainer) {
        moveItemInArray(
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
      } else {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
      }
      this.httpService.post(moveAction, body).subscribe(() => {
        this.reload();
      });
    }
  };

  private readonly updateItems = (changedIds: number[]) => {
    if (changedIds.length === 0 || this.href?.includes('audit-execution-structure')) {
      return;
    }
    const updateUrl = `${this.href}?ChangeIDs=${changedIds.toString()}`;
    this.beinformedService.fetchResponseWithContributions<'caselist'>(updateUrl  || '').subscribe({next: res => {
      const results = this.beinformedService.extractResults(res);
      const objectName = Object.keys(res.contributions.results)[0];
      const attributes = res.contributions.results[objectName].attributes;
      
      const iconColName = getIconColumnName(res);
      
      const items = results ? transformResultsToItems(this.getPopupItemsName() || '', this.opened, results, iconColName, attributes) : [];
      this.loading = false;
      this.setNewItemData(items);
    },
    complete: () => {
      this.loading = false;
    }
    });
  };

  private refreshItems(keys: string[]) {
    this.beinformedService.fetchResponseWithContributions<'caselist'>(this.href || '').subscribe({
      next: result => {
        const results = this.beinformedService.extractResults(result);
        const objectName = Object.keys(result.contributions.results)[0];
        const attributes = result.contributions.results[objectName].attributes;
        const iconColName = getIconColumnName(result);
        const items = results ? transformResultsToItems(this.getPopupItemsName() || '', this.opened, results, iconColName, attributes) : [];
        const updatedItems = (items as any[]).filter(x => keys.includes(x.id));
        this.setNewItemData(updatedItems);
      }
    });
  }

  private readonly deleteItem = (item: ITreeViewItem) => {
    let rootIndex = -1;
    this.items.forEach((rootItem, index) => {
      if (rootItem.id === item.id) {
        rootIndex = index;
      }
      if (rootIndex === -1 && !!rootItem.children?.length) {
        this.findAndDeleteItem(rootItem.children, item.id as number);
      }
    });

    if (rootIndex > -1) {
      this.items.splice(rootIndex, 1);
    }
  };

  private findAndDeleteItem(children: ITreeViewItem[], itemId: number) {
    const foundChildIndex = children?.findIndex(child => child.id === itemId);
    if (foundChildIndex !== -1) {
      children.splice(foundChildIndex, 1);
    } else {
      children.forEach(child => !!child.children?.length ? this.findAndDeleteItem(child.children, itemId) : null);
    }
  }

  private setNewItemData(items: ITreeViewItem[]) {
    items.forEach(updatedItem => {
      this.treeviewService.updateItem$.next(updatedItem);
    });
  }

  private getItemById(updatedItemId: number | string, items: ITreeViewItem[]): ITreeViewItem | null {
    let returnValue = null as any;
    items.forEach(item => {
      if (!!returnValue) {
        return;
      }
      if (item.id === updatedItemId) {
        returnValue = item;
      } else if (!!item?.children?.length) {
        returnValue = this.getItemById(updatedItemId, item.children);
      }
    });
    return returnValue;
  }

  public ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public passSelectedUrl(url: string) {
    this.selectedUrl.emit(url);
  }

  public getActionDescription(actionName: string): string {
    switch (actionName) {
      case 'create-object': return 'treeview.create-object';
      case 'add-template': return 'treeview.add-template';
    }

    return '';
  }

  public onClickAction(action: IAction) {
    const removeYourselfMessage = action.layouthint?.find(hint => hint.includes('remove_yourself'));
    const removeYourself = removeYourselfMessage?.split(':')?.[1];
    const removeOthersMessage = action.layouthint?.find(hint => hint.includes('remove_others'));
    const removeOthers = removeOthersMessage?.split(':')?.[1];
    const removeMessage =  removeYourself || removeOthers;
    void this.beinformedService.handleAction(action, this.fetchPanel, undefined, false, undefined, removeMessage);
  }

  public submit() {
    const aspectClasses: any[] = [];
    this.items.forEach(classification => {
      classification.children?.forEach(aspect => {
        if (!!aspect.children?.length)
          aspectClasses.push(...aspect.children.filter(aspectClass => !!aspectClass.checked));
      });
    });
    const returnSelection = aspectClasses.map(aspectClass => ({value: aspectClass.id, label: aspectClass.name}));
    this.submitSelection.next(returnSelection);
  }

  public clearSelection() {
    this.items.forEach(classification => {
      classification.children?.forEach(aspect => {
        aspect.children?.forEach(aspectClass => aspectClass.checked = false);
      });
    });
  }
}
