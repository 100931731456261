import { Component, Input, HostBinding, OnInit } from '@angular/core';
import { COLOR_REG } from '@core/constants/core-constants';
import { NgStyle } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';
import type { IInputOption, INarisOption } from '@core/models';

@Component({
  selector: 'naris-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
  standalone: true,
  imports: [NgStyle, MatTooltip, IconComponent, TranslateModule]
})
export class OptionComponent implements OnInit {

  @Input() public label: string;
  @Input() public icon?: string;
  @Input() public helperText?: string;
  @Input() public meta: IInputOption | INarisOption;
  public desc: string;
  public color: string;
  public id: string;

  @HostBinding('class') private readonly rootClass = 'naris-option';

  public ngOnInit() {
    const elements = this.meta?.elements as Record<string, any>;
    if ('code' in this.meta)
      this.id = this.meta.code || '';
    if ('_id' in this.meta)
      this.id = this.meta._id as string || '';
    if (!!elements) {
      for (const key in elements) {
        if (key.toLowerCase().includes('description')) this.desc = elements[key];
        if (!!elements[key] && COLOR_REG.test(elements[key].toString())) this.color = elements[key];
      }
    }
  }
}
