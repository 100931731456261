@defer (when !viewLoading) {
  @if (dashboard && activeView) {
    <div class="naris-dashboard" [class.naris-dashboard--saving]="viewSaving">
      @if (showToolbar) {
        <!-- Toolbar -->
        <naris-toolbar
          [spacing]="false"
          size="xlarge"
          class="naris-dashboard-toolbar"
        >
          @if (editModeEnabled) {
            <naris-toolbar-item>
              <naris-button size="small" color="primary" (clicked)="editDashboard()" class="mr-2" id="DashboardEdit">{{'edit' | translate}}</naris-button>
              <naris-taskgroup
                [taskgroup]="{ actions: activeView.actions | filter:'name':'update-dashboard':false }"
                [showLabel]="false"
                buttonClass="mr-2"
                buttonSize="small"
                (postCallback)="reload(true)"
                (formCallback)="reload()"
              />
            </naris-toolbar-item>
          }
          @if (activeView.WidgetsNoModule || activeView.WidgetsNoPermission) {
            <naris-toolbar-item>
              <div class="naris-dashboard-alert">
                {{'dashboard.no_widgets_due_to_permissions' | translate}}
              </div>
            </naris-toolbar-item>
          }
          <naris-toolbar-item class="ml-auto">
            <naris-taskgroup
              [taskgroup]="dashboard.taskgroup!"
              [showLabel]="false"
              [id]="'DashboardTaskgroup'"
            />
          </naris-toolbar-item>
        </naris-toolbar>
      } @else {
        <div class="naris-dashboard-spacer"></div>
      }

      @if (editModeEnabled) {
        <!-- Widgets-->
        <div class="naris-dashboard-placeholder widget" (click)="addWidget(undefined, true)" tabindex="0">
          {{'dashboard.add_widget' | translate}}
        </div>
      }
      
      <div
        cdkDropList
        cdkDropListOrientation="vertical"
        [cdkDropListDisabled]="!editModeEnabled"
        (cdkDropListDropped)="dropRow($event)"
        class="naris-dashboard-container"
      >
        @for (row of activeView.DashboardConfig?.rows; track $index) {
          <div
            cdkDrag
            class="naris-dashboard-row"
            cdkDropList
            [cdkDropListDisabled]="!editModeEnabled"
            cdkDropListOrientation="horizontal"
            (cdkDropListDropped)="dropWidget($event, $index)"
          >
            <div class="naris-dashboard-drag-preview" *cdkDragPreview>
              {{'dashboard.release_to_drop_row' | translate}}
            </div>
            <naris-grid>
              @for (widget of row.widgets; track widgetIndex; let widgetIndex = $index) {
                @if (row.type === 'Card') {
                  <naris-grid-item
                    #widgetElement
                    [colspan]="[3, 3, 3, 3]"
                    cdkDrag
                    class="naris-dashboard-item"
                  >
                    @if (editModeEnabled) {
                      <div class="naris-dashboard-actions naris-dashboard-item-actions">
                        <naris-button icon="drag" size="small" cdkDragHandle matTooltip="Move widget" matTooltipPosition="above" id="DashboardMoveWidget" />
                        <naris-button
                          size="small"
                          color="danger"
                          icon="delete"
                          [matTooltip]="'dashboard.remove_widget' | translate"
                          (clicked)="removeWidget($index, widgetIndex)"
                          id="DashboardRemoveCardWidget"
                        />
                      </div>
                    }
                    <div class="naris-dashboard-drag-preview" *cdkDragPreview>
                      {{'dashboard.release_to_drop_card' | translate}}
                    </div>
                    <naris-dashboard-card
                      [endpoint]="getWidget(widget.id).Endpoint"
                      [label]="getWidget(widget.id).WidgetName"
                    />
                  </naris-grid-item>
                }
                @if (row.type === 'List' || row.type === 'Graph') {
                  <naris-grid-item class="naris-dashboard-item" [colspan]="row.type === 'List' ? [12, 12, 12, 12] : [6, 6, 6, 6]">
                    @if (editModeEnabled) {
                      <div class="naris-dashboard-actions naris-dashboard-item-actions">
                        <naris-button
                          size="small"
                          color="danger"
                          icon="delete"
                          [matTooltip]="'dashboard.remove_widget' | translate"
                          (clicked)="removeWidget($index, widgetIndex)"
                          id="DashboardRemoveListGraphWidget"
                        />
                      </div>
                    }
                    <naris-panel
                      [panel]="getPanel(widget.id)"
                      [editMode]="editModeEnabled"
                      [widgetConfig]="widget.config"
                      (widgetConfigChanged)="widget.config = $event; saveDashboardView()"
                    />
                  </naris-grid-item>
                }
              }
              @if ((row.type === 'Card' && row.widgets.length < 4) || (row.type === 'Graph' && row.widgets.length < 2)) {
                <naris-grid-item
                  [colspan]="row.type === 'Card' ? [3, 3, 3, 3] : [6, 6, 6, 6]"
                >
                  @if (editModeEnabled) {
                    <div class="naris-dashboard-placeholder naris-dashboard-placeholder--card" (click)="addWidget($index, false, row.type)" tabindex="0">
                      {{(row.type === 'Card' ? 'dashboard.add_card' : 'dashboard.add_graph') | translate}}
                    </div>
                  }
                </naris-grid-item>
              }
            </naris-grid>
            @if (editModeEnabled) {
              <div class="naris-dashboard-placeholder" (click)="addWidget($index, true)" tabindex="0">
                {{'dashboard.add_widget' | translate}}
              </div>
              <div class="naris-dashboard-actions">
                <naris-button icon="dehaze" size="small" [matTooltip]="'dashboard.move_row' | translate" cdkDragHandle  id="DashboardMoveRow"/>
                <naris-button
                  icon="delete"
                  color="danger"
                  size="small"
                  [matTooltip]="'dashboard.remove_row' | translate"
                  class="mt-1"
                  (clicked)="removeRow($index)"
                  id="DashboardRemoveRow"
                />
              </div>
            }
          </div>
        }
      </div>
    </div>
  }
  
} @placeholder {
  <naris-loader [label]="'dashboard.loading' | translate" />
}

@if (dashboard && activeView?.updateWidgetsEndpoint) {
  <naris-overlay #addWidgetOverlay (changed)="$event ? addWidgetComponent.reset() : null">
    <naris-drawer [width]="'32rem'">
      <naris-dashboard-add-widget
        #addWidgetComponent
        [endpoint]="activeView?.updateWidgetsEndpoint"
        (selected)="addWidgetToGrid($event)"
      />
    </naris-drawer>
  </naris-overlay>
}

