import { trigger, state, transition, animate, style } from '@angular/animations';

export const rowExpand = trigger('rowExpand', [
  state('collapsed, void', style({height: '0px', minHeight: '0', opacity: 0})),
  state('expanded', style({height: 'auto', opacity: 1})),
  transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
]);

export const filterExpand = trigger('filterExpand', [
  state('collapsed, void', style({height: '0px', minHeight: '0', opacity: 0})),
  state('expanded', style({height: 'auto', paddingTop: '1rem', paddingBottom: '1rem', opacity: 1})),
  transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
]);
