import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { Base, Inspector } from '@jsplumbtoolkit/browser-ui';
import { jsPlumbService } from '@jsplumbtoolkit/browser-ui-angular';
import { IAction } from '@core/models';
import { ProcessManagerService } from '@core/services/process-manager.service';
import { TabService } from '@core/services';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import edgeMappings from '../../edge-mappings';
import { ButtonComponent } from '../../../../elements/button/button.component';
import { FormComponent } from '../../../../../core/form/form.component';

@Component({
  selector: 'app-process-inspector',
  templateUrl: './process-inspector.component.html',
  styleUrls: ['./process-inspector.component.scss'],
  standalone: true,
  imports: [ButtonComponent, MatTooltip, FormComponent, TranslateModule]
})
export class ProcessInspectorComponent implements AfterViewInit {

  @Input() public surfaceId: string;

  @Input() public readOnly: boolean;
  public obj: Base | undefined;
  public currentType = '';
  public edgeMappings = edgeMappings();
  public inspector: Inspector;

  public addAction: IAction;
  public deleteAction: IAction;
  public updateEndpoint = '';

  constructor(
    private readonly $jsplumb: jsPlumbService,
    public readonly el: ElementRef,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly processManagerService: ProcessManagerService,
    private readonly tabService: TabService
  ) {}

  public ngAfterViewInit(): void {
    this.$jsplumb.getSurface(this.surfaceId, surface => {
      this.inspector = new Inspector({
        container: this.el.nativeElement,
        surface,
        renderEmptyContainer: () => {
          this.currentType = '';
          this.updateEndpoint = '';
          this.changeDetector.detectChanges();
        },
        refresh: (obj: Base, cb: () => void) => {
          this.obj = obj;
          this.currentType = obj?.objectType;
          this.addAction = obj?.data.actions?.find((action: IAction) => action.name === 'add-process-object');
          this.deleteAction = obj?.data.actions.find((action: any) => action.name === 'detach-object');
          const updateAction = obj?.data.actions?.find((action: IAction) => action.name === 'update-step');
          if (!!updateAction) this.updateEndpoint = updateAction.href!;

          setTimeout(cb, 0);
          this.changeDetector.detectChanges();
        }
      });
    });
  }

  public addProcessObject() {
    this.processManagerService.addLinkedObject$.next(this.addAction.href!);
  }

  public updateEdge() {
    const el = document.getElementById('edgeLabel') as HTMLInputElement;
    this.processManagerService.updateEdge$.next({edgeId: this.obj?.getFullId() || '', value: el.value});
  }

  public updateNode() {
    const el = document.getElementById('nodeName') as HTMLInputElement;
    this.processManagerService.updateNode$.next({nodeId: this.obj?.getFullId() || '', value: this.obj?.type === 'process-task' ? '' : el.value });
  }

  public nodeChanged(obj: Record<string, any>) {
    this.processManagerService.nodeChanged$.next({obj: obj, id: this.obj?.data.id});
  }

  public deleteNode() {
    if (!!this.obj) this.processManagerService.removeNode$.next(this.obj.getFullId());
  }
} 
