@if (!collabService.collabLoading && !collabService.collabError) {
<naris-toolbar class="toolbar" border="bottom" size="xlarge">
  <naris-toolbar-item class="toolbar__header">
    <div class="collaboration-room-header">
      <div class="collaboration-room-header__attributes">
        <div 
          class="collaboration-room-header__attributes__state"
          [ngClass]="{
            'in-progress': collabService.objectData?.state === 'InProgress',
            'completed': collabService.objectData?.state === 'Completed'
          }"
        ></div>
        <span class="collaboration-room-header__attributes__title">
          {{collabService.objectData?.number}} - 
          {{collabService.objectData?.name}} - 
          {{collabService.objectData?.context?.name}} | {{collabService.objectData?.context?.type}}
        </span>
      </div>
      <div class="collaboration-room-header__description">{{collabService.objectData?.description}}</div>
    </div>
  </naris-toolbar-item>
  @if (!!collabService.sessionTime && !collabService.collabOffline) {
  <naris-toolbar-item [matTooltip]="'collab.time' | translate">
    <naris-icon icon="waiting" style="mr-1" />
    {{ collabService.sessionTime }}
  </naris-toolbar-item>
  } @if (collabService.isHost) {
  <naris-toolbar-item>
    <naris-button color="dark" (clicked)="collabService.stopSession()">{{
      "collab.stop_session" | translate
    }}</naris-button>
  </naris-toolbar-item>
  }
</naris-toolbar>
<div class="wrapper-parent">
  <div class="wrapper">
    <div class="menu__wrapper">
      @if (collabService.collabPotentialCases.length > 0) {
        <div class="menu__list">
          <div class="menu">
            <div class="menu__header">
              <h4 class="menu__header__title">{{ "collab.potentials" | translate }}</h4>
            </div>
            <ul>
              @for (case of collabService.collabPotentialCases; track $index) {
                <li
                  tabindex="0"
                  (click)="collabService.isHost && onSwitchCase(case)"
                  [ngClass]="{
                    active: collabService.currentCase === case,
                    completed: case.caseState.state === 2,
                    intermediate: case.caseState.state === 1,
                    host: collabService.isHost
                  }"
                  class="step"
                >
                  <div class="label">
                    <div class="label-indicator">
                      @if (case.caseState.state === 2) {
                        <naris-icon class="icon" icon="check" />
                      }
                      @if (case.caseState.state === 1) {
                        <naris-icon class="icon" icon="in-process" />
                      }
                    </div>
                    {{ case.label }}
                  </div>
                  @if(collabService.currentCase === case) {
                    <ng-container *ngTemplateOutlet="CausesConsequences;context:{case}" />
                  }
                </li>
              }
            </ul>
            <div class="menu__footer">
              <p>
                {{ completedPotentialCases() }}/{{
                  collabService.collabPotentialCases.length
                }}
                {{ "collab.completed" | translate }}
              </p>
              <div class="progress">
                <naris-progress-bar
                  [value]="completedPotentialCases()"
                  [maxValue]="collabService.collabPotentialCases.length"
                  [color]="colorSuccess"
                  style="width: 100%"
                />
              </div>
            </div>
          </div>
        </div>
      }
      @if (collabService.collabIdentificationCases.length > 0) {
        <div class="menu__list">
          <div class="menu">
            <div class="menu__header">
              <h4 class="menu__header__title">{{ "collab.identifications" | translate }}</h4>
            </div>
            <ul>
              @for (case of collabService.collabIdentificationCases; track $index) {
                <li
                  tabindex="0"
                  (click)="collabService.isHost && onSwitchCase(case)"
                  [ngClass]="{
                    active: collabService.currentCase === case,
                    completed: case.caseState.state === 2,
                    intermediate: case.caseState.state === 1,
                    host: collabService.isHost
                  }"
                  class="step"
                >
                  <div class="label">
                    <div class="label-indicator">
                      @if (case.caseState.state === 2) {
                        <naris-icon class="icon" icon="check" />
                      } 
                      @if (case.caseState.state === 1) {
                        <naris-icon class="icon" icon="in-process" />
                      }
                    </div>
                    {{ case.label }}
                  </div>
                  @if(collabService.currentCase === case) {
                    <ng-container *ngTemplateOutlet="CausesConsequences;context:{case}" />
                  }
                </li>
              }
            </ul>
            <div class="menu__footer">
              <p>
                {{ completedIdentificationCases() }}/{{
                  collabService.collabIdentificationCases.length
                }}
                {{ "collab.completed" | translate }}
              </p>
              <div class="progress">
                <naris-progress-bar
                  [value]="completedIdentificationCases()"
                  [maxValue]="collabService.collabIdentificationCases.length"
                  [color]="colorSuccess"
                  style="width: 100%"
                />
              </div>
            </div>
          </div>
        </div>
      }
    </div>

    <div class="panel">
      @if (!!collabService.currentCase) {
        <naris-collaboration-room-panel
          [case]="collabService.currentCase"
          [collabId]="collabService.collabId"
          [isHost]="collabService.isHost"
          (completed)="nextCase($event)"
          (next)="nextStep($event)"
          (click)="onClickPanel(collabService.currentCase)"
        />
      }
    </div>
  </div>
</div>

} @if (collabService.collabLoading) {
<div [@fadeInOutAnimation] class="naris-loader">
  <div class="naris-loader-animation"></div>
  <span>{{ "collab.loading" | translate }}</span>
</div>
} @if (!collabService.collabLoading && !!collabService.collabError) {
<div class="error">
  <h4>{{ "collab.error" | translate }}</h4>
  <p>{{ collabService.collabError.message }}</p>
</div>
}

<ng-template #CausesConsequences let-case="case">
  <div class="step__causes-consequences">
    <naris-collaboration-room-cc [endpoints]="[case.links.Causes?.href || case.links.PotentialCauses?.href, case.links.Consequences?.href || case.links.PotentialConsequences?.href]" />
  </div>
</ng-template>
