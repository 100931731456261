import { Injectable, Injector } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpService } from '@core/services/http.service';
import { UserService } from '@core/services/user.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { BeinformedService } from '@core/services/beinformed.service';
import { PushService } from '@core/services/push.service';
import { Changelog } from '@core/classes';
import type { INarisNotification, ICombinedResponse, IAction, ICaseListContributions, ICaseList } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class MessageService extends HttpService {

  public notifications: INarisNotification[] = [];
  public isSubscribed: boolean;
  private interval: any;
  public emailNotificationToggleVisible = false;
  public hasEmailNotifications = false;
  public unreadNotifications: number;

  constructor(
    injector: Injector,
    private readonly userService: UserService,
    private readonly httpService: HttpService,
    private readonly snackbarService: SnackbarService,
    private readonly beinformedService: BeinformedService,
    private readonly pushService: PushService
  ) {
    super(injector);
    pushService.getSubscribed(this.userService.userId).subscribe(res => this.isSubscribed = res.subscribed);
  }

  /**
   * Start the interval listener
   */
  public start() {
    if (this.userService.userId) {
      this.fetchNotifications();
      this.interval = setInterval(() => this.fetchNotifications(), 1000 * 60);
    }
  }

  /**
   * Stop the interval listener
   */
  public stop() {
    if (this.interval) clearInterval(this.interval);
  }

  /**
   * Returns unread notifications count
   */
  get unreadNotificationCount() {
    return this.notifications?.filter((n: INarisNotification) => !n.NotificationRead).length;
  }

  /**
   * Method to fetch and process notifications
   */
  public fetchNotifications() {
    this.beinformedService.fetchResponseWithContributions(this.endpoint + '/information-notifications').subscribe(result => {
      this.notifications = this.extractNotifications(result);
      this.unreadNotifications = this.getUnreadNotificationCount(result);
    });
  }

  public fetchUnreadNotifications() {
    this.beinformedService.fetchResponseWithContributions(this.endpoint + '/information-notifications').subscribe({
      next: result => this.unreadNotifications = this.getUnreadNotificationCount(result)
    });
  }

  /**
   * Subscribe user to notification service
   */
  public async subscribeUser(userId: number | string) {
    this.isSubscribed = true;
    await this.pushService.allowPush(true, userId, failMessage => {
      this.snackbarService.open({text: failMessage});
      this.isSubscribed = false;
    });
  }

  /**
   * Unubscribe user from notification service
   */
  public async unsubscribeUser(userId: number | string) {
    this.isSubscribed = false;
    await this.pushService.allowPush(false, userId, failMessage => {
      this.snackbarService.open({text: failMessage});
      this.isSubscribed = true;
    });
  }

  /**
   * Marks all notifications as read by unsetting unread on all items
   */
  public markNotificationsAsRead() {
    this.httpService.post(this.endpoint + '/information-notifications/set-all-notifications-as-read')
      .subscribe(() => this.fetchNotifications());
  }

  /**
   * Extract notifications from result
   * @param results combined results
   */
  private extractNotifications({data, contributions}: ICombinedResponse) {
    const notifications = data._embedded?.results?.map((i: { any: { actions: IAction[] } }) => {
      const notification = Object.values(i)[0];
      const actions = notification.actions.map((action: IAction) => {
        const actionMeta = contributions?.results.Notification.actions.find((a: IAction) => a.name === action.name);
        return { ...action, ...actionMeta };
      });
      return { ...notification, actions };
    });
    return notifications;
  }

  private getUnreadNotificationCount(result: ICombinedResponse) {
    return result.data.filter.NotificationRead.options.find((option: any) => option.key === 'false').count || 0;
  }

  /**
   * Generate endpoint based on the user profile href
   */
  get endpoint(): string {
    return `/profile-tab/my-person/${this.userService.userId}/notifications`;
  }

  public fetchChangelogs() {
    return this.beinformedService.fetchResponseWithContributions<'caselist'>('/b2c/version').pipe(map(result => {
      const extracted = this.extractChangelogs(result);
      return extracted?.map(e => new Changelog(e, this.httpService));
    }));
  }

  private extractChangelogs({ data, contributions }: ICombinedResponse<ICaseList, ICaseListContributions>) {
    const objId = Object.keys(contributions.results)?.[0] || 'VersionUserContentsView';
    return data._embedded?.results.map(({ [objId]: dataObj }) => {
      const actions = dataObj.actions?.map(action => {
        const actionMeta = contributions.results[objId].actions.find(a => a.name === action.name);
        return {...action, ...actionMeta};
      });
      return {...dataObj, actions};
    });
  }

  public setEmailNotifications(enable: boolean, userId: number | string) {
    const url = `/profile-tab/my-person/${userId}/tasks/turn-email-notifications-${enable ? 'on' : 'off'}`;
    this.beinformedService.fetchForm(url, true).subscribe();
  }

  public determineEmailNotifications(userId: number | string) {
    if (this.userService.userData._id === 1) return;
    const url = `/profile-tab/my-person/${userId}/tasks`;
    this.beinformedService.fetchResponseWithContributions(url, false).subscribe(result => {
      const emailNotificationAction = result?.data?.actions?.find((action: IAction) => (action.name === 'turn-email-notifications-off' || action.name === 'turn-email-notifications-on') && !!action.href);
      this.emailNotificationToggleVisible = !!emailNotificationAction;
      if (!!emailNotificationAction)
        if (emailNotificationAction.name?.endsWith('on'))
          this.hasEmailNotifications = false;
        else if (emailNotificationAction.name?.endsWith('off'))
          this.hasEmailNotifications = true;
    });
  }
}
