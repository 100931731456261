import { createClass as e, classCallCheck as t, defineProperty as r } from "../_virtual/_rollupPluginBabelHelpers.js";
import { isGroup as a, Group as i, Node as o, isNode as n } from "../core/model/graph.js";
import { uuid as s, extend as d } from "../ui-core/util/util.js";
import { SURFACE_MODE_VERTEX_DRAWING as l, SURFACE_MODE_PAN as c } from "../browser-ui/surface.js";
import { Collicat as u } from "../ui-core/browser-ui-renderer/collicat.js";
import { getPageLocation as v, EventManager as p } from "../ui-core/browser-ui-renderer/event-manager.js";
import { NONE as f, ABSOLUTE as g } from "../ui-core/core/constants.js";
import { intersects as h } from "../ui-core/util/geom.js";
import { TRUE as m } from "../ui-core/common/index.js";
import { matchesSelector as y, removeClass as C, addClass as x, findParent as b } from "../ui-core/browser-ui-renderer/browser-util.js";
import { _connectorBoundingBoxIntersection as D } from "../ui-core/core/connector/connectors.js";
import { GROUP_RELAYOUT_NEW_VERTEX_ADDED as w } from "../browser-ui/group-layout-adapter.js";
import { EVENT_SURFACE_MODE_CHANGED as E } from "../browser-ui/constants.js";
import { VertexDrawingPlugin as G } from "../browser-ui-plugin-vertex-drawing/vertex-drawing-plugin.js";
import { EVENT_ZOOM as O } from "../ui-core/core/event-constants.js";
import { EVENT_TAP as A } from "../ui-core/browser-ui-renderer/event-constants.js";
var j = "jtk-drag-drop-selected-element";
var k = "jtk-drag-drop-current";
var P = "jtk-drag-drop-active";
var T = "jtk-drag-drop-hover";
var _ = "Group";
var F = "width";
var L = "height";
var M = "mousemove";
var N = "mouseout";
var I = "touchmove";
var S = "data-jtk-is-group";
var z = "left";
var V = "top";
var Z = "drag";
var H = "tap";
var B = function () {
  function n(e) {
    var i = this;
    t(this, n);
    r(this, "surface", void 0);
    r(this, "surfaceCanvas", void 0);
    r(this, "surfaceCanvasPlaceholder", void 0);
    r(this, "source", void 0);
    r(this, "selector", void 0);
    r(this, "_$_mode", void 0);
    r(this, "dropFilter", void 0);
    r(this, "canvasDropFilter", void 0);
    r(this, "edgeDropFilter", void 0);
    r(this, "scope", void 0);
    r(this, "dragSize", void 0);
    r(this, "onEdgeDrop", void 0);
    r(this, "onDrop", void 0);
    r(this, "onCanvasDrop", void 0);
    r(this, "isInViewport", void 0);
    r(this, "dragActiveClass", void 0);
    r(this, "dragHoverClass", void 0);
    r(this, "dragElementClass", void 0);
    r(this, "dataGenerator", void 0);
    r(this, "typeGenerator", void 0);
    r(this, "onDrag", void 0);
    r(this, "canvasSelector", void 0);
    r(this, "ignoreZoom", void 0);
    r(this, "elementGenerator", void 0);
    r(this, "_generatedElement", void 0);
    r(this, "currentNodeList", void 0);
    r(this, "currentEdgeList", void 0);
    r(this, "candidateData", void 0);
    r(this, "candidate", void 0);
    r(this, "canvasMoveListener", void 0);
    r(this, "canvasMouseOutListener", void 0);
    r(this, "previousCandidateTapObject", void 0);
    r(this, "candidateTapObject", void 0);
    r(this, "isCurrentlyOnCanvasElement", void 0);
    r(this, "canDropOnCanvas", void 0);
    r(this, "viewportPosition", null);
    r(this, "enabled", void 0);
    r(this, "constrainFunction", void 0);
    r(this, "ignoreConstrainFunction", void 0);
    r(this, "ignoreGrid", void 0);
    r(this, "grid", void 0);
    r(this, "drag", void 0);
    r(this, "dragManager", void 0);
    r(this, "tapEventManager", void 0);
    r(this, "_active", void 0);
    this.surface = e.surface;
    this.surfaceCanvas = this.surface.getContainer().parentNode;
    this.surfaceCanvasPlaceholder = this.surfaceCanvas.querySelector(".jtk-surface-canvas");
    this.ignoreZoom = e.ignoreZoom === true;
    this.grid = this.surface.getGrid();
    this.ignoreGrid = e.ignoreGrid === true;
    this.constrainFunction = this.surface.getDragConstrainFunction();
    this.ignoreConstrainFunction = e.ignoreConstrainFunction === true;
    this._$_mode = e.mode || Z;
    this.enabled = e.enabled !== false;
    this.canvasSelector = e.canvasSelector || null;
    this.source = e.source;
    this.selector = e.selector;
    this.scope = e.scope || s();
    this.dragSize = e.dragSize;
    this.dropFilter = e.dropFilter || function (e, t) {
      return true;
    };
    this.canvasDropFilter = e.canvasDropFilter || function (e) {
      return true;
    };
    this.edgeDropFilter = e.edgeDropFilter || function (e, t) {
      return true;
    };
    this.isInViewport = e.isInViewport || function (e) {
      return true;
    };
    this.onDrag = e.onDrag;
    this.onDrop = e.onDrop;
    this.onEdgeDrop = e.onEdgeDrop;
    this.onCanvasDrop = e.onCanvasDrop;
    this.dragActiveClass = e.dragActiveClass || P;
    this.dragHoverClass = e.dragHoverClass || T;
    this.dragElementClass = e.dragElementClass || k;
    this.currentNodeList = [];
    this.elementGenerator = e.elementGenerator;
    this.dataGenerator = e.dataGenerator || function () {
      return {};
    };
    this.typeGenerator = e.typeGenerator || function (e) {
      return e.type;
    };
    this.canvasMoveListener = function (e) {
      var t = e.srcElement || e.target;
      i.isCurrentlyOnCanvasElement = t === i.surfaceCanvas || t === i.surfaceCanvasPlaceholder || i.surface.panZoom.background.owns(t) || i.canvasSelector != null && y(t, i.canvasSelector);
    };
    this.canvasMouseOutListener = function (e) {
      i.isCurrentlyOnCanvasElement = false;
    };
    this._active = false;
    this.dragManager = new u(this.surface.jsplumb);
    this.surface.bind(O, function (e) {
      if (i._active && !i.ignoreZoom) {
        var t = i.drag.getDragElement();
        if (t) {
          t.style.transform = "scale(".concat(i.surface.getZoom(), ")");
        }
      }
    });
    this.surface.bind(E, function (e) {
      if (e === l && i.candidateTapObject == null) {
        if (i.previousCandidateTapObject != null) {
          i._setTapElement(i.previousCandidateTapObject.el);
        }
      } else if (e !== l) {
        if (i.candidateTapObject != null) {
          C(i.candidateTapObject.el, j);
          i.previousCandidateTapObject = i.candidateTapObject;
          i.candidateTapObject = null;
        }
      }
    });
    if (this._$_mode === Z) {
      this.drag = this.dragManager.draggable(this.source, {
        scope: this.scope,
        clone: this.elementGenerator == null,
        cloneSize: this.dragSize,
        getTargetScale: function e() {
          return i.surface.getZoom();
        },
        ignoreZoom: this.ignoreZoom,
        constrainFunction: function e(t, r, a, o, n, s) {
          if (i.constrainFunction != null && i.ignoreConstrainFunction !== true) {
            var d = v(s);
            if (i.surface.isInViewport(d.x, d.y)) {
              var l = i.drag.getDragDelta();
              var c = t.x - d.x,
                u = t.y - d.y;
              var p = i.surface.fromPageLocation(d.x, d.y);
              var f = {
                x: p.x - l.x,
                y: p.y - l.y
              };
              var g = Object.assign({}, i.constrainFunction(f, r, a, o, f, s));
              g.x += l.x;
              g.y += l.y;
              var h = i.surface.toPageLocation(g.x, g.y);
              return {
                x: c + h.x,
                y: u + h.y
              };
            } else {
              return t;
            }
          } else {
            if (i.ignoreGrid || i.grid == null || !i.surface.isInViewport(t.x, t.y)) {
              return t;
            } else {
              var m = {
                x: t.x,
                y: t.y
              };
              var y = i.surface.fromPageLocation(m.x, m.y);
              var C = (y.x < 0 ? Math.ceil(y.x / i.grid.w) : Math.floor(y.x / i.grid.w)) * i.grid.w;
              var x = (y.y < 0 ? Math.ceil(y.y / i.grid.h) : Math.floor(y.y / i.grid.h)) * i.grid.h;
              var b = i.surface.toPageLocation(C, x);
              return {
                x: b.x,
                y: b.y
              };
            }
          }
        },
        start: function e(t) {
          if (!i.enabled) {
            return false;
          }
          var r = t.drag.getDragElement();
          r.style.pointerEvents = f;
          i.candidateData = i.dataGenerator(r);
          i.currentNodeList.length = 0;
          i.candidate = null;
          x(r, i.dragElementClass);
          if (i.onDrop) {
            var o = i.surface.panZoom._calculateCumulativeScroll();
            var n = i.getAllNodesAndGroups();
            n.forEach(function (e) {
              var t = i.surface.getRenderedElement(e);
              if (t) {
                var r = t.getBoundingClientRect();
                var a = i.isInViewport(r) && i.dropFilter(i.candidateData, e) !== false;
                i.currentNodeList.push({
                  vertex: e,
                  element: t,
                  apparentBounds: {
                    x: r.x + o.x,
                    y: r.y + o.y,
                    w: r.width,
                    h: r.height
                  },
                  canDrop: a
                });
                if (a) {
                  x(t, i.dragActiveClass);
                } else {
                  C(t, i.dragActiveClass);
                }
              }
            });
            i.currentNodeList.sort(function (e, t) {
              if (a(e.vertex) && a(t.vertex)) {
                var r = i.surface.toolkitInstance.graph;
                if (r.isAncestor(e.vertex, t.vertex)) {
                  return -1;
                } else if (r.isAncestor(t.vertex, e.vertex)) {
                  return 1;
                }
              }
              return 0;
            });
          }
          i.currentEdgeList = i.onEdgeDrop ? i.getAllConnectors(i.candidateData) : [];
          if (i.onCanvasDrop && i.canvasDropFilter(i.candidateData)) {
            i.canDropOnCanvas = true;
            i.isCurrentlyOnCanvasElement = false;
            i.surface.on(i.surfaceCanvas, M, i.canvasMoveListener);
            i.surface.on(i.surfaceCanvas, N, i.canvasMouseOutListener);
          }
        },
        stop: function e(t) {
          i._active = false;
          i.surface.off(i.surfaceCanvas, M, i.canvasMoveListener);
          i.surface.off(i.surfaceCanvas, N, i.canvasMouseOutListener);
          i._cleanupClasses();
          i._generatedElement && i._generatedElement.parentNode && i._generatedElement.parentNode.removeChild(i._generatedElement);
          var r = i.surface.fromPageLocation(i.viewportPosition.x, i.viewportPosition.y);
          if (i.candidate) {
            if (i.candidate.edge && i.onEdgeDrop) {
              i.onEdgeDrop(i.candidateData, i.candidate.edge, t.el, t.e, t.pos, r);
            } else if (i.onDrop) {
              if (i.candidate.canDrop) {
                var a;
                var o;
                var n = i.surface.toolkitInstance.getNode(i.candidate.id) || i.surface.toolkitInstance.getGroup(i.candidate.id);
                if (n.objectType === _) {
                  var s = i.surface.jsplumb.getGroup(n.id);
                  a = i.surface.jsplumb.getOffset(s.contentArea);
                } else {
                  a = i.surface.jsplumb.getOffset(i.surface.getRenderedElement(i.candidate.id));
                }
                o = {
                  x: r.x - a.x,
                  y: r.y - a.y
                };
                i.onDrop(i.candidateData, n, t.el, t.e, t.pos, r, a, o);
              }
            }
          } else if (i.canDropOnCanvas && i.isCurrentlyOnCanvasElement && i.viewportPosition != null && i.onCanvasDrop) {
            i.onCanvasDrop(i.candidateData, r, t.el, t.e, t.pos, t.size);
          }
          i.isCurrentlyOnCanvasElement = false;
        },
        drag: function e(t) {
          i._cleanupClasses(true);
          var r = {
            x: t.pos.x,
            y: t.pos.y
          };
          i.viewportPosition = r;
          i.candidate = null;
          var a = i.surface.fromPageLocation(r.x, r.y);
          if (i.onDrop) {
            var o = d(r, t.drag._size);
            var n = i.currentNodeList.filter(function (e) {
              return h(e.apparentBounds, o);
            })[0];
            if (n != null) {
              i.candidate = {
                r: n.apparentBounds,
                id: n.vertex.id,
                element: n.element,
                canDrop: n.canDrop
              };
            }
          }
          if (!i.candidate && i.onEdgeDrop) {
            var s = i.surface.getZoom();
            var l = {
              x: a.x,
              y: a.y,
              w: t.drag._size.w / s,
              h: t.drag._size.h / s
            };
            for (var c = 0; c < i.currentEdgeList.length; c++) {
              var u = i.currentEdgeList[c],
                v = u.r,
                p = u.connector;
              if (h(l, v)) {
                var f = D(p, {
                  x: l.x - v.x,
                  y: l.y - v.y,
                  w: l.w,
                  h: l.h
                });
                if (f.length > 0) {
                  i.candidate = u;
                  break;
                }
              }
            }
          }
          if (i.candidate) {
            if (i.candidate.canDrop) {
              x(i.candidate.element, i.dragHoverClass);
            }
          } else {
            if (i.canDropOnCanvas) {
              i.isCurrentlyOnCanvasElement = i.isEffectivelyOnCanvas(t.e);
              if (i.isCurrentlyOnCanvasElement && i.surface.isInViewport(r.x, r.y)) {
                i.viewportPosition = r;
                x(i.surfaceCanvas, i.dragHoverClass);
              } else {
                x(i.surface.getContainer(), i.dragActiveClass);
              }
            }
          }
          i.onDrag && i.onDrag(i.candidateData, t.e, r, a);
        },
        selector: this.selector,
        dragInit: function e(t, r) {
          if (i.elementGenerator != null) {
            i._generatedElement = i.elementGenerator(t, r);
            if (i._generatedElement != null) {
              document.body.appendChild(i._generatedElement);
              var a = i.surface.jsplumb.getSize(i._generatedElement);
              var o = v(r);
              i._generatedElement.style.position = g;
              i._generatedElement.style.left = "".concat(o.x - a.w / 2, "px");
              i._generatedElement.style.top = "".concat(o.y - a.h / 2, "px");
              return i._generatedElement;
            }
          }
        },
        dragAbort: function e(t) {
          if (i.elementGenerator != null) {
            t.parentNode && t.parentNode.removeChild(t);
          }
        }
      });
    } else if (this._$_mode == H) {
      this.tapEventManager = new p();
      this.tapEventManager.on(this.source, A, this.selector, function (e) {
        var t = e.target;
        i._setTapElement(t);
      });
      var o = {
        allowClickToAdd: e.allowClickToAdd,
        typeGenerator: function e(t, r) {
          return Object.assign({}, i.candidateTapObject);
        },
        autoExit: true,
        lassoClass: e.lassoClass
      };
      if (e.vertexPreviewGenerator != null) {
        o.vertexPreviewGenerator = function (t, r) {
          return e.vertexPreviewGenerator(i.candidateTapObject.objectType, i.candidateTapObject.type, i.candidateTapObject.data, t, r);
        };
      }
      if (e.vertexPreviewUpdater != null) {
        o.vertexPreviewUpdater = function (t, r, a) {
          e.vertexPreviewUpdater(a, t, r, i.candidateTapObject.data);
        };
      }
      this.surface.addPlugin({
        type: G.type,
        options: o
      });
    }
  }
  e(n, [{
    key: "_setTapElement",
    value: function e(t) {
      var r = b(t, this.selector, this.source, true);
      if (this.candidateTapObject != null) {
        C(this.candidateTapObject.el, j);
        if (this.candidateTapObject.el === r) {
          this.surface.setMode(c);
          return;
        }
      }
      var a = this.dataGenerator(r);
      this.candidateTapObject = {
        el: r,
        data: a,
        objectType: r.getAttribute(S) === m ? i.objectType : o.objectType,
        type: this.typeGenerator(a)
      };
      x(this.candidateTapObject.el, j);
      this.surface.setMode(l);
    }
  }, {
    key: "setEnabled",
    value: function e(t) {
      this.enabled = t;
    }
  }, {
    key: "setSurface",
    value: function e(t) {
      this.surface = t;
      this.surfaceCanvas = t.getContainer();
    }
  }, {
    key: "isEffectivelyOnCanvas",
    value: function e(t) {
      var r = this.isCurrentlyOnCanvasElement;
      if (!r && t.type === I) {
        r = true;
      }
      return r;
    }
  }, {
    key: "getAllNodesAndGroups",
    value: function e() {
      var t = [];
      Array.prototype.push.apply(t, this.surface.toolkitInstance.getNodes());
      Array.prototype.push.apply(t, this.surface.toolkitInstance.getGroups());
      return t;
    }
  }, {
    key: "getAllConnectors",
    value: function e(t) {
      var r = this;
      var a = [];
      var i = this.surface.jsplumb;
      i.select().each(function (e) {
        if (e.edge && r.edgeDropFilter(t, e.edge)) {
          var i = e.connector;
          var o = i.canvas;
          var n = {
            x: parseInt(o.style.left, 10),
            y: parseInt(o.style.top, 10),
            w: parseInt(o.getAttribute(F), 10),
            h: parseInt(o.getAttribute(L), 10)
          };
          a.push({
            connection: e,
            connector: i,
            element: o,
            r: n,
            id: e.edge.id,
            edge: e.edge,
            canDrop: true
          });
          x(o, r.dragActiveClass);
        }
      });
      return a;
    }
  }, {
    key: "_cleanupClasses",
    value: function e(t) {
      var r = this;
      this.currentNodeList.forEach(function (e) {
        if (!t) {
          C(e.element, r.dragActiveClass);
        }
        C(e.element, r.dragHoverClass);
      });
      this.currentEdgeList.forEach(function (e) {
        if (!t) {
          C(e.element, r.dragActiveClass);
        }
        C(e.element, r.dragHoverClass);
      });
      C(this.surfaceCanvas, this.dragActiveClass);
      C(this.surfaceCanvas, this.dragHoverClass);
    }
  }]);
  return n;
}();
var U = function () {
  function i(e) {
    var o = this;
    t(this, i);
    r(this, "surface", void 0);
    r(this, "dropManager", void 0);
    r(this, "toolkit", void 0);
    r(this, "typeGenerator", void 0);
    r(this, "groupIdentifier", void 0);
    r(this, "modelPositionAttributes", void 0);
    r(this, "magnetize", void 0);
    r(this, "ignoreZoom", void 0);
    r(this, "ignoreGrid", void 0);
    r(this, "grid", void 0);
    r(this, "allowDropOnEdge", void 0);
    r(this, "allowDropOnGroup", void 0);
    r(this, "allowDropOnCanvas", void 0);
    r(this, "allowDropOnNode", void 0);
    r(this, "constrainFunction", void 0);
    r(this, "ignoreConstrainFunction", void 0);
    r(this, "dragSize", void 0);
    r(this, "onVertexAdded", void 0);
    this.surface = e.surface;
    this.toolkit = this.surface.toolkitInstance;
    this.modelPositionAttributes = this.surface.getModelPositionAttributes();
    this.typeGenerator = e.typeGenerator || function (e) {
      return e.type;
    };
    this.groupIdentifier = e.groupIdentifier || function (e, t) {
      return t.getAttribute(S) === m;
    };
    this.allowDropOnEdge = e.allowDropOnEdge !== false;
    this.allowDropOnGroup = e.allowDropOnGroup !== false;
    this.allowDropOnNode = e.allowDropOnNode === true;
    this.allowDropOnCanvas = e.allowDropOnCanvas !== false;
    this.ignoreZoom = e.ignoreZoom === true;
    this.magnetize = e.magnetize !== false;
    this.grid = this.surface.getGrid();
    this.ignoreGrid = e.ignoreGrid === true;
    this.dragSize = e.dragSize;
    this.constrainFunction = this.surface.getDragConstrainFunction();
    this.ignoreConstrainFunction = e.ignoreConstrainFunction === true;
    this.onVertexAdded = e.onVertexAdded;
    var s = {
      lassoClass: e.lassoClass,
      vertexPreviewGenerator: e.vertexPreviewGenerator,
      vertexPreviewUpdater: e.vertexPreviewUpdater,
      surface: this.surface,
      dragSize: this.dragSize,
      ignoreGrid: this.ignoreGrid,
      ignoreZoom: this.ignoreZoom,
      ignoreConstrainFunction: this.ignoreConstrainFunction,
      canvasDropFilter: e.canvasDropFilter,
      mode: e.mode,
      allowClickToAdd: e.allowClickToAdd,
      isInViewport: function e(t) {
        return o.surface.isInViewport(t.x, t.y);
      },
      onCanvasDrop: this.allowDropOnCanvas ? function (e, t, r, a, i) {
        o.addNewVertexToCanvas(e, t, r, a, i, null);
      } : null,
      onDrop: function e(t, r, i, s, d, l, c, u) {
        if (o.allowDropOnGroup && a(r)) {
          var v = o.groupIdentifier(t, i);
          var p = o.typeGenerator(t);
          var f = o._mapToPositionAttributes({
            group: r.id,
            left: u.x,
            top: u.y
          }, t);
          var g = function e(t) {
            o.surface.setPosition(t, u.x, u.y);
            o.surface.relayoutGroup(r, w);
          };
          if (v) {
            o.toolkit.addFactoryGroup(p, f, g);
          } else {
            o.toolkit.addFactoryNode(p, f, g);
          }
        } else if (o.allowDropOnNode && n(r)) {
          o.addNewVertexToCanvas(t, l, i, s, d, r);
        }
      },
      onEdgeDrop: this.allowDropOnEdge ? function (e, t, r, a, i, n) {
        var s = o.typeGenerator(e);
        var d = o._mapToPositionAttributes({
          x: n.x,
          y: n.y
        }, e);
        var l = o.groupIdentifier(e, r);
        var c = function e(r) {
          var a = t.source;
          var i = t.target;
          o.toolkit.removeEdge(t);
          o.toolkit.addEdge({
            source: a,
            target: r
          });
          o.toolkit.addEdge({
            source: r,
            target: i
          });
          if (o.magnetize !== false) {
            o.surface.setMagnetizedPosition(r, n.x, n.y, true);
          } else {
            o.surface.setPosition(r, n.x, n.y);
          }
        };
        if (l) {
          o.toolkit.addFactoryGroup(s, d, c);
        } else {
          o.toolkit.addFactoryNode(s, d, c);
        }
      } : null,
      source: e.source,
      selector: e.selector,
      dataGenerator: e.dataGenerator,
      typeGenerator: e.typeGenerator,
      canvasSelector: e.canvasSelector,
      elementGenerator: e.elementGenerator,
      dropFilter: function e(t, r) {
        if (!o.allowDropOnNode && n(r)) {
          return false;
        } else if (!o.allowDropOnGroup && a(r)) {
          return false;
        } else {
          return true;
        }
      }
    };
    this.dropManager = new B(s);
  }
  e(i, [{
    key: "_mapToPositionAttributes",
    value: function e(t, r) {
      var a = {};
      for (var i in t) {
        if (i === z) {
          a[this.modelPositionAttributes[0]] = t[i];
        } else if (i === V) {
          a[this.modelPositionAttributes[1]] = t[i];
        } else {
          a[i] = t[i];
        }
      }
      d(a, r);
      return a;
    }
  }, {
    key: "addNewVertexToCanvas",
    value: function e(t, r, a, i, o, n) {
      var s = this;
      var d = this.typeGenerator(t);
      var l = this.groupIdentifier(t, a);
      var c = this._mapToPositionAttributes({
        left: r.x,
        top: r.y
      }, t);
      var u = null;
      if (n != null && this.onVertexAdded) {
        var v = this.surface.jsplumb.viewport._elementMap.get(n.id);
        if (v != null) {
          u = {
            vertex: n,
            pos: {
              x: v.x,
              y: v.y
            },
            size: {
              w: v.w,
              h: v.h
            }
          };
        }
      }
      if (l) {
        this.toolkit.addFactoryGroup(d, c, function (e) {
          var t = e.data[s.toolkit.modelLeftAttribute] == null ? r.x : e.data[s.toolkit.modelLeftAttribute];
          var a = e.data[s.toolkit.modelTopAttribute] == null ? r.y : e.data[s.toolkit.modelTopAttribute];
          s.surface.setPosition(e, t, a);
          if (s.onVertexAdded) {
            s.onVertexAdded(s.toolkit.getGroup(e.id), u);
          }
        });
      } else {
        this.toolkit.addFactoryNode(d, c, function (e) {
          var t = e.data[s.toolkit.modelLeftAttribute] == null ? r.x : e.data[s.toolkit.modelLeftAttribute];
          var a = e.data[s.toolkit.modelTopAttribute] == null ? r.y : e.data[s.toolkit.modelTopAttribute];
          s.surface.setPosition(e, t, a);
          if (s.onVertexAdded) {
            s.onVertexAdded(s.toolkit.getNode(e.id), u);
          }
        });
      }
    }
  }, {
    key: "setEnabled",
    value: function e(t) {
      this.dropManager.setEnabled(t);
    }
  }, {
    key: "setSurface",
    value: function e(t) {
      this.surface = t;
      this.toolkit = this.surface.toolkitInstance;
      this.modelPositionAttributes = this.surface.getModelPositionAttributes();
      this.dropManager.setSurface(t);
    }
  }]);
  return i;
}();
export { S as ATTR_IS_GROUP, Z as DROP_MANAGER_MODE_DRAG, H as DROP_MANAGER_MODE_TAP, B as DropManager, U as SurfaceDropManager };