import { Injectable, Injector } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { tap, map, concatMap, catchError } from 'rxjs/operators';
import { Observable, forkJoin, of } from 'rxjs';
import { HttpService } from '@core/services/http.service';
import { UserService } from '@core/services/user.service';
import { BeinformedService } from '@core/services/beinformed.service';
import { ConfigurationService } from '@core/services/configuration.service';
import { MODULES, MODULES_HIDDEN, MODULES_HIDDEN_ON_PRODUCTION, NARIS_MODULE_NAMES } from '@core/constants';
import { environment } from '@src/environments/environment';
import { WORDPRES_BASE_URL } from '@core/constants/core-constants';
import { Router } from '@angular/router';
import { NarisBreadcrumbService } from './breadcrumb.service';
import type { IWebApplication, ICaseViewPanel, ICombinedResponse, IHref, IModule } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class CoreService extends HttpService {

  /**
   * Cache for webapplication data from BeInformed
   */
  public applicationData: IWebApplication;
  public loading = false;
  public loadingMessage: string;
  public helpHref: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(WORDPRES_BASE_URL);
  public helpOpened = false;
  public openArticle = true;
  public sidebarCollapsed: boolean;

  constructor(
    injector: Injector,
    private readonly userService: UserService,
    private readonly beinformedService: BeinformedService,
    private readonly configurationService: ConfigurationService,
    private readonly sanitizer: DomSanitizer,
    private readonly narisBreadcrumb: NarisBreadcrumbService,
    private readonly router: Router
  ) {
    super(injector);
  }

  public initialize(): Observable<any> {
    return forkJoin([
      this.fetchApplicationData(),
      this.userService.fetchUserData()
    ]);
  }

  /**
   * Get the application data
   */
  public fetchApplicationData(): Observable<any> {
    return this.get('/')
      .pipe(tap(result => this.applicationData = result.webapplication));
  }

  public fetchModules() {
    return this.beinformedService.fetchResponseWithContributions('/')
      .pipe(
        map(result => this.transformModules(result)),
        concatMap(result => this.addModules(result))
      );
  }

  private addModules({ modules, configModules, supportModules, setupModules }: Record<string, IModule[]>): Observable<any> {
    const configBase = '/config';
    this.narisBreadcrumb.add(configBase, 'Configuration');
    if (!configModules?.find(m => m.name === 'Configuration')) {
      // Return if we dont have config access
      return of({ modules, configModules, supportModules, setupModules });
    } else {
      // Otherwise, transform config module
      return this.configurationService.fetchOrganization().pipe(
        catchError(() => of({ panels: null })),
        map(({ panels }) => {
          if (!panels) return { modules, configModules, supportModules, setupModules };

          // Filter only the modules from the panel array
          const modulePanels = panels.filter((p: ICaseViewPanel) => NARIS_MODULE_NAMES.includes(p.name));
          // Create sidebar routes
          const routes: Record<string, any>[] = [];
          const availableGeneralPanels = panels.filter((p: ICaseViewPanel) => !NARIS_MODULE_NAMES.includes(p.name));
          if (!!availableGeneralPanels?.length) {
            routes.push({ url: `${configBase}/general`, label: 'General' });
            this.configurationService.hasGeneralPanel = true;
          }
          // Create module routes in sidebar for modules
          modulePanels.forEach((p: ICaseViewPanel) => {
            const panelEndpoint = p.href.split('/').pop();
            const href = `${configBase}/${panelEndpoint}`;
            routes.push({ href, label: p.label, resourcetype: p.resourcetype, panelName: p.name });
          });
          const configModule = configModules.find(m => m.name === 'Configuration');
          if (!!configModule && (configModule as Record<string, any>).components?.some((c: Record<string, any>) => c.name === 'Deleted')) {
            const deletedComponent = (configModule as Record<string, any>).components?.find((c: Record<string, any>) => c.name === 'Deleted');
            routes.push({href: '/config/deleted', label: deletedComponent.label, resourcetype: 'GroupingPanel', panelName: deletedComponent.name});
          }
          if (!!configModule && (configModule as Record<string, any>).components?.some((c: Record<string, any>) => c.name === 'Questionnaire')) {
            const questionnaireComponent = (configModule as Record<string, any>).components?.find((c: Record<string, any>) => c.name === 'Questionnaire');
            routes.push({href: '/config/questionnaire', label: questionnaireComponent.label, resourcetype: 'GroupingPanel', panelName: questionnaireComponent.name});
          }

          // Add the custom configuration route to the configModules...
          configModules.unshift({href: configBase, label: 'Configuration', icon: MODULES.Config.icon, routes});
          // ...and remove the old one
          const newConfigModules = configModules.filter(m => m.name !== 'Configuration');
          // Return modules and configModules
          return {modules, configModules: newConfigModules, supportModules, setupModules};
        })
      );
    }
  }

  private transformModules({ data, contributions }: ICombinedResponse) {
    const filteredTabs = (data._links?.tab as IHref[])?.filter(tab => {
      if (environment.production) return  !MODULES_HIDDEN_ON_PRODUCTION.includes(tab.name!) && !MODULES_HIDDEN.includes(tab.name!) ;
      else return !MODULES_HIDDEN.includes(tab.name!);
    });
    const tabs = filteredTabs.map((tab: any) => {
      const tabConfig = (contributions._links?.tab as Record<string, any>[])?.find((t: any) => t.name === tab.name);
      if (!!tabConfig) {
        const isArchiveMode = this.router.url.includes('archive');
        const splittedLabel = tabConfig.label.split(' ');
        let newLabel = tabConfig.label;
        if (splittedLabel.length >  1) {
          splittedLabel.splice(splittedLabel.length - 1, 1);
          newLabel = splittedLabel.join(' ');
        }
        tabConfig.label = isArchiveMode ? newLabel : tabConfig.label;
        tabConfig.name = isArchiveMode ? tabConfig.name.replace('Archive', '') : tabConfig.name;
      }
      const moduleConfig = MODULES[tabConfig?.name || tab.name];
      const routes = moduleConfig?.routes || [];
      const icon = moduleConfig?.icon || 'help_outline';

      // Preload breadcrumb for module endpoint
      this.narisBreadcrumb.add(tab.href, tabConfig?.label);

      const components = tab.components
        ?.map((component: any) => {
          const componentConfig = tabConfig?.components.find((c: any) => c.name === component.name);
          // Preload breadcrumb for component endpoint
          this.narisBreadcrumb.add(component.href, componentConfig.label);
          return { ...component, ...componentConfig };
        })
        .filter((component: any) => !component.layouthint?.includes('hide'))
        .filter((component: any) => !component.layouthint?.includes('go-to-case-view'))
      || [];
      return { ...tab, ...tabConfig, icon, routes: [ ...routes, ...components ] };
    });
    const modules = tabs?.filter((tab: any) => !this.configModules.includes(tab.name) && !this.supportModules.includes(tab.name) && !this.setupModules.includes(tab.name));
    const configModules = tabs?.filter((tab: any) => this.configModules.includes(tab.name) && !this.supportModules.includes(tab.name) && !this.setupModules.includes(tab.name));
    const supportModules = tabs?.filter((tab: any) => this.supportModules.includes(tab.name) && !this.setupModules.includes(tab.name));
    const setupModules = tabs?.filter((tab: any) => this.setupModules.includes(tab.name));
    const myProfileModule = configModules.find(module => module.name === 'MyProfile');
    if (!!myProfileModule && myProfileModule.routes?.length < 2)
      myProfileModule.routes = [];
    if (!!supportModules?.[0] && supportModules?.[0].routes?.length < 2)
      supportModules[0].routes = [];
    if (!!setupModules?.[0] && setupModules?.[0].routes?.length < 2)
      setupModules[0].routes = [];
  
    if (!environment.production) configModules?.push({href: '/test', label: 'Test', name: 'Test', icon: 'draft'});
    return {modules, configModules, supportModules, setupModules} as Record<string, IModule[]>;
  }

  /**
   * Return config modules
   */
  get configModules() {
    return ['Configuration', 'NARISAdmin', 'MyProfile', 'Config', 'Knowledgebase', 'Import'];
  }

  get supportModules() {
    return ['NARISSupport', 'NARISCsm'];
  }

  get setupModules() {
    return ['SetupOrganization'];
  }

  public showLoader(loading: boolean): void {
    this.loading = loading;
  }

  public setHelp(href: string) {
    this.helpHref = this.sanitizer.bypassSecurityTrustResourceUrl(href);
  }
}
