import { createClass as e, classCallCheck as t } from "../_virtual/_rollupPluginBabelHelpers.js";
import { JSON_DATATYPE as r } from "../core/io.js";
var n = function () {
  function r() {
    t(this, r);
  }
  e(r, null, [{
    key: "setNodeText",
    value: function e(t, r) {
      t.text = r;
      try {
        t.textContent = r;
      } catch (e) {}
    }
  }, {
    key: "getNodeText",
    value: function e(t) {
      return t != null ? t.text || t.textContent : "";
    }
  }, {
    key: "getChild",
    value: function e(t, r) {
      var n = null;
      for (var o = 0; o < t.childNodes.length; o++) {
        if (t.childNodes[o].nodeType === 1 && t.childNodes[o].nodeName === r) {
          n = t.childNodes[o];
          break;
        }
      }
      return n;
    }
  }, {
    key: "getChildren",
    value: function e(t, r) {
      var n = [];
      for (var o = 0; o < t.childNodes.length; o++) {
        if (t.childNodes[o].nodeType === 1 && t.childNodes[o].nodeName === r) {
          n.push(t.childNodes[o]);
        }
      }
      return n;
    }
  }, {
    key: "xmlToString",
    value: function e(t) {
      try {
        return new XMLSerializer().serializeToString(t).replace(/\s*xmlns=\"http\:\/\/www.w3.org\/1999\/xhtml\"/g, "");
      } catch (e) {
        try {
          return t.xml;
        } catch (e) {
          throw new Error("Cannot serialize XML " + e);
        }
      }
    }
  }, {
    key: "createElement",
    value: function e(t, n, o) {
      var a;
      try {
        a = new ActiveXObject("Microsoft.XMLDOM").createNode(1, t, "");
      } catch (e) {
        a = document.createElement(t);
      }
      if (o) {
        r.setNodeText(a, o);
      }
      if (n) {
        for (var i in n) {
          a.setAttribute(i, n[i]);
        }
      }
      return a;
    }
  }]);
  return r;
}();
function o(e, t) {
  t = t || 150;
  var r = null;
  return function () {
    window.clearTimeout(r);
    r = window.setTimeout(e, t);
  };
}
function a(e) {
  var t = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject("Microsoft.XMLHTTP");
  var n = e.type || "GET";
  if (t) {
    var o = e.dataType === r ? function (e) {
      return JSON.parse(e);
    } : function (e) {
      return e;
    };
    t.open(n, e.url, true);
    var a = e.headers || {};
    for (var i in a) {
      t.setRequestHeader(i, a[i]);
    }
    t.onreadystatechange = function () {
      if (t.readyState === 4) {
        if (("" + t.status)[0] === "2") {
          e.success(o(t.responseText));
        } else {
          e.error && e.error(t.responseText, t.status);
        }
      }
    };
    t.send(e.data ? JSON.stringify(e.data) : null);
  } else {
    e.error && e.error("ajax not supported");
  }
}
export { a as ajax, o as debounce, n as xml };