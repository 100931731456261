import { inherits as t, createSuper as e, classCallCheck as i, defineProperty as r, assertThisInitialized as a, createClass as s } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { EndpointRepresentation as h } from "./endpoint-representation.js";
import { TYPE_RECTANGLE_ENDPOINT as n } from "./definitions.js";
var o = function (h) {
  t(l, h);
  var o = e(l);
  function l(t, e) {
    var s;
    i(this, l);
    s = o.call(this, t, e);
    r(a(s), "width", void 0);
    r(a(s), "height", void 0);
    r(a(s), "type", n);
    e = e || {};
    s.width = e.width || 10;
    s.height = e.height || 10;
    return s;
  }
  s(l, null, [{
    key: "_getParams",
    value: function t(e) {
      return {
        width: e.width,
        height: e.height
      };
    }
  }]);
  return l;
}(h);
r(o, "type", n);
export { o as RectangleEndpoint };