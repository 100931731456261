import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BASE_URL } from '@core/constants';
import { SnackbarService } from '@core/services';
import { DatafeedsService } from '@core/services/datafeeds.service';
import { fadeInOutAnimation } from '@shared/animations/core.animations';
import { Subscription } from 'rxjs';
import { MatTooltip } from '@angular/material/tooltip';
import { CdkCopyToClipboard } from '@angular/cdk/clipboard';
import { MatProgressBar } from '@angular/material/progress-bar';
import { JsonPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ToolbarComponent } from '../../../shared/components/toolbar/toolbar.component';
import { FormFieldComponent } from '../../../shared/components/form-field/form-field.component';
import { InputComponent } from '../../../shared/elements/input/input.component';
import { IconComponent } from '../../../shared/elements/icon/icon.component';
import { FormActionsComponent } from '../../../shared/components/form-actions/form-actions.component';
import { ButtonComponent } from '../../../shared/elements/button/button.component';
import { EmptyStateComponent } from '../../../shared/components/empty-state/empty-state.component';

@Component({
  selector: 'app-datafeeds',
  templateUrl: './datafeeds.component.html',
  styleUrls: ['./datafeeds.component.scss'],
  animations: [fadeInOutAnimation],
  standalone: true,
  imports: [ToolbarComponent, FormFieldComponent, InputComponent, IconComponent, MatTooltip, FormActionsComponent, ButtonComponent, CdkCopyToClipboard, MatProgressBar, EmptyStateComponent, JsonPipe, TranslateModule]
})
export class DatafeedsComponent implements OnInit {

  public endpoints: Record<string, any>[];
  public selectedEndpoint: Record<string, any> | null;
  public endpointForm: FormGroup;
  public datafeedUrl: string | null;
  public datafeedResult: any;
  public hideLogin = false;
  public expanded = true;
  public loading = false;
  public apiKey: string;
  public apiKeyControl: FormControl;

  private readonly subs: Subscription[] = [];
  private downloadElement: HTMLElement;

  constructor(
    private readonly datafeedService: DatafeedsService,
    private readonly snackbarService: SnackbarService
  ) { }

  public ngOnInit(): void {
    this.apiKeyControl = new FormControl('');

    this.datafeedService.getApiKey().subscribe(apiKey => {
      if (!!apiKey) {
        this.apiKey = apiKey;
        this.apiKeyControl.setValue(this.apiKey);
        this.subs.push(this.datafeedService.getEndpoints().subscribe({
          next: x => {
            this.endpoints = x['end_points'];
          },
          error: err => {
            this.snackbarService.open({
              text: err.error.message,
              type: 'error'
            });
          }
        }));
      }
    });
  }

  public resetForm() {
    this.hideLogin = false;
    this.endpoints = [];
    this.reset();
    this.selectedEndpoint = null;
  }

  public selectEndpoint(endpoint: Record<string, any>) {
    this.selectedEndpoint = endpoint;
    this.endpointForm = new FormGroup({});
    this.selectedEndpoint.parameters.forEach((param: Record<string, any>) => {
      const newControl = new FormControl('');
      this.endpointForm.addControl(param.param, newControl);
    });
    this.reset();
  }

  private reset() {
    this.datafeedUrl = null;
    this.datafeedResult = null;
    this.expanded = true;
  }

  public sendRequest() {
    const queryParams = this.getQueryParams();
    const url = this.selectedEndpoint?.endpoint;
    const fullUrl = `/${url}${queryParams}`;
    this.datafeedUrl = `${BASE_URL}/${url}${queryParams}`;
    switch (this.selectedEndpoint?.method.toUpperCase()) {
      case 'GET': this.getUrl(fullUrl); break;
      case 'POST': this.postUrl(fullUrl, null); break;
    }
  }

  private getQueryParams(): string {
    let queryParams = '';
    const formKeys = Object.keys(this.endpointForm.controls);
    formKeys.forEach(key => {
      const fieldValue = this.endpointForm.get(key)?.value;
      if (!!fieldValue) {
        if (!queryParams || queryParams === '') queryParams = '?';
        else queryParams += '&';
        queryParams += `${key}=${fieldValue}`;
      }
    });
    return queryParams;
  }

  private getUrl(url: string) {
    this.expanded = false;
    this.loading = true;
    this.subs.push(
      this.datafeedService.getUrl(url).subscribe({
        next: x => {
          if (!!x)
            this.datafeedResult = x;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        }
      })
    );
  }

  private postUrl(url: string, body: any) {
    this.expanded = false;
    this.loading = true;
    this.subs.push(
      this.datafeedService.postUrl(url, body).subscribe({
        next: x => {
          if (!!x)
            this.datafeedResult = x;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        }
      })
    );
  }

  public downloadResult() {
    if (!this.datafeedResult) return;
    const sanitizedEndpoint = this.selectedEndpoint?.endpoint.replace(/[^a-z0-9]/gi, '_').toLowerCase();
    const fileName = `${sanitizedEndpoint}.json`;
    const jsonData = JSON.stringify(this.datafeedResult.data);
    this.downloadJson(fileName, jsonData);
  }

  private downloadJson(fileName: string, jsonString: string) {
    if (!this.downloadElement) this.downloadElement = document.createElement('a');
    const fileType = 'text/json';
    this.downloadElement.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(jsonString)}`);
    this.downloadElement.setAttribute('download', fileName);
    const event = new MouseEvent('click');
    this.downloadElement.dispatchEvent(event);
  }

  public getFormControl(controlName: string) {
    return this.endpointForm.get(controlName) as FormControl;
  }
}
