import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ObservableInput, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BI_PATH, NON_REST_BI_PATH, BASE_URL } from '@core/constants';
import type { IHttpOptions } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private readonly httpClient: HttpClient;

  constructor(injector: Injector) {
    this.httpClient = injector.get(HttpClient);
  }

  /**
   * Function to call to do an API request via GET
   * @param url the url slug to be called
   * @param isRestUrl determines if '/BeInformed/restServices/ui' (default) or '/BeInformed' is being used as url
   * @param useJSONHeaders determines if call headers should use content-types 'application/x-www-form-urlencoded' or 'application/json' (default)
   */
  public get<T = any>(url: string, isRestUrl = true, isBeInformed = true, options?: IHttpOptions): Observable<T> {
    let newUrl = `${BASE_URL}${url}`;
    if (isBeInformed) {
      const beInformedUrl = isRestUrl ? BI_PATH : NON_REST_BI_PATH;
      newUrl = `${BASE_URL}${beInformedUrl}${url}`;
    }
    return this.httpClient.get(newUrl, options)
      .pipe(
        catchError(this.handleError<any>(url))
      );
  }

  /**
   * Function to call to do an API request via GET
   * @param url the url slug to be called
   * @param body the data to be sent to the backend
   * @param isRestUrl determines if '/BeInformed/restServices/ui' (default) or '/BeInformed' is being used as url
   * @param useJSONHeaders determines if call headers should use content-types 'application/x-www-form-urlencoded' or 'application/json' (default)
   */
  public post<T = any>(url: string, body = {}, isRestUrl = true, isBeInformed = true, options?: IHttpOptions, useJSONHeaders = true): Observable<T> {
    const httpOptions = {headers: new HttpHeaders({'Content-Type':  useJSONHeaders ? 'application/json' : 'application/x-www-form-urlencoded'})};
    let newUrl = `${BASE_URL}${url}`;
    if (isBeInformed) {
      const beInformedUrl = isRestUrl ? BI_PATH : NON_REST_BI_PATH;
      newUrl = `${BASE_URL}${beInformedUrl}${url}`;
    }

    return this.httpClient.post(newUrl, body, !!options ? options : httpOptions).pipe(catchError<any, ObservableInput<any>>(this.handleError<any>(url)));
  }

  /**
   * The error handler for the API calls
   * @param result The result of the call
   */
  protected handleError<T extends Record<string, any>>(result?: T) {
    return (error: any): Observable<T | void> => {
      // This call always returns a 404, this is expected behaviour and should not stop the application
      if (!!error.url?.includes('/redirectLogin') || !!error.url?.includes('/secureRedirect')) return of(result);
      throw error;
    };
  }
}
