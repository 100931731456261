import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StreamService } from '@core/services';

@Injectable({
  providedIn: 'root'
})
export class StreamGuard  {

  constructor(private readonly streamService: StreamService) {}

  public async canActivate(_: ActivatedRouteSnapshot, __: RouterStateSnapshot) {
    const stream = await this.streamService.openLocalStream();
    return !!stream;
  }
}
