import {DomSanitizer} from '@angular/platform-browser';
import {PipeTransform, Pipe} from '@angular/core';
import sanitizeHtml from 'sanitize-html';


@Pipe({
  name: 'safeHtml',
  standalone: true
})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private readonly sanitized: DomSanitizer) {}
  public transform(value: string, attrs: string[] = []) {
    const allowedAttributes = {
      span: ['style', ...attrs]
    };
    const allowedStyles = {
      '*': { 'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/], 'background-color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/] }
    };
    const sanitized = sanitizeHtml(value, {allowedAttributes, allowedStyles});
    return this.sanitized.bypassSecurityTrustHtml(sanitized);
  }
}
