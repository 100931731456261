import { extend as r, log as o } from "../util/util.js";
import { transformAnchorPlacement as e } from "../core/connector/abstract-connector.js";
import { CONNECTOR_TYPE_CUBIC_BEZIER as t } from "./bezier-connector.js";
import { quadrant as n } from "../util/geom.js";
import { _addConnectorSegment as c, Connectors as u } from "../core/connector/connectors.js";
import { SEGMENT_TYPE_ARC as i } from "../core/connector/arc-segment.js";
import { SEGMENT_TYPE_BEZIER as s } from "./bezier-segment.js";
function l(o) {
  if (o.geometry == null) {
    return null;
  } else {
    return {
      controlPoints: [r({}, o.geometry.controlPoints[0]), r({}, o.geometry.controlPoints[1])],
      source: r({}, o.geometry.source),
      target: r({}, o.geometry.target)
    };
  }
}
function y(r, o, e, t, n, c) {
  var u = n[0] !== c[0] || n[1] === c[1],
    i = {
      x: 0,
      y: 0
    };
  if (!u) {
    if (n[0] === 0) {
      i.x = e.curX < t.curX ? o.x + r.minorAnchor : o.x - r.minorAnchor;
    } else {
      i.x = o.x - r.majorAnchor * n[0];
    }
    if (n[1] === 0) {
      i.y = e.curY < t.curY ? o.y + r.minorAnchor : o.y - r.minorAnchor;
    } else {
      i.y = o.y + r.majorAnchor * c[1];
    }
  } else {
    if (c[0] === 0) {
      i.x = t.curX < e.curX ? o.x + r.minorAnchor : o.x - r.minorAnchor;
    } else {
      i.x = o.x + r.majorAnchor * c[0];
    }
    if (c[1] === 0) {
      i.y = t.curY < e.curY ? o.y + r.minorAnchor : o.y - r.minorAnchor;
    } else {
      i.y = o.y + r.majorAnchor * n[1];
    }
  }
  return i;
}
function a(r, o, e, t, n, u, i) {
  var l,
    a,
    m = t.curX < n.curX ? u : 0,
    f = t.curY < n.curY ? i : 0,
    x = t.curX < n.curX ? 0 : u,
    g = t.curY < n.curY ? 0 : i;
  if (r.edited !== true) {
    l = y(r, {
      x: m,
      y: f
    }, t, n, o.so, o.to);
    a = y(r, {
      x: x,
      y: g
    }, n, t, o.to, o.so);
  } else {
    l = r.geometry.controlPoints[0];
    a = r.geometry.controlPoints[1];
  }
  r.geometry = {
    controlPoints: [l, a],
    source: e.sourcePos,
    target: e.targetPos
  };
  c(r, s, {
    x1: m,
    y1: f,
    x2: x,
    y2: g,
    cp1x: l.x,
    cp1y: l.y,
    cp2x: a.x,
    cp2y: a.y
  });
}
function m(r, o, e, t, u, i, l) {
  var y = t.curX < u.curX ? 0 : i,
    a = t.curY < u.curY ? 0 : l,
    m = t.curX < u.curX ? i : 0,
    x = t.curY < u.curY ? l : 0;
  if (t.x === 0) {
    y -= r.margin;
  }
  if (t.x === 1) {
    y += r.margin;
  }
  if (t.y === 0) {
    a -= r.margin;
  }
  if (t.y === 1) {
    a += r.margin;
  }
  if (u.x === 0) {
    m -= r.margin;
  }
  if (u.x === 1) {
    m += r.margin;
  }
  if (u.y === 0) {
    x -= r.margin;
  }
  if (u.y === 1) {
    x += r.margin;
  }
  if (r.edited !== true) {
    var g = (y + m) / 2,
      P = (a + x) / 2,
      p = n({
        x: y,
        y: a
      }, {
        x: m,
        y: x
      }),
      X = Math.sqrt(Math.pow(m - y, 2) + Math.pow(x - a, 2));
    r._controlPoint = f(g, P, p, e.sourcePos, e.targetPos, r.curviness, r.curviness, X, r.proximityLimit);
  } else {
    r._controlPoint = r.geometry.controlPoints[0];
  }
  var Y, h, b, d;
  Y = r._controlPoint.x;
  h = r._controlPoint.x;
  b = r._controlPoint.y;
  d = r._controlPoint.y;
  r.geometry = {
    controlPoints: [r._controlPoint, r._controlPoint],
    source: e.sourcePos,
    target: e.targetPos
  };
  c(r, s, {
    x1: m,
    y1: x,
    x2: y,
    y2: a,
    cp1x: Y,
    cp1y: b,
    cp2x: h,
    cp2y: d
  });
}
function f(r, o, e, t, n, c, u, i, s) {
  if (i <= s) {
    return {
      x: r,
      y: o
    };
  }
  if (e === 1) {
    if (t.curY <= 0 && n.curY >= 1) {
      return {
        x: r + (t.x < .5 ? -1 * c : c),
        y: o
      };
    } else if (t.curX >= 1 && n.curX <= 0) {
      return {
        x: r,
        y: o + (t.y < .5 ? -1 * u : u)
      };
    } else {
      return {
        x: r + -1 * c,
        y: o + -1 * u
      };
    }
  } else if (e === 2) {
    if (t.curY >= 1 && n.curY <= 0) {
      return {
        x: r + (t.x < .5 ? -1 * c : c),
        y: o
      };
    } else if (t.curX >= 1 && n.curX <= 0) {
      return {
        x: r,
        y: o + (t.y < .5 ? -1 * u : u)
      };
    } else {
      return {
        x: r + c,
        y: o + -1 * u
      };
    }
  } else if (e === 3) {
    if (t.curY >= 1 && n.curY <= 0) {
      return {
        x: r + (t.x < .5 ? -1 * c : c),
        y: o
      };
    } else if (t.curX <= 0 && n.curX >= 1) {
      return {
        x: r,
        y: o + (t.y < .5 ? -1 * u : u)
      };
    } else {
      return {
        x: r + -1 * c,
        y: o + -1 * u
      };
    }
  } else if (e === 4) {
    if (t.curY <= 0 && n.curY >= 1) {
      return {
        x: r + (t.x < .5 ? -1 * c : c),
        y: o
      };
    } else if (t.curX <= 0 && n.curX >= 1) {
      return {
        x: r,
        y: o + (t.y < .5 ? -1 * u : u)
      };
    } else {
      return {
        x: r + c,
        y: o + -1 * u
      };
    }
  }
}
function x(r, o, e) {
  var n = e.sourcePos,
    u = e.targetPos,
    s = Math.abs(n.curX - u.curX),
    l = Math.abs(n.curY - u.curY);
  if (!r.showLoopback || e.sourceEndpoint.elementId !== e.targetEndpoint.elementId) {
    r.isLoopbackCurrently = false;
    if (r.type === t) {
      a(r, o, e, n, u, s, l);
    } else {
      m(r, o, e, n, u, s, l);
    }
  } else {
    r.isLoopbackCurrently = true;
    var y = e.sourcePos.curX,
      f = e.sourcePos.curY - r.margin,
      x = y,
      g = f - r.loopbackRadius,
      P = x - r.loopbackRadius,
      p = g - r.loopbackRadius;
    s = 2 * r.loopbackRadius;
    l = 2 * r.loopbackRadius;
    o.points[0] = P;
    o.points[1] = p;
    o.points[2] = s;
    o.points[3] = l;
    c(r, i, {
      loopback: true,
      x1: y - P + 4,
      y1: f - p,
      startAngle: 0,
      endAngle: 2 * Math.PI,
      r: r.loopbackRadius,
      ac: !r.clockwise,
      x2: y - P - 4,
      y2: f - p,
      cx: x - P,
      cy: g - p
    });
  }
}
function g(r, o, t) {
  return {
    controlPoints: [{
      x: r.controlPoints[0].x + o,
      y: r.controlPoints[0].y + t
    }, {
      x: r.controlPoints[1].x + o,
      y: r.controlPoints[1].y + t
    }],
    source: e(r.source, o, t),
    target: e(r.target, o, t)
  };
}
function P(r, e) {
  if (e != null) {
    if (e.controlPoints == null || e.controlPoints.length != 2) {
      o("jsPlumb Bezier: cannot import geometry; controlPoints missing or does not have length 2");
      u.setGeometry(r, null, true);
      return false;
    }
    if (e.controlPoints[0].x == null || e.controlPoints[0].y == null || e.controlPoints[1].x == null || e.controlPoints[1].y == null) {
      o("jsPlumb Bezier: cannot import geometry; controlPoints malformed");
      u.setGeometry(r, null, true);
      return false;
    }
    if (e.source == null || e.source.curX == null || e.source.curY == null) {
      o("jsPlumb Bezier: cannot import geometry; source missing or malformed");
      u.setGeometry(r, null, true);
      return false;
    }
    if (e.target == null || e.target.curX == null || e.target.curY == null) {
      o("jsPlumb Bezier: cannot import geometry; target missing or malformed");
      u.setGeometry(r, null, true);
      return false;
    }
    u.setGeometry(r, e, false);
    return true;
  } else {
    return false;
  }
}
export { x as computeBezierConnector, l as exportBezierGeometry, P as importBezierGeometry, g as transformBezierGeometry };