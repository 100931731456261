import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { catchError, of, Subject, Subscription } from 'rxjs';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { BeinformedService, FormService, HttpService, TreeviewService, FileService, TabService, NarisBreadcrumbService, SocketService } from '@core/services';
import { TREEVIEW_COPY_TOKEN } from '@core/constants';
import { LocalStorage } from '@core/decorators/storage.decorators';
import { fadeInOutAnimation } from '@shared/animations/core.animations';
import { getIcon, getIconColumnName, transformResultsToItems } from '@core/helpers';
import { CompareService } from '@core/services/compare.service';
import { MatMenuTrigger, MatMenu, MatMenuItem, MatMenuContent } from '@angular/material/menu';
import { AuditViewersService } from '@core/services/audit-viewers.service';
import { ThemePalette } from '@angular/material/core';
import { FooterToolbarService } from '@core/services/footer-toolbar.service';
import { BreadcrumbTabsService } from '@core/services/breadcrumb-tab.service';
import { CdkDrag, CdkDropList, CdkDragPlaceholder } from '@angular/cdk/drag-drop';
import { NgClass } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { DragAndDropManagerDirective } from '../../../directives/drag-and-drop-manager.directive';
import { IconComponent } from '../../../elements/icon/icon.component';
import { BouncerComponent } from '../../bouncer/bouncer.component';
import { CheckboxComponent } from '../../../elements/checkbox/checkbox.component';
import { ButtonComponent } from '../../../elements/button/button.component';
import { AvatarComponent } from '../../../elements/avatar/avatar.component';
import type { TreeviewCopyFormComponent } from '@shared/components/treeview/treeview-copy-form/treeview-copy-form.component';
import type { IAction, IContributionsAttributeOption, IFormResponse, ITaskGroup, ITreeviewCompareItem, ITreeViewItem } from '@core/models';
import type { ComponentType } from '@angular/cdk/portal';
import type { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'naris-treeview-group',
  templateUrl: './treeview-group.component.html',
  styleUrls: ['./treeview-group.component.scss'],
  animations: [fadeInOutAnimation],
  standalone: true,
  imports: [CdkDrag, CdkDropList, DragAndDropManagerDirective, NgClass, MatTooltip, CdkDragPlaceholder, IconComponent, BouncerComponent, CheckboxComponent, MatMenuTrigger, ButtonComponent, MatMenu, MatMenuItem, AvatarComponent, MatMenuContent, TranslateModule]
})
export class TreeviewGroupComponent implements OnInit, OnDestroy, AfterViewInit {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('onDragDrop') public onDragDrop$!: Subject<CdkDragDrop<ITreeViewItem[]>>;
  @Input() public item!: ITreeViewItem & Record<string, any>;
  @Input() public prefix?: string;
  @Input() public editMode = false;
  @Input() public grouplessNodeButtons: string[] = [];
  @Input() public popupData: { keyString: string; keyId: string; data: any; icon: string; label: string };
  @Input() public layouthint: string[] | null;
  @Input() public onlyRedirect: boolean;
  @Input() public dragDropDisabled = true;
  @Input() public label: string;
  @Input() public compareObject: { isNewTree: boolean; showCompareColors: boolean } | null;
  @Input() public enableLazyLoading = true;
  @Input() public roomName: string;
  @Input() public occupiedNodes: any[];
  @Input() public isAssetClassification: boolean;
  @Output() public readonly updated = new EventEmitter<any>();
  @Output() public readonly selected = new EventEmitter<ITreeViewItem>();
  @Output() public readonly dragged = new EventEmitter<boolean>();
  @Output() public readonly selectedUrl = new EventEmitter<string>();
  @Output() public readonly updateTaskGroup = new EventEmitter<boolean>();
  @Output() public readonly reloadParent$ = new EventEmitter<void>();
  @Output() public readonly refreshData$ = new EventEmitter<void>();

  @LocalStorage() public treeviewSelectedItem: ITreeViewItem;

  @ViewChild('group') public group: ElementRef;
  @ViewChild(MatMenuTrigger) public contextMenu: MatMenuTrigger;
  
  public actions?: ITaskGroup;
  public childrenLoading = false;
  public ungroupedActions?: IAction[] = [];
  public showExtraItems = false;
  public draggedIndex: number | null = null;

  private readonly subs: Subscription[] = [];
  private connectedThroughMsg: string;
  
  private formTokens: string[];
  private contextmenuItem: ITreeViewItem;
  public copyAction: any;  
  public extraActions: IAction[] | undefined;
  public scoreAction: any;
  public showContext = false;

  private timeout: NodeJS.Timeout;
  
  public contextMenuPosition =  {x: '0', y: '0'};
  private readonly retrievedParent: number[] = [];

  public colorAccent = 'accent' as ThemePalette;
  public colorNew = 'new' as ThemePalette;

  public hasInvolvedType = false;

  private availableTranslations: IContributionsAttributeOption[] | undefined = [];

  get isArchived(): boolean {
    return this.router.url.includes('archive') && this.item.archive == null ? true : !!this.item.archive;
  }

  constructor(
    public router: Router,
    private readonly beinformedService: BeinformedService,
    private readonly fileService: FileService,
    private readonly formService: FormService,
    private readonly translate: TranslateService,
    private readonly httpService: HttpService,
    private readonly dialog: MatDialog,
    private readonly compareService: CompareService,
    private readonly tabs: TabService,
    private readonly narisBreadcrumb: NarisBreadcrumbService,
    public auditViewersService: AuditViewersService,
    public treeviewService: TreeviewService,
    private readonly socketService: SocketService,
    @Inject(TREEVIEW_COPY_TOKEN) private readonly treeviewCopyComponent: ComponentType<TreeviewCopyFormComponent>,
    private readonly footerToolbarService: FooterToolbarService,
    private readonly breadcrumbTabService: BreadcrumbTabsService
  ) {}

  public ngOnInit() {
    this.setActions();
    this.showExtraItems = this.layouthint?.some(x => x.includes('show-popup')) || false;
    this.getTranslation();
    this.setSelection(this.item);

    this.subs.push(
      this.treeviewService.resetContextMenu$.subscribe(result => this.showContext = !result),
      this.treeviewService.updateItem$.subscribe(updatedItem => {
        if (updatedItem.id === this.item.id) {
          const {active, opened, children, ...splitItem} = updatedItem;
          this.item = {active: this.item.active, opened: this.item.opened, children: this.item.children, ...splitItem};
          if (this.item.hasChildren) {
            this.toggleClicked(true);
          }
          this.setActions();
        }
      }),
      this.treeviewService.reloadChildren$.subscribe(id => id === +this.item.id ? this.loadChildren() : null),
      this.treeviewService.expandCollapseLevel$.subscribe((value: { level: number; expand: boolean }) => (value.expand ? value.level : value.level + 1) === this.item.level ? this.toggleClicked(value.expand) : null),
      this.treeviewService.deselect$.subscribe(() => this.item.active = false)
    );

    this.compareService.updatePositions$.subscribe(_mustUpdate => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.updatePositions();
      }, 100);
    });

    this.compareService.setUserAccepted$.subscribe(id => {
      if (this.item.id === id) {
        (this.item as ITreeviewCompareItem).kindOfChange += ',UserAccepted';
      }
    });

    if (this.item.hasChildren) {
      this.treeviewService.maxLevel = this.item.level!;
    }

    this.hasInvolvedType = !!this.item?.involvedType;
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.updatePositions();
    }, 200);
  }

  get hasChildren(): boolean {
    return !!this.item.children?.length;
  }
  
  get isGroup(): boolean {
    return this.item.type !== 'AuditStep';
  }

  get isProcessStructure(): boolean {
    return (this.tabs.activeTab?.href.includes('/process/process/') || this.tabs.activeTab?.href.includes('/process/process-wizard/')) && this.tabs.activeTab?.href.endsWith('/structure') && !this.item.href?.includes('archimate');
  }

  get processIcon(): string {
    if (this.item.type === 'ProcessStep' && this.item.processStepType === 'StartEvent') return 'process-startEvent-2';
    else if (this.item.type === 'ProcessStep' && this.item.processStepType === 'EndEvent') return 'process-endEvent-2';
    else if (this.item.type === 'ProcessStep' && this.item.processStepType === 'Condition') return 'process-condition';
    else if (this.item.type === 'ProcessStep' && this.item.processStepType === 'Document') return 'process-document';
    else if (this.item.type === 'ProcessStep' && this.item.processStepType === 'Datastore') return 'process-datastore';
    else if (this.item.type === 'ProcessStep' && this.item.processStepType === 'IntermediateEvent') return 'process-intermediateEvent';
    else if (this.item.type === 'ProcessStep') return 'process-task';
    else if (this.item.type === 'Process') return 'process';
    return 'question';
  }

  private setActions() {    
    this.actions = this.item.actions;
    this.scoreAction = this.item?.actions?.actions?.find(action => action.name?.includes('score'));
    const taskgroupActions = this.actions?.actions?.filter(e => !this.grouplessNodeButtons.includes(e.name!));
    this.ungroupedActions = this.actions?.actions?.filter(e => this.grouplessNodeButtons.includes(e.name!));
    if (!!this.actions) this.actions.actions = taskgroupActions;
  }

  private updatePositions() {
    if (!!this.compareObject && this.item.kindOfChange.includes('Moved') ) {
      const rect = this.group.nativeElement.getBoundingClientRect();
      if (this.compareObject.isNewTree) {
        const rootX = this.findRootX(this.group.nativeElement);
        rect.x = rootX;
        this.compareService.newItemsPositions.set(this.item.id, rect);
      } else {
        this.compareService.oldItemsPositions.set(this.item.id, rect);
      }
    }
  }

  private findRootX(el: any): number {
    if (el.classList?.value.includes('root')) {
      return el.getBoundingClientRect()?.x;
    } else {
      return this.findRootX(el.parentElement);
    }
  }

  private setSelection(item: ITreeViewItem) {
    if (!!this.treeviewSelectedItem) {
      if (JSON.stringify(item) === JSON.stringify(this.treeviewSelectedItem)) {
        item.active = true;
      } else if (!!item.children?.length) {
        item.children.forEach(child => this.setSelection(child));
      }
    }
  }

  public update(): void {
    this.updated.emit(true);
  }

  public select(item: ITreeViewItem): void {
    if (this.enableLazyLoading) {
      this.treeviewService.deselect$.next(true);
    } else {
      this.selected.emit(item);
    }
  }

  get rowActions(): { actions: IAction[] } {
    let returnValue = {actions: this.actions?.actions?.filter((a: IAction) => !a.name?.includes('move-') && !a.name?.includes('copy-') && !a.name?.includes('score') && !a.name?.includes('context-')) || []};
    if (this.isProcessStructure)
      returnValue = {actions: this.actions?.actions?.filter((a: IAction) => !a.name?.includes('move-') && !a.name?.includes('copy-') && !a.name?.includes('score') && !a.name?.includes('context-') && !a.name?.includes('add-')) || []};
    return returnValue;
  }

  get hasItemInBuffer(): boolean {
    return !!this.treeviewService?.copyBufferItem;
  }

  get processStructureAddActions(): { actions: IAction[] } {
    if (!this.isProcessStructure) return {actions: []};
    return {actions: this.actions?.actions?.filter((a: IAction) => a.name?.startsWith('add-')) || []};
  }

  private actionType: string | undefined;
  public onClickAction(action: IAction, item: ITreeViewItem, event?: MouseEvent) {
    this.select(item);
    this.item.active = true;
    this.actionType = action.type;
    if ((action.name === 'goto-object' || action.name?.startsWith('GoToObject-') || action.name === 'open-case') && !!action?.href)  {
      if (action.name === 'open-case') {
        this.beinformedService.fetchForm(action.href).subscribe(actionResult => {
          const objectUrl = actionResult?.data?.error?.formresponse?.missing?.anchors?.[0]?.elements?.find(element => element.elementid === 'redirectURL')?.suggestion;
          if (!!objectUrl) this.doGotoObject(objectUrl, event);
        });
      } else {
        this.doGotoObject(action.href, event);
      }
      return;
    } else if (action.type !== 'delete')
      if (!!action.layouthint) {
        action.layouthint.push('pop-up-action');
        action.layouthint.push('refresh');
      } else
        action.layouthint = ['pop-up-action', 'refresh'];
    void this.beinformedService.handleAction(action, this.reload, this.reload);
  }

  private doGotoObject(href: string, event?: MouseEvent) {
    if (event?.ctrlKey) {
      const newTabUrl = (this.router['location']._locationStrategy._platformLocation.location.origin as string) + href;
      window.open(newTabUrl, '_blank');
    } else {
      this.breadcrumbTabService.add({label: '-', url: href, originalUrl: href, type: '- ', children: []});
      this.footerToolbarService.reset();
      void this.router.navigate([href]);
    }
  }

  public itemClicked(item: ITreeViewItem, eventFromAvailableIcon = false): void {
    if (this.isProcessStructure && !eventFromAvailableIcon) return;
    this.narisBreadcrumb.addBreadcrumb = false;
    this.treeviewService.sentHref = item.href!;
    if (this.router.url.includes('audit-execution') && !this.router.url.includes('-wizard')) {     
      const navUrl = `${this.prefix}/${!this.isGroup ? '' : !!this.item.parentId ? 'group/' : 'root/'}${this.item.referenceId}`; 
      this.selectedUrl.emit(navUrl);
    } 
    if (!!this.compareObject) { 
      if ((item as ITreeviewCompareItem).kindOfChange?.includes('Changed')) {
        this.compareService.compareData$.next(item as ITreeviewCompareItem);
      }
    } else {
      this.treeviewSelectedItem = item;
      this.treeviewService.deselect$.next(true);
      item.active = true;
      if (!this.router.url.includes('audit-execution'))
        this.selected.emit(item);
      if (!window.getSelection()?.toString()) {
        if (this.onlyRedirect) {
          const navUrl = `${this.prefix}/${!this.isGroup ? '' : !!this.item.parentId ? 'group/' : 'root/'}${this.item.referenceId}`;
          if (!!this.roomName && !!item.href) {
            this.socketService.sendNodeClick(item.href);
          }
          if (!this.router.url.includes('audit-execution')) {
            this.footerToolbarService.reset();
            void this.router.navigate([navUrl]);
          }
        } else if (!!this.layouthint?.includes('no-details')) {
          return;
        } else if (item['_links'] || !!item.href) {
          const href = item['_links']?.self?.href ?? item.href ?? '';
          // If href is in format {module}/{case-type}/{case-id}, redirect to route
          if (/^\/[a-z-]+\/[a-z-]+\/[0-9]+$/m.test(href)) {
            this.breadcrumbTabService.add({label: '-', url: href, originalUrl: href, type: '- ', children: []});
            this.footerToolbarService.reset();
            void this.router.navigate([href]);
          }
          // If href is in format {module}/{case-type}/{case-id}/{subcase-type}/{subcase-id}, show detail view
          if (
            /^\/[a-z-]+\/[a-z-]+\/[0-9]+\/[a-z-]+\/([a-z-]|[0-9])+(\/[0-9]+)*$/m.test(href) ||
            /^\/[a-z-]+\/([a-zA-Z-]+\/){2}[0-9]+$/m.test(href)
          ) this.formService.open(href, 'detail').subscribe(saved => saved ? this.reload() : null);
        } else {
          if (this.isGroup) this.item.opened = !this.item.opened;
          else if (this.prefix) {
            this.footerToolbarService.reset();
            void this.router.navigate([`${this.prefix}/${this.item.referenceId}`]);
          }
        }
      }
    }
    
  }

  public clickAvailable(item: ITreeViewItem, objectName: string): void {
    this.tabs.activeTabName = objectName.endsWith('s') ? objectName.toLowerCase() === 'process' ? `${objectName}es` : objectName : `${objectName}s`;
    this.itemClicked(item, true);
  }

  public reload = (_redirect?: boolean, _close?: boolean, data?: any) => {
    if (this.enableLazyLoading) {
      const changedIds = [];

      if (!!this.beinformedService.formResult) {
        changedIds.push(...JSON.parse(this.beinformedService.formResult.ChangeIDs));
        if (['enable', 'disable', 'score', 'add'].includes(this.beinformedService.formResult.ChangeType.toLowerCase())) {
          changedIds.push(+this.item.id);
        } else if (this.beinformedService.formResult.ChangeType.toLowerCase() === 'delete') {
          this.treeviewService.deleteItem$.next(this.item);
          return;
        }
      } else {
        if (this.actionType === 'delete') {
          if (this.item.level !== undefined) {
            if (this.item.level > 0) this.reloadParent$.emit();
            else this.refreshData$.emit();
          }
          return;
        } else if (!!data && typeof data !== 'boolean') {
          changedIds.push(...JSON.parse(data?.Result?.ChangeIDs));
        } else {
          this.item.opened = true;
          if (!!this.item.children) {
            if (this.item.children.length > 0) {
              this.item.children = undefined;
              this.loadChildren();
            } else if (this.item.level !== undefined) {
              if (this.item.level > 0) this.reloadParent$.emit();
              else this.refreshData$.emit();
            }
          }
        }
        changedIds.push(+this.item.id);
      }
      this.treeviewService.updatedItemIds$.next(changedIds);
      
      if (!!this.beinformedService.formResult) { //first, trigger updatedItemIds, then trigger reloadChildren;
        changedIds.forEach(id => this.treeviewService.reloadChildren$.next(id));
        this.beinformedService.formResult = null;
      }
      if (data === true) {        
        this.updateTaskGroup.emit(true);
      }
    } else {
      this.updated.emit(true);
    }
  };

  public executeAction(action: IAction) {
    if (action.name?.includes('delete')) void this.beinformedService.handleAction(action, this.reload, this.reload, false, false);
    else if (action.name?.includes('download')) this.downloadFile(action);
    else this.formService.open(action.href!).subscribe(() => this.reload());
  }

  private downloadFile(action: IAction) {
    this.subs.push(
      this.fileService.downloading.subscribe(downloading => {
        if (downloading) {
          action.name = 'spinner';
        } else {
          action.name = 'download-document';
        }
      })
    );
    this.fileService.downloadFile(action.href);
  }

  public showPopupData(item: ITreeViewItem & Record<string, any>) {
    if (this.showExtraItems) {
      const decLayouthint = this.deconstructLayouthint();
      this.popupData = {keyString: decLayouthint?.keyString || '', keyId: '', data: item.extraItems, icon: decLayouthint?.icon || '', label: decLayouthint?.label || '' };
      return !!item.extraItems?.length;
    } else {
      const keyId = this.popupData.keyId;
      const keyString = this.popupData.keyString;
      const foundPopupData = this.popupData.data?.find((e: Record<string, number | string>) => e[keyId] === item.id);
      if (!!foundPopupData) {
        item[keyString] = foundPopupData[keyString];
        return true;
      }
    }
  }

  private deconstructLayouthint(): { keyString: string; icon: string; label: string } | null {
    const extraItemHint = this.layouthint?.find(x => x.includes('show-popup'));
    if (!extraItemHint) return null;
    const keyString = extraItemHint.split('of')?.[0]?.replace('show-popup: ', '').trim();
    const icon = extraItemHint.split('icon')?.[1]?.trim();
    const label = extraItemHint.split('label')?.[1]?.split('and')?.[0]?.trim();
    return {keyString, icon, label};
  }

  public toggleClicked(expand?: boolean) {
    if (this.enableLazyLoading && !this.item.opened && !this.retrievedParent.includes(this.item.id as number) && (expand === undefined || expand)) {
      this.childrenLoading = true;
      this.loadChildren();
    } else {
      this.item.opened = expand ?? !this.item.opened;
    }
  }

  private loadChildren() {
    const splittedHref = this.item.href?.split('/');
    splittedHref?.pop();
    const newHref = `${splittedHref?.join('/')}?ParentID=${this.item.id}`;
    this.beinformedService.fetchResponseWithContributions<'caselist'>(newHref  || '').subscribe({next: res => {
      const results = this.beinformedService.extractResults(res);
      const objectName = Object.keys(res.contributions.results)[0];
      this.availableTranslations = res?.contributions?.results?.[objectName]?.attributes?.find(attr => Object.keys(attr)?.[0] === 'Available')?.Available.options;
      const attributes = res.contributions.results[objectName].attributes;
      const iconColName = getIconColumnName(res);
      const items = transformResultsToItems('', false, results, iconColName, attributes);
      this.item.children = items?.map((item: Record<string, any>) => ({...item, level: (this.item.level ?? 0) + 1}));
      this.item.hasChildren = !!this.item.children?.length;
      this.item.opened = true;
      this.retrievedParent.push(this.item.id as number);
    }, error: () => {
      this.item.opened = false;
    }, complete: () => this.childrenLoading = false});
  }

  public reloadParent() {
    this.item.children = undefined;
    this.loadChildren();
  }

  public getEventLinkText(event: string) {
    return `${this.connectedThroughMsg}${event}`;
  }

  private getTranslation() {
    this.subs.push(
      this.translate.get('structure.object_connected_through').subscribe((res: string) => {
        this.connectedThroughMsg = res;
      })
    );
  }

  public dragStart(item: ITreeViewItem & Record<string, any>) {
    this.dragged.emit(true);
    item.moveAction = item.actions?.actions?.find(action => action.name === 'move-object')?.href;
    if (this.layouthint?.includes('treeview-drag-lookup')) {
      this.httpService.post(item.moveAction, {}).subscribe({error: res => {
        if (res.status === 400) {
          const lookupHref = res?.error?.formresponse?.missing?.anchors?.[0]?.elements?.[0]?._links?.lookupList?.href;
          if (!!lookupHref) {
            this.httpService.get(lookupHref).subscribe(response => {
              const rootKeys = Object.keys(response);
              const results = response[rootKeys[0]]?._embedded?.results;
              const keys = Object.keys(results[0]);
              const allowedParents = results.map((result: Record<string, any>) => result[keys[0]]?._id);
              const allowedParentsNames = results.map((result: Record<string, any>) => {
                const nameKey = Object.keys(result[keys[0]]).find(key => key.toLowerCase().includes('name'));
                return !!nameKey ? result[keys[0]]?.[nameKey] : null;
              });
              item.allowedParents = allowedParents;
              item.allowedParentsNames = allowedParentsNames;
            });
          }
        }
      }});
    }
  }

  public getIcon(inputString?: string) {
    const lowerString = inputString?.toLowerCase().trim() || '';
    return getIcon(lowerString);
  }

  public show(event: any, item: ITreeViewItem) {
    this.copyAction = item?.actions?.actions?.find(action => action.name?.includes('copy-'));
    this.extraActions = item?.actions?.actions?.filter(action => action.name?.startsWith('context-'));
    if (!this.copyAction && !this.extraActions?.length) return;
    this.contextMenuPosition.x = `${event.clientX}px`;
    this.contextMenuPosition.y = `${event.clientY}px`;
    this.contextmenuItem = item;
    this.contextMenu.menu?.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  public copy() {
    this.treeviewService.copyBufferItem = this.contextmenuItem;
  }

  public paste() {
    if (!this.hasItemInBuffer) {
      return;
    }
    const copyAction = this.treeviewService.copyBufferItem?.actions?.actions?.find(action => action.name?.includes('copy-'));
    if (!!copyAction?.href) {
      this.httpService.post(copyAction.href, {}).pipe(
        catchError((err: IFormResponse) => {
          this.formTokens = err.error?.formresponse?.tokens || [];

          if (err.error?.formresponse?.missing?.anchors[0].elements.some((e: any) => e.elementid.includes('Include'))) {
            this.showCopyDialog(copyAction.href!);
          } else {
            this.saveCopyData(copyAction.href!);
          }

          return of(undefined);
        })).subscribe();
    }
  }

  private saveCopyData(endpoint: string) {
    const postableObject: any = {
      objects: {
        CopyObjectStructure: {
          ParentID: this.contextmenuItem.id
        }
      }
    };

    if (!!this.formTokens?.length) {
      postableObject.tokens = this.formTokens;
    }

    this.postCopyData(endpoint, postableObject);
  }

  private showCopyDialog(endpoint: string) {
    this.dialog.open(this.treeviewCopyComponent, {
      panelClass: 'no-padding',
      data: {
        endpoint
      }
    }).afterClosed().subscribe(postableObject => {
      postableObject.objects.CopyObjectStructure.ParentID = this.contextmenuItem.id;
      this.postCopyData(endpoint, postableObject);
    });
  }

  private postCopyData(endpoint: string, postableObject: any) {
    this.httpService.post(endpoint, postableObject, false).subscribe({next: () => {
      this.treeviewService.refresh$.next();
    }});
  }

  public stringIncludes(str: string, value: string): boolean {
    return str?.includes(value);
  }

  public mouseEnter(el: any) {
    if (!this.compareObject) return;
    const rect = el.getBoundingClientRect();
    const item = {id: this.item.id, isNewTree: this.compareObject.isNewTree, rect};
    this.compareService.hoveredItemUpdated$.next(item);
  }

  public mouseLeave() {
    this.compareService.hoveredItemUpdated$.next({} as { id: number | string; isNewTree: boolean; rect: DOMRect });
  }

  public scoreItem(action: any) {
    if (!action) return;
    const formCallback = (reload?: boolean) => reload ? this.reload() : null;
    const postCallback = (reload?: boolean) => reload ? this.reload() : null;
    void this.beinformedService.handleAction(action, formCallback, postCallback, true);
  }
  
  public showOccupied(item: ITreeViewItem) {
    if (!item.href && !this.occupiedNodes?.length) return;
    return this.occupiedNodes?.includes(item.href);
  }

  public hasMultipleViewers(item: ITreeViewItem) {
    if (!item.href && !this.occupiedNodes?.length) return;
    const isOwnItem = item.href === this.treeviewService.sentHref;
    const itemHasMultipleViewers = this.occupiedNodes.filter(x => x === item.href)?.length > 1;
    return isOwnItem && itemHasMultipleViewers;
  }
  
  get isAsset(): boolean {
    return this.router.url.startsWith('/asset');
  }

  public getAvailableLabel(objectType: string | undefined): string {
    const returnString = !!this.availableTranslations && this.availableTranslations.length > 0 ? this.availableTranslations.find(trans => trans.code === objectType)?.label : objectType;
    return !!returnString ? returnString : '';
  }

  public toggleCheckBox(event: any, item: ITreeViewItem) {
    if (!!item?.id)
      this.treeviewService.setItemChecked$.next({itemId: item.id, checked: event.checked});
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.compareService.newItemsPositions.clear();
    this.compareService.oldItemsPositions.clear();
  }
}
