import { Component } from '@angular/core';
import { FormControl, Validators, FormsModule } from '@angular/forms';
import { MatDialogRef, MatDialogTitle, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatTooltip } from '@angular/material/tooltip';
import { TitleCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { InlineTextareaComponent } from '../../../../shared/components/inline-textarea/inline-textarea.component';
import { ButtonComponent } from '../../../../shared/elements/button/button.component';

@Component({
  selector: 'app-knowledgebase-comment',
  templateUrl: './knowledgebase-comment.component.html',
  styleUrls: ['./knowledgebase-comment.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, ButtonComponent, MatDialogClose, CdkScrollable, MatDialogContent, FormsModule, InlineTextareaComponent, MatTooltip, MatDialogActions, TitleCasePipe, TranslateModule]
})
export class KnowledgebaseCommentComponent {

  public commentControl = new FormControl('', [Validators.required, Validators.maxLength(500)]);

  constructor(private readonly dialogRef: MatDialogRef<KnowledgebaseCommentComponent>) {}

  public onSubmit() {
    this.dialogRef.close(this.commentControl.value?.trim());
  }
}
