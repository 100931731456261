import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { NgClass } from '@angular/common';

@Component({
  selector: 'naris-inline-textarea',
  templateUrl: './inline-textarea.component.html',
  styleUrls: ['./inline-textarea.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatTooltip, NgClass]
})
export class InlineTextareaComponent implements OnInit {

  @Output() public readonly inputChange = new EventEmitter<Event>();

  @Input() public control: FormControl;
  @Input() public maxLength: number | null = null;
  @Input() public error?: string;
  @Input() public value?: number | string;
  @Input() public placeholder = '';
  @Input() public id: string;

  @Input()
  get rounded() {
    return this._rounded;
  }
  set rounded(val: BooleanInput) {
    this._rounded = coerceBooleanProperty(val);
  }

  private _rounded = false;

  public ngOnInit(): void {
    if (this.control && !this.control.value && this.value) this.control.setValue(this.value);
    this.control.updateValueAndValidity();
  }
}
