import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, OnChanges, SimpleChanges, HostBinding, AfterContentChecked, forwardRef } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BeinformedService, FormService, NarisBreadcrumbService, SnackbarService, TabService, TableService } from '@core/services';
import { AssessmentService } from '@core/services/assessment.service';
import { ConsequenceService } from '@core/services/consequence.service';
import { FooterToolbarService } from '@core/services/footer-toolbar.service';
import { GuardService } from '@core/services/guard.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { StrategyMapService } from '@core/services/strategy-map.service';
import { ProcessManagerService } from '@core/services/process-manager.service';
import { BreadcrumbTabsService } from '@core/services/breadcrumb-tab.service';
import { ArchimateService } from '@core/services/archimate.service';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ToolbarComponent } from '../../shared/components/toolbar/toolbar.component';
import { ToolbarItemComponent } from '../../shared/components/toolbar/toolbar-item/toolbar-item.component';
import { ButtonComponent } from '../../shared/elements/button/button.component';
import { SlidetoggleComponent } from '../../shared/elements/slidetoggle/slidetoggle.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { FormGroupComponent } from './form-group/form-group.component';
import type { ICaseListRow, IFormButton, IPostableObject, INarisDrawer, IFormError, IFormObject, IFormSuccess, IFormResult, TInputType, IFormLookupCreateSuccess, IExtractedForm } from '@core/models';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: true,
  imports: [ToolbarComponent, ToolbarItemComponent, ButtonComponent, SlidetoggleComponent, forwardRef(() => FormGroupComponent), FormsModule, ReactiveFormsModule, NgClass, NgTemplateOutlet, LoaderComponent, TranslateModule]
})
export class FormComponent implements OnInit, OnDestroy, OnChanges, AfterContentChecked {

  @Input() public endpoint: string;
  @Input() public id: string;
  @Input() private readonly drawer: INarisDrawer;
  @Input() public bypassSubmitEvent: boolean;
  @Input() public returnFormUpdates = false;
  @Input() public fillElementsObject: any;
  @Input() public isEmbedded = false;
  @Input() public isAssessment = false;
  @Input() public checkService = false;
  @Input() public checkConsequenceService = false;
  @Input() public isConsequence = false;
  @Input() public pushValidationToService = false;
  @Input() public hidePreviousAssessment = false;
  @Input() public isDynamic = false;
  @Input() public showActions = false;
  @Input() public canEditApplicability = false;
  @Input() public parentRow: ICaseListRow;
  @Input() public isEmbeddedInTable = false;
  @Input() public tabHref: string;
  @Input() public isEmbeddedInModal = false;
  @Input() public hideButtons = false;
  @Input() public isStriped = true;
  @Input() public initAfterReset = false;
  @Input() public showClearButton = true;
  @Input() public isProcessToolkitForm = false;
  @Input() public isRegisterForm = false;
  @Input() public tableLayouthint: string[];
  @Input() public isArchimate = false;
  @Input() public isDynamicDashboard = false;
  @Input() public isAddWidgetForm = false;
  @Input() public isCollabPush = false;
  @Input() set enabled(value: boolean) {
    setTimeout(() => {
      this.objectFormEnabled = value;
    });
  }

  private _expanded: boolean;
  @Input()
  set expanded(value: boolean) {
    this._expanded = value;
    if (!this.isWizardObject) this.updateGuardForms();
  }
  @Output() public readonly closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() public readonly formChanged = new EventEmitter<any>();
  @Output() public readonly dynamicSaved = new EventEmitter<IFormLookupCreateSuccess>();
  @Output() public readonly titleChanged = new EventEmitter<string>();
  @Output() public readonly initialApplicability: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly nodeChanged = new EventEmitter<Record<string, any>>();
  @Output() public readonly formDialogSaved = new EventEmitter<IFormSuccess>();
  @Output() public readonly completed = new EventEmitter<void>();

  public loading = true;
  public title: string;
  public form: FormGroup;
  public errors: any[] | null;
  public inputs: IFormObject[] = [];
  public layouthint: string[];
  public validationErrors: any[] | null;
  public activeObject: string;
  public missingObject: string;
  public objects = {} as Record<string, any>;
  public objectFormEnabled = true;
  public onlyOptionalInputs: boolean;
  public onlyMandatoryInputs: boolean;
  public showUpdateToggle = false;
  public nonEssentialFields = ['helperText', 'heading'];
  public isInitialApplicability = false;
  public isContextChips = false;
  private tokens?: string[];
  private readonly subs: Subscription[] = [];
  private repeatedPostableObject: IPostableObject;
  private updateObjectToggleTimeout: NodeJS.Timeout;
  private currentForm: { result: IFormResult; readonlyKeys?: string[] };

  private readonly blockLayouhtintsFromInitialize = ['show-one-result-as-detail', 'only-one'];

  get isCreateWizard(): boolean {
    const parts = this.router.url.split('/');
    const caseId = parts.find(part => !isNaN(parseInt(part)));
    return this.router.url.includes('-wizard') && caseId === undefined && !this.isEmbeddedInTable && !this.isEmbeddedInModal;
  }

  get isDetailObjectPage() {
    const url = this.endpoint || this.tabHref || this.router.url;
    return !url.includes('-wizard') && url.includes('/object/') && !url.includes('audit-execution');
  }

  get isConfig() {
    const url = this.tabHref || this.router.url;
    return url.includes('config');
  }

  get isWizardObject() {
    const url = this.tabService.pushStateUrl || this.router.url.split('?')?.[0];
    return url.includes('-wizard') && url.endsWith('object');
  }

  constructor(
    private readonly beinformedService: BeinformedService,
    private readonly snackbarService: SnackbarService,
    private readonly formService: FormService,
    private readonly router: Router,
    private readonly breadcrumb: NarisBreadcrumbService,
    private readonly assessmentService: AssessmentService,
    private readonly consequenceService: ConsequenceService,
    private readonly tableService: TableService,
    private readonly footerToolbarService: FooterToolbarService,
    private readonly guardService: GuardService,
    private readonly tabService: TabService,
    private readonly strategyMapService: StrategyMapService,
    private readonly processManagerService: ProcessManagerService,
    private readonly breadcrumbTabService: BreadcrumbTabsService,
    private readonly archimateService: ArchimateService
  ) {}

  @HostBinding('class')
  get rootClasses() {
    if (!this.isEmbedded && !this.isRegisterForm) return;
    else if (this.isAssessment) return ['assessment'];
    return ['embedded'];
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!!changes.fillElementsObject) {
      Object.keys(changes.fillElementsObject.currentValue).forEach(key => {
        const updatedValue = this.fillElementsObject?.[key];
        if (!updatedValue) return;
        const inputControl = this.form?.get('CreateSubProcesses')?.get(key);
        if (!!inputControl) inputControl.setValue(updatedValue);
      });
    }
  }

  public ngOnInit() {
    const endpointPart = this.endpoint.split('/')[2];
    const routerPart = this.router.url.split('/')[2];
    if (!(endpointPart !== routerPart && this.endpoint.includes('wizard'))) this.initializeForm();
    
    if (this.isConsequence) {
      this.subs.push(
        this.consequenceService.saveTrigger.subscribe(() => {
          this.submitForm();
        })
      );
    }

    this.subs.push(
      this.guardService.deactivated$.subscribe(guard => {
        if (guard.deactivated) {
          if (!guard.reset) this.resetForm();
          this.objectFormEnabled = false;
          this.formService.updateObjectForm$.next({ enabled: false });
        }
      })
    );

    if (this.isDetailObjectPage) {
      this.objectFormEnabled = false;
    }
  }

  public ngAfterContentChecked(): void {
    const isFormValid = this.isFormValid(this.activeObject);
    this.footerToolbarService.setFormValid(isFormValid);
  }

  public ngOnDestroy() {
    this.subs.forEach((s: Subscription) => s.unsubscribe());
  }

  public onAutoSubmit() {
    this.submitForm();
  }

  /**
   * Initialize the form based on the endpoint
   */
  private initializeForm() {
    this.beinformedService.fetchForm(this.endpoint).subscribe({
      next: result => {
        if (result.data.error?.error?.id === 'Error.ResourceNotFound') this.close();
        else {
          const objectid = result.data.error.formresponse.missing?.anchors[0].objectid;
          if (this.isDetailObjectPage && !objectid?.startsWith('ReadOnly')) this.showUpdateToggle = true;
          if (objectid?.startsWith('ReadOnly')) this.hideButtons = true;
          if (this.isCreateWizard) this.formService.setWizardSteps(result);
          const isInitialApplicability = result.data.error.formresponse.missing?.anchors[0].elements.find(el => el.elementid === 'ApplicabilityNotification');
          if (!!isInitialApplicability) {
            this.isInitialApplicability = true;
            this.initialApplicability.emit();
          }

          // Extract the form from the results
          const form = this.beinformedService.extractForm(result);
          // Generate the master form group
          this.form = this.buildForm(form.objects);

          if (this.checkService) {
            Object.keys(this.form.controls).forEach(rootFormKey => {
              const subForm = this.form.get(rootFormKey) as FormGroup;

              Object.keys(subForm.controls).forEach(subKey => {
                if (Object.keys(this.assessmentService.formData).includes(rootFormKey)) {
                  subForm.get(subKey)?.setValue(this.assessmentService.formData[rootFormKey][subKey]);
                }
              });
            });
          }

          // Set inputs
          this.inputs = form.objects;
          this.consequenceService.formsValid.clear();
          // Add listener to trigger drawer hasChanged to show confirmation when we close the drawer
          this.subs.push(
            this.form.valueChanges.subscribe(() => {
              if (this.drawer && !this.drawer?.hasChanged && !this.form.pristine) this.drawer.hasChanged = true;
              if (this.pushValidationToService) {
                const activeForm = this.form.get(this.activeObject);
                this.consequenceService.formsValid.set(this.endpoint, activeForm?.valid ?? true);
                this.consequenceService.updatedFormsValid.next(this.consequenceService.formsValid);
              }
            })
          );
          // Set the tokens
          this.tokens = result.data.error?.formresponse.tokens;
          // Set the layouthint
          this.layouthint = form.layouthint;
          this.isContextChips = this.layouthint?.includes('context-chips');
          if (this.returnFormUpdates) this.form.valueChanges.subscribe(value => this.formChanged.emit(value));
          // Handle the form response
          this.handleFormResponse(result, [], form);
        }
      },
      error: this.close
    });
  }

  /**
   * Submit the form
   * @param button optional button
   */
  public submitForm(button?: IFormButton) {
    // Unset any previous errors
    this.validationErrors = null;
    this.errors = null;
    // Start the loading state
    this.loading = true;
    // Close color picker overlay
    this.formService.closeColorPicker$.next();
    // Add form values for the current object to the postable objects
    const tmpValue = this.form.get(this.activeObject)?.getRawValue();

    const inputKeys = this.inputs.find(input => input.objectid === this.activeObject)?.elements.filter(element => !(element as Record<string, any>).hidden).map(element => element.elementId);

    const keys = Object.keys(tmpValue).filter(key => {
      if (!!inputKeys?.length && !inputKeys.includes(key)) return false;
      const foundItem = this.getFormGroup(this.activeObject)?.['controls'][key];
      return foundItem.status !== 'DISABLED';
    });
    const readonlyKeys = Object.keys(tmpValue).filter(key => {
      if (!!inputKeys?.length && !inputKeys.includes(key)) return false;
      const foundItem = this.getFormGroup(this.activeObject)?.['controls'][key];
      return foundItem.status === 'DISABLED';
    });
    const values: Record<string, any> = {};
    keys.forEach(key => {
      const input = this.inputs?.[0]?.inputs?.elements?.find(el => el['elementId'] === key);
      const dependencyColumn = input?.layouthint?.find(hint => hint.startsWith('dependency-column:'))?.replace('dependency-column: ', '');
      let value = tmpValue[key];
      const form = this.inputs.find(x => x.objectid === this.activeObject);
      const element = form?.inputs.elements.find(el => el['elementId'] === key);
      if (element?.controlType === 'slide' && !value) value = false;
      if (!!dependencyColumn) value = !Array.isArray(tmpValue[key]) ? tmpValue[key].id : tmpValue[key].map((item: Record<string, any>) => item.id);
      if (key === 'Position' && this.router.url.includes('strategy-map') && this.strategyMapService.nodePosition !== '') value = this.strategyMapService.getNodePosition();
      if (key === 'GoalCaseID' || key === 'RelatedCriticalSuccessFactorID' && this.router.url.includes('strategy-map')) {
        const relation = this.strategyMapService.getNodeRelation();
        if (!!relation) {
          const relationKey = relation.type === 'goal' ? 'GoalCaseID' : 'RelatedCriticalSuccessFactorID';
          if (key === relationKey) {
            value = relation.id;
          }
        }
      }
      if (key === 'Position' && this.router.url.includes('process') && this.processManagerService.nodePosition !== '') value = this.processManagerService.nodePosition;
      if (key === 'ProcessStepType' && this.router.url.includes('process') && this.processManagerService.nodeType !== '' && this.processManagerService.nodeType !== 'Process') value = this.processManagerService.nodeType;
      if (key === 'Name' && this.isArchimate) this.archimateService.updateObjectName$.next(value);
      return values[key] = value;
    });
    if (!!button) values['NextChoice'] = button?.id; 

    this.addPostableObject(this.activeObject, values);

    this.setPeriodValues(values);

    // Generate a BeInformed postable object
    const postableObject = this.getPostableObject();

    if (this.bypassSubmitEvent) {
      this.closed.emit(postableObject);
      return;
    }

    // if repeatable form, create postable object with previous sent items
    if (this.getActiveObject?.repeatable && !this.repeatedPostableObject) {
      this.repeatedPostableObject = JSON.parse(JSON.stringify(postableObject)) as IPostableObject;
    } else if (this.getActiveObject?.repeatable && !!this.repeatedPostableObject) {
      const objectId = this.getActiveObject.objectid;
      let tempObjects: Record<string, Record<string, any>>[] = [];
      if (Array.isArray(this.repeatedPostableObject.objects)) {
        tempObjects = this.repeatedPostableObject.objects;
      } else {
        const objectKeys = Object.keys(this.repeatedPostableObject.objects as Record<string, any>);
        objectKeys.forEach(key => {
          const objectArray = this.repeatedPostableObject.objects as Record<string, Record<string, any>>[];
          tempObjects.push({ [key]: objectArray?.[key as keyof typeof objectArray] } as Record<string, Record<string, any>>);
        });
      }
      const postableObjectsArray = postableObject.objects as Record<string, Record<string, any>>;
      tempObjects.push({ [objectId]: postableObjectsArray?.[objectId] });
      this.repeatedPostableObject.objects = tempObjects;
      postableObject.objects = tempObjects;
    }

    if (this.isConsequence) {
      if (!postableObject.objects) {
        return;
      }
      const splittedUrl = this.endpoint.split('/');
      const consequenceCategoryImpactRecordId = +splittedUrl[splittedUrl.length - 2];
      const objects = postableObject.objects as Record<string, any>;
      const tempObject = objects[Object.keys(objects)[0]];
      tempObject['ConsequenceCategoryImpactRecordId'] = consequenceCategoryImpactRecordId;
      this.consequenceService.dataArray.push(JSON.stringify(tempObject));
      this.loading = false;
      return;
    }
    // Run the post request
    this.beinformedService.fetchForm(this.endpoint, true).subscribe(res => {
      this.tokens = res.data.error?.formresponse.tokens;
      const attributes = res?.contributions?.objects?.[this.activeObject]?.attributes;
      const notAllowedProperties: string[] = [];
      if (!!attributes) {
        attributes.forEach(attribute => {
          const attributeName = Object.keys(attribute)?.[0];
          if (!!attributeName && attribute[attributeName]?.readonly) {
            notAllowedProperties.push(attributeName);
          }
        });
      }
      if (!!notAllowedProperties?.length && !!(postableObject.objects as any)?.[this.activeObject]) {
        const postObject = (postableObject.objects as any)?.[this.activeObject];
        notAllowedProperties.forEach(prop => delete postObject[prop]);
      }
      postableObject.tokens = this.tokens;
      this.beinformedService.fetchForm(this.endpoint, true, postableObject).subscribe(result => {
        // Normal intermediate form response
        if (result.data.error?.formresponse?.missing) {
          this.form.reset();
          this.handleFormResponse(result, readonlyKeys);
        }
        // Backend validation errors
        if (result.data.error?.formresponse?.errors?.some((error: any) => !!error.anchor?.elementid))
          this.handleValidationErrors(result.data.error.formresponse.errors);
        else if (result.data.error?.formresponse?.errors && !result.data.error?.formresponse?.errors?.some((error: any) => !!error.anchor?.elementid)) { // if there isn't an elementid to be found, treat errors as Backend API error
          this.errors = result.data.error.formresponse.errors!;
          this.loading = false;
        }
        // Backend API error
        if (result.data.error?.error)
          this.handleError(result.data.error.error);
        // Form success
        if (result.data.formresponse?.success) {
          this.form.reset();
          if (this.isDynamic && !this.blockLayouhtintsFromInitialize.some(lh => this.tableLayouthint?.includes(lh))) {
            setTimeout(() => {
              this.initializeForm();
            });
          }
          this.handleFormSuccess(result.data.formresponse.success, button);
          if (!this.isDynamic)
            this.tableService.updateEditedRow$.next(this.endpoint);
          if (this.router.url.includes('process') && this.router.url.endsWith('designer')) this.nodeChanged.emit(values);
          else this.closed.emit(result);
          this.objectFormEnabled = false;          
          this.formService.updateObjectForm$.next({enabled: this.objectFormEnabled, endpoint: this.endpoint});
        }
      });

    });
  }

  private setPeriodValues(values: Record<string, any>) {
    const foundPeriodInputs = this.inputs?.[0].elements.filter(el => el.layouthint?.includes('period'));
    if (!!foundPeriodInputs?.length) {
      foundPeriodInputs.forEach(periodInput => {
        const periodValueKey = Object.keys(values).find(key => key === (periodInput as any).id);
        if (!!periodValueKey) {
          const periodValue = values[periodValueKey];
          const valueKeys = Object.keys(periodValue);
          valueKeys.forEach(valueKey => {
            const value = periodValue[valueKey];
            this.objects[this.activeObject][valueKey] = valueKey.toLowerCase().endsWith('amount') ? Number.parseInt(value) : value;
          });
        }
      });
    }
  }

  /**
   * Handle form response with missing objects
   * @param result Beinformed form result
   */
  private handleFormResponse(result: IFormResult, readonlyKeys?: string[], _form?: IExtractedForm) {
    this.currentForm = { result, readonlyKeys };
    // Extract the form from result
    const form = !!_form ? _form : this.beinformedService.extractForm(result);
    const activeObj = !!form.activeObject ? form.activeObject : this.activeObject;
    const activeIndex = this.inputs.findIndex(({ objectid }) => objectid === activeObj);
    const newInput = form.objects.find(({ objectid }) => objectid === activeObj);
    if (activeIndex > -1 && !!newInput) this.inputs.splice(activeIndex, 1, newInput);
    this.activeObject = activeObj;
    this.missingObject = activeObj;
    // Check if form has diagram data
    this.getDiagramInfo(form.objects);
    // Update the angular form controls based on (new) information
    this.updateFormControls(form.objects, this.activeObject, readonlyKeys);
    this.updateGuardForms();
    // Set the form title
    this.title = form.title;
    this.titleChanged.emit(this.title);
    // Stop loading state
    this.loading = false;
  }

  /**
   * Handle form validation errors
   * @param errors validation errors
   */
  private handleValidationErrors(errors: IFormError[]) {
    // Add response errors to validation errors
    this.validationErrors = errors;
    // Stop loading state
    this.loading = false;
  }

  /**
   * Handle backend error
   * @param error single backend error
   */
  private handleError(error: any) {
    // Create errors array from one single backend error
    this.errors = [{
      id: error.id,
      message: error.properties.message
    }];
    // Stop loading state
    this.loading = false;
  }

  /**
   * Handle form success
   * @param result Beinformed form result
   */
  private handleFormSuccess(result: IFormSuccess, button?: IFormButton) {
    this.guardService.removeForm(this.endpoint).subscribe();
    if (this.isDynamicDashboard) this.formDialogSaved.emit(result);
    if (this.isCollabPush) {
      this.completed.emit();
      return;
    }
    const isAuditExeStructure = this.router.url.includes('audit-execution') && this.router.url.endsWith('structure') && !this.router.url.includes('wizard');
    if (isAuditExeStructure && this.layouthint?.includes('refresh-panels')) this.tableService.refreshPanels$.next(true);
    if (!this.isDynamic) {
      // Tell our audience that we saved
      this.formService.saveForm(this.id, result, button);
      // If we have a custom button, or other diversions, handle those exceptions
      if (button?.id === 'ADD_NEW') {
        // Show success snackbar and reset the form
        this.snackbarService.open({ text: 'snackbar.successfully', type: 'success' });
        this.resetForm();
      } else if (button?.id === 'CONTINUE' || button?.id === 'OPEN' || this.layouthint?.includes('redirect') || this.isCreateWizard) {
        // Handle form redirect and close
        this.handleRedirect(result);
      } else if (this.layouthint?.includes('reload') || this.layouthint?.includes('refresh')) {
        window.location.reload();
      } else {
        // Otherwise, show success snackbar and close
        this.snackbarService.open({ text: 'snackbar.successfully', type: 'success' });
        if (this.router.url.endsWith('designer') && this.endpoint.includes('archimate')) this.closed.emit(result);
        else this.close();
      }
    } else {
      const refreshWizard = result.data?.WizardStepRefresh?.RefreshWizardStep || !!result.redirect?.includes('audit-step');
      if (!!refreshWizard && !!result.redirect && !result.redirect.includes('collab') && !isAuditExeStructure) window.location.reload();
      if (!refreshWizard) {
        const endpoint = this.endpoint;
        this.endpoint = '';
        this.endpoint = endpoint;
        if (this.layouthint?.includes('reload') || this.layouthint?.includes('refresh')) window.location.reload();
      }
      let label = 'lookupCreate';
      if (!!result?.data?.Attribute) {
        const objectName = Object.keys(result.data.Attribute)?.[0];
        if (!!objectName) label = result.data.Attribute[objectName];
      }
      const isAuditExeStructureStatusUpdate = result.redirect.startsWith('/AuditExeStructureStatusUpdate:');
      if (isAuditExeStructureStatusUpdate) {
        const keyString = result.redirect.split(':')[1];
        const keysToUpdate = keyString.split(',');
        this.formService.refreshAuditExeStructureSteps$.next(keysToUpdate);
      }
      this.dynamicSaved.emit({result: result, refresh: !!refreshWizard, label, formValues: this.form.getRawValue()});
    }
  }

  /**
   * Handle form redirect after success
   * @param result success result
   */
  private handleRedirect({ redirect }: IFormSuccess) {
    if (!!redirect) {
      this.guardService.removeForm(this.endpoint).subscribe();
      if (!redirect.includes('wizard')) {
        this.breadcrumb.navigationClick(redirect, '', '', false);
      }
      if (redirect === this.router.url) window.location.reload();
      else {
        this.footerToolbarService.reset();
        this.formService.isRedirect = true;
        this.breadcrumbTabService.updateWizardTab(redirect);
        void this.router.navigate([redirect]);
      }
    }
    this.close();
  }

  /**
   * Resets the form
   */
  public resetForm(initialize = false, updateEnabled = true) {
    // this.handleFormResponse(this.currentForm.result, this.currentForm.readonlyKeys); // save for future reference...
    if (initialize)
      this.form.reset();

    if (this.objectFormEnabled && updateEnabled)
      setTimeout(() => {
        this.objectFormEnabled = !this.objectFormEnabled;
        this.formService.updateObjectForm$.next({ enabled: this.objectFormEnabled });
      });

    if (this.initAfterReset || initialize)
      this.initializeForm();
  }

  /**
   * Updates the form controls for the inputs
   * @param objects array of objects
   */
  private updateFormControls(objects: IFormObject[], objectId: string, readonlyKeys?: string[]) {
    objects.forEach(({ objectid, inputs }) => {
      inputs.elements.forEach(i => {
        // Get the control for this input
        const control = this.form.get(objectid)?.get(i.id);
        const isRepeatable = this.inputs.find(o => o.objectid === objectId)?.repeatable;
        const formValue = isRepeatable ? null : control?.value;
        if (objectId === objectid) {
          const existingElement = this.inputs.find(o => o.objectid === objectId)?.inputs.elements.find(el => el.id === i.id);
          if (!!existingElement) {
            let label = '';
            if (!!i.dynamicOptions) label = i.dynamicOptions.find(opt => opt.value === i.value)?.label || '';
            existingElement.lookup = i.lookup;
            existingElement.dynamicOptions = i.dynamicOptions;
            existingElement.value = label || i.value || formValue;
            existingElement.hidden = i.hidden;
            existingElement.controlType = i.controlType;
            existingElement.createEndpoint = i.createEndpoint;
            if (!!i['childUrl']) existingElement['childUrl'] = i['childUrl'];
          }
        }
        const updatedValue = this.fillElementsObject?.[i.id];
        // Set the value of the control
        let inputValue = i.value;
        if (readonlyKeys?.includes(i.id) && Array.isArray(inputValue) && inputValue.length === 0) {
          inputValue = undefined;
        }

        let consequenceValue = null;
        if (this.checkConsequenceService && !!this.consequenceService.formData?.length) {
          const formData = this.consequenceService.formData.find(item => item.href === this.endpoint);
          if (!!formData?.data?.[this.activeObject]?.[i.id]) {
            consequenceValue = formData.data[this.activeObject][i.id];
          }
        }

        control?.setValue(updatedValue || consequenceValue || inputValue || formValue);
        // Set the validators for the control
        control?.setValidators(this.formService.getValidators(i));
        // If needed, disable the control
        if (i.disabled) control?.disable();
      });
    });
  }

  /**
   * Build form groups
   * @param inputs inputs
   */
  private buildForm(objects: IFormObject[]) {
    const formGroup = {} as Record<string, FormGroup>;
    objects.forEach(o => {
      const formControls = {} as Record<keyof TInputType, FormControl>;
      o.inputs.elements.forEach(input => formControls[input.id as keyof TInputType] = this.formService.toFormField(input));
      formGroup[o.objectid] = new FormGroup(formControls);
    });
    return new FormGroup(formGroup);
  }

  /**
   * Add a postable object
   * @param objectid form object id
   * @param formValue form value
   */
  private addPostableObject(objectid: any, formValue: any) {
    const existingObjects = Object.keys(this.objects);
    const objectIndex = existingObjects.indexOf(objectid);
    // Flush all objects that come after this object to prevent posting objects that are not allowed
    if (objectIndex > -1) {
      existingObjects.forEach((o: any, i: number) => {
        if (i > objectIndex) delete this.objects[o];
      });
    }
    const postableObject = {} as Record<string, any>;
    // Transform each value if needed
    Object.keys(formValue).forEach((el: string) => {
      // const controlValue = Array.isArray(formValue[el]) && formValue[el][0] === null ? null : formValue[el];
      const controlValue = formValue[el];
      let v = controlValue ? controlValue : typeof controlValue === 'boolean' ? controlValue : typeof controlValue === 'number' ? controlValue : null;
      if (typeof v === 'object' && !Array.isArray(v) && v !== null) v = controlValue.value || controlValue.code || v;
      postableObject[el] = v;
    });
    this.objects[objectid] = postableObject;
  }

  /**
   * Generate a BeInformed postable object to use as request payload
   */
  private getPostableObject(): IPostableObject {
    return {
      tokens: this.tokens,
      objects: this.objects
    };
  }

  /**
   * Returns an array of 'active' form objects
   * based on what's in postable objects, plus missing object
   */
  get getFormObjects() {
    return this.inputs.filter(({ objectid }) => {
      if (objectid === this.missingObject) return true;
      return Object.keys(this.objects).includes(objectid);
    });
  }

  /**
   * Returns the entire active object
   */
  get getActiveObject() {
    const activeObject = this.inputs.find(({ objectid }) => objectid === this.activeObject);
    return activeObject;
  }

  /**
   * Returns the previous object, if there is one
   */
  get previousObject() {
    const objects = this.getFormObjects.map(({ objectid }) => objectid);
    if (!objects.includes(this.missingObject)) objects.push(this.missingObject);
    if (!objects.length || objects.length === 1 && objects[0] === this.activeObject) return false;
    return objects[objects.indexOf(this.activeObject) - 1];
  }

  get activeObjectInputs(): number {
    let inputs = 0;
    const formgroup = this.inputs.find(({ objectid }) => objectid === this.activeObject);
    if (!!formgroup) inputs = formgroup.inputs.elements.filter(el => !this.nonEssentialFields.includes(el.controlType)).length;
    return inputs;
  }

  /**
   * Set the previous object to be the active object
   */
  public back(doFlush = false) {
    if (this.previousObject) {
      const flush = this.inputs.find(({ objectid }) => objectid === this.previousObject)?.layouthint?.includes('clear-by-init') || doFlush;
      if (flush) this.form.get(this.previousObject)?.reset();
      this.activeObject = this.previousObject;
    }
  }

  /**
   * Close this form
   */
  public close = () => {
    this.closed.emit(true);
  };

  private getDiagramInfo(formObjects: IFormObject[]) {
    const diagramForm = formObjects.find(({ objectid }) => objectid === 'ProcessDiagram');
    const diagram = diagramForm?.inputs.elements.filter(element => element.layouthint?.includes('transform-to-diagram'));
    if (!!diagram?.length) {
      const childUrl = diagramForm?.inputs.elements.filter(element => element.id === 'ProcessIdCreateSubProcessAction');
      if (!!childUrl?.length) diagram[0]['childUrl'] = childUrl[0].value;
    }
  }

  /**
   * this.form.get(activeObject).valid doesn't work because disabled controls result in invalid. therefore check every control if it's invalid
   * @param activeObject Active step name
   */
  public isFormValid(activeObject: string): boolean {
    const controls = this.getFormGroup(activeObject)?.['controls'];
    if (!!controls) {
      let controlKeys = this.inputs.map(input => input.objectid);
      const foundActiveObjectInputs = this.inputs.find(i => i.objectid === activeObject);
      if (!!foundActiveObjectInputs) {
        const activeElements = foundActiveObjectInputs.elements;
        controlKeys = activeElements.filter(input => !(input as Record<string, any>).hidden).map(input => input.elementId);
      }
      const invalidControls = controlKeys.filter(key => controls[key]?.status === 'INVALID');
      return !invalidControls.length;
    } else return true;
  }

  public backAndReset() {
    this.back(true);
  }

  public getFormGroup(groupName: string) {
    return this.form?.get(groupName) as FormGroup;
  }

  public updateObjectForm(event: MatSlideToggleChange, endpoint: string) {
    clearTimeout(this.updateObjectToggleTimeout);
    this.updateObjectToggleTimeout = setTimeout(() => {
      if (event.checked) {
        const val = JSON.stringify(this.getFormGroup(this.activeObject).getRawValue());
        this.guardService.addForm(this.endpoint, val);
      } else this.guardService.removeForm(this.endpoint).subscribe({ complete: () => this.resetForm(false, false) });

      this.objectFormEnabled = event.checked;
      this.formService.updateObjectForm$.next({ enabled: this.objectFormEnabled, endpoint });
    }, 500);
  }

  public isFormGroupDisabled() {
    return this.getFormGroup(this.activeObject)?.disabled;
  }

  get hasChanges(): boolean {
    return this.guardService.hasChanges();
  }

  public updateGuardForms() {
    if (this._expanded || this.isWizardObject) {
      if (!!this.activeObject) {
        const val = JSON.stringify(this.getFormGroup(this.activeObject).getRawValue());
        this.guardService.addForm(this.endpoint, val);
      }
    }

  }

  public updateOptionalFields(event: MatSlideToggleChange) {
    localStorage.setItem('hide-optional-fields', String(event.checked));
    this.footerToolbarService.hideOptionalFields$.next(event.checked);
  }

  get isOptionalFieldsChecked(): boolean {
    return this.footerToolbarService.getChecked();
  }

  public setOnlyMandatoryInputs(event: boolean) {
    setTimeout(() => this.onlyMandatoryInputs = event);
  }

  public setOnlyOptionalInputs(event: boolean) {
    setTimeout(() => this.onlyOptionalInputs = event);
  }
}
