import { createClass as s, classCallCheck as a, defineProperty as e } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { addClass as n, createElement as t } from "./browser-util.js";
import { ELEMENT_DIV as r } from "./constants.js";
import { ABSOLUTE as i, NONE as l } from "../core/constants.js";
var c = function () {
  function c(s, n) {
    a(this, c);
    this.instance = s;
    this.overlay = n;
    e(this, "htmlElementOverlay", void 0);
    this.htmlElementOverlay = n;
  }
  s(c, null, [{
    key: "getElement",
    value: function s(a, e, c) {
      if (a.canvas == null) {
        if (c && e) {
          a.canvas = c(e);
          var o = a.instance.overlayClass + " " + (a.cssClass ? a.cssClass : "");
          n(a.canvas, o);
        } else {
          a.canvas = t(r, {}, a.instance.overlayClass + " " + (a.cssClass ? a.cssClass : ""));
        }
        a.canvas.setAttribute("jtk-overlay-id", a.id);
        for (var v in a.attributes) {
          a.canvas.setAttribute(v, a.attributes[v]);
        }
        a.canvas.style.position = i;
        a.instance._appendElement(a.canvas, a.instance.getContainer());
        a.instance.getId(a.canvas);
        var m = "translate(-50%, -50%)";
        a.canvas.style.webkitTransform = m;
        a.canvas.style.mozTransform = m;
        a.canvas.style.msTransform = m;
        a.canvas.style.oTransform = m;
        a.canvas.style.transform = m;
        if (!a.isVisible()) {
          a.canvas.style.display = l;
        }
        a.canvas.jtk = {
          overlay: a
        };
      }
      return a.canvas;
    }
  }, {
    key: "destroy",
    value: function s(a) {
      a.canvas && a.canvas.parentNode && a.canvas.parentNode.removeChild(a.canvas);
      delete a.canvas;
      delete a.cachedDimensions;
    }
  }, {
    key: "_getDimensions",
    value: function s(a, e) {
      if (a.cachedDimensions == null || e) {
        a.cachedDimensions = {
          w: 1,
          h: 1
        };
      }
      return a.cachedDimensions;
    }
  }]);
  return c;
}();
export { c as HTMLElementOverlay };