import { inherits as t, createSuper as i, classCallCheck as o, defineProperty as e, assertThisInitialized as n, createClass as a } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { Overlay as l } from "./overlay.js";
import { OverlayFactory as s } from "../factory/overlay-factory.js";
import { pointOnLine as r, perpendicularLineTo as h } from "../../util/geom.js";
import { _connectorPointAlongPathFrom as c, _connectorPointOnPath as d } from "../connector/connectors.js";
var y = 20;
var f = 20;
var p = function (l) {
  t(p, l);
  var s = i(p);
  function p(t, i, a) {
    var l;
    o(this, p);
    l = s.call(this, t, i, a);
    l.instance = t;
    l.component = i;
    e(n(l), "width", void 0);
    e(n(l), "length", void 0);
    e(n(l), "foldback", void 0);
    e(n(l), "direction", void 0);
    e(n(l), "location", .5);
    e(n(l), "paintStyle", void 0);
    e(n(l), "type", p.type);
    e(n(l), "cachedDimensions", void 0);
    a = a || {};
    l.width = a.width || y;
    l.length = a.length || f;
    l.direction = (a.direction || 1) < 0 ? -1 : 1;
    l.foldback = a.foldback || .623;
    l.paintStyle = a.paintStyle || {
      strokeWidth: 1
    };
    l.location = a.location == null ? l.location : Array.isArray(a.location) ? a.location[0] : a.location;
    return l;
  }
  a(p, [{
    key: "draw",
    value: function t(i, o, e) {
      var n, a, l, s, y;
      if (this.location > 1 || this.location < 0) {
        var f = this.location < 0 ? 1 : 0;
        n = c(i, f, this.location, false);
        a = c(i, f, this.location - this.direction * this.length / 2, false);
        l = r(n, a, this.length);
      } else if (this.location === 1) {
        n = d(i, this.location);
        a = c(i, this.location, -this.length);
        l = r(n, a, this.length);
        if (this.direction === -1) {
          var p = l;
          l = n;
          n = p;
        }
      } else if (this.location === 0) {
        l = d(i, this.location);
        a = c(i, this.location, this.length);
        n = r(l, a, this.length);
        if (this.direction === -1) {
          var m = l;
          l = n;
          n = m;
        }
      } else {
        n = c(i, this.location, this.direction * this.length / 2);
        a = d(i, this.location);
        l = r(n, a, this.length);
      }
      s = h(n, l, this.width);
      y = r(n, l, this.foldback * this.length);
      var v = {
          hxy: n,
          tail: s,
          cxy: y
        },
        u = this.paintStyle.stroke || o.stroke,
        g = this.paintStyle.fill || o.stroke,
        k = this.paintStyle.strokeWidth || o.strokeWidth;
      return {
        component: i,
        d: v,
        "stroke-width": k,
        stroke: u,
        fill: g,
        xmin: Math.min(n.x, s[0].x, s[1].x),
        xmax: Math.max(n.x, s[0].x, s[1].x),
        ymin: Math.min(n.y, s[0].y, s[1].y),
        ymax: Math.max(n.y, s[0].y, s[1].y)
      };
    }
  }, {
    key: "updateFrom",
    value: function t(i) {}
  }]);
  return p;
}(l);
e(p, "type", "Arrow");
function m(t) {
  return t.type === p.type;
}
s.register(p.type, p);
export { p as ArrowOverlay, m as isArrowOverlay };