import { inherits as e, createSuper as t, classCallCheck as i, defineProperty as n, assertThisInitialized as r, createClass as a } from "../_virtual/_rollupPluginBabelHelpers.js";
import { isNode as s, isGroup as o, isPort as u } from "../core/model/graph.js";
import { DialogConstants as l } from "../dialogs-core/constants.js";
import { EventManager as c } from "../ui-core/browser-ui-renderer/event-manager.js";
import { EVENT_SELECT as d, EVENT_DESELECT as f, EVENT_NODE_REMOVED as p, EVENT_PORT_REMOVED as m, EVENT_EDGE_REMOVED as v, EVENT_SELECTION_CLEARED as h, EVENT_UNDOREDO_UPDATE as _, EVENT_DESTROY as g } from "../core/constants.js";
import { log as C } from "../ui-core/util/util.js";
import { apply as E } from "../dialogs-core/binder.js";
import { att as y } from "../ui-core/core/constants.js";
import { OptimisticEventGenerator as k } from "../ui-core/util/event-generator.js";
import { EVENT_GROUP_REMOVED as b } from "../ui-core/core/event-constants.js";
var A = "input";
var T = "change";
var U = "blur";
var j = "keypress";
var V = "jtk-inspector-active";
var L = "jtk-inspector-inactive";
var M = "jtk-inspector";
var D = "jtk-node-inspector";
var S = "jtk-edge-inspector";
var O = "jtk-empty-inspector";
var X = function (k) {
  e(D, k);
  var M = t(D);
  function D(e) {
    var t;
    i(this, D);
    t = M.call(this);
    n(r(t), "container", void 0);
    n(r(t), "surface", void 0);
    n(r(t), "toolkit", void 0);
    n(r(t), "eventManager", void 0);
    n(r(t), "_multipleSelections", void 0);
    n(r(t), "_eventsSuspended", false);
    n(r(t), "_pendingUpdates", {});
    n(r(t), "autoCommit", true);
    n(r(t), "renderEmptyContainer", void 0);
    n(r(t), "refresh", void 0);
    n(r(t), "afterUpdate", void 0);
    n(r(t), "_resetting", false);
    n(r(t), "_fieldMap", new Map());
    n(r(t), "current", []);
    n(r(t), "_currentCommonData", {});
    n(r(t), "_filter", void 0);
    n(r(t), "_recomputeAndApply", function () {
      t._recomputeCommonData();
      E(t._currentCommonData, t.container);
      var e = t.container.querySelectorAll(y(l.Attributes.FOCUS))[0];
      if (e != null) {
        e.focus();
      }
      t.fire(T, t._currentCommonData);
    });
    t.container = e.container;
    t.surface = e.surface;
    t.toolkit = t.surface.toolkitInstance;
    t.eventManager = new c();
    t._multipleSelections = e.multipleSelections !== false;
    t.afterUpdate = e.afterUpdate;
    t.renderEmptyContainer = e.renderEmptyContainer;
    t.refresh = e.refresh;
    t.autoCommit = e.autoCommit !== false;
    t._filter = e.filter || function (e) {
      return true;
    };
    t.container.classList.add(L);
    t._bindEvents();
    return t;
  }
  a(D, [{
    key: "_bindEvents",
    value: function e() {
      var t = this;
      this.toolkit.bind(d, function (e) {
        if (!t._eventsSuspended) {
          t._edit(e.obj);
        }
      });
      this.toolkit.bind(f, function (e) {
        t._remove(e.obj);
      });
      this.toolkit.bind(p, function (e) {
        t._remove(e.node);
      });
      this.toolkit.bind(m, function (e) {
        t._remove(e.port);
      });
      this.toolkit.bind(b, function (e) {
        t._remove(e.group);
      });
      this.toolkit.bind(v, function (e) {
        t._remove(e.edge);
      });
      this.toolkit.bind(h, function () {
        if (t.autoCommit) {
          var e = Array.from(t._fieldMap.entries()).filter(function (e) {
            return e[1].edited && e[1].editedValue != null;
          });
          e.forEach(function (e) {
            t.setValue(e[0], e[1].editedValue);
            delete e[1].editedValue;
          });
        }
        t.reset(false);
      });
      this.toolkit.bind(_, function (e) {
        if (!t._eventsSuspended) {
          t._resetting = true;
          t._edit();
          t._resetting = false;
        }
      });
      var i = function e(i) {
        if (!t._resetting) {
          setTimeout(function () {
            var e = i.target,
              n = e.getAttribute(l.Attributes.JTK);
            if (n != null && (e.type.toUpperCase() === l.Elements.TEXT || e.tagName.toUpperCase() === l.Elements.TEXTAREA)) {
              if (e.checkValidity == null || e.checkValidity()) {
                var r = t._fieldMap.get(n);
                if (r != null) {
                  if (!r.common || r.edited) {
                    var a = e.value;
                    t.setValue(n, a);
                    r.edited = false;
                    delete r.editedValue;
                  }
                }
              }
            }
          }, 0);
        }
      };
      this.container.addEventListener(U, i, true);
      var n = function e(i) {
        if (!t._resetting) {
          var n = i.target,
            r = n.type.toUpperCase(),
            a = n.getAttribute(l.Attributes.JTK);
          if (a != null) {
            if (r === l.Elements.CHECKBOX) {
              t.setValue(a, n.checked === true);
            } else if (r === l.Elements.RADIO) {
              if (n.checked) {
                t.setValue(a, n.value);
              }
            } else if (r === l.Elements.COLOR || r === l.Elements.NUMBER) {
              t.setValue(a, n.value);
            } else if (r === l.Elements.TEXT || n.tagName.toUpperCase() === l.Elements.TEXTAREA) {
              var s = t._fieldMap.get(a);
              s.edited = true;
              s.editedValue = n.value;
            } else if (n.tagName.toUpperCase() === l.Elements.SELECT) {
              t.setValue(a, n.value);
            }
          }
        }
      };
      this.container.addEventListener(T, n, true);
      var r = function e(i) {
        if (!t._resetting) {
          var n = i.target;
          n.type.toUpperCase();
          var r = n.getAttribute(l.Attributes.JTK);
          if (r != null) {
            if (n.type.toUpperCase() === l.Elements.TEXT || n.tagName.toUpperCase() === l.Elements.TEXTAREA) {
              var a = t._fieldMap.get(r);
              a.edited = true;
              a.editedValue = n.value;
            }
          }
        }
      };
      this.container.addEventListener(A, r, true);
      var a = function e(i) {
        if (!t._resetting) {
          var n = i.target;
          n.type.toUpperCase();
          var r = n.getAttribute(l.Attributes.JTK);
          if (r != null && (i.keyCode === 13 || i.key === "13")) {
            if (n.type.toUpperCase() === l.Elements.TEXT || n.tagName.toUpperCase() === l.Elements.TEXTAREA) {
              var a = t._fieldMap.get(r);
              a.edited = true;
              t.setValue(r, n.value);
            }
          }
        }
      };
      this.container.addEventListener(j, a, true);
      this.surface.bind(g, function () {
        t.container.removeEventListener(j, a);
        t.container.removeEventListener(T, n);
        t.container.removeEventListener(U, i);
        t.container.removeEventListener(A, r);
        t._destroy();
      });
    }
  }, {
    key: "_recomputeCommonData",
    value: function e() {
      this._currentCommonData = {};
      var t = {};
      for (var i = 0; i < this.current.length; i++) {
        for (var n in this.current[i].data) {
          if (!t[n]) {
            var r = this.current[i].data[n];
            if (this._currentCommonData[n] == null) {
              this._currentCommonData[n] = r;
            } else {
              if (this._currentCommonData[n] !== r) {
                t[n] = true;
                this._currentCommonData[n] = "";
              }
            }
          }
        }
      }
      for (var a in t) {
        var s = this._fieldMap.get(a);
        if (s != null) {
          s.common = true;
          s.commonValue = this._currentCommonData[a];
        }
      }
    }
  }, {
    key: "_persistChanges",
    value: function e(t) {
      var i = this;
      this._eventsSuspended = true;
      try {
        this.toolkit.openTransaction();
        try {
          this.current.forEach(function (e, n) {
            i.toolkit.update(e, t);
          });
        } catch (e) {
          C("WARN: error occurred updating item from inspector");
        }
        this.toolkit.commitTransaction();
        this.afterUpdate && this.afterUpdate();
      } finally {
        this._eventsSuspended = false;
      }
    }
  }, {
    key: "_updateCurrent",
    value: function e(t) {
      if (this.autoCommit) {
        this._persistChanges(t);
      } else {
        Object.assign(this._pendingUpdates, t);
      }
    }
  }, {
    key: "reset",
    value: function e(t) {
      try {
        this._resetting = true;
        this._pendingUpdates = {};
        this.container.classList.remove(V);
        this.container.classList.add(L);
        if (!t) {
          this.renderEmptyContainer();
          this.current.length = 0;
        }
      } finally {
        this._resetting = false;
      }
    }
  }, {
    key: "_destroy",
    value: function e() {
      this.current.length = 0;
      this._pendingUpdates = {};
      this._fieldMap.clear();
      this.container.innerHTML = "";
    }
  }, {
    key: "_remove",
    value: function e(t) {
      var i = this.current.indexOf(t);
      if (i !== -1) {
        this.current.splice(i, 1);
      } else {
        if (s(t) || o(t)) {
          i = this.current.findIndex(function (e) {
            return u(e) && e.getParent().id === t.id;
          });
          if (i !== -1) {
            this.current.splice(i, 1);
          }
        }
      }
      if (this.current.length === 0) {
        this.reset(false);
      } else {
        this._recomputeCommonData();
      }
    }
  }, {
    key: "_edit",
    value: function e(t) {
      var i = this;
      if (t != null && !this._filter(t)) {
        return;
      }
      var n = !this._multipleSelections || this.current.length === 0;
      if (n) {
        this.reset(true);
      }
      if (t != null) {
        this.container.classList.remove(L);
        this.container.classList.add(V);
        this._pendingUpdates = {};
        if (t != null) {
          this.current.push(t);
        }
        var r = function e() {
          i._fieldMap.clear();
          var t = i.container.querySelectorAll(y(l.Attributes.JTK));
          t.forEach(function (e) {
            var t = e.getAttribute(l.Attributes.JTK);
            i._fieldMap.set(t, {
              field: e,
              edited: false,
              common: false,
              commonValue: null,
              attribute: t
            });
          });
          i._recomputeAndApply();
        };
        if (n) {
          if (t != null) {
            this.refresh(t, r);
          } else {
            r();
          }
        } else {
          this._recomputeAndApply();
        }
      } else {
        this.container.classList.add(L);
      }
    }
  }, {
    key: "setValue",
    value: function e(t, i) {
      this._updateCurrent(n({}, t, i));
    }
  }, {
    key: "getValue",
    value: function e(t) {
      return this._currentCommonData[t];
    }
  }, {
    key: "onChange",
    value: function e(t) {
      this.bind(T, t);
    }
  }, {
    key: "persist",
    value: function e() {
      var t = Object.keys(this._pendingUpdates).length;
      if (t > 0) {
        this._persistChanges(this._pendingUpdates);
        this._pendingUpdates = {};
      }
      return t;
    }
  }, {
    key: "hasPendingChanges",
    value: function e() {
      return Object.keys(this._pendingUpdates).length > 0;
    }
  }]);
  return D;
}(k);
export { S as CLASS_EDGE_INSPECTOR, O as CLASS_EMPTY_INSPECTOR, M as CLASS_INSPECTOR, V as CLASS_INSPECTOR_ACTIVE, L as CLASS_INSPECTOR_INACTIVE, D as CLASS_NODE_INSPECTOR, X as Inspector };