import { PLACEHOLDER_VALUE as r, PLACEHOLDER_KEY as e } from "./defs.js";
var t = ["*", "-", "+", "/", "%"];
var n = ["==", ">", "<", ">=", "<=", "===", "!=", "!==="];
var u = /([(])|([)])|(===)|(==)|(!==)|(!=)|(!===)|(<=)|(>=)|(<)|(>)|(\+)|(-)|(\*)|(\/)|(\s+)|([0-9\.]+)|(\$data\[[0-9]+\])|(\$value\[[0-9]+\])|(\$key)|(\$value)|('[^']+')|([#a-zA-Z\$]+[0-9a-zA-Z_\$\.]*)/g;
var a = "num";
var l = "group";
var f = "var";
var i = "op";
var c = "comp";
var s = "exp";
var v = "string";
var o = "array";
var p = "key";
var h = "value";
function m(r) {
  return r[r.length - 1];
}
function g(r) {
  return r.t === l;
}
function $(r) {
  return r.t === f;
}
function d(r) {
  return r.t === s;
}
function x(r) {
  return r.t === a;
}
function y(r) {
  return r.t === v;
}
function z(r) {
  return r.t === o;
}
function A(r) {
  return r.t === p;
}
function O(r) {
  return r.t === h;
}
function Z(r) {
  var e = r.match(u);
  var g,
    $ = [{
      t: "root",
      c: [],
      e: r,
      v: null
    }];
  for (var d = 0; d < e.length; d++) {
    g = e[d];
    var x = m($);
    if (g === "(") {
      var y = {
        t: l,
        c: [],
        v: null
      };
      x.c.push(y);
      $.push(y);
    } else if (g === ")") {
      $.pop();
    } else if (g.match(/\$value\[[0-9]+\]/)) {
      var z = parseInt(g.substring(7, g.length - 1), 10);
      x.c.push({
        t: o,
        v: g,
        index: z
      });
    } else if (g.match(/\$key/)) {
      x.c.push({
        t: p,
        v: null
      });
    } else if (g.match(/\$value/)) {
      x.c.push({
        t: h,
        v: null
      });
    } else if (g.match(/^#/)) {
      x.c.push({
        t: s,
        v: g.substring(1)
      });
    } else if (g.match(/'[a-zA-Z_\$\.]+'/)) {
      x.c.push({
        t: v,
        v: g.replace(/'/g, "")
      });
    } else if (g.match(/[a-zA-Z_\$\.]+/)) {
      x.c.push({
        t: f,
        v: g
      });
    } else if (g.match(/[0-9\.]/)) {
      var A = g.indexOf(".") !== -1 ? parseFloat(g) : parseInt(g, 10);
      x.c.push({
        t: a,
        v: A
      });
    } else if (t.indexOf(g) !== -1) {
      x.c.push({
        t: i,
        v: g
      });
    } else if (n.indexOf(g) !== -1) {
      x.c.push({
        t: c,
        v: g
      });
    }
  }
  return $[0];
}
function k(r) {
  var e = null,
    t = {
      t: "root",
      c: [],
      v: ""
    },
    u = {
      t: "root",
      c: [],
      v: ""
    },
    a = t.c;
  for (var l = 0; l < r.c.length; l++) {
    if (n.indexOf(r.c[l].v) !== -1) {
      if (e != null) {
        throw new Error("Cannot parse expression; more than one comparator.");
      }
      e = r.c[l].v;
      a = u.c;
    } else {
      a.push(r.c[l]);
    }
  }
  var f = function r(e) {
    if (g(e)) {
      e.c = i(e);
    }
    return e;
  };
  var i = function r(e) {
    var t = e.c;
    if (t.length === 0) {
      return [];
    }
    var n = [],
      u = f(t[0]);
    if (t.length === 1) {
      return [u];
    } else if (t.length === 3) {
      return [u, t[1], f(t[2])];
    } else {
      var a = u,
        l = null,
        i;
      for (var c = 1; c < t.length; c += 2) {
        i = t[c];
        var s = i.v === "+" || i.v === "-" ? 1 : 2;
        if (s === 2) {
          if (l == null) {
            l = {
              t: "group",
              c: [a, i],
              v: null
            };
            n.push(l);
          } else {
            var v;
            (v = l.c).push.apply(v, [a, i]);
          }
        } else {
          if (l != null) {
            l.c.push(a);
          } else {
            n.push(a);
          }
          l = null;
          n.push(i);
        }
        a = f(t[c + 1]);
      }
      if (l != null) {
        l.c.push(a);
      } else {
        n.push(a);
      }
      return n;
    }
  };
  t.c = i(t);
  u.c = i(u);
  return {
    left: t,
    right: u,
    comparator: e
  };
}
function _(r) {
  var e = Z(r);
  var t = k(e);
  return t;
}
function b(r, e, t) {
  var n = w(r.left.c, e, t);
  if (r.comparator == null) {
    return n;
  } else {
    var u = w(r.right.c, e, t);
    if (r.comparator === "==") {
      return n == u;
    } else if (r.comparator === "===") {
      return n === u;
    } else if (r.comparator === ">") {
      return n > u;
    } else if (r.comparator === ">=") {
      return n >= u;
    } else if (r.comparator === "<") {
      return n < u;
    } else if (r.comparator === "<=") {
      return n <= u;
    } else if (r.comparator === "!=") {
      return n != u;
    } else if (r.comparator === "!==") {
      return n !== u;
    }
  }
}
function w(t, n, u) {
  var a = t.map(function (t) {
    if (g(t)) {
      return w(t.c, n, u);
    } else if ($(t)) {
      return n(t.v);
    } else if (d(t)) {
      return u(t.v);
    } else if (z(t)) {
      var a = n(r);
      return a[t.index];
    } else if (A(t)) {
      return n(e);
    } else if (O(t)) {
      return n(r);
    } else if (x(t) || y(t)) {
      return t.v;
    } else {
      return t;
    }
  });
  var l = a[0];
  var f;
  for (var i = 1; i < a.length; i += 2) {
    f = a[i].v;
    var c = a[i + 1];
    if (f === "+") {
      l += c;
    } else if (f === "-") {
      l -= c;
    } else if (f === "*") {
      l *= c;
    } else if (f === "/") {
      l /= c;
    }
  }
  return typeof l === "undefined" ? "" : l;
}
export { b as evaluateExpression, w as evaluateSingleExpression, Z as parseExpression, _ as processExpression };