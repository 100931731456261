import { UNITS } from '@core/constants/frequency-constants';
import { Duration, DurationLikeObject } from 'luxon';
import { fromBase64 } from './base64.helper';
import type { Point } from '@angular/cdk/drag-drop';

/**
 * Generates a random id
 */
export const generateRandomId = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

/**
 * Deep clone anything
 */
export const clone = (subject: any) => JSON.parse(JSON.stringify(subject));

/**
 * Create a unique nonce
 */
export const generateNonce = () => {
  const RADIX = 36;
  const INDEX_START = 2;
  const INDEX_END = 15;

  return (
    Math.random()
      .toString(RADIX)
      .substring(INDEX_START, INDEX_END) +
    Math.random()
      .toString(RADIX)
      .substring(INDEX_START, INDEX_END)
  );
};

/**
 * Transform a number to a time
 * @param t number
 */
export const transformTime = (num: number) => {
  let timeLeft = num;
  let hours = '';
  let minutes = '00';
  let seconds = '00';
  if (timeLeft >= 3600) {
    const h = Math.floor(timeLeft / 3600);
    hours = h >= 10 ? h.toString() : `0${h}`;
    timeLeft %= 3600;
  }
  if (timeLeft >= 60) {
    const m = Math.floor(timeLeft / 60);
    minutes = m >= 10 ? m.toString() : `0${m}`;
    timeLeft %= 60;
  }
  if (timeLeft > 0) seconds = timeLeft >= 10 ? timeLeft.toString() : `0${timeLeft}`;
  return !hours ? `${minutes}:${seconds} m` : `${hours}:${minutes}:${seconds} h`;
};

/**
 * Calculate percentages from the given point, based on parent configuration
 * @param point Point
 * @param config \{parentWidth: number, parentHeight: number, offset?: number}
 */
export const getPercentage = (point: Point, config: { parentWidth: number; parentHeight: number; offset?: number }): Point => {
  // const width = config.parentWidth / 100;
  // const height = config.parentHeight / 100;
  // const offset = config.offset ?? 0;
  const percentageX = Math.round((point.x * 100) / config.parentWidth);//Math.round((point.x + offset) / width);
  const percentageY = Math.round((point.y * 100) / config.parentHeight);//Math.abs(Math.round((point.y + offset) / height) - 100);
  return { x: percentageX, y: percentageY };
};

/**
 * Calculate a point from the given percentages, bases on parent configuration
 * @param vote IVote
 * @param config \{parentWidth: number, parentHeight: number, offset?: number}
 */
export const getPoint = (vote: any, config: { parentWidth: number; parentHeight: number; offset?: number }): Point => {
  // const width = config.parentWidth / 100;
  // const height = config.parentHeight / 100;
  // const offset = config.offset ?? 0;
  // const pointX = Math.round(vote.likelihood * width - offset);
  // const pointY = Math.round(Math.abs(vote.impact - 100) * height - offset);
  const pointX = Math.round((config.parentWidth * vote.likelihood) / 100) - 12;
  const pointY = config.parentHeight - Math.round((config.parentHeight * vote.impact) / 100) - 29;
  // eslint-disable-next-line no-console
  console.log(pointX, pointY);
  return { x: pointX, y: pointY + 22 };
};

export const is = (a: any, b: any) => Array.isArray(a) ? a.includes(b) : a === b;
export const isNot = (a: any, b: any) => Array.isArray(a) ? a.some(value => value !== b) : a !== b;
export const has = (a: any, b: any) => a.includes(b);
export const hasNot = (a: any, b: any) => !a.includes(b);
export const equals = (a: any, b: any) => a === b;
export const and = (a: any, b: any) => a && b;

export const decodeRich = (val?: string) => !!val ? fromBase64(val.replace(/<\/*p>/g, '')) : val;

export const durationCompact = (ms: number, precision?: keyof DurationLikeObject, locale?: string) => {
  const units = UNITS.slice(0, UNITS.indexOf(precision!) + 1);
  const duration = Duration.fromMillis(ms, { locale }).shiftTo(...units).toHuman({ maximumFractionDigits: 0 });
  const splittedDuration = duration.split(', ');
  const filteredDuration = splittedDuration.filter((d, index) => !d.startsWith('0') && index !== splittedDuration.length - 1);
  determineLastValue(splittedDuration, filteredDuration);
  return filteredDuration.length === 0 ? splittedDuration[splittedDuration.length - 1] : filteredDuration.join(', ');
};

const determineLastValue = (splittedDuration: string[], filteredDuration: string[]) => {
  if (splittedDuration[splittedDuration.length - 1].startsWith('0')) {
    const lastValue = splittedDuration[splittedDuration.length - 1];
    const matches = lastValue.match(/([0-9]*)/g)?.filter(item => !!item && item !== '');
    const lastMatch = (matches?.length || 0) > 1 ? matches?.[matches.length - 1] : matches?.[0];
    const index = lastValue.lastIndexOf(lastMatch || '');
    if (index === -1 && !lastValue.startsWith('0')) filteredDuration.push(lastValue);
    else if (index !== -1) {
      const startValue = lastValue.slice(0, index).trim();
      const lastSpaceIndex = startValue.lastIndexOf(' ');
      const start = startValue.slice(0, lastSpaceIndex);
      const end = lastValue.slice(index, lastValue.length);
      if (!start.startsWith('0')) filteredDuration.push(start);
      if (!end.startsWith('0')) filteredDuration.push(end);
    }
  } else filteredDuration.push(splittedDuration[splittedDuration.length - 1]);
};

export const constructPageLength = (maxpages?: number, currentPage?: number, total?: number, pageLength = 1) => {
  if (!maxpages || !currentPage || !total) return '0';
  if (maxpages <= 1) return pageLength.toString();
  else if (currentPage === maxpages) return `${total - (pageLength - 1)}-${total}`;
  else {
    const max = currentPage * pageLength;
    const min = max - (pageLength - 1);
    return `${min}-${max}`;
  }
};

export const getIcon = (icon: string) => {
  if (icon.includes('update') && !icon.includes('update-group-members')) return 'edit';
  else if (icon.includes('process-step')) return 'process-task';
  else if (icon.includes('condition')) return 'process-condition';
  else if (icon.includes('end-event')) return 'process-endEvent';
  else if (icon.includes('add') || icon.includes('create')) {
    if (icon.includes('document')) return 'document_add';
    return 'add';
  } else if (icon.includes('enable')) return 'radio_button_unchecked';
  else if (icon.includes('disable')) return 'radio_button_checked';
  else if (icon.includes('delete') || icon.includes('remove')) return 'delete';
  else if (icon.includes('change-steps')) return 'change-steps';
  else if (icon.includes('change')) return 'change';
  else if (icon.includes('not-key')) return 'key-off';
  else if (icon.includes('key')) return 'key';
  else if (icon.includes('execute')) return 'play';
  else if (icon.includes('unblock')) return 'unlock';
  else if (icon.includes('copy')) return 'copy';
  else if (icon.includes('score')) return 'score';
  else if (icon.includes('download-document')) return 'download';
  else if (icon.includes('open')) return 'open';
  else if (icon.includes('invitation')) return 'send-mail';
  else if (icon.includes('spinner')) return 'spinner';
  else if (icon.includes('abort')) return 'dangerous';
  else if (icon.includes('bulk-change-assignments')) return 'bulk-change';
  else if (icon.includes('mark-notification-as-read')) return 'mark-as-read';
  else if (icon.includes('mark-notification-as-not-read')) return 'mark-as-unread';
  else if (icon.includes('update-group-members')) return 'update-group-members';
  else if (icon.includes('resume-audit-execution')) return 'audit_play';
  else if (icon.includes('suspend-audit-execution')) return 'audit_pause';
  else if (icon.startsWith('push-')) return 'push';
  else if (icon.startsWith('pushable')) return 'pushable';
  else if (icon.startsWith('notpushable')) return 'not-pushable';
  else if (icon.startsWith('accept')) return 'accept';
  else if (icon.includes('goto-object')) return 'go-to';
  else if (icon.startsWith('gotoobject-')) return 'go-to';
  else if (icon.startsWith('norm-')) return 'norm';
  else if (icon.startsWith('appetite-')) return 'appetite';
  else if (icon.startsWith('not-appetite-')) return 'not-appetite';
  else if (icon.startsWith('comparison-')) return 'comparison';
  else if (icon.startsWith('tolerance-')) return 'tolerance';
  else if (icon.startsWith('not-tolerance-')) return 'not-tolerance';
  else if (icon.startsWith('detach-')) return 'unlink';
  else if (icon.startsWith('unlink-')) return 'unlink';
  else if (icon.startsWith('archive')) return 'archive';
  else if (icon.includes('unarchive')) return 'unarchive';
  else if (icon.startsWith('join-live-session-facilitator')) return 'join-live-session-facilitator';
  else if (icon.startsWith('join-live-session')) return 'join-live-session';
  else if (icon.startsWith('join-questionnaire')) return 'join-questionnaire';  
  else if (icon.includes('show-details')) return 'show-details';
  else return 'help-outline';
};

export const stripObject = <T extends Record<string, any>>(obj: T, blacklist: (keyof (T & { __typename: string; _id: string }))[], depth = 0): T => {
  if (!obj || depth < 0 || typeof obj !== 'object' || Array.isArray(obj)) return obj;
  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      if (blacklist.includes(key)) return acc;
      let returnValue: typeof value = value;
      if (depth > 0 && !!value && typeof value === 'object') returnValue = Array.isArray(value) ? value.map(v => stripObject(v, blacklist, depth - 1)) : stripObject(value, blacklist, depth - 1);
      return { ...acc, [key]: returnValue };
    },
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
    {} as T
  );
};

export const compareObjects = (obj1: Record<string, unknown>, obj2: Record<string, unknown>) => JSON.stringify(obj1) === JSON.stringify(obj2);

export const toNumber = (value: any) => {
  const returnVal = [];
  if (Array.isArray(value)) {
    const nonNumbers = value.some(val => isNaN(val));
    if (!nonNumbers) value.forEach(val => returnVal.push(parseInt(val)));
    else return value;
  } else if (!isNaN(value) && value !== null) returnVal.push(parseInt(value));
  else return value;
  return returnVal;
};

export const generateGUID = (): string => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
  const r = Math.random() * 16 | 0,
    v = c === 'x' ? r : (r & 0x3 | 0x8);
  return v.toString(16);
});

export const getExtension = (filename: string) => filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);

export const roundTo = (num: number, places: number): number => {
  const factor = 10 ** places;
  return Math.round(num * factor) / factor;
};

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};
