/**
 * Check if a value is not partly the same as another value
 */
export const testOriginal = (value: string, checkValue: string, consecutiveChars: number) => {
  if (value === '') return false;
  let result = true;
  for (let i = 0; i <= value.length - consecutiveChars; i++) {
    if (checkValue.includes(value.slice(i, i + consecutiveChars))) result = false;
  }
  return result;
};

/**
   * Extract initials from any string
   * Returns the first 2 characters of single word strings, or
   * the first character of 1st and last word in the string, uppercased.
   * @param username user name string
   */
export const getInitials = (username: string): string => {
  if (!username) return '';
  const nameParts = username.split(' ');
  const initials =  nameParts.length > 1
    ? nameParts[0].slice(0, 1) + nameParts[nameParts.length - 1].slice(0, 1)
    : nameParts[0].slice(0, 2);
  return initials.toUpperCase();
};

/**
 * Truncate a given string to the given length
 * @param str The string to truncate
 * @param end The place to truncate the string
 */
export const truncate = (str: string, end: number) => str?.length > end ? `${str.slice(0, end)}...` : str;

export const isAbbreviation = (value: string) => /^[A-Z]+s*$/u.test(value);

export const decamelize = (value: string, upperFirst = false) => {
  const replaced = value.replaceAll(/([A-Z])/gu, (match, _, offset) => !offset ? match : ` ${match.toLowerCase()}`);
  return upperFirst ? replaced.charAt(0).toUpperCase() + replaced.slice(1) : replaced;
};

export const pluralize = (value: string) => {
  if (isAbbreviation(value)) return value + '\'s';
  if (value.endsWith('s')) return value + 'es';
  if (value.endsWith('y')) return value.slice(0, -1) + 'ies';
  return value + 's';
};
