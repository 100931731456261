import { createClass as e, inherits as t, createSuper as i, classCallCheck as o, defineProperty as r, assertThisInitialized as n, get as u, getPrototypeOf as s } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { Node as a, Group as h } from "../model/graph.js";
import { EVENT_GROUP_SIZE_CHANGED_REDO as l, EVENT_GROUP_SIZE_CHANGED_UNDO as d } from "../constants.js";
var c = function () {
  function t(e, i) {
    o(this, t);
    this.obj = e;
    this.toolkit = i;
  }
  e(t, [{
    key: "_add",
    value: function e() {
      if (this.obj.objectType === a.objectType) {
        this.obj = this.toolkit.addNode(this.obj.data);
      } else if (this.obj.objectType === h.objectType) {
        this.obj = this.toolkit.addGroup(this.obj.data);
      }
    }
  }, {
    key: "_remove",
    value: function e() {
      this.toolkit.remove(this.obj);
    }
  }, {
    key: "getTerminusId",
    value: function e() {
      return this.obj.getFullId();
    }
  }, {
    key: "hasDeltas",
    value: function e() {
      return this.obj != null;
    }
  }]);
  return t;
}();
var p = function (r) {
  t(u, r);
  var n = i(u);
  function u() {
    o(this, u);
    return n.apply(this, arguments);
  }
  e(u, [{
    key: "undo",
    value: function e() {
      this._remove();
    }
  }, {
    key: "redo",
    value: function e() {
      this._add();
    }
  }]);
  return u;
}(c);
var f = function (r) {
  t(u, r);
  var n = i(u);
  function u() {
    o(this, u);
    return n.apply(this, arguments);
  }
  e(u, [{
    key: "undo",
    value: function e() {
      this._add();
    }
  }, {
    key: "redo",
    value: function e() {
      this._remove();
    }
  }, {
    key: "isConnectedTo",
    value: function e(t) {
      var i = this.getTerminusId();
      return t.source === i || t.target === i;
    }
  }]);
  return u;
}(c);
var v = function (a) {
  t(l, a);
  var h = i(l);
  function l(e, t) {
    var i;
    o(this, l);
    i = h.call(this, e.group, t);
    i.params = e;
    r(n(i), "childrenRemoved", void 0);
    r(n(i), "orphanedChildren", void 0);
    i.childrenRemoved = e.removeChildren;
    i.orphanedChildren = e.children.map(function (e) {
      return e.data;
    });
    return i;
  }
  e(l, [{
    key: "_add",
    value: function e() {
      var t = this;
      u(s(l.prototype), "_add", this).call(this);
      if (!this.childrenRemoved) {
        this.orphanedChildren.forEach(function (e) {
          return t.toolkit.addToGroup(e, t.obj);
        });
      }
    }
  }]);
  return l;
}(f);
var y = function () {
  function t(e, i, r, n, u) {
    o(this, t);
    this.group = e;
    this.originalGroupSize = i;
    this.newGroupSize = r;
    this.toolkit = n;
    this.source = u;
  }
  e(t, [{
    key: "hasDeltas",
    value: function e() {
      return this.originalGroupSize.w !== this.newGroupSize.w || this.originalGroupSize.h !== this.newGroupSize.h;
    }
  }, {
    key: "redo",
    value: function e() {
      this._fire(l);
    }
  }, {
    key: "undo",
    value: function e() {
      this._fire(d);
    }
  }, {
    key: "_fire",
    value: function e(t) {
      this.toolkit.fire(t, {
        group: this.group,
        originalGroupSize: this.originalGroupSize,
        newGroupSize: this.newGroupSize,
        source: this.source
      });
    }
  }]);
  return t;
}();
export { v as GroupRemoveAction, y as GroupSizeChangedAction, c as NodeGroupAction, p as TerminusAddAction, f as TerminusRemoveAction };