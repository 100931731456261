import { Component, effect, EventEmitter, Input, OnDestroy, OnInit, Output, signal, ViewChild, WritableSignal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TableComponent } from '@shared/components/table/table.component';
import { HttpService, CollabService, DialogService, BeinformedService, TableService } from '@core/services';
import { ICategory, IFormResult, IVote } from '@core/models';
import { CollabCase } from '@core/classes';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelActionRow } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarComponent } from '../../../../elements/avatar/avatar.component';
import { CardComponent } from '../../../card/card.component';
import { TableComponent as TableComponent_1 } from '../../../table/table.component';
import { SlidetoggleComponent } from '../../../../elements/slidetoggle/slidetoggle.component';
import { SliderComponent } from '../../../../elements/slider/slider.component';
import { IconComponent } from '../../../../elements/icon/icon.component';
import { ButtonComponent } from '../../../../elements/button/button.component';
import { CollaborationRoomPanelVoteComponent } from '../collaboration-room-panel-vote/collaboration-room-panel-vote.component';
import { FormComponent } from '../../../../../core/form/form.component';
import { EmptyStateComponent } from '../../../empty-state/empty-state.component';
// import { CollabJson, CollabJsonStep } from '@core/classes/collab-case.class';

@Component({
  selector: 'naris-collaboration-room-panel',
  templateUrl: './collaboration-room-panel.component.html',
  styleUrls: ['./collaboration-room-panel.component.scss'],
  standalone: true,
  imports: [MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, AvatarComponent, CardComponent, TableComponent_1, SlidetoggleComponent, SliderComponent, IconComponent, ButtonComponent, CollaborationRoomPanelVoteComponent, FormComponent, EmptyStateComponent, MatExpansionPanelActionRow, TranslateModule]
})
export class CollaborationRoomPanelComponent implements OnInit, OnDestroy {

  @ViewChild('causesTable') public causesTable: TableComponent;
  @ViewChild('consequencesTable') public consequencesTable: TableComponent;
  private readonly _case: WritableSignal<CollabCase> = signal({} as CollabCase);
  @Input() set case(_case: CollabCase) {
    this.savedState = undefined;
    this._case.set(_case);
  }
  get case(): WritableSignal<CollabCase> {
    return this._case;
  }
  @Input() public isHost: boolean;
  @Input() public collabId: number | string;
  @Output() public readonly completed = new EventEmitter<string>();
  @Output() public readonly next = new EventEmitter<number>();
  @Output() public readonly voted = new EventEmitter<ICategory>();
  @Output() public readonly sendVotes = new EventEmitter<void>();
  public causesUrl: string;
  public consequencesUrl: string;
  public categoriesUrl: string;
  public voteUrl: string;
  public voteLikelihoodUrl: string;
  public categoryControl = new FormControl();
  public blindVoteControl = new FormControl(this.collabService.blindVoting);
  public blindVoting = false;
  public numOfConsequencesFetched: number;
  public savedState: Record<string, any> | undefined;
  public likelihoodSet = false;
  public hostLikelihoodSet = false;
  public showLikelihoodMessage = false;
  private subs: Subscription[];
  public pushUrl: string;  
  public voteScoresUrl: string;
  private isVoteSaved = false;
  public voteColor = 'primary';

  private _likelihoodValue: number | string = 0;
  set likelihoodValue(value: number | string) {
    if (this._likelihoodValue === value) return;
    this._likelihoodValue = value;
    if (this.likelihoodSet && !this.isVoteSaved)
      this.showLikelihoodMessage = true;
    this.voteColor = 'primary';
  }
  get likelihoodValue(): number | string {
    return this._likelihoodValue;
  }

  public _likelihoodValueHost: number | string = 0;
  set likelihoodValueHost(value: number | string) {
    this._likelihoodValueHost = value;
    this.voteColor = 'primary';
  }
  get likelihoodValueHost(): number | string {
    return this._likelihoodValueHost;
  }

  constructor(
    private readonly httpService: HttpService,
    public collabService: CollabService,
    public dialogService: DialogService,
    private readonly beinformedService: BeinformedService,
    private readonly tableService: TableService
  ) {
    effect(() => {
      const collabCase = this.case();
      if (!!collabCase) {
        this.voteUrl = collabCase.actions?.find(action => this.isHost ? action.name === 'final-voting-category' : action.name === 'vote-category')?.href || '';
        this.voteLikelihoodUrl = collabCase.actions?.find(action => this.isHost ? action.name === 'final-voting-likelihood' : action.name === 'vote-likelihood')?.href || '';
        this.categoriesUrl = collabCase.actions?.find(action => this.case().caseState.activeStep === 2 ? action.name === 'final-voting-category' : action.name === 'vote-category')?.href || '';
        this.pushUrl = collabCase.actions?.find(action => action.name === 'push')?.href || '';
        this.voteScoresUrl = collabCase.actions?.find(action => action.name === 'vote-scores')?.href || '';
        if (!!collabCase.links) {
          this.consequencesUrl = collabCase.links[Object.keys(collabCase.links).find(key => key.endsWith('Consequences')) || '']?.href;
          this.causesUrl = collabCase.links[Object.keys(collabCase.links).find(key => key.endsWith('Causes')) || '']?.href;
        }
        this.setActiveStep(false);
      }
    });
  }

  public ngOnInit(): void {
    this.blindVoting = this.collabService.blindVoting;
    this.blindVoteControl = new FormControl({ value: this.collabService.blindVoting, disabled: !this.isHost });
    
    //* uit actions halen
    this.voteUrl = this.case().actions?.find(action => this.isHost ? action.name === 'final-voting-category' : action.name === 'vote-category')?.href || '';
    this.voteLikelihoodUrl = this.case().actions?.find(action => this.isHost ? action.name === 'final-voting-likelihood' : action.name === 'vote-likelihood')?.href || '';
    this.categoriesUrl = this.case().actions?.find(action => this.case().caseState.activeStep === 2 ? action.name === 'final-voting-category' : action.name === 'vote-category')?.href || '';
    this.pushUrl = this.case().actions?.find(action => action.name === 'push')?.href || '';
    this.voteScoresUrl = this.case().actions?.find(action => action.name === 'vote-scores')?.href || '';;
    //* uit _links halen
    this.consequencesUrl =!!this.case().links ? this.case().links![Object.keys(this.case().links!).find(key => key.endsWith('Consequences')) || '']?.href || '' : '';
    this.causesUrl =      !!this.case().links ? this.case().links![Object.keys(this.case().links!).find(key => key.endsWith('Causes')) || '']?.href || '' : '';

    this.subs = [
      this.categoryControl.valueChanges.subscribe(val => {
        if (this.isHost) this.collabService.sendSocket('categoryChange', val);
        this.case().caseState.chosenCategories = this.case().caseState.categories?.filter(c => val.includes(c.key));
        this.case().caseState.currentCategory ??= this.case().caseState.chosenCategories![0];
      }),
      this.tableService.collabCategories$.subscribe(categories => {
        const mappedCategories = categories.map((category: any) => this.mapCategories(category[0])) as ICategory[];
        const uniqueCategories: ICategory[] = [];
        mappedCategories.forEach(category => {
          if (!uniqueCategories.find(x => x.class === category.class)) uniqueCategories.push(category);
        });
        this.case().caseState.categories = uniqueCategories;
        this.collabService.sendSocket('getCategories', this.case().caseState.categories);
        this.categoryControl.setValue(this.case().caseState.categories!.map(c => c.key));
        if (!!this.case().caseState.categories) this.case().caseState.currentCategory = this.case().caseState.categories![0];        
      }),
      this.collabService.resetVotes$.subscribe(() => this.resetVote()),
      this.collabService.completeCollab$.subscribe(caseId => this.completeCollabCase(caseId))
    ];
    if (this.isHost) {
      this.subs.push(this.blindVoteControl.valueChanges.subscribe(val => {
        this.collabService.sendSocket('blindVoteChange', val);
        this.blindVoting = val || false;
      }));
      this.subs.push(this.collabService.clicked$.pipe(
        filter(data => {
          const actions = [
            'refreshTable'
          ];
          return actions.includes(data.clicked) && this.case().id === data.id;
        })
      ).subscribe(data => {
        switch (data.clicked) {
          case 'refreshTable': this.reloadTable(data.data); break;
          default: break;
        }
      }));
    } else {
      this.subs.push(
        this.collabService.clicked$.pipe(
          filter(data => {
            const actions = [
              'steps', 'setStep', 'panelOpened', 'panelClosed', 'getCategories',
              'categoryChange', 'getPanelState', 'refreshTable', 'blindVoteChange'
            ];
            return actions.includes(data.clicked) && this.case().id === data.id;
          })
        ).subscribe(data => {
          switch (data.clicked) {
            case 'steps': this.case().caseState.steps = data.data; break;
            case 'setStep': this.setStep(data.data); break;
            case 'panelOpened': this.panelOpened(data.data); break;
            case 'panelClosed': this.panelClosed(); break;
            case 'getCategories': this.case().caseState.categories = data.data; break;
            case 'categoryChange': this.setCategories(data.data); break;
            case 'blindVoteChange': this.blindVoteControl.setValue(data.data); this.blindVoting = data.data || false; break;
            case 'refreshTable': this.reloadTable(data.data); break;
            default: break;
          }
        }),
        this.collabService.fetchState$.subscribe(() => this.collabService.getSaveState())
      );
    }
  }

  public addVote(_vote: { category: ICategory; likelihood: number; impact: number }) {
    // const catIndex = this.case().caseState.chosenCategories!.findIndex(c => !c.voted);
    // if (catIndex >= 0) this.case().caseState.currentCategory = this.case().caseState.chosenCategories![catIndex];
    
  }

  public saveVote(vote: IVote) {
    // const category = this.case().caseState.categories?.find(cat => cat === vote.category);
    // if (!!category) category.vote = {likelihood: vote.likelihood, impact: vote.impact};
    this.httpService.post(this.voteUrl, {
      [this.isHost ? 'FinalVotingCategory' : 'VoteCategory']: vote.VoteCategory
    }).subscribe({
      next: res => {
        this.collabService.addVote(vote, this.isHost);
        if (res.formresponse.success.redirect.endsWith('/push')) this.pushUrl = res.formresponse.success.redirect;
        this.getExpertVotes();
      },
      error: err => {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    });
  }

  public setStep(i: number, updateState = true) {
    if (this.isHost) this.collabService.sendSocket('setStep', i);

    else if (i === 1 && !this.case().caseState.currentCategory) this.case().caseState.currentCategory = this.case().caseState.categories![0];

    if (this.isHost) {
      if (i > 0 && this.case().caseState.steps[i - 1] !== 2) {
        this.case().steps[i - 1].completed = true;
        this.case().caseState.steps[i - 1] = 2;

        if (!this.isHost && (this.collabService.activeWorkingStep === 'collab.add_cause_consequence' || this.collabService.activeWorkingStep === 'collab.vote_category')) {
          this.case().caseState.state = 2;
        }

        this.collabService.sendSocket('steps', this.case().caseState.steps);
      }
    }

    if (i > 3) this.completed.emit(this.case().id);
    else this.next.emit(i);

    if (this.isHost) {
      this.case().caseState.activeStep = i;
      if (!!this.collabService.updateStateEndpoint && updateState) void this.save(this.collabService.updateStateEndpoint, ['StateJSON'], [this.getStateJSON()]);
    } 

    this.collabService.updateAverage$.next(true);
  }

  public getExpertVotes() {
    if (!this.voteScoresUrl) return;
    this.beinformedService.fetchForm(this.voteScoresUrl).subscribe({
      next: res => {
        this.isVoteSaved = true;
        const suggestion = res.data.error.formresponse.missing?.anchors[0].elements[0].suggestion;
        let votes = {} as any;
        try {
          votes = JSON.parse(suggestion);
        } catch (e) {
          console.log(e);
          return;
        }
        const expertVotes = {} as any;
        votes[0]?.UserVotes.forEach((vote: any) => {
          if (!vote.CategoryID || !vote.ConsequenceClassID || vote.LikelihoodPercentage === undefined) return;
          if (!expertVotes[vote.CategoryID]) expertVotes[vote.CategoryID] = [];
          setTimeout(() => {
            this.determineVoted(vote);
          }, 500);
          const impact = this.categoryIdToImpact(vote.CategoryID, vote.ConsequenceClassID);
          const likelihood = vote.LikelihoodPercentage;
          const voteCategory = this.case().caseState.categories?.find(cat => cat.key === vote.CategoryID) || this.case().caseState.currentCategory;
          const impactLabel = voteCategory?.classes?.find(item => item.key === vote.ConsequenceClassID)?.value;
          expertVotes[vote.CategoryID].push({user: {id: vote.UserID, name: vote.Name, host: this.collabService.facilitatorId === vote.UserID.toString()}, likelihood, impact, impactLabel});
        });
        this.collabService.expertVotes[this.collabService.currentCase?.id || ''] = expertVotes;
        this.collabService.updateImpactSelect$.next();
        this.collabService.expertVotesFetched$.next();
        this.isVoteSaved = false;
      }
    });
  }

  private determineVoted(vote: any) {
    if (this.collabService.selfId === vote.UserID?.toString()) {
      if (this.collabService.isHost)
        this.likelihoodValueHost = vote.LikelihoodPercentage;
      else
        this.likelihoodValue = vote.LikelihoodPercentage;
      this.case.update(collabCase => {
        const foundCat = collabCase.caseState.categories?.find((cat: any) => cat.key === vote.CategoryID);
        if (!!foundCat) foundCat.voted = true;
        return collabCase;
      });
    }
  }

  private categoryIdToImpact(catId: number, classId: number): number {
    const allCategories = this.case().caseState.categories;
    const foundCategory = allCategories?.find(cat => cat.key === catId);
    if (!foundCategory) return -1;
    const classes = foundCategory.classes;
    const foundClassIndex = classes.findIndex(categoryClass => categoryClass.key === classId);
    if (!classes[foundClassIndex]?.max) {
      const impact = ((!!foundClassIndex || foundClassIndex === 0 ? foundClassIndex + 1 : 1)/classes.length) * 100;
      return impact;
    } else if (!!classes[foundClassIndex]?.max) {
      const maxValue = Math.max(...foundCategory.classes.map(item => item.max));
      const max = classes[foundClassIndex]?.max;
      const min = classes[foundClassIndex]?.min;
      const median = ((max - min) / 2) + min;
      const impact = (median/maxValue) * 100;
      return impact;
    }
    return -1;
  }

  public getStateJSON(): string {
    const states: Record<string, any> = {};
    const cases = this.collabService.collabPotentialCases.concat(this.collabService.collabIdentificationCases);
    cases.forEach(x => {
      states[x.id] = {
        state: this.getState(x),
        steps: []
      };
      x.steps.forEach(step => {
        if (states[x.id].steps.length > 0) states[x.id].steps.push({
          state: this.getState(step),
          blindVoting: this.blindVoteControl.value
        });
        else states[x.id].steps = [{
          state: this.getState(step),
          blindVoting: this.blindVoteControl.value
        }];
      });
    });
    return JSON.stringify(states);
  }

  public getState(step: any): string {
    if (step.completed) return 'Completed';
    else if (step.intermediate) return 'Intermediate';
    else return 'Initial';
  }

  private save(href: string, mapKeys: string[], mapValues: any[], commit = true) {
    return new Promise<IFormResult>((resolve, reject) => {
      this.beinformedService.fetchForm(href, commit).subscribe({
        next: res => {
          const tokens = res.data.error?.formresponse.tokens;
          const objects = {} as Record<string, any>;
          const objectid = res.data.error.formresponse.missing?.anchors[0].objectid || '';
          const keys = res.data.error?.formresponse.missing?.anchors[0].elements.map(x => x.elementid);
          const values: Record<string, any> = {};
          keys?.forEach(key => {
            let value = '';
            const index = mapKeys.indexOf(key);
            if (index !== undefined) value = mapValues[index];
            return values[key] = value;
          });
          objects[objectid] = values;
          const postableObject = { tokens: tokens, objects: objects };
          this.beinformedService.fetchForm(href, true, postableObject).subscribe({
            next: result => {
              resolve(result);
            },
            error: (err: Error) => {
              reject(err);
            }
          });
        }
      });
    });
  }

  public panelOpened(i: number) {
    if (this.isHost) this.collabService.sendSocket('panelOpened', i);
    if (this.case().caseState.steps[i] !== 2) {
      this.case().steps[i].intermediate = true;
      this.case().caseState.steps[i] = 1;
    }
    this.case().caseState.activeStep = i;

    if (i === 2 || i === 1)
      this.getExpertVotes();
  }

  public panelClosed() {
    if (this.isHost) this.collabService.sendSocket('panelClosed');
    else this.case().caseState.activeStep = null;
  }

  public sendRefresh(num: number) {
    this.collabService.sendSocket('refreshTable', num);
    this.collabService.updateCausesOrConsequences$.next(num);
  }

  public ngOnDestroy() {
    this.subs?.forEach(sub => sub.unsubscribe());
  }

  private reloadTable(num: number) {
    if (num === 0) this.causesTable.reload();
    else this.consequencesTable.reload();
    this.collabService.updateCausesOrConsequences$.next(num);
  }

  private setCategories(catIds: any[]) {
    this.categoryControl.setValue(catIds);
    this.case().caseState.chosenCategories = this.case().caseState.categories?.filter(c => catIds.includes(c.key));
    this.case().caseState.currentCategory ??= this.case().caseState.chosenCategories![0];
  }

  private readonly mapCategories = (category: Record<string, any>) => {
    const entries = Object.entries(category);
    const catId = entries.find(x => x[0].toLowerCase().includes('recordid'))?.[1];
    const catName = entries.find(x => x[0].toLowerCase().includes('name'))?.[1];
    const catType = entries.find(x => x[0].toLowerCase().includes('type'))?.[1];
    const catClasses = category.classes.map(this.mapCatClasses);
    return {
      key: catId,
      label: catName,
      type: catType,
      classes: catClasses,
      voted: false,
      class: catClasses[0].key
    };
  };

  private readonly mapCatClasses = (catClass: Record<string, any>) => {
    const entries = Object.entries(catClass);
    const classId = entries.find(x => x[0].toLowerCase().includes('recordid'))?.[1];
    const className = entries.find(x => x[0].toLowerCase().includes('name'))?.[1];
    const classMax = entries.find(x => x[0].toLowerCase().includes('maximum'))?.[1];
    const classMin = entries.find(x => x[0].toLowerCase().includes('minimum'))?.[1];
    const classDescription = entries.find(x => x[0].toLowerCase().includes('description'))?.[1];
    return { value: className, key: classId, max: classMax, min: classMin, elements: { description: classDescription }};
  };

  public setLikelihood() {
    this.httpService.post(this.voteLikelihoodUrl, {
      [this.isHost ? 'FinalVotingLikelihood' : 'VoteLikelihood']: {
        LikelihoodPercentage: this.isHost ? this.likelihoodValueHost : this.likelihoodValue
      }
    }).subscribe({next: () => {
      this.voteColor = 'success';
      if (this.isHost) this.hostLikelihoodSet = true;
      else this.likelihoodSet = true;
    }});
  }

  public allVoted(): boolean {
    return !this.case().caseState.categories?.some(cat => !cat.voted);
  }

  public async collabCompleted() {
    this.case().completed = true;
    this.case().caseState.state = 2;
    this.case().steps[3].completed = true;
    this.case().caseState.steps[3] = 2;
    this.collabService.identificationCasesCompleted = this.collabService.collabIdentificationCases.filter(x => x.completed)?.length;
    this.collabService.potentialCasesCompleted = this.collabService.collabPotentialCases.filter(x => x.completed)?.length;
    this.collabService.collabState.completed.push(this.case().id);
    this.collabService.collabComplete();
    if (!!this.collabService.updateStateEndpoint) await this.save(this.collabService.updateStateEndpoint, ['StateJSON'], [this.getStateJSON()]);
    await this.collabService.getSaveState();
    this.collabService.fetchSaveState();
    if (this.collabService.allCasesCompleted) window.location.reload();
    
    this.resetVote();
  }

  public async completeCollabCase(caseId: string) {
    let collabCase = this.collabService.collabIdentificationCases.find(x => x.id === caseId);
    if (!collabCase) collabCase = this.collabService.collabPotentialCases.find(x => x.id === caseId);
    if (!!collabCase) {
      collabCase.completed = true;
      collabCase.caseState.state = 2;
      collabCase.steps[3].completed = true;
      collabCase.caseState.steps[3] = 2;
      this.collabService.identificationCasesCompleted = this.collabService.collabIdentificationCases.filter(x => x.completed)?.length;
      this.collabService.potentialCasesCompleted = this.collabService.collabPotentialCases.filter(x => x.completed)?.length;
      this.collabService.collabState.completed.push(collabCase.id);
      if (!!this.collabService.updateStateEndpoint) await this.save(this.collabService.updateStateEndpoint, ['StateJSON'], [this.getStateJSON()]);
      await this.collabService.getSaveState();
      this.collabService.fetchSaveState();
      if (this.collabService.allCasesCompleted) window.location.reload();
      this.resetVote();
    }
  }

  public setActiveStep(updateState = true) {
    const stepIndex = this.collabService.currentCase!.steps.findIndex(x => !x.completed);
    this.collabService.currentCase!.caseState.activeStep = stepIndex;
    this.setStep(stepIndex, updateState);
  }

  public resetVote() {
    this.likelihoodValue = 0;
    this.likelihoodValueHost = 0;
    this.likelihoodSet = false;
    this.hostLikelihoodSet = false;
    this.showLikelihoodMessage = false;
    this.voteColor = 'primary';
  }
}
