import { ObjectAnchorSpec, GeneratedGridBackground, GridTypes } from '@jsplumbtoolkit/browser-ui';

// Process elements
export const PROCESS = 'process-task';
export const START_EVENT = 'process-startEvent';
export const INTERMEDIATE_EVENT = 'process-intermediateEvent';
export const END_EVENT = 'process-endEvent';
export const CONDITION = 'process-condition';
export const SUB_PROCESS = 'sub-process';
export const DATASTORE = 'process-datastore';
export const DOCUMENT = 'process-document';
export const GROUP  = 'process-group';
export const SELECTABLE = 'selectable';
export const FLOATING_MENU = 'floating-menu';

export const CLASS_FLOWCHART_EDGE = 'jtk-flowchart-edge';
export const CLASS_EDGE_LABEL = 'jtk-flowchart-edge-label';

export const ANCHOR_POSITIONS: (ObjectAnchorSpec & { id: string })[] = [
  {x: 0, y: 0.5, ox: -1, oy: 0, id: 'left'},
  {x: 1, y: 0.5, ox: 1, oy: 0, id: 'right'},
  {x: 0.5, y: 0, ox: 0, oy: -1, id: 'top'},
  {x: 0.5, y: 1, ox: 0, oy: 1, id: 'bottom'}
];

export const GRID_BACKGROUND_OPTIONS = {
  dragOnGrid: true,
  showGrid: true,
  showBorder: true,
  autoShrink: true,
  minWidth: 8000,
  minHeight: 8000,
  showTickMarks: false,
  type: GeneratedGridBackground.type,
  gridType: GridTypes.dotted,
  dotRadius: 1
};

export const ICONS: any = {
  'process-task': `
    <svg width="150" height="100" viewBox="0 0 150 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.5" y="1.5" width="147" height="97" rx="6.5" stroke="black" stroke-width="3"/>
    </svg>
  `,
  'process-startEvent': `
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25" cy="25" r="23.5" stroke="black" stroke-width="3"/>
    </svg>
  `,
  'process-endEvent': `
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" id="">
      <circle cx="25" cy="25" r="22" stroke="black" stroke-width="6"/>
    </svg>
  `,
  'process-condition': `
    <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.41753 32.9038L32.8897 3.43159C35.4281 0.893185 39.5437 0.893186 42.0821 3.4316L71.5543 32.9038C74.0927 35.4422 74.0927 39.5578 71.5543 42.0962L42.0821 71.5684C39.5437 74.1068 35.4281 74.1068 32.8897 71.5684L3.41752 42.0962C0.879115 39.5578 0.879118 35.4422 3.41753 32.9038Z" stroke="black" stroke-width="3"/>
    </svg>  
  `
};

export const CUSTOM_SHAPES = {
  id: 'processManagementShapes',
  name: 'Process Management Shapes',
  shapes: {
    start: {
      type: 'start',
      template: `<svg:svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <svg:circle cx="25" cy="25" r="23.5" stroke-width="3"/>
      </svg:svg>`
    },
    end: {
      type: 'end',
      template: `<svg:svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <svg:circle cx="25" cy="25" r="22" stroke-width="6"/>
      </svg:svg>`
    },
    task: {
      type: 'task',
      template: `<svg:svg width="150" height="100" viewBox="0 0 150 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <svg:rect x="1.5" y="1.5" width="147" height="97" rx="6.5" stroke-width="3"/>
      </svg:svg>`
    },
    condition: {
      type: 'condition',
      template: `<svg:svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
      <svg:path d="M3.41753 32.9038L32.8897 3.43159C35.4281 0.893185 39.5437 0.893186 42.0821 3.4316L71.5543 32.9038C74.0927 35.4422 74.0927 39.5578 71.5543 42.0962L42.0821 71.5684C39.5437 74.1068 35.4281 74.1068 32.8897 71.5684L3.41752 42.0962C0.879115 39.5578 0.879118 35.4422 3.41753 32.9038Z" stroke="black" stroke-width="3"/>
      </svg:svg>`
    },
    test: {
      type: 'test',
      template: `
        <svg:g>
          <svg:circle cx="{{width/2}}" cy="{{height/2}}"/>
        </svg:g>
      `
    }
  }
};