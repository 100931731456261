import { EMPTY_BOUNDS as n } from "../util/util.js";
import { Segments as t } from "../core/connector/segments.js";
function e() {
  return {
    d: Infinity,
    x: null,
    y: null,
    l: null,
    x1: null,
    y1: null,
    x2: null,
    y2: null
  };
}
function l(n, t, l) {
  return e();
}
function u(n, t, e, l, u) {
  return [];
}
function r(n, e, l, u, r) {
  var i = [];
  i.push.apply(i, t.lineIntersection(n, e, l, e + u, l));
  i.push.apply(i, t.lineIntersection(n, e + u, l, e + u, l + r));
  i.push.apply(i, t.lineIntersection(n, e + u, l + r, e, l + r));
  i.push.apply(i, t.lineIntersection(n, e, l + r, e, l));
  return i;
}
function i(n, t) {
  return r(n, t.x, t.y, t.w, t.h);
}
function o(t) {
  return Object.assign({
    extents: n()
  }, t);
}
export { o as _createBaseSegment, i as _defaultSegmentBoundingBoxIntersection, r as _defaultSegmentBoxIntersection, l as _defaultSegmentFindClosestPointOnPath, u as _defaultSegmentLineIntersection };