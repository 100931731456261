@if (parent.opened | async) {
  <div
    [@slideInOutRightAnimation]
    class="naris-drawer"
    [style.width]="width"
    [style.right]="offset + 'rem'"
    [ngClass]="{'naris-dynamic-dashboard-drawer': isDynamicDashboardAddDrawer}"
  >
    <div class="naris-drawer-inner" cdkScrollable>
      <ng-content (close)="close()" />
    </div>
  </div>
}

