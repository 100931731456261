import { classCallCheck as e, defineProperty as t } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { Endpoints as s } from "../endpoint/endpoint.js";
import { extend as a, clone as i, merge as n, setToArray as r, populate as o } from "../../util/util.js";
import { Connections as l } from "../connector/connections.js";
function p(e) {
  return e == null ? null : e.split(" ").filter(function (e) {
    return e != null && e.length > 0;
  });
}
function c(e, t, s) {
  for (var a in t) {
    e[a] = s;
  }
}
var f = "connector";
var v = "override";
var y = "cssClass";
var h = "__default";
var u = "anchor";
var d = "anchors";
function m(e, t) {
  var i = e.objectType,
    p = {};
  var m = e._defaultType;
  var T = a({}, m);
  c(p, m, h);
  e._types.forEach(function (t) {
    if (t !== h) {
      var s = e.instance.getType(t, i);
      if (s != null) {
        var a = new Set([f, u, d]);
        if (s.mergeStrategy === v) {
          for (var o in s) {
            a.add(o);
          }
        }
        T = n(T, s, [y], r(a));
        c(p, s, t);
      }
    }
  });
  if (t) {
    T = o(T, t, "_");
  }
  if (_(e)) {
    l.applyType(e, T, p);
  } else if (C(e)) {
    s.applyType(e, T, p);
  }
}
function _(e) {
  return e.objectType === S;
}
function C(e) {
  return e.objectType === T;
}
var T = "endpoint";
var S = "connection";
function g(e, t) {
  var a = e.instance.getType(t, e.objectType);
  if (a != null && a.cssClass) {
    if (_(e)) {
      l.removeClass(e, a.cssClass);
    } else if (C(e)) {
      s.removeClass(e, a.cssClass);
    }
  }
}
function P(e) {
  if (e.paintStyle && e.hoverPaintStyle) {
    var t = {};
    a(t, e.paintStyle);
    a(t, e.hoverPaintStyle);
    e.hoverPaintStyle = t;
  }
}
var j = "add";
var b = "remove";
var w = function s(a, n, r) {
  e(this, s);
  this.instance = a;
  t(this, "objectType", void 0);
  t(this, "idPrefix", void 0);
  t(this, "defaultLabelLocation", .5);
  t(this, "clone", void 0);
  t(this, "deleted", void 0);
  t(this, "segment", void 0);
  t(this, "x", void 0);
  t(this, "y", void 0);
  t(this, "w", void 0);
  t(this, "h", void 0);
  t(this, "id", void 0);
  t(this, "visible", true);
  t(this, "typeId", void 0);
  t(this, "params", {});
  t(this, "paintStyle", void 0);
  t(this, "hoverPaintStyle", void 0);
  t(this, "paintStyleInUse", void 0);
  t(this, "_hover", false);
  t(this, "lastPaintedAt", void 0);
  t(this, "data", void 0);
  t(this, "_defaultType", void 0);
  t(this, "events", void 0);
  t(this, "parameters", void 0);
  t(this, "_types", void 0);
  t(this, "_typeCache", void 0);
  t(this, "cssClass", void 0);
  t(this, "hoverClass", void 0);
  r = r || {};
  this.cssClass = r.cssClass || "";
  this.hoverClass = r.hoverClass || a.defaults.hoverClass;
  this._types = new Set();
  this._typeCache = {};
  this.parameters = i(r.parameters || {});
  this.id = r.id || n + new Date().getTime();
  this._defaultType = {
    parameters: this.parameters,
    scope: r.scope || this.instance.defaultScope,
    overlays: {}
  };
};
var D = {
  create: function e(t, s, a) {
    a = a || {};
    var n = a.cssClass || "";
    var r = a.hoverClass || t.defaults.hoverClass;
    var o = new Set();
    var l = {};
    var p = i(a.parameters || {});
    var c = a.id || s + new Date().getTime();
    var f = {
      parameters: p,
      scope: a.scope || t.defaultScope,
      overlays: {}
    };
    return {
      cssClass: n,
      instance: t,
      hoverClass: r,
      _types: o,
      _typeCache: l,
      _defaultType: f,
      parameters: p,
      id: c
    };
  },
  addClass: function e(t, s, a) {
    var i = (t.cssClass || "").split(" ");
    i.push(s);
    t.cssClass = i.join(" ");
  },
  removeClass: function e(t, s, a) {
    var i = (t.cssClass || "").split(" ");
    t.cssClass = i.filter(function (e) {
      return e !== s;
    }).join(" ");
  },
  cacheTypeItem: function e(t, s, a, i) {
    t._typeCache[i] = t._typeCache[i] || {};
    t._typeCache[i][s] = a;
  },
  reapplyTypes: function e(t, s) {
    m(t, s);
  },
  hasType: function e(t, s) {
    return t._types.has(s);
  },
  removeType: function e(t, s, a) {
    var i = p(s),
      n = false,
      r = function e(s) {
        if (t._types.has(s)) {
          g(t, s);
          t._types.delete(s);
          return true;
        }
        return false;
      };
    if (i != null) {
      for (var o = 0, l = i.length; o < l; o++) {
        n = r(i[o]) || n;
      }
      if (n) {
        m(t, a);
      }
    }
  },
  addType: function e(t, s, a) {
    var i = p(s),
      n = false;
    if (i != null) {
      for (var r = 0, o = i.length; r < o; r++) {
        if (!t._types.has(i[r])) {
          t._types.add(i[r]);
          n = true;
        }
      }
      if (n) {
        m(t, a);
      }
    }
  },
  getCachedTypeItem: function e(t, s, a) {
    return t._typeCache[a] ? t._typeCache[a][s] : null;
  },
  setPaintStyle: function e(t, s) {
    t.paintStyle = s;
    t.paintStyleInUse = t.paintStyle;
    P(t);
  },
  setHoverPaintStyle: function e(t, s) {
    t.hoverPaintStyle = s;
    P(t);
  },
  getType: function e(t) {
    return Array.from(t._types.keys());
  },
  toggleType: function e(t, s, a) {
    var i = p(s);
    if (i != null) {
      for (var n = 0, r = i.length; n < r; n++) {
        if (t._types.has(i[n])) {
          g(t, i[n]);
          t._types.delete(i[n]);
        } else {
          t._types.add(i[n]);
        }
      }
      m(t, a);
    }
  },
  clearTypes: function e(t, s) {
    t._types.forEach(function (e) {
      g(t, e);
    });
    t._types.clear();
    m(t, s);
  },
  setType: function e(t, s, a) {
    D.clearTypes(t);
    (p(s) || []).forEach(t._types.add, t._types);
    m(t, a);
  },
  applyType: function e(t, s, a) {
    D.setPaintStyle(t, s.paintStyle);
    D.setHoverPaintStyle(t, s.hoverPaintStyle);
    D.mergeParameters(t, s.parameters);
    t.paintStyleInUse = t.paintStyle;
  },
  appendToDefaultType: function e(t, s) {
    for (var a in s) {
      t._defaultType[a] = s[a];
    }
  },
  mergeParameters: function e(t, s) {
    if (s != null) {
      a(t.parameters, s);
    }
  },
  getData: function e(t) {
    return t.data;
  },
  setData: function e(t, s) {
    t.data = s || {};
  },
  mergeData: function e(t, s) {
    t.data = a(t.data, s);
  },
  destroy: function e(t) {},
  setVisible: function e(t, s) {
    t.visible = s;
  }
};
export { j as ADD_CLASS_ACTION, S as COMPONENT_TYPE_CONNECTION, T as COMPONENT_TYPE_ENDPOINT, w as Component, D as Components, b as REMOVE_CLASS_ACTION, g as _removeTypeCssHelper, P as _updateHoverStyle, _ as isConnection, C as isEndpoint };