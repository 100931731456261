import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IInputOption } from '@core/models';
import { CheckboxComponent } from '../checkbox/checkbox.component';

@Component({
  selector: 'naris-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
  standalone: true,
  imports: [CheckboxComponent]
})
export class CheckboxGroupComponent implements OnInit {

  @Input() public control: FormControl;
  @Input() public options: IInputOption[];
  @Input() public disabled = false;
  @Input() public id: string;

  public ngOnInit(): void {
    if (this.control.disabled) this.disabled = true;
  }

  /**
   * Handle checkbox value changes
   * @param event MatCheckboxChange
   * @param option IInputOption
   */
  public onChange(event: MatCheckboxChange, option: IInputOption) {
    // Get initial value from the form control
    let val = this.control.value;
    // If option is checked and not in the control value, add it
    if (event.checked && !val.includes(option.key)) {
      val.push(option.key);
    }
    // If option is unchecked and in control value, remove it
    if (!event.checked && val.includes(option.key)) {
      val = val.filter((i: string) => i !== option.key);
    }
    // Set the value of the form control
    this.control.setValue(val);
  }
}
