import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ICategory, IVote } from '@core/models';
import { CollabService, UserService } from '@core/services';
import { SelectComponent } from '../../../../elements/select/select.component';
import { HeatmapComponent } from '../../../heatmap/heatmap.component';

@Component({
  selector: 'naris-collaboration-room-panel-vote',
  templateUrl: './collaboration-room-panel-vote.component.html',
  styleUrl: './collaboration-room-panel-vote.component.scss',
  standalone: true,
  imports: [SelectComponent, HeatmapComponent]
})
export class CollaborationRoomPanelVoteComponent {

  @Input() public isHost: boolean;
  @Input() public blindVoting: boolean;
  @Input() public isSingle = false;
  @Input() public discrete = false;
  @Input() public disabled = false;
  @Input() public displayLikelihood  = true;
  @Input() public mode: 'overview' | 'voting';

  private _category: ICategory | null;
  @Input() set category(value: ICategory | null) {
    this._category = value;
    const firstClass = value?.classes[0];
    if (!!firstClass && !!this._category && !!firstClass['max']) {
      this._category.classes.forEach(catClass => catClass.value = catClass.value.includes('(') ? catClass.value : `${catClass.value} (${catClass.min} - ${catClass.max})`);
    }
  };
  get category(): ICategory | null {
    return this._category;
  }

  private _xValue: number | string;
  @Input() set xValue(value: number | string) {
    if (!!value) this.disabled = false;
    this._xValue = value;
  }
  get xValue(): number | string {
    return this._xValue;
  }

  public voted = new EventEmitter<IVote>();
  @Output() public readonly saveVote = new EventEmitter<IVote>();

  public impactValue: number;

  public selectedCategoryId: number;

  @HostBinding('class')
  get rootClasses() {
    if (this.isSingle) return ['single'];
  }

  constructor(
    public readonly collabService: CollabService,
    private readonly userService: UserService
  ) {
    this.collabService.updateImpactSelect$.subscribe(() => {
      if (!!this.collabService.currentCase && !!this.category && Object.keys(this.collabService.expertVotes).length > 0) {
        const userVote = this.collabService.expertVotes[this.collabService.currentCase.id]?.[this.category.key]?.find(x => x.user?.id === this.userService.idOfUser);
        if (!!userVote && !!userVote.impact) {
          const impact = !!this.category.classes[0].max ? (Math.max(...this.category.classes.map(x => x.max)) / 100 * userVote.impact): userVote.impact;
          const selectedClassIndex = !!this.category.classes[0].max ? this.category.classes.findIndex(catClass => impact >= catClass.min && impact <= catClass.max) : Math.ceil(((this.category.classes.length || 1) * impact) / 100) - 1;
          this.selectedClass = this.category.classes[selectedClassIndex]?.key as number;
        }
      }
    });
  }

  public getImpactValue(event: number) {
    this.selectedCategoryId = event;
    const foundClass = this.category?.classes.find(item => item.key === event);
    if (!!foundClass) {
      if (!foundClass.max) {
        const maxValue = this.category!.classes.length;
        const selectedValue = this.category!.classes.findIndex(item => item.key === event) + 1;
        this.impactValue = (selectedValue/maxValue) * 100;
      } else {
        const maxValue = Math.max(...this.category!.classes.map(item => item.max));
        const selectedValue = foundClass.max;
        const previousMaxValueIndex = this.category!.classes.findIndex(item => item.key === event) - 1;
        const previousMaxValue = previousMaxValueIndex < 0 ? 0 : this.category!.classes[previousMaxValueIndex].max;
        const maxMedian = ((selectedValue - previousMaxValue) / 2) + previousMaxValue;
        this.impactValue = (maxMedian/maxValue) * 100;
      }
    }
  }

  public voteChanged(event: IVote) {
    const foundClass = this.category?.classes.find(item => item.key === this.selectedCategoryId);
    const vote: IVote = {
      VoteCategory: { // Casing op deze manier voor BE
        EventCategoryId: event.category!.key.toString(),
        ConsequenceClassID: foundClass?.key.toString()
      },
      impact: this.impactValue,
      likelihood: this.xValue as number
    };
    this.saveVote.emit(vote);
  }

  public selectedClass: number;
  public voteClicked(event: {category: Record<string, any>, impact: number, likelihood: number}) {
    this.impactValue = event.impact; 
    if (!this.category?.classes) return;
    let selectedClassIndex = 0;
    if (!this.category.classes[0].max) {
      const classLength = this.category.classes.length || 1;
      selectedClassIndex = Math.ceil((classLength * event.impact) / 100) - 1;
    } else {
      const maxValue = this.category.classes[this.category.classes.length - 1].max;
      const selectedValue = (maxValue * event.impact) / 100;
      selectedClassIndex = this.category.classes.findIndex(catClass => selectedValue >= catClass.min && selectedValue <= catClass.max);
    }
    this.selectedClass = this.category.classes[selectedClassIndex].key as number;
    this.selectedCategoryId = this.selectedClass;
  }

}
