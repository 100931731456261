import hark from 'hark';
import { getInitials } from '@core/helpers';
import { IPeerUser } from '@core/models';

export default class Participant {

  public id: number | string;
  public name: string;
  public initials: string;
  public muted: boolean;
  public voted: string[];
  public voteAverageIncluded: boolean;
  public talking: boolean;
  public speech: hark.Harker;
  public audio: MediaStreamTrack;
  public host: boolean;
  public socketId?: string | null;

  constructor(public user: IPeerUser, public stream: MediaStream | null) {
    this.id = user.id;
    this.name = user.name;
    this.initials = getInitials(user.name);
    this.muted = !!user.muted;
    this.host = !!user.host;
    this.voted = [];
    this.voteAverageIncluded = true;
    this.talking = false;
    this.socketId = user.socketId;
    if (!!stream) {
      this.audio = stream.getAudioTracks()[0];
      this.speech = hark(stream);
      this.speech.on('speaking', () => {
        if (!this.talking) this.talking = true;
      });
      this.speech.on('stopped_speaking', () => {
        if (this.talking) this.talking = false;
      });
    }
  }

  public leave() {
    this.speech?.stop();
    this.stream?.getTracks().forEach(track => track.stop());
    this.stream = null;
  }

  public mute() {
    if (!this.muted) {
      this.audio.enabled = false;
      this.muted = true;
      void this.speech.suspend();
    }
  }

  public unmute() {
    if (this.muted) {
      this.audio.enabled = true;
      this.muted = false;
      void this.speech.resume();
    }
  }
}
