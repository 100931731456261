import { inherits as t, createSuper as r, classCallCheck as e, defineProperty as n, assertThisInitialized as a, createClass as i } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { ElementDragHandler as o } from "./element-drag-handler.js";
import { EVENT_REVERT as s } from "./event-constants.js";
import { SELECTOR_MANAGED_ELEMENT as u } from "../core/constants.js";
import { SELECTOR_GROUP as l } from "./constants.js";
var c = function (o) {
  t(d, o);
  var c = r(d);
  function d(t, r, i) {
    var o;
    e(this, d);
    o = c.call(this, t, r, i);
    o.instance = t;
    o.dragSelection = r;
    n(a(o), "selector", [">", l, u].join(" "));
    n(a(o), "doRevalidate", void 0);
    n(a(o), "currentGroup", void 0);
    n(a(o), "currentChildPositions", new Map());
    n(a(o), "currentGroupEntry", void 0);
    o.doRevalidate = o._revalidate.bind(a(o));
    return o;
  }
  i(d, [{
    key: "reset",
    value: function t() {
      this.currentChildPositions.clear();
      this.currentGroup = null;
      this.currentGroupEntry = null;
      this.drag.off(s, this.doRevalidate);
    }
  }, {
    key: "_revalidate",
    value: function t(r) {
      this.instance.revalidate(r);
    }
  }, {
    key: "init",
    value: function t(r) {
      this.drag = r;
      r.on(s, this.doRevalidate);
    }
  }, {
    key: "useGhostProxy",
    value: function t(r, e) {
      var n = e._jsPlumbParentGroup;
      return n == null ? false : n.ghost === true;
    }
  }, {
    key: "makeGhostProxy",
    value: function t(r) {
      var e = r;
      var n = e.cloneNode(true);
      n._jsPlumbParentGroup = e._jsPlumbParentGroup;
      return n;
    }
  }, {
    key: "getConstrainFunction",
    value: function t() {
      return function (t, r, e, n) {
        var a = t.x,
          i = t.y;
        if (r._jsPlumbParentGroup && r._jsPlumbParentGroup.constrain) {
          a = Math.max(t.x, 0);
          i = Math.max(t.y, 0);
          a = Math.min(a, e.w - n.w);
          i = Math.min(i, e.h - n.h);
        }
        return {
          x: a,
          y: i
        };
      };
    }
  }]);
  return d;
}(o);
export { c as GroupDragHandler };