<naris-toolbar color="blue-gradient" sticky="top">
  <naris-toolbar-item>
    @if (!!objectTitle) {
      <h4>{{ objectTitle }}</h4>
    }
  </naris-toolbar-item>
  <naris-toolbar-item class="ml-auto">
    <naris-button (clicked)="close()" color="primary" icon="close" class="close-button" id="InfoDialogClose"/>
  </naris-toolbar-item>
</naris-toolbar>

@if (data.type === 'form') {
  <app-form 
    [endpoint]="data.endpoint" 
    [isEmbedded]="true" 
    [isEmbeddedInModal]="true"
    (titleChanged)="setTitle($event)" 
    (closed)="close($event)"
    [showClearButton]="!data.isWizardClosing"
    [isArchimate]="data.isArchimate"
  />
} @else {
  <naris-list
    [dataUrl]="data.endpoint" 
    [isEmbedded]="true" 
    (title)="setTitle($event)"
    class="auto-overflow"
  />
}
