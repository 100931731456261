<div class="wrapper" [attr.data-test-id]="'checked-tree__' + control.id">
  @if (!!treeValues.length) {
    <div class="checked-tree" [class.disabled]="disabled">
      @for (classification of treeValues; track classification.id) {
        <div class="checked-tree__classification" [@fadeInOutAnimation]>
          <div class="node">
            <naris-icon icon="keyboard_arrow_down" iconClass="small" />
            {{classification.label}}
          </div>
          @for (aspect of classification.children; track aspect.id) {
            <div class="checked-tree__classification__aspect ml-2" [@fadeInOutAnimation]>
              <div class="node">
                <naris-icon icon="keyboard_arrow_down" iconClass="small" />
                {{aspect.label}}
              </div>
              @for (aspectClass of aspect.children; track aspectClass.id) {
                <div class="checked-tree__classification__aspect_class ml-2" [@fadeInOutAnimation] [class.disabled]="disabled">
                  @if (!!aspectClass.color) {
                    <div
                      class="checked-tree__classification__aspect_class__color"
                      [style.backgroundColor]="aspectClass.color"
                      [matTooltip]="aspectClass.color || ''"
                      matTooltipPosition="left"
                    ></div>
                  }
                  <div class="checked-tree__classification__aspect_class__label">{{aspectClass.label}}</div>
                  @if (!disabled) {
                    <div class="checked-tree__classification__aspect_class__remove"><naris-icon icon="cancel" iconClass="small" (click)="removeItem(aspectClass.id)" /></div>
                  }
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
  }
  
  @if (!treeValues.length) {
    <div class="empty">
      {{'autocomplete.empty_selection' | translate}}
    </div>
  }

  @if (!disabled) {
    <naris-button
      class="lookup-button"
      icon="search"
      type="button"
      [matTooltip]="'input.advanced' | translate"
      matTooltipPosition="above"
      (clicked)="openAdvancedLookup()"
      id="CheckedTreeAdvanced"
    />
  }
</div>
