import { Component, Input, HostListener, ViewEncapsulation, HostBinding, Output, EventEmitter } from '@angular/core';
import { FormService, DialogService } from '@app/core/services';
import { INarisDrawer } from '@app/core/models';
import { BehaviorSubject } from 'rxjs';
import { fadeInOutAnimation } from '@shared/animations/core.animations';
import { generateGUID } from '@core/helpers';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'naris-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInOutAnimation],
  standalone: true,
  imports: [AsyncPipe]
})
export class OverlayComponent {

  @Input() public closeOnEscape = true;
  @Input() public closeOnClickOutside = true;
  @Input() public showBackdrop = true;
  @Input() public opened = new BehaviorSubject(false);
  @Input() public drawer: INarisDrawer;
  @Output() public readonly changed = new EventEmitter<any>();
  @Output() public readonly closed = new EventEmitter<any>();

  private modalActive = false;
  public data: any;

  constructor(
    private readonly formService: FormService,
    private readonly dialogService: DialogService
  ) { }

  public open(data?: any): void {
    this.data = data;
    this.opened.next(true);
    this.changed.emit(true);
  }

  public close(): void {
    if (this.drawer) {
      const drawerIndex = this.formService.drawers.findIndex((d: INarisDrawer) => d.id === this.drawer.id);
      if (drawerIndex === this.formService.drawers.length - 1) {
        if (this.drawer.hasChanged) {
          if (this.drawer.type === 'form' && this.drawer.saved.closed) {
            this.modalActive = true;
            this.dialogService.open({
              type: 'alert',
              title: 'are_you_sure',
              text: 'overlay.discard_message',
              confirmLabel: 'discard',
              confirmColor: 'primary',
              cancelLabel: 'cancel'
            }).subscribe((confirmed: boolean) => {
              this.modalActive = false;
              if (confirmed) {
                this.opened.next(false);
              }
            });
          } else {
            this.formService.saveForm(this.drawer.id, true);
            this.closed.next(generateGUID());
            this.opened.next(false);
          }
        } else {
          this.closed.next(generateGUID());
          this.opened.next(false);
        }
      }
    } else {
      this.closed.next(generateGUID());
      this.opened.next(false);
    }
    this.changed.emit(false);
  }

  @HostListener('window:keyup', ['$event'])
  public keyEvent(event: KeyboardEvent) {
    if (this.opened && this.closeOnEscape && event.code === 'Escape' && !this.modalActive) {
      this.close();
    }
  }

  @HostBinding('class')
  get rootClasses() {
    const classes = ['naris-overlay'];
    if (this.opened) classes.push('naris-overlay--opened');
    return classes;
  }

  public backdropClicked(): void {
    this.close();
  }
}
