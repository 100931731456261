import { CdkDragEnd, CdkDragMove, CdkDrag } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { colorAtPos, posOfColorY } from '@core/helpers/color.helper';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'naris-color-slider',
  templateUrl: './color-slider.component.html',
  styleUrls: ['./color-slider.component.scss'],
  standalone: true,
  imports: [NgStyle, CdkDrag]
})
export class ColorSliderComponent implements AfterViewInit, OnChanges {

  @ViewChild('canvas') public canvas: ElementRef<HTMLCanvasElement>;
  @Output() public readonly color = new EventEmitter<string>();
  @Input() public height = 256;
  @Input() public chosenHue: string;
  public dragPos = {x: 0, y: 128};
  public dragging = false;
  public currentColor: string;
  private ctx: CanvasRenderingContext2D | null;

  public ngOnChanges(changes: SimpleChanges): void {
    if (!!this.canvas && !!changes.chosenHue && changes.chosenHue.currentValue !== changes.chosenHue.previousValue) {
      const {closestColor, colorPos} = posOfColorY(this.ctx, this.canvas.nativeElement, changes.chosenHue.currentValue);
      this.dragPos = colorPos;
      setTimeout(() => this.color.emit(closestColor), 0);
    }
  }

  public ngAfterViewInit(): void {
    this.draw();
    if (!this.chosenHue) setTimeout(() => this.emitColor(2, this.height / 2), 0);
    else {
      const {closestColor, colorPos} = posOfColorY(this.ctx, this.canvas.nativeElement, this.chosenHue);
      this.dragPos = colorPos;
      setTimeout(() => this.color.emit(closestColor), 0);
    }
  }

  public onClick(evt: MouseEvent): void {
    this.dragPos = {x: 0, y: evt.offsetY};
    this.emitColor(this.dragPos.x, this.dragPos.y);
  }

  private draw(): void {
    const canvas = this.canvas.nativeElement;
    this.ctx ??= canvas.getContext('2d');
    if (!this.ctx) return;
    this.ctx.clearRect(0, 0, canvas.width, canvas.height);
    this.ctx.beginPath();
    this.ctx.rect(0, 0, canvas.width, canvas.height);
    this.ctx.fillStyle = this.createGradient(this.ctx, canvas.height)!;
    this.ctx.fill();
    this.ctx.closePath();
  }

  public onDragMoved(evt: CdkDragMove): void {
    const pos = evt.source.getFreeDragPosition();
    this.emitColor(0, pos.y);
  }

  public onDragEnded(evt: CdkDragEnd): void {
    const pos = evt.source.getFreeDragPosition();
    this.dragPos = {x: 0, y: pos.y};
  }

  private createGradient(ctx: CanvasRenderingContext2D | null, height: number) {
    const gradient = ctx?.createLinearGradient(0, 0, 1, height);
    if (!gradient) return;
    gradient.addColorStop(0, '#f00');
    gradient.addColorStop(0.17, '#ff0');
    gradient.addColorStop(0.34, '#0f0');
    gradient.addColorStop(0.51, '#0ff');
    gradient.addColorStop(0.68, '#00f');
    gradient.addColorStop(0.85, '#f0f');
    gradient.addColorStop(1, '#f00');
    return gradient;
  }

  private emitColor(x: number, y: number): void {
    this.currentColor = colorAtPos(this.ctx, x, y, 'hex');
    this.color.emit(this.currentColor);
  }
}
