import { Injectable } from '@angular/core';

export const WS_ENDPOINT = 'ws://localhost:4202/';

export interface Message {
  source: string;
  content: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuditViewersService {

  public currentRoomUsers: Record<string, any>[] | undefined;
  
  private _numberOfViewers = 0;
  private _updateNumOfViewers = false;

  set numberOfViewers(value: number) {
    this._numberOfViewers = value;
    this._updateNumOfViewers = true;

    setTimeout(() => {
      this._updateNumOfViewers = false;
    }, 1000);
  }

  get numberOfViewers(): number {
    return this._numberOfViewers;
  }

  get updateNumOfViewers(): boolean {
    return this._updateNumOfViewers;
  }

}
  

