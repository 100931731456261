import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public loading = false;
  public message?: string | null;

  /**
   * Open the loader
   * @param message Optional ID that will be translated trough translation service
   */
  public open(message?: string) {
    this.message = message;
    this.loading = true;
  }

  public close(): void {
    this.message = null;
    this.loading = false;
  }
}
