@if(!!causes && !!causes.names?.length) {
  <div class="item">
    <div class="item__label">{{causes.label}}</div>
    @for (name of causes.names; track $index) {
      <div class="item__name">
        {{name}}
      </div>
    }
  </div>
}

@if(!!consequences && !!consequences.names?.length) {
  <div class="item">
    <div class="item__label">{{consequences.label}}</div>
    @for (name of consequences.names; track $index) {
      <div class="item__name">
        {{name}}
      </div>
    }
  </div>
}
