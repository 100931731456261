import { objectsToShowOnClick, STATUS_CONFIG } from '@core/constants';
import { ICaseListResult, ITreeViewItem } from '@core/models';

export const transformResultsToItems = (popupDataToShow: string, opened: boolean, results: Record<string, any>[] | undefined, iconColumnName: string | null, attributes: Record<string, any>[]): any => results?.map((i: any) => {
  // Check if the item is already opened
  const isOpened = opened || results?.find((s: Record<string, any>) => s.id === i._id)?.opened;
  const stateLabel = i.statusOptions?.find((option: Record<string, any>) => option.code === i.Status)?.label ?? i.Status;
  const stateColor = STATUS_CONFIG[i.Status]?.color;
  const scoreStatusLabel = i.statusOptions?.find((option: Record<string, any>) => option.code === i.ComplianceStructureScoreResult)?.label ?? i.ComplianceStructureScoreResult;
  const scoreStatusColor = STATUS_CONFIG[scoreStatusLabel]?.color;
  const options = attributes.find(attr => Object.keys(attr)[0] === 'StructureType')?.['StructureType']?.options;
  const typeLabel = options?.find((option: { code: string; label: string }) => option.code === i.StructureType)?.label || i.StructureType?.replace(/[a-zA-Z]*StructureItem_/, '');
  // Map attributes to item
  let name = i.Name;
  if (!!i.Position && i.ProcessStepType === 'Condition') {
    const posObject = JSON.parse(i.Position);
    name = !!posObject?.name ? posObject.name : name;
  }
  const procesStepTypeContributionOption = attributes.find(attr => Object.keys(attr)?.[0] === 'ProcessStepType')?.ProcessStepType?.options?.find((option: any) => option.code === i.ProcessStepType);
  return {
    id: i._id.toString(),
    referenceId: i.ReferenceID,
    name: name,
    type: i.StructureType?.replace(/[a-zA-Z]*StructureItem_/, ''),
    typeLabel: !!i.ProcessStepType ? procesStepTypeContributionOption?.label : typeLabel,
    event: i.EventName,
    parentId: +i.ParentID,
    actions: { actions: i.actions },
    opened: isOpened,
    href: i.Href ?? i._links?.self.href,
    active: false,
    status: stateColor,
    statusLabel: stateLabel,
    extraItems: i[popupDataToShow],
    _links: objectsToShowOnClick.includes(i.objectType.toLowerCase()) ? i._links : null,
    testType: i.ControlTestType,
    keyItem: i.KeyItem,
    scoreResult: i.ComplianceStructureScoreResult,
    scoreId: i.ComplianceStructureScoreID?.ScoringScaleRankName,
    scoreColor: i.Status === 'Not_scored' ? '' : i.ComplianceStructureScoreID?.ScoringScaleRankHEXColorCode,
    scoreStatus: scoreStatusColor,
    scoreStatusLabel: scoreStatusLabel,
    isEnabled: i.IsEnabled ?? true,
    available: iconColumnName ? i[iconColumnName] : [],
    contextName: i.ContextName,
    contextType: i.ContextType,
    hasChildren: i.HasChildren,
    description: i.Description,
    processStepType: i.ProcessStepType,
    archive: i.Archive,
    involvedType: i.InvolvedType
  } as ITreeViewItem;
})
  // Order by type to show steps on top
  .sort((a, b) => a.type < b.type ? -1 : 0);

export const getIconColumnName = (res: ICaseListResult): string | null => {
  const objName = Object.keys(res.contributions.results)[0];
  const attributes = res.contributions.results[objName].attributes;
  
  const foundIconColumn = attributes.find(attr => {
    const colName = Object.keys(attr)[0];
    return attr[colName].layouthint?.includes('icon-column');
  });
  
  const iconColName = !!foundIconColumn ? Object.keys(foundIconColumn)[0] : null;

  return iconColName;
};