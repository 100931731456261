@if (showLabel) {
  <h4 class="label">
    {{ taskgroup.label }}
  </h4>
}
@if (buttons?.length) {
  @for (action of buttons; track $index) {
    <naris-button
      [color]="buttonColor(action)"
      [icon]="buttonIcon(action)"
      [disabled]="!action.href || (action.disabled || false) || (action.layouthint?.includes('readonly') || false)"
      [size]="buttonSize"
      [class]="buttonClass"
      (clicked)="onActionClicked(action)"
      [class.w100]="fullwidthButtons"
      [fullwidth]="fullwidthButtons"
      [loading]="loading"
      [margin]="false"
      [matTooltip]="!!buttonIcon(action) ? action.label || '' : ''"
      [id]="'Taskgroup' + action.name"
    >
      @if (!buttonIcon(action) && !!action.label) {
        <span>{{ action.label | translate }}</span>
      }
    </naris-button>
  }
}
@if (menu?.length) {
  <naris-button
    icon="more"
    color="transparent"
    matTooltip="Actions"
    [size]="buttonSize"
    [class]="buttonClass"
    (click)="$event.stopPropagation()"
    [matMenuTriggerFor]="actionsMenu"
    id="TaskgroupActions"
  />
  <mat-menu #actionsMenu="matMenu">
    <naris-menu>
      @for (action of menu; track $index) {
        <naris-menu-item (clicked)="onActionClicked(action)">
          {{ action.label }}
        </naris-menu-item>
      }
    </naris-menu>
  </mat-menu>
}
