import { CLASS_DASHED_EDGE, PROPERTY_LINE_STYLE } from '@core/constants/jsplumb-base-constants';
import { ArrowOverlay, EdgeDefinition, PropertyMapping } from '@jsplumbtoolkit/browser-ui';

export default function edgeMappings() {

  return [{
    property: PROPERTY_LINE_STYLE,
    mappings: {
      ['Composition']: {
        overlays: [
          {
            type: ArrowOverlay.type,
            options: {
              location: 1,
              foldback: 2,
              width: 15,
              length: 12,
              paintStyle: {
                stroke: '#000'
              }
            }
          }
        ],
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      },
      ['Aggregation']: {
        overlays: [
          {
            type: ArrowOverlay.type,
            options: {
              location: 1,
              foldback: 2,
              width: 15,
              length: 12,
              paintStyle: {
                stroke: '#000',
                fill: '#fff'
              }
            }
          }
        ],
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      },
      ['Assignment']: {
        overlays: [
          {
            type: ArrowOverlay.type,
            options: {
              location: 1,
              foldback: 1,
              width: 15,
              length: 15,
              paintStyle: {
                stroke: '#000'
              }
            }
          }
        ],
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      },
      ['Realization']: {
        overlays: [
          {
            type: ArrowOverlay.type,
            options: {
              location: 1,
              foldback: 1,
              width: 15,
              length: 15,
              paintStyle: {
                stroke: '#000',
                fill: '#fff'
              }
            }
          }
        ],
        cssClass: CLASS_DASHED_EDGE,
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      },
      ['Serving']: {
        overlays: [
          {
            type: ArrowOverlay.type,
            options: {
              location: 1,
              foldback: 0.1,
              width: 15,
              length: 15,
              paintStyle: {
                stroke: '#000'
              }
            }
          }
        ],
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      },
      ['Access']: {
        overlays: [
          {
            type: ArrowOverlay.type,
            options: {
              location: 1,
              foldback: 0.3,
              width: 15,
              length: 15,
              paintStyle: {
                stroke: '#000',
                strokeWidth: 2
              }
            }
          }
        ],
        cssClass: CLASS_DASHED_EDGE,
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      },
      ['Influence']: {
        overlays: [
          {
            type: ArrowOverlay.type,
            options: {
              location: 1,
              width: 15,
              length: 15,
              paintStyle: {
                stroke: '#000'
              }
            }
          }
        ],
        cssClass: CLASS_DASHED_EDGE,
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      },
      ['Association']: {
        overlays: [
          {
            type: ArrowOverlay.type,
            options: {
              foldback: 1,
              width: 10,
              length: 10,
              paintStyle: {
                stroke: '#000'
              }
            }
          }
        ],
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      },
      ['Triggering']: {
        overlays: [
          {
            type: ArrowOverlay.type,
            options: {
              location: 1,
              foldback: 1,
              width: 10,
              length: 10,
              paintStyle: {
                stroke: '#000'
              }
            }
          }
        ],
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      },
      ['Flow']: {
        overlays: [
          {
            type: ArrowOverlay.type,
            options: {
              location: 1,
              foldback: 1,
              width: 15,
              length: 15,
              paintStyle: {
                stroke: '#000'
              }
            }
          }
        ],
        cssClass: CLASS_DASHED_EDGE,
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      },
      ['Specialization']: {
        overlays: [
          {
            type: ArrowOverlay.type,
            options: {
              location: 1,
              foldback: 1,
              width: 15,
              length: 15,
              paintStyle: {
                stroke: '#000',
                fill: '#fff'
              }
            }
          }
        ],
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      },
      ['Composition_Junction']: {
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      },
      ['Aggregation_Junction']: {
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      },
      ['Assignment_Junction']: {
        overlays: [
          {
            type: 'Custom',
            options: {
              create: () => {
                const div = document.createElement('div');
                div.className = 'archimate-assignment-overlay';
                div.style.borderRadius = '100%';
                div.style.width = '13px';
                div.style.height = '13px';
                div.style.backgroundColor = '#000';
                return div;
              },
              location: 0
            },
          }
        ],
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      },
      ['Realization_Junction']: {
        cssClass: CLASS_DASHED_EDGE,
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      },
      ['Serving_Junction']: {
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      },
      ['Access_Junction']: {
        cssClass: CLASS_DASHED_EDGE,
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      },
      ['Influence_Junction']: {
        cssClass: CLASS_DASHED_EDGE,
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      },
      ['Association_Junction']: {
        overlays: [
          {
            type: ArrowOverlay.type,
            options: {
              foldback: 1,
              width: 10,
              length: 10,
              paintStyle: {
                stroke: '#000'
              }
            }
          }
        ],
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      },
      ['Triggering_Junction']: {
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      },
      ['Flow_Junction']: {
        cssClass: CLASS_DASHED_EDGE,
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      },
      ['Specialization_Junction']: {
        paintStyle: {
          stroke: '#000',
          outlineWidth: 15,
          outlineStroke: 'rgba(0, 0, 0, 0)'
        }
      }
    }
  } as PropertyMapping<EdgeDefinition>];

}