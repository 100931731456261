import { createClass as e, classCallCheck as t, defineProperty as r, slicedToArray as n } from "../_virtual/_rollupPluginBabelHelpers.js";
import { ABSOLUTE as o, NONE as i } from "../ui-core/core/constants.js";
import { LEFT as s, RIGHT as c, TOP as a, BOTTOM as u } from "../ui-core/core/factory/definitions.js";
import { rotateAnchorOrientation as l, rotatePoint as h, extend as d, uuid as v } from "../ui-core/util/util.js";
import { EventManager as f } from "../ui-core/browser-ui-renderer/event-manager.js";
import { EVENT_GRAPH_CLEAR_START as g, EVENT_EDGE_REMOVED as m, EVENT_EDGE_PATH_RESTORED as p, EVENT_NODE_MOVE_END as y, EVENT_NODE_MOVE as x, EVENT_GROUP_MOVE_END as _, EVENT_GROUP_MOVE as A, EVENT_NODE_UPDATED as E, EVENT_GROUP_UPDATED as P } from "../core/constants.js";
import { isContinuous as b } from "../ui-core/core/router/lightweight-router.js";
import { LabelOverlay as j } from "../ui-core/core/overlay/label-overlay.js";
import { createFinderFromAnchorPositions as C } from "../browser-ui/browser-ui-model.js";
import { findClosestPoint as k } from "../core/util.js";
import { getOffsetSize as D } from "../ui-core/browser-ui-renderer/positioning-and-sizing-utils.js";
import { Connectors as w, _resetConnectorGeometry as F } from "../ui-core/core/connector/connectors.js";
import { _removeOverlay as I, Connections as O } from "../ui-core/core/connector/connections.js";
var N = "px";
var L = "jtk-anchor-face";
var M = "jtk-anchor-placeholder";
var S = "jtk-anchor-candidate";
var H = "jtk-edge-delete";
var T = "jtk-connection-edit";
var z = "clearConnectionEdits";
var B = "startConnectionEdit";
var V = "stopConnectionEdit";
var R = "zoom";
function X(e) {
  return "." + e;
}
var Y = X(M);
function G(e, t, r, n) {
  var s = document.createElement("div");
  s.className = r;
  s.style.position = o;
  s.style.left = e + N;
  s.style.top = t + N;
  if (!n) {
    s.style.display = i;
  }
  return s;
}
function q(e, t, r, n, o) {
  var i = G(e, t, n, o);
  r._appendElement(i, r.getContainer());
  var s = r.getSize(i);
  i.style.left = e - s.w / 2 + "px";
  i.style.top = t - s.h / 2 + "px";
  return i;
}
function W(e, t, r, n, o, i, s, c) {
  var a = t(r, n, o);
  if (a != null) {
    var u = true;
    var l = {
      x: a[0],
      y: a[1],
      ox: a[2],
      oy: a[3],
      iox: a[2],
      ioy: a[3],
      offx: a[4] || 0,
      offy: a[5] || 0,
      cls: ""
    };
    if (e.currentLocation != null && e.locations[e.currentLocation] != null) {
      u = e.locations[e.currentLocation].x !== l.x || e.locations[e.currentLocation].y !== l.y;
    }
    if (u) {
      e.currentLocation = -1;
      e.locations[0] = l;
      c.router._setCurrentLocation(e, 0, s);
    }
    return {
      x: l.x * i.w,
      y: l.y * i.h
    };
  }
}
function J(e, t, r, n, o, i, h, d, v) {
  var f = e.faces;
  var g = n.parentNode;
  var m = [[s, r.x], [c, o.w - r.x], [a, r.y], [u, o.h - r.y]];
  var p = new Map();
  p.set(a, [0, -1]);
  p.set(u, [0, 1]);
  p.set(s, [-1, 0]);
  p.set(c, [1, 0]);
  m.sort(function (e, t) {
    if (e[1] < t[1]) {
      return -1;
    } else {
      return 1;
    }
  });
  var y = null;
  for (var x = 0; x < m.length; x++) {
    if (f.indexOf(m[x][0]) != -1) {
      y = m[x][0];
      break;
    }
  }
  if (y != null && y !== e.lockedFace) {
    g.setAttribute(L, y);
    v.router._setCurrentContinuousAnchorFace(e, y, d, h);
    v.router.lock(e);
    var _ = p.get(y);
    var A = v._getRotation(t.elementId);
    if (A !== 0) {
      _ = l(_, A);
    }
    w.setAnchorOrientation(d, h, _);
  }
  return r;
}
function K(e, t, r, n, o, i, s) {
  var c = k(r, o, e.locations);
  s.router._setCurrentLocation(e, c.idx, i);
  return r;
}
function Q(e, t, r) {
  var n = document.createElement("div");
  n.className = r ? r : "";
  n.style.position = o;
  n.style.left = e + N;
  n.style.top = t + N;
  return n;
}
function U(e, t, r, n) {
  var o = D(e);
  var i = o.w * t,
    s = o.h * r;
  var c = Q(i, s, n);
  e.appendChild(c);
  var a = D(c);
  c.style.left = i - a.w / 2 + N;
  c.style.top = s - a.h / 2 + N;
  return c;
}
var Z = "jtk-edge-handle";
var $ = function () {
  function o(e, n) {
    var i = this;
    t(this, o);
    this.surface = e;
    r(this, "current", void 0);
    r(this, "currentConnector", void 0);
    r(this, "currentEdge", void 0);
    r(this, "currentSourceVertex", void 0);
    r(this, "currentTargetVertex", void 0);
    r(this, "sourceInfo", void 0);
    r(this, "targetInfo", void 0);
    r(this, "currentOverlays", []);
    r(this, "sourceDimensions", void 0);
    r(this, "targetDimensions", void 0);
    r(this, "sourceAnchorPlaceholder", void 0);
    r(this, "targetAnchorPlaceholder", void 0);
    r(this, "active", void 0);
    r(this, "jsplumb", void 0);
    r(this, "sourceAnchorPositionFinder", void 0);
    r(this, "targetAnchorPositionFinder", void 0);
    r(this, "snapToAnchors", void 0);
    r(this, "eventManager", void 0);
    r(this, "update", void 0);
    r(this, "cleanup", void 0);
    r(this, "_dragHandlers", {});
    r(this, "_anchorDragContext", {
      rotation: 0,
      center: {
        x: 0,
        y: 0
      }
    });
    this.active = false;
    this.jsplumb = this.surface.jsplumb;
    this.surface.toolkitInstance.bind(g, function () {
      i.deactivate();
    });
    this.surface.toolkitInstance.bind(m, function (e) {
      if (e.edge === i.currentEdge) {
        i.deactivate();
      }
    });
    this.surface.toolkitInstance.bind(p, function (e) {
      if (e.edge == i.currentEdge) {
        i.repaintConnection();
      }
    });
    this.eventManager = new f();
    this._addDragHandler({
      selector: Y,
      constrainFunction: function e(t, r, n, o) {
        var s = r._jsPlumbAnchor.anchor;
        var c = r._jsPlumbAnchor.endpoint;
        var a = r._jsPlumbAnchor.idx;
        if (i._anchorDragContext.rotation !== 0) {
          var u = i.jsplumb.dragManager.drag._posAtDown;
          var l = {
            x: t.x - u.x,
            y: t.y - u.y
          };
          t = h(l, i._anchorDragContext.center, -i._anchorDragContext.rotation);
        }
        var d = r.parentNode;
        var v;
        var f = r.parentNode.jtk;
        if (f != null) {
          v = f.port || f.group || f.node || f.vertex;
        }
        var g = i.currentEdge.source === v ? i.sourceAnchorPositionFinder : i.targetAnchorPositionFinder;
        var m = {
          x: t.x / n.w,
          y: t.y / n.h
        };
        if (g != null) {
          t = W(s, g, d, m, v, n, i.current.connector, i.jsplumb);
        } else if (b(s)) {
          t = J(s, c, t, r, n, o, a, i.current.connector, i.jsplumb);
        } else {
          t = K(s, c, t, r, n, i.current.connector, i.jsplumb);
          if (i.snapToAnchors) {
            t = {
              x: s.locations[s.currentLocation].x * n.w,
              y: s.locations[s.currentLocation].y * n.h
            };
          }
        }
        i.jsplumb.revalidate(r.parentNode);
        var p = [r.offsetWidth, r.offsetHeight],
          y = {
            xmin: -p[0] / 2,
            xmax: n.w - p[0] / 2,
            ymin: -p[1] / 2,
            ymax: n.h - p[1] / 2
          };
        return {
          x: t.x < y.xmin ? y.xmin : t.x > y.xmax ? y.xmax : t.x + y.xmin,
          y: t.y < y.ymin ? y.ymin : t.y > y.ymax ? y.ymax : t.y + y.ymin
        };
      },
      start: function e(t) {
        var r = t.drag.getDragElement();
        var n = r._jsPlumbAnchor.anchor;
        var o = r._jsPlumbAnchor.endpoint;
        var s = r.parentNode;
        var c;
        var a = r.parentNode.jtk;
        if (a != null) {
          c = a.port || a.group || a.node || a.vertex;
        }
        var u = i.currentEdge.source === c ? i.sourceAnchorPositionFinder : i.targetAnchorPositionFinder;
        var l = i.jsplumb.getId(s);
        i._anchorDragContext.rotation = i.jsplumb._getRotation(l);
        if (i._anchorDragContext.rotation !== 0) {
          var h = i.jsplumb.getSize(s);
          i._anchorDragContext.center = {
            x: h.w / 2,
            y: h.h / 2
          };
        }
        if (u == null) {
          if (b(n)) {
            r.parentNode.setAttribute(L, n.currentFace);
          } else {
            var d = n.locations.map(function (e) {
              return [e.x, e.y, e.ox, e.oy];
            });
            d.forEach(function (e) {
              U(o.element, e[0], e[1], S);
            });
          }
        }
      },
      stop: function e(t) {
        var r = t.el.parentNode;
        var n = t.drag.getDragElement();
        var o = n._jsPlumbAnchor.anchor;
        var s = n._jsPlumbAnchor.endpoint;
        if (b(o)) {
          r.removeAttribute(L);
        }
        var c = s.element.querySelectorAll(X(S));
        c.forEach(function (e) {
          return e.parentNode.removeChild(e);
        });
        i._drawAnchorPlaceholders();
        i._update();
        i.fireConnectionEditEvent();
      },
      drag: function e(t) {
        i._update();
      }
    }, true);
    this.surface.bind(y, function (e) {
      if (i.active && i.currentEdge && (e.vertex === i.currentEdge.source || e.vertex === i.currentEdge.target)) {
        i._elementDragged(e);
        i.fireConnectionEditEvent();
      }
    });
    this.surface.bind(x, function (e) {
      if (i.active && i.currentEdge && (e.vertex === i.currentEdge.source || e.vertex === i.currentEdge.target)) {
        i._elementDragging(e);
      }
    });
    this.surface.bind(_, function (e) {
      if (i.active && i.currentEdge && (e.vertex === i.currentEdge.source || e.vertex === i.currentEdge.target)) {
        i._elementDragged(e);
        i.fireConnectionEditEvent();
      }
    });
    this.surface.bind(A, function (e) {
      if (i.active && i.currentEdge && (e.vertex === i.currentEdge.source || e.vertex === i.currentEdge.target)) {
        i._elementDragging(e);
      }
    });
    this.surface.toolkitInstance.bind(E, function (e) {
      if (i.active && i.currentEdge && (e.vertex === i.currentEdge.source || e.vertex === i.currentEdge.target)) {
        i._elementDragged(e);
        i.fireConnectionEditEvent();
      }
    });
    this.surface.toolkitInstance.bind(P, function (e) {
      if (i.active && i.currentEdge && (e.vertex === i.currentEdge.source || e.vertex === i.currentEdge.target)) {
        i._elementDragged(e);
        i.fireConnectionEditEvent();
      }
    });
  }
  e(o, [{
    key: "_resolveAnchorPositions",
    value: function e(t) {
      var r = this.surface.view.getTypeDefinition(t);
      return r.anchorPositions;
    }
  }, {
    key: "_attachOverlay",
    value: function e(t, r) {
      var n = {
        type: r.type,
        options: d(r.options, {
          id: v()
        })
      };
      this.currentOverlays.push(this.surface.jsplumb.addOverlay(t, n));
      this.repaintConnection();
    }
  }, {
    key: "_attachOverlays",
    value: function e(t, r) {
      var n = this;
      this._detachOverlays();
      r.forEach(function (e) {
        n._attachOverlay(t, e);
      });
    }
  }, {
    key: "_detachOverlays",
    value: function e() {
      var t = this;
      this.currentOverlays.forEach(function (e) {
        I(t.current, e.id);
      });
    }
  }, {
    key: "_attachDeleteButton",
    value: function e(t) {
      var r = this;
      var n = t.deleteButtonClass || H;
      var o = t.deleteButtonLocation || .1;
      var i = function e() {
        return r.surface.toolkitInstance.removeEdge(r.currentEdge);
      };
      var s = function e() {
        if (t.onMaybeDelete) {
          t.onMaybeDelete(r.currentEdge, r.current, i);
        } else {
          i();
        }
      };
      if (!Array.isArray(o)) {
        o = [o];
      }
      o.forEach(function (e) {
        r._attachOverlay(r.current, {
          type: j.type,
          options: {
            location: e,
            cssClass: n,
            events: {
              click: s
            }
          }
        });
      });
    }
  }, {
    key: "repaintConnection",
    value: function e(t) {
      if (this.current) {
        this.jsplumb._paintConnection(this.current, t);
        this.refresh(t);
      }
    }
  }, {
    key: "fireConnectionEditEvent",
    value: function e() {
      this.surface._connectionEdited(this.current);
    }
  }, {
    key: "refresh",
    value: function e(t) {
      if (this.current) {
        this._drawAnchorPlaceholders();
        this._repaint && this._repaint(t);
      }
    }
  }, {
    key: "_drawAnchorPlaceholders",
    value: function e() {
      var t = this;
      if (this.current) {
        var r = function e(r, n) {
          var o = t.current.endpoints[r];
          var i = o._anchor;
          var s = r === 0 ? t.sourceAnchorPositionFinder : t.targetAnchorPositionFinder;
          if (s != null || i.isDynamic || i.isContinuous) {
            var c = d({}, i.computedPosition);
            var a = t.jsplumb.getOffset(o.element);
            var u = t.jsplumb._getRotation(o.elementId);
            if (u !== 0) {
              var l = t.jsplumb.getSize(o.element),
                v = {
                  x: a.x + l.w / 2,
                  y: a.y + l.h / 2
                },
                f = h({
                  x: c.curX,
                  y: c.curY
                }, v, -u);
              c.curX = f.x;
              c.curY = f.y;
            }
            var g = [c.curX - a.x, c.curY - a.y],
              m = g[0],
              p = g[1];
            var y;
            if (n != null) {
              y = n;
            } else {
              y = Q(m, p, M);
              o.element.appendChild(y);
              t._setElementPosition(y, m, p);
              y._jsPlumbAnchor = {
                anchor: i,
                endpoint: o,
                idx: r
              };
            }
            var x = t.jsplumb.getSize(y);
            y.style.left = m - x.w / 2 + N;
            y.style.top = p - x.h / 2 + N;
            return [[a.x, a.y], y];
          } else {
            return [[0, 0], null];
          }
        };
        var o = r(0, this.sourceAnchorPlaceholder);
        var i = n(o, 2);
        this.sourceDimensions = i[0];
        this.sourceAnchorPlaceholder = i[1];
        var s = r(1, this.targetAnchorPlaceholder);
        var c = n(s, 2);
        this.targetDimensions = c[0];
        this.targetAnchorPlaceholder = c[1];
      }
    }
  }, {
    key: "_cleanupAnchors",
    value: function e() {
      if (this.current) {
        if (this.sourceAnchorPlaceholder) {
          this.sourceAnchorPlaceholder.parentNode.removeChild(this.sourceAnchorPlaceholder);
          delete this.sourceAnchorPlaceholder._jsPlumbAnchor;
        }
        if (this.targetAnchorPlaceholder) {
          this.targetAnchorPlaceholder.parentNode.removeChild(this.targetAnchorPlaceholder);
          delete this.targetAnchorPlaceholder._jsPlumbAnchor;
        }
      }
      this.sourceAnchorPlaceholder = null;
      this.targetAnchorPlaceholder = null;
      this.sourceDimensions = null;
      this.targetDimensions = null;
    }
  }, {
    key: "_clearGeometry",
    value: function e() {
      if (this.current) {
        F(this.current.connector);
      }
    }
  }, {
    key: "reset",
    value: function e() {
      this.deactivate();
      this._clearGeometry();
      this._clearHandles();
      this.jsplumb.revalidate(this.current.source);
      this.jsplumb.revalidate(this.current.target);
      this.jsplumb.fire(z, this.current);
    }
  }, {
    key: "isActive",
    value: function e() {
      return this.active;
    }
  }, {
    key: "_setElementPosition",
    value: function e(t, r, n) {
      var o = this.jsplumb.getSize(t),
        i = r - o.w / 2,
        s = n - o.h / 2;
      t.style.left = i + N;
      t.style.top = s + N;
    }
  }, {
    key: "activate",
    value: function e(t, r, n) {
      if (this.current != null && this.current === r) {
        return;
      }
      this.deactivate();
      this.current = r;
      this.currentConnector = this.current.connector;
      this.currentEdge = t;
      this.currentSourceVertex = this.currentEdge.source;
      this.currentTargetVertex = this.currentEdge.target;
      this.sourceInfo = this.surface.jsplumb.viewport._elementMap.get(this.current.sourceId);
      this.targetInfo = this.surface.jsplumb.viewport._elementMap.get(this.current.targetId);
      n = n || {};
      this.sourceAnchorPositionFinder = n.anchorPositionFinder;
      this.targetAnchorPositionFinder = n.anchorPositionFinder;
      if (n.anchorPositions != null) {
        this.sourceAnchorPositionFinder = C(n.anchorPositions);
        this.targetAnchorPositionFinder = C(n.anchorPositions);
      } else {
        var o = this._resolveAnchorPositions(this.currentEdge.source);
        if (o != null) {
          this.sourceAnchorPositionFinder = C(o);
        }
        var i = this._resolveAnchorPositions(this.currentEdge.target);
        if (i != null) {
          this.targetAnchorPositionFinder = C(i);
        }
      }
      this.snapToAnchors = n.snapToAnchors !== false;
      this._activate(r, n);
      if (n.overlays) {
        this._attachOverlays(r, n.overlays);
      }
      if (n.deleteButton === true) {
        this._attachDeleteButton(n);
      }
      var s = this.current.endpoints[0];
      var c = s._anchor;
      var a = this.current.endpoints[1];
      var u = a._anchor;
      this.jsplumb.router.lock(c);
      this.jsplumb.router.lock(u);
      this._drawAnchorPlaceholders();
      O.addClass(this.current, T);
      this.active = true;
      this.jsplumb.fire(B, this.current);
    }
  }, {
    key: "deactivate",
    value: function e(t) {
      if (this.current != null) {
        this._detachOverlays();
        O.removeClass(this.current, T);
        this._cleanupAnchors();
      }
      this._clearHandles();
      if (this.current != null) {
        this.jsplumb.fire(V, this.current);
      }
      this.active = false;
      this.current = null;
      this.currentEdge = null;
      this.sourceAnchorPositionFinder = null;
      this.targetAnchorPositionFinder = null;
    }
  }, {
    key: "_addDragHandler",
    value: function e(t, r) {
      this.jsplumb.dragManager.addSelector(t, r);
      this._dragHandlers[t.selector] = t;
    }
  }]);
  return o;
}();
export { Y as ANCHOR_PLACEHOLDER_SELECTOR, L as ATTR_ANCHOR_FACE, S as CLASS_ANCHOR_CANDIDATE, M as CLASS_ANCHOR_PLACEHOLDER, T as CLASS_CONNECTION_EDIT, H as CLASS_EDGE_DELETE_BUTTON, Z as EDGE_DRAG_HANDLE, z as EVT_CLEAR_CONNECTION_EDITS, B as EVT_START_CONNECTION_EDIT, V as EVT_STOP_CONNECTION_EDIT, R as EVT_ZOOM, $ as EditorBase, N as PX, q as _makeAndAppendHandle, G as _makeHandle, J as relocateContinuousAnchor, K as relocateDynamicAnchor };