import { Component, ElementRef, Host, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { DateTime } from 'luxon';
import { OverlayComponent } from '@shared/components/overlay/overlay.component';
import { BeinformedService } from '@core/services';
import { FilterChip } from '@core/classes';
import { HighlightPipe } from '@shared/pipes/highlight.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressSpinnerComponent } from '../../../shared/elements/progress-spinner/progress-spinner.component';
import { ButtonComponent } from '../../../shared/elements/button/button.component';
import { IconComponent } from '../../../shared/elements/icon/icon.component';
import { EmptyStateComponent } from '../../../shared/components/empty-state/empty-state.component';
import { TableComponent } from '../../../shared/components/table/table.component';
import type { IMappedInput, IWidgetConfig } from '@core/models';

@Component({
  selector: 'app-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ProgressSpinnerComponent, ButtonComponent, IconComponent, EmptyStateComponent, TableComponent, HighlightPipe, TranslateModule]
})
export class QuickSearchComponent implements OnInit, OnDestroy {
  public query = new FormControl();
  public search = false;
  public results: any[] | null;
  public loading = false;
  public advanced = false;
  private readonly subs: Subscription[] = [];

  @ViewChild('input')
  public input: ElementRef;

  constructor(
    private readonly beinformedService: BeinformedService,
    private readonly router: Router,
    @Host() public parent: OverlayComponent
  ) {}

  public fetchResults(filter: string): void {
    const endpoint = `/configuration/search?pagesize=25&page=1&CaseNameOrID=${filter}`;
    this.beinformedService.fetchResponseWithContributions<'caselist'>(endpoint).subscribe(result => {
      const results = this.beinformedService.extractResults(result)?.map((i: any) => {
        i.objectType = i.objectType.replace(/([A-Z])/g, ' $1').replace(/^./, (str: string) => str.toUpperCase());
        return i;
      });
      this.results = results;
      this.loading = false;
    });
  }

  public onItemClicked(item: any) {
    this.parent.close();
    void this.router.navigate([item._links.self.href]);
    setTimeout(() => this.query.setValue(null), 500);
  }

  public clear(mouseEvent?: MouseEvent): void {
    mouseEvent?.stopPropagation();
    if (this.query.value) this.query.setValue(null);
    if (this.results) this.results = null;
    if (this.advanced) this.advanced = false;
    if (mouseEvent) this.input.nativeElement.focus();
  }

  public ngOnInit(): void {
    [
      this.query.valueChanges
        .pipe(tap(v => this.loading = !!v), debounceTime(200))
        .subscribe((v: any) => v ? this.fetchResults(v) : null),
      this.parent.opened
        .subscribe((opened: boolean) => opened ? setTimeout(() => this.input.nativeElement.focus(), 200) : this.clear())
    ].forEach((s: Subscription) => this.subs.push(s));
  }

  public ngOnDestroy(): void {
    this.subs.forEach((s: Subscription) => s.unsubscribe());
  }

  public ago(date: string) {
    const str = DateTime.fromISO(date).toRelative();
    return str;
  }

  get widgetConfig(): IWidgetConfig {
    const filterInput: IMappedInput = {id: 'CaseNameOrID', controlType: 'string', value: this.query.value, multiple: false};
    return {filters: [new FilterChip('Search term', this.query.value, this.query.value, filterInput)]};
  }
}
