import { KeyValue, NgTemplateOutlet, KeyValuePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { inOutAnimation } from '@shared/animations/core.animations';
import { TranslateModule } from '@ngx-translate/core';
import { CardComponent } from '../card/card.component';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { IconComponent } from '../../elements/icon/icon.component';

@Component({
  selector: 'naris-json-viewer',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['./json-viewer.component.scss'],
  animations: [inOutAnimation],
  standalone: true,
  imports: [CardComponent, ToolbarComponent, IconComponent, NgTemplateOutlet, KeyValuePipe, TranslateModule]
})
export class JsonViewerComponent {

  public headerExpanded: boolean;

  @Input() public data: any;
  @Input() public name: string;

  public originalOrder = (_: KeyValue<number, string>, __: KeyValue<number, string>): number => 0;
  public isArray(obj: any ) {
    return Array.isArray(obj);
  }
  public isObject(obj: any) {
    return typeof obj === 'object' && obj !== null && !this.isArray(obj);
  }
}
