<div class="form-input" [ngClass]="{'form-input--odd': oddInput}" [formGroup]="formGroup" #self *narisDependencyAttribute="let dependency of formGroup control input from step">
  <!-- Create action button -->
  @if (input.layouthint?.includes('create-action')) {
    <div class="create-action">
      <naris-button size="small" type="button" color="success" (clicked)="createAction(input)" [id]="formId + '__' + input.id">
        {{'form.create_new' | translate}}
      </naris-button>
    </div>
  }

  <!-- Static headings -->
  @if (input.controlType === 'heading' && !input.layouthint?.includes('create-action')) {
    <h4 class="heading">{{ input.label }}</h4>
  }

  <!-- Static helper text block -->
  @if (input.controlType === 'helperText') {
    @if (isNotify) {
      <div class="helper-text notify-text">
        <div class="notify-text-icon">
          <naris-icon [icon]="'error'" />
        </div>
        <div class="notify-text-content">
          <div [innerHtml]="input.text?.message"></div>
        </div>
      </div>
    } @else {
      <div class="helper-text" [innerHTML]="input.text?.message"></div>
    }
  }

  @if (input.controlType === 'warningText') {
    <div class="notify-text">
      <div class="notify-text-icon">
        <naris-icon [icon]="'error'" />
      </div>
      <div class="notify-text-content">
        <div [innerHtml]="input.text?.message"></div>
      </div>
    </div>
  }

  @if (!['helperText', 'warningText', 'heading'].includes(input.controlType) && !input.layouthint?.includes('hide') 
  && (input.controlType !== 'table' || (input.controlType === 'table' && !!input.tableData?.records?.length))) {
      <naris-form-field
        [label]="hidePreviousAssessment && input.controlType === 'table'? '' : input.label.endsWith('OverviewAction') ? ' ' : input.label"
        [labelSuffix]="input.label.endsWith('OverviewAction') ? '' : ((!input.layouthint?.includes('mandatory') && !getFormControlRequired(input.id) && input.controlType !== 'table' ? 'optional' :  '') | translate)"
        [error]="error"
        [style.width]="input.displaysize ? input.displaysize + '%' : 'auto'"
        [tooltip]="input.assistant"
        [isProcessToolkitForm]="isProcessToolkitForm"
      >
      @switch (input.controlType) {
        <!-- Text -->
        @case ('text') {
          @if (input.formatted) {
            <naris-ngx-text-editor
              [control]="getFormControl(input.id)"
              [mandatory]="input.mandatory"
              [id]="formId + '__' + input.id"
              docEnabled
            />
          }
          @if (input.label.endsWith('OverviewAction')) {
            <naris-overview-table [formGroup]="formGroup" [controlId]="input.id" />
          } @else if (!input.formatted && input.inputType === 'text' || input.inputType === 'number' || input.inputType === 'email') {
            <naris-input
              [control]="getFormControl(input.id)"
              [id]="formId + '__' + input.label"
              [placeholder]="input.placeholder!"
              [lookup]="input.lookup"
              [type]="input.inputType"
              [append]="input.filterSuffix!"
              [filter]="!!input.filterSuffix"
              (enterPress)="enterPressed.emit()"
              [layouthint]="input.layouthint"
              [elementId]="input.elementId"
              [prepend]="input.prepend"
              [ngClass]="{'process-toolkit-input': isProcessToolkitForm}"
            />
          }
          @if (!input.formatted && input.inputType === 'textarea') {
            <naris-input
              [control]="getFormControl(input.id)"
              [id]="formId + '__' + input.label"
              [placeholder]="input.placeholder || ''"
              type="textarea"
              [autosize]="true"
            />
          }
        }

        <!-- CheckboxGroup -->
        @case ('checkboxGroup') {
          <naris-checkbox-group
            [control]="getFormControl(input.id)"
            [options]="input.options || []"
            [disabled]="input.disabled!"
            [id]="formId + '__' + input.id"
          />
        }

        <!-- Slidetoggle -->
        @case ('checkbox') {
          <naris-answer-toggle
            [input]="input" 
            [control]="getFormControl(input.id)"
            [isInitialApplicability]="isInitialApplicability"
            [id]="formId + '__' + input.id"
          />
        }

        <!-- Select -->
        @case ('select') {
          @if (input.layouthint?.includes('radiobutton')) {
            <naris-radio [control]="getFormControl(input.id)" [options]="input.options || []"  [id]="formId + '__' + input.id" />
          } @else {
            <naris-select
              [placeholder]="input.placeholder || input.label"
              [control]="getFormControl(input.id)"
              [options]="input.options || []"
              [multiple]="!!input.multiple"
              [mandatory]="!!input.mandatory"
              [id]="formId + '__' + input.id"
            />
          }
        }

        <!-- Radio -->
        @case ('radio') {
          <naris-radio [control]="getFormControl(input.id)" [options]="input.options || []" [id]="formId + '__' + input.id"/>
        }

        <!-- Slide (toggle) -->
        @case ('slide') {
          <naris-answer-toggle
            [input]="input" 
            [control]="getFormControl(input.id)" 
            [id]="input.label"
            [isInitialApplicability]="isInitialApplicability"
            [attr.data-test-id]="formId + '__' + input.id"
          />
        }

        <!-- Date -->
        @case ('date') {
          @if (input.inputType === 'range') {
            <naris-daterangepicker
              [control]="getFormControl(input.id)"
              [id]="formId + '__' + input.id"
              [input]="input"
            />
          } @else {
            <naris-datepicker
              [control]="getFormControl(input.id)"
              [id]="formId + '__' + input.id"
              [input]="input"
            />
          }          
        }

        <!-- Time -->
        @case ('time') {
          <naris-timepicker [control]="getFormControl(input.id)" [id]="formId + '__' + input.id" />
        }

        <!-- Autocomplete -->
        @case ('autocomplete') {
          @if (input.layouthint?.includes('checked-tree')) {
            <naris-checked-tree
              [input]="input"
              [lookup]="input.lookup"        
              [control]="getFormControl(input.id)"
              [disabled]="formGroup.disabled"
              [id]="formId + '__' + input.id"
            />
          } @else {
            @if (!!input.multiple) {
              <naris-autocomplete-multi 
                [control]="getFormControl(input.id)"
                [input]="input"
                [isFilter]="isFilter"
                [formGroup]="formGroup"
                [isContextChips]="isContextChips"
                [id]="formId + '__' + input.id"
                (autoSubmit)="autoSubmit.emit()"
              />
            } @else {
              <naris-autocomplete-single 
                [control]="getFormControl(input.id)"
                [input]="input"
                [isFilter]="isFilter"
                [id]="formId + '__' + input.id"
                (autoSubmit)="autoSubmit.emit()"
              />
            }
          }
        }

        <!-- Multi input -->
        @case ('multi-input') {
          <naris-multi-input [inputs]="input.children || []" [formGroup]="formGroup" [id]="formId + '__' + input.id"/>
        }

        <!-- Slider -->
        @case ('slider') {
          <naris-slider [control]="getFormControl(input.id)" [id]="formId + '__' + input.id"/>
        }

        <!-- Table -->
        @case ('table') {
          @if (hidePreviousAssessment) {
            <div [matMenuTriggerFor]="historyMenu" class="history" [matTooltip]="input.label">
              <naris-icon iconClass="small" icon="icon-history" />
            </div>
          }
          <mat-menu #historyMenu="matMenu">
            <div class="history__data">
              @if (input.tableData?.records?.length) {
                <table class="input-table" [attr.data-test-id]="'table__' + formId + '__' + input.id">
                  <tr>
                    @for (column of input.tableData?.columns; track $index) {
                      <th>{{ input.tableData?.meta[column].label }}</th>
                    }
                  </tr>
                  @for (record of input.tableData?.records; track $index) {
                    <tr>
                      @for (column of input.tableData?.columns; track $index) {
                        <td>
                          {{ record.elements[column] ?? '-' }}
                          @if (input.tableData?.meta[column].unit) {
                            <span>{{ input.tableData?.meta[column].unit }}</span>
                          }
                        </td>
                      }
                    </tr>
                  }
                </table>
              }
              @if (!input.tableData?.records?.length) {
                <p>{{'table.no_records_found' | translate}}</p>
              }
            </div>
          </mat-menu>
          @if (input.tableData?.records?.length && !hidePreviousAssessment) {
            <table class="input-table" [attr.data-test-id]="'table__' + formId + '__' + input.id">
              <tr>
                @for (column of input.tableData?.columns; track $index) {
                  <th>{{ input.tableData?.meta[column].label }}</th>
                }
              </tr>
              @for (record of input.tableData?.records; track $index) {
                <tr>
                  @for (column of input.tableData?.columns; track $index) {
                    <td>
                      {{ record.elements[column] ?? '-' }}
                      @if (input.tableData?.meta[column].unit) {
                        <span>{{ input.tableData?.meta[column].unit }}</span>
                      }
                    </td>
                  }
                </tr>
              }
            </table>
          }
          @if (!input.tableData?.records?.length && !hidePreviousAssessment) {
            <p>{{'table.no_records_found' | translate}}</p>
          }
        }

        <!-- File upload -->
        @case ('file') {
          <naris-file-upload
            [accept]="input.allowedMimeTypes"
            [allowedExtensions]="input.allowedExtensions"
            [maxFileSize]="getUnknownPropertyValue('maxFileSize', input)"
            [acceptMultipleFiles]="input.multiple ?? false"
            [control]="getFormControl(input.id)"
            [id]="formId + '__' + input.id"
          />
        }

        <!-- Color picker -->
        @case ('color-picker') {
          <naris-color-input [control]="getFormControl(input.id)" [input]="input" [id]="formId + '__' + input.id" />
        }

        @case ('notifyText') {
          <div class="notify-text" [attr.data-test-id]="'notify-text__' + formId + '__' + input.id">
            <div class="notify-text-icon">
              <naris-icon [icon]="'error'" />
            </div>
            <div class="notify-text-content">
              <div class="notify-text-content-label" [innerHtml]="input.value.label || input.text?.message">{{input.value.label}}</div>
              <ul>
                @for (notifyValue of input.value.values; track $index) {
                  <li>
                    {{notifyValue}}
                  </li>
                }
              </ul>
            </div>
          </div>
        }

        @case ('period') {
          <naris-period [input]="input" [control]="getFormControl(input.id)" [id]="formId + '__' + input.id"/>
        }
        
        @case ('frequency') {
          <naris-frequency [frequencyObject]="input.frequency" [isFormInput]="true" [id]="formId + '__' + input.id" />
        }
      }
    </naris-form-field>
  }
</div>
