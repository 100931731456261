import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractDocumentActions',
  standalone: true
})
export class ExtractDocumentActionsPipe implements PipeTransform {

  public transform(actions: any[]) {
    const convertedArr: any[] = [];
    actions.forEach(action => {
      convertedArr.push(Object.entries(action));
    });
    return convertedArr;
  }
}