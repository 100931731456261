import { Component, Input } from '@angular/core';
import { DateTime } from 'luxon';
import { MatTooltip } from '@angular/material/tooltip';
import { DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naris-audit-execution-history-properties',
  templateUrl: './audit-execution-history-properties.component.html',
  styleUrls: ['./audit-execution-history-properties.component.scss'],
  standalone: true,
  imports: [MatTooltip, DatePipe, TranslateModule]
})
export class AuditExecutionHistoryPropertiesComponent {
  @Input() public data: any[];

  public isDate(value: any): boolean {
    if (isNaN(value)) {
      return DateTime.fromISO(value).isValid;
    }
    return false;
  }
}
