/* eslint-disable @typescript-eslint/no-useless-constructor */
import { FormInput } from '@core/classes/form-input.class';
import type { IInputOption, IInputConfig, INarisOption, TControlType } from '@core/models';

export class SelectInput extends FormInput {
  public controlType: TControlType = 'select';
  public options: IInputOption[] = [];

  constructor(config: IInputConfig) {
    super(config);
    this.options = config['options'] || [];
  }
}

export class TextInput extends FormInput {
  public controlType: TControlType = 'text';
  public inputType: string;
  public autoComplete: string;

  constructor(config: IInputConfig) {
    super(config);
    this.inputType = config['inputType'] || 'text';
    this.autoComplete = config['inputType'] === 'password' ? 'current-password' : 'username';
  }
}

export class CheckboxInput extends FormInput {
  public controlType: TControlType = 'checkbox';
}

export class RadioInput extends FormInput {
  public controlType: TControlType = 'radio';

  constructor(config: IInputConfig) {
    super(config);
    this.options = config['options'] || [];
    this.value = config['value'] || [];
  }
}

export class DiagramInput extends FormInput {
  public controlType: TControlType = 'diagram';

  constructor(config: IInputConfig) {
    super(config);
  }
}

export class FrequencyTextBlock extends FormInput {
  public controlType: TControlType = 'frequency';

  constructor(config: IInputConfig) {
    super(config);
    this.frequency = config?.['dynamicschema']?.[0]?.elements;
  }
}

export class AutoCompleteInput extends FormInput {
  public controlType: TControlType = 'autocomplete';
  public options: IInputOption[];
  public dynamicOptions: INarisOption[];

  constructor(config: IInputConfig) {
    super(config);
    this.options = config['options'] || [];
    this.dynamicOptions = config['dynamicOptions'] || [];
  }
}

export class SlideToggleInput extends FormInput {
  public controlType: TControlType = 'slide';

  constructor(config: IInputConfig) {
    super(config);
    this.value = config['value'] || false;
  }
}

export class SliderInput extends FormInput {
  public controlType: TControlType = 'slider';
}

export class DateInput extends FormInput {
  public controlType: TControlType = 'date';
  public inputType = 'date';

  constructor(config: IInputConfig) {
    super(config);
    this.inputType = config['inputType'] || 'date';
  }
}

export class TimeInput extends FormInput {
  public controlType: TControlType = 'time';
  public inputType = 'time';

  constructor(config: IInputConfig) {
    super(config);
    this.inputType = config['inputType'] || 'time';
  }
}

export class CheckboxGroup extends FormInput {
  public controlType: TControlType = 'checkboxGroup';

  constructor(config: IInputConfig) {
    super(config);
    this.options = config['options'] || [];
    this.value = config['value'] || [];
  }
}

export class RadioGroup extends FormInput {
  public controlType: TControlType = 'radio';

  constructor(config: IInputConfig) {
    super(config);
  }
}

export class HelperTextBlock extends FormInput {
  public controlType: TControlType = 'helperText';
}

export class HeadingBlock extends FormInput {
  public controlType: TControlType = 'heading';
}

export class TableBlock extends FormInput {
  public controlType: TControlType = 'table';
  public tableData: any;
}

export class FileInput extends FormInput {
  public controlType: TControlType = 'file';
  public inputType: string;
  public maxFileSize: number;

  constructor(config: IInputConfig) {
    super(config);
    this.inputType = config['inputType'] || 'text';
    this.maxFileSize = config['uploadMaxFileSize']!;
  }
}

export class ColorInput extends FormInput {
  public controlType: TControlType = 'color-picker';

  constructor(config: IInputConfig) {
    super(config);
  }
}

export class NotifyTextBlock extends FormInput {
  public controlType: TControlType = 'notifyText';
}

export class WarningTextBlock extends FormInput {
  public controlType: TControlType = 'warningText';
}

export class PeriodInput extends FormInput {
  public amount: number;
  public scale: string;
  public controlType: TControlType = 'period';

  public periodElements: any[];

  constructor(element: IInputConfig, periodElements: any[]) {
    super(element);
    this.periodElements = periodElements;
  }
}

export class RangeInput extends FormInput {
  public controlType: TControlType = 'multi-input';
  public children: FormInput[];

  constructor(config: IInputConfig) {
    super(config);
    this.children = config.children?.map(c => {
      const input = Object.values(c)[0];
      return {...input, id: input.name, inputType: config.inputType, parent: this};
    }) || [];
  }
}
