import { IAction, ILinks } from '@core/models';
import { ICollabCaseState, ICollabStep } from '@core/models/core.models';

export default class CollabCase {

  public completed = false;
  public intermediate = false;
  public steps: ICollabStep[] = [
    {title: 'collab.add_cause_consequence', intermediate: true, completed: false},
    // {title: 'collab.select_categories', intermediate: false, completed: false},
    {title: 'collab.vote_category', intermediate: false, completed: false},
    {title: 'collab.final_vote', intermediate: false, completed: false},
    {title: 'collab.wrap_up', intermediate: false, completed: false}
  ];
  public activeStep: number | null = 0;
  public caseState: ICollabCaseState = {
    state: 0,
    activeStep: 0,
    steps: {0: 0, 1: 0, 2: 0, 3: 0},
    categories: [],
    chosenCategories: [],
    currentCategory: null
  };

  constructor(
    public id: string, 
    public recordId: number | string, 
    public label: string, 
    public url: string, 
    initialState?: ICollabCaseState, 
    public causes?: {data?: any, labels: {key: string, label: string}[] | null}, 
    public consequences?: {data?: any, labels: {key: string, label: string}[] | null},
    public riskIdentificationState?: string,
    public actions?: IAction[],
    public links?: ILinks
  ) {
    initialState && this.mapState(initialState);
  }

  private mapState(state: ICollabCaseState) {
    this.caseState = state;
    this.activeStep = state.activeStep;
    this.completed = state.state === 2;
    this.intermediate = state.state === 1 || state.state === 2;
    this.steps = this.steps.map((s, i) => ({
      ...s,
      completed: state.steps[i] === 2,
      intermediate: state.steps[i] === 1 || state.steps[i] === 2
    }));
  }
}

export interface CollabJson {
  state: string;
  steps: CollabJsonStep[];
}

export interface CollabJsonStep {
  state: string;
  blindvoting?: boolean;
  categories?: number[];
}

export interface CollabCategory {
  id: number;
  label: string;
  type: string;
  min: number;
  max: number;
}