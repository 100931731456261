import { ELEMENT_SVG as t } from "./svg-util.js";
import { isSVGElement as e } from "./browser-util.js";
function n(t, e) {
  var n = t;
  if (n.offsetWidth != null) {
    return x(t);
  } else if (n.width && n.width.baseVal) {
    return m(n);
  } else if (n.getBBox) {
    return V(n);
  } else if (n.localName === "g") {
    return c(n, e);
  }
}
function r(t, e) {
  var n = t;
  if (n.offsetWidth != null) {
    return p(t);
  } else if (n.width && n.width.baseVal) {
    return m(n);
  } else if (n.getBBox) {
    return V(n);
  } else if (n.localName === "g") {
    return c(n, this);
  }
}
function a(t) {
  var e = t;
  if (e.offsetLeft != null) {
    return y(e);
  } else if (e.x && e.x.baseVal) {
    return d(e);
  } else if (e.localName === "g") {
    return B(e);
  }
}
function i(n, r) {
  var a = n;
  if (a.offsetLeft != null) {
    g(a, r);
  } else if (a.x && a.x.baseVal) {
    b(a, r);
    if (a.localName === t && !e(n.parentElement)) {
      g(a, r);
    }
  } else if (a.localName === "g") {
    F(a, r);
  }
}
function l(t, e, n, r, a) {
  var i = t;
  if (i.offsetLeft != null) {
    i.style[n] = "".concat(e, "px");
  } else if (i[r] && i[r].baseVal) {
    i.setAttribute(r, e);
  } else if (i.localName === "g") {
    var l = B(i);
    l[a] = e;
    F(i, l);
  }
}
function o(t, e) {
  l(t, e, "left", "x", "x");
}
function f(t, e) {
  l(t, e, "top", "y", "y");
}
function s(t, e, n) {
  var r = t;
  if (r.offsetWidth != null) {
    v(t, e, n);
  } else if (r.width && r.width.baseVal) {
    v(t, e, n);
    t.setAttribute("width", "".concat(e));
    t.setAttribute("height", "".concat(n));
  } else if (r.localName === "g") ;
}
function u(t, e, n) {
  i(t, e);
  s(t, n.w, n.h);
}
function c(t, e) {
  var n = t.getBoundingClientRect();
  var r = e || 1;
  return {
    w: n.width / r,
    h: n.height / r
  };
}
function h(t, e) {
  var n = e.getContainer().getBoundingClientRect();
  var r = t.getBoundingClientRect();
  var a = e.getEffectiveVisibleZoom();
  return {
    x: (r.left - n.left) / a,
    y: (r.top - n.top) / a
  };
}
function x(t) {
  return {
    w: t.offsetWidth,
    h: t.offsetHeight
  };
}
function p(t) {
  return {
    w: t.clientWidth,
    h: t.clientHeight
  };
}
function y(t) {
  return {
    x: parseFloat(t.offsetLeft),
    y: parseFloat(t.offsetTop)
  };
}
function g(t, e) {
  t.style.left = e.x + "px";
  t.style.top = e.y + "px";
}
function v(t, e, n) {
  t.style.width = "".concat(e, "px");
  t.style.height = "".concat(n, "px");
}
function w(t) {
  return {
    x: parseInt(t.style.left, 10),
    y: parseInt(t.style.top, 10)
  };
}
function d(t) {
  try {
    return {
      x: parseFloat(t.x.baseVal.value),
      y: parseFloat(t.y.baseVal.value)
    };
  } catch (t) {
    return {
      x: 0,
      y: 0
    };
  }
}
function b(t, e) {
  t.setAttribute("x", "".concat(e.x));
  t.setAttribute("y", "".concat(e.y));
}
function m(t) {
  try {
    return {
      w: parseFloat(t.width.baseVal.value),
      h: parseFloat(t.height.baseVal.value)
    };
  } catch (t) {
    return {
      w: 0,
      h: 0
    };
  }
}
function V(t) {
  try {
    var e = t.getBBox();
    return {
      w: e.width,
      h: e.height
    };
  } catch (t) {
    return {
      w: 0,
      h: 0
    };
  }
}
function B(t) {
  var e = t.style.transform.match(/translate\(([-0-9\.]+)px, ([-0-9\.]+)px\)/);
  try {
    return {
      x: parseFloat(e[1]),
      y: parseFloat(e[2])
    };
  } catch (t) {
    return {
      x: 0,
      y: 0
    };
  }
}
function F(t, e) {
  t.style.transform = "translate(".concat(e.x, "px, ").concat(e.y, "px)");
}
export { w as getAbsolutePosition, c as getBoundingClientRectElementSize, p as getClientSize, r as getElementInternalSize, h as getElementPosition, n as getElementSize, y as getOffsetPosition, x as getOffsetSize, a as getPosition, V as getSvgBoundingBoxSize, B as getSvgTranslatePosition, m as getSvgWidthHeightSize, d as getSvgXYPosition, u as setBoundingRect, s as setElementSize, g as setOffsetPosition, i as setPosition, F as setSvgTranslatePosition, b as setSvgXYPosition, o as setXPosition, f as setYPosition };