@if (!advanced) {
  <div class="quick-search">
    <div class="quick-search-toolbar">
      <input
        class="quick-search-input"
        [placeholder]="('search.search' | translate) + '...'"
        #input
        [formControl]="query"
        (focus)="$event.stopPropagation();"
        (click)="$event.stopPropagation()"
      >
      @if (loading) {
        <naris-progress-spinner [diameter]="24" [strokeWidth]="2" />
      }
      @if (!loading && query.value) {
        <naris-button icon="close" color="transparent" (click)="clear($event)" id="QuickSearchClear"/>
      }
    </div>
    @if (query.value && results?.length) {
      <a class="quick-search-item quick-search-item-content" href="#" (click)="$event.preventDefault(); advanced = true">
        <naris-icon icon="search" class="mr-2" />
        {{ 'search.advanced_search' | translate }}...
      </a>
      @for (item of results; track $index) {
        <a class="quick-search-item" href="#" (click)="$event.preventDefault(); onItemClicked(item)">
          <div class="quick-search-item-content">
            <div class="quick-search-item-subtitle">
              {{ item.objectType }}
            </div>
            <div class="quick-search-item-title quick-search-item-overflow" [innerHTML]="item.CaseName | highlight : query.value"></div>
          </div>
          <div class="quick-search-item-info">
            @if (item.LastChangeDate) {
              <div class="quick-search-item-subtitle">{{ 'search.updated' | translate }}</div>
              <div class="quick-search-item-title">{{ ago(item.LastChangeDate) }}</div>
            }
          </div>
        </a>
      }
    }
    @if (query.value && !results?.length && !loading) {
      <naris-empty-state
        [title]="'search.no_results.title' | translate"
        [text]="'search.no_results.text' | translate"
      />
    }
    @if (!query.value) {
      <naris-empty-state
        [title]="'search.empty.title' | translate"
        [text]="'search.empty.text' | translate"
        image="empty"
      />
    }
  </div>
} @else {
  <div class="advanced-search">
    <naris-table dataUrl="/configuration/search" [widgetConfig]="widgetConfig" />
  </div>
}
