import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../elements/icon/icon.component';
import { DatepickerComponent } from '../datepicker/datepicker.component';
import type { DateTime } from 'luxon';
import type { FormInput } from '@core/classes';

@Component({
  selector: 'naris-double-datepicker',
  templateUrl: './double-datepicker.component.html',
  styleUrls: ['./double-datepicker.component.scss'],
  standalone: true,
  imports: [IconComponent, MatTooltip, DatepickerComponent, TranslateModule]
})
export class DoubleDatepickerComponent implements OnInit {

  public formGroup = new FormGroup({});
  public generalError: string;

  @Input() public control: FormControl;

  @Input() public input: FormInput;

  @Input() public minDate?: DateTime;
  @Input() public maxDate?: DateTime;

  @Input() public requiredIfVisible: boolean;

  @Input()
  public id: string;

  @Input() set error(value: { error: string; control: string }) {
    if (!!value) {
      const errorControl = this.formGroup.get(value.control);
      if (!!errorControl) errorControl.setErrors({error: value.error});
      else this.generalError = value.error;
    }
  }

  public getControl(index: number) {
    if (!this.formGroup?.controls) return null;
    const key = Object.keys(this.formGroup.controls)[index];
    return this.formGroup.get(key) as FormControl;
  }

  public getLabel(index: number) {
    if (!this.formGroup?.controls) return null;
    const key = Object.keys(this.formGroup.controls)[index] as keyof FormInput;
    const foundInput = this.input.children?.find(item => Object.keys(item).includes(key));
    return !!foundInput?.[key]?.label ? foundInput[key].label : null;
  }

  public getTooltip(index: number) {
    if (!this.formGroup?.controls) return null;
    const key = Object.keys(this.formGroup.controls)[index] as keyof FormInput;
    const foundInput = this.input.children?.find(item => Object.keys(item).includes(key));
    return !!foundInput?.[key]?.assistant ? foundInput[key].assistant.replace(' | ', '').replace('| ', '') : null;
  }

  public ngOnInit(): void {
    this.input.elements?.forEach((element: Record<string, any>) => {
      const dateControl = new FormControl('');
      dateControl.setValue(element.suggestion?.split('T')[0]);
      this.formGroup.addControl(element.elementid, dateControl);
    });

    this.formGroup.valueChanges.subscribe(changes => {
      this.control.setValue(changes);
    });
  }
}
