import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { IDialogData } from '@core/models';
import { EXPIRE_TIME, TIME_OUT } from '@core/constants';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgStyle } from '@angular/common';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { DoclinkDirective } from '../../directives/doclink.directive';
import { ButtonComponent } from '../../elements/button/button.component';
import { IconComponent } from '../../elements/icon/icon.component';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, IconComponent, ButtonComponent, MatDialogClose, CdkScrollable, MatDialogContent, DoclinkDirective, NgStyle, MatDialogActions, SafeHtmlPipe, TranslateModule]
})
export class DialogComponent implements OnInit, OnDestroy {

  public iconTypes = {
    success: 'check_circle',
    error: 'cancel',
    alert: 'error'
  } as Record<string, string>;

  public iconClasses = {
    success: 'medium green',
    error: 'medium warn',
    alert: 'medium accent'
  } as Record<string, string>;

  public timeLeft: number;
  public expireTime = EXPIRE_TIME;
  private interval: any;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData
  ) {}

  public ngOnInit() {
    if (!!this.data.timer) {
      this.timeLeft = this.data.timer;
      this.interval = setInterval(() => {
        if (!this.timeLeft) this.dialogRef.close(TIME_OUT);
        else this.timeLeft -= 1;
      }, 1000);
    }
  }

  public ngOnDestroy() {
    if (!!this.interval) clearInterval(this.interval);
  }
}
