<naris-card class="card">
  <h2>{{'organization.choose' | translate}}</h2>
  <p class="mb-8">{{'organization.choose_label' | translate}}</p>
  @if (!!orgForm) {
    <form [formGroup]="orgForm" (ngSubmit)="onSubmit()">
      <naris-form-field 
        label="Organization"
        [fixStyling]="true"
      >
        <naris-select
          [options]="organizations"
          [control]="getFormControl('organization')"
          [mandatory]="true"
          [id]="'OrgChoiceOrganizations'"
        />
      </naris-form-field>
      <naris-form-field [fixStyling]="true">
        <naris-checkbox
          color="primary"
          [control]="getFormControl('isDefault')"
          id="OrgChoiceIsDefault"
        >
          {{'organization.default' | translate}}
        </naris-checkbox>
      </naris-form-field>
      <naris-form-actions>
        <a 
          class="naris-link"
          role="button"
          href="javascript:void(0)"
          (click)="onBack()" 
        >{{'go_back' | translate}}</a>
        <naris-button color="primary" id="OrgChoiceSignIn">{{'sign_in' | translate}}</naris-button>
      </naris-form-actions>
    </form>
  }
</naris-card>
