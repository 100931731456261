import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@core/services';
import { Subscription } from 'rxjs';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { ToolbarItemComponent } from '../toolbar/toolbar-item/toolbar-item.component';
import { ButtonComponent } from '../../elements/button/button.component';
import { ProcessManagerComponent } from '../process-manager/process-manager.component';

@Component({
  selector: 'app-process-viewer-dialog',
  templateUrl: './process-viewer-dialog.component.html',
  styleUrl: './process-viewer-dialog.component.scss',
  standalone: true,
  imports: [ToolbarComponent, ToolbarItemComponent, ButtonComponent, ProcessManagerComponent]
})
export class ProcessViewerDialogComponent implements OnInit, OnDestroy{

  public viewerUrl: string;

  private readonly subs: Subscription[] = [];

  constructor(
    private readonly authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: {
      processId: number;
      processName: string;
    },
    public dialogRef: MatDialogRef<ProcessViewerDialogComponent>
  ) {}

  public ngOnInit(): void {
    this.viewerUrl = `/process/process/${this.data.processId}/designer/process-structure-list`;

    this.subs.push(
      this.authService.closePopUps$.subscribe(() => this.close())
    );
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public close() {
    this.dialogRef.close(); 
  }
}
