import { ordinalValues } from '@core/constants/ordinal-constants';

export const checkOrdinal = (num: number, ordinalRule: string, ordinalSuffix: string) => {
  if (ordinalRule === 'other') {
    return ordinalSuffix;
  }
  const splittedRule = ordinalRule.split('and');
  const value = splittedRule.every(rule => {
    const compareValue = +rule.split('=')[1].trim();
    const notIs = rule.includes('!=');
    const exprValue = rule.split('=')[0].replace('!', '').trim();

    const splittedExpr = exprValue.split('%');
    if (splittedExpr.length === 2) {
      const dividerValue = +splittedExpr[1].trim();
      return notIs ? num % dividerValue !== compareValue : num % dividerValue === compareValue;
    } else {
      return notIs ? num !== compareValue : num === compareValue;
    }
  });
  if (value) {
    return ordinalSuffix;
  } else {
    return null;
  }
};

export const getOrdinal = (num: number, lang: string) => {
  const currentOrdinals = ordinalValues.get(lang);
  let found = false;
  let ordinal = null;
  currentOrdinals?.forEach(rule => {
    if (found) {
      return;
    }
    ordinal = checkOrdinal(num, rule.rule, rule.suffix);
    if (ordinal !== null) {
      found = true;
      return;
    }
  });
  return ordinal;
};
