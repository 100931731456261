import { Component, OnInit, Inject, HostBinding } from '@angular/core';
import { BeinformedService, TabService } from '@core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { ICaseTabComponent, ICombinedResponse } from '@core/models';
import { ModuleComponent } from '@core/module/module.component';
import { GROUPING_PANEL_TYPES } from '@core/constants';
import { SetTabClass } from '@core/classes';
import { FooterToolbarService } from '@core/services/footer-toolbar.service';
import { TableSettingsService } from '@core/services/table-settings.service';
import { DashboardService } from '@core/services/dashboard.service';
import { DynamicDashboardComponent } from '../../../shared/components/dynamic-dashboard/dynamic-dashboard.component';
import { GridComponent } from '../../../shared/components/grid/grid.component';
import { GridItemComponent } from '../../../shared/components/grid/grid-item/grid-item.component';
import { PanelComponent } from '../../../shared/components/panel/panel.component';

@Component({
  selector: 'app-module-component',
  templateUrl: './module-component.component.html',
  styleUrls: ['./module-component.component.scss'],
  standalone: true,
  imports: [DynamicDashboardComponent, GridComponent, GridItemComponent, PanelComponent]
})
export class ModuleComponentComponent implements OnInit {

  public panels?: ICaseTabComponent[] | null;
  public loading = true;

  public url = '';
  public dashboardResult: ICombinedResponse;

  @HostBinding('class.dashboard-module') private isDashboard = false;

  constructor(
    @Inject(ModuleComponent) public moduleComponent: ModuleComponent,
    private readonly beinformedService: BeinformedService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly tabs: TabService,
    private readonly footerToolbarService: FooterToolbarService,
    private readonly tableSettingsService: TableSettingsService,
    private readonly dashboardService: DashboardService
  ) {}

  public ngOnInit(): void {
    if (!this.router.url.startsWith('/dashboard')) this.tabs.clear();
    else this.isDashboard = true;
    this.footerToolbarService.reset();
    // Subscribe to param changes
    this.activatedRoute.params.subscribe(() => {
      this.loading = true;
      this.panels = null;
      this.url = this.router.url;

      const endpoint = `/${this.router.url.split('/').slice(1, 3).join('/')}`;
      const componentConfig = this.moduleComponent.components.find((c: ICaseTabComponent) => c.href === endpoint);

      // Check for layouthint 'go-to-case-view' to trigger redirect
      const redirect = componentConfig?.layouthint?.includes('go-to-case-view');

      if (redirect) {
        this.redirectToCaseView(endpoint);
      } else if (GROUPING_PANEL_TYPES.includes(componentConfig?.resourcetype || '')) {
        // If this component is a grouping panel, fetch the information
        this.fetchComponent(endpoint);
      } else {
        // Otherwise, load the single panel into the panels array
        this.panels = [componentConfig!];
        this.loading = false;

        const singlePanel = {title: componentConfig?.label, active: true, href: endpoint};
        const setTab = new SetTabClass(this.tabs, this.router);
        setTab.setTabs([singlePanel]);
      }
    });
  }

  /**
   * Redirect to case view
   * Fetches information for an endpoint and redirects the user to the href of the first row in the results.
   * @param endpoint BeInformed API endpoint
   */
  private redirectToCaseView(endpoint: string) {
    this.beinformedService.fetchResponseWithContributions(endpoint).subscribe(({ data }) => {
      // Get the href for the first result
      const singleResult = Object.values(data._embedded.results[0])[0] as any;
      const href = singleResult._links.self.href;

      // Redirect user without adding to router history
      this.footerToolbarService.reset();
      void this.router.navigate([href], { replaceUrl: true });
    });
  }

  /**
   * Fetch the component information and set the panels.
   * @param endpoint BeInformed API endpoint
   */
  private fetchComponent(endpoint: string) {
    this.beinformedService.fetchResponseWithContributions(endpoint).subscribe((result: ICombinedResponse) => {
      const individualSettings = result.data.taskgroups?.find(group => group.name?.toLowerCase().startsWith('individualsettings_'));
      if (!!individualSettings) {
        if (this.router.url.includes('dashboard')) this.dashboardService.setUrls(individualSettings.actions);
        else this.tableSettingsService.setUrls(individualSettings.actions);
      } 
      
      if (this.router.url.includes('dashboard')) this.dashboardResult = result;

      this.panels = this.beinformedService.extractPanels(result);
      this.loading = false;
      const setTab = new SetTabClass(this.tabs, this.router);
      if (!this.router.url.startsWith('/dashboard')) setTab.setTabs(this.panels || []);
    });
  }
}
