<naris-toolbar>
  <naris-toolbar-item class="toggles">
    <naris-slidetoggle [checked]="isSubscribed" (changed)="toggleSubscription($event)" [id]="'NotificationsSubscription'">
      {{'notifications.get_push_notifications' | translate}}
    </naris-slidetoggle>
    @if (messageService.emailNotificationToggleVisible) {
      <naris-slidetoggle [checked]="hasEmailNotificationOn" (changed)="toggleEmailSubscription($event)" [id]="'NotificationsEmailSubscription'">
        {{'notifications.get_email_notifications' | translate}}
      </naris-slidetoggle>
    }
  </naris-toolbar-item>
  <naris-toolbar-item class="ml-auto">
    <naris-button icon="close" color="white" class="close-button" (clicked)="close()" id="NotificationsClose"/>
  </naris-toolbar-item>
</naris-toolbar>

@if (showPushInfo) {
  <div class="helper-text">{{'push_subscription_info' | translate}}</div>
}

@if (!!notifications?.length) {
  <naris-toolbar height="auto" sticky="top" class="header">
    <naris-toolbar-item [matBadge]="unreadCount ? (unreadCount > 99 ? '99+' : unreadCount) : null" [matBadgeColor]="colorNew">
      <h3 class="pr-5">{{'notifications.notifications' | translate}}</h3>
    </naris-toolbar-item>
    @if (unreadCount) {
      <naris-toolbar-item class="ml-auto">
        <naris-button color="transparent" size="small" (click)="markAsRead()" id="NotificationsMarkAsRead">
          {{'mark_all_as_read' | translate}}
        </naris-button>
        <naris-button icon="refresh" color="white" size="small" class="close-button" (clicked)="reload()" id="NotificationsReload"/>
      </naris-toolbar-item>
    }
  </naris-toolbar>
  @for (notification of notifications; track $index) {
    <naris-notification
      [notification]="notification"
      (closed)="close()"
    />
  }
} @else {
  <naris-empty-state
    [title]="'notifications.you_have_no_notifications' | translate"
    [text]="'notifications.appear' | translate"
    [image]="'notification'"
  />
}

@if (notifications?.length) {
  <div class="fab-button">
    <naris-button color="primary" (click)="viewNotifications()" id="NotificationsViewAll">{{'notifications.view_all' | translate}}</naris-button>
  </div>
}
