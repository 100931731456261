<div class="naris-autocomplete-multi" [attr.data-test-id]="'autocomplete-multi__' + id">
  @if (!control.disabled) {
    <div class="naris-autocomplete-multi__row">
      <div class="naris-autocomplete-multi__row__input">
        @if (input.id === 'TagIDs') {
          <input
            class="naris-autocomplete-multi__row__input__control"
            [type]="input.inputType || 'text'"
            [placeholder]="input.placeholder || ''"
            [formControl]="autocompleteInput"
            [matAutocomplete]="autocomplete"
            [matChipInputFor]="chipGrid"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="handleChipInput($event)"
            (focus)="focused = true"
            (blur)="focused = false"
          />
        } @else {
          <input
            class="naris-autocomplete-multi__row__input__control"
            [type]="input.inputType || 'text'"
            [placeholder]="input.placeholder || ''"
            [formControl]="autocompleteInput"
            [matAutocomplete]="autocomplete"
            (focus)="focused = true"
            (blur)="focused = false"
          />
        }
        @if (loading) {
          <div 
            class="naris-autocomplete-multi__row__input__loader" 
            [ngClass]="{move: !!selection.selected.length}"
          >
            <naris-icon 
              iconClass="small" 
              icon="spinner" 
              [inline]="true" 
            />
          </div>
        }
        @if (!control.disabled && !!selection.selected.length) {
          <naris-button
            icon="close"
            class="naris-autocomplete-multi__row__input__clear"
            color="white"
            (clicked)="onClearSelection()"
            [matTooltip]="'input.clear_selection' | translate"
            matTooltipPosition="above"
            [id]="'autocomplete__' + id + '__AutoCompleteClearSelection'"
          />
        }
      </div>
      <div class="naris-autocomplete-multi__row__button">
        @if (!!input.lookup?.list && !control.disabled) {
          <naris-button
            class="lookup-button"
            icon="search"
            type="button"
            [matTooltip]="'input.advanced' | translate"
            matTooltipPosition="above"
            [id]="'autocomplete__' + id + '__AutoCompleteAdvanced'"
            (clicked)="openAdvancedLookup()"
          />
        }
      </div>
    </div>
  }
  <div 
    class="naris-autocomplete-multi__chips"
    [ngClass]="{
      valid: (control.valid && !control.disabled && (control.value !== null || autocompleteInput.touched)),
      invalid: (!control.valid && !control.disabled && (control.dirty || autocompleteInput.touched)),
      focused: focused,
      disabled: control.disabled
    }"
  >
    @if (!selection.hasValue()) {
      <div class="naris-autocomplete-multi__chips--empty-state">{{ 'autocomplete.empty_selection' | translate }}</div>
    }
    <div [ngStyle]="{display: selection.hasValue() ? 'unset' : 'none'}">
      <mat-chip-grid class="chip-grid" #chipGrid [disabled]="!!input.disabled || !!control.disabled">
        @for (chip of selection.selected; track $index) {
          @if (this.isContextChips) {
            <mat-chip-row
              [removable]="!input.disabled && !readOnly"
              [ngClass]="{'naris-autocomplete-multi__chips--chip-disabled': control.disabled}"
              [matTooltip]="chip.label.length > 35 ? chip.label : ''"
              matTooltipShowDelay="200"
              matTooltipPosition="above"
              (removed)="onChipRemove(chip)"
            >
              {{chip.label.length > 35 ? chip.label.slice(0, 35) + '...' : chip.label}}
              @if (!!chip.context) {
                - {{chip.context.length > 35 ? chip.context.slice(0, 35) + '...' : chip.context}}
              }
              @if (!input.disabled && !readOnly) {
                <naris-icon matChipRemove icon="cancel" />
              }
            </mat-chip-row>
          } @else {
            <mat-chip-row
              [removable]="!input.disabled && !readOnly"
              [ngClass]="{'naris-autocomplete-multi__chips--chip-disabled': control.disabled}"
              (removed)="onChipRemove(chip)"
            >
              {{chip.label}}
              @if (!input.disabled && !readOnly) {
                <naris-icon matChipRemove icon="cancel" />
              }
            </mat-chip-row>
          }
        }
        <input [matChipInputFor]="chipGrid" class="chip-grid__input">
      </mat-chip-grid>
    </div>
  </div>
  <div class="naris-autocomplete-multi__select">
    <mat-autocomplete
      class="autocomplete"
      [autoActiveFirstOption]="true"
      [displayWith]="autocompleteLabel"
      #autocomplete="matAutocomplete"
      (optionSelected)="onOptionSelected($event)"
    >
      @for (option of filteredOptions | async; track $index) {
        <mat-option [value]="option">
          <naris-option
            [label]="option.label"
            [icon]="option.icon"
            [helperText]="option.helperText"
            [meta]="option"
          />
        </mat-option>
      }
    </mat-autocomplete>
  </div>
</div>