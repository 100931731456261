import { Injectable } from '@angular/core';
import { ITreeviewCompareItem } from '@core/models';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompareService {  
  public compareData$: Subject<ITreeviewCompareItem> = new Subject<ITreeviewCompareItem>();
  public updateAccepted$: Subject<boolean> = new Subject<boolean>();
  
  public hoveredItemUpdated$: Subject<{ id: number | string; isNewTree: boolean; rect: DOMRect }> = new Subject<{ id: number | string; isNewTree: boolean; rect: DOMRect }>();

  public oldItemsPositions = new Map<number | string, DOMRect>();
  public newItemsPositions = new Map<number | string, DOMRect>();

  public updatePositions$: Subject<boolean> = new Subject<boolean>();

  public setUserAccepted$: Subject<number> = new Subject<number>();
}
