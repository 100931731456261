import { defineProperty as e } from "../_virtual/_rollupPluginBabelHelpers.js";
var t, n, r, a, i, o;
function u(e, t, n) {
  return function (r) {
    return r[e] / t * (n ? -1 : 1);
  };
}
var l = "deltaY",
  f = "deltaX",
  d = "wheelDeltaY",
  c = "wheelDeltaX",
  s = "webkit",
  v = "mac",
  h = "win",
  m = "ie",
  w = "safari",
  g = "firefox",
  p = "default";
function y(e) {
  return e.deltaY * (e.deltaMode === 1 ? 25 : 1) / 120;
}
function E(e) {
  return e.deltaX * (e.deltaMode === 1 ? 25 : 1) / 120;
}
var D = (r = {}, e(r, s, (t = {}, e(t, v, u(l, 120)), e(t, h, u(l, 100)), t)), e(r, w, u(d, 120, true)), e(r, g, (n = {}, e(n, v, y), e(n, h, y), n)), e(r, m, function (e) {
    return e.wheelDelta / 120;
  }), e(r, p, function (e) {
    return e.deltaY || e.wheelDelta;
  }), r),
  A = (o = {}, e(o, s, (a = {}, e(a, v, u(f, 120)), e(a, h, u(f, 100)), a)), e(o, w, u(c, 120, true)), e(o, g, (i = {}, e(i, v, E), e(i, h, E), i)), e(o, m, function (e) {
    return e.wheelDelta / 120;
  }), e(o, p, function (e) {
    return e.deltaX || e.wheelDelta;
  }), o),
  K = typeof navigator !== "undefined",
  M = K ? /Mac/.test(navigator.userAgent) ? v : h : v,
  x = K ? navigator.userAgent.indexOf("Firefox") !== -1 ? g : /Chrome/.test(navigator.userAgent) ? s : /Safari/.test(navigator.userAgent) ? w : /WebKit/.test(navigator.userAgent) ? s : /Trident/.test(navigator.userAgent) ? m : "default" : p,
  L = typeof D[x] === "function" ? D[x] : D[x][M],
  X = typeof A[x] === "function" ? A[x] : A[x][M],
  Y = function e(t) {
    return L(t || event);
  },
  _ = function e(t) {
    return X(t || event);
  },
  b = function e(t, n, r) {
    var a = function e(n) {
      var a = Y(n),
        i = _(n);
      n.normalizedWheelDelta = a;
      if (!r || n.metaKey || n.ctrlKey) {
        t(n, i, a, n.metaKey || n.ctrlKey);
      }
    };
    t._jtkwl = a;
    return a;
  },
  j = typeof document !== "undefined",
  k = j ? "onwheel" in document.createElement("div") ? "wheel" : document.onmousewheel !== undefined ? "mousewheel" : "DOMMouseScroll" : "wheel";
function O(e, t, n, r) {
  var a = b(t, n, r);
  if (e.addEventListener) {
    e.addEventListener(k, a, false);
  } else if (e.attachEvent) {
    e.attachEvent("onmousewheel", a);
  }
  return a;
}
function P(e, t) {
  var n = t._jtkwl;
  if (n != null) {
    if (e.removeEventListener) {
      e.removeEventListener(k, n, false);
    } else if (e.detachEvent) {
      e.detachEvent("onmousewheel", n);
    }
  }
}
export { O as addWheelListener, P as removeWheelListener };