import { Pipe, PipeTransform } from '@angular/core';
import { decamelize, isAbbreviation } from '@core/helpers';

@Pipe({
  name: 'decamelize',
  standalone: true
})
export class DecamelizePipe implements PipeTransform {

  public transform(value: string, upperFirst?: boolean): string {
    return isAbbreviation(value) ? value : decamelize(value, upperFirst);
  }
}
