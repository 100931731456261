import { defineProperty as e, inherits as t, createSuper as n, classCallCheck as o, assertThisInitialized as i, createClass as r, slicedToArray as a, toConsumableArray as s } from "../_virtual/_rollupPluginBabelHelpers.js";
import { AppendedElementHorizontalAlignmentValues as l, AppendedElementVerticalAlignmentValues as u } from "./decorators.js";
import { PanZoom as d } from "./pan-zoom.js";
import { attWithValue as p } from "./browser-ui-instance.js";
import { _initialiseDecorators as c } from "./surface-decorator.js";
import { BrowserUIModel as f, DEFAULT_LABEL_LOCATION_ATTRIBUTE as g } from "./browser-ui-model.js";
import { CanvasLayoutAdapter as v } from "./canvas-layout-adapter.js";
import { GROUP_RELAYOUT_ADHOC as h, ALL_GROUPS_RELAYOUT as m, GROUP_RELAYOUT_MEMBER_REMOVED as y, GROUP_RELAYOUT_NEW_VERTEX_ADDED as b, GROUP_RELAYOUT_EXISTING_VERTEX_ADDED as _, GroupLayoutAdapter as E } from "./group-layout-adapter.js";
import { UIPath as j } from "./ui-path.js";
import { ATTRIBUTE_JTK_PORT as k, ATTRIBUTE_JTK_PORT_TYPE as S, ATTRIBUTE_ANCHOR_X as x, ATTRIBUTE_ANCHOR_Y as P, ATTRIBUTE_OFFSET_X as I, ATTRIBUTE_OFFSET_Y as T, ATTRIBUTE_ORIENTATION_X as w, ATTRIBUTE_ORIENTATION_Y as G, ATTRIBUTE_JTK_EDGE_TYPE as z, ATTRIBUTE_JTK_SOURCE as M, ATTRIBUTE_JTK_TARGET as R, Constants as A, CLASS_SURFACE_CANVAS as D, EVENT_PAN as C, EVENT_RELAYOUT as F, CLASS_MOST_RECENTLY_DRAGGED as O, CLASS_SURFACE_ELEMENT_DRAGGING as Z, CLASS_SURFACE as $, CLASS_SURFACE_TOUCH_DEVICE as L, CLASS_SURFACE_POINTER_DEVICE as V, CLASS_SURFACE_NO_PAN as N, CLASS_SURFACE_PANNING as B, CLASS_SELECT_DEFEAT as H, EVENT_PLUGIN_ADDED as W, EVENT_SURFACE_MODE_CHANGED as U, CLASS_SURFACE_DIRECT as q, EVENT_ROTATE as K, EVENT_AFTER_LAYOUT_REFRESH as J, EVENT_ADHOC_LAYOUT as X, ATTRIBUTE_JTK_SOURCE_PORT as Y, ATTRIBUTE_JTK_TARGET_PORT as Q, ATTRIBUTE_JTK_PORT_ID as ee, ATTRIBUTE_JTK_SOURCE_PORT_ID as te, ATTRIBUTE_JTK_TARGET_PORT_ID as ne, ELEMENT_JTK_ENDPOINT as oe, EVENT_GROUP_RELAYOUT as ie, EVENT_OBJECT_REPAINTED as re, CLASS_NODE as ae, CLASS_GROUP as se, CLASS_PORT as le, EVENT_NODE_VISIBILITY as ue, EVENT_RENDER_START as de, EVENT_RENDER_END as pe, ATTRIBUTE_JTK_ENDPOINT as ce, CLASS_SURFACE_EDGE_DRAGGING as fe, CLASS_SURFACE_SELECTED_ELEMENT as ge, CLASS_SURFACE_SELECTED_CONNECTION as ve, EVENT_INTERNAL_VERTEX_UPDATED as he, EVENT_GROUP_RESIZE as me, ATTRIBUTE_JTK_SOURCE_PORT_TYPE as ye, ATTRIBUTE_JTK_TARGET_PORT_TYPE as be, EVENT_DIRECT_RENDER_RELAYOUT as _e, EVENT_CANVAS_CLICK as Ee, EVENT_CANVAS_DBL_CLICK as je } from "./constants.js";
import { AttributeExtractor as ke, ObjectValueExtractor as Se } from "./value-sources.js";
import { GridProfile as xe } from "./surface-grid-profile.js";
import { MagnetizeProfile as Pe } from "./surface-magnetize-profile.js";
import { PropertyMapper as Ie } from "./property-mappings/property-mapper.js";
import { filterNull as Te, merge as we, log as Ge, uuid as ze, forEach as Me, isString as Re, extend as Ae, isObject as De, removeWithFunction as Ce, populate as Fe } from "../ui-core/util/util.js";
import { isGroup as Oe, isNodeInsideAGroup as Ze, isNestedGroup as $e, isNode as Le, isEdge as Ve, Edge as Ne, isPort as Be, resolveNode as He, Group as We, Node as Ue, resolveNodeId as qe, resolvePortId as Ke, Port as Je } from "../core/model/graph.js";
import { Selection as Xe } from "../core/selection.js";
import { APPEND_TO_CURRENT as Ye } from "../core/undo-redo/undo-redo.js";
import { data as Qe } from "../core/util.js";
import { att as et, ATTRIBUTE_MANAGED as tt, SOURCE as nt, TARGET as ot } from "../ui-core/core/constants.js";
import { EVENT_ZOOM as it, EVENT_GROUP_REMOVED as rt, EVENT_GROUP_ADDED as at, EVENT_GROUP_MEMBER_ADDED as st, EVENT_GROUP_MEMBER_REMOVED as lt, EVENT_CONNECTION_MOVED as ut, EVENT_CONNECTION_DETACHED as dt, EVENT_NESTED_GROUP_REMOVED as pt, EVENT_GROUP_COLLAPSE as ct, EVENT_GROUP_EXPAND as ft } from "../ui-core/core/event-constants.js";
import { addClass as gt, offsetRelativeToRoot as vt, removeClass as ht, toggleClass as mt, hasClass as yt, isArrayLike as bt, createElementNS as _t, matchesSelector as Et, getSelector as jt, findParent as kt, isNodeList as St } from "../ui-core/browser-ui-renderer/browser-util.js";
import { FALSE as xt, DEFAULT as Pt, TRUE as It } from "../ui-core/common/index.js";
import { SELECTOR_GROUP_CONTAINER as Tt, ATTRIBUTE_JTK_ENABLED as wt, ATTRIBUTE_GROUP_CONTENT as Gt } from "../ui-core/browser-ui-renderer/constants.js";
import { EVENT_MOUSEDOWN as zt, EVENT_MOUSEUP as Mt, EVENT_DRAG_START as Rt, EVENT_DRAG_MOVE as At, EVENT_DRAG_STOP as Dt, EVENT_TAP as Ct, EVENT_DBL_TAP as Ft, EVENT_CONNECTION_DRAG as Ot, EVENT_CONNECTION_ABORT as Zt, EVENT_CONNECTION_CLICK as $t, EVENT_CONNECTION_DBL_CLICK as Lt, EVENT_CONNECTION_TAP as Vt, EVENT_CONNECTION_DBL_TAP as Nt, EVENT_CONNECTION_MOUSEDOWN as Bt, EVENT_CONNECTION_MOUSEUP as Ht, EVENT_CONNECTION_MOUSEOVER as Wt, EVENT_CONNECTION_MOUSEOUT as Ut, EVENT_CONNECTION_CONTEXTMENU as qt, EVENT_ELEMENT_CLICK as Kt, EVENT_CLICK as Jt, EVENT_ELEMENT_TAP as Xt, EVENT_ELEMENT_DBL_TAP as Yt, EVENT_ELEMENT_DBL_CLICK as Qt, EVENT_DBL_CLICK as en, EVENT_ELEMENT_MOUSE_OUT as tn, EVENT_MOUSEOUT as nn, EVENT_ELEMENT_MOUSE_OVER as on, EVENT_MOUSEOVER as rn, EVENT_ELEMENT_MOUSE_MOVE as an, EVENT_MOUSEMOVE as sn, EVENT_ELEMENT_MOUSE_UP as ln, EVENT_ELEMENT_MOUSE_DOWN as un, EVENT_ELEMENT_CONTEXTMENU as dn, EVENT_CONTEXTMENU as pn, EVENT_ENDPOINT_CLICK as cn, EVENT_ENDPOINT_DBL_CLICK as fn } from "../ui-core/browser-ui-renderer/event-constants.js";
import { BrowserJsPlumbInstance as gn, getPositionOnElement as vn } from "../ui-core/browser-ui-renderer/browser-jsplumb-instance.js";
import { DEFAULT_KEY_CONNECTOR as hn, DEFAULT_KEY_ENDPOINTS as mn, DEFAULT_KEY_ENDPOINT as yn, DEFAULT_KEY_ENDPOINT_STYLES as bn, DEFAULT_KEY_ENDPOINT_STYLE as _n, DEFAULT_KEY_HOVER_CLASS as En, DEFAULT_KEY_ANCHOR as jn, DEFAULT_KEY_ANCHORS as kn } from "../ui-core/core/defaults.js";
import { Endpoints as Sn } from "../ui-core/core/endpoint/endpoint.js";
import { isTouchDevice as xn, isMouseDevice as Pn } from "../ui-core/browser-ui-renderer/event-manager.js";
import { OptimisticEventGenerator as In } from "../ui-core/util/event-generator.js";
import { WIDTH as Tn, EVENT_NODE_REMOVED as wn, EVENT_NODE_MOVE_START as Gn, EVENT_NODE_MOVE as zn, EVENT_DESTROY as Mn, EVENT_NODE_MOVE_END as Rn, EVENT_EDGE_REMOVED as An, Parameters as Dn, EVENT_EDGE_ADDED as Cn, EVENT_GROUP_SIZE_CHANGED_UNDO as Fn, EVENT_GROUP_SIZE_CHANGED_REDO as On, EVENT_EDGE_TYPE_CHANGED as Zn, EVENT_EDGE_TARGET_CHANGED as $n, EVENT_EDGE_SOURCE_CHANGED as Ln, EVENT_EDGE_PATH_RESTORED as Vn, EVENT_PORT_ADDED as Nn, EVENT_PORT_REMOVED as Bn, EVENT_EDGE_UPDATED as Hn, EVENT_PORT_UPDATED as Wn, EVENT_SELECT as Un, EVENT_SELECTION_CLEARED as qn, EVENT_DESELECT as Kn, EVENT_DATA_LOAD_START as Jn, EVENT_DATA_APPEND_START as Xn, EVENT_DATA_LOAD_END as Yn, EVENT_DATA_APPEND_END as Qn, EVENT_NODE_ADDED as eo, EVENT_NODE_UPDATED as to, EVENT_GROUP_UPDATED as no, EVENT_GROUP_MOVE_END as oo, EVENT_GROUP_SIZE_CHANGED as io } from "../core/constants.js";
import { EmptyLayout as ro } from "../core/layout/empty-layout.js";
import { Layouts as ao } from "../core/layout/layouts.js";
import { LEFT as so, TOP as lo } from "../ui-core/core/factory/definitions.js";
import { isFilterableDataset as uo } from "../core/filterable-dataset.js";
import { AbsoluteLayout as po } from "../core/layout/absolute-layout.js";
import { UPDATE_NODE_REASON_MOVED as co, UPDATE_GROUP_REASON_RESIZED as fo } from "../core/toolkit.js";
import { TAG_SHAPE as go } from "../browser-ui-shape-library/shape-library-definitions.js";
import { ELEMENT_SVG as vo, SVG_NAMESPACE as ho } from "../ui-core/browser-ui-renderer/svg-util.js";
import { EdgePathEditor as mo } from "../connector-editors/index.js";
import { initializeOrthogonalConnectorEditors as yo } from "../connector-editors-orthogonal/index.js";
import { initializeSegmentedConnectorEditors as bo } from "../connector-editors-segmented/index.js";
import { initializeBezierConnectorEditors as _o } from "../connector-editors-bezier/index.js";
import { Connectors as Eo, _resetConnectorGeometry as jo } from "../ui-core/core/connector/connectors.js";
import { _showOverlays as ko, _hideOverlays as So, Connections as xo } from "../ui-core/core/connector/connections.js";
import { isConnection as Po, Components as Io } from "../ui-core/core/component/component.js";
import { getOffsetPosition as To, getElementSize as wo, setPosition as Go, setElementSize as zo } from "../ui-core/browser-ui-renderer/positioning-and-sizing-utils.js";
import { ElasticGroupManager as Mo } from "./elastic-group-manager.js";
import { computeGroupSize as Ro } from "./group-sizer.js";
var Ao = "pan";
var Do = "select";
var Co = "disabled";
var Fo = "vertexDrawing";
var Oo = "left";
var Zo = "top";
var $o = "selected-vertices";
var Lo = ["color", "lineWidth", "outlineWidth", "outlineColor"];
function Vo(e) {
  return e.endpoints[0].element._jtkVisible !== false && e.endpoints[1].element._jtkVisible !== false;
}
function No(e, t, n) {
  if (t.connections.length === 1) {
    Sn.setVisible(t, n, true);
  } else {
    if (n) {
      Sn.setVisible(t, true, true);
    } else {
      for (var o = 0; o < t.connections.length; o++) {
        if (t.connections[o] !== e && t.connections[o].visible) {
          return;
        }
      }
      Sn.setVisible(t, false, true);
    }
  }
}
function Bo(e) {
  var t = e.value(k);
  var n = e.value(S);
  var o = e.value(x);
  var i = e.value(P);
  var r = e.value(I);
  var a = e.value(T);
  var s = e.value(w);
  var l = e.value(G);
  var u = e.value(z);
  var d = e.value(M);
  var p = e.value(R);
  var c = Te({
    portId: t,
    portType: n,
    source: d,
    target: p,
    edgeType: u
  });
  if (o != null && i != null) {
    c.anchor = [parseFloat(o), parseFloat(i), parseInt(s || "0", 10), parseInt(l || "0", 10), parseFloat(r || "0"), parseFloat(a || "0")];
  }
  return c;
}
function Ho(e) {
  return e.nodeType != null && e.nodeType !== 3 && e.nodeType !== 8;
}
var Wo = function (x) {
  t(I, x);
  var P = n(I);
  function I(t, n) {
    var r;
    var a;
    o(this, I);
    a = P.call(this);
    a.templateRenderer = n;
    e(i(a), "_debug", void 0);
    e(i(a), "_$_magnetizeProfile", void 0);
    e(i(a), "_$_gridProfile", void 0);
    e(i(a), "_$_layoutMap", new Map());
    e(i(a), "dataSource", void 0);
    e(i(a), "toolkitInstance", void 0);
    e(i(a), "_layout", void 0);
    e(i(a), "_addToDragSelectionOnSelect", void 0);
    e(i(a), "_edgePathEditor", void 0);
    e(i(a), "container", void 0);
    e(i(a), "containerElement", void 0);
    e(i(a), "canvasElement", void 0);
    e(i(a), "logicalPorts", void 0);
    e(i(a), "_$_storePositionsInModel", void 0);
    e(i(a), "_$_storeGroupSizesInModel", false);
    e(i(a), "decorators", void 0);
    e(i(a), "vertexList", []);
    e(i(a), "connMap", {});
    e(i(a), "_unrenderedEdges", []);
    e(i(a), "nodeRenderer", void 0);
    e(i(a), "portRenderer", void 0);
    e(i(a), "groupRenderer", void 0);
    e(i(a), "_$_dataLoading", false);
    e(i(a), "_$_suspendRendering", void 0);
    e(i(a), "_refreshAutomatically", void 0);
    e(i(a), "_$_propertyMapper", void 0);
    e(i(a), "_$_shapeLibrary", void 0);
    e(i(a), "_unrenderedVertices", new Map());
    e(i(a), "objectFilter", void 0);
    e(i(a), "_modelTopAttribute", void 0);
    e(i(a), "_modelLeftAttribute", void 0);
    e(i(a), "_modelWidthAttribute", void 0);
    e(i(a), "_modelHeightAttribute", void 0);
    e(i(a), "elementsDraggable", void 0);
    e(i(a), "enhancedView", void 0);
    e(i(a), "_ignoreToolkitEvents", false);
    e(i(a), "view", void 0);
    e(i(a), "dragOptions", void 0);
    e(i(a), "refreshLayoutOnEdgeConnect", void 0);
    e(i(a), "jsplumb", void 0);
    e(i(a), "_useModelForSizes", void 0);
    e(i(a), "_currentElasticGroup", void 0);
    e(i(a), "id", void 0);
    e(i(a), "panZoom", void 0);
    e(i(a), "contentBounds", null);
    e(i(a), "plugins", void 0);
    e(i(a), "pluginMap", new Map());
    e(i(a), "wheelReverse", void 0);
    e(i(a), "_$_simpleEdgeStyles", void 0);
    e(i(a), "directRender", void 0);
    e(i(a), "_directRenderResizeObserver", void 0);
    e(i(a), "_autoPanObserver", void 0);
    e(i(a), "zoomToFitOnLoad", void 0);
    e(i(a), "mode", Ao);
    e(i(a), "autoExitSelectMode", true);
    e(i(a), "_boundToolkitEvents", []);
    e(i(a), "_shapeDefsSvgElement", null);
    a.refreshLayoutOnEdgeConnect = t.refreshLayoutOnEdgeConnect === true;
    a.directRender = t.directRender === true;
    a.elementsDraggable = a.directRender !== true && !(t.elementsDraggable === false);
    var s = we(t.defaults || {}, {
      managedElementsSelector: et(tt, k),
      useSvgContainer: t.useSvgContainer,
      elementsDraggable: a.elementsDraggable
    });
    var l = {
      getCompoundZoom: function e() {
        return a.panZoom.getCompoundZoom();
      },
      container: t.container,
      beforeDetach: function e(t, n) {
        return a.beforeDetach(t, n);
      },
      beforeDrag: function e(t) {
        return a.beforeDrag(t);
      },
      beforeStartDetach: function e(t) {
        return a.beforeStartDetach(t);
      },
      beforeDrop: function e(t) {
        return a.beforeDrop(t);
      },
      connectionEstablished: function e(t) {
        return a._connectionEstablished(t);
      },
      onElementDragInit: function e(t) {
        var n = a.getModelObjectFromElement(t, true);
        if (n != null && Oe(n)) {
          if (a.mode === Fo) {
            return false;
          }
        }
        return null;
      }
    };
    a.logicalPorts = t.logicalPorts === true;
    a.jsplumb = new gn(t.toolkitInstance, l, s);
    a.decorators = c(t.decorators, i(a), t.container);
    a._debug = t.debug === true;
    a._addToDragSelectionOnSelect = t.addToDragSelectionOnSelect === true;
    a.container = t.container;
    a.dataSource = t.dataSource;
    a.toolkitInstance = t.toolkitInstance;
    a.containerElement = a.container;
    a._$_simpleEdgeStyles = t.simpleEdgeStyles !== false;
    if (t.editablePaths) {
      a._edgePathEditor = new mo(i(a));
      yo();
      bo();
      _o();
    }
    a.dragOptions = t.dragOptions || {};
    if (a.dragOptions.autoPan !== false && typeof IntersectionObserver !== "undefined") {
      a._autoPanObserver = new IntersectionObserver(function (e, t) {
        e.forEach(function (e) {
          if (!e.isIntersecting) {
            var t = e.boundingClientRect.y < e.rootBounds.y ? 1 : e.boundingClientRect.y + e.boundingClientRect.height > e.rootBounds.y + e.rootBounds.height ? -1 : 0;
            var n = e.boundingClientRect.x < e.rootBounds.x ? 1 : e.boundingClientRect.x + e.boundingClientRect.width > e.rootBounds.x + e.rootBounds.width ? -1 : 0;
            a.panZoom.startPanRepeat(n, t, a.dragOptions.autoPanDelta || 5, a.dragOptions.autoPanDelta || 5, a.dragOptions.autoPanRepeat || 15, function (e) {
              a.jsplumb.dragManager.canvasPanned({
                x: e.x / a.panZoom.zoom,
                y: e.y / a.panZoom.zoom
              });
            });
          } else {
            a.panZoom.stopPanRepeat();
          }
        });
      }, {
        root: a.container,
        rootMargin: "0px",
        threshold: .99
      });
    }
    a._$_suspendRendering = false;
    a._refreshAutomatically = t.refreshAutomatically !== false;
    a.enhancedView = t.enhancedView !== false;
    a._useModelForSizes = t.useModelForSizes === true;
    a._modelLeftAttribute = t.modelLeftAttribute || a.toolkitInstance.modelLeftAttribute || so;
    a._modelTopAttribute = t.modelTopAttribute || a.toolkitInstance.modelTopAttribute || lo;
    a._modelWidthAttribute = t.modelWidthAttribute || a.toolkitInstance.modelWidthAttribute || Tn;
    a._modelHeightAttribute = t.modelHeightAttribute || a.toolkitInstance.modelHeightAttribute || lo;
    a._$_storePositionsInModel = t.storePositionsInModel !== false;
    a.objectFilter = t.objectFilter || function (e) {
      return true;
    };
    a._$_gridProfile = new xe(t.grid);
    a._$_magnetizeProfile = new Pe(t.magnetize, a._$_gridProfile);
    a._layout = ao.get(ro.type, {
      adapter: new v(i(a)),
      toolkit: a.toolkitInstance,
      container: t.container,
      options: {}
    });
    a._$_storePositionsInModel = t.storePositionsInModel !== false;
    if (t.shapes != null) {
      if (t.shapes.library == null) {
        Ge('Render option "shapes" passed to Surface but no "library" is present. Shape library is not enabled.');
      } else {
        a._$_shapeLibrary = t.shapes.library;
        var u = a._$_shapeLibrary.getShapeTagDefinition(t.shapes.strokeWidth || 2, t.shapes.showLabels, t.shapes.labelAttribute);
        a.registerTag(t.shapes.tagName || go, u);
        a._setupShapeDefs();
      }
    }
    if (t.events) {
      for (var p in t.events) {
        a.bind(p, t.events[p]);
      }
    }
    a.setView(t.view);
    if (t.propertyMappings != null) {
      a._$_propertyMapper = new Ie(i(a), t.propertyMappings);
    }
    a._bindToolkitEvents();
    a.nodeRenderer = a.createRenderer("node", a.dataSource.getNodeId.bind(a.dataSource), a.dataSource.getNodeType.bind(a.dataSource));
    a.portRenderer = a.createRenderer("port", a.dataSource.getPortId.bind(a.dataSource), a.dataSource.getPortType.bind(a.dataSource));
    a.groupRenderer = a.createRenderer("group", a.dataSource.getNodeId.bind(a.dataSource), a.dataSource.getNodeType.bind(a.dataSource));
    a.id = t.id || ze();
    var f = {
      position: A.relative,
      width: A.nominalSize,
      height: A.nominalSize,
      left: 0,
      top: 0,
      clazz: D
    };
    if (t.useSvgContainer) {
      f.type = vo;
      f.ns = ho;
    }
    a.canvasElement = a._createElement(f, a.containerElement);
    a.jsplumb.setContainer(a.canvasElement);
    a._setSelectors();
    a._bindToJsPlumb();
    if (a.dragOptions.filter != null) {
      a.jsplumb.addDragFilter(a.dragOptions.filter, true);
    }
    if (a.dragOptions.constrainFunction != null) {
      a.jsplumb.setDragConstrainFunction(a.dragOptions.constrainFunction);
    }
    if (a._$_gridProfile.grid != null) {
      a.jsplumb.setDragGrid(a._$_gridProfile.grid);
    }
    a.wheelReverse = t.wheel && t.wheel.reverse === true;
    gt(a.containerElement, $);
    if (xn()) {
      gt(a.containerElement, L);
    }
    if (Pn()) {
      gt(a.containerElement, V);
    }
    if (a.directRender) {
      a._configureDirectRender();
    }
    if (t.enablePan === false) {
      gt(a.containerElement, N);
    }
    var g = function e(t, n, o) {
      var r = function e(t) {
        var r = t.srcElement || t.target;
        if (r === a.containerElement || r === a.canvasElement || a.panZoom.background.owns(r)) {
          var s = t.button === 2 && o != null ? o : n;
          a.fire(s, i(a), t);
        }
      };
      a.jsplumb.on(a.containerElement, t, r);
    };
    g(Ct, Ee, pn);
    g(Ft, je);
    a.zoomToFitOnLoad = t.zoomToFit === true;
    a.panZoom = new d({
      viewport: a.jsplumb.viewport,
      viewportElement: a.containerElement,
      canvasElement: a.canvasElement,
      getOffset: function e(t) {
        return a.jsplumb.getOffset(t);
      },
      getOffsetRelativeToRoot: function e(t) {
        return vt(t);
      },
      getSize: function e(t) {
        return a.jsplumb.getSize(t);
      },
      consumeRightClick: t.consumeRightClick,
      idFunction: function e(t) {
        return a.jsplumb.getId(t);
      },
      directRender: a.directRender,
      enablePan: !a.directRender && t.enablePan !== false,
      wheel: a._getWheelOptions(t),
      enableAnimation: t.enableAnimation,
      clamp: t.clamp,
      clampToBackground: t.clampToBackground,
      clampToBackgroundExtents: t.clampToBackgroundExtents,
      panFilter: t.panFilter,
      panWithMetaKey: t.panWithMetaKey,
      events: (r = {}, e(r, C, function (e, t, n, o, i) {
        a.fire(C, {
          x: e,
          y: t,
          zoom: n,
          oldZoom: o,
          event: i
        });
      }), e(r, it, function (e, t, n, o, i) {
        a.jsplumb.setZoom(n);
        a.fire(it, {
          x: e,
          y: t,
          zoom: n,
          oldZoom: o,
          event: i
        });
      }), e(r, zt, function () {
        gt(a.containerElement, B);
        gt(document.body, H);
      }), e(r, Mt, function () {
        ht(a.containerElement, B);
        ht(document.body, H);
      }), r),
      zoom: t.zoom,
      zoomRange: t.zoomRange
    });
    a.autoExitSelectMode = t.autoExitSelectMode !== false;
    var h = t.plugins || [];
    a.plugins = [];
    Me(h, function (e) {
      a._addPlugin(e, true);
    });
    a.bind(F, function () {
      a.relayoutPanZoom();
    });
    a.bind(wn, function (e) {
      a.panZoom.remove(e.el);
      a.relayoutPanZoom();
    });
    a.bind(rt, function (e) {
      a.panZoom.remove(e.el);
      if (e.childrenRemoved === false) {
        for (var t = 0; t < e.children.length; t++) {
          var n = a.jsplumb.getManagedElement(e.children[t].id);
          if (n) {
            a.panZoom.restore(n);
          }
        }
      }
      a.relayoutPanZoom();
    });
    a.jsplumb.bind(Rt, function (e) {
      if (a.elementsDraggable && !a.panZoom.isPinchZooming() && a.mode !== Co) {
        a.toolkitInstance.openTransaction(e.dragGroup && e.dragGroupMemberSpec ? Ye : null);
        a.contentBounds = a.panZoom.getBoundsInfo();
        if (a.dragOptions.addHelperClasses !== false) {
          gt(a.containerElement, Z);
          Me(a.containerElement.querySelectorAll("." + O), function (e) {
            ht(e, O);
          });
        }
        var t = a.getModelObjectFromElement(e.el);
        if (t.group) {
          var n = a.getGroupDefinition(t.group);
          if (n.elastic && e.e.shiftKey !== true) {
            var o = n.allowShrinkFromOrigin === true;
            var r = e.e.ctrlKey === true || e.e.metaKey === true;
            if (r) {
              o = !o;
            }
            a._currentElasticGroup = new Mo(i(a), t, t.group, n, o);
            a._sizeElasticGroupFrame(e.e);
          }
        }
        a.fire(Gn, {
          domEl: e.el,
          pos: To(e.el),
          elementId: a.jsplumb.getId(e.el),
          originalPosition: e.originalPosition,
          vertex: t
        });
        a._autoPanObserver && a._autoPanObserver.observe(e.el);
        return {
          vertex: t
        };
      } else {
        return false;
      }
    });
    a.jsplumb.bind(At, function (e) {
      var t = e.el;
      var n = t.jtk;
      var o = (n === null || n === void 0 ? void 0 : n.node) || (n === null || n === void 0 ? void 0 : n.group) || e.payload.vertex;
      var r = {
        el: t,
        vertex: o,
        pos: e.pos,
        e: e.e,
        type: o.objectType,
        originalPosition: e.originalPosition,
        renderer: i(a)
      };
      if (a._$_magnetizeProfile.constant && !Ze(r.vertex) && !$e(r.vertex)) {
        a._setMagnetizedPosition(r.vertex.id, r.pos.x, r.pos.y, true, a._layout);
      }
      if (a._currentElasticGroup != null && a._currentElasticGroup.focusVertex.id === o.id) {
        a._sizeElasticGroupFrame(e.e, e.pos);
      }
      a.fire(zn, r);
    });
    a.jsplumb.bind(Dt, function (e) {
      a.panZoom.stopPanRepeat();
      try {
        var t = [];
        var n = {};
        var o = new Map();
        if (a.dragOptions.addHelperClasses !== false) {
          ht(a.containerElement, Z);
          Me(a.containerElement.querySelectorAll("." + O), function (e) {
            ht(e, O);
          });
        }
        Me(e.elements, function (r) {
          a._autoPanObserver && a._autoPanObserver.unobserve(r.el);
          if (a.dragOptions.addHelperClasses !== false) {
            gt(r.el, O);
          }
          var s = r.el;
          var l = a.getModelObjectFromElement(r.el);
          if (l != null && (Le(l) || Oe(l))) {
            var u = {
              el: s,
              vertex: l,
              pos: r.pos,
              e: e.e,
              type: l.objectType,
              originalPosition: r.originalPos,
              renderer: i(a)
            };
            var d = Ze(u.vertex) || $e(u.vertex);
            r.redrawResult.c.forEach(function (e) {
              var t = e.edge;
              if (t != null && t.geometry != null) {
                a.dataSource.setEdgeGeometry(t, Eo.exportGeometry(e.connector), i(a));
              }
            });
            var p = d ? a._$_layoutMap.get(u.vertex.group.id) : a._layout;
            var c = p.getPosition(u.vertex.id);
            u.originalPosition = {
              x: c.x,
              y: c.y
            };
            if (r.dropGroup == null) {
              if (a._$_magnetizeProfile.afterDrag && !d) {
                t.push({
                  id: u.vertex.id,
                  x: u.pos.x,
                  y: u.pos.y
                });
              } else {
                n[u.vertex.id] = {
                  id: u.vertex.id,
                  x: u.pos.x,
                  y: u.pos.y,
                  layout: p
                };
                if (d) {
                  var f = u.vertex.group;
                  var g = a.view.getGroupDefinition(a.dataSource.getNodeType(f.data));
                  if (!(a._useModelForSizes && g.elastic)) {
                    o.set(f.id, [f, g]);
                  }
                }
              }
            }
          }
        });
        for (var r in n) {
          a._setDirectPosition(r, n[r].x, n[r].y, false, n[r].layout);
        }
        o.forEach(function (e) {
          var t = a._autoSizeAGroup(e[0], e[1], null, null, false),
            n = t.movedElements,
            o = t.resizedGroups,
            i = t.layout;
          a._updateMovedElements(n);
          a._trackGroupSizeChanges(o);
        });
        a._setMagnetizedPositions(t, !a._$_magnetizeProfile.repositionDraggedElement, a._layout);
        if (a._currentElasticGroup && a._useModelForSizes) {
          a._persistElasticGroup(a._currentElasticGroup);
        }
      } catch (e) {
        Ge("Could not process dragged vertex", e);
      } finally {
        a._currentElasticGroup && a._currentElasticGroup.cleanup();
        a._currentElasticGroup = null;
        a.toolkitInstance.commitTransaction(true);
      }
    });
    var m = t.layout || {
      type: po.type
    };
    a.setLayout(m, true);
    if (t.modelEvents) {
      t.modelEvents.forEach(function (e) {
        a.bindModelEvent(e.event, e.selector, function (t, n, o) {
          setTimeout(function () {
            return e.callback(t, n, o);
          }, 0);
        });
      });
    }
    a._loadStartFn();
    var y = a._loadExistingData(t);
    a._loadEndFn(!y);
    return a;
  }
  r(I, [{
    key: "getShapeLibrary",
    value: function e() {
      return this._$_shapeLibrary;
    }
  }, {
    key: "_persistElasticGroup",
    value: function e(t) {
      var n = t.persist(),
        o = n.movedElements,
        i = n.resizedGroups;
      this._updateMovedElements(o);
      this._trackGroupSizeChanges(i, true);
    }
  }, {
    key: "getNodes",
    value: function e() {
      return this.vertexList.filter(Le);
    }
  }, {
    key: "getGroups",
    value: function e() {
      return this.vertexList.filter(Oe);
    }
  }, {
    key: "setView",
    value: function e(t) {
      this.view = new f(this.toolkitInstance, this.dataSource.getModel(), t, this.jsplumb);
    }
  }, {
    key: "isSuspendRendering",
    value: function e() {
      return this._$_suspendRendering;
    }
  }, {
    key: "isDataLoading",
    value: function e() {
      return this._$_dataLoading;
    }
  }, {
    key: "_sizeElasticGroupFrame",
    value: function e(t, n) {
      if (this._currentElasticGroup != null) {
        if (n != null) {
          this._currentElasticGroup.setFocusPosition(n, t);
        }
      }
    }
  }, {
    key: "addPlugin",
    value: function e(t) {
      return this._addPlugin(t, true);
    }
  }, {
    key: "_addPlugin",
    value: function e(t, n) {
      var o = Re(t) ? t : t.type;
      var i = Re(t) ? {} : t.options || {};
      i.viewport = this.jsplumb.viewport;
      var r = qo.get(o);
      if (r != null) {
        r.initialise(this, i);
        this.plugins.push(r);
        this.pluginMap.set(o, r);
        if (n) {
          this.fire(W, r);
        }
        return r;
      }
    }
  }, {
    key: "getPlugin",
    value: function e(t) {
      return this.pluginMap.get(t);
    }
  }, {
    key: "setHoverSuspended",
    value: function e(t) {
      this.jsplumb.hoverSuspended = t;
    }
  }, {
    key: "setMode",
    value: function t(n, o) {
      var i = this,
        r;
      var a = (r = {}, e(r, Ao, function () {
        i.panZoom.setEnabled(true);
      }), e(r, Do, function () {
        i.jsplumb.clearDragSelection();
        i.panZoom.setEnabled(false);
      }), e(r, Co, function () {
        i.jsplumb.clearDragSelection();
        i.panZoom.setEnabled(false);
      }), e(r, Fo, function () {
        i.panZoom.setEnabled(false);
      }), r);
      if (a[n]) {
        this.mode = n;
        a[n]();
        if (n === A.select && !o) {
          this.toolkitInstance.clearSelection();
        }
        this.fire(U, n);
      } else {
        throw new TypeError("Surface: unknown mode '" + n + "'");
      }
    }
  }, {
    key: "selectEdges",
    value: function e(t) {
      return this._selectEdges(t, "getEdges");
    }
  }, {
    key: "selectAllEdges",
    value: function e(t) {
      return this._selectEdges(t, "getAllEdges");
    }
  }, {
    key: "getOffset",
    value: function e(t) {
      return this.jsplumb.getOffset(t);
    }
  }, {
    key: "setPosition",
    value: function e(t, n, o, i, r) {
      var a = this;
      this.toolkitInstance.openTransaction(Ye);
      try {
        var s = this._setPosition({
            vertex: t,
            x: n,
            y: o,
            doRevalidate: true,
            doNotRunGroupResize: r
          }),
          l = s.movedElements,
          u = s.resizedGroups;
        if (this._$_gridProfile.snap) {
          Ae(l, this.snapToGrid(t));
        }
        var d = this.getObjectInfo(t);
        if (d.obj) {
          var p = l[d.id];
          this._fireNodeMovedEvent(t, p.current.x, p.current.y, p.original.x, p.original.y);
          if (i) {
            setTimeout(function () {
              return a.jsplumb.revalidate(d.el);
            }, 0);
          }
        }
      } catch (e) {
        Ge("Exception thrown when setting position", e);
      } finally {
        this.toolkitInstance.commitTransaction();
      }
    }
  }, {
    key: "addClass",
    value: function e(t, n) {
      var o = this;
      if (t.constructor === Xe) {
        t.eachVertex(function (e, t) {
          return o.addClass(t, n);
        });
        t.eachEdge(function (e, t) {
          return o.addClass(t, n);
        });
      } else {
        this._classOperation(t, n, gt);
      }
    }
  }, {
    key: "removeClass",
    value: function e(t, n) {
      var o = this;
      if (t.constructor === Xe) {
        t.eachVertex(function (e, t) {
          return o.removeClass(t, n);
        });
        t.eachEdge(function (e, t) {
          return o.removeClass(t, n);
        });
      } else {
        this._classOperation(t, n, ht);
      }
    }
  }, {
    key: "toggleClass",
    value: function e(t, n) {
      var o = this;
      if (t.constructor === Xe) {
        t.eachVertex(function (e, t) {
          return o.toggleClass(t, n);
        });
        t.eachEdge(function (e, t) {
          return o.toggleClass(t, n);
        });
      } else {
        this._classOperation(t, n, mt);
      }
    }
  }, {
    key: "hasClass",
    value: function e(t, n) {
      if (t != null) {
        var o = this.getObjectInfo(t);
        if (o.el) {
          return yt(o.el, n);
        } else {
          if (Ho(t)) {
            return yt(t, n);
          } else {
            return false;
          }
        }
      }
    }
  }, {
    key: "on",
    value: function e(t, n, o, i) {
      this.jsplumb.on(t, n, o, i);
    }
  }, {
    key: "off",
    value: function e(t, n, o) {
      var i = o.__tkWrapper || o;
      this.jsplumb.off(t, n, i);
    }
  }, {
    key: "bindModelEvent",
    value: function e(t, n, o) {
      var i = this;
      var r = function e(t, n) {
        var r = i.getObjectInfo(n);
        if (r.obj != null) {
          o(t, n, r);
        }
      };
      o.__tkWrapper = r;
      this.jsplumb.on(this.canvasElement, t, n, r);
    }
  }, {
    key: "unbindModelEvent",
    value: function e(t, n) {
      if (n.__tkWrapper != null) {
        this.jsplumb.off(this.canvasElement, t, n.__tkWrapper);
      }
    }
  }, {
    key: "setDraggable",
    value: function e(t, n) {
      var o = this.getObjectInfo(t);
      if (o.obj) {
        this.jsplumb.setDraggable(o.el, n);
      }
    }
  }, {
    key: "getCoordinates",
    value: function e(t, n) {
      var o = this.getObjectInfo(t);
      if (o.obj) {
        var i = this.jsplumb.getSize(o.el);
        var r = Ze(o.obj) || $e(o.obj) ? o.obj.group : null;
        var a = r != null ? this._$_layoutMap.get(r.id) : this._layout;
        var s = a.getPosition(o.id),
          l = {
            x: s.x,
            y: s.y
          };
        if (n && r != null) {
          var u = this.getRenderedElement(r),
            d = this.jsplumb.getOffset(u),
            p = u.querySelector(Tt);
          l.x += d.x;
          l.y += d.y;
          if (p != null) {
            var c = p.getBoundingClientRect(),
              f = u.getBoundingClientRect();
            l.x += c.left - f.left;
            l.y += c.top - f.top;
          }
        }
        return {
          x: l.x,
          y: l.y,
          w: i.w,
          h: i.h
        };
      } else {
        return {
          x: 0,
          y: 0,
          w: 0,
          h: 0
        };
      }
    }
  }, {
    key: "snapToGrid",
    value: function e(t, n) {
      n = n || this._$_gridProfile.grid;
      var o;
      if (n) {
        if (t != null) {
          var i = this.getObjectInfo(t);
          if (i.obj) {
            o = i.obj.id;
          }
        }
        var r = this._layout.snapToGrid(n, o);
        this._updateMovedElements(r);
        return r;
      }
    }
  }, {
    key: "fixElement",
    value: function e(t, n, o, i) {
      return this.panZoom.fixElement(t, n, o, i);
    }
  }, {
    key: "unfixElement",
    value: function e(t) {
      this.panZoom.unfixElement(t);
    }
  }, {
    key: "appendElement",
    value: function e(t, n, o) {
      var i = n.x,
        r = n.y,
        a = null;
      this._append(t, true);
      if (o && o.alignX && o.alignX === l.right) {
        a = wo(t, this.getZoom());
        i -= a.w;
      }
      if (o && o.alignY && o.alignY === u.bottom) {
        a = a || wo(t, this.getZoom());
        r -= a.h;
      }
      Go(t, {
        x: i,
        y: r
      });
    }
  }, {
    key: "floatElement",
    value: function e(t, n) {
      this.panZoom.floatElement(t, n);
    }
  }, {
    key: "zoomToFit",
    value: function e(t) {
      this.panZoom.zoomToFit(t);
    }
  }, {
    key: "zoomToFitIfNecessary",
    value: function e(t) {
      this.panZoom.zoomToFitIfNecessary(t);
    }
  }, {
    key: "zoomToElements",
    value: function e(t) {
      this.panZoom.zoomToElements(t);
    }
  }, {
    key: "zoomToBackground",
    value: function e(t) {
      this.panZoom.zoomToBackground(t);
    }
  }, {
    key: "zoomToDecorator",
    value: function e(t) {
      var n = Re(t.decorator) ? this.getDecorator(t.decorator) : t.decorator;
      if (n != null) {
        var o = this.getBoundsInfo();
        var i = n.getBounds();
        this.panZoom.zoomToExtents(Object.assign(t, {
          extents: [o, i]
        }));
      }
    }
  }, {
    key: "zoomToExtents",
    value: function e(t) {
      this.panZoom.zoomToExtents(t);
    }
  }, {
    key: "alignContent",
    value: function e(t) {
      this.panZoom.alignContent(t);
    }
  }, {
    key: "alignContentTop",
    value: function e(t) {
      this.panZoom.alignContentTop(t);
    }
  }, {
    key: "alignContentBottom",
    value: function e(t) {
      this.panZoom.alignContentBottom(t);
    }
  }, {
    key: "alignContentLeft",
    value: function e(t) {
      this.panZoom.alignContentLeft(t);
    }
  }, {
    key: "alignContentRight",
    value: function e(t) {
      this.panZoom.alignContentRight(t);
    }
  }, {
    key: "mapEventLocation",
    value: function e(t) {
      return this.panZoom.mapEventLocation(t);
    }
  }, {
    key: "getLayoutFor",
    value: function e(t) {
      return Ze(t) || $e(t) ? this._$_layoutMap.get(t.group.id) : this._layout;
    }
  }, {
    key: "centerContent",
    value: function e(t) {
      this.panZoom.centerContent(t);
    }
  }, {
    key: "zoomToSelection",
    value: function e(t) {
      var n = this;
      t = t || {};
      var o = function e() {
        if (t.filter) {
          return n.toolkitInstance.filter(t.filter);
        } else {
          return n.toolkitInstance.getSelection();
        }
      };
      var i = t.selection || o(),
        r = [];
      i.eachNode(function (e, t) {
        r.push(n.getRenderedElement(t.id));
      });
      i.eachGroup(function (e, t) {
        r.push(n.getRenderedElement(t.id));
      });
      if (r.length > 0) {
        this.panZoom.zoomToElements({
          elements: r,
          fill: t.fill,
          doNotZoomIfVisible: t.doNotZoomIfVisible,
          doNotAnimate: t.doNotAnimate
        });
      }
    }
  }, {
    key: "getPath",
    value: function e(t) {
      return new j(this.toolkitInstance.getPath(t), this);
    }
  }, {
    key: "setGrid",
    value: function e(t) {
      this._$_gridProfile.grid = t;
      this.jsplumb.setDragGrid(t);
    }
  }, {
    key: "getGrid",
    value: function e() {
      return this._$_gridProfile.grid;
    }
  }, {
    key: "getDragConstrainFunction",
    value: function e() {
      return this.dragOptions.constrainFunction;
    }
  }, {
    key: "getApparentCanvasLocation",
    value: function e() {
      return this.panZoom.getApparentCanvasLocation();
    }
  }, {
    key: "setApparentCanvasLocation",
    value: function e(t, n) {
      return this.panZoom.setApparentCanvasLocation(t, n);
    }
  }, {
    key: "getBoundsInfo",
    value: function e() {
      return this.panZoom.getBoundsInfo();
    }
  }, {
    key: "setZoom",
    value: function e(t, n) {
      return this.panZoom.setZoom(t, n);
    }
  }, {
    key: "setZoomRange",
    value: function e(t, n) {
      return this.panZoom.setZoomRange(t, n);
    }
  }, {
    key: "getZoomRange",
    value: function e() {
      return this.panZoom.getZoomRange();
    }
  }, {
    key: "getZoom",
    value: function e() {
      return this.panZoom.getZoom();
    }
  }, {
    key: "nudgeZoom",
    value: function e(t, n) {
      return this.panZoom.nudgeZoom(t, n);
    }
  }, {
    key: "nudgeWheelZoom",
    value: function e(t, n) {
      this.panZoom.nudgeWheelZoom(t, n);
    }
  }, {
    key: "getPan",
    value: function e() {
      return this.panZoom.getPan();
    }
  }, {
    key: "pan",
    value: function e(t, n, o) {
      this.panZoom.pan(t, n, !o);
    }
  }, {
    key: "setPan",
    value: function e(t, n, o, i) {
      this.panZoom.setPan(t, n, o, i);
    }
  }, {
    key: "setPanAndZoom",
    value: function e(t, n, o, i) {
      this.setPan(t, n, i);
      this.setZoom(o, i);
    }
  }, {
    key: "centerOnAndZoom",
    value: function e(t, n, o) {
      var i = this.getObjectInfo(t);
      if (i.el != null) {
        this.panZoom.centerOnAndZoom({
          bounds: this.jsplumb.viewport._elementMap.get(i.id),
          fillRatio: n,
          doNotAnimate: o
        });
      }
    }
  }, {
    key: "centerOn",
    value: function e(t) {
      var n = this.getObjectInfo(t);
      if (n.el != null) {
        this.panZoom.centerOn({
          bounds: this.jsplumb.viewport._elementMap.get(n.id)
        });
      }
    }
  }, {
    key: "centerOnHorizontally",
    value: function e(t) {
      var n = this.getObjectInfo(t);
      if (n.el != null) {
        this.panZoom.centerOnHorizontally({
          bounds: this.jsplumb.viewport._elementMap.get(n.id)
        });
      }
    }
  }, {
    key: "centerOnVertically",
    value: function e(t) {
      var n = this.getObjectInfo(t);
      if (n.el != null) {
        this.panZoom.centerOnVertically({
          bounds: this.jsplumb.viewport._elementMap.get(n.id)
        });
      }
    }
  }, {
    key: "setClamping",
    value: function e(t) {
      this.panZoom.setClamping(t);
    }
  }, {
    key: "isClamping",
    value: function e() {
      return this.panZoom.isClamping();
    }
  }, {
    key: "getContainer",
    value: function e() {
      return this.jsplumb.getContainer();
    }
  }, {
    key: "isInViewport",
    value: function e(t, n) {
      return this.panZoom.isInViewport(t, n);
    }
  }, {
    key: "fromPageLocation",
    value: function e(t, n) {
      return this.panZoom.fromPageLocation(t, n);
    }
  }, {
    key: "toPageLocation",
    value: function e(t, n) {
      return this.panZoom.toPageLocation(t, n);
    }
  }, {
    key: "findIntersectingVertices",
    value: function e(t, n, o, i, r, a, s) {
      var l = this;
      if (r === true && i === true && a === true) {
        return [];
      }
      var u = this.panZoom.findIntersectingElements(t, n, o, function (e, t, n) {
        var o = l.toolkitInstance.getVertex(n);
        if (o != null) {
          var u = Le(o),
            d = u && Ze(o),
            p = Oe(o),
            c = p && $e(o),
            f = r === true && u,
            g = i === true && p,
            v = a && d,
            h = s && c;
          return !(f || g || v || h);
        } else {
          return false;
        }
      });
      return u.map(function (e) {
        return {
          enclosed: o,
          id: e.id,
          r: e.r,
          v: l.toolkitInstance.getVertex(e.id),
          el: l.getRenderedElement(e.id)
        };
      });
    }
  }, {
    key: "findAllIntersectingVerticesInCanvas",
    value: function e(t, n, o) {
      var i = this;
      var r = this.panZoom.findIntersectingElements(t, n, o, null, true);
      return r.map(function (e) {
        return {
          enclosed: o,
          id: e.id,
          r: e.r,
          v: i.toolkitInstance.getVertex(e.id),
          el: i.getRenderedElement(e.id)
        };
      });
    }
  }, {
    key: "findIntersectingNodes",
    value: function e(t, n, o, i) {
      return this.findIntersectingVertices(t, n, o, true, false, i, true);
    }
  }, {
    key: "findIntersectingGroups",
    value: function e(t, n, o, i) {
      return this.findIntersectingVertices(t, n, o, true, false, true, true);
    }
  }, {
    key: "positionElementAt",
    value: function e(t, n, o, i, r, a) {
      this.panZoom.positionElementAt(t, n, o, i, r, a);
    }
  }, {
    key: "positionElementAtEventLocation",
    value: function e(t, n, o, i) {
      this.panZoom.positionElementAtEventLocation(t, n, o, i);
    }
  }, {
    key: "positionElementAtPageLocation",
    value: function e(t, n, o, i, r) {
      this.panZoom.positionElementAtPageLocation(t, n, o, i, r);
    }
  }, {
    key: "repaintEverything",
    value: function e() {
      this.jsplumb.repaintEverything();
    }
  }, {
    key: "reload",
    value: function e() {
      if (this.dataSource instanceof Xe) {
        this.dataSource.reload();
      } else {
        Ge("JSPLUMB: Surface not rendering a selection, `reload` has no effect.");
      }
    }
  }, {
    key: "destroy",
    value: function e() {
      if (this._edgePathEditor != null) {
        this._edgePathEditor.destroy();
      }
      if (this._directRenderResizeObserver) {
        this._directRenderResizeObserver.disconnect();
      }
      if (this._autoPanObserver) {
        this._autoPanObserver.disconnect();
      }
      if (this._shapeDefsSvgElement != null && this._shapeDefsSvgElement.parentNode != null) {
        this._shapeDefsSvgElement.parentNode.removeChild(this._shapeDefsSvgElement);
      }
      var t;
      for (var n = 0; n < this._boundToolkitEvents.length; n++) {
        try {
          t = this._boundToolkitEvents[n];
          this.dataSource.unbind(t.event, t.fn);
        } catch (e) {
          Ge("WARN: Could not unbind event " + t.event + " during destroy operation.");
        }
      }
      this.canvasElement.parentNode.removeChild(this.canvasElement);
      ht(this.containerElement, $);
      ht(this.containerElement, q);
      ht(this.containerElement, N);
      Me(this.plugins, function (e) {
        e.destroy();
      });
      this.panZoom.destroy();
      this.panZoom = null;
      this.fire(Mn, this);
    }
  }, {
    key: "batch",
    value: function e(t) {
      this.setSuspendEvents(true);
      this.dataSource.batch(t);
      this.setSuspendEvents(false);
    }
  }, {
    key: "rotate",
    value: function e(t, n) {
      var o = this;
      var i = this.getObjectInfo(t);
      if (i.el) {
        i.obj.data.rotation = n;
        var r = this.jsplumb.rotate(i.el, n);
        r.c.forEach(function (e) {
          var t = e.edge,
            n = e.connector;
          if (n.geometry) {
            o.toolkitInstance.setEdgeGeometry(t, Eo.exportGeometry(n), o);
          }
        });
        this.fire(K, {
          obj: i.obj,
          rotation: n
        });
      }
    }
  }, {
    key: "setMagnetizedPosition",
    value: function e(t, n, o, i) {
      this.toolkitInstance.openTransaction(Ye);
      var r = this._setPosition({
        info: null,
        vertex: t,
        x: n,
        y: o,
        doNotUpdateElement: i,
        magnetize: true,
        doRevalidate: true
      });
      this.toolkitInstance.commitTransaction();
      return r;
    }
  }, {
    key: "storePositionInModel",
    value: function e(t) {
      var n = typeof t === "string" ? t : t.id;
      var o = typeof t === "string" ? null : t.pos;
      var i = typeof t === "string" ? Oo : t.leftAttribute || Oo,
        r = typeof t === "string" ? Zo : t.topAttribute || Zo,
        a = t.obj || this.dataSource.getVertex(n);
      if (a && (this._$_storePositionsInModel || t.force)) {
        var s = Ze(a) || $e(a) ? this._$_layoutMap.get(a.group.id) : this._layout;
        o = o || s.getPosition(n);
        Qe(a.data, i, o.x);
        Qe(a.data, r, o.y);
      }
      return o;
    }
  }, {
    key: "storePositionsInModel",
    value: function e(t) {
      var n = this;
      t = t || {};
      var o = t.leftAttribute || A.left,
        i = t.topAttribute || A.top;
      var r = this._layout.getPositions();
      r.forEach(function (e, t) {
        var r = n.toolkitInstance.getNode(t) || n.toolkitInstance.getGroup(t);
        if (r) {
          Qe(r.data, o, e.x);
          Qe(r.data, i, e.y);
        }
      });
      this.toolkitInstance.eachGroup(function (e, t) {
        var r = t.getMembers(),
          a = n._$_layoutMap.get(t.id).getPositions();
        for (e = 0; e < r.length; e++) {
          Qe(r[e].data, o, a.get(r[e].id).x);
          Qe(r[e].data, i, a.get(r[e].id).y);
        }
      });
    }
  }, {
    key: "magnetize",
    value: function e(t) {
      this._doMagnetize(t, null, null, false);
    }
  }, {
    key: "magnetizeAtPoint",
    value: function e(t) {
      this._doMagnetize(null, t, null, false);
    }
  }, {
    key: "magnetizeAtEvent",
    value: function e(t) {
      this._doMagnetize(null, null, t, false);
    }
  }, {
    key: "gather",
    value: function e(t) {
      this._doMagnetize(t, null, null, true);
    }
  }, {
    key: "setVisible",
    value: function e(t, n, o) {
      var i = this;
      if (t == null) return;
      var r = function e(t) {
        switch (t.type) {
          case Ne.objectType:
            i._toggleEdge(t.obj, n, o);
            break;
          case Ue.objectType:
          case We.objectType:
            i._toggleNode(t.obj, t.el, n, o);
            break;
          case Je.objectType:
            i._togglePort(t.obj, n);
            break;
        }
      };
      this._applyFunctionToObject(t, r);
    }
  }, {
    key: "setOverlaysVisible",
    value: function e(t, n) {
      var o = this;
      for (var i = arguments.length, r = new Array(i > 2 ? i - 2 : 0), a = 2; a < i; a++) {
        r[a - 2] = arguments[a];
      }
      if (t == null) {
        return;
      }
      var s = [];
      if (uo(t)) {
        t.eachEdge(function (e, t) {
          s.push(o.getRenderedConnection(t.id));
        });
      } else if (bt(t)) {
        for (var l = 0; l < t.length; l++) {
          if (Ve(t[l])) {
            s.push(this.getRenderedConnection(t[l].id));
          }
        }
      } else if (Ve(t)) {
        s.push(this.getRenderedConnection(t.id));
      }
      Me(s, function (e) {
        if (n) {
          ko.apply(void 0, [e].concat(r));
        } else {
          So.apply(void 0, [e].concat(r));
        }
      });
    }
  }, {
    key: "getObjectInfo",
    value: function e(t) {
      if (t == null) {
        return {
          id: null,
          obj: null,
          el: null,
          type: null
        };
      } else if (Po(t)) {
        var n = t.edge;
        return {
          obj: n,
          id: n.id,
          type: Ne.objectType,
          el: t.connector.canvas
        };
      } else {
        if (Ho(t)) {
          var o = this.getModelObjectFromElement(t, true);
          if (o != null) {
            return {
              el: this.getRenderedElement(o.getFullId()),
              id: o.getFullId(),
              type: o.objectType,
              obj: o
            };
          } else {
            return {
              id: null,
              obj: null,
              el: null,
              type: null
            };
          }
        } else {
          var i = this.toolkitInstance.getObjectInfo(t);
          if (i.obj != null) {
            if (i.type === Ne.objectType) {
              return Ae({
                el: this.getRenderedConnection(i.id).connector.canvas
              }, i);
            } else {
              return Ae({
                el: this.getRenderedElement(i.obj)
              }, i);
            }
          } else {
            return Ae({
              el: null
            }, i);
          }
        }
      }
    }
  }, {
    key: "refresh",
    value: function e(t) {
      var n = this;
      if (!this._$_suspendRendering && (!t || this._refreshAutomatically)) {
        this._layout.layout(function (e) {
          n.updateVertexPositions(e.positions, e.sizes);
          n.fire(J, {
            bounds: n.getBoundsInfo(),
            positions: e.positions,
            sizes: n._layout.getSizes()
          });
          if (n.directRender) {
            n.relayoutPanZoom();
          }
          n.jsplumb.repaintEverything();
        }, this._$_magnetizeProfile.afterLayout);
      }
    }
  }, {
    key: "_decorate",
    value: function e(t, n) {
      var o = this;
      t.decorate({
        surface: this,
        adapter: this._layout.adapter,
        layout: this._layout,
        setAbsolutePosition: function e(t, n) {
          return Go(t, n);
        },
        toolkit: this.toolkitInstance,
        bounds: n.bounds,
        positions: n.positions,
        fixElement: function e(n, i, r, a) {
          var s = Array.from(t.fixedElements).find(function (e) {
            return e.el === n;
          });
          if (s == null) {
            s = o.fixElement(n, i, r, a);
            t.fixedElements.add(s);
          }
          return s;
        },
        floatElement: function e(n, i) {
          var r = Array.from(t.floatedElements).find(function (e) {
            return e.el === n;
          });
          if (r == null) {
            r = {
              el: n,
              pos: i,
              constraints: {}
            };
            o.floatElement(n, i);
            t.floatedElements.add(r);
          }
          return r;
        },
        appendElement: function e(n, i, r) {
          var a = Array.from(t.appendedElements).find(function (e) {
            return e.el === n;
          });
          if (a == null) {
            a = {
              el: n,
              pos: i,
              constraints: r
            };
            o.appendElement(n, i, r);
            t.appendedElements.add(a);
          }
          return a;
        }
      });
    }
  }, {
    key: "relayout",
    value: function e(t) {
      var n = this;
      if (!this._$_suspendRendering) {
        this._layout.relayout(t, function (e) {
          n.updateVertexPositions(e.positions, e.sizes);
          n._relayoutGroups();
          for (var t = 0; t < n.decorators.length; t++) {
            if (!n.decorators[t].incremental) {
              n.decorators[t].clear();
            }
            n._decorate(n.decorators[t], e);
          }
          if (n.directRender) {
            n.relayoutPanZoom();
          }
          n.jsplumb.repaintEverything();
          n.fire(F, {
            bounds: n.getBoundsInfo(),
            positions: e.positions,
            sizes: n._layout.getSizes()
          });
        }, this._$_magnetizeProfile.afterLayout);
      }
    }
  }, {
    key: "getModelObjectFromElement",
    value: function e(t, n) {
      var o = this;
      var i = function e(t) {
        var i;
        var r = t.getAttribute(tt);
        if (r != null) {
          var a = o.toolkitInstance.getVertex(r);
          if (a != null) {
            i = a;
          }
        }
        if (i == null) {
          var s = t.getAttribute(k) || t.getAttribute(Y) || t.getAttribute(Q);
          if (s != null) {
            var l = kt(t, et(tt), o.jsplumb.getContainer(), false);
            if (l != null) {
              var u = l.getAttribute(tt);
              var d = o.toolkitInstance.getNode(u) || o.toolkitInstance.getGroup(u);
              if (d) {
                var p = t.getAttribute(S) || t.getAttribute(ye) || t.getAttribute(be) || Pt;
                return d.addPort({
                  id: s,
                  type: p
                });
              }
            }
          }
        }
        if (i == null && n === true) {
          var c = t.parentElement;
          if (c != null && c !== o.jsplumb.getContainer()) {
            return e(c);
          } else {
            return null;
          }
        } else {
          return i;
        }
      };
      return i(t);
    }
  }, {
    key: "setLayout",
    value: function e(t, n) {
      if (t) {
        this._layout = this._createLayout(t);
        if (!n) {
          this.refresh();
        }
      }
    }
  }, {
    key: "adHocLayout",
    value: function e(t) {
      if (t) {
        var n = this._layout;
        this.jsplumb.select().each(function (e) {
          jo(e.connector);
        });
        this.setLayout(t);
        this._layout = n;
        this.fire(X);
      }
    }
  }, {
    key: "adHocGroupLayout",
    value: function e(t, n) {
      if (n) {
        var o = this.toolkitInstance.getObjectInfo(t);
        if (o.obj) {
          var i = this._$_layoutMap.get(o.id);
          this._$_layoutMap.set(o.id, this._createGroupLayout(n, o.obj));
          this.relayoutGroup(o.obj, h);
          this._$_layoutMap.set(o.id, i);
        }
      }
    }
  }, {
    key: "getModelPositionAttributes",
    value: function e() {
      return [this._modelLeftAttribute, this._modelTopAttribute];
    }
  }, {
    key: "getLabelLocationAttribute",
    value: function e(t) {
      var n = this.view.getEdgeDefinition(this.dataSource.getEdgeType(t.data || {}));
      return n.labelLocationAttribute || g;
    }
  }, {
    key: "setEnabled",
    value: function e(t, n) {
      var o = t.getFullId();
      var i = this.getRenderedEndpoint(o) || this.getRenderedElement(o) || this.getRenderedPort(o);
      if (i != null) {
        if (Ho(i)) {
          if (n) {
            i.removeAttribute(wt);
          } else {
            i.setAttribute(wt, xt);
          }
        } else {
          i.enabled = n;
        }
      }
      return n;
    }
  }, {
    key: "getRenderedElement",
    value: function e(t) {
      if (t == null) {
        return null;
      }
      var n = Re(t) ? t : t.getFullId();
      var o = n.split(this.toolkitInstance.portSeparator),
        i = this.jsplumb.getManagedElement(o[0]);
      if (i && o.length > 1) {
        var r = i.querySelector(p(k, o[1])) || i.querySelector(p(Y, o[1])) || i.querySelector(p(Q, o[1]));
        if (r == null) {
          if (this.logicalPorts === true || i.getAttribute(k) === o[1] || i.getAttribute(Y) === o[1] || i.getAttribute(Q) === o[1] || i.getAttribute(ee) === o[1] || i.getAttribute(te) === o[1] || i.getAttribute(ne) === o[1]) {
            return i;
          }
        } else {
          return r;
        }
      } else {
        return i;
      }
    }
  }, {
    key: "getRenderedVertex",
    value: function e(t) {
      return this.jsplumb.getManagedElement(t);
    }
  }, {
    key: "getRenderedPort",
    value: function e(t) {
      var n = t.split(".");
      var o = this.getRenderedElement(n[0]);
      var i = o ? o.querySelector(p(k, n[1])) || o.querySelector(p(Q, n[1])) || o.querySelector(p(Y, n[1])) || o : null;
      return i == null ? null : i.tagName.toLowerCase() === oe ? null : i;
    }
  }, {
    key: "getRenderedConnection",
    value: function e(t) {
      return this.connMap[t];
    }
  }, {
    key: "getRenderedEndpoint",
    value: function e(t) {
      return this.jsplumb.getEndpoint(t);
    }
  }, {
    key: "setSuspendRendering",
    value: function e(t, n) {
      this.panZoom.setSuspendRendering(t);
      this._$_suspendRendering = t;
      this.jsplumb.setSuspendDrawing(t);
      if (n) {
        this.refresh();
      }
    }
  }, {
    key: "expandGroup",
    value: function e(t) {
      this.jsplumb.expandGroup(typeof t === "string" ? t : t.id);
    }
  }, {
    key: "collapseGroup",
    value: function e(t) {
      this.jsplumb.collapseGroup(typeof t === "string" ? t : t.id);
    }
  }, {
    key: "toggleGroup",
    value: function e(t) {
      this.jsplumb.toggleGroup(typeof t === "string" ? t : t.id);
    }
  }, {
    key: "autoSizeGroups",
    value: function e(t) {
      var n = this;
      this.jsplumb.groupManager.forEach(function (e) {
        var o = n.dataSource.getGroup(e.id);
        if (o) {
          var i = n.view.getGroupDefinition(n.dataSource.getNodeType(o.data));
          n._autoSizeAGroup(o, i, null, null, t);
        }
      });
    }
  }, {
    key: "sizeGroupToFit",
    value: function e(t, n) {
      if (t) {
        var o = this.view.getGroupDefinition(this.dataSource.getNodeType(t.data));
        var i = this._autoSizeAGroup(t, o, null, null, n),
          r = i.movedElements,
          a = i.resizedGroups;
        i.layout;
        this._updateMovedElements(r);
        this._trackGroupSizeChanges(a);
      }
    }
  }, {
    key: "setSize",
    value: function e(t, n, o) {
      var i = this.getObjectInfo(t);
      if (i.obj && i.el) {
        var r = Ze(i.obj) || $e(i.obj) ? this._$_layoutMap.get(i.obj.group.id) : this._layout;
        r.setSize(i.obj.getFullId(), {
          w: n,
          h: o
        });
        zo(i.el, n, o);
      }
    }
  }, {
    key: "getConnectionForEdge",
    value: function e(t) {
      return this.connMap[t.getId()];
    }
  }, {
    key: "getConnectionsForEdges",
    value: function e(t) {
      var n = [];
      for (var o = 0; o < t.length; o++) {
        n.push(this.connMap[t[o].getId()]);
      }
      return n;
    }
  }, {
    key: "_connectionEdited",
    value: function e(t) {
      var n = Eo.exportGeometry(t.connector);
      this.dataSource.setEdgeGeometry(t.edge, n, this);
    }
  }, {
    key: "onDestroy",
    value: function e(t) {
      this.bind(Mn, t);
    }
  }, {
    key: "_setPosition",
    value: function e(t) {
      var n = {};
      var o = t.info || this.getObjectInfo(t.vertex);
      var i = {};
      if (o && o.obj) {
        var r = o.obj;
        if (Be(o.obj)) {
          r = o.obj.getParent();
        }
        var a = r.id;
        if (t.doNotUpdateLayout !== true) {
          var s = Ze(r) || $e(r);
          var l = s ? this._$_layoutMap.get(r.group.id) : this._layout;
          if (t.magnetize) {
            Ae(n, this._setMagnetizedPosition(a, t.x, t.y, true, l));
          } else {
            Ae(n, l.setPosition(a, t.x, t.y));
          }
          if (s && !t.doNotRunGroupResize) {
            var u = this.view.getGroupDefinition(this.dataSource.getNodeType(r.group.data));
            var d = this._autoSizeAGroup(r.group, u, null, null, false);
            if (d != null) {
              Object.assign(n, d.movedElements);
              Object.assign(i, d.resizedGroups);
            }
          }
        }
        var p = t.size ? t.size.w : null;
        var c = t.size ? t.size.h : null;
        if (o.el != null) {
          this.jsplumb.viewport.updateElement(this.jsplumb.getId(o.el), t.x, t.y, p, c, null);
          if (t.doNotUpdateElement !== true && !t.magnetize) {
            Go(o.el, {
              x: t.x,
              y: t.y
            });
          }
          if (t.doRevalidate === true) {
            this.jsplumb.revalidate(o.el);
          }
        }
        if (t.storeInModel && this._$_storePositionsInModel) {
          this.storePositionInModel(a);
        }
      }
      return {
        movedElements: n,
        resizedGroups: i
      };
    }
  }, {
    key: "_relayoutGroups",
    value: function e() {
      var t = this;
      this.dataSource.eachGroup(function (e, n) {
        if (t.objectFilter(n)) {
          t.relayoutGroup(n, m);
        }
      });
    }
  }, {
    key: "relayoutGroup",
    value: function e(t, n) {
      var o = this;
      var i = Oe(t) ? t : this.toolkitInstance.getGroup(t);
      var r = this._$_layoutMap.get(i.id);
      if (r == null) {
        return;
      }
      r.relayout(null, function (e) {
        e.positions.forEach(function (e, t) {
          Go(o.jsplumb.getManagedElement(t), e);
          if (o._$_storePositionsInModel) {
            o.storePositionInModel({
              id: t,
              pos: e
            });
          }
        });
      }, false);
      var a = true;
      if (this._$_dataLoading && this._useModelForSizes) {
        a = false;
      }
      if (a) {
        this.sizeGroupToFit(i);
      }
      var s = this.jsplumb.getManagedElement(i.id);
      this.jsplumb.revalidate(s);
      this.fire(ie, {
        group: i,
        layout: r,
        el: s
      });
    }
  }, {
    key: "_getViewportPosition",
    value: function e(t) {
      return this._getViewportPositionById(t.id);
    }
  }, {
    key: "_getViewportPositionById",
    value: function e(t) {
      return this.jsplumb.viewport.getPosition(t);
    }
  }, {
    key: "repaint",
    value: function e(t, n) {
      var o = this.getObjectInfo(t);
      if (o.el != null && o.obj != null) {
        if (n) {
          var i = this._layout.getPosition(o.id);
          Go(o.el, i);
        }
        this.jsplumb.revalidate(o.el);
        this.fire(re, o);
      }
    }
  }, {
    key: "_resolveInitialVertexSize",
    value: function e(t, n) {
      var o = Le(t) ? this.jsplumb.defaults.nodeSize || {
        w: 100,
        h: 80
      } : this.jsplumb.defaults.groupSize || {
        w: 300,
        h: 300
      };
      var i = t.data[this._modelWidthAttribute];
      var r = t.data[this._modelHeightAttribute];
      if (i == null) {
        var a;
        i = (a = n.defaultSize) === null || a === void 0 ? void 0 : a.w;
      }
      if (r == null) {
        var s;
        r = (s = n.defaultSize) === null || s === void 0 ? void 0 : s.h;
      }
      if (i == null) {
        i = o === null || o === void 0 ? void 0 : o.w;
      }
      if (r == null) {
        r = o === null || o === void 0 ? void 0 : o.h;
      }
      return {
        w: i,
        h: r
      };
    }
  }, {
    key: "vertexRendered",
    value: function e(t, n, o, i) {
      if (!n) {
        throw new Error("Cannot render vertex");
      }
      var r = n;
      n.setAttribute("data-jtk-vertex", t.getFullId());
      var a = Le(t),
        s = Oe(t);
      var l = a ? ae : s ? se : Be(t) ? le : "";
      gt(n, l);
      r.jtk = r.jtk || {};
      r.jtk[t.objectType.toLowerCase()] = t;
      r.jtk.vertex = t;
      this.jsplumb.manage(n, t.id);
      if (Le(t) || Oe(t)) {
        this._extractEndpointFromElementAndDefinition(n, t);
        this.vertexList.push(t);
      }
      if (a) {
        n.jtk = {
          node: t
        };
      }
      if (s) {
        n.jtk = {
          group: t
        };
      }
      if (this._useModelForSizes) {
        var u = this._resolveInitialVertexSize(t, o),
          d = u.w,
          p = u.h;
        zo(n, d, p);
        t.data[this._modelWidthAttribute] = d;
        t.data[this._modelHeightAttribute] = p;
      }
      if (s || Le(t) && t.group == null) {
        this._append(n);
      } else if (Le(t)) {
        this._appendNodeToGroup(n, t, t.group);
      }
      this.jsplumb.viewport.refreshElement(t.id);
      if (t.data.rotation != null) {
        this.jsplumb.rotate(n, t.data.rotation);
      }
      if (Le(t)) {
        var c = {
          el: n,
          id: t.id,
          vertex: t
        };
        if (t.group == null) {
          c.pos = this._layout.vertexAdded(c, i);
        }
        this.fireNodeAdded(c);
      } else if (Oe(t)) {
        var f = {
          el: n,
          vertex: t,
          id: t.id
        };
        var g = o;
        var v = Object.assign({
          el: n,
          id: t.id
        }, g);
        var h = this.jsplumb.addGroup(t, v);
        var m = o.layout || {
          type: po.type
        };
        this._$_layoutMap.set(t.id, this._createGroupLayout(m, t));
        var y = this._layout;
        if (t.group != null) {
          this.jsplumb.addToGroup(t.group.id, n);
          y = this._$_layoutMap.get(t.group.id);
        }
        f.pos = y.vertexAdded({
          el: n,
          id: t.id,
          vertex: t
        }, i);
        this.fire(at, f);
        if (!this._$_dataLoading) {
          if (g.minSize) {
            var b = this.jsplumb.getSize(h.el);
            if (b.w < g.minSize.w || b.h < g.minSize.h) {
              zo(h.el, Math.max(g.minSize.w, b.w), Math.max(g.minSize.h, b.h));
            }
          }
        }
      }
      this._unrenderedVertices.delete(t.id);
      if (this._$_dataLoading && this.templateRenderer.asynchronous) {
        this._loadEndFn(false);
      } else {
        this._flushUnrenderedEdges();
        this.refresh(true);
      }
    }
  }, {
    key: "_createElement",
    value: function e(t, n) {
      var o = {
        width: t.width,
        height: t.height,
        position: t.position || A.absolute
      };
      var i = t.atts || {};
      if (t.display != null) {
        o.display = t.display;
      }
      if (t.id != null) {
        i.id = t.id;
      }
      if (t.top != null) {
        o.top = t.top + "px";
      }
      if (t.left != null) {
        o.left = t.left + "px";
      }
      if (t.right != null) {
        o.right = t.right + "px";
      }
      if (t.bottom != null) {
        o.bottom = t.bottom + "px";
      }
      var r = _t(t.ns, t.type || A.div, o, t.clazz, i);
      if (n != null) {
        n.appendChild(r);
      }
      return r;
    }
  }, {
    key: "startEditingPath",
    value: function e(t, n) {
      if (this._edgePathEditor != null) {
        this._edgePathEditor.startEditing(t, n);
      } else {
        Ge("ERROR: cannot start path edit - surface not created with 'editablePaths:true'");
      }
    }
  }, {
    key: "stopEditingPath",
    value: function e() {
      if (this._edgePathEditor != null) {
        this._edgePathEditor.stopEditing();
      } else {
        Ge("ERROR: cannot edit paths - surface not created with 'editablePaths:true'");
      }
    }
  }, {
    key: "clearPathEdits",
    value: function e(t) {
      if (this._edgePathEditor != null) {
        return this._edgePathEditor.clearEdits(t);
      } else {
        Ge("ERROR: cannot edit paths - surface not created with 'editablePaths:true'");
        return false;
      }
    }
  }, {
    key: "_getWheelOptions",
    value: function e(t) {
      var n = t.wheel != null ? t.wheel.zoom !== false : true;
      var o = t.wheel ? t.wheel.filter : null;
      return {
        filter: o ? function (e) {
          var t = e.srcElement || e.target;
          return !Et(t, o);
        } : null,
        reverse: t.wheel != null ? t.wheel.reverse === true : false,
        pan: t.wheel != null ? t.wheel.pan === true : false,
        zoom: !this.directRender && n,
        useMetaKey: t.wheel != null ? t.wheel.useMetaKey === true : false,
        axis: t.wheel ? t.wheel.axis : "both"
      };
    }
  }, {
    key: "_fireNodeMovedEvent",
    value: function e(t, n, o, i, r, a) {
      if (a || n !== i || o !== r) {
        var s = this.getObjectInfo(t);
        if (s.el) {
          this.fire(Rn, {
            el: s.el,
            id: s.id,
            pos: {
              x: n,
              y: o
            },
            vertex: s.obj,
            e: null,
            type: s.obj.objectType,
            originalPosition: {
              x: i,
              y: r
            },
            renderer: this
          });
        }
      }
    }
  }, {
    key: "_applyFunctionToObject",
    value: function e(t, n) {
      var o = this;
      var i;
      if (uo(t)) {
        i = {
          nodes: [],
          edges: []
        };
        t.eachVertex(function (e, t) {
          i.nodes.push(n(o.getObjectInfo(t)));
        });
        t.eachEdge(function (e, t) {
          i.edges.push(n(o.getObjectInfo(t)));
        });
      } else if (bt(t)) {
        i = [];
        for (var r = 0; r < t.length; r++) {
          i.push(n(this.getObjectInfo(t[r])));
        }
      } else {
        i = n(this.getObjectInfo(t));
      }
      return i;
    }
  }, {
    key: "updateVertexPositions",
    value: function e(t, n) {
      var o = this;
      t.forEach(function (e, t) {
        o._setPosition({
          vertex: t,
          x: e.x,
          y: e.y,
          doNotUpdateElement: false,
          doNotUpdateLayout: true,
          doRevalidate: false,
          size: n.get(t)
        });
        if (o._$_storePositionsInModel) {
          o.storePositionInModel({
            id: t,
            pos: e
          });
        }
      });
    }
  }, {
    key: "_doMagnetize",
    value: function e(t, n, o, i) {
      var r = {
        options: {}
      };
      if (n) {
        r.origin = n;
      }
      if (i) {
        r.options.gather = i;
      }
      if (o) {
        r.origin = this.panZoom.mapEventLocation(o);
      }
      if (t) {
        var a = this.getObjectInfo(t);
        if (a.obj) {
          r.focus = a.obj.id;
        }
      }
      if (this._$_gridProfile.grid) {
        r.options.grid = this._$_gridProfile.grid;
      }
      var s = this._layout.magnetize(r, false);
      this._updateMovedElements(s);
      this.jsplumb.repaintEverything();
    }
  }, {
    key: "_toggleNode",
    value: function e(t, n, o, i) {
      if (n) {
        n.style.display = o ? "block" : "none";
        n._jtkVisible = o;
        this.fire(ue, {
          node: t,
          el: n,
          state: o
        });
        if (!i) {
          var r = this.dataSource.getAllEdgesFor(t);
          for (var a = 0; a < r.length; a++) {
            if (!o) {
              this._toggleEdge(r[a], o, i);
            } else {
              var s = He(r[a].source),
                l = He(r[a].target),
                u = s === t ? l : s,
                d = this.isVisible(u);
              if (d) {
                this._toggleEdge(r[a], o, i);
              }
            }
          }
        }
      }
    }
  }, {
    key: "_togglePort",
    value: function e(t, n) {
      var o = t.getFullId(),
        i = this.jsplumb.getEndpoint(o);
      Sn.setVisible(i, n);
    }
  }, {
    key: "isVisible",
    value: function e(t) {
      var n = this;
      return this._applyFunctionToObject(t, function (e) {
        switch (e.type) {
          case Ne.objectType:
            var t = n.getConnectionForEdge(e.obj);
            return t ? t.visible : false;
          case Ue.objectType:
          case We.objectType:
            return e.el._jtkVisible !== false;
        }
      });
    }
  }, {
    key: "beforeDrop",
    value: function e(t) {
      var n = t.connection,
        o = this.jsplumb.getModelObjectFromEndpoint(n.endpoints[0]),
        i = this.jsplumb.getModelObjectFromEndpoint(t.dropEndpoint),
        r = t.connection.edge;
      if (r == null) return this.dataSource.beforeConnect(o, i, Io.getData(n), true);else return this.dataSource.beforeMoveConnection(o, i, r);
    }
  }, {
    key: "beforeDrag",
    value: function e(t) {
      var n = t.endpoint.parameters,
        o = this.toolkitInstance.getNode(n.vertexId),
        i = n.portId ? o.addPort({
          id: n.portId,
          type: n.portType
        }) : o,
        r = t.endpoint.edgeType,
        a = this.dataSource.beforeStartConnect(i, r);
      if (this._debug) {
        var s = this.view.getEdgeDefinition(r) || {};
        console.log("\n------------------------\nDrag started with edge type `" + r + "`, having definition :");
        console.log(s);
        console.log("Edge source is a " + i.objectType + ", having type [" + i.getType() + "], with definition :");
        var l = this.view["get" + i.objectType + "Definition"](i.getType()) || {};
        console.log(l);
        console.log("Toolkit response to drag start :");
        console.log(a);
        console.log("---------------------------\n");
      }
      if (a === false) {
        if (t.endpoint.isTemporarySource) {
          this.jsplumb.deleteEndpoint(t.endpoint);
        }
      } else if (De(a)) {
        if (this._$_propertyMapper != null) {
          var u = this._$_propertyMapper.computeTypes(a);
          if (u.length > 0) {
            a.type = (a.type || "") + " " + u;
          }
        }
      }
      return a;
    }
  }, {
    key: "beforeDetach",
    value: function e(t, n) {
      var o = t.edge;
      return o == null ? true : this.dataSource.beforeDetach(o.source, o.target, o);
    }
  }, {
    key: "beforeStartDetach",
    value: function e(t) {
      if (this.directRender) {
        return false;
      }
      var n = this.jsplumb.getModelObjectFromEndpoint(t.endpoint),
        o = t.connection.edge;
      return o == null ? true : this.dataSource.beforeStartDetach(n, o);
    }
  }, {
    key: "_fireEdgeRemoved",
    value: function e(t) {
      this.fire(An, {
        sourceNodeId: qe(t.source),
        targetNodeId: qe(t.target),
        sourcePortId: Ke(t.source),
        targetPortId: Ke(t.target),
        sourceId: t.source.getFullId(),
        targetId: t.target.getFullId(),
        source: t.source,
        target: t.target,
        edge: t
      });
    }
  }, {
    key: "_loadStartFn",
    value: function e() {
      this._$_dataLoading = true;
      this.setSuspendRendering(true);
      this.fire(de);
    }
  }, {
    key: "_appendStartFn",
    value: function e() {
      this.setSuspendRendering(true);
    }
  }, {
    key: "_loadEndFn",
    value: function e(t) {
      if (!this.templateRenderer.asynchronous || this._unrenderedVertices.size === 0) {
        this.setSuspendRendering(false, false);
        this._flushUnrenderedEdges();
        if (!t) {
          this.relayout();
          this.jsplumb.repaintEverything();
        }
        if (this.zoomToFitOnLoad && !t) {
          this.zoomToFit();
        }
        this._$_dataLoading = false;
        this.fire(pe);
      }
    }
  }, {
    key: "_appendEndFn",
    value: function e() {
      this.setSuspendRendering(false);
      this._flushUnrenderedEdges();
      this.refresh();
      this.jsplumb.setSuspendDrawing(false, true);
      this.fire(pe);
    }
  }, {
    key: "_appendNodeToGroup",
    value: function e(t, n, o) {
      var i = this.jsplumb.getManagedElement(o.id);
      if (i) {
        var r = i.querySelector(et(Gt)) || i;
        r.appendChild(t);
        this.jsplumb.addToGroup(o.id, t);
        this._$_layoutMap.get(o.id).vertexAdded({
          el: t,
          vertex: n
        });
        this.panZoom.suspend(t);
      }
    }
  }, {
    key: "_extractEndpointsFromVertex",
    value: function e(t) {
      var n = [];
      var o = t.querySelectorAll([et(ce), oe].join(","));
      Me(o, function (e) {
        var t = Bo(new ke(e));
        n.push([t, e]);
      });
      if (t.getAttribute(ce) != null) {
        var i = Bo(new ke(t));
        n.push([i, t]);
      }
      return n;
    }
  }, {
    key: "_extractEndpointFromElementAndDefinition",
    value: function e(t, n) {
      var o = this;
      var i = this._extractEndpointsFromVertex(t);
      Me(i, function (e) {
        var i = a(e, 2),
          r = i[0],
          s = i[1];
        var l;
        var u = Ae({}, r);
        if (r.portId != null) {
          l = n.addPort({
            id: r.portId,
            type: r.portType
          });
          u.uuid = l.getFullId();
          u.data = l.data;
        }
        if (u.uuid == null) {
          if (s === t) {
            u.uuid = n.id;
          }
        }
        var d = o.view.getPortDefinition(r.portType || Pt);
        if (d != null) {
          Ae(u, d);
        }
        u.element = t;
        var p = o.jsplumb._internal_newEndpoint(u);
        Io.mergeParameters(p, Te({
          vertexId: n.id,
          portId: r.portId,
          portType: r.portType,
          edgeType: r.edgeType
        }));
      });
    }
  }, {
    key: "_doRenderNode",
    value: function e(t, n) {
      var o = this.jsplumb.getManagedElement(t.id);
      if (o == null) {
        var i = this.getNodeDefinition(t);
        if (i.ignore !== true) {
          this._unrenderedVertices.set(t.id, {
            vertex: t,
            def: i,
            eventInfo: n
          });
          this.nodeRenderer(t, t.data, t, i, n);
        }
      }
    }
  }, {
    key: "_doRenderGroup",
    value: function e(t, n) {
      var o = this.jsplumb.getManagedElement(t.id);
      if (o == null) {
        var i = this.getGroupDefinition(t);
        if (i.ignore !== true) {
          if (i.ghost === true) {
            i.ghostProxyParent = this.containerElement;
          }
          this._unrenderedVertices.set(t.id, {
            vertex: t,
            def: i,
            eventInfo: n
          });
          this.groupRenderer(t, t.data, t, i, n);
        }
      }
    }
  }, {
    key: "_flushUnrenderedEdges",
    value: function e() {
      var t = [];
      for (var n = 0; n < this._unrenderedEdges.length; n++) {
        var o = this._maybeRenderEdge(this._unrenderedEdges[n]);
        if (o == null) {
          t.push(this._unrenderedEdges[n]);
        }
      }
      this._unrenderedEdges = t;
    }
  }, {
    key: "_prepareConnectionParams",
    value: function e(t) {
      var n = this;
      var o = this.dataSource.getEdgeType(t.data),
        i = {
          type: o,
          edgeType: o,
          data: t.data,
          cost: t.getCost(),
          directed: t.isDirected(),
          geometry: t.geometry,
          ports: [],
          parameters: {
            edgeId: t.id
          },
          edge: t
        },
        r = this.view.getEdgeDefinition(o);
      (function (e) {
        if (r) {
          for (var t = 0; t < e.length; t++) {
            if (r[e[t]]) i[e[t]] = r[e[t]];
          }
        }
      })([hn, mn, yn, bn, _n, "cssClass", En]);
      if (i.anchor && !i.anchors) {
        i.anchors = [i.anchor, i.anchor];
        delete i.anchor;
      }
      if (i.endpoint && !i.endpoints) {
        i.endpoints = [i.endpoint, i.endpoint];
        delete i.endpoint;
      }
      var a = function e(t, n, o, i, r) {
        if (i && i[o]) {
          var a = t[n] || [i[o], i[o]];
          a[r] = i[o];
          t[n] = a;
        }
      };
      var s = function e(o, r, s) {
        if (Be(o)) {
          var l = o.getParent(),
            u = o.getFullId(),
            d = n.jsplumb.getEndpoint(u) || n.getRenderedPort(u);
          i[r] = d || n.jsplumb.getManagedElement(n.dataSource.getNodeId(l.data));
          var p = n.view.getPortDefinition(o.getType());
          a(i, kn, jn, p, s);
          a(i, mn, yn, p, s);
          i.ports[s] = o.id;
        } else {
          var c = t[r].id;
          var f = n.jsplumb.getEndpoint(c);
          i[r] = f || n.jsplumb.getManagedElement(c);
        }
      };
      s(t.source, nt, 0);
      s(t.target, ot, 1);
      return i;
    }
  }, {
    key: "getDecorator",
    value: function e(t) {
      return this.decorators.find(function (e) {
        return e.id === t;
      });
    }
  }, {
    key: "__bindToToolkit",
    value: function e(t, n) {
      this._boundToolkitEvents.push({
        event: t,
        fn: n
      });
      this.dataSource.bind(t, n, true);
    }
  }, {
    key: "_nodeAdded",
    value: function e(t) {
      var n = t.node;
      if (this.objectFilter(n)) {
        this._doRenderNode(n, t.eventInfo);
      }
    }
  }, {
    key: "_groupAdded",
    value: function e(t) {
      var n = t.group;
      if (this.objectFilter(n)) {
        this._doRenderGroup(n, t.eventInfo);
      }
    }
  }, {
    key: "_nodeRemoved",
    value: function e(t) {
      var n = t.parentGroup ? t.parentGroup.id : Ze(t.node) || $e(t.node) ? t.node.group.id : null;
      var o = n != null ? this._$_layoutMap.get(n) : this._layout;
      var i = o.getPosition(t.node.id);
      o.vertexRemoved(t.node, false);
      var r = this.jsplumb.getManagedElement(t.node.id);
      if (t.parentGroup) {
        this.jsplumb.removeFromGroup(t.parentGroup.id, r, true);
        if (!t.parentGroupIsBeingRemoved) {
          this.relayoutGroup(t.parentGroup, y);
        }
      }
      this.fire(wn, {
        vertex: t.node,
        el: r,
        pos: i
      });
      this.jsplumb.unmanage(r, false);
      this.templateRenderer.cleanupVertex(t.node.id, r);
      Ce(this.vertexList, function (e) {
        return e.id === t.node.id;
      });
      delete r["jtk"];
      this.refresh(true);
    }
  }, {
    key: "_groupRemoved",
    value: function e(t) {
      var n = t.group;
      var o = t.parentGroup ? t.parentGroup.id : null;
      var i = o != null ? this._$_layoutMap.get(o) : this._layout;
      var r = i.getPosition(t.group.id);
      i.vertexRemoved(n, false);
      var a = this.jsplumb.getManagedElement(n.id);
      if (t.parentGroup) {
        this.jsplumb.removeFromGroup(t.parentGroup.id, a, true);
        if (!t.parentGroupIsBeingRemoved) {
          this.relayoutGroup(t.parentGroup, y);
        }
      }
      var s = this.jsplumb.removeGroup(n.id, t.removeChildren, true, true),
        l = null;
      if (s != null) {
        l = {};
        for (var u in s) {
          var d = this.toolkitInstance.getVertex(u);
          if (d != null) {
            if (d.group == null) {
              this.panZoom.restore(d.id);
            }
            this._setPosition({
              vertex: d,
              x: s[u].x,
              y: s[u].y,
              doNotUpdateElement: true
            });
            l[d.id] = {
              x: s[u].x,
              y: s[u].y
            };
          } else {
            Ge("Could not reposition element removed from group - no DOM node found.");
          }
        }
      }
      this.fire(rt, {
        el: a,
        vertex: n,
        children: t.children,
        childrenRemoved: t.removeChildren,
        childPositions: l,
        pos: r
      });
      this.templateRenderer.cleanupVertex(n.id, a);
      Ce(this.vertexList, function (e) {
        return e.id === t.group.id;
      });
      delete a["jtk"];
    }
  }, {
    key: "_edgeAdded",
    value: function e(t) {
      if (t.source !== this) {
        var n = t.edge;
        if (this.objectFilter(n.source) && this.objectFilter(n.target)) {
          var o = this.view.getEdgeDefinition(this.dataSource.getEdgeType(n.data || {}));
          if (o && o.ignore === true) return;
          this._enqueueEdge(n, true);
        }
      }
    }
  }, {
    key: "_connectionEstablished",
    value: function e(t) {
      var n = this;
      if (this.dragOptions.addHelperClasses !== false) {
        ht(this.containerElement, fe);
      }
      if (t.connection.edge != null) {
        return;
      }
      var o = t.sourceEndpoint.parameters,
        i = t.targetEndpoint.parameters,
        r = o.portId,
        a = o.vertexId,
        s = i.portId,
        l = i.vertexId,
        u = t.sourceEndpoint.parameters[Dn.portType] || Pt,
        d = this.view.getPortDefinition(u),
        p = d != null && d.edgeType ? d.edgeType : t.sourceEndpoint.parameters[Dn.edgeType] || Pt;
      var c = this.jsplumb.getModelObjectFromEndpoint(t.sourceEndpoint),
        f = this.jsplumb.getModelObjectFromEndpoint(t.targetEndpoint),
        g = c.getFullId(),
        v = f.getFullId();
      var h = {
        sourceVertexId: a,
        sourcePortId: r,
        targetVertexId: l,
        targetPortId: s,
        type: p,
        source: c,
        target: f,
        sourceId: g,
        targetId: v,
        sourceType: c.objectType,
        targetType: f.objectType
      };
      var m = this.dataSource.edgeFactory(p, Io.getData(t.connection) || {}, function (e) {
        e = Fe(e, c, null, true);
        e.type = e.type || p;
        h.edge = n.dataSource.addEdge({
          source: g,
          target: v,
          cost: t.connection.cost,
          directed: t.connection.directed,
          data: e,
          addedByMouse: true
        }, n);
        if (h.edge != null) {
          n.connMap[h.edge.getId()] = t.connection;
          t.connection.edge = h.edge;
          var o = n._prepareConnectionParams(h.edge);
          Io.setType(t.connection, [A.default, o.type].join(" "), o.data);
          h.addedByMouse = true;
          h.connection = t.connection;
          n.fire(Cn, h);
        } else {
          n.jsplumb.deleteConnection(t.connection);
        }
      }, function () {
        n.jsplumb.deleteConnection(t.connection);
      }, h);
      if (m === false) {
        this.jsplumb.deleteConnection(t.connection);
      }
    }
  }, {
    key: "_graphClearStart",
    value: function e() {
      var t = this;
      this.jsplumb.reset();
      for (var n = 0; n < this.decorators.length; n++) {
        this.decorators[n].reset({
          remove: function e(t) {
            return t.parentNode && t.parentNode.removeChild(t);
          }
        });
      }
      this.panZoom.reset();
      Me(this.plugins, function (e) {
        return e.reset();
      });
      Me(this.vertexList, function (e) {
        var n = t.jsplumb.getManagedElement(e.id);
        if (n != null) {
          t.templateRenderer.cleanupVertex(e.id, n);
        }
      });
      this._layout && this._layout._reset();
      this.vertexList.length = 0;
      this.connMap = {};
      this._$_layoutMap.clear();
      this.jsplumb.setZoom(this.getZoom());
    }
  }, {
    key: "_graphClearEnd",
    value: function e() {}
  }, {
    key: "_bindToolkitEvents",
    value: function e() {
      var t = this;
      this.__bindToToolkit(st, function (e) {
        var n = t.jsplumb.getManagedElement(e.vertex.id);
        var o = t.jsplumb.getManagedElement(e.group.id);
        if (n && o) {
          t.panZoom.suspend(n);
          if (e.pos) {
            if (e.vertex.data[t._modelLeftAttribute] != null) {
              e.vertex.data[t._modelLeftAttribute] = e.pos.x;
            }
            if (e.vertex.data[t._modelTopAttribute] != null) {
              e.vertex.data[t._modelTopAttribute] = e.pos.y;
            }
          }
          t.fire(st, {
            vertex: e.vertex,
            group: e.group,
            uigroup: t.jsplumb.getGroup(e.group.id),
            sourceGroup: e.sourceGroup,
            pos: e.pos,
            el: n,
            groupEl: t.jsplumb.getManagedElement(e.group.id)
          });
          if (e.source !== t) {
            t.jsplumb.addToGroup(e.group.id, n);
          }
          t._layout.vertexRemoved(e.vertex, false);
          if (e.sourceGroup) {
            t._$_layoutMap.get(e.sourceGroup.id).vertexRemoved(e.vertex, false);
          }
          t._$_layoutMap.get(e.group.id).vertexAdded({
            el: n,
            pos: e.pos,
            vertex: e.vertex
          });
          if (!t._$_suspendRendering) {
            t.relayoutGroup(e.group, e.vertexIsNew ? b : _);
          }
        }
      });
      this.__bindToToolkit(lt, function (e) {
        var n = t.jsplumb.getManagedElement(e.vertex.id);
        if (n) {
          var o = null;
          if (e.source !== t) {
            t.jsplumb.removeFromGroup(e.group.id, n);
          }
          t._$_layoutMap.get(e.group.id).vertexRemoved(e.vertex, false);
          if (e.targetGroup) {
            o = t._$_layoutMap.get(e.targetGroup.id);
            o.vertexAdded({
              el: n,
              vertex: e.vertex
            });
          } else {
            o = t._layout;
            o.vertexAdded({
              el: n,
              vertex: e.vertex
            });
            var i = t.jsplumb.getOffset(n);
            t._layout.setPosition(e.vertex.id, i.x, i.y);
            if (t._$_storePositionsInModel) {
              t.storePositionInModel(e.vertex.id);
            }
          }
          t.panZoom.restore(n);
          t.fire(lt, Ae(e, {
            el: n,
            groupEl: t.jsplumb.getManagedElement(e.group.id),
            pos: o.getPosition(e.vertex.id)
          }));
          if (!t._$_suspendRendering) {
            t.relayoutGroup(e.group, y);
          }
        }
      });
      this.__bindToToolkit(Fn, function (e) {
        if (e.source === t) {
          t._setGroupElementSize(e.group, e.originalGroupSize);
        }
      });
      this.__bindToToolkit(On, function (e) {
        if (e.source === t) {
          t._setGroupElementSize(e.group, e.newGroupSize);
        }
      });
      this.__bindToToolkit(An, function (e) {
        if (!t._ignoreToolkitEvents && e.source !== t) {
          var n = e.edge;
          var o = t.connMap[n.getId()];
          if (o) {
            if (t.dataSource.debugEnabled) console.log("Renderer", "removing edge", n);
            t._fireEdgeRemoved(n);
            delete t.connMap[n.getId()];
            t.jsplumb.deleteConnection(o, {
              fireEvent: false
            });
          }
        }
      });
      this.__bindToToolkit(Zn, function (e) {
        if (!t._ignoreToolkitEvents && e.source !== t) {
          var n = e.obj;
          var o = t.connMap[n.getId()];
          if (o) {
            var i = t.view.getEdgeDefinition(e.newType);
            if (i && i.ignore === true) return;
            if (i.endpoint || i.endpoints) {
              xo.replaceEndpoint(o, 0, i.endpoints ? i.endpoints[0] : i.endpoint);
              xo.replaceEndpoint(o, 1, i.endpoints ? i.endpoints[1] : i.endpoint);
            }
            if (i.connector) {
              xo._setConnector(o, i.connector);
            }
            Io.setType(o, e.newType, n.data);
          }
        }
      });
      this.__bindToToolkit($n, function (e) {
        if (!t._ignoreToolkitEvents) {
          var n = e.edge;
          var o = t.connMap[n.getId()];
          var i = n.target.getFullId();
          var r = t.jsplumb.getManagedElement(i) || t.jsplumb.getEndpoint(i) || t.getRenderedElement(i);
          if (o) {
            t.jsplumb.silently(function () {
              if (r != null) {
                if (t.dataSource.debugEnabled) console.log("DEBUG: target change", o);
                t.jsplumb.setTarget(o, r);
              } else {
                delete t.connMap[n.getId()];
                t.jsplumb.deleteConnection(o, {
                  force: true,
                  fireEvent: false
                });
              }
            });
          } else {
            if (r != null) {
              if (t.dataSource.debugEnabled) {
                Ge("DEBUG: Target for Edge " + n.getId() + " changed to Node " + r.id + "; we have no valid connection.");
              }
            }
          }
        }
      });
      this.__bindToToolkit(Ln, function (e) {
        if (!t._ignoreToolkitEvents) {
          var n = e.edge;
          var o = t.connMap[n.getId()];
          var i = n.source.getFullId();
          var r = t.jsplumb.getManagedElement(i) || t.jsplumb.getEndpoint(i) || t.getRenderedElement(i);
          if (o) {
            t.jsplumb.silently(function () {
              if (r != null) {
                t.jsplumb.setSource(o, r);
              } else {
                delete t.connMap[n.getId()];
                t.jsplumb.deleteConnection(o, {
                  force: true,
                  fireEvent: false
                });
              }
            });
          } else {
            if (r != null) {
              if (t.dataSource.debugEnabled) {
                Ge("DEBUG: Source for Edge " + n.getId() + " changed to Node " + n.source.getFullId() + "; we have no valid connection.");
              }
            }
          }
        }
      });
      this.__bindToToolkit(Vn, function (e) {
        if (e.renderer.id === t.id) {
          var n = t.getRenderedConnection(e.edge.getId());
          if (e.geometry != null) {
            Eo.importGeometry(n.connector, e.geometry);
            t.jsplumb.revalidate(n.source);
          } else {
            jo(n.connector);
          }
        }
      });
      this.__bindToToolkit(Nn, function (e) {
        var n = t.jsplumb.getManagedElement(e.vertex.id);
        var o = t.view.getPortDefinition(t.dataSource.getPortType(e.data));
        if (o.isEndpoint) {
          var i = t.getRenderedPort(e.port.getFullId());
          var r = Ae({
            parameters: {
              vertexId: e.vertex.id,
              portId: e.port.id,
              portType: e.port.getType()
            },
            uuid: e.port.getFullId(),
            data: e.port.data
          }, o);
          var a = Bo(new Se(e.data, "data-jtk-"));
          Ae(r, a);
          r.element = i || n;
          t.jsplumb._internal_newEndpoint(r);
        } else {
          var s = t.getRenderedPort(e.port.getFullId());
          if (!s) {
            t.portRenderer(e.port, e.data, e.vertex, o, null);
          }
        }
        t.jsplumb.revalidate(n);
        t.refresh(true);
      });
      this.__bindToToolkit(Bn, function (e) {
        var n = t.jsplumb.getManagedElement(e.vertex.id),
          o = e.port.getFullId(),
          i = t.getRenderedPort(o),
          r = t.jsplumb.getEndpoint(o);
        if (r != null) {
          t.jsplumb.deleteEndpoint(r);
          t.refresh(true);
        } else if (i != null) {
          if (n !== i) {
            t.jsplumb.setSuspendEvents(true);
            t.jsplumb.unmanage(i);
            t.templateRenderer.cleanupPort(o, i);
            t.jsplumb.setSuspendEvents(false);
          }
          t.fire(Bn, {
            vertex: e.vertex,
            port: e.port,
            portEl: i,
            vertexEl: n
          });
          t.jsplumb.revalidate(n);
          t.refresh(true);
        }
      });
      this.__bindToToolkit(Hn, function (e) {
        var n = t.connMap[e.edge.getId()];
        if (n) {
          var o = t._prepareConnectionParams(e.edge);
          var i = s(new Set([A.default, o.type]));
          t.jsplumb.setConnectionType(n, i.join(" "), o.data);
          if (t._$_simpleEdgeStyles === true) {
            var r = {};
            var a = false;
            Lo.forEach(function (t) {
              if (e.edge.data[t] != null) {
                a = true;
                r[t] = e.edge.data[t];
              }
            });
            if (a) {
              t.jsplumb.setLineStyle(n, r);
            }
          }
        }
      });
      this.__bindToToolkit(Wn, function (e) {
        var n = t.getRenderedPort(e.originalId);
        if (n) {
          if (t.templateRenderer.reactive === false) {
            t.templateRenderer.update(n, e.port.data, e.port, t);
          }
          t.repaint(t.jsplumb.getManagedElement(e.vertex.id));
        }
      });
      this.__bindToToolkit(Un, function (e) {
        if (Le(e.obj) || Oe(e.obj)) {
          var n = t.getRenderedElement(e.obj.id);
          if (n) {
            gt(n, ge);
            if (t._addToDragSelectionOnSelect) {
              t.jsplumb.addToDragSelection(n);
            } else {
              t.jsplumb.addToDragGroup($o, n);
            }
          }
        } else if (Ve(e.obj)) {
          var o = t.getConnectionForEdge(e.obj);
          if (o) {
            xo.addClass(o, ve);
          }
        }
      });
      this.__bindToToolkit(qn, function () {
        var e = t.jsplumb.getContainer();
        t.jsplumb.clearDragSelection();
        t.jsplumb.clearDragGroup($o);
        Me(jt(e, "." + ve), function (e) {
          return ht(e, ve);
        });
        Me(jt(e, "." + ge), function (e) {
          return ht(e, ge);
        });
      });
      this.__bindToToolkit(Kn, function (e) {
        if (Le(e.obj) || Oe(e.obj)) {
          var n = t.getRenderedElement(e.obj.id);
          if (n) {
            t.jsplumb.removeFromDragGroup(n);
            ht(n, ge);
            t.jsplumb.removeFromDragSelection(n);
          }
        } else if (Ve(e.obj)) {
          var o = t.getConnectionForEdge(e.obj);
          if (o) {
            xo.removeClass(o, ve);
          }
        }
      });
      this.__bindToToolkit(Jn, this._loadStartFn.bind(this));
      this.__bindToToolkit(Xn, this._appendStartFn.bind(this));
      this.__bindToToolkit(Yn, this._loadEndFn.bind(this));
      this.__bindToToolkit(Qn, this._appendEndFn.bind(this));
    }
  }, {
    key: "_vertexUpdated",
    value: function e(t) {
      if (t.vertex != null) {
        var n = this.jsplumb.getManagedElement(t.vertex.getFullId());
        if (n) {
          if (this.templateRenderer.reactive === false) {
            this.templateRenderer.update(n, t.vertex.data, t.vertex, this);
          }
          this.fire(he, {
            el: n,
            vertex: t.vertex
          });
          if (this._useModelForSizes && t.updates != null) {
            if (t.updates[this._modelWidthAttribute] != null || t.updates[this._modelHeightAttribute] != null) {
              var o = this._resolveInitialVertexSize(t.vertex, this.view.getTypeDefinition(t.vertex));
              zo(n, o.w, o.h);
            }
          }
          this.repaint(n);
        }
      }
    }
  }, {
    key: "_autoSizeAGroup",
    value: function e(t, n, o, i, r) {
      var a = this;
      o = o || {};
      i = i || {};
      var s = this._$_layoutMap.get(t.id);
      var l = {
        movedElements: o,
        resizedGroups: i,
        layout: s
      };
      var u = n.elastic && this._useModelForSizes;
      var d = r || (n.autoShrink || n.autoGrow || n.autoSize || n.elastic) && !u;
      if (!d) {
        return l;
      }
      var p = n.autoShrink === true || n.elastic === true || n.autoSize === true && n.autoShrink !== false;
      var c = n.autoGrow === true || n.elastic === true || n.autoSize === true && n.autoGrow !== false;
      n.padding || 0;
      var f = function e(t, n, i) {
        o[t] = o[t] || {
          original: n,
          current: i
        };
        o[t].current = i;
      };
      var g = this.jsplumb.getGroup(t.id);
      var v = g.contentArea;
      var h = 0,
        m = 0;
      var y, b;
      var _ = this.jsplumb.getSize(v);
      var E = this.jsplumb.getSize(g.el);
      var j = Math.max(_.w, E.w);
      var k = Math.max(_.h, E.h);
      var S = t.group ? this._$_layoutMap.get(t.group.id) : this._layout;
      var x = Object.assign({}, S.getPosition(t.id));
      var P;
      var I = this._currentElasticGroup && this._currentElasticGroup.getValues(t.id);
      if (I != null) {
        h = I.layoutShiftX;
        m = I.layoutShiftY;
        y = I.width;
        b = I.height;
        P = {
          x: x.x - h,
          y: x.y - m
        };
      } else {
        var T = n.allowShrinkFromOrigin === true;
        var w = Ro(s._getExtents(), n, T, x, j, k, p, c, this._$_gridProfile);
        y = w.width;
        b = w.height;
        h = w.layoutShiftX;
        m = w.layoutShiftY;
        P = {
          x: x.x - h,
          y: x.y - m
        };
      }
      zo(v, y, b);
      var G = this.jsplumb.getSize(g.el);
      var z = G.w - j;
      var M = G.h - k;
      if (!r && p === false) {
        var R = false;
        if (z < 0) {
          v.style.width = j + "px";
          z = 0;
          R = true;
        }
        if (M < 0) {
          v.style.height = k + "px";
          M = 0;
          R = true;
        }
        if (R) {
          G = this.jsplumb.getSize(g.el);
        }
      }
      if (!r && c === false) {
        var A = false;
        if (z > 0) {
          v.style.width = j + "px";
          z = 0;
          A = true;
        }
        if (M > 0) {
          v.style.height = k + "px";
          M = 0;
          A = true;
        }
        if (A) {
          G = this.jsplumb.getSize(g.el);
        }
      }
      if (h !== 0 || m !== 0) {
        s.getPositions().forEach(function (e, t) {
          var n = {
            x: e.x + h,
            y: e.y + m
          };
          s.setPosition(t, n.x, n.y, true);
          a.jsplumb.viewport.updateElement(t, n.x, n.y, null, null, null);
          f(t, e, n);
        });
        s._$_calculateExtents();
        this.jsplumb.viewport.updateElement(t.id, P.x, P.y, G.w, G.h, null);
        S.setPosition(t.id, P.x, P.y, true);
        f(t.id, x, P);
      }
      S.setSize(t.id, G);
      if (t.group) {
        var D = this.getGroupDefinition(t.group);
        if (D) {
          this._autoSizeAGroup(t.group, D, o, i, false);
        }
      } else {
        if (this._$_magnetizeProfile.afterGroupShrink && (z < 0 || M < 0)) {
          this.gather(t);
        } else if (this._$_magnetizeProfile.afterGroupGrow && (z > 0 || M > 0)) {
          var C = this._layout.getPosition(t.id);
          if (C != null) {
            Ae(o, this._setMagnetizedPosition(t.id, C.x, C.y, !this._$_magnetizeProfile.repositionDraggedElement, this._layout));
          }
        }
      }
      if (z !== 0 || M !== 0) {
        this.fire(me, {
          group: t,
          el: v,
          w: y,
          h: b
        });
      }
      i[t.id] = {
        group: t,
        originalGroupSize: _,
        newGroupSize: G
      };
      return l;
    }
  }, {
    key: "_connect",
    value: function e(t, n, o) {
      var i = this;
      if (this.dataSource.debugEnabled) {
        console.log("Renderer", "adding connection with params", t);
      }
      var r = function e(t, n) {
        t.endpoint = t.endpoint || i.jsplumb.defaults.endpoints[n] || i.jsplumb.defaults.endpoint;
        t.paintStyle = t.paintStyle || i.jsplumb.defaults.endpointStyles[n] || i.jsplumb.defaults.endpointStyle;
        t.hoverPaintStyle = t.hoverPaintStyle || i.jsplumb.defaults.endpointHoverStyles[n] || i.jsplumb.defaults.endpointHoverStyle;
        t.anchor = t.anchor != null ? t.anchor : i.jsplumb.defaults.anchors != null ? i.jsplumb.defaults.anchors[n] : i.jsplumb.defaults.anchor;
        t.deleteOnEmpty = t.deleteOnEmpty === false ? t.deleteOnEmpty : true;
      };
      var a = function e(t, n) {
        if (o != null) {
          t.endpoint = t.endpoint || o.endpoint || (o.endpoints ? o.endpoints[n] : null);
          t.anchor = t.anchor || o.anchor || (o.anchors ? o.anchors[n] : null);
          t.paintStyle = t.paintStyle || o.endpointStyle || (o.endpointStyles ? o.endpointStyles[n] : null);
          t.hoverPaintStyle = t.hoverPaintStyle || o.endpointHoverStyle || (o.endpointHoverStyles ? o.endpointHoverStyles[n] : null);
        }
      };
      var s = Ae({}, t);
      if (t.type != null) {
        o = o || this.view.getEdgeDefinition(t.type);
        if (o != null) {
          if (this.enhancedView) {
            Ae(s, Fe(o, n.data, null, true));
          } else {
            Ae(s, o);
          }
        }
      }
      if (Ho(t.source)) {
        var l = {
          element: t.source,
          parameters: {
            vertexId: Be(n.source) ? n.source.getParent().id : n.source.id,
            portId: Be(n.source) ? n.source.id : null
          }
        };
        if (Le(n.source) || Oe(n.source)) {
          var u = this.view.getTypeDefinition(n.source);
          if (u.anchorPositions) {
            l.anchor = u.anchorPositions;
          }
        }
        var d = t.source.getAttribute(S) || t.source.getAttribute(ye);
        if (d != null) {
          l.parameters.portType = d;
          var p = this.view.getPortDefinition(d);
          if (p != null) {
            if (this.enhancedView) {
              Ae(l, Fe(p, n.source.data, null, true));
            } else {
              Ae(l, p);
            }
          }
        }
        a(l, 0);
        r(l, 0);
        s.sourceEndpoint = this.jsplumb._internal_newEndpoint(l);
      }
      if (Ho(t.target)) {
        var c = {
          element: t.target,
          parameters: {
            vertexId: Be(n.target) ? n.target.getParent().id : n.target.id,
            portId: Be(n.target) ? n.target.id : null
          }
        };
        if (Le(n.target) || Oe(n.target)) {
          var f = this.view.getTypeDefinition(n.target);
          if (f.anchorPositions) {
            c.anchor = f.anchorPositions;
          }
        }
        var g = t.target.getAttribute(S) || t.target.getAttribute(be);
        if (g != null) {
          c.parameters.portType = g;
          var v = this.view.getPortDefinition(g);
          if (v != null) {
            if (this.enhancedView) {
              Ae(c, Fe(v, n.target.data, null, true));
            } else {
              Ae(c, v);
            }
          }
        }
        a(c, 1);
        r(c, 1);
        s.targetEndpoint = this.jsplumb._internal_newEndpoint(c);
      }
      if (this._$_simpleEdgeStyles === true) {
        Lo.forEach(function (e) {
          if (n.data[e] != null) {
            s[e] = n.data[e];
          }
        });
      }
      return this.jsplumb.connect(s);
    }
  }, {
    key: "_enqueueEdge",
    value: function e(t, n) {
      this._unrenderedEdges.push(t);
      if (n) {
        this._flushUnrenderedEdges();
      }
    }
  }, {
    key: "_maybeRenderEdge",
    value: function e(t) {
      var n = this.getRenderedEndpoint(t.target.getFullId()) || this.getRenderedElement(t.target),
        o = this.getRenderedEndpoint(t.source.getFullId()) || this.getRenderedElement(t.source);
      if (n == null || o == null) {
        return null;
      } else {
        return this._doRenderEdge(t);
      }
    }
  }, {
    key: "_doRenderEdge",
    value: function e(t) {
      var n = this.view.getEdgeDefinition(this.dataSource.getEdgeType(t.data || {}));
      var o = this._prepareConnectionParams(t);
      var i = this._connect(o, t, n);
      if (i != null) {
        var r = i.connector;
        if (o.geometry) {
          Eo.importGeometry(r, o.geometry);
          this.jsplumb.revalidate(i.source);
          this.jsplumb.revalidate(i.target);
        }
        i.edge = t;
        this.connMap[t.getId()] = i;
        this.fire(Cn, {
          source: t.source,
          target: t.target,
          connection: i,
          edge: t,
          geometry: t.geometry
        });
        if (this.refreshLayoutOnEdgeConnect) {
          this.refresh(true);
        }
        var a = Be(t.source) ? t.source.getParent() : t.source,
          s = this.jsplumb.getManagedElement(a.id),
          l = Be(t.target) ? t.target.getParent() : t.target,
          u = this.jsplumb.getManagedElement(l.id);
        if (u._jtkVisible === false || s._jtkVisible === false) {
          this._toggleEdge(t, false);
        }
      } else {
        Ge("jsPlumb: WARN : An edge could not be rendered " + t + " and is being removed from the dataset.");
        this.dataSource.removeEdge(t);
      }
      return i;
    }
  }, {
    key: "_toggleEdge",
    value: function e(t, n, o) {
      var i = this.getConnectionForEdge(t);
      if (i) {
        var r = !n || Vo(i);
        if (r) {
          xo.setVisible(i, n);
          if (!o) {
            No(i, i.endpoints[0], n);
            No(i, i.endpoints[1], n);
          }
        }
      }
    }
  }, {
    key: "relayoutPanZoom",
    value: function e() {
      this.panZoom.relayout(this._layout._getExtents());
    }
  }, {
    key: "_configureDirectRender",
    value: function e() {
      var t = this;
      gt(this.containerElement, q);
      try {
        this._directRenderResizeObserver = new ResizeObserver(function (e) {
          t.relayoutPanZoom();
          t.fire(_e);
        });
        this._directRenderResizeObserver.observe(this.containerElement);
      } catch (e) {
        Ge("WARN: Resize observer for direct rendering mode could not be attached. Not fatal.");
      }
    }
  }, {
    key: "_createLayout",
    value: function e(t) {
      var n = this;
      var o = {
        container: this.containerElement,
        options: t.options || {},
        toolkit: this.toolkitInstance,
        adapter: new v(this)
      };
      if (!o.options.locationFunction) {
        o.options.locationFunction = function (e) {
          return {
            x: Qe(e.data, n._modelLeftAttribute),
            y: Qe(e.data, n._modelTopAttribute)
          };
        };
      }
      return ao.get(t.type, o);
    }
  }, {
    key: "_createGroupLayout",
    value: function e(t, n) {
      var o = this;
      var i = this._getGroupDragArea(n);
      if (i == null) {
        i = this.jsplumb.getManagedElement(n.id);
      }
      var r = {
        container: i,
        options: t.options || {},
        toolkit: this.toolkitInstance,
        adapter: new E(n, this)
      };
      if (!r.options.locationFunction) {
        r.options.locationFunction = function (e) {
          return {
            x: Qe(e.data, o._modelLeftAttribute),
            y: Qe(e.data, o._modelTopAttribute)
          };
        };
      }
      return ao.get(t.type, r);
    }
  }, {
    key: "_loadExistingData",
    value: function e(t) {
      var n = this;
      var o, i, r, a, s;
      var l = false;
      var u = function e(t) {
        var o = n.dataSource.getAllEdgesFor(t);
        for (s = 0; s < o.length; s++) {
          var i = He(o[s].source);
          if (i === t) {
            n._enqueueEdge(o[s]);
          }
        }
      };
      for (o = 0, r = this.dataSource.getGroupCount(); o < r; o++) {
        a = this.dataSource.getGroupAt(o);
        if (this.objectFilter(a)) {
          this._doRenderGroup(a);
          u(a);
          l = true;
        }
      }
      for (o = 0, r = this.dataSource.getNodeCount(); o < r; o++) {
        i = this.dataSource.getNodeAt(o);
        if (this.objectFilter(i)) {
          this._doRenderNode(i);
          u(i);
          l = true;
        }
      }
      this._flushUnrenderedEdges();
      return l;
    }
  }, {
    key: "fireNodeAdded",
    value: function e(t) {
      this.fire(eo, t);
    }
  }, {
    key: "createRenderer",
    value: function e(t, n, o) {
      var i = this;
      return function (e, r, a, s, l) {
        n(r);
        var u = o(r),
          d = r,
          p;
        if (i.enhancedView) {
          d = Ae({}, s ? s.parameters || {} : {});
          Ae(d, r);
          var c = {};
          for (p in d) {
            if (d.hasOwnProperty(p)) {
              if (d[p] != null) {
                if (d[p].constructor == Function) c[p] = d[p](r);else c[p] = d[p];
              }
            }
          }
          d = c;
        }
        if (s) {
          var f = s.templateId || "jtk-template-" + u;
          if (i._useModelForSizes) {
            var g = i._resolveInitialVertexSize(e, s);
            d[i._modelWidthAttribute] = g.w;
            d[i._modelHeightAttribute] = g.h;
          }
          i.templateRenderer.render(f, d, i.dataSource, t, i, s, e, a, l);
        }
      };
    }
  }, {
    key: "_handleClickOrTap",
    value: function e(t, n, o) {
      var i = this.getModelObjectFromElement(t);
      if (i != null) {
        var r = Le(i) ? this.getNodeDefinition(i) : Oe(i) ? this.getGroupDefinition(i) : Be(i) ? this.getPortDefinition(i) : null;
        if (r && r.events && r.events[o]) {
          r.events[o]({
            el: t,
            obj: i,
            e: n,
            toolkit: this.dataSource,
            renderer: this
          });
        }
      }
    }
  }, {
    key: "_handleEndpointClick",
    value: function e(t, n, o) {
      var i = this.jsplumb.getModelObjectFromEndpoint(t);
      if (i != null) {
        if (t.parameters.portType != null) {
          var r = this.view.getPortDefinition(t.parameters.portType);
          if (r && r.events && r.events[o]) {
            r.events[o]({
              el: t,
              obj: i,
              e: n,
              toolkit: this.dataSource,
              renderer: this
            });
          }
        } else {
          if (Be(i)) {
            var a = this.getPortDefinition(i);
            if (a && a.events && a.events[o]) {
              a.events[o]({
                el: t,
                obj: i,
                e: n,
                toolkit: this.dataSource,
                renderer: this
              });
            }
          }
        }
      }
    }
  }, {
    key: "_bindToJsPlumb",
    value: function e() {
      var t = this;
      this.jsplumb.bind(Ot, function (e) {
        if (t.dragOptions.addHelperClasses !== false) {
          gt(t.containerElement, fe);
        }
      });
      this.jsplumb.bind(Zt, function (e) {
        if (t.dragOptions.addHelperClasses !== false) {
          ht(t.containerElement, fe);
        }
      });
      this.jsplumb.bind(ut, function (e) {
        var n = t.jsplumb.getModelObjectFromEndpoint(e.newEndpoint);
        t._whileIgnoringToolkitEvents(function () {
          t.dataSource.edgeMoved(e.connection.edge, n, e.index);
        });
      });
      this.jsplumb.bind(dt, function (e) {
        var n = e.connection.edge;
        t._whileIgnoringToolkitEvents(function () {
          t.dataSource.removeEdge(n);
        });
      });
      this.jsplumb.bind(st, function (e) {
        var n = t.getModelObjectFromElement(e.el);
        if (n != null && (Le(n) || Oe(n))) {
          var o = e.sourceGroup == null ? null : t.dataSource.getGroup(e.sourceGroup.id);
          var i = t.dataSource.getGroup(e.group.id);
          if (i) {
            t.dataSource.addToGroup(n, i, o, e.pos, t);
          }
        }
      });
      this.jsplumb.bind(lt, function (e) {
        var n = t.getModelObjectFromElement(e.el);
        if (n != null && (Le(n) || Oe(n))) {
          var o = e.targetGroup == null ? null : t.dataSource.getGroup(e.targetGroup.id);
          t.dataSource.removeFromGroup(n, false, o, t);
        }
      });
      this.jsplumb.bind(pt, function (e) {
        var n = t.getModelObjectFromElement(e.child.el);
        if (n != null && (Le(n) || Oe(n))) {
          t.dataSource.removeFromGroup(n, false, null, t);
        }
      });
      this.jsplumb.bind(ct, function (e) {
        var n = e.group.uiGroup == null ? t._layout : t._$_layoutMap.get(e.group.uiGroup.id);
        var o = t.jsplumb.getSize(e.group.el);
        n.setSize(e.group.id, o);
        if (e.group.group) {
          var i = t.toolkitInstance.getGroup(e.group.group.id);
          if (i != null) {
            var r = t.view.getGroupDefinition(t.dataSource.getNodeType(i.data));
            t._autoSizeAGroup(i, r, null, null, false);
          }
        }
        if (t._$_magnetizeProfile.afterGroupCollapse === true) {
          if (e.group.uiGroup == null) {
            t.gather(e.group.id);
          }
        }
        var a = t.dataSource.getGroup(e.group.id);
        if (a) {
          t.fire(ct, {
            group: a,
            uigroup: e.group,
            renderer: t
          });
        }
      });
      this.jsplumb.bind(ft, function (e) {
        var n = e.group.uiGroup == null ? t._layout : t._$_layoutMap.get(e.group.uiGroup.id);
        var o = t.jsplumb.getSize(e.group.el);
        n.setSize(e.group.id, o);
        var i = t.toolkitInstance.getGroup(e.group.id);
        if (i != null) {
          var r = t.view.getGroupDefinition(t.dataSource.getNodeType(i.data));
          t._autoSizeAGroup(i, r, null, null, false);
        }
        if (t._$_magnetizeProfile.afterGroupExpand !== false) {
          if (e.group.uiGroup == null) {
            t.magnetize(e.group.id);
          }
        }
        var a = t.dataSource.getGroup(e.group.id);
        if (a) {
          t.fire(ft, {
            group: a,
            uigroup: e.group,
            renderer: t
          });
        }
      });
      Me([$t, Lt, Vt, Nt, Bt, Ht, Wt, Ut, qt], function (e) {
        t.jsplumb.bind(e, function (n, o) {
          if (n.edge) {
            var i = n.edge;
            var r = t.view.getEdgeDefinition(t.dataSource.getEdgeType(i.data));
            var a = e.split(":")[1];
            if (r && r.events && r.events[a]) {
              r.events[a]({
                edge: i,
                e: o,
                toolkit: t.dataSource,
                renderer: t,
                connection: n
              });
            }
          }
        });
      });
      Me([[Kt, Jt], [Xt, Ct], [Yt, Ft], [Qt, en], [tn, nn], [on, rn], [an, sn], [ln, Mt], [un, zt], [dn, pn]], function (e) {
        var n = a(e, 2),
          o = n[0],
          i = n[1];
        t.jsplumb.bind(o, function (e, n) {
          t._handleClickOrTap(e, n, i);
        });
      });
      Me([[cn, Jt], [fn, en]], function (e) {
        var n = a(e, 2),
          o = n[0],
          i = n[1];
        t.jsplumb.bind(o, function (e, n) {
          t._handleEndpointClick(e, n, i);
        });
      });
    }
  }, {
    key: "_whileIgnoringToolkitEvents",
    value: function e(t) {
      this._ignoreToolkitEvents = true;
      try {
        t();
      } catch (e) {
        Ge("An error occurred while ignoring Toolkit events", e);
      } finally {
        this._ignoreToolkitEvents = false;
      }
    }
  }, {
    key: "_updateMovedElements",
    value: function e(t) {
      for (var n in t) {
        var o = this.jsplumb.getManagedElement(n);
        Go(o, t[n].current);
        this.repaint(o);
        var i = this.toolkitInstance.getVertex(n);
        var r = {
          el: o,
          vertex: i,
          e: event,
          type: i.objectType,
          renderer: this,
          pos: t[n].current,
          originalPosition: t[n].original
        };
        if (this._$_storePositionsInModel !== false) {
          var a = Ae({}, i.data);
          this.storePositionInModel({
            obj: i,
            id: n,
            leftAttribute: this._modelLeftAttribute,
            topAttribute: this._modelTopAttribute
          });
          var s = {};
          s[this._modelLeftAttribute] = t[n].current.x;
          s[this._modelTopAttribute] = t[n].current.y;
          if (Le(i)) {
            this.toolkitInstance.fire(to, {
              vertex: i,
              updates: s,
              originalData: a,
              reason: co
            }, null);
          } else if (Oe(i)) {
            this.toolkitInstance.fire(no, {
              vertex: i,
              updates: s,
              originalData: a,
              reason: co
            }, null);
          }
        }
        if (Le(i)) {
          this.fire(Rn, r);
        } else if (Oe(i)) {
          this.fire(oo, r);
        }
      }
    }
  }, {
    key: "_trackGroupSizeChanges",
    value: function t(n, o) {
      for (var i in n) {
        var r = n[i];
        if (r.originalGroupSize.w !== r.newGroupSize.w || r.originalGroupSize.h !== r.newGroupSize.h) {
          if (this._useModelForSizes) {
            var a, s;
            var l = (a = {}, e(a, this._modelWidthAttribute, r.group.data[this._modelWidthAttribute]), e(a, this._modelHeightAttribute, r.group.data[this._modelHeightAttribute]), a);
            var u = (s = {}, e(s, this._modelWidthAttribute, r.newGroupSize.w), e(s, this._modelHeightAttribute, r.newGroupSize.h), s);
            r.group.data[this._modelWidthAttribute] = r.newGroupSize.w;
            r.group.data[this._modelHeightAttribute] = r.newGroupSize.h;
            this.toolkitInstance.fire(no, {
              vertex: r.group,
              updates: u,
              originalData: l,
              reason: fo
            }, null);
          }
          if (o) {
            this._setGroupElementSize(r.group, r.newGroupSize);
          }
          this.toolkitInstance.fire(io, {
            group: r.group,
            originalGroupSize: r.originalGroupSize,
            newGroupSize: r.newGroupSize,
            source: this
          });
        }
      }
    }
  }, {
    key: "_setGroupElementSize",
    value: function e(t, n) {
      var o = this._getGroupDragArea(t);
      if (o) {
        zo(o, n.w, n.h);
      }
    }
  }, {
    key: "_setMagnetizedPosition",
    value: function e(t, n, o, i, r) {
      var a = Ae({}, this._layout.getPosition(t));
      var s = {};
      s[t] = {
        original: a,
        current: {
          x: n,
          y: o
        }
      };
      Ae(s, r.setMagnetizedPosition(t, n, o, i, this._$_gridProfile.grid));
      if (s[t].current.x === s[t].original.x && s[t].current.y === s[t].original.y) {
        delete s[t];
      }
      if (i === true) {
        Go(this.jsplumb.getManagedElement(t), {
          x: n,
          y: o
        });
      } else {
        s[t].original = a;
      }
      this._updateMovedElements(s);
      return s;
    }
  }, {
    key: "_setMagnetizedPositions",
    value: function e(t, n, o) {
      var i = this;
      var r = {};
      if (t.length > 0) {
        Me(t, function (e) {
          Ae(r, i._setMagnetizedPosition(e.id, e.x, e.y, !i._$_magnetizeProfile.repositionDraggedElement, o));
        });
      }
      return r;
    }
  }, {
    key: "_setDirectPosition",
    value: function e(t, n, o, i, r) {
      var a = r.setPosition(t, n, o, i);
      this._updateMovedElements(a);
    }
  }, {
    key: "_ensureTemplate",
    value: function e(t) {
      if (t != null && t.template && t.templateId == null) {
        if (this.templateRenderer.addTemplate) {
          t.templateId = ze();
          this.templateRenderer.addTemplate(t.templateId, t.template);
        }
      }
      return t;
    }
  }, {
    key: "registerTag",
    value: function e(t, n) {
      if (this.templateRenderer.recado != null) {
        this.templateRenderer.recado.registerTag(t, n);
      }
    }
  }, {
    key: "_setupShapeDefs",
    value: function e() {
      this._shapeDefsSvgElement = this._$_shapeLibrary.getSvgDefsElement();
      this._shapeDefsSvgElement.style.position = "absolute";
      this._shapeDefsSvgElement.style.width = "1px";
      this._shapeDefsSvgElement.style.height = "1px";
      this._shapeDefsSvgElement.style.left = "-10000px";
      this._shapeDefsSvgElement.style.top = "-10000px";
      this.getContainer().appendChild(this._shapeDefsSvgElement);
    }
  }, {
    key: "getGroupDefinition",
    value: function e(t) {
      return this._ensureTemplate(this.view.getGroupDefinition(this.dataSource.getNodeType(t.data)));
    }
  }, {
    key: "getPortDefinition",
    value: function e(t) {
      return this._ensureTemplate(this.view.getPortDefinition(this.dataSource.getPortType(t.data)));
    }
  }, {
    key: "getNodeDefinition",
    value: function e(t) {
      return this._ensureTemplate(this.view.getNodeDefinition(this.dataSource.getNodeType(t.data)));
    }
  }, {
    key: "_getModelInfoForDragElement",
    value: function e(t, n) {
      var o = null;
      var i = null;
      var r = null;
      var a = kt(t, et(tt), this.jsplumb.getContainer(), true);
      var s = this.getModelObjectFromElement(a, false);
      if (s != null) {
        var l = Be(s) ? s.getParent() : s;
        var u = {};
        if (!Be(s)) {
          var d = n ? [Y, te] : [Q, ne];
          r = t.getAttribute(d[0]) || t.getAttribute(d[1]) || t.getAttribute(k) || t.getAttribute(ee);
          if (r != null) {
            s = s.getPort(r) || s;
          }
        }
        if (Be(s)) {
          r = s.id;
          i = this.toolkitInstance.getPortType(s);
        }
        var p = n ? ye : be;
        o = t.getAttribute(p) || t.getAttribute(S);
        if (o != null) {
          u = this.view.getPortDefinition(o);
        } else {
          u = this.view.getTypeDefinition(s);
        }
        return {
          obj: s,
          vertex: l,
          typeDef: u,
          portType: o || i,
          portId: r
        };
      } else {
        return null;
      }
    }
  }, {
    key: "_setSelectors",
    value: function e() {
      var t = this;
      var n = function e(n, o, i) {
        var r = t._getModelInfoForDragElement(n, i);
        if (r == null) {
          return false;
        } else {
          if (r.obj != null && r.typeDef.maxConnections != null && r.typeDef.maxConnections !== -1) {
            return r.obj.getEdges().length < r.typeDef.maxConnections;
          } else {
            return true;
          }
        }
      };
      var o = function e(n, o, i, r) {
        var a = Bo(new ke(n));
        var s = a.edgeType;
        var l = {
          edgeType: s
        };
        var u = Te({
          edgeType: s
        });
        var d = t._getModelInfoForDragElement(n, o);
        if (d != null) {
          var p = d.obj || d.vertex;
          l.portId = d.portId;
          l.portType = d.portType;
          l.vertexId = d.vertex.id;
          Ae(u, d.typeDef);
          if (d.typeDef.anchorPositionFinder != null) {
            var c = vn(r, n, t.jsplumb.currentZoom);
            var f = d.typeDef.anchorPositionFinder(n, c, d.vertex, d.typeDef, r);
            if (f != null) {
              u.anchor = f;
              if (f.portId != null) {
                l.portId = f.portId;
              }
            }
          }
          if (p != null) {
            u.data = p.data;
          }
        }
        u.parameters = Te(l);
        if (a.anchor) {
          u.anchor = a.anchor;
        }
        return u;
      };
      this.jsplumb.addSourceSelector(p(M, It), {
        parameterExtractor: function e(t, n, i) {
          return o(n, true, false, i);
        },
        parentSelector: et(k, Y, Q),
        canAcceptNewConnection: function e(t, o) {
          return n(t, o, true);
        }
      });
      this.jsplumb.addTargetSelector(p(R, It), {
        parameterExtractor: function e(t, n, i) {
          return o(t, false, true, i);
        },
        parentSelector: et(k, Y, Q),
        canAcceptNewConnection: function e(t, o) {
          return n(t, o, false);
        }
      });
    }
  }, {
    key: "_selectEdges",
    value: function e(t, n) {
      var o = Ae({}, t);
      o.source = this.getObjectInfo(t.source).obj;
      o.target = this.getObjectInfo(t.target).obj;
      o.element = this.getObjectInfo(t.element).obj;
      var i = this.toolkitInstance[n](o),
        r = this.getConnectionsForEdges(i);
      return this.jsplumb.select({
        connections: r
      });
    }
  }, {
    key: "_classOperation",
    value: function e(t, n, o) {
      var i = this;
      var r = function e(t) {
        var r = i.getObjectInfo(t);
        if (r.el) {
          o(r.el, n);
        } else if (Ho(t)) {
          o(t, n);
        }
      };
      if (t != null) {
        if (St(t)) {
          o(t, n);
        } else if (bt(t)) {
          Me(t, function (e) {
            return r(e);
          });
        } else {
          r(t);
        }
      }
    }
  }, {
    key: "_append",
    value: function e(t, n) {
      this.canvasElement.appendChild(t);
      this.panZoom.add(t, n);
    }
  }, {
    key: "_getGroupDragArea",
    value: function e(t) {
      var n = this.jsplumb.getGroup(t.id);
      return n === null || n === void 0 ? void 0 : n.contentArea;
    }
  }]);
  return I;
}(In);
e(Wo, "type", "Surface");
var Uo = {};
var qo = {
  get: function e(t) {
    var n = Uo[t];
    if (!n) {
      throw {
        message: "JsPlumb: unknown surface plugin '" + t + "'"
      };
    } else {
      return new n();
    }
  },
  register: function e(t, n) {
    Uo[t] = n;
  }
};
export { Co as SURFACE_MODE_DISABLED, Ao as SURFACE_MODE_PAN, Do as SURFACE_MODE_SELECT, Fo as SURFACE_MODE_VERTEX_DRAWING, Wo as Surface, qo as SurfacePlugins, Bo as _extractEndpointDefinitionFromValueSource };