<div
  #node
  data-jtk-target="true" 
  class="node"
  [ngClass]="{
    'ksf': obj.type === 'ksf',
    'goal': obj.type === 'goal'
  }"
>
  <div class="body">
    <div class="text-container">
      <div 
        class="title"
        matTooltipPosition="above"
        matTooltipShowDelay="200"
        [matTooltip]="obj.name"
      >{{obj.name}}</div>
      <div 
        class="description"
        matTooltipPosition="above"
        matTooltipShowDelay="200"
        [matTooltip]="obj.description"
      >{{obj.description}}</div>
    </div>
    <div class="actions">
      @if (!!updateCSFAction || !!updateGoalAction) {
        <naris-button
          size="small"
          color="transparent"
          [matTooltip]="'strategy-map_node.config' | translate"
          matTooltipShowDelay="200"
          [icon]="'edit'"
          (clicked)="configClicked(obj, node)"
          id="StrategyMapNodeConfig"
        />
      }
      @if (obj.type === 'ksf') {
        <naris-button
          size="small"
          color="transparent"
          [matTooltip]="'strategy-map_node.connected-objects' | translate"
          matTooltipShowDelay="200"
          [icon]="'attachment'"
          (clicked)="openConnectedObjects()"
          id="StrategyMapNodeConnectedObjects"
        />
      }
    </div>
  </div>

  @if (obj.type === 'ksf' && (!!obj.connectedObjects || !!obj.keyPerformanceIndicators)) {
    <div class="connections">
      @for (object of obj.connectedObjects; track $index) {
        <naris-button
          color="transparent"
          size="small"
          [matTooltip]="'ksf_connected-objects.'+ object.toLowerCase() | translate"
          matTooltipPosition="below"
          matTooltipShowDelay="200"
          [icon]="object.toLowerCase()"
          (clicked)="openConnectedObject(object)"
          [id]="'StrategyMapNode' + object"
        />
      }
      @if (!!obj.keyPerformanceIndicators) {
        <naris-button
          color="transparent"
          size="small"
          [matBadge]="obj.keyPerformanceIndicators"
          matBadgeSize="small"
          [matBadgeColor]="kpiBadgeColor"
          [matTooltip]="'ksf_connected-objects.kpi' | translate"
          matTooltipPosition="below"
          matTooltipShowDelay="200"
          [icon]="'thermostat'"
          (clicked)="openConnectedKPI()"
          id="StrategyMapNodeKPI"
        />
      }
    </div>
  }
  

  <!-- Anchors (absolutely positioned) -->
  <div class="anchor top" data-jtk-source="true"></div>
  <div class="anchor right" data-jtk-source="true"></div>
  <div class="anchor left" data-jtk-source="true"></div>
  <div class="anchor bottom" data-jtk-source="true"></div>
  
  @if (obj.type !== 'goal' && !!updateCSFAction) {
    @if (!isRight) {
      <naris-button class="add right" icon="add" size="small" color="new" (clicked)="addNode(obj.id, 'right')" [matTooltip]="'strategy_map.add-csf' | translate"  id="StrategyMapNodeAddCSFRight"/>
    }
    @if (!isLeft) {
      <naris-button class="add left" icon="add" size="small" color="new" (clicked)="addNode(obj.id, 'left')" [matTooltip]="'strategy_map.add-csf' | translate" id="StrategyMapNodeAddCSFLeft"/>
    }
  }
  @if (!obj.isLast && (!!updateCSFAction || !!updateGoalAction)) {
    <naris-button class="add bottom" icon="add" size="small" color="new" (clicked)="addNode(obj.id, 'bottom')" [matTooltip]="'strategy_map.add-csf' | translate" id="StrategyMapNodeAddCSFLast"/>
  }
</div>