/**

 This module contains the mappings for the various edge types.

 */
import {ArrowOverlay} from '@jsplumbtoolkit/browser-ui';

export default function edgeMappings(width?: number, length?: number) {

  const arrowWidth = width || 20;
  const arrowLength = length || 15;

  return [
    {
      property: 'lineStyle',
      mappings: {
        ['sourceArrow']: {
          overlays: [{type: ArrowOverlay.type, options: {location: 0, direction: -1, width: arrowWidth, Length: arrowLength}}]
        },
        ['targetArrow']: {
          overlays: [{type: ArrowOverlay.type, options: {location: 1, width: arrowWidth, length: arrowLength}}]
        },
        ['bothArrows']: {
          overlays: [{
            type: ArrowOverlay.type,
            options: {
              location: 1,
              width: arrowWidth,
              length: arrowLength
            }
          }, {
            type: ArrowOverlay.type,
            options: {
              location: 0,
              direction: -1,
              width: arrowWidth,
              length: arrowLength
            }
          }]
        },
        ['plain']: {},
        ['dashed']: {
          cssClass: 'jtk-flowchart-dashed-edge'
        }
      }
    }
  ];
}
