import { theta as t, TWO_PI as n, normal as e } from "../../util/geom.js";
import { _createBaseSegment as r, _defaultSegmentBoxIntersection as a, _defaultSegmentFindClosestPointOnPath as i, _defaultSegmentLineIntersection as c } from "../../common/abstract-segment.js";
import { _registerSegmentHandler as s } from "./segments.js";
var o = 1e-10;
function u(t) {
  var n = Math.floor(t),
    e = Math.ceil(t);
  if (t - n < o) {
    return n;
  } else if (e - t < o) {
    return e;
  }
  return t;
}
var l = "Arc";
function f(n, e, r, a) {
  return t({
    x: n,
    y: e
  }, {
    x: r,
    y: a
  });
}
function g(t, e) {
  if (t.anticlockwise) {
    var r = t.startAngle < t.endAngle ? t.startAngle + n : t.startAngle,
      a = Math.abs(r - t.endAngle);
    return r - a * e;
  } else {
    var i = t.endAngle < t.startAngle ? t.endAngle + n : t.endAngle,
      c = Math.abs(i - t.startAngle);
    return t.startAngle + c * e;
  }
}
var h = {
  create: function t(e) {
    var a = r(e);
    var i = e.cx;
    var c = e.cy;
    var s = e.r;
    var o = e.ac;
    var u, g;
    if (e.startAngle && e.endAngle) {
      u = e.startAngle;
      g = e.endAngle;
      a.x1 = i + s * Math.cos(u);
      a.y1 = c + s * Math.sin(u);
      a.x2 = i + s * Math.cos(g);
      a.y2 = c + s * Math.sin(g);
    } else {
      u = f(i, c, a.x1, a.y1);
      g = f(i, c, a.x2, a.y2);
    }
    if (g < 0) {
      g += n;
    }
    if (u < 0) {
      u += n;
    }
    var h = g < u ? g + n : g;
    var x = Math.abs(h - u);
    if (o) {
      x = n - x;
    }
    var y = 2 * Math.PI * s;
    var v = x / n;
    var A = y * v;
    var d = {
      xmin: i - s,
      xmax: i + s,
      ymin: c - s,
      ymax: c + s
    };
    return Object.assign(a, {
      extents: d,
      length: A,
      frac: v,
      circumference: y,
      sweep: x,
      startAngle: u,
      endAngle: g,
      anticlockwise: o,
      cx: i,
      cy: c,
      radius: s,
      type: l
    });
  },
  boundingBoxIntersection: function t(n, e) {
    return h.boxIntersection(n, e.x, e.y, e.w, e.h);
  },
  boxIntersection: function t(n, e, r, i, c) {
    return a(n, e, r, i, c);
  },
  findClosestPointOnPath: function t(n, e, r) {
    return i();
  },
  getLength: function t(n) {
    return n.length;
  },
  getPath: function t(n, e) {
    var r = n.sweep > Math.PI ? 1 : 0,
      a = n.anticlockwise ? 0 : 1;
    return (e ? "M" + n.x1 + " " + n.y1 + " " : "") + "A " + n.radius + " " + n.radius + " 0 " + r + "," + a + " " + n.x2 + " " + n.y2;
  },
  gradientAtPoint: function t(n, r, a) {
    var i = h.pointOnPath(n, r, a);
    var c = e({
      x: n.cx,
      y: n.cy
    }, i);
    if (!n.anticlockwise && (c === Infinity || c === -Infinity)) {
      c *= -1;
    }
    return c;
  },
  lineIntersection: function t(n, e, r, a, i) {
    return c();
  },
  pointAlongPathFrom: function t(n, e, r, a) {
    var i = h.pointOnPath(n, e, a),
      c = r / n.circumference * 2 * Math.PI,
      s = n.anticlockwise ? -1 : 1,
      o = i.theta + s * c,
      u = n.cx + n.radius * Math.cos(o),
      l = n.cy + n.radius * Math.sin(o);
    return {
      x: u,
      y: l
    };
  },
  pointOnPath: function t(n, e, r) {
    if (e === 0) {
      return {
        x: n.x1,
        y: n.y1,
        theta: n.startAngle
      };
    } else if (e === 1) {
      return {
        x: n.x2,
        y: n.y2,
        theta: n.endAngle
      };
    }
    if (r) {
      e = e / length;
    }
    var a = g(n, e),
      i = n.cx + n.radius * Math.cos(a),
      c = n.cy + n.radius * Math.sin(a);
    return {
      x: u(i),
      y: u(c),
      theta: a
    };
  }
};
s(l, h);
export { h as ArcSegmentHandler, l as SEGMENT_TYPE_ARC };