import { Pipe, PipeTransform } from '@angular/core';
import { FileIcon } from '@core/enums';

@Pipe({
  name: 'fileIcon',
  standalone: true
})
export class FileIconPipe implements PipeTransform {
  public transform(value?: string): string {
    if (!value || typeof value !== 'string') return FileIcon.Unknown;
    else if (value.includes('doc')) return FileIcon.Word;
    else if (value.includes('pdf')) return FileIcon.PDF;
    else if (value.includes('xml')) return FileIcon.XML;
    else if (/gif|jpe?g|png|bmp|tiff?/.test(value)) return FileIcon.Image;
    else return FileIcon.Unknown;
  }
}
