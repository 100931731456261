import { Component, Input, HostBinding, OnInit, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { startWith, map, debounceTime, switchMap, catchError, tap, finalize } from 'rxjs/operators';
import { INarisOption } from '@core/models';
import { AuthService, HttpService, FormService } from '@core/services';
import { EAuthContext } from '@core/enums';
import { NgStyle } from '@angular/common';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatChipInput, MatChipGrid, MatChipRow, MatChipRemove } from '@angular/material/chips';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../icon/icon.component';
import type { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'naris-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [NgStyle, CdkTextareaAutosize, FormsModule, ReactiveFormsModule, MatChipInput, MatChipGrid, MatChipRow, IconComponent, MatChipRemove, MatTooltip, TranslateModule]
})
export class InputComponent implements OnInit, AfterViewInit {

  /**
   * Internal focus state
   */
  public focused = false;

  /**
   * Internal type reference for switching visibility for password inputs
   */
  public displayType = 'text';

  @Output()
  public readonly blurred = new EventEmitter<any>();

  @Output()
  public readonly enterPress = new EventEmitter<void>();

  @Output()
  public readonly focusedChange = new EventEmitter();

  @Output()
  public readonly togglePicker = new EventEmitter<void>();

  @Input()
  public prepend?: string;

  @Input()
  public prependColor: string;

  @Input()
  public append: string;

  @Input()
  public filter: boolean;

  @Input()
  public placeholder = '';

  @Input()
  public clearable: boolean;

  @Input()
  public autosize: boolean;

  @Input()
  public autofocus = false;

  @Input()
  public disabled = false;

  @Input()
  public control: FormControl;

  @Input()
  public type = 'text';

  @Input()
  public min: number;

  @Input()
  public max: number;

  @Input()
  public minLength: number;

  @Input()
  public maxLength: number;

  @Input()
  public pattern: string;

  @Input()
  public isTime = false;

  @Input()
  public autocomplete = 'off';

  @Input()
  public layouthint?: string[];

  @Input()
  public id: string;

  @HostBinding('class')
  get classes() {
    const classes = [];
    if (this.focused) classes.push('focused');
    if (this.control.valid && !this.control.disabled && (this.control.value !== '' || this.control.touched)) classes.push('valid');
    if (!this.control.valid && !this.control.disabled && (this.control.dirty || this.control.touched)) classes.push('invalid');
    if (this.control.disabled) classes.push('disabled');
    if (this.layouthint?.includes('tags')) classes.push('tag-layout');
    return classes;
  }

  @ViewChild('inputElement')
  public inputElement: ElementRef;

  @Input()
  public options?: INarisOption[];

  @Input()
  public lookup?: { href: string; filter?: Record<string, any> };

  @Input()
  set displayValue(value: INarisOption) {
    if (this.lookup && value) this.control.setValue({ value: value.value, code: '', label: value.label });
  }

  @Input()
  public elementId: string | undefined;

  public filteredOptions: Observable<INarisOption[]>;
  public loading = false;
  public selection: SelectionModel<string>;
  public readonly separatorKeysCodes = [ENTER, COMMA, SPACE] as const;
  public inputControl: FormControl;

  constructor(
    private readonly httpService: HttpService,
    private readonly authService: AuthService,
    private readonly formService: FormService
  ) {}

  /**
   * Set focus state
   */
  public focus(): void {
    this.focused = true;
    this.inputElement?.nativeElement.focus();
    this.focusedChange.emit(this.control);
  }

  /**
   * Unset focus state
   */
  public blur(event: FocusEvent): void {
    this.focused = false;
    this.blurred.emit(event);
  }

  /**
   * Clears the input value
   */
  public clear(): void {
    this.control.setValue('');
  }

  /**
   * Toggle visibility for password fields
   */
  public toggleVisibility(): void {
    this.displayType = this.type === 'password' && this.displayType === 'text'
      ? 'password'
      : 'text';
  }

  /**
   * Return validity of the from control
   *
   * Returns true if the from control status is exactly 'VALID'
   */
  get valid() {
    return this.control.status === 'VALID';
  }

  /**
   * Pristine state
   *
   * Return true if the form control hsa not been changed since instantiation.
   */
  get pristine() {
    return this.control.pristine;
  }

  /**
   * Touched state
   *
   * Returns true if the form control has been touched.
   */
  get touched() {
    return this.control?.touched;
  }

  public ngOnInit() {
    this.control.valueChanges.subscribe(val => {
      if (val !== '') this.formService.resetControlError$.next(this.elementId);
    });
    // Create a copy of originally requested input type
    this.displayType = this.type;

    // If lookup is set, enable lookup watcher on form control
    if (this.lookup) {
      this.filteredOptions = this.control.valueChanges.pipe(
        tap(() => this.loading = true),
        debounceTime(500),
        switchMap(value => this.fetchOptions(value).pipe(
          finalize(() => this.loading = false)
        ))
      );
    }

    // Enable autocomplete filtered options for static injected options
    if (!this.lookup && this.options) {
      this.filteredOptions = this.control.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.label),
          map(option => option ? this._filter(option) : this.options!.slice())
        );
    }

    if (!!this.layouthint?.includes('tags')) {
      this.inputControl = new FormControl('');
      this.selection = new SelectionModel<string>(true, []);
    }
  }

  public ngAfterViewInit(): void {
    // Set focus if autofocus is enabled
    // timeout is needed to prevent change detection errors
    if (this.autofocus) setTimeout(() => {
      this.inputElement?.nativeElement?.focus();
    }, 0);
  }

  private fetchOptions(filter: string): Observable<INarisOption[]> {
    if (typeof filter !== 'string') // If filter is not a string, we don't know what to filter => return;
      return new Observable(observer => {
        observer.next(undefined);
        observer.complete();
      });
    const endpoint = this.lookup?.filter
      ? `${this.lookup.href}&${this.lookup.filter[Object.keys(this.lookup.filter)[0]]}=${filter}`
      : this.lookup?.href;
    const isRest = this.authService.authContext === EAuthContext.SSO;
    return this.httpService.get(endpoint!, isRest).pipe(
      catchError(() => []),
      map(result => result.lookup?.options?.map((option: any) => {
        let label = option.label || '';
        if (option.elements) label = this.mapEl(option.elements);
        return {
          value: option.code,
          code: option.code,
          label
        };
      }))
    );
  }

  private mapEl(elementsObj: any) {
    if (typeof elementsObj !== 'object') return elementsObj;
    const elArr = [];
    for (const key in elementsObj) {
      if (!!elementsObj[key]) elArr.push(elementsObj[key]);
    }
    return elArr.join(' | ');
  }

  /**
   * Filter for autocomplete options
   * @param value value taken from the input field host
   */
  private _filter(value: string): INarisOption[] {
    const filterValue = value.toLowerCase();
    return this.options?.filter(option => option.label.toLowerCase().includes(filterValue)) || [];
  }

  /**
   * Function to transform value that is shown in the input field.
   * Shows the option label by default, and falls back on the value.
   * @param option INarisOption passed from autocomplete
   */
  public autocompleteLabel(option: INarisOption): string {
    return option
      ? option.label
        ? option.label
        : option.value
      : '';
  }

  public onEnterPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.enterPress.emit();
    }
  }

  public onChange() {
    if (this.isTime) {
      const timeValue = this.inputElement.nativeElement.value;
      this.inputElement.nativeElement.value = timeValue.length < 2 ? `0${timeValue}` : timeValue;
    }
  }

  public onInput() {
    // Check if length of input exceeds maxlength. If so, delete exceeding part.
    if (this.maxLength > 0 && this.inputElement.nativeElement.value.length > this.maxLength)
      this.inputElement.nativeElement.value = this.inputElement.nativeElement.value.slice(0, this.maxLength);
  }

  public handleChipInput({ value }: MatChipInputEvent) {
    if (!!value && !this.selection.selected.includes(value)) {
      this.selection.select(value);
      this.inputControl.setValue(null);
      const tagVal = this.control.value ? `${this.control.value} ${value}` : value;
      this.control.setValue(tagVal);
    }
  }

  public onChipRemove(chip: string) {
    this.selection.deselect(chip);
    const filtered = this.control.value?.split(' ').filter((tag: string) => tag !== chip).join(' ') || null;
    this.control.setValue(filtered);
  }

  public onClearSelection() {
    this.selection.clear();
    this.control.reset();
  }
}
