<!-- eslint-disable @angular-eslint/template/no-interpolation-in-attributes -->
<div
  class="node"
  data-jtk-target="true"
  [style.width.px]="obj.width"
  [style.height.px]="obj.height"
  [style.left.px]="obj.left"
  [style.top.px]="obj.top"
  [style.color]="obj.textColor"
  (mouseenter)="!readOnly ? toggleAnchors('show') : null"
  (mouseleave)="!readOnly ? hideAddAnchors() : null"
>
  <div>
    @switch(obj.type) {
      @case ('process-task') {
        <div 
          class="task"
          [style.width.px]="obj.width"
          [style.height.px]="obj.height"
          [style.borderColor]="obj.outline"
          [style.backgroundColor]="obj.fill"
        >
          <div
            [id]="'process-task-body-'+obj.id"
            class="task__body"
          >
            {{obj.name}}
          </div>
          @if (!!obj.children) {
            @if (obj.children.length > 0) {
              <div class="task__footer">
                @for (child of obj.children; track $index) {
                  <naris-button
                    size="small"
                    color="transparent"
                    [icon]="child | lowercase"
                    (click)="openConnectedObjects(child)"
                    id="ProcessNodeConnectedObjects"
                  />
                }
              </div>
            }
          }
        </div>
      }
      @case ('sub-process') {
        <div 
          class="task"
          [style.width.px]="obj.width"
          [style.height.px]="obj.height"
          [style.borderColor]="obj.outline"
          [style.backgroundColor]="obj.fill"
        >
          <div
            [id]="'process-task-body-'+obj.id"
            class="task__body"
          >
            {{obj.name}}
          </div>
          <div class="task__go-to">
            <naris-button
              icon="go-to"
              color="transparent"
              size="small"
              (click)="openSubProcess()"
              id="ProcessNodeSubProcess"
            />
          </div>
        </div>
      }
      @case ('process-startEvent') {
        <svg
          [id]="'icon-'+obj.id"
          [attr.width]="obj.width" 
          [attr.height]="obj.height" 
          [attr.viewBox]="'0 0 '+obj.width+' '+obj.height"
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle [attr.cx]="obj.width / 2" [attr.cy]="obj.height / 2" [attr.r]="(obj.width / 2) - obj.outlineWidth / 2" [attr.stroke]="obj.outline" [attr.stroke-width]="obj.outlineWidth"/>
        </svg>
      }
      @case ('process-intermediateEvent') {
        <svg 
          [id]="'icon-'+obj.id"
          [attr.width]="obj.width" 
          [attr.height]="obj.height" 
          [attr.viewBox]="'0 0 '+obj.width+' '+obj.height"
          fill="#fff" 
          xmlns="http://www.w3.org/2000/svg" 
        >
          <path fill-rule="evenodd" clip-rule="evenodd" d="M25 47C37.1503 47 47 37.1503 47 25C47 12.8497 37.1503 3 25 3C12.8497 3 3 12.8497 3 25C3 37.1503 12.8497 47 25 47ZM25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z" fill="black"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M25 40C33.2843 40 40 33.2843 40 25C40 16.7157 33.2843 10 25 10C16.7157 10 10 16.7157 10 25C10 33.2843 16.7157 40 25 40ZM25 43C34.9411 43 43 34.9411 43 25C43 15.0589 34.9411 7 25 7C15.0589 7 7 15.0589 7 25C7 34.9411 15.0589 43 25 43Z" fill="black"/>
        </svg>
      }
      @case ('process-endEvent') {
        <svg
          [id]="'icon-'+obj.id"
          [attr.width]="obj.width" 
          [attr.height]="obj.height" 
          [attr.viewBox]="'0 0 '+obj.width+' '+obj.height"
          fill="none" 
          xmlns="http://www.w3.org/2000/svg" 
        >
          <circle [attr.cx]="obj.width / 2" [attr.cy]="obj.height / 2" [attr.r]="(obj.width / 2) - obj.outlineWidth / 2" [attr.stroke]="obj.outline" [attr.stroke-width]="obj.outlineWidth"/>
        </svg>
      }
      @case ('process-condition') {
        <svg
          [id]="'icon-'+obj.id"
          [attr.width]="obj.width" 
          [attr.height]="obj.height" 
          [attr.viewBox]="'0 0 '+obj.width+' '+obj.height"
          fill="none" 
          xmlns="http://www.w3.org/2000/svg" 
        >
          <path d="M3.41753 32.9038L32.8897 3.43159C35.4281 0.893185 39.5437 0.893186 42.0821 3.4316L71.5543 32.9038C74.0927 35.4422 74.0927 39.5578 71.5543 42.0962L42.0821 71.5684C39.5437 74.1068 35.4281 74.1068 32.8897 71.5684L3.41752 42.0962C0.879115 39.5578 0.879118 35.4422 3.41753 32.9038Z" [attr.stroke]="obj.outline" [attr.stroke-width]="3" [attr.fill]="obj.fill"/>
        </svg> 
      }
      @case ('process-datastore') {
        <svg 
          [id]="'icon-'+obj.id" 
          [attr.width]="obj.width"
          [attr.height]="obj.height"
          [attr.viewBox]="'0 0 '+obj.width+' '+obj.height"
          fill="none"
          xmlns="http://www.w3.org/2000/svg" 
        >
          <path d="M16.6667 29.1667V70.8333C16.6667 80.0417 31.5917 87.5 50 87.5C68.4084 87.5 83.3334 80.0417 83.3334 70.8333V29.1667M16.6667 29.1667C16.6667 38.375 31.5917 45.8333 50 45.8333C68.4084 45.8333 83.3334 38.375 83.3334 29.1667M16.6667 29.1667C16.6667 19.9583 31.5917 12.5 50 12.5C68.4084 12.5 83.3334 19.9583 83.3334 29.1667M83.3334 50C83.3334 59.2083 68.4084 66.6667 50 66.6667C31.5917 66.6667 16.6667 59.2083 16.6667 50" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      }
      @case ('process-document') {
        <svg 
          [id]="'icon-'+obj.id"
          [attr.width]="obj.width" 
          [attr.height]="obj.height" 
          [attr.viewBox]="'0 0 '+obj.width+' '+obj.height"
          fill="#fff" 
          xmlns="http://www.w3.org/2000/svg" 
        >
          <path 
            d="M81.25 59.375V48.4375C81.25 44.7079 79.7684 41.131 77.1312 38.4938C74.494 35.8566 70.9171 34.375 67.1875 34.375H60.9375C59.6943 34.375 58.502 33.8811 57.6229 33.0021C56.7439 32.123 56.25 30.9307 56.25 29.6875V23.4375C56.25 19.7079 54.7684 16.131 52.1312 13.4938C49.494 10.8566 45.9171 9.375 42.1875 9.375H34.375M43.75 9.375H23.4375C20.85 9.375 18.75 11.475 18.75 14.0625V85.9375C18.75 88.525 20.85 90.625 23.4375 90.625H76.5625C79.15 90.625 81.25 88.525 81.25 85.9375V46.875C81.25 36.9294 77.2991 27.3911 70.2665 20.3585C63.2339 13.3259 53.6956 9.375 43.75 9.375Z" 
            stroke="black" 
            stroke-width="6" 
            stroke-linecap="round" 
            stroke-linejoin="round"
          />
        </svg>
      }
    }
  </div>

  <!-- Add anchors -->
  @if (showAddProcessStep || showAddCondition || showAddEndEvent) {
    <span 
      #anchor
      class="add-node right"
    >
      <naris-button
        size="small"
        icon="add"
        color="new"
        (mouseenter)="showAddList(rightList)"
        (mouseleave)="hideAddList(rightList)"
        id="ProcessNodeAddNodeRight"
      />
    </span>
  }

  @if (showAddProcessStep || showAddCondition || showAddEndEvent) {
    <span 
      #anchor
      class="add-node top"
    >
      <naris-button
        size="small"
        icon="add"
        color="new"
        (mouseenter)="showAddList(topList)"
        (mouseleave)="hideAddList(topList)"
        id="ProcessNodeAddNodeTop"
      />
    </span>
  }
  
  @if (showAddProcessStep || showAddCondition || showAddEndEvent) {
    <span 
      #anchor
      class="add-node bottom"
    >
      <naris-button
        size="small"
        icon="add"
        color="new"
        (mouseenter)="showAddList(bottomList)"
        (mouseleave)="hideAddList(bottomList)"
        id="ProcessNodeAddNodeBottom"
      />
    </span>
  }

  @if (showAddProcessStep || showAddCondition || showAddEndEvent) {
    <span 
      #anchor
      class="add-node left"
    >
      <naris-button
        size="small"
        icon="add"
        color="new"
        (mouseenter)="showAddList(leftList)"
        (mouseleave)="hideAddList(leftList)"
        id="ProcessNodeAddNodeLeft"
      />
    </span>
  }
  
  <!-- Add anchor list -->
  <div 
    #rightList
    class="add-anchor-list right" 
    (mouseenter)="clearAddListTimeout()"
    (mouseleave)="toggleAnchorList(rightList, 'hide')"
  >
    <div class="add-anchor-list__column">
      @if (showAddProcessStep) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <svg
            [attr.width]="21"
            [attr.height]="16" 
            [attr.viewBox]="'0 0 24 16'"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            [matTooltip]="'process-management.processStep' | translate"
            matTooltipPosition="left"
            (click)="addNode('Process', 'right')"
          >
            <rect x="1.5" y="1.5" [attr.width]="21" [attr.height]="14" [attr.stroke]="'#1d2e40'" [attr.stroke-width]="1" rx="2" />
          </svg>
        </div>
      }
      @if (showAddEndEvent) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <svg
            [attr.width]="24" 
            [attr.height]="24" 
            [attr.viewBox]="'0 0 24 24'"
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            [matTooltip]="'process-management.endEvent' | translate"
            matTooltipPosition="left"
            (click)="addNode('End Event', 'right')"
          >
            <circle [attr.cx]="24 / 2" [attr.cy]="24 / 2" [attr.r]="(18 / 2) - 3 / 2" [attr.stroke]="'#1d2e40'" [attr.stroke-width]="2"/>
          </svg>
        </div>
      }
      @if (showDatastore) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <naris-icon
            icon="process-datastore-node"
            iconClass="process-node-icon"
            [matTooltip]="'process-management.datastore' | translate"
            matTooltipPosition="left"
            (click)="addNode('Datastore', 'right')"
          />
        </div>
      }
    </div>
    <div class="add-anchor-list__column-spacer"></div>
    <div class="add-anchor-list__column">
      @if (showAddCondition) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <svg
            [attr.width]="21"
            [attr.height]="21" 
            [attr.viewBox]="'0 0 24 24'"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            [matTooltip]="'process-management.condition' | translate"
            matTooltipPosition="right"
            (click)="addNode('Condition', 'right')"
          >
            <rect x="5.5" y="2" [attr.width]="14" [attr.height]="14" [attr.stroke]="'#1d2e40'" [attr.stroke-width]="1" [attr.transform]="'rotate(45, 9, 9)'" rx="2" />
          </svg>
        </div>
      }
      @if (showIntermediate) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <naris-icon
            icon="process-intermediateEvent"
            iconClass="process-node-icon"
            [matTooltip]="'process-management.intermediateEvent' | translate"
            matTooltipPosition="right"
            (click)="addNode('IntermediateEvent', 'right')"
          />
        </div>
      }
      @if (showDocument) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <naris-icon
            icon="process-document-node"
            iconClass="process-node-icon"
            [matTooltip]="'process-management.document' | translate"
            matTooltipPosition="right"
            (click)="addNode('Document','right')"
          />
        </div>
      }
      @if (showAddProcess) {
        <div 
          class="add-anchor-list__column__item"
          (mouseleave)="clearAddListTimeout()"
        >
          <naris-icon
            icon="sub-process-node"
            iconClass="process-node-icon"
            [matTooltip]="'process-management.process' | translate"
            matTooltipPosition="right"
            (click)="addNode('SubProcess', 'right')"
          />
        </div>
      }
    </div>
  </div>

  <div 
    #topList
    class="add-anchor-list top" 
    (mouseenter)="clearAddListTimeout()"
    (mouseleave)="toggleAnchorList(topList, 'hide')"
  >
    <div class="add-anchor-list__column">
      @if (showAddProcessStep) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <svg
            [attr.width]="21"
            [attr.height]="16" 
            [attr.viewBox]="'0 0 24 16'"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            [matTooltip]="'process-management.processStep' | translate"
            matTooltipPosition="above"
            (click)="addNode('Process', 'top')"
          >
            <rect x="1.5" y="1.5" [attr.width]="21" [attr.height]="14" [attr.stroke]="'#1d2e40'" [attr.stroke-width]="1" rx="2" />
          </svg>
        </div>
      }
      @if (showAddEndEvent) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <svg
            [attr.width]="24" 
            [attr.height]="24" 
            [attr.viewBox]="'0 0 24 24'"
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            [matTooltip]="'process-management.endEvent' | translate"
            matTooltipPosition="above"
            (click)="addNode('End Event', 'top')"
          >
            <circle [attr.cx]="24 / 2" [attr.cy]="24 / 2" [attr.r]="(18 / 2) - 3 / 2" [attr.stroke]="'#1d2e40'" [attr.stroke-width]="2"/>
          </svg>
        </div>
      }
      @if (showDatastore) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <naris-icon
            icon="process-datastore-node"
            iconClass="process-node-icon"
            [matTooltip]="'process-management.datastore' | translate"
            matTooltipPosition="above"
            (click)="addNode('Datastore', 'top')"
          />
        </div>
      }
    </div>
    <div class="add-anchor-list__column-spacer"></div>
    <div class="add-anchor-list__column">
      @if (showAddCondition) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <svg
            [attr.width]="21"
            [attr.height]="21" 
            [attr.viewBox]="'0 0 24 24'"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            [matTooltip]="'process-management.condition' | translate"
            matTooltipPosition="below"
            (click)="addNode('Condition', 'top')"
          >
            <rect x="5.5" y="2" [attr.width]="14" [attr.height]="14" [attr.stroke]="'#1d2e40'" [attr.stroke-width]="1" [attr.transform]="'rotate(45, 9, 9)'" rx="2" />
          </svg>
        </div>
      }
      @if (showIntermediate) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <naris-icon
            icon="process-intermediateEvent"
            iconClass="process-node-icon"
            [matTooltip]="'process-management.intermediateEvent' | translate"
            matTooltipPosition="below"
            (click)="addNode('IntermediateEvent', 'top')"
          />
        </div>
      }
      @if (showDocument) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <naris-icon
            icon="process-document-node"
            iconClass="process-node-icon"
            [matTooltip]="'process-management.document' | translate"
            matTooltipPosition="below"
            (click)="addNode('Document','top')"
          />
        </div>
      }
      @if (showAddProcess) {
        <div 
          class="add-anchor-list__column__item"
          (mouseleave)="clearAddListTimeout()"
        >
          <naris-icon
            icon="sub-process-node"
            iconClass="process-node-icon"
            [matTooltip]="'process-management.process' | translate"
            matTooltipPosition="below"
            (click)="addNode('SubProcess', 'top')"
          />
        </div>
      }
    </div>
  </div>

  <div 
    #bottomList
    class="add-anchor-list bottom" 
    (mouseenter)="clearAddListTimeout()"
    (mouseleave)="toggleAnchorList(bottomList, 'hide')"
  >
    <div class="add-anchor-list__column">
      @if (showAddProcessStep) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <svg
            [attr.width]="21"
            [attr.height]="16" 
            [attr.viewBox]="'0 0 24 16'"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            [matTooltip]="'process-management.processStep' | translate"
            matTooltipPosition="above"
            (click)="addNode('Process', 'bottom')"
          >
            <rect x="1.5" y="1.5" [attr.width]="21" [attr.height]="14" [attr.stroke]="'#1d2e40'" [attr.stroke-width]="1" rx="2" />
          </svg>
        </div>
      }
      @if (showAddEndEvent) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <svg
            [attr.width]="24" 
            [attr.height]="24" 
            [attr.viewBox]="'0 0 24 24'"
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            [matTooltip]="'process-management.endEvent' | translate"
            matTooltipPosition="above"
            (click)="addNode('End Event', 'bottom')"
          >
            <circle [attr.cx]="24 / 2" [attr.cy]="24 / 2" [attr.r]="(18 / 2) - 3 / 2" [attr.stroke]="'#1d2e40'" [attr.stroke-width]="2"/>
          </svg>
        </div>
      }
      @if (showDatastore) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <naris-icon
            icon="process-datastore-node"
            iconClass="process-node-icon"
            [matTooltip]="'process-management.datastore' | translate"
            matTooltipPosition="above"
            (click)="addNode('Datastore', 'bottom')"
          />
        </div>
      }
      
    </div>
    <div class="add-anchor-list__column-spacer"></div>
    <div class="add-anchor-list__column">
      @if (showAddCondition) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <svg
            [attr.width]="21"
            [attr.height]="21" 
            [attr.viewBox]="'0 0 24 24'"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            [matTooltip]="'process-management.condition' | translate"
            matTooltipPosition="below"
            (click)="addNode('Condition', 'bottom')"
          >
            <rect x="5.5" y="2" [attr.width]="14" [attr.height]="14" [attr.stroke]="'#1d2e40'" [attr.stroke-width]="1" [attr.transform]="'rotate(45, 9, 9)'" rx="2" />
          </svg>
        </div>
      }
      @if (showIntermediate) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <naris-icon
            icon="process-intermediateEvent"
            iconClass="process-node-icon"
            [matTooltip]="'process-management.intermediateEvent' | translate"
            matTooltipPosition="below"
            (click)="addNode('IntermediateEvent', 'bottom')"
          />
        </div>
      }
      @if (showDocument) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <naris-icon
            icon="process-document-node"
            iconClass="process-node-icon"
            [matTooltip]="'process-management.document' | translate"
            matTooltipPosition="below"
            (click)="addNode('Document','bottom')"
          />
        </div>
      }
      @if (showAddProcess) {
        <div 
          class="add-anchor-list__column__item"
          (mouseleave)="clearAddListTimeout()"
        >
          <naris-icon
            icon="sub-process-node"
            iconClass="process-node-icon"
            [matTooltip]="'process-management.process' | translate"
            matTooltipPosition="below"
            (click)="addNode('SubProcess', 'bottom')"
          />
        </div>
      }
    </div>
  </div>

  <div 
    #leftList
    class="add-anchor-list left" 
    (mouseenter)="clearAddListTimeout()"
    (mouseleave)="toggleAnchorList(leftList, 'hide')"
  >
    <div class="add-anchor-list__column">
      @if (showAddProcessStep) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <svg
            [attr.width]="21"
            [attr.height]="16" 
            [attr.viewBox]="'0 0 24 16'"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            [matTooltip]="'process-management.processStep' | translate"
            matTooltipPosition="left"
            (click)="addNode('Process', 'left')"
          >
            <rect x="1.5" y="1.5" [attr.width]="21" [attr.height]="14" [attr.stroke]="'#1d2e40'" [attr.stroke-width]="1" rx="2" />
          </svg>
        </div>
      }
      @if (showAddEndEvent) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <svg
            [attr.width]="24" 
            [attr.height]="24" 
            [attr.viewBox]="'0 0 24 24'"
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            [matTooltip]="'process-management.endEvent' | translate"
            matTooltipPosition="left"
            (click)="addNode('End Event', 'left')"
          >
            <circle [attr.cx]="24 / 2" [attr.cy]="24 / 2" [attr.r]="(18 / 2) - 3 / 2" [attr.stroke]="'#1d2e40'" [attr.stroke-width]="2"/>
          </svg>
        </div>
      }
      @if (showDatastore) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <naris-icon
            icon="process-datastore-node"
            iconClass="process-node-icon"
            [matTooltip]="'process-management.datastore' | translate"
            matTooltipPosition="left"
            (click)="addNode('Datastore', 'left')"
          />
        </div>
      }
    </div>
    <div class="add-anchor-list__column-spacer"></div>
    <div class="add-anchor-list__column">
      @if (showAddCondition) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <svg
            [attr.width]="21"
            [attr.height]="21" 
            [attr.viewBox]="'0 0 24 24'"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            [matTooltip]="'process-management.condition' | translate"
            matTooltipPosition="right"
            (click)="addNode('Condition', 'left')"
          >
            <rect x="5.5" y="2" [attr.width]="14" [attr.height]="14" [attr.stroke]="'#1d2e40'" [attr.stroke-width]="1" [attr.transform]="'rotate(45, 9, 9)'" rx="2" />
          </svg>
        </div>
      }
      @if (showIntermediate) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <naris-icon
            icon="process-intermediateEvent"
            iconClass="process-node-icon"
            [matTooltip]="'process-management.intermediateEvent' | translate"
            matTooltipPosition="right"
            (click)="addNode('IntermediateEvent', 'left')"
          />
        </div>
      }
      @if (showDocument) {
        <div 
          class="add-anchor-list__column__item" 
          (mouseleave)="clearAddListTimeout()"
        >
          <naris-icon
            icon="process-document-node"
            iconClass="process-node-icon"
            [matTooltip]="'process-management.document' | translate"
            matTooltipPosition="right"
            (click)="addNode('Document','left')"
          />
        </div>
      }
      @if (showAddProcess) {
        <div 
          class="add-anchor-list__column__item"
          (mouseleave)="clearAddListTimeout()"
        >
          <naris-icon
            icon="sub-process-node"
            iconClass="process-node-icon"
            [matTooltip]="'process-management.process' | translate"
            matTooltipPosition="right"
            (click)="addNode('SubProcess', 'left')"
          />
        </div>
      }
    </div>
  </div>

  
  @if (!readOnly) {
    <!-- Anchors-->
    @if (showAddRelation) {
      @for (anchor of anchorPositions; track $index) {
        <div
          class="jtk-connect jtk-connect-{{anchor.id}}"
          data-jtk-source="true"
        ></div>
      }
    }
  }

  @if (obj.type === 'process-condition') {
    <div 
      class="condition-label"
    >
      {{obj.name}}
    </div>
  }
</div>
