import { Component, ElementRef,  Inject,  Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { fadeInOutAnimation } from '@shared/animations/core.animations';
import { DashboardService } from '@core/services/dashboard.service';
import { FORM_LOOKUP_TOKEN } from '@core/constants';
import { ComponentType } from '@angular/cdk/portal';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { FormLookupComponent } from '@core/form/form-lookup/form-lookup.component';
import { NgClass } from '@angular/common';
import { EmptyStateComponent } from '../empty-state/empty-state.component';
import type { IFormAnchorElementDynamic, IHeatmap } from '@core/models';

@Component({
  selector: 'naris-widget-heatmap',
  templateUrl: './widget-heatmap.component.html',
  styleUrls: ['./widget-heatmap.component.scss'],
  animations: [fadeInOutAnimation],
  standalone: true,
  imports: [NgClass, EmptyStateComponent, TranslateModule]
})
export class WidgetHeatmapComponent {

  @ViewChild('heatmapCanvas', {static: false}) public heatmapCanvas: ElementRef;
  @ViewChild('heatmap', {static: false}) public heatmap: ElementRef;

  @Input() public data?: IFormAnchorElementDynamic[] | null;
  @Input() set heatmapData(value: IHeatmap[] | null) {
    this._heatmapData = value;
    if (!!value?.length) {
      this.isHeatmapSet = true;
      setTimeout(() => {
        this.drawHeatmap(value);
        this.drawHeatmapData(value.length);
          
        this.getHeatmapDimensions();
      });
    } else this.isHeatmapSet = false;

    this.dashboardService.dashboardWidgetResized$.subscribe((href: string) => {
      if (this.href !== href) return;
      this.getHeatmapDimensions();
    });
  }
  @Input() public isOverview = false;

  @Input() public href: string; //! only for checking resized event!

  @Input() public heatmapTableHref: string | undefined;

  public nodeRisks: any[] = [];

  public labelWidth: number;
  public heatmapDimensions: {size: number[]}[] = [];

  public selectedNode: { x: number; y: number } | null;
  public heatmapValues: Record<string, any>[][] = [];
  public isHeatmapSet = false;
  public heatmapWidth: number;
  public heatmapHeight: number;
  public isOverviewExpanded: boolean;

  private ctx: CanvasRenderingContext2D;
  private width: number;
  private height: number;
  private maxCount = 1;
  private _heatmapData: IHeatmap[] | null;

  public yLabelLeft: number;
  public canvasWidth: number;

  constructor(
    private readonly router: Router,
    private readonly dashboardService: DashboardService,
    private readonly dialog: MatDialog,
    @Inject(FORM_LOOKUP_TOKEN) protected readonly formLookupComponent: ComponentType<FormLookupComponent>,
    private readonly translate: TranslateService
  ) {}

  private getHeatmapDimensions() {
    this.heatmapDimensions = [];
    this.heatmapValues.forEach((yValue, y) => {
      yValue.forEach((xValue, x) => {
        this.getCount(x + 1, y + 1);
      });
    });
    this.heatmapValues.forEach((yValue, y) => {
      if (!this.heatmapDimensions[y]) this.heatmapDimensions.push({size: []});
      yValue.forEach((xValue, x) => {
        this.heatmapDimensions[y].size.push(this.getCountSize(this.getCount(x + 1, this.heatmapValues.length - y)));
      });
    });
  }

  private drawHeatmap(heatmapHeatmap: IHeatmap[]) {
    this.ctx = this.heatmapCanvas.nativeElement.getContext('2d');
    const canvas = this.heatmapCanvas.nativeElement;
    canvas.height = canvas.width;
    this.width = canvas.width;
    this.height = canvas.height;
    this.heatmapWidth = this.width / heatmapHeatmap.length;
    this.heatmapHeight = this.height / heatmapHeatmap.length;
    heatmapHeatmap.forEach((_, yIndex) => {
      heatmapHeatmap.forEach((__, xIndex) => {
        const colorValue = (yIndex + 1) * (xIndex + 1);
        const heatmap = heatmapHeatmap.find(x => colorValue <= x.RangeMaximum && colorValue >= x.RangeMinimum);
        if (!heatmap) return;
        const xPosition = xIndex * this.heatmapWidth;
        const yPosition = this.height - (yIndex + 1) * this.heatmapHeight;
        this.ctx.fillStyle = heatmap.HEXColorCode;
        this.ctx.fillRect(xPosition, yPosition, this.heatmapWidth, this.heatmapHeight);
        this.ctx.strokeStyle = 'white';
        this.ctx.strokeRect(xPosition, yPosition, this.heatmapWidth, this.heatmapHeight);
      });
    });
  }

  private drawHeatmapData(axisLength: number) {
    this.heatmapValues = [];
    let count = 1;
    for (let y = 1; y <= axisLength; y++) {
      const xValues = [];
      for (let x = 1; x <= axisLength; x++) {
        xValues.push({count: count++});
      }
      this.heatmapValues.push(xValues);
    }
  }


  public getCount(x: number, y: number): number {
    let count = 0;
    this.data?.forEach(item => {
      if (item.elements.LikelihoodClassRank === x && item.elements.ConsequenceClassRank === y) count++;
    });
    this.maxCount = count > this.maxCount ? count : this.maxCount;
    return count;
  }

  public getCountSize(count: number): number {
    const canvas = this.heatmapCanvas.nativeElement;
    const canvasWidth = canvas.offsetWidth + 3;
    const heatmapWidth = canvasWidth / 5;
    const maxSize = heatmapWidth - 20;
    const minSize = 18;
    const percentage = 100 * count / this.maxCount;
    return (maxSize - minSize) * percentage / 100 + minSize;
  }

  public setSelectedNode(x: number, y: number) {
    const nodeData = this.data?.filter(item => item.elements.LikelihoodClassRank === x && item.elements.ConsequenceClassRank === y);
    if (!nodeData?.length) return;
    const mappedData = nodeData?.map(item => item.elements.FilterOption);
    if (!this.heatmapTableHref) return;
    const queryParamString = this.heatmapTableHref.split('?')[1];
    const queryParams = queryParamString.split('&').map(param => {
      if (param.includes('{FilterOption}') && !!mappedData) return param.replace('{FilterOption}', mappedData?.join(','));
      else return param;
    }).join('&');
    const href = `${this.heatmapTableHref.split('?')[0]}?${queryParams}`;
    this.dialog.open(this.formLookupComponent, {
      panelClass: 'naris-advanced-lookup-dialog',
      minWidth: '54rem',
      data: {endpoint: href, multiple: false}
    }).afterClosed().subscribe(val => {
      if (!!val?._links?.self.href) {
        void this.router.navigate([val._links.self.href]);
      }
    });
  }

  public navigateTo(risk: any) {
    void this.router.navigate(['open-case', risk.data.RiskAssessmentCaseID]);
  }
}
