import { inherits as t, createSuper as e, classCallCheck as r, defineProperty as s, assertThisInitialized as i } from "../_virtual/_rollupPluginBabelHelpers.js";
import { ElementEntry as n } from "./element-entry.js";
import { Elements as o } from "./elements.js";
import { processExpression as a, evaluateExpression as l } from "./expressions.js";
import { data as u } from "../core/util.js";
var p = function (n) {
  t(l, n);
  var o = e(l);
  function l(t, e, n, u, p) {
    var f;
    r(this, l);
    f = o.call(this, t, e, n, u, p);
    s(i(f), "type", "if");
    s(i(f), "tag", l.tag);
    s(i(f), "test", void 0);
    s(i(f), "expression", void 0);
    f.test = t.atts["test"];
    f.expression = a(f.test);
    return f;
  }
  return l;
}(n);
s(p, "tag", "r-if");
o.register(p.tag, p);
function f(t, e, r) {
  t.resolution = l(t.entry.expression, function (t) {
    return u(e, t);
  }, function (t) {
    return r(t);
  });
  return t.resolution;
}
export { p as IfEntry, f as resolveIfExecution };