import { classCallCheck as e, defineProperty as t, inherits as r, createSuper as n, assertThisInitialized as i, createClass as o } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { DotEndpoint as s } from "../endpoint/dot-endpoint.js";
import { ATTRIBUTE_GROUP as a } from "../constants.js";
import { EVENT_GROUP_MEMBER_REMOVED as l, EVENT_NESTED_GROUP_ADDED as u, EVENT_NESTED_GROUP_REMOVED as h } from "../event-constants.js";
import { getWithFunction as c, removeWithFunction as d, log as p } from "../../util/util.js";
var v = function r(n, i, o) {
  e(this, r);
  this.instance = n;
  this.el = i;
  this.id = o;
  t(this, "uiGroup", void 0);
};
var f = function (f) {
  r(g, f);
  var m = n(g);
  function g(r, n, o, s) {
    var l;
    e(this, g);
    l = m.call(this, r, o, n.id);
    l.instance = r;
    l.group = n;
    t(i(l), "children", []);
    t(i(l), "collapsed", false);
    t(i(l), "elementsDraggable", void 0);
    t(i(l), "droppable", void 0);
    t(i(l), "enabled", void 0);
    t(i(l), "orphan", void 0);
    t(i(l), "constrain", void 0);
    t(i(l), "proxied", void 0);
    t(i(l), "ghost", void 0);
    t(i(l), "revert", void 0);
    t(i(l), "prune", void 0);
    t(i(l), "dropOverride", void 0);
    t(i(l), "elastic", void 0);
    t(i(l), "contentArea", void 0);
    t(i(l), "anchor", void 0);
    t(i(l), "endpoint", void 0);
    t(i(l), "connections", {
      source: [],
      target: [],
      internal: []
    });
    t(i(l), "manager", void 0);
    var u = l.el;
    l.contentArea = l.instance.getGroupContentArea(i(l));
    u._isJsPlumbGroup = true;
    u._jsPlumbGroup = i(l);
    l.orphan = s.orphan === true;
    l.revert = l.orphan === true ? false : s.revert !== false;
    l.droppable = s.droppable !== false;
    l.ghost = s.ghost === true;
    l.enabled = s.enabled !== false;
    l.elastic = s.elastic === true;
    l.elementsDraggable = s.elementsDraggable !== false;
    l.prune = l.orphan !== true && s.prune === true;
    l.constrain = l.ghost || s.constrain === true;
    l.proxied = s.proxied !== false;
    l.id = n.id;
    l.dropOverride = s.dropOverride === true;
    l.anchor = s.anchor;
    l.endpoint = s.endpoint;
    l.anchor = s.anchor;
    r.setAttribute(o, a, "");
    return l;
  }
  o(g, [{
    key: "overrideDrop",
    value: function e(t, r) {
      return this.dropOverride && (this.revert || this.prune || this.orphan);
    }
  }, {
    key: "getAnchor",
    value: function e(t, r) {
      return this.anchor || "Continuous";
    }
  }, {
    key: "getEndpoint",
    value: function e(t, r) {
      return this.endpoint || {
        type: s.type,
        options: {
          radius: 10
        }
      };
    }
  }, {
    key: "add",
    value: function e(t, r, n) {
      var i = this.contentArea;
      var o = t;
      if (o._jsPlumbParentGroup != null) {
        if (o._jsPlumbParentGroup === this) {
          return;
        } else {
          o._jsPlumbParentGroup.remove(t, true, n, false);
        }
      }
      o._jsPlumbParentGroup = this;
      this.children.push(new v(this.instance, t, r));
      this.instance._appendElement(o, i);
      this.manager._updateConnectionsForGroup(this);
    }
  }, {
    key: "resolveNode",
    value: function e(t) {
      return t == null ? null : c(this.children, function (e) {
        return e.el === t;
      });
    }
  }, {
    key: "remove",
    value: function e(t, r, n, i, o) {
      var s = this.resolveNode(t);
      if (s != null) {
        this._doRemove(s, r, n, i, o);
      }
    }
  }, {
    key: "_doRemove",
    value: function e(t, r, n, i, o) {
      var s = t.el;
      delete s._jsPlumbParentGroup;
      d(this.children, function (e) {
        return e === t;
      });
      if (r) {
        try {
          this.instance._removeElement(s);
        } catch (e) {
          p("Could not remove element from Group " + e);
        }
      }
      if (!n) {
        var a = {
          group: this,
          el: s
        };
        if (o) {
          a.targetGroup = o;
        }
        this.instance.fire(l, a);
      }
      if (!i) {
        this.manager._updateConnectionsForGroup(this);
      }
    }
  }, {
    key: "removeAll",
    value: function e(t, r) {
      for (var n = 0, i = this.children.length; n < i; n++) {
        var o = this.children[0];
        this._doRemove(o, t, r, true);
        this.instance.unmanage(o.el, true);
      }
      this.children.length = 0;
      this.manager._updateConnectionsForGroup(this);
    }
  }, {
    key: "orphanAll",
    value: function e() {
      var t = {};
      for (var r = 0; r < this.children.length; r++) {
        var n = this.manager.orphan(this.children[r].el, false);
        t[n.id] = n.pos;
      }
      this.children.length = 0;
      return t;
    }
  }, {
    key: "addGroup",
    value: function e(t) {
      if (this.instance.allowNestedGroups && t !== this) {
        if (this.instance.groupManager.isAncestor(this, t)) {
          return false;
        }
        if (t.uiGroup != null) {
          t.uiGroup.removeGroup(t);
        }
        var r = this.instance.getManagedElements()[t.id];
        r.group = this.id;
        var n = this.instance.getOffsetRelativeToRoot(t.el);
        var i = this.collapsed ? this.instance.getOffsetRelativeToRoot(this.el) : this.instance.getOffsetRelativeToRoot(this.contentArea);
        t.el._jsPlumbParentGroup = this;
        this.children.push(t);
        this.instance._appendElementToGroup(this, t.el);
        t.uiGroup = this;
        var o = {
          x: n.x - i.x,
          y: n.y - i.y
        };
        this.instance.setPosition(t.el, o);
        this.instance.fire(u, {
          parent: this,
          child: t
        });
        return true;
      } else {
        return false;
      }
    }
  }, {
    key: "removeGroup",
    value: function e(t) {
      if (t.uiGroup === this) {
        var r = t.el;
        var n = this.contentArea;
        if (n === r.parentNode) {
          this.instance._removeElement(t.el);
        }
        var i = this.instance.getId(t.el);
        var o = this.instance.getManagedElements()[i];
        if (o) {
          delete o.group;
        }
        this.children = this.children.filter(function (e) {
          return e.id !== t.id;
        });
        delete t.uiGroup;
        delete r._jsPlumbParentGroup;
        this.instance.fire(h, {
          parent: this,
          child: t
        });
      }
    }
  }, {
    key: "getGroups",
    value: function e() {
      return this.children.filter(function (e) {
        return e.constructor === g;
      });
    }
  }, {
    key: "getNodes",
    value: function e() {
      return this.children.filter(function (e) {
        return e.constructor === v;
      });
    }
  }, {
    key: "collapseParent",
    get: function e() {
      var t = null;
      if (this.uiGroup == null) {
        return null;
      } else {
        var r = this.uiGroup;
        while (r != null) {
          if (r.collapsed) {
            t = r;
          }
          r = r.uiGroup;
        }
        return t;
      }
    }
  }]);
  return g;
}(v);
export { f as UIGroup, v as UINode };