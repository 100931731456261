import { inherits as e, createSuper as r, classCallCheck as t, defineProperty as a, assertThisInitialized as s, createClass as o } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { Overlay as i } from "./overlay.js";
import { OverlayFactory as n } from "../factory/overlay-factory.js";
var c = "Custom";
var l = function (i) {
  e(l, i);
  var n = r(l);
  function l(e, r, o) {
    var i;
    t(this, l);
    i = n.call(this, e, r, o);
    i.instance = e;
    i.component = r;
    a(s(i), "create", void 0);
    a(s(i), "type", c);
    i.create = o.create;
    return i;
  }
  o(l, [{
    key: "updateFrom",
    value: function e(r) {}
  }]);
  return l;
}(i);
a(l, "type", c);
function p(e) {
  return e.type === l.type;
}
n.register(l.type, l);
export { l as CustomOverlay, c as OVERLAY_TYPE_CUSTOM, p as isCustomOverlay };