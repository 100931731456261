import { classCallCheck as i, defineProperty as s } from "../_virtual/_rollupPluginBabelHelpers.js";
var r = function r(t) {
  i(this, r);
  s(this, "grid", void 0);
  s(this, "snap", false);
  s(this, "fitGroupsToGrid", false);
  if (t != null) {
    this.snap = t.snap === true;
    this.grid = t.size;
    this.fitGroupsToGrid = t.fitGroupsToGrid !== false;
  }
};
export { r as GridProfile };