import { Pipe, PipeTransform } from '@angular/core';
import type { FormControl, FormGroup } from '@angular/forms';
import type { ISubItem } from '@core/models';

@Pipe({
  name: 'asType',
  standalone: true
})
export class AsTypePipe implements PipeTransform {

  public transform(value: unknown, asType: 'string'): string;
  public transform(value: unknown, asType: 'number'): number;
  public transform(value: unknown, asType: 'boolean'): boolean;
  public transform(value: unknown, asType: 'ISubItem'): ISubItem;
  public transform(value: unknown, asType: 'FormControl'): FormControl;
  public transform(value: unknown, asType: 'FormGroup'): FormGroup;
  public transform(value: unknown, _: string) {
    return value;
  }
}
