var e = {};
var r = {
  get: function r(n, t, o, a) {
    var s = e[t];
    if (!s) {
      throw {
        message: "jsPlumb: unknown overlay type '" + t + "'"
      };
    } else {
      return new s(n, o, a);
    }
  },
  register: function r(n, t) {
    e[n] = t;
  }
};
export { r as OverlayFactory };