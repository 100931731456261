import { Injectable } from '@angular/core';
import { ICaseListAction } from '@core/models';
import { Base } from '@jsplumbtoolkit/browser-ui';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArchimateService {

  public edgeTypeChanged$: Subject<{id: string; type: string; label: string}> = new Subject<{id: string; type: string; label: string}>();
  public junctionTypeChanged$: Subject<{id: string; type: string}> = new Subject<{id: string; type: string}>();
  public closeInspector$: Subject<void> = new Subject<void>();
  public remove$: Subject<Base> = new Subject<Base>();
  public updateObjectName$: Subject<any> = new Subject<any>();

  public position: string;
  public type: string;
  public newActions: ICaseListAction[] = [];

}
