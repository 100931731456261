import { Component, forwardRef, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TableComponent } from '@shared/components/table/table.component';
import { FormComponent } from '../form.component';
import { TreeviewComponent } from '../../../shared/components/treeview/treeview.component';
import type { ICaseListRow, IFormLookupCreateSuccess } from '@core/models';

@Component({
  selector: 'app-form-lookup',
  templateUrl: './form-lookup.component.html',
  styleUrls: ['./form-lookup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [forwardRef(() => TableComponent), forwardRef(() => FormComponent), forwardRef(() => TreeviewComponent)]
})
export class FormLookupComponent {
  public value: ICaseListRow;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      endpoint: string;
      multiple: boolean;
      selected: [];
      layouthint: string[] | undefined;
      createAction: boolean;
      isForm: boolean;
      createEndpoint: string;
      enableRowClick: boolean;
    },
    public dialogRef: MatDialogRef<FormLookupComponent>
  ) {}

  public setValue(event: any) {
    this.value = event;
    this.dialogRef.close(this.value);
  }

  public onCreateAction() {
    this.dialogRef.close('createAction');
  }

  public onLookupCreate(event: IFormLookupCreateSuccess) {
    this.dialogRef.close(event);
  }

  public popupClosed() {
    this.dialogRef.close(true);
  }

  get isInfoClass(): boolean {
    return this.data.endpoint.includes('information-classifications-list');
  }
}
