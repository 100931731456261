import { inherits as e, createSuper as t, classCallCheck as o, defineProperty as i, assertThisInitialized as n, createClass as r } from "../_virtual/_rollupPluginBabelHelpers.js";
import { EVENT_NODE_REMOVED as u, EVENT_PORT_REMOVED as l, EVENT_EDGE_REMOVED as s, EVENT_EDGE_TARGET_CHANGED as a, EVENT_EDGE_SOURCE_CHANGED as d, EVENT_NODE_ADDED as c, EVENT_NODE_UPDATED as f, EVENT_GROUP_UPDATED as h, EVENT_EDGE_UPDATED as g, EVENT_PORT_UPDATED as p, EVENT_GRAPH_CLEARED as y, EVENT_DATA_LOAD_END as v, EVENT_GRAPH_CLEAR_START as _, EVENT_DATA_LOAD_START as k } from "./constants.js";
import { Edge as b, Node as m, Group as j } from "./model/graph.js";
import { removeWithFunction as E, findWithFunction as T, isString as I } from "../ui-core/util/util.js";
import { EVENT_GROUP_REMOVED as G, EVENT_GROUP_ADDED as O } from "../ui-core/core/event-constants.js";
import { EventGenerator as x } from "../ui-core/util/event-generator.js";
var F;
(function (e) {
  e["mixed"] = "mixed";
  e["isolated"] = "isolated";
  e["nodesOnly"] = "nodesOnly";
  e["groupsOnly"] = "groupsOnly";
  e["edgesOnly"] = "edgesOnly";
})(F || (F = {}));
var N = function (x) {
  e(C, x);
  var N = t(C);
  function C(e, t) {
    var r;
    o(this, C);
    r = N.call(this);
    r.toolkit = e;
    i(n(r), "maxNodes", Infinity);
    i(n(r), "maxEdges", Infinity);
    i(n(r), "maxGroups", Infinity);
    i(n(r), "_nodes", []);
    i(n(r), "_groups", []);
    i(n(r), "_edges", []);
    i(n(r), "capacityPolicy", void 0);
    i(n(r), "generator", void 0);
    i(n(r), "onReload", void 0);
    i(n(r), "onBeforeReload", void 0);
    i(n(r), "onClear", void 0);
    i(n(r), "autoFill", void 0);
    i(n(r), "dataSource", void 0);
    i(n(r), "_$_objMap", {});
    i(n(r), "_mode", void 0);
    i(n(r), "_loading", false);
    i(n(r), "edgeFactory", void 0);
    t = t || {};
    r.edgeFactory = e.edgeFactory;
    r.generator = t.generator;
    r.onReload = t.onReload;
    r.onBeforeReload = t.onBeforeReload;
    r.autoFill = t.autoFill === true;
    r.onClear = t.onClear || function () {};
    r._mode = t.mode || F.mixed;
    r.toolkit.bind(u, function (e) {
      r._removeOne(e.node);
    });
    r.toolkit.bind(G, function (e) {
      r._removeOne(e.group);
    });
    r.toolkit.bind(l, function (e) {
      r._removeOne(e.port);
    });
    r.toolkit.bind(s, function (e) {
      r._removeOne(e.edge);
    });
    r.toolkit.bind(a, function (e) {
      if (r._$_objMap[e.edge.getFullId()]) {
        r.fire(a, e);
      }
    });
    r.toolkit.bind(d, function (e) {
      if (r._$_objMap[e.edge.getFullId()]) {
        r.fire(d, e);
      }
    });
    r.toolkit.bind(c, function (e) {
      if (r.generator && r.autoFill && !r._loading) {
        r.reload();
      }
    });
    r.toolkit.bind(O, function (e) {
      if (r.generator && r.autoFill && !r._loading) {
        r.reload();
      }
    });
    r.toolkit.bind(f, function (e) {
      if (r._$_objMap[e.vertex.getFullId()]) {
        r.fire(f, e);
      }
    });
    r.toolkit.bind(h, function (e) {
      if (r._$_objMap[e.vertex.getFullId()]) {
        r.fire(h, e);
      }
    });
    r.toolkit.bind(g, function (e) {
      if (r._$_objMap[e.edge.getFullId()]) {
        r.fire(g, e);
      }
    });
    r.toolkit.bind(p, function (e) {
      if (e.port != null && r._$_objMap[e.port.getFullId()]) {
        r.fire(p, e);
      }
    });
    r.toolkit.bind(y, function () {
      r.clear();
    });
    r.toolkit.bind(v, function () {
      r.reload();
    });
    r.setCapacityPolicy(C.DISCARD_EXISTING);
    if (t.lazy !== true) {
      r.reload();
    }
    return r;
  }
  r(C, [{
    key: "_getList",
    value: function e(t) {
      return t.objectType === b.objectType ? this._edges : t.objectType === m.objectType ? this._nodes : this._groups;
    }
  }, {
    key: "setMode",
    value: function e(t) {
      this._mode = t;
      if (t === F.isolated) {
        this._clearAll();
      } else if (t === F.edgesOnly) {
        this._clearVertices();
      } else if (t === F.nodesOnly) {
        this._clearEdges();
        this._clearGroups();
      } else if (t === F.groupsOnly) {
        this._clearEdges();
        this._clearNodes();
      }
    }
  }, {
    key: "_pushToList",
    value: function e(t) {
      var o = [];
      var i = this._getList(t),
        n = t.objectType === b.objectType ? this.maxEdges : t.objectType === m.objectType ? this.maxNodes : this.maxGroups;
      if (i.length >= n) {
        if (this.capacityPolicy === C.DISCARD_NEW) {
          return false;
        } else {
          o = i.splice(0, 1);
          this._fireListEvent(o[0], ":removed");
          delete this._$_objMap[o[0].getFullId()];
        }
      }
      i.push(t);
      this._fireListEvent(t, ":added");
      return o;
    }
  }, {
    key: "_fireListEvent",
    value: function e(t, o) {
      var i = t.objectType.toLowerCase() + o,
        n = {
          Group: function e(t) {
            return {
              data: t.data,
              group: t
            };
          },
          Node: function e(t) {
            return {
              data: t.data,
              node: t
            };
          },
          Port: function (e) {
            function t(t) {
              return e.apply(this, arguments);
            }
            t.toString = function () {
              return e.toString();
            };
            return t;
          }(function (e) {
            return {
              data: e.data,
              node: e.getParent(),
              port: e
            };
          }),
          Edge: function e(t) {
            return {
              data: t.data,
              edge: t
            };
          }
        };
      this.fire(i, n[t.objectType](t));
    }
  }, {
    key: "_addOne",
    value: function e(t, o) {
      if (this._modePermitsAddition(t) && !this._$_objMap[t.getFullId()]) {
        var i = this._pushToList(t);
        if (i === false) {
          return [[], []];
        } else {
          this._$_objMap[t.getFullId()] = t;
          if (o) o(t, true);
          return [[t], i];
        }
      }
      return [[], []];
    }
  }, {
    key: "_modePermitsAddition",
    value: function e(t) {
      if (this._mode === F.mixed) {
        return true;
      } else if (this._mode === F.nodesOnly && t.objectType === m.objectType) {
        return true;
      } else if (this._mode === F.groupsOnly && t.objectType === j.objectType) {
        return true;
      } else if (this._mode === F.edgesOnly && t.objectType === b.objectType) {
        return true;
      } else if (this._mode === F.isolated) {
        if (t.objectType === b.objectType) {
          return this._nodes.length === 0 && this._groups.length === 0;
        } else {
          return this._edges.length === 0;
        }
      } else {
        return false;
      }
    }
  }, {
    key: "_removeOne",
    value: function e(t, o, i) {
      var n = t.getFullId();
      var r = E(this._getList(t), function (e) {
        return e.getFullId() == n;
      });
      if (r) {
        this._fireListEvent(t, ":removed");
      }
      delete this._$_objMap[t.getFullId()];
      if (o) {
        o(t, false);
      }
      return [[], []];
    }
  }, {
    key: "_toggle",
    value: function e(t, o) {
      if (this._$_objMap[t.getFullId()]) return this._removeOne(t, o);else return this._addOne(t, o);
    }
  }, {
    key: "_makeSenseOf",
    value: function e(t, o, i) {
      var n = this;
      var r = [],
        u = [],
        l;
      if (t == null) return r;
      var s = function e(t) {
        var s;
        if (I(t)) {
          s = n.toolkit.getNode(t) || n.toolkit.getEdge(t) || n.toolkit.getGroup(t);
          if (s != null) {
            l = o(s, i);
            r.push.apply(r, l[0]);
            u.push.apply(u, l[1]);
          }
        } else if (t.eachVertex && t.eachEdge) {
          t.eachVertex(function (t, o) {
            e(o);
          });
          t.eachEdge(function (t, o) {
            e(o);
          });
        } else if (t.each) {
          t.each(function (t, o) {
            e(o.vertex || o);
          });
        } else if (t.length != null) {
          for (var a = 0; a < t.length; a++) {
            e(t[a]);
          }
        } else {
          l = o(t, i);
          r.push.apply(r, l[0]);
          u.push.apply(u, l[1]);
        }
      };
      s(t);
      return [r, u];
    }
  }, {
    key: "remove",
    value: function e(t, o) {
      return this._makeSenseOf(t, this._removeOne.bind(this), o);
    }
  }, {
    key: "append",
    value: function e(t, o) {
      return this._makeSenseOf(t, this._addOne.bind(this), o);
    }
  }, {
    key: "toggle",
    value: function e(t, o) {
      return this._makeSenseOf(t, this._toggle.bind(this), o);
    }
  }, {
    key: "setMaxNodes",
    value: function e(t) {
      this.maxNodes = t;
    }
  }, {
    key: "setMaxGroups",
    value: function e(t) {
      this.maxGroups = t;
    }
  }, {
    key: "setMaxEdges",
    value: function e(t) {
      this.maxEdges = t;
    }
  }, {
    key: "setCapacityPolicy",
    value: function e(t) {
      this.capacityPolicy = t;
    }
  }, {
    key: "_clearEdges",
    value: function e() {
      for (var t = this._edges.length, o = 0; o < t; o++) {
        this._removeOne(this._edges[0]);
      }
    }
  }, {
    key: "_clearNodes",
    value: function e() {
      for (var t = this._nodes.length, o = 0; o < t; o++) {
        this._removeOne(this._nodes[0]);
      }
    }
  }, {
    key: "_clearGroups",
    value: function e() {
      for (var t = this._groups.length, o = 0; o < t; o++) {
        this._removeOne(this._groups[0]);
      }
    }
  }, {
    key: "_clearVertices",
    value: function e() {
      this._clearNodes();
      this._clearGroups();
    }
  }, {
    key: "_clearAll",
    value: function e() {
      this._clearVertices();
      this._clearEdges();
    }
  }, {
    key: "clear",
    value: function e(t) {
      this._nodes.length = 0;
      this._edges.length = 0;
      this._groups.length = 0;
      this._$_objMap = {};
      this.fire(_);
      if (!t) {
        this.onClear(this);
      }
    }
  }, {
    key: "_filterEdgeList",
    value: function e(t) {
      var o = [];
      for (var i = 0; i < t.length; i++) {
        if (this._$_objMap[t[i].getId()] != null) o.push(t[i]);
      }
      return o;
    }
  }, {
    key: "reload",
    value: function e() {
      if (this.generator != null) {
        this.onBeforeReload && this.onBeforeReload();
        this.clear();
        this._loading = true;
        this.fire(k);
        this.generator(this, this.toolkit);
        this.fire(v);
        this.onReload && this.onReload();
        this._loading = false;
      }
    }
  }, {
    key: "each",
    value: function e(t, o) {
      var i = o === b.objectType ? this._edges : o === j.objectType ? this._groups : this._nodes;
      for (var n = 0; n < i.length; n++) {
        try {
          t(n, i[n]);
        } catch (e) {}
      }
    }
  }, {
    key: "eachNode",
    value: function e(t) {
      this.each(t, m.objectType);
    }
  }, {
    key: "eachGroup",
    value: function e(t) {
      this.each(t, j.objectType);
    }
  }, {
    key: "eachVertex",
    value: function e(t) {
      this.each(t, m.objectType);
      this.each(t, j.objectType);
    }
  }, {
    key: "eachEdge",
    value: function e(t) {
      this.each(t, b.objectType);
    }
  }, {
    key: "getNodeCount",
    value: function e() {
      return this._nodes.length;
    }
  }, {
    key: "getNodeAt",
    value: function e(t) {
      return this._nodes[t];
    }
  }, {
    key: "getNodes",
    value: function e() {
      return this._nodes;
    }
  }, {
    key: "getNode",
    value: function e(t) {
      var o = T(this._nodes, function (e) {
        return e.id === t;
      });
      return o === -1 ? null : this._nodes[o];
    }
  }, {
    key: "getGroupAt",
    value: function e(t) {
      return this._groups[t];
    }
  }, {
    key: "getGroups",
    value: function e() {
      return this._groups;
    }
  }, {
    key: "getGroup",
    value: function e(t) {
      var o = T(this._groups, function (e) {
        return e.id === t;
      });
      return o === -1 ? null : this._groups[o];
    }
  }, {
    key: "getGroupCount",
    value: function e() {
      return this._groups.length;
    }
  }, {
    key: "getAll",
    value: function e() {
      var t = [];
      Array.prototype.push.apply(t, this._nodes);
      Array.prototype.push.apply(t, this._edges);
      Array.prototype.push.apply(t, this._groups);
      return t;
    }
  }, {
    key: "getAllEdgesFor",
    value: function e(t, o) {
      return this._filterEdgeList(t.getAllEdges({
        filter: o
      }));
    }
  }, {
    key: "getSourceEdgesFor",
    value: function e(t) {
      return this._filterEdgeList(t.getSourceEdges());
    }
  }, {
    key: "getEdgeCount",
    value: function e() {
      return this._edges.length;
    }
  }, {
    key: "getEdgeAt",
    value: function e(t) {
      return this._edges[t];
    }
  }, {
    key: "getEdges",
    value: function e() {
      return this._edges;
    }
  }, {
    key: "shouldFireEvent",
    value: function e(t, o, i) {
      return true;
    }
  }, {
    key: "getGroupType",
    value: function e(t) {
      return this.toolkit.getGroupType(t);
    }
  }, {
    key: "getNodeType",
    value: function e(t) {
      return this.toolkit.getNodeType(t);
    }
  }, {
    key: "getModel",
    value: function e() {
      return this.toolkit.getModel();
    }
  }, {
    key: "getGraph",
    value: function e() {
      return this.toolkit.getGraph();
    }
  }, {
    key: "setSuspendGraph",
    value: function e(t) {
      this.toolkit.setSuspendGraph(t);
    }
  }, {
    key: "getNodeId",
    value: function e(t) {
      return this.toolkit.getNodeId(t);
    }
  }, {
    key: "getGroupId",
    value: function e(t) {
      return this.toolkit.getGroupId(t);
    }
  }, {
    key: "getPortType",
    value: function e(t) {
      return this.toolkit.getPortType(t);
    }
  }, {
    key: "addPort",
    value: function e(t, o, i) {
      return this.toolkit.addPort(t, o, i);
    }
  }, {
    key: "getPortId",
    value: function e(t) {
      return this.toolkit.getPortId(t);
    }
  }, {
    key: "getEdge",
    value: function e(t) {
      return this.toolkit.getEdge(t);
    }
  }, {
    key: "addEdge",
    value: function e(t, o, i) {
      return this.toolkit.addEdge(t, o, i);
    }
  }, {
    key: "edgeMoved",
    value: function e(t, o, i) {
      this.toolkit.edgeMoved(t, o, i);
    }
  }, {
    key: "removeEdge",
    value: function e(t) {
      this.toolkit.removeEdge(t);
    }
  }, {
    key: "setEdgeGeometry",
    value: function e(t, o, i) {
      this.toolkit.setEdgeGeometry(t, o, i);
    }
  }, {
    key: "getEdgeType",
    value: function e(t) {
      return this.toolkit.getEdgeType(t);
    }
  }, {
    key: "addToGroup",
    value: function e(t, o, i, n) {
      return this.toolkit.addToGroup(t, o, n);
    }
  }, {
    key: "removeFromGroup",
    value: function e(t, o, i, n) {
      return this.toolkit.removeFromGroup(t, o, i, n);
    }
  }, {
    key: "getVertex",
    value: function e(t) {
      return this.getNode(t) || this.getGroup(t);
    }
  }, {
    key: "containsVertex",
    value: function e(t) {
      return this.getVertex(t) != null;
    }
  }, {
    key: "getObjectInfo",
    value: function e(t) {
      return this.toolkit.getObjectInfo(t);
    }
  }, {
    key: "beforeConnect",
    value: function e(t, o, i) {
      return this.toolkit.beforeConnect(t, o, i);
    }
  }, {
    key: "beforeMoveConnection",
    value: function e(t, o, i) {
      return this.toolkit.beforeMoveConnection(t, o, i);
    }
  }, {
    key: "beforeStartConnect",
    value: function e(t, o) {
      return this.toolkit.beforeStartConnect(t, o);
    }
  }, {
    key: "beforeDetach",
    value: function e(t, o, i) {
      return this.toolkit.beforeDetach(t, o, i);
    }
  }, {
    key: "beforeStartDetach",
    value: function e(t, o) {
      return this.toolkit.beforeStartDetach(t, o);
    }
  }, {
    key: "debugEnabled",
    get: function e() {
      return this.toolkit.debugEnabled;
    }
  }, {
    key: "batch",
    value: function e(t) {
      this.toolkit.batch(t);
    }
  }, {
    key: "_createSelection",
    value: function e(t) {
      return new C(this.toolkit, {
        onClear: t || function () {}
      });
    }
  }, {
    key: "filter",
    value: function e(t, o) {
      var i = typeof t === "function" ? t : function (e) {
          var i = e.data,
            n = false;
          for (var r in t) {
            var u = t[r] === i[r];
            if (!u && !o) return false;
            n = n || u;
          }
          return n;
        },
        n = this._createSelection();
      this.eachNode(function (e, t) {
        if (i(t)) n.append(t);
        var o = t.getPorts();
        for (var r = 0; r < o.length; r++) {
          if (i(o[r])) n.append(o[r]);
        }
      });
      this.eachEdge(function (e, t) {
        if (i(t)) n.append(t);
      });
      this.eachGroup(function (e, t) {
        if (i(t)) n.append(t);
      });
      return n;
    }
  }]);
  return C;
}(x);
i(N, "DISCARD_EXISTING", "discardExisting");
i(N, "DISCARD_NEW", "discardNew");
export { N as Selection, F as SelectionModes };