import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { FileService, UserService } from '@core/services';

@Directive({
  selector: '[narisDoclink]',
  standalone: true
})
export class DoclinkDirective {

  constructor(
    private readonly fileService: FileService, 
    private readonly renderer: Renderer2, 
    private readonly hostElement: ElementRef, 
    private readonly userService: UserService
  ) {
    renderer.addClass(hostElement.nativeElement, this.userService.userData.sessiondata.ArchiveMode ? 'archive-docs' : 'non-archive-docs');
  }

  @HostListener('click', ['$event'])
  public onClick(evt: MouseEvent) {
    if(this.userService.userData.sessiondata.ArchiveMode) return;
    const { dataset } = evt.target as HTMLElement;
    if (!dataset || !('doclinkHash' in dataset || 'doclinkRevision' in dataset)) return;
    evt.preventDefault();
    evt.stopPropagation();
    const { doclinkRevision, doclinkHash } = dataset;
    this.fileService.openDoclink(doclinkHash!, doclinkRevision!);
  }
}
