import { createClass as i, classCallCheck as t, defineProperty as n } from "../_virtual/_rollupPluginBabelHelpers.js";
import { extend as e } from "../ui-core/util/util.js";
import { gradient as r, quadrant as s, snapToGrid as o, lineLength as u, add as a, intersects as h, lineRectangleIntersection as l, subtract as f } from "../ui-core/util/geom.js";
function c(i, t, n, e) {
  return i[t] <= e && e <= i[t] + i[n];
}
var y = [function (i, t) {
    return i.x + i.w - t.x;
  }, function (i, t) {
    return i.x - (t.x + t.w);
  }],
  d = [function (i, t) {
    return i.y + i.h - t.y;
  }, function (i, t) {
    return i.y - (t.y + t.h);
  }],
  v = [null, [y[0], d[1]], [y[0], d[0]], [y[1], d[0]], [y[1], d[1]]],
  p = function i(t, n, e, r, s) {
    if (isNaN(e)) e = 0;
    var o = n.y + n.h,
      u = e == Infinity || e == -Infinity ? n.x + n.w / 2 : (o - r) / e,
      a = Math.atan(e),
      h,
      l,
      f;
    if (c(n, "x", "w", u)) {
      h = v[s][1](t, n);
      l = h / Math.sin(a);
      f = l * Math.cos(a);
      return {
        x: f,
        y: h
      };
    } else {
      f = v[s][0](t, n);
      l = f / Math.cos(a);
      h = l * Math.sin(a);
      return {
        x: f,
        y: h
      };
    }
  };
function g(i, t) {
  var n = i.center || {
      x: i.x + i.w / 2,
      y: i.y + i.h / 2
    },
    e = t.center || {
      x: t.x + t.w / 2,
      y: t.y + t.h / 2
    },
    o = r(n, e),
    u = s(n, e),
    a = o == Infinity || o == -Infinity || isNaN(o) ? 0 : n.y - o * n.x;
  return p(i, t, o, a, u);
}
function m(i, t, n) {
  var e = t.w + 2 * n.x;
  var r = t.h + 2 * n.y;
  return {
    x: i.x - e / 2,
    y: i.y - r / 2,
    w: e,
    h: r
  };
}
function x(i) {
  var t = [];
  if (i == null) {
    return null;
  } else if (Array.isArray(i)) {
    var n = [];
    n.push.apply(n, i);
    return n;
  } else {
    for (var e in i) {
      t.push(i[e]);
    }
  }
  return t;
}
var b = {
  x: 20,
  y: 20
};
var A = function () {
  function r(i) {
    t(this, r);
    n(this, "origin", void 0);
    n(this, "elements", void 0);
    n(this, "debug", void 0);
    n(this, "container", void 0);
    n(this, "getContainerPosition", void 0);
    n(this, "originDebugMarker", void 0);
    n(this, "constrain", void 0);
    n(this, "padding", void 0);
    n(this, "filter", void 0);
    n(this, "exclude", void 0);
    n(this, "currentFocus", void 0);
    n(this, "focusElement", null);
    n(this, "getSize", void 0);
    n(this, "getId", void 0);
    n(this, "getPosition", void 0);
    n(this, "setPosition", void 0);
    n(this, "positionArray", []);
    n(this, "positions", new Map());
    n(this, "originalPositions", new Map());
    n(this, "sizes", new Map());
    this.getPosition = i.getPosition;
    this.setPosition = i.setPosition;
    this.getId = i.getId;
    this.getSize = i.getSize;
    this.elements = x(i.elements || []);
    this.debug = i.debug === true;
    this.padding = i.padding || b;
    this.filter = i.filter || function (i) {
      return true;
    };
    this.exclude = i.exclude || function (i) {
      return false;
    };
    this.container = i.container;
    this.getContainerPosition = i.getContainerPosition;
    this.constrain = i.constrain || function (i, t, n) {
      return n;
    };
  }
  i(r, [{
    key: "executeAtPoint",
    value: function i(t, n) {
      if (this.elements.length > 0) {
        this._updatePositions();
        this.setOrigin(t);
        this.focusElement = null;
        return this._run(n);
      } else {
        return {};
      }
    }
  }, {
    key: "executeAtCenter",
    value: function i(t) {
      if (this.elements.length > 0) {
        var n = this._updatePositions();
        this.setOrigin({
          x: (n[0] + n[1]) / 2,
          y: (n[2] + n[3]) / 2
        });
        this.focusElement = null;
        return this._run(t);
      } else {
        return {};
      }
    }
  }, {
    key: "executeWithFocus",
    value: function i(t, n) {
      if (this.elements.length > 0) {
        this._updatePositions();
        this.focusElement = {
          id: t,
          bounds: this.positions.get(t)
        };
        this.setOrigin(this.focusElement.bounds.center);
        return this._run(n);
      } else {
        return {};
      }
    }
  }, {
    key: "snapToGrid",
    value: function i(t) {
      var n = {};
      this._updatePositions();
      for (var e = 0; e < this.positionArray.length; e++) {
        var r = this.positions.get(this.positionArray[e].id);
        var s = this._snapPositionToGrid(this.positionArray[e].bounds, t),
          o = s.moved,
          u = s.pos;
        if (o) {
          n[this.positionArray[e].id] = {
            original: r,
            current: u
          };
        }
      }
      this._positionElements(n);
      return n;
    }
  }, {
    key: "setElements",
    value: function i(t) {
      this.elements = x(t);
      return this;
    }
  }, {
    key: "addElement",
    value: function i(t, n) {
      if (t != null && (n || this.elements.indexOf(t) === -1)) {
        this.elements.push(t);
      }
      return this;
    }
  }, {
    key: "addElements",
    value: function i(t, n) {
      if (n) {
        Array.prototype.push.apply(this.elements, t);
      } else {
        for (var e = 0; e < t.length; e++) {
          this.addElement(t[e]);
        }
      }
      return this;
    }
  }, {
    key: "removeElement",
    value: function i(t) {
      var n = -1;
      for (var e = 0; e < this.elements.length; e++) {
        if (this.elements[e] === t) {
          n = e;
          break;
        }
      }
      if (n !== -1) this.elements.splice(n, 1);
      return this;
    }
  }, {
    key: "reset",
    value: function i() {
      this.elements.length = 0;
    }
  }, {
    key: "setOrigin",
    value: function i(t) {
      if (t != null) {
        this.origin = t;
      }
    }
  }, {
    key: "_updatePositions",
    value: function i() {
      this.positionArray.length = 0;
      this.positions.clear();
      this.originalPositions.clear();
      this.sizes.clear();
      return this._computeExtents(this.elements);
    }
  }, {
    key: "_computeExtents",
    value: function i(t) {
      var n, r, s, o;
      n = r = Infinity;
      s = o = -Infinity;
      for (var u = 0; u < t.length; u++) {
        var a = this.getPosition(t[u]),
          h = this.getSize(t[u]),
          l = this.getId(t[u]);
        var f = {
          x: a.x,
          y: a.y,
          w: h.w,
          h: h.h,
          center: {
            x: a.x + h.w / 2,
            y: a.y + h.h / 2
          }
        };
        this.positions.set(l, f);
        this.originalPositions.set(l, e({}, f));
        this.positionArray.push({
          bounds: f,
          id: l,
          element: t[u]
        });
        this.sizes.set(l, h);
        n = Math.min(n, a.x);
        r = Math.min(r, a.y);
        s = Math.max(s, a.x + h.w);
        o = Math.max(o, a.y + h.h);
      }
      return [n, s, r, o];
    }
  }, {
    key: "_run",
    value: function i(t) {
      var n = {};
      if (this.elements.length > 1) {
        t = t || {};
        var r = t.filter || this.filter;
        var s = t.padding || b;
        var o = t.iterations;
        var u = t.exclude || this.exclude;
        if (t.gather) {
          e(n, this._gather(s, this.constrain, r, u));
        }
        e(n, this._magnetize(s, this.constrain, r, o, u, t.grid));
      }
      var a = {};
      for (var h in n) {
        a[h] = {
          original: this.originalPositions.get(h),
          current: n[h]
        };
      }
      this._positionElements(a);
      return a;
    }
  }, {
    key: "_snapPositionToGrid",
    value: function i(t, n) {
      var e = {
        x: t.x,
        y: t.y
      };
      if (n != null) {
        var r = o(t, n);
        t.x = r.x;
        t.y = r.y;
        t.center.x = t.x + t.w / 2;
        t.center.y = t.y + t.h / 2;
      }
      return {
        moved: e.x !== t.x || e.y !== t.y,
        pos: t
      };
    }
  }, {
    key: "_gather",
    value: function i(t, n, e, r) {
      var s = this;
      var o = {};
      var h = m(this.origin, {
        w: 0,
        h: 0
      }, t);
      var f = {};
      this.positionArray.sort(function (i, t) {
        var n = f[i.id] || Math.min(u(i.bounds, s.origin), u(a(i.bounds, {
          x: i.bounds.w,
          y: 0
        }), s.origin), u(a(i.bounds, {
          x: i.bounds.w,
          y: i.bounds.h
        }), s.origin), u(a(i.bounds, {
          x: 0,
          y: i.bounds.h
        }), s.origin));
        var e = f[t.id] || Math.min(u(t.bounds, s.origin), u(a(t.bounds, {
          x: t.bounds.w,
          y: 0
        }), s.origin), u(a(t.bounds, {
          x: t.bounds.w,
          y: t.bounds.h
        }), s.origin), u(a(t.bounds, {
          x: 0,
          y: t.bounds.h
        }), s.origin));
        f[i.id] = n;
        f[t.id] = e;
        return n === e ? 0 : n < e ? -1 : 1;
      });
      var c = {};
      var y = function i(t) {
        var n = [s.origin, t];
        var e = [];
        Array.prototype.push.apply(e, l(n, h).map(function (i) {
          return [h, i, null];
        }));
        var r = function i(t) {
          var r = s.positions.get(t);
          Array.prototype.push.apply(e, l(n, r).map(function (i) {
            return [r, i, t];
          }));
        };
        for (var o in c) {
          r(o);
        }
        return e.filter(function (i) {
          return i != null;
        });
      };
      var d = function i(n) {
        if (r(s.positionArray[n].id, s.positionArray[n].element)) {
          return "continue";
        }
        var a = s.positionArray[n].bounds,
          h = s.positionArray[n].bounds,
          l = m(a.center, h, t);
        if (e(s.positionArray[n].id, s.positionArray[n].element)) {
          var f = y(a.center);
          if (f.length > 0) {
            f.sort(function (i, t) {
              var n = u(i[1], a),
                e = u(t[1], a);
              return n < e ? -1 : 1;
            });
            var d = f[0][0];
            var v = g(d, l);
            a.x += v.x;
            a.y += v.y;
            a.center.x += v.x;
            a.center.y += v.y;
            o[s.positionArray[n].id] = a;
            c[s.positionArray[n].id] = true;
          }
        }
      };
      for (var v = 0; v < this.positionArray.length; v++) {
        var p = d(v);
        if (p === "continue") continue;
      }
      return o;
    }
  }, {
    key: "_magnetize",
    value: function i(t, n, e, r, s, o) {
      var u = this;
      r = r || 2;
      var l, c;
      if (this.focusElement != null) {
        l = m(this.origin, this.focusElement.bounds, {
          x: 0,
          y: 0
        });
        c = this.focusElement.id;
      } else {
        l = m(this.origin, {
          w: 0,
          h: 0
        }, t);
      }
      var y = 1,
        d = true,
        v,
        p,
        x = {},
        b = function i(t, n, e, r) {
          n.x += e;
          n.y += r;
          n.center.x += e;
          n.center.y += r;
          x[t] = n;
        },
        A = function i(t, n) {
          if (o == null) {
            return n;
          } else {
            var e = a(t, n),
              r = e.x / o.w,
              s = e.y / o.h,
              u = n.x >= 0 ? Math.ceil(r) : Math.floor(r),
              h = n.y >= 0 ? Math.ceil(s) : Math.floor(s),
              l = {
                x: o.w * u,
                y: o.h * h
              };
            return f(l, t);
          }
        },
        P = function i() {
          var o, a;
          for (var f = 0; f < u.positionArray.length; f++) {
            o = u.positionArray[f];
            if (o.id === c || s(o.id, o.element)) {
              continue;
            }
            var x = o.bounds,
              P = m(x.center, x, t);
            if (e(o.id, o.element) && h(l, P)) {
              v = g(l, P);
              v = A(x, v);
              p = n(o.id, x, v);
              b(o.id, x, p.x, p.y);
              P = m(x.center, x, t);
            }
            for (var w = 0; w < u.positionArray.length; w++) {
              if (f !== w) {
                a = u.positionArray[w];
                if (a.id === c || s(a.id, a.element)) {
                  continue;
                }
                if (e(a.id, a.element)) {
                  var _ = a.bounds,
                    k = m(_.center, _, t);
                  if (h(P, k)) {
                    d = true;
                    v = g(P, k);
                    v = A(_, v);
                    p = n(a.id, _, v);
                    b(a.id, _, p.x, p.y);
                  }
                }
              }
            }
          }
          if (d && y < r) {
            d = false;
            y++;
            i();
          }
        };
      P();
      return x;
    }
  }, {
    key: "_positionElements",
    value: function i(t) {
      for (var n = 0; n < this.elements.length; n++) {
        var e = this.getId(this.elements[n]);
        if (t[e]) {
          this.setPosition(this.elements[n], t[e].current);
        }
      }
    }
  }]);
  return r;
}();
export { A as Magnetizer, g as calculateSpacingAdjustment };