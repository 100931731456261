@defer (when !loading) {
  @if (!!frequency) {
    @if (showHeader) {
      <naris-toolbar>
        <naris-toolbar-item>
          <h3>{{title}}</h3>
        </naris-toolbar-item>
      </naris-toolbar>
    }
    <div class="naris-frequency" [class.no-top-pad]="showHeader" [class.no-padding]="isFormInput">
      @if (!isFormInput) {
        <naris-icon icon="waiting" class="naris-frequency-icon mr-8" />
      }
      <div>
        <div class="naris-frequency-label" [innerHTML]="frequencyString"></div>
        @if (!!deadlineString) {
          <div
            class="naris-frequency-label naris-frequency-label--muted"
            [innerHTML]="deadlineString"
          ></div>
        }
      </div>
      @if (hasUpdateAction) {
        <div class="naris-frequency__update-button">
          <naris-button color="primary" size="small" (click)="toggleEdit()" [id]="id + '__FrequencyUpdate'">update</naris-button>
        </div>
      }
    </div>
  } @else {
    <div class="no-frequency">
      <h3>{{title}}</h3>
    </div>
  }
  @if (hasCreateAction || (hasUpdateAction && formExpanded)) {
    <mat-expansion-panel 
      class="dynamic-table__expansion__panel"
      [ngClass]="{'dynamic-table__expansion__panel--expanded': expansionPanel.expanded}"
      [expanded]="formExpanded || hasCreateAction"
      hideToggle
      #expansionPanel
    >
      <mat-expansion-panel-header [ngClass]="{'dynamic-table__expansion__header--expanded': expansionPanel.expanded}">
        <mat-panel-title class="dynamic-table__expansion__header">
          <naris-button
            icon="add"
            color="default"
            size="small"
            [matTooltip]="'form.add_another' | translate"
            [class.expanded]="expansionPanel.expanded"
            (click)="hasUpdateAction && formExpanded && toggleEdit()"
            [id]="id + '__FrequencyAddAnother'"
          />
        </mat-panel-title>
      </mat-expansion-panel-header>
      <naris-card>
        <app-form
          [endpoint]="frequencyAction?.href || ''"
          [isEmbedded]="true"
          [isDynamic]="true"
          [isEmbeddedInTable]="true"
          [tabHref]="tabHref"
          (dynamicSaved)="dynamicSaved($event, expansionPanel)"
        />
      </naris-card>
    </mat-expansion-panel>
  }

} @placeholder {
  <naris-loader />
}
