import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'naris-input-condition',
  templateUrl: './input-condition.component.html',
  styleUrls: ['./input-condition.component.scss'],
  standalone: true
})
export class InputConditionComponent {
  @HostBinding('class.valid')
  @Input()
  public valid = false;
}
