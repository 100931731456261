<div class="naris-autocomplete-single" [attr.data-test-id]="'autocomplete-single__' + id">
  <div 
    class="naris-autocomplete-single__input"
    [ngClass]="{
      valid: (control.valid && !control.disabled && (control.value !== null || autocompleteInput.touched)),
      invalid: (!control.valid && !control.disabled && (control.dirty || autocompleteInput.touched)),
      focused: focused
    }"
  >
    <input
      class="naris-autocomplete-single__input__control"
      [type]="input.inputType || 'text'"
      [placeholder]="input.placeholder || ''"
      [formControl]="autocompleteInput"
      [matAutocomplete]="autocomplete"
      (focus)="focused = true"
      (blur)="focused = false"
    />
    @if (loading) {
      <div 
        class="naris-autocomplete-single__input__loader" 
        [ngClass]="{move: !!selection.selected.length}"
      >
        <naris-icon 
          iconClass="small" 
          icon="spinner" 
          [inline]="true" 
        />
      </div>
    }
    @if (!control.disabled && !!selection.selected.length) {
      <naris-button
        icon="close"
        class="naris-autocomplete-single__input__clear"
        color="white"
        (clicked)="onClearSelection()"
        [matTooltip]="'input.clear_selection' | translate"
        matTooltipPosition="above"
        [id]="'autocomplete__' + id + '__AutoCompleteClearSelection'"
      />
    }
  </div>
  @if (!!input.lookup?.list && !control.disabled) {
  <div class="naris-autocomplete-single__button">
      <naris-button
        class="lookup-button"
        icon="search"
        type="button"
        [matTooltip]="'input.advanced' | translate"
        matTooltipPosition="above"
        [id]="'autocomplete__' + id + '__AutoCompleteAdvanced'"
        (clicked)="openAdvancedLookup()"
      />
    </div>
  }
  <div class="naris-autocomplete-single__select">
    <mat-autocomplete
      class="autocomplete"
      [autoActiveFirstOption]="true"
      #autocomplete="matAutocomplete"
      [displayWith]="autocompleteLabel"
      (optionSelected)="onOptionSelected($event)"
    >
      @for (option of filteredOptions | async; track option._id) {
        <mat-option [value]="option">
          <naris-option
            [label]="option.label"
            [icon]="option.icon"
            [helperText]="option.helperText"
            [meta]="option"
          />
        </mat-option>
      }
    </mat-autocomplete>
  </div>
</div>