import { TOP as t, LEFT as o, RIGHT as r, BOTTOM as e } from "../factory/definitions.js";
import { EMPTY_BOUNDS as s } from "../../util/util.js";
import { isContinuous as n } from "../router/lightweight-router.js";
function a() {
  return {
    sx: 0,
    sy: 0,
    tx: 0,
    ty: 0,
    xSpan: 0,
    ySpan: 0,
    mx: 0,
    my: 0,
    so: [0, 0],
    to: [0, 0],
    x: 0,
    y: 0,
    w: 0,
    h: 0,
    segment: 0,
    startStubX: 0,
    startStubY: 0,
    endStubX: 0,
    endStubY: 0,
    isXGreaterThanStubTimes2: false,
    isYGreaterThanStubTimes2: false,
    opposite: false,
    perpendicular: false,
    orthogonal: false,
    sourceAxis: "x",
    points: [0, 0, 0, 0, 0, 0, 0, 0],
    stubs: [0, 0]
  };
}
function i(t, o, r) {
  return {
    x: t.x,
    y: t.y,
    ox: t.ox,
    oy: t.oy,
    curX: t.curX + o,
    curY: t.curY + r
  };
}
function c(t, o, r, e) {
  var n = r.gap || 0;
  var i = r.stub || e,
    c = Array.isArray(i) ? i[0] : i,
    u = Array.isArray(i) ? i[1] : i,
    l = Math.max(c, u);
  var y = {
    connection: o,
    type: t,
    segments: [],
    segmentProportionalLengths: [],
    segmentProportions: [],
    totalLength: 0,
    cssClass: r.cssClass || "",
    hoverClass: r.hoverClass || "",
    gap: n,
    stub: i,
    sourceStub: c,
    targetStub: u,
    maxStub: l,
    sourceGap: Array.isArray(n) ? n[0] : n,
    targetGap: Array.isArray(n) ? n[1] : n,
    typeDescriptor: "connector",
    geometry: null,
    idPrefix: "_jsplumb_connector",
    bounds: s(),
    x: 0,
    y: 0,
    w: 0,
    h: 0,
    edited: false,
    strokeWidth: 1,
    typeId: null,
    segment: 0,
    paintInfo: a()
  };
  return y;
}
function u(s, a, i) {
  var c = a.endpoints[i]._anchor;
  if (n(c)) {
    var u = t;
    if (s.x === 0) {
      u = o;
    } else if (s.x === 1) {
      u = r;
    } else if (s.y === 1) {
      u = e;
    }
    c.currentFace = u;
  } else if (c.isDynamic) {
    a.instance.router.selectAnchorLocation(c, {
      x: s.x,
      y: s.y
    });
  } else {
    c.locations[0].x = s.x;
    c.locations[0].y = s.y;
    c.locations[0].ox = s.ox;
    c.locations[0].oy = s.oy;
    c.locations[0].iox = s.ox;
    c.locations[0].ioy = s.oy;
  }
  a.instance.router.lock(c);
}
export { c as _createConnectorBase, u as _setAnchorLocation, a as emptyPaintGeometry, i as transformAnchorPlacement };