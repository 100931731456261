import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { INarisDynamicDashboard } from '@core/models/dynamic-dashboard.model';
import { BASE_CHART_COLORS, LEGEND_COLORS } from '@core/constants';
import { HttpService } from './http.service';
import { UserService } from './user.service';
import { SnackbarService } from './snackbar.service';
import type { IAction, IDashboard, IFormResponse, ILayoutTab } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  public editDashChanged = new Subject<boolean>();
  public dashChanged = new Subject<IDashboard>();
  public activeDashIdChanged = new Subject<number>();
  public dashViewChanged = new Subject<number>();

  public dashboardSaved$ = new Subject<void>();
  public dashboardAddWidget$ = new Subject<void>();
  public dashboardSaveStateChanged$ = new Subject<boolean>();
  public dashboardSetDefault$ = new Subject<void>();
  public dashboardWidgetResized$ = new Subject<string>();

  public dashboardConfigChanged = new Subject<ILayoutTab[]>();

  private dashboardConfig: ILayoutTab[] | undefined;
  private allowedDashboards: ILayoutTab[] = [];
  private allowedTabs: ILayoutTab[] = [];
  public updateUrl: string | undefined;
  public readUrl: string | undefined;
  public readonly dashboardJsonReadEndpoint = '/dashboard/individual-settings/read';
  public readonly dashboardJsonUpdateEndpoint = '/dashboard/individual-settings/update';

  constructor(
    private readonly http: HttpService,
    private readonly router: Router,
    private readonly userService: UserService,
    // private readonly beinformedService: BeinformedService,
    private readonly snackbar: SnackbarService
  ) {}

  public initialize(navigation: any): void {
    if (this.userService.userData._id === 1) return;
    if (!!navigation)
      this.setAllowedDashboards(navigation);
    this.getDashboardConfig();
  }

  private getDashboardConfig(): void {
    this.http.post(this.dashboardJsonReadEndpoint, {}).subscribe({
      next: (result: IFormResponse) => {
        const config = result.formresponse?.success?.data['Result']['JSON'];
        const dashboards = JSON.parse(config) as ILayoutTab[];
        const allowedHrefs = this.allowedDashboards.map(dashboard => dashboard.href);
        this.dashboardConfig = dashboards.filter(dashboard => allowedHrefs.includes(dashboard.href))
          .map(dashboard => {
            const title = this.allowedDashboards.find(allowedDashboard => allowedDashboard.name === dashboard.name)?.title || dashboard.title;
            return {...dashboard, active: this.router.url === dashboard.href, title};
          });
        this.dashboardConfigChanged.next(this.dashboardConfig);
      }
    });
  }

  public setUrls(actions: IAction[] | undefined) {
    actions?.forEach(action => {
      switch (action.name!.toLowerCase()) {
        case 'read': this.readUrl = action.href;
        case 'update': this.updateUrl = action.href;
      }
    });
  }

  public getDashboard(): Observable<INarisDynamicDashboard[]> {
    return new Observable(obs => {
      if (!!this.readUrl) {
        this.http.post(this.readUrl, {}).subscribe({
          next: (res: IFormResponse) => {
            const json = res.formresponse?.success?.data['Result']['JSON'];
            const parsedJson = JSON.parse(json);
            obs.next(parsedJson);
            obs.complete();
          },
          error: e => {
            this.snackbar.open({ text: e, type: 'error' });
            obs.next([]);
            obs.complete();
          }
        });
      }
    });
  }

  public getLegendColors(values: any[]): string[] {
    const colors: string[] = [];
    const options = values.map(x => x.stateColor);
    if (options.length > 0 && !!options[0]) options.forEach(value => colors.push(LEGEND_COLORS[value]));
    else values.forEach((value, index) => colors.push(BASE_CHART_COLORS[index]));
    return colors;
  }

  public getDatasetLegendColors(datasets: any[]) {
    return datasets.map((dataset: any, index: number) => {
      const colors: string[] = [];
      const options = dataset.data.map((x: any) => x.stateColor);
      options.forEach((value: any) => {
        if (!value) colors.push(BASE_CHART_COLORS[index]);
        else {
          if (value.startsWith('#')) colors.push(value);
          else colors.push(LEGEND_COLORS[value]);
        }
      });
      return {...dataset, backgroundColor: colors};
    });
  }

  public getLineDatasetLegendColors(datasets: any[]) {
    return datasets.map((dataset: any, index: number) => ({
      ...dataset, 
      borderColor: BASE_CHART_COLORS[index], 
      backgroundColor: BASE_CHART_COLORS[index],
      pointBackgroundColor: BASE_CHART_COLORS[index],
      pointBorderColor: BASE_CHART_COLORS[index]
    }));
  }

  public saveDashboardConfig(tabs: ILayoutTab[]): void {
    const filteredTabs = tabs.map(tab => {
      const {active, title, ...rest} = tab;
      return rest;
    });
    const json = JSON.stringify(filteredTabs);
    this.http.post(this.dashboardJsonUpdateEndpoint, {JSON: json}).subscribe({
      next: (result: IFormResponse) => {
        const redirect = result.formresponse?.success?.redirect;
        if (!!redirect) {
          this.dashboardConfig = tabs.map(tab => ({...tab, active: tab.href === redirect}));
          this.dashboardConfigChanged.next(this.dashboardConfig);
          void this.router.navigate([redirect]);
        }
      }
    });
  }

  private setAllowedDashboards(navigation: any) {
    const dashboardTabs = navigation['modules'].find((tab: any) => tab.href === '/dashboard');
    const dashboards: any[] = dashboardTabs.routes;
    this.allowedDashboards = dashboards.map(dashboard => ({title: dashboard.label, active: false, href: dashboard.href, name: dashboard.name} as ILayoutTab));
    this.allowedTabs = this.allowedDashboards.filter(tab => tab.name !== 'MyWork');
  }

  public getAllowedDashboards() {
    return this.allowedDashboards;
  }

  public getAllowedTabs() {
    return this.allowedTabs;
  }

  public clearConfig() {
    this.dashboardConfig = undefined;
  }

}
