<mat-expansion-panel [expanded]="expanded" (opened)="onOpened()" (closed)="expanded = false">
  <mat-expansion-panel-header [ngClass]="{expanded, first}">
    <mat-panel-title>
      <span class="header-item version">v{{ version }}</span>
    </mat-panel-title>
    <mat-panel-description>
      <span class="header-item date">{{ date | date }}</span>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <div class="content" [innerHTML]="content | safeHtml:['class', 'data-doclink-revision', 'data-doclink-hash']" narisDoclink></div>           
  </ng-template>
  <mat-action-row>
    <a class="naris-link" (click)="openHelp()" tabindex="0">{{ 'changelogs.full' | translate }}</a>
  </mat-action-row>
</mat-expansion-panel>
