import { inherits as t, createSuper as e, classCallCheck as i, defineProperty as s, assertThisInitialized as r, createClass as o } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { addClass as a, matchesSelector as n, offsetRelativeToRoot as l, consume as h, removeClass as c } from "./browser-util.js";
import { pageLocation as u, EventManager as _ } from "./event-manager.js";
import { EVENT_MOUSEDOWN as g, EVENT_MOUSEMOVE as d, EVENT_MOUSEUP as v, EVENT_REVERT as f } from "./event-constants.js";
import { log as m, isString as p, extend as y, uuid as P } from "../util/util.js";
import { snapToGrid as x } from "../util/geom.js";
function S(t, e, i) {
  if (n(e, i, t)) {
    return e;
  } else {
    var s = e.parentNode;
    while (s != null && s !== t) {
      if (n(s, i, t)) {
        return s;
      } else {
        s = s.parentNode;
      }
    }
  }
}
function D(t) {
  if (typeof t === "function") {
    t._katavorioId = P();
    return t._katavorioId;
  } else {
    return t;
  }
}
function E(t, e, i) {
  var s = null;
  var r = e.getAttribute("katavorio-draggable"),
    o = r != null ? "[katavorio-draggable='" + r + "'] " : "";
  for (var a = 0; a < t.length; a++) {
    if (t[a].selector === ".") {
      s = e;
    } else {
      s = S(e, i, o + t[a].selector);
    }
    if (s != null) {
      if (t[a].filter) {
        var l = n(i, t[a].filter, s),
          h = t[a].filterExclude === true;
        if (h && !l || l) {
          return null;
        }
      }
      return [t[a], s];
    }
  }
  return null;
}
var k = "start";
var w = "beforeStart";
var A = "drag";
var b = "drop";
var T = "over";
var z = "out";
var L = "stop";
var C = "katavorio-draggable";
var F = C;
var N = 10;
var G = 10;
var M = function t() {
  return true;
};
var Z = function t() {
  return false;
};
var B = {
  delegatedDraggable: "katavorio-delegated-draggable",
  draggable: F,
  drag: "katavorio-drag",
  selected: "katavorio-drag-selected",
  ghostProxy: "katavorio-ghost-proxy",
  clonedDrag: "katavorio-clone-drag"
};
var I = [L, k, A, b, T, z, w];
var R = function t() {};
var j = function t(e, i) {
  if (e == null) return;
  e = !p(e) && e.tagName == null && e.length != null ? e : [e];
  for (var s = 0; s < e.length; s++) {
    i.apply(e[s], [e[s]]);
  }
};
var O = function t(e, i, s) {
  var r = e.srcElement || e.target;
  return !n(r, s.getInputFilterSelector(), i);
};
var V = function () {
  function t(e, r) {
    i(this, t);
    this.el = e;
    this.manager = r;
    s(this, "_class", void 0);
    s(this, "uuid", P());
    s(this, "enabled", true);
    s(this, "scopes", []);
    s(this, "eventManager", void 0);
    this.eventManager = r.eventManager;
  }
  o(t, [{
    key: "setEnabled",
    value: function t(e) {
      this.enabled = e;
    }
  }, {
    key: "isEnabled",
    value: function t() {
      return this.enabled;
    }
  }, {
    key: "toggleEnabled",
    value: function t() {
      this.enabled = !this.enabled;
    }
  }, {
    key: "addScope",
    value: function t(e) {
      var i = {};
      j(this.scopes, function (t) {
        i[t] = true;
      });
      j(e ? e.split(/\s+/) : [], function (t) {
        i[t] = true;
      });
      this.scopes.length = 0;
      for (var s in i) {
        this.scopes.push(s);
      }
    }
  }, {
    key: "removeScope",
    value: function t(e) {
      var i = {};
      j(this.scopes, function (t) {
        i[t] = true;
      });
      j(e ? e.split(/\s+/) : [], function (t) {
        delete i[t];
      });
      this.scopes.length = 0;
      for (var s in i) {
        this.scopes.push(s);
      }
    }
  }, {
    key: "toggleScope",
    value: function t(e) {
      var i = {};
      j(this.scopes, function (t) {
        i[t] = true;
      });
      j(e ? e.split(/\s+/) : [], function (t) {
        if (i[t]) delete i[t];else i[t] = true;
      });
      this.scopes.length = 0;
      for (var s in i) {
        this.scopes.push(s);
      }
    }
  }]);
  return t;
}();
var X;
(function (t) {
  t["notNegative"] = "notNegative";
  t["parent"] = "parent";
  t["parentEnclosed"] = "parentEnclosed";
})(X || (X = {}));
function Y(t, e) {
  var i = null;
  switch (t) {
    case X.notNegative:
      {
        i = function t(e, i, s, r) {
          return {
            x: Math.max(0, Math.min(e.x)),
            y: Math.max(0, Math.min(e.y))
          };
        };
        break;
      }
    case X.parent:
      {
        var s = e || 5;
        i = function t(e, i, r, o) {
          var a = e.x < 0 ? 0 : e.x > r.w - s ? r.w - s : e.x;
          var n = e.y < 0 ? 0 : e.y > r.h - s ? r.h - s : e.y;
          return {
            x: a,
            y: n
          };
        };
        break;
      }
    case X.parentEnclosed:
      {
        i = function t(e, i, s, r) {
          var o = e.x < 0 ? 0 : e.x + r.w > s.w ? s.w - r.w : e.x;
          var a = e.y < 0 ? 0 : e.y + r.h > s.h ? s.h - r.h : e.y;
          return {
            x: o,
            y: a
          };
        };
        break;
      }
  }
  return i;
}
var U = function (_) {
  t(P, _);
  var y = e(P);
  function P(t, e, o) {
    var n;
    i(this, P);
    n = y.call(this, t, o);
    s(r(n), "_class", void 0);
    s(r(n), "rightButtonCanDrag", void 0);
    s(r(n), "consumeStartEvent", void 0);
    s(r(n), "clone", void 0);
    s(r(n), "scroll", void 0);
    s(r(n), "trackScroll", void 0);
    s(r(n), "cloneSize", void 0);
    s(r(n), "_currentZoom", void 0);
    s(r(n), "_downAt", void 0);
    s(r(n), "_posAtDown", void 0);
    s(r(n), "_pagePosAtDown", void 0);
    s(r(n), "_pageDelta", {
      x: 0,
      y: 0
    });
    s(r(n), "_canvasShift", {
      x: 0,
      y: 0
    });
    s(r(n), "_currentDeltas", {
      x: 0,
      y: 0
    });
    s(r(n), "_moving", void 0);
    s(r(n), "_lastPosition", void 0);
    s(r(n), "_lastScrollValues", {
      x: 0,
      y: 0
    });
    s(r(n), "_initialScroll", {
      x: 0,
      y: 0
    });
    s(r(n), "_size", void 0);
    s(r(n), "_currentParentPosition", void 0);
    s(r(n), "_ghostParentPosition", void 0);
    s(r(n), "_dragEl", void 0);
    s(r(n), "_multipleDrop", void 0);
    s(r(n), "_ghostProxyOffsets", void 0);
    s(r(n), "_ghostDx", void 0);
    s(r(n), "_ghostDy", void 0);
    s(r(n), "_isConstrained", false);
    s(r(n), "_ghostProxyParent", void 0);
    s(r(n), "_useGhostProxy", void 0);
    s(r(n), "_ghostProxyFunction", void 0);
    s(r(n), "_activeSelectorParams", void 0);
    s(r(n), "_availableSelectors", []);
    s(r(n), "_canDrag", void 0);
    s(r(n), "_consumeFilteredEvents", void 0);
    s(r(n), "_parent", void 0);
    s(r(n), "_ignoreZoom", void 0);
    s(r(n), "_getTargetScale", void 0);
    s(r(n), "_filters", {});
    s(r(n), "_constrainRect", void 0);
    s(r(n), "_elementToDrag", void 0);
    s(r(n), "downListener", void 0);
    s(r(n), "moveListener", void 0);
    s(r(n), "upListener", void 0);
    s(r(n), "scrollTracker", void 0);
    s(r(n), "listeners", {
      start: [],
      drag: [],
      stop: [],
      over: [],
      out: [],
      beforeStart: [],
      revert: []
    });
    n._class = n.manager.css.draggable;
    a(n.el, n._class);
    n.downListener = n._downListener.bind(r(n));
    n.upListener = n._upListener.bind(r(n));
    n.moveListener = n._moveListener.bind(r(n));
    n.rightButtonCanDrag = e.rightButtonCanDrag === true;
    n.consumeStartEvent = e.consumeStartEvent !== false;
    n._dragEl = n.el;
    n.clone = e.clone === true;
    n.cloneSize = e.cloneSize;
    n.scroll = e.scroll === true;
    n.trackScroll = e.trackScroll !== false;
    n._multipleDrop = e.multipleDrop !== false;
    n._canDrag = e.canDrag || M;
    n._consumeFilteredEvents = e.consumeFilteredEvents;
    n._parent = e.parent;
    n._ignoreZoom = e.ignoreZoom === true;
    n._ghostProxyParent = e.ghostProxyParent;
    n._getTargetScale = e.getTargetScale;
    if (n.trackScroll) {
      n.scrollTracker = n._trackScroll.bind(r(n));
      document.addEventListener("scroll", n.scrollTracker);
    }
    if (e.ghostProxy === true) {
      n._useGhostProxy = M;
    } else {
      if (e.ghostProxy && typeof e.ghostProxy === "function") {
        n._useGhostProxy = e.ghostProxy;
      } else {
        n._useGhostProxy = function (t, e) {
          if (n._activeSelectorParams && n._activeSelectorParams.useGhostProxy) {
            return n._activeSelectorParams.useGhostProxy(t, e);
          } else {
            return false;
          }
        };
      }
    }
    if (e.makeGhostProxy) {
      n._ghostProxyFunction = e.makeGhostProxy;
    } else {
      n._ghostProxyFunction = function (t) {
        if (n._activeSelectorParams && n._activeSelectorParams.makeGhostProxy) {
          return n._activeSelectorParams.makeGhostProxy(t);
        } else {
          return t.cloneNode(true);
        }
      };
    }
    if (e.selector) {
      var l = n.el.getAttribute(C);
      if (l == null) {
        l = "" + new Date().getTime();
        n.el.setAttribute("katavorio-draggable", l);
      }
      n._availableSelectors.push(e);
    }
    n.eventManager.on(n.el, g, n.downListener);
    return n;
  }
  o(P, [{
    key: "_trackScroll",
    value: function t(e) {
      if (this._moving) {
        var i = {
            x: document.documentElement.scrollLeft,
            y: document.documentElement.scrollTop
          },
          s = i.x - this._lastScrollValues.x,
          r = i.y - this._lastScrollValues.y,
          o = {
            x: s + this._lastPosition.x,
            y: r + this._lastPosition.y
          },
          a = o.x - this._downAt.x,
          n = o.y - this._downAt.y;
        if (this._dragEl && this._dragEl.parentNode) {
          a += this._dragEl.parentNode.scrollLeft - this._initialScroll.x;
          n += this._dragEl.parentNode.scrollTop - this._initialScroll.y;
        }
        a /= this._currentZoom;
        n /= this._currentZoom;
        this.moveBy(a, n, e);
        this._lastPosition = o;
        this._lastScrollValues = i;
      }
    }
  }, {
    key: "on",
    value: function t(e, i) {
      if (this.listeners[e]) {
        this.listeners[e].push(i);
      }
    }
  }, {
    key: "off",
    value: function t(e, i) {
      if (this.listeners[e]) {
        var s = [];
        for (var r = 0; r < this.listeners[e].length; r++) {
          if (this.listeners[e][r] !== i) {
            s.push(this.listeners[e][r]);
          }
        }
        this.listeners[e] = s;
      }
    }
  }, {
    key: "_upListener",
    value: function t(e) {
      if (this._downAt) {
        this._downAt = null;
        this.eventManager.off(document, d, this.moveListener);
        this.eventManager.off(document, v, this.upListener);
        this.unmark(e);
        this.stop(e);
        this._moving = false;
        if (this.clone) {
          this._dragEl && this._dragEl.parentNode && this._dragEl.parentNode.removeChild(this._dragEl);
          this._dragEl = null;
        } else {
          if (this._activeSelectorParams && this._activeSelectorParams.revertFunction) {
            if (this._activeSelectorParams.revertFunction(this._dragEl, this.manager.getPosition(this._dragEl)) === true) {
              this.manager.setPosition(this._dragEl, this._posAtDown);
              this._dispatch(f, this._dragEl);
            }
          }
        }
      }
    }
  }, {
    key: "_downListener",
    value: function t(e) {
      if (e.defaultPrevented) {
        return;
      }
      var i = this.rightButtonCanDrag || e.which !== 3 && e.button !== 2;
      if (i && this.isEnabled() && this._canDrag()) {
        var s = this._testFilter(e) && O(e, this.el, this.manager);
        if (s) {
          this._activeSelectorParams = null;
          this._elementToDrag = null;
          if (this._availableSelectors.length === 0) {
            m("JSPLUMB: no available drag selectors");
          }
          var r = e.target || e.srcElement;
          var o = E(this._availableSelectors, this.el, r);
          if (o != null) {
            this._activeSelectorParams = o[0];
            this._elementToDrag = o[1];
          }
          if (this._activeSelectorParams == null || this._elementToDrag == null) {
            return;
          }
          var n = this._activeSelectorParams.dragInit ? this._activeSelectorParams.dragInit(this._elementToDrag, e) : null;
          if (n === false) {
            return;
          }
          if (n != null) {
            this._elementToDrag = n;
          }
          var c = 1;
          if (this.clone) {
            this._elementToDrag.getBoundingClientRect();
            this._dragEl = this._elementToDrag.cloneNode(true);
            a(this._dragEl, B.clonedDrag);
            this._dragEl.setAttribute("id", null);
            this._dragEl.style.position = "absolute";
            if (this._parent != null) {
              var _ = this.manager.getPosition(this.el);
              this._dragEl.style.left = _.x + "px";
              this._dragEl.style.top = _.y + "px";
              this._parent.appendChild(this._dragEl);
            } else {
              var g = l(this._elementToDrag, true);
              var f = this._elementToDrag.getBoundingClientRect();
              this._dragEl.style.left = "".concat(g.x, "px");
              this._dragEl.style.top = "".concat(g.y, "px");
              var p = this.cloneSize == null ? f.width : this.cloneSize.w;
              var y = this.cloneSize == null ? f.height : this.cloneSize.h;
              this._dragEl.style.width = "".concat(p, "px");
              this._dragEl.style.height = "".concat(y, "px");
              document.body.appendChild(this._dragEl);
              if (this._getTargetScale && !this._ignoreZoom) {
                c = this._getTargetScale();
                if (c !== 1) {
                  this._dragEl.style.transform = "scale(".concat(c, ")");
                  this._dragEl.style.transformOrigin = "0 0";
                }
              }
            }
          } else {
            this._dragEl = this._elementToDrag;
          }
          if (this.consumeStartEvent) {
            h(e);
          }
          this._downAt = u(e);
          this._currentZoom = this.manager._getEffectiveZoom();
          if (this._dragEl && this._dragEl.parentNode) {
            this._initialScroll = {
              x: this._dragEl.parentNode.scrollLeft,
              y: this._dragEl.parentNode.scrollTop
            };
          }
          this._posAtDown = this.manager.getPosition(this._dragEl);
          this._pagePosAtDown = l(this._dragEl);
          this._pageDelta = {
            x: this._pagePosAtDown.x - this._posAtDown.x,
            y: this._pagePosAtDown.y - this._posAtDown.y
          };
          this._size = this.manager.getSize(this._dragEl);
          this._size.w = this._size.w * c;
          this._size.h = this._size.h * c;
          this.eventManager.on(document, d, this.moveListener);
          this.eventManager.on(document, v, this.upListener);
          this._dispatch(w, {
            el: this.el,
            pos: this._posAtDown,
            e: e,
            drag: this,
            size: this._size
          });
        } else if (this._consumeFilteredEvents) {
          h(e);
        }
      }
    }
  }, {
    key: "_moveListener",
    value: function t(e) {
      if (this._downAt) {
        if (!this._moving) {
          var i = this._dispatch(k, {
            el: this.el,
            pos: this._posAtDown,
            e: e,
            drag: this,
            size: this._size
          });
          if (i !== false) {
            if (!this._downAt) {
              return;
            }
            this.mark(i);
            this._moving = true;
          } else {
            this.abort();
          }
        }
        if (this._downAt) {
          var s = u(e),
            r = s.x - this._downAt.x,
            o = s.y - this._downAt.y;
          this._lastPosition = {
            x: s.x,
            y: s.y
          };
          this._lastScrollValues = {
            x: document.documentElement.scrollLeft,
            y: document.documentElement.scrollTop
          };
          if (this._dragEl && this._dragEl.parentNode) {
            r += this._dragEl.parentNode.scrollLeft - this._initialScroll.x;
            o += this._dragEl.parentNode.scrollTop - this._initialScroll.y;
          }
          r /= this._currentZoom;
          o /= this._currentZoom;
          this.moveBy(r, o, e);
        }
      }
    }
  }, {
    key: "getDragDelta",
    value: function t() {
      if (this._posAtDown != null && this._downAt != null) {
        return {
          x: this._downAt.x - this._posAtDown.x,
          y: this._downAt.y - this._posAtDown.y
        };
      } else {
        return {
          x: 0,
          y: 0
        };
      }
    }
  }, {
    key: "canvasPanned",
    value: function t(e) {
      this._canvasShift.x -= e.x;
      this._canvasShift.y -= e.y;
      this.moveBy(this._currentDeltas.x, this._currentDeltas.y);
    }
  }, {
    key: "mark",
    value: function t(e) {
      this._canvasShift = {
        x: 0,
        y: 0
      };
      this._posAtDown = this.manager.getPosition(this._dragEl);
      this._currentDeltas = {
        x: 0,
        y: 0
      };
      this._pagePosAtDown = l(this._dragEl);
      this._pageDelta = {
        x: this._pagePosAtDown.x - this._posAtDown.x,
        y: this._pagePosAtDown.y - this._posAtDown.y
      };
      this._size = this.manager.getSize(this._dragEl);
      a(this._dragEl, this.manager.css.drag);
      this._constrainRect = this.manager.getParentConstrainingRectangle(this._dragEl);
      this._ghostDx = 0;
      this._ghostDy = 0;
    }
  }, {
    key: "unmark",
    value: function t(e) {
      if (this._isConstrained && this._useGhostProxy(this._elementToDrag, this._dragEl)) {
        this._ghostProxyOffsets = {
          x: this._dragEl.offsetLeft - this._ghostDx,
          y: this._dragEl.offsetTop - this._ghostDy
        };
        this._dragEl.parentNode.removeChild(this._dragEl);
        this._dragEl = this._elementToDrag;
      } else {
        this._ghostProxyOffsets = null;
      }
      c(this._dragEl, this.manager.css.drag);
      this._isConstrained = false;
    }
  }, {
    key: "moveBy",
    value: function t(e, i, s) {
      var r = this.toGrid({
          x: this._posAtDown.x + e + this._canvasShift.x,
          y: this._posAtDown.y + i + this._canvasShift.y
        }),
        o = this._doConstrain(r, this._dragEl, this._constrainRect, this._size, {
          x: this._posAtDown.x,
          y: this._posAtDown.y
        }, s);
      if (o != null) {
        if (this._useGhostProxy(this.el, this._dragEl)) {
          if (r.x !== o.x || r.y !== o.y) {
            if (!this._isConstrained) {
              var n = this._ghostProxyFunction(this._elementToDrag);
              a(n, B.ghostProxy);
              if (this._ghostProxyParent) {
                this._ghostProxyParent.appendChild(n);
                this._currentParentPosition = l(this._elementToDrag.parentNode);
                this._ghostParentPosition = l(this._ghostProxyParent);
                this._ghostDx = this._currentParentPosition.x - this._ghostParentPosition.x;
                this._ghostDy = this._currentParentPosition.y - this._ghostParentPosition.y;
              } else {
                this._elementToDrag.parentNode.appendChild(n);
              }
              this._dragEl = n;
              this._isConstrained = true;
            }
            o = r;
          } else {
            if (this._isConstrained) {
              this._dragEl.parentNode.removeChild(this._dragEl);
              this._dragEl = this._elementToDrag;
              this._isConstrained = false;
              this._currentParentPosition = null;
              this._ghostParentPosition = null;
              this._ghostDx = 0;
              this._ghostDy = 0;
            }
          }
        }
        this._currentDeltas.x = o.x - this._posAtDown.x - this._canvasShift.x;
        this._currentDeltas.y = o.y - this._posAtDown.y - this._canvasShift.y;
        this.manager.setPosition(this._dragEl, {
          x: o.x + this._ghostDx,
          y: o.y + this._ghostDy
        });
        this._dispatch(A, {
          el: this.el,
          pos: o,
          e: s,
          drag: this,
          size: this._size,
          originalPos: this._posAtDown,
          viewport: this._constrainRect
        });
      }
    }
  }, {
    key: "abort",
    value: function t() {
      if (this._downAt != null) {
        this._upListener();
      }
    }
  }, {
    key: "getDragElement",
    value: function t(e) {
      return e ? this._elementToDrag || this.el : this._dragEl || this.el;
    }
  }, {
    key: "stop",
    value: function t(e, i) {
      if (i || this._moving) {
        var s = [],
          r = this.manager.getPosition(this._dragEl);
        s.push([this._dragEl, r, this, this._size]);
        this._dispatch(L, {
          el: this._dragEl,
          pos: this._ghostProxyOffsets || r,
          finalPos: r,
          e: e,
          drag: this,
          selection: s,
          size: this._size,
          originalPos: {
            x: this._posAtDown.x,
            y: this._posAtDown.y
          },
          viewport: this._constrainRect
        });
      } else if (!this._moving) {
        this._activeSelectorParams.dragAbort ? this._activeSelectorParams.dragAbort(this._elementToDrag) : null;
      }
    }
  }, {
    key: "_dispatch",
    value: function t(e, i) {
      var s = null;
      if (this._activeSelectorParams && this._activeSelectorParams[e]) {
        s = this._activeSelectorParams[e](i);
      } else if (this.listeners[e]) {
        for (var r = 0; r < this.listeners[e].length; r++) {
          try {
            var o = this.listeners[e][r](i);
            if (o != null) {
              s = o;
            }
          } catch (t) {}
        }
      }
      return s;
    }
  }, {
    key: "resolveGrid",
    value: function t() {
      var e = {
        grid: null,
        thresholdX: N / 2,
        thresholdY: G / 2
      };
      if (this._activeSelectorParams != null && this._activeSelectorParams.grid != null) {
        e.grid = this._activeSelectorParams.grid;
        if (this._activeSelectorParams.snapThreshold != null) {
          e.thresholdX = this._activeSelectorParams.snapThreshold;
          e.thresholdY = this._activeSelectorParams.snapThreshold;
        }
      }
      return e;
    }
  }, {
    key: "toGrid",
    value: function t(e) {
      var i = this.resolveGrid(),
        s = i.grid,
        r = i.thresholdX,
        o = i.thresholdY;
      if (s == null) {
        return e;
      } else {
        var a = s ? s.w / 2 : r,
          n = s ? s.h / 2 : o;
        return x(e, s, a, n);
      }
    }
  }, {
    key: "setUseGhostProxy",
    value: function t(e) {
      this._useGhostProxy = e ? M : Z;
    }
  }, {
    key: "_doConstrain",
    value: function t(e, i, s, r, o, a) {
      if (this._activeSelectorParams != null && this._activeSelectorParams.constrainFunction && typeof this._activeSelectorParams.constrainFunction === "function") {
        return this._activeSelectorParams.constrainFunction(e, i, s, r, o, a);
      } else {
        return e;
      }
    }
  }, {
    key: "_testFilter",
    value: function t(e) {
      for (var i in this._filters) {
        var s = this._filters[i];
        var r = s[0](e);
        if (s[1]) {
          r = !r;
        }
        if (!r) {
          return false;
        }
      }
      return true;
    }
  }, {
    key: "addFilter",
    value: function t(e, i) {
      var s = this;
      if (e) {
        var r = D(e);
        this._filters[r] = [function (t) {
          var i = t.srcElement || t.target;
          var r;
          if (p(e)) {
            r = n(i, e, s.el);
          } else if (typeof e === "function") {
            r = e(t, s.el);
          }
          return r;
        }, i !== false];
      }
    }
  }, {
    key: "removeFilter",
    value: function t(e) {
      var i = typeof e === "function" ? e._katavorioId : e;
      delete this._filters[i];
    }
  }, {
    key: "clearAllFilters",
    value: function t() {
      this._filters = {};
    }
  }, {
    key: "addSelector",
    value: function t(e, i) {
      if (e.selector) {
        if (i) {
          this._availableSelectors.unshift(e);
        } else {
          this._availableSelectors.push(e);
        }
      }
    }
  }, {
    key: "destroy",
    value: function t() {
      this.eventManager.off(this.el, g, this.downListener);
      this.eventManager.off(document, d, this.moveListener);
      this.eventManager.off(document, v, this.upListener);
      this.downListener = null;
      this.upListener = null;
      this.moveListener = null;
      if (this.scrollTracker != null) {
        document.removeEventListener("scroll", this.scrollTracker);
      }
    }
  }]);
  return P;
}(V);
var H = ["input", "textarea", "select", "button", "option"];
var J = H.join(",");
var q = function () {
  function t(e) {
    i(this, t);
    s(this, "eventManager", void 0);
    s(this, "zoom", 1);
    s(this, "css", {});
    s(this, "inputFilterSelector", void 0);
    s(this, "getPosition", void 0);
    s(this, "getSize", void 0);
    s(this, "setPosition", void 0);
    s(this, "getCompoundZoom", void 0);
    this.inputFilterSelector = e.inputFilterSelector || J;
    this.eventManager = new _();
    this.zoom = e.zoom || 1;
    this.getCompoundZoom = e.getCompoundZoom || function () {
      return 1;
    };
    this.getPosition = e.getPosition;
    this.getSize = e.getSize;
    this.setPosition = e.setPosition;
    var r = e.css || {};
    y(this.css, r);
  }
  o(t, [{
    key: "_getEffectiveZoom",
    value: function t() {
      return this.zoom * this.getCompoundZoom();
    }
  }, {
    key: "getParentConstrainingRectangle",
    value: function t(e) {
      var i = e.parentNode;
      if (i == null) {
        return {
          w: 0,
          h: 0
        };
      } else {
        var s = i.scrollLeft != null ? i.scrollLeft : 0;
        var r = i.scrollTop != null ? i.scrollTop : 0;
        var o = this.getSize(i);
        return {
          w: o.w + s,
          h: o.h + r
        };
      }
    }
  }, {
    key: "_prepareParams",
    value: function t(e) {
      e = e || {};
      var i = {
          events: {}
        },
        s;
      for (s in e) {
        i[s] = e[s];
      }
      for (s = 0; s < I.length; s++) {
        i.events[I[s]] = e[I[s]] || R;
      }
      return i;
    }
  }, {
    key: "getInputFilterSelector",
    value: function t() {
      return this.inputFilterSelector;
    }
  }, {
    key: "setInputFilterSelector",
    value: function t(e) {
      this.inputFilterSelector = e;
      return this;
    }
  }, {
    key: "draggable",
    value: function t(e, i) {
      if (e._katavorioDrag == null) {
        var s = this._prepareParams(i);
        var r = new U(e, s, this);
        a(e, B.delegatedDraggable);
        e._katavorioDrag = r;
        return r;
      } else {
        return e._katavorioDrag;
      }
    }
  }, {
    key: "destroyDraggable",
    value: function t(e) {
      if (e._katavorioDrag) {
        e._katavorioDrag.destroy();
        delete e._katavorioDrag;
      }
    }
  }]);
  return t;
}();
export { q as Collicat, X as ContainmentType, U as Drag, w as EVENT_BEFORE_START, A as EVENT_DRAG, b as EVENT_DROP, z as EVENT_OUT, T as EVENT_OVER, k as EVENT_START, L as EVENT_STOP, Y as generateConstrainFunctionFromContainmentType };