import { Component, forwardRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { ToolbarItemComponent } from '../toolbar/toolbar-item/toolbar-item.component';
import { ButtonComponent } from '../../elements/button/button.component';
import { FormComponent } from '../../../core/form/form.component';
import { ListComponent } from '../list/list.component';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
  standalone: true,
  imports: [ToolbarComponent, ToolbarItemComponent, ButtonComponent, forwardRef(() => FormComponent), forwardRef(() => ListComponent)]
})
export class InfoDialogComponent {

  public objectTitle: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      endpoint: string;
      type: string;
      isWizardClosing: boolean;
      isArchimate: boolean;
    },
    public dialogRef: MatDialogRef<InfoDialogComponent>
  ) {}


  public close(saved = false) {
    this.dialogRef.close(this.data.type !== 'form' ? true : saved);
  }

  public setTitle(title: string) {
    this.objectTitle = title;
  }
}
