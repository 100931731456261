<div class="container">
  <naris-toolbar>
    <h4>{{'datafeeds.datafeeds' | translate}}</h4>
  </naris-toolbar>
  <div class="grid">
    <div class="sidebar">
      <div>
        <naris-form-field
          [label]="'API key'"
        >
          <naris-input
            [control]="apiKeyControl"
            [disabled]="true"
            type="password"
          />
        </naris-form-field>
      </div>
      <div class="endpoints">
        @for (endpoint of endpoints; track $index) {
          <div (click)="selectEndpoint(endpoint)" class="endpoints-item" tabindex="0">
            <span class="endpoints-item-method">{{endpoint.method}}</span>
            {{endpoint.endpoint}}<br>
          </div>
        }
      </div>
    </div>
    <div class="content">
      @if (!!selectedEndpoint) {
        <div>
          <div class="header">
            <h3>{{selectedEndpoint.endpoint}}</h3>
            <span class="header-method">{{selectedEndpoint.method}}</span>
            {{selectedEndpoint.description}}
          </div>
          @if (expanded) {
            <div class="parameters" [@fadeInOutAnimation]>
              @for (parameter of selectedEndpoint.parameters; track $index) {
                <div class="parameters-item">
                  <div class="parameters-item-header">
                    <span class="parameters-item-header-label">{{parameter.param}}</span>
                    <naris-icon
                      [icon]="'info-outline'"
                      [matTooltip]="parameter.description"
                      [iconClass]="'small'"
                    />
                  </div>
                  <naris-input
                    [control]="getFormControl(parameter.param)"
                    [placeholder]="parameter.format"
                  />
                </div>
              }
            </div>
            <naris-form-actions>
              <naris-button (click)="expanded = !expanded" id="DatafeedsCollapse">{{'datafeeds.collapse' | translate}}</naris-button>
              <naris-button color="primary" (click)="sendRequest()" [loading]="loading" id="DatafeedsSend">Send</naris-button>
            </naris-form-actions>
          } @else {
            <div>
              <naris-button (click)="expanded = !expanded" id="DatafeedsExpand">{{'datafeeds.expand' | translate}}</naris-button>
            </div>
          }
          @if (!!datafeedUrl) {
            <div>
              <span class="label">{{'datafeeds.generated_url' | translate}}:</span><br>
              <div class="generated-url">
                {{datafeedUrl}}
                <naris-icon
                  [icon]="'copy'"
                  [matTooltip]="'datafeeds.copy_url' | translate"
                  [iconClass]="'small'"
                  [cdkCopyToClipboard]="datafeedUrl"
                />
              </div>
            </div>
          }
          @if (datafeedResult && !loading) {
            <div>
              <div class="result">
                <pre>{{datafeedResult | json}}</pre>
              </div>
              <div class="download-result">
                <naris-button color="primary" (click)="downloadResult()" id="DatafeedsDownload">{{'download' | translate}}</naris-button>
              </div>
            </div>
          }
          @if (loading) {
            <div class="loading">
              <mat-progress-bar mode="indeterminate" />
            </div>
          }
        </div>
      } @else {
        <div class="empty">
          <naris-empty-state
            [title]="'audit_execution.nothing_selected' | translate"
            [text]="'datafeeds.no_endpoint_selected' | translate"
            [image]="'empty'"
          />
        </div>
      }
    </div>
  </div>
</div>
