import { _node as e, _appendAtIndex as n, ELEMENT_PATH as t, _attr as l, ELEMENT_G as o, svg as r, ELEMENT_RECT as i, ELEMENT_TEXT as m } from "./svg-util.js";
import { extend as a, isFunction as c } from "../util/util.js";
import { addClass as u } from "./browser-util.js";
function d(t, l) {
  if (t.contentElement == null) {
    var o = a({
      "jtk-overlay-id": t.id
    }, t.attributes);
    t.contentElement = e(l, o);
    var r = t.instance.overlayClass + " " + (t.cssClass ? t.cssClass : "");
    u(t.contentElement, r);
    t.contentElement.jtk = {
      overlay: t
    };
  }
  var i = t.contentElement.parentNode;
  if (i == null) {
    var m = t.component.connector;
    i = m != null ? m.canvas : null;
    if (i != null) {
      n(i, t.contentElement, 1);
    }
  }
  return t.contentElement;
}
function s(e, n, o, r) {
  d(e, t);
  var i = [0, 0];
  if (r.xmin < 0) {
    i[0] = -r.xmin;
  }
  if (r.ymin < 0) {
    i[1] = -r.ymin;
  }
  var m = {
    d: n,
    stroke: o.stroke ? o.stroke : null,
    fill: o.fill ? o.fill : null,
    transform: "translate(" + i[0] + "," + i[1] + ")",
    "pointer-events": "visibleStroke"
  };
  l(e.contentElement, m);
}
function E(e) {
  return d(e, t);
}
function f(e) {
  d(e, o);
  if (e.bgElement == null) {
    e.bgElement = r.node(i);
    e.contentElement.appendChild(e.bgElement);
  }
  if (e.textElement == null) {
    e.textElement = r.node(m);
    e.contentElement.appendChild(e.textElement);
  }
  return e.contentElement;
}
function v(e, n, t) {
  f(e);
  var o = c(e.label) ? e.label(e) : e.label;
  var r = [0, 0];
  if (t.xmin < 0) {
    r[0] = -t.xmin;
  }
  if (t.ymin < 0) {
    r[1] = -t.ymin;
  }
  var i = {
    x: n.d.loc.x,
    y: n.d.loc.y,
    "text-anchor": "middle",
    "dominant-baseline": "middle",
    "pointer-events": "visibleStroke",
    transform: "translate(" + r[0] + "," + r[1] + ")"
  };
  l(e.textElement, i);
  e.textElement.textContent = o;
  var m = e.textElement.getBoundingClientRect();
  var a = e.instance.currentZoom;
  var u = m.width / a;
  var d = m.height / a;
  var s = 5;
  var E = {
    transform: "translate(" + r[0] + "," + r[1] + ")",
    x: i.x - s - u / 2,
    y: i.y - s - d / 2,
    width: u + 2 * s,
    height: d + 2 * s,
    fill: o == null || o.length === 0 ? "none" : "white"
  };
  l(e.bgElement, E);
}
function b(e, n) {
  if (e.contentElement != null && e.contentElement.parentNode != null) {
    e.contentElement.parentNode.removeChild(e.contentElement);
  }
  if (e.backgroundContentElement != null && e.backgroundContentElement.parentNode != null) {
    e.backgroundContentElement.parentNode.removeChild(e.backgroundContentElement);
  }
  delete e.contentElement;
  delete e.backgroundContentElement;
}
export { b as destroySVGOverlay, f as ensureSVGLabelElements, E as ensureSVGPathElement, v as paintSVGLabelOverlay, s as paintSVGPathOverlay };