import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import type { Observable } from 'rxjs';
import type { IDialogData } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog) { }

  public open({type, title, text, confirmLabel = 'close', confirmColor = 'primary', cancelLabel, closable = true, timer, isRichText = false}: IDialogData): Observable<boolean> {
    const dialogConfig = {
      panelClass: 'naris-dialog',
      data: {type, title, text, confirmLabel, confirmColor, cancelLabel, closable, timer, isRichText},
      disableClose: !closable,
      closeOnNavigation: closable,
      autoFocus: true
    };
    return this.dialog.open(DialogComponent, dialogConfig).afterClosed();
  }
}
