import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { Location, NgClass, NgStyle } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Subject, forkJoin, Subscription, of } from 'rxjs';
import { filter, catchError } from 'rxjs/operators';
import { ModuleComponent } from '@core/module/module.component';
import { ICaseViewPanel, INarisStepperStep, ITaskGroup, ICombinedResponse, IAttribute, IAction, ILayoutTab } from '@core/models';
import { SetTabClass } from '@core/classes';
import { ACTIONS } from '@core/constants/action-constants';
import { NarisBreadcrumbService } from '@core/services/breadcrumb.service';
import { BeinformedService, FormService, LanguageService, StreamService, TabService, TaskgroupService } from '@core/services';
import { PreviousRouteService } from '@core/services/previous-route.service';
import { durationCompact, truncate } from '@core/helpers';
import { COMPARE_TOKEN } from '@core/constants';
import { MatDialog } from '@angular/material/dialog';
import { JSONCompareComponent } from '@shared/components/json-compare/json-compare.component';
import { ComponentType } from '@angular/cdk/portal';
import { FooterToolbarService } from '@core/services/footer-toolbar.service';
import { GuardService } from '@core/services/guard.service';
import { MatTabChangeEvent, MatTabGroup, MatTab, MatTabContent } from '@angular/material/tabs';
import { BreadcrumbTabsService } from '@core/services/breadcrumb-tab.service';
import { TableSettingsService } from '@core/services/table-settings.service';
import { CaseViewService } from '@core/services/case-view-service.service';
import { CdkOverlayOrigin, CdkConnectedOverlay } from '@angular/cdk/overlay';
import { MatTooltip } from '@angular/material/tooltip';
import { ToolbarComponent } from '../../../shared/components/toolbar/toolbar.component';
import { ToolbarItemComponent } from '../../../shared/components/toolbar/toolbar-item/toolbar-item.component';
import { ModuleCaseAttributeComponent } from '../module-case-attribute/module-case-attribute.component';
import { IconComponent } from '../../../shared/elements/icon/icon.component';
import { TaskgroupComponent } from '../../../shared/components/taskgroup/taskgroup.component';
import { OverviewComponent } from '../../../shared/components/overview/overview.component';
import { ModuleCollaborationViewComponent } from '../module-collaboration-view/module-collaboration-view.component';
import { StepperComponent } from '../../../shared/components/stepper/stepper.component';
import { AuditExecutionComponent } from '../../../features/audit/audit-execution/audit-execution.component';
import { ModuleCaseViewPanelComponent } from '../module-case-view-panel/module-case-view-panel.component';
import { ModuleAssessmentViewComponent } from '../module-assessment-view/module-assessment-view.component';
import { FinishedWizardComponent } from '../../../shared/components/finished-wizard/finished-wizard.component';
import { ProgressSpinnerComponent } from '../../../shared/elements/progress-spinner/progress-spinner.component';

@Component({
  selector: 'app-module-case-view',
  templateUrl: './module-case-view.component.html',
  styleUrls: ['./module-case-view.component.scss'],
  standalone: true,
  imports: [ToolbarComponent, ToolbarItemComponent, ModuleCaseAttributeComponent, CdkOverlayOrigin, CdkConnectedOverlay, IconComponent, MatTooltip, NgClass, TaskgroupComponent, MatTabGroup, MatTab, MatTabContent, OverviewComponent, ModuleCollaborationViewComponent, StepperComponent, NgStyle, AuditExecutionComponent, ModuleCaseViewPanelComponent, ModuleAssessmentViewComponent, FinishedWizardComponent, RouterOutlet, ProgressSpinnerComponent]
})
export class ModuleCaseViewComponent implements OnInit, OnDestroy {

  @ViewChild(MatTabGroup)
  public tabGroup: MatTabGroup;

  public loading = true;
  public title: string;
  public description: string;
  public state: IAttribute;
  public isWizard: boolean;
  public endpoint: string;
  public changed = new Subject();
  public caseId: number;
  public panelName: string;
  public panels?: (INarisStepperStep | null)[] | null;
  public dashboard: string | null;
  public toolbarTaskgroups: ITaskGroup[] = [];
  public wizardTaskgroups: ITaskGroup[] = [];
  public toolbarItems = ['abort-audit-execution', 'tags', 'accept-updates', 'resume-audit-execution', 'suspend-audit-execution'];
  public attributes?: IAttribute[];
  public _panels?: ICaseViewPanel[] | null;
  public showBackButton = true;
  public showStructureButton = false;
  public urlsWithStructureButtons = ['audit-execution/', 'audit-execution-inprogress/'];
  public layoutTabs: ILayoutTab[] = [];
  public selectedTab: ILayoutTab;
  public showTitleOverlay = false;
  public toolbarTaskgroupActions = ['tags', 'add-document', 'push-updates', 'accept-updates'];
  private readonly subs: Subscription[] = [];

  public isAssessment = false;
  public isCollaboration = false;
  public isWizardInitial = true;
  public isAuditProgram = false;
  public activeStepUrl: string | undefined;

  private initialUrl = this.router.url;
  private isNavigated: boolean;
  public selectedTabIndex: number;
  public processIds: number[];
  public _isWizard = false;
  public isStepperCollapsed = false;

  constructor(
    @Inject(ModuleComponent) public moduleComponent: ModuleComponent,
    private readonly beinformedService: BeinformedService,
    private readonly formService: FormService,
    private readonly activatedRoute: ActivatedRoute,
    public readonly router: Router,
    private readonly taskgroupService: TaskgroupService,
    private readonly location: Location,
    private readonly previousRouteService: PreviousRouteService,
    public readonly tabs: TabService,
    private readonly narisBreadcrumbService: NarisBreadcrumbService,
    private readonly languageService: LanguageService,
    @Inject(COMPARE_TOKEN) private readonly compareComponent: ComponentType<JSONCompareComponent>,
    private readonly popUpDialog: MatDialog,
    private readonly footerToolbarService: FooterToolbarService,
    private readonly guardService: GuardService,
    private readonly breadcrumbTabService: BreadcrumbTabsService,
    private readonly tableSettingsService: TableSettingsService,
    private readonly caseViewService: CaseViewService,
    private readonly streamService: StreamService
  ) {
    const stepperCollapsed = localStorage.getItem('stepperCollapsed');
    if (!!stepperCollapsed) this.isStepperCollapsed = JSON.parse(stepperCollapsed); 
  }

  public ngOnInit(): void {
    // On initial load, set config based on the route URL and fetch case view
    if (!this.isCreateWizard) {
      this.setup();
      this.tabs.clear();
    } else this.loading = false;
    // Listen for route changes, (re)set config and fetch case view if needed
    this.subs.push(
      this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(e => {
        this.setup();
        this.narisBreadcrumbService.add((e).url, '___');
        this.isNavigated = true;
        this._isWizard = this.router.url.includes('-wizard');
        if (!this._isWizard && !this._isAssessment) this.tabs.useTabServiceForHelp = true;
      }),
      this.taskgroupService.refreshPage.subscribe(this.setup),
      this.formService.wizardSteps$.subscribe(steps => {
        this.panels = steps;
      }),
      this.formService.updateFormTitle$.subscribe(newTitle => this.title = newTitle),
      this.caseViewService.stepperCollapsed$.subscribe(collapsed => this.isStepperCollapsed = collapsed)
    );

    if (this.router.url.includes('/collaboration/collaboration/')) 
      this.subs.push(
        this.streamService.streamError$.subscribe(() => this.fetchCaseView())
      );

    if (this.isCreateWizard) {
      this.tabs.pushStateUrl = null;
    }
    const param = this.activatedRoute.snapshot.queryParamMap.get('endpoint') || '';
    this.isAuditProgram = param.includes('create-audit-program');
    this._isWizard = this.router.url.includes('-wizard');
    if (!this._isWizard && !this._isAssessment) this.tabs.useTabServiceForHelp = true;
  }

  public ngOnDestroy(): void {
    if (!this._isWizard && !this._isAssessment) this.tabs.useTabServiceForHelp = false;
    this.subs.forEach(s => s.unsubscribe());
  }

  get dashboardActive(): boolean {
    return !!this.dashboard && this.router.url.includes('dashboard');
  }

  get showStepper(): boolean {
    if (this._isWizard) return true;
    const panel = this._panels!.find(_panel => _panel.name.toLowerCase() === this.panelName);
    return !panel?.layouthint?.includes('separate-structure');
  }

  get _isAssessment(): boolean {
    return this.router.url.includes('-assessment') && !this.router.url.includes('compliance-assessment');
  }

  get isCreateWizard(): boolean {
    const parts = this.router.url.split('/');
    const caseId = parts.find(part => !isNaN(parseInt(part)));
    return this.router.url.includes('-wizard') && caseId === undefined;
  }

  get currentUrl(): string {
    return this.router.url;
  }

  private setTab(url: string) {
    const tabIndex = this.layoutTabs.findIndex(tab => tab.href === url);
    if (tabIndex > -1) {
      this.selectedTabIndex = tabIndex;
      this.tabs.activeTab = this.layoutTabs[tabIndex];
    }
  }

  public go(index: number): void {
    void this.router.navigate([this._panels?.[index].href]);
  }

  public goToStructure(): void {
    const splittedUrl = this._panels?.[0]?.href?.split('/');
    splittedUrl?.pop();
    splittedUrl?.push('structure');
    const structureUrl = splittedUrl?.join('/');
    void this.router.navigate([structureUrl]);
  }

  public goBack() {
    this.location.back();
  }

  /**
   * Fetches latest info about panel process and updates interface.
   * @param onIncomplete callback function that is executed when current step is not completed.
   */
  public validate(onIncomplete?: () => void) {
    this.beinformedService.fetchResponseWithContributions(this.endpoint).subscribe(result => {
      const panels = this._panels = this.beinformedService.extractPanels(result);
      const taskgroups = this.beinformedService.extractTaskGroups(result);
      const attributes = this.beinformedService.extractAttributes(result);

      this.setPanels(panels);
      this.setAttributes(attributes);
      this.fetchTaskGroups(taskgroups);

      if (this.isWizard && this.activatedRoute.snapshot.params.caseType !== 'configuration-check') {
        const currentStepCompleted = panels?.[this.currentIndex!].processStatus?.state === 'Completed';
        if (currentStepCompleted) this.navigateToFirstUnfinishedItem();
        const routeParts = this.router.url.split('/').slice(1, 5);
        const currentPanelName = routeParts[3];
        if (!!onIncomplete && (!currentStepCompleted || this.panelName === currentPanelName)) onIncomplete();
      }
    });
  }

  private setConfig(): void {
    const oldEndpoint = this.endpoint; // Save old endpoint to check if anything changed
    const routeParts = this.router.url.split('/').slice(1, 5);
    const newEndpoint = '/' + routeParts.slice(0, 3).join('/');
    if (oldEndpoint !== newEndpoint) this.changed.next(true);
    // Set new values
    this.endpoint = newEndpoint;
    this.caseId = parseInt(this.router.url.split('/')[3]);
    this.panelName = routeParts[3] || '';
  }

  /**
   * Fetch case view based on the current endpoint
   */
  private fetchCaseView() {
    this.setBackButtonVisibility();
    this.loading = true;

    // Unset previously loaded information
    // this._panels = null;
    this.toolbarTaskgroups = [];
    this.wizardTaskgroups = [];

    // Fetch the case view
    this.beinformedService.fetchResponseWithContributions(this.endpoint).subscribe({
      next: ({ data, contributions }: ICombinedResponse) => {
        const panels = this._panels = this.beinformedService.extractPanels({ data, contributions });
        const attributes = this.beinformedService.extractAttributes({ data, contributions });
        const taskgroups = this.beinformedService.extractTaskGroups({ data, contributions });

        this.processIds = data?.ProcessIDs?.map((processId: Record<string, any>) => processId._id);

        const structurePanel = panels?.find(panel => panel.name.toLowerCase() === 'structure');
        if (!!structurePanel) {
          this.showStructureButton = true;
        }

        this.tabs.updated.subscribe(tabs => {
          this.layoutTabs = [];
          setTimeout(() => {
            const details = tabs?.find(tab => tab.title === 'tabbar.details');
            if (details) {
              void this.router.navigate([details.href]);
            } else {
              this.layoutTabs = tabs.filter(tab => !tab.href.endsWith('session'));
              this.setTab(this.initialUrl);
            }
          });
        });

        this.isAssessment = Object.keys(data).includes('RiskAssessmentName') || Object.keys(data).includes('ControlImplementationAssessmentName') || Object.keys(data).includes('ControlTestAssessmentName');
        this.isCollaboration = Object.keys(data).includes('CollaborationName');

        // Wizards get a layouthint 'Wizard'
        this.isWizard = !!contributions.layouthint?.includes('Wizard');
        this.setPanels(panels);
        this.setAttributes(attributes, contributions.label);
        this.fetchTaskGroups(taskgroups);
        this.setTabs();
        // If user is not on a panel yet, redirect him to first unfinished item.
        // If items have no processStatus, the first item will be navigated to.
        if (!this.isCreateWizard && this.router.url.includes('wizard') && !this.router.url.includes('audit-execution-inprogress')) this.navigateToFirstUnfinishedItem();
      },
      error: () => this.panels = null,
      complete: () => {
        this.loading = false;
        this.tabs.caseViewLoaded.next(true);
      }
    });

    this.showStructureButton = this.urlsWithStructureButtons.some(url => this.router.url.includes(url));
  }

  public setBackButtonVisibility() {
    const previousUrl = this.previousRouteService.getPreviousUrl();
    const currentUrl = this.previousRouteService.getCurrentUrl();
    this.showBackButton = currentUrl?.includes('wizard') || !currentUrl?.endsWith('properties') || !previousUrl?.includes('wizard');
  }

  /**
   * Fetch the task groups for the case view.
   * Seperate call so we can rerun this every time the case view is revalidated.
   */
  private fetchTaskGroups(taskgroups: ITaskGroup[]): void {
    this.toolbarTaskgroups = [];
    this.wizardTaskgroups = [];
    this.footerToolbarService.reset();
    // Map endpoint urls to flat array
    let endpoints = taskgroups?.map((group: ITaskGroup) => group.href);
    // If we have endpoints, fetch the taskgroups
    const settingsObject = taskgroups?.find(taskGroup => taskGroup.name === 'IndividualSettings_Object');
    this.tableSettingsService.resetUrls();
    if (!!settingsObject) {
      this.tableSettingsService.setSettingsObject(settingsObject);
      endpoints = taskgroups.filter(taskGroup => taskGroup.name !== 'IndividualSettings_Object').map(group => group.href);
    }
    if (endpoints) forkJoin(endpoints.map(endpoint => this.beinformedService.fetchResponseWithContributions(endpoint!).pipe(catchError(e => of(e))))).subscribe(result => {
      const tgs = result
        .map((taskgroup: any) => this.beinformedService.extractTaskGroup(taskgroup))
        .filter((taskgroup: ITaskGroup) => !!taskgroup.actions?.length) as ITaskGroup[];
      const iconButtonNames = Object.keys(ACTIONS);
      const allActions: IAction[] = [];
      tgs.forEach(tg => allActions.push(...tg.actions || []));
      allActions.sort((a, b) => iconButtonNames.findIndex(name => name === a.name) - iconButtonNames.findIndex(name => name === b.name));
      if (this.isWizard) {
        this.toolbarTaskgroups = [{ actions: allActions.filter(action => this.toolbarItems.includes(action.name?.toLowerCase() ?? '')) }];
        const wizardTaskgroups = [{ actions: allActions.filter(action => !this.toolbarItems.includes(action.name?.toLowerCase() ?? '')) }];
        if (wizardTaskgroups) this.footerToolbarService.add(wizardTaskgroups[0].actions, 'WIZARD');
      } else this.toolbarTaskgroups = [{ actions: allActions }];
      const mappedActions = this.toolbarTaskgroups.map(group => group.actions);
      this.footerToolbarService.add(mappedActions.flat() as IAction[], 'OBJECT');
      if (!this._isAssessment)
        this.toolbarTaskgroups.forEach(group => group.actions = group.actions?.filter(action => this.toolbarTaskgroupActions.includes(action.name?.toLowerCase() || '')));
    });
  }

  /**
   * Set the attributes for this case view
   * @param attributes array of IAttributes
   */
  private setAttributes(attributes?: IAttribute[], typeLabel?: string) {
    const caseType = `casetype.${this.activatedRoute.snapshot.params.caseType}`;
    this.description = attributes?.find((a: IAttribute) => a.label?.toLowerCase() === 'description')?.valueLabel as string;
    this.attributes = attributes?.filter((a: IAttribute) => a.name?.toLowerCase() !== 'consequencetype' && a.label?.toLowerCase() !== 'description');
    if (this.attributes) this.title = this.setCaseViewTitle(this.attributes, typeLabel);
    const dataType = attributes?.find(attr => attr.name?.toLowerCase() === 'consequencetype');
    const foundItemIndex = this.attributes?.findIndex(attr => attr.name?.toLowerCase() === 'totalexpectedvalue');
    if (!!foundItemIndex && !!dataType) {
      const locale = this.languageService.getAppLang();
      const attribute = this.attributes?.[foundItemIndex];
      if (!!attribute) switch (dataType.options?.find(o => o.label === dataType.valueLabel).code.toLowerCase()) {
        case 'financial': attribute.valueLabel = (attribute?.valueLabel as number).toLocaleString(locale, { style: 'currency', currency: 'EUR' }); break;
        case 'numeric': attribute.valueLabel = (attribute?.valueLabel as number).toLocaleString(locale); break;
        case 'time': attribute.valueLabel = durationCompact(+(attribute?.valueLabel || '00'), 'hours', locale); break;
      }
    }
    const label = truncate(this.title, 35);
    const url = this.router.routerState.snapshot.url;
    this.narisBreadcrumbService.add(url, label, caseType);
  }

  private setCaseViewTitle(_attributes: IAttribute[], typeLabel?: string): string {
    let title = '';
    let breadcrumbTooltip = '';
    let breadcrumbTitle: any = '';

    const state = _attributes.find(attr => attr.name?.toLowerCase() === 'casestate');
    if (state) {
      this.state = state;
      breadcrumbTooltip += `${state.valueLabel}`;
    }

    const number = _attributes.find(attr => attr.name?.endsWith('Number') && attr.name !== 'PhoneNumber');
    if (number) {
      // const splittedActionHref = this.endpoint.split('/');
      // const translatedType = this.translate.instant(`breadcrumb.${splittedActionHref[2]}`);
      if (!!number.valueLabel) {
        title += `${number.valueLabel}`;
        breadcrumbTitle = number.valueLabel;
      }
    } else {
      const name = _attributes.find(attr => attr.layouthint?.includes('title'));
      if (!!name) breadcrumbTitle = name.valueLabel;
    }
    const name = _attributes.find(attr => attr.layouthint?.includes('title'));
    if (name) {
      title += `${number?.valueLabel ? ' - ' : ''}${name.valueLabel}`;
      breadcrumbTooltip += `${state ? ' - ' : ''}${name.valueLabel}`;
    }

    const context = _attributes.find(attr => attr.label === 'Context');
    if (context) {
      title += `${number || name ? ' - ' : ''}${context.valueCode}`;
      breadcrumbTooltip += `${state || name ? ' - ' : ''}${context.valueCode}`;
    }

    const ctrlTestType = _attributes.find(attr => attr.label === 'Control test type');
    if (ctrlTestType) {
      title += `${number || name || context ? ' - ' : ''}${ctrlTestType.valueCode}`;
      breadcrumbTooltip += `${state || name || context ? ' - ' : ''}${ctrlTestType.valueCode}`;
    }

    this.breadcrumbTabService.setLabel(typeLabel || '', breadcrumbTitle as string || name?.valueLabel as string || '');
    this.breadcrumbTabService.setTooltip(breadcrumbTooltip);

    return title;
  }

  /**
   * Create local copy of steps in INarisStepperStep format based on steps from case view
   */
  private setPanels(panels?: ICaseViewPanel[]): void {
    this.panels = null;
    this.dashboard = null;
    this.panels = panels?.map(({ label, href, processStatus, name, layouthint }: any) => {
      if (name === 'Overview') {
        this.dashboard = href;
        return null;
      }
      if (name === 'Execution' || layouthint?.includes('hide')) return null;
      const returnObject = { label, url: href } as Record<string, any>;
      if (this.isWizard) {
        returnObject['completed'] = processStatus
          ? (processStatus.completed || processStatus.needed === 'false') && !processStatus.canComplete
          : true;
        returnObject['intermediate'] = processStatus ? processStatus.canComplete : false;
      }
      return returnObject as INarisStepperStep;
    }).filter(i => !!i && (!i.layouthint?.includes('separate-structure') || !i.layouthint?.includes('hide')));

    const hasIncompletePanel = this.panels?.some(panel => panel?.completed === false);
    if (!hasIncompletePanel && this.isWizard)
      this.addFinishedPanel();
  }

  private addFinishedPanel() {
    const lastIndex = this.panels?.[0]?.url?.lastIndexOf('/');
    const urlPart = this.panels?.[0]?.url?.slice(0, lastIndex);
    this._panels?.push({ name: 'Finish', label: 'Finish', processStatus: { state: 'InProgress', applicable: false, needed: 'false', completed: false, canComplete: false }, resourcetype: 'GroupingPanel', href: `${urlPart}/wizard-finished` });
    this.panels?.push({ label: 'Finish', url: `${urlPart}/wizard-finished`, completed: false, intermediate: false });
  }

  /**
   * Navigate to the first unfinished item, or the last item (fallback)
   */
  private navigateToFirstUnfinishedItem() {
    this.isWizardInitial = false;
    const pushedPanelName = this.tabs.pushStateUrl?.split('/').slice(1, 5)?.[3];
    const currentPanel = this._panels?.find(p => p.name.toLowerCase() === pushedPanelName && p.processStatus && p.processStatus.state !== 'Completed');
    const nextStep = currentPanel || this._panels?.find(p => p.processStatus && p.processStatus.state === 'InProgress')
      // If no unfinished steps, redirect to first
      || this._panels?.[0];
    const routeParts = nextStep?.href.split('/').slice(1, 5);
    const currentPanelName = routeParts?.[3];
    if (!!currentPanelName && nextStep?.href) {
      this.tabs.pushStateUrl = nextStep.href;
      this.guardService.reset();
      this.footerToolbarService.reset();
      void this.router.navigate([nextStep?.href], { replaceUrl: true });
    }
  }

  /**
   * Route the user to the previous step if available
   */
  public back(): void {
    const prevStep = this._panels?.[this.currentIndex! - 1];
    if (prevStep) void this.router.navigate([prevStep.href]);
  }

  /**
   * Route the user to the next step if available
   */
  public next(): void {
    const nextStep = this._panels?.[this.currentIndex! + 1];
    if (nextStep) void this.router.navigate([nextStep.href]);
  }

  /**
   * Returns the index of the current step
   */
  get currentIndex() {
    return this._panels?.map(panel => panel.href).indexOf(this.router.url);
  }

  private readonly setup = () => {
    this.setConfig();
    this.fetchCaseView();
  };

  private setTabs() {
    const setTab = new SetTabClass(this.tabs, this.router);
    setTab.clear();
    if (this.dashboard) {
      setTab.setTabs(this._panels || [], this.showStructureButton);
    } else if (!!this._panels?.length) {
      let panels = [];
      if (this._panels.length === 1) panels.push({ title: 'Details', active: true, href: this._panels[0].href });
      else panels = this._panels;
      setTab.setTabs(panels);
    }
  }

  public selectedIndex: number;
  public updateSelectedIndex(event: number) {
    this.selectedIndex = event;
  }

  public tabClicked(selectedTabIndex: number) {
    if (this.isNavigated) {
      const foundTabIndex = this.layoutTabs.findIndex(tab => tab.href === this.router.url);
      if (foundTabIndex > -1) selectedTabIndex = foundTabIndex;
      this.isNavigated = false;
    }
    this.selectedTab = this.layoutTabs[selectedTabIndex];
    this.tabs.activeTab = this.selectedTab;
    if (!!this.selectedTab) {
      window.history.pushState(this.selectedTab.title, this.selectedTab.title, this.selectedTab.href);
      this.initialUrl = this.selectedTab.href;
      this.breadcrumbTabService.setHref(this.selectedTab.href);
    }
    setTimeout(() => {
      this.caseViewService.updatePanel$.next();
    });
  }

  public generateTooltip() {
    let tooltipText = '';
    this.attributes?.forEach(attr => {
      if (attr.name?.toLowerCase() === 'casestate') tooltipText += `${attr.label}: ${attr.valueLabel}`;
    });
    return tooltipText;
  }

  public stepClicked(stepUrl: string) {
    if (this.isWizard) {
      this.activeStepUrl = stepUrl;
      window.history.pushState(stepUrl, '', stepUrl);
      this.tabs.pushStateUrl = stepUrl;
      const routeParts = stepUrl.split('/').slice(1, 5);
      this.panelName = routeParts[3] || '';
      this.beinformedService.fetchResponseWithContributions(this.endpoint).subscribe({
        next: ({ data, contributions }: ICombinedResponse) => {
          const taskgroups = this.beinformedService.extractTaskGroups({data, contributions}) as ITaskGroup[];
          this.fetchTaskGroups(taskgroups);
          this.caseViewService.updatePanel$.next();
        },
        complete: () => {
          this.loading = false;
        }
      });
    }
    const selectedTab = this.layoutTabs.find(tab => tab.href === stepUrl);
    if (!!selectedTab) this.tabs.activeTab = selectedTab;
  }

  public isAuditExecutionStructure(href: string): boolean {
    return href?.includes('audit-execution') && href?.endsWith('structure');
  }

  public isStrategyMap(): boolean {
    const url = !!this.selectedTab ? this.selectedTab.href : this.router.url;
    return url.endsWith('/strategy-map');
  }

  public isProcessStructure() {
    const url = !!this.selectedTab ? this.selectedTab.href : this.router.url;
    return url.startsWith('/process') && (url.endsWith('/designer') || url.endsWith('/viewer'));
  }

  public checkSelectedTab(_event: MatTabChangeEvent) {
    const selectedTab = this.layoutTabs[_event.index];
    if (selectedTab?.href.includes('/process/process/') && selectedTab?.href.endsWith('/structure')) {
      this.tabs.updateTab$.next(true);
    }
  }
}
