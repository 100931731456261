import { quadrant as t, perpendicularLineTo as e, lineIntersection as r, lineLength as a } from "../util/geom.js";
import { transformAnchorPlacement as n, _setAnchorLocation as s } from "../core/connector/abstract-connector.js";
import { Connectors as u, _addConnectorSegment as o } from "../core/connector/connectors.js";
import { SEGMENT_TYPE_STRAIGHT as y } from "../core/connector/straight-segment.js";
import { SEGMENT_TYPE_BEZIER as c } from "../connector-bezier/bezier-segment.js";
import { SEGMENT_TYPE_ARC as x } from "../core/connector/arc-segment.js";
function g(t, e) {
  return t.curX === e.curX && t.curY === e.curY;
}
function m(t, e) {
  var r = X(e);
  if (t.smooth === true && r.length >= 2) {
    var a = f(t, r);
    a.map(function (e) {
      return o(t, e.type, e.segment);
    });
  } else {
    if (t.cornerRadius != null) {
      v(t, r);
    } else {
      r.map(function (e) {
        return o(t, y, e);
      });
    }
  }
  return r;
}
function i(t) {
  return Math.atan2(t.ay1 - t.ay2, t.ax1 - t.ax2);
}
function l(t) {
  return Math.sqrt(Math.pow(t.ax2 - t.ax1, 2) + Math.pow(t.ay2 - t.ay1, 2));
}
function v(t, n) {
  var s = null,
    u,
    c,
    g;
  for (var m = 0; m < n.length - 1; m++) {
    s = s || Object.assign({}, n[m]);
    u = Object.assign(n[m + 1]);
    c = i(s);
    g = i(u);
    if (t.cornerRadius > 0 && c !== g) {
      var v = Math.min(l(s), l(u));
      var f = Math.min(t.cornerRadius, .99 * v / 2);
      var h = {
        x: s.x2 + f * Math.cos(c),
        y: s.y2 + f * Math.sin(c)
      };
      var b = {
        x: u.x1 - f * Math.cos(g),
        y: u.y1 - f * Math.sin(g)
      };
      var p = Math.atan((c - g) / (1 + c * g));
      var X = e({
        x: s.x1,
        y: s.y1
      }, h, 1e4);
      var Y = e({
        x: u.x1,
        y: u.y1
      }, b, 1e4);
      var M = r(X, Y);
      var d = M != null ? a(M, h) : 0;
      if (d > 0) {
        s.x2 = h.x;
        s.y2 = h.y;
        u.x1 = b.x;
        u.y1 = b.y;
        o(t, y, Object.assign({}, s));
        o(t, x, {
          r: d,
          x1: h.x,
          y1: h.y,
          x2: b.x,
          y2: b.y,
          cx: M.x,
          cy: M.y,
          ac: p > 0
        });
      } else {
        o(t, y, Object.assign({}, s));
      }
    } else {
      o(t, y, Object.assign({}, s));
    }
    s = u;
  }
  if (u != null) {
    o(t, y, Object.assign({}, s));
  }
}
function f(t, e) {
  var r = function t(e, r) {
    var a = r.x - e.x;
    var n = r.y - e.y;
    return {
      length: Math.sqrt(Math.pow(a, 2) + Math.pow(n, 2)),
      angle: Math.atan2(n, a)
    };
  };
  var a = function e(a, n, s, u) {
    var o = n || a;
    var y = s || a;
    var c = r(o, y);
    var x = c.angle + (u ? Math.PI : 0);
    var g = c.length * t.smoothing;
    var m = a.x + Math.cos(x) * g;
    var i = a.y + Math.sin(x) * g;
    return {
      x: m,
      y: i
    };
  };
  var n = e[e.length - 1];
  var s = [];
  function u(t) {
    var r = t === e.length ? {
      x: n.x2,
      y: n.y2
    } : e[t] != null ? {
      x: e[t].x1,
      y: e[t].y1
    } : null;
    return r;
  }
  for (var o = 2; o < e.length + 1; o++) {
    var y = u(o - 1);
    var x = u(o);
    var g = u(o + 1);
    var m = u(o - 2);
    var i = a(y, m, x);
    var l = a(x, y, g, true);
    s.push({
      type: c,
      segment: {
        x1: x.x,
        x2: o === 2 ? e[0].x1 : y.x,
        y1: x.y,
        y2: o === 2 ? e[0].y1 : y.y,
        cp2x: i.x,
        cp2y: i.y,
        cp1x: l.x,
        cp1y: l.y
      }
    });
  }
  return s;
}
function h(e, r, a) {
  var n = t({
    x: r.sx,
    y: r.sy
  }, {
    x: r.tx,
    y: r.ty
  });
  if (e.geometry == null || !e.edited) {
    var s = [{
      ax1: r.sx + r.x,
      ay1: r.sy + r.y,
      ax2: r.x + r.startStubX,
      ay2: r.y + r.startStubY,
      x1: r.sx,
      y1: r.sy,
      x2: r.startStubX,
      y2: r.startStubY
    }, {
      ax1: r.x + r.startStubX,
      ay1: r.y + r.startStubY,
      ax2: r.x + r.endStubX,
      ay2: r.y + r.endStubY,
      x1: r.startStubX,
      y1: r.startStubY,
      x2: r.endStubX,
      y2: r.endStubY
    }, {
      ax1: r.x + r.endStubX,
      ay1: r.y + r.endStubY,
      ax2: r.x + r.tx,
      ay2: r.y + r.ty,
      x1: r.endStubX,
      y1: r.endStubY,
      x2: r.tx,
      y2: r.ty
    }];
    var u = m(e, s);
    e.geometry = {
      source: a.sourcePos,
      target: a.targetPos,
      segments: u,
      quadrant: n
    };
  } else {
    var o = a.sourcePos,
      y = a.targetPos;
    var c = !g(o, e.geometry.source),
      x = !g(y, e.geometry.target);
    if (x) {
      var i = e.geometry.segments[e.geometry.segments.length - 1];
      i.ax2 = y.curX;
      i.ay2 = y.curY;
      e.geometry.target.curX = y.curX;
      e.geometry.target.curY = y.curY;
    } else if (c) {
      var l = e.geometry.segments[0];
      l.ax1 = o.curX;
      l.ay1 = o.curY;
      e.geometry.source.curX = o.curX;
      e.geometry.source.curY = o.curY;
    }
    e.geometry.quadrant = n;
    d(e);
    m(e, e.geometry.segments);
  }
}
function b(t, e, r) {
  return {
    source: n(t.source, e, r),
    target: n(t.target, e, r),
    segments: t.segments.map(function (t) {
      return {
        x: t.x + e,
        y: t.y + r
      };
    })
  };
}
function p(e, r) {
  if (r != null && r.segments != null && r.source != null && r.target != null) {
    var a = [];
    var n = r.source.curX,
      o = r.source.curY;
    var y = n,
      c = o;
    for (var x = 0; x < r.segments.length; x++) {
      a.push({
        ax1: y,
        ay1: c,
        ax2: r.segments[x].x,
        ay2: r.segments[x].y,
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0
      });
      y = r.segments[x].x;
      c = r.segments[x].y;
    }
    a.push({
      ax1: y,
      ay1: c,
      ax2: r.target.curX,
      ay2: r.target.curY,
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 0
    });
    var g = t({
      x: r.source.curX,
      y: r.source.curY
    }, {
      x: r.target.curX,
      y: r.target.curY
    });
    var i = m(e, Y(a));
    u.setGeometry(e, {
      source: r.source,
      target: r.target,
      segments: i,
      quadrant: g
    }, false);
    s(r.source, e.connection, 0);
    s(r.target, e.connection, 1);
    return true;
  }
  return false;
}
function X(t) {
  if (t.length === 0) {
    return [];
  } else {
    return t.filter(function (t) {
      return !(t.x1 === t.x2 && t.y1 === t.y2);
    });
  }
}
function Y(t) {
  var e = t[0].ax1,
    r = t[0].ay1,
    a = t[t.length - 1].ax2,
    n = t[t.length - 1].ay2,
    s = a >= e ? e : a,
    u = n >= r ? r : n;
  t.forEach(function (t) {
    t.x1 = t.ax1 - s;
    t.y1 = t.ay1 - u;
    t.x2 = t.ax2 - s;
    t.y2 = t.ay2 - u;
  });
  return t;
}
function M(t) {
  if (t.geometry == null) {
    return null;
  } else {
    var e = t.geometry.segments.slice(0, t.geometry.segments.length - 1).map(function (t) {
      return {
        x: t.ax2,
        y: t.ay2
      };
    });
    return {
      segments: e,
      source: t.geometry.source,
      target: t.geometry.target
    };
  }
}
function d(t) {
  if (t.geometry != null) {
    t.geometry.segments = Y(t.geometry.segments);
  }
}
export { d as _updateSegmentedConnectorFromAbsolute, h as computeSegmentedConnector, l as connectorSegmentLength, M as exportSegmentedGeometry, p as importSegmentedGeometry, i as segmentLineAngle, Y as transformSegmentedFromAbsolute, b as transformSegmentedGeometry };