@if (formGroup) {
  <ng-template #deadlineInputs>
    <ng-container>
      <h4 class="pt-6 pb-4">{{'frequency.set_the_deadline_for_each_iteration' | translate}}</h4>
      <div class="input-row">
        <div>
          @switch (formGroup.get('Deadline_FrequencyUnit')?.value) {
            @case ('Deadline_Days') {
              <naris-inline-input
                [control]="getFormControl('Deadline_Daily_Frequency')"
                [error]="getErrorForInput('Deadline_Daily_Frequency')"
                [min]="1"
                [max]="9999"
                type="number"
                value="1"
                [requiredIfVisible]="true"
                [clearOnDestroy]="true"
                [id]="formId + '__DeadlineDailyFrequency'"
              />
            }
            @case ('Deadline_Workdays') {
              <naris-inline-input
                [control]="getFormControl('Deadline_WorkDay_Frequency')"
                [error]="getErrorForInput('Deadline_WorkDay_Frequency')"
                [min]="1"
                [max]="999"
                type="number"
                value="1"
                [requiredIfVisible]="true"
                [clearOnDestroy]="true"
                [id]="formId + '__DeadlineWorkDayFrequency'"
              />
            }
            @case ('Deadline_Weeks') {
              <naris-inline-input
                [control]="getFormControl('Deadline_Weekly_Frequency')"
                [error]="getErrorForInput('Deadline_Weekly_Frequency')"
                [min]="1"
                [max]="999"
                type="number"
                value="1"
                [requiredIfVisible]="true"
                [clearOnDestroy]="true"
                [id]="formId + '__DeadlineWeeklyFrequency'"
              />
            }
            @case ('Deadline_Months') {
              <naris-inline-input
                [control]="getFormControl('Deadline_Monthly_YMonths')"
                [error]="getErrorForInput('Deadline_Monthly_YMonths')"
                [min]="1"
                [max]="31"
                type="number"
                value="1"
                [requiredIfVisible]="true"
                [clearOnDestroy]="true"
                [id]="formId + '__DeadlineMonthlyYMonths'"
              />
            }
            @case ('Deadline_Years') {
              <naris-inline-input
                [control]="getFormControl('Deadline_Years_Frequency')"
                [error]="getErrorForInput('Deadline_Years_Frequency')"
                [min]="1"
                [max]="99"
                type="number"
                value="1"
                [requiredIfVisible]="true"
                [clearOnDestroy]="true"
                [id]="formId + '__DeadlineYearsFrequency'"
              />
            }
          }
        </div>
        <naris-inline-select
          [control]="getFormControl('Deadline_FrequencyUnit')"
          [error]="getErrorForInput('Deadline_FrequencyUnit')"
          [options]="config.deadlineUnits"
          [placeholder]="'frequency.select_unit' | translate"
          value="Deadline_Days"
          class="ml-2 mr-2"
          [id]="formId + '__DeadlineFrequencyUnit'"
        />
        <div class="mr-2">{{'frequency.later' | translate}}</div>

        <!-- Weeks -->
        @if (formGroup.get('Deadline_FrequencyUnit')?.value === 'Deadline_Weeks') {
          <div class="mr-2">{{'frequency.on' | translate}}</div>
          <naris-inline-select
            [control]="getFormControl('Deadline_Weekly_DayofWeek')"
            [error]="getErrorForInput('Deadline_Weekly_DayofWeek')"
            [options]="config.periods.Days.options"
            [placeholder]="'frequency.select_a_day' | translate"
            value="Monday"
            [requiredIfVisible]="true"
            [clearOnDestroy]="true"
            [id]="formId + '__DeadlineWeeklyDayofWeek'"
          />
        }

        <!-- Months -->
        @if (formGroup.get('Deadline_FrequencyUnit')?.value === 'Deadline_Months') {
          <div class="mr-2">
            {{'frequency.on_the' | translate}}
          </div>
          @if (formGroup.get('Deadline_Monthly_DayofWeek')?.value) {
            <naris-inline-input
              [control]="getFormControl('Deadline_Monthly_Day_xth')"
              [error]="getErrorForInput('Deadline_Monthly_Day_xth')"
              [min]="1"
              [max]="4"
              type="number"
              value="1"
              [requiredIfVisible]="true"
              [clearOnDestroy]="true"
              [id]="formId + '__DeadlineMonthlyDayxth'"
            />
            <div class="mr-2">
              {{formGroup.get('Deadline_Monthly_Day_xth')?.value | ordinal}}
            </div>
            <naris-inline-select
              [control]="getFormControl('Deadline_Monthly_DayofWeek')"
              [error]="getErrorForInput('Deadline_Monthly_DayofWeek')"
              [options]="config.periods.Days.options"
              [placeholder]="'frequency.select_a_day' | translate"
              class="mr-2"
              value="Monday"
              [requiredIfVisible]="true"
              [clearOnDestroy]="true"
              [id]="formId + '__DeadlineMonthlyDayofWeek'"
            />
            <naris-button
              size="small"
              icon="close"
              [matTooltip]="'frequency.Remove_weekday_constraint' | translate"
              class="mr-2"
              (clicked)="formGroup.get('Deadline_Monthly_DayofWeek')?.setValue(null)"
              [id]="formId + '__DeadlineRemoveWeekdayConstraint'"
            />
          }
          @if (!formGroup.get('Deadline_Monthly_DayofWeek')?.value) {
            <naris-inline-input
              [control]="getFormControl('Deadline_Monthly_Daynumber')"
              [error]="getErrorForInput('Deadline_Monthly_Daynumber')"
              [min]="1"
              [max]="31"
              type="number"
              value="1"
              [requiredIfVisible]="true"
              [clearOnDestroy]="true"
              [id]="formId + '__DeadlineMonthlyDaynumber'"
            />
            <div class="mr-2">
              {{formGroup.get('Deadline_Monthly_Daynumber')?.value | ordinal}} {{'frequency.day' | translate}}
            </div>
            <naris-button
              [matTooltip]="'frequency.add_weekday_constraint' | translate"
              size="small"
              icon="add"
              class="mr-2"
              (clicked)="formGroup.get('Deadline_Monthly_DayofWeek')?.setValue('Monday')"
              [id]="formId + '__DeadlineAddWeekdayConstraint'"
            />
          }
        }

        @if (formGroup.get('Deadline_FrequencyUnit')?.value === 'Deadline_Years') {
          <div class="mr-2">
            {{'frequency.on_the' | translate}}
          </div>
          @if (formGroup.get('Deadline_Years_DayofWeek')?.value) {
            <naris-inline-input
              [control]="getFormControl('Deadline_Years_Day_xth')"
              [error]="getErrorForInput('Deadline_Years_Day_xth')"
              [min]="1"
              [max]="4"
              type="number"
              value="1"
              [requiredIfVisible]="true"
              [clearOnDestroy]="true"
              [id]="formId + '__DeadlineYearsDayxth'"
            />
            <div class="mr-2">
              {{formGroup.get('Deadline_Years_Day_xth')?.value | ordinal}}
            </div>
            <naris-inline-select
              [control]="getFormControl('Deadline_Years_DayofWeek')"
              [error]="getErrorForInput('Deadline_Years_DayofWeek')"
              [options]="config.periods.Days.options"
              [placeholder]="'frequency.select_a_day' | translate"
              class="mr-2"
              value="Monday"
              [requiredIfVisible]="true"
              [clearOnDestroy]="true"
              [id]="formId + '__Deadline_Years_DayofWeek'"
            />
            <naris-button
              size="small"
              icon="close"
              [matTooltip]="'frequency.remove_weekday_constraint' | translate"
              class="mr-2"
              (clicked)="formGroup.get('Deadline_Years_DayofWeek')?.setValue(null)"
              [id]="formId + '__DeadlineRemoveWeekdayConstraint'"
            />
          }
          @if (!formGroup.get('Deadline_Years_DayofWeek')?.value) {
            <naris-inline-input
              [control]="getFormControl('Deadline_Years_Day')"
              [error]="getErrorForInput('Deadline_Years_Day')"
              [min]="1"
              [max]="31"
              type="number"
              value="1"
              [requiredIfVisible]="true"
              [clearOnDestroy]="true"
              [id]="formId + '__DeadlineYearsDay'"
            />
            <div class="mr-2">
              {{formGroup.get('Deadline_Years_Day')?.value | ordinal}}
            </div>
            <naris-button
              size="small"
              icon="add"
              [matTooltip]="'frequency.add_weekday_constraint' | translate"
              class="mr-2"
              (clicked)="formGroup.get('Deadline_Years_DayofWeek')?.setValue('Monday')"
              [id]="formId + '__DeadlineAddWeekdayConstraint'"
            />
          }
          <div class="mr-2">
            {{'frequency.of' | translate}}
          </div>
          <naris-inline-select
            [control]="getFormControl('Deadline_Years_MonthofYear')"
            [error]="getErrorForInput('Deadline_Years_MonthofYear')"
            [options]="config.periods.Months.options"
            [placeholder]="'frequency.select_a_month' | translate"
            value="January"
            [requiredIfVisible]="true"
            [clearOnDestroy]="true"
            [id]="formId + '__Deadline_Years_MonthofYear'"
          />
        }
      </div>

      @if (getInput('DeadlineTime')) {
        <!-- Deadline Time -->
        <div class="pt-4 pb-4">{{'frequency.with_an_end_time_of' | translate}}</div>
        <naris-timepicker
          [control]="getFormControl('DeadlineTime')"
          [matTooltip]="getErrorForInput('DeadlineTime')"
          [requiredIfVisible]="true"
          [id]="formId + '__DeadlineTime'"
        />
      }
    </ng-container>
  </ng-template>

  <mat-accordion class="frequency">
    @for (option of config.options; track $index) {
      <mat-expansion-panel
        [expanded]="type === option.key"
        [hideToggle]="true"
        [disabled]="false"
        (opened)="setType(option.key)"
        class="option"
        #panel
      >
        <mat-expansion-panel-header class="option-header">
          <mat-panel-title class="option-title">
            <div class="mock-radio-button" [class.active]="panel.expanded"></div>
            {{ option.label | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="panel-body">
          @if (option.key === 'once' && type === option.key) {
            <!-- Once -->
            @if (getInput('StartDate_Once')) {
              <div class="pb-4">
                <div class="pb-4">{{'frequency.with_start_date_on' | translate}}</div>
                <naris-datepicker
                  [control]="getFormControl('StartDate_Once')"
                  [requiredIfVisible]="true"
                  [input]="getInput('StartDate_Once')"
                  [minDate]="restrictions?.minDate"
                  [maxDate]="restrictions?.maxDate"
                  [id]="formId + '__StartDateOnce'"
                />
              </div>
            }
            <div class="pb-4">{{'frequency.with_a_deadline_on' | translate}}</div>
            @if (getInput('Once_Deadline')) {
              <naris-datepicker
                [control]="getFormControl('Once_Deadline')"
                [requiredIfVisible]="true"
                [input]="getInput('Once_Deadline')"
                [minDate]="restrictions?.minDate"
                [maxDate]="restrictions?.maxDate"
                [id]="formId + '__Once_Deadline'"
              />
            }
            @if (getInput('Once_Deadline_Timestamp')) {
              <naris-datepicker
                [control]="getFormControl('Once_Deadline_Timestamp')"
                [requiredIfVisible]="true"
                [input]="getInput('Once_Deadline_Timestamp')"
                [minDate]="restrictions?.minDate"
                [maxDate]="restrictions?.maxDate"
                [id]="formId + '__OnceDeadlineTimestamp'"
              />
            }
          }

          @if (option.key === 'custom' && type === option.key) {
            <!-- Custom -->
            <div class="input-row">
              <!-- Frequency unit switch -->
              @switch (formGroup.get('FrequencyUnit')?.value) {
                @case ('Days') {
                  <naris-inline-input
                    [control]="getFormControl('Daily_Frequency')"
                    [error]="getErrorForInput('Daily_Frequency')"
                    [min]="1"
                    [max]="999"
                    type="number"
                    value="1"
                    [requiredIfVisible]="true"
                    [clearOnDestroy]="true"
                    [id]="formId + '__DailyFrequency'"
                  />
                }
                @case ('Weeks') {
                  <naris-inline-input
                    [control]="getFormControl('Weekly_Frequency')"
                    [error]="getErrorForInput('Weekly_Frequency')"
                    [min]="1"
                    [max]="999"
                    type="number"
                    value="1"
                    [requiredIfVisible]="true"
                    [clearOnDestroy]="true"
                    [id]="formId + '__WeeklyFrequency'"
                  />
                }
                @case ('Months') {
                  <naris-inline-input
                    [control]="getFormControl('Monthly_YMonths')"
                    [error]="getErrorForInput('Monthly_YMonths')"
                    [min]="1"
                    [max]="999"
                    type="number"
                    value="1"
                    [requiredIfVisible]="true"
                    [clearOnDestroy]="true"
                    [id]="formId + '__MonthlyYMonths'"
                  />
                }
                @case ('Years') {
                  <naris-inline-input
                    [control]="getFormControl('Years_Frequency')"
                    [error]="getErrorForInput('Years_Frequency')"
                    [min]="1"
                    [max]="999"
                    type="number"
                    value="1"
                    [requiredIfVisible]="true"
                    [clearOnDestroy]="true"
                    [id]="formId + '__YearsFrequency'"
                  />
                }
              }
              <naris-inline-select
                [control]="getFormControl('FrequencyUnit')"
                [error]="getErrorForInput('FrequencyUnit')"
                [options]="config.units"
                [placeholder]="'frequency.select_unit' | translate"
                class="ml-2 mr-2"
                [id]="formId + '__FrequencyUnit'"
              />

              <!-- Weeks -->
              @if (formGroup.get('FrequencyUnit')?.value === 'Weeks') {
                <div class="mr-2">{{'frequency.on' | translate}}</div>
                <naris-inline-select
                  [control]="getFormControl('Weekly_DayofWeek')"
                  [error]="getErrorForInput('Weekly_DayofWeek')"
                  [options]="config.periods.Days.options"
                  [placeholder]="'frequency.select_a_day' | translate"
                  value="Monday"
                  [requiredIfVisible]="true"
                  [clearOnDestroy]="true"
                  [id]="formId + '__WeeklyDayofWeek'"
                />
              }

              <!-- Months -->
              @if (formGroup.get('FrequencyUnit')?.value === 'Months') {
                <div class="mr-2">{{'frequency.on_the' | translate}}</div>
                @if (formGroup.get('Monthly_DayofWeek')?.value) {
                  <naris-inline-input
                    [control]="getFormControl('Monthly_Day_xth')"
                    [error]="getErrorForInput('Monthly_Day_xth')"
                    [min]="1"
                    [max]="4"
                    type="number"
                    value="1"
                    [requiredIfVisible]="true"
                    [clearOnDestroy]="true"
                    [id]="formId + '__MonthlyDayxth'"
                  />
                  <div class="mr-2">
                    {{formGroup.get('Monthly_Day_xth')?.value | ordinal}}
                  </div>
                  <naris-inline-select
                    [control]="getFormControl('Monthly_DayofWeek')"
                    [error]="getErrorForInput('Monthly_DayofWeek')"
                    [options]="config.periods.Days.options"
                    [placeholder]="'frequency.select_a_day' | translate"
                    class="mr-2"
                    value="Monday"
                    [requiredIfVisible]="true"
                    [clearOnDestroy]="true"
                    [id]="formId + '__MonthlyDayofWeek'"
                  />
                  <naris-button
                    [matTooltip]="'frequency.remove_weekday_constraint' | translate"
                    size="small"
                    icon="close"
                    class="mr-2"
                    (clicked)="formGroup.get('Monthly_DayofWeek')?.setValue(null)"
                    [id]="formId + '__RemoveWeekdayConstraint'"
                  />
                }
                @if (!formGroup.get('Monthly_DayofWeek')?.value) {
                  <naris-inline-input
                    [control]="getFormControl('Monthly_Daynumber')"
                    [error]="getErrorForInput('Monthly_Daynumber')"
                    [min]="1"
                    [max]="31"
                    type="number"
                    value="1"
                    [requiredIfVisible]="true"
                    [clearOnDestroy]="true"
                    [id]="formId + '__MonthlyDaynumber'"
                  />
                  <div class="mr-2">
                    {{formGroup.get('Monthly_Daynumber')?.value | ordinal}} {{'frequency.day' | translate}}
                  </div>
                  <naris-button
                    [matTooltip]="'frequency.add_weekday_constraint' | translate"
                    size="small"
                    icon="add"
                    class="mr-2"
                    (clicked)="formGroup.get('Monthly_DayofWeek')?.setValue('Monday')"
                    [id]="formId + '__AddWeekdayConstraint'"
                  />
                }
              }

              <!-- Years -->
              @if (formGroup.get('FrequencyUnit')?.value === 'Years') {
                <div class="mr-2">{{'frequency.on_the' | translate}}</div>
                @if (formGroup.get('Years_DayofWeek')?.value) {
                  <naris-inline-input
                    [control]="getFormControl('Years_Day_xth')"
                    [error]="getErrorForInput('Years_Day_xth')"
                    [min]="1"
                    [max]="4"
                    type="number"
                    value="1"
                    [requiredIfVisible]="true"
                    [clearOnDestroy]="true"
                    [id]="formId + '__YearsDayxth'"
                  />
                  <div class="mr-2">
                    {{formGroup.get('Years_Day_xth')?.value | ordinal}}
                  </div>
                  <naris-inline-select
                    [control]="getFormControl('Years_DayofWeek')"
                    [error]="getErrorForInput('Years_DayofWeek')"
                    [options]="config.periods.Days.options"
                    [placeholder]="'frequency.select_a_day' | translate"
                    class="mr-2"
                    value="Monday"
                    [requiredIfVisible]="true"
                    [clearOnDestroy]="true"
                    [id]="formId + '__YearsDayofWeek'"
                  />
                  <naris-button
                    [matTooltip]="'frequency.remove_weekday_constraint' | translate"
                    size="small"
                    icon="close"
                    class="mr-2"
                    (clicked)="formGroup.get('Years_DayofWeek')?.setValue(null)"
                    [id]="formId + '__YearsRemoveWeekdayConstraint'"
                  />
                }
                @if (!formGroup.get('Years_DayofWeek')?.value) {
                  <naris-inline-input
                    [control]="getFormControl('Years_Day')"
                    [error]="getErrorForInput('Years_Day')"
                    [min]="1"
                    [max]="31"
                    type="number"
                    value="1"
                    [requiredIfVisible]="true"
                    [clearOnDestroy]="true"
                    [id]="formId + '__YearsDay'"
                  />
                  <div class="mr-2">
                    {{formGroup.get('Years_Day')?.value | ordinal}} {{'frequency.day' | translate}}
                  </div>
                  <naris-button
                    [matTooltip]="'frequency.add_weekday_constraint' | translate"
                    size="small"
                    icon="add"
                    class="mr-2"
                    (clicked)="formGroup.get('Years_DayofWeek')?.setValue('Monday')"
                    [id]="formId + '__YearsAddWeekdayConstraint'"
                  />
                }
                <div class="mr-2">{{'frequency.of' | translate}}</div>
                <naris-inline-select
                  [control]="getFormControl('Years_MonthofYear')"
                  [error]="getErrorForInput('Years_MonthofYear')"
                  [options]="config.periods.Months.options"
                  [placeholder]="'frequency.select_a_month' | translate"
                  class="mr-2"
                  value="January"
                  [requiredIfVisible]="true"
                  [clearOnDestroy]="true"
                  [id]="formId + '__YearsMonthofYear'"
                />
              }
            </div>

            <!-- Optional: Start Time -->
            @if (getInput('StartTime')) {
              <div class="pt-4 pb-4">{{'frequency.with_a_start_time_of' | translate}}</div>
              <naris-timepicker
                [control]="getFormControl('StartTime')"
                [matTooltip]="getErrorForInput('StartTime')"
                [requiredIfVisible]="true"
                [id]="formId + '__StartTime'"
              />
            }

            <!-- Period -->
            <div class="period-spacer">
              <naris-double-datepicker
                [control]="getFormControl('Period')"
                [requiredIfVisible]="true"
                [input]="getInput('Period')"
                [minDate]="rangeRestrictions?.minDate"
                [maxDate]="rangeRestrictions?.maxDate"
                [error]="periodError"
                [id]="formId + '__Period'"
              />
            </div>

            <!-- Optional: Deadline -->
            @if (getInput('Deadline_FrequencyUnit')) {
              <ng-template [ngTemplateOutlet]="deadlineInputs" />
            }
          }
        </div>
      </mat-expansion-panel>
    }
  </mat-accordion>
  @if (getInput('FrequencyEditable')) {
    <app-form-input
      [input]="getInput('FrequencyEditable')"
      [formGroup]="formGroup"
    />
  }
}
