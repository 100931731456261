export const CAT_TYPES = {
  OTHER: 'Other',
  FINANCIAL: 'Financial',
  TIME: 'Time',
  NUMERIC: 'Numeric'
};

export const COLOR_REG = /^#{1}([a-fA-F0-9]{3}|[a-fA-F0-9]{6})$/;

export const SWATCH_COLORS = [
  '#4D4D4D', '#999999', '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00',
  '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115',
  '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF',
  '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33',
  '#0C797D', '#0062B1', '#653294', '#AB149E'
];

export const WORDPRES_BASE_URL = 'https://help.naris-grc.com';
export const WORDPRESS_API_BASE_URL = `${WORDPRES_BASE_URL}/wp-json/wp/v2`;

export const UNIT_VALS = {
  days: 86400000,
  weeks: 604800000,
  months: 2629746000,
  years: 31556952000
};

export const EMPTY_XML = `
  <?xml version="1.0" encoding="UTF-8"?>
  <bpmn:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" id="Definitions_00rm2ud" targetNamespace="http://bpmn.io/schema/bpmn" exporter="bpmn-js (https://demo.bpmn.io)" exporterVersion="7.3.0">
    <bpmn:process id="Process_00pn3ga" isExecutable="false" />
    <bpmndi:BPMNDiagram id="BPMNDiagram_1">
      <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_00pn3ga" />
    </bpmndi:BPMNDiagram>
  </bpmn:definitions>
`;
