import { createClass as t, classCallCheck as e, defineProperty as n } from "../_virtual/_rollupPluginBabelHelpers.js";
import { extend as o } from "../ui-core/util/util.js";
import { EVENT_INTERNAL_CONNECTION as r } from "../ui-core/core/event-constants.js";
import { EVENT_DATA_LOAD_START as i, EVENT_DATA_APPEND_START as s, EVENT_DATA_LOAD_END as c, EVENT_DATA_APPEND_END as a } from "../core/constants.js";
import { Connectors as u, _resetConnectorGeometry as f } from "../ui-core/core/connector/connectors.js";
import { isConnection as l } from "../ui-core/core/component/component.js";
import { CONNECTOR_TYPE_ORTHOGONAL as d, ORTHOGONAL_CONNECTOR_RECALC_STRATEGY_LEGACY as h } from "../ui-core/connector-orthogonal/definitions.js";
import { EVENT_INTERNAL_ANCHOR_CHANGED as v } from "../ui-core/core/constants.js";
var p = new Map();
function m(t, e, n) {
  var o = p.get(t);
  return new o(e, n);
}
function g(t) {
  return p.has(t);
}
var _ = function () {
  function g(t, o) {
    var l = this;
    e(this, g);
    this.surface = t;
    this.options = o;
    n(this, "_connectorEditors", new Map());
    n(this, "activeMode", void 0);
    n(this, "_dataLoading", false);
    t.bind("destroy", function () {
      return l.destroy();
    });
    this.options = this.options || {};
    this.activeMode = this.options.activeMode !== false;
    this.surface.toolkitInstance.bind(i, function () {
      return l._dataLoading = true;
    });
    this.surface.toolkitInstance.bind(s, function () {
      return l._dataLoading = true;
    });
    this.surface.toolkitInstance.bind(c, function () {
      return l._dataLoading = false;
    });
    this.surface.toolkitInstance.bind(a, function () {
      return l._dataLoading = false;
    });
    this.surface.jsplumb.bind(r, function (t) {
      if (!l._dataLoading && l.activeMode) {
        var e = t.connection;
        setTimeout(function () {
          if (e.connector != null) {
            e.connector.edited = true;
            var t = u.exportGeometry(e.connector);
            l.surface.toolkitInstance.setEdgeGeometry(e.edge, t, l.surface);
          }
        });
      }
    });
    this.surface.jsplumb.bind(v, function (t) {
      if (t.connector.type === d) {
        if (t.connector.recalcStrategy === h) {
          return;
        } else {
          f(t.connector);
        }
      }
    });
  }
  t(g, [{
    key: "startEditing",
    value: function t(e, n) {
      var r = this._resolveConnection(e);
      if (r != null) {
        var i = r.edge;
        n = o({}, n || {});
        var s = r.connector.type;
        if (!p.has(s)) {
          throw new Error("No editor available for connector type [" + s + "]");
        } else {
          if (!this._connectorEditors.has(s)) {
            var c = o({}, this.options || {});
            var a = m(s, this.surface, c);
            this._connectorEditors.set(s, a);
          }
          this._connectorEditors.get(s).activate(i, r, n);
        }
      }
    }
  }, {
    key: "stopEditing",
    value: function t() {
      this._connectorEditors.forEach(function (t) {
        return t.deactivate();
      });
    }
  }, {
    key: "clearEdits",
    value: function t(e) {
      var n = this._resolveConnection(e);
      if (n != null) {
        var o = n.connector;
        f(o);
        return true;
      } else {
        return false;
      }
    }
  }, {
    key: "_resolveConnection",
    value: function t(e) {
      if (e == null) {
        return null;
      } else if (typeof e === "string") {
        return this.surface.getRenderedConnection(e);
      } else if (l(e)) {
        return e;
      } else {
        return this.surface.getRenderedConnection(e.getId());
      }
    }
  }, {
    key: "destroy",
    value: function t() {
      this.stopEditing();
      this._connectorEditors.clear();
    }
  }]);
  return g;
}();
export { _ as EdgePathEditor, p as editors, g as isEdgeEditingSupported };