import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { getOrdinal, transformTranslation } from '@core/helpers';
import { DateTime } from 'luxon';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class FrequencyService implements OnDestroy {

  private readonly subs: Subscription[] = [];

  private transOnce: string;
  private transDeadline: string;
  private transDeadlineOn: string;
  private transDay: string;
  private transDays: string;
  private transWorkdays: string;
  private transWeeks: string;
  private transMonths: string;
  private transYears: string;
  private transLater: string;
  private transOn: string;
  private transOnThe: string;
  private transEvery: string;
  private transWorkday: string;
  private transAt: string;
  private transOf: string;
  private transStartingAt: string;
  private transInPeriodOf: string;
  private transDaysId: string;
  private transWeeksId: string;
  private transMonthsId: string;
  private transYearsId: string;

  constructor(private readonly translate: TranslateService, private readonly languageService: LanguageService) {
    this.getTranslations();
  }

  public deadlineString(frequency: any): boolean | string {
    const locale = this.languageService.getAppLang();
    const f = frequency;
    if (!f.Deadline_FrequencyUnit && !f.Once_Deadline_Timestamp) return false;
    const stringParts = [this.transDeadline];
    if (f.FrequencyUnit === 'Once' || f.FrequencyUnit === this.transOnce) stringParts.push(`${this.transOn} <span>${DateTime.fromISO(f.Once_Deadline_Timestamp).toLocaleString(DateTime.DATETIME_MED)}</span>`);
    else {
      switch (f.Deadline_FrequencyUnit) {
        case 'Deadline_Days':
          stringParts.push(`<span>${f.Deadline_Daily_Frequency} ${transformTranslation(this.transDays, f.Deadline_Daily_Frequency)}</span> ${this.transLater}`);
          break;
        case 'Deadline_Workdays':
          stringParts.push(`<span>${f.Deadline_WorkDay_Frequency} ${transformTranslation(this.transWorkdays, f.Deadline_WorkDay_Frequency)}</span> ${this.transLater}`);
          break;
        case 'Deadline_Weeks':
          stringParts.push(`<span>${f.Deadline_Weekly_Frequency} ${transformTranslation(this.transWeeks, f.Deadline_Weekly_Frequency)}</span> ${this.transLater} ${this.transOn} <span>${f.Deadline_Weekly_DayofWeek}</span>`);
          break;
        case 'Deadline_Months':
          if (f.Deadline_Monthly_Frequency) stringParts.push(`<span>${f.Deadline_Monthly_Frequency} ${transformTranslation(this.transMonths, f.Deadline_Monthly_Frequency)}</span> ${this.transLater}`);
          if (f.Deadline_Monthly_YMonths) stringParts.push(`<span>${f.Deadline_Monthly_YMonths} ${transformTranslation(this.transMonths, f.Deadline_Monthly_YMonths)}</span> ${this.transLater}`);
          if (f.Deadline_Monthly_Day_xth) stringParts.push(`${this.transOnThe} <span>${f.Deadline_Monthly_Day_xth}${getOrdinal(+f.Deadline_Monthly_Day_xth, locale )} ${f.Deadline_Monthly_DayofWeek}</span>`);
          if (f.Deadline_Monthly_Daynumber) stringParts.push(`${this.transOnThe} <span>${f.Deadline_Monthly_Daynumber}${getOrdinal(+f.Deadline_Monthly_Daynumber, locale)}</span> ${this.transDay}`);
          break;
        case 'Deadline_Years':
          stringParts.push(`<span>${f.Deadline_Years_Frequency} ${transformTranslation(this.transYears, f.Deadline_Years_Frequency)}</span> ${this.transLater}`);
          if (f.Deadline_Years_Day_xth) stringParts.push(`${this.transOnThe} <span>${f.Deadline_Years_Day_xth}${getOrdinal(+f.Deadline_Years_Day_xth, locale)} ${f.Deadline_Years_DayofWeek}</span>`);
          stringParts.push(`${this.transOf} <span>${f.Deadline_Years_MonthofYear}</span>`);
          break;
      }
      // stringParts.push('later');
    }
    if (f.DeadlineTime) stringParts.push(`${this.transAt} <span>${f.DeadlineTime}</span>`);
    return stringParts.join(' ');
  }

  public frequencyString(frequency: any): string {
    const f = frequency;
    const locale = this.languageService.getAppLang();
    if (f.FrequencyUnit === 'Once' || f.FrequencyUnit.toLowerCase() === this.transOnce.toLowerCase()) {
      // Once
      const stringParts = [`<span>${this.transOnce}</span>`];
      if (f.Once_Deadline) stringParts.push(`${this.transDeadlineOn} <span>${DateTime.fromISO(f.Once_Deadline).toLocaleString(DateTime.DATETIME_MED)}</span>`);
      if (f.StartDate_Once) stringParts.push(`${this.transOn} <span>${DateTime.fromISO(f.StartDate_Once).toLocaleString(DateTime.DATETIME_MED)}</span>`);
      return stringParts.join(' ');
    } else if ((f.FrequencyUnit === 'Days' || f.FrequencyUnit.toLowerCase() === this.transDaysId.toLowerCase()) && (f.Daily_EveryWorkday === true || f.Daily_EveryWorkday === 'true' || f.Daily_EveryWorkday === 1)) {
      // Every workday
      const stringParts = [`${this.transEvery} <span>${this.transWorkday}</span>`];
      if (f.StartTime) stringParts.push(`${this.transStartingAt} <span>${f.StartTime}</span>`);
      stringParts.push(`${this.transInPeriodOf} <span>${this.frequencyPeriod(frequency)}</span>`);
      return stringParts.join(' ');
    } else {
      // Every...
      const stringParts = [this.transEvery];
      switch (f.FrequencyUnit.toLowerCase()) {
        case 'days':
        case this.transDaysId.toLowerCase():
          stringParts.push(`<span>${f.Daily_Frequency} ${transformTranslation(this.transDays, f.Daily_Frequency)}</span>`);
          break;
        case 'weeks':
        case this.transWeeksId.toLowerCase():
          stringParts.push(`
            <span>${f.Weekly_Frequency} ${transformTranslation(this.transWeeks, f.Weekly_Frequency)}</span>
            ${this.transOn} <span>${this.translate.instant(f.Weekly_DayofWeek)}</span>
          `);
          break;
        case 'months':
        case this.transMonthsId.toLowerCase():
          stringParts.push(`<span>${f.Monthly_YMonths} ${transformTranslation(this.transMonths, f.Monthly_YMonths)}</span>`);
          if (f.Monthly_Day_xth) stringParts.push(`${this.transOnThe} <span>${f.Monthly_Day_xth}${getOrdinal(+f.Monthly_Day_xth, locale)} ${this.translate.instant(f.Monthly_DayofWeek)}</span>`);
          if (f.Monthly_Daynumber) stringParts.push(`${this.transOnThe} <span>${f.Monthly_Daynumber}${getOrdinal(+f.Monthly_Daynumber, locale)}</span> ${this.transDay}`);
          break;
        case 'years':
        case this.transYearsId.toLowerCase():
          stringParts.push(`<span>${f.Years_Frequency} ${transformTranslation(this.transYears, f.Years_Frequency)}</span>`);
          if (f.Years_Day_xth) stringParts.push(`${this.transOnThe} <span>${f.Years_Day_xth}${getOrdinal(+f.Years_Day_xth, locale)} ${this.translate.instant(f.Years_DayofWeek)}</span>`);
          if (f.Years_Day) stringParts.push(`${this.transOnThe} <span>${f.Years_Day}${getOrdinal(+f.Years_Day, locale)}</span>`);
          if (f.Years_MonthofYear) stringParts.push(`${this.transOf} <span>${this.translate.instant(f.Years_MonthofYear)}</span>`);
          break;
      }
      if (f.StartTime) stringParts.push(`${this.transStartingAt} <span>${f.StartTime}</span>`);
      stringParts.push(`${this.transInPeriodOf} <span>${this.frequencyPeriod(frequency)}</span>`);
      return stringParts.join(' ');
    }
  }

  public frequencyPeriod(frequency: any) {
    const start = DateTime.fromISO(frequency.Period.StartDate_TimeStamp || frequency.Period?.[0]?.StartDate_TimeStamp);
    const end = DateTime.fromISO(frequency.Period.EndDate_TimeStamp || frequency.Period?.[0]?.EndDate_TimeStamp);
    return `${start.toLocaleString(DateTime.DATE_MED)} - ${end.toLocaleString(DateTime.DATE_MED)}`;
  }

  private getTranslations() {
    this.subs.push(
      this.translate.get('frequency.with_deadline').subscribe((res: string) => this.transDeadline = res),
      this.translate.get('frequency.once').subscribe((res: string) => this.transOnce = res),
      this.translate.get('frequency.day').subscribe((res: string) => this.transDay = res),
      this.translate.get('frequency.days').subscribe((res: string) => this.transDays = res),
      this.translate.get('frequency.workday').subscribe((res: string) => this.transWorkday = res),
      this.translate.get('frequency.workdays').subscribe((res: string) => this.transWorkdays = res),
      this.translate.get('frequency.weeks').subscribe((res: string) => this.transWeeks = res),
      this.translate.get('frequency.months').subscribe((res: string) => this.transMonths = res),
      this.translate.get('frequency.years').subscribe((res: string) => this.transYears = res),
      this.translate.get('frequency.later').subscribe((res: string) => this.transLater = res),
      this.translate.get('frequency.on').subscribe((res: string) => this.transOn = res),
      this.translate.get('frequency.on_the').subscribe((res: string) => this.transOnThe = res),
      this.translate.get('frequency.with_a_deadline_on').subscribe((res: string) => this.transDeadlineOn = res),
      this.translate.get('frequency.every').subscribe((res: string) => this.transEvery = res),
      this.translate.get('frequency.at').subscribe((res: string) => this.transAt = res),
      this.translate.get('frequency.of').subscribe((res: string) => this.transOf = res),
      this.translate.get('frequency.starting_at').subscribe((res: string) => this.transStartingAt = res),
      this.translate.get('frequency.in_the_period_of').subscribe((res: string) => this.transInPeriodOf = res),
      this.translate.get('days').subscribe((res: string) => this.transDaysId = res),
      this.translate.get('weeks').subscribe((res: string) => this.transWeeksId = res),
      this.translate.get('months').subscribe((res: string) => this.transMonthsId = res),
      this.translate.get('years').subscribe((res: string) => this.transYearsId = res)
    );
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => {
      if (!sub.closed) sub.unsubscribe();
    });
  }
}
