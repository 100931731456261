<naris-input
  class="input"
  [control]="control"
  [minLength]="4"
  [maxLength]="7"
  pattern="^#{1}([a-fA-F0-9]{3}|[a-fA-F0-9]{6})$"
  [placeholder]="input.placeholder || input.label"
  [prependColor]="pickedColor"
  (focusedChange)="showPicker = true"
  (togglePicker)="showPicker = !showPicker"
  cdkOverlayOrigin
  #colorInputOverlay="cdkOverlayOrigin"
  [attr.data-test-id]="'color-input__' + id"
/>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="colorInputOverlay"
  [cdkConnectedOverlayOpen]="showPicker"
>
  <naris-color-picker
    class="picker"
    (colorPicked)="onColorPicked($event)"
    (closed)="showPicker = false"
    [chosenHue]="chosenHue"
  />
</ng-template>

