import { InjectionToken } from '@angular/core';
import { is, isNot, has, hasNot, equals, and } from '@core/helpers';
import { MatrixDialogComponent } from '@shared/components/matrix-dialog/matrix-dialog.component';
import type { ComponentType } from '@angular/cdk/portal';
import type { KnowledgebaseDialogComponent } from '@features/knowledgebase/knowledgebase-edit/knowledgebase-dialog/knowledgebase-dialog.component';
import type { KnowledgebaseBatchComponent } from '@features/knowledgebase/knowledgebase-edit/knowledgebase-batch/knowledgebase-batch.component';
import type { KnowledgebaseCommentComponent } from '@features/knowledgebase/knowledgebase-review/knowledgebase-comment/knowledgebase-comment.component';
import type { FormLookupComponent } from '@core/form/form-lookup/form-lookup.component';
// import type { DiagramComponent } from '@shared/components/diagram/diagram.component';
import type { JSONCompareComponent } from '@shared/components/json-compare/json-compare.component';
import type { TreeviewCopyFormComponent } from '@shared/components/treeview/treeview-copy-form/treeview-copy-form.component';
import type { InfoDialogComponent } from '@shared/components/info-dialog/info-dialog.component';
import type { ProcessViewerDialogComponent } from '@shared/components/process-viewer-dialog/process-viewer-dialog.component';

export const REQUIRED = 'required';
export const REQUIRED_TRUE = 'requiredTrue';
export const EMAIL = 'email';
export const MIN = 'min';
export const MAX = 'max';
export const MIN_LENGTH = 'minLength';
export const MAX_LENGTH = 'maxLength';
export const PATTERN = 'pattern';

export const OPS = {
  greaterThan: ' >',
  lessThan: ' <',
  greaterThanOrEqualTo: ' >=',
  lessThanOrEqualTo: ' <='
};

export const FORM_LOOKUP_TOKEN = new InjectionToken<ComponentType<FormLookupComponent>>('FORM_LOOKUP_TOKEN');
export const INFO_DIALOG_TOKEN = new InjectionToken<ComponentType<InfoDialogComponent>>('INFO_DIALOG_TOKEN');
export const PROCESS_VIEWER_DIALOG_TOKEN = new InjectionToken<ComponentType<ProcessViewerDialogComponent>>('PROCESS_VIEWER_DIALOG_TOKEN');
// export const DIAGRAM_TOKEN = new InjectionToken<ComponentType<DiagramComponent>>('DIAGRAM_TOKEN');
export const COMPARE_TOKEN = new InjectionToken<ComponentType<JSONCompareComponent>>('COMPARE_TOKEN');
export const TREEVIEW_COPY_TOKEN = new InjectionToken<ComponentType<TreeviewCopyFormComponent>>('TREEVIEW_COPY_TOKEN');
export const KNOWLEDGEBASE_TOKEN = new InjectionToken<ComponentType<KnowledgebaseDialogComponent>>('KNOWLEDGEBASE_TOKEN');
export const KNOWLEDGEBASE_BATCH_TOKEN = new InjectionToken<ComponentType<KnowledgebaseBatchComponent>>('KNOWLEDGEBASE_BATCH_TOKEN');
export const KNOWLEDGEBASE_COMMENT_TOKEN = new InjectionToken<ComponentType<KnowledgebaseCommentComponent>>('KNOWLEDGEBASE_COMMENT_TOKEN');
export const MATRIX_DIALOG_TOKEN = new InjectionToken<ComponentType<MatrixDialogComponent>>('MATRIX_DIALOG_TOKEN');

export const CON_FUNCS = {is, isNot, has, hasNot, equals, and} as Record<string, (a: any, b: any) => boolean>;

export const DOC_URL = '/b2c/documents';
export const DOC_HASH_URL = `${DOC_URL}/download-document`;
export const DOC_TRIGGER = '@';
