import { BehaviorSubject } from 'rxjs';
import { FormInput, FilterChip } from '@core/classes';
import { DateTime } from 'luxon';
import { IMappedInput } from '@core/models';

export const handleFilter = (activeFilters: FilterChip[], _filterChips$: BehaviorSubject<FilterChip[]>, value: any, input: FormInput) => {
  // Remove all filters for this input
  activeFilters = activeFilters.filter((f: any) => f.input.id !== input.id);
  // If there is no value, emit & return
  if (!value) {
    _filterChips$.next(activeFilters); return;
  }
  const mappedInput = {id: input.id, controlType: input.controlType, value: input.value, multiple: input.multiple} as IMappedInput;
  // If the input is an autocomplete, filtering should be handled differently
  if (input.controlType === 'autocomplete') {
    if (Array.isArray(value)) {
      value.forEach(obj => {
        const chip = new FilterChip(input.label, obj.label, obj.value, mappedInput);
        activeFilters.push(chip);
      });
    } else {
      const chip = new FilterChip(input.label, value.label, value.value, mappedInput);
      activeFilters.push(chip);
    }
    // If array, go through the options and create chips
  } else if (Array.isArray(value)) {
    value.forEach((val: string) => {
      const option = input.options?.find((opt: any) => opt.value === val);
      const chipValue = option?.code || option?.value || option?.key;
      const chip = new FilterChip(input.label, option?.label, chipValue, mappedInput);
      activeFilters.push(chip);
    });
  // Get date from date object
  } else if (input.controlType === 'date') {
    const formattedDate = DateTime.fromISO(value).toFormat('dd-MM-yyyy');
    const chip = new FilterChip(input.label, formattedDate, value, mappedInput);
    activeFilters.push(chip);
  // Fall back to normal string values (for strings and numbers)
  } else if (input.controlType === 'radio') {
    const foundOption = input.options?.find((option: any) => option.value === value);
    const chip = new FilterChip(input.label, value, foundOption?.key || value, mappedInput);
    activeFilters.push(chip);
  } else {
    const chip = new FilterChip(input.label, value, value, mappedInput);
    activeFilters.push(chip);
  }
  // TODO: Add date support?
  // Emit changes
  _filterChips$.next(activeFilters);
  return activeFilters;
};
