import { Injectable, Injector } from '@angular/core';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpService } from '@core/services/http.service';
import { ContributionsService } from '@core/services/contributions.service';
import { UserService } from '@core/services/user.service';
import { BeinformedService } from '@core/services/beinformed.service';
import { FormService } from '@core/services/form.service';
import { NARIS_MODULE_NAMES, CONFIG_BASE_URL } from '@core/constants';
import { IListModel } from '@core/models';
import { NarisBreadcrumbService } from './breadcrumb.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService extends HttpService {
  public properties: IListModel;
  public components?: any[];
  public hasGeneralPanel = false;

  constructor(
    _injector: Injector,
    private readonly contributionsService: ContributionsService,
    private readonly beinformedService: BeinformedService,
    private readonly userService: UserService,
    private readonly formService: FormService,
    private readonly narisBreadcrumb: NarisBreadcrumbService
  ) {
    super(_injector);
  }

  public fetchOrganization() {
    const organizationEndpoint = `/configuration/organization/${this.userService.organizationID}`;
    return this.beinformedService.fetchResponseWithContributions(organizationEndpoint).pipe(
      map(result => ({
        attributes: this.beinformedService.extractAttributes(result),
        panels: this.beinformedService.extractPanels(result)
      })),
      tap(({ panels }) => {
        // Save information for panels
        this.components = panels?.map((p: any) => {
          const panelEndpoint = p.href.split('/').pop();
          const url = NARIS_MODULE_NAMES.includes(p.name)
            ? `${CONFIG_BASE_URL}/${panelEndpoint}`
            : `${CONFIG_BASE_URL}/general/${panelEndpoint}`;
          this.narisBreadcrumb.add(url, p.label);
          return { url, resourcetype: p.resourcetype, href: p.href, name: p.name, layouthint: p.layouthint };
        });
      })
    );
  }

  /**
   * Returns endpoint within the organization context
   * @param endpoint BeInformed API endpoint
   */
  public organizationEndpoint(endpoint: string) {
    return `/configuration/organization/${this.userService.organizationID + endpoint}`;
  }

  /**
   * Opens a form within organization context
   * @param endpoint BeInformed API endpoint
   */
  public openForm(endpoint: string) {
    const href = `/configuration/organization/${this.userService.organizationID + endpoint}`;
    this.formService.open(href);
  }

  public getNavigationItems() {
    return forkJoin([
      this.get(`/configuration/organization/${this.userService.organizationID}`),
      this.contributionsService.getContributions('/contributions/configuration/organization/(case-id)')
    ]);
  }
}
