import { Component } from '@angular/core';

@Component({
  selector: 'app-easter-egg',
  standalone: true,
  imports: [],
  templateUrl: './easter-egg.component.html',
  styleUrl: './easter-egg.component.scss'
})
export class EasterEggComponent {

}
