import { classCallCheck as t, defineProperty as r } from "../_virtual/_rollupPluginBabelHelpers.js";
var e = function e(i, o) {
  t(this, e);
  this.gridProfile = o;
  r(this, "afterGroupCollapse", void 0);
  r(this, "afterGroupExpand", void 0);
  r(this, "afterGroupResize", void 0);
  r(this, "afterGroupShrink", void 0);
  r(this, "afterGroupGrow", void 0);
  r(this, "afterLayout", void 0);
  r(this, "afterDrag", void 0);
  r(this, "constant", void 0);
  r(this, "constrainToViewport", void 0);
  r(this, "repositionDraggedElement", void 0);
  r(this, "grid", void 0);
  if (i != null) {
    this.afterDrag = i.afterDrag === true;
    this.constant = i.constant === true;
    this.constrainToViewport = i.constrainToViewport === true;
    this.repositionDraggedElement = i.repositionDraggedElement === true;
    this.afterLayout = i.afterLayout === true;
    this.afterGroupResize = i.afterGroupResize === true;
    this.afterGroupShrink = i.afterGroupShrink === true || this.afterGroupResize && i.afterGroupShrink !== false;
    this.afterGroupGrow = i.afterGroupGrow === true || this.afterGroupResize && i.afterGroupGrow !== false;
    this.afterGroupCollapse = i.afterGroupCollapse === true;
    this.afterGroupExpand = i.afterGroupExpand === true;
    if (this.constant && this.afterDrag) {
      this.constant = false;
    }
    if (this.constant) {
      this.repositionDraggedElement = false;
    }
  }
};
export { e as MagnetizeProfile };