import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { BaseChartDirective, NgChartsModule } from 'ng2-charts';
import { ChartConfiguration, ChartEvent, ChartTypeRegistry, TooltipItem } from 'chart.js';
// eslint-disable-next-line @typescript-eslint/naming-convention
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { ChartLegendColors, ChartTypes, StatusColor } from '@core/enums';
import { BeinformedService, FormService, LanguageService } from '@core/services';
import { FormInput, RadioInput, FilterChip } from '@core/classes';
import { clone, handleFilter } from '@core/helpers';
import { STATUS_CONFIG } from '@core/constants';
import { UNIT_VALS } from '@core/constants/core-constants';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { MatChipListbox, MatChip } from '@angular/material/chips';
import { MatTooltip } from '@angular/material/tooltip';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { NgClass, NgStyle, AsyncPipe } from '@angular/common';
import { CompareObjPipe } from '@shared/pipes/compare-obj.pipe';
import { ButtonComponent } from '../../elements/button/button.component';
import { FormInputComponent } from '../../../core/form/form-input/form-input.component';
import { EmptyStateComponent } from '../empty-state/empty-state.component';
import { IconComponent } from '../../elements/icon/icon.component';
import { SelectComponent } from '../../elements/select/select.component';
import { DividerComponent } from '../../elements/divider/divider.component';
import type { IFormAnchorElement, IFormResult, IInputConfig, IInputOption, IWidgetConfig, TChartThis, TFormAnchorDynamicElements } from '@core/models';
import type { KeyValue } from '@angular/common';

@Component({
  selector: 'naris-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss'],
  standalone: true,
  imports: [MatChipListbox, MatChip, ButtonComponent, MatTooltip, MatMenuTrigger, MatMenu, FormsModule, ReactiveFormsModule, FormInputComponent, NgClass, NgStyle, NgChartsModule, EmptyStateComponent, IconComponent, SelectComponent, MatMenuItem, DividerComponent, AsyncPipe, CompareObjPipe, TranslateModule]
})
export class GraphComponent implements OnInit, OnDestroy {

  @Input() public layouthint: string[];
  @Input() public colors: any[];
  @Input() public showValuesInChart = false;
  @Input() public href: string;
  @Input() public editMode = false;
  @Input() public widgetConfig: IWidgetConfig;
  @Output() public readonly widgetConfigChanged = new EventEmitter<any>();

  // @ViewChild('graphCanvas') public graphCanvas: ElementRef<HTMLCanvasElement>;
  // @ViewChild(BaseChartDirective, { static: true }) public chart: BaseChartDirective;
  @ViewChild(BaseChartDirective) public chart: BaseChartDirective | undefined;

  public chartData: any[] | null;
  public chartLabels: string[] = [];
  public originalChartLabels: string[] = [];
  public solidColorTypes = ['bar', 'horizontalBar'];
  public chartColors: { backgroundColor: string; borderColor: string; hoverBackgroundColor: string; hoverBorderColor: string }[] = [];
  public pieChartColors: { backgroundColor: string[] }[] = [];
  public chartLegend = true;
  public currentLevel = 1;
  public isAlpha = false;
  public isAsc = false;
  public clear = false;
  public newData: TFormAnchorDynamicElements[];
  public filteredData: TFormAnchorDynamicElements[] | null;
  public pieChartPlugins = [DataLabelsPlugin];
  public chartType: ChartTypes = ChartTypes.horizontalBar;
  public label: string;
  public chartHeight: number;
  public chartWidth: number | null;
  public dataTypes: any[] = [];
  public dataTypeControl = new FormControl();
  public selectedParentId: string[] = [];
  public chartOptions: ChartConfiguration['options'];
  public legendKey: string;
  public histogramDataType: string | null;
  public histogramTimeUnit = 'graph.days';
  private timeUnit: keyof typeof UNIT_VALS = 'days';
  public filterMeta: RadioInput[] = [];
  public filterForm = new FormGroup({});
  public activeFilters?: FilterChip[] = [];
  public activeOption: string | null = null;
  public previousWidgetConfig: IWidgetConfig;
  public newWidgetConfig: IWidgetConfig;
  public barChartPlugins = [DataLabelsPlugin];
  public isOverview = false;

  public contextFilterInput: any;
  public contextFilterForm = new FormGroup<Record<string, FormControl>>({});
  public contextFilterObj: IFormAnchorElement | undefined; 

  private isInitialized = false;
  private chartLegendColor: ChartLegendColors;
  private filterLegend: { options: IInputOption[] } | null;
  private readonly subs: Subscription[] = [];
  private readonly _filterChips$ = new BehaviorSubject<FilterChip[]>([]);
  private readonly nameString = 'Name';
  private readonly valueString = 'value';
  private objectType: string;
  private filterKey: string | null;

  private chartDataTimer: NodeJS.Timeout;

  private readonly dictionary = new Map<string, string>();

  private chartUnit: keyof typeof UNIT_VALS = 'days';
  private locale: string;

  private isMultipleBar = false;

  get filterChips$(): Observable<FilterChip[]> {
    return this._filterChips$.asObservable();
  }

  constructor(
    private readonly renderer: Renderer2,
    private readonly beinformedService: BeinformedService,
    private readonly formService: FormService,
    private readonly languageService: LanguageService,
    private readonly translate: TranslateService,
    private readonly router: Router
  ) {}

  public ngOnInit() {
    if (!this.router.url.startsWith('/dashboard')) this.isOverview = true;
    if (!!this.href) this.getData(this.href);
    this.locale = this.languageService.getAppLang();
  }

  private getData(href: string) {
    this.beinformedService.fetchForm(href, false, {}).subscribe({
      next: res => {
        this.layouthint = res.contributions.layouthint;
        this.setLayouthintData();
        this.label = res.contributions?.label;
        const data = res.data.error?.formresponse.missing?.anchors[0].elements[0].dynamicschema;
        this.newData = data?.map(x => x.elements) || [];
        if (this.newData.length > 0) {
          const dataTypeKey = Object.keys(this.newData[0]).find(x => x.toLowerCase() === 'consequencetype');
          this.histogramDataType = this.newData.length > 0 && !!dataTypeKey ? (this.newData[0][dataTypeKey] as string).toLowerCase() : null;
        }
        if (!!this.filterKey) this.setContextFilter(res);
        const objKey = Object.keys(res.contributions.objects)[0];
        this.objectType = Object.keys(res?.contributions?.objects?.[objKey]?.attributes?.[0])?.[0];
        const dynamicschema = res.data.error?.formresponse.dynamicschema;
        const legendItem = dynamicschema?.[`${this.objectType}.${this.legendKey}`];
        this.filterLegend = !!legendItem ? {options: legendItem} : null;
        const schemaKeys = !!dynamicschema ? Object.keys(dynamicschema) : null;
        const filters: Record<string, any>[] = [];
        schemaKeys?.forEach(key => {
          const filterName = key.split('.')[1];
          if (!filterName.includes(this.legendKey)) filters.push({[key]: dynamicschema?.[key]});
        });
        filters.forEach(filter => {
          const label = Object.keys(filter)[0].replace('.', '_');
          const element = {label: Object.keys(filter)[0].replace(`${this.objectType}.`, ''), id: label};
          const options = filter[Object.keys(filter)[0]] as IInputOption[];
          const mappedOptions = options.map(option => ({key: option.label, value: option.code} as IInputOption));
          const input = new RadioInput({ ...element, options: mappedOptions } as IInputConfig);
          this.filterMeta.push(input);
          input.value = '';
          const control = this.formService.toFormField(input);
          this.filterForm.addControl(label, control);
          this.subs.push(
            control.valueChanges.subscribe((val: string) => {
              this.selectedParentId = [];
              this.activeFilters = handleFilter(this.activeFilters || [], this._filterChips$, val, input);
            })
          );
          if (!!input.options && input.options.length > 0)
            setTimeout(() => {
              const foundFilter = this.widgetConfig?.filters?.find(x => x.input.id === input.id);
              if (!!foundFilter && !!foundFilter.value) control.setValue(foundFilter.value);
              else control.setValue(input.options?.[0].value);
            });
        });
        this.subs.push(this._filterChips$.subscribe(filterChip => {
          this.previousWidgetConfig = clone(this.widgetConfig);
          this.newWidgetConfig = clone(this.widgetConfig);
          if (!this.isMultipleBar) {
            this.preFilterData(filterChip);
          }
          if (this.editMode) this.newWidgetConfig = {...this.newWidgetConfig, filters: filterChip};
        }));
        if (this.isMultipleBar && !!data) {
          const labelContributions = res?.contributions?.objects?.Attributes?.attributes?.[0][this.objectType].children;
          this.chartLabels = data.map(el => el.elements.Name) as string[] || [];
          data.forEach(item => {
            const keys = Object.keys(item.elements).filter(keyName => keyName !== 'Name');
            keys.forEach(key => {
              const itemLabel = labelContributions?.find(cont => Object.keys(cont)?.[0] === key)?.[key].label;
              let dataItem = this.chartData?.find(chartDataItem => chartDataItem.label === itemLabel);
              if (!!dataItem) {
                dataItem.data.push(item.elements[key] as number);
              } else {
                if (!this.chartData) {
                  this.chartData = [];
                }
                dataItem = {} as { data: number[]; label: string };
                dataItem.data = [item.elements[key] as number];
                dataItem.label = itemLabel;
                dataItem.barThickness = 20;
                this.chartData?.push(dataItem);
              }
            });
          });


          this.setSolid();
          this.generateColors();

          const heightCalc = this.chartLabels.length * 80 + 30;
          this.chartHeight = this.chartType === ChartTypes.horizontalBar ? heightCalc : 400;
        } else {
          this.changeGraphStyle(this.chartType);
          this.preFilterData();
        }
      },
      error: () => {
        // eslint-disable-next-line no-console
        console.log('Error while loading filters');
      }
    });
  }

  private setLayouthintData() {
    if (!!this.layouthint) {
      const foundIndex = this.layouthint.findIndex(x => x.includes('graph'));
      if (foundIndex !== -1) {

        const splittedValues = this.layouthint[foundIndex].split(':');
        if (splittedValues.includes('horizontalMultipleBar')) {
          this.isMultipleBar = true;
          this.chartType = ChartTypes.horizontalBar;
        } else {
          this.chartType = splittedValues[1]?.trim() as ChartTypes;
        }
        if (this.chartType !== ChartTypes.histogram) this.legendKey = splittedValues[2]?.trim();
        else this.histogramDataType = splittedValues[2]?.trim();
        this.chartLegendColor = splittedValues[3]?.trim() as ChartLegendColors;
        this.filterKey = splittedValues[4]?.trim();
      }
    }
  }

  private preFilterData(filters?: FilterChip[]) {
    let filteredData = this.newData;
    if (!!filters) {
      filters.forEach(filter => {
        const filterValue = filter['value'] === 'NULL' ? null : filter['value'];
        filteredData = filteredData.filter(data => data[filter['label'].replace(`${this.objectType}.`, '')] === filterValue);
      });
    }
    const filteredElements = filteredData.filter(x => x.ParentID === (this.selectedParentId[this.selectedParentId.length - 1] ?? null));
    filteredElements.forEach(element => {
      const dataType = {label: element.Type, code: element.Type};
      if (!this.dataTypes.find(type => type.code === element.Type)) this.dataTypes.push(dataType);
      element.value = element.Count;
    });
    this.chartLabels = filteredElements.map(x => x[this.nameString]) as [];
    this.originalChartLabels = this.chartLabels;
    switch (this.histogramDataType) {
      case 'time': this.setTimeLabels('days'); break;
      case 'financial': this.setFinancialLabels(); break;
      default: break;
    }
    this.setSolid();
    this.generateColors();
    this.setSort(false, false, false, filteredElements);
    this.isInitialized = true;
  }

  private setTimeLabels(unit: keyof typeof UNIT_VALS) {
    if (this.chartType !== ChartTypes.histogram) return;
    this.chartUnit = unit;
    this.chartLabels = this.originalChartLabels.map(x => {
      const newValue = this.getLocaleValue(+x / UNIT_VALS[unit]);
      this.dictionary.set(newValue, x);
      return newValue;
    });
  }

  private getLocaleValue(value: number) {
    const translatedUnit = this.translate.instant(this.histogramTimeUnit);
    return `${value.toLocaleString(this.locale, {maximumFractionDigits: 1})} ${translatedUnit}`;
  }

  private setFinancialLabels() {
    this.chartLabels = this.originalChartLabels.map(x => {
      let newValue = this.chartType === ChartTypes.horizontalBar || this.chartType === ChartTypes.bar ? x : (+x).toLocaleString(this.locale, {style: 'currency', currency: 'EUR'});
      const intValue = parseInt(x);
      if (!!intValue) {
        if (intValue < 1000 && intValue !== 0) {
          newValue = (1000).toLocaleString(this.locale, {style: 'currency', currency: 'EUR'});
        } else if (intValue >= 1000) {
          newValue = (Math.round(intValue / 1000) * 1000).toLocaleString(this.locale, {style: 'currency', currency: 'EUR'});
        }
      }
      this.dictionary.set(newValue, x);
      return newValue;
    });
  }

  private getTotalValues(data: TFormAnchorDynamicElements): number | null {
    const children = this.newData.filter(x => x.ParentID === data.ID && x[this.legendKey] === data[this.legendKey]);
    let returnValue = 0;
    if (!!children.length) {
      children.forEach(child => returnValue += this.getTotalValues(child)!);
      const value = returnValue + +data.Count;
      return value === 0 ? null : value;
    } else return data.Count as number;
  }

  private setSolid() {
    if (this.chartLegendColor === ChartLegendColors.boolean)
      this.chartColors = [
        {backgroundColor: 'rgba(255,77,91,0.75)', borderColor: 'rgb(255,77,91)', hoverBackgroundColor: 'rgba(255,77,91,0.5)', hoverBorderColor: 'rgba(255,77,91,0.75)'},
        {backgroundColor: 'rgba(57,191,124,0.75)', borderColor: 'rgb(57,191,124)', hoverBackgroundColor: 'rgba(57,191,124,0.5)', hoverBorderColor: 'rgba(57,191,124,0.75)'},
        {backgroundColor: 'rgba(64,127,255,0.75)', borderColor: 'rgb(64,127,255)', hoverBackgroundColor: 'rgba(64,127,255,0.5)', hoverBorderColor: 'rgba(64,127,255,0.75)'}
      ];
    else if (this.chartLegendColor === ChartLegendColors.state) {
      this.chartColors = [];
      this.filterLegend?.options.forEach(option => {
        const foundStatus = STATUS_CONFIG[option.code || 'Initial'];
        if (!!foundStatus) this.chartColors.push(this.getStatusColor(foundStatus.color));
      });
    } else this.chartColors = [{backgroundColor: 'rgba(64, 127, 255,0.75)', borderColor: 'rgb(64, 127, 255)', hoverBackgroundColor: 'rgba(64, 127, 255,0.5)', hoverBorderColor: 'rgba(64, 127, 255,0.75)'}];
  }

  private getStatusColor(statusColor: StatusColor): { backgroundColor: string; borderColor: string; hoverBackgroundColor: string; hoverBorderColor: string } {
    let returnValue = { backgroundColor: 'rgba(134, 156, 179, 0.75)', borderColor: 'rgb(134, 156, 179)', hoverBackgroundColor: 'rgba(134, 156, 179, 0.5)', hoverBorderColor: 'rgba(134, 156, 179, 0.75)' };
    switch (statusColor) {
      case StatusColor.success: returnValue = { backgroundColor: 'rgba(57,191,124, 0.75)', borderColor: 'rgb(57,191,124)', hoverBackgroundColor: 'rgba(57,191,124, 0.5)', hoverBorderColor: 'rgba(57,191,124, 0.75)' }; break;
      case StatusColor.warning: returnValue = { backgroundColor: 'rgba(255, 143, 64, 0.75)', borderColor: 'rgb(255, 143, 64)', hoverBackgroundColor: 'rgba(255, 143, 64, 0.5)', hoverBorderColor: 'rgba(255, 143, 64, 0.75)' }; break;
      case StatusColor.danger: returnValue = { backgroundColor: 'rgba(255, 77, 91, 0.75)', borderColor: 'rgb(255, 77, 91)', hoverBackgroundColor: 'rgba(255, 77, 91, 0.5)', hoverBorderColor: 'rgba(255, 77, 91, 0.75)' }; break;
      case StatusColor.primary: returnValue = { backgroundColor: 'rgba(64, 127, 255, 0.75)', borderColor: 'rgb(64, 127, 255)', hoverBackgroundColor: 'rgba(64, 127, 255, 0.5)', hoverBorderColor: 'rgba(64, 127, 255, 0.75)' }; break;
      case StatusColor.default: returnValue = { backgroundColor: 'rgba(134, 156, 179, 0.75)', borderColor: 'rgb(134, 156, 179)', hoverBackgroundColor: 'rgba(134, 156, 179, 0.5)', hoverBorderColor: 'rgba(134, 156, 179, 0.75)' }; break;
      default: break;
    }
    return returnValue;
  }

  public chartClicked(event: { active: Record<string, any>[]; event: ChartEvent }) {
    if (!event.active || event.active.length < 1) return;
    const clickedIndex = event.active[0].index;
    const itemName = this.chartLabels[clickedIndex];
    const legendValue = this.filterLegend?.options[event.active[0].datasetIndex]?.code;
    const foundSelected = this.filteredData?.find(x => x[this.nameString] === itemName && x[this.legendKey] === legendValue);
    if (!!foundSelected) {
      this.selectedParentId.push(foundSelected.ID as string);
      const data = this.newData.filter(x => x.ParentID === foundSelected.ID);
      if (!data || data.length < 1) {
        this.selectedParentId.pop();
        return;
      }
      this.getValuesAndSetData(data);
    }
  }

  public save() {
    const dataUrl = this.chart?.toBase64Image();
    const a = this.renderer.createElement('a');
    a.download = `${this.label} - ${this.chartType}.png`;
    a.rel = 'noopener';
    a.href = dataUrl;
    a.dispatchEvent(new MouseEvent('click'));
  }

  public drillUp() {
    const foundSelected = this.filteredData?.[0];
    if (!!foundSelected) {
      const parent = this.newData.find(x => x.ID === foundSelected.ParentID);
      if (!parent) return;
      const data = this.newData.filter(x => x.ParentID === parent.ParentID);
      let filteredData = data;
      if (!!this.activeFilters) {
        this.activeFilters.forEach(filter => {
          const filterValue = filter['value'] === 'NULL' ? null : filter['value'];
          filteredData = filteredData.filter(x => x[filter['label']] === filterValue);
        });
      }
      if (!filteredData) return;
      this.selectedParentId.pop();
      this.getValuesAndSetData(filteredData);
    }
  }

  private getValuesAndSetData(data: TFormAnchorDynamicElements[]) {
    data.forEach(element => element.value = this.getTotalValues(element)!);
    this.sort(data);
  }

  private setData(data: TFormAnchorDynamicElements[] | null) {
    this.filteredData = data;
    const newData = data?.map(x => x[this.valueString]);
    if (this.chartType === ChartTypes.scatter) { // hard coded data, no view available yet
      this.chartData = [
        {
          data: [
            { x: 1, y: 1, label: 'Koe' },
            { x: 2, y: 3 },
            { x: 3, y: -2 },
            { x: 4, y: 4 },
            { x: 5, y: -3 }
          ],
          label: 'Series A',
          pointRadius: 10
        }
      ];
      return;
    }
    if (this.chartType === ChartTypes.bubble) { // hard coded data, no view available yet
      this.chartData = [
        {
          data: [
            { x: 10, y: 10, r: 10, label: 'kip' },
            { x: 15, y: 5, r: 15 },
            { x: 26, y: 12, r: 23 },
            { x: 7, y: 8, r: 8 }
          ],
          label: 'Series A',
          backgroundColor: 'green',
          borderColor: 'blue',
          hoverBackgroundColor: 'rgba(255,255,255,0.5)',
          hoverBorderColor: 'rgba(0,199,217,1)'
        }
      ];
      return;
    }
    const barData = this.getBarData(data, this.legendKey);
    this.chartHeight = this.chartType === ChartTypes.horizontalBar ? barData.labels.length * 40 + 30 : 400;
    this.chartWidth = this.chartType === ChartTypes.bar || this.chartType === ChartTypes.histogram ? barData.labels.length * (this.chartType === ChartTypes.histogram ? 4.6 : 40) + 100 : null;
    this.chartData = null;
    clearTimeout(this.chartDataTimer); // chartDataTimer clearen om flicker in ForeFox te voorkomen
    this.chartDataTimer = setTimeout(() => { // set timeout else chart doesn't get redrawn
      this.chartData = this.chartType === ChartTypes.pie ? newData?.slice(0, 100) || [] :  barData.dataSets;
      this.setColors();
      const labels = data?.map(x => x[this.nameString]);
      this.chartLabels = (this.chartType === ChartTypes.pie ? labels?.slice(0, 100) || [] : barData.labels) as string[];
      switch (this.histogramDataType) {
        case 'time': this.setTimeLabels('days'); break;
        case 'financial': this.setFinancialLabels(); break;
        default: break;
      }
    });
  }

  private setColors() {
    this.chartData?.forEach((item, index) => {
      item.backgroundColor = this.chartColors[index].backgroundColor;
      item.borderColor = this.chartColors[index].borderColor;
      item.hoverBackgroundColor = this.chartColors[index].hoverBackgroundColor;
      item.hoverBorderColor = this.chartColors[index].hoverBorderColor;
    });
  }

  private getBarData(data: TFormAnchorDynamicElements[] | null, key: string) {
    const dataSets = [];
    let labels: string[] = [];
    const kvps: KeyValue<string, number>[] = [];
    if (!!this.filterLegend && !!key) { // if legend should be based on a filter option, data needs different formatting
      this.filterLegend.options.forEach(option => {
        const newArr = [];
        const tmp = data?.filter(x => x[key] === option.code);
        newArr.push(tmp?.map(x => ({key: x[this.nameString], value: x[this.valueString]})));
        newArr.forEach(arr => {
          arr?.forEach(item => {
            const foundKvp = kvps.find(kvp => kvp.key === item.key);
            if (!foundKvp) kvps.push(item as KeyValue<string, number>);
            else foundKvp.value += item.value as number;
          });
        });

      });
      const sortedKvps = this.sortArray(kvps, 'value', false);
      this.filterLegend.options.forEach(option => {
        const tmp = data?.filter(x => x[key] === option.code);
        const orderedValues: TFormAnchorDynamicElements[] = [];
        sortedKvps?.forEach(kvp => {
          const foundItem = tmp?.find(item => item[this.nameString] === kvp.key);
          if (!!foundItem) orderedValues.push(foundItem);
        });
        const mappedValues = orderedValues.map(x => x[this.valueString]);
        if (this.isMultipleBar) {
          dataSets.push({data: mappedValues, label: option.label});
        } else {
          dataSets.push({data: mappedValues, label: option.label, barThickness: this.chartType === ChartTypes.histogram ? 4.6 : 30, stack: 'a'});
        }
        labels = orderedValues.map(x => x[this.nameString]) as string[];
      });
    } else {
      labels = data?.map(x => x[this.nameString]) as string[];
      dataSets.push({data: data?.map(x => x[this.valueString]), barThickness: this.chartType === ChartTypes.histogram ? 4.6 : 30});
    }
    return {dataSets, labels};
  }

  private castData(currentValue: any): any {
    if (!!this.histogramDataType && (this.chartType === ChartTypes.bar || this.chartType === ChartTypes.horizontalBar)) {
      switch (this.histogramDataType) {
        case 'time': return this.getLocaleValue(+currentValue / +UNIT_VALS[this.timeUnit]);
        case 'financial': return (+currentValue).toLocaleString(this.locale, {style: 'currency', currency: 'EUR'});
        default: return currentValue;
      }
    } else {
      return currentValue;
    }
  }

  private generateColors() { // generate colors for the pie chart
    const colors = [];
    colors.push('rgba(0,199,217,1)'); // 00c7d9 turquoise
    colors.push('rgba(255,77,91,1)'); // ff4d5b red
    colors.push('rgba(255,143,64,1)'); // ff8f40 orange
    colors.push('rgba(255,213,0,1)'); // ffd500 yellow
    colors.push('rgba(87,217,152,1)'); // 57d998 green
    colors.push('rgba(64,127,255,1)'); // 407fff blue
    colors.push('rgba(170,128,255,1)'); // aa80ff purple
    colors.push('rgba(0,199,217,0.5)'); // 00c7d9 turquoise
    colors.push('rgba(255,77,91,0.5)'); // ff4d5b red
    colors.push('rgba(255,143,64,0.5)'); // ff8f40 orange
    colors.push('rgba(255,213,0,0.5)'); // ffd500 yellow
    colors.push('rgba(87,217,152,0.5)'); // 57d998 green
    colors.push('rgba(64,127,255,0.5)'); // 407fff blue
    colors.push('rgba(170,128,255,0.5)'); // aa80ff purple
    this.pieChartColors[0] = {backgroundColor: colors};
  }

  public setSort(isAlpha: boolean, isAsc: boolean, clear: boolean, data: TFormAnchorDynamicElements[] | null) {
    this.isAlpha = isAlpha;
    this.isAsc = isAsc;
    this.clear = clear;
    this.sort(data);
  }

  public sort(data: TFormAnchorDynamicElements[] | null) {
    if (this.clear) this.setData(data);
    else {
      data = this.chartType === ChartTypes.histogram ? data : this.sortArray(data, this.isAlpha ? 'Name' : 'value', this.isAsc);
      this.setData(data);
    }
  }

  private sortArray<T = TFormAnchorDynamicElements>(arr: T[] | null, propertyName: keyof T, isAsc: boolean) {
    return arr?.sort((a, b) => {
      if (isAsc) {
        if (a[propertyName] < b[propertyName]) return -1;
        if (a[propertyName] > b[propertyName]) return 1;
      }
      if (!isAsc) {
        if (a[propertyName] > b[propertyName]) return -1;
        if (a[propertyName] < b[propertyName]) return 1;
      }
      return 0;
    }) || null;
  }

  public setPieSettings() {
    this.chartOptions = {};
    this.chartOptions = {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: 'left',
          labels: {
            usePointStyle: true
          }
        },
        datalabels: {
          anchor: 'end',
          align: 'end'
        },
        tooltip: {
          enabled: false
        }
      }
    };

    return this.chartOptions;
  }

  private setBarSettings() {
    this.chartOptions = {} as ChartConfiguration['options'];
    this.chartLegend = !!this.legendKey;
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: this.isMultipleBar
          }
        },
        y: {
          grid: {
            display: false
          }
        }
      },
      plugins: {
        legend: {
          display: this.chartLegend
        },
        datalabels: {
          display: context => {
            const data = context.dataset.data[context.dataIndex] as number;
            return data >= 1;
          }
        },
        tooltip: {
          enabled: this.chartType === ChartTypes.histogram || this.isMultipleBar
        }
      }
    };
    if (this.showValuesInChart && this.chartType !== ChartTypes.histogram && !this.isMultipleBar) {
      const compThis = this;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const drawValue = function (this: Record<string, any> & TChartThis) { // this functions draws the value of the bar inside the bar
        const chartInstance = this.chart || this;
        if (!chartInstance) return;
        const ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        (this.data.datasets as any[]).forEach((dataset, i) => {
          const meta = chartInstance._metasets[i];
          (meta.data as any[]).forEach((bar, index) =>  {
            const data = compThis.castData(dataset.data[index]);
            if (!data || data === 0) return;
            const isHorizontalBar = chartInstance.config.options.indexAxis === 'y';
            const fontArgs = ctx.font.split(' ');
            const newSize = isHorizontalBar ? `${bar.height - 10}px` : `${bar.width - 10}px`;
            ctx.font = `${newSize} ${fontArgs[fontArgs.length - 1]}`;
            const metrics = ctx.measureText(data);
            const offset = metrics.width / 2 + 4;
            ctx.fillStyle = '#ffffff';
            if (chartInstance.config.type === ChartTypes.bar && !isHorizontalBar) {
              if (metrics.width > bar.width) {
                ctx.save();
                ctx.translate(bar.x, bar.y - 30);
                ctx.rotate(-0.5 * Math.PI);
                if (!!bar.height && metrics.width < bar.height) {
                  const valueYpos = 10;
                  const valueXPos = bar.height / 2 * -1 - metrics.width / 2 + 24;
                  ctx.fillText(data, valueXPos, valueYpos);
                } else if (!!bar.height) {
                  ctx.fillStyle = '#6b8299';
                  const valueYpos = 10;
                  const valueXPos = metrics.width - metrics.actualBoundingBoxLeft - 24;
                  ctx.fillText(data, valueXPos, valueYpos);
                }
                ctx.restore();
              } else {
                const valueYpos = +bar.y + bar.height / 2 - 4;
                const valueXPos = +bar.x;
                if (bar.width - 10 > bar.height) {
                  ctx.fillStyle = '#6b8299';
                  ctx.fillText(data, valueXPos, valueYpos);
                } else ctx.fillText(data, valueXPos, valueYpos + 10);
              }
            } else if (chartInstance.config.type === ChartTypes.bar && isHorizontalBar) {
              if (metrics.width < bar.width) {
                const valueYpos = +bar.y + bar.height / 2 - 4;
                const valueXPos = +bar.x - bar.width / 2;
                ctx.fillText(data, valueXPos, valueYpos);
              } else {
                ctx.fillStyle = '#6b8299';
                const valueYpos = +bar.y + bar.height / 2 - 4;
                const valueXPos = +bar.base  + +bar.width + metrics.width / 2 + 8;
                ctx.fillText(data, valueXPos, valueYpos);
              }
            } else {
              const valueXpos = bar._model.x - offset;
              ctx.fillText(data, valueXpos, +bar._model.y + 10);
            }
          });
        });
      };
      //this.chartOptions.animation = {onProgress: drawValue, onComplete: drawValue};
    }
    if (this.chartType === ChartTypes.bar) {
      this.chartOptions.scales!.y!.display = false;
      this.chartOptions.indexAxis = 'x';
      this.chartOptions.scales!.y!.ticks = {
        callback: this.setLabelsToLocaleValue.bind(this)
      };
    }
    if (this.chartType === ChartTypes.horizontalBar) {
      this.chartOptions.scales!.x!.display = this.isMultipleBar;
      this.chartOptions.indexAxis = 'y';
      this.chartOptions.scales!.x!.ticks = {
        callback: this.setLabelsToLocaleValue.bind(this),
        stepSize: 1
      };
      this.chartOptions.datasets = {bar: {barThickness: 10}};
    }
    if (this.chartType === ChartTypes.histogram) {
      this.chartOptions.scales!.y!.display = true;
      this.chartOptions.plugins!.tooltip!.callbacks = {
        title: () => '',
        label: this.toolTipHistogramLabel.bind(this)
      };
      this.chartOptions.scales!.y!.ticks = {
        callback: this.setLabelsToLocaleValue.bind(this)
      };
      this.chartOptions.plugins!.datalabels!.display = false;
    }
  }

  private setLabelsToLocaleValue(label: number | string, _index: any, _labels: any): string {
    return label.toLocaleString(this.locale, {maximumFractionDigits: 1});
  }

  private setScatterBubbleSettings() {
    this.chartOptions = {} as ChartConfiguration['options'];
    this.chartOptions!.responsive = true;
    this.chartOptions!.plugins!.legend = {};
    this.chartOptions!.plugins!.legend.display = false;
  }

  public changeGraphStyle(chartType: ChartTypes | string) {
    this.chartType = chartType as ChartTypes;
    if (this.isInitialized) this.sort(this.filteredData);
    if (chartType as ChartTypes === ChartTypes.pie) this.setPieSettings();
    if (chartType as ChartTypes === ChartTypes.scatter || chartType as ChartTypes === ChartTypes.bubble) this.setScatterBubbleSettings();
    else this.setBarSettings();
  }

  public handleFilter(value: any, input: FormInput): void {
    this.activeFilters = handleFilter(this.activeFilters || [], this._filterChips$, value, input);
  }

  public ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public changeHistogramUnit(unit: keyof typeof UNIT_VALS) {
    this.histogramTimeUnit = `graph.${unit}`;
    this.timeUnit = unit;
    this.setTimeLabels(unit);
    this.chart?.render();
  }

  private toolTipHistogramLabel(item: TooltipItem<keyof ChartTypeRegistry>) {
    if (this.chartType !== ChartTypes.histogram) return [];
    const dictionaryItemValue = this.dictionary.get(item.label);
    if (!!dictionaryItemValue) {
      const foundDataItem = this.newData.find(dataItem => dataItem.Name === dictionaryItemValue.toString()) || {};
      const checkArray = ['min', 'max'];
      const foundItemKeys = Object.keys(foundDataItem).map(key => key.toLowerCase());
      if (checkArray.every(key => foundItemKeys.includes(key))) {
        const minProperty = Object.keys(foundDataItem).find(key => key.toLowerCase() === 'min')!;
        const maxProperty = Object.keys(foundDataItem).find(key => key.toLowerCase() === 'max')!;
        let minValue = '';
        let maxValue = '';
        if (this.histogramDataType === 'time') {
          minValue = this.getLocaleValue(+foundDataItem[minProperty] / UNIT_VALS[this.chartUnit]);
          maxValue = this.getLocaleValue(+foundDataItem[maxProperty] / UNIT_VALS[this.chartUnit]);
        } else if (this.histogramDataType === 'financial') {
          minValue = (+foundDataItem[minProperty]).toLocaleString(this.locale, {style: 'currency', currency: 'EUR'});
          maxValue = (+foundDataItem[maxProperty]).toLocaleString(this.locale, {style: 'currency', currency: 'EUR'});
        } else {
          minValue = foundDataItem[minProperty] as string;
          maxValue = foundDataItem[maxProperty] as string;
        }
        return [(item.raw as number)?.toLocaleString(this.locale, {maximumFractionDigits: 2}), `Min: ${minValue}`, `Max: ${maxValue}`];
      }
    }
    return [];
  }

  private setContextFilter(res: IFormResult) {
    this.contextFilterObj = res.data.error.formresponse.missing?.anchors[0].elements.find(el => el.elementid === this.filterKey);
    const options: IInputOption[] = [];
    this.contextFilterObj?.dynamicschema?.forEach(x => options.push({label: x.elements['Name'] as string, value: x.code, key: x.code}));
    this.contextFilterInput = {
      param: 'PrimaryContext',
      name: 'PrimaryContext',
      options,
      type: 'choicefilter',
      label: this.translate.instant('graph.context'),
      layouthint: ['checkbox'],
      multiplechoice: true,
      optionMode: 'dynamicWithThreshold',
      children: [
        {
          PrimaryContextName: {
            type: 'string',
            label: 'Primary context name',
            displaysize: 50,
            maxLength: 50
          }
        }
      ],
      dynamics: [],
      id: 'PrimaryContext',
      value: this.contextFilterObj?.suggestion,
      disabled: false,
      multiple: false,
      hidden: true,
      validators: {},
      mandatory: false,
      controlType: 'select'
    };
    const control = this.formService.toFormField(this.contextFilterInput as unknown as FormInput);
    this.contextFilterForm.addControl(this.contextFilterInput.id, control, {emitEvent: false});
    const changesObs = this.contextFilterForm.valueChanges.subscribe(changes => {
      const change = changes[this.filterKey!];
      if (!!change && !Array.isArray(changes[this.filterKey!]) && change !== this.contextFilterObj?.suggestion) {
        const href = `${this.href}?${this.filterKey!}=${changes[this.filterKey!]}`;
        changesObs.unsubscribe();
        this.getData(href);
      }
    });
  }

  public getFormControl(controlName: string) {
    return this.contextFilterForm.get(controlName) as FormControl;
  }
}
