import { Component, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'naris-audit-execution-history-array',
  templateUrl: './audit-execution-history-array.component.html',
  styleUrls: ['./audit-execution-history-array.component.scss'],
  standalone: true,
  imports: [MatTooltip]
})
export class AuditExecutionHistoryArrayComponent {
  @Input() public data: any[];
}
