import { inherits as e, createSuper as t, classCallCheck as n, defineProperty as u, assertThisInitialized as a, createClass as r } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { AbstractLayout as i } from "./abstract-layout.js";
import { Layouts as o } from "./layouts.js";
var l = new Map();
var s = function (i) {
  e(s, i);
  var o = t(s);
  function s(e) {
    var t;
    n(this, s);
    t = o.call(this, e);
    u(a(t), "type", s.type);
    u(a(t), "defaultMagnetized", void 0);
    return t;
  }
  r(s, [{
    key: "getDefaultParameters",
    value: function e() {
      return {};
    }
  }, {
    key: "layout",
    value: function e() {}
  }, {
    key: "refresh",
    value: function e() {
      this.layout();
    }
  }, {
    key: "relayout",
    value: function e() {
      this.layout();
    }
  }, {
    key: "getPositions",
    value: function e() {
      return l;
    }
  }, {
    key: "getPosition",
    value: function e(t) {
      return {
        x: 0,
        y: 0
      };
    }
  }, {
    key: "setPosition",
    value: function e(t, n, a) {
      return u({}, t, {
        original: {
          x: n,
          y: a
        },
        current: {
          x: n,
          y: a
        }
      });
    }
  }, {
    key: "_getExtents",
    value: function e() {
      return {
        xmin: 0,
        ymin: 0,
        xmax: 0,
        ymax: 0
      };
    }
  }, {
    key: "getSize",
    value: function e(t) {
      return {
        w: 0,
        h: 0
      };
    }
  }, {
    key: "begin",
    value: function e(t, n) {}
  }, {
    key: "canMagnetize",
    value: function e(t) {
      return false;
    }
  }, {
    key: "end",
    value: function e(t, n, u) {}
  }, {
    key: "reset",
    value: function e() {}
  }, {
    key: "step",
    value: function e(t, n) {
      this.done = true;
    }
  }, {
    key: "getSizes",
    value: function e() {
      return l;
    }
  }]);
  return s;
}(i);
u(s, "type", "Empty");
o.register(s.type, s);
export { s as EmptyLayout };