import { createClass as t, inherits as e, createSuper as n, classCallCheck as r } from "../../_virtual/_rollupPluginBabelHelpers.js";
var i = function () {
  function e(t, n, i) {
    r(this, e);
    this.obj = t;
    this.parent = n;
    this.toolkit = i;
  }
  t(e, [{
    key: "_add",
    value: function t() {
      this.toolkit.addPort(this.parent, this.obj.data);
    }
  }, {
    key: "_remove",
    value: function t() {
      this.toolkit.removePort(this.parent, this.obj.id);
    }
  }, {
    key: "getTerminusId",
    value: function t() {
      return this.obj.getFullId();
    }
  }]);
  return e;
}();
var u = function (i) {
  e(a, i);
  var u = n(a);
  function a() {
    r(this, a);
    return u.apply(this, arguments);
  }
  t(a, [{
    key: "undo",
    value: function t() {
      this._remove();
    }
  }, {
    key: "redo",
    value: function t() {
      this._add();
    }
  }, {
    key: "hasDeltas",
    value: function t() {
      return this.obj != null;
    }
  }]);
  return a;
}(i);
var a = function (i) {
  e(a, i);
  var u = n(a);
  function a() {
    r(this, a);
    return u.apply(this, arguments);
  }
  t(a, [{
    key: "undo",
    value: function t() {
      this._add();
    }
  }, {
    key: "redo",
    value: function t() {
      this._remove();
    }
  }, {
    key: "isConnectedTo",
    value: function t(e) {
      var n = this.getTerminusId();
      return e.generateSourceId() === n || e.generateTargetId() === n;
    }
  }, {
    key: "hasDeltas",
    value: function t() {
      return this.obj != null;
    }
  }]);
  return a;
}(i);
export { i as PortAction, u as PortAddAction, a as PortRemoveAction };