import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SWATCH_COLORS } from '@core/constants/core-constants';
import { Subject } from 'rxjs';
import { NgClass, NgStyle, LowerCasePipe } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { ButtonComponent } from '../../elements/button/button.component';
import { ColorSliderComponent } from './color-slider/color-slider.component';
import { ColorPaletteComponent } from './color-palette/color-palette.component';

@Component({
  selector: 'naris-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  standalone: true,
  imports: [NgClass, NgStyle, ButtonComponent, ColorSliderComponent, ColorPaletteComponent, MatTooltip, LowerCasePipe]
})
export class ColorPickerComponent {

  @Output() public readonly colorPicked = new EventEmitter<string>();
  @Output() public readonly closed = new EventEmitter<void>();
  @Input() public chosenHue: string;
  @Input() public colorSpan = false;
  @Input() public swatches = SWATCH_COLORS;
  public hueChange = new Subject<string>();
  public color: string;

  public emitColor(evt: string) {
    this.color = evt;
    setTimeout(() => this.colorPicked.emit(evt), 0);
  }
}
