import { _createConnectorBase as o } from "../core/connector/abstract-connector.js";
function i(i, r, c) {
  var a = o(i, r, c, [0, 0]);
  var t = Object.assign(a, {
    showLoopback: c.showLoopback !== false,
    curviness: c.curviness || 10,
    margin: c.margin || 5,
    proximityLimit: c.proximityLimit || 80,
    clockwise: c.orientation && c.orientation === "clockwise",
    loopbackRadius: c.loopbackRadius || 25,
    isLoopbackCurrently: false
  });
  return t;
}
export { i as createAbstractBezierConnector };