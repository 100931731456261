import { createClass as e, classCallCheck as i, defineProperty as n } from "../_virtual/_rollupPluginBabelHelpers.js";
import { EventManager as t } from "../ui-core/browser-ui-renderer/event-manager.js";
import { consume as r } from "../ui-core/browser-ui-renderer/browser-util.js";
var a = "pointerdown";
var o = "pointermove";
var s = "pointerup";
var u = "touchstart";
var c = "touchmove";
var h = "touchend";
function d(e, i, n, t) {
  return Math.sqrt(Math.pow(n - e, 2) + Math.pow(t - i, 2));
}
var v = function () {
  function v(e) {
    var f = this;
    i(this, v);
    n(this, "isPointerDevice", void 0);
    n(this, "isTouchDevice", void 0);
    n(this, "center", void 0);
    n(this, "radius", void 0);
    n(this, "startRadius", void 0);
    n(this, "onPinchStart", void 0);
    n(this, "onPinch", void 0);
    n(this, "onPinchEnd", void 0);
    n(this, "eventManager", void 0);
    n(this, "listenerTypes", {
      pointer: function e(i) {
        var n = {},
          t = [],
          r = 0,
          u = false;
        var c = function e() {
          if (r === 2) {
            f.center = {
              x: (t[1].p[0] + t[0].p[0]) / 2,
              y: (t[1].p[1] + t[0].p[1]) / 2
            };
            f.radius = d(t[1].p[0], t[1].p[1], t[0].p[0], t[0].p[1]);
          }
        };
        var h = function e(i) {
          if (r >= 2 || u) return;
          t[r] = {
            e: i,
            p: [i.pageX, i.pageY]
          };
          n["" + i.pointerId] = r;
          r++;
          c();
          if (r === 2) {
            f.startRadius = f.radius;
            f._fire(f.onPinchStart);
          }
        };
        var v = function e(i) {
          var t = n["" + i.pointerId];
          if (t != null) {
            delete n["" + i.pointerId];
            r--;
            u = r !== 0;
            f._fireEnd();
          }
        };
        var p = function e(i) {
          if (u || r !== 2) return;
          var a = n[i.pointerId];
          if (a != null) {
            t[a].p = [i.pageX, i.pageY];
            c();
            f._fire(f.onPinch);
          }
        };
        f.bind(i.el, a, h, {
          passive: false
        });
        f.bind(document, s, v, {
          passive: false
        });
        f.bind(document, o, p, {
          passive: false
        });
      },
      touch: function e(i) {
        var n = function e(i) {
          return i.touches || [];
        };
        var t = function e(i, n) {
          return i.item ? i.item(n) : i[n];
        };
        var a = function e(i) {
          var n = t(i, 0),
            r = t(i, 1);
          return d(n.pageX, n.pageY, r.pageX, r.pageY);
        };
        var o = function e(i) {
          var n = t(i, 0),
            r = t(i, 1);
          return {
            x: (n.pageX + r.pageX) / 2,
            y: (n.pageY + r.pageY) / 2
          };
        };
        var s = false;
        var v = function e(t) {
          var u = n(t);
          if (u.length === 2 && i.enableWheelZoom !== false) {
            f.center = o(u);
            f.radius = f.startRadius = a(u);
            s = true;
            f.bind(document, c, l);
            f.bind(document, h, p);
            r(t);
            f._fire(f.onPinchStart);
          }
        };
        var p = function e(i) {
          s = false;
          r(i);
          f.unbind(document, c, l);
          f.unbind(document, h, e);
          f._fireEnd();
        };
        var l = function e(i) {
          if (s) {
            var t = n(i);
            if (t.length === 2) {
              r(i);
              f.radius = a(t);
              f.center = o(t);
              f._fire(f.onPinch);
            }
          }
        };
        f.bind(i.el, u, v);
      }
    });
    this.eventManager = new t();
    this.isPointerDevice = "onpointerdown" in document.documentElement;
    this.isTouchDevice = "ontouchstart" in document.documentElement;
    this.center = {
      x: 0,
      y: 0
    };
    this.radius = 0;
    this.startRadius = 0;
    this.onPinch = e.onPinch;
    this.onPinchStart = e.onPinchStart;
    this.onPinchEnd = e.onPinchEnd;
    if (this.isPointerDevice) {
      this.listenerTypes.pointer(e);
    } else if (this.isTouchDevice) {
      this.listenerTypes.touch(e);
    }
  }
  e(v, [{
    key: "_fire",
    value: function e(i) {
      i(this.center, this.startRadius, this.radius, this.radius / this.startRadius);
    }
  }, {
    key: "_fireEnd",
    value: function e() {
      this.onPinchEnd();
    }
  }, {
    key: "bind",
    value: function e(i, n, t, r) {
      this.eventManager.on(i, n, t);
    }
  }, {
    key: "unbind",
    value: function e(i, n, t) {
      this.eventManager.off(i, n, t);
    }
  }, {
    key: "destroy",
    value: function e() {}
  }]);
  return v;
}();
export { v as PinchListener };