import { Component, Input, OnInit } from '@angular/core';
import { BeinformedService } from '@core/services';
import { TranslateModule } from '@ngx-translate/core';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { TaskgroupComponent } from '../taskgroup/taskgroup.component';
import { IconComponent } from '../../elements/icon/icon.component';
import { LoaderComponent } from '../loader/loader.component';
import type { TCaseListContributionsResults, TCaseListDynamicSchema, ICaseListResult, ILinks, IParentDetails, TCaseListRows, ICaseListAction } from '@core/models';

@Component({
  selector: 'naris-cause-and-consequence-overview',
  templateUrl: './cause-and-consequence-overview.component.html',
  styleUrls: ['./cause-and-consequence-overview.component.scss'],
  standalone: true,
  imports: [ToolbarComponent, TaskgroupComponent, IconComponent, LoaderComponent, TranslateModule]
})
export class CauseAndConsequenceOverviewComponent implements OnInit {

  @Input() public href = '/risk/risk-identification/118/risk-properties/impact';
  @Input() public isEmbedded = false;

  public title: string;
  public loading = false;

  public eventCategoryName: string;
  public consequenceType: string;
  public class: string;
  public argumentation: string;
  public parents: any[] = [];
  public detailResults: any[];
  public detailDynamicSchema: any;

  private readonly excludedFields = ['actions', '_id', '_links', 'EventCaseId', 'EventCategoryName', 'ConsequenceType', 'HasChildren'];

  constructor(private readonly beinformedService: BeinformedService) { }

  public ngOnInit(): void {
    this.loading = true;
    this.reloadAll();
  }

  public reloadAll() {
    this.beinformedService.fetchResponseWithContributions<'caselist'>(this.href).subscribe(res => {
      const results = res?.data?._embedded?.results;
      this.title = res?.contributions?.label;
      this.mapParents(results, res?.contributions?.results, res?.data?.dynamicschema);
    });
  }

  public mapParents(parents: TCaseListRows[], contributions: TCaseListContributionsResults, dynamicValues?: TCaseListDynamicSchema) {
    this.parents = [];
    if (!parents) return;
    parents.forEach(parent => {
      const objectName = Object.keys(parent)[0];
      const parentKeys = Object.keys(parent[objectName]).filter(key => !this.excludedFields.includes(key));
      const detailKey = Object.keys(parent?.[objectName]?._links).filter(e => !e.includes('self'))?.[0] as keyof ILinks;
      const detailUrl = parent?.[objectName]?._links?.[detailKey].href;
      const parentKey = Object.keys(contributions)?.[0];
      const parentAttributes = contributions[parentKey]?.attributes;
      const parentLabels = {} as Record<string, any>;
      parentKeys.forEach(key => {
        parentAttributes.forEach(attr => {
          if (!!attr[key]) return parentLabels[key] = attr[key].label;
        });
      });
      parent[objectName].actions = this.beinformedService.extractTaskGroup({data: parent?.[objectName] as Record<string, any>, contributions: contributions?.[objectName] as any}) as any;
      let parentObject = {...parent[objectName], parentKeys, parentLabels} as IParentDetails;
      this.beinformedService.fetchResponseWithContributions<'caselist'>(`BeInformed/restServices/ui/${detailUrl}`).subscribe(detailRes => {
        this.mapParentDetails(detailRes, parentObject, detailUrl);
      });
      parentObject = this.mapDynamicValues<Record<string, IParentDetails>>(dynamicValues!, {[objectName]: parentObject});
      this.parents.push(parentObject);
    });
    this.loading = false;
  }

  public reloadDetail(detail: Record<string, any>, parentObject: Record<string, any>) {
    this.beinformedService.fetchResponseWithContributions<'caselist'>(`BeInformed/restServices/ui/${detail.detailUrl}`).subscribe(detailRes => {
      this.mapParentDetails(detailRes, parentObject, detail.detailUrl);
    });
  }

  public mapParentDetails(detailRes: ICaseListResult, parentObject: Record<string, any>, detailUrl: string) {
    const results = detailRes?.data?._embedded?.results;
    this.detailDynamicSchema = detailRes.data.dynamicschema;
    if (!parentObject.details) parentObject.details = [];
    results.forEach(detailResult => {
      const contributions = detailRes.contributions.results;
      const mappedValues = this.mapDynamicValues(this.detailDynamicSchema, detailResult);
      const parentKey = Object.keys(contributions)?.[0];
      const parentAttributes = contributions[parentKey]?.attributes;
      const detailLabels = {} as Record<string, any>;
      mappedValues.detailKeys?.forEach(key => {
        parentAttributes.forEach(attr => {
          if (!!attr[key]) return detailLabels[key] = attr[key].label;
        });
      });
      mappedValues.detailLabels = detailLabels;
      const foundData = detailRes.data._embedded.results.find(e => e[parentKey]._id === detailResult[parentKey]._id);
      mappedValues.actions = this.beinformedService.extractTaskGroup<'caselist'>({data: foundData?.[parentKey] as any, contributions: detailRes?.contributions?.results[parentKey] as any}) as { actions: ICaseListAction[] };
      mappedValues.detailUrl = detailUrl;
      const indexOfDetail = (parentObject.details as IParentDetails[]).findIndex(detail => detail._id === mappedValues._id);
      if (indexOfDetail !== -1) parentObject.details.splice(indexOfDetail, 1);
      parentObject.details.push(mappedValues);
    });
  }

  public mapDynamicValues<T extends Record<string, any> = TCaseListRows>(detailDynamicSchema: TCaseListDynamicSchema, resultObject: T) {
    const objectName = Object.keys(resultObject)[0];
    const detailKeys = Object.keys(resultObject[objectName]).filter(key => !this.excludedFields.includes(key));
    const dynamicSchemaKeys = !!detailDynamicSchema ? Object.keys(detailDynamicSchema) : [];
    dynamicSchemaKeys.forEach(key => {
      if (!detailKeys.includes(key)) return;
      resultObject[objectName][key] = detailDynamicSchema[key].find(element => element.code === resultObject[objectName][key])?.label || resultObject[objectName][key];
    });
    return {...resultObject[objectName], detailKeys} as IParentDetails;
  }
}
