import { createClass as e, classCallCheck as t, defineProperty as n } from "../_virtual/_rollupPluginBabelHelpers.js";
import { EVENT_DATA_LOAD_START as r, EVENT_DATA_LOAD_END as i, EVENT_GRAPH_CLEAR_START as a, EVENT_GRAPH_CLEARED as o } from "./constants.js";
import { JSON_DATATYPE as s } from "./io.js";
var u = ["node", "port", "edge", "group"],
  l = [":added", ":removed", ":updated", ":moved"],
  c = ["edge"],
  d = [":source", ":target"];
var f = function e(t, n, r, i) {
  for (var a = 0; a < n.length; a++) {
    for (var o = 0; o < r.length; o++) {
      v(t, n[a] + r[o], i);
    }
  }
};
var v = function e(t, n, r) {
  t.instance.bind(n, r);
  t.bindings.push([n, r]);
};
var h = function () {
  function h(e, m) {
    var p = this;
    t(this, h);
    this.instance = e;
    n(this, "bindings", []);
    n(this, "timer", null);
    if (m.saveUrl == null) {
      if (m.autoSaveHandler == null) {
        throw new Error("JSPLUMB autosave: neither saveUrl nor autoSaveHandler was specified");
      }
    }
    function b(e) {
      return function () {
        e && e.apply(e, arguments);
        m.onAfterAutoSave && m.onAfterAutoSave();
      };
    }
    var g = false;
    var S = function t() {
      if (!g) {
        try {
          m.onBeforeAutoSave && m.onBeforeAutoSave();
        } catch (e) {}
        if (m.autoSaveHandler != null) {
          m.autoSaveHandler(e);
        } else {
          e.save({
            type: m.type || s,
            url: m.saveUrl,
            success: b(m.onAutoSaveSuccess),
            error: b(m.onAutoSaveError),
            headers: m.saveHeaders
          });
        }
      }
    };
    var A = function e() {
      if (m.autoSaveDebounceTimeout == null) {
        S();
      } else {
        if (p.timer == null) {
          p.timer = setTimeout(S, m.autoSaveDebounceTimeout);
        } else {
          clearTimeout(p.timer);
          p.timer = setTimeout(S, m.autoSaveDebounceTimeout);
        }
      }
    };
    v(this, r, function () {
      g = true;
    });
    v(this, i, function () {
      g = false;
    });
    v(this, a, function () {
      g = true;
    });
    v(this, o, function () {
      g = false;
    });
    f(this, u, l, A);
    f(this, c, d, A);
    f(this, ["group:"], ["member:added", "member:removed"], A);
  }
  e(h, [{
    key: "discard",
    value: function e() {
      var t = this;
      this.bindings.forEach(function (e) {
        return t.instance.unbind(e[0], e[1]);
      });
      this.bindings.length = 0;
    }
  }]);
  return h;
}();
var m = function e(r) {
  t(this, e);
  this.instance = r;
  n(this, "bindings", []);
  var i = function e() {
    r.fire("dataUpdated");
  };
  f(this, u, l, i);
  f(this, c, d, i);
  f(this, ["group:"], ["member:added", "member:removed"], i);
};
export { h as AutoSaver, m as CatchAllEventHandler };