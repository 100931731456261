var t = "jtk-connector";
var n = "jtk-connector-outline";
var o = "jtk-connected";
var r = "jtk-endpoint";
var e = "jtk-endpoint-connected";
var a = "jtk-endpoint-full";
var d = "jtk-floating-endpoint";
var v = "jtk-endpoint-drop-allowed";
var j = "jtk-endpoint-drop-forbidden";
var k = "jtk-endpoint-anchor";
var p = "jtk-group-collapsed";
var l = "jtk-group-expanded";
var c = "jtk-overlay";
var i = "jtk-label-overlay";
export { o as CLASS_CONNECTED, t as CLASS_CONNECTOR, n as CLASS_CONNECTOR_OUTLINE, r as CLASS_ENDPOINT, k as CLASS_ENDPOINT_ANCHOR_PREFIX, e as CLASS_ENDPOINT_CONNECTED, v as CLASS_ENDPOINT_DROP_ALLOWED, j as CLASS_ENDPOINT_DROP_FORBIDDEN, d as CLASS_ENDPOINT_FLOATING, a as CLASS_ENDPOINT_FULL, p as CLASS_GROUP_COLLAPSED, l as CLASS_GROUP_EXPANDED, i as CLASS_LABEL_OVERLAY, c as CLASS_OVERLAY };