export const PASSWORD = 'password';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const PASSWORD_REPEAT = 'passwordRepeat';
export const TEXT = 'text';
export const VISIBILITY = 'visibility';
export const VISIBILITY_OFF = 'visibility_off';
export const CAPITAL_REGEX = /[A-Z]+/;
export const NUMBER_REGEX = /[0-9]+/;
export const SPECIAL_REGEX = /\W+/;
export const VALIDATION_REGS = [CAPITAL_REGEX, NUMBER_REGEX, SPECIAL_REGEX];
export const TOKEN_INVALID = 'TokenInvalid';
export const BEINFORMED_SSO = 'BeInformedSSO';
export const BEINFORMED = 'BeInformed';
export const ID_TOKEN = 'id_token';
