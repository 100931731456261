@if (!allInOne) {
  <naris-card>
    <naris-toolbar class="header">
      <naris-icon
          (click)="headerExpanded = !headerExpanded"
          icon="keyboard_arrow_right"
          class="mr-4"
          [class.open]="headerExpanded"
        /><h3>{{name | translate}}</h3>
        <naris-audit-execution-history-properties [data]="data?.[getFirstObjectName(data)]?.['history.root_properties']" />
    </naris-toolbar>
    @if (headerExpanded) {
      <div [@inOutAnimation]>
        @for (item of data | keyvalue: originalOrderHistory; track item.key) {
          <div class="container ml-2 mr-5 pb-5">
            <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ item: item.value }" />
          </div>
        }
      </div>
    }
  </naris-card>
}

@if (allInOne) {
  <naris-card>
    <naris-toolbar class="header">
      <h3>{{name | translate}}</h3>
      <naris-audit-execution-history-properties [data]="data?.[getFirstObjectName(data)]?.['history.root_properties']" />
    </naris-toolbar>
    <div
      [@inOutAnimation]
      class="content"
    >
      @for (historyData of data; track historyData) {
        <div class="container ml-2 mr-5 pb-5">
          <div class="node">
            <div class="property-parent">
              <naris-icon
                (click)="historyData.data.expanded = !historyData.data.expanded"
                icon="keyboard_arrow_right"
                class="item-toggle mr-4"
                [class.open]="historyData.data.expanded"
              />
              <span class="key mr-2">{{historyData.name | translate}}</span>
              <naris-audit-execution-history-properties [data]="historyData.data?.[getFirstObjectName(historyData.data)]?.['history.root_properties']" />
            </div>
          </div>
          @if (historyData.data.expanded) {
            <div [@inOutAnimation]>
              @for (item of historyData.data | keyvalue: originalOrderHistory; track item.key) {
                <div class="container ml-2 mr-5 pb-5">
                  <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ item: item.value }" />
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
  </naris-card>
}

<ng-template #recursiveListTmpl let-item="item" let-isDocument="isDocument">
  @for (property of item | keyvalue: originalOrder; track property.key) {
    <div class="node">
      @if (property?.key?.toString() === 'history.record') {
        <div>
          @if (!!property.value) {
            <span>
              <table>
                <tr>
                  &bullet;<naris-audit-execution-history-array [data]="$any(property.value)" />
                </tr>
              </table>
            </span>
          }
        </div>
      }
      @if (isReqularItem(property?.key?.toString()) && isNaN(property?.key)) {
        <div>
          <div class="property-parent" [ngClass]="{'property-parent-flex': isDocument}" [class.expandable]="isArray(property.value) || isObject(property.value)">
            @if ((isArray(property.value) || isObject(property.value)) && !onlyHasProperties(property) && !isDocument) {
              <naris-icon
                (click)="$any(property).expanded = !$any(property).expanded"
                icon="keyboard_arrow_right"
                class="item-toggle mr-4"
                [class.open]="$any(property).expanded"
              />
            }
            @if (!!property.key) {
              <span
                class="key mr-2"
                [ngClass]="{'document-key ml-2': isDocument}"
              >
                {{property.key.toString() | translate}}
              </span>
            }
            @if (!!$any(property.value)?.['history.properties']) {
              <span>
                <naris-audit-execution-history-properties [data]="$any(property.value)?.['history.properties']" />
              </span>
            }
            @if (!!$any(property.value)?.['history.document-actions']) {
              <span>
                @for (action of ($any(property.value)?.['history.document-actions'] | extractDocumentActions)[0]; track action; let index = $index) {
                  <naris-button
                    type="button"
                    color="transparent"
                    size="small"
                    matTooltipPosition="above"
                    matTooltipShowDelay="200"
                    [matTooltip]="action[0] | translate"
                    [icon]="extractIcon(action[0].toString())"
                    (clicked)="performDocumentAction(action[1].toString())"
                    [id]="'AuditExecutionHistoryViewer'+action[0]"
                  />
                }
              </span>
            }
            @if (!isArray(property.value) && !isObject(property.value) && !valueIsBoolean(property.value)) {
              <span>
                {{isDate(property.value) ? (property.value | date:'dd-MM-yyyy') : (property.value | translate)}}
              </span>
            }
            @if (valueIsBoolean(property.value)) {
              <span class="bool">
                @if (!!property.value) {
                  <naris-icon
                    [icon]="'check'"
                    [iconClass]="'green'"
                  />
                } @else {
                  <naris-icon
                    [icon]="'close'"
                    [iconClass]="'warn'"
                  />
                }
              </span>
            }
          </div>
          @if (isArray(property.value) && $any(property).expanded && !isDocument) {
            <div>
              @for (subProperty of property.value; track subProperty) {
                <div class="child">
                  <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ item: subProperty, isDocument: property.key.toString() === 'history.documents' }" />
                </div>
              }
            </div>
          }
          @if (isObject(property.value) && $any(property).expanded && !isDocument) {
            <div>
              <div class="child">
                <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ item: property.value, isDocument: property.key.toString() === 'history.documents' }" />
              </div>
            </div>
          }
        </div>
      }
      @if (property.key.toString().toLowerCase() === 'history.frequency') {
        <div class="property-parent">
          <span class="key mr-2">{{property.key.toString() | translate}}</span>
          <span [innerHTML]="property?.value | toFrequencyString"></span>
        </div>
      }
      @if (!isNaN(property?.key) && !isDocument) {
        <div>
          @if (isArray(property.value)) {
            <div>
              @for (subProperty of property.value; track subProperty) {
                <div class="child">
                  <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ item: subProperty }" />
                </div>
              }
            </div>
          }
          @if (isObject(property.value)) {
            <div>
              <div class="child">
                <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ item: property.value }" />
              </div>
            </div>
          }
        </div>
      }
    </div>
  }
</ng-template>
