@if (!data.isForm && !isInfoClass) {
  <naris-table
    [preSelected]="data.selected"
    [isModal]="true"
    [dataUrl]="data.endpoint"
    (rowClicked)="setValue($event)"
    (submitSelection)="setValue($event)"
    (createActionClicked)="onCreateAction()"
    (lookupCreateSaved)="setValue($event)"
    (closeLookup)="popupClosed()"
    [createAction]="data.createAction"
    [lookupEndpoint]="data.createEndpoint"
    [tableConfig]="{
      check: data.multiple,
      parentTable: !!data.layouthint?.includes('parent-list'),
      nestedTable: !!data.layouthint?.includes('nested-list')
    }"
    [enableRowClick]="data.enableRowClick === undefined ? true : data.enableRowClick"
  />
}
@if (data.isForm) {
  <div>
    <app-form 
      [endpoint]="data.endpoint" 
      [isEmbedded]="false" 
      [returnFormUpdates]="true"
      [checkService]="true"
      [pushValidationToService]="true"
      [hidePreviousAssessment]="true"
      (closed)="popupClosed()"
      (formDialogSaved)="setValue($event)"
      [isEmbeddedInModal]="true"
      />
  </div>
}
@if (isInfoClass) {
  <div class="info-class-tree">
    <naris-treeview 
      [href]="data.endpoint" 
      [enableLazyLoading]="false" 
      [isAssetClassification]="true" 
      [selectedData]="data.selected"   
      (submitSelection)="setValue($event)"
    />
  </div>
}
