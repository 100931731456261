import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators, FormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { Apollo, QueryRef, TypedDocumentNode } from 'apollo-angular';
import { catchError, Subscription } from 'rxjs';
import { KB_ICONS } from '@core/constants';
import { decamelize, isAbbreviation, stripObject } from '@core/helpers';
import { FieldNode, GraphQLError, Kind, OperationDefinitionNode } from 'graphql';
import { NgClass, NgTemplateOutlet, TitleCasePipe, KeyValuePipe } from '@angular/common';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatDivider } from '@angular/material/divider';
import { MatTooltip } from '@angular/material/tooltip';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { StripPipe } from '@shared/pipes/strip.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressSpinnerComponent } from '../../../../shared/elements/progress-spinner/progress-spinner.component';
import { InlineInputComponent } from '../../../../shared/components/inline-input/inline-input.component';
import { NodeTreeComponent } from '../../../../shared/components/node-tree/node-tree.component';
import { ButtonComponent } from '../../../../shared/elements/button/button.component';
import { IconComponent } from '../../../../shared/elements/icon/icon.component';
import type { IGQLResponse, ISearchVariables, ISubItem, TKbDialogData, TKbObject, TKbRelated, ISearchResponseData, TSearchDocument } from '@core/models';
import type { ApolloQueryResult } from '@apollo/client';

@Component({
  selector: 'naris-knowledgebase-dialog',
  templateUrl: './knowledgebase-dialog.component.html',
  styleUrls: ['./knowledgebase-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, IconComponent, NgClass, ButtonComponent, MatDialogClose, CdkScrollable, MatDialogContent, NodeTreeComponent, MatDivider, FormsModule, InlineInputComponent, MatTooltip, NgTemplateOutlet, MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatDialogActions, ProgressSpinnerComponent, TitleCasePipe, KeyValuePipe, StripPipe, TranslateModule]
})
export class KnowledgebaseDialogComponent implements OnInit, OnDestroy {

  public clonedData: TKbObject;
  public nameControl: FormControl<string | null>;
  public nameLength = this.kbObject.type === 'event' ? 255 : 50;
  public relatedObjects = {} as TKbRelated<ISubItem>;
  public relatedFields = {} as TKbRelated<string>;
  public kbIcons = KB_ICONS;
  public loading = true;
  public error?: Error | GraphQLError | null;
  public suggestions: TSearchDocument[] = [];
  public relatedId: string | null = null;
  public expandedSub: number | null = null;
  public compareFunc = () => 0;

  private searchRef: QueryRef<IGQLResponse, Partial<ISearchVariables>>;
  private readonly subs: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public kbObject: TKbDialogData<TKbObject>,
    private readonly dialogRef: MatDialogRef<KnowledgebaseDialogComponent>,
    private readonly apollo: Apollo
  ) {}

  public ngOnInit() {
    this.searchRef = this.apollo.watchQuery<IGQLResponse, Partial<ISearchVariables>>({query: this.kbObject.search, variables: {searchTerm: this.kbObject.objData.name, caseId: this.kbObject.objData.caseId}});
    this.subs.push(this.searchRef.valueChanges.pipe(catchError(this.onError)).subscribe(this.onMongoResponse));
    this.clonedData = structuredClone(this.kbObject.objData);
    this.nameControl = new FormControl(this.clonedData.name.trim(), [Validators.required, Validators.maxLength(this.nameLength)]);
    const entries = Object.entries(this.clonedData) as [keyof TKbObject, TKbObject[keyof TKbObject]][];
    entries.forEach(([ key, value ]) => {
      if (!value || !Array.isArray(value) || !value.length) return;
      const label = isAbbreviation(key) ? key.replace('s', '\'s') : decamelize(key, true);
      const item = {label, ...KB_ICONS[key]};
      if (typeof value[0] === 'string') this.relatedFields[key] = {...item, items: value as string[]};
      else this.relatedObjects[key] = {...item, items: value as ISubItem[]};
    }, {});
  }

  public onSubmit() {
    const submitted = {...this.clonedData, name: this.nameControl.value?.trim()};
    if (!!this.relatedId) submitted['related'] = this.relatedId;
    else if (submitted.hasOwnProperty('related')) delete submitted['related'];
    this.dialogRef.close(submitted);
  }

  public removeSubItem(caseId: number, relatedKey: string) {
    if (!caseId || !relatedKey) return;
    const typedKey = relatedKey as keyof TKbObject;
    const filtered = (this.clonedData[typedKey] as ISubItem[]).filter(item => item.caseId !== caseId);
    this.relatedObjects[typedKey].items = filtered;
    (this.clonedData[typedKey] as ISubItem[]) = filtered;
    if (!filtered.length) {
      delete this.clonedData[typedKey];
      delete this.relatedObjects[typedKey];
    }
  }

  public isArray(item: unknown) {
    return Array.isArray(item);
  }

  public ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private disectGQL(query: TypedDocumentNode<IGQLResponse, Partial<ISearchVariables>>, result: IGQLResponse) {
    const queryDefinition = query.definitions.find(({ kind }) => kind === Kind.OPERATION_DEFINITION) as OperationDefinitionNode | void;
    if (!queryDefinition) throw new Error(`No query definition found for query:\n${query.loc?.source}`);
    const queryField = queryDefinition.selectionSet.selections.find(({ kind }) => kind === Kind.FIELD) as FieldNode;
    if (!queryField) throw new Error(`No query field found for query:\n${query.loc?.source}`);
    return result[queryField.name?.value as keyof IGQLResponse] as ISearchResponseData[];
  }

  private readonly onMongoResponse = ({ data: result, error }: ApolloQueryResult<IGQLResponse>) => {
    if (!!error) this.onError(error);
    const data = this.disectGQL(this.kbObject.search, result);
    this.suggestions = data.map(({ document }) => stripObject(document, ['__typename', 'searchId'], 1));
    this.loading = false;
  };

  private readonly onError = (err: Error | GraphQLError) => {
    this.error = err;
    this.loading = false;
    throw err;
  };
}
