@if (loaded) {
  <div class="header">
    <div class="header-title">
      <h3>{{title}}</h3>
      <span class="ml-2 status-label">{{state}}</span>
      <div class="status ml-2" [ngClass]="stateClass"></div>
    </div>
    <div class="header-taskgroups">
      @for (taskgroup of taskgroups; track $index) {
        <naris-taskgroup class="ml-auto"
          [taskgroup]="taskgroup"
          [collapseAfter]="2"
          buttonClass="ml-2"
          (formCallback)="reload(true)"
          (postCallback)="reload(true)"
        />
      }
    </div>
  </div>
  @if (!!description) {
    <div class="desc-header">
      <div 
        class="description" 
        [ngStyle]="{'cursor': description.length > 70 && 'help'}"
        [matTooltip]="description.length > 70 ? description : ''"
        [matTooltipClass]="{truncated: description.length > 70}"
        matTooltipPosition="above">
        {{description.length > 70 ? (description.slice().substr(0, 71) + '...') : description }}
      </div>
    </div>
  }
  @if (!!descriptionPanelText) {
    <naris-card
      class="description-panel"
    >
      <naris-toolbar class="header">
        <naris-icon
            (click)="descriptionHeaderExpanded = !descriptionHeaderExpanded"
            icon="keyboard_arrow_right"
            class="mr-4"
            [class.open]="descriptionHeaderExpanded"
          /><h3 class="mr-05">{{'table.description' | translate}}</h3>
      </naris-toolbar>
      @if (descriptionHeaderExpanded) {
        <div
          class="content"
          [@inOutAnimation]>
          {{descriptionPanelText}}
        </div>
      }
    </naris-card>
  }
  
  @if (historyObject) {
    <naris-audit-execution-history [jsonObject]="historyObject" [name]="historyPanelName" />
  }
  @if (!isBranch && !isRoot) {
    <naris-audit-execution-matrix [panel]="samples" (changed)="panelUpdated(samples, $event)" [minNumOfSamples]="minNumOfSamples" [dataIsLoading]="loading" />
  }
  @for (panel of panels; track $index) {
    <naris-panel
      [panel]="panel"
      (updated)="panelUpdated($event)"
      [reloadPanel]="!!panel.reload"
      [isTableReload]="!!panel.reload"
    />
  } 
}
