import { Component, Input } from '@angular/core';
import { NgStyle } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { InputComponent } from '../input/input.component';
import type { FormControl, FormGroup } from '@angular/forms';
import type { FormInput } from '@core/classes';

@Component({
  selector: 'naris-multi-input',
  templateUrl: './multi-input.component.html',
  styleUrls: ['./multi-input.component.scss'],
  standalone: true,
  imports: [InputComponent, NgStyle, TranslateModule]
})
export class MultiInputComponent {
  @Input()
  public inputs: FormInput[] = [];

  @Input()
  public formGroup: FormGroup;

  public getPrepend(operator: string) {
    const op = operator.slice().toLowerCase();
    if (op.includes('greater') || op.includes('less')) {
      const dir = op.includes('greater') ? 'greater' : 'less';
      return `operator.${dir}${op.includes('equal') ? '_equal' : ''}`;
    }
  }

  public getFormControl(controlName: string) {
    return this.formGroup.get(controlName) as FormControl;
  }
}
