import { createClass as e, inherits as t, createSuper as n, classCallCheck as r, defineProperty as i, assertThisInitialized as a } from "../_virtual/_rollupPluginBabelHelpers.js";
import { isAssignableFrom as s } from "../core/util.js";
var l = {};
var o;
(function (e) {
  e["right"] = "right";
  e["left"] = "left";
})(o || (o = {}));
var u;
(function (e) {
  e["top"] = "top";
  e["bottom"] = "bottom";
})(u || (u = {}));
var f = function () {
  function t(e, n, a, s) {
    r(this, t);
    this.adapter = n;
    this.container = a;
    this.id = s;
    i(this, "incremental", void 0);
    i(this, "fixedElements", new Set());
    i(this, "floatedElements", new Set());
    i(this, "appendedElements", new Set());
  }
  e(t, [{
    key: "getFixedElement",
    value: function e(t) {
      return Array.from(this.fixedElements).find(function (e) {
        return e.id === t;
      });
    }
  }, {
    key: "getBounds",
    value: function e() {
      var t = this;
      var n = Infinity,
        r = -Infinity,
        i = Infinity,
        a = -Infinity;
      var s = function e(s) {
        var l = t.adapter.jsplumb.getPosition(s.el);
        var o = t.adapter.jsplumb.getSize(s.el);
        n = Math.min(n, l.x);
        r = Math.max(r, l.x + o.w);
        i = Math.min(i, l.y);
        a = Math.max(a, l.y + o.h);
      };
      this.fixedElements.forEach(s);
      this.floatedElements.forEach(s);
      this.appendedElements.forEach(s);
      return {
        x: n,
        y: i,
        w: r - n,
        h: a - i
      };
    }
  }, {
    key: "clear",
    value: function e() {
      var t = this;
      this.appendedElements.forEach(function (e) {
        return t.adapter.jsplumb._removeElement(e.el);
      });
      this.floatedElements.forEach(function (e) {
        return t.adapter.jsplumb._removeElement(e.el);
      });
      this.fixedElements.forEach(function (e) {
        return t.adapter.unfixElement(e.el);
      });
      this.appendedElements.clear();
      this.floatedElements.clear();
      this.fixedElements.clear();
    }
  }]);
  return t;
}();
var h = function (s) {
  t(o, s);
  var l = n(o);
  function o(e, t, n, s, u, f) {
    var h;
    r(this, o);
    h = l.call(this, t, n, s, u);
    h.methods = e;
    i(a(h), "incremental", void 0);
    h.incremental = f === true;
    return h;
  }
  e(o, [{
    key: "decorate",
    value: function e(t) {
      this.methods.decorate(t);
    }
  }, {
    key: "reset",
    value: function e(t) {
      this.methods.reset(t);
    }
  }]);
  return o;
}(f);
function c(e) {
  var t = function t(n, r, i, a) {
    var s = new e(n, r, i, a);
    return new h(s, n, r, i, a, s.incremental);
  };
  return t;
}
var m = {
  get: function e(t, n, r, i, a) {
    var s = l[t];
    if (!s) {
      throw {
        message: "JsPlumb: unknown decorator type '" + t + "'"
      };
    } else {
      return new s(n, r, i, a);
    }
  },
  register: function e(t, n) {
    if (s(n, f)) {
      l[t] = n;
    } else {
      l[t] = c(n);
    }
  }
};
export { o as AppendedElementHorizontalAlignmentValues, u as AppendedElementVerticalAlignmentValues, f as Decorator, m as Decorators, h as SyntheticDecorator };