<div class="assessment-control">
  <naris-icon
    [icon]="'circle'"
    class="icon icon-to-be-determined"
    [class.selected]="state?.id === 'ToBeDetermined'"
    (click)="selectAppraisal('ToBeDetermined')"
    [matTooltip]="'appraissal.to_be_determined' | translate"
  />
  <naris-icon
    [icon]="'circle-cancel'"
    class="icon icon-not-ok"
    [class.selected]="state?.id === 'NotOk'"
    (click)="selectAppraisal('NotOk')"
    [matTooltip]="'appraissal.not_ok' | translate"
  />
  <naris-icon
    [icon]="'circle-check'"
    class="icon icon-ok"
    [class.selected]="state?.id === 'Ok'"
    (click)="selectAppraisal('Ok')"
    [matTooltip]="'appraissal.ok' | translate"
  />
  <naris-icon
    [icon]="'circle-notification'"
    class="icon icon-not-applicable"
    [class.selected]="state?.id === 'NotApplicable'"
    (click)="selectAppraisal('NotApplicable')"
    [matTooltip]="'appraissal.not_applicable' | translate"
  />
  <naris-icon
    [icon]="'circle-question'"
    class="icon icon-indeterminable"
    [class.selected]="state?.id === 'Indeterminable'"
    (click)="selectAppraisal('Indeterminable')"
    [matTooltip]="'appraissal.indeterminable' | translate"
  />
</div>
