import { inherits as n, createSuper as e, classCallCheck as o, defineProperty as t, assertThisInitialized as c, createClass as r } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { EndpointFactory as s } from "./endpoint-factory.js";
import { EndpointRepresentation as i } from "./endpoint-representation.js";
import { COMPONENT_TYPE_ENDPOINT as a, Components as l, Component as d } from "../component/component.js";
import { AnchorLocations as p } from "../factory/definitions.js";
import { isString as u, extend as v } from "../../util/util.js";
import { DEFAULT as f } from "../../common/index.js";
import { Connections as y } from "../connector/connections.js";
var C = ["connectorStyle", "connectorHoverStyle", "connectorOverlays", "connector", "connectionType", "connectorClass", "connectorHoverClass"];
var h = function (s) {
  n(d, s);
  var i = e(d);
  function d(n, e) {
    var r;
    o(this, d);
    r = i.call(this, n, "_jsplumb_e", e);
    r.instance = n;
    t(c(r), "idPrefix", "_jsplumb_e");
    t(c(r), "objectType", a);
    t(c(r), "connections", []);
    t(c(r), "endpoint", void 0);
    t(c(r), "element", void 0);
    t(c(r), "elementId", void 0);
    t(c(r), "dragAllowedWhenFull", true);
    t(c(r), "timestamp", void 0);
    t(c(r), "portId", void 0);
    t(c(r), "maxConnections", void 0);
    t(c(r), "proxiedBy", void 0);
    t(c(r), "connectorClass", void 0);
    t(c(r), "connectorHoverClass", void 0);
    t(c(r), "finalEndpoint", void 0);
    t(c(r), "enabled", true);
    t(c(r), "isSource", void 0);
    t(c(r), "isTarget", void 0);
    t(c(r), "isTemporarySource", void 0);
    t(c(r), "connectionCost", 1);
    t(c(r), "connectionsDirected", void 0);
    t(c(r), "connectionsDetachable", void 0);
    t(c(r), "reattachConnections", void 0);
    t(c(r), "currentAnchorClass", void 0);
    t(c(r), "referenceEndpoint", void 0);
    t(c(r), "edgeType", void 0);
    t(c(r), "connector", void 0);
    t(c(r), "connectorOverlays", void 0);
    t(c(r), "connectorStyle", void 0);
    t(c(r), "connectorHoverStyle", void 0);
    t(c(r), "deleteOnEmpty", void 0);
    t(c(r), "uuid", void 0);
    t(c(r), "scope", void 0);
    t(c(r), "_anchor", void 0);
    t(c(r), "defaultLabelLocation", [.5, .5]);
    l.appendToDefaultType(c(r), {
      edgeType: e.edgeType,
      maxConnections: e.maxConnections == null ? r.instance.defaults.maxConnections : e.maxConnections,
      paintStyle: e.paintStyle || r.instance.defaults.endpointStyle,
      hoverPaintStyle: e.hoverPaintStyle || r.instance.defaults.endpointHoverStyle,
      connectorStyle: e.connectorStyle,
      connectorHoverStyle: e.connectorHoverStyle,
      connectorClass: e.connectorClass,
      connectorHoverClass: e.connectorHoverClass,
      connectorOverlays: e.connectorOverlays,
      connector: e.connector
    });
    r.enabled = !(e.enabled === false);
    r.visible = true;
    r.element = e.element;
    r.uuid = e.uuid;
    r.portId = e.portId;
    r.elementId = e.elementId;
    r.connectionCost = e.connectionCost == null ? 1 : e.connectionCost;
    r.connectionsDirected = e.connectionsDirected;
    r.currentAnchorClass = "";
    r.events = {};
    r.connectorOverlays = e.connectorOverlays;
    r.connectorStyle = e.connectorStyle;
    r.connectorHoverStyle = e.connectorHoverStyle;
    r.connector = e.connector;
    r.edgeType = e.edgeType;
    r.connectorClass = e.connectorClass;
    r.connectorHoverClass = e.connectorHoverClass;
    r.deleteOnEmpty = e.deleteOnEmpty === true;
    r.isSource = e.source || false;
    r.isTemporarySource = e.isTemporarySource || false;
    r.isTarget = e.target || false;
    r.connections = e.connections || [];
    r.scope = e.scope || n.defaultScope;
    r.timestamp = null;
    r.reattachConnections = e.reattachConnections || n.defaults.reattachConnections;
    r.connectionsDetachable = n.defaults.connectionsDetachable;
    if (e.connectionsDetachable === false) {
      r.connectionsDetachable = false;
    }
    r.dragAllowedWhenFull = e.dragAllowedWhenFull !== false;
    var s = e.endpoint || e.existingEndpoint || n.defaults.endpoint;
    m.setEndpoint(c(r), s);
    if (e.preparedAnchor != null) {
      m.setPreparedAnchor(c(r), e.preparedAnchor);
    } else {
      var u = e.anchor ? e.anchor : e.anchors ? e.anchors : n.defaults.anchor || p.Top;
      m.setAnchor(c(r), u);
    }
    var v = [f, e.type || ""].join(" ");
    l.addType(c(r), v, e.data);
    return r;
  }
  r(d, [{
    key: "getXY",
    value: function n() {
      return {
        x: this.endpoint.x,
        y: this.endpoint.y
      };
    }
  }, {
    key: "getDefaultOverlayKey",
    value: function n() {
      return "endpointOverlays";
    }
  }]);
  return d;
}(d);
var m = {
  _prepareEndpoint: function n(e, o, t) {
    var c = {
      cssClass: e.cssClass,
      endpoint: e
    };
    var r;
    if (o instanceof i) {
      var a = o;
      r = s.clone(a);
      r.classes = c.cssClass.split(" ");
    } else if (u(o)) {
      r = s.get(e, o, c);
    } else {
      var l = o;
      v(c, l.options || {});
      r = s.get(e, l.type, c);
    }
    r.typeId = t;
    return r;
  },
  setEndpoint: function n(e, o) {
    var t = m._prepareEndpoint(e, o);
    m._setPreparedEndpoint(e, t);
  },
  _setPreparedEndpoint: function n(e, o) {
    if (e.endpoint != null) {
      e.instance.destroyEndpoint(e);
    }
    e.endpoint = o;
  },
  addClass: function n(e, o, t) {
    l.addClass(e, o, t);
    if (e.endpoint != null) {
      e.endpoint.addClass(o);
    }
  },
  removeClass: function n(e, o, t) {
    l.removeClass(e, o, t);
    if (e.endpoint != null) {
      e.endpoint.removeClass(o);
    }
  },
  destroy: function n(e) {
    l.destroy(e);
    e.deleted = true;
    if (e.endpoint != null) {
      e.instance.destroyEndpoint(e);
    }
  },
  applyType: function n(e, o, t) {
    l.applyType(e, o, t);
    e.paintStyle = o.endpointStyle || o.paintStyle;
    l.setHoverPaintStyle(e, o.endpointHoverStyle || o.hoverPaintStyle);
    e.connectorStyle = o.connectorStyle;
    e.connectorHoverStyle = o.connectorHoverStyle;
    e.connector = o.connector;
    e.connectorOverlays = o.connectorOverlays;
    e.edgeType = o.edgeType;
    if (o.maxConnections != null) {
      e.maxConnections = o.maxConnections;
    }
    if (o.scope) {
      e.scope = o.scope;
    }
    v(o, C);
    e.instance.applyEndpointType(e, o);
  },
  setVisible: function n(e, o, t, c) {
    l.setVisible(e, o);
    e.endpoint.setVisible(o);
    if (!t) {
      for (var r = 0; r < e.connections.length; r++) {
        y.setVisible(e.connections[r], o);
        if (!c) {
          var s = e === e.connections[r].endpoints[0] ? 1 : 0;
          if (e.connections[r].endpoints[s].connections.length === 1) {
            m.setVisible(e.connections[r].endpoints[s], o, true, true);
          }
        }
      }
    }
  },
  deleteEveryConnection: function n(e, o) {
    var t = e.connections.length;
    for (var c = 0; c < t; c++) {
      e.instance.deleteConnection(e.connections[0], o);
    }
  },
  setAnchor: function n(e, o) {
    var t = e.instance.router.prepareAnchor(o);
    m.setPreparedAnchor(e, t);
    return this;
  },
  setPreparedAnchor: function n(e, o) {
    e.instance.router.setAnchor(e, o);
    m._updateAnchorClass(e);
    return e;
  },
  _updateAnchorClass: function n(e) {
    var o = e._anchor && e._anchor.cssClass;
    if (o != null && o.length > 0) {
      var t = e.instance.endpointAnchorClassPrefix + "-" + e.currentAnchorClass;
      e.currentAnchorClass = o;
      var c = e.instance.endpointAnchorClassPrefix + (e.currentAnchorClass ? "-" + e.currentAnchorClass : "");
      if (t !== c) {
        m.removeClass(e, t);
        m.addClass(e, c);
        e.instance.removeClass(e.element, t);
        e.instance.addClass(e.element, c);
      }
    }
  },
  detachFromConnection: function n(e, o, t, c) {
    t = t == null ? e.connections.indexOf(o) : t;
    if (t >= 0) {
      e.connections.splice(t, 1);
      e.instance._refreshEndpoint(e);
    }
    if (!c && e.deleteOnEmpty && e.connections.length === 0) {
      e.instance.deleteEndpoint(e);
    }
  },
  isFull: function n(e) {
    return e.maxConnections === 0 ? true : !(m.isFloating(e) || e.maxConnections < 0 || e.connections.length < e.maxConnections);
  },
  isFloating: function n(e) {
    return e.instance.router.isFloating(e);
  },
  _anchorLocationChanged: function n(e, o) {
    m._updateAnchorClass(e);
  },
  addConnection: function n(e, o) {
    e.connections.push(o);
    e.instance._refreshEndpoint(e);
  },
  connectorSelector: function n(e) {
    return e.connections[0];
  }
};
export { h as EndpointImpl, m as Endpoints };