import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SocketService } from '@core/services/socket.service';
import { StreamService } from '@core/services/stream.service';
import { CONNFIG } from '@core/constants/config-constants';
import { Participant } from '@core/classes';
import { environment } from '@src/environments/environment';
import type { TPeerConnections, IPeerData, IPeerEvent, IPeerUser, IRoomEvent } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class RtcService {

  public _endConnection$ = new Subject<void>();
  private _peerConnections: TPeerConnections = {};
  private readonly _peerUsers$ = new Subject<IPeerEvent>();
  private _currentRoom: string;

  constructor(private readonly streamService: StreamService, private readonly socketService: SocketService) { }

  get endConnection$(): Observable<void> {
    return this._endConnection$.asObservable();
  }

  get peerUsers$(): Observable<IPeerEvent> {
    return this._peerUsers$.asObservable();
  }

  get currentRoom(): string {
    return this._currentRoom;
  }

  public enterRoom(room: string, isHost: boolean, id?: string, name?: string) {
    const localStream = this.streamService.localStream;
    if (!!localStream) {
      this._currentRoom = room;
      this.socketService.self.host = isHost;
      this.socketService.self.id = id || '';
      this.socketService.self.name = name || '';
      this._peerUsers$.next({ type: 'joined', participant: new Participant(this.socketService.self, localStream) });
      this.socketService.peerListeners().pipe(takeUntil(this.endConnection$)).subscribe({
        next: async data => {
          switch (data.type) {
            case 'joined': await this.handleJoined(data); break;
            case 'left': this.handleLeft(data); break;
            case 'offer': await this.handleOffer(data); break;
            case 'answer': await this.handleAnswer(data); break;
            case 'candidate': await this.handleCandidate(data); break;
            default: this.logError('No type could be determined from data!');
          }
        },
        error: this.logError
      });
      this.socketService.joinRoom(room);
    }
  }

  public leaveRoom(room: string): void {
    this.streamService.localStream = null;
    this.socketService.leaveRoom(room);
    for (const id in this._peerConnections) {
      this._peerConnections[id].close();
      delete this._peerConnections[id];
    }
    this._endConnection$.next();
  }

  private async handleJoined(data: IRoomEvent): Promise<void> {
    const peerConnection = this.createPeerConnection(data.socketId, data.user);
    try {
      const offer = await peerConnection.createOffer();
      await peerConnection.setLocalDescription(offer);
      this.socketService.sendOffer(data.socketId, { type: offer.type, sdp: offer.sdp });
    } catch (e) {
      this.logError(e);
    }
  }

  private handleLeft(data: IRoomEvent): void {
    this._peerConnections[data.socketId]?.close();
    delete this._peerConnections[data.socketId];
    this._peerUsers$.next({ type: 'left', userId: data.user.id });
  }

  private async handleOffer(data: IPeerData): Promise<void> {
    const peerConnection = this.createPeerConnection(data.socketId, data.user);
    try {
      await peerConnection.setRemoteDescription(new RTCSessionDescription(data.offer!));
      const answer = await peerConnection.createAnswer();
      await peerConnection.setLocalDescription(answer);
      this.socketService.sendAnswer(data.socketId, { type: answer.type, sdp: answer.sdp });
    } catch (e) {
      this.logError(e);
    }
  }

  private async handleAnswer(data: IPeerData): Promise<void> {
    const peerConnection = this._peerConnections[data.socketId];
    try {
      await peerConnection.setRemoteDescription(new RTCSessionDescription(data.answer!));
    } catch (e) {
      this.logError(e);
    }
  }

  private async handleCandidate(data: IPeerData): Promise<void> {
    const peerConnection = this._peerConnections[data.socketId];
    try {
      await peerConnection.addIceCandidate(new RTCIceCandidate(data.candidate));
    } catch (e) {
      this.logError(e);
    }
  }

  private createPeerConnection(socketId: string, user: IPeerUser): RTCPeerConnection {
    const peerConnection = this._peerConnections[socketId] = new RTCPeerConnection(CONNFIG);
    const localStream = this.streamService.localStream;
    localStream?.getAudioTracks().forEach(track => peerConnection.addTrack(track, localStream));
    peerConnection.addEventListener('icecandidate', event => {
      if (!!event.candidate) this.socketService.sendCandidate(socketId, event.candidate.toJSON());
    });
    peerConnection.addEventListener('track', event => {
      const remoteStream = event.streams[0];
      const participant = new Participant(user, remoteStream);
      this._peerUsers$.next({ type: 'joined', participant });
    });
    return peerConnection;
  }

  private readonly logError = (err: unknown) => {
    if (environment.production) return;
    // eslint-disable-next-line no-console
    console.error(err);
  };
}
