var t;
(function (t) {
  t["top"] = "top";
  t["left"] = "left";
  t["right"] = "right";
  t["bottom"] = "bottom";
})(t || (t = {}));
var o = t.top;
var n = t.left;
var i = t.right;
var e = t.bottom;
var u = [n, i];
var r = [o, e];
var s;
(function (t) {
  t["Circle"] = "Circle";
  t["Ellipse"] = "Ellipse";
  t["Triangle"] = "Triangle";
  t["Diamond"] = "Diamond";
  t["Rectangle"] = "Rectangle";
  t["Square"] = "Square";
})(s || (s = {}));
var a;
(function (t) {
  t["Assign"] = "Assign";
  t["AutoDefault"] = "AutoDefault";
  t["Bottom"] = "Bottom";
  t["BottomLeft"] = "BottomLeft";
  t["BottomRight"] = "BottomRight";
  t["Center"] = "Center";
  t["Continuous"] = "Continuous";
  t["ContinuousBottom"] = "ContinuousBottom";
  t["ContinuousLeft"] = "ContinuousLeft";
  t["ContinuousRight"] = "ContinuousRight";
  t["ContinuousTop"] = "ContinuousTop";
  t["ContinuousLeftRight"] = "ContinuousLeftRight";
  t["ContinuousTopBottom"] = "ContinuousTopBottom";
  t["Left"] = "Left";
  t["Perimeter"] = "Perimeter";
  t["Right"] = "Right";
  t["Top"] = "Top";
  t["TopLeft"] = "TopLeft";
  t["TopRight"] = "TopRight";
})(a || (a = {}));
export { a as AnchorLocations, e as BOTTOM, t as FaceValues, n as LEFT, s as PerimeterAnchorShapes, i as RIGHT, o as TOP, u as X_AXIS_FACES, r as Y_AXIS_FACES };