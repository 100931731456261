var e = {};
var n = {
  construct: function n(r, t, o, u, s, c) {
    var i = e[r];
    if (!i) {
      throw {
        message: "Recado: unknown element '" + r + "'"
      };
    } else {
      return new i(t, o, u, s, c);
    }
  },
  register: function n(r, t) {
    e[r] = t;
  },
  exists: function n(r) {
    return e[r] != null;
  }
};
export { n as Elements };