var t = {};
function n(n, e) {
  t[n] = e;
}
var e = {
  lineIntersection: function n(e, o, r, i, u) {
    return t[e.type].lineIntersection(e, o, r, i, u);
  },
  boxIntersection: function n(e, o, r, i, u) {
    return t[e.type].boxIntersection(e, o, r, i, u);
  },
  boundingBoxIntersection: function n(e, o) {
    return t[e.type].boundingBoxIntersection(e, o);
  },
  pointOnPath: function n(e, o, r) {
    return t[e.type].pointOnPath(e, o, r);
  },
  gradientAtPoint: function n(e, o, r) {
    return t[e.type].gradientAtPoint(e, o, r);
  },
  pointAlongPathFrom: function n(e, o, r, i) {
    return t[e.type].pointAlongPathFrom(e, o, r, i);
  },
  findClosestPointOnPath: function n(e, o, r) {
    return t[e.type].findClosestPointOnPath(e, o, r);
  },
  getPath: function n(e, o) {
    return t[e.type].getPath(e, o);
  }
};
function o(n) {
  return t[n.type].getLength(n);
}
function r(n, e) {
  return t[n].create(e);
}
export { e as Segments, r as _createSegment, o as _getSegmentLength, n as _registerSegmentHandler };