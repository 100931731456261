<div #rootContainer [attr.data-test-id]="'table__' + tableName" class="grid-table">
    @if (tableSettings?.heading) {
      <naris-toolbar class="naris-table-toolbar">
        <naris-toolbar-item class="naris-table-toolbar-title">
          @if (!widgetConfig || !editMode || !titleEdit) {
            <h3 class="mr-8">{{tableTitle}}</h3>
          }
        </naris-toolbar-item>
        <naris-toolbar-item 
          class="ml-auto" 
          [style.position]="tableSettings?.child && 'absolute'"      
          [style.right.rem]="tableSettings?.child && '1'"
        >
          @if (tableSettings?.options?.includes('filter') && !!dataSource.filterMeta.length) {
            <naris-button
              color="white"
              icon="filter"
              [matTooltip]="'filter' | translate"
              matTooltipPosition="above"
              [active]="activeOption === 'filter'"
              (onMenuOpen)="activeOption = 'filter'; dataSource.optionsChanged = true;"
              (onMenuClose)="activeOption = null"
              [matMenuTriggerFor]="filterMenu"
              id="TableFilter"
            />
          }
          @if (tableSettings?.options?.includes('arrange') && (rootColumns?.length ?? -1) > 1) {
            <naris-button
              color="white"
              icon="preferences"
              [matTooltip]="'table.arrange_columns' | translate"
              matTooltipPosition="above"
              [active]="activeOption === 'arrange'"
              (onMenuOpen)="activeOption = 'arrange'; dataSource.optionsChanged = true;"
              (onMenuClose)="activeOption = null"
              [matMenuTriggerFor]="columnMenu"
              id="TableArrangeColumns"
            />
          }
          <mat-menu #filterMenu="matMenu" xPosition="before" class="naris-table-menu">
            <div class="naris-table-filter" (click)="$event.stopPropagation(); filterFormClick($event)" tabindex="0">
              <form [formGroup]="dataSource.filterForm">
                @for (input of dataSource.filterMeta; track $index) {
                  <app-form-input
                    [input]="input"
                    [formGroup]="dataSource.filterForm"
                    (enterPressed)="dataSource.handleFilter(dataSource.filterForm.get(input.id)?.value, input)"
                    [isFilter]="true"
                    [formId]="tableName + 'Filter'"
                  />
                }
              </form>
            </div>
          </mat-menu>
          <mat-menu #columnMenu="matMenu" xPosition="before" class="naris-table-menu">
            <div class="naris-drop-list" cdkDropList (cdkDropListDropped)="drop($event)">
              @for (column of rootColumns; track trackByID) {
                <div
                  (click)="$event.stopPropagation()"
                  class="naris-drag-item"
                  tabindex="0"
                  [ngClass]="{active: columnsToDisplay.includes(column.id.toString())}"
                  cdkDrag
                  [cdkDragDisabled]="!columnsToDisplay.includes(column.id.toString())"
                >
                  <div class="naris-drag-placeholder" *cdkDragPlaceholder></div>
                  <naris-icon icon="drag" cdkDragHandle class="naris-drag-icon" />
                  <naris-slidetoggle
                    [disabled]="activeColumns.length <= 1 && !!activeColumns[0] && activeColumns[0].id === column.id"
                    class="slide-toggle"
                    [checked]="column.active"
                    (toggled)="toggleColumn(column)"
                    [id]="'TableToggleColumn'"
                  >
                    {{column.label}}
                  </naris-slidetoggle>
                </div>
              }
            </div>
          </mat-menu>
        </naris-toolbar-item>
      </naris-toolbar>
    }
    
    <div 
      [ngClass]="{'modal-wrapper': isModal}" 
      [style.height]="error ? '0px' : 'auto'"
      #modalWrapper 
      [style.position]="!tableSettings?.child && 'relative'"
      [style.width.px]="tableSettings?.child ? rootWidth - (childTableIndents * 33) : null"
    >
      @if (!tableLayouthint?.includes('list-summary')) {
        <div
          class="naris-table-wrapper"
          [ngStyle]="{'height.px': !!widgetPanelSize?.height ? widgetPanelSize!.height! - 96 : null}"
        >
          <table
            mat-table
            matSort
            matSortDisabled
            class="naris-table-element"
            [dataSource]="dataSource"
            [trackBy]="trackByID"
            multiTemplateDataRows
          >
            @for (column of rootColumns; track $index) {
              <ng-container
                [matColumnDef]="column.id.toString()"
              >
                <th
                  mat-header-cell
                  mat-sort-header
                  *matHeaderCellDef
                  class="naris-table-dynamic-column grid-table__th"
                >
                  @if (tableSettings?.check && columnsToDisplay.indexOf(column.id.toString()) === 0) {
                    <naris-checkbox
                      class="naris-table-checkbox"
                      (click)="$event.stopPropagation()"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="isIndeterminate()"
                      (changed)="$event ? masterToggle() : null"
                      [id]="'TableColumnsToDisplay' + column.id"
                      
                    />
                  }
                  @if ((tableSettings?.parentTable || tableSettings?.nestedTable) && columnsToDisplay.indexOf(column.id.toString()) === 0) {
                    <div class="naris-table-no-child"></div>
                  }
                  <span
                    (click)="sortableColumns.includes(column.id.toString()) && tableSettings?.sorting && !refreshing ? dataSource.sortColumn(column) : null"
                    tabindex="0"
                    class="naris-table-dynamic-column-header"
                    [ngClass]="{sortable: sortableColumns.includes(column.id.toString()) && tableSettings?.sorting}"
                  >
                    {{column.label}}
                    <!-- @if (sortableColumns.includes(column.id.toString()) && tableSettings?.sorting) {
                      <naris-icon
                        class="sort-icon"
                        [ngClass]="{
                          desc: dataSource.sortingMeta.sortDirection !== 'asc' && dataSource.sortingMeta.sortedColumn === column.id,
                          active: dataSource.sortingMeta.sortedColumn === column.id
                        }"
                        icon="keyboard_arrow_down"
                      />
                    } -->
                  </span>
                </th>
                <td
                  *matCellDef="let row;"
                  mat-cell
                  class="grid-table__td"
                  [ngClass]="{
                    blocked: isBlocked(row), 
                    'full-text': column.layouthint?.includes('full-text'), 
                    'not-read': !row.NotificationRead && isMessagesTable, 
                    'read': !!row.NotificationRead && isMessagesTable,
                    'disabled': (router.url.includes('archive') && (row.Archive !== null && row.Archive !== undefined) && !row.Archive) || (!router.url.includes('archive') && !!row.Archive)
                  }"
                  [matTooltip]="(router.url.includes('archive') && (row.Archive !== null && row.Archive !== undefined) && !row.Archive ? 'not-archived' : !router.url.includes('archive') && !!row.Archive ? 'archived' : '') | translate"
                  #tableData
                >
                  <div class="naris-table-td">
                    <ng-container [formGroup]="rowForm">
                        @if (![true, false].includes(row[column.id])) {
                          <span class="data-cell">
                            @if (!column.layouthint?.includes('color') && !column.layouthint?.includes('rich_text')) {
                              @if (!column.layouthint?.includes('full-text')) {
                                @if (!column.layouthint?.includes('chips')) {
                                  <span
                                    [ngStyle]="{'cursor': showTooltip(row[column.id]) && 'help'}"
                                    [matTooltip]="showTooltip(row[column.id]) ? mapCellVal(row[column.id], false) : ''"
                                    [matTooltipClass]="{truncated: showTooltip(row[column.id])}"
                                    matTooltipPosition="above"
                                  >
                                    {{ mapCellVal(row[column.id], true, row, column) || '-' }}
                                  </span>
                                } @else {
                                  <span
                                    class="tag-column-data"
                                    [ngStyle]="{'cursor': hasOverflow(tagSpan) && 'help'}"
                                    [matTooltip]="hasOverflow(tagSpan) ? mapCellVal(row[column.id], false, row, column) : ''"
                                    [matTooltipClass]="{truncated: hasOverflow(tagSpan)}"
                                    matTooltipPosition="above"
                                    #tagSpan
                                  >
                                    {{ mapCellVal(row[column.id], true, row, column) }}
                                  </span>
                                }
                              } @else {
                                <span>{{ mapFullTextVal(row[column.id]) || '-' }}</span>
                              }
                            }
                            @if ((isArray(row[column.id]) && row[column.id].length > 3) && !column.layouthint?.includes('rich_text')) {
                              <a
                                class="data-cell-all"
                                tabindex="0"
                                [matMenuTriggerFor]="rowMenu"
                                (click)="$event.stopPropagation()"
                              >
                                {{'table.show_all' | translate}} {{row[column.id].length}}
                              </a>
                            }
                          </span>
                        } @else {
                          <naris-icon
                            [icon]="row[column.id] ? 'check' : 'close'"
                            [iconClass]="row[column.id] ? 'green' : 'warn'"
                            class="boolean-icon"
                          />
                        }
                        @if (!!row.IsPrivate && columnsToDisplay.indexOf(column.id.toString()) === 0) {
                          <span>
                            <naris-icon
                              [matTooltip]="'table.object_is_private' | translate"
                              matTooltipPosition="right"
                              class="private"
                              icon="visibility_off"
                            />
                          </span>
                        }
                        @if (column.title && !!getDesc(row)) {
                          <span>
                            <naris-icon
                              class="desc-icon"
                              [matTooltip]="('table.description' | translate) + ':&#13;&#13;' + getDesc(row)"
                              matTooltipPosition="above"
                              matTooltipClass="desc-icon-tooltip"
                              icon="info-outline"
                            />
                          </span>
                        }
                        @if (column.title && !!getRelatedRecordType(row)?.length) {
                          <span class="pl-2">
                            @for (icon of getRelatedRecordType(row); track $index) {
                              <naris-icon
                                class="desc-icon available-icon"
                                [matTooltip]="getIconLabel(icon)"
                                matTooltipPosition="above"
                                matTooltipClass="desc-icon-tooltip"
                                [icon]="getIconName(icon)"                    
                                (click)="$event.stopPropagation(); clickAvailable(row, icon);"
                              />
                            }
                          </span>
                        }
                    </ng-container>
                    <mat-menu
                      class="naris-table-menu--row"
                      [ngClass]="{text: typeOf(row[column.id]) === 'string'}"
                      #rowMenu="matMenu"
                      xPosition="after"
                      yPosition="above"
                    >
                      @if (isArray(row[column.id])) {
                        <table>
                          @for (row of row[column.id]; track $index) {
                            <tr>
                              @if (isArray(row)) {
                                @for (item of row; track $index) {
                                  <td>{{item}}</td>
                                }
                              } @else {
                                <td>{{row}}</td>
                              }
                            </tr>
                          }
                        </table>
                      }
                      @if (typeOf(row[column.id]) === 'string') {
                        <p>{{row[column.id]}}</p>
                      }
                    </mat-menu>
                  </div>
                </td>
              </ng-container>
            }
            @if (hasRowActions()) {
              <ng-container
                matColumnDef="actions"
              >
                <th
                  #actionColumnHeader
                  mat-header-cell
                  *matHeaderCellDef
                  class="naris-table-actions-column"
                  [style.visibility]="hasRowActions() && !tableSettings?.parentTable && tableSettings?.child && stickyColumns.includes('actions') && 'hidden'"
                >
                  <div class="naris-table-th">
                    {{'table.actions' | translate}}
                    <naris-button
                      size="small"
                      icon="pin"
                      color="transparent"
                      class="naris-table-pin"
                      [active]="stickyColumns.includes('actions')"
                      [matTooltip]="(stickyColumns.includes('actions') ? 'table.detach' : 'table.attach') | translate"
                      matTooltipPosition="above"
                      matTooltipShowDelay="200"
                      (click)="$event.stopPropagation(); stick('actions');"
                      id="TableAttachDetach"
                    />
                  </div>
                </th>
                <td
                  mat-cell
                  *matCellDef="let row;"
                  class="naris-table-actions-column"
                  [style.visibility]="hasRowActions() && !tableSettings?.parentTable && tableSettings?.child && stickyColumns.includes('actions') && 'hidden'"
                >
                  @if (tableSettings?.inlineEdit && editRow === row) {
                    <naris-icon
                      class="naris-table-actions-edit"
                      icon="check_circle"
                      iconClass="green"
                      [matTooltip]="'Bestätigen' | translate"
                      matTooltipPosition="above"
                      matTooltipShowDelay="200"
                      (click)="$event.stopPropagation(); onConfirmEdit(row)"
                    />
                    <naris-icon
                      class="naris-table-actions-edit"
                      icon="cancel"
                      iconClass="warn"
                      [matTooltip]="'cancel' | translate"
                      matTooltipPosition="above"
                      matTooltipShowDelay="200"
                      (click)="$event.stopPropagation(); onCancelEdit()"
                    />
                  } @else {
                    @for (action of row.actions; track $index) {
                      <naris-button
                        [icon]="getIconType(action.name)"
                        [matTooltip]="(action.label || action.name) | translate"
                        matTooltipPosition="above"
                        matTooltipShowDelay="200"
                        size="small"
                        color="transparent"
                        [disabled]="(!action.href && action.name !== 'hamburger_menu') || action.layouthint?.includes('readonly')"
                        (clicked)="$event.stopPropagation(); onClickAction(action, row, $event)"
                        [ngStyle]="{'display': action.layouthint?.includes('hide') && 'none'}"
                        [id]="'Table' + action.name"
                      />
                    }
                  }
                </td>
              </ng-container>
            }
            @if (hasRowActions() && tableSettings?.child && stickyColumns.includes('actions')) {
              <ng-container
                matColumnDef="actions_sticky"
              >
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="naris-table-actions-column nested-action"
                  [style.width.px]="actionColumnHeaderElement ? actionColumnHeaderElement.nativeElement.offsetWidth : ''"
                >
                  <div class="naris-table-th">
                    {{'table.actions' | translate}}
                    <naris-button
                      size="small"
                      icon="pin"
                      color="transparent"
                      class="naris-table-pin"
                      [active]="stickyColumns.includes('actions')"
                      [matTooltip]="(stickyColumns.includes('actions') ? 'table.detach' : 'table.attach') | translate"
                      matTooltipPosition="above"
                      matTooltipShowDelay="200"
                      (click)="$event.stopPropagation(); stick('actions');"
                      id="TableChildAttachDetach"
                    />
                  </div>
                </th>
                <td
                  mat-cell
                  *matCellDef="let row;"
                  class="naris-table-actions-column nested-action"
                  [style.width.px]="actionColumnHeaderElement ? actionColumnHeaderElement.nativeElement.offsetWidth : ''"
                >
                  @if (tableSettings?.inlineEdit && editRow === row) {
                    <naris-icon
                      class="naris-table-actions-edit"
                      icon="check_circle"
                      iconClass="green"
                      [matTooltip]="'Bestätigen' | translate"
                      matTooltipPosition="above"
                      matTooltipShowDelay="200"
                      (click)="$event.stopPropagation(); onConfirmEdit(row)"
                    />
                    <naris-icon
                      class="naris-table-actions-edit"
                      icon="cancel"
                      iconClass="warn"
                      [matTooltip]="'cancel' | translate"
                      matTooltipPosition="above"
                      matTooltipShowDelay="200"
                      (click)="$event.stopPropagation(); onCancelEdit()"
                    />
                  } @else {
                    @for (action of row.actions; track $index) {
                      <div>
                        @if (action.name !== 'hamburger_menu') {
                          <naris-button
                            [icon]="getIconType(action.name)"
                            [matTooltip]="(action.label || action.name) | translate"
                            matTooltipPosition="above"
                            matTooltipShowDelay="200"
                            size="small"
                            color="transparent"
                            [disabled]="!action.href || action.layouthint?.includes('readonly')"
                            (clicked)="$event.stopPropagation(); onClickAction(action, row, $event)"
                            [ngStyle]="{'display': action.layouthint?.includes('hide') && 'none'}"
                            [id]="'Table' + action.name"
                          />
                        }
                        @if (action.name === 'hamburger_menu') {
                          <naris-button
                            icon="hamburger"
                            [matTooltip]="(action.label || action.name) | translate"
                            matTooltipPosition="above"
                            matTooltipShowDelay="200"
                            size="small"
                            color="transparent"
                            [matMenuTriggerFor]="rowMenu"
                            #menuTrigger="matMenuTrigger"
                            (mouseenter)="menuTrigger.openMenu()"
                            (mouseleave)="hamburgerCloseActionMenu(menuTrigger)"
                            [id]="'Table' + action.name"
                          />
                        }
                        <mat-menu #rowMenu="matMenu" [hasBackdrop]="false" xPosition="before" class="naris-table-menu">
                          <div (mouseenter)="menuOpenActionMenu()" (mouseleave)="menuCloseActionMenu()">
                            @for (childAction of action.children; track $index) {
                              <div (click)="$event.stopPropagation(); onClickAction(childAction, row, $event)" class="action-menu-item" tabindex="0">
                                <naris-icon [icon]="getIconType(childAction.name)" iconClass="small" />
                                <button mat-menu-item type="button">{{childAction.label || childAction.name | translate}}</button>
                              </div>
                            }
                          </div>
                        </mat-menu>
                      </div>
                    }
                  }
                </td>
              </ng-container>
            }
            <ng-container matColumnDef="noResult">
              <td
                class="no-result-cell"
                mat-footer-cell 
                *matFooterCellDef 
                [attr.colspan]="columnsToDisplay.length"
              >
                @if (isEmpty && !loading && !error && !dataSource.activeFilters.length && !refreshing && !tableSettings?.child) {
                  <div
                    class="naris-table-empty-state"
                    [ngClass]="{'naris-table-empty-border': !tableCreateAction && !lookupEndpoint && !!tableActions, 'expanded': expanded}"
                  >
                    <naris-empty-state
                      [text]="'table.no_items_of_kind' | translate"
                      [image]="'empty'"
                      [padding]="0"
                    />
                  </div>
                }
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="columnsToDisplay;"
              class="naris-table-tr grid-table__tr"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; let odd = odd; let last = last; columns: columnsToDisplay;"
              class="naris-table-tr grid-table__tr"
              [ngClass]="{oddrow: odd, lastrow: last, clickable: isModal || clickable || tableSettings?.modalChild, blurred: refreshing}"
              [formGroup]="rowForm"
              (click)="onRowClicked(row)"
            ></tr>
            <tr
              mat-footer-row
              *matFooterRowDef="['noResult']"
              class="naris-table-tr no-result grid-table__tr"
              [ngClass]="{closed: !isEmpty, blurred: refreshing}"
            ></tr>
          </table>
        </div>
      }
    </div>
  </div>
  
  @if (error && !loading && !tableSettings?.child) {
    <div class="naris-table-empty-state">
      <naris-icon icon="error" iconClass="medium accent" />
      <p>{{'table.dat_not_loaded' | translate}}</p>
    </div>
  }

  