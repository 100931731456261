<div class="process-archimate-inspector">
  <div class="process-archimate-inspector__wrapper">
    @if (currentType === '') {
      <div></div>
    }
    @if (currentType === 'Edge') {
      <div class="process-archimate-inspector__actions">
        @if (!!deleteAction) {
          <naris-button
            icon="delete"
            size="small"
            color="transparent"
            (clicked)="remove()"
          />
        }
        <naris-button
          icon="close"
          color="transparent"
          size="small"
          (clicked)="close()"
        />
      </div>
      @if (!isJunctionSource) {
        <div class="process-archimate-inspector__form-field">
          <label class="process-archimate-inspector__form-field__label" for="edgeType">Edge type:</label>
          <div class="process-archimate-inspector__form-field__wrapper">
            <input 
              class="process-archimate-inspector__form-field__input" 
              type="text" 
              id="edgeType"
              matAutocompletePosition="below"
              [value]="obj.data.lineStyle"
              [matAutocomplete]="auto"
            >
            <mat-autocomplete 
              #auto="matAutocomplete"
              (optionSelected)="edgeTypeChanged($event)"
            >
              <mat-option>-- None --</mat-option>
              @for (relation of filteredRelations; track $index) {
                <mat-option [value]="relation.label">{{relation.label}}</mat-option>
              }
            </mat-autocomplete>
          </div>
        </div>
      }
    }
    @if (currentType === 'Node') {
      <div class="process-archimate-inspector__header">
        <div class="process-archimate-inspector__header__title">
          {{title}}
        </div>
        <div class="process-archimate-inspector__header__actions">
          <naris-button
            icon="delete"
            size="small"
            color="transparent"
            (clicked)="remove()"
          />
          <naris-button
            icon="close"
            color="transparent"
            size="small"
            (clicked)="close()"
          />
        </div>
      </div>      
      @if (!!updateAction && !isJunction) {
        <app-form
          [endpoint]="updateAction.href"
          [isArchimate]="true"
          [isProcessToolkitForm]="true"
          [class.no-padding]="true"
        />
      }
      @if (isJunction) {
        <div class="process-archimate-inspector__form-field">
          <label class="process-archimate-inspector__form-field__label" for="edgeType">Junction type:</label>
          <div class="process-archimate-inspector__form-field__wrapper">
            <input 
              class="process-archimate-inspector__form-field__input" 
              type="text" 
              id="edgeType"
              matAutocompletePosition="below"
              [value]="obj.data.connectionType"
              [matAutocomplete]="auto"
            >
            <mat-autocomplete 
              #auto="matAutocomplete"
              (optionSelected)="junctionTypeChanged($event)"
            >
              <mat-option>-- None --</mat-option>
              @for (relation of filteredRelations; track $index) {
                <mat-option [value]="relation.label">{{relation.label}}</mat-option>
              }
            </mat-autocomplete>
          </div>
        </div>
      }
    }
  </div>
</div>
