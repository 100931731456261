import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { InputComponent } from '../../elements/input/input.component';

@Component({
  selector: 'naris-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  standalone: true,
  imports: [InputComponent, TranslateModule]
})
export class TimepickerComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  public internalFormgroup: FormGroup;

  public isFocused = false;

  @Input()
  public control: FormControl;

  @ViewChild('minuteElement')
  public minuteElement: HTMLElement;

  @Input() public requiredIfVisible = false;

  @Input()
  public disabled: boolean;

  @Input()
  public id: string;

  // return format has to be ##:##:##

  public ngOnInit(): void {
    this.internalFormgroup = new FormGroup({
      hours: new FormControl(''),
      minutes: new FormControl('')
    }, this.timeValidator());

    if (this.control && this.requiredIfVisible) setTimeout(() => {
      this.control.setValidators([Validators.required]);
      this.control.updateValueAndValidity();
    }, 0);

    this.subscriptions.push(this.internalFormgroup.statusChanges.subscribe(() => {
      // if value is invalid, set actual control invalid
      if (this.internalFormgroup.status !== 'VALID') this.control.setErrors({invalid: true});
      else this.control.setErrors(null);
    }));

    // if the status of the internal formgroup changes, change the status of the actual control
    this.subscriptions.push(this.internalFormgroup.valueChanges.subscribe(x => {
      const selectedHours = x.hours ? x.hours.padStart(2, '0').slice(0, 2) : '00';       // limit input to two digits,
      const selectedMinutes = x.minutes ? x.minutes.padStart(2, '0').slice(0, 2) : '00'; // add leading zero if only one digit is entered
      const selectedTime = `${selectedHours}:${selectedMinutes}:00`;
      this.control.setValue(selectedTime);
    }));

    this.subscriptions.push(this.internalFormgroup.controls['hours'].valueChanges.subscribe(x => {
      if (x.length === 2) this.minuteElement?.focus();
    }));

    this.initValues();
  }

  private initValues() {
    if (this.control.value !== null) {
      const splittedTime = this.control.value.split(':');
      this.internalFormgroup.controls['hours'].setValue(splittedTime[0]);
      this.internalFormgroup.controls['minutes'].setValue(splittedTime[1]);
    }
  }

  /**
   * timValidators checks both input fields to be valid
   */
  private timeValidator(): ValidatorFn {
    return (fg): Record<string, any> | null => {
      const formGroup = fg as FormGroup;
      const hoursValue = formGroup.controls['hours'].value;
      const minutesValue = formGroup.controls['minutes'].value;
      const valid = hoursValue !== null && hoursValue !== '' && (minutesValue !== null && minutesValue !== '');
      const controlValue = `${hoursValue}:${minutesValue}`;
      return valid ? null : {valueNotValid: controlValue};
    };
  }

  public focusChanged(event: FormControl) {
    // name of the control needs to be resolved
    let controlName: string | null = null;
    const parentControls = (event.parent?.controls || {}) as Record<string, AbstractControl>;
    const controlKeys = Object.keys(parentControls);
    controlKeys.forEach(key => {
      if (parentControls[key] === event) controlName = key;
    });
    this.isFocused = controlName === 'hours' || controlName === 'minutes';
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    if (this.control && this.requiredIfVisible) {
      this.control.clearValidators();
      this.control.updateValueAndValidity();
    }
  }

  public getFormControl(controlName: string) {
    return this.internalFormgroup.get(controlName) as FormControl;
  }
}
