import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { CompareService } from '@core/services/compare.service';
import { NgClass } from '@angular/common';
import { ToFrequencyStringPipe } from '@shared/pipes/frequency.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../elements/button/button.component';
import { ProgressSpinnerComponent } from '../../../elements/progress-spinner/progress-spinner.component';
import type { ISameValue, TValOrArr } from '@core/models';

@Component({
  selector: 'naris-json-compare-input',
  templateUrl: './json-compare-input.component.html',
  styleUrls: ['./json-compare-input.component.scss'],
  animations: [
    trigger('slideIn', [
      transition('void => *', [
        style({ opacity: '0', transform: 'translateY(50px)' }),
        animate('500ms')
      ])
    ])
  ],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass, ButtonComponent, ProgressSpinnerComponent, ToFrequencyStringPipe, TranslateModule]
})
export class JSONCompareInputComponent {

  public restructuredData: Record<string, any>[] = [];
  private readonly sameValueArray: ISameValue[] = [];
  private timer: NodeJS.Timeout;
  public hasData: boolean;

  @Input() public noColors = true;

  @Input() set data(value: Record<string, any> | null) {
    this.restructuredData = [];
    this.hasData = false;
    if (!!value) {
      this.hasData = true;
      this.structureData(value);
    }
  }

  @Input() public selectedItems: Record<string, any>[];
  @Output() public readonly selectedItemsChange = new EventEmitter<Record<string, any>[]>();

  @Input() public sameValueItems: any[];
  @Output() public readonly sameValueItemsChange = new EventEmitter<ISameValue[]>();

  @Input() public pickChanges = false;
  @Input() public tableLabels: string[];

  @Input() public showAcceptButtons = false;

  @Input() public animationDisabled = false;

  @Input() public readOnlyKey: string | undefined;

  constructor(
    private readonly compareService: CompareService
  ) {  }

  private structureData(unstructuredData: Record<string, TValOrArr<Record<string, any>>> = {}) {
    const dataKeys = Object.keys(unstructuredData);
    const compareKeys: string[] = [];
    dataKeys.forEach(key => {
      const compareKey = key.split('_');
      if (!compareKeys.includes(compareKey[1])) compareKeys.push(compareKey[1]);
    });
    const tempData: Record<string, any>[] = [];
    compareKeys.forEach(key => {
      if (!unstructuredData[`OLD_${key}`]) return;
      const oldData = ['properties', 'testdefinition', 'implementationproperties'].includes(key.toLowerCase()) ? (unstructuredData[`OLD_${key}`] as Record<string, any>[])[0] : unstructuredData[`OLD_${key}`];
      const newData = ['properties', 'testdefinition', 'implementationproperties'].includes(key.toLowerCase()) ? (unstructuredData[`NEW_${key}`] as Record<string, any>[])[0] : unstructuredData[`NEW_${key}`];

      if (key.toLowerCase() === 'controltest') {
        oldData.forEach((data: any, index: number) => {
          this.fillTempData(tempData, data, (newData as any[])[index], key);
          const foundDataItem = tempData.find(item => item.name.toLowerCase() === 'controltest');
          if (!!foundDataItem) {
            const newName = `${foundDataItem.name} - ${data.TestTypeName}`;
            foundDataItem.name = newName;
          }
        });
      } else this.fillTempData(tempData, oldData, newData, key);
    });
    let index = 0;
    this.timer = setInterval(() => {
      if (index < tempData.length) {
        this.restructuredData.push(tempData[index]);
        index++;
        if (this.pickChanges) this.setOldAsSelected();
      } else clearInterval(this.timer);
    }, this.animationDisabled ? 0 : 250);
    this.sameValueItemsChange.emit(this.sameValueArray);
  }

  private fillTempData(tempData: Record<string, any>[], oldData: any, newData: any, key: string) {
    if (!!oldData && !!newData && (['properties', 'frequency', 'frequencyimplementation', 'testdefinition', 'implementationproperties'].includes(key.toLowerCase()) || !Array.isArray(oldData))) {
      const objectKeys = Object.keys(oldData);
      const oldDataObj = oldData as Record<string, any>;
      const newDataObj = newData as Record<string, any>;
      objectKeys.forEach(objectKey => {
        if (!Array.isArray(oldDataObj[objectKey])) this.checkNotArrayValue(oldDataObj, newDataObj, tempData, key, objectKey);
        if (Array.isArray(oldDataObj[objectKey])) {
          const arrayData: Record<string, Record<string, any>[]>[] = [];
          this.compareArrays(oldDataObj[objectKey], newDataObj[objectKey], arrayData, objectKey);
          let compareObject = tempData.find(obj => obj.name === key);
          const isNewObject = !compareObject;
          if (isNewObject) compareObject = {name: key};
          if (!compareObject?.['compareData']) compareObject!['compareData'] = [];
          arrayData.forEach(a => {
            a.compareData.forEach(comp => {
              compareObject?.['compareData'].push({key: objectKey, old: comp['old'], new: comp['new']});
            });
          });
          if (isNewObject && arrayData?.length > 0) tempData.push(compareObject!);
        }
      });
    } else this.compareArrays(oldData as Record<string, any>[], newData as Record<string, any>[], tempData, key);
  }

  private checkNotArrayValue(oldData: Record<string, any>, newData: Record<string, any>, tempData: Record<string, any>[], key: string, objectKey: string) {
    const oldDataObject = JSON.stringify(oldData[objectKey]);
    const newDataObject = JSON.stringify(newData[objectKey]);
    if (oldDataObject !== newDataObject) {
      let compareObject = tempData.find(obj => obj.name === key);
      const isNewObject = !compareObject;
      if (isNewObject) compareObject = {name: key};
      if (!compareObject?.['compareData']) compareObject!['compareData'] = [];
      compareObject?.['compareData'].push({key: key.toLowerCase() === 'frequency' ? '' : objectKey, old: oldData[objectKey], new: newData[objectKey]});
      if (isNewObject) tempData.push(compareObject!);
    }
  }

  private compareArrays(oldArray: Record<string, any>[], newArray: Record<string, any>[], tempData: Record<string, any>[], objectKey: string) {
    const oldCompareArray: string[] = [];
    const newCompareArray: string[] = [];
    const stringifiedOldArray = oldArray?.map(item => JSON.stringify(item));
    const stringifiedNewArray = newArray?.map(item => JSON.stringify(item));
    stringifiedOldArray?.forEach(oldString => {
      if (!stringifiedNewArray?.find(newString => newString === oldString)) oldCompareArray.push(oldString);
    });
    stringifiedNewArray?.forEach(newString => {
      if (!stringifiedOldArray?.find(oldString => oldString === newString)) newCompareArray.push(newString);
    });
    const sameValueObject = {name: objectKey, isArray: true, compareData: [] as Record<string, string>[]};
    stringifiedOldArray?.forEach(oldString => {
      if (stringifiedNewArray?.find(newString => newString === oldString)) sameValueObject['compareData'].push({value: JSON.parse(oldString)});
    });
    this.sameValueArray.push(sameValueObject);
    const compareObject = {name: objectKey, isArray: true, compareData: [] as Record<string, any>};
    oldCompareArray.forEach(oldie => {
      compareObject['compareData'].push({key: null, old: JSON.parse(oldie), new: '-'});
    });
    newCompareArray.forEach(newbie => {
      compareObject['compareData'].push({key: null, old: '-', new: JSON.parse(newbie)});
    });
    if (!!compareObject['compareData']?.length) tempData.push(compareObject);
  }

  public objectToString(obj: any) {
    if (typeof obj === 'object' && obj !== null) {
      let returnString = '';
      const objectKeys = Object.keys(obj);
      objectKeys.forEach(key => {
        if (!key.toLowerCase().endsWith('id')) {
          if (returnString.length > 0)
            returnString += ', ';
          returnString += obj[key];
        }
      });
      return returnString;
    } else return obj;
  }

  public selectedVersion(data: Record<string, Record<string, any>[]>, versionData: Record<string, any>, isNew: boolean) {
    if (!this.pickChanges || this.isReadonly(versionData.key || data.name)) return;
    this.addToSelection(data, versionData, isNew);
  }

  private addToSelection(data: Record<string, Record<string, any>[]>, versionData: Record<string, any>, isNew: boolean) {
    data.compareData.forEach(item => {
      if (JSON.stringify(versionData.key) === JSON.stringify(item.key) && JSON.stringify(versionData.new) === JSON.stringify(item.new) && JSON.stringify(versionData.old) === JSON.stringify(item.old)) {
        item['isNewSelected'] = isNew;
        const foundSelected = this.selectedItems.find(sel => JSON.stringify(versionData.key) === JSON.stringify(sel.key) && JSON.stringify(versionData.new) === JSON.stringify(sel.new) && JSON.stringify(versionData.old) === JSON.stringify(sel.old));
        if (foundSelected) foundSelected['isNewSelected'] = isNew;
        else this.selectedItems.push({parent: data.name, ...item});
      }
    });
    this.selectedItemsChange.emit(this.selectedItems);
  }

  private setOldAsSelected() {
    this.selectedItems = [];
    this.restructuredData.forEach(restructuredItem => {
      (restructuredItem.compareData as Record<string, any>[]).forEach(itemData => {
        itemData['isNewSelected'] = false;
        const selectedItem = {parent: restructuredItem.name, ...itemData};
        this.selectedItems.push(selectedItem);
      });
    });
    this.selectedItemsChange.emit(this.selectedItems);
  }

  public accept(isAccepted: boolean): void {
    this.compareService.updateAccepted$.next(isAccepted);
  }

  public isReadonly(name: string, data?: Record<string, Record<string, any>[]>, versionData?: Record<string, any>) {
    const returnValue = this.readOnlyKey?.split(',').includes(name);
    if (returnValue && !!data && !!versionData) {
      this.addToSelection(data, versionData, true);
    }
    return returnValue;
  }
}
