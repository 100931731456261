import { inherits as e, createSuper as n, classCallCheck as t, defineProperty as i, assertThisInitialized as o, createClass as r, toConsumableArray as s } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { DEFAULT_KEY_PAINT_STYLE as a, DEFAULT_KEY_SCOPE as l, DEFAULT_KEY_ALLOW_NESTED_GROUPS as u } from "./defaults.js";
import { Endpoints as c, EndpointImpl as d } from "./endpoint/endpoint.js";
import { convertToFullOverlaySpec as p } from "./overlay/overlay.js";
import { EmptyRedrawResult as f } from "./router/router.js";
import { ATTRIBUTE_MANAGED as h, ERROR_SOURCE_ENDPOINT_FULL as v, ERROR_SOURCE_DOES_NOT_EXIST as m, ERROR_TARGET_ENDPOINT_FULL as g, ERROR_TARGET_DOES_NOT_EXIST as y, BLOCK as E, NONE as _ } from "./constants.js";
import { GroupManager as k } from "./group/group-manager.js";
import { EndpointSelection as I } from "./selection/endpoint-selection.js";
import { ConnectionSelection as C } from "./selection/connection-selection.js";
import { Viewport as x } from "./viewport.js";
import { isEndpoint as w, Components as S } from "./component/component.js";
import { AnchorLocations as T } from "./factory/definitions.js";
import { CLASS_CONNECTOR as b, CLASS_CONNECTOR_OUTLINE as D, CLASS_CONNECTED as A, CLASS_ENDPOINT as P, CLASS_ENDPOINT_CONNECTED as O, CLASS_ENDPOINT_FULL as M, CLASS_ENDPOINT_FLOATING as U, CLASS_ENDPOINT_DROP_ALLOWED as j, CLASS_ENDPOINT_DROP_FORBIDDEN as F, CLASS_ENDPOINT_ANCHOR_PREFIX as B, CLASS_OVERLAY as G, CLASS_LABEL_OVERLAY as W } from "./css-constants.js";
import { EVENT_CONNECTION_DETACHED as V, EVENT_INTERNAL_CONNECTION_DETACHED as L, EVENT_CONNECTION_MOVED as R, EVENT_INTERNAL_ENDPOINT_UNREGISTERED as H, EVENT_INTERNAL_CONNECTION as q, EVENT_CONNECTION as z } from "./event-constants.js";
import { LightweightRouter as N } from "./router/lightweight-router.js";
import { CONNECTOR_TYPE_STRAIGHT as Z } from "./connector/straight-connector.js";
import { ConnectionDragSelector as X } from "./source-selector.js";
import { extend as Y, filterList as J, removeWithFunction as K, isString as Q, uuid as $, forEach as ee, rotatePoint as ne, addToDictionary as te, log as ie, findWithFunction as oe } from "../util/util.js";
import { WILDCARD as re, DEFAULT as se } from "../common/index.js";
import { OptimisticEventGenerator as ae } from "../util/event-generator.js";
import { EndpointFactory as le } from "./endpoint/endpoint-factory.js";
import { Segments as ue } from "./connector/segments.js";
import { Connections as ce, _addOverlay as de, _removeOverlay as pe } from "./connector/connections.js";
import { ConnectionImpl as fe } from "./connector/connection-impl.js";
import { BlankEndpoint as he } from "./endpoint/blank-endpoint.js";
function ve(e, n) {
  var t = e.scope.split(/\s/),
    i = n.scope.split(/\s/);
  for (var o = 0; o < t.length; o++) {
    for (var r = 0; r < i.length; r++) {
      if (i[r] === t[o]) {
        return true;
      }
    }
  }
  return false;
}
function me(e, n, t) {
  var i = [];
  var o = function n(t) {
    if (Q(t)) {
      return t;
    } else {
      return e.getId(t);
    }
  };
  if (n) {
    if (typeof n === "string") {
      if (n === "*") {
        return n;
      }
      i.push(n);
    } else {
      if (t) {
        i = n;
      } else {
        if (n.length != null) {
          var r;
          (r = i).push.apply(r, s(s(n).map(o)));
        } else {
          i.push(o(n));
        }
      }
    }
  }
  return i;
}
function ge(e, n) {
  if (e != null) {
    e.endpoints.push(n);
  }
}
function ye(e, n) {
  if (e != null) {
    K(e.endpoints, function (e) {
      return e === n;
    });
  }
}
function Ee(e, n, t) {
  if (n != null) {
    n.connections.push(e);
    if (n.connections.length === 1) {
      e.instance.addClass(e.source, e.instance.connectedClass);
    }
  }
  if (t != null) {
    if (n == null || e.sourceId !== e.targetId) {
      t.connections.push(e);
      if (t.connections.length === 1) {
        e.instance.addClass(e.target, e.instance.connectedClass);
      }
    }
  }
}
function _e(e, n, t) {
  if (n != null) {
    var i = n.connections.length;
    K(n.connections, function (n) {
      return e.id === n.id;
    });
    if (i > 0 && n.connections.length === 0) {
      e.instance.removeClass(e.source, e.instance.connectedClass);
    }
  }
  if (t != null) {
    var o = t.connections.length;
    if (n == null || e.sourceId !== e.targetId) {
      K(t.connections, function (n) {
        return e.id === n.id;
      });
    }
    if (o > 0 && t.connections.length === 0) {
      e.instance.removeClass(e.target, e.instance.connectedClass);
    }
  }
}
var ke = function (s) {
  e(ke, s);
  var ae = n(ke);
  function ke(e, n, r) {
    var s;
    t(this, ke);
    s = ae.call(this);
    s.toolkit = e;
    i(o(s), "defaults", void 0);
    i(o(s), "_initialDefaults", {});
    i(o(s), "isConnectionBeingDragged", false);
    i(o(s), "currentlyDragging", false);
    i(o(s), "hoverSuspended", false);
    i(o(s), "_suspendDrawing", false);
    i(o(s), "_suspendedAt", null);
    i(o(s), "connectorClass", b);
    i(o(s), "connectorOutlineClass", D);
    i(o(s), "connectedClass", A);
    i(o(s), "endpointClass", P);
    i(o(s), "endpointConnectedClass", O);
    i(o(s), "endpointFullClass", M);
    i(o(s), "endpointFloatingClass", U);
    i(o(s), "endpointDropAllowedClass", j);
    i(o(s), "endpointDropForbiddenClass", F);
    i(o(s), "endpointAnchorClassPrefix", B);
    i(o(s), "overlayClass", G);
    i(o(s), "labelOverlayClass", W);
    i(o(s), "connections", []);
    i(o(s), "endpointsByElement", {});
    i(o(s), "endpointsByUUID", new Map());
    i(o(s), "sourceSelectors", []);
    i(o(s), "targetSelectors", []);
    i(o(s), "allowNestedGroups", void 0);
    i(o(s), "_curIdStamp", 1);
    i(o(s), "viewport", new x(o(s)));
    i(o(s), "router", void 0);
    i(o(s), "groupManager", void 0);
    i(o(s), "_connectionTypes", new Map());
    i(o(s), "_endpointTypes", new Map());
    i(o(s), "_container", void 0);
    i(o(s), "_managedElements", {});
    i(o(s), "DEFAULT_SCOPE", void 0);
    i(o(s), "_zoom", 1);
    i(o(s), "_connectionEstablished", void 0);
    s._connectionEstablished = n.connectionEstablished || function (e) {};
    s.defaults = {
      anchor: T.Continuous,
      anchors: [null, null],
      connectionsDetachable: true,
      connectionOverlays: [],
      connector: Z,
      endpoint: he.type,
      endpoints: [null, null],
      endpointStyle: {
        fill: "#456"
      },
      endpointStyles: [null, null],
      endpointHoverStyle: null,
      endpointHoverStyles: [null, null],
      hoverPaintStyle: null,
      listStyle: {},
      maxConnections: 1,
      paintStyle: {
        strokeWidth: 2,
        stroke: "#456"
      },
      reattachConnections: false,
      scope: "jsplumb_defaultscope",
      allowNestedGroups: true
    };
    if (r) {
      Y(s.defaults, r);
    }
    Y(s._initialDefaults, s.defaults);
    if (s._initialDefaults[a] != null) {
      s._initialDefaults[a].strokeWidth = s._initialDefaults[a].strokeWidth || 2;
    }
    s.DEFAULT_SCOPE = s.defaults[l];
    s.allowNestedGroups = s._initialDefaults[u] !== false;
    s.router = new N(o(s));
    s.groupManager = new k(o(s));
    s.setContainer(n.container);
    return s;
  }
  r(ke, [{
    key: "defaultScope",
    get: function e() {
      return this.DEFAULT_SCOPE;
    }
  }, {
    key: "currentZoom",
    get: function e() {
      return this._zoom;
    }
  }, {
    key: "areDefaultAnchorsSet",
    value: function e() {
      return this.validAnchorsSpec(this.defaults.anchors);
    }
  }, {
    key: "validAnchorsSpec",
    value: function e(n) {
      return n != null && n[0] != null && n[1] != null;
    }
  }, {
    key: "getContainer",
    value: function e() {
      return this._container;
    }
  }, {
    key: "setZoom",
    value: function e(n, t) {
      this._zoom = n;
      if (t) {
        this.repaintEverything();
      }
      return true;
    }
  }, {
    key: "_idstamp",
    value: function e() {
      return "" + this._curIdStamp++;
    }
  }, {
    key: "getId",
    value: function e(n, t) {
      if (n == null) {
        return null;
      }
      var i = this.getAttribute(n, h);
      if (!i || i === "undefined") {
        if (arguments.length === 2 && arguments[1] !== undefined) {
          i = t;
        } else if (arguments.length === 1 || arguments.length === 3 && !arguments[2]) {
          i = "jsplumb-".concat(this._idstamp());
        }
        this.setAttribute(n, h, i);
      }
      return i;
    }
  }, {
    key: "getConnections",
    value: function e(n, t) {
      if (!n) {
        n = {};
      } else if (n.constructor === String) {
        n = {
          scope: n
        };
      }
      var i = n.scope || this.defaultScope,
        o = me(this, i, true),
        r = me(this, n.source),
        s = me(this, n.target),
        a = !t && o.length > 1 ? {} : [],
        l = function e(n, i) {
          if (!t && o.length > 1) {
            var r = a[n];
            if (r == null) {
              r = a[n] = [];
            }
            r.push(i);
          } else {
            a.push(i);
          }
        };
      for (var u = 0, c = this.connections.length; u < c; u++) {
        var d = this.connections[u],
          p = d.proxies && d.proxies[0] ? d.proxies[0].originalEp.elementId : d.sourceId,
          f = d.proxies && d.proxies[1] ? d.proxies[1].originalEp.elementId : d.targetId;
        if (J(o, d.scope) && J(r, p) && J(s, f)) {
          l(d.scope, d);
        }
      }
      return a;
    }
  }, {
    key: "select",
    value: function e(n) {
      n = n || {};
      n.scope = n.scope || "*";
      return new C(this, n.connections || this.getConnections(n, true));
    }
  }, {
    key: "selectEndpoints",
    value: function e(n) {
      n = n || {};
      n.scope = n.scope || re;
      var t = !n.element && !n.source && !n.target,
        i = t ? re : me(this, n.element),
        o = t ? re : me(this, n.source),
        r = t ? re : me(this, n.target),
        s = me(this, n.scope, true);
      var a = [];
      for (var l in this.endpointsByElement) {
        var u = J(i, l, true),
          c = J(o, l, true),
          d = o !== "*",
          p = J(r, l, true),
          f = r !== "*";
        if (u || c || p) {
          e: for (var h = 0, v = this.endpointsByElement[l].length; h < v; h++) {
            var m = this.endpointsByElement[l][h];
            if (J(s, m.scope, true)) {
              var g = d && o.length > 0 && !m.isSource,
                y = f && r.length > 0 && !m.isTarget;
              if (g || y) {
                continue e;
              }
              a.push(m);
            }
          }
        }
      }
      return new I(this, a);
    }
  }, {
    key: "setContainer",
    value: function e(n) {
      this._container = n;
    }
  }, {
    key: "_set",
    value: function e(n, t, i) {
      var o = [{
        el: "source",
        elId: "sourceId"
      }, {
        el: "target",
        elId: "targetId"
      }];
      var r,
        s = o[i],
        a = n[s.elId],
        l,
        u = n.endpoints[i];
      var d = {
        index: i,
        originalEndpoint: u,
        originalSourceId: i === 0 ? a : n.sourceId,
        newSourceId: n.sourceId,
        originalTargetId: i === 1 ? a : n.targetId,
        newTargetId: n.targetId,
        connection: n,
        newEndpoint: u
      };
      if (w(t)) {
        r = t;
        c.addConnection(t, n);
      } else {
        l = this.getId(t);
        if (l === n[s.elId]) {
          r = null;
        } else {
          r = ce.makeEndpoint(n, i === 0, t, l);
        }
      }
      if (r != null) {
        d.newEndpoint = r;
        c.detachFromConnection(u, n);
        n.endpoints[i] = r;
        n[s.el] = r.element;
        n[s.elId] = r.elementId;
        d[i === 0 ? "newSourceId" : "newTargetId"] = r.elementId;
        this.fireMoveEvent(d);
        this._paintConnection(n);
      }
      return d;
    }
  }, {
    key: "setSource",
    value: function e(n, t) {
      _e(n, this._managedElements[n.sourceId]);
      var i = this._set(n, t, 0);
      Ee(n, this._managedElements[i.newSourceId]);
    }
  }, {
    key: "setTarget",
    value: function e(n, t) {
      _e(n, this._managedElements[n.targetId]);
      var i = this._set(n, t, 1);
      Ee(n, this._managedElements[i.newTargetId]);
    }
  }, {
    key: "setConnectionType",
    value: function e(n, t, i) {
      S.setType(n, t, i);
      this._paintConnection(n);
    }
  }, {
    key: "isHoverSuspended",
    value: function e() {
      return this.hoverSuspended;
    }
  }, {
    key: "setSuspendDrawing",
    value: function e(n, t) {
      var i = this._suspendDrawing;
      this._suspendDrawing = n;
      if (n) {
        this._suspendedAt = "" + new Date().getTime();
      } else {
        this._suspendedAt = null;
        this.viewport.recomputeBounds();
      }
      if (t) {
        this.repaintEverything();
      }
      return i;
    }
  }, {
    key: "getSuspendedAt",
    value: function e() {
      return this._suspendedAt;
    }
  }, {
    key: "batch",
    value: function e(n, t) {
      var i = this._suspendDrawing === true;
      if (!i) {
        this.setSuspendDrawing(true);
      }
      n();
      if (!i) {
        this.setSuspendDrawing(false, !t);
      }
    }
  }, {
    key: "each",
    value: function e(n, t) {
      if (n == null) {
        return;
      }
      if (n.length != null) {
        for (var i = 0; i < n.length; i++) {
          t(n[i]);
        }
      } else {
        t(n);
      }
      return this;
    }
  }, {
    key: "updateOffset",
    value: function e(n) {
      var t = n.elId;
      if (n.recalc) {
        return this.viewport.refreshElement(t);
      } else {
        return this.viewport.getPosition(t);
      }
    }
  }, {
    key: "deleteConnection",
    value: function e(n, t) {
      if (n != null && n.deleted !== true) {
        t = t || {};
        if (t.force || this.beforeDetach(n) === true) {
          _e(n, this._managedElements[n.sourceId], this._managedElements[n.targetId]);
          this.fireDetachEvent(n, !n.pending && t.fireEvent !== false, t.originalEvent);
          var i = n.endpoints[0];
          var o = n.endpoints[1];
          if (i !== t.endpointToIgnore) {
            c.detachFromConnection(i, n, null, true);
          }
          if (o !== t.endpointToIgnore) {
            c.detachFromConnection(o, n, null, true);
          }
          K(this.connections, function (e) {
            return n.id === e.id;
          });
          ce.destroy(n);
          if (i !== t.endpointToIgnore && i.deleteOnEmpty && i.connections.length === 0) {
            this.deleteEndpoint(i);
          }
          if (o !== t.endpointToIgnore && o.deleteOnEmpty && o.connections.length === 0) {
            this.deleteEndpoint(o);
          }
          return true;
        }
      }
      return false;
    }
  }, {
    key: "deleteEveryConnection",
    value: function e(n) {
      var t = this;
      n = n || {};
      var i = this.connections.length,
        o = 0;
      this.batch(function () {
        for (var e = 0; e < i; e++) {
          o += t.deleteConnection(t.connections[0], n) ? 1 : 0;
        }
      });
      return o;
    }
  }, {
    key: "deleteConnectionsForElement",
    value: function e(n, t) {
      var i = this.getId(n),
        o = this._managedElements[i];
      if (o) {
        var r = o.connections.length;
        for (var s = 0; s < r; s++) {
          this.deleteConnection(o.connections[0], t);
        }
      }
      return this;
    }
  }, {
    key: "fireDetachEvent",
    value: function e(n, t, i) {
      var o = n.id != null,
        r = o ? {
          connection: n,
          source: n.source,
          target: n.target,
          sourceId: n.sourceId,
          targetId: n.targetId,
          sourceEndpoint: n.endpoints[0],
          targetEndpoint: n.endpoints[1]
        } : n;
      if (t) {
        this.fire(V, r, i);
      }
      this.fire(L, r, i);
    }
  }, {
    key: "fireMoveEvent",
    value: function e(n, t) {
      this.fire(R, n, t);
    }
  }, {
    key: "manageAll",
    value: function e(n, t) {
      var i = Q(n) ? this.getSelector(this.getContainer(), n) : n;
      for (var o = 0; o < i.length; o++) {
        this.manage(i[o], null, t);
      }
    }
  }, {
    key: "manage",
    value: function e(n, t, i) {
      if (this.getAttribute(n, h) == null) {
        t = t || this.getAttribute(n, "id") || $();
        this.setAttribute(n, h, t);
      }
      var o = this.getId(n);
      if (!this._managedElements[o]) {
        var r = {
          el: n,
          endpoints: [],
          connections: [],
          rotation: 0,
          data: {}
        };
        this._managedElements[o] = r;
        if (this._suspendDrawing) {
          r.viewportElement = this.viewport.registerElement(o, true);
        } else {
          r.viewportElement = this.updateOffset({
            elId: o,
            recalc: true
          });
        }
      } else {
        if (i) {
          this._managedElements[o].viewportElement = this.updateOffset({
            elId: o,
            timestamp: null,
            recalc: true
          });
        }
      }
      return this._managedElements[o];
    }
  }, {
    key: "getManagedData",
    value: function e(n, t, i) {
      if (this._managedElements[n]) {
        var o = this._managedElements[n].data[t];
        return o != null ? o[i] : null;
      }
    }
  }, {
    key: "setManagedData",
    value: function e(n, t, i, o) {
      if (this._managedElements[n]) {
        this._managedElements[n].data[t] = this._managedElements[n].data[t] || {};
        this._managedElements[n].data[t][i] = o;
      }
    }
  }, {
    key: "getManagedElement",
    value: function e(n) {
      return this._managedElements[n] ? this._managedElements[n].el : null;
    }
  }, {
    key: "unmanage",
    value: function e(n, t) {
      var i = this;
      this.removeAllEndpoints(n, true);
      var o = function e(n) {
        var o = i.getId(n);
        i.removeAttribute(n, h);
        delete i._managedElements[o];
        i.viewport.remove(o);
        if (n && t) {
          i._removeElement(n);
        }
      };
      this._getAssociatedElements(n).map(o);
      o(n);
    }
  }, {
    key: "rotate",
    value: function e(n, t, i) {
      var o = this.getId(n);
      if (this._managedElements[o]) {
        this._managedElements[o].rotation = t;
        this.viewport.rotateElement(o, t);
        if (i !== true) {
          return this.revalidate(n);
        }
      }
      return {
        c: new Set(),
        e: new Set()
      };
    }
  }, {
    key: "_getRotation",
    value: function e(n) {
      var t = this._managedElements[n];
      if (t != null) {
        return t.rotation || 0;
      } else {
        return 0;
      }
    }
  }, {
    key: "_getRotations",
    value: function e(n) {
      var t = this;
      var i = [];
      var o = this._managedElements[n];
      var r = function e(n) {
        if (n.group != null) {
          var o = t._managedElements[n.group];
          if (o != null) {
            i.push({
              r: o.viewportElement.r,
              c: o.viewportElement.c
            });
            e(o);
          }
        }
      };
      if (o != null) {
        i.push({
          r: o.viewportElement.r || 0,
          c: o.viewportElement.c
        });
        r(o);
      }
      return i;
    }
  }, {
    key: "_applyRotations",
    value: function e(n, t) {
      var i = n.slice();
      var o = {
        x: i[0],
        y: i[1],
        cr: 0,
        sr: 0
      };
      ee(t, function (e) {
        o = ne(o, e.c, e.r);
      });
      return o;
    }
  }, {
    key: "_applyRotationsXY",
    value: function e(n, t) {
      ee(t, function (e) {
        n = ne(n, e.c, e.r);
      });
      return n;
    }
  }, {
    key: "_internal_newEndpoint",
    value: function e(n) {
      var t = Y({}, n);
      var i = this.manage(t.element);
      t.elementId = this.getId(t.element);
      t.id = "ep_" + this._idstamp();
      t.useHTMLLabel = true;
      var o = new d(this, t);
      ge(i, o);
      if (n.uuid) {
        this.endpointsByUUID.set(n.uuid, o);
      }
      te(this.endpointsByElement, o.elementId, o);
      if (!this._suspendDrawing) {
        this._paintEndpoint(o, {
          timestamp: this._suspendedAt
        });
      }
      return o;
    }
  }, {
    key: "_deriveEndpointAndAnchorSpec",
    value: function e(n, t) {
      var i = ((t ? "" : "default ") + n).split(/[\s]/),
        o = null,
        r = null,
        s = null,
        a = null;
      for (var l = 0; l < i.length; l++) {
        var u = this.getConnectionType(i[l]);
        if (u) {
          if (u.endpoints) {
            o = u.endpoints;
          }
          if (u.endpoint) {
            r = u.endpoint;
          }
          if (u.anchors) {
            a = u.anchors;
          }
          if (u.anchor) {
            s = u.anchor;
          }
        }
      }
      return {
        endpoints: o ? o : [r, r],
        anchors: a ? a : [s, s]
      };
    }
  }, {
    key: "revalidate",
    value: function e(n, t) {
      var i = this.getId(n);
      this.updateOffset({
        elId: i,
        recalc: true,
        timestamp: t
      });
      return this.repaint(n);
    }
  }, {
    key: "repaintEverything",
    value: function e() {
      var n = $(),
        t;
      for (t in this._managedElements) {
        this.viewport.refreshElement(t, true);
      }
      this.viewport.recomputeBounds();
      for (t in this._managedElements) {
        this.repaint(this._managedElements[t].el, n, true);
      }
      return this;
    }
  }, {
    key: "setElementPosition",
    value: function e(n, t, i, o) {
      var r = this.getId(n);
      this.viewport.setPosition(r, t, i);
      if (!o) {
        return this.repaint(n);
      } else {
        return f();
      }
    }
  }, {
    key: "repaint",
    value: function e(n, t, i) {
      var o = {
        c: new Set(),
        e: new Set()
      };
      var r = function e(n) {
        n.c.forEach(function (e) {
          return o.c.add(e);
        });
        n.e.forEach(function (e) {
          return o.e.add(e);
        });
      };
      if (!this._suspendDrawing) {
        var s = this.getId(n);
        if (n != null) {
          var a = this._getAssociatedElements(n);
          if (t == null) {
            t = $();
          }
          if (!i) {
            for (var l = 0; l < a.length; l++) {
              this.updateOffset({
                elId: this.getId(a[l]),
                recalc: true,
                timestamp: t
              });
            }
          }
          r(this.router.redraw(s, t, null));
          if (a.length > 0) {
            for (var u = 0; u < a.length; u++) {
              r(this.router.redraw(this.getId(a[u]), t, null));
            }
          }
        }
      }
      return o;
    }
  }, {
    key: "unregisterEndpoint",
    value: function e(n) {
      var t = n.uuid;
      if (t) {
        this.endpointsByUUID.delete(t);
      }
      ye(this._managedElements[n.elementId], n);
      var i = this.endpointsByElement[n.elementId];
      if (i != null) {
        this.endpointsByElement[n.elementId] = i.filter(function (e) {
          return e !== n;
        });
      }
      this.fire(H, n);
    }
  }, {
    key: "_maybePruneEndpoint",
    value: function e(n) {
      if (n.deleteOnEmpty && n.connections.length === 0) {
        this.deleteEndpoint(n);
        return true;
      } else {
        return false;
      }
    }
  }, {
    key: "deleteEndpoint",
    value: function e(n) {
      var t = this;
      var i = typeof n === "string" ? this.endpointsByUUID.get(n) : n;
      if (i) {
        var o = i.proxiedBy;
        var r = i.connections.slice();
        ee(r, function (e) {
          c.detachFromConnection(i, e, null, true);
        });
        this.unregisterEndpoint(i);
        c.destroy(i);
        ee(r, function (e) {
          t.deleteConnection(e, {
            force: true,
            endpointToIgnore: i
          });
        });
        if (o != null) {
          this.deleteEndpoint(o);
        }
      }
      return this;
    }
  }, {
    key: "addEndpoint",
    value: function e(n, t, i) {
      i = i || {};
      var o = Y({}, i);
      Y(o, t || {});
      var r = Y({
        element: n
      }, o);
      return this._internal_newEndpoint(r);
    }
  }, {
    key: "addEndpoints",
    value: function e(n, t, i) {
      var o = [];
      for (var r = 0, s = t.length; r < s; r++) {
        o.push(this.addEndpoint(n, t[r], i));
      }
      return o;
    }
  }, {
    key: "reset",
    value: function e() {
      var n = this;
      this.silently(function () {
        n.endpointsByElement = {};
        n._managedElements = {};
        n.endpointsByUUID.clear();
        n.viewport.reset();
        n.router.reset();
        n.groupManager.reset();
        n.connections.length = 0;
      });
    }
  }, {
    key: "destroy",
    value: function e() {
      this.reset();
      this.unbind();
      this.sourceSelectors.length = 0;
      this.targetSelectors.length = 0;
      this._connectionTypes.clear();
      this._endpointTypes.clear();
    }
  }, {
    key: "getEndpoints",
    value: function e(n) {
      return this.endpointsByElement[this.getId(n)] || [];
    }
  }, {
    key: "getEndpoint",
    value: function e(n) {
      return this.endpointsByUUID.get(n);
    }
  }, {
    key: "connect",
    value: function e(n, t) {
      try {
        var i = this._prepareConnectionParams(n, t),
          o = this._newConnection(i);
        this._finaliseConnection(o, i);
        return o;
      } catch (e) {
        ie(e);
        return;
      }
    }
  }, {
    key: "_prepareConnectionParams",
    value: function e(n, t) {
      var i = Y({}, n);
      if (t) {
        Y(i, t);
      }
      var o = i;
      if (o.source && w(o.source)) {
        o.sourceEndpoint = o.source;
      }
      if (o.target && w(o.target)) {
        o.targetEndpoint = o.target;
      }
      if (o.sourceEndpoint != null) {
        if (c.isFull(o.sourceEndpoint)) {
          throw v;
        }
        if (!o.type) {
          o.type = o.sourceEndpoint.edgeType;
        }
        if (o.sourceEndpoint.connectorOverlays) {
          o.overlays = o.overlays || [];
          for (var r = 0, s = o.sourceEndpoint.connectorOverlays.length; r < s; r++) {
            o.overlays.push(o.sourceEndpoint.connectorOverlays[r]);
          }
        }
        if (o.sourceEndpoint.scope) {
          o.scope = o.sourceEndpoint.scope;
        }
      } else {
        if (o.source == null) {
          throw m;
        }
      }
      if (o.targetEndpoint != null) {
        if (c.isFull(o.targetEndpoint)) {
          throw g;
        }
      } else {
        if (o.target == null) {
          throw y;
        }
      }
      if (o.sourceEndpoint && o.targetEndpoint) {
        if (!ve(o.sourceEndpoint, o.targetEndpoint)) {
          throw "Cannot establish connection: scopes do not match";
        }
      }
      return o;
    }
  }, {
    key: "_newConnection",
    value: function e(n) {
      n.id = "con_" + this._idstamp();
      var t = new fe(this, n);
      Ee(t, this._managedElements[t.sourceId], this._managedElements[t.targetId]);
      this._paintConnection(t);
      return t;
    }
  }, {
    key: "_finaliseConnection",
    value: function e(n, t, i) {
      if (!n.suspendedEndpoint) {
        this.connections.push(n);
      }
      n.pending = null;
      n.endpoints[0].isTemporarySource = false;
      this.repaint(n.source);
      var o = {
        connection: n,
        source: n.source,
        target: n.target,
        sourceId: n.sourceId,
        targetId: n.targetId,
        sourceEndpoint: n.endpoints[0],
        targetEndpoint: n.endpoints[1]
      };
      this._connectionEstablished(o);
      this.fire(q, o, i);
      this.fire(z, o, i);
    }
  }, {
    key: "removeAllEndpoints",
    value: function e(n, t) {
      var i = this;
      var o = function e(n) {
        var t = i.getId(n),
          o = i.endpointsByElement[t],
          r,
          s;
        if (o) {
          for (r = 0, s = o.length; r < s; r++) {
            i.deleteEndpoint(o[r]);
          }
        }
        delete i.endpointsByElement[t];
      };
      if (t) {
        this._getAssociatedElements(n).map(o);
      }
      o(n);
      return this;
    }
  }, {
    key: "_createSourceDefinition",
    value: function e(n, t) {
      var i = Y({}, t);
      Y(i, n);
      i.edgeType = i.edgeType || se;
      var o = this._deriveEndpointAndAnchorSpec(i.edgeType);
      i.endpoint = i.endpoint || o.endpoints[0];
      i.anchor = i.anchor || o.anchors[0];
      var r = i.maxConnections || -1;
      var s = {
        def: Y({}, i),
        uniqueEndpoint: i.uniqueEndpoint,
        maxConnections: r,
        enabled: true,
        endpoint: null
      };
      return s;
    }
  }, {
    key: "addSourceSelector",
    value: function e(n, t) {
      var i = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var o = this._createSourceDefinition(t);
      var r = new X(n, o, i);
      this.sourceSelectors.push(r);
      return r;
    }
  }, {
    key: "removeSourceSelector",
    value: function e(n) {
      K(this.sourceSelectors, function (e) {
        return e === n;
      });
    }
  }, {
    key: "removeTargetSelector",
    value: function e(n) {
      K(this.targetSelectors, function (e) {
        return e === n;
      });
    }
  }, {
    key: "addTargetSelector",
    value: function e(n, t) {
      var i = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var o = this._createTargetDefinition(t);
      var r = new X(n, o, i);
      this.targetSelectors.push(r);
      return r;
    }
  }, {
    key: "_createTargetDefinition",
    value: function e(n, t) {
      var i = Y({}, t);
      Y(i, n);
      i.edgeType = i.edgeType || se;
      var o = i.maxConnections || -1;
      var r = {
        def: Y({}, i),
        uniqueEndpoint: i.uniqueEndpoint,
        maxConnections: o,
        enabled: true,
        endpoint: null
      };
      return r;
    }
  }, {
    key: "show",
    value: function e(n, t) {
      return this._setVisible(n, E, t);
    }
  }, {
    key: "hide",
    value: function e(n, t) {
      return this._setVisible(n, _, t);
    }
  }, {
    key: "_setVisible",
    value: function e(n, t, i) {
      var o = t === E;
      var r = null;
      if (i) {
        r = function e(n) {
          c.setVisible(n, o, true, true);
        };
      }
      var s = this.getId(n);
      this._operation(n, function (e) {
        if (o && i) {
          var n = e.sourceId === s ? 1 : 0;
          if (e.endpoints[n].visible) {
            ce.setVisible(e, true);
          }
        } else {
          ce.setVisible(e, o);
        }
      }, r);
      return this;
    }
  }, {
    key: "toggleVisible",
    value: function e(n, t) {
      var i = null;
      if (t) {
        i = function e(n) {
          c.setVisible(n, !n.visible);
        };
      }
      this._operation(n, function (e) {
        ce.setVisible(e, !e.visible);
      }, i);
    }
  }, {
    key: "_operation",
    value: function e(n, t, i) {
      var o = this.getId(n);
      var r = this.endpointsByElement[o];
      if (r && r.length) {
        for (var s = 0, a = r.length; s < a; s++) {
          for (var l = 0, u = r[s].connections.length; l < u; l++) {
            var c = t(r[s].connections[l]);
            if (c) {
              return;
            }
          }
          if (i) {
            i(r[s]);
          }
        }
      }
    }
  }, {
    key: "registerConnectionType",
    value: function e(n, t) {
      this._connectionTypes.set(n, Y({}, t));
      if (t.overlays) {
        var i = {};
        for (var o = 0; o < t.overlays.length; o++) {
          var r = p(t.overlays[o]);
          i[r.options.id] = r;
        }
        this._connectionTypes.get(n).overlays = i;
      }
    }
  }, {
    key: "registerConnectionTypes",
    value: function e(n) {
      for (var t in n) {
        this.registerConnectionType(t, n[t]);
      }
    }
  }, {
    key: "registerEndpointType",
    value: function e(n, t) {
      this._endpointTypes.set(n, Y({}, t));
      if (t.overlays) {
        var i = {};
        for (var o = 0; o < t.overlays.length; o++) {
          var r = p(t.overlays[o]);
          i[r.options.id] = r;
        }
        this._endpointTypes.get(n).overlays = i;
      }
    }
  }, {
    key: "registerEndpointTypes",
    value: function e(n) {
      for (var t in n) {
        this.registerEndpointType(t, n[t]);
      }
    }
  }, {
    key: "getType",
    value: function e(n, t) {
      return t === "connection" ? this.getConnectionType(n) : this.getEndpointType(n);
    }
  }, {
    key: "getConnectionType",
    value: function e(n) {
      return this._connectionTypes.get(n);
    }
  }, {
    key: "getEndpointType",
    value: function e(n) {
      return this._endpointTypes.get(n);
    }
  }, {
    key: "importDefaults",
    value: function e(n) {
      for (var t in n) {
        this.defaults[t] = n[t];
      }
      if (this.defaults[a] != null) {
        this.defaults[a].strokeWidth = this.defaults[a].strokeWidth || 2;
      }
      return this;
    }
  }, {
    key: "restoreDefaults",
    value: function e() {
      this.defaults = Y({}, this._initialDefaults);
      return this;
    }
  }, {
    key: "getManagedElements",
    value: function e() {
      return this._managedElements;
    }
  }, {
    key: "proxyConnection",
    value: function e(n, t, i, o, r) {
      var s = n.proxies[t] != null,
        a,
        l = s ? n.proxies[t].originalEp.elementId : n.endpoints[t].elementId,
        u = s ? n.proxies[t].originalEp : n.endpoints[t],
        d = this.getId(i);
      if (n.proxies[t]) {
        if (n.proxies[t].ep.elementId === d) {
          a = n.proxies[t].ep;
        } else {
          c.detachFromConnection(n.proxies[t].ep, n, t);
          a = this._internal_newEndpoint({
            element: i,
            endpoint: o(n, t),
            anchor: r(n, t),
            parameters: {
              isProxyEndpoint: true
            }
          });
        }
      } else {
        a = this._internal_newEndpoint({
          element: i,
          endpoint: o(n, t),
          anchor: r(n, t),
          parameters: {
            isProxyEndpoint: true
          }
        });
      }
      a.deleteOnEmpty = true;
      n.proxies[t] = {
        ep: a,
        originalEp: u
      };
      this.sourceOrTargetChanged(l, d, n, i, t);
      c.detachFromConnection(u, n, null, true);
      a.connections = [n];
      n.endpoints[t] = a;
      u.proxiedBy = a;
      c.setVisible(u, false);
      ce.setVisible(n, true);
      this.revalidate(i);
    }
  }, {
    key: "unproxyConnection",
    value: function e(n, t) {
      if (n.proxies == null || n.proxies[t] == null) {
        return;
      }
      var i = n.proxies[t].originalEp.element,
        o = n.proxies[t].originalEp.elementId,
        r = n.proxies[t].ep.elementId;
      n.endpoints[t] = n.proxies[t].originalEp;
      delete n.proxies[t].originalEp.proxiedBy;
      this.sourceOrTargetChanged(r, o, n, i, t);
      c.detachFromConnection(n.proxies[t].ep, n, null);
      c.addConnection(n.proxies[t].originalEp, n);
      if (n.visible) {
        c.setVisible(n.proxies[t].originalEp, true);
      }
      n.proxies[t] = null;
      if (oe(n.proxies, function (e) {
        return e != null;
      }) === -1) {
        n.proxies.length = 0;
      }
    }
  }, {
    key: "sourceOrTargetChanged",
    value: function e(n, t, i, o, r) {
      if (n !== t) {
        if (r === 0) {
          i.sourceId = t;
          i.source = o;
        } else if (r === 1) {
          i.targetId = t;
          i.target = o;
        }
        _e(i, this._managedElements[n]);
        Ee(i, this._managedElements[t]);
      }
    }
  }, {
    key: "getGroup",
    value: function e(n) {
      return this.groupManager.getGroup(n);
    }
  }, {
    key: "getGroupFor",
    value: function e(n) {
      return this.groupManager.getGroupFor(n);
    }
  }, {
    key: "addGroup",
    value: function e(n, t) {
      return this.groupManager.addGroup(n, t);
    }
  }, {
    key: "addToGroup",
    value: function e(n) {
      var t;
      for (var i = arguments.length, o = new Array(i > 1 ? i - 1 : 0), r = 1; r < i; r++) {
        o[r - 1] = arguments[r];
      }
      return (t = this.groupManager).addToGroup.apply(t, [n, false].concat(o));
    }
  }, {
    key: "collapseGroup",
    value: function e(n) {
      this.groupManager.collapseGroup(n);
    }
  }, {
    key: "expandGroup",
    value: function e(n) {
      this.groupManager.expandGroup(n);
    }
  }, {
    key: "toggleGroup",
    value: function e(n) {
      this.groupManager.toggleGroup(n);
    }
  }, {
    key: "removeGroup",
    value: function e(n, t, i, o) {
      return this.groupManager.removeGroup(n, t, i, o);
    }
  }, {
    key: "removeAllGroups",
    value: function e(n, t) {
      this.groupManager.removeAllGroups(n, t, false);
    }
  }, {
    key: "removeFromGroup",
    value: function e(n, t, i) {
      this.groupManager.removeFromGroup(n, i, t);
      this._appendElement(t, this.getContainer());
      this.updateOffset({
        recalc: true,
        elId: this.getId(t)
      });
    }
  }, {
    key: "_paintEndpoint",
    value: function e(n, t) {
      function i(e) {
        var n = 0;
        if (t.elementWithPrecedence != null) {
          for (var i = 0; i < e.connections.length; i++) {
            if (e.connections[i].sourceId === t.elementWithPrecedence || e.connections[i].targetId === t.elementWithPrecedence) {
              n = i;
              break;
            }
          }
        }
        return e.connections[n];
      }
      t = t || {};
      var o = t.timestamp,
        r = !(t.recalc === false);
      if (!o || n.timestamp !== o) {
        var s = this.viewport.getPosition(n.elementId);
        var a = t.offset ? {
          x: t.offset.x,
          y: t.offset.y
        } : {
          x: s.x,
          y: s.y
        };
        if (a != null) {
          var l = t.anchorLoc;
          if (l == null) {
            var u = {
              xy: a,
              wh: s,
              element: n,
              timestamp: o,
              otherAnchor: t.otherAnchor
            };
            if (r && this.router.isDynamicAnchor(n) && n.connections.length > 0) {
              var c = i(n),
                d = c.endpoints[0] === n ? 1 : 0,
                p = d === 0 ? c.sourceId : c.targetId,
                f = this.viewport.getPosition(p);
              u.index = d === 0 ? 1 : 0;
              u.connection = c;
              u.txy = f;
              u.twh = f;
              u.tElement = c.endpoints[d];
              u.tRotation = this._getRotations(p);
            } else if (n.connections.length > 0) {
              u.connection = n.connections[0];
            }
            u.rotation = this._getRotations(n.elementId);
            l = this.router.computeAnchorLocation(n._anchor, u);
          }
          n.endpoint.computedValue = le.compute(n.endpoint, l, this.router.getEndpointOrientation(n), n.paintStyleInUse);
          n.endpoint.bounds.xmin = n.endpoint.x;
          n.endpoint.bounds.ymin = n.endpoint.y;
          n.endpoint.bounds.xmax = n.endpoint.x + n.endpoint.w;
          n.endpoint.bounds.ymax = n.endpoint.y + n.endpoint.h;
          this.renderEndpoint(n, n.paintStyleInUse);
          n.timestamp = o;
        }
      }
    }
  }, {
    key: "_paintConnection",
    value: function e(n, t) {
      if (!this._suspendDrawing && n.visible !== false) {
        t = t || {};
        var i = t.timestamp;
        if (i != null && i === n.lastPaintedAt) {
          return;
        }
        if (i == null || i !== n.lastPaintedAt) {
          this.router.computePath(n, i);
          var o = {
            xmin: Infinity,
            ymin: Infinity,
            xmax: -Infinity,
            ymax: -Infinity
          };
          for (var r in n.overlays) {
            if (n.overlays.hasOwnProperty(r)) {
              var s = n.overlays[r];
              if (s.isVisible()) {
                n.overlayPlacements[r] = this.drawOverlay(s, n.connector, n.paintStyleInUse, ce.getAbsoluteOverlayPosition(n, s));
                o.xmin = Math.min(o.xmin, n.overlayPlacements[r].xmin);
                o.xmax = Math.max(o.xmax, n.overlayPlacements[r].xmax);
                o.ymin = Math.min(o.ymin, n.overlayPlacements[r].ymin);
                o.ymax = Math.max(o.ymax, n.overlayPlacements[r].ymax);
              }
            }
          }
          var a = parseFloat("" + n.paintStyleInUse.strokeWidth || "1") / 2,
            l = parseFloat("" + n.paintStyleInUse.strokeWidth || "0"),
            u = {
              xmin: Math.min(n.connector.bounds.xmin - (a + l), o.xmin),
              ymin: Math.min(n.connector.bounds.ymin - (a + l), o.ymin),
              xmax: Math.max(n.connector.bounds.xmax + (a + l), o.xmax),
              ymax: Math.max(n.connector.bounds.ymax + (a + l), o.ymax)
            };
          this.paintConnector(n.connector, n.paintStyleInUse, u);
          for (var c in n.overlays) {
            if (n.overlays.hasOwnProperty(c)) {
              var d = n.overlays[c];
              if (d.isVisible()) {
                this._paintOverlay(d, n.overlayPlacements[c], u);
              }
            }
          }
        }
        n.lastPaintedAt = i;
      }
    }
  }, {
    key: "_refreshEndpoint",
    value: function e(n) {
      if (!n._anchor.isFloating) {
        if (n.connections.length > 0) {
          this.addEndpointClass(n, this.endpointConnectedClass);
        } else {
          this.removeEndpointClass(n, this.endpointConnectedClass);
        }
        if (c.isFull(n)) {
          this.addEndpointClass(n, this.endpointFullClass);
        } else {
          this.removeEndpointClass(n, this.endpointFullClass);
        }
      }
    }
  }, {
    key: "addOverlay",
    value: function e(n, t, i) {
      var o = de(n, t);
      if (o != null) {
        o.ignoreTypes = true;
        if (!i) {
          var r = n.source;
          this.revalidate(r);
        }
      }
      return o;
    }
  }, {
    key: "removeOverlay",
    value: function e(n, t) {
      pe(n, t);
      var i = n.source;
      this.revalidate(i);
    }
  }, {
    key: "setOutlineColor",
    value: function e(n, t) {
      n.paintStyleInUse.outlineStroke = t;
      this._paintConnection(n);
    }
  }, {
    key: "setOutlineWidth",
    value: function e(n, t) {
      n.paintStyleInUse.outlineWidth = t;
      this._paintConnection(n);
    }
  }, {
    key: "setColor",
    value: function e(n, t) {
      n.paintStyleInUse.stroke = t;
      this._paintConnection(n);
    }
  }, {
    key: "setLineWidth",
    value: function e(n, t) {
      n.paintStyleInUse.strokeWidth = t;
      this._paintConnection(n);
    }
  }, {
    key: "setLineStyle",
    value: function e(n, t) {
      if (t.lineWidth != null) {
        n.paintStyleInUse.strokeWidth = t.lineWidth;
      }
      if (t.outlineWidth != null) {
        n.paintStyleInUse.outlineWidth = t.outlineWidth;
      }
      if (t.color != null) {
        n.paintStyleInUse.stroke = t.color;
      }
      if (t.outlineColor != null) {
        n.paintStyleInUse.outlineStroke = t.outlineColor;
      }
      this._paintConnection(n);
    }
  }, {
    key: "getModelObjectFromEndpoint",
    value: function e(n) {
      var t = n.parameters,
        i = this.toolkit.getNode(t.vertexId),
        o = t.portId ? i.addPort({
          id: t.portId,
          type: t.portType
        }) : i;
      return o;
    }
  }, {
    key: "getPathData",
    value: function e(n) {
      var t = "";
      for (var i = 0; i < n.segments.length; i++) {
        t += ue.getPath(n.segments[i], i === 0);
        t += " ";
      }
      return t;
    }
  }]);
  return ke;
}(ae);
export { ke as JsPlumbInstance };