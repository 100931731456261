<span
  #menuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="selectMenu"
  tabindex="0"
  class="naris-inline-select"
  [class.error]="error || !valid"
  (click)="menuTrigger.openMenu()"
  [attr.data-test-id]="'inline-select__' + id"
>
  {{ label || placeholder | translate}}
</span>
<mat-menu #selectMenu="matMenu" xPosition="after">
  @for (option of options; track $index) {
    <div
      tabindex="0"
      class="naris-menu-item"
      [class.active]="control.value === option.key"
      (click)="select(option)"
    >
      {{ option.label | translate }}
    </div>
  }
</mat-menu>
