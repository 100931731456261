import { typeof as e, inherits as t, createSuper as n, classCallCheck as o, defineProperty as r, assertThisInitialized as i, createClass as a, get as s, getPrototypeOf as l } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { _node as c, _attr as u, _pos as v } from "./svg-util.js";
import { DragManager as h } from "./drag-manager.js";
import { ElementDragHandler as f } from "./element-drag-handler.js";
import { EndpointDragHandler as d } from "./endpoint-drag-handler.js";
import { GroupDragHandler as g } from "./group-drag-handler.js";
import { getElementType as p, getClass as m, addClass as y, hasClass as C, removeClass as _, toggleClass as M, isNodeList as k, offsetRelativeToRoot as b, getSelector as D, getEventSource as x, findParent as S, consume as j, ElementTypes as E, isSVGElement as w } from "./browser-util.js";
import { pageLocation as T, EventManager as O } from "./event-manager.js";
import { HTMLElementOverlay as A } from "./html-element-overlay.js";
import { ensureSVGPathElement as H, ensureSVGLabelElements as P, paintSVGPathOverlay as N, paintSVGLabelOverlay as F, destroySVGOverlay as L } from "./svg-element-overlay.js";
import { paintSvgConnector as z } from "./svg-element-connector.js";
import { SvgEndpoint as G } from "./svg-element-endpoint.js";
import { EVENT_CONNECTION_CLICK as I, EVENT_CONNECTION_DBL_CLICK as R, EVENT_CONNECTION_TAP as Z, EVENT_CONNECTION_DBL_TAP as V, EVENT_CONNECTION_CONTEXTMENU as W, EVENT_ENDPOINT_CLICK as B, EVENT_ENDPOINT_DBL_CLICK as U, EVENT_CLICK as X, EVENT_DBL_CLICK as Y, EVENT_TAP as q, EVENT_DBL_TAP as J, EVENT_ELEMENT_CLICK as K, EVENT_ELEMENT_TAP as Q, EVENT_ELEMENT_DBL_TAP as $, EVENT_ELEMENT_MOUSE_MOVE as ee, EVENT_ELEMENT_MOUSE_UP as te, EVENT_ELEMENT_MOUSE_DOWN as ne, EVENT_ELEMENT_CONTEXTMENU as oe, EVENT_CONTEXTMENU as re, EVENT_MOUSEUP as ie, EVENT_MOUSEDOWN as ae, EVENT_MOUSEOVER as se, EVENT_MOUSEOUT as le, EVENT_MOUSEMOVE as ce, EVENT_MOUSEENTER as ue, EVENT_MOUSEEXIT as ve, EVENT_CONNECTION_MOUSEOVER as he, EVENT_CONNECTION_MOUSEOUT as fe, EVENT_CONNECTION_MOUSEUP as de, EVENT_CONNECTION_MOUSEDOWN as ge, EVENT_ENDPOINT_MOUSEOVER as pe, EVENT_ENDPOINT_MOUSEOUT as me, EVENT_ENDPOINT_MOUSEUP as ye, EVENT_ENDPOINT_MOUSEDOWN as Ce, EVENT_ELEMENT_DBL_CLICK as _e, EVENT_ELEMENT_MOUSE_OVER as Me, EVENT_ELEMENT_MOUSE_OUT as ke } from "./event-constants.js";
import { PROPERTY_POSITION as be, SELECTOR_GROUP_CONTAINER as De, SELECTOR_OVERLAY as xe, SELECTOR_CONNECTOR as Se, SELECTOR_ENDPOINT as je, ATTRIBUTE_CONTAINER as Ee, CONNECTION as we } from "./constants.js";
import { DragSelection as Te } from "./drag-selection.js";
import { isArrowOverlay as Oe } from "../core/overlay/arrow-overlay.js";
import { isDiamondOverlay as Ae } from "../core/overlay/diamond-overlay.js";
import { isPlainArrowOverlay as He } from "../core/overlay/plain-arrow-overlay.js";
import { EndpointRepresentation as Pe } from "../core/endpoint/endpoint-representation.js";
import { isLabelOverlay as Ne } from "../core/overlay/label-overlay.js";
import { isCustomOverlay as Fe } from "../core/overlay/custom-overlay.js";
import { isEndpoint as Le, isConnection as ze } from "../core/component/component.js";
import { SELECTOR_MANAGED_ELEMENT as Ge, ATTRIBUTE_MANAGED as Ie, STATIC as Re, ABSOLUTE as Ze, FIXED as Ve, ATTRIBUTE_NOT_DRAGGABLE as We } from "../core/constants.js";
import { JsPlumbInstance as Be } from "../core/core.js";
import { UNDEFINED as Ue, TRUE as Xe, FALSE as Ye } from "../common/index.js";
import { log as qe, forEach as Je, uuid as Ke, fromArray as Qe, isString as $e, isFunction as et } from "../util/util.js";
import { getPosition as tt, getElementSize as nt, getElementInternalSize as ot, setPosition as rt } from "./positioning-and-sizing-utils.js";
import { _connectorPointOnPath as it } from "../core/connector/connectors.js";
import { register as at } from "./dot-endpoint-renderer.js";
import { register as st } from "./blank-endpoint-renderer.js";
import { register as lt } from "./rectangle-endpoint-renderer.js";
import { CLASS_CONNECTOR as ct, CLASS_ENDPOINT as ut, CLASS_OVERLAY as vt } from "../core/css-constants.js";
var ht = {};
function ft(e, t) {
  ht[e] = t;
}
function dt(t, n, o) {
  var r = n;
  var i = e(n.getBoundingClientRect) !== Ue ? n.getBoundingClientRect() : {
      left: 0,
      top: 0,
      width: 0,
      height: 0
    },
    a = document.body,
    s = document.documentElement,
    l = window.pageYOffset || s.scrollTop || a.scrollTop,
    c = window.pageXOffset || s.scrollLeft || a.scrollLeft,
    u = s.clientTop || a.clientTop || 0,
    v = s.clientLeft || a.clientLeft || 0,
    h = 0,
    f = 0,
    d = i.top + l - u + h * o,
    g = i.left + c - v + f * o,
    p = T(t),
    m = i.width || r.offsetWidth * o,
    y = i.height || r.offsetHeight * o,
    C = (p.x - g) / m,
    _ = (p.y - d) / y;
  return {
    x: C,
    y: _
  };
}
function gt(e) {
  return Oe(e) || Ae(e) || He(e);
}
function pt(e) {
  return Ne(e) && e.useHTMLElement !== true;
}
function mt(e, t) {
  if (e.canvas) {
    e.canvas.style.display = t ? "block" : "none";
  }
}
function yt(e) {
  if (e.canvas) {
    e.canvas.parentNode.removeChild(e.canvas);
  }
  delete e.canvas;
}
function Ct(e) {
  return e.canvas;
}
function _t(e) {
  if (e.useHTMLElement) {
    var t = A.getElement(e);
    y(t, e.instance.labelOverlayClass);
    return t;
  } else {
    P(e);
    return e.textElement;
  }
}
function Mt(e) {
  return A.getElement(e, e.component, function (t) {
    var n = e.create(t);
    y(n, e.instance.overlayClass);
    return n;
  });
}
function kt(e, t, n, o) {
  var r = e.x,
    i = e.y;
  if (t._jsPlumbParentGroup && t._jsPlumbParentGroup.constrain) {
    r = Math.max(e.x, 0);
    i = Math.max(e.y, 0);
    r = Math.min(r, n.w - o.w);
    i = Math.min(i, n.h - o.h);
  }
  return {
    x: r,
    y: i
  };
}
at();
st();
lt();
var bt = function (T) {
  t(at, T);
  var Be = n(at);
  function at(e, t, n) {
    var a;
    o(this, at);
    a = Be.call(this, e, t, n);
    a.toolkit = e;
    r(i(a), "containerType", null);
    r(i(a), "dragSelection", void 0);
    r(i(a), "dragManager", void 0);
    r(i(a), "_connectorClick", void 0);
    r(i(a), "_connectorDblClick", void 0);
    r(i(a), "_connectorTap", void 0);
    r(i(a), "_connectorDblTap", void 0);
    r(i(a), "_endpointClick", void 0);
    r(i(a), "_endpointDblClick", void 0);
    r(i(a), "_overlayClick", void 0);
    r(i(a), "_overlayDblClick", void 0);
    r(i(a), "_overlayTap", void 0);
    r(i(a), "_overlayDblTap", void 0);
    r(i(a), "_connectorMouseover", void 0);
    r(i(a), "_connectorMouseout", void 0);
    r(i(a), "_endpointMouseover", void 0);
    r(i(a), "_endpointMouseout", void 0);
    r(i(a), "_connectorContextmenu", void 0);
    r(i(a), "_connectorMousedown", void 0);
    r(i(a), "_connectorMouseup", void 0);
    r(i(a), "_endpointMousedown", void 0);
    r(i(a), "_endpointMouseup", void 0);
    r(i(a), "_overlayMouseover", void 0);
    r(i(a), "_overlayMouseout", void 0);
    r(i(a), "_elementClick", void 0);
    r(i(a), "_elementTap", void 0);
    r(i(a), "_elementDblTap", void 0);
    r(i(a), "_elementMouseenter", void 0);
    r(i(a), "_elementMouseexit", void 0);
    r(i(a), "_elementMousemove", void 0);
    r(i(a), "_elementMouseup", void 0);
    r(i(a), "_elementMousedown", void 0);
    r(i(a), "_elementContextmenu", void 0);
    r(i(a), "_resizeObserver", void 0);
    r(i(a), "eventManager", void 0);
    r(i(a), "draggingClass", "jtk-dragging");
    r(i(a), "elementDraggingClass", "jtk-element-dragging");
    r(i(a), "hoverClass", "jtk-hover");
    r(i(a), "sourceElementDraggingClass", "jtk-source-element-dragging");
    r(i(a), "targetElementDraggingClass", "jtk-target-element-dragging");
    r(i(a), "hoverSourceClass", "jtk-source-hover");
    r(i(a), "hoverTargetClass", "jtk-target-hover");
    r(i(a), "dragSelectClass", "jtk-drag-select");
    r(i(a), "managedElementsSelector", void 0);
    r(i(a), "_hoverEvents", void 0);
    r(i(a), "getEffectiveVisibleZoom", void 0);
    r(i(a), "elementsDraggable", void 0);
    r(i(a), "elementDragHandler", void 0);
    r(i(a), "groupDragOptions", void 0);
    r(i(a), "elementDragOptions", void 0);
    r(i(a), "onElementDragInit", void 0);
    r(i(a), "svg", {
      node: function e(t, n) {
        return c(t, n);
      },
      attr: function e(t, n) {
        return u(t, n);
      },
      pos: function e(t) {
        return v(t);
      }
    });
    r(i(a), "beforeDetach", void 0);
    r(i(a), "beforeDrag", void 0);
    r(i(a), "beforeStartDetach", void 0);
    r(i(a), "beforeDrop", void 0);
    n = n || {};
    a.containerType = p(a.getContainer());
    a.elementsDraggable = n && n.elementsDraggable !== false;
    a._hoverEvents = n.hoverEvents === true;
    a.onElementDragInit = t.onElementDragInit || function (e) {
      return null;
    };
    a.managedElementsSelector = n ? n.managedElementsSelector || Ge : Ge;
    a.eventManager = new O();
    a.dragSelection = new Te(i(a));
    a.dragManager = new h(i(a), a.dragSelection, {
      useSvgContainer: n.useSvgContainer,
      getCompoundZoom: t.getCompoundZoom
    });
    a.dragManager.addHandler(new d(i(a)));
    a.getEffectiveVisibleZoom = function () {
      var e = t.getCompoundZoom ? t.getCompoundZoom() : 1;
      return a.currentZoom * e;
    };
    var s = new g(i(a), a.dragSelection, function (e) {
      return a.onElementDragInit(e);
    });
    var l = s.getConstrainFunction();
    a.groupDragOptions = {
      constrainFunction: l
    };
    a.beforeDetach = t.beforeDetach || function (e, t) {
      return true;
    };
    a.beforeDrag = t.beforeDrag || function (e) {
      return true;
    };
    a.beforeStartDetach = t.beforeStartDetach || function (e) {
      return true;
    };
    a.beforeDrop = t.beforeDrop || function (e) {
      return true;
    };
    a.dragManager.addHandler(s, a.groupDragOptions);
    a.elementDragHandler = new f(i(a), a.dragSelection, function (e) {
      return a.onElementDragInit(e);
    });
    a.elementDragOptions = n && n.dragOptions || {};
    a.dragManager.addHandler(a.elementDragHandler, a.elementDragOptions);
    if (n && n.dragOptions && n.dragOptions.filter) {
      a.dragManager.addFilter(n.dragOptions.filter);
    }
    a._createEventListeners();
    a._attachEventDelegates();
    if (n.resizeObserver !== false) {
      try {
        a._resizeObserver = new ResizeObserver(function (e) {
          var t = e.filter(function (e) {
            var t = a.getAttribute(e.target, Ie);
            if (t != null) {
              var n = a.viewport._elementMap.get(t);
              return n ? n.w !== e.contentRect.width || n.h !== e.contentRect.height : false;
            } else {
              return false;
            }
          });
          t.forEach(function (e) {
            a.revalidate(e.target);
          });
        });
      } catch (e) {
        qe("WARN: ResizeObserver could not be attached.");
      }
    }
    return a;
  }
  a(at, [{
    key: "fireOverlayMethod",
    value: function e(t, n, o) {
      var r = we;
      var i = "".concat(r, ":").concat(n);
      o._jsPlumbOverlay = t;
      t.fire(n, {
        e: o,
        overlay: t
      });
      this.fire(i, t.component, o);
    }
  }, {
    key: "addDragFilter",
    value: function e(t, n) {
      this.dragManager.addFilter(t, n);
    }
  }, {
    key: "removeDragFilter",
    value: function e(t) {
      this.dragManager.removeFilter(t);
    }
  }, {
    key: "setDragGrid",
    value: function e(t) {
      this.dragManager.setOption(this.elementDragHandler, {
        grid: t
      });
    }
  }, {
    key: "setDragConstrainFunction",
    value: function e(t) {
      this.dragManager.setOption(this.elementDragHandler, {
        constrainFunction: t
      });
    }
  }, {
    key: "_removeElement",
    value: function e(t) {
      t.parentNode && t.parentNode.removeChild(t);
    }
  }, {
    key: "_appendElement",
    value: function e(t, n) {
      if (n) {
        n.appendChild(t);
      }
    }
  }, {
    key: "_appendElementToGroup",
    value: function e(t, n) {
      this._appendElement(n, t.contentArea);
    }
  }, {
    key: "_appendElementToContainer",
    value: function e(t) {
      this._appendElement(t, this.getContainer());
    }
  }, {
    key: "_getAssociatedElements",
    value: function e(t) {
      var n = [];
      if (t.nodeType !== 3 && t.nodeType !== 8) {
        var o = t.querySelectorAll(Ge);
        Array.prototype.push.apply(n, o);
      }
      return n.filter(function (e) {
        return e.nodeType !== 3 && e.nodeType !== 8;
      });
    }
  }, {
    key: "getClass",
    value: function e(t) {
      return m(t);
    }
  }, {
    key: "addClass",
    value: function e(t, n) {
      y(t, n);
    }
  }, {
    key: "hasClass",
    value: function e(t, n) {
      return C(t, n);
    }
  }, {
    key: "removeClass",
    value: function e(t, n) {
      _(t, n);
    }
  }, {
    key: "toggleClass",
    value: function e(t, n) {
      M(t, n);
    }
  }, {
    key: "setAttribute",
    value: function e(t, n, o) {
      t.setAttribute(n, o);
    }
  }, {
    key: "getAttribute",
    value: function e(t, n) {
      return t.getAttribute(n);
    }
  }, {
    key: "setAttributes",
    value: function e(t, n) {
      for (var o in n) {
        t.setAttribute(o, n[o]);
      }
    }
  }, {
    key: "removeAttribute",
    value: function e(t, n) {
      t.removeAttribute && t.removeAttribute(n);
    }
  }, {
    key: "on",
    value: function e(t, n, o, r) {
      var i = this;
      var a = function e(t) {
        if (r == null) {
          i.eventManager.on(t, n, o);
        } else {
          i.eventManager.on(t, n, o, r);
        }
      };
      if (k(t)) {
        Je(t, function (e) {
          return a(e);
        });
      } else {
        a(t);
      }
      return this;
    }
  }, {
    key: "off",
    value: function e(t, n, o) {
      var r = this;
      if (k(t)) {
        Je(t, function (e) {
          return r.eventManager.off(e, n, o);
        });
      } else {
        this.eventManager.off(t, n, o);
      }
      return this;
    }
  }, {
    key: "trigger",
    value: function e(t, n, o, r, i) {
      this.eventManager.trigger(t, n, o, r, i);
    }
  }, {
    key: "getOffsetRelativeToRoot",
    value: function e(t) {
      return b(t);
    }
  }, {
    key: "_adjustForParents",
    value: function e(t, n, o) {
      function r(e) {
        if (e.offsetParent != null) {
          return e.offsetParent;
        } else {
          return e.parentNode;
        }
      }
      var i = t !== o && r(t) !== o ? r(t) : null,
        a = function e(t) {
          if (t != null && t !== document.body && !isNaN(t.scrollTop) && !isNaN(t.scrollLeft) && (t.scrollTop > 0 || t.scrollLeft > 0)) {
            n.x -= t.scrollLeft;
            n.y -= t.scrollTop;
          }
        };
      while (i != null) {
        var s = tt(i) || {
          x: 0,
          y: 0
        };
        n.x += s.x;
        n.y += s.y;
        a(i);
        i = r(i) === o ? null : r(i);
      }
    }
  }, {
    key: "getOffset",
    value: function e(t) {
      var n = t;
      var o = this.getContainer();
      var r = tt(n);
      this._adjustForParents(n, r, o);
      if (o != null && !isNaN(o.scrollTop) && !isNaN(o.scrollLeft) && (o.scrollTop > 0 || o.scrollLeft > 0)) {
        var i = n.offsetParent != null ? this.getStyle(n.offsetParent, be) : Re,
          a = this.getStyle(n, be);
        if (a !== Ze && a !== Ve && i !== Ze && i !== Ve) {
          r.x -= o.scrollLeft;
          r.y -= o.scrollTop;
        }
      }
      return r;
    }
  }, {
    key: "getSize",
    value: function e(t) {
      return nt(t, this.currentZoom);
    }
  }, {
    key: "getInternalSize",
    value: function e(t) {
      return ot(t);
    }
  }, {
    key: "getPosition",
    value: function e(t) {
      return tt(t);
    }
  }, {
    key: "getStyle",
    value: function t(n, o) {
      if (e(window.getComputedStyle) !== Ue) {
        return getComputedStyle(n, null).getPropertyValue(o);
      } else {
        return n.currentStyle[o];
      }
    }
  }, {
    key: "getGroupContentArea",
    value: function e(t) {
      var n = this.getSelector(t.el, De);
      return n && n.length > 0 ? n[0] : t.el;
    }
  }, {
    key: "getSelector",
    value: function e(t, n) {
      return D.apply(null, arguments);
    }
  }, {
    key: "setPosition",
    value: function e(t, n) {
      rt(t, n);
    }
  }, {
    key: "setDraggable",
    value: function e(t, n) {
      if (n) {
        this.removeAttribute(t, We);
      } else {
        this.setAttribute(t, We, Xe);
      }
    }
  }, {
    key: "isDraggable",
    value: function e(t) {
      var n = this.getAttribute(t, We);
      return n == null || n === Ye;
    }
  }, {
    key: "toggleDraggable",
    value: function e(t) {
      var n = this.isDraggable(t);
      this.setDraggable(t, !n);
      return !n;
    }
  }, {
    key: "_createEventListeners",
    value: function e() {
      var t = function e(t, n) {
        if (!n.defaultPrevented && n._jsPlumbOverlay == null) {
          var o = S(x(n), Se, this.getContainer(), true);
          this.fire(t, o.jtk.connector.connection, n);
        }
      };
      this._connectorClick = t.bind(this, I);
      this._connectorDblClick = t.bind(this, R);
      this._connectorTap = t.bind(this, Z);
      this._connectorDblTap = t.bind(this, V);
      var n = function e(t, n) {
        var o = x(n).parentNode;
        if (o.jtk && o.jtk.connector) {
          var r = o.jtk.connector;
          var i = r.connection;
          this.setConnectorHover(r, t);
          if (t) {
            this.addClass(i.source, this.hoverSourceClass);
            this.addClass(i.target, this.hoverTargetClass);
          } else {
            this.removeClass(i.source, this.hoverSourceClass);
            this.removeClass(i.target, this.hoverTargetClass);
          }
          this.fire(t ? he : fe, o.jtk.connector.connection, n);
        }
      };
      this._connectorMouseover = n.bind(this, true);
      this._connectorMouseout = n.bind(this, false);
      var o = function e(t, n) {
        var o = x(n).parentNode;
        if (o.jtk && o.jtk.connector) {
          this.fire(t ? de : ge, o.jtk.connector.connection, n);
        }
      };
      this._connectorMouseup = o.bind(this, true);
      this._connectorMousedown = o.bind(this, false);
      this._connectorContextmenu = function (e) {
        var t = x(e).parentNode;
        if (t.jtk && t.jtk.connector) {
          this.fire(W, t.jtk.connector.connection, e);
        }
      }.bind(this);
      var r = function e(t, n, o) {
        if (!n.defaultPrevented && n._jsPlumbOverlay == null) {
          this.fire(t, o.jtk.endpoint, n);
        }
      };
      this._endpointClick = r.bind(this, B);
      this._endpointDblClick = r.bind(this, U);
      var i = function e(t, n) {
        var o = x(n);
        if (o.jtk && o.jtk.endpoint) {
          this.setEndpointHover(o.jtk.endpoint, t);
          this.fire(t ? pe : me, o.jtk.endpoint, n);
        }
      };
      this._endpointMouseover = i.bind(this, true);
      this._endpointMouseout = i.bind(this, false);
      var a = function e(t, n) {
        var o = x(n);
        if (o.jtk && o.jtk.endpoint) {
          this.fire(t ? ye : Ce, o.jtk.endpoint, n);
        }
      };
      this._endpointMouseup = a.bind(this, true);
      this._endpointMousedown = a.bind(this, false);
      var s = function (e, t) {
        var n = S(x(t), xe, this.getContainer(), true);
        var o = n.jtk.overlay;
        if (o) {
          this.fireOverlayMethod(o, e, t);
        }
      }.bind(this);
      this._overlayClick = s.bind(this, X);
      this._overlayDblClick = s.bind(this, Y);
      this._overlayTap = s.bind(this, q);
      this._overlayDblTap = s.bind(this, J);
      var l = function e(t, n) {
        var o = S(x(n), xe, this.getContainer(), true);
        var r = o.jtk.overlay;
        if (r) {
          this.setOverlayHover(r, t);
        }
      };
      this._overlayMouseover = l.bind(this, true);
      this._overlayMouseout = l.bind(this, false);
      var c = function e(t, n, o) {
        if (!n.defaultPrevented) {
          this.fire(n.detail === 1 ? K : _e, o, n);
        }
      };
      this._elementClick = c.bind(this, K);
      var u = function e(t, n, o) {
        if (!n.defaultPrevented) {
          this.fire(Q, o, n);
        }
      };
      this._elementTap = u.bind(this, Q);
      var v = function e(t, n, o) {
        if (!n.defaultPrevented) {
          this.fire($, o, n);
        }
      };
      this._elementDblTap = v.bind(this, $);
      var h = function e(t, n) {
        this.fire(t ? Me : ke, x(n), n);
      };
      this._elementMouseenter = h.bind(this, true);
      this._elementMouseexit = h.bind(this, false);
      this._elementMousemove = function (e) {
        this.fire(ee, x(e), e);
      }.bind(this);
      this._elementMouseup = function (e) {
        this.fire(te, x(e), e);
      }.bind(this);
      this._elementMousedown = function (e) {
        this.fire(ne, x(e), e);
      }.bind(this);
      this._elementContextmenu = function (e) {
        this.fire(oe, x(e), e);
      }.bind(this);
    }
  }, {
    key: "_attachEventDelegates",
    value: function e() {
      var t = this.getContainer();
      this.eventManager.on(t, X, xe, this._overlayClick);
      this.eventManager.on(t, Y, xe, this._overlayDblClick);
      this.eventManager.on(t, q, xe, this._overlayTap);
      this.eventManager.on(t, J, xe, this._overlayDblTap);
      this.eventManager.on(t, X, Se, this._connectorClick);
      this.eventManager.on(t, Y, Se, this._connectorDblClick);
      this.eventManager.on(t, q, Se, this._connectorTap);
      this.eventManager.on(t, J, Se, this._connectorDblTap);
      this.eventManager.on(t, X, je, this._endpointClick);
      this.eventManager.on(t, Y, je, this._endpointDblClick);
      this.eventManager.on(t, X, this.managedElementsSelector, this._elementClick);
      this.eventManager.on(t, q, this.managedElementsSelector, this._elementTap);
      this.eventManager.on(t, J, this.managedElementsSelector, this._elementDblTap);
      this.eventManager.on(t, re, Se, this._connectorContextmenu);
      this.eventManager.on(t, ie, Se, this._connectorMouseup);
      this.eventManager.on(t, ae, Se, this._connectorMousedown);
      this.eventManager.on(t, ie, je, this._endpointMouseup);
      this.eventManager.on(t, ae, je, this._endpointMousedown);
      if (this._hoverEvents === true) {
        this.eventManager.on(t, se, Se, this._connectorMouseover);
        this.eventManager.on(t, le, Se, this._connectorMouseout);
        this.eventManager.on(t, se, je, this._endpointMouseover);
        this.eventManager.on(t, le, je, this._endpointMouseout);
        this.eventManager.on(t, se, xe, this._overlayMouseover);
        this.eventManager.on(t, le, xe, this._overlayMouseout);
        this.eventManager.on(t, se, Ge, this._elementMouseenter);
        this.eventManager.on(t, le, Ge, this._elementMouseexit);
        this.eventManager.on(t, ce, Ge, this._elementMousemove);
      }
      this.eventManager.on(t, ie, Ge, this._elementMouseup);
      this.eventManager.on(t, ae, Ge, this._elementMousedown);
      this.eventManager.on(t, re, Ge, this._elementContextmenu);
    }
  }, {
    key: "_detachEventDelegates",
    value: function e() {
      var t = this.getContainer();
      if (t) {
        this.eventManager.off(t, X, this._connectorClick);
        this.eventManager.off(t, Y, this._connectorDblClick);
        this.eventManager.off(t, q, this._connectorTap);
        this.eventManager.off(t, J, this._connectorDblTap);
        this.eventManager.off(t, X, this._endpointClick);
        this.eventManager.off(t, Y, this._endpointDblClick);
        this.eventManager.off(t, X, this._overlayClick);
        this.eventManager.off(t, Y, this._overlayDblClick);
        this.eventManager.off(t, q, this._overlayTap);
        this.eventManager.off(t, J, this._overlayDblTap);
        this.eventManager.off(t, X, this._elementClick);
        this.eventManager.off(t, q, this._elementTap);
        this.eventManager.off(t, J, this._elementDblTap);
        this.eventManager.off(t, re, this._connectorContextmenu);
        this.eventManager.off(t, ie, this._connectorMouseup);
        this.eventManager.off(t, ae, this._connectorMousedown);
        this.eventManager.off(t, ie, this._endpointMouseup);
        this.eventManager.off(t, ae, this._endpointMousedown);
        if (this._hoverEvents === true) {
          this.eventManager.off(t, se, this._connectorMouseover);
          this.eventManager.off(t, le, this._connectorMouseout);
          this.eventManager.off(t, se, this._endpointMouseover);
          this.eventManager.off(t, le, this._endpointMouseout);
          this.eventManager.off(t, se, this._overlayMouseover);
          this.eventManager.off(t, le, this._overlayMouseout);
          this.eventManager.off(t, ue, this._elementMouseenter);
          this.eventManager.off(t, ve, this._elementMouseexit);
          this.eventManager.off(t, ce, this._elementMousemove);
        }
        this.eventManager.off(t, ie, this._elementMouseup);
        this.eventManager.off(t, ae, this._elementMousedown);
        this.eventManager.off(t, re, this._elementContextmenu);
      }
    }
  }, {
    key: "setContainer",
    value: function e(t) {
      var n = this;
      if (t === document || t === document.body) {
        throw new Error("Cannot set document or document.body as container element");
      }
      this._detachEventDelegates();
      var o;
      if (this.dragManager != null) {
        o = this.dragManager.reset();
      }
      this.setAttribute(t, Ee, Ke().replace("-", ""));
      var r = this.getContainer();
      if (r != null) {
        r.removeAttribute(Ee);
        var i = Qe(r.childNodes).filter(function (e) {
          return e != null && (n.hasClass(e, ct) || n.hasClass(e, ut) || n.hasClass(e, vt) || e.getAttribute && e.getAttribute(Ie) != null);
        });
        Je(i, function (e) {
          t.appendChild(e);
        });
      }
      s(l(at.prototype), "setContainer", this).call(this, t);
      this.containerType = p(t);
      if (this.eventManager != null) {
        this._attachEventDelegates();
      }
      if (this.dragManager != null) {
        this.dragManager.addHandler(new d(this));
        this.dragManager.addHandler(new g(this, this.dragSelection, function (e) {
          return n.onElementDragInit(e);
        }), this.groupDragOptions);
        this.elementDragHandler = new f(this, this.dragSelection, function (e) {
          return n.onElementDragInit(e);
        });
        this.dragManager.addHandler(this.elementDragHandler, this.elementDragOptions);
        if (o != null) {
          this.dragManager.setFilters(o);
        }
      }
    }
  }, {
    key: "reset",
    value: function e() {
      s(l(at.prototype), "reset", this).call(this);
      if (this._resizeObserver) {
        this._resizeObserver.disconnect();
      }
      var t = this.getContainer();
      var n = t.querySelectorAll([Ge, je, Se, xe].join(","));
      Je(n, function (e) {
        return e.parentNode && e.parentNode.removeChild(e);
      });
    }
  }, {
    key: "destroy",
    value: function e() {
      this._detachEventDelegates();
      if (this.dragManager != null) {
        this.dragManager.reset();
      }
      this.clearDragSelection();
      s(l(at.prototype), "destroy", this).call(this);
    }
  }, {
    key: "unmanage",
    value: function e(t, n) {
      if (this._resizeObserver != null) {
        this._resizeObserver.unobserve(t);
      }
      this.removeFromDragSelection(t);
      s(l(at.prototype), "unmanage", this).call(this, t, n);
    }
  }, {
    key: "addToDragSelection",
    value: function e() {
      var t = this;
      for (var n = arguments.length, o = new Array(n), r = 0; r < n; r++) {
        o[r] = arguments[r];
      }
      Je(o, function (e) {
        return t.dragSelection.add(e);
      });
    }
  }, {
    key: "clearDragSelection",
    value: function e() {
      this.dragSelection.clear();
    }
  }, {
    key: "removeFromDragSelection",
    value: function e() {
      var t = this;
      for (var n = arguments.length, o = new Array(n), r = 0; r < n; r++) {
        o[r] = arguments[r];
      }
      Je(o, function (e) {
        return t.dragSelection.remove(e);
      });
    }
  }, {
    key: "toggleDragSelection",
    value: function e() {
      var t = this;
      for (var n = arguments.length, o = new Array(n), r = 0; r < n; r++) {
        o[r] = arguments[r];
      }
      Je(o, function (e) {
        return t.dragSelection.toggle(e);
      });
    }
  }, {
    key: "addToDragGroup",
    value: function e(t) {
      var n;
      for (var o = arguments.length, r = new Array(o > 1 ? o - 1 : 0), i = 1; i < o; i++) {
        r[i - 1] = arguments[i];
      }
      (n = this.elementDragHandler).addToDragGroup.apply(n, [t].concat(r));
    }
  }, {
    key: "removeFromDragGroup",
    value: function e() {
      var t;
      (t = this.elementDragHandler).removeFromDragGroup.apply(t, arguments);
    }
  }, {
    key: "setDragGroupState",
    value: function e(t) {
      var n;
      for (var o = arguments.length, r = new Array(o > 1 ? o - 1 : 0), i = 1; i < o; i++) {
        r[i - 1] = arguments[i];
      }
      (n = this.elementDragHandler).setDragGroupState.apply(n, [t].concat(r));
    }
  }, {
    key: "clearDragGroup",
    value: function e(t) {
      this.elementDragHandler.clearDragGroup(t);
    }
  }, {
    key: "consume",
    value: function e(t, n) {
      j(t, n);
    }
  }, {
    key: "rotate",
    value: function e(t, n, o) {
      var r = this.getId(t);
      if (this._managedElements[r]) {
        this._managedElements[r].el.style.transform = "rotate(" + n + "deg)";
        this._managedElements[r].el.style.transformOrigin = "center center";
        return s(l(at.prototype), "rotate", this).call(this, t, n, o);
      }
      return {
        c: new Set(),
        e: new Set()
      };
    }
  }, {
    key: "addOverlayClass",
    value: function e(t, n) {
      if (gt(t)) {
        y(H(t), n);
      } else if (pt(t)) {
        y(P(t), n);
      } else if (Ne(t)) {
        y(_t(t), n);
      } else if (Fe(t)) {
        y(Mt(t), n);
      } else {
        throw "Could not add class to overlay of type [" + t.type + "]";
      }
    }
  }, {
    key: "removeOverlayClass",
    value: function e(t, n) {
      if (gt(t)) {
        t.instance.removeClass(H(t), n);
      } else if (pt(t)) {
        t.instance.removeClass(P(t), n);
      } else if (Ne(t)) {
        t.instance.removeClass(_t(t), n);
      } else if (Fe(t)) {
        t.instance.removeClass(Mt(t), n);
      } else {
        throw "Could not remove class from overlay of type [" + t.type + "]";
      }
    }
  }, {
    key: "_paintOverlay",
    value: function e(t, n, o) {
      if (gt(t)) {
        var r = isNaN(n.d.cxy.x) || isNaN(n.d.cxy.y) ? "M 0 0" : "M" + n.d.hxy.x + "," + n.d.hxy.y + " L" + n.d.tail[0].x + "," + n.d.tail[0].y + " L" + n.d.cxy.x + "," + n.d.cxy.y + " L" + n.d.tail[1].x + "," + n.d.tail[1].y + " Z";
        N(t, r, n, o);
      } else if (pt(t)) {
        F(t, n, o);
      } else if (Ne(t)) {
        _t(t);
        var i = t.component.getXY();
        t.canvas.style.left = i.x + n.d.minx + "px";
        t.canvas.style.top = i.y + n.d.miny + "px";
      } else if (Fe(t)) {
        var a = Mt(t);
        var s = t.component.getXY();
        if (t.location === 0 || t.location === 1) {
          var l = t.component.endpoints[t.location]._anchor.computedPosition;
          this.setAttributes(a, {
            "data-anchor-x": "".concat(l.x),
            "data-anchor-y": "".concat(l.y),
            "data-anchor-ox": "".concat(l.ox),
            "data-anchor-oy": "".concat(l.oy)
          });
        }
        t.canvas.style.left = s.x + n.d.minx + "px";
        t.canvas.style.top = s.y + n.d.miny + "px";
      } else {
        throw "Could not paint overlay of type [" + t.type + "]";
      }
    }
  }, {
    key: "setOverlayVisible",
    value: function e(t, n) {
      var o = n ? "block" : "none";
      function r(e) {
        if (e != null) {
          e.style.display = o;
        }
      }
      if (gt(t) || pt(t)) {
        r(t.contentElement);
      } else if (Ne(t)) {
        r(_t(t));
      } else if (Fe(t)) {
        r(Mt(t));
      }
    }
  }, {
    key: "reattachOverlay",
    value: function e(t, n) {
      if (pt(t)) {
        this._appendElement(P(t), n.connector.canvas);
      } else if (Ne(t)) {
        t.instance._appendElement(_t(t), this.getContainer());
      } else if (Fe(t)) {
        t.instance._appendElement(Mt(t), this.getContainer());
      } else if (gt(t)) {
        this._appendElement(H(t), n.connector.canvas);
      }
    }
  }, {
    key: "setOverlayHover",
    value: function e(t, n) {
      var o;
      if (Ne(t)) {
        o = _t(t);
      } else if (Fe(t)) {
        o = Mt(t);
      } else if (gt(t)) {
        o = H(t);
      } else if (pt(t)) {
        o = P(t);
      }
      if (o != null) {
        if (this.hoverClass != null) {
          if (n) {
            this.addClass(o, this.hoverClass);
          } else {
            this.removeClass(o, this.hoverClass);
          }
        }
        this.setHover(t.component, n);
      }
    }
  }, {
    key: "destroyOverlay",
    value: function e(t) {
      if (Ne(t)) {
        var n = _t(t);
        n.parentNode && n.parentNode.removeChild(n);
        delete t.canvas;
        delete t.cachedDimensions;
      } else if (Oe(t) || Ae(t) || He(t)) {
        L(t);
      } else if (Fe(t)) {
        var o = Mt(t);
        o.parentNode && o.parentNode.removeChild(o);
        delete t.canvas;
        delete t.cachedDimensions;
      }
    }
  }, {
    key: "drawOverlay",
    value: function e(t, n, o, r) {
      if (Oe(t) || Ae(t) || He(t)) {
        return t.draw(n, o, r);
      } else if (pt(t)) {
        var i = it(n, t.location);
        var a = o.stroke,
          s = o.stroke,
          l = o.strokeWidth;
        return {
          component: n,
          d: {
            loc: i
          },
          "stroke-width": l,
          stroke: a,
          fill: s,
          xmin: i.x,
          xmax: i.x,
          ymin: i.y,
          ymax: i.y
        };
      } else if (Ne(t) || Fe(t)) {
        var c = A._getDimensions(t);
        if (c != null && c.w != null && c.h != null) {
          var u = {
            x: 0,
            y: 0
          };
          if (r) {
            u = {
              x: r.x,
              y: r.y
            };
          } else if (n instanceof Pe) {
            var v = Array.isArray(t.location) ? t.location : [t.location, t.location];
            u = {
              x: v[0] * n.w,
              y: v[1] * n.h
            };
          } else {
            var h = t.location,
              f = false;
            if ($e(t.location) || t.location < 0 || t.location > 1) {
              h = parseInt("" + t.location, 10);
              f = true;
            }
            u = it(n, h, f);
          }
          var d = u.x - c.w / 2,
            g = u.y - c.h / 2;
          return {
            component: t,
            d: {
              minx: d,
              miny: g,
              td: c,
              cxy: u
            },
            xmin: d,
            xmax: d + c.w,
            ymin: g,
            ymax: g + c.h
          };
        } else {
          return {
            xmin: 0,
            xmax: 0,
            ymin: 0,
            ymax: 0
          };
        }
      } else {
        throw "Could not draw overlay of type [" + t.type + "]";
      }
    }
  }, {
    key: "updateLabel",
    value: function e(t) {
      var n = _t(t);
      if (et(t.label)) {
        var o = t.label(this);
        if (o != null) {
          n.innerText = o;
          n.textContent = o;
        } else {
          n.innerText = "";
          n.textContent = "";
        }
      } else {
        if (t.labelText == null) {
          t.labelText = t.label;
        }
        if (t.labelText != null) {
          n.innerText = t.labelText;
          n.textContent = t.labelText;
        } else {
          n.innerText = "";
          n.textContent = "";
        }
      }
    }
  }, {
    key: "setHover",
    value: function e(t, n) {
      t._hover = n;
      if (Le(t) && t.endpoint != null) {
        this.setEndpointHover(t, n, -1);
      } else if (ze(t) && t.connector != null) {
        this.setConnectorHover(t.connector, n);
      }
    }
  }, {
    key: "setZoom",
    value: function e(t, n) {
      this.dragManager.setZoom(t);
      return s(l(at.prototype), "setZoom", this).call(this, t, n);
    }
  }, {
    key: "paintConnector",
    value: function e(t, n, o) {
      z(this, t, n, o);
    }
  }, {
    key: "setConnectorHover",
    value: function e(t, n, o) {
      if (n === false || !this.currentlyDragging && !this.isHoverSuspended()) {
        var r = t.canvas;
        if (r != null) {
          if (t.hoverClass != null) {
            if (n) {
              this.addClass(r, t.hoverClass);
            } else {
              this.removeClass(r, t.hoverClass);
            }
          }
          if (n) {
            this.addClass(r, this.hoverClass);
          } else {
            this.removeClass(r, this.hoverClass);
          }
        }
        if (t.connection.hoverPaintStyle != null) {
          t.connection.paintStyleInUse = n ? t.connection.hoverPaintStyle : t.connection.paintStyle;
          if (!this._suspendDrawing) {
            this._paintConnection(t.connection);
          }
        }
        if (t.connection.endpoints[0] !== o) {
          this.setEndpointHover(t.connection.endpoints[0], n, 0, true);
        }
        if (t.connection.endpoints[1] !== o) {
          this.setEndpointHover(t.connection.endpoints[1], n, 1, true);
        }
      }
    }
  }, {
    key: "destroyConnector",
    value: function e(t) {
      if (t.connector != null) {
        yt(t.connector);
      }
    }
  }, {
    key: "addConnectorClass",
    value: function e(t, n) {
      if (t.canvas) {
        this.addClass(t.canvas, n);
      }
    }
  }, {
    key: "removeConnectorClass",
    value: function e(t, n) {
      if (t.canvas) {
        this.removeClass(t.canvas, n);
      }
    }
  }, {
    key: "getConnectorClass",
    value: function e(t) {
      if (t.canvas) {
        return t.canvas.className.baseVal;
      } else {
        return "";
      }
    }
  }, {
    key: "setConnectorVisible",
    value: function e(t, n) {
      mt(t, n);
    }
  }, {
    key: "applyConnectorType",
    value: function e(t, n) {
      if (n.cssClass) {
        var o = Array.isArray(n.cssClass) ? n.cssClass : [n.cssClass];
        if (t.canvas) {
          this.addClass(t.canvas, o.join(" "));
        } else {
          t.classesToAddWhenRendered = o;
        }
      }
    }
  }, {
    key: "addEndpointClass",
    value: function e(t, n) {
      var o = Ct(t.endpoint);
      if (o != null) {
        this.addClass(o, n);
      }
    }
  }, {
    key: "applyEndpointType",
    value: function e(t, n) {
      if (n.cssClass) {
        var o = Ct(t.endpoint);
        if (o) {
          var r = Array.isArray(n.cssClass) ? n.cssClass : [n.cssClass];
          this.addClass(o, r.join(" "));
        }
      }
    }
  }, {
    key: "destroyEndpoint",
    value: function e(t) {
      var n = this.endpointAnchorClassPrefix + (t.currentAnchorClass ? "-" + t.currentAnchorClass : "");
      this.removeClass(t.element, n);
      yt(t.endpoint);
    }
  }, {
    key: "renderEndpoint",
    value: function e(t, n) {
      var o = ht[t.endpoint.type];
      if (o != null) {
        G.paint(t.endpoint, o, n);
      } else {
        qe("jsPlumb: no endpoint renderer found for type [" + t.endpoint.type + "]");
      }
    }
  }, {
    key: "removeEndpointClass",
    value: function e(t, n) {
      var o = Ct(t.endpoint);
      if (o != null) {
        this.removeClass(o, n);
      }
    }
  }, {
    key: "getEndpointClass",
    value: function e(t) {
      var n = Ct(t.endpoint);
      if (n != null) {
        return n.className;
      } else {
        return "";
      }
    }
  }, {
    key: "setEndpointHover",
    value: function e(t, n, o, r) {
      if (t != null && (n === false || !this.currentlyDragging && !this.isHoverSuspended())) {
        var i = Ct(t.endpoint);
        if (i != null) {
          if (t.hoverClass != null) {
            if (n) {
              this.addClass(i, t.hoverClass);
            } else {
              this.removeClass(i, t.hoverClass);
            }
          }
          if (o === 0 || o === 1) {
            var a = o === 0 ? this.hoverSourceClass : this.hoverTargetClass;
            if (n) {
              this.addClass(i, a);
            } else {
              this.removeClass(i, a);
            }
          }
        }
        if (t.hoverPaintStyle != null) {
          t.paintStyleInUse = n ? t.hoverPaintStyle : t.paintStyle;
          if (!this._suspendDrawing) {
            this.renderEndpoint(t, t.paintStyleInUse);
          }
        }
        if (!r) {
          for (var s = 0; s < t.connections.length; s++) {
            this.setConnectorHover(t.connections[s].connector, n, t);
          }
        }
      }
    }
  }, {
    key: "setEndpointVisible",
    value: function e(t, n) {
      mt(t.endpoint, n);
    }
  }, {
    key: "setGroupVisible",
    value: function e(t, n) {
      var o = this.getSelector(t.el, Ge);
      for (var r = 0; r < o.length; r++) {
        if (n) {
          this.show(o[r], true);
        } else {
          this.hide(o[r], true);
        }
      }
    }
  }, {
    key: "deleteConnection",
    value: function e(t, n) {
      if (t != null && t.deleted !== true) {
        if (t.endpoints[0].deleted !== true) {
          this.setEndpointHover(t.endpoints[0], false, 0, true);
        }
        if (t.endpoints[1].deleted !== true) {
          this.setEndpointHover(t.endpoints[1], false, 1, true);
        }
        return s(l(at.prototype), "deleteConnection", this).call(this, t, n);
      } else {
        return false;
      }
    }
  }, {
    key: "addSourceSelector",
    value: function e(t, n, o) {
      this.addDragFilter(t);
      return s(l(at.prototype), "addSourceSelector", this).call(this, t, n, o);
    }
  }, {
    key: "removeSourceSelector",
    value: function e(t) {
      this.removeDragFilter(t.selector);
      s(l(at.prototype), "removeSourceSelector", this).call(this, t);
    }
  }, {
    key: "manage",
    value: function e(t, n, o) {
      if (this.containerType === E.SVG && !w(t)) {
        throw new Error("ERROR: cannot manage non-svg element when container is an SVG element.");
      }
      var r = s(l(at.prototype), "manage", this).call(this, t, n, o);
      if (r != null) {
        if (this._resizeObserver != null) {
          this._resizeObserver.observe(r.el);
        }
      }
      return r;
    }
  }, {
    key: "isInsideParent",
    value: function e(t, n) {
      var o = t.parentNode,
        r = this.getSize(o),
        i = this.getSize(t),
        a = n.x,
        s = a + i.w,
        l = n.y,
        c = l + i.h;
      return s > 0 && a < r.w && c > 0 && l < r.h;
    }
  }]);
  return at;
}(Be);
export { bt as BrowserJsPlumbInstance, dt as getPositionOnElement, kt as groupDragConstrain, ft as registerEndpointRenderer };