import { Injectable } from '@angular/core';
import { IDialogData } from '@core/models';
import { Observable, Subject } from 'rxjs';
import { RouterStateSnapshot } from '@angular/router';
import { DialogService } from './dialog.service';
import { TabService } from './tab.service';
import { FooterToolbarService } from './footer-toolbar.service';

export interface IGuardForm {
  endpoint: string;
  initialVal: string;
  changedVal?: string;
}

export interface IGuardDeactivated {
  deactivated: boolean;
  reset: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class GuardService {

  public deactivated$ = new Subject<IGuardDeactivated>();

  public guardForms: IGuardForm[] = [];
  public dialogOpen: boolean;

  constructor(
    private readonly dialogService: DialogService,
    private readonly tabs: TabService,
    private readonly footerToolbar: FooterToolbarService
  ) { }

  public canDeactivate(reset = true, targetRoute?: RouterStateSnapshot, isWizardClosing = false, resetFooterToolbar = true): Promise<boolean> {
    // const isWizard = this.router.url.includes('-wizard');
    return new Promise(resolve => {
      if (targetRoute?.url.includes('/login')) resolve(true);
      else {
        let hasChanges = false;

        // Loop over all the saved forms and look for a form with changes
        this.guardForms.forEach(f => {
          if (f.changedVal) {
            if (f.changedVal !== f.initialVal) hasChanges = true;
          } 
        });

        // If there are changes we open a dialog, otherwise allow navigation
        if (hasChanges) {
          this.dialogOpen = true;
          const dialogSettings: IDialogData = {
            type: 'alert',
            title: 'guard.dialogTitle',
            text: 'guard.dialogText',
            confirmLabel: 'confirm',
            cancelLabel: 'cancel',
            closable: false,
            isRichText: false
          };
          const dialog = this.dialogService.open(dialogSettings);

          // Subscribe to the afterClosed of the dialog to reset the forms we are keeping track of
          dialog.subscribe(val => {
            if (val) {
              if (!!this.guardForms.length) this.deactivated$.next({deactivated: val, reset});
              if (!isWizardClosing) this.guardForms.length = 0;
              if (reset) this.tabs.clear();
            }
            this.dialogOpen = false;
            if (val) this.footerToolbar.reset();
            resolve(val);
          });
        } else {
          // Reset the forms we are keeping track of after allowing navigation
          if (!!this.guardForms.length) this.deactivated$.next({deactivated: true, reset});
          if (!isWizardClosing) this.guardForms.length = 0;
          if (resetFooterToolbar) this.footerToolbar.reset();
          this.dialogOpen = false;
          resolve(true);
        }
      }
    });
  }

  public addForm(endpoint: string, value: string) {
    // Make sure the form we are trying to add does not exist already
    const formExists = this.guardForms.find(f => f.endpoint === endpoint);
    if (!formExists) {
      const form: IGuardForm = {
        endpoint: endpoint,
        initialVal: value
      };
      this.guardForms.push(form);
    }
  }

  public removeForm(endpoint: string): Observable<void> {
    //  Return observable to allow logic being executed when forms get closed
    return new Observable(obs => {
      const formIdx = this.guardForms.findIndex(f => f.endpoint === endpoint);
      if (formIdx > -1) {
        this.guardForms.splice(formIdx, 1);
        obs.complete();
      }
    });
  }

  public setChanged(endpoint: string, value: string) {
    const index = this.guardForms.findIndex(f => f.endpoint === endpoint);
    if (index > -1) this.guardForms[index].changedVal = value;
  }

  public hasChanges(): boolean {
    let hasChanges = false;
    this.guardForms.forEach(f => {
      if (f.changedVal) {
        if (f.changedVal !== f.initialVal) hasChanges = true;
      } 
    });
    return hasChanges;
  }

  public reset() {
    this.guardForms.length = 0;
  }
}