import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CdkDragEnd, CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CoreService, FormService, LanguageService, TabService } from '@core/services';
import { WORDPRESS_API_BASE_URL, WORDPRES_BASE_URL } from '@core/constants/core-constants';
import { LocalStorage } from '@core/decorators/storage.decorators';
import { NgClass, NgStyle } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ToolbarComponent } from '../../../shared/components/toolbar/toolbar.component';
import { ToolbarItemComponent } from '../../../shared/components/toolbar/toolbar-item/toolbar-item.component';
import { ButtonComponent } from '../../../shared/elements/button/button.component';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import type { INarisHelpContext, INarisHelpPanelPosition } from '@core/models';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  standalone: true,
  imports: [CdkDrag, NgClass, NgStyle, ToolbarComponent, CdkDragHandle, ToolbarItemComponent, ButtonComponent, LoaderComponent, TranslateModule]
})
export class HelpComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('helpPanel')
  private readonly helpPanel: ElementRef<HTMLDivElement>;

  @LocalStorage()
  public width: number;
  @LocalStorage()
  public height: number;
  @LocalStorage()
  public _helpPanelPosition: INarisHelpPanelPosition;

  @Output()
  public readonly closed = new EventEmitter<void>();

  public context: INarisHelpContext;
  private observer: ResizeObserver;
  private goHome = false;

  constructor(
    private readonly router: Router,
    private readonly formService: FormService,
    private readonly languageService: LanguageService,
    private readonly http: HttpClient,
    public readonly coreService: CoreService,
    private readonly tabService: TabService
  ) {}

  public ngOnInit(): void {
    // If panel position does not have a value, set the default
    this._helpPanelPosition ??= { x: 20, y: 20 };
    if (this.coreService.openArticle) {
      // Update the current context
      this.context = this.getContextConfig();
      // Perform the search
      this.searchArticle();
    }
    this.observer = new ResizeObserver(([ entry ]) => {
      this.width = entry.contentRect.width;
      this.height = entry.contentRect.height;
    });
  }

  public ngAfterViewInit(): void {
    this.observer.observe(this.helpPanel.nativeElement);
  }

  /**
   * Navigates the iframe to a specific page
   * @param endpoint wordpress page endpoint
   */
  public navigate(endpoint = ''): void {
    const href = `${WORDPRES_BASE_URL}${endpoint}`;
    this.coreService.setHelp(href);
  }

  /**
   * Executes a search using WordPress API to find relevant article
   * based on current context endpoint
   */
  public searchArticle() {
    // Update the current context
    this.context = this.getContextConfig();
    // Perform the search
    this.findRelatedArticle();
  }

  /**
   * Executes when panel dragging ends
   * @param event CdkDragEnd event
   */
  public cdkDragEnded(event: CdkDragEnd): void {
    // Save the chosen panel position
    this._helpPanelPosition = event.source.getFreeDragPosition();
  }

  /**
   * Determines if language warning should be shown
   * Returns true if current language is not Dutch
   */
  get showLanguageBanner(): boolean {
    return this.languageService.getAppLang() !== 'nl';
  }

  /**
   * Generates config object for the current context
   * @returns INarisContextConfig
   */
  private getContextConfig(): INarisHelpContext {
    let route = this.tabService.useTabServiceForHelp ? this.tabService.activeTab?.href : this.router.url|| this.router.url;

    if (route.includes('-wizard') && !route.includes('endpoint=')) {
      route = this.tabService.activeTab ? this.tabService.activeTab.href : route;
    }

    const isDrawer = this.formService.drawers.length ? true : false;
    let endpoint = isDrawer
      ? this.prepareEndpoint(this.formService.drawers[this.formService.drawers.length - 1].endpoint)
      : this.prepareEndpoint(route);

    if (!endpoint.includes('-wizard') && endpoint.includes('(case-id)')) {
      endpoint = this.tabService.activeTab ? this.prepareEndpoint(this.tabService.activeTab.href) : endpoint;
      route = this.tabService.activeTab ? this.tabService.activeTab.href : route;
    }

    const endpointParts = endpoint.slice(1).split('/');
    const routeParts = route.slice(1).split('/');
    const moduleName = routeParts[0];
    let objectName = routeParts[1] ? routeParts[1] : null;
    objectName += routeParts[2] ? '/' + routeParts[2] : '';
    objectName += routeParts[3] ? '/' + routeParts[3] : '';
    const isCaseView = routeParts.length > 2;

    if (this.router.url.includes('-wizard') && this.router.url.includes('?endpoint=')) {
      const wizardEndpoint = this.router.url.split('=')?.[1]?.split('&')?.[0];
      if (!!wizardEndpoint)
        objectName = decodeURIComponent(wizardEndpoint);
    }

    return {
      isDrawer,
      route,
      routeParts,
      endpoint,
      endpointParts,
      moduleName,
      objectName,
      isCaseView
    };
  }

  /**
   * Returns prepared endpoint where IDs are replaced with (case-id)
   * @param endpoint endpoint href
   * @returns string
   */
  private prepareEndpoint(endpoint: string): string {
    const items = endpoint
      .slice(1)
      .split('/')
      .map((i: string) => /^\d+$/.test(i) ? '(case-id)' : i);
    return '/' + items.join('/');
  }

  /**
   * Returns a WordPress API endpoint with filters based on context
   * @param fallback boolean to enable the fallback option
   * @returns WordPress API endpoint string
   */
  private buildWordPressQuery(fallback = false): string {
    // Prepare endpoint
    const baseUrl = WORDPRESS_API_BASE_URL
      + '/posts'
      + '?order=asc';

    const language = this.languageService.getAppLang().toUpperCase();
    if (fallback) {
      // If we want the fallback endpoint, return query for the module name only

      const newUrl = baseUrl
      + '&filter[meta_query][0][key]=module_name'
      + '&filter[meta_query][0][value]=' + this.context.moduleName
      + '&filter[meta_query][1][key]=object_name'
      + '&filter[meta_query][1][value]='
      + '&filter[meta_query][2][key]=language'
      + '&filter[meta_query][2][value]=' + language;

      return newUrl;

    } else if (this.context.isDrawer) {
      // If a drawer is active, try fetching by endpoint
      const newUrl = baseUrl
      + '&filter[meta_compare]=LIKE'
      + '&filter[meta_key]=endpoint'
      + '&filter[meta_value]=' + this.context.endpoint
      + '&filter[meta_query][2][key]=language'
      + '&filter[meta_query][2][value]=' + language;
      return newUrl;

    } else if (this.goHome) {
      this.goHome = false;
      // If a drawer is active, try fetching by endpoint
      const newUrl = baseUrl
      + '&filter[meta_compare]=LIKE'
      + '&filter[meta_key]=endpoint'
      + '&filter[meta_value]=$home';
      return newUrl;

    } else {
      // If no fallback and no drawer, try fetching by module (and object) name
      let query = baseUrl
        + '&filter[meta_query][0][key]=module_name'
        + '&filter[meta_query][0][value]=' + this.context.moduleName;
      // Only add object name if it's present
      if (this.context.objectName) {
        const newObjectName = this.context.objectName.replace(/\d+/g, '(case-id)');
        query = query
          + '&filter[meta_query][1][key]=object_name'
          + '&filter[meta_query][1][value]=' + newObjectName;
      }

      // Add language parameter
      query = `${query}&filter[meta_query][2][key]=language&filter[meta_query][2][value]=${language}`;
      return query;

    }
  }

  /**
   * Find results in WordPress API based on an endpoint
   */
  private findRelatedArticle(): void {
    // Run the query
    this.http.get(this.buildWordPressQuery())
      .pipe(
        // Try to fall back to the current module if there are no results and there is no object name in current context
        switchMap((res: any) => !res.length && !this.context.isDrawer && this.context.objectName ?
          this.http.get(this.buildWordPressQuery(true)) : of(res)
        )
      )
      .subscribe((res: any) => {
        const href = res.length ? res[0].link : WORDPRES_BASE_URL;
        this.coreService.setHelp(href);
      });
  }

  public home() {
    this.goHome = true;
    this.findRelatedArticle();
  }

  public ngOnDestroy(): void {
    this.observer?.disconnect();
  }
}
