<div class="root">
  <div>
    <div class="wrapper">
      <div class="content" [class.disabled]="disabled">
        @if (!!category && (!isHost && mode === 'voting' || isHost && mode === 'overview') && !!currentVote) {
          <div
            class="handle"
            cdkDrag
            [cdkDragDisabled]="false"
            cdkDragBoundary=".wrapper"
            [cdkDragFreeDragPosition]="currentVote | point"
            (cdkDragEnded)="onDragEnded($event)"
            matTooltipPosition="right"
            [matTooltip]="(!blindVoting ? currentVote.user?.name + '&#013;&#010;' : '') + 'Likelihood: ' + currentVote.likelihood + '%' + (!!currentVote.impactLabel ? '&#013;&#010;Impact: ' + currentVote.impactLabel : '')"
            matTooltipClass="desc-icon-tooltip"
          ></div>
        }
        @if (!!expertVotes && expertVotes[category?.key ?? ''] && ((!blindVoting && mode === 'voting') || mode === 'overview' || isHost)) {
          @for (eVote of expertVotes[category?.key ?? '']; track trackByIndex){
            @if (mode === 'voting' && !eVote.user?.host && eVote.user!.id.toString() !== selfId.toString() || mode === 'overview' && !(eVote.user?.host && collabService.isHost)) {
              <div
                class="vote"
                [ngClass]="{host: eVote.user?.host}"
                cdkDrag
                cdkDragBoundary=".wrapper"
                [cdkDragFreeDragPosition]="{likelihood: eVote.likelihood, impact: eVote.impact } | point"
                cdkDragDisabled
                matTooltipPosition="right"
                [matTooltip]="(!blindVoting ? eVote.user?.name + '&#013;&#010;' : '') + 'Likelihood: ' + eVote.likelihood + '%' + (!!eVote.impactLabel ? '&#013;&#010;Impact: ' + eVote.impactLabel : '')"
                matTooltipClass="desc-icon-tooltip"
              ></div>
            }
          }
        }
        <!-- @for (catClass of category?.classes; let i = $index; track i) { -->
          <!-- <div
            tabindex="0"
            class="cat-class"
            [ngStyle]="{bottom: getCatPosition(i, 320, 10) + 'px'}"
            (click)="setImpact(i)"
          >
          </div> -->
          @if (!!likelihood && !disabled) {
            <div class="likelihood-value" [style.left.%]="likelihood"></div>
          }
          <div
            tabindex="0"
            class="cat-class"
            [ngStyle]="{bottom: '0px', height: '100%'}"
            (click)="setVoteClick($event)"
          >
          </div>
        <!-- } -->
      </div>
      <span class="axel">0</span>
      <span class="axel right">100</span>
    </div>
    @if (!!category && ((isHost && mode === 'overview') || (!isHost && mode === 'voting'))) {
      <div class="info">
        <div class="likelihood" style="display: none;">
          <h4>
            {{'heatmap.likelihood' | translate}} %
            @if (mode === 'overview') {
              <span>({{'collab.final' | translate}})</span>
            }
            <naris-icon
              class="icon"
              [icon]="dragDisabled ? 'lock' : 'unlock'"
              [matTooltip]="dragDisabled ? 'Unlock' : 'Lock'"
              (click)="dragDisabled ? dragDisabled = false : dragDisabled = true"
              [ngClass]="{locked: dragDisabled}"
            />
          </h4>
        </div>
        <div class="impact" style="display: none;">
          <h4>
            {{category.label}} - Impact
            @if (mode === 'overview') {
              <span>({{'collab.final' | translate}})</span>
            }
          </h4>
        </div>
        <div class="buttons">
          <naris-button size="small" (click)="resetVote()" class="mr-8" id="HeatmapReset">{{'collab.reset' | translate}}</naris-button>
          @if (isHost && mode === 'overview') {
            <naris-button size="small" [color]="voteColor" (click)="nextVote(category)" id="HeatmapCastVote" [disabled]="!currentVote">{{'collab.cast_final_vote' | translate}}</naris-button>
          } @else {
            <naris-button size="small" [color]="voteColor" (click)="nextVote(category)" id="HeatmapCastVote" [disabled]="!currentVote">{{'collab.cast_vote' | translate}}</naris-button>
          }
        </div>
      </div>
    }
  </div>
  @if (isHost && mode === 'voting') {
    <div class="status">
      <h4>{{'collab.vote_status' | translate}}</h4>
      <ul>
        @for (expert of (experts | filter:'host':false); track $index) {
          <li [ngClass]="{done: !!expertVotes && !!expertVotes[category?.key ?? '']}">
            <naris-icon
              class="icon"
              [icon]="expertHasVoted(expert) ? 'compliance' : 'check_box_outline_blank'"
              [iconClass]="expertHasVoted(expert) ? 'green' : 'default lightest'"
            />
            {{expert.name}}
          </li>
        }
      </ul>
    </div>
  }
</div>