import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, effect, viewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { PROCESS_GRID_BACKGROUND_OPTIONS } from '@core/constants/jsplumb-base-constants';
import { ARCHIMATE_PALETTE_ELEMENTS, ARCHIMATE_RELATIONSHIP_OPTIONS } from '@core/constants/jsplumb-constants';
import { IArchimatePalette } from '@core/models/process-manager.models';
import { AbsoluteLayout, AnchorLocations, BackgroundPlugin, BlankEndpoint, DEFAULT, EVENT_CANVAS_CLICK, EVENT_CLICK, EVENT_EDGE_ADDED, EVENT_NODE_ADDED, EVENT_TAP, EVENT_NODE_MOVE_END, Edge, EdgeAddedParams, SurfaceNodeAddedParams, JsPlumbToolkit, JsPlumbToolkitOptions, StraightConnector, Surface, PointXY, Node, VertexMovedParams, EVENT_PAN } from '@jsplumbtoolkit/browser-ui';
import { AngularRenderOptions, SurfaceComponent, jsPlumbToolkitModule } from '@jsplumbtoolkit/browser-ui-angular';
import { ArchimateService } from '@core/services/archimate.service';
import { Subscription } from 'rxjs';
import { BeinformedService, DialogService, FormService, HttpService, SnackbarService } from '@core/services';
import { ICaseListAction, ICaseListRow, IFormResult } from '@core/models';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgStyle } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../elements/icon/icon.component';
import { ArchimateNodeComponent } from './archimate-node/archimate-node.component';
import edgeMappings from './edge-mappings';
import { ArchimateJunctionComponent } from './archimate-junction/archimate-junction.component';
import { ArchimateInspectorComponent } from './archimate-inspector/archimate-inspector.component';

@Component({
  selector: 'app-process-archimate',
  templateUrl: './process-archimate.component.html',
  styleUrl: './process-archimate.component.scss',
  standalone: true,
  imports: [jsPlumbToolkitModule, ArchimateInspectorComponent, MatProgressSpinner, NgStyle, IconComponent, MatTooltip, TranslateModule]
})
export class ProcessArchimateComponent implements OnInit, AfterViewInit, OnDestroy {

  public surfaceComponent = viewChild(SurfaceComponent);
  public inspector = viewChild<ElementRef<HTMLDivElement>>('archimateInspector');

  @Input() public href: string;

  public surface: Surface;
  public toolkit: JsPlumbToolkit;
  public palette: IArchimatePalette[] = ARCHIMATE_PALETTE_ELEMENTS;
  public relations = ARCHIMATE_RELATIONSHIP_OPTIONS;
  public loading = true;
  public data: ICaseListRow[] | undefined;
  public actions: ICaseListAction[];

  private readonly subs: Subscription[] = [];

  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly archimateService: ArchimateService,
    private readonly snackbar: SnackbarService,
    private readonly beinformedService: BeinformedService,
    private readonly formService: FormService,
    private readonly dialogService: DialogService,
    private readonly httpService: HttpService
  ) {
    const safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('~/../assets/archimate-icons.svg');
    this.matIconRegistry.addSvgIconSet(safeUrl);
    effect(() => {
      const cmp = this.surfaceComponent();
      if (!!cmp) {
        this.surface = cmp.surface;
        this.toolkit = cmp.toolkit;
        this.bindToolkitEvents();
      }
    });
  }

  public ngOnInit(): void {
    this.subs.push(
      this.archimateService.edgeTypeChanged$.subscribe(data => this.updateEdgeType(data.id, data.type, data.label)),
      this.archimateService.junctionTypeChanged$.subscribe(data => this.updateJunctionType(data.id, data.type)),
      this.archimateService.closeInspector$.subscribe(() => this.closeInspector()),
      this.archimateService.remove$.subscribe(node => this.remove(node)),
      this.archimateService.updateObjectName$.subscribe(value => this.updateObjectName(value))
    );
  }

  public ngAfterViewInit(): void {
    this.initialize();
  }

  public ngOnDestroy(): void {
    this.toolkit.clear(); 
  }

  private initialize() {
    this.loading = true;
    this.subs.push(
      this.beinformedService.fetchResponseWithContributions<'caselist'>(this.href).subscribe({
        next: res => {
          this.data = this.beinformedService.extractResults(res) as ICaseListRow[];
          this.actions = res.data.actions;
          if (!!this.data && this.data.length > 0) void this.draw(this.data);
          else this.loading = false;
        }
      })
    );
  }

  private async draw(data: ICaseListRow[]) {
    const nodes = data.filter(x => x.ArchiMateType === 'Object');
    const edges = data.filter(x => x.ArchiMateType === 'Relation');
    await this.drawNodes(nodes);
    this.drawEdges(edges);
    this.loading = false;
  }

  private drawNodes(nodes: ICaseListRow[]) {
    return new Promise<void>(resolve => {
      nodes.forEach(node => {
        const pos = JSON.parse(node.Position) as PointXY;
        const splittedType = (node.Type as string).split('_');
        this.toolkit.addNode({
          objectId: node._id,
          name: node.Name,
          type: node.Type,
          icon: this.getIcon(splittedType[1]),
          top: pos.y,
          left: pos.x,
          actions: node.actions,
          layer: splittedType[0],
          element: splittedType[1],
          connectionType: node.connectionType,
          lastChangeEvent: 'drawAdd'
        });
      });
      resolve();
    });
  }

  private drawEdges(edges: ICaseListRow[]) {
    edges.forEach(edge => {
      const source = this.toolkit.getNodes().find(x => x.data.objectId === edge.SourceID);
      const target = this.toolkit.getNodes().find(x => x.data.objectId === edge.TargetID);
      if (!!source && !!target) {
        this.toolkit.addEdge({
          source: source,
          target: target,
          data: {
            objectId: edge._id,
            lineStyle: target.type === 'Archimate_Junction' ? edge.Type + '_Junction' : edge.Type,
            actions: edge.actions,
            label: target.type === 'Archimate_Junction' ? '' : edge.Name,
            name: target.type === 'Archimate_Junction' ? '' : edge.Name,
            lastChangeEvent: 'added'
          }
        });
      }
    });
  }

  public dataGenerator = (el: Element) => {
    const type = el.getAttribute('data-type');
    const icon = el.getAttribute('data-icon');
    const splittedType = type!.split('_');
    const layer = splittedType[0];
    const element = splittedType[1];
    const label = el.innerHTML;
    const base = { type };

    // Default node styling
    Object.assign(base, {
      label,
      fill: '#fff',
      outline: '#000',
      textColor: '#000',
      outlineWidth: 3,
      icon,
      layer,
      element,
      lastChangeEvent: 'paletteAdd'
    });

    return base;
  };

  private bindToolkitEvents() {
    // this.toolkit.bind(EVENT_EDGE_UPDATED, (e: EdgeUpdatedParams) => {
    //   if (e.edge.target.type === 'Archimate_Junction' && e.edge.data.lastChangeEvent !== 'junction_updated') this.handleJunction(e);
    // });
  }

  private async updateEdgeType(id: string, type: string, label: string) {
    const edge = this.toolkit.getEdge(id);
    const action = edge.data.actions.find((x: ICaseListAction) => x.name === 'update-archimate-relation');
    if (!!action) {
      let lineStyle = type;
      let save = true;
      if (edge.target.type === 'Archimate_Junction') {
        save = this.handleJunction(edge);
        const junction = this.toolkit.getNode(edge.target.id);
        if (!!junction.data.connectionType) lineStyle = junction.data.connectionType + '_Junction';
        else lineStyle = type + '_Junction';
      }
      if (save) {
        await this.save(action.href, ['SourceID', 'TargetID', 'Name', 'Type'], [edge.source.data.objectId, edge.target.data.objectId, label, lineStyle]);
        this.toolkit.updateEdge(edge, {
          lineStyle: lineStyle,
          label: edge.target.type !== 'Archimate_Junction' ? label : '',
          lastChangeEvent: 'updated'
        });
      }
    }
  }

  private updateJunctionType(id: string, type: string) {
    const action = this.actions.find((x: ICaseListAction) => x.name === 'update-archimate-relations-type');
    if (!!action) {
      const junction = this.toolkit.getNode(id);
      const junctionEdges = this.toolkit.getEdges({element: junction});
      this.toolkit.updateNode(junction, { connectionType: type });
      junctionEdges.forEach(edge => {
        let lineStyle = type;
        let label = this.getEdgeLabel(type);
        if (edge.target.type === 'Archimate_Junction') {
          lineStyle = type + '_Junction';
          label = '';
        }
        this.toolkit.updateEdge(edge, { lineStyle: lineStyle, label: label, lastChangeEvent: 'junction_updated' });
      });
      const body = {
        ArchiMateIDs: junctionEdges.map(edge => edge.data.objectId).join(','),
        Type: type,
        Name: this.getEdgeLabel(type)
      };
      this.httpService.post(action.href, body).subscribe({
        next: async res => {
          if (res.formresponse.success) {
            const junctionAction = junction.data.actions.find((x: ICaseListAction) => x.name === 'update-archimate-object');
            await this.save(junctionAction.href, ['Position', 'Name', 'Type', 'connectionType'], [JSON.stringify({x: junction.data.left, y: junction.data.top}), junction.data.name, junction.data.type, type]);
            this.closeInspector();
          } 
        }
      });
    }
  }

  private handleJunction(e: Edge): boolean {
    const junction = this.toolkit.getNode(e.target.id);
    const sourceEdges = this.toolkit.getEdges({target: junction.id});
    const isSameSource = sourceEdges.length === 1 && sourceEdges[0].source.id === e.source.id;
    const lineStyle = (e.data.lineStyle as string).includes('_Junction') ? (e.data.lineStyle as string).split('_')[0] : e.data.lineStyle;
    if (!junction.data.connectionType || isSameSource) {
      this.toolkit.updateNode(junction, { connectionType: lineStyle });
      const junctionSourceEdges = this.toolkit.getEdges({source: junction.id});
      junctionSourceEdges.forEach(edge => {
        const label = this.getEdgeLabel(lineStyle);
        this.toolkit.updateEdge(edge, { lineStyle: lineStyle, label: label, lastChangeEvent: 'junction_updated' });
      });
      return true;
    } else if (lineStyle !== junction.data.connectionType) {
      this.snackbar.open({text: 'archimate.junction_error', type: 'error', dismissLabel: 'ok'});
      return false;
    } else return false;
  }

  private getEdgeLabel(lineStyle: string): string {
    const relation = this.relations.find(rel => rel.label === lineStyle);
    return relation!.name;
  }

  private getIcon(element: string) {
    switch(element) {
      case 'Resource': return 'archimate-resource';
      case 'Capability': return 'archimate-capability';
      case 'ValueStream': return 'archimate-value_stream';
      case 'CourseOfAction': return 'archimate-course_of_action';
      case 'BusinessActor': return 'archimate-business_actor';
      case 'Role': return 'archimate-business_role';
      case 'Collaboration': return 'archimate-collaboration';
      case 'Interface': return 'archimate-interface';
      case 'Process': return 'archimate-process';
      case 'Function': return 'archimate-function';
      case 'Interaction': return 'archimate-interaction';
      case 'Event': return 'archimate-event';
      case 'Service': return 'archimate-service';
      case 'Object': return 'archimate-object';
      case 'Contract': return 'archimate-contract';
      case 'Representation': return 'archimate-representation';
      case 'Product': return 'archimate-product';
      case 'Component': return 'archimate-component';
      case 'Node': return 'archimate-node';
      case 'Device': return 'archimate-device';
      case 'SystemSoftware': return 'archimate-system_software';
      case 'Path': return 'archimate-path';
      case 'CommunicationNetwork': return 'archimate-communication_network';
      case 'Equipment': return 'archimate-equipment';
      case 'Facility': return 'archimate-facility';
      case 'DistributionNetwork': return 'archimate-distribution_network';
      case 'Material': return 'archimate-material';
      case 'Driver': return 'archimate-driver';
      case 'Assessment': return 'archimate-assessment';
      case 'Goal': return 'archimate-goal';
      case 'Outcome': return 'archimate-outcome';
      case 'Principle': return 'archimate-principle';
      case 'Requirement': return 'archimate-requirement';
      case 'Constraint': return 'archimate-constraint';
      case 'Meaning': return 'archimate-meaning';
      case 'Value': return 'archimate-value';
      case 'WorkPackage': return 'archimate-work_package';
      case 'Plateau': return 'archimate-plateau';
      case 'Gap': return 'archimate-gap';
      case 'Artifact': return 'archimate-artifact';
    }
  }

  /**
   * Function which makes saving without going through a form easier
   * Make sure the order of the values in mapKeys and mapValues correspond to each other
   * 
   * @param href 
   * @param mapKeys 
   * @param mapValues 
   * @returns 
   */
  private save(href: string, mapKeys: string[], mapValues: any[], saveNewActions = false) {
    return new Promise<IFormResult>((resolve, reject) => {
      this.beinformedService.fetchForm(href, true).subscribe({
        next: res => {
          const tokens = res.data.error?.formresponse.tokens;
          const objects = {} as Record<string, any>;
          const objectid = res.data.error.formresponse.missing?.anchors[0].objectid || '';
          const keys = res.data.error?.formresponse.missing?.anchors[0].elements.map(x => x.elementid);
          const values: Record<string, any> = {};
          keys?.forEach(key => {
            let value = '';
            const index = mapKeys.indexOf(key);
            if (index !== undefined) value = mapValues[index];
            return values[key] = value;
          });
          objects[objectid] = values;
          const postableObject = { tokens: tokens, objects: objects };
          this.beinformedService.fetchForm(href, true, postableObject).subscribe({
            next: result => {
              if (saveNewActions) this.archimateService.newActions = this.getResultData(result).actions;
              resolve(result);
            },
            error: (err: Error) => {
              reject(err);
            }
          });
        }
      });
    });
  }

  private getResultData(res: any): { actions: ICaseListAction[]; id: number } {
    const actions: ICaseListAction[] = [];
    let id = '';
    const keys = !!res.data.formresponse ? Object.keys(res.data.formresponse?.success?.data['ResultArchiMate']) : Object.keys(res.data['ResultArchiMate']);
    keys.forEach(key => {
      const href = !!res.data.formresponse ? res.data.formresponse?.success?.data['ResultArchiMate'][key] as string : res.data['ResultArchiMate'][key] as string;
      const splittedHref = href.split('/');
      const name = splittedHref[splittedHref.length - 1];
      id = splittedHref[splittedHref.length - 2];
      actions.push({href, name, method: 'POST'});
    });
    return { actions, id: parseInt(id) };
  }

  private closeInspector() {
    const inspector = this.inspector();
    if (!!inspector) {
      inspector.nativeElement.style.display = 'none';
      this.toolkit.clearSelection();
    }
  }

  private remove(obj: any) {
    const action = obj.data.actions.find((x: ICaseListAction) => x.name.includes('delete'));
    if (!!action) {
      this.dialogService.open({
        type: 'alert',
        title: action.label || '',
        text: 'dialog.sure_continue',
        confirmLabel: action.label,
        confirmColor: 'primary',
        cancelLabel: 'cancel'
      }).subscribe((confirmed: boolean) => {
        if (confirmed) this.httpService.post(action.href).subscribe({
          next: res => {
            if (!!res.formresponse.success) {
              this.closeInspector();
              if (obj.objectType === 'Node') this.toolkit.removeNode(obj.id);
              if (obj.objectType === 'Edge') this.toolkit.removeEdge(obj.id);
            }
          }
        });
      });
    }
  }

  private updateObjectName(value: any) {
    const selected = this.getSelectedItem();
    if (!!selected) {
      this.toolkit.updateNode(selected, {
        name: value
      });
    }
    this.closeInspector();
  }

  private getSelectedItem(): Node | undefined {
    const selection = this.toolkit.getSelection();
    return selection._nodes.length > 0 ? selection._nodes[0] : undefined;
  }

  // JsPlumb Archimate Config
  public renderOpts: AngularRenderOptions = {
    grid: {
      size: {w: 12.5, h: 12.5}
    },
    layout: {
      type: AbsoluteLayout.type
    },
    defaults: {
      endpoint: BlankEndpoint.type,
      anchor: AnchorLocations.Continuous,
      connector: {type: StraightConnector.type, options: {}}
    },
    propertyMappings: {
      edgeMappings: edgeMappings()
    },
    plugins: [
      {
        type: BackgroundPlugin.type,
        options: PROCESS_GRID_BACKGROUND_OPTIONS
      }
    ],
    events: {
      [EVENT_CANVAS_CLICK]: () => {
        this.resetInspectorAndToolkit();
      },
      [EVENT_PAN]: () => {
        this.resetInspectorAndToolkit();
      },
      [EVENT_EDGE_ADDED]: async (e: EdgeAddedParams) => {
        if (e.addedByMouse) {
          const action = this.actions.find(x => x.name === 'add-archimate-relation');
          if (e.edge.source.id === e.edge.target.id || !action) {
            this.toolkit.removeEdge(e.edge);
            return;
          } 
          let lineStyle = 'Association';
          let label = '';

          // If the target is a Junction and already has a connectionType we find the corresponding label
          if (e.edge.target.type === 'Archimate_Junction') {
            if (!!e.edge.target.data.connectionType) {
              lineStyle = e.edge.target.data.connectionType;
            }
            label = this.getEdgeLabel(lineStyle);
            lineStyle = lineStyle + '_Junction';
          }

          // If the source is a Junction and already has a connectionType we find the corresponding label
          if (e.edge.source.type === 'Archimate_Junction') {
            if (!!e.edge.source.data.connectionType) {
              lineStyle = e.edge.source.data.connectionType;
            }
            label = this.getEdgeLabel(lineStyle);
          }

          const saveStyle = lineStyle.includes('_Junction') ? lineStyle.split('_')[0] : lineStyle;
          await this.save(action.href, ['SourceID', 'TargetID', 'Name', 'Type'], [e.edge.source.data.objectId, e.edge.target.data.objectId, label, saveStyle], true).then(
            async (res: IFormResult) => {
              if (res.data.formresponse?.success) {
                const resultData = this.getResultData(res);
                this.toolkit.updateEdge(e.edge, {
                  objectId: resultData.id,
                  actions: resultData.actions
                });
              }
              if (e.edge.target.type === 'Archimate_Junction' && !e.edge.target.data.connectionType) {
                const junctionAction = e.edge.target.data.actions.find((x: ICaseListAction) => x.name === 'update-archimate-object');
                if (!!junctionAction) {
                  await this.save(junctionAction.href, ['Position', 'Name', 'Type', 'connectionType'], [JSON.stringify({x: e.edge.target.data.left, y: e.edge.target.data.top}), e.edge.target.data.name, e.edge.target.data.type, saveStyle]);
                }
              }
            }
          );

          this.toolkit.updateEdge(e.edge, {
            lineStyle: lineStyle,
            label: e.edge.target.type !== 'Archimate_Junction' ? label : '',
            lastChangeEvent: 'added',
            actions: this.archimateService.newActions.length > 0 ? this.archimateService.newActions : []
          });

          let junction = undefined;
          if (e.edge.target.type === 'Archimate_Junction') junction = e.edge.target;
          else if (e.edge.source.type === 'Archimate_Junction') junction = e.edge.source;
          if (!!junction) {
            this.toolkit.updateNode(junction, {
              connectionType: saveStyle
            });
          }
        }
      },
      [EVENT_NODE_ADDED]: (e: SurfaceNodeAddedParams) => {
        if (e.vertex.data.lastChangeEvent === 'drawAdd') return;
        this.toolkit.setSelection(e.vertex);
        const action = this.actions.find(x => x.name === 'add-archimate-object');
        if (!!action) {
          this.archimateService.position = JSON.stringify(e.pos);
          this.archimateService.type = e.vertex.type;
          this.formService.open(action.href, 'form', false, true).subscribe({
            next: saved => {
              if (!saved) this.toolkit.removeNode(e.vertex);
              else {
                const data = this.getResultData(saved);
                this.toolkit.updateNode(e.vertex, {
                  objectId: data.id,
                  actions: data.actions,
                  element: e.vertex.type.split('_')[1]
                });
              }
            }
          });
        } else this.toolkit.removeNode(e.vertex);
      },
      [EVENT_NODE_MOVE_END]: (e: VertexMovedParams<any>) => {
        const action = e.vertex.data.actions.find((x: ICaseListAction) => x.name === 'update-archimate-object');
        const connecionType = e.vertex.type === 'Archimate_Junction' ? e.vertex.data.connectionType : null;
        if (!!action) void this.save(action.href, ['Position', 'Name', 'Type', 'connectionType'], [JSON.stringify(e.pos), e.vertex.data.name, e.vertex.data.type, connecionType]);
      }
    }
  };

  private resetInspectorAndToolkit() {
    const inspector = this.inspector();
    if (!!inspector) {
      inspector.nativeElement.style.display = 'none';
    }
    this.toolkit.clearSelection();
  }

  public viewOpts = {
    nodes: {
      [DEFAULT]: {
        events: {
          [EVENT_TAP]: (p: any) => {
            const inspector = this.inspector();
            if (!!inspector) {
              const left = p.obj.data.left;
              const top = p.obj.data.top;
              const position = this.surface.toPageLocation(left, top);
              inspector.nativeElement.style.display = 'block';
              inspector.nativeElement.style.top = `${position.y}px`;
              inspector.nativeElement.style.left = `${position.x + 190 * p.renderer.panZoom.zoom}px`;
            }
            this.toolkit.setSelection(p.obj);
          }
        },
        component: ArchimateNodeComponent
      },
      ['Archimate_Junction']: {
        events: {
          [EVENT_TAP]: (p: any) => {
            const inspector = this.inspector();
            if (!!inspector) {
              const left = p.obj.data.left;
              const top = p.obj.data.top;
              const position = this.surface.toPageLocation(left, top);
              inspector.nativeElement.style.display = 'block';
              inspector.nativeElement.style.top = `${position.y}px`;
              inspector.nativeElement.style.left = `${position.x + 50 * p.renderer.panZoom.zoom}px`;
            }
            this.toolkit.setSelection(p.obj);
          }
        },
        component: ArchimateJunctionComponent,
        anchor: {
          type: 'Perimeter',
          options: {
            shape: 'Circle'
          }
        }
      }
    },
    edges: {
      [DEFAULT]: {
        events: {
          [EVENT_CLICK]: (params: any) => {
            const inspector = this.inspector();
            if (!!inspector) {
              inspector.nativeElement.style.display = 'block';
              const el = params.connection.connector.canvas as HTMLElement;

              const left = +el.style.left.split('px')[0] + (params.connection.connector.w / 2) + 20 * params.renderer.panZoom.zoom;
              const top = +el.style.top.split('px')[0] + params.connection.connector.h / 2;
              
              const position = this.surface.toPageLocation(left, top);
              inspector.nativeElement.style.top = `${position.y}px`;
              inspector.nativeElement.style.left = `${position.x}px`;
            }
            this.toolkit.setSelection(params.edge);
          }
        },
        useHTMLLabel: true
      }
    }
  };

  public toolkitOpts: JsPlumbToolkitOptions = {};

}
