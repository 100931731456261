import { inherits as t, createSuper as e, classCallCheck as n, defineProperty as s, assertThisInitialized as i, createClass as r } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { EventGenerator as l } from "../util/event-generator.js";
import { forEach as a, insertSorted as u, findWithFunction as o } from "../util/util.js";
import { getsert as m } from "../../core/util.js";
var h = function t() {
  n(this, t);
  s(this, "affectedElements", new Set());
};
function c() {
  return {
    x: 0,
    y: 0,
    w: 0,
    h: 0,
    r: 0,
    c: {
      x: 0,
      y: 0
    },
    x2: 0,
    y2: 0,
    t: {
      x: 0,
      y: 0,
      c: {
        x: 0,
        y: 0
      },
      w: 0,
      h: 0,
      r: 0,
      x2: 0,
      y2: 0,
      cr: 0,
      sr: 0
    },
    dirty: true
  };
}
function d(t, e, n, s, i) {
  var r = {
      x: t + n / 2,
      y: e + s / 2
    },
    l = Math.cos(i / 360 * Math.PI * 2),
    a = Math.sin(i / 360 * Math.PI * 2),
    u = function t(e, n) {
      return {
        x: r.x + Math.round((e - r.x) * l - (n - r.y) * a),
        y: r.y + Math.round((n - r.y) * l - (e - r.x) * a)
      };
    };
  var o = u(t, e),
    m = u(t + n, e),
    h = u(t + n, e + s),
    c = u(t, e + s),
    d = u(t + n / 2, e + s / 2);
  var y = Math.min(o.x, m.x, h.x, c.x),
    x = Math.max(o.x, m.x, h.x, c.x),
    f = Math.min(o.y, m.y, h.y, c.y),
    _ = Math.max(o.y, m.y, h.y, c.y);
  return {
    x: y,
    y: f,
    w: x - y,
    h: _ - f,
    c: d,
    r: i,
    x2: x,
    y2: _,
    cr: l,
    sr: a
  };
}
var y = function t(e, n) {
  var s = 0;
  if (n[1] > e[1]) {
    s = -1;
  } else if (n[1] < e[1]) {
    s = 1;
  }
  return s;
};
var x = function t(e, n) {
  return y(e, n) * -1;
};
function f(t, e, n, s) {
  u([t, e], n, y, s);
}
function _(t, e) {
  var n = o(e, function (e) {
    return e[0] === t;
  });
  if (n > -1) {
    e.splice(n, 1);
  }
}
var E = function (l) {
  t(o, l);
  var u = e(o);
  function o(t) {
    var e;
    n(this, o);
    e = u.call(this);
    e.instance = t;
    s(i(e), "_currentTransaction", null);
    s(i(e), "_sortedElements", {
      xmin: [],
      xmax: [],
      ymin: [],
      ymax: []
    });
    s(i(e), "_elementMap", new Map());
    s(i(e), "_transformedElementMap", new Map());
    s(i(e), "_bounds", {
      minx: 0,
      maxx: 0,
      miny: 0,
      maxy: 0
    });
    return e;
  }
  r(o, [{
    key: "_updateBounds",
    value: function t(e, n, s) {
      if (n != null) {
        _(e, this._sortedElements.xmin);
        _(e, this._sortedElements.xmax);
        _(e, this._sortedElements.ymin);
        _(e, this._sortedElements.ymax);
        f(e, n.t.x, this._sortedElements.xmin, false);
        f(e, n.t.x + n.t.w, this._sortedElements.xmax, true);
        f(e, n.t.y, this._sortedElements.ymin, false);
        f(e, n.t.y + n.t.h, this._sortedElements.ymax, true);
        if (s !== true) {
          this._recalculateBounds();
        }
      }
    }
  }, {
    key: "_recalculateBounds",
    value: function t() {
      this._bounds.minx = this._sortedElements.xmin.length > 0 ? this._sortedElements.xmin[0][1] : 0;
      this._bounds.maxx = this._sortedElements.xmax.length > 0 ? this._sortedElements.xmax[0][1] : 0;
      this._bounds.miny = this._sortedElements.ymin.length > 0 ? this._sortedElements.ymin[0][1] : 0;
      this._bounds.maxy = this._sortedElements.ymax.length > 0 ? this._sortedElements.ymax[0][1] : 0;
    }
  }, {
    key: "recomputeBounds",
    value: function t() {
      var e = this;
      this._sortedElements.xmin.length = 0;
      this._sortedElements.xmax.length = 0;
      this._sortedElements.ymin.length = 0;
      this._sortedElements.ymax.length = 0;
      this._elementMap.forEach(function (t, n) {
        e._sortedElements.xmin.push([n, t.t.x]);
        e._sortedElements.xmax.push([n, t.t.x + t.t.w]);
        e._sortedElements.ymin.push([n, t.t.y]);
        e._sortedElements.ymax.push([n, t.t.y + t.t.h]);
      });
      this._sortedElements.xmin.sort(y);
      this._sortedElements.ymin.sort(y);
      this._sortedElements.xmax.sort(x);
      this._sortedElements.ymax.sort(x);
      this._recalculateBounds();
    }
  }, {
    key: "_finaliseUpdate",
    value: function t(e, n, s) {
      n.t = d(n.x, n.y, n.w, n.h, n.r);
      this._transformedElementMap.set(e, n.t);
      if (s !== true) {
        this._updateBounds(e, n, s);
      }
    }
  }, {
    key: "shouldFireEvent",
    value: function t(e, n, s) {
      return true;
    }
  }, {
    key: "startTransaction",
    value: function t() {
      if (this._currentTransaction != null) {
        throw new Error("Viewport: cannot start transaction; a transaction is currently active.");
      }
      this._currentTransaction = new h();
    }
  }, {
    key: "endTransaction",
    value: function t() {
      var e = this;
      if (this._currentTransaction != null) {
        this._currentTransaction.affectedElements.forEach(function (t) {
          var n = e.getPosition(t);
          e._finaliseUpdate(t, n, true);
        });
        this.recomputeBounds();
        this._currentTransaction = null;
      }
    }
  }, {
    key: "updateElements",
    value: function t(e) {
      var n = this;
      a(e, function (t) {
        return n.updateElement(t.id, t.x, t.y, t.width, t.height, t.rotation);
      });
    }
  }, {
    key: "updateElement",
    value: function t(e, n, s, i, r, l, a) {
      var u = m(this._elementMap, e, c);
      u.dirty = n == null && u.x == null || s == null && u.y == null || i == null && u.w == null || r == null && u.h == null;
      if (n != null) {
        u.x = n;
      }
      if (s != null) {
        u.y = s;
      }
      if (i != null) {
        u.w = i;
      }
      if (r != null) {
        u.h = r;
      }
      if (l != null) {
        u.r = l || 0;
      }
      u.c.x = u.x + u.w / 2;
      u.c.y = u.y + u.h / 2;
      u.x2 = u.x + u.w;
      u.y2 = u.y + u.h;
      if (this._currentTransaction == null) {
        this._finaliseUpdate(e, u, a);
      } else {
        this._currentTransaction.affectedElements.add(e);
      }
      return u;
    }
  }, {
    key: "refreshElement",
    value: function t(e, n) {
      var s = this.instance.getManagedElements();
      var i = s[e] ? s[e].el : null;
      if (i != null) {
        var r = this.getSize(i);
        var l = this.getOffset(i);
        return this.updateElement(e, l.x, l.y, r.w, r.h, null, n);
      } else {
        return null;
      }
    }
  }, {
    key: "getSize",
    value: function t(e) {
      return this.instance.getSize(e);
    }
  }, {
    key: "getOffset",
    value: function t(e) {
      return this.instance.getOffset(e);
    }
  }, {
    key: "registerElement",
    value: function t(e, n) {
      return this.updateElement(e, 0, 0, 0, 0, 0, n);
    }
  }, {
    key: "addElement",
    value: function t(e, n, s, i, r, l) {
      return this.updateElement(e, n, s, i, r, l);
    }
  }, {
    key: "rotateElement",
    value: function t(e, n) {
      var s = m(this._elementMap, e, c);
      s.r = n || 0;
      this._finaliseUpdate(e, s);
      return s;
    }
  }, {
    key: "getBoundsWidth",
    value: function t() {
      return this._bounds.maxx - this._bounds.minx;
    }
  }, {
    key: "getBoundsHeight",
    value: function t() {
      return this._bounds.maxy - this._bounds.miny;
    }
  }, {
    key: "getX",
    value: function t() {
      return this._bounds.minx;
    }
  }, {
    key: "getY",
    value: function t() {
      return this._bounds.miny;
    }
  }, {
    key: "setSize",
    value: function t(e, n, s) {
      if (this._elementMap.has(e)) {
        return this.updateElement(e, null, null, n, s, null);
      }
    }
  }, {
    key: "setPosition",
    value: function t(e, n, s) {
      if (this._elementMap.has(e)) {
        return this.updateElement(e, n, s, null, null, null);
      }
    }
  }, {
    key: "reset",
    value: function t() {
      this._sortedElements.xmin.length = 0;
      this._sortedElements.xmax.length = 0;
      this._sortedElements.ymin.length = 0;
      this._sortedElements.ymax.length = 0;
      this._elementMap.clear();
      this._transformedElementMap.clear();
      this._recalculateBounds();
    }
  }, {
    key: "remove",
    value: function t(e) {
      _(e, this._sortedElements.xmin);
      _(e, this._sortedElements.xmax);
      _(e, this._sortedElements.ymin);
      _(e, this._sortedElements.ymax);
      this._elementMap.delete(e);
      this._transformedElementMap.delete(e);
      this._recalculateBounds();
    }
  }, {
    key: "getPosition",
    value: function t(e) {
      return this._elementMap.get(e);
    }
  }, {
    key: "getElements",
    value: function t() {
      return this._elementMap;
    }
  }, {
    key: "isEmpty",
    value: function t() {
      return this._elementMap.size === 0;
    }
  }]);
  return o;
}(l);
export { E as Viewport };