import { createClass as t, classCallCheck as i, defineProperty as a } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { extend as e, objectsEqual as o } from "../../ui-core/util/util.js";
var s = function () {
  function s(t, o, n) {
    i(this, s);
    this.obj = t;
    this.toolkit = n;
    a(this, "newData", void 0);
    a(this, "originalData", void 0);
    this.newData = e({}, t.data);
    this.originalData = e({}, o);
  }
  t(s, [{
    key: "_getMethod",
    value: function t() {
      return "update" + this.obj.objectType;
    }
  }, {
    key: "undo",
    value: function t() {
      this.toolkit[this._getMethod()](this.obj, this.originalData);
    }
  }, {
    key: "redo",
    value: function t() {
      this.toolkit[this._getMethod()](this.obj, this.newData);
    }
  }, {
    key: "hasDeltas",
    value: function t() {
      return !o(this.newData, this.originalData);
    }
  }]);
  return s;
}();
export { s as UpdateAction };