<div 
  class="naris-form-field"
  [ngClass]="{'login-input': fixStyling}"
  [class.process-toolkit-form-field]="isProcessToolkitForm"
>
  @if (!!label) {
    <div
      class="naris-form-field__header"
    >
      <div class="naris-form-field__label">
        <span>{{ label }}</span>
        @if (tooltip) {
          <div class="tooltip">
            <naris-icon
              [matTooltip]="tooltip"
              matTooltipPosition="right"
              tabindex="0"
              icon="help"
              class="naris-form-field__icon"
            />
          </div>
        }
      </div>
      @if (labelSuffix) {
        <div class="naris-form-field__suffix">
          {{ labelSuffix }}
        </div>
      }
    </div>
  }
  
  <div class="naris-form-field__container">
    <div class="naris-form-field__input">
      <ng-content />
    </div>
    @if (error) {
      @if (isHrefError) {
        <div class="naris-form-field__error-text" >
          <span>{{parsedError.firstPart}} <span class="naris-form-field__error-text__link" tabindex="0" (click)="navigateToErrorHref(parsedError.url)">{{'href.here' | translate}}</span> {{parsedError.lastPart}}</span>
        </div>
      } @else {
        <div class="naris-form-field__error-text">
          {{ errorString }}
        </div>
      }
    }
  </div>
</div>
