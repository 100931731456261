import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naris-inline-select',
  templateUrl: './inline-select.component.html',
  styleUrls: ['./inline-select.component.scss'],
  standalone: true,
  imports: [MatMenuTrigger, MatMenu, TranslateModule]
})
export class InlineSelectComponent implements OnInit, OnDestroy {
  @Input() public options: { key: string; label: string }[];
  @Input() public placeholder = 'select...';
  @Input() public control: FormControl;
  @Input() public error?: string;
  @Input() public value?: number | string;
  @Input() public clearOnDestroy = false;
  @Input() public requiredIfVisible = false;  
  @Input() public id: string;

  public ngOnInit(): void {
    if (this.control && !this.control.value && this.value) this.control.setValue(this.value);
    if (this.control && this.requiredIfVisible) this.control.setValidators([Validators.required]); this.control.updateValueAndValidity();
  }

  public select(option: any) {
    this.control.setValue(option.key);
  }

  get label(): string {
    return this.options?.find((o: any) => o.key === this.control.value)?.label || this.placeholder;
  }

  public ngOnDestroy(): void {
    if (this.control && this.requiredIfVisible) this.control.clearValidators();
    if (this.control && this.clearOnDestroy) this.control.setValue(null);
    this.control.updateValueAndValidity();
  }

  get valid(): boolean {
    return this.control.valid;
  }
}
