import { createClass as e, classCallCheck as r, defineProperty as t } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { uuid as a } from "../util/util.js";
var i = function () {
  function i(e, l) {
    var n = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    r(this, i);
    this.selector = e;
    this.def = l;
    this.exclude = n;
    t(this, "id", void 0);
    t(this, "redrop", void 0);
    this.id = a();
    this.redrop = l.def.redrop || s;
  }
  e(i, [{
    key: "setEnabled",
    value: function e(r) {
      this.def.enabled = r;
    }
  }, {
    key: "isEnabled",
    value: function e() {
      return this.def.enabled !== false;
    }
  }]);
  return i;
}();
var s = "strict";
var l = "any";
var n = "anySource";
var d = "anyTarget";
var o = "anySourceOrTarget";
export { i as ConnectionDragSelector, l as REDROP_POLICY_ANY, n as REDROP_POLICY_ANY_SOURCE, o as REDROP_POLICY_ANY_SOURCE_OR_TARGET, d as REDROP_POLICY_ANY_TARGET, s as REDROP_POLICY_STRICT };