<!-- eslint-disable @angular-eslint/template/prefer-ngsrc -->

<div class="wrapper">
  <div class="wrapper__naris"><img [src]="'../../../assets/easter-egg/naris-logo.png'" alt="naris logo"/></div>
  <div class="wrapper__konami">
    <img [src]="'../../../assets/easter-egg/konami-logo.png'" alt="konami logo"/>
    <span><b>The Konami Code</b> (Japanese: コナミコマンド, Konami Komando, "Konami command").</span>
    <span>Also commonly referred to as the Contra Code and sometimes the 30 Lives Code,</span>
    <span>is a cheat code that appears in many Konami video games, as well as some non-Konami games.</span><br>
    <span>For further information, see <a target="_blank"ba href="https://en.wikipedia.org/wiki/Konami_Code">Konami Code Wiki</a></span>
  </div>
</div>