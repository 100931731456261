<div 
  class="process-archimate-junction"
  [style.left.px]="obj.left"
  [style.top.px]="obj.top"
  data-jtk-target="true"
>
  <!-- Anchor positions -->
  @for (anchor of anchorPositions; track $index) {
    <div
      [class]="'jtk-connect jtk-connect-'+anchor.id"
      data-jtk-source="true"
    ></div>
  }
</div>
