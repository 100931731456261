import { Component, Input, OnInit } from '@angular/core';
import { BeinformedService, CollabService } from '@core/services';

@Component({
  selector: 'naris-collaboration-room-cc',
  standalone: true,
  imports: [],
  templateUrl: './collaboration-room-cc.component.html',
  styleUrl: './collaboration-room-cc.component.scss'
})
export class CollaborationRoomCcComponent implements OnInit{
  private _endpoints: string[] = [];
  @Input() set endpoints(value: string[]) {
    this._endpoints = value;
    this.getEndpointData();
  }
  get endpoints(): string[] {
    return this._endpoints;
  }

  public causes: {objectName: string, label: string, names?: string[]};
  public consequences: {objectName: string, label: string, names?: string[]};

  constructor(
    private readonly beinformedService: BeinformedService,
    private readonly collabService: CollabService
  ) {}

  public ngOnInit() {
    this.collabService.updateCausesOrConsequences$.subscribe(causeOrConsequence => {
      this.getData(this.endpoints[causeOrConsequence]);
    });
  }

  private getEndpointData() {
    this.endpoints.forEach(endpoint => {
      this.getData(endpoint);
    });
  }

  private getData(endpoint: string) {
    if (!!endpoint) this.beinformedService.fetchResponseWithContributions(endpoint).subscribe({next: result => {
      const label = result.contributions.label;
      const objectName = result.objectName || '';
      const names = result.data._embedded?.results.map((item: Record<string, any>) => {
        const itemId = Object.keys(item)?.[0];
        if (!itemId) return '';
        const nameId = Object.keys(item[itemId])?.find(key => key.toLowerCase().endsWith('name'));
        if (!nameId) return '';
        return item[itemId][nameId];
      });
      if (endpoint.includes('causes')) this.causes = {objectName, label, names};
      else if (endpoint.includes('consequences')) this.consequences = {objectName, label, names};
    }});
  }
}
