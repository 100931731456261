import { defineProperty as e } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { BOTTOM as n, TOP as t, LEFT as r, RIGHT as o } from "./definitions.js";
import { uuid as s } from "../../util/util.js";
var u, i, c;
var l = "currentFace";
var a = (u = {}, e(u, t, n), e(u, o, r), e(u, r, o), e(u, n, t), u);
var f = (i = {}, e(i, t, o), e(i, o, n), e(i, r, t), e(i, n, r), i);
var d = (c = {}, e(c, t, r), e(c, o, t), e(c, r, n), e(c, n, o), c);
function p(e) {
  return e.faces.length === 0 ? t : e.faces[0];
}
function k(e, n) {
  return e.faces.indexOf(n) !== -1;
}
function m(e, n) {
  return (e.clockwise ? f : d)[n];
}
function v(e, n) {
  return (e.clockwise ? d : f)[n];
}
function C(e, n) {
  return e.lockedAxis == null ? e.lockedFace == null ? k(e, n) === true : e.lockedFace === n : e.lockedAxis.indexOf(n) !== -1;
}
function F(e, n) {
  if (k(e, n)) {
    return n;
  } else if (k(e, a[n])) {
    return a[n];
  } else {
    var t = m(e, n);
    if (k(e, t)) {
      return t;
    } else {
      var r = v(e, n);
      if (k(e, r)) {
        return r;
      }
    }
  }
  return n;
}
function _(e, n, t) {
  var r = {
    type: e,
    locations: [],
    currentLocation: 0,
    locked: false,
    id: s(),
    cssClass: t.cssClass || "",
    isFloating: false,
    isContinuous: true,
    timestamp: null,
    faces: t.faces || n,
    lockedFace: null,
    lockedAxis: null,
    clockwise: !(t.clockwise === false),
    __currentFace: null
  };
  Object.defineProperty(r, l, {
    get: function e() {
      return this.__currentFace;
    },
    set: function e(n) {
      this.__currentFace = F(this, n);
    }
  });
  return r;
}
var g = {
  Continuous: {
    faces: [t, r, n, o]
  },
  ContinuousTop: {
    faces: [t]
  },
  ContinuousRight: {
    faces: [o]
  },
  ContinuousBottom: {
    faces: [n]
  },
  ContinuousLeft: {
    faces: [r]
  },
  ContinuousLeftRight: {
    faces: [r, o]
  },
  ContinuousTopBottom: {
    faces: [t, n]
  }
};
export { _ as _createContinuousAnchor, p as getDefaultFace, C as isEdgeSupported, g as namedContinuousValues };