
<div class="sample-action">
  @if (!!description) {
    <div class="action-icon">
      <naris-icon
        icon="info-outline"
        class="desc-icon"
        [matTooltip]="description"
        matTooltipPosition="above"
        matTooltipClass="desc-icon-tooltip"
      />
    </div>
  }
  @if (!!actions) {
    <div class="actions-more">
      <naris-icon
        class="actions-more-icon"
        icon="more_vert"
        [matMenuTriggerFor]="auditActionsMenu"
      />
      <mat-menu
        #auditActionsMenu="matMenu"
        xPosition="after"
        [class]="'audit-actions-menu'"
      >
        @for (action of actions; track $index) {
          <button
            type="button"
            mat-menu-item
            (click)="executeAction(action)"
          >
            @if (action.icon !== 'spinner') {
              <naris-icon
                [icon]="action.icon || 'help-outline'"
                class="action"
              />
              <span class="action-label">{{action.label}}</span>
            } @else {
              <mat-spinner
                [diameter]="30"
                [strokeWidth]="3"
                [value]="null"
                [mode]="'indeterminate'"
              />
            }
          </button>
        }
      </mat-menu>
    </div>
  }
</div>
