import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressSpinnerComponent } from '../../elements/progress-spinner/progress-spinner.component';
import { BouncerComponent } from '../bouncer/bouncer.component';

@Component({
  selector: 'naris-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports: [NgClass, ProgressSpinnerComponent, BouncerComponent, TranslateModule]
})
export class LoaderComponent implements OnInit {
  @Input() public label?: string;
  @Input() public timeout?: number;
  @Input() public height?: string;
  @Input() public embeddedInForm? = false;
  @Output() public readonly cancel = new EventEmitter<any>();
  public timeoutPassed = false;

  public onCancel(): void {
    this.cancel.emit();
  }

  public ngOnInit(): void {
    if (this.timeout) {
      setTimeout(() => {
        this.timeoutPassed = true;
      }, this.timeout * 1000);
    }
  }
}
