import { nearestPointOnCurve as n, computeBezierLength as t, gradientAtPoint as r, bezierLineIntersection as e, pointAlongCurveFrom as c, pointOnCurve as o, locationAlongCurveFrom as i } from "./bezier.js";
import { _createBaseSegment as u, _defaultSegmentBoundingBoxIntersection as x, _defaultSegmentBoxIntersection as a } from "../common/abstract-segment.js";
import { _registerSegmentHandler as y } from "../core/connector/segments.js";
var p = "Bezier";
function l(n, t, r) {
  if (r) {
    t = i(n, t > 0 ? 0 : 1, t);
  }
  return t;
}
var v = {
  create: function n(t) {
    var r = u(t);
    var e = t.cp1x;
    var c = t.cp1y;
    var o = t.cp2x;
    var i = t.cp2y;
    var x = [{
      x: r.x1,
      y: r.y1
    }, {
      x: e,
      y: c
    }, {
      x: o,
      y: i
    }, {
      x: r.x2,
      y: r.y2
    }];
    var a = {
      xmin: Math.min(r.x1, r.x2, e, o),
      ymin: Math.min(r.y1, r.y2, c, i),
      xmax: Math.max(r.x1, r.x2, e, o),
      ymax: Math.max(r.y1, r.y2, c, i)
    };
    return Object.assign(r, {
      cp1x: e,
      cp2x: o,
      cp1y: c,
      cp2y: i,
      curve: x,
      extents: a,
      length: 0,
      type: p
    });
  },
  boundingBoxIntersection: function n(t, r) {
    return x(t, r);
  },
  boxIntersection: function n(t, r, e, c, o) {
    return a(t, r, e, c, o);
  },
  findClosestPointOnPath: function t(r, e, c) {
    var o = n({
      x: e,
      y: c
    }, r.curve);
    return {
      d: Math.sqrt(Math.pow(o.point.x - e, 2) + Math.pow(o.point.y - c, 2)),
      x: o.point.x,
      y: o.point.y,
      l: 1 - o.location,
      s: r,
      x1: null,
      y1: null,
      x2: null,
      y2: null
    };
  },
  getLength: function n(r) {
    if (r.length == null || r.length === 0) {
      r.length = t(r.curve);
    }
    return r.length;
  },
  getPath: function n(t, r) {
    return (r ? "M " + t.x2 + " " + t.y2 + " " : "") + "C " + t.cp2x + " " + t.cp2y + " " + t.cp1x + " " + t.cp1y + " " + t.x1 + " " + t.y1;
  },
  gradientAtPoint: function n(t, e, c) {
    e = l(t.curve, e, c);
    return r(t.curve, e);
  },
  lineIntersection: function n(t, r, c, o, i) {
    return e(r, c, o, i, t.curve);
  },
  pointAlongPathFrom: function n(t, r, e, o) {
    r = l(t.curve, r, o);
    return c(t.curve, r, e);
  },
  pointOnPath: function n(t, r, e) {
    r = l(t.curve, r, e);
    return o(t.curve, r);
  }
};
y(p, v);
export { v as BezierSegmentHandler, p as SEGMENT_TYPE_BEZIER };