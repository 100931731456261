import { Component, Input, OnInit } from '@angular/core';
import { CompareService } from '@core/services/compare.service';
import { TranslateModule } from '@ngx-translate/core';
import { showLine } from '../json-compare-animations';
import { TreeviewComponent } from '../../treeview/treeview.component';

@Component({
  selector: 'naris-json-compare-tree',
  templateUrl: './json-compare-tree.component.html',
  styleUrls: ['./json-compare-tree.component.scss'],
  animations: [showLine],
  standalone: true,
  imports: [TreeviewComponent, TranslateModule]
})
export class JsonCompareTreeComponent implements OnInit {

  public line: string;
  
  @Input() public oldData: any[];
  @Input() public newData: any[];
  @Input() public tableLabels: string[];
  @Input() public overlayTop: number;
  @Input() public overlayBottom: number;

  constructor(
    private readonly compareService: CompareService
  ) {}

  public ngOnInit(): void {
    this.compareService.hoveredItemUpdated$.subscribe(item => {
      if (!!item) {
        this.line = '';
          
        const oldItem = this.compareService.oldItemsPositions.get(item.id);
        const newItem = this.compareService.newItemsPositions.get(item.id);

        
        if (!oldItem || !newItem) {
          return;
        }
        const oldX = oldItem.right + 8;
        let oldY = oldItem.y + 18;

        oldY = oldY <= this.overlayTop ? this.overlayTop : oldY;
        oldY = oldY >= this.overlayBottom ? this.overlayBottom : oldY;
        
        const newX = newItem.x - 8;
        let newY = newItem.y + 18;

        newY = newY <= this.overlayTop ? this.overlayTop : newY;
        newY = newY >= this.overlayBottom ? this.overlayBottom : newY;
        
        const marker1X = oldX;
        const marker1Y1 = oldY - 19;
        const marker1Y2 = oldY + 19;

        const marker2X = newX;
        const marker2Y1 = newY - 19;
        const marker2Y2 = newY + 19;

        let tmpLine = `M${oldX} ${oldY} L${newX} ${newY}`;

        if (oldY > this.overlayTop && oldY < this.overlayBottom) {
          tmpLine = `${tmpLine} M${marker1X} ${marker1Y1} L${marker1X} ${marker1Y1} ${marker1X} ${marker1Y2}`;
        }
        if (newY > this.overlayTop && newY < this.overlayBottom) {
          tmpLine = `${tmpLine} M${marker2X} ${marker2Y1} L${marker2X} ${marker2Y1} ${marker2X} ${marker2Y2}`;
        }

        this.line = tmpLine;
      } else {
        this.line = '';
      }
    });
  }
}
