import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, forkJoin, of, throwError, zip } from 'rxjs';
import { concatMap, map, finalize, catchError, switchMap } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { HttpService } from '@core/services/http.service';
import { DialogService } from '@core/services/dialog.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { FormService } from '@core/services/form.service';
import { LoaderService } from '@core/services/loader.service';
import { CollabService } from '@core/services/collab.service';
// import { DiagramService } from '@core/services/diagram.service';
import { SocketService } from '@core/services/socket.service';
import { TaskgroupService } from '@core/services/taskgroup.service';
import { NarisBreadcrumbService } from '@core/services/breadcrumb.service';
import { AuthService } from '@core/services/auth.service';
import { environment } from '@src/environments/environment';
import { FORM_LOOKUP_TOKEN, INFO_DIALOG_TOKEN } from '@core/constants';
import { ComponentType } from '@angular/cdk/portal';
import { FormLookupComponent } from '@core/form/form-lookup/form-lookup.component';
import { MatDialog } from '@angular/material/dialog';
import { INarisDynamicDashboardWidget } from '@core/models/dynamic-dashboard.model';
import { TranslateService } from '@ngx-translate/core';
import { EAuthContext } from '@core/enums';
import { InfoDialogComponent } from '@shared/components/info-dialog/info-dialog.component';
import { FooterToolbarService } from './footer-toolbar.service';
import { BreadcrumbTabsService } from './breadcrumb-tab.service';
import { GuardService } from './guard.service';
import { StrategyMapService } from './strategy-map.service';
import type { HttpErrorResponse } from '@angular/common/http';
import type {
  IKeyValuePair, ITaskGroup, ICaseTabComponent, IAction, ICombinedResponse, IPostableObject,
  IFormResult, TFormContributionsResponse, IFormResponse, TFormElement, IFormObject, IExtractedForm,
  TResponse, TResponseTypes, ICaseListResult, TSuggestions, IContributionsResponseTaskGroup, ICaseViewPanel, ICaseListAction, IContributionsAction
} from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class BeinformedService {

  /**
   * Storage for contribution responses
   */
  private readonly contributionCollection: IKeyValuePair<Record<string, string>>[] = [];
  public formResult: Record<string, any> | null;

  constructor(
    private readonly httpService: HttpService,
    private readonly dialogService: DialogService,
    private readonly snackbarService: SnackbarService,
    private readonly formService: FormService,
    private readonly loaderService: LoaderService,
    private readonly router: Router,
    private readonly collabService: CollabService,
    // private readonly diagramService: DiagramService,
    private readonly socketService: SocketService,
    private readonly taskgroupService: TaskgroupService,
    private readonly breadcrumb: NarisBreadcrumbService,
    private readonly authService: AuthService,
    private readonly dialog: MatDialog,
    private readonly guardService: GuardService,
    private readonly breadcrumbTabService: BreadcrumbTabsService,
    private readonly translate: TranslateService,
    @Inject(FORM_LOOKUP_TOKEN) private readonly formLookupComponent: ComponentType<FormLookupComponent>,    
    @Inject(INFO_DIALOG_TOKEN) protected readonly infoDialogComponent: ComponentType<InfoDialogComponent>,
    private readonly footerToolbarService: FooterToolbarService,
    private readonly strategyMapService: StrategyMapService
  ) { }

  public getContributions(url: string, refreshContribution = false): Observable<any> {
    return new Observable(observer => {
      const foundContribution = this.contributionCollection.find(item => item.key === url);
      if (foundContribution != null && !foundContribution.value['error'] && !refreshContribution) {
        observer.next(foundContribution.value);
        observer.complete();
      } else {
        this.httpService.get(url).subscribe(x => {
          this.contributionCollection.push({ key: url, value: x });
          observer.next(x);
          observer.complete();
        });
      }
    });
  }

  /**
   * Returns the result of the endpoint, including the contributions call.
   * Returns { data, contributions, objectName }
   * @param endpoint BeInformed API endpoint
   */
  public fetchResponseWithContributions<T extends TResponseTypes = 'default'>(endpoint: string, isRest = true, refreshContribution = false) {
    const rest = isRest || this.authService.authContext === EAuthContext.SSO;
    return this.httpService.get(endpoint, rest).pipe(
      concatMap(result => {
        const objectName = Object.keys(result);
        const contributionsLink = (Object.values(result)?.[0] as Record<string, any>)['_links']?.['contributions'].href;
        return forkJoin({
          data: of(result),
          contributions: this.getContributions(contributionsLink, refreshContribution),
          objectName
        });
      }),
      map(({ objectName, ...response }) => {
        const data = Object.values(response.data)[0];
        const contributions = response.contributions[objectName];
        return { data, contributions, objectName } as TResponse<T>;
      })
    );
  }

  /**
   * Returns a combined response for form endpoints.
   * Returns { data, contributions, objectName }
   * @param endpoint BeInformed API endpoint
   * @param commit whether to commit or not
   * @param payload optional payload
   */
  public fetchForm(endpoint: string, commit = false, payload: IPostableObject = {}) {
    const url = this.getEndpointUrl(endpoint, commit);
    const rest = this.authService.authContext === EAuthContext.SSO;
    return this.httpService.post(url, payload, rest).pipe(
      catchError(err => of(err)), // Rethrow error as success
      concatMap((result: IFormResponse) => {
        const contributionsLink = result.error?.formresponse?._links?.contributions?.href;
        return forkJoin<{ data: Observable<IFormResponse>; contributions: Observable<TFormContributionsResponse> }>({
          data: of(result),
          contributions: contributionsLink ? this.getContributions(contributionsLink) : of([])
        });
      }),
      map(result => {
        const objectName = Object.keys(result.contributions)[0];
        const contributions = Object.values(result.contributions)[0];
        const data = result.data;
        return { data, contributions, objectName } as TResponse<'form'>;
      })
    );
  }

  /**
   * Extract attributes from form response
   * @param resultObject object containing data and contributions.
   */
  public extractForm({ data, contributions }: IFormResult) {
    const title = contributions?.label;
    const actiontype = contributions?.actiontype;
    const missing = data.error?.formresponse?.missing;
    !!missing && (this.formService.currentForm = { ...missing.anchors[0] });
    const activeObject = missing?.anchors[0].objectid;
    const layouthint = contributions.layouthint;
    const objects = Object.keys(contributions.objects).map(objName => {
      const objConfig = contributions.objects[objName];
      const objMissing = missing?.anchors.find(a => a.objectid === objName)?.elements || missing?.anchors?.[0]?.elements;
      const buttonLabels = objConfig.buttonLabels;
      const label = objConfig.label;
      const repeatable = objConfig.repeatable;
      const elements = objConfig.attributes.map(e => {
        const elementId = Object.keys(e)[0];
        const elementMissing = objMissing?.find(el => el.elementid === elementId);
        return { ...e[elementId], ...elementMissing, elementId, isMissing: !!elementMissing } as TFormElement;
      });
      const inputs = this.formService.mapElementsToInputs(elements);
      return { objectid: objName, layouthint: objConfig.layouthint, label, elements, inputs, buttonLabels, repeatable } as IFormObject;
    });
    return { title, actiontype, objects, activeObject, layouthint } as IExtractedForm;
  }

  /**
   * Extract panels from data and contributions results.
   * @param resultObject object containing data and contributions.
   */
  public extractPanels({ data, contributions }: ICombinedResponse) {
    return contributions?._links?.panel
      // Combine data and contributions
      ?.map(panel => {
        const panelConfig = data?._links?.[panel.name];
        return { ...panel, ...panelConfig } as ICaseViewPanel;
      })
      // Remove panel items that have no href
      .filter(panel => panel.href);
  }

  /**
   * Extract taskgroups from data and contributions results.
   * @param resultObject object containing data and contributions.
   */
  public extractTaskGroups({ data, contributions }: ICombinedResponse) {
    return (data._links?.taskgroup as ITaskGroup[])
      // Combine data and contributions information into single objects
      ?.map(taskgroup => {
        const taskgroupConfig = contributions._links?.taskgroup?.find((t: ITaskGroup) => t.name === taskgroup.name);
        return { ...taskgroup, ...taskgroupConfig };
      }) as (IContributionsResponseTaskGroup & ITaskGroup)[];
  }

  /**
   * Extract taskgroups from data and contributions results for grouping panels
   * @param resultObject object containing data and contributions.
   */
  public extractTaskGroupsFromGroupingPanel({ data, contributions }: ICombinedResponse) {
    const taskgroups = data.taskgroups
      // Filter only taskgroups that contain actions
      ?.filter((taskgroup: ITaskGroup) => taskgroup.actions?.length)
      // Combine data and contributions information into single objects
      ?.map((taskgroup: ITaskGroup) => {
        const taskgroupConfig = contributions.taskgroups?.find((t: ITaskGroup) => t.name === taskgroup.name);
        const actions = taskgroup.actions?.map((action: IAction) => {
          const actionConfig = taskgroupConfig?.actions?.find((a: IAction) => a.name === action.name);
          return { ...action, ...actionConfig };
        });
        return { ...taskgroup, ...taskgroupConfig, actions };
      });
    return taskgroups?.length ? taskgroups : null;
  }

  /**
   * Extract taskgroups from _links
   * @param resultObject object containing data and contributions.
   */
  public extractTaskGroup<T extends Exclude<TResponseTypes, 'form'> = 'default'>({ data, contributions }: TResponse<T>) {
    const actions = data.actions?.map<IAction | ICaseListAction | IContributionsAction>(action => {
      const actionConfig = contributions.actions?.find((a: IAction) => a.name === action.name);
      return { ...action, ...actionConfig };
    });
    return { actions };
  }

  public extractActions({ data }: ICaseListResult): Promise<IAction[]> {
    return new Promise(resolve => {
      const taskgroup = data._links?.taskgroup as any as any[];
      if (!!taskgroup) {
        const href = taskgroup[0].href;
        if (!!href) {
          this.fetchResponseWithContributions(href).subscribe(result => {
            const _taskgroup = this.extractTaskGroup(result);
            if (!!_taskgroup.actions) resolve(_taskgroup.actions as IAction[]);
            else resolve([]);
          });
        }
      } else resolve([]);
    });
  }

  public extractWidget(result: IFormResult): Promise<INarisDynamicDashboardWidget> {
    return new Promise(resolve => {
      const attrKey = Object.keys(result.contributions.objects).find(obj => obj.includes('Attributes'));
      const anchor = result.data.error.formresponse?.missing?.anchors.find(a => a.objectid === attrKey);
      const label = result.contributions.label;
      if (anchor) {
        const type = anchor.elements.find(el => el.elementid === 'WidgetType')?.suggestion || '';
        const value = anchor.elements.find(el => el.elementid === 'Value')?.suggestion || '';
        const color = anchor.elements.find(el => el.elementid === 'Color')?.suggestion || '';
        const icon = anchor.elements.find(el => el.elementid === 'Icon')?.suggestion || '';
        const href = anchor.elements.find(el => el.elementid === 'Href')?.suggestion || '';
        const contexts = anchor.elements.find(el => el.elementid === 'Contexts')?.suggestion || '';
        const layouthint = anchor.elements.find(el => el.elementid === 'Layouthint')?.suggestion || '';
        resolve({ type, value, color, icon, href, contexts, label, layouthint });
      } else resolve({} as INarisDynamicDashboardWidget);
    });
  }

  public extractGraph(res: IFormResult): Promise<INarisDynamicDashboardWidget> {
    return new Promise(resolve => {
      const label = res.contributions?.label;
      const data = res.data.error?.formresponse.missing?.anchors[0].elements[0]?.dynamicschema;
      const newData = data?.map(x => x.elements) || [];
      let histogramDataType: string | null = '';
      if (newData.length > 0) {
        const dataTypeKey = Object.keys(newData[0]).find(x => x.toLowerCase() === 'consequencetype');
        histogramDataType = newData.length > 0 && !!dataTypeKey ? (newData[0][dataTypeKey] as string).toLowerCase() : null;
      }
      const objectType = Object.keys(res?.contributions?.objects?.Attributes?.attributes?.[0])?.[0];
      // const dynamicschema = res.data.error?.formresponse.dynamicschema;
      // const legendItem = dynamicschema?.[`${this.objectType}.${this.legendKey}`];
      // this.filterLegend = !!legendItem ? {options: legendItem} : null;
      // const schemaKeys = !!dynamicschema ? Object.keys(dynamicschema) : null;
      // const filters: Record<string, any>[] = [];
      // schemaKeys?.forEach(key => {
      //   const filterName = key.split('.')[1];
      //   if (!filterName.includes(this.legendKey)) filters.push({[key]: dynamicschema?.[key]});
      // });
      // filters.forEach(filter => {
      //   const label = Object.keys(filter)[0].replace('.', '_');
      //   const element = {label: Object.keys(filter)[0].replace(`${this.objectType}.`, ''), id: label};
      //   const options = filter[Object.keys(filter)[0]] as IInputOption[];
      //   const mappedOptions = options.map(option => ({key: option.label, value: option.code} as IInputOption));
      //   const input = new RadioInput({ ...element, options: mappedOptions } as IInputConfig);
      //   this.filterMeta.push(input);
      //   input.value = '';
      //   const control = this.formService.toFormField(input);
      //   this.filterForm.addControl(label, control);
      //   this.subs.push(
      //     control.valueChanges.subscribe((val: string) => {
      //       this.selectedParentId = [];
      //       this.activeFilters = handleFilter(this.activeFilters || [], this._filterChips$, val, input);
      //     })
      //   );
      //   if (!!input.options && input.options.length > 0)
      //     setTimeout(() => {
      //       const foundFilter = this.widgetConfig?.filters?.find(x => x.input.id === input.id);
      //       if (!!foundFilter && !!foundFilter.value) control.setValue(foundFilter.value);
      //       else control.setValue(input.options?.[0].value);
      //     });
      // });
      // this.subs.push(this._filterChips$.subscribe(filterChip => {
      //   this.previousWidgetConfig = clone(this.widgetConfig);
      //   this.newWidgetConfig = clone(this.widgetConfig);
      //   if (!this.isMultipleBar) {
      //     this.preFilterData(filterChip);
      //   }
      //   if (this.editMode) this.newWidgetConfig = {...this.newWidgetConfig, filters: filterChip};
      // }));
      // if (this.isMultipleBar && !!data) {
      if (!!data) {
        const labelContributions = res?.contributions?.objects?.Attributes?.attributes?.[0][objectType].children;
        const chartLabels = data.map(el => el.elements.Name) as string[] || [];
        let chartData: any[] = [];
        data.forEach(item => {
          const keys = Object.keys(item.elements).filter(keyName => keyName !== 'Name');
          keys.forEach(key => {
            const itemLabel = labelContributions?.find(cont => Object.keys(cont)?.[0] === key)?.[key].label;
            let dataItem = chartData?.find(chartDataItem => chartDataItem.label === itemLabel);
            if (!!dataItem) {
              dataItem.data.push(item.elements[key] as number);
            } else {
              if (!chartData.length) {
                chartData = [];
              }
              dataItem = {} as { data: number[]; label: string };
              dataItem.data = [item.elements[key] as number];
              dataItem.label = itemLabel;
              dataItem.barThickness = 20;
              chartData?.push(dataItem);
            }
          });
        });

        resolve({
          type: 'Graph',
          chartData,
          label,
          href: '',
          dynamicGraph: {
            chartLabels,
            newData,
            histogramDataType,
            objectType
          }
        });

        // this.setSolid();
        // this.generateColors();

        // const heightCalc = this.chartLabels.length * 80 + 30;
        // this.chartHeight = this.chartType === ChartTypes.horizontalBar ? heightCalc : 400;
      } else {
        // this.preFilterData();
      }
    });
  }

  /**
   * Extract components from data and contributions results.
   * @param resultObject object containing data and contributions.
   */
  public extractComponents({ data, contributions }: ICombinedResponse) {
    return (data._links?.component as ICaseTabComponent[])
      ?.map(component => {
        const componentConfig = contributions._links?.component?.find((c: ICaseTabComponent) => c.name === component.name);
        return { ...component, ...componentConfig };
      });
  }

  /**
   * Extract attributes from data and contributions results.
   * @param resultObject object containing data and contributions.
   */
  public extractAttributes({ data, contributions }: ICombinedResponse) {
    return contributions.attributes?.map(attr => {
      const name = Object.keys(attr)[0];
      const attrConfig = attr[name];
      let valueLabel = data[name];
      let valueCode = data[name];
      // If attribute has static options, get the label by code from options
      if (attrConfig?.optionMode === 'static') {
        if (attrConfig.multiplechoice) {
          valueLabel = valueLabel?.map((v: any) => attrConfig.options?.find((o: any) => o.code === v)?.label || v);
          valueCode = valueLabel?.map((v: any) => attrConfig.options?.find((o: any) => o.code === v)?.code || v);
        } else {
          valueLabel = attrConfig.options?.find((o: any) => o.code === valueLabel)?.label || valueLabel;
          valueCode = attrConfig.options?.find((o: any) => o.code === valueLabel)?.code || valueCode;
        }
      }
      // Transform date types to formatted date (DD-MM-YYYY)
      if (attrConfig?.type === 'date') valueLabel = valueLabel ? DateTime.fromISO(valueLabel).toFormat('dd-MM-yyyy') : valueLabel;
      // Transform datetime types to formatted datetime (DD-MM-YYYY @ hh:mm)
      if (attrConfig?.type === 'datetime' || attrConfig?.type === 'timestamp') valueLabel = valueLabel ? DateTime.fromISO(valueLabel).toFormat('dd-MM-yyyy @ HH:mm') : valueLabel;
      // Map the valueLabel to a string
      valueLabel = this.mapToString(valueLabel, true);
      valueCode = this.mapToString(valueCode);
      return { ...attrConfig, name, valueLabel, valueCode };
    });
  }

  /**
   * Extract results from data and contributions results.
   * @param resultObject object containing data and contributions.
   */
  public extractResults({ data, contributions }: ICaseListResult) {
    const results = data._embedded?.results.map(row => {
      const objName = Object.keys(row)[0];
      const rowObj = row[objName];
      const rowMeta = contributions.results?.[objName];
      const statusAttribute = rowMeta.attributes.find((attr: any) => attr.hasOwnProperty('Status'));
      const statusOptions = statusAttribute?.Status?.options;
      const actions = rowObj.actions?.map((action: IAction) => {
        const actionMeta = rowMeta.actions?.find((a: IAction) => a.name === action.name);
        return { ...action, ...actionMeta };
      });
      return { ...rowObj, actions, statusOptions, objectType: objName };
    });
    return results;
  }

  /**
   * Returns a string for whatever value you input.
   * @param value any value
   */
  public mapToString(value: any, table = false) {
    if (!['number', 'boolean'].includes(typeof value) && !value) return null;
    else if (['number', 'string', 'boolean'].includes(typeof value)) return value;
    else {
      const objStrings: string[] = [];
      for (const key in value as Record<string, any>) {
        if (!!value[key] && !['_id', 'ParentID'].includes(key)) {
          const objString = this.mapToString(value[key], table);
          objStrings.push(objString);
        }
      }
      return table ? objStrings : objStrings.join(' | ');
    }
  }

  /**
   * Maps data from beinformed response to key value pairs.
   * @param contributions contributions response object
   * @param data data response object
   */
  public mapDataToKVPArray(contributions: any, data: any): IKeyValuePair[] {
    const returnValue: IKeyValuePair[] = [];
    const attributes = contributions.attributes;
    attributes.forEach((element: any) => {
      const itemKey = Object.keys(element)[0];
      let value = data[itemKey];
      if (
        !Array.isArray(data[itemKey]) &&
        typeof data[itemKey] === 'object' &&
        data[itemKey] !== null &&
        (element[itemKey].children !== null && element[itemKey].children.length > 0)
      ) {
        const childKey = Object.keys(element[itemKey].children[0])[0];
        value = value[childKey];
      }
      const kvp: IKeyValuePair = { key: element[itemKey].label, value };
      returnValue.push(kvp);
    });
    return returnValue;
  }

  /**
   * Handle post request
   * @param endpoint BeInformed API endpoint
   * @param redirect Whether to redirect after success
   * @param postCallback Function that is executed when post is handled successfully
   * @param showLoader Whether to show a loading state while processing the post request
   */
  public handlePost(
    endpoint: string,
    redirect = false,
    close = false,
    postCallback?: (...args: any[]) => any,
    showLoader = true,
    showSnackbar = true,
    refresh = false,
    isWizardClosing = false,
    tableReload = false
  ) {
    if (showLoader) this.loaderService.open('one_moment');
    this.httpService.post(endpoint).pipe(
      finalize(() => {
        if (showLoader) this.loaderService.close();
      })
    ).subscribe(
      (result: any) => {
        const redirectHref = result.formresponse?.success?.redirect;
        const isStrategyMap = this.router.url.includes('strategy-map');
        if (showSnackbar) this.snackbarService.open({
          type: 'success',
          text: 'snackbar.successfully',
          dismissLabel: 'dismiss',
          duration: 3000
        });
        if (redirect) {
          this.breadcrumb.navigationClick(redirectHref, '', '', false);
          this.footerToolbarService.reset();
          void this.router.navigate([redirectHref]);
        }
        if (refresh) this.taskgroupService.refreshPage.next(true);
        if (!!postCallback && !redirect) { //? && !redirect Als je redirect, navigeer je weg, zie rgl. 497. Vraag is of dit wel altijd wordt uitgevoerd en zorgt voor fout bij afrinden asset wizard
          const callbackRedirect = tableReload ? !tableReload : !redirect;
          postCallback(callbackRedirect, close, result?.formresponse?.success?.data);
        }
        if (isWizardClosing) {
          this.guardService.reset();
          this.breadcrumb.removeLastCrumb();
        }
        if (isStrategyMap) this.strategyMapService.refreshMap$.next();
      }
    );
  }

  /**
   * Handle action click
   * @param action IAction object
   */
  public async handleAction(
    action: IAction, 
    formCallback?: (...args: any[]) => any, 
    postCallback?: (...args: any[]) => any, 
    showLoader = false, 
    showSnackbar?: boolean, 
    redirectMessage = 'dialog.sure_continue', 
    isGridTable = false,
    selfHref?: string
  ) {
    const confirm = action.layouthint?.includes('confirm');
    const emptyPost = action.layouthint?.includes('empty-post');
    const redirect = action.layouthint?.includes('redirect');
    const forcedRedirect = action.layouthint?.includes('forcedredirect');
    const refresh = action.layouthint?.includes('refresh');
    const createWizard = action.layouthint?.includes('wizard');
    const close = action.type === 'delete';
    const isWizardClosing = action.name?.startsWith('complete') && action.name.endsWith('wizard');
    const popupAction = action.layouthint?.includes('pop-up-action') || isGridTable;
    const showDetails = action.layouthint?.includes('show-details');
    const tableReload = action.layouthint?.includes('table-reload') || action.layouthint?.includes('refresh-panels');

    const showDialog = () => {
      this.dialogService.open({
        type: 'alert',
        title: action.label || '',
        text: redirectMessage,
        confirmLabel: action.label,
        confirmColor: 'primary',
        cancelLabel: 'cancel'
      }).subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.handlePost(action.href!, redirect, close, postCallback, showLoader, showSnackbar, refresh, isWizardClosing, tableReload);
        }
      });
    };

    if (confirm) {
      if (action.name === 'join-live-session' && this.collabService.inSession) {
        let title = '';
        let text = '';

        if (this.collabService.isHost) {
          this.translate.get('collab.dialog.title.host_already_in_session').subscribe(value => title = value);
          this.translate.get('collab.dialog.text.host_already_in_session').subscribe(value => text = value);

          this.dialogService.open({
            type: 'alert',
            title,
            text
          });
        } else {
          this.translate.get('collab.dialog.title.participant_already_in_session').subscribe(value => title = value);
          this.translate.get('collab.dialog.text.participant_already_in_session').subscribe(value => text = value);

          this.dialogService.open({
            type: 'alert',
            title,
            text,
            confirmLabel: 'Leave active session',
            confirmColor: 'primary',
            cancelLabel: 'cancel'
          }).subscribe((confirmed: boolean) => {
            if (confirmed) {
              this.collabService.stopSession();

              showDialog();
            }
          });
        }
      } else {
        showDialog();
      }
    } else if (forcedRedirect) this.forceRedirect(action.href!);

    else if (emptyPost) {
      if (action.name === 'stop-live-session') {
        this.collabService.isHost ? this.collabService.stopSession() : this.collabService.stopCollab();
      }

      if (action.name === 'disable-questionnaire') {
        window.location.reload();
      }

      this.handlePost(action.href!, redirect, close, postCallback, showLoader, showSnackbar, refresh, isWizardClosing);
    } else if (createWizard) {
      // Als proces wizard wordt geopend, moeten we iets 'speciaals' doen
      // routing kijkt op dat oment naar /config, dit veranderen we in /process
      const isConfigProcess = action.href?.startsWith('/configuration/organization') && action.href.endsWith('/create-process');
      const module = isConfigProcess ? 'process' : this.router.url.split('/')[1];
      const label = isConfigProcess ? 'Process' : `breadcrumb.${module}-wizard`; // De term 'Process' is bekend in FE vertalingen en zal dus vertaald worden
      const url = `${module}/${module}-wizard/object`;
      this.breadcrumbTabService.add({ label: this.translate.instant(label), url: url, originalUrl: url, type: module, children: [], queryParams: { endpoint: action.href, type: 'wizard' } });
      this.breadcrumb.addBreadcrumb = false;
      this.footerToolbarService.reset();
      void this.router.navigate([url], { queryParams: { endpoint: action.href, type: 'wizard' } });
    } else if (popupAction) {
      this.dialog.open(this.formLookupComponent, {
        panelClass: 'naris-advanced-lookup-dialog',
        minWidth: '54rem',
        data: { endpoint: action.href, multiple: false, isForm: true }
      }).afterClosed().subscribe(_val => {
        if (refresh && formCallback) formCallback(!redirect, close, refresh);
      });
    } else if (showDetails) {
      this.dialog.open(this.infoDialogComponent, {
        panelClass: 'naris-advanced-lookup-dialog',
        minWidth: '54rem',
        data: { endpoint: selfHref }
      }).afterClosed().subscribe(_val => {
        if (refresh && formCallback) formCallback(!redirect, close, refresh);
      });
    } else {
      if (isWizardClosing) {
        if (await this.guardService.canDeactivate(true, undefined, true, false)) {
          this.formService.open(action.href!, 'form', isWizardClosing).subscribe(isSaved => {
            if (!!isSaved?.data?.Result) this.formResult = isSaved.data.Result;
            if (isSaved && formCallback) formCallback(!redirect, close, refresh);
            if (isWizardClosing) {
              this.breadcrumb.removeLastCrumb();
            }
            if (isSaved) void this.guardService.canDeactivate(true, undefined, true);
          });
        }
      } else this.formService.open(action.href!, 'form', isWizardClosing).subscribe(isSaved => {
        if (!!isSaved?.data?.Result) this.formResult = isSaved.data.Result;
        if (isSaved && formCallback) formCallback(!redirect, close, refresh, action);
        if (isWizardClosing) {
          this.guardService.reset();
          this.breadcrumb.removeLastCrumb();
        }
      });
    }
  }

  /**
   * Starts a session by collecting data for the collaboration panel and opening it
   * @param href The endpoint to call for collecting collaboration data
   */
  public startLiveSession(href: string) {
    return !this.socketService.connected ? this.socketService.openConnection().pipe(
      finalize(() => this.postSession(href).subscribe())
    ) : this.postSession(href);
  }

  private postSession(href: string) {
    return this.fetchForm(`${href}?commit=false`).pipe(
      switchMap(
        result => zip(of(result), this.getSuggestions(result)),
      ),
      switchMap(
        ([result, res]) => zip(of(result), of(res), this.postSuggestions(res, href))
      )
    );
  }

  /**
   * Returns an API endpoint with or without commit
   * @param endpoint API endpoint
   * @param commit whether to add or remove commit
   */
  private getEndpointUrl(endpoint: string, commit = false): string {
    const separator = endpoint.includes('?') ? '&' : '?';
    if (!commit && !endpoint.includes('?commit=false')) endpoint = `${endpoint}${separator}commit=false`;
    if (commit && /(\?|&)commit=false/.test(endpoint)) endpoint = endpoint.replace(/(\?|&)commit=false/, '');
    return endpoint;
  }

  public getSuggestions = (e: IFormResult) => {
    if (!!e.data.error?.formresponse) {
      const tokens = e.data.error.formresponse.tokens;
      const attributes = e.data.error.formresponse.missing?.anchors?.find(a => a.objectid === 'Attributes');
      const postBody = { tokens, objects: { Attributes: {} as Record<string, any> } };
      const elements = attributes?.elements?.map(el => {
        let value = el.suggestion;
        postBody.objects.Attributes[el.elementid] = el.suggestion ?? el.suggestions?.map(s => +s) ?? null;
        if (!!el.dynamicschema?.length) value = el.dynamicschema.map(d => ({ id: d.code, name: d.elements?.Name }));
        return { elementId: el.elementid, value };
      }) || [];
      return of([elements, postBody] as TSuggestions);
    } else return throwError(() => e);
  };

  public postSuggestions([elements, postBody]: TSuggestions, href: string) {
    this.httpService.post(href, postBody, false).subscribe({
      next: res => {
        if (!!res.formresponse.success.redirect) void this.router.navigate([res.formresponse.success.redirect]);
      },
      error: e => {
        const error = e.error.formresponse.errors[0];
        this.dialogService.open({
          type: 'alert',
          title: 'Error',
          text: error?.message,
          confirmLabel: 'ok',
          confirmColor: 'primary'
        }).subscribe();
      }
    });
    return elements;
  }

  /**
   * Call form of url en then redirect to url in redirectURL
   * @param url is the location of the form containing redirect url
   */
  private forceRedirect(url: string) {

    /*
    When forcedredirect layouthint is set, then call url to form
    form will result in 400 because there is missing data
    in the missing data there will be an elementid whit the value 'redirectURL'
    and the suggestion will contain the url to which we must redirect
    */
    this.httpService.post(url).pipe(
      catchError(err => {
        if (err.status === 400) {
          const elements = err?.error?.formresponse?.missing?.anchors?.[0]?.elements?.[0];
          if (!!elements && elements.elementid === 'redirectURL' && !!elements.suggestion) {
            this.breadcrumb.navigationClick(elements.suggestion, '', '', false);
            this.footerToolbarService.reset();
            void this.router.navigate([elements.suggestion]);
          }
        }
        return of(err);
      })
    ).subscribe();
  }

  public openCase(caseId: number | string) {
    return this.httpService.post(`/b2c/tasks/open-case?id=${caseId}`, {}).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 400 && !!err.error?.formresponse) {
          const redirectUrl = (err.error as IFormResponse).formresponse?.missing?.anchors[0]?.elements?.find(el => el.elementid === 'redirectURL')?.suggestion;
          return of(redirectUrl);
        } else {
          // eslint-disable-next-line no-console
          !environment.production && console.error(err);
          throw err;
        }
      })
    );
  }
}
