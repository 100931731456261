import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router, RouterOutlet } from '@angular/router';
import { LanguageService } from '@core/services';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import type { ILanguage } from '@core/models';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [RouterOutlet, MatFormField, MatLabel, MatSelect, FormsModule, ReactiveFormsModule, MatOption, TranslateModule]
})
export class AuthLayoutComponent implements OnInit, OnDestroy {

  public langSelect = new FormControl('nl');
  private selectedLanguage: string;
  public locales: ILanguage[] = [];
  private subs: Subscription[];

  public isDoQuestionnaire = false;

  constructor(public langService: LanguageService, private readonly router: Router) {}

  public ngOnInit() {
    this.selectedLanguage = this.langService.getAppLang();
    this.selectedLanguage = this.langService.isLanguageAvailable(this.selectedLanguage) ? this.selectedLanguage : 'en';
    this.langSelect.setValue(this.selectedLanguage);
    this.locales = this.langService.availableLanguages;
    this.subs = [
      this.langSelect.valueChanges.subscribe(val => {
        this.langService.setAppLang(val || 'en');
        window.location.reload();
      })
    ];
    this.isDoQuestionnaire = this.router.url.includes('do-questionnaire');
  }

  public ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
