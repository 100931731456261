import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { KB_ICONS, SUB_ITEMS } from '@core/constants';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatBadge } from '@angular/material/badge';
import { MatDivider } from '@angular/material/divider';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyStateComponent } from '../../../../shared/components/empty-state/empty-state.component';
import { IconComponent } from '../../../../shared/elements/icon/icon.component';
import { AvatarComponent } from '../../../../shared/elements/avatar/avatar.component';
import { ButtonComponent } from '../../../../shared/elements/button/button.component';
import type { IKbIcon, TKbObject, TSelaction } from '@core/models';

@Component({
  selector: 'naris-knowledgebase-batch',
  templateUrl: './knowledgebase-batch.component.html',
  styleUrls: ['./knowledgebase-batch.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, ButtonComponent, MatDialogClose, CdkScrollable, MatDialogContent, AvatarComponent, IconComponent, MatBadge, MatDivider, MatDialogActions, MatTooltip, EmptyStateComponent, TranslateModule]
})
export class KnowledgebaseBatchComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public selection: Partial<TKbObject>[],
    public readonly dialogRef: MatDialogRef<KnowledgebaseBatchComponent, TSelaction>
  ) {}

  public getIcons(item: Partial<TKbObject>) {
    return Object.entries(item).reduce<IKbIcon[]>((acc, [ key, value ]) => {
      if (!SUB_ITEMS.includes(key as keyof typeof item) || !value || Array.isArray(value) && !value.length) return acc;
      const iconObj = {tooltip: `casetype.${key}`, ...KB_ICONS[key]} as IKbIcon;
      if (Array.isArray(value)) iconObj['badge'] = value.length;
      return [...acc, iconObj];
    }, []);
  }
}
