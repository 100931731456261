
import { ComponentType } from '@angular/cdk/portal';
import { AfterViewInit, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FORM_LOOKUP_TOKEN } from '@core/constants';
import { FormLookupComponent } from '@core/form/form-lookup/form-lookup.component';
import { BeinformedService } from '@core/services';
import { MatFormField } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { IconComponent } from '../../elements/icon/icon.component';

export interface MatrixJsonData {
  COLS: string;
  ROWS: string;
  VALUE: number | string;
  OBJECTID: number;
  HOVER: string;
}

@Component({
  selector: 'app-matrix',
  templateUrl: './matrix.component.html',
  styleUrl: './matrix.component.scss',
  standalone: true,
  imports: [MatFormField, MatSelect, MatOption, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, NgClass, MatCellDef, MatCell, IconComponent, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, TranslateModule, OverlayModule]
})
export class MatrixComponent implements AfterViewInit {

  @Input() public href: string;
  @Input() public data: Record<string, any>[];
  @Input() public showTitle = true;
  @Input() public firstColumnSticky = true;
  @Input() public isDashboard = false;
  @Input() public matrixSelection: string[];

  @Output() public readonly closeParent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public readonly selectionChanged: EventEmitter<string[]> = new EventEmitter<string[]>();

  public loading = false;
  public title: string;
  public columnsToDisplay: string[] = [];
  public allData: Record<string, any>[] = [];
  public hoverData: {id: number; date: string; name: string; description: string} | undefined;
  public dataSource: Record<string, any>[] = [];
  public rowOptions: string[] = [];
  private dialogHref: string;

  constructor(

    private readonly beinformedService: BeinformedService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    @Inject(FORM_LOOKUP_TOKEN) private readonly formLookupComponent: ComponentType<FormLookupComponent>
  ) {}

  public ngAfterViewInit(): void {
    this.fetchData(this.href);
  }

  private fetchData(href: string) {
    this.loading = true;

    if (!!this.data?.length) {
      setTimeout(() => {
        this.columnsToDisplay.push(...Object.keys(this.data[0]));
        this.dataSource = this.data;
        this.loading = false;
      });
      return;
    }
    this.beinformedService.fetchForm(href).subscribe({
      next: res => {
        this.title = res.contributions.label;
        const filterUrl = res.data.error.formresponse.missing?.anchors[0].elements.find(el => el.elementid === 'Href');
        if (!!filterUrl) this.dialogHref = filterUrl.suggestion;
        const element = res.data.error.formresponse.missing?.anchors[0].elements.find(el => el.elementid === 'JSON');
        if (!!element?.suggestion) {
          const json = JSON.parse(element.suggestion) as MatrixJsonData[];
          this.extractMatrixData(json);
          this.loading = false;
        }
      }
    });
  }

  private extractMatrixData(json: MatrixJsonData[]): Record<string, any>[] {
    const columns = json.map(obj => obj.COLS);
    const uniqueCols = [...new Set(columns)];
    this.columnsToDisplay.push('Name');
    this.columnsToDisplay.push(...new Set(columns));
    const rows: Record<string, any>[] = [];
    json.forEach(obj => {
      const seen = rows.find(x => x['Name'] === obj.ROWS);
      if (!seen) {
        const row: Record<string, any> = {};
        row['Name'] = obj.ROWS;
        uniqueCols.forEach(col => {
          const value = json.find(x => x.COLS === col && x.ROWS === obj.ROWS)?.VALUE;
          const id = json.find(x => x.COLS === col && x.ROWS === obj.ROWS)?.OBJECTID;
          const hover = json.find(x => x.COLS === col && x.ROWS === obj.ROWS)?.HOVER?.split('<br>');
          row[col] = {
            value: value,
            id: id,
            hover: !hover ? undefined : {date: hover[0], name: hover[1], description: hover[2]}
          };
        });
        rows.push(row);
      }
    });
    this.rowOptions = rows.map(row => row['Name']);
    this.allData = rows;
    this.setSelection(this.matrixSelection);
    return rows;
  }

  public navigateTo(href: string) {
    this.closeParent.emit(true);
    void this.router.navigateByUrl(href);
  }

  public openElementDialog(element: any) {
    if (!!element.id) {
      const index = this.dialogHref.lastIndexOf('=');
      const baseHref = this.dialogHref.slice(0, index);
      const href = `${baseHref}=${element.id}`;
      this.dialog.open(this.formLookupComponent, {
        panelClass: 'naris-advanced-lookup-dialog',
        minWidth: '54rem',
        data: {endpoint: href, multiple: false, enableRowClick: false}
      }).afterClosed().subscribe(val => {
        if (!!val?._links?.self.href) {
          void this.router.navigate([val._links.self.href]);
        }
      });
    }
  }

  public hoverIsOpen = false;
  public highlightColumn(column: string, element: any) {
    if (element.value !== undefined) {
      this.hoverIsOpen = true;
      this.hoverData = {
        id: element.id,
        date: element.hover.date || '',
        name: element.hover.name || '',
        description: element.hover.description || ''
      };
    }
    const elements = document.getElementsByClassName('column-'+column.replaceAll(' ', ''));
    for (const arrayElement of Array.from(elements)) {
      const el = arrayElement as HTMLElement;
      el.style.backgroundColor = '#f7f7f7';
    }
  }

  public focusColumn(column: string) {
    const elements = document.getElementsByClassName('column-'+column.replaceAll(' ', ''));
    for (const arrayElement of Array.from(elements)) {
      const el = arrayElement as HTMLElement;
      el.style.backgroundColor = '#f7f7f7';
    }
  }

  public removeHighlight(column: string) {
    this.hoverIsOpen = false;
    this.hoverData = undefined;
    const elements = document.getElementsByClassName('column-'+column.replaceAll(' ', ''));
    for (const arrayElement of Array.from(elements)) {
      const el = arrayElement as HTMLElement;
      el.style.backgroundColor = '#fff';
    }
  }

  public blurHighlight(column: string) {
    const elements = document.getElementsByClassName('column-'+column.replaceAll(' ', ''));
    for (const arrayElement of Array.from(elements)) {
      const el = arrayElement as HTMLElement;
      el.style.backgroundColor = '#fff';
    }
  }

  public setSelection(change: string[]) {
    this.selectionChanged.emit(change);
    if (change.length > 0) this.dataSource = this.allData.filter(row => change.includes(row['Name']));
    else this.dataSource = this.allData;
  }

}
