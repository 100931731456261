import {AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import { AngularRenderOptions, AngularViewOptions, BrowserUIAngular, SurfaceComponent, jsPlumbToolkitModule } from '@jsplumbtoolkit/browser-ui-angular';
import {
  AbsoluteLayout,
  AnchorLocations,
  BlankEndpoint,
  Edge,
  EVENT_TAP,
  Surface,
  VertexMovedParams,
  EVENT_NODE_MOVE_END,
  EVENT_NODE_MOVE,
  SelectionModes,
  EVENT_NODE_MOVE_START,
  EVENT_EDGE_ADDED,
  Node,
  EVENT_CANVAS_CLICK,
  EdgeAddedParams,
  Vertex,
  OrthogonalConnector,
  initializeOrthogonalConnectorEditors
} from '@jsplumbtoolkit/browser-ui';
import { StrategyMapService } from '@core/services/strategy-map.service';
import { BeinformedService, CoreService, FormService, HttpService, SnackbarService } from '@core/services';
import { IAction, IPostableObject } from '@core/models';
import { Subscription } from 'rxjs';
import { MatTooltip } from '@angular/material/tooltip';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../elements/icon/icon.component';
import { ButtonComponent } from '../../elements/button/button.component';
import { BouncerComponent } from '../bouncer/bouncer.component';
import {GOAL, KSF, SELECTABLE} from './constants';
import { NodeComponent } from './components/node/node.component';
import { InspectorComponent } from './components/inspector/inspector.component';
import edgeMappings from './components/functions/edge-mappings';

interface IStrategyLane {
  id: number;
  name: string;
  goal: boolean;
  actions?: any[];
  children?: any[];
}

@Component({
  selector: 'naris-process-editor',
  templateUrl: './strategy-map.component.html',
  styleUrls: ['./strategy-map.component.scss'],
  standalone: true,
  imports: [IconComponent, MatTooltip, jsPlumbToolkitModule, NgClass, ButtonComponent, InspectorComponent, BouncerComponent, TranslateModule]
})
export class StrategyMapComponent implements AfterViewInit, OnDestroy {

  @ViewChild(SurfaceComponent)
  public surfaceComponent!: SurfaceComponent;

  @ViewChild('poolContainer')
  public poolContainer: ElementRef<HTMLDivElement>;

  @ViewChild('dragPreview')
  public dragPreview: ElementRef<HTMLDivElement>;

  @ViewChild('deleteEdge')
  public deleteEdge: ElementRef<HTMLDivElement>;

  @ViewChild(InspectorComponent)
  public inspector: InspectorComponent;

  @Input() public endpoint: string;

  public toolkit!: BrowserUIAngular;
  public surface!: Surface;
  public loading = false;
  public addGoalAction: string;
  public dragging = false;
  public lanes: IStrategyLane[] = [];
  public vision: string;
  private edgeToBeDeleted: Edge;
  private readonly deleteIds: string[] = [];
  private readonly subs: Subscription[] = [];

  constructor(
    public readonly strategyMapService: StrategyMapService,
    private readonly beinformedService: BeinformedService,
    private readonly formService: FormService,
    private readonly httpService: HttpService,
    private readonly snackbarService: SnackbarService,
    private readonly coreService: CoreService
  ) { }

  public ngAfterViewInit() {
    setTimeout(async () => {
      initializeOrthogonalConnectorEditors();
      this.surface = this.surfaceComponent.surface;
      this.toolkit = this.surfaceComponent.toolkit;
      this.strategyMapService.bounds = {vw: this.poolContainer.nativeElement.scrollWidth};
      await this.initialize();
      this.subs.push(
        this.strategyMapService.addNode$.subscribe(data => this.addNode(data)),
        this.strategyMapService.refreshMap$.subscribe(() => this.initialize()),
        this.strategyMapService.configClicked$.subscribe(data => {
          const node = this.toolkit.getNode(data.obj.id);
          const top = parseInt(data.el.style.top.split('px')[0]);
          const left = parseInt(data.el.style.left.split('px')[0]);
          let newLeft = left + data.el.offsetWidth + 10;
          const inspectorWidth = (this.inspector.el.nativeElement.offsetWidth as number) === 0 ? 240 : (this.inspector.el.nativeElement.offsetWidth as number);
          if (newLeft + inspectorWidth > this.poolContainer.nativeElement.scrollWidth) newLeft = left - inspectorWidth - 10;
          this.inspector.el.nativeElement.style.transform = `translate3d(${newLeft}px, ${top}px, 0px)`;
          this.inspector.el.nativeElement.style.display = 'block';
          this.toolkit.setSelection(node.id);
        })
      );
    });
  }

  public ngOnDestroy(): void {
    this.toolkit.clear();
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private initialize(): Promise<void> {
    if (this.lanes.length > 0) {
      this.lanes = [];
      this.toolkit.clear();
    }
    this.loading = true;
    return new Promise(resolve => {
      this.beinformedService.fetchResponseWithContributions<'caselist'>(this.endpoint).subscribe({
        next: res => {
          const results = this.beinformedService.extractResults(res);
          this.vision = this.strategyMapService.strategyVision || '';
          this.addGoalAction = res.data.actions?.find(action => action.name === 'add-goal')?.href || '';
          results?.forEach((result: any, index) => {
            const children = results.filter((_perspective: any) => !_perspective['IsParent'] && parseInt(_perspective['ParentID']) === result['_id']);
            if (result['IsParent'])
              this.lanes.push({
                id: result['_id'],
                name: result['Name'],
                goal: index === 0,
                actions: result['actions'],
                children: children
              });
          });
          this.drawNodes();
          this.loading = false;
          resolve();
        }
      });
    });
  }

  private drawNodes() {
    // draw all nodes
    this.lanes.forEach((lane, index) => {
      lane.children?.forEach(child => {
        const actions = child['actions'] as any[];
        const position = JSON.parse(child['Position']) as { x: number; y: number };
        const connectGoalAction = actions?.find((action: any) => action.name === 'add-critical-success-factor-goal');
        const connectKsfAction = actions?.find((action: any) => action.name === 'add-critical-success-factor-critical-success-factor');
        const connectActions = { goal: connectGoalAction, ksf: connectKsfAction };
        this.toolkit.addNode({
          nodeId: child['_id'],
          type: index === 0 ? GOAL : KSF,
          name: child['Name'],
          description: child['Description'],
          risks: ['Risico 1', 'Risico 2'],
          actions: child['actions'] as IAction[],
          objectHref: child['_links']['self']['href'],
          connectActions: connectActions,
          connectedObjects: child['Available'],
          objectId: child['GoalID'] || child['CriticalSuccessFactorID'],
          top: !!position ? position.y : 0,
          left: !!position ? position.x : 0,
          isLast: index === this.lanes.length - 1,
          keyPerformanceIndicators: !!child['KeyPerformanceIndicator'] ? parseInt(child['KeyPerformanceIndicator']) : 0
        });
      });
    });

    // draw edges after all nodes are rendered
    this.lanes.forEach(lane => {
      lane.children?.forEach(child => {
        const connections: string[] = [];
        if (!!child['RelatedToGoalIDs']) child['RelatedToGoalIDs'].split(',').forEach((connection: string) => connections.push(connection));
        if (!!child['RelatedCriticalSuccessFactorIDs']) child['RelatedCriticalSuccessFactorIDs'].split(',').forEach((connection: string) => connections.push(connection));
        this.drawEdges(child['_id'], connections);
      });
    });
  }

  private drawEdges(sourceId: any, connections: string[]) {
    const nodes = this.toolkit.getNodes();
    const source = nodes.find(node => node.data.nodeId === sourceId);
    if (!!source) {
      connections.forEach(connection => {
        const connectionId = parseInt(connection);
        const target = nodes.find(node => node.data.objectId === connectionId);
        if (!!target) {
          const connectionType = target.type === 'goal' ? 'goal-connection' : 'ksf-connection';
          this.toolkit.addEdge({source, target, data: {lineStyle: connectionType}});
        }
      });
    }
  }

  private redrawEdges(node: Node) {
    const edges = this.toolkit.getEdges({source: node, target: node});
    edges.forEach(edge => {
      this.toolkit.removeEdge(edge);
      const connectionType = edge.target.type === 'goal' ? 'goal-connection' : 'ksf-connection'; 
      this.toolkit.addEdge({source: edge.source, target: edge.target, data: {lineStyle: connectionType}});
    });
  }

  private redrawEdge(edge: Edge): Edge {
    this.toolkit.removeEdge(edge);
    const connectionType = edge.target.type === 'goal' ? 'goal-connection' : 'ksf-connection'; 
    return this.toolkit.addEdge({source: edge.source, target: edge.target, data: {lineStyle: connectionType}});
  }

  public toolkitParams = {
    selectionMode: SelectionModes.isolated,
    portDataProperty: 'choices'
  };

  public renderParams: AngularRenderOptions = {
    zoom: 0.1,
    zoomToFit: false,
    zoomRange: [1, 1],
    consumeRightClick: false,
    enablePan: false,
    defaults: {
      endpoint: BlankEndpoint.type,
      anchor: AnchorLocations.AutoDefault
    },
    propertyMappings: {
      edgeMappings: edgeMappings()
    },
    layout: {
      type: AbsoluteLayout.type,
      options: {}
    },
    wheel: {
      zoom: false
    },
    dragOptions: {
      constrainFunction: (desiredLoc, dragEl, constrainRect, size) => {
        let xPos = desiredLoc.x;
        if (xPos + size.w > constrainRect.w - 45) xPos = constrainRect.w - 45 - size.w;
        else if (xPos < 84) xPos = 84;

        let yPos = desiredLoc.y;
        if (yPos + size.h > constrainRect.h) yPos = constrainRect.h - size.h;
        else if (yPos < 0) yPos = 0;

        // constrain the y position based on type
        const nodeId = dragEl.getAttribute('data-jtk-vertex');
        if (!!nodeId) {
          const node = this.toolkit.getNode(nodeId);
          if (node.type === 'goal') yPos = 0;
          else if (node.type === 'ksf') {
            if (desiredLoc.y < 168) yPos = 168;
          }
        }

        return {x: xPos, y: yPos};
      }
    },
    events: {
      [EVENT_NODE_MOVE]: (e: VertexMovedParams<any>) => {           
        this.deleteEdge.nativeElement.style.visibility = 'hidden';
        this.dragging = true;
        const dragPreview = document.getElementById('dragPreview');
        if (!!dragPreview) {
          const yPos = Math.round(e.pos.y / 168) * 168 + 23;
          const xPos = Math.round(e.pos.x / 84) * 84;
          dragPreview.style.display = 'block';
          dragPreview.style.top = `${yPos}px`;
          dragPreview.style.left = `${xPos}px`;
        }
      },
      [EVENT_NODE_MOVE_START]: () => {           
        this.deleteEdge.nativeElement.style.visibility = 'hidden';
        this.inspector.el.nativeElement.style.display = 'none';
      },
      [EVENT_NODE_MOVE_END]: (e: VertexMovedParams<any>) => {
        this.redrawEdges(e.vertex);
        if (this.dragging) {
          this.dragging = false;
          const yPos = Math.round(e.pos.y / 168) * 168;
          const xPos = Math.round(e.pos.x / 84) * 84;
          const parentId = e.vertex.type === 'ksf' ? this.lanes[Math.round(e.pos.y / 168)].id : null;
          const moveAction = e.vertex.data.actions.find((action: IAction) => action.name === 'update-position') as IAction;
          if (!!moveAction) {
            this.updatePosition(JSON.stringify({x: xPos, y: yPos}), moveAction, parentId);
            e.renderer.setPosition(e.vertex, xPos, yPos);
          }
        }
        const node = e.vertex;
        this.highlightEdges(node, node.edges);
      },
      [EVENT_EDGE_ADDED]: (e: EdgeAddedParams) => {           
        this.deleteEdge.nativeElement.style.visibility = 'hidden';
        if (e.addedByMouse) {
          if (e.edge.source.type === 'goal' || e.edge.target.id === e.edge.source.id) this.toolkit.removeEdge(e.edge);
          else {
            const action = e.edge.target.type === 'goal' ? e.edge.source.data.connectActions.goal : e.edge.source.data.connectActions.ksf;
            const newEdge = this.redrawEdge(e.edge);
            this.saveConnection(action.href, newEdge.target);
          }
        }
      },
      [EVENT_CANVAS_CLICK]: () => {
        this.toolkit.clearSelection();
        this.deleteEdge.nativeElement.style.visibility = 'hidden';
        this.clearAllEdges();
      }
    }
  };

  public highlightedEdge: string;
  public view: AngularViewOptions = {
    nodes: {
      [GOAL]: {
        parent: SELECTABLE,
        component: NodeComponent
      },
      [KSF]: {
        parent: SELECTABLE,
        component: NodeComponent,        
        events: {
          [EVENT_TAP]: (p: any) => {
            this.deleteEdge.nativeElement.style.visibility = 'hidden';
            const node = p.obj;
            this.highlightEdges(node, node.edges);
          }
        }
      },
      default: {
      }
    },
    edges: {
      default: {
        connector: {
          type: OrthogonalConnector.type,
          options: {
            cornerRadius: 5,
            alwaysRespectStubs: false
          } 
        },
        label: '{{label}}',
        useHTMLLabel: true,
        events: {
          [EVENT_TAP]: (p: { e: MouseEvent; edge: Edge }) => {
            this.highlightEdge(p.edge);
            this.toolkit.setSelection(p.edge);
            const node = this.toolkit.getNode(p.edge.source.id);
            const sourceEdges = node.edges.filter(edge => edge.source.id === p.edge.source.id);            
            this.deleteEdge.nativeElement.style.visibility = 'hidden';
            if (sourceEdges.length > 1) {
              this.edgeToBeDeleted = p.edge;
              const menuWidth = this.coreService.sidebarCollapsed ? 96 : 240;
              const top = 170;
              this.deleteEdge.nativeElement.style.visibility = 'visible';
              this.deleteEdge.nativeElement.style.left = `${p.e.clientX - menuWidth}px`;
              this.deleteEdge.nativeElement.style.top = `${p.e.clientY - top - 20}px`;
            }
          }
        }
      }
    },
    ports: {
      choice: {
        anchor: [AnchorLocations.Left, AnchorLocations.Right ]
      }
    }
  };

  private highlightEdges(node: Node, edges: Edge[]) {
    this.clearAllEdges();
    
    edges.forEach(edge => this.highlightEdge(edge, false));
  }

  private highlightEdge(edge: Edge, clearOtherEdges = true) {
    if (!!this.highlightedEdge && clearOtherEdges)
      this.clearAllEdges();
    if (this.highlightedEdge !== edge.id || !clearOtherEdges) {
      this.toolkit.updateEdge(edge.id, {
        outlineColor: '#fced97',
        outlineWidth: 2
      });
      if (clearOtherEdges)
        this.highlightedEdge = edge.id;
    } else if (this.highlightedEdge === edge.id && clearOtherEdges) this.highlightedEdge = '';
  }

  private clearAllEdges() {
    this.toolkit.getAllEdges().forEach(edge => this.clearEdgeHighlight(edge));
  }

  private clearEdgeHighlight(edge: Edge) {
    if (edge.data.lineStyle === 'goal-connection')
      this.toolkit.updateEdge(edge.id, {
        outlineColor: 'none',
        outlineWidth: 10
      });
    if (edge.data.lineStyle === 'ksf-connection')
      this.toolkit.updateEdge(edge.id, {
        outlineColor: 'none',
        outlineWidth: 10
      });
  }

  private deleteAllowed(source: Node, target?: Node): boolean {
    this.deleteIds.push(source.id);
    let returnValue = false;
    let outboundEdges: Edge[] = [];
    if (!!target) outboundEdges = source.edges.filter(e => e.source.id === source.id && !this.deleteIds.includes(e.target.id) && e.target.id !== target.id);
    else outboundEdges = source.edges.filter(e => e.source.id === source.id && !this.deleteIds.includes(e.target.id));
    if (outboundEdges.length === 0) returnValue = false;
    outboundEdges.forEach(edge => {
      if (edge.target.type === 'goal') {
        returnValue = true;
        return;
      } else returnValue = this.deleteAllowed(edge.target as Node);
    });
    return returnValue;
  }

  public addGoal() {
    const position = this.getGoalPosition();
    this.strategyMapService.nodePosition = JSON.stringify({x: position, y: 0});
    this.strategyMapService.nodeRelation = undefined;
    this.formService.open(this.addGoalAction).subscribe(saved => {
      if (!!saved) {
        this.strategyMapService.refreshTables$.next();
        void this.initialize();
      }
    });
  }

  private getGoalPosition() {
    const nodes = this.toolkit.getNodes();
    const goals = nodes.filter(node => node.type === 'goal');
    const xPositions = goals.map(goal => goal.data.left);

    let hasPos = false;
    let widgetPos = 1;
    let newPos = 0;

    while (!hasPos) {
      newPos = widgetPos * 84;
      if (xPositions.includes(newPos)) widgetPos ++;
      else hasPos = true;
    }

    return newPos;
  }

  public addNode(data: { parentId: string; position: string }) {
    const parent = this.toolkit.getNode(data.parentId);
    // determine new node position
    let xPos = parent.data.left as number;
    let yPos = parent.data.top as number;

    if (data.position === 'right') xPos += 252;
    else if (data.position === 'bottom') yPos += 168;
    else if (data.position === 'left') xPos -= 252;
    this.strategyMapService.nodePosition = JSON.stringify({x: xPos, y: yPos});
    this.strategyMapService.nodeRelation = {type: parent.type, id: parent.data.objectId};
    const laneIdx = Math.round(yPos / 168);
    const lane = this.lanes[laneIdx];
    const addAction = lane.actions?.find(action => action.name === 'add-critical-success-factor');
    if (!!addAction) {
      this.subs.push(
        this.formService.open(addAction.href).subscribe(saved => {
          if (!!saved) {
            this.strategyMapService.refreshTables$.next();
            void this.initialize();
          }
        })
      );
    }
  }

  public updatePosition(pos: string, action: IAction, parentId: number | null) {
    this.httpService.post(action.href!, {Position: pos, ParentID: parentId}).subscribe();
  }

  public saveConnection(href: string, parent: Node | Vertex) {
    this.beinformedService.fetchForm(href).subscribe({
      next: res => {
        const tokens = res.data.error?.formresponse.tokens;
        const objects: Record<string, any> = {};
        const values: Record<string, any> = {};
        const valueKey = res.data.error.formresponse?.missing?.anchors[0].elements[0].elementid || '';
        const objectKey = res.data.error.formresponse?.missing?.anchors[0].objectid || '';
        values[valueKey] = parent.type === 'goal' ? parent.data.objectId : [parent.data.nodeId];
        objects[objectKey] = values;
        const postableObject: IPostableObject = {objects: objects, tokens: tokens};
        this.beinformedService.fetchForm(href, true, postableObject).subscribe();
      }
    });
  }

  public doDeleteEdge() {
    const source = this.toolkit.getNode(this.edgeToBeDeleted.source.id);
    const target = this.toolkit.getNode(this.edgeToBeDeleted.target.id);
    const allowed = target.type === 'goal' ? this.deleteAllowed(source, target) : true;
    if (allowed) {
      const actionName = target.type === 'goal' ? 'detach-critical-success-factor-goal' : 'detach-critical-success-factor-critical-success-factor';
      const detachAction = source.data.actions.find((action: any) => action.name === actionName);
      this.beinformedService.fetchForm(detachAction.href).subscribe({
        next: res => {
          const tokens = res.data.error?.formresponse.tokens;
          const objects: Record<string, any> = {};
          const values: Record<string, any> = {};
          const valueKey = res.data.error.formresponse.missing?.anchors[0].elements[0].elementid || '';
          const objectKey = res.data.error.formresponse.missing?.anchors[0].objectid || '';
          values[valueKey] = target.data.objectId;
          objects[objectKey] = values;
          const postableObject: IPostableObject = {objects: objects, tokens: tokens};
          this.beinformedService.fetchForm(detachAction.href, true, postableObject).subscribe({
            next: _res => {
              if (!!_res.data.formresponse?.success) {
                this.deleteEdge.nativeElement.style.visibility = 'hidden';
                this.toolkit.removeEdge(this.edgeToBeDeleted.id);
              }
            }
          });
        }
      });
    } else {
      this.snackbarService.open({text: 'strategy_map.no_alternate_goal', type: 'error'});
    } 
  }
}