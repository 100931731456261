import { createClass as t, classCallCheck as n, defineProperty as i } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { EVENT_GRAPH_CLEARED as e, EVENT_NODE_ADDED as o, EVENT_NODE_REMOVED as r, EVENT_NODE_UPDATED as a, EVENT_GROUP_UPDATED as s, EVENT_GROUP_SIZE_CHANGED as c, EVENT_EDGE_ADDED as u, EVENT_EDGE_REMOVED as d, EVENT_EDGE_UPDATED as l, EVENT_EDGE_PATH_EDITED as h, EVENT_PORT_ADDED as f, EVENT_PORT_REMOVED as m, EVENT_PORT_UPDATED as p, EVENT_NODE_MOVE_END as k, EVENT_GROUP_MOVE_END as v, EVENT_EDGE_PATH_RESTORED as g } from "../constants.js";
import { CompoundAction as S } from "./compound-action.js";
import { MoveAction as b } from "./move-action.js";
import { TerminusAddAction as w, TerminusRemoveAction as y, GroupRemoveAction as T, GroupSizeChangedAction as x } from "./node-group-action.js";
import { UpdateAction as _ } from "./update-action.js";
import { EdgeAddAction as C, EdgeRemoveAction as j, EdgeAction as z } from "./edge-action.js";
import { PortAddAction as D, PortRemoveAction as E } from "./port-action.js";
import { AddGroupMemberAction as G, RemoveGroupMemberAction as P, GroupCollapseAction as U, GroupExpandAction as A } from "./group-action.js";
import { ToolkitRendererVertexRemovedAction as L } from "./renderer-vertex-removed-action.js";
import { isEmpty as N, log as I, objectsEqual as B } from "../../ui-core/util/util.js";
import { EVENT_GROUP_ADDED as H, EVENT_GROUP_REMOVED as q, EVENT_GROUP_MEMBER_ADDED as F, EVENT_GROUP_MEMBER_REMOVED as J, EVENT_GROUP_COLLAPSE as K, EVENT_GROUP_EXPAND as M } from "../../ui-core/core/event-constants.js";
var O = function () {
  function i(t, e, o, r, a) {
    n(this, i);
    this.toolkit = t;
    this.edge = e;
    this.originalGeometry = o;
    this.geometry = r;
    this.renderer = a;
  }
  t(i, [{
    key: "_apply",
    value: function t(n) {
      this.toolkit.fire(g, {
        edge: this.edge,
        geometry: n,
        renderer: this.renderer
      });
    }
  }, {
    key: "undo",
    value: function t() {
      this._apply(this.originalGeometry);
    }
  }, {
    key: "redo",
    value: function t() {
      this._apply(this.geometry);
    }
  }, {
    key: "hasDeltas",
    value: function t() {
      return !B(this.originalGeometry, this.geometry);
    }
  }]);
  return i;
}();
var Q = 50;
var R = "Cannot start a new transaction while one is active. Either commit/rollback the current transaction before creating a new one, or pass a cleanup flag in to this method";
var V = "Cannot rollback transaction from here; it has been appended to. Close all transaction appenders before attempting to rollback the transaction.";
var W = function () {
  function g(t) {
    n(this, g);
    i(this, "toolkit", void 0);
    i(this, "maximumSize", void 0);
    i(this, "suspend", void 0);
    i(this, "onChange", void 0);
    i(this, "undoStack", void 0);
    i(this, "redoStack", void 0);
    i(this, "currentTransaction", void 0);
    i(this, "appendStack", 0);
    this.toolkit = t.toolkit;
    this.suspend = false;
    this.undoStack = [];
    this.redoStack = [];
    this.maximumSize = t.maximumSize || Q;
    this.onChange = t.onChange;
    this._bindListeners();
  }
  t(g, [{
    key: "_setSuspended",
    value: function t(n) {
      this.suspend = n;
    }
  }, {
    key: "dataLoadStart",
    value: function t() {
      this.clear();
      this._setSuspended(true);
    }
  }, {
    key: "dataLoadEnd",
    value: function t() {
      this._setSuspended(false);
    }
  }, {
    key: "_bindListeners",
    value: function t() {
      var n = this;
      this.toolkit.bind(e, function () {
        n.clear();
      });
      this.toolkit.bind(o, function (t) {
        n.command(new w(t.node, n.toolkit));
      });
      this.toolkit.bind(r, function (t) {
        n.command(new y(t.node, n.toolkit));
      });
      this.toolkit.bind(a, function (t) {
        if (!N(t.updates)) {
          n.command(new _(t.vertex, t.originalData, n.toolkit));
        }
      });
      this.toolkit.bind(H, function (t) {
        n.command(new w(t.group, n.toolkit));
      });
      this.toolkit.bind(q, function (t) {
        n.command(new T(t, n.toolkit));
      });
      this.toolkit.bind(s, function (t) {
        if (!N(t.updates)) {
          n.command(new _(t.vertex, t.originalData, n.toolkit));
        }
      });
      this.toolkit.bind(c, function (t) {
        var i = new x(t.group, t.originalGroupSize, t.newGroupSize, n.toolkit, t.source);
        if (i.hasDeltas()) {
          n.command(i, true);
        }
      });
      this.toolkit.bind(u, function (t) {
        n.command(new C(t.edge, n.toolkit, n));
      });
      this.toolkit.bind(d, function (t) {
        n.command(new j(t.edge, n.toolkit, n));
      });
      this.toolkit.bind(l, function (t) {
        if (!N(t.updates)) {
          n.command(new _(t.edge, t.originalData, n.toolkit));
        }
      });
      this.toolkit.bind(h, function (t) {
        n.command(new O(n.toolkit, t.edge, t.originalGeometry, t.geometry, t.renderer));
      });
      this.toolkit.bind(f, function (t) {
        n.command(new D(t.port, t.vertex, n.toolkit));
      });
      this.toolkit.bind(m, function (t) {
        n.command(new E(t.port, t.vertex, n.toolkit));
      });
      this.toolkit.bind(p, function (t) {
        if (!N(t.updates)) {
          n.command(new _(t.port, t.originalData, n.toolkit));
        }
      });
      this.toolkit.bind(F, function (t) {
        if (t.vertexIsNew !== true) {
          n.command(new G(t.vertex, t.group, n.toolkit));
        }
      });
      this.toolkit.bind(J, function (t) {
        n.command(new P(t.vertex, t.group, n.toolkit));
      });
      this.toolkit.bind("renderer:added", function (t) {
        t.renderer.bind(k, function (t) {
          if (t.originalPosition != null) {
            n.command(new b(t.vertex, t.originalPosition, t.pos, t.renderer));
          }
        });
        t.renderer.bind(v, function (t) {
          if (t.originalPosition != null) {
            n.command(new b(t.vertex, t.originalPosition, t.pos, t.renderer));
          }
        });
        t.renderer.bind(K, function (t) {
          n.command(new U(t.group, t.renderer));
        });
        t.renderer.bind(M, function (t) {
          n.command(new A(t.group, t.renderer));
        });
        t.renderer.bind(r, function (i) {
          n.command(new L(t.renderer, i.vertex, i.pos));
        });
        t.renderer.bind(q, function (i) {
          n.command(new L(t.renderer, i.vertex, i.pos));
        });
      });
    }
  }, {
    key: "_fireUpdate",
    value: function t() {
      this.onChange && this.onChange(this, this.undoStack.length, this.redoStack.length);
    }
  }, {
    key: "command",
    value: function t(n, i) {
      if (!this.suspend) {
        if (!n.hasDeltas()) {
          return;
        }
        if ($(n)) {
          n = n.compress();
        }
        if (this.currentTransaction != null) {
          this.currentTransaction.addAction(n, i);
        } else {
          this.undoStack.push(n);
          if (this.undoStack.length > this.maximumSize) {
            this.undoStack.splice(this.undoStack.length - this.maximumSize - 1, this.undoStack.length - this.maximumSize);
          }
          this.redoStack.length = 0;
          this._fireUpdate();
        }
      }
    }
  }, {
    key: "edgeChange",
    value: function t(n, i) {
      var e = function t(e) {
        e.forEach(function (t) {
          if (t instanceof z && t.edgeId === n) {
            t.edgeChange(i);
          } else if (t instanceof S) {
            t.edgeChange(n, i);
          }
        });
      };
      e(this.undoStack);
      e(this.redoStack);
    }
  }, {
    key: "undo",
    value: function t() {
      var n = this.undoStack.pop();
      if (n) {
        this.suspend = true;
        this.redoStack.push(n);
        n.undo();
        this.suspend = false;
        this._fireUpdate();
      }
    }
  }, {
    key: "redo",
    value: function t() {
      var n = this.redoStack.pop();
      if (n) {
        this.suspend = true;
        this.undoStack.push(n);
        n.redo();
        this.suspend = false;
        this._fireUpdate();
      }
    }
  }, {
    key: "clear",
    value: function t() {
      this.undoStack.length = 0;
      this.redoStack.length = 0;
      this.currentTransaction = null;
      this.appendStack = 0;
      this.suspend = false;
      this._fireUpdate();
    }
  }, {
    key: "transaction",
    value: function t(n, i) {
      var e = this.currentTransaction != null;
      if (e && i == null) {
        I(R);
        return;
      }
      this.openTransaction(i);
      try {
        var o = n();
        if (o !== false) {
          var r = this.currentTransaction;
          this.currentTransaction = null;
          this.command(r);
          return o;
        } else {
          this.rollbackTransaction();
        }
      } catch (t) {
        I("Exception thrown in transaction " + t.message + "; rolling transaction back");
        this.rollbackTransaction();
      }
    }
  }, {
    key: "_createNewTransaction",
    value: function t() {
      this.currentTransaction = new S();
      this.appendStack = 0;
    }
  }, {
    key: "openTransaction",
    value: function t(n) {
      if (!this.suspend) {
        var i = true;
        if (this.currentTransaction != null) {
          if (n != null) {
            if (n === X) {
              this.commitTransaction();
            } else if (n === Y) {
              this.rollbackTransaction();
            } else if (n === Z) {
              i = false;
              this.appendStack++;
            } else {
              throw new Error(R);
            }
          } else {
            throw new Error(R);
          }
        }
        if (i) {
          this._createNewTransaction();
        }
        return i;
      }
    }
  }, {
    key: "rollbackTransaction",
    value: function t() {
      if (this.currentTransaction != null) {
        if (this.appendStack > 0) {
          this.appendStack--;
          I(V);
        } else {
          this.currentTransaction.undo();
          this.currentTransaction = null;
          this._fireUpdate();
        }
      }
    }
  }, {
    key: "commitTransaction",
    value: function t(n) {
      if (this.currentTransaction != null) {
        if (n !== true && this.appendStack > 0) {
          this.appendStack--;
        } else {
          var i = this.currentTransaction;
          this.currentTransaction = null;
          this.command(i);
          this.appendStack = 0;
        }
      }
    }
  }, {
    key: "_attach",
    value: function t(n) {
      if (this.undoStack.length === 0) {
        var i = new S();
        i.addAction(n);
        this.command(i);
      } else {
        var e = this.undoStack[this.undoStack.length - 1];
        if (e instanceof S) {
          e.addAction(n);
        } else {
          this.undoStack.pop();
          var o = new S();
          o.addAction(e);
          o.addAction(n);
          this.undoStack.push(o);
        }
      }
    }
  }]);
  return g;
}();
var X = "commitCurrent";
var Y = "rollbackCurrent";
var Z = "appendToCurrent";
function $(t) {
  return t instanceof S;
}
function tt(t) {
  return t instanceof _;
}
function nt(t) {
  return t instanceof b;
}
export { Z as APPEND_TO_CURRENT, X as COMMIT_CURRENT, Y as ROLLBACK_CURRENT, W as UndoRedoManager, $ as isCompoundAction, nt as isMoveAction, tt as isUpdateAction };