import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
  standalone: true
})
export class HighlightPipe implements PipeTransform {

  public transform(str: any, query: any): any {
    if (!query) return str;
    const re = new RegExp(query, 'gi');
    return str.replace(re, '<mark>$&</mark>');
  }

}
