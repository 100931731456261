export const GOAL = 'goal';
export const KSF = 'ksf';
export const SELECTABLE = 'selectable';

export const PROPERTY_MESSAGE = 'message';
export const PROPERTY_LABEL = 'label';
export const PROPERTY_PROMPT = 'prompt';

export const GROUP_HEIGHT = 144;
export const GROUP_SPACING = 8;

export const NODE_HEIGHT = 110;