<div class="graph-matrix">
  @if (showTitle) {
    <div class="graph-matrix__header">
      <h3 class="graph-matrix__header__title">{{title}}</h3>
      <mat-form-field>
        <mat-select 
          [value]="matrixSelection"
          (selectionChange)="setSelection($event.value)"
          multiple
        >
          @for(option of rowOptions; track $index) {
            <mat-option [value]="option">{{option}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  }
  <div class="graph-matrix__table__wrapper">
    @if (!loading) {
      <table
        mat-table
        class="graph-matrix__table"
        [dataSource]="dataSource"
      >
        @for (column of columnsToDisplay; track $index) {
          <ng-container [matColumnDef]="column" [sticky]="column === 'Name' && firstColumnSticky">
            <th 
              *matHeaderCellDef
              mat-header-cell 
              class="graph-matrix__table__th"
              [ngClass]="{'name-column': column === 'Name' && firstColumnSticky}"
            >{{column === 'color' ? '' : column | translate}}</th>
            <td 
              *matCellDef="let element"
              mat-cell
              [class]="'graph-matrix__table__td column-' + column.replaceAll(' ', '')"
              [ngClass]="{
                'name-column': column === 'Name' && firstColumnSticky,
                'left-align': !firstColumnSticky,
                'clickable': isDashboard && (element[column].value === 0 || element[column].value === 1)
              }"
              (click)="openElementDialog(element[column])"
              (mouseover)="highlightColumn(column, element[column])"
              (mouseout)="removeHighlight(column)"
              (focus)="focusColumn(column)"
              (blur)="blurHighlight(column)"
            >
              <span
                cdkOverlayOrigin
                #hoverTrigger="cdkOverlayOrigin"
              >
                @if (column === 'Name') {
                  {{element[column]}}
                } @else if (column === 'color') {
                  <div class="indicator" [style.background-color]="element[column]"></div>
                } @else if (column === 'GoTo') {
                  <naris-icon class="goto-icon" icon="go-to" iconClass="small" (click)="navigateTo(element[column])"/>
                } @else {
                  @switch (element[column].value) {
                    @case (1) {
                      <naris-icon icon="check_circle" iconClass="green" />
                    }
                    @case (0) {
                      <naris-icon icon="close" iconClass="warn" />
                    }
                    @default {
                      {{element[column].value}}
                    }
                  }
                }
              </span>
              @if (!!hoverData && hoverData.id === element[column].id) {
                <ng-template
                  cdkConnectedOverlay
                  [cdkConnectedOverlayOrigin]="hoverTrigger"
                  [cdkConnectedOverlayOpen]="hoverIsOpen"
                >
                  <div class="graph-matrix__hover">
                    @if (!!hoverData.name) {
                      <span class="graph-matrix__hover__field">{{'matrix.hover-name' | translate}}:</span> {{hoverData.name}}
                    }
                    @if (!!hoverData.date) {
                      <span class="graph-matrix__hover__field">{{'matrix.hover-date' | translate}}:</span> {{hoverData.date}}
                    }
                    @if (!!hoverData.description) {
                      <span class="graph-matrix__hover__field">{{'matrix.hover-description' | translate}}:</span> {{hoverData.description}}
                    }
                  </div>
                </ng-template>
              }
            </td>
          </ng-container> 
        }
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
      </table>
    }
  </div>
</div>
