import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CAPITAL_REGEX, EMAIL, FIRST_NAME, LAST_NAME, NUMBER_REGEX, PASSWORD, PASSWORD_REPEAT, SPECIAL_REGEX, TOKEN_INVALID, VALIDATION_REGS } from '@core/constants';
import { sameValidator, regexValidator, notPartlySameValidator } from '@core/helpers/custom-validators';
import { AuthService, HttpService, SnackbarService } from '@core/services';
import { testOriginal } from '@core/helpers';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CardComponent } from '../../../shared/components/card/card.component';
import { FormFieldComponent } from '../../../shared/components/form-field/form-field.component';
import { InputComponent } from '../../../shared/elements/input/input.component';
import { InputConditionComponent } from '../../../shared/elements/input-condition/input-condition.component';
import { FormActionsComponent } from '../../../shared/components/form-actions/form-actions.component';
import { ButtonComponent } from '../../../shared/elements/button/button.component';
import { ProgressSpinnerComponent } from '../../../shared/elements/progress-spinner/progress-spinner.component';

@Component({
  selector: 'app-signup-info',
  templateUrl: './signup-info.component.html',
  styleUrls: ['./signup-info.component.scss'],
  standalone: true,
  imports: [CardComponent, NgClass, FormsModule, ReactiveFormsModule, FormFieldComponent, InputComponent, InputConditionComponent, FormActionsComponent, RouterLink, ButtonComponent, ProgressSpinnerComponent, TranslateModule]
})
export class SignupInfoComponent implements OnInit, OnDestroy {

  public signupForm: FormGroup;
  public lengthOK: boolean;
  public numOK: boolean;
  public capsOK: boolean;
  public specialOK: boolean;
  public originalOK: boolean;
  public invalidToken: boolean;
  public loading = true;
  private subs: Subscription[];
  private readonly validators = [
    Validators.required,
    Validators.minLength(8),
    Validators.maxLength(50),
    regexValidator(VALIDATION_REGS)
  ];

  constructor(
    private readonly snackbar: SnackbarService,
    private readonly router: Router,
    private readonly httpService: HttpService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService
  ) {}

  public ngOnInit(): void {
    const token = this.activatedRoute.snapshot.queryParams['idToken'] || '';
    this.authService.checkToken(token).pipe(
      finalize(() => this.loading = false)
    ).subscribe(res => {
      if (res === TOKEN_INVALID) this.invalidToken = true;
      else {
        this.setInvited();
        this.signupForm = new FormGroup({
          [EMAIL]: new FormControl({value: this.authService.invitedEmail, disabled: true}, [Validators.required]),
          [FIRST_NAME]: new FormControl({value: this.authService.invitedFirstName, disabled: true}, [Validators.required]),
          [LAST_NAME]: new FormControl({value: this.authService.invitedLastName, disabled: true}, [Validators.required]),
          [PASSWORD]: new FormControl(''),
          [PASSWORD_REPEAT]: new FormControl('')
        });
        this.signupForm.get(PASSWORD)?.setValidators([...this.validators, notPartlySameValidator(this.signupForm.get(EMAIL), 5)]);
        this.signupForm.get(PASSWORD_REPEAT)?.setValidators([...this.validators, sameValidator(this.signupForm.get(PASSWORD))]);
        this.subs = [
          this.signupForm.get(PASSWORD)!.valueChanges.subscribe((val: string) => this.checkErrors(val))
        ];
        this.invalidToken = false;
      }
    });
  }

  public onSubmit = (): void => {
    const url = `/b2c/tasks/validate-user?idToken=${this.authService.idToken}`;
    const body = {
      tokens: null,
      objects: {
        Attributes: {
          FirstName: this.signupForm.get(FIRST_NAME)?.value,
          LastName: this.signupForm.get(LAST_NAME)?.value,
          NewPassword: this.signupForm.get(PASSWORD)?.value,
          RepeatPassword: this.signupForm.get(PASSWORD_REPEAT)?.value
        }
      }
    };
    this.httpService.post(url, body).subscribe(() => {
      this.resetInvited();
      this.snackbar.open({text: 'User successfully registered', type: 'success'});
      void this.router.navigate(['login']);
    });
  };

  public resetInvited() {
    this.authService.idToken = '';
    this.authService.invitedEmail = '';
    this.authService.invitedFirstName = '';
    this.authService.invitedLastName = '';
  }

  private setInvited() {
    this.authService.invitedEmail ??= this.activatedRoute.snapshot.queryParams['invitedEmail'] || '';
    this.authService.invitedFirstName ??= this.activatedRoute.snapshot.queryParams['invitedFirstName'] || '';
    this.authService.invitedLastName ??= this.activatedRoute.snapshot.queryParams['invitedLastName'] || '';
    this.authService.idToken ??= this.activatedRoute.snapshot.queryParams['idToken'] || '';
  }

  public ngOnDestroy(): void {
    this.subs?.forEach(sub => sub.unsubscribe());
  }

  private checkErrors(value: string) {
    this.lengthOK = value.length >= 8 && value.length <= 50;
    this.numOK = NUMBER_REGEX.test(value);
    this.capsOK = CAPITAL_REGEX.test(value);
    this.specialOK = SPECIAL_REGEX.test(value);
    this.originalOK = testOriginal(value, this.signupForm.get(EMAIL)?.value, 5);
  }

  public getFormControl(controlName: string) {
    return this.signupForm.get(controlName) as FormControl;
  }
}
