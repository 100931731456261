import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgClass } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '../../elements/icon/icon.component';

@Component({
  selector: 'naris-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  standalone: true,
  imports: [NgClass, IconComponent, MatTooltip, TranslateModule]
})
export class FormFieldComponent {
  @Input() public label: string;
  @Input() public control: FormControl;
  @Input() public tooltip?: string;
  @Input() public labelSuffix?: string;
  @Input() public error?: any[] | undefined;
  @Input() public fixStyling = false;
  @Input() public isProcessToolkitForm = false;

  constructor(private readonly router: Router) {}

  get errorString(): string {
    return this.error?.map(error => error.message).join(', ') ?? '';
  }

  get errorIds(): any[] | undefined {
    return this.error?.map(error => error.id);
  }

  get isHrefError(): boolean | undefined {
    return this.errorIds?.some(id => id.includes('href_'));
  }

  get parsedError(): Record<string, any> {
    const firstPart = this.errorString.split('[')?.[0];
    const splittedValue = this.errorString.split('[')?.[1]?.split(']');
    const urlPart = splittedValue?.[0];
    const url = urlPart.split(':')?.[1];
    const lastPart = splittedValue?.[1];
    const returnObject = {firstPart, url, lastPart};
    return returnObject;
  }

  public navigateToErrorHref(href: string) {
    const newTabUrl = (this.router['location']._locationStrategy._platformLocation._location.origin as string) + href;
    localStorage.setItem('new-tab', 'true');
    window.open(newTabUrl, '_blank');
  }
}
