import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ISnackbarData } from '@core/models/core.models';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { UpperCasePipe } from '@angular/common';
import { IconComponent } from '../../elements/icon/icon.component';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  standalone: true,
  imports: [IconComponent, MatButton, UpperCasePipe, TranslateModule]
})
export class SnackbarComponent {

  public iconTypes = {
    success: 'check_outline',
    error: 'highlight_off',
    warning: 'warning',
    default: 'info-outline'
  } as Record<string, any>;

  constructor(
    public snackbarRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: ISnackbarData,
    public translate: TranslateService
  ) {}
}
