<div
class="datepicker-wrapper"
matTooltipPosition="left"
[matTooltip]="error.message || error.toString()"
[matTooltipDisabled]="!error"
[ngClass]="{error: error, disabled: control.disabled}"
[attr.data-test-id]="'daterangepicker__' + id"
>
 <mat-date-range-input
    [rangePicker]="rangepicker"
    readonly
    matInput
    type="text"
    [disabled]="control.disabled"
    [formGroup]="rangeGroup"
    (click)="rangepicker.open()"
    [min]="minDate"
    [max]="maxDate"
    [attr.data-test-id]="'daterangepicker__RangePicker'"
  >
      <input
        matStartDate
        placeholder="Start date"
        formControlName="start"
      >
      <input
        matEndDate
        placeholder="End date"
        formControlName="end"
      >
    </mat-date-range-input>
    <div class="icon-wrapper" [ngClass]="{disabled: control.disabled}">
      <mat-datepicker-toggle [for]="rangepicker">
        <naris-icon
          matDatepickerToggleIcon
          icon="calendar-o"
          iconClass="default light"
        />
      </mat-datepicker-toggle>
    </div>
    <mat-date-range-picker
      #rangepicker
      yPosition="below"
      xPosition="start"
      [disabled]="control.disabled"
      [attr.data-test-id]="'daterangepicker__RangePickerBelow'"
    >
      <mat-date-range-picker-actions >
        <div class="bottom-content">
          <!-- for future reference -->
          <!-- <div *ngIf="['datetime', 'timestamp'].includes(input?.inputType)" class="timepicker">
            <naris-icon icon="waiting" iconClass="default light"></naris-icon>
            <naris-inline-select [control]="hourControl" [options]="hourOpts"></naris-inline-select>
            :
            <naris-inline-select [control]="minuteControl" [options]="minuteOpts"></naris-inline-select>
          </div> -->
          <div class="datepicker-actions">
            <naris-button matDateRangePickerCancel size="small" id="DateRangePickerCancel">Cancel</naris-button>
            <naris-button
              matDateRangePickerApply
              size="small"
              color="primary"
              class="ml-2"
              (click)="submitDate()"
              id="DateRangePickerApply"
            >
              Apply
            </naris-button>
          </div>
        </div>
      </mat-date-range-picker-actions>
    </mat-date-range-picker>
</div>

@if (helpText) {
  <div class="help-text">
    {{helpText}}
  </div>
}

