import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { 
  JsPlumbToolkitOptions, 
  SelectionModes, 
  DEFAULT, 
  Surface, 
  EVENT_TAP, 
  AbsoluteLayout, 
  EVENT_CANVAS_CLICK,
  initializeOrthogonalConnectorEditors, 
  EVENT_EDGE_ADDED,
  PlainArrowOverlay,
  AnchorLocations,
  BlankEndpoint,
  Node,
  EVENT_NODE_MOVE,
  VertexMovedParams,
  EVENT_NODE_MOVE_END,
  EdgeAddedParams,
  Edge,
  ObjectData,
  BackgroundPlugin,
  EVENT_EDGE_PATH_EDITED,
  EdgePathEditedParams
} from '@jsplumbtoolkit/browser-ui';
import { SurfaceComponent, BrowserUIAngular, AngularRenderOptions, jsPlumbService, jsPlumbToolkitModule } from '@jsplumbtoolkit/browser-ui-angular';
import { CONDITION, CUSTOM_SHAPES, DATASTORE, DOCUMENT, END_EVENT, GRID_BACKGROUND_OPTIONS, ICONS, INTERMEDIATE_EVENT, PROCESS, SELECTABLE, START_EVENT, SUB_PROCESS } from '@core/constants/process-constants';
import { BeinformedService, CoreService, FormService, HttpService, TabService } from '@core/services';
import { ICaseListAction, ICaseListRow } from '@core/models';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ProcessManagerService } from '@core/services/process-manager.service';
import { BreadcrumbTabsService } from '@core/services/breadcrumb-tab.service';
import { MatTooltip } from '@angular/material/tooltip';
import { NgClass } from '@angular/common';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../elements/button/button.component';
import { IconComponent } from '../../elements/icon/icon.component';
import { ProcessNodeComponent } from './components/process-node/process-node.component';
import edgeMappings from './edge-mappings';
import { ProcessInspectorComponent } from './components/process-inspector/process-inspector.component';
import { ProcessGroupComponent } from './components/process-group/process-group.component';

interface IProcessNodeConfig {
  type: string;
  size: { width: number; height: number };
  position: { left: number; top: number; edgeLabel?: string; name?: string; edgeGeometries?: string[] };
  style: { outline: string; fill: string; outlineWidth: number };
}

interface IProcessGeometry {
  targetId: string;
  geometry: any;
}

@Component({
  selector: 'naris-process-manager',
  templateUrl: './process-manager.component.html',
  styleUrls: ['./process-manager.component.scss'],
  standalone: true,
  imports: [ButtonComponent, MatTooltip, jsPlumbToolkitModule, NgClass, IconComponent, CdkDrag, ProcessInspectorComponent, TranslateModule]
})
export class ProcessManagerComponent implements AfterViewInit, OnDestroy {

  @ViewChild(SurfaceComponent) public surfaceComponent: SurfaceComponent;
  @ViewChild(ProcessInspectorComponent) public inspector: ProcessInspectorComponent;
  @ViewChild('deleteEdge') public deleteEdge: ElementRef<HTMLDivElement>;

  @Input() public embedded = false;
  @Input() set href(value: string) {
    this.processManagerService.href = value;
  }

  get href() {
    return this.processManagerService.href;
  }

  set rootActions(value: ICaseListAction[]) {
    this.processManagerService.rootActions = value;
  }

  get rootActions() {
    return this.processManagerService.rootActions;
  }

  public dragging = false;
  public toolbarActions = [
    { 
      group: 'zoomGroup',
      hideWithReadOnly: false,
      actions: [
        { icon: 'zoomin', action: 'zoom-in', label: 'process-management.toolbar-zoom_in' },
        { icon: 'zoomout', action: 'zoom-out', label: 'process-management.toolbar-zoom_out' }
      ]
    },
    {
      group: 'undeRedoGroup',
      hideWithReadOnly: true,
      actions: [
        { icon: 'undo2', action: 'undo', label: 'process-management.toolbar-undo' },
        { icon: 'redo', action: 'redo', label: 'process-management.toolbar-redo' }
      ]
    },
    {
      group: 'zoomFitGroup',
      hideWithReadOnly: false,
      actions: [
        { icon: 'fit-screen', action: 'fit-to-screen', label: 'process-management.toolbar-fit_screen' },
        { icon: 'fit-selection', action: 'fit-to-selection', label: 'process-management.toolbar-fit_selection' }
      ]
    },
    {
      group: 'deleteGroup',
      hideWithReadOnly: true,
      actions: [
        { icon: 'delete', action: 'delete-selection', label: 'process-management.toolbar-delete_selection' }
      ]
    }
  ];

  set surface(value: Surface) {
    this.processManagerService.surface = value;
  }

  get surface() {
    return this.processManagerService.surface;
  }

  public toolkit: BrowserUIAngular;
  public readOnly = false;
  public edgeToBeDeleted: Edge;
  public showDeleteButton = false;

  private readonly subs: Subscription[] = [];
  private edgeEditTimeout: NodeJS.Timeout;

  public nodeTypes = [
    { type: PROCESS, label: 'process-management.processStep' },
    { type: CONDITION, label: 'process-management.condition' },
    { type: INTERMEDIATE_EVENT, label: 'process-management.intermediateEvent' },
    { type: END_EVENT, label: 'process-management.endEvent' },
    { type: DATASTORE, label: 'process-management.datastore' },
    { type: DOCUMENT, label: 'process-management.document' },
    { type: SUB_PROCESS, label: 'process-management.sub-process' }
  ];

  constructor(
    private readonly $jsplumb: jsPlumbService,
    private readonly beinformedService: BeinformedService,
    private readonly processManagerService: ProcessManagerService,
    private readonly formService: FormService,
    private readonly httpService: HttpService,
    private readonly coreService: CoreService,
    private readonly router: Router,
    private readonly breadcrumbTabService: BreadcrumbTabsService,
    private readonly tabService: TabService
  ) { 
    this.$jsplumb.registerShapeLibrary([CUSTOM_SHAPES]);
  }

  public ngAfterViewInit(): void {
    const splittedBasePath = this.href.split('/');
    const basePath = splittedBasePath.slice(0, splittedBasePath.length - 1).join('/');
    if (this.tabService.activeTab.href === basePath || this.embedded) {
      initializeOrthogonalConnectorEditors();
      this.surface = this.surfaceComponent.surface;
      this.toolkit = this.surfaceComponent.toolkit;
      this.readOnly = this.isReadOnly;
      this.toolkit.unbind(EVENT_EDGE_PATH_EDITED);
      this.initialize();

      // Observables
      this.subs.push(
        this.processManagerService.addNode$.subscribe(res => this.addNode(res)),
        this.processManagerService.addLinkedObject$.subscribe(href => this.addLinkedObject(href)),
        this.processManagerService.removeNode$.subscribe(id => this.removeNode(id)),
        this.processManagerService.updateEdge$.subscribe(data => this.updateEdge(data)),
        this.processManagerService.updateNode$.subscribe(data => this.updateNode(data)),
        this.processManagerService.openSubProcess$.subscribe(obj => this.openSubProcess(obj)),
        this.processManagerService.nodeChanged$.subscribe(obj => this.nodeChanged(obj))
      );

      // JsPlumb events
      this.toolkit.bind(EVENT_EDGE_PATH_EDITED, (e: EdgePathEditedParams) => {
        clearTimeout(this.edgeEditTimeout);
        this.edgeEditTimeout = setTimeout(() => {
          const action = e.edge.source.data.actions.find((x: any) => x.name === 'update-position');
          if (!!action) {
            let geometries: IProcessGeometry[] = [];
            if (!!e.edge.source.data.edgeGeometries) {
              const existingGeometries = e.edge.source.data.edgeGeometries as IProcessGeometry[];
              const filteredGeometries = existingGeometries.filter(geometry => geometry.targetId !== e.edge.target.id);
              geometries = filteredGeometries;
              geometries.push({targetId: e.edge.target.id, geometry: e.geometry});
            } else geometries.push({targetId: e.edge.target.id, geometry: e.geometry}); 
            const position = JSON.stringify({
              left: e.edge.source.data.left, 
              top: e.edge.source.data.top, 
              edgeLabel: e.edge.source.data.edgeLabel, 
              name: e.edge.source.data.name,
              edgeGeometries: geometries
            });
            const body = {
              Position: position
            };
            this.httpService.post(action.href, body).subscribe();
          }
        }, 500);
      });
    }
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    if (!!this.toolkit) this.toolkit.clear();
  }

  private initialize(href?: string, isSubProcess = false) {
    const endpoint = href || this.href;
    if (this.toolkit.getNodeCount() > 0) this.toolkit.clear();
    this.subs.push(
      this.beinformedService.fetchResponseWithContributions<'caselist'>(endpoint).subscribe({
        next: res => {
          const results = this.beinformedService.extractResults(res) as ICaseListRow[];
          const root = results.find((result: any) => !result['ParentID']);
          if (!!root) {
            this.rootActions = root.actions;
            if (!root['HasChildren'] && !isSubProcess) this.drawStartEvent(root.actions);
          }
          this.draw(results);
        }
      })
    );
  }

  private draw(objects: ICaseListRow[]) {
    const filteredObjects = objects.filter(object => !!object['ParentID']);
    filteredObjects.forEach(object => {
      if (!!object['ParentID'] && object['StructureType'] === 'ProcessStep' || object['StructureType'] === 'Process') this.drawNode(object);
    });
    this.drawEdges(filteredObjects);
    this.surface.zoomToFit();
  }

  get isReadOnly() {
    return this.embedded || this.tabService.activeTab.href.endsWith('/viewer');
  }

  private drawStartEvent(rootActions: ICaseListAction[]) {
    const action = rootActions.find(rAction => rAction.name === 'add-start-event');
    if (!!action) {
      this.beinformedService.fetchForm(action.href, true).subscribe({
        next: res => {
          if (!!res.data.formresponse?.success) this.initialize();
        }
      });
    }
  }

  private drawNode(node: ICaseListRow) {
    const config = this.getNodeConfig(node);
    if (config.type !== '') {
      this.toolkit.addNode({
        id: `${node['ID']}`,
        parentIds: this.getParentIds(node['ParentIDs']),
        type: config.type,
        left: config.position.left,
        top: config.position.top,
        edgeLabel: config.position.edgeLabel,
        width: config.size.width,
        height: config.size.height,
        outline: config.style.outline,
        outlineWidth: config.style.outlineWidth,
        fill: config.style.fill,
        name: node['ProcessStepType'] === 'Condition' ? config.position.name || node['Name'] : node['Name'],
        description: node['Description'],
        children: node['Available'],
        actions: node['actions'],
        selfHref: node['_links']['self']['href'],
        subProcess: !node['ProcessStepType'] ? node['_links']['Process']?.['href'] : '',
        edgeGeometries: config.position.edgeGeometries
      });
      if (!node['Position']) this.surface.magnetize();
    }
  }

  private drawEdges(objects: ICaseListRow[]) {
    const nodes = this.toolkit.getNodes();
    objects.forEach(object => {
      const source = nodes.find(node => parseInt(node.data.id) === object['ID']);
      if (!!source) {
        const targets = nodes.filter(node => node.data.parentIds.includes(object['ID']));
        targets.forEach(target => {
          const edgeGeometry = (source.data.edgeGeometries as IProcessGeometry[] || []).find(x => x.targetId === target.id);
          if (!!edgeGeometry) this.toolkit.addEdge({source, target, data: { label: !!target.data.edgeLabel ? target.data.edgeLabel : '' }, geometry: edgeGeometry.geometry});
          else this.toolkit.addEdge({source, target, data: { label: !!target.data.edgeLabel ? target.data.edgeLabel : '' }});
        });
      }
    });
  }

  private drawGroup(object: ICaseListRow) {
    const config = this.getNodeConfig(object);
    const group = this.toolkit.addGroup({
      name: object['Name'],
      actions: object['actions'],
      left: config.position.left,
      top: config.position.top
    });
    if (!object['Position']) this.surface.magnetize(group);
  }

  private addNode(data: { type: string; direction: string; id: string }) {
    const node = this.toolkit.getNode(data.id);
    this.setNodePosition(node, data.direction, data.type);
    this.processManagerService.nodeType = data.type;
    const action = this.getAddAction(node.data.actions as ICaseListAction[], data.type);
    if (!!action) {
      if (action.name === 'add-process-step' || action.name === 'add-process-object') {
        this.formService.open(action.href).subscribe({
          next: saved => {
            if (saved) this.initialize();
          }
        });
      } else {
        const body = {
          Position: this.processManagerService.nodePosition
        };
        this.httpService.post(action.href, body).subscribe({
          next: res => {
            if (!!res.formresponse?.success) this.initialize();
          }
        });
      }
    }
  }

  private addPaletteNode(type: string, position: { left: string; top: string }) {
    this.processManagerService.nodePosition = JSON.stringify(position);
    const reversedType = this.getReverseNodeType(type);
    this.processManagerService.nodeType = reversedType;
    const action = this.getAddAction(this.rootActions, reversedType);
    if (!!action) {
      if (action.name === 'add-process-step' || action.name === 'add-process-object') {
        this.formService.open(action.href).subscribe({
          next: saved => {
            if (saved) this.initialize();
          }
        });
      } else {
        const body = {
          Position: this.processManagerService.nodePosition
        };
        this.httpService.post(action.href, body).subscribe({
          next: res => {
            if (!!res.formresponse?.success) this.initialize();
          }
        });
      }
    }
  }

  private removeNode(id: string) {
    const node = this.toolkit.getNode(id);
    const action = node.data.actions.find((x: any) => x.name === 'detach-object');
    if (!!action) {
      this.formService.open(action.href).subscribe({
        next: saved => {
          if (saved) this.initialize();
        }
      });
    }
  }

  private updateEdge(data: { edgeId: string; value: string }) {
    const edge = this.toolkit.getEdge(data.edgeId);
    const action = edge.target.data.actions.find((x: any) => x.name === 'update-position');
    if (!!action) {
      const json = JSON.stringify({left: edge.target.data.left, top: edge.target.data.top, edgeLabel: data.value, name: edge.target.data.name});
      const body = {
        Position: json
      };
      this.httpService.post(action.href, body).subscribe();
    }
  }

  private updateNode(data: { nodeId: string; value?: string }) {
    const node = this.toolkit.getNode(data.nodeId);
    if (node.data.type === 'process-condition') {
      const action = node.data.actions.find((x: any) => x.name === 'update-position');
      if (!!action) {
        const json = JSON.stringify({left: node.data.left, top: node.data.top, edgeLabel: node.data.edgeLabel, name: data.value});
        const body = {
          Position: json
        };
        this.httpService.post(action.href, body).subscribe({
          next: () => {
            this.toolkit.clearSelection();
          }
        });
      }
    } else {
      const action = node.data.actions.find((x: any) => x.name === 'update-step');
      if (!!action) {
        this.formService.open(action.href).subscribe({
          next: saved => {
            if (saved) {
              this.initialize();
              this.toolkit.clearSelection();
            }
          }
        });
      }
    }
  }

  private nodeChanged(data: { obj: Record<string, any>, id: string }) {
    this.toolkit.clearSelection();
    const keys = Object.keys(data.obj);
    const nameKey = keys?.find(key => key.includes('Name'));
    const descKey = keys?.find(key => key.includes('Description'));
    const name = !!nameKey ? data.obj[nameKey] : '';
    const desc = !!descKey ? data.obj[descKey] : '';
    this.toolkit.updateNode(data.id, {
      name: name,
      description: desc
    });
  }

  private getParentIds(idString: string) {
    const split = idString.split(',');
    return split.map(x => parseInt(x));
  }

  private getAddAction(actions: ICaseListAction[], type: string): ICaseListAction | undefined {
    if (type === 'Process' || type === 'Step') return actions.find(action => action.name === 'add-process-step');
    else if (type === 'Condition') return actions.find(action => action.name === 'add-condition');
    else if (type === 'End Event') return actions.find(action => action.name === 'add-end-event');
    else if (type === 'SubProcess') return actions.find(action => action.name === 'add-process-object');
    else if (type === 'Datastore') return actions.find(action => action.name === 'add-datastore');
    else if (type === 'Document') return actions.find(action => action.name === 'add-document');
    else if (type === 'IntermediateEvent') return actions.find(action => action.name === 'add-intermediate');
  }

  private addLinkedObject(href: string) {
    this.formService.open(href).subscribe({
      next: saved => {
        if (saved) this.initialize();
      }
    });
  }

  private openSubProcess(obj: ObjectData) {
    const action = obj.actions.find((x: any) => x.name === 'open-case-diagram');
    if (!!action) {
      this.subs.push(
        this.beinformedService.fetchForm(action.href).subscribe({
          next: res => {
            const redirectUrl = res.data.error.formresponse.missing?.anchors[0].elements[0].suggestion;
            this.breadcrumbTabService.add({label: '-', url: redirectUrl, originalUrl: redirectUrl, type: '- ', children: []});
            void this.router.navigate([redirectUrl]);
          }
        })
      );
    }
  }

  private setNodePosition(node: Node, direction: string, type: string) {
    const size = this.getNodeSize(type);
    let left = node.data.left as number;
    let top = node.data.top as number;
    if (direction === 'right') {
      left = parseInt(node.data.left) + parseInt(node.data.width) + 100;
      const median = (parseInt(node.data.height) - size.height) / 2;
      top = parseInt(node.data.top) + median;
    } else if (direction === 'bottom') {
      top = parseInt(node.data.top) + parseInt(node.data.height) + 100;
      const median = (parseInt(node.data.width) - size.width) / 2;
      left = parseInt(node.data.left) + median;
    } else if (direction === 'left') {
      left = parseInt(node.data.left) - parseInt(node.data.width) - 100;
      const median = (parseInt(node.data.height) - size.height) / 2;
      top = parseInt(node.data.top) + median;
    } else if (direction === 'top') {
      top = parseInt(node.data.top) - parseInt(node.data.height) - 100;
      const median = (parseInt(node.data.width) - size.width) / 2;
      left = parseInt(node.data.left) + median;
    }
    this.processManagerService.nodePosition = JSON.stringify({left, top});
  }

  private getNodeConfig(node: ICaseListRow): IProcessNodeConfig {
    const type = this.getNodeType(node['ProcessStepType'] || '');
    const position: { left: number; top: number; edgeLabel?: string; name?: string } = !!node['Position'] ? JSON.parse(node['Position']) : {left: 0, top: 0};
    let size: { width: number; height: number } = {width: 0, height: 0};
    const style: { outline: string; fill: string; outlineWidth: number } = {outline: '#1d2e40', fill: '#fff', outlineWidth: type === END_EVENT ? 6 : 3};
    if (type === PROCESS || type === SUB_PROCESS) size = {width: 150, height: 100};
    else if (type === CONDITION) size = {width: 75, height: 75};
    else if (type === END_EVENT || type === START_EVENT || type === INTERMEDIATE_EVENT) size = {width: 50, height: 50};
    else if (type === DOCUMENT || type === DATASTORE) size = {width: 100, height: 100};
    return {type, size, position, style};
  }

  private getNodeSize(_type: string, correctType?: boolean) {
    const type = !!correctType ? _type : this.getNodeType(_type);
    let size: { width: number; height: number } = {width: 0, height: 0};
    if (type === PROCESS || type === SUB_PROCESS) size = {width: 150, height: 100};
    else if (type === CONDITION) size = {width: 75, height: 75};
    else if (type === END_EVENT || type === START_EVENT || type === INTERMEDIATE_EVENT) size = {width: 50, height: 50};
    else if (type === DOCUMENT || type === DATASTORE) size = {width: 100, height: 100};
    return size;
  }

  private getNodeType(type: string) {
    if (type === 'Process' || type === 'Step') return PROCESS;
    else if (type === 'Condition') return CONDITION;
    else if (type === 'EndEvent') return END_EVENT;
    else if (type === 'StartEvent') return START_EVENT;
    else if (type === 'Datastore') return DATASTORE;
    else if (type === 'Document') return DOCUMENT;
    else if (type === 'Intermediate') return INTERMEDIATE_EVENT;
    return SUB_PROCESS;
  }

  private getReverseNodeType(type: string): string {
    if (type === PROCESS) return 'Step';
    else if (type === CONDITION) return 'Condition';
    else if (type === END_EVENT) return 'End Event';
    else if (type === DATASTORE) return 'Datastore';
    else if (type === DOCUMENT) return 'Document';
    else if (type === INTERMEDIATE_EVENT) return 'IntermediateEvent';
    else return 'SubProcess';
  }

  public dataGenerator = (el: Element) => {
    const type = el.getAttribute('data-type');
    const label = el.innerHTML;
    const base = { type };

    // Define node size based on type
    if (type === PROCESS || type === SUB_PROCESS) Object.assign(base, {width: 150, height: 100});
    else if (type === START_EVENT || type === END_EVENT || type === INTERMEDIATE_EVENT) Object.assign(base, {width: 50, height: 50});
    else if (type === CONDITION) Object.assign(base, {width: 75, height: 75});
    else if (type === END_EVENT) Object.assign(base, {width: 50, height: 50});
    else if (type === DATASTORE || type === DOCUMENT) Object.assign(base, {width: 100, height: 100});

    // Default node styling
    Object.assign(base, {
      label,
      fill: '#fff',
      outline: '#000',
      textColor: '#000',
      outlineWidth: 3
    });

    return base;
  };

  public getPaletteIcon(type: string, el: HTMLDivElement) {
    const icon = ICONS[type];
    el.innerHTML = icon;
  }

  private getShapeConfig(type: string, data: Record<string, any>) {
    if (type === START_EVENT || type === END_EVENT || type === INTERMEDIATE_EVENT) {
      data['width'] = 50;
      data['height'] = 50;
      if (type === END_EVENT) data['outlineWidth'] = 6;
    }
    if (type === PROCESS) {
      data['width'] = 150;
      data['height'] = 100;
    }
    if (type === CONDITION) {
      data['width'] = 75;
      data['height'] = 75;
    }
    if (type === DATASTORE || type === DOCUMENT) {
      data['width'] = 100;
      data['height'] = 100;
    }
    return data;
  }

  /**
   * Redraws all the edges which are connected to the given Node,
   * this is done to prevent JsPlumb from drawing weird lines
   * @param node 
   */
  private redrawEdges(node: Node) {
    const edges = this.toolkit.getEdges({source: node, target: node});
    edges.forEach(edge => {
      this.toolkit.removeEdge(edge);
      this.toolkit.addEdge({source: edge.source, target: edge.target});
    });
  }

  // get canUndo(): boolean {
  //   return this.toolkit.undo();
  // }

  public handleToolbarAction(action: string) {
    switch (action) {
      case 'undo': this.toolkit.undo(); break;
      case 'redo': this.toolkit.redo(); break;
      case 'zoom-in': this.surface.nudgeZoom(0.20); break;
      case 'zoom-out': this.surface.nudgeZoom(-0.10); break;
      case 'fit-to-screen': this.surface.zoomToFit(); break;
      case 'fit-to-selection': this.surface.zoomToSelection({}); break;
      case 'delete-selection': this.deleteSelection(); break;
      case 'add-process-object': this.addProcessObject(action);
    }
  }

  private deleteSelection() {
    let selection = this.toolkit.getSelection();
    // If there are groups selected remove those first
    if (selection._groups.length > 0) {
      selection._groups?.forEach(group => this.toolkit.removeGroup(group, true));
      // After deleting the groups get the selection so that we dont try to delete elements which arent there
      selection = this.toolkit.getSelection();
    }
    selection._edges?.forEach(edge => this.toolkit.removeEdge(edge));
    selection._nodes?.forEach(node => this.toolkit.removeNode(node));
  }

  private addProcessObject(name: string) {
    const action = this.rootActions.find(x => x.name === name);
    if (!!action) {
      this.formService.open(action.href).subscribe({
        next: saved => {
          if (saved) this.initialize();
        }
      });
    }
  }

  public doDeleteEdge() {
    const action = this.edgeToBeDeleted.target.data.actions.find((x: any) => x.name === 'remove-relation');
    if (!!action) {
      const body = {
        ID: this.edgeToBeDeleted.source.data.id
      };
      this.httpService.post(action.href, body).subscribe({
        next: res => {
          if (res.formresponse?.success) {
            this.deleteEdge.nativeElement.style.visibility = 'hidden';
            this.toolkit.removeEdge(this.edgeToBeDeleted);
          }
        }
      });
    }
  }

  public createPaletteItem(type: string) {
    const size = this.getNodeSize(type, true);
    this.toolkit.addNode({
      type,
      top: 0,
      left: 0,
      width: size.width,
      height: size.height,
      fill: '#fff',
      outline: '#000',
      textColor: '#000',
      outlineWidth: 3
    });
  }

  get selection() {
    if (!!this.toolkit) {
      const selection = this.toolkit.getSelection();
      return selection._edges.length + selection._nodes.length + selection._groups.length;
    }
  }

  // JsPlumb config
  public toolkitParams: JsPlumbToolkitOptions = {
    selectionMode: SelectionModes.mixed,
    nodeFactory: (type: string, data: Record<string, any>) => {
      data = this.getShapeConfig(type, data);
      this.addPaletteNode(type, {left: data.left, top: data.top});
      return true;
    },
    groupFactory: (type: string, data: Record<string, any>, callback: any) => {
      callback(data);
      return true;
    }
  };

  public view = {
    nodes: {
      [SELECTABLE]: {
        events: {
          [EVENT_TAP]: (p: any) => {
            this.toolkit.setSelection(p.obj);
          }
        }
      },
      [PROCESS]: {
        parent: SELECTABLE,
        component: ProcessNodeComponent
      },
      [START_EVENT]: {
        parent: SELECTABLE,
        component: ProcessNodeComponent
      },
      [END_EVENT]: {
        parent: SELECTABLE,
        component: ProcessNodeComponent
      },
      [CONDITION]: {
        parent: SELECTABLE,
        component: ProcessNodeComponent,
        anchor: AnchorLocations.AutoDefault
      },
      [SUB_PROCESS]: {
        parent: SELECTABLE,
        component: ProcessNodeComponent
      },
      [DATASTORE]: {
        parent: SELECTABLE,
        component: ProcessNodeComponent
      },
      [DOCUMENT]: {
        parent: SELECTABLE,
        component: ProcessNodeComponent
      },
      [INTERMEDIATE_EVENT]: {
        parent: SELECTABLE,
        component: ProcessNodeComponent
      }
    },
    groups: {
      [DEFAULT]: {
        component: ProcessGroupComponent,
        elastic: true
      }
    },
    edges: {
      [DEFAULT]: {
        overlays: [
          {
            type: PlainArrowOverlay.type,
            options: {
              location: 1,
              width: 10,
              length: 10
            }
          }
        ],
        label: '{{label}}',
        useHTMLLabel: true,
        events: {
          [EVENT_TAP]: (params: { e: MouseEvent; edge: Edge }) => {
            this.surface.startEditingPath(params.edge);
            this.toolkit.setSelection(params.edge);
            this.showDeleteButton = !!params.edge.target.data.actions.find((x: any) => x.name === 'remove-relation');
            const node = this.toolkit.getNode(params.edge.source.id);
            const sourceEdges = node.edges.filter(edge => edge.source.id === params.edge.source.id);            
            if (sourceEdges.length > 1 && this.showDeleteButton) {
              this.edgeToBeDeleted = params.edge;
              const menuWidth = this.coreService.sidebarCollapsed ? 96 : 240;
              const top = 170;
              this.deleteEdge.nativeElement.style.visibility = 'visible';
              this.deleteEdge.nativeElement.style.left = `${params.e.clientX - menuWidth}px`;
              this.deleteEdge.nativeElement.style.top = `${params.e.clientY - top - 20}px`;
            }
          }
        }
      }
    }
  };

  public renderParams: AngularRenderOptions = {
    elementsDraggable: !this.isReadOnly,
    editablePaths: true,
    propertyMappings: {
      edgeMappings: edgeMappings()
    },
    layout: {
      type: AbsoluteLayout.type
    },
    grid: {
      size: {w: 12.5, h: 12.5}
    },
    defaults: {
      endpoint: BlankEndpoint.type,
      anchor: AnchorLocations.Continuous,
      connector: { 
        type: 'Orthogonal', 
        options: { 
          cornerRadius: 10
        }
      }
    },
    events: {
      [EVENT_CANVAS_CLICK]: () => {
        if (!!this.deleteEdge) this.deleteEdge.nativeElement.style.visibility = 'hidden';
        this.surface.stopEditingPath();
        this.toolkit.clearSelection();
      },
      [EVENT_EDGE_ADDED]: (e: EdgeAddedParams) => {
        if (e.addedByMouse) {
          if (e.edge.target.id === e.edge.source.id) this.toolkit.removeEdge(e.edge);
          else {
            const action = e.edge.source.data.actions.find((x: any) => x.name === 'add-relation');
            const body = {
              ID: e.edge.target.data.id
            };
            this.httpService.post(action.href, body).subscribe({
              next: res => {
                if (res.formresponse?.success) this.initialize();
              }
            });
          }
        }
      },
      [EVENT_NODE_MOVE]: () => {
        this.dragging = true;
      },
      [EVENT_NODE_MOVE_END]: (e: VertexMovedParams<any>) => {
        if (this.dragging && !!e.vertex.data.actions) {
          const action = e.vertex.data.actions.find((x: any) => x.name === 'update-position');
          if (!!action) {
            const position = JSON.stringify({left: e.pos.x, top: e.pos.y, edgeLabel: e.vertex.data.edgeLabel, name: e.vertex.data.name});
            const body = {
              Position: position
            };
            this.httpService.post(action.href, body).subscribe();
          }
        }
      }
    },
    consumeRightClick: false,
    dragOptions: {
      filter: '.jtk-draw-handle, .node-action, .node-action i'
    },
    //! veroorzaakt een foutmelding wanneer er via de router wordt genavigeerd
    plugins: [
      {
        type: BackgroundPlugin.type,
        options: GRID_BACKGROUND_OPTIONS
      }
    ],
    zoomToFit: true,
    zoomRange: [0.1, 1.5]
  };
}
