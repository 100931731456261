
<div class="heatmap-container" #heatmap>
  <naris-toolbar>
    <naris-toolbar-item>
      <h4>{{title}}</h4>
    </naris-toolbar-item>
    <naris-toolbar-item class="ml-auto">
      @if (!!dialogAllHref) {
        <div class="graph-bar__header__button">
          <naris-button
            icon="go-to"
            color="transparent"
            size="small"
            (clicked)="openDialog(dialogAllHref)"
          />
        </div>
      }
    </naris-toolbar-item>
  </naris-toolbar>

  <div class="heatmap-wrapper" [class.blurred]="isLoading" [class.overview-heatmap]="isOverview">
    <naris-widget-heatmap
      [heatmapData]="selectedHeatmap"
      [data]="heatmapData"
      [isOverview]="isOverview"
      [href]="href"
      [heatmapTableHref]="heatmapTableHref"
    />
  </div>
</div>
<div class="footer">
  <div class="action-bar">
    <form>
      @if (!!input) {
        <naris-select
          [placeholder]="'Category'"
          [control]="getFormControl('RiskCategoryID')"
          [options]="input.options"
          [multiple]="input.multiple"
          [mandatory]="input.mandatory"
          [id]="'WidgetHeatmapRiskCategory'"
        />
      }
    </form>
  </div>
</div>
