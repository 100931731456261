import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'naris-indicator',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss'],
  standalone: true,
  imports: [NgClass, IconComponent]
})
export class IndicatorComponent {
  @Input() public status = 'default';

  get iconName(): string | null {
    if (this.status === 'default') return null;
    return this.status === 'completed'
      ? 'check'
      : 'in-process';
  }
}
