@for (option of options; track option.label) {
  <div [attr.data-test-id]="'checkbox-group__' + id">
    <naris-checkbox
      (changed)="onChange($event, option)"
      [checked]="control.value?.includes(option.code || option.key || option.value)"
      [disabled]="disabled"
      [id]="'CheckboxGroup' + (option.code || option.key || option.value)"
    >
      {{ option.label || option.value || option.key }}
      @if (option.count !== null && option.count !== undefined) {
        <span class="checkbox-count">({{ option.count }})</span>
      }
    </naris-checkbox>
  </div>
}
