import { Pipe, PipeTransform } from '@angular/core';
import { stripObject } from '@core/helpers';

@Pipe({
  name: 'strip',
  standalone: true
})
export class StripPipe implements PipeTransform {

  public transform<T extends Record<string, any>>(value: T, blacklist: (keyof T)[], depth?: number): T {
    return stripObject(value, blacklist, depth);
  }
}
