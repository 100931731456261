import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { HttpService } from '@core/services/http.service';
import { EAuthContext } from '@core/enums';

@Injectable({
  providedIn: 'root'
})
export class DatafeedsService {

  private apiKey: string;

  constructor(
    private readonly httpService: HttpService,
    private readonly authService: AuthService
  ) {}

  public getApiKey(): Observable<any> {
    return new Observable(observer => {
      const rest = this.authService.authContext === EAuthContext.SSO;
      this.httpService.post('/datafeeds/tasks/get-token', {}, rest).subscribe({
        next: () => {
          observer.next(null);
          observer.complete();
        },
        error: err => {
          if (!!err?.error?.formresponse?.missing?.anchors?.[0]?.elements?.[0]?.value) {
            this.apiKey = err?.error?.formresponse?.missing?.anchors?.[0]?.elements?.[0]?.value;
            observer.next(this.apiKey);
            observer.complete();
          } else {
            observer.next(null);
            observer.complete();
          }
        }
      });
    });
  }

  public login(username: string, password: string): Observable<any> {
    if (!!username && !!password) {
      const postableObject = {email: username, password: password};
      const url = '/api/login';
      const header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.apiKey}`)
          .set('Content-Type', 'application/json')
      };
      return this.httpService.post(url, postableObject, false, false, header, false);
    } else {
      return of(null);
    }
  }

  public getEndpoints(): Observable<any> {
    const url = '/api/datafeed/end-points';
    const header = {
      headers: new HttpHeaders().set('Authorization',  `Bearer ${this.apiKey}`)
    };
    return this.httpService.get(url, false, false, header);
  }

  public getUrl(url: string) {
    const header = {
      headers: new HttpHeaders().set('Authorization',  `Bearer ${this.apiKey}`)
    };
    return this.httpService.get(url, false, false, header);
  }

  public postUrl(url: string, body: any) {
    const header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.apiKey}`)
        .set('Content-Type', 'application/json')
    };
    return this.httpService.post(url, body, false, false, header, false);
  }
}
