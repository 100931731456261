import type { IMappedInput } from '@core/models';

export class FilterChip {
  constructor(
    public label: string,
    public value: any,
    public val: any,
    public input: IMappedInput,
    public separator = ':'
  ) {}
}
