import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PASSWORD, CAPITAL_REGEX, NUMBER_REGEX, PASSWORD_REPEAT, VALIDATION_REGS, SPECIAL_REGEX, TOKEN_INVALID } from '@core/constants';
import { notPartlySameStringValidator, regexValidator, sameValidator, testOriginal } from '@core/helpers';
import { AuthService, HttpService, SnackbarService } from '@core/services';
import { TranslateModule } from '@ngx-translate/core';
import { CardComponent } from '../../../shared/components/card/card.component';
import { FormFieldComponent } from '../../../shared/components/form-field/form-field.component';
import { InputComponent } from '../../../shared/elements/input/input.component';
import { InputConditionComponent } from '../../../shared/elements/input-condition/input-condition.component';
import { FormActionsComponent } from '../../../shared/components/form-actions/form-actions.component';
import { ButtonComponent } from '../../../shared/elements/button/button.component';
import { ProgressSpinnerComponent } from '../../../shared/elements/progress-spinner/progress-spinner.component';

@Component({
  selector: 'app-pw-change',
  templateUrl: './pw-change.component.html',
  styleUrls: ['./pw-change.component.scss'],
  standalone: true,
  imports: [CardComponent, FormsModule, ReactiveFormsModule, FormFieldComponent, InputComponent, InputConditionComponent, FormActionsComponent, RouterLink, ButtonComponent, ProgressSpinnerComponent, TranslateModule]
})
export class PwChangeComponent implements OnInit, OnDestroy {

  public changeForm: FormGroup;
  public lengthOK: boolean;
  public numOK: boolean;
  public capsOK: boolean;
  public specialOK: boolean;
  public originalOK: boolean;
  public invalidToken: boolean;
  public loading = true;
  private subs: Subscription[];
  private idToken: string;
  private checkEmail: string;
  private readonly validators = [
    Validators.required,
    Validators.minLength(8),
    Validators.maxLength(50),
    regexValidator(VALIDATION_REGS)
  ];

  constructor(
    private readonly snackbar: SnackbarService,
    private readonly router: Router,
    private readonly httpService: HttpService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService
  ) {}

  public ngOnInit(): void {
    this.idToken = this.activatedRoute.snapshot.queryParams['idToken'];
    this.authService.checkToken(this.idToken).pipe(
      finalize(() => this.loading = false)
    ).subscribe(res => {
      if (res === TOKEN_INVALID) this.invalidToken = true;
      else {
        this.checkEmail = this.activatedRoute.snapshot.queryParams['userEmail'] || '';
        this.changeForm = new FormGroup({
          [PASSWORD]: new FormControl('', [...this.validators, notPartlySameStringValidator(this.checkEmail, 5)]),
          [PASSWORD_REPEAT]: new FormControl('')
        });
        this.changeForm.get(PASSWORD_REPEAT)?.setValidators([...this.validators, sameValidator(this.changeForm.get(PASSWORD))]);
        this.subs = [
          this.changeForm.get(PASSWORD)!.valueChanges.subscribe((val: string) => this.checkErrors(val))
        ];
        this.invalidToken = false;
      }
    });
  }

  public onSubmit = (): void => {
    const url = `/b2c/tasks/change-password?idToken=${this.idToken}`;
    const body = {
      tokens: null,
      objects: {
        Attributes: {
          newPassword: this.changeForm.get(PASSWORD)?.value,
          repeatPassword: this.changeForm.get(PASSWORD_REPEAT)?.value
        }
      }
    };
    this.httpService.post(url, body).subscribe(() => {
      this.snackbar.open({text: 'password.change_successfull', type: 'success'});
      void this.router.navigate(['login']);
    });
  };

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private checkErrors(value: string) {
    this.lengthOK = value.length >= 8 && value.length <= 50;
    this.numOK = NUMBER_REGEX.test(value);
    this.capsOK = CAPITAL_REGEX.test(value);
    this.specialOK = SPECIAL_REGEX.test(value);
    this.originalOK = testOriginal(value, this.checkEmail, 5);
  }

  public getFormControl(controlName: string) {
    return this.changeForm.get(controlName) as FormControl;
  }
}
