import { defineProperty as e, createClass as t, classCallCheck as n } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { removeWithFunction as r, forEach as a, uuid as u } from "../util/util.js";
import { EVENT_TOUCHSTART as i, EVENT_MOUSEDOWN as o, EVENT_TOUCHEND as l, EVENT_MOUSEUP as s, EVENT_TOUCHMOVE as f, EVENT_MOUSEMOVE as c, EVENT_TAP as _, EVENT_DBL_TAP as d, EVENT_CONTEXTMENU as h, EVENT_MOUSEENTER as v, EVENT_MOUSEEXIT as p, EVENT_FOCUS as m, EVENT_MOUSEOVER as g, EVENT_MOUSEOUT as y } from "./event-constants.js";
import { WILDCARD as T } from "../common/index.js";
import { ATTRIBUTE_TABINDEX as E } from "../core/constants.js";
var x, H;
function w(e, t, n, r, a, i, o) {
  return new Touch({
    target: e,
    identifier: u(),
    pageX: t,
    pageY: n,
    screenX: r,
    screenY: a,
    clientX: i || r,
    clientY: o || a
  });
}
function k() {
  var e = [];
  e.push.apply(e, arguments);
  e.item = function (e) {
    return this[e];
  };
  return e;
}
function b(e, t, n, r, a, u, i) {
  return k(w(e, t, n, r, a, u, i));
}
function P(e, t, n) {
  n = n || e.parentNode;
  var r = n.querySelectorAll(t);
  for (var a = 0; a < r.length; a++) {
    if (r[a] === e) {
      return true;
    }
  }
  return false;
}
function X(e) {
  return e.srcElement || e.target;
}
function S(e, t, n, r) {
  if (!r) {
    return {
      path: [t],
      end: 1
    };
  } else {
    var a = e.composedPath ? e.composedPath() : e.path;
    if (typeof a !== "undefined" && a.indexOf) {
      return {
        path: a,
        end: a.indexOf(n)
      };
    } else {
      var u = {
          path: [],
          end: -1
        },
        i = function e(t) {
          u.path.push(t);
          if (t === n) {
            u.end = u.path.length - 1;
          } else if (t.parentNode != null) {
            e(t.parentNode);
          }
        };
      i(t);
      return u;
    }
  }
}
function Y(e, t) {
  var n = 0,
    r;
  for (n = 0, r = e.length; n < r; n++) {
    if (e[n][0] === t) {
      break;
    }
  }
  if (n < e.length) {
    e.splice(n, 1);
  }
}
var C = 1;
var A = false;
var j = false;
function L() {
  return A || "ontouchstart" in document.documentElement || navigator.maxTouchPoints != null && navigator.maxTouchPoints > 0;
}
function M() {
  return j || "onmousedown" in document.documentElement;
}
var K = (x = {}, e(x, o, i), e(x, s, l), e(x, c, f), x);
var O = "page";
var U = "screen";
var B = "client";
function N(e, t) {
  if (e == null) return {
    x: 0,
    y: 0
  };
  var n = G(e),
    r = F(n, 0);
  return {
    x: r[t + "X"],
    y: r[t + "Y"]
  };
}
function q(e) {
  return N(e, O);
}
function z(e) {
  return N(e, U);
}
function D(e) {
  return N(e, B);
}
function F(e, t) {
  return e.item ? e.item(t) : e[t];
}
function G(e) {
  return e.touches && e.touches.length > 0 ? e.touches : e.changedTouches && e.changedTouches.length > 0 ? e.changedTouches : e.targetTouches && e.targetTouches.length > 0 ? e.targetTouches : [e];
}
function I(e) {
  return G(e).length;
}
function J(e) {
  if (e == null) {
    return {
      x: 0,
      y: 0
    };
  } else if (e.pageX !== null) {
    return {
      x: e.pageX,
      y: e.pageY
    };
  } else {
    var t = G(e),
      n = F(t, 0);
    if (n != null && n.pageX != null) {
      return {
        x: n.pageX,
        y: n.pageY
      };
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  }
}
function Q(e, t, n, r, a) {
  W(e, t, n);
  r.__tauid = n.__tauid;
  if (e.addEventListener) {
    e.addEventListener(t, n, false, a);
  } else if (e.attachEvent) {
    var u = t + n.__tauid;
    e["e" + u] = n;
    e[u] = function () {
      e["e" + u] && e["e" + u](window.event);
    };
    e.attachEvent("on" + t, e[u]);
  }
}
function R(e, t, n) {
  var r = this;
  if (n == null) return;
  V(e, function (a) {
    Z(a, t, n);
    if (n.__tauid != null) {
      if (a.removeEventListener) {
        a.removeEventListener(t, n, false);
        if (L() && K[t]) a.removeEventListener(K[t], n, false);
      } else if (r.detachEvent) {
        var u = t + n.__tauid;
        a[u] && a.detachEvent("on" + t, a[u]);
        a[u] = null;
        a["e" + u] = null;
      }
    }
    if (n.__taTouchProxy) {
      R(e, n.__taTouchProxy[1], n.__taTouchProxy[0]);
    }
  });
}
function V(e, t) {
  if (e == null) return;
  var n = typeof e === "string" ? document.querySelectorAll(e) : Array.isArray(e) ? e : [e];
  for (var r = 0; r < n.length; r++) {
    t(n[r]);
  }
}
function W(e, t, n) {
  var r = C++;
  e.__ta = e.__ta || {};
  e.__ta[t] = e.__ta[t] || {};
  e.__ta[t][r] = n;
  n.__tauid = r;
  return r;
}
function Z(e, t, n) {
  e.__ta && e.__ta[t] && delete e.__ta[t][n.__tauid];
  if (n.__taExtra) {
    for (var r = 0; r < n.__taExtra.length; r++) {
      R(e, n.__taExtra[r][0], n.__taExtra[r][1]);
    }
    n.__taExtra.length = 0;
  }
  n.__taUnstore && n.__taUnstore();
}
var $ = /:not\(([^)]+)\)/;
function ee(e, t, n, r) {
  if (e == null) {
    return n;
  } else {
    var u = e.split(","),
      i = [],
      o = [];
    a(u, function (e) {
      var t = e.match($);
      if (t != null) {
        o.push(t[1]);
      } else {
        i.push(e);
      }
    });
    if (o.length > 0 && i.length === 0) {
      i.push(T);
    }
    var l = function r(a) {
      r.__tauid = n.__tauid;
      var u = X(a);
      var l = false;
      var s = u;
      var f = S(a, u, t, e != null);
      if (f.end != -1) {
        for (var c = 0; !l && c < f.end; c++) {
          s = f.path[c];
          for (var _ = 0; _ < o.length; _++) {
            if (P(s, o[_], t)) {
              return;
            }
          }
          for (var d = 0; !l && d < i.length; d++) {
            if (P(s, i[d], t)) {
              n.apply(s, [a, s]);
              l = true;
              break;
            }
          }
        }
      }
    };
    te(n, r, l);
    return l;
  }
}
function te(e, t, n) {
  e.__taExtra = e.__taExtra || [];
  e.__taExtra.push([t, n]);
}
var ne = function e(t, n, r, a, u) {
  if (L() && K[n]) {
    var i = ee(a, t, r, K[n]);
    Q(t, K[n], i, r, u);
  }
  if (n === m && t.getAttribute(E) == null) {
    t.setAttribute(E, "1");
  }
  Q(t, n, ee(a, t, r, n), r, u);
};
var re = (H = {}, e(H, _, {
  touches: 1,
  taps: 1
}), e(H, d, {
  touches: 1,
  taps: 2
}), e(H, h, {
  touches: 2,
  taps: 1
}), H);
function ae(e, t, n, r) {
  for (var a in n.__tamee[e]) {
    if (n.__tamee[e].hasOwnProperty(a)) {
      n.__tamee[e][a].apply(r, [t]);
    }
  }
}
var ue = function () {
  function e() {
    n(this, e);
  }
  t(e, null, [{
    key: "generate",
    value: function e(t, n) {
      return function (e, a, u, i) {
        if (a == h && M()) ne(e, a, u, i);else {
          if (e.__taTapHandler == null) {
            var l = e.__taTapHandler = {
              tap: [],
              dbltap: [],
              down: false,
              taps: 0,
              downSelectors: []
            };
            var f = function r(a) {
                var u = X(a),
                  o = S(a, u, e, i != null),
                  s = false;
                for (var f = 0; f < o.end; f++) {
                  if (s) return;
                  u = o.path[f];
                  for (var c = 0; c < l.downSelectors.length; c++) {
                    if (l.downSelectors[c] == null || P(u, l.downSelectors[c], e)) {
                      l.down = true;
                      setTimeout(_, t);
                      setTimeout(d, n);
                      s = true;
                      break;
                    }
                  }
                }
              },
              c = function t(n) {
                if (l.down) {
                  var r = X(n),
                    a,
                    u;
                  l.taps++;
                  var i = I(n);
                  for (var o in re) {
                    if (re.hasOwnProperty(o)) {
                      var s = re[o];
                      if (s.touches === i && (s.taps === 1 || s.taps === l.taps)) {
                        for (var f = 0; f < l[o].length; f++) {
                          u = S(n, r, e, l[o][f][1] != null);
                          for (var c = 0; c < u.end; c++) {
                            a = u.path[c];
                            if (l[o][f][1] == null || P(a, l[o][f][1], e)) {
                              l[o][f][0].apply(a, [n, a]);
                              break;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              },
              _ = function e() {
                l.down = false;
              },
              d = function e() {
                l.taps = 0;
              };
            e.__taTapHandler.downHandler = f;
            e.__taTapHandler.upHandler = c;
            ne(e, o, f);
            ne(e, s, c);
          }
          e.__taTapHandler.downSelectors.push(i);
          e.__taTapHandler[a].push([u, i]);
          u.__taUnstore = function () {
            if (e.__taTapHandler != null) {
              r(e.__taTapHandler.downSelectors, function (e) {
                return e === i;
              });
              Y(e.__taTapHandler[a], u);
              if (e.__taTapHandler.downSelectors.length === 0) {
                R(e, o, e.__taTapHandler.downHandler);
                R(e, s, e.__taTapHandler.upHandler);
                delete e.__taTapHandler;
              }
            }
          };
        }
      };
    }
  }]);
  return e;
}();
var ie = function () {
  function e() {
    n(this, e);
  }
  t(e, null, [{
    key: "generate",
    value: function e() {
      var t = [];
      return function (e, n, r, a) {
        if (!e.__tamee) {
          e.__tamee = {
            over: false,
            mouseenter: [],
            mouseexit: []
          };
          var u = function n(r) {
              var u = X(r);
              if (a == null && u == e && !e.__tamee.over || P(u, a, e) && (u.__tamee == null || !u.__tamee.over)) {
                ae(v, r, e, u);
                u.__tamee = u.__tamee || {};
                u.__tamee.over = true;
                t.push(u);
              }
            },
            i = function n(r) {
              var a = X(r);
              for (var u = 0; u < t.length; u++) {
                if (a == t[u] && !P(r.relatedTarget || r.toElement, "*", a)) {
                  a.__tamee.over = false;
                  t.splice(u, 1);
                  ae(p, r, e, a);
                }
              }
            };
          Q(e, g, ee(a, e, u, g), u);
          Q(e, y, ee(a, e, i, y), i);
        }
        r.__taUnstore = function () {
          delete e.__tamee[n][r.__tauid];
        };
        W(e, n, r);
        e.__tamee[n][r.__tauid] = r;
      };
    }
  }]);
  return e;
}();
var oe = function () {
  function r(t) {
    n(this, r);
    e(this, "clickThreshold", void 0);
    e(this, "dblClickThreshold", void 0);
    e(this, "tapHandler", void 0);
    e(this, "mouseEnterExitHandler", void 0);
    t = t || {};
    this.clickThreshold = t.clickThreshold || 250;
    this.dblClickThreshold = t.dblClickThreshold || 450;
    this.mouseEnterExitHandler = ie.generate();
    this.tapHandler = ue.generate(this.clickThreshold, this.dblClickThreshold);
  }
  t(r, [{
    key: "_doBind",
    value: function e(t, n, r, a, u) {
      if (r == null) return;
      var i = t;
      if (n === _ || n === d || n === h) {
        this.tapHandler(i, n, r, a, u);
      } else if (n === v || n == p) this.mouseEnterExitHandler(i, n, r, a, u);else {
        ne(i, n, r, a, u);
      }
    }
  }, {
    key: "on",
    value: function e(t, n, r, a, u) {
      var i = a == null ? null : r,
        o = a == null ? r : a;
      this._doBind(t, n, o, i, u);
      return this;
    }
  }, {
    key: "off",
    value: function e(t, n, r) {
      R(t, n, r);
      return this;
    }
  }, {
    key: "trigger",
    value: function e(t, n, r, a, u) {
      var i = M() && (typeof MouseEvent === "undefined" || r == null || r.constructor === MouseEvent);
      var o = L() && !M() && K[n] ? K[n] : n,
        l = !(L() && !M() && K[n]);
      var s = q(r),
        f = z(r),
        c = D(r);
      V(t, function (e) {
        var t;
        r = r || {
          screenX: f.x,
          screenY: f.y,
          clientX: c.x,
          clientY: c.y
        };
        var _ = function e(t) {
          if (a) {
            t.payload = a;
          }
        };
        var d = {
          TouchEvent: function t(n) {
            var r = b(e, s.x, s.y, f.x, f.y, c.x, c.y),
              a = n.initTouchEvent || n.initEvent;
            a(o, true, true, window, null, f.x, f.y, c.x, c.y, false, false, false, false, r, r, r, 1, 0);
          },
          MouseEvents: function t(n) {
            n.initMouseEvent(o, true, true, window, u == null ? 1 : u, f.x, f.y, c.x, c.y, r.ctrlKey === true, r.altKey === true, r.shiftKey === true, r.metaKey === true, 1, e);
          }
        };
        var h = !l && !i && L() && K[n],
          v = h ? "TouchEvent" : "MouseEvents";
        t = document.createEvent(v);
        d[v](t);
        _(t);
        e.dispatchEvent(t);
      });
      return this;
    }
  }]);
  return r;
}();
function le(e) {
  A = e;
}
function se(e) {
  j = e;
}
export { oe as EventManager, J as getPageLocation, F as getTouch, M as isMouseDevice, L as isTouchDevice, q as pageLocation, se as setForceMouseEvents, le as setForceTouchEvents, I as touchCount, G as touches };