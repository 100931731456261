import { Component, OnInit, Inject, OnDestroy, Input } from '@angular/core';
import { BeinformedService, FormService, UserService } from '@core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ICaseTabComponent, ICaseViewPanel, ILayoutTab, ITaskGroup } from '@core/models';
import { ModuleCaseViewComponent } from '@core/module/module-case-view/module-case-view.component';
import { GROUPING_PANEL_TYPES } from '@core/constants';
import { NarisBreadcrumbService } from '@core/services/breadcrumb.service';
import { FooterToolbarService } from '@core/services/footer-toolbar.service';
import { StrategyMapService } from '@core/services/strategy-map.service';
import { fadeInOutAnimation } from '@shared/animations/core.animations';
import { CaseViewService } from '@core/services/case-view-service.service';
import { NgClass } from '@angular/common';
import { CardComponent } from '../../../shared/components/card/card.component';
import { FormComponent } from '../../form/form.component';
import { ProcessSidebarComponent } from '../../../shared/components/process-sidebar/process-sidebar.component';
import { GridComponent } from '../../../shared/components/grid/grid.component';
import { GridItemComponent } from '../../../shared/components/grid/grid-item/grid-item.component';
import { PanelComponent } from '../../../shared/components/panel/panel.component';

@Component({
  selector: 'app-module-case-view-panel',
  templateUrl: './module-case-view-panel.component.html',
  styleUrls: ['./module-case-view-panel.component.scss'],
  animations: [fadeInOutAnimation],
  standalone: true,
  imports: [CardComponent, FormComponent, NgClass, ProcessSidebarComponent, GridComponent, GridItemComponent, PanelComponent]
})
export class ModuleCaseViewPanelComponent implements OnInit, OnDestroy {
  public label: string;
  public loading = true;
  public endpoint: string;
  public formListEndpoint: string;
  public panels?: ICaseViewPanel[] | null;
  public taskgroups: ITaskGroup[] | null;
  public helpText?: string;
  public isFormList = false;
  public stepUrl: string;
  public initialApplicability = false;
  public processIds: number[];
  private subs: Subscription[] = [];
  @Input() public tabHref: string;
  @Input() public selectedTab: ILayoutTab;
  @Input() public caseId: number;
  @Input() set stepEndpoint(url: string | undefined) {
    if (url) {
      this.stepUrl = url;
      this.endpoint = url;
    }
  }

  public applicabilityEndpoint: string;
  public applicabilityModules = ['control-implementation', 'control-test', 'risk-identification'];
  public hasApplicability = false;

  get isCreateWizard(): boolean {
    const parts = this.router.url.split('/');
    const caseId = parts.find(part => !isNaN(parseInt(part)));
    return this.router.url.includes('-wizard') && caseId === undefined;
  }

  get isWizard(): boolean {
    return this.router.url.includes('-wizard');
  }

  get isApplicabilityModule() {
    return !!this.applicabilityModules.find(m => this.router.url.includes(m));
  }

  constructor(
    @Inject(ModuleCaseViewComponent) public moduleCaseViewComponent: ModuleCaseViewComponent,
    private readonly beinformedService: BeinformedService,
    private readonly activatedRoute: ActivatedRoute,
    public readonly router: Router,
    private readonly narisBreadcrumb: NarisBreadcrumbService,
    private readonly footerToolbarService: FooterToolbarService,
    private readonly formService: FormService,
    private readonly strategyMapService: StrategyMapService,
    private readonly userService: UserService,
    private readonly caseViewService: CaseViewService
  ) { }

  public ngOnInit(): void {
    // for future refrence:
    // const isRedirect = this.formService.isRedirect;
    // const url = isRedirect ? this.router.url : this.tabHref || this.stepUrl || this.router.url;
    const url = this.tabHref || this.stepUrl || this.router.url;
    const showList = url.includes('-wizard') && url.endsWith('object') || url.endsWith('object') && !url.includes('-wizard');
    this.formService.isRedirect = false;
    if (showList) this.isFormList = true;
    if (this.isFormList || this.isCreateWizard) {
      this.loading = false;
      if (this.isCreateWizard) {
        const endpoint = this.activatedRoute.snapshot.queryParamMap.get('endpoint');
        if (endpoint) this.formListEndpoint = endpoint;
      } else {
        const endpointPart = url.split('/')[2];
        const routerPart = this.router.url.split('/')[2];
        if (endpointPart === routerPart) {
          const applicabilityModule = this.applicabilityModules.find(m => this.router.url.includes(m));
          if (applicabilityModule) {
            this.applicabilityEndpoint = url + '/object/applicability';
            this.beinformedService.fetchForm(this.applicabilityEndpoint).subscribe({
              next: result => {
                if (result.data.status !== 404) this.hasApplicability = true;
                this.formListEndpoint = url + '/object/update';
                this.loadProcesses();
              }
            });
          } else {
            this.formListEndpoint = url + '/object/update';
          }
        }
      }
      if (this.formListEndpoint) this.loadProcesses();
    } else {
      this.subs = [
        // If our parent case view component is reloaded, also refetch the case view panel
        this.moduleCaseViewComponent.changed.subscribe(() => {
          this.setCaseViewPanel();
        }),
        // On param change, refetch the panel
        this.caseViewService.updatePanel$.subscribe(() => {
          this.setCaseViewPanel();
        }),
        this.footerToolbarService.refreshView$.subscribe(() => {
          this.update();
        })
      ];
    }
  }

  public ngOnDestroy(): void {
    if (!this.isCreateWizard) this.subs.forEach((s: Subscription) => s.unsubscribe());
  }

  /**
   * Set the case view panel based on the endpoint
   */
  public setCaseViewPanel() {
    this.panels = null;
    this.taskgroups = null;
    this.loading = true;

    if (!this.stepUrl) this.endpoint = this.tabHref ?? `/${this.router.url.split('/').slice(1, 5).join('/')}`;
    const endpointPart = this.endpoint.split('/')[2];
    const routerPart = this.router.url.split('/')[2];
    if (endpointPart === routerPart) {
      const panelConfig = this.moduleCaseViewComponent._panels?.find((panel: ICaseViewPanel) => panel.href === this.endpoint);
      const resourcetype = panelConfig?.resourcetype || '';
      if (!this.endpoint.endsWith('/wizard-finished'))
        if (GROUPING_PANEL_TYPES.includes(resourcetype)) {
          // If it's a grouping panel, fetch the panel information
          this.fetchCaseViewPanel(this.endpoint);
        } else {
          // Otherwise, load the single panel into the panels array
          this.panels = [panelConfig!];
          const url = this.router.routerState.snapshot.url;
          if (!!panelConfig) {
            this.narisBreadcrumb.add(url, panelConfig.label!);
          }
          this.loading = false;
        }
    }
  }

  /**
   * Update the view
   */
  public update() {
    // Validate if the case is a wizard
    if (this.moduleCaseViewComponent.isWizard) this.validate();
    // Otherwise, just update the panel
    else this.setCaseViewPanel();
  }

  /**
   * Returns true if the current step is the first step
   */
  get isFirstStep() {
    return this.moduleCaseViewComponent._panels?.map(panel => panel.href).indexOf(this.router.url) === 0;
  }

  /**
   * Returns true if the current step is the last step
   */
  get isLastStep() {
    const currentIndex = this.moduleCaseViewComponent._panels?.map(panel => panel.href).indexOf(this.router.url);
    return currentIndex === (this.moduleCaseViewComponent._panels?.length ?? 0) - 1;
  }

  /**
   * Run validation and update the current case view
   */
  public validate() {
    this.moduleCaseViewComponent.validate(() => {
      // If the current step was invalid after validating, refresh the panel to reflect updates
      this.setCaseViewPanel();
    });
  }

  /**
   * Fetch the case view panel and set the taskgroups, panels and label.
   * @param endpoint BeInformed API endpoint
   */
  private fetchCaseViewPanel(endpoint: string) {
    this.beinformedService.fetchResponseWithContributions(endpoint).pipe(
      finalize(() => this.loading = false)
    ).subscribe(({ data, contributions }) => {
      if (this.router.url.endsWith('/overview')) this.taskgroups = this.beinformedService.extractTaskGroups({ data, contributions });
      else this.taskgroups = this.beinformedService.extractTaskGroupsFromGroupingPanel({ data, contributions });
      if (this.taskgroups?.[0]?.actions) this.footerToolbarService.add(this.taskgroups[0].actions, 'PANELACTIONS');
      this.panels = this.beinformedService.extractPanels({ data, contributions })?.filter(panel => !panel?.layouthint?.includes('dashboard-only'));
      if (!!this.panels) {
        const strategyMapVision = this.panels?.findIndex(panel => panel.layouthint?.includes('strategymap-vision'));
        if (strategyMapVision !== -1) {
          this.getStrategyMapVision(this.panels[strategyMapVision]);
          this.panels?.splice(strategyMapVision, 1);
        }
      }
      this.label = contributions.label;
      this.helpText = contributions.text?.message;
      const url = this.router.routerState.snapshot.url;
      this.narisBreadcrumb.add(url, this.label);
    });
  }

  private getStrategyMapVision(panel: ICaseTabComponent | ICaseViewPanel) {
    this.beinformedService.fetchResponseWithContributions(panel.href!).subscribe(res => this.strategyMapService.strategyVision = res.data['Vision']);
  }

  public titleChanged(event: string) {
    if (this.isCreateWizard)
      this.formService.updateFormTitle$.next(event);
  }

  get showProcessBar() {
    return !!this.userService.userData.sessiondata?.OrganizationModules?.includes('Process');
  }

  private loadProcesses() {
    if (!this.showProcessBar) return;
    this.beinformedService.fetchForm(this.formListEndpoint).subscribe({
      next: res => {
        this.processIds = res?.data?.error?.formresponse?.missing?.anchors?.[0]?.elements.find(el => el.elementid === 'ProcessIDs')?.suggestions as [] || [];
      }
    });
  }
}
