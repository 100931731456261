import { createClass as t, classCallCheck as i, defineProperty as e } from "../../_virtual/_rollupPluginBabelHelpers.js";
import { Magnetizer as n } from "../magnetizer.js";
import { extend as s, log as r, rotatePoint as o } from "../../ui-core/util/util.js";
import { getsert as a } from "../util.js";
var h = 10;
function u(t, i, e) {
  t.push(i);
  var n = t.length - 1;
  var s = t[n];
  while (n > 0 && s[e] < t[n - 1][e]) {
    t[n] = t[n - 1];
    n -= 1;
  }
  t[n] = s;
  return n;
}
var l = function () {
  function l(t) {
    i(this, l);
    e(this, "toolkit", void 0);
    e(this, "adapter", void 0);
    e(this, "_vertices", []);
    e(this, "magnetizer", void 0);
    e(this, "magnetizerIterations", void 0);
    e(this, "positions", new Map());
    e(this, "sizes", new Map());
    e(this, "positionArray", []);
    e(this, "parameters", void 0);
    e(this, "done", void 0);
    e(this, "_minx", Infinity);
    e(this, "_miny", Infinity);
    e(this, "_maxx", -Infinity);
    e(this, "_maxy", -Infinity);
    e(this, "entries", {});
    e(this, "xmax", []);
    e(this, "ymax", []);
    e(this, "xmin", []);
    e(this, "ymin", []);
    e(this, "width", void 0);
    e(this, "height", void 0);
    e(this, "container", void 0);
    e(this, "containerSize", void 0);
    e(this, "padding", void 0);
    e(this, "xShift", void 0);
    e(this, "yShift", void 0);
    e(this, "_locationFunction", void 0);
    e(this, "_vertexMap", {});
    e(this, "defaultMagnetized", void 0);
    e(this, "type", void 0);
    this._locationFunction = t.options.locationFunction;
    this.adapter = t.adapter;
    this._$_initialiseMagnetizer(t.options);
    this.toolkit = t.toolkit;
    this.container = t.container;
    this.containerSize = this.adapter.getSize(this.container);
    this.width = t.options.width || this.containerSize.w;
    this.height = t.options.height || this.containerSize.h;
    this.padding = t.options.padding;
    this.done = false;
    this.parameters = t.options || {};
  }
  t(l, [{
    key: "_reset",
    value: function t() {
      this.done = false;
      this._minx = Infinity;
      this._maxx = -Infinity;
      this._miny = Infinity;
      this._maxy = -Infinity;
      this.positions.clear();
      this.positionArray.splice(0);
      this.entries = {};
      this.xmin.length = 0;
      this.ymin.length = 0;
      this.xmax.length = 0;
      this.ymax.length = 0;
      this._vertices.length = 0;
      this.sizes.clear();
      this.magnetizer.reset();
      this.reset();
    }
  }, {
    key: "_magnetizerFilter",
    value: function t(i) {
      return true;
    }
  }, {
    key: "_$_prepareParameters",
    value: function t() {
      var i = s(l._defaultParameters(), this.getDefaultParameters() || {});
      s(i, this.parameters || {});
      this.parameters = i;
      return this.parameters;
    }
  }, {
    key: "_$_getEntry",
    value: function t(i, e) {
      if (!this.entries[i]) {
        var n = e || {
          x: 0,
          y: 0
        };
        this.entries[i] = {
          id: i,
          size: this._getSize(i),
          position: n,
          xmax: n.x,
          ymax: n.y,
          xmin: n.x,
          ymin: n.y
        };
      }
      return this.entries[i];
    }
  }, {
    key: "_cleanupEntry",
    value: function t(i) {
      delete this.entries[i];
    }
  }, {
    key: "_$_calculateExtents",
    value: function t() {
      this.xmax.length = 0;
      this.xmin.length = 0;
      this.ymax.length = 0;
      this.ymin.length = 0;
      this.xShift = 0;
      this.yShift = 0;
      for (var i in this.entries) {
        u(this.xmax, this.entries[i], "xmax");
        u(this.ymax, this.entries[i], "ymax");
        u(this.xmin, this.entries[i], "xmin");
        u(this.ymin, this.entries[i], "ymin");
      }
      this._minx = this.xmin.length > 0 ? this.xmin[0].xmin : 0;
      this._maxx = this.xmax.length > 0 ? this.xmax[this.xmax.length - 1].xmax : 0;
      this._miny = this.ymin.length > 0 ? this.ymin[0].ymin : 0;
      this._maxy = this.ymax.length > 0 ? this.ymax[this.ymax.length - 1].ymax : 0;
    }
  }, {
    key: "snapToGrid",
    value: function t(i, e) {
      if (e != null) {
        this.magnetizer.setElements([e]);
      } else {
        this.magnetizer.setElements(this._vertices.map(function (t) {
          return t.id;
        }));
      }
      return this.magnetizer.snapToGrid(i);
    }
  }, {
    key: "magnetize",
    value: function t(i, e) {
      var n = this;
      i = i || {};
      i.options = i.options || {};
      i.options.iterations = i.options.iterations || this.magnetizerIterations;
      var s = {};
      this.magnetizer.setElements(this._vertices.map(function (t) {
        return t.id;
      }).filter(function (t) {
        return e ? n._magnetizerFilter(t) : true;
      }));
      if (i.origin) {
        s = this.magnetizer.executeAtPoint(i.origin, i.options);
      } else if (i.focus) {
        s = this.magnetizer.executeWithFocus(i.focus, i.options);
      } else {
        s = this.magnetizer.executeAtCenter(i.options);
      }
      return s;
    }
  }, {
    key: "vertexAdded",
    value: function t(i, e) {
      if (this.adapter.filter(i.vertex)) {
        var n = e && e.position ? e.position : i.vertex.data && i.vertex.data.left && i.vertex.data.top ? {
          x: i.vertex.data.left,
          y: i.vertex.data.top
        } : this.adapter.getOffset(i.el);
        var s = this._vertexAdded(i, e);
        if (s && !isNaN(s.x) && !isNaN(s.y)) {
          n.x = s.x;
          n.y = s.y;
        }
        this._vertices.push(i.vertex);
        this._vertexMap[i.vertex.id] = i.vertex;
        this._setPosition(i.vertex.id, n);
        this._getSize(i.vertex.id);
        this.magnetizer.addElement(i.vertex.id);
        return n;
      }
    }
  }, {
    key: "vertexRemoved",
    value: function t(i, e) {
      this.positions.delete(i.id);
      this.sizes.delete(i.id);
      this._vertices = this._vertices.filter(function (t) {
        return t !== i;
      });
      delete this._vertexMap[i.id];
      this._cleanupEntry(i.id);
      this._vertexRemoved(i);
      this.magnetizer.removeElement(i.id);
      if (!e) {
        this._$_calculateExtents();
      }
    }
  }, {
    key: "_getSize",
    value: function t(i) {
      var e = this;
      return a(this.sizes, i, function () {
        var t = e.adapter.getViewportPositionById(i);
        return t != null ? {
          w: t.w,
          h: t.h
        } : {
          w: 0,
          h: 0
        };
      });
    }
  }, {
    key: "_getPosition",
    value: function t(i, e, n, s) {
      var r = this.positions.get(i);
      if (!r) {
        if (e != null && n != null) r = {
          x: e,
          y: n
        };else if (!s) r = {
          x: Math.floor(Math.random() * (this.width + 1)),
          y: Math.floor(Math.random() * (this.height + 1))
        };else return null;
        this._setPosition(i, r);
      }
      return r;
    }
  }, {
    key: "_doSetPosition",
    value: function t(i, e, n) {
      if (i != null) {
        if (isNaN(e)) {
          e = 0;
        }
        if (isNaN(n)) {
          n = 0;
        }
        var s = this.positions.get(i);
        if (!s) {
          s = {
            x: e,
            y: n
          };
          this.positions.set(i, s);
          this.positionArray.push([s, i]);
        }
        var r = this._$_getEntry(i, {
          x: e,
          y: n
        });
        r.position.x = e;
        r.position.y = n;
        this._$_updateEntry(r);
        this.positions.set(i, {
          x: e,
          y: n
        });
      }
    }
  }, {
    key: "_$_doUpdateAfterMove",
    value: function t(i, e, n) {
      if (this._vertexMap[i]) {
        this._vertexMoved(i, e, n);
      }
    }
  }, {
    key: "_vertexMoved",
    value: function t(i, e, n) {}
  }, {
    key: "_vertexAdded",
    value: function t(i, e) {
      return null;
    }
  }, {
    key: "_vertexRemoved",
    value: function t(i) {}
  }, {
    key: "setMagnetizedPosition",
    value: function t(i, e, n, s, r) {
      var o = this;
      this._doSetPosition(i, e, n);
      var a = this.magnetize({
        focus: s === true ? i : null,
        options: {
          filter: function t(e) {
            var n = e !== i;
            return s !== true ? !n : n;
          },
          exclude: function t(i, e) {
            return o._vertexMap[i] == null ? false : o._vertexMap[i].group != null;
          },
          grid: r
        }
      }, false);
      var h = this._getPosition(i);
      this._$_doUpdateAfterMove(i, h.x, h.y);
      return a;
    }
  }, {
    key: "_setPosition",
    value: function t(i, e) {
      this._doSetPosition(i, e.x, e.y);
      this._$_doUpdateAfterMove(i, e.x, e.y);
    }
  }, {
    key: "_getRandomPosition",
    value: function t(i, e, n, s) {
      e = e || 10;
      n = n || 10;
      var r = Math.floor(Math.random() * e);
      var o = Math.floor(Math.random() * n);
      var a = this._$_getEntry(i, {
        x: r,
        y: o
      });
      a.position = {
        x: r,
        y: o
      };
      this._$_updateEntry(a, s !== true);
      this.positions.set(i, {
        x: r,
        y: o
      });
      return {
        x: r,
        y: o
      };
    }
  }, {
    key: "dumpPos",
    value: function t() {
      this.positions.forEach(function (t, i) {
        r("".concat(i, ", ").concat(t.x, ", ").concat(t.y));
      });
    }
  }, {
    key: "_$_initialiseMagnetizer",
    value: function t(i) {
      var e = this;
      this.magnetizer = new n({
        getPosition: function t(i) {
          return e.positions.get(i);
        },
        getSize: function t(i) {
          return e.sizes.get(i);
        },
        getId: function t(i) {
          return i;
        },
        setPosition: function t(i, n) {
          var s = e.adapter.getViewportPositionById(i);
          if (s.r !== 0) {
            var r = n,
              a = {
                x: n.x + s.w,
                y: n.y
              },
              h = {
                x: n.x + s.w,
                y: n.y + s.h
              },
              u = {
                x: n.x,
                y: n.y + s.h
              },
              l = {
                x: (r.x + a.x) / 2,
                y: (r.y + u.y) / 2
              },
              x = o(r, l, -s.r),
              y = o(a, l, -s.r),
              m = o(u, l, -s.r),
              v = o(h, l, -s.r),
              d = Math.min(x.x, y.x, m.x, v.x),
              f = Math.min(x.y, y.y, m.y, v.y);
            e.setPosition(i, d, f);
          } else {
            e.setPosition(i, n.x, n.y);
          }
        },
        padding: i.padding,
        filter: function t(i) {
          if (e._vertexMap[i] && e._vertexMap[i].group) {
            return false;
          }
          return e.canMagnetize(i);
        }
      });
      this.magnetizerIterations = i.magnetizer ? i.magnetizer.iterations || h : h;
    }
  }, {
    key: "setPosition",
    value: function t(i, e, n) {
      var s = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      var r = {};
      r[i] = {
        original: this.positions.get(i) || {
          x: 0,
          y: 0
        },
        current: {
          x: e,
          y: n
        }
      };
      this._setPosition(i, {
        x: e,
        y: n
      });
      if (!s) {
        this._$_calculateExtents();
      }
      return r;
    }
  }, {
    key: "getPositions",
    value: function t() {
      return this.positions;
    }
  }, {
    key: "getPosition",
    value: function t(i) {
      return this.positions.get(i);
    }
  }, {
    key: "_getExtents",
    value: function t() {
      return {
        xmin: this._minx,
        ymin: this._miny,
        xmax: this._maxx,
        ymax: this._maxy
      };
    }
  }, {
    key: "setSize",
    value: function t(i, e) {
      this.sizes.set(i, e);
      var n = this._$_getEntry(i);
      n.size = e;
      this._$_updateEntry(n, true);
    }
  }, {
    key: "getSizes",
    value: function t() {
      return this.sizes;
    }
  }, {
    key: "_$_updateEntry",
    value: function t(i, e) {
      i.xmax = i.position.x + i.size.w;
      i.ymax = i.position.y + i.size.h;
      i.xmin = i.position.x;
      i.ymin = i.position.y;
      if (e) {
        this._$_calculateExtents();
      }
    }
  }, {
    key: "_layout",
    value: function t(i, e) {
      var n = this;
      if (this.toolkit == null) {
        return;
      }
      this.containerSize = this.adapter.getSize(this.container);
      this.width = this.containerSize.w;
      this.height = this.containerSize.h;
      var s = this._$_prepareParameters();
      this._vertices = this.adapter.getElements().slice();
      this.begin(this.toolkit, s);
      var r = function t() {
        if (e || n.defaultMagnetized) {
          n.magnetize({}, true);
        }
        n._$_calculateExtents();
        n.end(n.toolkit, s, e);
        i && i({
          positions: n.positions,
          bounds: {
            xmin: n._minx,
            ymin: n._miny,
            xmax: n._maxx,
            ymax: n._maxy
          },
          sizes: n.sizes
        });
      };
      while (!this.done) {
        this.step(this.toolkit, s);
      }
      r();
    }
  }, {
    key: "relayout",
    value: function t(i, e, n) {
      this._reset();
      if (i != null) {
        this.parameters = i;
      }
      this._layout(e, n);
    }
  }, {
    key: "layout",
    value: function t(i, e) {
      this.done = false;
      this._layout(i, e);
    }
  }], [{
    key: "_defaultParameters",
    value: function t() {
      return {
        padding: {
          x: 0,
          y: 0
        }
      };
    }
  }]);
  return l;
}();
export { l as AbstractLayout };