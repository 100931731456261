import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ILayoutTab } from '@core/models';
import { BeinformedService, TabService } from '@core/services';
import { BreadcrumbTabsService } from '@core/services/breadcrumb-tab.service';
import { Subscription } from 'rxjs';
import { MatTabGroup, MatTab, MatTabContent } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { CardComponent } from '../../shared/components/card/card.component';
import { PanelComponent } from '../../shared/components/panel/panel.component';

@Component({
  selector: 'app-tab-by-module',
  templateUrl: './tab-by-module.component.html',
  styleUrls: ['./tab-by-module.component.scss'],
  standalone: true,
  imports: [MatTabGroup, MatTab, MatTabContent, CardComponent, PanelComponent, TranslateModule]
})
export class TabByModuleComponent implements OnInit, OnDestroy {
  
  public layoutTabs?: ILayoutTab[] = [{title: 'Risico', name: 'Risk', active: true, href: '/risk'}];
  public modules: { name: string; panels: { name: string; label: string | undefined; href: string }[] }[] = [];

  public selectedTab: ILayoutTab;
  private subscription: Subscription;

  constructor(
    private readonly router: Router,
    private readonly beinformedService: BeinformedService,
    private readonly tabs: TabService,
    private readonly breadcrumbTabService: BreadcrumbTabsService
  ) {}


  public ngOnInit(): void {

    this.tabs.useTabServiceForHelp = true;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!this.subscription) this.initialize();
      }
    });

    const splittedUrl = this.router.url.split('/');
    if (splittedUrl.length === 4) {
      splittedUrl.pop();
      void this.router.navigate(splittedUrl);
      return;
    }
    this.initialize();
  }

  public ngOnDestroy(): void {
    this.tabs.useTabServiceForHelp = false;
  }

  private initialize() {
    this.subscription = this.beinformedService.fetchResponseWithContributions(this.router.url).subscribe(result => {
      const contributionsPanelLinks = result.contributions._links?.panel;
      contributionsPanelLinks?.forEach(panelLink => {
        const moduleName = panelLink.layouthint?.find(hint => hint.startsWith('module:'));
        const panel = {name: panelLink.name, label: panelLink.label, href: (result.data._links?.[panelLink.name] as any)?.href, layouthint: panelLink.layouthint };
        if (!!moduleName) {
          const foundModule = this.modules.find(module => module.name === moduleName);
          if (!foundModule) {
            const moduleToAdd = {name: moduleName, panels: [panel]};
            this.modules.push(moduleToAdd);
          } else {
            foundModule.panels.push(panel);
          }
        }
      });
    });
  }

  public tabClicked(selectedTabIndex: number) {
    this.selectedTab = this.modules[selectedTabIndex] as ILayoutTab;
    this.selectedTab.href = `${this.router.url}/${this.selectedTab.name?.split(':')[1]}`;
    this.tabs.activeTab = this.selectedTab;
    if (!!this.selectedTab) window.history.pushState(this.selectedTab.name ?? this.router.url, this.selectedTab.name ?? this.router.url, this.selectedTab.href);
  }
}
