<p>{{category?.label}}</p>
@if (displayLikelihood) {
  <div style="width:20rem;padding-left:61px;">
    <naris-select
      [options]="category?.classes || []"
      [mandatory]="true"
      [id]="'collaborationCategoryClass' + category?.key"
      (selectionChanged)="getImpactValue($event)"
      [disabled]="disabled"
      [value]="selectedClass"
    />
  </div>
}
<naris-heatmap
  class="heatmap"
  [mode]="mode"
  (voted)="voteClicked($event)"
  (saveVote)="voteChanged($event)"
  [category]="category"
  [isHost]="isHost"
  [expertVotes]="collabService.expertVotes[collabService.currentCase?.id || '']"
  [blindVoting]="blindVoting"
  [experts]="collabService.experts"
  [selfId]="collabService.selfId"
  [currentCaseId]="collabService.currentCase?.id || ''"
  [likelihood]="xValue"
  [impact]="impactValue"
  [disabled]="disabled"
  [selectedClass]="selectedCategoryId"
/>