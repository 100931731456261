<div 
  [class]="'process-archimate-node ' + cornerStyle"
  [ngStyle]="{'background-color': backgroundColor}"
  [style.left.px]="obj.left"
  [style.top.px]="obj.top"
  data-jtk-target="true"
>
  <span class="process-archimate-node__title">{{obj.name}}</span>

  <!-- Object type icon -->
  <div class="process-archimate-node__element">
    <naris-icon 
      [icon]="obj.icon" 
      [matTooltip]="('archimate.'+obj.element) | translate"
      matTooltipPosition="above"
      matTooltipShowDelay="200"
    />
  </div>

  <!-- Anchor positions -->
  @for (anchor of anchorPositions; track $index) {
    <div
      [class]="'jtk-connect jtk-connect-'+anchor.id"
      data-jtk-source="true"
    ></div>
  }
</div>
