import { extend as t } from "../util/util.js";
import { SvgComponent as e } from "./svg-component.js";
import { _node as n, ELEMENT_PATH as a, _appendAtIndex as s, _attr as o, _applyStyles as i, ELEMENT_SVG as l } from "./svg-util.js";
import { NONE as r, ABSOLUTE as d } from "../core/constants.js";
function c(l, r, d, c) {
  h(l, r);
  e.paint(r, l, d, c);
  var p = "",
    u = [0, 0];
  if (c.xmin < 0) {
    u[0] = -c.xmin;
  }
  if (c.ymin < 0) {
    u[1] = -c.ymin;
  }
  if (r.segments.length > 0) {
    p = l.getPathData(r);
    var v = {
        d: p,
        transform: "translate(" + u[0] + "," + u[1] + ")",
        "pointer-events": "visibleStroke"
      },
      f = null;
    if (d.outlineStroke) {
      var m = d.outlineWidth || 1,
        g = d.strokeWidth + 2 * m;
      f = t({}, d);
      f.stroke = d.outlineStroke;
      f.strokeWidth = g;
      if (r.bgPath == null) {
        r.bgPath = n(a, v);
        l.addClass(r.bgPath, l.connectorOutlineClass);
        s(r.canvas, r.bgPath, 0);
      } else {
        o(r.bgPath, v);
      }
      i(r.bgPath, f);
    }
    var k = r;
    if (k.path == null) {
      k.path = n(a, v);
      s(k.canvas, k.path, d.outlineStroke ? 1 : 0);
    } else {
      if (k.path.parentNode !== k.canvas) {
        s(k.canvas, k.path, d.outlineStroke ? 1 : 0);
      }
      o(r.path, v);
    }
    i(r.path, d);
  }
}
function h(t, e) {
  if (e.canvas != null) {
    return e.canvas;
  } else {
    var a = n(l, {
      style: "",
      width: "0",
      height: "0",
      "pointer-events": r,
      position: d
    });
    e.canvas = a;
    t._appendElement(e.canvas, t.getContainer());
    if (e.cssClass != null) {
      t.addClass(a, e.cssClass);
    }
    t.addClass(a, t.connectorClass);
    if (e.classesToAddWhenRendered != null) {
      t.addClass(a, e.classesToAddWhenRendered.join(" "));
      delete e.classesToAddWhenRendered;
    }
    a.jtk = a.jtk || {};
    a.jtk.connector = e;
    return a;
  }
}
export { h as getConnectorElement, c as paintSvgConnector };