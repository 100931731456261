import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, timer } from 'rxjs';
import { filter, finalize, map, takeUntil, tap } from 'rxjs/operators';
import { Participant, CollabCase } from '@core/classes';
import { transformTime } from '@core/helpers/common.helper';
import { RtcService } from '@core/services/rtc.service';
import { SocketService } from '@core/services/socket.service';
import { UserService } from '@core/services/user.service';
import { HttpService } from '@core/services/http.service';
import { SessionStorage } from '@core/decorators/storage.decorators';
import { DialogService } from '@core/services/dialog.service';
import { StreamService } from '@core/services/stream.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { getInitials } from '@core/helpers';
import { CollaborationSessionState } from '@core/enums/collaboration.enum';
import type { ICollabEvent, IMessage, ICollabState, IVote, IFinalVote, TCaseListResponse, TCaseListRows, ITaskGroup, IAction, ICollabObjectData } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class CollabService {

  @SessionStorage()
  private _storageInSession: boolean;
  private _inSession: boolean | null;

  @SessionStorage()
  private _storageCurrentRoom: string;
  private _currentRoom: string;

  @SessionStorage()
  private _storageDisplayRoom: string;
  private _displayRoom: string;

  @SessionStorage()
  private _storageSelfId: string;
  private _selfId: string;

  @SessionStorage()
  private _storageIsHost: boolean;
  private _isHost: boolean;

  @SessionStorage()
  private _storageCollabId: number | string;
  private _collabId: number | string;

  @SessionStorage()
  private _storageSessionTime: string;
  private _sessionTime: string | null;

  @SessionStorage()
  private _storageMySessionTime: string;
  private _mySessionTime: string | null;

  @SessionStorage()
  private _storageCurrentCaseId: number | string;
  private _currentCaseId: number | string | null;

  @SessionStorage()
  private _storageCurrentCaseRecordId: number | string;
  private _currentCaseRecordId: number | string | null;

  @SessionStorage()
  private _storageCollabState: ICollabState | null;
  private _collabState: ICollabState | null = { active: null, cases: {}, completed: [] };

  public collabCompleted: string[] = [];
  public collabIdentificationCases: CollabCase[] = [];
  public currentCase: CollabCase | undefined;
  public currentCaseUrl: string;
  public collabPotentialCases: CollabCase[] = [];
  public messages: IMessage[] = [];
  public unreadMessages = 0;
  public showChat = false;
  public collabOpened = false;
  public collabOffline = false;
  public experts: Participant[] = [];
  public missingParticipants: {name: string; initials: string; action: IAction, id: number; invited: boolean}[] = []; 
  public expertsUpdated$ = new Subject<Participant[]>;
  public selfMuted = false;
  public categories: any[] = [];
  public selectedCategories = [];
  public votes: Record<string, Record<string, IFinalVote>> = {};
  public expertVotes: Record<string, Record<string, IVote[]>> = {};
  public timer$ = timer(1000, 1000);
  public clicked$: Observable<ICollabEvent>;
  public onCollabPage: boolean;
  public collabLoading = false;
  public collabError: Error | null;
  private localStream: MediaStream;
  public updateCausesOrConsequences$ = new Subject<number>;
  public facilitatorId: string;
  public objectData: ICollabObjectData | undefined;

  public activeWorkingStep: string;

  public readonly audioEvent = new EventEmitter<{ userId: number | string; muted: boolean }>();
  public readonly sendAnswers$ = new Subject<string>();
  public readonly fetchState$ = new Subject<void>();
  public readonly updateImpactSelect$ = new Subject<void>();
  public readonly setStep$ = new Subject<number>();  
  public readonly resetVotes$ = new Subject<void>();
  public readonly completeCollab$ = new Subject<string>();
  public readonly expertVotesFetched$ = new Subject<void>();

  public updateAverage$ = new Subject<boolean>;

  public hostVotes: Record<string, any> = {};

  public identificationCasesCompleted = 0;
  public potentialCasesCompleted = 0;

  private sessionState = CollaborationSessionState.STOPPED;

  constructor(
    private readonly snackbar: SnackbarService,
    private readonly socketService: SocketService,
    private readonly rtcService: RtcService,
    private readonly streamService: StreamService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly httpService: HttpService,
    private readonly dialogService: DialogService
  ) {
    this.selfId = this.userService.idOfUser?.toString();
    this.clicked$ = socketService.clickListener().pipe(takeUntil(rtcService.endConnection$));
    router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(event => this.onCollabPage = (event)['urlAfterRedirects'].includes('collab/'));
  }

  get selfId() {
    if (!this._selfId) this.selfId = this.userService.idOfUser?.toString();
    return this._selfId?.toString() || this._storageSelfId?.toString() || '';
  }

  set selfId(val: string) {
    if (!val) sessionStorage.removeItem('naris4_storageSelfId');
    else this._storageSelfId = val;
    this._selfId = val;
  }

  get collabId() {
    return this._collabId?.toString() || this._storageCollabId?.toString() || '';
  }

  set collabId(val: number | string) {
    if (!val) sessionStorage.removeItem('naris4_storageCollabId');
    else this._storageCollabId = val;
    this._collabId = val;
  }

  get inSession() {
    return this._inSession ?? this._storageInSession;
  }

  set inSession(val: boolean | null) {
    if (!val) sessionStorage.removeItem('naris4_storageInSession');
    else this._storageInSession = val;
    this._inSession = val;
  }

  get sessionTime() {
    return this._sessionTime || this._storageSessionTime;
  }

  set sessionTime(val: string | null) {
    if (!val) sessionStorage.removeItem('naris4_storageSessionTime');
    else this._storageSessionTime = val;
    this._sessionTime = val;
  }

  get mySessionTime() {
    return this._mySessionTime || this._storageMySessionTime;
  }

  set mySessionTime(val: string | null) {
    if (!val) sessionStorage.removeItem('naris4_storageMySessionTime');
    else this._storageMySessionTime = val;
    this._mySessionTime = val;
  }
  get displayRoom() {
    return this._displayRoom || this._storageDisplayRoom;
  }

  set displayRoom(val: string) {
    if (!val) sessionStorage.removeItem('naris4_storageDisplayRoom');
    else this._storageDisplayRoom = val;
    this._displayRoom = val;
  }

  get currentRoom() {
    return this._currentRoom || this._storageCurrentRoom;
  }

  set currentRoom(val: string) {
    if (!val) sessionStorage.removeItem('naris4_storageCurrentRoom');
    else this._storageCurrentRoom = val;
    this._currentRoom = val;
  }

  get isHost() {
    return this._isHost ?? this._storageIsHost;
  }

  set isHost(val: boolean) {
    if (!val) sessionStorage.removeItem('naris4_storageIsHost');
    else this._storageIsHost = val;
    this._isHost = val;
  }

  get currentCaseId() {
    return this._currentCaseId?.toString() || this._storageCurrentCaseId?.toString() || '';
  }

  set currentCaseId(val: number | string | null) {
    if (!val) sessionStorage.removeItem('naris4_storageCurrentCaseId');
    else this._storageCurrentCaseId = val;
    this._currentCaseId = val?.toString() ?? null;
    this._collabState!['active'] ??= val?.toString() ?? null;
  }

  get currentCaseRecordId() {
    return this._currentCaseRecordId?.toString() || this._storageCurrentCaseRecordId?.toString() || '';
  }

  set currentCaseRecordId(val: number | string | null) {
    if (!val) sessionStorage.removeItem('naris4_storageCurrentCaseRecordId');
    else this._storageCurrentCaseRecordId = val;
    this._currentCaseRecordId = val?.toString() ?? null;
    this._collabState!['active'] ??= val?.toString() ?? null;
  }

  get collabState() {
    return !!this._collabState?.cases && !!Object.keys(this._collabState?.cases)?.length ? this._collabState : this._storageCollabState || { active: null, cases: {}, completed: [] };
  }

  get allFinalVotes() {
    const finalVotes = this.votes[this.currentCase?.id || ''] || {};
    return Object.keys(finalVotes).length === this.selectedCategories.length;
  }

  public async startCollab(roomName: string, facilitatorId?: string, setListeners = true) {
    this.resetCollabState();

    const stream = !this.streamService.localStream ? await this.streamService.openLocalStream() : this.streamService.localStream;
    this.localStream = stream!;
    this.isHost = facilitatorId === this.selfId;

    if (!!stream) {
      this.collabLoading = true;
      this.collabError &&= null;
      if (setListeners) this.setListeners();

      if (!this.socketService.connected) {
        this.socketService.openConnection().subscribe({
          next: () => this.setupCollab(roomName),
          error: () => {
            this.snackbar.open({ text: 'collab.dialog.text.no_socket', type: 'error' });
            void this.router.navigate(['/start']);
          }
        });
      } else {
        void this.setupCollab(roomName);
      }
    } else {
      this.isHost ? this.stopSession(true) : this.stopCollab(false, true);
    }
    this.sessionState = CollaborationSessionState.JOINED;
  }

  public setupOfflineCollab() {
    this.getSessionData(this.router.url).subscribe(async () => {
      this.collabOpened = true;
      this.getAssignments();
      await this.getIdentificationCases(true);
      await this.getPotentialCases(true);
      await this.getSaveState();
      this.objectData = await this.getCollabObjectData();
    });
  }

  private getAssignments() {
    this.httpService.get(this.assignmentsEndpoint).subscribe({
      next: res => {
        const facilitatorId: string = res.Assignments._embedded.results[0].CollaborationFacilitator.User || '';
        this.isHost = this.selfId === facilitatorId;
      }
    });
  }

  private async setupCollab(roomName: string) {
    this.collabId = this.router.routerState.snapshot.url.split('/')[3] ? this.router.routerState.snapshot.url.split('/')[3] : roomName.split('-').pop()!;
    this.displayRoom = this.router.routerState.snapshot.url.split('/')[3] ? roomName : roomName.split('-').shift()!;
    this.currentRoom = roomName.includes('%') ? roomName : encodeURIComponent(this.router.routerState.snapshot.url.split('/')[3] ? `${roomName}-${this.collabId}` : roomName);

    this.inSession = true;

    this.rtcService.enterRoom(this.currentRoom, this.isHost, this.selfId, this.userService.userData.Fullname);
    this.collabOpened = true;

    this.objectData = await this.getCollabObjectData();
    this.startTimer();
    await this.getIdentificationCases(true);
    await this.getPotentialCases(true);
    await this.getSaveState();
    // void this.router.navigate([`/collaboration/collaboration/${this.collabId}/session`]);

    if (this.isHost && !!this.assignmentsEndpoint) {
      this.httpService.get(this.assignmentsEndpoint).subscribe(res => {
        const experts: string[] = [this.selfId];
        res.Assignments._embedded.results.forEach((result: Record<string, any>) => {
          const collaborationExpertKey = Object.keys(result).find(key => key === 'CollaborationExpert');
          if (!!collaborationExpertKey) {
            const expert = result[collaborationExpertKey];
            experts.push(expert.User);
          }
        });
        this.socketService.setWhiteList(this.currentRoom, experts);
      });

    } else {
      this.startMyTimer();
    }
  }

  public stopCollab(host = false, streamFail = false) {
    if (this.sessionState === CollaborationSessionState.STOPPED) return;

    if (this.isHost) {
      this.socketService.sendCloseSession();
    } else this.httpService.post(this.leaveLiveSessionEndpoint, {}).subscribe();

    if (!streamFail) {
      this.rtcService.leaveRoom(this.currentRoom);
      this.inSession &&= null;
      this.collabOpened &&= false;
      this.experts = [];
      if (!host) {
        this.sessionTime &&= null;
        this.mySessionTime &&= null;
        this.currentCaseId &&= null;
        this.currentCaseRecordId &&= null;
        this.collabIdentificationCases &&= [];
        this.collabPotentialCases &&= [];
        this.resetCollabState();
      }
    }

    this.streamService.stopStream(this.localStream);
    this.socketService.closeConnection();

    void this.router.navigate(['/collaboration/']);
    // TODO: Remove stream tracks from every participant
    // TODO: Microphone is still open, refresh is needed

    this.sessionState = CollaborationSessionState.STOPPED;
  }

  public stopSession(streamFail = false) {
    if (!this.stopLiveSessionEndpoint) return;
    this.httpService.post(this.stopLiveSessionEndpoint, {}).pipe(
      finalize(() => {
        if (!streamFail) {
          this.stopCollab(this.isHost, streamFail);
          this.resetService();
          void this.router.navigate([`/collaboration/collaboration/${this.collabId}`]);
        }
      })
    ).subscribe();
  }

  private resetService() {
    sessionStorage.clear();
    this.collabCompleted = [];
    this.collabIdentificationCases = [];
    this.currentCase = undefined;
    this.currentCaseUrl = '';
    this.collabPotentialCases = [];
    this.messages = [];
    this.unreadMessages = 0;
    this.showChat = false;
    this.collabOpened = false;
    this.experts = [];
    this.selfMuted = false;
    this.categories = [];
    this.selectedCategories = [];
    this.votes = {};
    this.expertVotes = {};
    this.timer$ = timer(1000, 1000);
    this.collabLoading = false;
    this.collabError = null;
    this.hostVotes = {};
  }

  public startTimer() {
    const secondsSinceStart = Math.floor((new Date().getTime() - new Date(this.objectData!.sessionStartTime).getTime()) / 1000);
    this.timer$.pipe(
      takeUntil(this.rtcService.endConnection$),
      map(num => transformTime(num + secondsSinceStart))
    ).subscribe((time: string) => {
      this.sessionTime = time;
    });
    this.displayRoom = this.objectData!.name;
  }

  public startMyTimer() {
    this.timer$.pipe(
      takeUntil(this.rtcService.endConnection$),
      map(transformTime)
    ).subscribe((time: string) => {
      this.mySessionTime = time;
    });
  }

  public setListeners() {
    this.rtcService.peerUsers$.pipe(takeUntil(this.rtcService.endConnection$)).subscribe(data => {
      setTimeout(() => {
        this.getMissingExperts();
      });
      if (data.type === 'joined' && !!data.participant) {
        let addParticipant = true;
        for (const expert of this.experts) if (expert.name === data.participant.name) addParticipant = false;
        if (addParticipant) this.experts.push(data.participant);
      } else {
        const user = this.experts.find(p => p.id === data.userId);
        user?.leave();
        this.experts = this.experts.filter(p => p.id !== data.userId);
      }
      this.expertsUpdated$.next(this.experts);
      this.syncSession();
    });
    this.socketService.messageListeners().pipe(takeUntil(this.rtcService.endConnection$)).subscribe(data => {
      if (Array.isArray(data)) {
        this.messages = data;
      } else {
        this.messages.push({ ...data });
        this.unreadMessages = this.unreadMessages + 1;
      }
    });
    this.socketService.audioListener().pipe(takeUntil(this.rtcService.endConnection$)).subscribe(data => {
      if (data.userId === this.selfId) {
        this.selfMuted = data.muted;
      }
      const mutedParticipant = this.experts.find(p => p.id === data.userId);
      if (!!mutedParticipant) mutedParticipant.muted = data.muted;
    });
    this.socketService.stateListener().pipe(takeUntil(this.rtcService.endConnection$)).subscribe(this.setCollabState);
    this.socketService.stateRequestListener().pipe(takeUntil(this.rtcService.endConnection$)).subscribe(socketId => {
      if (!!this.currentCase) {
        const stateData = { socketId, state: this.collabState };
        this.socketService.sendState(stateData);
        this.sendSocket('categoryChange', this.collabState.cases[this.currentCase.id].categories?.map(c => c.key));
      }
    });
    this.socketService.voteListener().pipe(takeUntil(this.rtcService.endConnection$)).subscribe(data => {
      if (!!this.currentCase) {
        const { caseId, user, vote } = data;
        vote.user = user;
        const caseVotes = this.expertVotes[caseId];

        vote.impactLabel = this.collabState.cases[this.currentCase?.id || '']?.categories?.find((cat: Record<string, any>) => cat.key === +vote.VoteCategory.EventCategoryId)?.classes.find((catClass: Record<string, any>) => catClass.key === +vote.VoteCategory.ConsequenceClassID)?.value;

        if (!caseVotes) this.expertVotes[caseId] = { [vote.VoteCategory.EventCategoryId]: [vote] };
        else if (!caseVotes[vote.VoteCategory.EventCategoryId]) caseVotes[vote.VoteCategory.EventCategoryId] = [vote];
        else {
          const existing = caseVotes[vote.VoteCategory.EventCategoryId].findIndex(v => v.user?.id === user.id || v.user?.id === +user.id);
          if (existing >= 0) {
            caseVotes[vote.VoteCategory.EventCategoryId].splice(existing, 1, vote);
          } else {
            caseVotes[vote.VoteCategory.EventCategoryId].push(vote);
          }
        }

        let userVotes = 0;
        const caseCategories = this._collabState?.cases[this.currentCase.id]?.categories || [];

        for (const caseCategory of caseCategories) {
          if (this.expertVotes[caseId]?.[caseCategory.key]?.filter(v => v.user?.id === user.id).length > 0) {
            userVotes = userVotes + 1;
          }
        }

        // Set vote icon to green in participant panel
        if (userVotes === caseCategories.length) {
          const participant = this.experts.find(p => p.id === data.user.id);
          if (!!participant && !!this.currentCase.id) {
            participant.voted.push(this.currentCase.id.toString());
          }
        } else {
          const participant = this.experts.find(p => p.id === data.user.id);
          const index = participant!.voted.indexOf(this.currentCase.id?.toString() || '');
          if (!!participant && index >= 0) {
            participant.voted.splice(index, 1);
          }
        }
        // this.updateAverage$.next(true);
      }
    });
    this.socketService.joinListener().pipe(takeUntil(this.rtcService.endConnection$)).subscribe(() => {
      this.dialogService.open({
        type: 'alert',
        text: 'collab.dialog.text.no_host',
        title: 'collab.dialog.title.no_host'
      });

      this.stopCollab();
    });
    this.socketService.whitelistListener().pipe(takeUntil(this.rtcService.endConnection$)).subscribe(() => {
      this.dialogService.open({
        type: 'alert',
        text: 'collab.dialog.text.no_whitelist',
        title: 'collab.dialog.title.no_whitelist'
      });

      this.stopCollab();
    });
    this.socketService.sessionClosed().pipe(takeUntil(this.rtcService.endConnection$)).subscribe(() => {
      this.stopCollab();
      this.streamService.stopStream(this.localStream);
      this.socketService.closeConnection();
      this.snackbar.open({ text: 'collab.dialog.text.session_closed' });
    });
    this.socketService.hostDisconnected().pipe(takeUntil(this.rtcService.endConnection$)).subscribe(() => {
      this.snackbar.open({ text: 'collab.dialog.text.host_disconnected', type: 'error' });
    });
    this.socketService.hostConnected().pipe(takeUntil(this.rtcService.endConnection$)).subscribe(() => {
      this.snackbar.open({ text: 'collab.dialog.text.host_connected', type: 'success' });
    });
    this.socketService.currentCaseListener().pipe(takeUntil(this.rtcService.endConnection$)).subscribe(data => {
      this.currentCase = this.getCurrentCaseByUrl(data.currentCase);
      this.currentCaseUrl = data.currentCase;
      if (!!data.blindVote) this.blindVoting = data.blindVote.data;
      this.resetVotes$.next();
    });
    this.socketService.fetchStateListener().pipe(takeUntil(this.rtcService.endConnection$)).subscribe(() => {
      this.fetchState$.next();
    });
    this.socketService.collabCompleteListener().pipe(takeUntil(this.rtcService.endConnection$)).subscribe(data => {
      if (!this.isHost) {
        this.completeCollab$.next(data);
      }
    });
    this.socketService.syncSessionListener().pipe(takeUntil(this.rtcService.endConnection$)).subscribe(data => {
      if (!!data) {
        data.forEach((userId: any) => {
          const expert = this.experts.find(x => x.id === userId);
          if (!!expert) {
            if (expert.id === this.selfId) {
              expert.mute();
              this.selfMuted = expert.id === this.selfId;
            } else expert.muted = true;
          }
        });
      }
    });
  }

  public setAudio({ userId, muted }: { userId: number | string; muted: boolean }) {
    this.socketService.sendMuted(this.currentRoom, userId, muted);
  }

  public toggleSelf() {
    const self = this.experts.find(p => p.id === this.selfId);

    this.selfMuted ? self?.unmute() : self?.mute();
    this.selfMuted = !this.selfMuted;
    this.setAudio({ userId: this.selfId, muted: this.selfMuted });
  }

  public toChat() {
    this.showChat = true;
    this.unreadMessages = 0;
  }

  public submitMessage(event: any) {
    this.messages.push({
      msg: event,
      timestamp: Date.now(),
      read: true,
      user: { id: this._selfId, name: '', socketId: this.socketService.socketId, host: this.isHost }
    });
    this.socketService.sendMessage(this.currentRoom, event);
  }

  public sendSocket(event: string, data?: any) {
    if (event === 'categoryChange') this.selectedCategories = data;
    this.socketService.sendClick(this.currentRoom, { clicked: event, id: this.currentCase?.id, data });
  }

  public requestState() {
    this.socketService.sendStateRequest(this.currentRoom);
  }

  public kickParticipant(socketId: string) {
    this.socketService.sendClick(socketId, { clicked: 'kick' });
  }

  public getIdentificationCases(isFirst = false): Promise<void> {
    return new Promise<void>(resolve => {
      if (!!this.riskIdentificationsEndpoint)
        this.httpService.get(this.riskIdentificationsEndpoint).pipe(
          finalize(() => this.collabLoading = false)
        ).subscribe({
          next: (res: TCaseListResponse) => {
            const results = Object.values(res)[0]._embedded?.results;
            if (!results) {
              resolve();
              return;
            }
            const contributionsUrl = Object.values(res)[0]._links.contributions.href;
            if (!!contributionsUrl) {
              this.httpService.get(contributionsUrl).subscribe(contributionsResult => {
                const newIdentifications = results.map(mapping => this.mapResult(mapping, false)).filter(identification => !this.collabIdentificationCases.find(a => a.id === identification.id));
                this.setCausesAndConsequecesLabels(newIdentifications, contributionsResult);
                this.collabIdentificationCases.push(...newIdentifications);
                // this.toFirstNewIdentification();
                this.isHost ? this.setCollabState() : this.requestState();
                resolve();
                if (!isFirst && this.isHost) this.sendSocket('getIdentificationCases');
              });
            }
          },
          error: e => this.collabError = e
        });
    });
  } 

  private setCausesAndConsequecesLabels(identifications: CollabCase[], contributions: Record<string, any>[]) {
    const results = Object.values(contributions)?.[0].results as Record<string, any>;
    const attributes = Object.values(results)?.[0]?.attributes;
    const causeContributions = attributes?.find((attr: Record<string, any>) => Object.keys(attr)?.[0]?.toLowerCase().includes('cause'));
    const causeContributionsObjectId = !!causeContributions ? Object.keys(causeContributions)?.[0] : '';
    const consequenceContributions = attributes?.find((attr: Record<string, any>) => Object.keys(attr)?.[0]?.toLowerCase().includes('consequence'));
    const consequenceContributionsObjectId = !!consequenceContributions ? Object.keys(consequenceContributions)?.[0] : '';

    identifications.forEach(identification => {
      let labels: { key: string, label: string }[] = [];
      if (!identification?.causes?.data?.length) return;
      identification.causes.data.forEach((cause: Record<string, any>) => {
        const keys = Object.keys(cause)?.filter(key => !['_id'].includes(key));
        keys.forEach(key => {
          if (!labels.find(label => label.key === key)) {
            const foundCont = causeContributions[causeContributionsObjectId]?.children.find((child: Record<string, any>) => Object.keys(child)?.[0] === key);
            if (!!foundCont) {
              labels.push({ key, label: foundCont[key].label });
            }
          }
        });
      });
      identification.causes.labels = labels;

      labels = [];
      if (!identification?.consequences?.data?.length) return;
      identification.consequences.data.forEach((consequence: Record<string, any>) => {
        const keys = Object.keys(consequence)?.filter(key => !['_id'].includes(key));
        keys.forEach(key => {
          if (!labels.find(label => label.key === key)) {
            const foundCont = consequenceContributions[consequenceContributionsObjectId]?.children.find((child: Record<string, any>) => Object.keys(child)?.[0] === key);
            if (!!foundCont) {
              labels.push({ key, label: foundCont[key].label });
            }
          }
        });
      });
      identification.consequences.labels = labels;
    });
  }

  public getPotentialCases(isFirst = false): Promise<void> {
    return new Promise<void>(resolve => {
      if (!!this.potentialRisksEndpoint)
        this.httpService.get(this.potentialRisksEndpoint).pipe(
          finalize(() => this.collabLoading = false)
        ).subscribe({
          next: (res: TCaseListResponse) => {
            const results = Object.values(res)[0]._embedded?.results;
            if (!results) {
              resolve();
              return;
            }
            const newPotentials = results.map(mapping => this.mapResult(mapping, true)).filter(potential => !this.collabPotentialCases.find(a => a.id === potential.id));
            this.collabPotentialCases.push(...newPotentials);
            // this.toFirstNewPotential();
            this.isHost ? this.setCollabState() : this.requestState();
            resolve();
            if (!isFirst && this.isHost) this.sendSocket('getPotentialCases');
          },
          error: e => this.collabError = e
        });
    });
  } 

  private readonly mapResult = (result: TCaseListRows, potential: boolean) => {
    const collAssess = Object.values(result)[0];
    const caseRecordId = collAssess._id;
    const actions = collAssess.actions;
    const links = collAssess._links;
    const caseId = caseRecordId.toString() + (potential ? '-potential' : '-identification');//generateGUID();
    let caseLabel = 'Not found';
    let initialState = null;
    let riskIdentificationState = '';
    const causes: { data?: any, labels: { key: string, label: string }[] | null } = { labels: null };
    const consequences: { data?: any, labels: { key: string, label: string }[] | null } = { labels: null };
    for (const key in collAssess) {
      if (key.toLowerCase().includes('name')) caseLabel = collAssess[key];
      if (key.toLowerCase().includes('statejson')) initialState = collAssess[key];
      if (key.toLowerCase().includes('causes')) causes.data = collAssess[key];
      if (key.toLowerCase().includes('consequences')) consequences.data = collAssess[key];
      if (key.toLowerCase().includes('state')) riskIdentificationState = collAssess[key];
    }

    const listUrl = potential ? this.potentialRisksEndpoint : this.riskIdentificationsEndpoint;
    initialState &&= JSON.parse(initialState);
    const initState = this.collabState.cases[caseId] || initialState;
    const newCase = new CollabCase(caseId, caseRecordId, caseLabel, listUrl, initState, causes, consequences, riskIdentificationState, actions, links);
    return newCase;
  };

  public addVote(vote: IVote, isHost = false) {
    if (!this.votes[this.currentCase?.id || '']) this.votes[this.currentCase?.id || ''] = {};

    if (isHost) {
      if (!this.hostVotes[this.currentCase?.id || '']) this.hostVotes[this.currentCase?.id || ''] = new Set();
      this.hostVotes[this.currentCase?.id || ''].add(vote.VoteCategory!.EventCategoryId);
    } else {
      this.votes[this.currentCase?.id || ''][vote.category?.key || ''] = {
        likelihood: vote.likelihood!,
        impact: vote.impact!
      };
    }
    this.socketService.sendVote(this.currentRoom, { caseId: this.currentCase?.id || '', vote });
  }

  public setCollabState = (state?: ICollabState) => {
    if (!!state) {
      this._collabState = state;
      this._storageCollabState = state;
      this.currentCaseId = state.active!;
      this.collabIdentificationCases = this.collabIdentificationCases.map(c => {
        c.caseState = state.cases[c.id] || c.caseState;
        return c;
      });
      this.collabPotentialCases = this.collabPotentialCases.map(c => {
        c.caseState = state.cases[c.id] || c.caseState;
        return c;
      });
    } else {
      const cases = { ...this.collabState.cases };
      const completed = [...this.collabState.completed];

      this.collabIdentificationCases.filter(cc => !this.collabState.cases[cc.id]).forEach(c => {
        cases[c.id] = c.caseState;
        if (c.completed) completed.push(c.id);
      });
      this.collabPotentialCases.filter(cc => !this.collabState.cases[cc.id]).forEach(c => {
        cases[c.id] = c.caseState;
        if (c.completed) completed.push(c.id);
      });
      const clbState = { active: this.currentCase?.id, cases, completed } as ICollabState;
      this._collabState = clbState;
      this._storageCollabState = clbState;
    }
  };

  // public removePotential(recordId: number | string) {
  //   const url = `/collaboration/collaboration/${this.collabId}/risks/collaboration-risks/${recordId}/delete-temporary-risks`;
  //   return this.httpService.post(url, {});
  // }

  public getCollabFacilitator() {
    return new Promise<void>(resolve => {
      if (!!this.assignmentsEndpoint)
        this.httpService.get(this.assignmentsEndpoint).subscribe({
          next:
            res => {
              this.facilitatorId = res.Assignments._embedded.results[0].CollaborationFacilitator.User || '';
              this.isHost = this.facilitatorId === this.selfId;
              this.inSession = true;
              resolve();
            }
        });
    });
  }

  public postCollabState(caseId: string) {
    const collabCase = this.collabIdentificationCases.find(c => c.id === caseId);
    const url = collabCase?.actions?.find(action => action.name === 'update-status')?.href;
    if (!url) return;
    const body = {
      tokens: [],
      objects: {
        Attributes: {
          StateJSON: JSON.stringify(this.collabState.cases[caseId])
        }
      }
    };
    this.httpService.post(url, body).subscribe();
  }

  public postVotes(caseId: string) {
    const collabCase = this.collabIdentificationCases.find(c => c.id === caseId);
    const url = collabCase?.actions?.find(action => action.name === 'finish-vote')?.href;
    if (!url) return;
    const body = {
      tokens: [],
      objects: {
        JSONWithLikelihoodAndImpactValues: {
          JSON: JSON.stringify(this.votes[this.currentCase?.id || ''])
        }
      }
    };
    return this.httpService.post(url, body);
  }


  private resetCollabState() {
    this._collabState = { active: null, cases: {}, completed: [] };
    this._storageCollabState = { active: null, cases: {}, completed: [] };
  }


  //------------------------- new -------------------------//
  public assignmentsEndpoint: string;
  public potentialRisksEndpoint: string;
  public riskIdentificationsEndpoint: string;
  public expertsNotInSessionEndpoint: string;
  public objectEndpoint: string;
  public taskgroup: ITaskGroup[];
  private stopLiveSessionEndpoint: string;
  private leaveLiveSessionEndpoint: string;
  public readStateEndpoint: string;
  public updateStateEndpoint?: string;
  public blindVoting = false;
  public getSessionData(url: string) {
    return this.httpService.get(url).pipe(
      tap(res => {
        const elementId = Object.keys(res)?.[0];
        if (!!elementId) {
          const links = res[elementId]._links;
          if (!links) return;
          this.assignmentsEndpoint = links.Assignments.href;
          this.potentialRisksEndpoint = links.PotentialRisksSession.href;
          this.riskIdentificationsEndpoint = links.RiskIdentifications.href;
          this.expertsNotInSessionEndpoint = links.CollaborationExpertsNotInSession?.href;
          this.objectEndpoint = links.Object.href;
          this.taskgroup = links.taskgroup;
          this.taskgroup.forEach(tasks => {

            if (!!tasks.href)
              this.httpService.get(tasks.href).subscribe(result => {
                const actions = result.taskgroup.actions;
                this.stopLiveSessionEndpoint = actions.find((action: IAction) => action.name === 'stop-live-session')?.href;
                this.readStateEndpoint = actions.find((action: IAction) => action.name === 'read-state')?.href;
                this.updateStateEndpoint = actions.find((action: IAction) => action.name === 'update-state')?.href;
                this.leaveLiveSessionEndpoint = actions.find((action: IAction) => action.name === 'leave-live-session')?.href;
              });
          });
        }
      })
    );
  }

  public updateCurrentCaseState(state: string) {
    let caseToUpdate: CollabCase | undefined = undefined;
    if (this.collabIdentificationCases.length > 0) caseToUpdate = this.collabIdentificationCases.find(x => x === this.currentCase);
    else if (this.collabPotentialCases.length > 0) caseToUpdate = this.collabPotentialCases.find(x => x === this.currentCase);
    if (!!caseToUpdate) {
      if (state === 'Completed') {
        caseToUpdate.completed = true;
        caseToUpdate.intermediate = false;
        caseToUpdate.caseState.state = 2;
        if (this.isHost) this.collabState.completed.push(caseToUpdate.id);
      } else if (state === 'Intermediate') {
        caseToUpdate.intermediate = true;
        caseToUpdate.completed = false;
        caseToUpdate.caseState.state = 1;
      }
    }
  }

  public getCurrentCaseByUrl(url: string): CollabCase {
    let currentCase = this.collabIdentificationCases.find(x => x.links?.self.href === url);
    if (!currentCase) currentCase = this.collabPotentialCases.find(x => x.links?.self.href === url);
    return currentCase!;
  }

  public setCurrentCase(caseUrl: string) {
    this.socketService.setCurrentCase(this.currentRoom, caseUrl);
  }

  public getCollabObjectData(): Promise<ICollabObjectData> {
    return new Promise(resolve => {
      const url = `/collaboration/collaboration/${this.router.url.split('/')[3]}`;
      this.httpService.get(url).subscribe({
        next: res => {
          resolve({
            state: res.Collaboration.CaseState,
            description: res.Collaboration.CollaborationDescription,
            name: res.Collaboration.CollaborationName,
            number: res.Collaboration.CollaborationNumber,
            context: {name: res.Collaboration.PrimaryContext.Name, type: res.Collaboration.PrimaryContext.Type},
            sessionStartTime: res.Collaboration.SessionStartTime
          });
        }
      });
    });
  }

  public fetchSaveState() {
    this.socketService.sendFetchSaveState(this.currentRoom);
  }

  public getSaveState(): Promise<void> {
    return new Promise<void>(resolve => {
      if (!!this.readStateEndpoint) {
        this.httpService.post(this.readStateEndpoint, {}).subscribe({
          error: res => {
            if (!!res.error.formresponse?.missing?.anchors[0].elements[0].suggestion) {
              const savedState = JSON.parse(res.error.formresponse.missing.anchors[0].elements[0].suggestion);
              if (!!savedState) {
                const cases = this.collabPotentialCases.concat(this.collabIdentificationCases);
                this.collabState.completed = [];
                cases.forEach(x => {
                  const caseState = savedState![x.id];
                  if (!!caseState) {
                    if (caseState.state === 'Completed') {
                      x.completed = true;
                      x.intermediate = false;
                      x.caseState.state = 2;
                      if (this.isHost) this.collabState.completed.push(x.id);
                    } else if (caseState.state === 'Intermediate') {
                      x.intermediate = true;
                      x.completed = false;
                      x.caseState.state = 1;
                    }
                  }
                  x.steps.forEach((step, index) => {
                    const stepState = savedState![x.id]?.steps[index];
                    if (!!stepState) {
                      if (index === 0 ) {
                        this.blindVoting = stepState.blindVoting;
                      }
                      if (stepState.state === 'Completed') {
                        step.completed = true;
                        step.intermediate = false;
                        x.caseState.steps[index] = 2;
                      } else if (stepState.state === 'Intermediate') {
                        step.intermediate = true;
                        step.completed = false;
                        x.caseState.steps[index] = 1;
                      }
                    }
                  });
                });
              }
            }
            this.setActiveCase();
            resolve();
          }
        });
      } else resolve();
    });
  }


  public setActiveCase() {
    const cases = this.collabPotentialCases.concat(this.collabIdentificationCases);
    const collabCase = cases.find(x => !x.completed);
    if (!!collabCase) {
      this.currentCase = collabCase;
      let url = collabCase.url;
      if (collabCase.url.endsWith('potential-risks') || collabCase.url.endsWith('current-risk-identifications')) url = `${url}/${collabCase.recordId}`;
      if (this.isHost) this.setCurrentCase(url);
    }
  }

  get allCasesCompleted(): boolean {
    return !this.collabPotentialCases.concat(this.collabIdentificationCases).some(collabCase => !collabCase.completed);
  }

  public getMissingExperts() {
    if (!!this.expertsNotInSessionEndpoint) {
      this.httpService.get(this.expertsNotInSessionEndpoint).subscribe({
        next: (res: any) => {
          const results = res.CollaborationExperts_NotInSession._embedded?.results;
          this.missingParticipants = [];
          if (!!results?.length) {
            const objName = 'CollaborationExperts_View';
            const experts: {name: string; initials: string; action: IAction, id: number; invited: boolean}[] = []; 
            results.forEach((result: any) => {
              const initials = getInitials(result[objName].Name);
              experts.push({name: result[objName].Name, initials: initials, action: this.isHost ? result[objName].actions[0] : undefined, id: result[objName].UserID, invited: false});
            });
            this.missingParticipants = experts;
          } 
        }
      });
    }
  }

  public collabComplete() {
    this.socketService.sendCollabComplete(this.currentRoom, this.currentCase!.id);
  }

  public syncSession() {
    this.socketService.sendSyncSession(this.currentRoom);
  }

}
