
import { BASE_URL } from '@core/constants/http-constants';
/* eslint-disable import/no-commonjs */
/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable @typescript-eslint/no-var-requires */

export const environment = {
  version: require('../../package.json').version,
  production: true,
  vapid_public_key: 'BBBq-edzTaCU4W-T9HFpqKPwmWmq31kcoZaghzWeWeVUPcRpwevkRjuUBIX-hb5XHjeQd_c1BTJmQjpbpyOWpnQ',
  clientId: 'a3169538-ea0f-42ff-ba5c-31b6c8d55e81',
  redirectUri: `${BASE_URL}/open-case/b2c`,
  scope: 'openid profile email',
  response_type: 'id_token',
  b2cUri: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  logoffUri: 'https://login.windows.net/common/oauth2/logout',
  iceServers: [
    {urls: 'stun:srv02.naris-grc.com:80'},
    {urls: 'turn:srv02.naris-grc.com:80', username: 'gSTw57s6FaWk', credential: 'DwSWp)r?7J[L%xfz'}
  ],
  socketOptions: {auth: {token: 'Naris', pw: 'Naris'}, secure: true, autoConnect: true, reconnection: true},
  socketUrl: 'https://srv01.naris-grc.com:443/collaborate',
  auditSocketUrl: 'https://srv01.naris-grc.com:443/audit',
  auditSocketOptions: {auth: {token: 'Naris', pw: 'Naris'}, secure: true, autoConnect: true, reconnection: true},
  mapsApiKey: 'AIzaSyAQgnZYOTwVHl_K1CjAFj0hELN_1RpDPSs',
  datafeedBaseUrl: 'https://datafeed-dev.naris-grc.com'
};
