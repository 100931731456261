import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormInput, PeriodInput } from '@core/classes';
import { TranslateModule } from '@ngx-translate/core';
import { InlineInputComponent } from '../inline-input/inline-input.component';
import { InlineSelectComponent } from '../inline-select/inline-select.component';

@Component({
  selector: 'naris-period',
  templateUrl: './period.component.html',
  styleUrl: './period.component.scss',
  standalone: true,
  imports: [InlineInputComponent, InlineSelectComponent, TranslateModule]
})
export class PeriodComponent implements OnInit {
  
  @Input()
  public input: FormInput;
  
  @Input()
  public control: FormControl;
  
  @Input()
  public id: string;
  
  public amountControl = new FormControl();
  
  public scaleControl = new FormControl();

  public scaleOptions: { key: string; label: string }[];
  public firstOption: string;

  private controlValue: any = {};
  
  public ngOnInit(): void {
    this.setOptions();

    this.setValueChangeSubscriptions();

    this.setInitialValues();
  }

  private setOptions() {
    const foundScaleInput = (this.input as PeriodInput).periodElements.find(periodElement => periodElement.id.toLowerCase().endsWith('scale'));
    if (!!foundScaleInput) {
      this.scaleOptions = foundScaleInput.options.map((option: {code: string, key: string, label: string}) => ({key: option.code || option.key, label: option.label}));
      this.firstOption = !!this.scaleOptions?.length ? this.scaleOptions[0].key : '';
    }
  }

  private setValueChangeSubscriptions() {
    this.amountControl.valueChanges.subscribe(amountValue => {
      const foundAmountInput = (this.input as PeriodInput).periodElements.find(periodElement => periodElement.id.toLowerCase().endsWith('amount'));
      if (!!foundAmountInput) {
        this.controlValue[foundAmountInput.id] = amountValue;
        this.control.setValue(this.controlValue);
      }
    });

    this.scaleControl.valueChanges.subscribe(scaleValue => {
      const foundScaleInput = (this.input as PeriodInput).periodElements.find(periodElement => periodElement.id.toLowerCase().endsWith('scale'));
      if (!!foundScaleInput) {
        this.controlValue[foundScaleInput.id] = scaleValue;
        this.control.setValue(this.controlValue);
      }
    });
  }

  private setInitialValues() {
    const foundAmountInput = (this.input as PeriodInput).periodElements.find(periodElement => periodElement.id.toLowerCase().endsWith('amount'));
    if (!!foundAmountInput?.value) {
      this.amountControl.setValue(foundAmountInput.value);
    }

    const foundScaleInput = (this.input as PeriodInput).periodElements.find(periodElement => periodElement.id.toLowerCase().endsWith('scale'));
    if (!!foundScaleInput?.value) {
      this.scaleControl.setValue(foundScaleInput.value);
    }
  }

}
