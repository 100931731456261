import { inherits as e, createSuper as s, classCallCheck as t, defineProperty as a, assertThisInitialized as r } from "../_virtual/_rollupPluginBabelHelpers.js";
import { AbstractEntry as i } from "./abstract-entry.js";
import { TYPE_ELEMENT as l } from "./defs.js";
var n = function (i) {
  e(o, i);
  var n = s(o);
  function o(e, s, i, p, c) {
    var m;
    t(this, o);
    m = n.call(this, s);
    m.templateResolver = i;
    m.stack = p;
    m.parseIdStack = c;
    a(r(m), "type", l);
    a(r(m), "namespace", void 0);
    a(r(m), "atts", void 0);
    a(r(m), "custom", void 0);
    var v = e.el.split(":");
    m.tag = e.el;
    if (v.length === 2) {
      m.namespace = v[0];
    }
    m.atts = e.atts;
    m._processBindings(e.bindings);
    m.remove = false;
    return m;
  }
  return o;
}(i);
export { n as ElementEntry };