import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { AUTH_TIMEOUT } from '@core/constants';
import { EAuthContext } from '@core/enums';
import { generateNonce } from '@core/helpers';
import { AuthService, LanguageService, UserService } from '@core/services';
import { BreadcrumbTabsService } from '@core/services/breadcrumb-tab.service';
import { environment } from '@src/environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import { CardComponent } from '../../../shared/components/card/card.component';
import { ButtonComponent } from '../../../shared/elements/button/button.component';
import { DividerComponent } from '../../../shared/elements/divider/divider.component';
import { FormFieldComponent } from '../../../shared/components/form-field/form-field.component';
import { InputComponent } from '../../../shared/elements/input/input.component';
import { FormActionsComponent } from '../../../shared/components/form-actions/form-actions.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [CardComponent, ButtonComponent, DividerComponent, FormsModule, ReactiveFormsModule, FormFieldComponent, InputComponent, FormActionsComponent, RouterLink, TranslateModule]
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public loading = false;

  constructor(
    public readonly langService: LanguageService,
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly breadcrumbTabs: BreadcrumbTabsService
  ) {}

  public ngOnInit(): void {
    this.authService.authContext = EAuthContext.Form;
    if (!!this.authService.auth) this.authService.logout().subscribe();
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });
  }

  /**
   * Form submit
   */
  public onSubmit = (): void => {
    if (this.loginForm.status !== 'VALID') return;
    this.loading = true;
    this.authService.login().subscribe({
      next: () => this.authenticate(this.loginForm.value.email, this.loginForm.value.password),
      error: () => this.loading = false
    });
  };

  private authenticate(email: string, password: string) {
    this.userService.userOrgs = null;
    this.authService.postSecurityCheck(email, password).subscribe({
      next: () => {
        this.breadcrumbTabs.clear();
        this.authService.auth = Date.now() + AUTH_TIMEOUT;
        this.userService.fetchUserData().subscribe(res => {
          const defaultOrg = res.userdata?.DefaultOrganizationID;
          const multiOrg = res.userdata?.sessiondata?.MultipleOrganizations === 'true';
          if (res.userdata.Roles?.includes('Administrator') || !multiOrg)
            void this.router.navigate([this.authService.redirectUrl || '/start']);
          else this.userService.getUserOrgs().subscribe(() => {
            if (!!defaultOrg) {
              this.userService.organizationID = defaultOrg;
              this.userService.defaultOrg = defaultOrg;
              this.userService.submitOrganization(defaultOrg, null);
            } else void this.router.navigate(['/org']);
          });
        });
      },
      error: () => {
        this.authService.auth = null;
        this.loading = false;
      }
    });
  }

  public onClickB2C() {
    const nonce = generateNonce();
    this.authService.nonce = nonce;
    const url = new URL(environment.b2cUri);
    url.searchParams.set('client_id', environment.clientId);
    url.searchParams.set('redirect_uri', environment.redirectUri);
    url.searchParams.set('scope', environment.scope);
    url.searchParams.set('response_type', environment.response_type);
    url.searchParams.set('nonce', nonce);
    window.open(url.toString(), '_self');
  }

  public getFormControl(controlName: string) {
    return this.loginForm.get(controlName) as FormControl;
  }
}
