import { createClass as e, classCallCheck as t, defineProperty as a } from "../_virtual/_rollupPluginBabelHelpers.js";
import { ShapeLibraryDefaults as r, CLASS_SHAPE as i } from "./shape-library-definitions.js";
import { forEach as o } from "../ui-core/util/util.js";
import { Group as s } from "../core/model/graph.js";
import { newRecadoInstance as n } from "../browser-ui-templates-2/browser-ui-recado.js";
import { ATTR_IS_GROUP as l, SurfaceDropManager as d } from "../drop/jsplumbtoolkit-drop.js";
import { cls as c, BLOCK as p, NONE as h } from "../ui-core/core/constants.js";
import { TRUE as v } from "../ui-core/common/index.js";
import { svg as u, ELEMENT_SVG as f } from "../ui-core/browser-ui-renderer/svg-util.js";
var m = 150;
var g = 100;
var b = "jtk-shape-library-palette-set";
var y = "jtk-shape-library-palette";
var S = "jtk-shape-library-palette-label";
var _ = "jtk-shape-library-palette-filter";
var A = "jtk-shape-library-palette-shape";
var w = "jtk-shape-library-palette-set-title";
var j = "jtk-shape-library-palette-selected-shape";
var k = "jtk-shape-library-palette-lasso";
var x = "data-category";
var C = "data-type";
var z = "shape-icon";
var L = "mainTemplate";
var $ = "setTemplate";
var V = "__jtkALL";
function D() {
  var e;
  var t = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "Show all";
  return e = {}, a(e, $, '<div class="'.concat(b, '" data-category="{{id}}">\n                            <r-each in="shapes"><shape-icon/></r-each>\n                         </div>')), a(e, L, '<div class="'.concat(y, '">\n                            <r-if test="sets.length > 1">\n                                <select class="').concat(_, '">\n                                    <option value="').concat(V, '">').concat(t, '</option>\n                                    <r-each in="sets">\n                                        <option value="{{id}}">{{name}}</option>\n                                    </r-each>\n                                </select>\n                            </r-if>\n                            <r-each in="sets">\n                                <div class="').concat(w, '" data-category="{{id}}">{{name}}</div>\n                                <r-tmpl id="').concat($, '"/>\n                            </r-each>\n                         </div>')), e;
}
var T = 1;
var E = 2;
var G = function () {
  function b(e) {
    var o = this;
    t(this, b);
    a(this, "_$_templateEngine", void 0);
    a(this, "_$_dropManager", void 0);
    a(this, "_$_userDataGenerator", void 0);
    a(this, "onVertexAdded", void 0);
    a(this, "palette", void 0);
    var p = e.surface;
    var h = p.getShapeLibrary() || e.shapeLibrary;
    var y = e.container;
    var w = e.paletteStrokeWidth || T;
    e.canvasStrokeWidth || E;
    var j = e.iconSize || {
      w: m,
      h: g
    };
    this.onVertexAdded = e.onVertexAdded;
    this._$_userDataGenerator = e.dataGenerator;
    e.dragSize = e.dragSize || Object.assign({}, j);
    e.fill = e.fill || r.FILL;
    e.outline = e.outline || r.STROKE;
    this._$_templateEngine = n({
      templates: D(e.showAllMessage)
    });
    var $ = '<div class="'.concat(A, '" data-type="{{type}}"><svg:svg viewBox="0 0 ').concat(j.w, " ").concat(j.h, '" preserveAspectRatio="none" stroke-width="').concat(w, '"/>') + (e.showLabels ? '<div class="'.concat(S, '">{{label}}</div>') : "") + "</div>";
    this._$_templateEngine.registerTag(z, {
      template: $,
      rendered: function e(t, a, r, i) {
        var o = a.type;
        var n = i.getAttribute(x);
        t.setAttribute(x, n);
        if (a.objectType === s.objectType) {
          t.setAttribute(l, v);
        }
        t.setAttribute("title", a.label || "");
        var d = h.renderCompiledShape({
          type: o,
          category: n,
          width: j.w,
          height: j.h
        }, w);
        t.childNodes[0].appendChild(d);
      },
      updated: function e(t, a, r) {}
    });
    var V = this._$_templateEngine.template(L, {
      sets: h.shapeSetList
    });
    this.palette = V.childNodes[0];
    y.appendChild(this.palette);
    this._$_dropManager = new d({
      surface: p,
      source: this.palette,
      selector: c(A),
      dragSize: e.dragSize,
      mode: e.mode,
      allowClickToAdd: true,
      lassoClass: k,
      vertexPreviewGenerator: function e(t, r, o, s, n) {
        var l;
        var d = Object.assign(o, (l = {}, a(l, p._modelWidthAttribute, 5), a(l, p._modelHeightAttribute, 5), l));
        var c = u.node(f, {
          preserveAspectRatio: "none",
          viewBox: "0 0 5 5"
        });
        c.classList.add(i);
        var v = h.renderCompiledShape(d);
        c.appendChild(v);
        return c;
      },
      vertexPreviewUpdater: function e(t, r, i, o) {
        var s;
        var n = h.renderCompiledShape(Object.assign(o, (s = {}, a(s, p._modelWidthAttribute, i.w), a(s, p._modelHeightAttribute, i.h), s)));
        t.replaceChildren(n);
        t.setAttribute("viewBox", "0 0 ".concat(i.w, " ").concat(i.h));
      },
      dataGenerator: function t(a) {
        var r = o._$_userDataGenerator ? o._$_userDataGenerator(a) || {} : {};
        var i = a.getAttribute(C),
          s = a.getAttribute(x),
          n = h.getShapeDefinition(i, s),
          l = r.fill,
          d = r.outline;
        var c = r.width || n.square ? Math.min(e.dragSize.w, e.dragSize.h) : e.dragSize.w;
        var p = r.height || n.square ? Math.min(e.dragSize.w, e.dragSize.h) : e.dragSize.h;
        Object.assign(r, {
          type: i,
          category: s,
          width: c,
          height: p,
          fill: e.fill,
          outline: e.outline
        });
        if (l != null) {
          r.fill = l;
        }
        if (d != null) {
          r.outline = d;
        }
        return r;
      },
      allowDropOnEdge: false,
      allowDropOnNode: true,
      onVertexAdded: function t(a, r) {
        if (e.selectAfterDrop !== false) {
          setTimeout(function () {
            return p.toolkitInstance.setSelection(a);
          });
        }
        o.onVertexAdded && o.onVertexAdded(a, r);
      }
    });
    var G = this.palette.querySelector(c(_));
    if (G) {
      G.addEventListener("change", function (e) {
        var t = G.options[G.selectedIndex].value;
        o._setVisibleSet(t);
      });
      if (e.initialSet) {
        G.value = e.initialSet;
        this._setVisibleSet(e.initialSet);
      }
    }
  }
  e(b, [{
    key: "_setVisibleSet",
    value: function e(t) {
      this.palette.querySelector(c(_));
      var a = Array.from(this.palette.querySelectorAll(c(A)));
      var r = Array.from(this.palette.querySelectorAll(c(w)));
      if (t === V) {
        o(a, function (e) {
          e.style.display = p;
        });
        o(r, function (e) {
          e.style.display = p;
        });
      } else {
        o(a, function (e) {
          e.style.display = e.getAttribute(x) === t ? p : h;
        });
        o(r, function (e) {
          e.style.display = h;
        });
      }
    }
  }]);
  return b;
}();
export { y as CLASS_PALETTE, j as CLASS_PALETTE_SELECTED_SHAPE, b as CLASS_PALETTE_SET, _ as CLASS_PALETTE_SET_FILTER, w as CLASS_PALETTE_SET_TITLE, A as CLASS_PALETTE_SHAPE, S as CLASS_PALETTE_SHAPE_LABEL, k as CLASS_PALETTE_VERTEX_DRAWING_LASSO, G as ShapeLibraryPalette };