var e = "anchor:changed";
var r = "connection";
var n = "internal.connection";
var a = "connection:detach";
var o = "connection:move";
var d = "endpoint:replaced";
var v = "internal.endpoint:unregistered";
var c = "internal.connection:detached";
var t = "group:added";
var p = "group:collapse";
var i = "group:expand";
var g = "group:member:added";
var m = "group:member:removed";
var u = "group:removed";
var l = "maxConnections";
var s = "group:nested:added";
var h = "group:nested:removed";
var x = "zoom";
export { e as EVENT_ANCHOR_CHANGED, r as EVENT_CONNECTION, a as EVENT_CONNECTION_DETACHED, o as EVENT_CONNECTION_MOVED, d as EVENT_ENDPOINT_REPLACED, t as EVENT_GROUP_ADDED, p as EVENT_GROUP_COLLAPSE, i as EVENT_GROUP_EXPAND, g as EVENT_GROUP_MEMBER_ADDED, m as EVENT_GROUP_MEMBER_REMOVED, u as EVENT_GROUP_REMOVED, n as EVENT_INTERNAL_CONNECTION, c as EVENT_INTERNAL_CONNECTION_DETACHED, v as EVENT_INTERNAL_ENDPOINT_UNREGISTERED, l as EVENT_MAX_CONNECTIONS, s as EVENT_NESTED_GROUP_ADDED, h as EVENT_NESTED_GROUP_REMOVED, x as EVENT_ZOOM };