<div class="options" [attr.data-test-id]="'answer-toggle__'+input.id">
  @for (option of options; track $index) {
    <div 
      tabindex="0"
      class="options__option" 
      [class.active]="option.active"
      [class.last]="$index === options.length -1"
      [class.disabled]="control.disabled"
      (click)="setOptionActive(option)"
    >
      @if (option.active) {
        <naris-icon [icon]="'check'" [iconClass]="'small'" />
      }
      {{option.label | translate}}
    </div>
  }
</div>