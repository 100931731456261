import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormService } from '@core/services';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { CdkOverlayOrigin, CdkConnectedOverlay } from '@angular/cdk/overlay';
import { InputComponent } from '../../elements/input/input.component';
import { ColorPickerComponent } from '../color-picker/color-picker.component';

@Component({
  selector: 'naris-color-input',
  templateUrl: './color-input.component.html',
  styleUrls: ['./color-input.component.scss'],
  standalone: true,
  imports: [InputComponent, CdkOverlayOrigin, CdkConnectedOverlay, ColorPickerComponent]
})
export class ColorInputComponent implements OnInit {

  @Input() public control: FormControl;
  @Input() public input: any;
  @Input() public id: string;
  public chosenHue: string;
  public showPicker = false;
  public pickedColor: string;

  constructor(
    private readonly formService: FormService
  ) { }

  public ngOnInit() {
    this.pickedColor = this.control?.value;
    this.chosenHue = this.control?.value;
    this.control.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(500),
      filter(val => !val || val.length > 3)
    ).subscribe(val => {
      this.pickedColor = val;
      this.chosenHue = val;
    });
    this.formService.closeColorPicker$.subscribe(() => this.showPicker = false);
  }

  public onColorPicked(evt: string) {
    this.pickedColor = evt;
    this.control.setValue(evt, {emitEvent: false});
  }
}
