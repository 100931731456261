@defer (when !loading) {
  @if (url.includes('dashboard')) {
    <app-dynamic-dashboard [data]="dashboardResult" />
  } @else {
    <naris-grid>
    @for (panel of panels; track $index) {
        <naris-grid-item>
          <naris-panel [panel]="panel" />
        </naris-grid-item>
    }
    </naris-grid>
  }
}
