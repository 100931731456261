import { inherits as e, createSuper as r, classCallCheck as t, defineProperty as a, assertThisInitialized as i } from "../_virtual/_rollupPluginBabelHelpers.js";
import { ElementEntry as n } from "./element-entry.js";
import { TYPE_EACH as s } from "./defs.js";
import { Elements as o } from "./elements.js";
import { forEach as l } from "../ui-core/util/util.js";
var p = function (n) {
  e(l, n);
  var o = r(l);
  function l(e, r, n, p, u) {
    var c;
    t(this, l);
    c = o.call(this, e, r, n, p, u);
    a(i(c), "key", void 0);
    a(i(c), "type", s);
    c.context = e.atts["in"];
    c.key = e.atts["key"];
    c.tag = l.tag;
    return c;
  }
  return l;
}(n);
a(p, "tag", "r-each");
o.register(p.tag, p);
function u(e, r, t) {
  e.entryMap[r] = e.entryMap[r] || [];
  e.entryMap[r].push(t);
}
function c(e, r, t, a) {
  for (var i = 0; i < r.children.length; i++) {
    var n = r.children[i];
    u(e, t, n);
  }
  l(r.fragment.childNodes, function (e) {
    return a.added.push({
      el: e
    });
  });
  e.placeholder.before(r.fragment);
}
export { p as EachEntry, u as addEntryToEachExecution, c as addNewExecutionToEachExecution };