export enum TreeItemStatus {
  new,
  partial,
  finished
}

export enum PickerPositions {
  left = 'left',
  right = 'right',
  center = 'center'
}

export enum PickerDropDirection {
  up = 'up',
  down = 'down'
}

export enum WidgetIcons {
  add,
  done_all,
  dehaze,
  trending_down,
  trending_up,
  waiting,
  notes,
  snooze
}

export enum WidgetColors {
  turquoise,
  blue,
  gray,
  red,
  orange,
  green,
  yellow,
  purple
}

export enum ChartTypes {
  line = 'line',
  bar = 'bar',
  horizontalBar = 'horizontalBar',
  histogram = 'histogram',
  pie = 'pie',
  scatter = 'scatter',
  bubble = 'bubble',
  radar = 'radar'
}

export enum ChartLegendColors {
  boolean = 'boolean',
  state = 'state',
  default = 'default'
}

export enum DependencySetAction {
  show,
  mandatory
}

export const enum EKbStatus {
  Initial,
  Updated,
  InReview,
  Rejected,
  NotUsable
}

export enum EMongoStatus {
  Initial,
  InReview,
  Approved,
  Rejected,
  ToBeRemoved
}

export enum GraphAppraisalColors {
  NotEffective = '#ff4d5b',
  PartiallyEffective = '#f2ca00',
  Effective = '#39bf7c'
}

export enum GraphBooleanColors {
  zero = '#ff4d5b',
  one = '#39bf7c'
}

export enum FileIcon {
  Image = 'file-image',
  PDF = 'file-pdf',
  XML = 'file-xml',
  Word = 'file-word',
  Unknown = 'file-check'
}
