import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBadgeModule } from '@angular/material/badge';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLuxonDateModule, MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import { MatTreeModule } from '@angular/material/tree';
import { MatStepperModule } from '@angular/material/stepper';
import { OverlayModule } from '@angular/cdk/overlay';
import { NarisDateAdapter } from '@core/classes';
import { FORM_LOOKUP_TOKEN, COMPARE_TOKEN, TREEVIEW_COPY_TOKEN, KNOWLEDGEBASE_BATCH_TOKEN, KNOWLEDGEBASE_TOKEN, KNOWLEDGEBASE_COMMENT_TOKEN, INFO_DIALOG_TOKEN, PROCESS_VIEWER_DIALOG_TOKEN, MATRIX_DIALOG_TOKEN } from '@core/constants';
import { NARIS_DATE_FORMATS } from '@core/constants/config-constants';
import { FormLookupComponent } from '@core/form/form-lookup/form-lookup.component';
// import { DiagramComponent } from '@shared/components/diagram/diagram.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ObserversModule } from '@angular/cdk/observers';
import { JSONCompareComponent } from '@shared/components/json-compare/json-compare.component';
import { TreeviewCopyFormComponent } from '@shared/components/treeview/treeview-copy-form/treeview-copy-form.component';
import { KnowledgebaseBatchComponent } from '@features/knowledgebase/knowledgebase-edit/knowledgebase-batch/knowledgebase-batch.component';
import { KnowledgebaseDialogComponent } from '@features/knowledgebase/knowledgebase-edit/knowledgebase-dialog/knowledgebase-dialog.component';
import { KnowledgebaseCommentComponent } from '@features/knowledgebase/knowledgebase-review/knowledgebase-comment/knowledgebase-comment.component';
import { InfoDialogComponent } from '@shared/components/info-dialog/info-dialog.component';
import { ProcessViewerDialogComponent } from '@shared/components/process-viewer-dialog/process-viewer-dialog.component';
import { MatrixDialogComponent } from '@shared/components/matrix-dialog/matrix-dialog.component';

@NgModule({
  exports: [
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule,
    MatSelectModule,
    MatMenuModule,
    MatTableModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    TextFieldModule,
    MatChipsModule,
    MatPaginatorModule,
    MatSortModule,
    DragDropModule,
    MatSnackBarModule,
    MatDialogModule,
    ScrollingModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatExpansionModule,
    ClipboardModule,
    MatLuxonDateModule,
    MatTreeModule,
    ObserversModule,
    MatStepperModule,
    OverlayModule
  ],
  providers: [
    {provide: FORM_LOOKUP_TOKEN, useValue: FormLookupComponent},
    {provide: INFO_DIALOG_TOKEN, useValue: InfoDialogComponent},
    // {provide: DIAGRAM_TOKEN, useValue: DiagramComponent},
    {provide: PROCESS_VIEWER_DIALOG_TOKEN, useValue: ProcessViewerDialogComponent},
    {provide: COMPARE_TOKEN, useValue: JSONCompareComponent},
    {provide: TREEVIEW_COPY_TOKEN, useValue: TreeviewCopyFormComponent},
    {provide: KNOWLEDGEBASE_TOKEN, useValue: KnowledgebaseDialogComponent},
    {provide: KNOWLEDGEBASE_BATCH_TOKEN, useValue: KnowledgebaseBatchComponent},
    {provide: KNOWLEDGEBASE_COMMENT_TOKEN, useValue: KnowledgebaseCommentComponent},
    {provide: MATRIX_DIALOG_TOKEN, useValue: MatrixDialogComponent},
    {provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: DateAdapter, useClass: NarisDateAdapter, deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: NARIS_DATE_FORMATS}
  ]
})
export class MaterialModule {}
