import { inherits as e, createSuper as t, classCallCheck as r, defineProperty as o, assertThisInitialized as n, createClass as i, typeof as a } from "../_virtual/_rollupPluginBabelHelpers.js";
import { defaultIdFunction as u, Graph as d, isPort as s, isNode as l, Node as c, Port as f, isEdge as p, isGroup as h, Edge as g, Group as v } from "./model/graph.js";
import { AutoSaver as y, CatchAllEventHandler as _ } from "./autosaver.js";
import { Path as m } from "./model/path.js";
import { Selection as b } from "./selection.js";
import { EVENT_UNDOREDO_UPDATE as k, EVENT_SELECTION_CLEARED as S, EVENT_GRAPH_CLEAR_START as $, EVENT_GRAPH_CLEARED as T, EVENT_NODE_ADDED as j, EVENT_DATA_UPDATED as F, EVENT_NODE_REMOVED as P, EVENT_EDGE_ADDED as x, EVENT_EDGE_REMOVED as E, EVENT_EDGE_TARGET_CHANGED as A, EVENT_EDGE_SOURCE_CHANGED as D, EVENT_PORT_ADDED as G, EVENT_PORT_REMOVED as N, EVENT_NODE_UPDATED as R, EVENT_GROUP_UPDATED as I, EVENT_PORT_UPDATED as C, EVENT_EDGE_UPDATED as O, EVENT_EDGE_PATH_EDITED as V, EVENT_SELECT as w, EVENT_DESELECT as L, EVENT_DATA_LOAD_START as U, EVENT_DATA_LOAD_END as M, EVENT_DATA_APPEND_START as B, EVENT_DATA_APPEND_END as H, EVENT_RENDERER_ADDED as W, EVENT_NODE_TYPE_CHANGED as z, EVENT_PORT_TYPE_CHANGED as J, EVENT_EDGE_TYPE_CHANGED as q, EVENT_GROUP_TYPE_CHANGED as Y, EVENT_GRAPH_CHANGED as K } from "./constants.js";
import { manage as Q, ManagedOperations as X, JSON_DATATYPE as Z, HIERARCHICAL_JSON_DATATYPE as ee, exportData as te, parse as re } from "./io.js";
import { DataModel as oe } from "./datamodel/data-model.js";
import { UndoRedoManager as ne, APPEND_TO_CURRENT as ie } from "./undo-redo/undo-redo.js";
import { OptimisticEventGenerator as ae } from "../ui-core/util/event-generator.js";
import { isObject as ue, clone as de, uuid as se, extend as le, log as ce, replace as fe, isString as pe, map as he } from "../ui-core/util/util.js";
import { EVENT_GROUP_MEMBER_ADDED as ge, EVENT_GROUP_ADDED as ve, EVENT_GROUP_MEMBER_REMOVED as ye, EVENT_GROUP_REMOVED as _e } from "../ui-core/core/event-constants.js";
import { recordGetsert as me } from "./util.js";
var be = "type";
var ke = "default";
var Se = function e() {};
var $e = "addport";
var Te = "addnewport";
var je = "removeport";
var Fe = "updateport";
var Pe = "moved";
var xe = "groupResized";
var Ee = "drawingToolsResize";
var Ae = function (ae) {
  e(xe, ae);
  var Pe = t(xe);
  function xe(e) {
    var t;
    r(this, xe);
    t = Pe.call(this);
    o(n(t), "graph", void 0);
    o(n(t), "autoSaver", void 0);
    o(n(t), "idFunction", void 0);
    o(n(t), "typeProperty", void 0);
    o(n(t), "edgeTypeProperty", void 0);
    o(n(t), "portTypeProperty", void 0);
    o(n(t), "typeFunction", void 0);
    o(n(t), "edgeIdFunction", void 0);
    o(n(t), "edgeTypeFunction", void 0);
    o(n(t), "portIdFunction", void 0);
    o(n(t), "portTypeFunction", void 0);
    o(n(t), "portExtractor", void 0);
    o(n(t), "portUpdater", void 0);
    o(n(t), "portDataProperty", void 0);
    o(n(t), "portOrderProperty", void 0);
    o(n(t), "modelTopAttribute", void 0);
    o(n(t), "modelLeftAttribute", void 0);
    o(n(t), "modelWidthAttribute", void 0);
    o(n(t), "modelHeightAttribute", void 0);
    o(n(t), "model", void 0);
    o(n(t), "_$_suspendGraph", void 0);
    o(n(t), "_$_dataLoading", void 0);
    o(n(t), "_$_originalData", void 0);
    o(n(t), "_$_originalDataType", void 0);
    o(n(t), "debugEnabled", void 0);
    o(n(t), "defaultObjectFactory", void 0);
    o(n(t), "nodeFactory", void 0);
    o(n(t), "edgeFactory", void 0);
    o(n(t), "portFactory", void 0);
    o(n(t), "groupFactory", void 0);
    o(n(t), "autoSave", void 0);
    o(n(t), "saveUrl", void 0);
    o(n(t), "autoSaveDebounceTimeout", void 0);
    o(n(t), "autoSaveHandler", void 0);
    o(n(t), "saveHeaders", void 0);
    o(n(t), "onAutoSaveSuccess", void 0);
    o(n(t), "onAutoSaveError", void 0);
    o(n(t), "doNotUpdateOriginalData", void 0);
    o(n(t), "onBeforeAutoSave", void 0);
    o(n(t), "onAfterAutoSave", void 0);
    o(n(t), "portSeparator", ".");
    o(n(t), "defaultCost", void 0);
    o(n(t), "defaultDirected", void 0);
    o(n(t), "enableSubgraphs", void 0);
    o(n(t), "undoRedo", void 0);
    o(n(t), "graphParams", void 0);
    o(n(t), "beforeConnect", void 0);
    o(n(t), "beforeMoveConnection", void 0);
    o(n(t), "beforeStartConnect", void 0);
    o(n(t), "beforeDetach", void 0);
    o(n(t), "beforeStartDetach", void 0);
    o(n(t), "_$_currentSelection", void 0);
    o(n(t), "_$_renderersById", new Map());
    o(n(t), "_transientVertices", {});
    o(n(t), "_transientVerticesByRenderer", {});
    e = e || {};
    var i = e.undoRedo || {};
    if (i.enabled !== false) {
      t.undoRedo = new ne({
        toolkit: n(t),
        maximumSize: i.maximumSize,
        onChange: function e(r, o, n) {
          t.fire(k, {
            undoCount: o,
            redoCount: n
          });
        }
      });
    }
    t.idFunction = e.idFunction || u;
    t.typeProperty = e.typeProperty || be;
    t.edgeTypeProperty = e.edgeTypeProperty || be;
    t.portTypeProperty = e.portTypeProperty || be;
    t.typeFunction = e.typeFunction || function (e) {
      return e[t.typeProperty] || ke;
    };
    t.edgeIdFunction = e.edgeIdFunction || t.idFunction;
    t.edgeTypeFunction = e.edgeTypeFunction || function (e) {
      return e[t.edgeTypeProperty] || ke;
    };
    t.portIdFunction = e.portIdFunction || t.idFunction;
    t.portTypeFunction = e.portTypeFunction || function (e) {
      return e[t.portTypeProperty] || ke;
    };
    t.portExtractor = e.portExtractor;
    t.portUpdater = e.portUpdater;
    t.portDataProperty = e.portDataProperty;
    t.portOrderProperty = e.portOrderProperty;
    t._$_suspendGraph = false;
    t.debugEnabled = false;
    t.model = new oe(n(t), e.model);
    t.defaultObjectFactory = function (e, t, r) {
      t = t == null || !ue(t) ? {} : t;
      t = de(t);
      t.id = t.id || se();
      t.type = t.type || (e == null ? null : e.type || e);
      r(t);
      return true;
    };
    t.modelLeftAttribute = e.modelLeftAttribute || "left";
    t.modelTopAttribute = e.modelTopAttribute || "top";
    t.modelWidthAttribute = e.modelWidthAttribute || "width";
    t.modelHeightAttribute = e.modelHeightAttribute || "height";
    t.nodeFactory = e.nodeFactory || t.defaultObjectFactory;
    t.edgeFactory = e.edgeFactory || t.defaultObjectFactory;
    t.portFactory = e.portFactory || t.defaultObjectFactory;
    t.groupFactory = e.groupFactory || t.defaultObjectFactory;
    t.autoSave = e.autoSave === true && (e.saveUrl != null || e.autoSaveHandler != null);
    t.autoSaveDebounceTimeout = e.autoSaveDebounceTimeout;
    t.autoSaveHandler = e.autoSaveHandler;
    t.saveUrl = e.saveUrl;
    t.saveHeaders = e.saveHeaders;
    t.onAutoSaveSuccess = e.onAutoSaveSuccess || Se;
    t.onAutoSaveError = e.onAutoSaveError || Se;
    t.doNotUpdateOriginalData = e.doNotUpdateOriginalData === true;
    t.onBeforeAutoSave = e.onBeforeAutoSave || Se;
    t.onAfterAutoSave = e.onAfterAutoSave || Se;
    t.graphParams = {
      portSeparator: e.portSeparator,
      defaultCost: e.defaultCost,
      defaultDirected: e.defaultDirected,
      enableSubgraphs: e.enableSubgraphs
    };
    if (t.portDataProperty != null && t.portExtractor == null) {
      t.portExtractor = function (e) {
        var r = e[t.portDataProperty] || [];
        if (t.portOrderProperty != null) {
          r.sort(function (e, r) {
            var o = e[t.portOrderProperty],
              n = r[t.portOrderProperty],
              i = o == null ? Infinity : o,
              a = n == null ? Infinity : n;
            return i - a;
          });
        }
        return r;
      };
    }
    if (t.portDataProperty != null && t.portUpdater == null) {
      t.portUpdater = function (e, r, o) {
        var n = {};
        if (t.portOrderProperty != null) {
          o.sort(function (e, r) {
            var o = e.data[t.portOrderProperty],
              n = r.data[t.portOrderProperty],
              i = o == null ? Infinity : o,
              a = n == null ? Infinity : n;
            return i - a;
          });
        }
        n[t.portDataProperty] = o.map(function (e) {
          return e.data;
        });
        return le(e, n);
      };
    }
    t.graph = new d(t.graphParams);
    if (t.autoSave) {
      t.autoSaver = new y(n(t), {
        type: e.autoSaveType,
        saveUrl: t.saveUrl,
        saveHeaders: t.saveHeaders,
        onAutoSaveSuccess: t.onAutoSaveSuccess,
        onAutoSaveError: t.onAutoSaveError,
        onBeforeAutoSave: t.onBeforeAutoSave,
        onAfterAutoSave: t.onAfterAutoSave,
        autoSaveDebounceTimeout: t.autoSaveDebounceTimeout,
        autoSaveHandler: t.autoSaveHandler
      });
    }
    new _(n(t));
    var a = function e(r, o) {
      if (t.model == null) return true;
      var n = t.getType(r),
        i = t.getType(o),
        a = s(r) ? r.getParent() : r,
        u = s(o) ? o.getParent() : o,
        d = s(r) ? t.model.getPortDefinition(n) : l(r) ? t.model.getNodeDefinition(n) : t.model.getGroupDefinition(n),
        c = s(o) ? t.model.getPortDefinition(i) : l(o) ? t.model.getNodeDefinition(i) : t.model.getGroupDefinition(i),
        f = l(a) ? t.model.getNodeDefinition(n) : t.model.getGroupDefinition(n),
        p = l(u) ? t.model.getNodeDefinition(i) : t.model.getGroupDefinition(i);
      if (d.maxConnections != null && r.getEdges().length >= d.maxConnections) {
        return false;
      }
      if (c.maxConnections != null && o.getEdges().length >= c.maxConnections) {
        return false;
      }
      if (r === o) {
        return !(f.allowLoopback === false || d.allowLoopback === false || c.allowLoopback === false || p.allowLoopback === false);
      }
      if (a === u) {
        return !(f.allowVertexLoopback === false || d.allowVertexLoopback === false || c.allowVertexLoopback === false || p.allowVertexLoopback === false);
      }
      return true;
    };
    t.beforeConnect = e.beforeConnect || a;
    t.beforeMoveConnection = e.beforeMoveConnection || a;
    t.beforeStartConnect = e.beforeStartConnect || function (e, t) {
      return {};
    };
    t.beforeDetach = e.beforeDetach || function (e, t, r) {
      return true;
    };
    t.beforeStartDetach = e.beforeStartDetach || function (e, t) {
      return true;
    };
    t._$_currentSelection = t._$_createSelection(function (e) {
      t.fire(S, {
        selection: e
      });
    });
    if (e.selectionMode) {
      t._$_currentSelection.setMode(e.selectionMode);
    }
    if (e.maxSelectedNodes) {
      t._$_currentSelection.setMaxNodes(e.maxSelectedNodes);
    }
    if (e.maxSelectedGroups) {
      t._$_currentSelection.setMaxGroups(e.maxSelectedGroups);
    }
    if (e.maxSelectedEdges) {
      t._$_currentSelection.setMaxEdges(e.maxSelectedEdges);
    }
    if (e.selectionCapacityPolicy) {
      t._$_currentSelection.setCapacityPolicy(e.selectionCapacityPolicy);
    }
    if (e.data != null) {
      t.load({
        data: e.data
      });
    }
    return t;
  }
  i(xe, [{
    key: "_$_createSelection",
    value: function e(t) {
      return new b(this, {
        onClear: t || function () {}
      });
    }
  }, {
    key: "filter",
    value: function e(t, r) {
      var o = typeof t === "function" ? t : function (e) {
          var o = e.data,
            n = false,
            i = t;
          for (var a in i) {
            var u = i[a] === o[a];
            if (!u && !r) {
              return false;
            }
            n = n || u;
          }
          return n;
        },
        n = this._$_createSelection();
      this.eachNode(function (e, t) {
        if (o(t)) {
          n.append(t);
        }
        var r = t.getPorts();
        for (var i = 0; i < r.length; i++) {
          if (o(r[i])) {
            n.append(r[i]);
          }
        }
      });
      this.eachEdge(function (e, t) {
        if (o(t)) {
          n.append(t);
        }
      });
      this.eachGroup(function (e, t) {
        if (o(t)) {
          n.append(t);
        }
      });
      return n;
    }
  }, {
    key: "getModel",
    value: function e() {
      return this.model;
    }
  }, {
    key: "setSuspendGraph",
    value: function e(t) {
      this._$_suspendGraph = t;
    }
  }, {
    key: "setAutoSave",
    value: function e(t) {
      if (this.autoSaver != null) {
        this.autoSaver.discard();
      }
      this.autoSaver = new y(this, t);
    }
  }, {
    key: "setDoNotUpdateOriginalData",
    value: function e(t) {
      this.doNotUpdateOriginalData = t;
    }
  }, {
    key: "getTypeFunction",
    value: function e() {
      return this.typeFunction;
    }
  }, {
    key: "connect",
    value: function e(t) {
      var r;
      if (!this._$_suspendGraph) {
        var o = this.graph.getVertex(t.source),
          n = this.graph.getVertex(t.target),
          i = t.cost,
          u = t.directed;
        if (!o) {
          if (!t.doNotCreateMissingNodes) {
            o = this.graph.addNode(t.source);
            this._$_notifyNodeAdded(o);
          } else return;
        }
        if (!n) {
          if (!t.doNotCreateMissingNodes) {
            n = this.graph.addNode(t.target);
            this._$_notifyNodeAdded(n);
          } else return;
        }
        var d = t.data || {};
        var s = this.beforeStartConnect(o, this.edgeTypeFunction(d));
        if (s) {
          if (a(s) === "object") {
            d = le(s, d);
          }
          var l = this.beforeConnect(o, n, d, false);
          if (l !== false) {
            r = this.addEdge({
              source: o,
              target: n,
              cost: i,
              directed: u,
              data: d,
              geometry: t.geometry
            });
          }
        }
      }
      return r;
    }
  }, {
    key: "clear",
    value: function e() {
      this._$_dispatchToRenderers(function (e) {
        return e._graphClearStart();
      });
      this.fire($);
      this.graph.clear();
      this._transientVertices = {};
      this._transientVerticesByRenderer = {};
      this._$_dispatchToRenderers(function (e) {
        return e._graphClearEnd();
      });
      this.fire(T);
      return this;
    }
  }, {
    key: "getGraph",
    value: function e() {
      return this.graph;
    }
  }, {
    key: "getNodeCount",
    value: function e() {
      return this.graph.getVertexCount();
    }
  }, {
    key: "getNodeAt",
    value: function e(t) {
      return this.graph.getVertexAt(t);
    }
  }, {
    key: "getNodes",
    value: function e() {
      return this.graph.getVertices();
    }
  }, {
    key: "eachNode",
    value: function e(t) {
      var r;
      for (var o = 0, n = this.graph.getVertexCount(); o < n; o++) {
        r = this.graph.getVertexAt(o);
        t(o, r);
      }
    }
  }, {
    key: "eachGroup",
    value: function e(t) {
      var r;
      for (var o = 0, n = this.graph.getGroupCount(); o < n; o++) {
        r = this.graph.getGroupAt(o);
        t(o, r);
      }
    }
  }, {
    key: "eachVertex",
    value: function e(t) {
      this.eachNode(t);
      this.eachGroup(t);
    }
  }, {
    key: "eachEdge",
    value: function e(t) {
      var r = this.graph.getEdges();
      for (var o = 0, n = r.length; o < n; o++) {
        t(o, r[o]);
      }
    }
  }, {
    key: "getEdgeCount",
    value: function e() {
      return this.graph.getEdgeCount();
    }
  }, {
    key: "getGroupCount",
    value: function e() {
      return this.graph.getGroupCount();
    }
  }, {
    key: "getGroupAt",
    value: function e(t) {
      return this.graph.getGroupAt(t);
    }
  }, {
    key: "getGroups",
    value: function e() {
      return this.graph.getGroups();
    }
  }, {
    key: "getClusters",
    value: function e() {
      return this.graph.getClusters();
    }
  }, {
    key: "getNodeId",
    value: function e(t) {
      return typeof t === "string" ? t : this.idFunction(t);
    }
  }, {
    key: "getNodeType",
    value: function e(t) {
      return this.typeFunction(t) || "default";
    }
  }, {
    key: "getGroupId",
    value: function e(t) {
      return typeof t === "string" ? t : this.idFunction(t);
    }
  }, {
    key: "getGroupType",
    value: function e(t) {
      return this.typeFunction(t) || "default";
    }
  }, {
    key: "getEdgeId",
    value: function e(t) {
      return this.edgeIdFunction(t);
    }
  }, {
    key: "getEdgeType",
    value: function e(t) {
      return this.edgeTypeFunction(t) || "default";
    }
  }, {
    key: "getPortId",
    value: function e(t) {
      return this.portIdFunction(t);
    }
  }, {
    key: "getPortType",
    value: function e(t) {
      return this.portTypeFunction(t) || "default";
    }
  }, {
    key: "getType",
    value: function e(t) {
      var r = l(t) ? this.typeFunction : s(t) ? this.portTypeFunction : this.edgeTypeFunction;
      return r(t.data) || "default";
    }
  }, {
    key: "setType",
    value: function e(t, r) {
      var o = this.getType(t);
      if (o === r) return;
      var n = t.objectType === c.objectType ? this.typeProperty : t.objectType === f.objectType ? this.portTypeProperty : this.edgeTypeProperty;
      var i;
      if (l(t)) {
        i = z;
      } else if (s(t)) {
        i = J;
      } else if (p(t)) {
        i = q;
      } else if (h(t)) {
        i = Y;
      }
      t.data[n] = r;
      if (i != null) {
        this.fire(i, {
          obj: t,
          previousType: o,
          newType: r
        });
      }
    }
  }, {
    key: "addNode",
    value: function e(t, r, o) {
      var n = this.idFunction(t);
      var i = null;
      if (n == null && typeof t !== "string") {
        t.id = se();
      }
      var a = this.graph.addNode(t, this.idFunction);
      if (a != null) {
        if (this.portExtractor != null) {
          var u = this.portExtractor(a.data, a);
          if (u != null) {
            for (var d = 0; d < u.length; d++) {
              a.addPort(u[d], this.portIdFunction);
            }
          }
        }
        if (typeof t !== "string" && t.group != null) {
          i = this.getGroup(t.group);
          if (i != null) {
            i.addMember(a);
          } else {
            throw new Error("parent group [" + t.group + "] not found");
          }
        }
        if (!this._$_dataLoading && !this.doNotUpdateOriginalData) {
          Q(X.addNode, this._$_originalData, this._$_originalDataType, a, this.idFunction || this.graph.getIdFunction());
        }
        if (!o) {
          this._$_notifyNodeAdded(a, i);
        }
        if (i != null) {
          this.fire(ge, {
            group: i,
            vertex: a,
            vertexIsNew: true
          });
        }
        return a;
      } else return this.graph.getVertex(n);
    }
  }, {
    key: "_$_notifyNodeAdded",
    value: function e(t, r) {
      var o = {
        node: t,
        parentGroup: r
      };
      this._$_dispatchToRenderers(function (e) {
        return e._nodeAdded(o);
      });
      this.fire(j, o);
    }
  }, {
    key: "_$_notifyGroupAdded",
    value: function e(t, r) {
      var o = {
        group: t,
        parentGroup: r
      };
      this._$_dispatchToRenderers(function (e) {
        return e._groupAdded(o);
      });
      this.fire(ve, o);
    }
  }, {
    key: "addFactoryNode",
    value: function e(t, r, o, n) {
      var i = this;
      if (o == null) {
        if (r != null) {
          r = a(r) === "object" ? r : {};
          o = typeof r === "function" ? r : null;
        } else {
          r = {};
        }
      }
      r.type = r.type || t;
      try {
        this.nodeFactory(t, r, function (e) {
          i.openTransaction();
          var t = i.addNode(e);
          try {
            if (o) {
              o(t);
            }
          } catch (e) {
            ce("WARN: Factory node callback threw an error", e);
          }
          i.commitTransaction();
        }, function () {
          if (n) {
            n();
          }
        });
      } catch (e) {
        ce("WARN: Factory node threw an error", e);
        this.rollbackTransaction();
      }
    }
  }, {
    key: "addNodes",
    value: function e(t) {
      for (var r = 0; r < t.length; r++) {
        this.addNode(t[r]);
      }
      return this;
    }
  }, {
    key: "_getTransientVerticesForRenderer",
    value: function e(t) {
      return me(this._transientVerticesByRenderer, t.id, function () {
        return {};
      });
    }
  }, {
    key: "addTransientVertex",
    value: function e(t, r) {
      var o = this.graph.addNode(r);
      o.transient = true;
      var n = this._getTransientVerticesForRenderer(t);
      n[o.id] = o;
      this._transientVertices[o.id] = {
        renderer: t,
        vertex: o
      };
      return o;
    }
  }, {
    key: "cleanupTransientVertex",
    value: function e(t, r) {
      var o = r.getAllEdges() || [];
      this.graph.deleteNode(r);
      for (var n = 0; n < o.length; n++) {
        this.graph.deleteEdge(o[n]);
      }
      t._nodeRemoved({
        node: r,
        edges: o,
        parentGroupIsBeingRemoved: false
      });
      var i = this._getTransientVerticesForRenderer(t);
      delete i[r.id];
      delete this._transientVertices[r.id];
    }
  }, {
    key: "addFactoryGroup",
    value: function e(t, r, o, n) {
      var i = this;
      if (o == null) {
        if (r != null) {
          r = a(r) === "object" ? r : {};
          o = typeof r === "function" ? r : null;
        } else if (r == null && o == null) {
          r = {};
        }
      }
      r.type = r.type || t;
      try {
        this.groupFactory(t, r, function (e) {
          i.openTransaction();
          var t = i.addGroup(e);
          try {
            if (o) {
              o(t);
            }
          } catch (e) {
            ce("WARN: Factory group callback threw an error", e);
          }
          i.commitTransaction();
          i.commitTransaction();
        }, function () {
          if (n) {
            n();
          }
          i.rollbackTransaction();
        });
      } catch (e) {
        ce("WARN: group factory threw an error", e);
      }
    }
  }, {
    key: "addGroup",
    value: function e(t, r, o) {
      var n, i;
      var a = this.idFunction(t);
      if (a == null && typeof t !== "string") {
        t.id = se();
      }
      i = this.graph.addGroup(t, this.idFunction);
      if (i != null) {
        if (typeof t !== "string" && t.group != null) {
          n = this.getGroup(t.group);
          if (n == null) {
            throw new Error("Cannot find parent group [" + t.group + "]");
          } else {
            n.addMember(i);
          }
        }
        if (!this._$_dataLoading && !this.doNotUpdateOriginalData) {
          Q(X.addGroup, this._$_originalData, this._$_originalDataType, i, this.idFunction || this.graph.getIdFunction());
        }
        if (!o) {
          this._$_notifyGroupAdded(i, n);
          if (n != null) {
            this.fire(ge, {
              group: n,
              vertex: i,
              vertexIsNew: true
            });
          }
        }
      }
      return i;
    }
  }, {
    key: "addToGroup",
    value: function e(t, r, o, n, i) {
      var a = false;
      var u = this.getObjectInfo(r);
      var d = this.getObjectInfo(t);
      if (d.obj && u.obj) {
        o = o || d.obj.group;
        a = u.obj.addMember(d.obj);
        if (a) {
          o && o.removeMember(d.obj);
          d.obj.data.group = u.obj.id;
          var s = {
            vertex: d.obj,
            group: u.obj,
            pos: n,
            source: i
          };
          if (o) {
            s.sourceGroup = o;
          }
          this.fire(ge, s);
          this.fire(F);
        }
      }
      return a;
    }
  }, {
    key: "removeFromGroup",
    value: function e(t, r, o, n) {
      var i = this.getObjectInfo(t);
      var a;
      if (i.obj && i.obj.group) {
        a = i.obj.group;
        if (a.removeMember(i.obj)) {
          delete i.obj.data.group;
          if (!r) {
            var u = {
              group: a,
              source: n,
              vertex: i.obj
            };
            if (o) {
              u.targetGroup = o;
            }
            this.fire(ye, u);
          }
          this.fire(F);
        }
      }
      return a;
    }
  }, {
    key: "_$_notifyGroupRemoved",
    value: function e(t, r, o, n) {
      var i = this;
      t.nestedGroups.forEach(function (e) {
        return i._$_notifyGroupRemoved(e, r, true, n);
      });
      t.nodes.forEach(function (e) {
        return i._$_notifyNodeRemoved(e, true, n);
      });
      for (var a = 0; a < t.edges.length; a++) {
        this._$_notifyEdgeRemoved(t.edges[a]);
      }
      if (!r) {
        t.children.forEach(function (e) {});
      }
      var u = {
        group: t.group,
        removeChildren: r,
        children: t.children,
        parentGroup: t.parentGroup,
        parentGroupIsBeingRemoved: o
      };
      this._$_dispatchToRenderers(function (e) {
        return e._groupRemoved(u);
      });
      if (!this._$_dataLoading && !this.doNotUpdateOriginalData) {
        Q(X.removeGroup, this._$_originalData, this._$_originalDataType, t.group, this.idFunction || this.graph.getIdFunction());
      }
      if (!n) {
        this.fire(_e, u);
      }
    }
  }, {
    key: "_$_notifyNodeRemoved",
    value: function e(t, r, o) {
      for (var n = 0; n < t.edges.length; n++) {
        this._$_notifyEdgeRemoved(t.edges[n]);
      }
      var i = {
        node: t.node,
        edges: t.edges,
        parentGroup: t.parentGroup,
        parentGroupIsBeingRemoved: r
      };
      this._$_dispatchToRenderers(function (e) {
        return e._nodeRemoved(i);
      });
      if (!this._$_dataLoading && !this.doNotUpdateOriginalData) {
        Q(X.removeNode, this._$_originalData, this._$_originalDataType, t.node, this.idFunction || this.graph.getIdFunction());
      }
      if (!o) {
        this.fire(P, i);
      }
    }
  }, {
    key: "removeGroup",
    value: function e(t, r, o) {
      var n = this.getObjectInfo(t);
      if (n && n.obj) {
        var i = n.obj;
        this.openTransaction(ie);
        var a = this.graph.deleteGroup(i, r);
        if (a.group) {
          this._$_notifyGroupRemoved(a, r, false, o);
        }
        this.commitTransaction();
      }
    }
  }, {
    key: "getNode",
    value: function e(t) {
      return this.graph.getVertex(t);
    }
  }, {
    key: "getEdge",
    value: function e(t) {
      return this.graph.getEdge(t);
    }
  }, {
    key: "getGroup",
    value: function e(t) {
      return this.graph.getGroup(t);
    }
  }, {
    key: "getVertex",
    value: function e(t) {
      return this.getNode(t) || this.getGroup(t);
    }
  }, {
    key: "getPort",
    value: function e(t) {
      var r = this.graph.getVertexByPortId(t);
      if (r) {
        return r.getPort(this.graph.splitPortId(t)[1]);
      }
    }
  }, {
    key: "exists",
    value: function e() {
      for (var t = 0; t < arguments.length; t++) {
        if (this.graph.getVertex(t < 0 || arguments.length <= t ? undefined : arguments[t]) == null) return false;
      }
      return true;
    }
  }, {
    key: "removeNode",
    value: function e(t, r) {
      var o = this.graph.getVertex(t);
      if (o.transient === true) {
        return this;
      }
      this.openTransaction(ie);
      var n = o.getAllEdges() || [];
      for (var i = 0; i < n.length; i++) {
        this.removeEdge(n[i]);
      }
      o.group;
      var a = this.graph.deleteNode(o);
      this._$_notifyNodeRemoved(a, false, r);
      this.commitTransaction();
      return this;
    }
  }, {
    key: "addEdge",
    value: function e(t, r, o) {
      var n = this;
      var i = this.graph.addEdge(t, this.edgeIdFunction, function (e, r) {
        return n.beforeConnect(e, r, t.data, t.addedByMouse === true);
      });
      if (i != null) {
        if (!this._$_dataLoading && !this.doNotUpdateOriginalData) {
          Q(X.addEdge, this._$_originalData, this._$_originalDataType, i, this.idFunction || this.graph.getIdFunction());
        }
        var a = {
          edge: i,
          source: r,
          geometry: t.geometry,
          addedByMouse: t.addedByMouse
        };
        this._$_dispatchToRenderers(function (e) {
          return e._edgeAdded(a);
        });
        if (!o) {
          this.fire(x, a, null);
        }
      } else {
        if (this.debugEnabled) {
          ce("Could not add edge");
        }
      }
      return i;
    }
  }, {
    key: "_$_notifyEdgeRemoved",
    value: function e(t, r) {
      if (!this._$_dataLoading && !this.doNotUpdateOriginalData) {
        Q(X.removeEdge, this._$_originalData, this._$_originalDataType, t, this.idFunction || this.graph.getIdFunction());
      }
      this.fire(E, {
        edge: t,
        source: r
      }, null);
    }
  }, {
    key: "removeEdge",
    value: function e(t, r) {
      var o = this.graph.getEdge(t);
      if (o != null) {
        this.graph.deleteEdge(o);
        this._$_notifyEdgeRemoved(o, r);
      }
      return this;
    }
  }, {
    key: "edgeMoved",
    value: function e(t, r, o) {
      if (o === 0) {
        this.setSource(t, r);
      } else if (o === 1) {
        this.setTarget(t, r);
      }
    }
  }, {
    key: "setTarget",
    value: function e(t, r, o) {
      var n = this.graph.setTarget(t, r);
      if (n.success !== false && !o) {
        this.fire(A, n);
      }
      return n;
    }
  }, {
    key: "setSource",
    value: function e(t, r, o) {
      var n = this.graph.setSource(t, r);
      if (n.success !== false && !o) {
        this.fire(D, n);
      }
      return n;
    }
  }, {
    key: "addNewPort",
    value: function e(t, r, o, n) {
      var i = this;
      var a = this.graph.getVertex(t);
      this.portFactory({
        node: a,
        type: r
      }, o, function (e) {
        var t = i.portIdFunction(e),
          o = a.addPort(t);
        o.data = e;
        o.type = e.type || r;
        i._$_updateVertexAfterPortChange(a, Te);
        if (!i._$_dataLoading && !i.doNotUpdateOriginalData) {
          Q(X.addPort, i._$_originalData, i._$_originalDataType, {
            node: a,
            port: o
          }, i.idFunction || i.graph.getIdFunction());
        }
        if (!n) {
          i.fire(G, {
            vertex: a,
            data: e,
            port: o
          }, null);
        }
      }, function () {});
    }
  }, {
    key: "addPort",
    value: function e(t, r, o) {
      var n = this.graph.getVertex(t);
      var i = n.addPort(r, this.portIdFunction);
      this._$_updateVertexAfterPortChange(n, $e);
      if (!this._$_dataLoading && !this.doNotUpdateOriginalData) {
        Q(X.addPort, this._$_originalData, this._$_originalDataType, {
          node: n,
          port: i
        }, this.idFunction || this.graph.getIdFunction());
      }
      if (!o) {
        this.fire(G, {
          vertex: n,
          data: r,
          port: i
        }, null);
      }
      return i;
    }
  }, {
    key: "removePort",
    value: function e(t, r) {
      var o;
      if (typeof t === "string") {
        var n = this.graph.splitPortId(t);
        if (n.length === 2) {
          o = this.graph.getVertex(n[0]);
          r = n[1];
        } else {
          o = this.graph.getVertex(t);
        }
      } else {
        if (s(t)) {
          r = t.id;
          o = t.getParent();
        } else {
          o = this.graph.getVertex(t);
        }
      }
      if (o != null && r != null) {
        var i = o.getPort(r);
        if (i) {
          var a = i.getAllEdges();
          for (var u = a.length, d = 0; d < u; d++) {
            this.removeEdge(a[0]);
          }
          o.removePort(i);
          this._$_updateVertexAfterPortChange(o, je);
          this.fire(N, {
            vertex: o,
            port: i,
            edges: a
          }, null);
          return true;
        }
      }
      return false;
    }
  }, {
    key: "remove",
    value: function e(t) {
      if (t == null) return;
      var r = this.getObjectInfo(t);
      this.setSuspendRendering(true);
      try {
        if (r.obj && (r.type === c.objectType || r.type === g.objectType || r.type === v.objectType)) {
          this["remove" + r.type](r.obj);
        } else {
          while (t.getNodeCount() > 0) {
            this.removeNode(t.getNodeAt(0));
          }
          while (t.getEdgeCount() > 0) {
            this.removeEdge(t.getEdgeAt(0));
          }
          while (t.getGroupCount() > 0) {
            this.removeGroup(t.getGroupAt(0));
          }
        }
      } finally {
        this.setSuspendRendering(false, true);
      }
    }
  }, {
    key: "setSuspendRendering",
    value: function e(t, r) {
      for (var o in this._$_renderersById) {
        this._$_renderersById[o].setSuspendRendering(t, r);
      }
    }
  }, {
    key: "batch",
    value: function e(t) {
      this.setSuspendRendering(true);
      try {
        t();
      } catch (e) {
        ce("Error in transaction " + e);
      } finally {
        this.setSuspendRendering(false, true);
      }
    }
  }, {
    key: "updateGroup",
    value: function e(t, r, o) {
      this.updateVertex(t, r, o);
    }
  }, {
    key: "updateNode",
    value: function e(t, r, o) {
      this.updateVertex(t, r, o);
    }
  }, {
    key: "updateVertex",
    value: function e(t, r, o) {
      var n = this.getObjectInfo(t);
      if (n.obj) {
        var i = s(n.obj) ? n.obj.getParent() : n.obj;
        var a = i.objectType === c.objectType ? R : I;
        this._$_updateVertex(n.obj, r, a, function (e, t) {
          return {
            vertex: e,
            updates: r || {},
            originalData: t,
            reason: o
          };
        });
      }
    }
  }, {
    key: "updatePort",
    value: function e(t, r, o) {
      var n = this.getObjectInfo(t);
      if (n.obj) {
        this._$_updateVertex(n.obj, r, C, function (e, t) {
          return {
            port: e,
            vertex: e.getParent(),
            updates: r || {},
            originalData: t,
            reason: o
          };
        });
      }
    }
  }, {
    key: "updateEdge",
    value: function e(t, r, o) {
      var n = typeof t === "string" ? this.graph.getEdge(t) : t;
      if (n != null) {
        var i = le({}, n.data);
        if (r) {
          for (var a in r) {
            if (n.data[a] == null) n.data[a] = r[a];else fe(n.data, a, r[a]);
          }
        }
        this.fire(O, {
          edge: n,
          updates: r || {},
          originalData: i,
          reason: o
        }, null);
      }
    }
  }, {
    key: "update",
    value: function e(t, r, o) {
      if (l(t) || h(t)) {
        this.updateVertex(t, r, o);
      } else if (p(t)) {
        this.updateEdge(t, r, o);
      } else if (s(t)) {
        this.updatePort(t, r, o);
      }
    }
  }, {
    key: "setEdgeGeometry",
    value: function e(t, r, o) {
      var n = de(t.geometry);
      t.geometry = r;
      this.fire(V, {
        edge: t,
        geometry: r,
        originalGeometry: n,
        renderer: o
      });
      this.fire(F);
    }
  }, {
    key: "getPath",
    value: function e(t) {
      return new m(this, t);
    }
  }, {
    key: "findGraphObject",
    value: function e(t) {
      if (t == null) {
        return null;
      }
      if (t === "*") {
        return this.graph;
      } else if (t.constructor === c || t.constructor === f || t.constructor === v) {
        return t;
      } else if (t.constructor === g) {
        return t;
      } else if (pe(t) || ue(t)) {
        return this.graph.getVertex(t);
      }
      return null;
    }
  }, {
    key: "_$_select",
    value: function e(t, r, o, n) {
      var i = this;
      if (!r) {
        o.clear();
      }
      return o.append(t, function (e) {
        if (n) {
          i.fire(w, {
            append: r,
            obj: e,
            selection: o
          });
        }
      });
    }
  }, {
    key: "_selectEdges",
    value: function e(t, r, o) {
      var n = this;
      t = t || {};
      var i = [],
        a = {},
        u = function e(t) {
          if (!a[t.getId()]) {
            i.push(t);
            a[t.getId()] = true;
          }
        },
        d = function e(i, a, d, l) {
          if (i != null) {
            var c = i[r]({
              filter: t.filter
            });
            for (var f = 0; f < c.length; f++) {
              var p = a && i === n.graph || c[f].source === i || o && s(c[f].source) && c[f].source.getParent() === i,
                h = d && i === n.graph || c[f].target === i || o && s(c[f].target) && c[f].target.getParent() === i;
              if (a && p || d && h || l && (p || h)) {
                u(c[f]);
              }
            }
          }
        };
      d(this.findGraphObject(t.source), true, false, false);
      d(this.findGraphObject(t.target), false, true, false);
      d(this.findGraphObject(t.element), false, false, true);
      return i;
    }
  }, {
    key: "_$_updateVertex",
    value: function e(t, r, o, n) {
      if (t && t.objectType) {
        var i = le({}, t.data),
          a = t.objectType === f.objectType ? t.getFullId() : t.id,
          u = t.objectType === f.objectType ? this.portIdFunction(i) : null,
          d;
        if (r) {
          for (var s in r) {
            fe(t.data, s, r[s]);
          }
          if (t.objectType === f.objectType) {
            d = this.portIdFunction(t.data);
            if (d !== t.id) {
              this.graph.renamePort(t, d);
            }
            this._$_updateVertexAfterPortChange(t.getParent(), Fe);
          }
        }
        var l = n(t, i);
        l.originalId = a;
        if (u != null) {
          l.originalPortId = u;
        }
        this._$_dispatchToRenderers(function (e) {
          return e._vertexUpdated(l);
        });
        this.fire(o, l, null);
      }
    }
  }, {
    key: "_$_updateVertexAfterPortChange",
    value: function e(t, r) {
      if (this.portUpdater != null) {
        var o = this.portUpdater(t.data, t, t.getPorts());
        if (o != null) {
          t.data = o;
          if (!this._$_dataLoading) {
            var n = h(t) ? I : R;
            var i = {
              vertex: t,
              updates: {},
              originalData: t.data,
              reason: r
            };
            this._$_dispatchToRenderers(function (e) {
              return e._vertexUpdated(i);
            });
            this.fire(n, i);
          }
        }
      }
    }
  }, {
    key: "getEdges",
    value: function e(t) {
      return this._selectEdges(t, "getEdges", false);
    }
  }, {
    key: "getAllEdges",
    value: function e() {
      return this.graph.getAllEdges();
    }
  }, {
    key: "getAllEdgesFor",
    value: function e(t, r) {
      return t.getAllEdges({
        filter: r
      });
    }
  }, {
    key: "selectAllEdges",
    value: function e() {
      return this.filter(p);
    }
  }, {
    key: "addAllEdgesToSelection",
    value: function e() {
      this.addToSelection(this.getAllEdges());
    }
  }, {
    key: "setSelection",
    value: function e(t) {
      var r = this._$_resolveObjectForSelection(t);
      this._$_select(r, false, this._$_currentSelection, true);
    }
  }, {
    key: "select",
    value: function e(t, r) {
      var o = this._$_createSelection();
      var n = this._$_resolveObjectForSelection(t);
      var i = this._$_select(n, true, o);
      if (r) {
        for (var a = 0; a < i[0].length; a++) {
          var u = i[0][a];
          if (l(u || h(u) || s(u))) {
            var d = u.getAllEdges();
            for (var c = 0; c < d.length; c++) {
              o.append(d[c]);
            }
          }
        }
      }
      return o;
    }
  }, {
    key: "_$_descendants",
    value: function e(t, r, o, n) {
      var i = t.getAllEdges();
      for (var a = 0, u = i.length; a < u; a++) {
        if (i[a].source === t) {
          var d = i[a].target,
            s = d.getFullId();
          if (!n[s]) {
            r.append(d);
            if (o) {
              r.append(i[a]);
            }
            n[s] = true;
            this._$_descendants(d, r, o, n);
          }
        }
      }
    }
  }, {
    key: "selectDescendants",
    value: function e(t, r, o) {
      var n = this.getObjectInfo(t);
      var i = this._$_createSelection();
      if (n.obj && n.obj.objectType === c.objectType) {
        if (r) {
          this._$_select(n.obj, true, i);
        }
        var a = {};
        a[n.obj.getFullId()] = true;
        this._$_descendants(n.obj, i, o, a);
      }
      return i;
    }
  }, {
    key: "_$_resolveObjectForSelection",
    value: function e(t) {
      var r = this;
      if (t instanceof m) {
        return t;
      } else if (t instanceof b) {
        return t;
      } else if (pe(t)) {
        return this.getVertex(t) || this.getEdge(t);
      } else if (Array.isArray(t)) {
        return he(t, function (e) {
          return pe(e) ? r.getVertex(t) || r.getEdge(t) : e;
        });
      } else {
        return t;
      }
    }
  }, {
    key: "addToSelection",
    value: function e(t) {
      var r = this._$_resolveObjectForSelection(t);
      if (r) {
        var o = this._$_select(r, true, this._$_currentSelection, false);
        if (o[1] != null && o[1].length > 0) {
          this._$_adhocSel(L, o[1]);
        }
        if (o[0] != null && o[0].length > 0) {
          this._$_adhocSel(w, o[0]);
        }
      }
    }
  }, {
    key: "_$_adhocSel",
    value: function e(t, r) {
      for (var o = 0; o < r.length; o++) {
        this.fire(t, {
          obj: r[o],
          selection: this._$_currentSelection
        });
      }
    }
  }, {
    key: "toggleSelection",
    value: function e(t) {
      var r = this._$_resolveObjectForSelection(t);
      if (r) {
        var o = [];
        var n = this._$_currentSelection.toggle(r, function (e, t) {
          if (!t) {
            o.push(e);
          }
        });
        this._$_adhocSel(L, n[1]);
        this._$_adhocSel(L, o);
        this._$_adhocSel(w, n[0]);
      }
    }
  }, {
    key: "removeFromSelection",
    value: function e(t) {
      var r = this;
      var o = this._$_resolveObjectForSelection(t);
      if (o) {
        this._$_currentSelection.remove(o, function (e) {
          r.fire(L, {
            obj: e,
            selection: r._$_currentSelection
          });
        });
      }
    }
  }, {
    key: "addPathToSelection",
    value: function e(t) {
      this.addToSelection(this.getPath(t));
    }
  }, {
    key: "clearSelection",
    value: function e() {
      this._$_currentSelection.clear();
    }
  }, {
    key: "getSelection",
    value: function e() {
      return this._$_currentSelection;
    }
  }, {
    key: "setMaxSelectedNodes",
    value: function e(t) {
      this._$_currentSelection.setMaxNodes(t);
    }
  }, {
    key: "setMaxSelectedEdges",
    value: function e(t) {
      this._$_currentSelection.setMaxEdges(t);
    }
  }, {
    key: "setSelectionCapacityPolicy",
    value: function e(t) {
      this._$_currentSelection.setCapacityPolicy(t);
    }
  }, {
    key: "_$_notifyDataLoaded",
    value: function e(t) {
      this.setSuspendGraph(true);
      this.fire(t);
      this.setSuspendGraph(false);
      this._$_dataLoading = false;
    }
  }, {
    key: "_$_doLoad",
    value: function e(t, r, o) {
      var n = this;
      t = t || {};
      var i = t.type || "json",
        a = t.data,
        u = t.url,
        d = t.jsonp,
        s = t.onload,
        l = t.parameters || {},
        c = t.error || function (e) {};
      if (a == null && u == null) {
        throw new TypeError("You must supply either data or url to load.");
      }
      var f = function e(t) {
        n._$_originalData = t;
        n._$_originalDataType = i;
        n._$_dataLoading = true;
        if (n.undoRedo && r === U) {
          n.undoRedo.dataLoadStart();
        }
        n.fire(r);
        re(i, t, n, l);
        n._$_notifyDataLoaded(o);
        if (s) {
          s(n, t);
        }
        n.fire(K);
        if (n.undoRedo && o === M) {
          n.undoRedo.dataLoadEnd();
        }
      };
      if (a) {
        f(a);
      } else if (u) {
        if (d) {
          var p = u.indexOf("?") === -1 ? "?" : "&";
          u = u + p + "callback=?";
        }
        var h = i === Z || i === ee ? Z : t.dataType;
        var g = t.headers || {
          Accept: "application/json"
        };
        this.loadData({
          url: u,
          success: f,
          dataType: h,
          error: c,
          headers: g
        });
      }
      return this;
    }
  }, {
    key: "load",
    value: function e(t) {
      return this._$_doLoad(t, U, M);
    }
  }, {
    key: "append",
    value: function e(t) {
      return this._$_doLoad(t, B, H);
    }
  }, {
    key: "save",
    value: function e(t) {
      if (t == null) {
        throw new Error("JSPLUMB: save method requires at least URL parameter - no params given.");
      }
      if (t.url == null) {
        throw new Error("JSPLUMB: save method requires at least URL parameter");
      }
      var r = this.exportData(t);
      var o = {
        "Content-Type": "application/json"
      };
      le(o, t.headers || {});
      this.loadData({
        url: t.url,
        type: "POST",
        data: r,
        success: t.success || function (e) {},
        error: t.error || function (e) {},
        headers: o
      });
      return this;
    }
  }, {
    key: "exportData",
    value: function e(t) {
      t = t || {};
      return te(t.type || Z, this, t.parameters);
    }
  }, {
    key: "toJSON",
    value: function e() {
      return te(Z, this, {});
    }
  }, {
    key: "getRenderer",
    value: function e(t) {
      return this._$_renderersById.get(t);
    }
  }, {
    key: "getRenderers",
    value: function e() {
      return this._$_renderersById;
    }
  }, {
    key: "_$_dispatchToRenderers",
    value: function e(t, r) {
      this._$_renderersById.forEach(function (e, o) {
        if (r == null || r !== e) {
          try {
            t(e, o);
          } catch (e) {
            ce("WARN: could not invoke renderer " + o + "  [" + e + "]");
          }
        }
      });
    }
  }, {
    key: "addRenderer",
    value: function e(t, r) {
      var o = this;
      r = r || se();
      this._$_renderersById.set(r, t);
      t.id = r;
      t.onDestroy(function (e) {
        o._$_renderersById.delete(e.id);
      });
      this.fire(W, {
        renderer: t,
        id: r
      });
    }
  }, {
    key: "getObjectInfo",
    value: function e(t) {
      var r = {
        obj: null,
        type: null,
        id: null
      };
      if (t != null) {
        if (typeof t === "string") {
          t = this.getNode(t) || this.getGroup(t) || this.getPort(t) || this.getEdge(t);
        } else if (a(t) === "object" && typeof t.objectType === "undefined") {
          var o = this.idFunction(t),
            n = this.edgeIdFunction(t);
          t = this.getNode(o) || this.getGroup(o) || this.getEdge(n);
        }
        if (t != null) {
          r.obj = t;
        } else {
          return r;
        }
        if (r.obj != null) {
          r.id = r.obj.id;
          r.type = r.obj.objectType;
        }
      }
      return r;
    }
  }, {
    key: "undo",
    value: function e() {
      this.undoRedo && this.undoRedo.undo();
    }
  }, {
    key: "redo",
    value: function e() {
      this.undoRedo && this.undoRedo.redo();
    }
  }, {
    key: "transaction",
    value: function e(t, r) {
      this.undoRedo && this.undoRedo.transaction(t, r);
    }
  }, {
    key: "openTransaction",
    value: function e(t) {
      this.undoRedo && this.undoRedo.openTransaction(t);
    }
  }, {
    key: "rollbackTransaction",
    value: function e() {
      this.undoRedo && this.undoRedo.rollbackTransaction();
    }
  }, {
    key: "commitTransaction",
    value: function e(t) {
      this.undoRedo && this.undoRedo.commitTransaction(t);
    }
  }, {
    key: "flushUndoRedo",
    value: function e() {
      this.undoRedo && this.undoRedo.clear();
    }
  }]);
  return xe;
}(ae);
export { Ae as JsPlumbToolkit, xe as UPDATE_GROUP_REASON_RESIZED, Te as UPDATE_NODE_REASON_ADD_NEW_PORT, $e as UPDATE_NODE_REASON_ADD_PORT, Pe as UPDATE_NODE_REASON_MOVED, je as UPDATE_NODE_REASON_REMOVE_PORT, Fe as UPDATE_NODE_REASON_UPDATE_PORT, Ee as UPDATE_REASON_DRAWING_TOOLS_RESIZE };