import { ModuleComponent } from '@core/module/module.component';
import { ModuleComponentComponent } from '@core/module/module-component/module-component.component';
import { ModuleCaseViewComponent } from '@core/module/module-case-view/module-case-view.component';
import { ModuleCaseViewPanelComponent } from '@core/module/module-case-view-panel/module-case-view-panel.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { Routes } from '@angular/router';
import { AuditExecutionStepComponent } from '@features/audit/audit-execution-step/audit-execution-step.component';
import { changesGuard } from '@core/guards/changes.guard';
import { TabByModuleComponent } from '@features/tab-by-module/tab-by-module.component';

/**
 * Generate module routing config
 * @param path Angular router entry point
 * @param breadcrumb Name used as breadcrumb. Can also be an object with breadcrumb config.
 */
export const generateModuleRouting = (_path = '', routes?: any[], module = '') => {
  const path = _path || module;
  const routeConfig: Routes = [{
    path: _path,
    component: ModuleComponent,
    children: [
      {
        path: 'deleted',
        component: TabByModuleComponent,
        children: [{
          path: '**',
          component: TabByModuleComponent
        }]
      },
      {
        path: 'assignments',
        component: TabByModuleComponent,
        children: [{
          path: '**',
          component: TabByModuleComponent
        }]
      },
      {
        path: 'administrate',
        component: TabByModuleComponent,
        children: [{
          path: '**',
          component: TabByModuleComponent
        }]
      },
      {
        path: 'findings-and-recommendations',
        component: TabByModuleComponent,
        children: [{
          path: '**',
          component: TabByModuleComponent
        }]
      },
      {
        path: 'triggers-and-activities',
        component: TabByModuleComponent,
        children: [{
          path: '**',
          component: TabByModuleComponent
        }]
      },
      {
        path: 'register-import',
        component: TabByModuleComponent,
        children: [{
          path: '**',
          component: TabByModuleComponent
        }]
      },
      {
        path: `${path}-wizard`,
        component: ModuleCaseViewComponent,
        children: [
          {
            path: 'object',
            pathMatch: 'full',
            component: ModuleCaseViewPanelComponent,
            canDeactivate: [changesGuard]
          }
        ]
      },
      {
        path: 'audit-execution-inprogress/:id/structure',
        component: ModuleCaseViewComponent,
        data: { breadcrumb: { alias: 'auditExecutionName' } },
        children: [
          {
            path: ':stepId',
            component: AuditExecutionStepComponent,
            data: { breadcrumb: { alias: 'auditExecutionStepName' } }
          },
          {
            path: 'group/:objectId',
            component: AuditExecutionStepComponent,
            data: { breadcrumb: { alias: 'auditExecutionStepName' } }
          },
          {
            path: 'root/:rootObjectId',
            component: AuditExecutionStepComponent,
            data: { breadcrumb: { alias: 'auditExecutionStepName' } }
          }
        ]
      },
      {
        path: 'audit-execution/:id/structure',
        component: ModuleCaseViewComponent,
        data: { breadcrumb: { alias: 'auditExecutionName' } },
        children: [
          {
            path: ':stepId',
            component: AuditExecutionStepComponent,
            data: { breadcrumb: { alias: 'auditExecutionStepName' } }
          },
          {
            path: 'group/:objectId',
            component: AuditExecutionStepComponent,
            data: { breadcrumb: { alias: 'auditExecutionStepName' } }
          },
          {
            path: 'root/:rootObjectId',
            component: AuditExecutionStepComponent,
            data: { breadcrumb: { alias: 'auditExecutionStepName' } }
          }
        ]
      },
      {
        path: ':component',
        component: ModuleComponentComponent,
        canDeactivate: [changesGuard]
      },
      {
        path: ':caseType/:caseId',
        component: ModuleCaseViewComponent,
        canDeactivate: [changesGuard],
        data: { breadcrumb: { alias: 'caseViewName' } },
        children: [
          {
            path: ':panelName',
            component: ModuleCaseViewPanelComponent,
            canDeactivate: [changesGuard],
            data: { breadcrumb: { alias: 'caseViewPanelName' } }
          }
        ]
      }
    ],
    canActivate: [AuthGuard]}];
  if (routes) routeConfig[0].children!.unshift(...routes);
  return routeConfig;
};
