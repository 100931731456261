import { ComponentType } from '@angular/cdk/portal';
import { Component, Inject, effect, input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FORM_LOOKUP_TOKEN } from '@core/constants';
import { FormLookupComponent } from '@core/form/form-lookup/form-lookup.component';
import { BeinformedService } from '@core/services';
import { NgStyle } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { IconComponent } from '../icon/icon.component';

interface IDynamicCard {
  href: string;
  value: number;
  color: string;
  icon: string;
  label: string;
}

@Component({
  selector: 'app-dynamic-dashboard-card',
  templateUrl: './dynamic-dashboard-card.component.html',
  styleUrl: './dynamic-dashboard-card.component.scss',
  standalone: true,
  imports: [NgStyle, MatTooltip, IconComponent]
})
export class DynamicDashboardCardComponent {

  public href = input.required<string>();
  public card: IDynamicCard;

  constructor(
    private readonly beinformedService: BeinformedService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    @Inject(FORM_LOOKUP_TOKEN) private readonly formLookupComponent: ComponentType<FormLookupComponent>
  ) {
    effect(() => {
      if (!!this.href()) this.fetchData(this.href());
    });
  }

  public fetchData(href: string) {
    this.beinformedService.fetchForm(href).subscribe({
      next: res => {
        const elements = res.data.error.formresponse.missing?.anchors[0].elements;
        const objectid = res.data.error.formresponse.missing?.anchors[0].objectid || '';
        this.card = {
          href: elements?.find(el => el.elementid === 'Href')?.suggestion || '', 
          value: elements?.find(el => el.elementid === 'Value')?.suggestion || '0', 
          color: elements?.find(el => el.elementid === 'Color')?.suggestion || '', 
          icon: elements?.find(el => el.elementid === 'Icon')?.suggestion || '',
          label: res.contributions.objects[objectid].label
        };
      }
    });
  }

  public openDialog() {
    if (!!this.card.href) {
      this.dialog.open(this.formLookupComponent, {
        panelClass: 'naris-advanced-lookup-dialog',
        minWidth: '54rem',
        data: {endpoint: this.card.href, multiple: false, enableRowClick: false}
      }).afterClosed().subscribe(val => {
        if (!!val?._links?.self.href) {
          void this.router.navigate([val._links.self.href]);
        }
      });
    }
  }

}
