@if (prepend) {
  <div class="prepend">
    {{ prepend }}
  </div>
}

@if (prependColor) {
  <div
    tabindex="0"
    class="color-prepend"
    [ngStyle]="{backgroundColor: prependColor}"
    (click)="togglePicker.emit()"
  ></div>
}

@if (type === 'textarea' && !layouthint?.includes('tags')) {
  <textarea
    #inputElement
    class="textarea"
    [cdkTextareaAutosize]="autosize"
    rows="3"
    [placeholder]="placeholder"
    [formControl]="control"
    (focus)="focus()"
    (blur)="blur($event)"
    [attr.data-test-id]="'input__' + id"
  ></textarea>
}

@if (type !== 'textarea' && (!options && !lookup) && !layouthint?.includes('tags')) {
  <input
    #inputElement
    class="input"
    [type]="displayType"
    [formControl]="control"
    (focus)="focus()"
    (blur)="blur($event)"
    [autofocus]="autofocus"
    [placeholder]="placeholder || ''"
    (keypress)="onEnterPress($event)"
    [min]="min"
    [max]="max"
    [minlength]="minLength"
    [maxlength]="maxLength"
    [pattern]="pattern"
    (change)="onChange()"
    (input)="onInput()"
    [autocomplete]="type === 'password' && autocomplete === 'off' ? 'current-password' : autocomplete"
    [attr.data-test-id]="'input__' + id"
  >
}

@if (!!layouthint?.includes('tags') && !!inputControl) {
  <div #inputElement class="input chip-list">
    <input
      class="chip-input"
      [placeholder]="placeholder"
      [formControl]="inputControl"
      [autofocus]="autofocus"
      [pattern]="pattern"
      [matChipInputFor]="chipGrid"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="handleChipInput($event)"
      (focus)="focus()"
      (blur)="blur($event)"
      [attr.data-test-id]="'input__' + id"
    >
    <div class="chip-wrapper">
      <mat-chip-grid
        #chipGrid
        [disabled]="disabled"
      >
        @if (selection.selected.length !== 0) {
          @for (chip of selection.selected; track chip) {
            <mat-chip-row
              [removable]="!disabled"
              (removed)="onChipRemove(chip)"
            >
              {{chip}}
              @if (!disabled) {
                <naris-icon matChipRemove icon="cancel" />
              }
            </mat-chip-row>
          }
        } @else {
          <div class="chip-list-empty">
            <p class="chip-list-empty-label">{{ 'autocomplete.empty_selection' | translate }}</p>
          </div>
        }
      </mat-chip-grid>
    </div>
  </div>
}

@if (type === 'password') {
  <div class="handle">
    @switch (displayType) {
      @case ('password') {
        <naris-icon
          icon="perspective"
          (click)="toggleVisibility()"
          class="icon"
          iconClass="default light"
          [matTooltip]="'input.show_tooltip' | translate"
          matTooltipPosition="above"
          matTooltipShowDelay="750"
          [attr.data-test-id]="'input__' + id + '__showPassword'"
        />
      }
 
      @case ('text') {
        <naris-icon
          icon="perspective_off"
          (click)="toggleVisibility()"
          class="icon"
          iconClass="default light"
          [matTooltip]="'input.hide_tooltip' | translate"
          matTooltipPosition="above"
          matTooltipShowDelay="750"
          [attr.data-test-id]="'input__' + id + '__hidePassword'"
        />
      }
    }
  </div>
}

@if (clearable && control.value) {
  <div class="handle">
    <naris-icon
      icon="close"
      class="icon closer"
      iconClass="default light"
      [matTooltip]="'input.clear_tooltip' | translate"
      matTooltipPosition="right"
      (click)="clear()"
    />
  </div>
}

@if (loading) {
  <div class="loader">
    <naris-icon iconClass="loader-icon" [inline]="true" icon="loader" />
  </div>
}

@if (append) {
  <div class="append">
    @if (!filter) {
      <span>{{ append }}</span>
    } @else {
      <naris-icon
        [icon]="append"
        iconClass="small"
        class="append-icon"
        [matTooltip]="'input.apply_tooltip' | translate"
      />
    }
  </div>
}
