import { Component, OnInit, OnDestroy } from '@angular/core';
import { BeinformedService, UserService } from '@core/services';
import { ActivatedRoute, Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { ICaseTabComponent, ICombinedResponse } from '@core/models';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.scss'],
  standalone: true,
  imports: [RouterOutlet]
})
export class ModuleComponent implements OnInit, OnDestroy {
  public module: string;
  public endpoint: string;
  public components: ICaseTabComponent[];
  public loading = true;
  private subs: Subscription[];

  constructor(
    private readonly beinformedService: BeinformedService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly userService: UserService
  ) { }

  public ngOnInit(): void {
    this.subs = [
      // Check for empty component routes, and redirect if no component is loaded
      this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
        if (this.endpoint === this.router.url) this.redirectToFirstItem();
      }),
      // Update on param changes
      this.activatedRoute.params.subscribe(() => {
        const endpoint = this.endpoint = `/${this.router.url.split('/')[1]}`;
        this.fetchModule(endpoint);
      })
    ];
  }

  public ngOnDestroy(): void {
    this.subs.forEach((s: Subscription) => s.unsubscribe());
  }

  /**
   * Navigate to the first item in the components array
   */
  private redirectToFirstItem(): void {
    void this.router.navigate([this.components[0].href], { replaceUrl: true });
  }

  /**
   * Fetch the module
   * @param endpoint BeInformed API endpoint
   */
  private fetchModule(endpoint: string): void {
    if (endpoint === '/config') {
      this.loading = false; 
      return;
    }

    if (this.userService.userData._id === 1) 
      endpoint = '/naris-admin';

    this.beinformedService.fetchResponseWithContributions(endpoint).subscribe({
      next: (result: ICombinedResponse) => {
        this.components = this.beinformedService.extractComponents(result);

        // If the current url matches the endpoint for this module, redirect user to first component
        if (this.router.url === this.endpoint) this.redirectToFirstItem();

        this.loading = false;
      },
      // If module fails to load, this mostly means user has no permission, so redirect to start
      error: () => {
        if (this.userService.userData.sessiondata.HoldingID === this.userService.userData.sessiondata.OrganizationID) void this.router.navigate(['/organizations']);
        else void this.router.navigate(['/start']);
      }
    });
  }
}
