import { Injectable } from '@angular/core';
import { ICaseListAction } from '@core/models';
import { ObjectData, Surface } from '@jsplumbtoolkit/browser-ui';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProcessManagerService {

  public surface: Surface;

  public addNode$: Subject<{ type: string; direction: string; id: string }> = new Subject<{ type: string; direction: string; id: string }>();
  public addLinkedObject$: Subject<string> = new Subject<string>();
  public removeNode$: Subject<string> = new Subject<string>();
  public updateEdge$: Subject<{ edgeId: string; value: string }> = new Subject<{ edgeId: string; value: string }>();
  public updateNode$: Subject<{ nodeId: string; value: string }> = new Subject<{ nodeId: string; value: string }>();
  public openSubProcess$: Subject<ObjectData> = new Subject<ObjectData>();
  public nodeChanged$: Subject<{obj: Record<string, any>, id: string}> = new Subject<{obj: Record<string, any>, id: string}>();

  public nodePosition = '';
  public nodeType = '';

  public href: string;
  public rootActions: ICaseListAction[] = [];
}
