import { Component, OnInit, HostBinding, ViewChild, OnDestroy, HostListener, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router, RouterLinkActive, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';
import { OverlayComponent } from '@shared/components/overlay/overlay.component';
import { CoreService, FormService, MessageService, AuthService, UserService, LoaderService, LanguageService, TabService, TableService } from '@core/services';
import { fadeInOutAnimation } from '@shared/animations/core.animations';
import { LocalStorage } from '@core/decorators/storage.decorators';
import { getInitials } from '@core/helpers';
import { CollabService } from '@core/services/collab.service';
import { WORDPRES_BASE_URL } from '@core/constants/core-constants';
import { IDashboard, ILanguage, ILayoutTab } from '@core/models';
import { DashboardService } from '@core/services/dashboard.service';
import { NarisBreadcrumbService } from '@core/services/breadcrumb.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { BreadcrumbTabsService } from '@core/services/breadcrumb-tab.service';
import { IBreadcrumbTab } from '@core/models/breadcrumb-tab.models';
import { MatTooltip } from '@angular/material/tooltip';
import { NgStyle } from '@angular/common';
import { MatBadge } from '@angular/material/badge';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { IconComponent } from '../../shared/elements/icon/icon.component';
import { ButtonComponent } from '../../shared/elements/button/button.component';
import { ToolbarComponent } from '../../shared/components/toolbar/toolbar.component';
import { ToolbarItemComponent } from '../../shared/components/toolbar/toolbar-item/toolbar-item.component';
import { BreadcrumbTabsComponent } from '../../shared/components/breadcrumb-tabs/breadcrumb-tabs.component';
import { SlidetoggleComponent } from '../../shared/elements/slidetoggle/slidetoggle.component';
import { DividerComponent } from '../../shared/elements/divider/divider.component';
import { AvatarComponent } from '../../shared/elements/avatar/avatar.component';
import { TabbarComponent } from '../../shared/components/tabbar/tabbar.component';
import { OverlayComponent as OverlayComponent_1 } from '../../shared/components/overlay/overlay.component';
import { DrawerComponent } from '../../shared/components/drawer/drawer.component';
import { FormComponent } from '../../core/form/form.component';
import { ListComponent } from '../../shared/components/list/list.component';
import { CollabComponent } from '../../shared/components/collab/collab.component';
import { FooterToolbarComponent } from '../../shared/components/footer-toolbar/footer-toolbar.component';
import { EasterEggComponent } from '../../shared/components/easter-egg/easter-egg.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ChangelogsComponent } from './changelogs/changelogs.component';
import { HelpComponent } from './help/help.component';
import type { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'naris-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  animations: [fadeInOutAnimation],
  standalone: true,
  imports: [RouterLinkActive, MatTooltip, NgStyle, IconComponent, ButtonComponent, ToolbarComponent, ToolbarItemComponent, BreadcrumbTabsComponent, SlidetoggleComponent, MatBadge, MatMenu, MatMenuItem, DividerComponent, MatMenuTrigger, AvatarComponent, TabbarComponent, RouterOutlet, OverlayComponent_1, DrawerComponent, FormComponent, ListComponent, NotificationsComponent, ChangelogsComponent, HelpComponent, CollabComponent, FooterToolbarComponent, EasterEggComponent, TranslateModule]
})

export class AppLayoutComponent implements OnInit, OnDestroy {

  @LocalStorage()
  public sidebarCollapsed: boolean;
  public navigation: { modules: any[] | undefined; configModules: any[] | undefined; supportModules: any[] | undefined; setupModules: any[] | undefined };
  public loaded = false;
  public selectedLanguage: string;
  public selectedLanguageFlag?: string;
  public availableLanguages: ILanguage[] = [];
  public collabOpened = false;
  public version: string;
  private subs: Subscription[];

  public layoutTabs: ILayoutTab[];
  public caseViewIsLoaded = true;

  public editModeEnabled: boolean;

  public dashboard: IDashboard;
  private activeDashboardId: number;
  public isViewSelectionOpen = false;
  public colorNew = 'new' as ThemePalette;
  public moduleTypes: (keyof typeof this.navigation)[] = ['modules', 'configModules', 'supportModules', 'setupModules'];

  public subNavTop: number;
  private hoveredTabName: any;
  private timeout: NodeJS.Timeout;
  private hoveredIndex: number;
  private prevUrl: string;

  public showHoldingToggle: string;
  public holdingToggleState = false;
  public currentTab: any;
  public holdingTabsToAddBar = ['activities', 'organizations'];
  public nonPushRoutes = ['Events', 'Tags', 'Documents'];

  public profileTabUrl = `profile-tab/my-person/${this.userService.userId}`;

  private readonly pattern = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'];
  private current = 0;

  @ViewChildren('subNavs', { read: ElementRef}) public subNavs: QueryList<ElementRef>;

  /**
   * Reference to the messages drawer
   */
  @ViewChild('notifications') private readonly notificationsDrawer: OverlayComponent;

  /**
   * Reference to the search overlay
   */
  @ViewChild('search') private readonly searchModal: OverlayComponent;

  /**
   * Reference to the messages drawer
   */
  @ViewChild('modal') private readonly modalDrawer: OverlayComponent;

  /**
   * Reference to the changelogs drawer
   */
  @ViewChild('changelogs') private readonly changelogsDrawer: OverlayComponent;

  constructor(
    public userService: UserService,
    private readonly coreService: CoreService,
    private readonly formService: FormService,
    private readonly messageService: MessageService,
    private readonly authService: AuthService,
    private readonly languageService: LanguageService,
    public readonly loaderService: LoaderService,
    public readonly router: Router,
    public readonly collabService: CollabService,
    public readonly tabs: TabService,
    private readonly dashboardService: DashboardService,
    private readonly narisBreadcrumbService: NarisBreadcrumbService,
    private readonly translate: TranslateService,
    private readonly breadcrumbTabService: BreadcrumbTabsService,
    private readonly tableService: TableService
  ) {}

  get helpOpened() {
    return this.coreService.helpOpened;
  }

  set helpOpened(val: boolean) {
    this.coreService.helpOpened = val;
  }

  get dashboardUrl(): boolean {
    const url = this.router.url.split('?')[0];
    return url.includes('start') || url.includes('-dashboard');
  }

  get complianceAssessment(): boolean {
    return this.router.url.includes('compliance-assessment');
  }
  
  public ngOnInit(): void {
    this.loaderService.open();
    this.sidebarCollapsed ??= false;
    this.coreService.sidebarCollapsed = this.sidebarCollapsed;
    this.selectedLanguage = this.languageService.getAppLang();
    this.selectedLanguage = this.languageService.isLanguageAvailable(this.selectedLanguage) ? this.selectedLanguage : 'en';
    this.availableLanguages = this.languageService.availableLanguages;
    this.selectedLanguageFlag = this.availableLanguages.find(item => item.code === this.selectedLanguage)?.image;
    this.version = this.userService.currentVersion;

    if (this.userService.userData._id !== 1 && !this.router.url.includes('archive'))
      this.userService.getUserActions().subscribe(() => {
        const holdingToggleFound = this.userService.userActions?.find(action => action.name === 'toggle-holding-organization');
        if (!!holdingToggleFound) {
          this.showHoldingToggle = holdingToggleFound.href!;
        }
      });

    this.userService.getTasks().subscribe(() => {
      // get toggle-archive-mode action. If not available user may not perform action
      this.userService.toggleArchiveAction = this.userService.tasks.find(action => action.name === 'toggle-archive-mode');
    });

    this.subs = [
      this.userService.userDataUpdated.subscribe(() => this.setHoldingToggleState()),
      this.coreService.initialize().pipe(
        finalize(() => this.loaderService.close())
      ).subscribe(() => {
        this.loaded = true;
        if (this.userService.userId && !this.userService.isArchiveMode) {
          this.messageService.start();
        }
      }),
      this.coreService.fetchModules().subscribe(result => {
        this.navigation = result;
        this.initBreadcrumbTabs();
        if (this.userService.userData.sessiondata.HoldingID === this.userService.userData.sessiondata.OrganizationID) void this.router.navigate(['/organizations/organizations']);
        if (this.router.url.startsWith('/dashboard') && this.userService.userData.sessiondata.HoldingID !== this.userService.userData.sessiondata.OrganizationID) this.dashboardService.initialize(this.navigation);
        this.currentTab = this.navigation['modules']!.find(tab => (tab.href || tab.url).includes(this.router.url.split('/')[1]));
      }),
      this.router.events.pipe(filter((event: Event) => event instanceof NavigationEnd)).subscribe((event: any) => {
        if (event.url.startsWith('/dashboard')) this.dashboardService.initialize(this.navigation);
      }),
      this.tabs.updated.subscribe(res => setTimeout(() => {
        this.layoutTabs = res;
      })),
      this.tabs.caseViewLoaded.subscribe(loading => this.caseViewIsLoaded = loading),
      this.dashboardService.dashChanged.subscribe(dashboard => this.dashboard = dashboard),
      this.dashboardService.activeDashIdChanged.subscribe(activeDashboardId => this.activeDashboardId = activeDashboardId),
      this.router.events.pipe(filter(( event: Event ) => event instanceof NavigationStart)).subscribe((event: any) => {
        if (event.navigationTrigger === 'popstate') {
          this.prevUrl = event.url;
        }
      })
    ];

    this.narisBreadcrumbService.start();
    setTimeout(() => {
      this.narisBreadcrumbService.add('/start', this.translate.instant('breadcrumb.mydashboard'));
    }, 200);

    const breadcrumbTabs = localStorage.getItem('breadcrumb-tabs');
    const isNewTab = !!localStorage.getItem('new-tab');
    if (breadcrumbTabs !== null && !isNewTab) {
      this.breadcrumbTabService.tabs = JSON.parse(breadcrumbTabs) as IBreadcrumbTab[];
    }
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    if (this.userService.userId) {
      this.messageService.stop();
    }
  }

  /**
   * Toggle sidebar
   */
  public toggleSidebar(): void {
    this.sidebarCollapsed = !this.sidebarCollapsed;    
    this.coreService.sidebarCollapsed = this.sidebarCollapsed;
  }

  /**
   * Opens the notifications drawer
   */
  public openNotifications(): void {
    this.notificationsDrawer.open();
  }

  public setup(): void {
    void this.router.navigate([`/configuration/configuration-check/${this.userService.organizationID}`]);
  }

  /**
   * Called to fetch demo form. Opens the form drawer if loaded, otherwise fetches the form from API.
   */
  public getForm(endpoint: string): void {
    this.formService.open(endpoint).subscribe();
  }

  public setContext(): void {
    this.getForm(`/profile-tab/my-person/${this.userService.userId}/tasks/set-context`);
  }

  get isUser(): boolean {
    return !!this.userService.userId;
  }

  public getDrawerOffset(i: number): number {
    return (this.formService.drawers.filter(d => d.active).length - 1 - i) * 2;
  }

  get drawers() {
    return this.formService.drawers;
  }

  /**
   * Returns the unread count of notifications
   */
  get unreadNotificationCount(): number {
    return this.messageService.unreadNotifications;
  }

  /**
   * Opens changelogs and releasenotes
   */

  public toChangeLogs() {
    this.changelogsDrawer.open();
    this.userService.latestRead = this.version;
  }

  /**
   * Returns the cached applicationData object from the core service
   */
  get application() {
    return this.coreService.applicationData;
  }

  get hasActiveContext(): boolean {
    return !!this.userService.userData.sessiondata.WorkingContextIds;
  }

  /**
   * Returns an object with the cached user's email and name (if present)
   */
  get user() {
    return {
      email: this.userService.userData?.Username || 'NoEmail',
      name: this.userService.userData?.Fullname || 'NoName',
      initials: getInitials(this.userService.userData?.Fullname),
      organization: this.userService.userData?.OrganizationName || ''
    };
  }

  /**
   * Logs out the current user
   */
  public logout() {
    this.authService.logout().subscribe(() => void this.router.navigate(['/login']));
  }

  public toOrg() {
    this.breadcrumbTabService.clear();
    const currentUrl = this.router.routerState.snapshot.url;
    void this.router.navigate(['/org'], {state: {previousUrl: currentUrl}});
  }

  get hasMultipleOrgs(): boolean {
    return (this.userService.userOrgs?.length ?? 0) > 1;
  }

  public openWindow(url: string, appendLang = false, resource = '') {
    const fullUrl = `${url}/${appendLang ? this.languageService.getAppLang() : ''}${resource}`;
    window.open(fullUrl);
  }

  @HostBinding('class')
  get rootClasses() {
    const classes = ['naris-layout'];
    if (this.sidebarCollapsed) classes.push('collapsed');
    return classes;
  }

  public isModuleActive(path: string): boolean {
    return this.router.url.startsWith(path);
  }

  public playStartupSound() {
    const audio = new Audio();
    audio.src = '/assets/media/winxp.mp3';
    audio.volume = 0.2;
    audio.load();
    void audio.play();
  }

  public useLanguage(language: string) {
    this.selectedLanguageFlag = this.availableLanguages.find(item => item.code === language)?.image;
    this.languageService.setLanguage(language, true)?.pipe(finalize(() => window.location.reload())).subscribe();
  }

  @HostListener('window:keydown', ['$event'])
  public keyDownListenFunction($event: KeyboardEvent) {
    // TODO: for future reference. De open funtie herkent ie niet meer...
    // if (!this.formService.drawers?.length && ($event.altKey && $event.code === 'KeyF')) this.searchModal.open();
    
    if ($event.key === 'Escape') {
      this.showEasterEgg = false;
      return;
    }
    if (!this.pattern.includes($event.key) || $event.key !== this.pattern[this.current]) {
      this.current = 0;
      return;
    }

    this.current++;

    if (this.pattern.length === this.current) {
      this.current = 0;
      this.showEasterEgg = true;
    }
  }

  public toCollab() {
    void this.router.navigate([`/collaboration/collaboration/${this.collabService.currentRoom.split('-')[1]}/session`]);
  }

  public newVersion() {
    return !this.userService.readLatest();
  }

  public openHelp(isArticle = true) {
    if (!isArticle) {
      const href = `${WORDPRES_BASE_URL}/index.php/category/release-notes`;
      this.coreService.setHelp(href);
    }
    this.coreService.openArticle = isArticle;
    this.helpOpened = true;
  }

  public setCrumbData(event: MouseEvent, tab: any) {
    this.breadcrumbTabService.clear();
    this.currentTab = tab;
    this.narisBreadcrumbService.menuClick = true;
    if (tab.name === 'SetupOrganization') {
      this.breadcrumbTabService.add({label: tab.label, url: `/setup-organization/setup-organization-wizard/${this.userService.organizationID}`} as IBreadcrumbTab);
      void this.router.navigate([`/setup-organization/setup-organization-wizard/${this.userService.organizationID}`]);
    } else if (tab.name === 'MyProfile') {
      this.breadcrumbTabService.add({label: tab.label, url: `profile-tab/my-person/${this.userService.userId}`} as IBreadcrumbTab);
      void this.router.navigate([`profile-tab/my-person/${this.userService.userId}`]);
    } else if (tab.name === 'Home') {
      this.breadcrumbTabService.add({label: this.translate.instant('tabbar.dashboard') as string, url: tab.url || tab.href} as IBreadcrumbTab);
      this.navigateTo(tab.url || tab.href, event.ctrlKey);
    } else if (!!tab.routes?.length) {
      this.breadcrumbTabService.add({label: tab.routes[0].label, url: tab.routes[0].href || tab.routes[0].url} as IBreadcrumbTab);
      this.navigateTo(tab.routes[0].url || tab.routes[0].href, event.ctrlKey);
    } else {
      this.breadcrumbTabService.add({label: tab.label, url: tab.url || tab.href} as IBreadcrumbTab);
      this.navigateTo(tab.url || tab.href, event.ctrlKey);
    }
  }

  private initBreadcrumbTabs() {
    const tabs = localStorage.getItem('breadcrumb-tabs');    
    const isNewTab = !!localStorage.getItem('new-tab');
    if (tabs === null || isNewTab) {
      if (isNewTab) localStorage.removeItem('new-tab');
      this.navigation.modules?.forEach(module => {
        if (this.isModuleActive(module.href)) {
          if (!!module.routes.length) {
            this.breadcrumbTabService.add({label: module.routes[0].label, url: module.routes[0].href} as IBreadcrumbTab);
          } else {
            this.breadcrumbTabService.add({label: module.label, url: module.url || module.href} as IBreadcrumbTab);
          }
        }
      });
    }
  }

  private navigateTo(href: string, openInNewTab: boolean) {
    if (openInNewTab) {
      const newTabUrl = window.location.origin + href;
      window.open(newTabUrl, '_blank');
    } else void this.router.navigate([href]);
  }

  public hoverNavItem(moduleTypeIndex: number, itemIndex: number, tab: any) {
    if (tab.name === this.hoveredTabName) {
      clearTimeout(this.timeout);
    } else {
      this.hoveredTabName = tab.name;
    }
    this.moduleTypes.forEach(moduleType => this.navigation[moduleType]?.forEach((module: { hovered: boolean }) => module.hovered = false));
    tab.hovered = true;

    const numOfModules = this.navigation[this.moduleTypes[0]]!.length;
    const numOfConfigModules = this.navigation[this.moduleTypes[1]]!.length;
    const numOfSupportModules = this.navigation[this.moduleTypes[2]]!.length;
    this.hoveredIndex = itemIndex;
    if (moduleTypeIndex === 1) {
      this.hoveredIndex = numOfModules + itemIndex;
    } else if (moduleTypeIndex === 2) {
      this.hoveredIndex = numOfModules + numOfConfigModules + itemIndex;
    } else if (moduleTypeIndex === 3) {
      this.hoveredIndex = numOfModules + numOfConfigModules + numOfSupportModules + itemIndex;
    }

    const element = this.subNavs.toArray()?.[this.hoveredIndex].nativeElement;
    const elementRect =  element.getBoundingClientRect() as DOMRect;

    const sibling = element.nextElementSibling;
    const siblingHeight = (sibling?.children?.length - (this.sidebarCollapsed ? 0 : 1)) * 32 + 24; // 32 = height of nav item, 24 is padding in subNav

    const windowHeight = window.innerHeight;
    const oversizeTop = elementRect.y + elementRect.height - siblingHeight;
    this.subNavTop = siblingHeight + elementRect.y >= windowHeight ? oversizeTop : elementRect.y;
  }

  public stopHoverNavItem(tab: any) {
    if (tab.name !== this.hoveredTabName) {
      tab.hovered = false;
    } else {
      this.timeout = setTimeout(() => { // timeout is necessary for firefox users with 'always show scrollbars' setting 'off' in windows...
        tab.hovered = false;
      }, 200);
    }
  }

  private setHoldingToggleState() {
    this.holdingToggleState = this.userService.userData.sessiondata.HoldingID === this.userService.userData.sessiondata.OrganizationID;
  }

  public toggleHoldingOrganization(event: MouseEvent, url: string) {
    const classList = Array.from((event.target as any)?.['classList'] as DOMTokenList);
    if (!!classList) {
      const isToggleClicked = classList.some((className: string) => ['mat-slide-toggle-thumb', 'mat-slide-toggle-bar', 'mat-slide-toggle-thumb-container'].includes(className));
      if (!isToggleClicked) {
        this.holdingToggleState = !this.holdingToggleState;
      }
    }

    this.subs.push(
      this.userService.toggleUserHoldingOrganization(url).subscribe(() => {
        void this.router.navigate(['start']);
        setTimeout(() => window.location.reload());
      })
    );
  }

  public showHoldingToolbar() {
    return this.holdingTabsToAddBar.includes(this.currentTab.label.toLowerCase());
  }

  public isHolding() {
    return this.userService.userData.sessiondata.HoldingID === this.userService.userData.sessiondata.OrganizationID;
  }

  public isDashboard() {
    return this.router.url.includes('-dashboard');
  }

  public navigateToMyProfile() {    
    this.breadcrumbTabService.clear();
    this.breadcrumbTabService.add({label: this.translate.instant('my_profile'), url: `profile-tab/my-person/${this.userService.userId}`} as IBreadcrumbTab);
    void this.router.navigate([this.profileTabUrl]);
  }

  public showEasterEgg = false;
  public toggleEasterEgg(event: MouseEvent) {
    if (event.detail === 3) {
      this.showEasterEgg = !this.showEasterEgg;
    }
  }

  public userActionExists(actionName: string): boolean {
    return this.userService.userActions?.map(userAction => userAction.name).includes(actionName) || false;
  }
}
