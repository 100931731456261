import { createClass as e, defineProperty as t, classCallCheck as o } from "../_virtual/_rollupPluginBabelHelpers.js";
import { SurfacePlugins as r, SURFACE_MODE_VERTEX_DRAWING as i, SURFACE_MODE_DISABLED as a, SURFACE_MODE_PAN as n } from "../browser-ui/surface.js";
import { Lasso as s } from "../browser-ui-plugin-lasso/lasso.js";
import { EVENT_SURFACE_MODE_CHANGED as u } from "../browser-ui/constants.js";
import { uuid as l } from "../ui-core/util/util.js";
import { Node as d, Group as c, isGroup as p } from "../core/model/graph.js";
import { addClass as f, removeClass as v } from "../ui-core/browser-ui-renderer/browser-util.js";
import { Selection as h } from "../core/selection.js";
import { DEFAULT as y } from "../ui-core/common/index.js";
import { snapToGrid as _ } from "../ui-core/util/geom.js";
var T = "jtk-vertex-drawing-lasso";
var g = "jtk-group-candidate-child";
var m = function () {
  function r() {
    o(this, r);
    t(this, "id", void 0);
    t(this, "surface", void 0);
    t(this, "lasso", void 0);
    t(this, "autoExit", void 0);
    t(this, "typeGenerator", void 0);
    t(this, "groupType", void 0);
    t(this, "nodeType", void 0);
    t(this, "defaultGroupSize", void 0);
    t(this, "defaultNodeSize", void 0);
    t(this, "_allowClickToAdd", void 0);
    t(this, "_parentGroupTarget", void 0);
    t(this, "_objectTypeToCreate", void 0);
    t(this, "_typeToCreate", void 0);
    t(this, "_newObjectPayload", void 0);
    t(this, "_minSize", void 0);
    t(this, "vertexSelection", void 0);
    t(this, "selectedElements", []);
  }
  e(r, [{
    key: "destroy",
    value: function e() {}
  }, {
    key: "_deriveTypesToCreate",
    value: function e(t, o) {
      if (this.typeGenerator != null) {
        var r = this.typeGenerator(t, o);
        this._objectTypeToCreate = r.objectType;
        this._typeToCreate = r.type;
        this._newObjectPayload = r.data || {};
      } else if (this.nodeType != null) {
        this._objectTypeToCreate = d.objectType;
        this._typeToCreate = this.nodeType;
        this._newObjectPayload = {};
      } else {
        this._objectTypeToCreate = c.objectType;
        this._typeToCreate = this.groupType || y;
        this._newObjectPayload = {};
      }
      var i = this.surface.getModelObjectFromElement(o.target, true);
      if (i != null && p(i)) {
        this._parentGroupTarget = i;
      } else {
        this._parentGroupTarget = null;
      }
    }
  }, {
    key: "initialise",
    value: function e(t, o) {
      var r = this;
      this.surface = t;
      this._minSize = o.minSize || {
        w: 50,
        h: 50
      };
      this.nodeType = o.nodeType;
      this.groupType = o.groupType;
      this.typeGenerator = o.typeGenerator;
      this._allowClickToAdd = o.allowClickToAdd === true;
      this.defaultGroupSize = o.defaultGroupSize || {
        w: 250,
        h: 250
      };
      this.defaultNodeSize = o.defaultNodeSize || {
        w: 80,
        h: 80
      };
      var l = "".concat(T, " ").concat(o.lassoClass || "");
      t.bind(u, function (e) {
        r.lasso.enabled = e === i;
      });
      this.vertexSelection = new h(t.toolkitInstance);
      this.autoExit = o.autoExit === true;
      var d = {
        surface: t,
        invert: o.invert,
        canvas: t.containerElement,
        cssClass: l,
        mapToGrid: function e(t) {
          if (r.surface._$_gridProfile.grid) {
            var o = r.surface.fromPageLocation(t.x, t.y);
            var i = _(o, r.surface._$_gridProfile.grid);
            return r.surface.toPageLocation(i.x, i.y);
          } else {
            return t;
          }
        },
        onStart: function e(o, i, a) {
          t.setHoverSuspended(true);
          r._deriveTypesToCreate(o, a);
        },
        onSelect: function e(i, a, n, s, u) {
          if (u != null && o.vertexPreviewUpdater) {
            o.vertexPreviewUpdater(i, a, u);
          }
          if (r._objectTypeToCreate === c.objectType) {
            r.vertexSelection.clear();
            r._resetSelectedElements();
            var l = [],
              d,
              p = t.findAllIntersectingVerticesInCanvas(i, a, true),
              v = p.length,
              h = {};
            for (d = 0; d < v; d++) {
              l.push(p[d].v);
              h[p[d].id] = true;
              r.vertexSelection.append(p[d].v);
              f(p[d].el, g);
              r.selectedElements.push(p[d].el);
            }
          }
        },
        onEnd: function e(o, i) {
          t.setHoverSuspended(false);
          r._resetSelectedElements();
          if (r.autoExit && t.mode !== a) {
            t.setMode(n);
          }
          if (i.w >= r._minSize.w || i.h >= r._minSize.h) {
            r._doAddVertex(o, i);
          }
        },
        filter: function e(o) {
          var r = o.srcElement || o.target;
          return r === t.getContainer() || r === t.canvasElement;
        },
        onClick: function e(o, i) {
          if (r.autoExit && t.mode !== a) {
            t.setMode(n);
          }
          if (r._allowClickToAdd) {
            var s = r._objectTypeToCreate === c.objectType ? r.defaultGroupSize : r.defaultNodeSize;
            r._doAddVertex(o, s);
          }
        }
      };
      if (o.vertexPreviewGenerator != null) {
        d.generateLassoContent = function (e, t) {
          return o.vertexPreviewGenerator(e, t);
        };
      }
      this.lasso = new s(d);
      return false;
    }
  }, {
    key: "_doAddVertex",
    value: function e(t, o) {
      var r = this.surface.fromPageLocation(t.x, t.y);
      var i = this.surface.fromPageLocation(t.x + o.w, t.y + o.h);
      if (this._objectTypeToCreate === c.objectType) {
        this._addNewGroup(this.surface, r, i);
      } else {
        this._addNewNode(this.surface, r, i);
      }
    }
  }, {
    key: "_generateNewObjectPayload",
    value: function e(t, o) {
      var r = Object.assign(this._newObjectPayload, {
        id: l(),
        left: t.x,
        top: t.y,
        width: o.x - t.x,
        height: o.y - t.y,
        type: this._typeToCreate
      });
      if (this._parentGroupTarget != null) {
        var i = this.surface.jsplumb.viewport.getPosition(this._parentGroupTarget.id);
        r.left -= i.x;
        r.top -= i.y;
      }
      return r;
    }
  }, {
    key: "_filterVertexSelection",
    value: function e() {
      var t = this;
      var o = this.vertexSelection.getNodes();
      var r = this.vertexSelection.getGroups();
      var i = {
        nodes: [],
        groups: []
      };
      o.forEach(function (e) {
        if (r.find(function (o) {
          return t.surface.toolkitInstance.graph.isAncestor(e, o);
        }) == null) {
          i.nodes.push(e);
        }
      });
      r.forEach(function (e) {
        if (r.find(function (o) {
          return t.surface.toolkitInstance.graph.isAncestor(e, o);
        }) == null) {
          i.groups.push(e);
        }
      });
      return i;
    }
  }, {
    key: "_addNewGroup",
    value: function e(o, r, i) {
      var a = this;
      o.toolkitInstance.transaction(function () {
        var e = a._generateNewObjectPayload(r, i),
          n = e.left,
          s = e.top;
        var u = o.toolkitInstance.addGroup(e);
        var l = [{
          v: u,
          x: n,
          y: s
        }];
        o.setSuspendRendering(true);
        var d = a._filterVertexSelection();
        d.nodes.forEach(function (e) {
          var i;
          var a = o.jsplumb.viewport.getPosition(e.id);
          var n = a.x - r.x,
            s = a.y - r.y;
          o.toolkitInstance.addToGroup(e, u);
          o.toolkitInstance.updateNode(e, (i = {}, t(i, o._modelLeftAttribute, n), t(i, o._modelTopAttribute, s), i));
          l.push({
            v: e,
            x: n,
            y: s
          });
        });
        d.groups.forEach(function (e) {
          var i;
          var a = o.jsplumb.viewport.getPosition(e.id);
          var n = a.x - r.x,
            s = a.y - r.y;
          o.toolkitInstance.addToGroup(e, u);
          o.toolkitInstance.updateGroup(e, (i = {}, t(i, o._modelLeftAttribute, n), t(i, o._modelTopAttribute, s), i));
          l.push({
            v: e,
            x: n,
            y: s
          });
        });
        if (a._parentGroupTarget != null) {
          o.toolkitInstance.addToGroup(u, a._parentGroupTarget);
        }
        l.forEach(function (e) {
          o.setPosition(e.v, e.x, e.y);
        });
      });
      o.setSuspendRendering(false);
    }
  }, {
    key: "_addNewNode",
    value: function e(t, o, r) {
      var i = this;
      t.setSuspendRendering(true);
      var a = this._generateNewObjectPayload(o, r);
      t.toolkitInstance.transaction(function () {
        var e = t.toolkitInstance.addNode(a);
        if (i._parentGroupTarget != null) {
          t.toolkitInstance.addToGroup(e, i._parentGroupTarget);
        }
        t.setPosition(e, a.left, a.top);
      });
      t.setSuspendRendering(false);
    }
  }, {
    key: "_resetSelectedElements",
    value: function e() {
      this.selectedElements.forEach(function (e) {
        return v(e, g);
      });
      this.selectedElements.length = 0;
    }
  }, {
    key: "reset",
    value: function e() {}
  }, {
    key: "activated",
    value: function e() {
      this.lasso.enabled = true;
    }
  }, {
    key: "deactivated",
    value: function e() {
      this.lasso.enabled = false;
    }
  }]);
  return r;
}();
t(m, "type", "vertexDrawing");
r.register(m.type, m);
export { g as CLASS_VERTEX_DRAWING_GROUP_CHILD_CANDIDATE, T as CLASS_VERTEX_DRAWING_LASSO, m as VertexDrawingPlugin };