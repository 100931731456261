import { inherits as r, createSuper as e, classCallCheck as t, defineProperty as a, assertThisInitialized as i } from "../../../_virtual/_rollupPluginBabelHelpers.js";
import { ArrowOverlay as o } from "./arrow-overlay.js";
import { OverlayFactory as s } from "../factory/overlay-factory.js";
var l = function (o) {
  r(l, o);
  var s = e(l);
  function l(r, e, o) {
    var n;
    t(this, l);
    n = s.call(this, r, e, o);
    n.instance = r;
    a(i(n), "type", l.type);
    n.foldback = 1;
    return n;
  }
  return l;
}(o);
a(l, "type", "PlainArrow");
function n(r) {
  return r.type === l.type;
}
s.register("PlainArrow", l);
export { l as PlainArrowOverlay, n as isPlainArrowOverlay };